import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCRdrDataReportGridComponent } from './rdr-data-report-grid.component';
import { UCRdrDataReportPageComponent } from './rdr-data-report-page.component';
import { UCRdrDataReportRoutingModule } from './rdr-data-report-routing.module';

@NgModule({
  imports: [
    SharedModule,
    UCRdrDataReportRoutingModule
  ],
  exports: [
    UCRdrDataReportPageComponent
  ],
  declarations: [
    UCRdrDataReportPageComponent,
    UCRdrDataReportGridComponent
  ]
})

export class UCRdrDataReportModule {

}
