'use strict';

// Inspection Details
export interface Login {
  // to store client Id
  client_id?: string;
  // to store client secret
  client_secret?: string;
  // to store grant_type
  grant_type?: string;
}
