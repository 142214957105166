import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCLexusDailyRecapReportRoutingModule }
    from './lexus-daily-recap-report-routing.module';
import { UCLexusDailyRecapPageReportComponent }
    from './lexus-daily-recap-report-page.component';
import { UCLexusDailyRecapRReportGridComponent }
    from './lexus-daily-recap-report-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCLexusDailyRecapReportRoutingModule
    ],
    exports: [
      UCLexusDailyRecapPageReportComponent
    ],
    declarations: [
      UCLexusDailyRecapPageReportComponent,
      UCLexusDailyRecapRReportGridComponent
    ]
})

export class UCLexusDailyRecapReportModule {

}
