import { ViewConstants } from '../../../shared/shared.constants';
import { ValidationTypes } from '../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// vin
      key: ViewConstants.DEALER_REGION_NAME_STRING_COLUMN, fieldName: 'regionName',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }, {// vin
      key: ViewConstants.DEALER_DISTRICT_COLUMN, fieldName: 'district',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCode',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '225', columnMinWidth: '225',
      columnMaxWidth: '225', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// obj
      key: 'OBJECTIVE', fieldName: 'objective',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }
  ];
  static subGridColumns = [
    {// vin
      key: ViewConstants.DEALER_DISTRICT_COLUMN, fieldName: 'district',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }, {// vin
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCode',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// obj
      key: 'OBJECTIVE', fieldName: 'objective',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }
  ];
}
