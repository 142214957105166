import {
  Component, ElementRef, EventEmitter, Input, OnInit,
  Output, ViewChild, ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import * as fromRoot from '../../reducers';
import { SharedDataService } from '../../services/common/shared-data.service';
import { ValidationTypes } from '../../services/model/ValidatorTypes';
import * as models from '../../services/model/models';
import * as viewModels from '../../services/view-model/view-models';
import { Observable } from 'rxjs';
import { DealerDetailView } from '../../services/view-model/view-models';
import * as moment from 'moment'

@Component({
  selector: 'uc-sale-detail',
  templateUrl: 'sale-detail.component.html',
  styleUrls: ['./sale-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/***
 * This component is used to the show the Vehicle Detail screen
 */
export class SaleDetailComponent implements OnInit {

  @ViewChild('rdrDetailForm',  {static: false}) form;

  public validationTypes: typeof ValidationTypes = ValidationTypes;

  interiorTrim: string;

  // to display error message after click on Add, Add and Certify button
  isErrorMessageDisplay: boolean = false;

  // to display error message after click on Add, Add and Certify button
  isErrorMessageDisplayForCo: boolean = false;

  // to get update flag value from parent page
  @Input() updateFlag: boolean;

  // to get update flag value from parent page
  @Input() addFlag: boolean;

  // to get update flag value from parent page
  @Input() addCertifyFlag: boolean;

  // to get update flag value from parent page
  @Input() isValidPQSS: boolean;

  // to get update flag value from parent page
  @Input() isValidCarFax: boolean;

  // to get update flag value from parent page
  @Input() isValidForeignvin: boolean;

  @Input() isMandatoryForCertify: boolean;

  displayMsgForRetailDate: boolean = false;

  displayMsgForVin: boolean = false;

  displayMsgForComments: boolean = false;

  @Input() myDatePickerOptions: IMyDpOptions;

  isCertifyEligible: boolean = false;

  isForceCertifyEligible: boolean = false;

  isDateChanged: boolean = false;

  showPriceWarning: boolean = false;

  regionNo: string;

  @Input() displayInvalidVin: boolean;

  @Input() displayInvalidDealerCode: boolean;

  @Input() showAdditionalDriver: boolean = false;

  @Input() isEmailMandatory: boolean = false;

  @Input() isCoEmailMandatory: boolean = false;

  @Input() isIndividualCustomer: boolean = false;

  zipCode: string;

  @Input() reversalFlag: boolean = false;

  @Input() reversedFlag: boolean = false;

  @Input() isForceCertified: boolean;

  @Input() fIManagerItems: any;

  @Input() saleManagerItems: any;

  @Input() oldRetailDate: any;

  @Input() isRetailModified: boolean = false;
  @Input() isValidatePQSS: boolean = false;

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  contactMethods: Array<any> = ['PHONE', 'EMAIL'];
  coBuyerContactMethods: Array<any> = ['PHONE', 'EMAIL'];

  taxIdLength: string = '10';

  salesCodeitems: Array<any> = this.sharedDataService.salesCodeItems;

  salesCodeitemsSET: Array<any> = this.sharedDataService.salesCodeItemsSET;

  salutationitems: Array<any> = this.sharedDataService.salutationItems;

  suffixitems: Array<any> = this.sharedDataService.suffixItems;

  stateItems: Array<any> =
    this.sharedDataService.stateItems.
      filter(t => t.disabled === this.sharedDataService.isTMEXUser);


  reversalCodeitems: Array<any> = this.sharedDataService.reversalCodeitems;
  rfcTaxIdBuyer = false;
  rfcTaxIdCoBuyer = false;
  isValidWarrantyContractNum = false;
  isRdrDetailsRdataPrePopulated = false;
  isSETDealer: boolean;
  maxFirstName: number = 20;
  maxBuyerLastName: number = 40;
  maxAddressLineOne: number = 28;
  maxBuyerCity: number = 28;
  maxEmail: number = 40;

  /***
 * To set the obtained VehicleDetailView object to _vehicleDetails
 */
  @Input('rdrDetails')
  set rdrDetails(value: viewModels.RdrDetailView) {
    // this._vehicleDetails = (<any>Object).assign({}, value);
    if (value !== null && value !== undefined && value.rdrData !== null &&
      value.rdrData !== undefined) {

      // if (!isNil(value)) {
      if (!isNil(value) && isNil(value.rdrData.vin)) {
        value.rdrData.vin = '';
        value.rdrData.dealerCd = '';
        value.rdrData.mileage = '';
      }
      if (!isNil(value) && !isNil(value.rdrData.vin) &&
        !isNil(value.rdrData.acquiredMethod)) {
        this.acquiredMethod = value.rdrData.acquiredMethod;
      }
      if (!isNil(value) && isNil(value.rdrData.comment)) {
        value.rdrData.comment = null;
      }

      if (!isNil(value) && !isNil(value.rdrData) &&
        !isNil(value.rdrData.rdrDetail.retailDate)) {
        // let retailDateCopy = value.rdrData.rdrDetail.retailDate;
        this.oldRetailDate = value.rdrData.rdrDetail.retailDate;
      }

      // if(isNil(this._vehicleDetails.vehicleData.inEligibleFlag)){
      //     this._vehicleDetails.vehicleData.inEligibleFlag='Y';
      // }

      this._rdrDetails = cloneDeep(value);
      this.setAddressMaxLength(this._rdrDetails?.rdrData?.rdrDetail?.saleType || '')
      this.isRdrDetailsRdataPrePopulated = this._rdrDetails && this._rdrDetails.rdrData &&
        this._rdrDetails.rdrData.rdrDetail && this._rdrDetails.rdrData.rdrDetail.retailDate
        ? true : false;
      if (!isNil(this._rdrDetails.rdrData.rdrDetail.buyer.zipCode)) {
        this.zipCode = this._rdrDetails.rdrData.rdrDetail.buyer.zipCode;
      }

      if (!isNil(this._rdrDetails.rdrData.rdrDetail.cobuyer) &&
        !isNil(this._rdrDetails.rdrData.rdrDetail.cobuyer.lastName)) {
        this.showAdditionalDriver = true;
      } else {
        this.showAdditionalDriver = false;
      }


      if (this._rdrDetails.rdrData.openCampaignStatus === 'Y') {
        this.isCertifyEligible = false;
        this.isForceCertifyEligible = false;
      } else if (this._rdrDetails.rdrData.carFaxFlag === 'N') {
        this.isCertifyEligible = false;
        this.isForceCertifyEligible = false;
      } else if (this._rdrDetails.rdrData.foreignVin === 'Y') {
        this.isCertifyEligible = false;
        this.isForceCertifyEligible = false;
      } else if (!isNil(this._rdrDetails.rdrData.model) &&
        !this.yearCheck(this._rdrDetails.rdrData.model.modelYear)) {
        this.isCertifyEligible = false;
        this.isForceCertifyEligible = true;
        this.addCertifyFlag = false;
      } else if (!isNil(this._rdrDetails.rdrData) &&
        (
          (this.sharedDataService.brandName === 'LEXUS' &&
            !this.yearCheck((new Date(this._rdrDetails.rdrData.dofu)).getFullYear()))
          || (this.sharedDataService.brandName === 'TOYOTA' &&
            !this.yearCheckForToyota(this._rdrDetails.rdrData.dofu))
        )) {
        this.isCertifyEligible = false;
        this.isForceCertifyEligible = true;
        this.addCertifyFlag = false;
      } else {
        this.isCertifyEligible = true;
        this.isForceCertifyEligible = true;
      }
      // }
      if (isEmpty(value.rdrData.vin)) {
        this.isErrorMessageDisplay = false;
        this.isErrorMessageDisplayForCo = false;
        this.form?.resetForm();
      }
    }

  }

  @Input() makeCode: string;

  @Input() certifiedFlag: string;

  @Input() forcecertifiedFlag: string;

  // Event emitter to naviagete to new vehicle detail event
  @Output() navigateToNewVehicleDetailEvent = new EventEmitter<any>();

  // Event emitter to naviagete to rdr summary event
  @Output() navigateToRDRSummaryEvent = new EventEmitter<any>();

  // Event emitter to naviagete to get vehicle details event
  @Output() getVehicleDetailsEvent = new EventEmitter<any>();

  // Event emitter to naviagete to get details details event
  @Output() getDealerEvent = new EventEmitter<any>();

  // Event emitter to save vehicle
  @Output() saveVehicleEvent = new EventEmitter<any>();

  // Event emitter to save vehicle
  @Output() zipcodeChangeEvent = new EventEmitter<any>();

  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<DealerDetailView>;

  /***
* To get the obtained VehicleDetailView object to _vehicleDetails
*/
  get rdrDetails() {
    return this._rdrDetails;
  }

  @Input() saveButtonText: string;

  @Input() updateButtonText: string;

  @Input() warrantExpDateText: string;

  @Input() isCertify: boolean;

  acquiredMethod: string;

  columns2 = [
    { prop: 'code', name: 'Code' },
    { prop: 'name', name: 'Name' }
  ];

  public rows2: Array<models.AccessoryDetails> = [];

  // To set and get the obtained VehicleDetailView object from reducer using this variable
  public _rdrDetails: viewModels.RdrDetailView
    = <viewModels.RdrDetailView>new Object();

  // private moment = require('moment');

  onDateSelected(event: IMyDateModel, value: string) {
    this._rdrDetails = cloneDeep(this._rdrDetails);
    let tempRdr = this._rdrDetails.rdrData;
    this.isDateChanged = true;
    this.sharedDataService.isPageEdited = true;
    let date = event.formatted;

    switch (value) {
      case 'retailDate': {
        if (this.updateFlag &&
          (this.convertDateDisplay(this.oldRetailDate) !== cloneDeep(String(date)))) {
          this.isRetailModified = true;
        } else {
          this.isRetailModified = false;
        }
        this._rdrDetails.rdrData.rdrDetail.retailDate = cloneDeep(String(date));
        return;
      }
      default:
        return '';
    }

  }

  /**
   * Constructor method to create new instance of VehicleDetailComponent
   */
  constructor(public router: Router,
    private store: Store<fromRoot.AppState>, private el: ElementRef,
    public sharedDataService: SharedDataService) {
    // To initialize _vehicleDetails and selectedVinIndex
    this._rdrDetails = <viewModels.RdrDetailView>new Object();
    try {
      this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
    } catch (error) {
    } finally {

    }
  }


  /**
   * Init method to initialize state of VehicleDetailDetailComponent
   */
  ngOnInit() {    
    this.dealerDetailsData$.subscribe(x => {
      this.regionNo = x?.currentDealer.areaRegionNo;
      this._rdrDetails.rdrData.rdrDetail.regionNo = this.regionNo;
      if (this.regionNo === '50') {
        this.isSETDealer = true;
        this.showAdditionalDriver = false;
        this.salutationitems = this.sharedDataService.salutationItemsForSET;
      } else {
        this.isSETDealer = false;
      }
    },
      error => {
        this.regionNo = '';
      });
  }

  salesValueChanged(event: any) {
    if (!isNil(event.value)) {
      this.isErrorMessageDisplay = false;
      this.isErrorMessageDisplayForCo = false;
      if (event.value === '01' || event.value === '05') {
        this.isIndividualCustomer = true;
      } else {
        this.isIndividualCustomer = false;
      }
      this.setAddressMaxLength(event?.value || '');
    }
  }

  setAddressMaxLength(eventValue:any){
    if(this.sharedDataService.isTMEXUser){
      this.maxFirstName = 40;
      this.maxBuyerLastName = 60;
      this.maxAddressLineOne = 28;//same for address line tqwo
      this.maxBuyerCity = 28;
      this.maxEmail = 40;
    }
    else{
      this.maxFirstName = eventValue === '03' ? 35 : this.isSETDealer ? 15 : 20;
      this.maxBuyerLastName = eventValue === '03' ? 40 : this.isSETDealer ? 20 : 40;
      this.maxAddressLineOne =  this.isSETDealer ? 30 : 28;
      this.maxBuyerCity =  this.isSETDealer ? 20 : 28 
      this.maxEmail = this.isSETDealer ? 50 : 40;
 
    }
    
  }

  valueChanged(event: any) {
    this.sharedDataService.isPageEdited = true;
  }

  /**
   * Method to emit the delete disclaimer event emitter
   */
  navigateToNewVehicleDetail(event: any) {
    this.navigateToNewVehicleDetailEvent.emit(event);
  }

  /**
   * Method to emit the delete disclaimer event emitter
   */
  navigateToRDRSumamry(event: any) {
    this.navigateToRDRSummaryEvent.emit(event);
  }

  getVehicleDetails(event: any) {
    if (!isEmpty(event)) {
      this.displayMsgForVin = false;
      if (event.length >= 17) {
        event = event.toUpperCase().trim();
        this._rdrDetails.rdrData.vin = event;
        this.getVehicleDetailsEvent.emit(event);
      } else {
        this.displayMsgForVin = true;
      }
    }
  }

  saveRDR(event: any) {
    this.form.form.markAsPristine();
    this.isErrorMessageDisplay = true;
    this.displayMsgForComments = false;
    if (isEmpty(this._rdrDetails.rdrData.comment) && this.isValidatePQSS) {
      this.displayMsgForComments = true;
    }
    if (!isNil(event.rdrData.rdrDetail.cobuyer) &&
      (!isNil(event.rdrData.rdrDetail.cobuyer.lastName)
        || !isNil(event.rdrData.rdrDetail.cobuyer.addressLineOne) ||
        !isNil(event.rdrData.rdrDetail.cobuyer.state))) {
      this.isErrorMessageDisplayForCo = true;
    }
    // this.isMandatoryForCertify = true;
    this.displayMsgForVin = false;
    if (this.form.valid) {
      this.saveVehicleEvent.emit({
        vehicleData: event,
        isEmailMandatory: this.isEmailMandatory,
        isCoEmailMandatory: this.isCoEmailMandatory,
        additionalDriver: this.showAdditionalDriver,
        certificationFlag: 'true',
        registrationFlag: 'false',
        forceCertificationFlag: 'true',
        deCertifyFlag: 'false',
        isIndividualCustomer: this.isIndividualCustomer,
        isRetailModified: this.isRetailModified,
        oldRetailDate: this.oldRetailDate,
        isValidatePQSS: this.isValidatePQSS,
      });
    } else if (event.rdrData.vin.length < 17) {
      this.displayMsgForVin = true;
    }
  }

  convertDateDisplay(fromdate: any) {
    let date = new Date(fromdate);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return month + '-' + dt + '-' + year;
  }




  canShowErrorMessage(fieldName: string) {
    if (fieldName === 'retailDate') {
      this.displayMsgForRetailDate = true;
    }
  }

  dealerChangeEvent() {
    this.getDealerEvent.emit(this._rdrDetails.rdrData.dealerCd);
  }

  pageEdited(event: any) {
    if (this.sharedDataService.rdrScreenAccess) {
      if (event.keyCode >= 65 && event.keyCode <= 90) {
        this.sharedDataService.isPageEdited = true;
      } else if (event.keyCode >= 48 && event.keyCode <= 57) {
        this.sharedDataService.isPageEdited = true;
      }
    }
  }

  yearCheck(year: number): boolean {
    let currDate = new Date();
    let currYear = currDate.getFullYear();
    return currYear - year <= 6 ? true : false;
  }

  yearCheckForToyota(dofu: any): boolean {
    let a = moment(dofu, 'YYYY-MM-DD');
    let b = moment();
    let diff = a.diff(b, 'year');
    let year = Math.abs(diff);
    return year <= 6 ? true : false;
  }


  showAdditionalDriverSection() {
    this.showAdditionalDriver = true;
    this._rdrDetails.rdrData.rdrDetail.cobuyer = <models.Buyer>new Object();
    this._rdrDetails.rdrData.rdrDetail.cobuyer.customerConsent = false;
  }

  hideAdditionalDriverSection() {
    this.showAdditionalDriver = false;
    this._rdrDetails.rdrData.rdrDetail.cobuyer = null;
    this.form.form.markAsDirty();
  }

  zipcodeChange(event: any) {
    this.zipcodeChangeEvent.emit(event);
  }

  emailOptions(event: any) {
    this.isEmailMandatory = event.checked;
    if (event.checked) {
      this.contactMethods = ['PHONE'];
      this._rdrDetails.rdrData.rdrDetail.buyer.contactMethod = 'PHONE';
    } else {
      this.contactMethods = ['PHONE', 'EMAIL'];
    }
  }

  coEmailOptions(event: any) {
    this.isCoEmailMandatory = event.checked;
    if (event.checked) {
      this.coBuyerContactMethods = ['PHONE'];
      this._rdrDetails.rdrData.rdrDetail.cobuyer.contactMethod = 'PHONE';
    } else {
      this.coBuyerContactMethods = ['PHONE', 'EMAIL'];
    }
  }

  checkMandatoryAlphaMandatoryNumeric(event, formName) {
    const mandatoryAlphaMandatoryNumeric = '^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{10,}$';
    let regex = new RegExp(mandatoryAlphaMandatoryNumeric);
    if (formName === 'buyer') {
      this.rfcTaxIdBuyer = regex.test(event.target.value);
    } else {
      this.rfcTaxIdCoBuyer = regex.test(event.target.value);
    }
  }

  checkWarrantyContractNum(event) {
    const mandatoryAlphaMandatoryNumeric = '^([A-Z]{1})([0-9]{11})$';
    let regex = new RegExp(mandatoryAlphaMandatoryNumeric);
    this.isValidWarrantyContractNum = regex.test(event.target.value);
  }

  skipPQSSChange(event: any) {
    this.isValidatePQSS = event.checked;
  }
}
