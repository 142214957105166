import { FilterType } from '../../../../services/model/Filter';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { ViewConstants } from '../../../../shared/shared.constants';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    { // Region No
      key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'areaRegionNo', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '80',
      columnMinWidth: '80', columnMaxWidth: '80', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.STARTS_WITH
    },
    {// Region Name
      key: ViewConstants.DEALER_REGION_NAME_STRING_COLUMN, fieldName: 'regionName',
      style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_4_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// district Name
      key: ViewConstants.DISTRICT_NAME_COLUMN, fieldName: 'districtNo', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '80',
      columnMinWidth: '80', columnMaxWidth: '80', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer #
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer Name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '150', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.VIN, fieldName: 'vin',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '185', columnMinWidth: '185',
      columnMaxWidth: '185', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// Sale DATE
      key: ViewConstants.CERTIFICATION_STATUS, fieldName: 'certificationStatus', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '160',
      columnMinWidth: '160', columnMaxWidth: '160', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// certification date
      key: ViewConstants.CERTIFICATION_NUMBER, fieldName: 'certificationNumber',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
     {// Gold Silver Certification
      key: ViewConstants.GOLD_SILVER_CERTIFICATION_TIER, fieldName: 'certificationType',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
      columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.CERTIFICATION_DATE,
      fieldName: 'certification.certificationDate', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// SALE DATE
      key: ViewConstants.SALE_DATE,
      fieldName: 'rdrDetail.retailDate', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// SALE TYPE
      key: ViewConstants.SALE_TYPE,
      fieldName: 'rdrDetail.saleType', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// SALE MILEAGE
      key: ViewConstants.SALE_MILEAGE,
      fieldName: 'rdrDetail.mileage', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// MAKE
      key: ViewConstants.MAKE,
      fieldName: 'makeCode', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// ACQUIRED DATE
      key: ViewConstants.ACQUIRED_DATE,
      fieldName: 'acquiredDate', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// RDR DATE
      key: ViewConstants.RDR_DATE,
      fieldName: 'rdrDetail.createdAt', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// SALES PRICE
      key: ViewConstants.SALES_PRICE,
      fieldName: 'rdrDetail.soldAmount', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// RECONDITION COST
      key: ViewConstants.RECONDITION_COST,
      fieldName: 'repairOrder.reconditionCost', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// MODEL NUMBER
      key: ViewConstants.MODEL_NUMBER,
      fieldName: 'model.modelNumber', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// MODEL YEAR
      key: ViewConstants.MODEL_YEAR,
      fieldName: 'model.modelYear', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// MODEL SERIES
      key: ViewConstants.MODEL_SERIES,
      fieldName: 'model.modelSeries', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// MODEL SERIES DESCRIPTION
      key: ViewConstants.MODEL_DESC,
      fieldName: 'model.modelDescription', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// MODEL TYPE
      key: ViewConstants.VEHICLE_TYPE,
      fieldName: 'type', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// rdr status
      key: ViewConstants.RDR_STATUS, fieldName: 'rdrDetail.rdrStatus', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false',
      filterValue: '', dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// RDR REVERSAL DATE
      key: ViewConstants.REVERSAL_DATE, fieldName: 'rdrDetail.reversalDate', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '120', currencyField: 'false',
      filterValue: '', dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// ACQUIRED_TO_CERT
      key: ViewConstants.ACQUIRED_TO_CERT,
      fieldName: 'acquiredTOCert', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// CERT_TO_SALE
      key: ViewConstants.CERT_TO_SALE,
      fieldName: 'certTOSale', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dofu
      key: ViewConstants.DOFU,
      fieldName: 'dofu', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// ACV PRICE
      key: ViewConstants.ACV_PRICE,
      fieldName: 'price.acvPrice', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Same day Cert
      key: ViewConstants.SAME_DAY_CERT,
      fieldName: 'rdrDetail.sameDayCert', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// SALE_MONTH
      key: ViewConstants.SALE_MONTH,
      fieldName: 'rdrDetail.saleMonth', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// overlap
      key: ViewConstants.OVERLAP,
      fieldName: 'rdrDetail.overlap', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// RT_VEHCILE
      key: ViewConstants.RT_VEHCILE,
      fieldName: 'rdrDetail.rtVehicle', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// SALE_TO_RDR
      key: ViewConstants.SALE_TO_RDR,
      fieldName: 'rdrDetail.saleDateToRdrDate', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
  ];

  static totalGridColumns = [
    {// region Name
      key: ViewConstants.REGION_NAME_COLUMN, fieldName: 'regionName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// district Name
      key: ViewConstants.DISTRICT_NAME_COLUMN, fieldName: 'districtNo', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// district Total
      key: ViewConstants.DISTRICT_TOTAL_COLUMN, fieldName: 'districtTotal', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer name
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealer', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// dealer Name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer Total
      key: ViewConstants.DEALER_TOTAL_COLUMN, fieldName: 'total', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }

  ];
}


