import { NgModule } from '@angular/core';
import { SaleDetailPageComponent } from './sale-detail-page.component';
import { SaleDetailComponent } from './sale-detail.component';
import { SharedModule } from '../../shared/shared.module';
import { SaleDetailDialogService } from './sale-detail-dialog/sale-detail-dialog.service';
import { SaleDetailDialogComponent } from './sale-detail-dialog/sale-detail-dialog.component';
import { SaleDetailRoutingModule } from './sale-detail-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    imports: [
        SharedModule,
        SaleDetailRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule
    ],
    exports: [
        SaleDetailComponent,
        SaleDetailPageComponent,
        SaleDetailDialogComponent
    ],
    declarations: [
        SaleDetailComponent,
        SaleDetailPageComponent,
        SaleDetailDialogComponent
    ],
    providers: [
        SaleDetailDialogService
    ]
})
export class SaleDetailModule {
}
