'use strict';
// Import statement required for Vehicle
import * as Models from './models';

// Model for holding Vehicle Informations
export interface AccountingDetails {
  // To store VIN number
  vin?: string;
  model?: Models.Model;
  // rdrDetail
  rdrDetail?: Models.RdrDetail;
  // To store certification details
  certification?: Models.CertificationDetails;
  // To store Sale Date
  saleDate?: string;
  // retailDate
  retailDate?: string;
  // To store Sale Code
  salecode?: string;
  // To store DOFU
  dofu?: string;
  // To store Dealer Code
  dealerCd?: string;
  // To store Dealer Name
  dealerName?: string;
  // To store make and model
  makeModel?: string;
  // To store customer name
  customerName?: string;
  // To store Reversal Date
  reversalDate?: string;
  // To store Reversal Code
  reversalcode?: string;
  // To store Acquired Method
  acquiredMethod?: string;
  // To store Acquired Date
  acquiredDate?: string;
  // To store Road Side Exp Date
  roadsideexpDate?: string;
  // To store Warranty Exp Date
  warrantyexpDate?: string;
  // To store Warranty
  warranty?: string;
  // Road Side Fee
  roadsideFee?: number;
  // certification
  // certification?: string;
  // certification date
  certificationDate?: string;
  // RDR report date
  rdrreportDate?: string;
  // Sale Mileage
  saleMileage?: string;
  // To store Inventory Status
  inventoryStatus?: string;
  // Agreement Number
  agreementNumber?: number;
  // Submittal Date
  agreementSubmittalDate?: string;
  // TMIS Invoice Date
  agreementInvoiceDate?: string;
  // Plan Code
  planCode?: string;
  // Application Number
  applicationNumber?: string;
  // plan amount
  planAmount?: number;
  // bill date
  billDate?: string;
  // MIS Invoice Date
  misinvoiceDate?: string;
  // bill amount
  billAmount?: number;
  // To store isTradedVehicle
  isTradedVehicle?: string;

  certificationNumber?: string;

  sellArea?: string;

  certificationType?: 'GOLD' | 'SILVER';

}
