import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SaleDetailPageComponent } from './sale-detail-page.component';
// import { AuthService } from '../../services/common/auth.service';
// import { AuthGuardService } from '../../services/common/auth-guard.service';

const saleDetailRoutes: Routes = [
  {
    path: 'saleDetail/:vin/:dlrCd/:action',
    component: SaleDetailPageComponent
    // canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(saleDetailRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class SaleDetailRoutingModule { }
