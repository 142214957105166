import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dealer-vin-search-report-toolbar',
  templateUrl: 'dealer-vin-search-report-toolbar.component.html',
  styleUrls: ['./dealer-vin-search-report-toolbar.component.scss']
})

export class DealerVinSearchReportToolbarComponent {

  @Output() viewInventoryEvent = new EventEmitter<any>();

  @Output() findDealerEvent = new EventEmitter<any>();

  @Output() findVehicleEvent = new EventEmitter<any>();

  @Input() enteredDealerCode: string;

  @Input() enteredvin: string;

  viewInventory() {
    this.viewInventoryEvent.emit(this.enteredDealerCode);
  }

  findDealer() {
    this.findDealerEvent.emit();
  }

  findVehicle() {
    this.enteredvin = this.enteredvin.toUpperCase().trim();
    this.findVehicleEvent.emit(this.enteredvin);
  }
}
