'use strict';
// Model holding Global Filter data
// Import statement required for Vehicle
import * as Models from './models';
export interface VehicleHistory {
  // To store vin value
  vin?: string;
  // To store vin value
  dealerCd?: string;
  // To store vin value
  model?: Models.Model;
  // To store vin value
  mileage?: string;
  // To store vin value
  price?: Models.Price;
  // To store evebts
  events?: any[];
  // To store isNew
  isNew?: boolean;
}
