import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { UCLayoutComponent } from './components/layout/layout.component';
import { HttpClient } from './components/helpers/http-client';
import { UCLayoutNavComponent } from './components/layout/layout-nav/layout-nav.component';
import { UCMessageComponent } from './components/message-panel/message-display.component';
import { UCMenubarPanelComponent } from './components/menubar-panel/menubar-panel.component';
import { UCAccountingMenubarPanelComponent } from './components/accounting-menubar-panel/accounting-menubar-panel.component';
import { UCRdrMenubarPanelComponent }
  from './components/rdr-menubar-panel/rdr-menubar-panel.component';
import { UCSalesMenubarPanelComponent }
  from './components/sales-menubar-panel/sales-menubar-panel.component';
import { UCInventoryReportMenubarPanelComponent }
  from './components/inventory-report-menubar-panel/inventory-report-menubar-panel.component';
import { UCSaleReversalReportMenubarPanelComponent }
  from './components/sale-reversal-report-menubar-panel/sale-reversal-report-menubar-panel.component';
import { DealerVinSearchToolbarComponent } from
  './components/dealer-vin-search-toolbar/dealer-vin-search-toolbar.component';
import { AccountingVinSearchToolbarComponent } from
  './components/accounting-vin-search-toolbar/accounting-vin-search-toolbar.component';
import { RdrDealerVinSearchToolbarComponent } from
  './components/rdr-dealer-vin-search-toolbar/rdr-dealer-vin-search-toolbar.component';
import { RegionDashboardSearchToolbarComponent } from
  './components/region-dashboard-search-toolbar/region-dashboard-search-toolbar.component';
import { UCRegionDashboardMenubarPanelComponent }
  from './components/region-dashboard-menubar-panel/region-dashboard-menubar-panel.component';
import { SalesDealerVinSearchToolbarComponent } from
  './components/sales-dealer-vin-search-toolbar/sales-dealer-vin-search-toolbar.component';
import { DealerVinSearchReportToolbarComponent } from
  './components/dealer-vin-search-report-toolbar/dealer-vin-search-report-toolbar.component';
import { VehicleReportVinSearchToolbarComponent } from
  './components/vehicle-report-vin-search-toolbar/vehicle-report-vin-search-toolbar.component';
import { RDRReportSearchToolbarComponent } from
  './components/rdr-report-search-toolbar/rdr-report-search-toolbar.component';
import { InventoryReportSearchToolbarComponent } from
  './components/inventory-report-search-toolbar/inventory-report-search-toolbar.component';
import { RecallReportSearchToolbarComponent } from
  './components/recall-report-search-toolbar/recall-report-search-toolbar.component';
import { VehicleCertificationReportSearchToolbarComponent } from
  './components/vehicle-certification-report-search-toolbar/vehicle-certification-report-search-toolbar.component';
import { VehicleReversalReportVinSearchToolbarComponent } from
  './components/vehicle-reversal-report-vin-search-toolbar/vehicle-reversal-report-vin-search-toolbar.component';
import { UCObjectiveMenubarPanelComponent }
  from './components/objective-menubar-panel/objective-menubar-panel.component';
import { RegionSelectionSearchToolbarComponent } from
  './components/region-selection-search-toolbar/region-selection-search-toolbar.component';
import { ExportToExcelService } from '../services/common/excel-service';
import { UCSalesTrendMenubarPanelComponent }
  from './components/sales-trend-menubar-panel/sales-trend-menubar-panel.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UCMFieldValidatorDirective } from './components/fieldvalidator/fieldvalidator.directive';
import { Logger } from './components/helpers/Logger';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogService } from './components/dialog/dialog.service';
import { DealerDetailsDialogComponent }
  from './components/dealer-dialog/dealer-details-dialog.component';
import { DealerDetailsDialogService }
  from './components/dealer-dialog/dealer-details-dialog.service';
import { MyDatePickerModule } from 'mydatepicker';
import { UCCurrencyPricePipe } from './pipes/currency-price.pipe';
import { UCNormalPricePipe } from './pipes/normal-price.pipe';
import { UCLayoutService } from './components/layout/services/layout.services';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        MaterialModule,
        NgxDatatableModule,
        MyDatePickerModule,
        FlexLayoutModule,
        InfiniteScrollModule
    ],
    declarations: [
        UCLayoutComponent,
        UCLayoutNavComponent,
        UCMessageComponent,
        UCMenubarPanelComponent,
        UCAccountingMenubarPanelComponent,
        UCRdrMenubarPanelComponent,
        UCSalesMenubarPanelComponent,
        UCInventoryReportMenubarPanelComponent,
        UCSaleReversalReportMenubarPanelComponent,
        DealerVinSearchToolbarComponent,
        AccountingVinSearchToolbarComponent,
        RdrDealerVinSearchToolbarComponent,
        SalesDealerVinSearchToolbarComponent,
        DealerVinSearchReportToolbarComponent,
        VehicleReportVinSearchToolbarComponent,
        VehicleReversalReportVinSearchToolbarComponent,
        RegionSelectionSearchToolbarComponent,
        UCObjectiveMenubarPanelComponent,
        UCSalesTrendMenubarPanelComponent,
        UCMFieldValidatorDirective,
        DialogComponent,
        DealerDetailsDialogComponent,
        UCCurrencyPricePipe,
        UCNormalPricePipe,
        RDRReportSearchToolbarComponent,
        InventoryReportSearchToolbarComponent,
        RecallReportSearchToolbarComponent,
        VehicleCertificationReportSearchToolbarComponent,
        UCRegionDashboardMenubarPanelComponent,
        RegionDashboardSearchToolbarComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        MaterialModule,
        NgxDatatableModule,
        UCLayoutComponent,
        UCLayoutNavComponent,
        UCMessageComponent,
        UCMenubarPanelComponent,
        UCAccountingMenubarPanelComponent,
        UCRdrMenubarPanelComponent,
        UCSalesMenubarPanelComponent,
        UCInventoryReportMenubarPanelComponent,
        UCSaleReversalReportMenubarPanelComponent,
        DealerVinSearchToolbarComponent,
        AccountingVinSearchToolbarComponent,
        RdrDealerVinSearchToolbarComponent,
        SalesDealerVinSearchToolbarComponent,
        DealerVinSearchReportToolbarComponent,
        VehicleReportVinSearchToolbarComponent,
        VehicleReversalReportVinSearchToolbarComponent,
        RegionSelectionSearchToolbarComponent,
        UCObjectiveMenubarPanelComponent,
        UCSalesTrendMenubarPanelComponent,
        UCMFieldValidatorDirective,
        DialogComponent,
        DealerDetailsDialogComponent,
        MyDatePickerModule,
        UCCurrencyPricePipe,
        UCNormalPricePipe,
        FlexLayoutModule,
        RDRReportSearchToolbarComponent,
        InventoryReportSearchToolbarComponent,
        RecallReportSearchToolbarComponent,
        VehicleCertificationReportSearchToolbarComponent,
        UCRegionDashboardMenubarPanelComponent,
        RegionDashboardSearchToolbarComponent
    ],
    providers: [
        Logger,
        HttpClient,
        DialogService,
        DealerDetailsDialogService,
        UCLayoutService,
        ExportToExcelService
    ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [

      ]
    };
  }
}


