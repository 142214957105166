import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCSaleReversalReportRoutingModule } from './sale-reversal-report-routing.module';
import { UCSaleReversalPageReportComponent } from './sale-reversal-report-page.component';
import { UCSaleReversalReportGridComponent } from './sale-reversal-report-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCSaleReversalReportRoutingModule
    ],
    exports: [
        UCSaleReversalPageReportComponent
    ],
    declarations: [
        UCSaleReversalPageReportComponent,
        UCSaleReversalReportGridComponent
    ]
})

export class UCSaleReversalReportModule {
}
