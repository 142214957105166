import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCInventoryExceptionPageReportComponent }
    from './inventory-exception-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
    {
        path: 'inventoryExceptionReport',
        component: UCInventoryExceptionPageReportComponent,
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(inventorySummaryRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})

export class UCInventoryExceptionReportRoutingModule {

}
