

import { ActionBase } from '../actions/actionbase';
import { DealerDetailView } from '../services/view-model/dealer-detail-view';
import { DealerDetailsModel } from '../services/model/DealerDetailsModel';
import * as dealerDetailActions from '../actions/dealer-detail.action';
import { isNil, cloneDeep } from 'lodash';
import * as models from '../services/model/models';
import { ScreenMessages } from '../shared/master-data/messages';

/**
 * Interface used to maintain the state for the dealer details
 */
export interface DealerDetailState {
  dealerDetails: DealerDetailView;
}
/**
 * Variable used to store the initial state of Dealer Detail
 */
const initialState: DealerDetailState = {
  dealerDetails: null
};

/**
 * Method used to set the default initial state of VehicleDetailsState
 */
function setDefaulValue(): DealerDetailView {
  let dealerDetails: DealerDetailView =
    <DealerDetailView>new Object();
  dealerDetails.currentDealer = null;
  dealerDetails.allDealers = new Array();
  return dealerDetails;
}
/**
 * Reducer method to handle the dealer detail actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): DealerDetailState {
  switch (action.type) {
    case dealerDetailActions.actionTypes.LOAD_DEALER_DETAIL_SUCCESS: {
      let dealerDetail: DealerDetailsModel = {};
      if (!isNil(action.payload.Item)) {
        dealerDetail.dealerCode = action.payload.Item.dlrNo;
        dealerDetail.dealerName = action.payload.Item.dealerName.trim();
        dealerDetail.certDlrFlag = action.payload.Item.certDlrFlag;
        dealerDetail.fuelCellDlrFlag = action.payload.Item.fuelCellDlrFlag;
        dealerDetail.city = action.payload.Item.city.trim();
        dealerDetail.state = action.payload.Item.state.trim();
        dealerDetail.areaRegionName = action.payload.Item.regionName.trim();
        dealerDetail.areaRegionNo = action.payload.Item.regionNo.trim();
        dealerDetail.updatedAt = action.payload.Item.updatedAt;
        dealerDetail.comments = action.payload.Item.comments;
        dealerDetail.janObjective = action.payload.Item.janObjective;
        dealerDetail.febObjective = action.payload.Item.febObjective;
        dealerDetail.marObjective = action.payload.Item.marObjective;
        dealerDetail.aprObjective = action.payload.Item.aprObjective;
        dealerDetail.mayObjective = action.payload.Item.mayObjective;
        dealerDetail.junObjective = action.payload.Item.junObjective;
        dealerDetail.julObjective = action.payload.Item.julObjective;
        dealerDetail.augObjective = action.payload.Item.augObjective;
        dealerDetail.sepObjective = action.payload.Item.sepObjective;
        dealerDetail.octObjective = action.payload.Item.octObjective;
        dealerDetail.novObjective = action.payload.Item.novObjective;
        dealerDetail.decObjective = action.payload.Item.decObjective;
      }
      return {
        dealerDetails: (<any>Object).assign({},state.dealerDetails,
          {
            currentDealer: dealerDetail,
            allDealers: null
          })
      };
    }
    case dealerDetailActions.actionTypes.LOAD_DEALER_DETAIL_FAIL: {
      let dealerDetail: DealerDetailsModel = {};
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      if (errorResponse === ScreenMessages.SERVICE_FAILURE_MESSAGE ||
        errorResponse === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
        message.message = errorResponse;
        message.type = models.MessageType.DANGER;
        return {
          dealerDetails: (<any>Object).assign({}, state.dealerDetails,
            {
              message: message,
              currentDealer: dealerDetail
            })
        };
      } else {
        return {
          dealerDetails: (<any>Object).assign({}, state.dealerDetails,
            {
              currentDealer: dealerDetail
            })
        };
      }
    }
    case dealerDetailActions.actionTypes.LOAD_ALL_DEALERS_SUCCESS: {
      let dealers = frameDealerDetails(cloneDeep(action.payload));
      return {
        dealerDetails: (<any>Object).assign({}, state.dealerDetails,
          {
            allDealers: dealers
          })
      };
    }
    case dealerDetailActions.actionTypes.LOAD_ALL_DEALERS_FAIL: {
      return {
        dealerDetails: (<any>Object).assign({}, state.dealerDetails,
          {
            allDealers: []
          })
      };
    }
    case dealerDetailActions.actionTypes.CLEAR_ALL_ACTION: {
      return { dealerDetails: setDefaulValue() };
    }
    default: {
      return state;
    }
  }
}
/* tslint:enable:no-switch-case-fall-through */

/**
 * Method to get the state of dealer details
 */
export function getDealerDetail(state: DealerDetailState) {
  return state.dealerDetails;
}

function frameDealerDetails(allDealers: any): DealerDetailsModel[] {
  let formattedDealerDetails: DealerDetailsModel[] = [];
  let tempDealerDetails: DealerDetailsModel = {};
  let phone: any[] = [];
  if (!isNil(allDealers)) {
    allDealers.Items.map(dt => {
      tempDealerDetails = {};
      tempDealerDetails.dealerCode = dt.dlrNo;
      tempDealerDetails.dealerName = dt.dealerName;
      tempDealerDetails.address = dt.address;
      tempDealerDetails.city = dt.city;
      tempDealerDetails.state = dt.state;
      tempDealerDetails.areaRegionNo = dt.regionNo;
      tempDealerDetails.areaRegionName = dt.regionName;
      tempDealerDetails.brand = dt.dealerType;
      tempDealerDetails.zip = dt.zip;
      tempDealerDetails.phone = '';
      tempDealerDetails.districtNo = dt.districtNo;
      tempDealerDetails.selected = false;
      tempDealerDetails.janObjective = dt.janObjective;
      tempDealerDetails.febObjective = dt.febObjective;
      tempDealerDetails.marObjective = dt.marObjective;
      tempDealerDetails.aprObjective = dt.aprObjective;
      tempDealerDetails.mayObjective = dt.mayObjective;
      tempDealerDetails.junObjective = dt.junObjective;
      tempDealerDetails.julObjective = dt.julObjective;
      tempDealerDetails.augObjective = dt.augObjective;
      tempDealerDetails.sepObjective = dt.sepObjective;
      tempDealerDetails.octObjective = dt.octObjective;
      tempDealerDetails.novObjective = dt.novObjective;
      tempDealerDetails.decObjective = dt.decObjective;
      tempDealerDetails.terminatedFlag = dt.terminatedFlag;
      tempDealerDetails.terminatedDate = dt.terminatedDate;
      formattedDealerDetails.push(tempDealerDetails);
    });
  }
  return formattedDealerDetails;
}


