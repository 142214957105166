import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VehicleDecertificationPageComponent } from './vehicle-decertification-page.component';
import { AuthenticationGuard } from '../../services/common/auth-guard';
// import { AuthService } from '../../services/common/auth.service';
// import { AuthGuardService } from '../../services/common/auth-guard.service';

const rDRDetailRoutes: Routes = [
  {
    path: 'vehicleDecertification',
    component: VehicleDecertificationPageComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'vehicleDecertification/:vin/:dlrCd/:action',
    component: VehicleDecertificationPageComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(rDRDetailRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class VehicleDecertificationRoutingModule { }
