




import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { VehicleObjectiveService } from '../services/api/vehicle-objective.service';

import * as detailActions from '../actions/vehicle-objective.action';

import * as models from '../services/model/models';
import {map, catchError, switchMap} from 'rxjs/operators'

/**
 * Class used to handle the service interaction in the vehicle detail screen
 */
@Injectable()
export class VehicleObjectiveEffects {
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getDealerObjective$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.GET_DEALER_OBJECTIVE)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleObjectiveService.getDealerObjective(data)
      .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.GetDealerObjectiveSuccessAction(vehicleData))
          , catchError(error =>
          of(new detailActions.GetDealerObjectiveFailureAction(
            { 'error': error, 'vehicleData': data }))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getRegionObjective$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.GET_REGION_OBJECTIVE)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleObjectiveService.getRegionObjective(data)
      .pipe(map((vehicleData: any) =>
          new detailActions.GetRegionObjectiveSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.GetRegionObjectiveFailureAction(error))))
    ));
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  saveRegionObjective$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.SAVE_REGION_OBJECTIVE)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleObjectiveService.saveRegionObjective(data)
      .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.SaveRegionObjectiveSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.SaveRegionObjectiveFailureAction(error))))
    ));
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  saveDealerObjective$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.SAVE_DEALER_OBJECTIVE)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleObjectiveService.saveDealerObjective(data)
        .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.SaveDealerObjectiveSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.SaveDealerObjectiveFailureAction(error))))
    ));

  /**
   * Constructor method used to create new instance of VehicleDetailsEffects class
   */
  constructor(private actions$: Actions,
    private vehicleObjectiveService: VehicleObjectiveService) { }
}
