import { ViewConstants } from '../../../../shared/shared.constants';
import { Filter, FilterType } from '../../../../services/model/Filter';

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// Region No
      key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'regionNumber',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS,
    },
    {// Region Name
      key: ViewConstants.DEALER_REGION_NAME_STRING_COLUMN, fieldName: 'regionName',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS,
    }, {// Region No
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS,
    },
    {// Region No
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS,
    },
    {// De Certify reason
      key: ViewConstants.TOTAL_SALES, fieldName: 'totalRDR',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH, tooltip: '',
    },
    {// De Certify reason
      key: ViewConstants.TOTAL_REVERSAL, fieldName: 'totalReversal',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
    },
    {// De Certify reason
      key: ViewConstants.TOTAL, fieldName: 'total',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
    },
    {// Count
      key: ViewConstants.AVG_SALES, fieldName: 'avgRDR',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true',
      columnWidth: '150', columnMinWidth: '150', columnMaxWidth: '150',
      currencyField: 'false', filterValue: '', dataTypeformat: '',
      filterType: FilterType.CONTAINS,
    }
  ];
}
