'use strict';
// Model for holding Engine
export interface Filter {
    // To store column name
    columnname?: string;
    // To store filter value
    filterValue?: string;
    // To store horse power
    filterType?: FilterType;
    // To store horse power
    compareValue?: string;
    // To store refresh box value
    refreshBoxValue?: boolean;
    // To store refresh bar value
    refreshBarValue?: boolean;
}

// To indicate Filter types
export enum FilterType {
    // Contains filter
    CONTAINS = 0,
    // Starts with filter
    STARTS_WITH = 1,
    // Spaces filter
    SPACES = 2,
    // Number with greater and lesser than symbol
    PRICE_COLUMN = 3,
    // Date with greater and lesser than symbol
    DATE_SYMBOL = 4,
    CURRENCY_COLUMN = 5,
    CONTAINS_WITH_NOTEQUAL = 6
}
