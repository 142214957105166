import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCTRACRetailSalesReportRoutingModule }
    from './trac-vehicles-retail-sales-report-routing.module';
import { UCTRACRetailSalesPageReportComponent }
    from './trac-vehicles-retail-sales-report-page.component';
import { UCTRACRetailSalesReportGridComponent }
    from './trac-vehicles-retail-sales-report-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCTRACRetailSalesReportRoutingModule
    ],
    exports: [
        UCTRACRetailSalesPageReportComponent
    ],
    declarations: [
        UCTRACRetailSalesPageReportComponent,
        UCTRACRetailSalesReportGridComponent
    ]
})

export class UCTRACRetailSalesReportModule {

}
