<div fxFlex="100" fxLayout="row" class="dealer-vin-search layoutbglite">
  <mat-card fxFlex="row" fxFlex="50">
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;margin-left: 2%">
      <label>Report Type</label>
    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;margin-left: 10%">
      <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;" class="mat-no-underline"
        name="region" [(ngModel)]="reportType" #region="ngModel">
        <mat-option [value]="">
          -- select --
        </mat-option>
        <mat-option *ngFor="let i of filterMethoditems" [value]="i.value">{{i.name}}</mat-option>
      </mat-select>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="15" style="padding: 5px;">
      <button [disabled]="!reportType" (click)="filterValueChanged();" mat-button mat-raised-button>Apply</button>
    </div>
  </mat-card>

</div>
