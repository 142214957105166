import { Injectable } from '@angular/core';
import { Headers, Http, Response, URLSearchParams } from '@angular/http';
import { MsalService } from '@azure/msal-angular';
import { SharedDataService } from '../../../services/common/shared-data.service';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import { ViewConstants } from '../../shared.constants';
import { map, catchError } from 'rxjs/operators'
import { HttpClient as  HTTP, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';

/***
 * Class that is injectable for performing
 * http GET/POST and including authorization headers
 */
@Injectable()
export class HttpClient {
  // To populate http instance
  protected headers;


  /***
   * Constructor of http client for performing
   * http client related operations
   */
  constructor(private http: HTTP,
    private sharedFuncService: SharedFunctionService,
    private msalSvc: MsalService,
    private sharedDataService: SharedDataService) {
    try {
      this.headers = new HttpHeaders();
      this.headers.append(ViewConstants.CONTENT_TYPE, ViewConstants.APPLICATION_JSON);
      this.headers.append(ViewConstants.ACCEPT, ViewConstants.APPLICATION_JSON);
    } catch (error) {

    }
  }

  /***
   * This method is used to perform HTTP GET
   */
  get(url: string, requestOptions: any): Observable<HttpResponse<any>> {
    this.sharedFuncService.IncrementServiceCallCount();
    // if (url.indexOf('eigws.toyota.com') === -1) {
    //   requestOptions = this.addCustomheaders(requestOptions);
    // }
    // this.http.get<any[]>('https://api.test-usedcars.toyota.com/dealer-search/dealers', requestOptions).subscribe(data => {
    //  debugger
    // })
    
    return this.http.get(url, {observe: 'response'}).pipe(map((response) => {
      this.sharedFuncService.DecrementServiceCallCount();
      return response;
    }), catchError((error: any) => {
      // Hide the Loading Indicator on service exception
      this.sharedFuncService.DecrementServiceCallCount();
      return this.sharedFuncService.handleError(error);
    }));
  }

  /***
  * This method is used to perform HTTP GET for getting token to find login  user id
  */
  getForUserId(url: string, requestOptions: any): any {
    // this.sharedFuncService.IncrementServiceCallCount();
    // if (url.indexOf('eigws.toyota.com') === -1) {
    //   requestOptions = this.addCustomheadersforuserId(requestOptions);
    // }
    return this.http.get(url, {observe: 'response'}).pipe(map((response) => {
      // this.sharedFuncService.DecrementServiceCallCount();
      return response;
    }), catchError((error: any) => {
      // Hide the Loading Indicator on service exception
      // this.sharedFuncService.DecrementServiceCallCount();
      return this.sharedFuncService.handleError(error);
    }));
  }

  /***
 * This method is used to perform HTTP POST
 */
  post(url: string, body: any, requestOptions: any) {
    // this.createAuthorizationHeader();
    // Showing loader
    this.sharedFuncService.IncrementServiceCallCount();
    // requestOptions.headers.delete(ViewConstants.CONTENT_TYPE);
    // requestOptions.headers.append(ViewConstants.CONTENT_TYPE, ViewConstants.APPLICATION_JSON);
    // if (url.indexOf(ViewConstants.RTI) > -1) {
    //   requestOptions = this.addCustomheaders(requestOptions);
    // }
    // if (url.indexOf('eigws.toyota.com') === -1) {
    //   requestOptions = this.addCustomheaders(requestOptions);
    // }
    return this.http.post(url, body, {observe: 'response'}).pipe(map((response) => {
      // Hiding loader
      this.sharedFuncService.DecrementServiceCallCount();
      return response;
    }), catchError((error: any) => {
      // Hide the Loading Indicator on service exception
      this.sharedFuncService.DecrementServiceCallCount();
      return this.sharedFuncService.handleError(error);
    }));
  }

  /***
* This method is used to perform HTTP POST for getting login user id value
*/
  postforToken(url: string, body: any, requestOptions: any) {
    // this.createAuthorizationHeader();
    // Showing loader
    // this.sharedFuncService.IncrementServiceCallCount();
    // requestOptions.headers.delete(ViewConstants.CONTENT_TYPE);
    // requestOptions.headers.append(ViewConstants.CONTENT_TYPE, ViewConstants.APPLICATION_JSON);

    requestOptions = this.addCustomheadersforToken(requestOptions);

    let bodycontent = new URLSearchParams();
    bodycontent.set('client_id', '167f36b4-e34e-4429-a89b-dcce092cc74b');
    bodycontent.set('client_secret', 'tgMFOxQ3aZ74Qxovc7e3fV6zbRVIpeicus2kGbkyF9M=');
    bodycontent.set('grant_type', 'client_credentials');

    return this.http.post(url, bodycontent).pipe(map((response) => {
      // Hiding loader
      // this.sharedFuncService.DecrementServiceCallCount();
      return response;
    }), catchError((error: any) => {
      // Hide the Loading Indicator on service exception
      // this.sharedFuncService.DecrementServiceCallCount();
      return this.sharedFuncService.handleError(error);
    }));
  }

  /***
   * This method is used to perform HTTP PUT
   */
  put(url: string, body: any, requestOptions: any) {
    // this.createAuthorizationHeader();
    // Showing loader
    this.sharedFuncService.IncrementServiceCallCount();
    // requestOptions.headers.delete(ViewConstants.CONTENT_TYPE);
    // requestOptions.headers.append(ViewConstants.CONTENT_TYPE, ViewConstants.APPLICATION_JSON);
    // if (url.indexOf('eigws.toyota.com') === -1) {
    //   requestOptions = this.addCustomheaders(requestOptions);
    // }
    return this.http.put(url, body, { observe: 'response'}).pipe(map((response) => {
      // Hiding loader
      this.sharedFuncService.DecrementServiceCallCount();
      return response;
    }), catchError((error: any) => {
      // Hide the Loading Indicator on service exception
      this.sharedFuncService.DecrementServiceCallCount();
      return this.sharedFuncService.handleError(error);
    }));
  }

  /***
* This method is used to perform HTTP POST
*/
  delete(url: string, body: any, requestOptions: any) {
    // this.createAuthorizationHeader();
    // Showing loader
    this.sharedFuncService.IncrementServiceCallCount();
    // requestOptions.headers.delete(ViewConstants.CONTENT_TYPE);
    // requestOptions.headers.append(ViewConstants.CONTENT_TYPE, ViewConstants.APPLICATION_JSON);
    // if (url.indexOf(ViewConstants.RTI) > -1) {
    //   requestOptions = this.addCustomheaders(requestOptions);
    // }
    // if (url.indexOf('eigws.toyota.com') === -1) {
    //   requestOptions = this.addCustomheaders(requestOptions);
    // }
    requestOptions.body = body;

    return this.http.delete(url, {observe: 'response', body}).pipe(map((response) => {
      // Hiding loader
      this.sharedFuncService.DecrementServiceCallCount();
      return response;
    }), catchError((error: any) => {
      // Hide the Loading Indicator on service exception
      this.sharedFuncService.DecrementServiceCallCount();
      return this.sharedFuncService.handleError(error);
    }));
  }

  /**
   * This method adds custom headers to each request being made from client
   */
  addCustomheaders(requestOptions: any): any {
    requestOptions.headers.delete(ViewConstants.AUTHORIZATION_LOWER_CASE);
    if (this.msalSvc.getAccount() != null) {
      requestOptions.headers.append(ViewConstants.AUTHORIZATION_LOWER_CASE,
        ViewConstants.BEARER + this.sharedDataService.accessToken);
    }
    return {headers: {
      authorization: `bearer ${this.sharedDataService.accessToken}`
    }};
  }
  /**
   * This method adds custom headers to each request being made from client
   */

  addCustomheadersforuserId(requestOptions: any): any {
    requestOptions.headers.delete(ViewConstants.AUTHORIZATION_LOWER_CASE);
    if (this.msalSvc.getAccount() != null) {
      requestOptions.headers.append(ViewConstants.AUTHORIZATION_LOWER_CASE,
        ViewConstants.BEARER + this.sharedDataService.accessToken);
    }
    return requestOptions;
  }


  /**
   * This method adds custom headers to each request being made from client
   */
  addCustomheadersforToken(requestOptions: any): any {
    requestOptions.headers.delete(ViewConstants.CONTENT_TYPE);

    let bodycontent = new URLSearchParams();
    bodycontent.set('client_id', '167f36b4-e34e-4429-a89b-dcce092cc74b');
    bodycontent.set('client_secret', 'tgMFOxQ3aZ74Qxovc7e3fV6zbRVIpeicus2kGbkyF9M=');
    bodycontent.set('grant_type', 'client_credentials');

    requestOptions.headers.append(ViewConstants.CONTENT_TYPE, 'application/x-www-form-urlencoded');
    requestOptions.params = bodycontent;
    requestOptions.headers.append('Access-Control-Allow-Origin', '');
    return requestOptions;
  }


}
