import { NgModule } from '@angular/core';
import { ReportComponent } from './report.component';
import { ReportRoutingModule } from './report-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    ReportRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatTabsModule,
    SharedModule
  ],
  exports: [
    ReportComponent
  ],
  declarations: [
    ReportComponent
  ]
})
export class ReportModule {
}
