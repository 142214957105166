import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCLexusDistrictSummaryReportRoutingModule }
    from './lexus-district-summary-report-routing.module';
import { UCLexusDistrictSummaryPageReportComponent }
    from './lexus-district-summary-report-page.component';
import { UCLexusDistrictSummaryReportGridComponent }
    from './lexus-district-summary-report-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCLexusDistrictSummaryReportRoutingModule
    ],
    exports: [
        UCLexusDistrictSummaryPageReportComponent
    ],
    declarations: [
        UCLexusDistrictSummaryPageReportComponent,
        UCLexusDistrictSummaryReportGridComponent
    ]
})

export class UCLexusDistrictSummaryReportModule {

}
