'use strict';
import * as models from '../model/models';
import { VehicleObjective } from '../model/VehicleObjective';

// Dealer Detail View Model
export interface VehicleObjectiveView {
  pageType?: string;
  // Message to be shown in the screen
  message?: models.Message;
  // to hold the list of dealers
  vehicleData: VehicleObjective[];
}
