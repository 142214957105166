import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    { // Region No
      key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'areaRegionNo', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '80',
      columnMinWidth: '80', columnMaxWidth: '80', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.STARTS_WITH
    },
    {// Region Name
      key: ViewConstants.DEALER_REGION_NAME_STRING_COLUMN, fieldName: 'regionName',
      style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_4_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// district Name
      key: ViewConstants.DISTRICT_NAME_COLUMN, fieldName: 'districtNo', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer #
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer Name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '150', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.VIN, fieldName: 'vin',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '185', columnMinWidth: '185',
      columnMaxWidth: '185', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// Sale DATE
      key: ViewConstants.SALE_DATE, fieldName: 'rdrDetail.retailDate', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '130',
      columnMinWidth: '130', columnMaxWidth: '130', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// customer
      key: ViewConstants.CUSTOMER, fieldName: 'rdrDetail.buyer.firstName',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '185', columnMinWidth: '185',
      columnMaxWidth: '185', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// CERTIFICATION NO
      key: ViewConstants.CERTIFICATION_NUMBER, fieldName: 'certificationNumber',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Gold Silver Certification
      key: ViewConstants.GOLD_SILVER_CERTIFICATION_TIER, fieldName: 'certificationType',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
      columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// certification date
      key: ViewConstants.CERTIFICATION_DATE, fieldName: 'certification.certificationDate',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '160',
      columnMinWidth: '160', columnMaxWidth: '160', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.INSPECTION_RO, fieldName: 'inspection.inspectionFormNumber', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.REPAIR_ORDER1_FORM,
      fieldName: 'repairOrder.repairOrderNumber1', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.REPAIR_ORDER1_DATE,
      fieldName: 'repairOrder.dateofFirstRepairOrder', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.REPAIR_ORDER2_FORM,
      fieldName: 'repairOrder.repairOrderNumber2', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.REPAIR_ORDER2_DATE,
      fieldName: 'repairOrder.dateofSecondRepairOrder', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }
  ];
  static totalGridColumns = [
    {// region Name
      key: ViewConstants.REGION_NAME_COLUMN, fieldName: 'regionName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// district Name
      key: ViewConstants.DISTRICT_NAME_COLUMN, fieldName: 'districtNo', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// district Total
      key: ViewConstants.DISTRICT_TOTAL_COLUMN, fieldName: 'districtTotal', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer name
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealer', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// dealer Name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer Total
      key: ViewConstants.DEALER_TOTAL_COLUMN, fieldName: 'total', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }

  ];
}


