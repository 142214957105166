import { MatDialogRef } from '@angular/material/dialog';
import {
  Component, ChangeDetectionStrategy, OnInit,
  OnDestroy, ViewEncapsulation
} from '@angular/core';
// import * as models from '../../../services/model';
import { Logger } from '../../../shared/components/helpers/Logger';
// import { SharedDataService } from '../../../services/common/shared-data.service';
/**
 * Class that is defined as component
 * to show the customer dialog in the deal detail screen
 */
@Component({
  selector: 'uc-vehicle-decertification-dialog',
  templateUrl: 'vehicle-decertification-dialog.component.html',
  styleUrls: ['./vehicle-decertification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class VehicleDecertificationDialogComponent implements OnInit, OnDestroy {

  /**
   * Variable to hold the consuming component instance
   */
  public parent: any;
  /**
   * variable is used to hold the title of the dialog
   */
  public title: string;

  // Vin to load the vehicle detail popup
  public vin: string;
  /**
   * Getting data from obseravable
   */
  ngOnInit() {
  }

  /**
   * Destroy event for Comment Dialog
   */
  ngOnDestroy() {
    try {

    } catch (error) {
      this.logger.error(error);
    }
  }

  /**
   * constructor method to create new instance of CommenttDialogComponent
   */
  constructor(public dialogRef: MatDialogRef<VehicleDecertificationDialogComponent>,
    private logger: Logger) {
  }

}
