import { NgModule } from '@angular/core';
import { VehicleDecertificationPageComponent } from './vehicle-decertification-page.component';
import { VehicleDecertificationComponent } from './vehicle-decertification.component';
import { SharedModule } from '../../shared/shared.module';
import { VehicleDecertificationDialogService }
  from './vehicle-decertification-dialog/vehicle-decertification-dialog.service';
import { VehicleDecertificationDialogComponent }
  from './vehicle-decertification-dialog/vehicle-decertification-dialog.component';
import { VehicleDecertificationRoutingModule } from './vehicle-decertification-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    imports: [
        SharedModule,
        VehicleDecertificationRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule
    ],
    exports: [
        VehicleDecertificationComponent,
        VehicleDecertificationPageComponent,
        VehicleDecertificationDialogComponent
    ],
    declarations: [
        VehicleDecertificationComponent,
        VehicleDecertificationPageComponent,
        VehicleDecertificationDialogComponent
    ],
    providers: [
        VehicleDecertificationDialogService
    ]
})
export class VehicleDecertificationModule {
}
