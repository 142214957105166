'use strict';
import { UCRDRSummary } from '../model/RdrSummary';
import { Message } from '../model/Message';

// Vehicle Summary View Model
export interface UCRdrSummaryView {
  // List of vehicles
  inventories: UCRDRSummary[];
  // message object
  message?: Message;
}
