import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// Region No
      key: ViewConstants.DEALER_REGION_STRING_COLUMN, fieldName: 'areaRegionNo',
      style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// DEALER NO
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '250', columnMinWidth: '250',
      columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    }, {// model
      key: ViewConstants.MODEL_NUMBER, fieldName: 'model.modelNumber', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    }, {// year
      key: ViewConstants.M_YEAR, fieldName: 'model.modelYear', style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// vin
      key: ViewConstants.VIN, fieldName: 'vin', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '250', columnMinWidth: '250',
      columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    }, {// SALE DATE
      key: ViewConstants.SALE_DATE, fieldName: 'rdrDetail.retailDate', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '130',
      columnMinWidth: '130', columnMaxWidth: '130', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
  },
  ];
}
