import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */


/**
 * Following action types are being used in the inventory summary screen
 */

export const actionTypes = {
    LOAD_DEALER_DETAIL: type('[DealerDetails] Initiate load for dealer detail'),
    LOAD_DEALER_DETAIL_SUCCESS: type('[DealerDetails] dealer detail loaded Success'),
    LOAD_DEALER_DETAIL_FAIL: type('[DealerDetails] dealer detail loaded Fail'),
    LOAD_ALL_DEALERS: type('[AllDealerDetails] Initiate load all dealer details'),
    LOAD_ALL_DEALERS_SUCCESS: type('[AllDealerDetails] Initiate load all dealer details success'),
    LOAD_ALL_DEALERS_FAIL: type('[AllDealerDetails] Initiate load all dealer details failure'),
    CLEAR_ALL_ACTION: type('[DealerDetails] clear all saved data'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Method used to load the dealer detail
 */
export class LoadDealerAction implements ActionBase {
    type = actionTypes.LOAD_DEALER_DETAIL;
    constructor(public payload: any) { }
}

/**
 * Method used to handle the Success action for dealer detail load
 */
export class LoadDealerSuccessAction implements ActionBase {
    type = actionTypes.LOAD_DEALER_DETAIL_SUCCESS;
    constructor(public payload: any) { }
}

/**
 * Method used to handle the Failure action for dealer detail load
 */
export class LoadDealerFailAction implements ActionBase {
    type = actionTypes.LOAD_DEALER_DETAIL_FAIL;
    constructor(public payload: any) { }
}

/**
 * Method used to load all the dealer details
 */
export class LoadAllDealersAction implements ActionBase {
    type = actionTypes.LOAD_ALL_DEALERS;
    constructor(public payload: any) { }
}

/**
 * Method used to load all the dealer details success
 */
export class LoadAllDealersSuccessAction implements ActionBase {
    type = actionTypes.LOAD_ALL_DEALERS_SUCCESS;
    constructor(public payload: any) { }
}

/**
 * Method used to load all the dealer details failure
 */
export class LoadAllDealersFailureAction implements ActionBase {
    type = actionTypes.LOAD_ALL_DEALERS_FAIL;
    constructor(public payload: any) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class ClearAllAction implements ActionBase {
    type = actionTypes.CLEAR_ALL_ACTION;

    constructor() { }
  }


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Action
    = LoadDealerAction
    | LoadDealerSuccessAction
    | LoadDealerFailAction
    | LoadAllDealersAction
    | LoadAllDealersSuccessAction
    | LoadAllDealersFailureAction
    | ClearAllAction;
