import { ActionBase } from '../actions/actionbase';
import { WarrrantyContractView } from '../services/view-model/feature-config-view';
import * as warrrantyContractActions from '../actions/feature-config-action';
import { isNil, cloneDeep } from 'lodash';
import * as models from '../services/model/models';
import { ScreenMessages } from '../shared/master-data/messages';
import { WarrantyContractModel } from '../services/model/featureConfigModel';
import { Message, MessageType } from '../services/model/Message';
/**
 * Interface used to maintain the state for the dealer details
 */
export interface WarrantyContractState {
  warrantyContractDetails: WarrrantyContractView;
}
/**
 * Variable used to store the initial state of Dealer Detail
 */
const initialState: WarrantyContractState = {
  warrantyContractDetails: null
};

/**
 * Method used to set the default initial state of featureConfigDetails
 */
function setDefaulValue(): WarrrantyContractView {
  let warrantyContractDetails: WarrrantyContractView = <WarrrantyContractView>new Object();
  // warrantyContractDetails.warrantyData = new Array;
  warrantyContractDetails.warrantyData = '';
  return warrantyContractDetails;
}
/**
 * Reducer method to handle the dealer detail actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): WarrantyContractState {
  switch (action.type) {
    case warrrantyContractActions.actionTypes.LOAD_WARRANTY_CONTRACT_SUCCESS: {
      let warrantyContractDetail: WarrantyContractModel = {};
      if (!isNil(action.payload)) {
        warrantyContractDetail = action.payload;
      }
      return {
        warrantyContractDetails: (<any>Object).assign({}, state.warrantyContractDetails,
          {
            warrantyData: warrantyContractDetail,
          })
      };
    }
    case warrrantyContractActions.actionTypes.LOAD_WARRANTY_CONTRACT_DATA_FAIL: {
      let warrantyDetail = [];
      let errorResponse = cloneDeep(action.payload);
      let message: Message = {};
      message.message = 'Internal server error, kindly try after sometime';
      message.type = MessageType.WARNING;
      return {
        warrantyContractDetails: (<any>Object).assign({}, state.warrantyContractDetails,
          {
            message: message,
            warrantyData: warrantyDetail
          })
      };
    }

    case warrrantyContractActions.actionTypes.CLEAR_ALL_ACTION: {
      return { warrantyContractDetails: setDefaulValue() };
    }
    default: {
      return state;
    }
  }
}
/* tslint:enable:no-switch-case-fall-through */

/**
 * Method to get the state of dealer details
 */
export function WarrantyContractDetail(state: WarrantyContractState) {
  return state.warrantyContractDetails;
}




