import { Component } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { UCLayoutService } from '../services/layout.services';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ViewConstants } from '../../../../shared/shared.constants';

@Component({
    selector: 'uc-layout-nav',
    templateUrl: 'layout-nav.component.html',
    styleUrls: ['./layout-nav.component.scss']
})

export class UCLayoutNavComponent {

    /**
     * title in toolbar
     */
    @Input() title: string;

    /**
     * icon for toolbar
     */
    @Input() icon: string;

    /**
     * logo file for toolbar
     */
    @Input() logo: string;

    /**
     * method thats called when menu is clicked
     */
    @Output() onOpenMenu: EventEmitter<void> = new EventEmitter<void>();

    constructor(private layoutService: UCLayoutService,
        private sanitizer: DomSanitizer,
        private iconRegistry: MatIconRegistry) {
        iconRegistry.addSvgIcon('dealer-daily-logo',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icon/dealer-daily-logo.png'));
    }

    public menuClick(): void {
        this.onMenuClick();
    }

    /**
     * emits menuEvent
     */
    private onMenuClick(): void {
        this.onOpenMenu.emit(undefined);
        this.layoutService.openSideNav(ViewConstants.MENU);
    }

}
