import {
  Component, Input, OnInit, Output, ChangeDetectionStrategy,
  ViewEncapsulation, ViewContainerRef, EventEmitter, ViewChild, ElementRef
} from '@angular/core';

import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import * as viewModels from '../../services/view-model/view-models';
import * as models from '../../services/model/models';
import { isNil, cloneDeep, isEmpty } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as detailActions from '../../actions/vehicle-detail.action';
import { ValidationTypes } from '../../services/model/ValidatorTypes';
import { FormControl, FormControlDirective, FormControlName, Validators } from '@angular/forms';
import { SharedDataService } from '../../services/common/shared-data.service';

@Component({
  selector: 'uc-vehicle-history',
  templateUrl: 'vehicle-history.component.html',
  styleUrls: ['./vehicle-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/***
 * This component is used to the show the Vehicle Detail screen
 */
export class VehicleHistoryComponent implements OnInit {

  @ViewChild('rdrDetailForm',  {static: false}) form;

  interiorTrim: string;

  // to display error message after click on Add, Add and Certify button
  isErrorMessageDisplay: boolean = false;

  // to display error message after click on Add, Add and Certify button
  isErrorMessageDisplayForCo: boolean = false;

  // to get update flag value from parent page
  @Input() updateFlag: boolean;

  // to get update flag value from parent page
  @Input() addFlag: boolean;

  // to get update flag value from parent page
  @Input() addCertifyFlag: boolean;

  // to get update flag value from parent page
  @Input() isValidPQSS: boolean;

  // to get update flag value from parent page
  @Input() isValidCarFax: boolean;

  // to get update flag value from parent page
  @Input() isValidForeignvin: boolean;

  @Input() isMandatoryForCertify: boolean;

  displayMsgForRetailDate: boolean = false;

  displayMsgForVin: boolean = false;

  displayMsgForComments: boolean = false;

  @Input() myDatePickerOptions: IMyDpOptions;

  isCertifyEligible: boolean = false;
  isForceCertifyEligible: boolean = false;

  isDateChanged: boolean = false;

  showPriceWarning: boolean = false;

  @Input() displayInvalidVin: boolean;

  @Input() displayInvalidDealerCode: boolean;

  @Input() showAdditionalDriver: boolean = false;

  @Input() isEmailMandatory: boolean = false;

  @Input() isCoEmailMandatory: boolean = false;

  @Input() isIndividualCustomer: boolean = false;

  @Input() actionMode: boolean = false;

  zipCode: string;

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,3}$';

  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  @Input() reversalFlag: boolean = false;
  /***
 * To set the obtained VehicleDetailView object to _vehicleDetails
 */
  @Input('vehicleDetails')
  set vehicleDetails(value: viewModels.VehicleHistoryView) {
    // this._vehicleDetails = (<any>Object).assign({}, value);
    if (value !== null && value !== undefined &&
      value.vehicleHistory !== null && value.vehicleHistory !== undefined) {

      // if(isNil(this._vehicleDetails.vehicleData.inEligibleFlag)){
      //     this._vehicleDetails.vehicleData.inEligibleFlag='Y';
      // }

      this._vehicleDetails = cloneDeep(value);

      // }
    }

  }

  @Input() makeCode: string;

  @Input() certifiedFlag: string;

  @Input() forcecertifiedFlag: string;

  @Input() isForceCertified: boolean;

  @Input() vinNo: string;

  // Event emitter to naviagete to new vehicle detail event
  @Output() navigateToNewVehicleDetailEvent = new EventEmitter<any>();

  // Event emitter to naviagete to rdr summary event
  @Output() navigateToRDRSummaryEvent = new EventEmitter<any>();

  // Event emitter to naviagete to get vehicle details event
  @Output() getVehicleDetailsEvent = new EventEmitter<any>();

  // Event emitter to naviagete to get details details event
  @Output() getDealerEvent = new EventEmitter<any>();

  // Event emitter to save vehicle
  @Output() saveVehicleEvent = new EventEmitter<any>();

  // Event emitter to find dealer
  @Output() findDealerEvent = new EventEmitter<any>();

  /***
* To get the obtained VehicleDetailView object to _vehicleDetails
*/
  get vehicleDetails() {
    return this._vehicleDetails;
  }

  @Input() saveButtonText: string;

  @Input() updateButtonText: string;

  @Input() warrantExpDateText: string;

  @Input() isCertify: boolean;

  acquiredMethod: string;

  columns2 = [
    { prop: 'code', name: 'Code' },
    { prop: 'name', name: 'Name' }
  ];


  moduleItems: Array<any> =
    [
      { name: 'INVENTORY', value: 'INVENTORY', disabled: false },
      { name: 'RDR', value: 'RDR', disabled: false },
      { name: 'SALES', value: 'SALES', disabled: false },
      { name: 'REPORT', value: 'REPORT', disabled: false }
    ];


  public rows2: Array<models.AccessoryDetails> = [];

  // To set and get the obtained VehicleDetailView object from reducer using this variable
  public _vehicleDetails: viewModels.VehicleHistoryView
    = <viewModels.VehicleHistoryView>new Object();

  public validationTypes: typeof ValidationTypes = ValidationTypes;

  /**
   * Constructor method to create new instance of VehicleDetailComponent
   */
  constructor(private viewContainerRef: ViewContainerRef,
    public router: Router, private store: Store<fromRoot.AppState>, private el: ElementRef,
    public sharedDataService: SharedDataService) {
    // To initialize _vehicleDetails and selectedVinIndex
    this._vehicleDetails = <viewModels.VehicleHistoryView>new Object();


  }


  /**
   * Init method to initialize state of VehicleDetailDetailComponent
   */
  ngOnInit() {
  }

  salesValueChanged(event: any) {
    if (!isNil(event.value)) {
      this.isErrorMessageDisplay = false;
      this.isErrorMessageDisplayForCo = false;
      if (event.value === '01' || event.value === '05') {
        this.isIndividualCustomer = true;
      } else {
        this.isIndividualCustomer = false;
      }
    }
  }

  valueChanged(event: any) {
    this.sharedDataService.isPageEdited = true;
  }

  /**
   * Method to emit the delete disclaimer event emitter
   */
  navigateToNewVehicleDetail(event: any) {
    this.navigateToNewVehicleDetailEvent.emit(event);
  }

  /**
   * Method to emit the delete disclaimer event emitter
   */
  navigateToRDRSumamry(event: any) {
    this.navigateToRDRSummaryEvent.emit(event);
  }

  getVehicleDetails(event: any) {
    if (!isEmpty(event)) {
      this.isErrorMessageDisplay = true;
      if (event.length >= 17) {
        this.getVehicleDetailsEvent.emit(event);
      } else {
        this.displayMsgForVin = true;
      }
      // if (event.length >= 4) {
      //     event = event.toUpperCase().trim();
      //     //this._vehicleDetails.vehicleHistory.vin = event;
      //     this.getVehicleDetailsEvent.emit(event);
      // }
      // else {
      //     this.displayMsgForVin = true;
      // }
    }
  }

  checkPriceWarning(event: any) {
    this.showPriceWarning = false;
    this.sharedDataService.isPageEdited = true;
    if (event.keyCode >= 65 && event.keyCode <= 90) {
      this.sharedDataService.isPageEdited = true;
    } else if (event.keyCode >= 48 && event.keyCode <= 57) {
      this.sharedDataService.isPageEdited = true;
    }

  }

  saveRDR(event: any) {
    this.form.form.markAsPristine();
    this.isErrorMessageDisplay = true;
    if (event.vehicleData.certDlrBooleanFlag === false
       && (isNil(event.vehicleData.comments) || isEmpty(event.vehicleData.comments))) {
      this.displayMsgForComments = true;
    } else {
      this.displayMsgForComments = false;
    }
    // this.isMandatoryForCertify = true;
    this.displayMsgForVin = false;
    if (this.form.valid) {
      this.saveVehicleEvent.emit({
        vehicleData: event,
        isEmailMandatory: this.isEmailMandatory,
        isCoEmailMandatory: this.isCoEmailMandatory,
        additionalDriver: this.showAdditionalDriver,
        certificationFlag: 'true',
        registrationFlag: 'false',
        forceCertificationFlag: 'true',
        deCertifyFlag: 'false',
        isIndividualCustomer: this.isIndividualCustomer
      });
    } else if (event.vehicleData.dealerCd.length < 4) {
      this.displayMsgForVin = true;
    }
  }



  canShowErrorMessage(fieldName: string) {
    if (fieldName === 'retailDate') {
      this.displayMsgForRetailDate = true;
    }
  }

  pageEdited(event: any) {
    if (event.keyCode >= 65 && event.keyCode <= 90) {
      this.sharedDataService.isPageEdited = true;
    } else if (event.keyCode >= 48 && event.keyCode <= 57) {
      this.sharedDataService.isPageEdited = true;
    }
  }

  yearCheck(year: number): boolean {
    let currDate = new Date();
    let currYear = currDate.getFullYear();
    return currYear - year < 7 ? true : false;
  }

  emailOptions(event: any) {
    // this.isErrorMessageDisplay=false;
    this.isEmailMandatory = event.checked;
  }

  coEmailOptions(event: any) {
    // this.isErrorMessageDisplayForCo=false;
    this.isCoEmailMandatory = event.checked;
  }

  tologLength(event: any) {
    console.log(event.length);
  }

  findDealer() {
    this.findDealerEvent.emit();
  }

  reset() {
    this.form.resetForm();
  }
}
