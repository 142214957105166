import { NgModule } from '@angular/core';
import { RoleDetailPageComponent } from './role-detail-page.component';
import { RoleDetailComponent } from './role-detail.component';
import { SharedModule } from '../../shared/shared.module';
import { RoleDetailRoutingModule } from './role-detail-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    SharedModule,
    RoleDetailRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule
  ],
  exports: [
    RoleDetailComponent,
    RoleDetailPageComponent
  ],
  declarations: [
    RoleDetailComponent,
    RoleDetailPageComponent
  ]
})
export class RoleDetailModule {
}
