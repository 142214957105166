import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */


/**
 * Following action types are being used in the inventory summary screen
 */

export const actionTypes = {
    LOAD_STAFF_MASTER_FINANCE: type('[staffMasterFinanceDetails] Ini load staff Master Finance'),
    LOAD_STAFF_MASTER_FINANCE_SUCCESS: type('[staffMasterFinanceDetails] staffMastrFin Success'),
    LOAD_STAFF_MASTER_FINANCE_FAIL: type('[staffMasterFinanceDetails] staff Master Finance Fail'),
    LOAD_STAFF_MASTER_SALES: type('[staffMasterSalesData] Initiate load for staff Master Sales'),
    LOAD_STAFF_MASTER_SALES_SUCCESS: type('[staffMasterSalesData] staff Master Sales  Success'),
    LOAD_STAFF_MASTER_SALES_FAIL: type('[staffMasterSalesData] staffMaster Sales  loaded Fail'),
    CLEAR_ALL_ACTION: type('[staffMasterAPI] clear all saved data'),
};





export class LoadStaffMasterFinanceAction implements ActionBase {
    type = actionTypes.LOAD_STAFF_MASTER_FINANCE;
    constructor(public payload: any) { }
}


export class LoadStaffMasterFinanceSuccesssAction implements ActionBase {
    type = actionTypes.LOAD_STAFF_MASTER_FINANCE_SUCCESS;
    constructor(public payload: any) { }
}


export class LoadStaffMasterFinanceFailAction implements ActionBase {
    type = actionTypes.LOAD_STAFF_MASTER_FINANCE_FAIL;
    constructor(public payload: any) { }
}
export class LoadStaffMasterSalesAction implements ActionBase {
    type = actionTypes.LOAD_STAFF_MASTER_SALES;
    constructor(public payload: any) { }
}


export class LoadStaffMasterSalesSuccesssAction implements ActionBase {
    type = actionTypes.LOAD_STAFF_MASTER_SALES_SUCCESS;
    constructor(public payload: any) { }
}


export class LoadStaffMasterSalesFailAction implements ActionBase {
    type = actionTypes.LOAD_STAFF_MASTER_SALES_FAIL;
    constructor(public payload: any) { }
}

export class ClearAllAction implements ActionBase {
    type = actionTypes.CLEAR_ALL_ACTION;
    constructor() { }
}


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Action
    = LoadStaffMasterFinanceAction
    | LoadStaffMasterFinanceSuccesssAction
    | LoadStaffMasterFinanceFailAction
    | LoadStaffMasterSalesAction
    | LoadStaffMasterSalesSuccesssAction
    | LoadStaffMasterSalesFailAction
    | ClearAllAction;
