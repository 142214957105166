'use strict';
// import statement for models
import * as models from './models';

// Model for holding Vehicle Route Informations
export interface VehicleRoute {
  // To store VIN number
  vin?: string;
  // To store Model Code
  modelCd?: string;
  // To store Stock Number
  stockNum?: string;
  // To store Dealer Sub Category
  dealerSubCategory?: string;
  // To store UnitId
  unitId?: number;
  // To store year
  year?: string;
  // To store markerting series
  marketingSeries?: string;
  // Factory/Port/Dealer Installed Option details
  // options?: Array<models.Option>;
  // if the vehicle is Online
  isOnline?: string;
  // Vehicle price detail
  // price?: models.VehiclePrice;
  // Model detail
  model?: models.Model;
  // To store rdr status
  rdrStatus?: string;
  // To store comment
  comment?: string;
  // To store reserveFlag
  reserveFlag?: boolean;
  // To store holdStatus
  holdStatus?: string;

  dealerCd?: string;

  certificationNumber?: string;

  isMigrateData?: string;

}
