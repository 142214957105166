<mat-card fxFlex="100" fxFlex.xs="100" class="uc-menubar-panel">
    <div fxFlex="82" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
        <div style="box-sizing:border-box">
            <button #btn1
                [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected)"
                mat-button target="_blank" class="uc-menubar-item" (click)="navigateToVehicleScreen()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn1.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn1.disabled">description</mat-icon>Vehicle Details
            </button>
        </div>
        <div *ngIf="sharedDataService.inventoryScreenAccess">
            <button #btn2
                [disabled]="(sharedDataService.dealerCode === '' || sharedDataService.dealerCode === undefined)"
                mat-button target="_blank" class="uc-menubar-item" (click)="navigateToVehicleScreenNew()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn2.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn2.disabled">add_circle_outline</mat-icon>Add New
            </button>
        </div>
        <div *ngIf="sharedDataService.inventoryScreenAccess">
            <button #btn3
                [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected)"
                mat-button target="_blank" class="uc-menubar-item" (click)="deleteVehicle()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn3.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn3.disabled">delete</mat-icon>Delete
            </button>
        </div>
        <div *ngIf="sharedDataService.inventoryScreenAccess">
            <button #btn4
                [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected && selectedCertificationStatus!=='C')"
                mat-button target="_blank" class="uc-menubar-item" (click)="navigateToVehicleScreen()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn4.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn4.disabled">check_circle</mat-icon>Certify
            </button>
        </div>
        <div *ngIf="sharedDataService.inventoryScreenAccess">
            <button #btn5
                [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected && selectedCertificationStatus==='C')"
                mat-button target="_blank" class="uc-menubar-item" (click)="navigateToVehicleScreen()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn5.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn5.disabled">cancel</mat-icon>De-Certify
            </button>
        </div>
        <div *ngIf="sharedDataService.inventoryScreenAccess">
            <button #btn6
                [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected && isCertificationNumberAvailable)"
                mat-button target="_blank" class="uc-menubar-item" (click)="navigateToRdrDetailScreenNew()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn6.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn6.disabled">add_circle_outline</mat-icon>Create RDR
            </button>
        </div>
        <div *ngIf="sharedDataService.inventoryScreenAccess">
            <button #btn7
                [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected)"
                mat-button target="_blank" class="uc-menubar-item" (click)="navigateToVehicleHistoryScreen()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn7.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn7.disabled">history</mat-icon>History
            </button>
        </div>
        <div *ngIf="sharedDataService.inventoryScreenAccess">
            <button #btn8 mat-button
                [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isDataVisible)"
                target="_blank" class="uc-menubar-item" (click)="exportExcel()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn8.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn8.disabled">
                    <img src="assets/icon/excel-3-24.png" alt="excel.png">
                </mat-icon>Export Excel
            </button>
        </div>
    </div>
    <div *ngIf="!showClearAllButton" fxFlex="10"></div>
    <div *ngIf="showClearAllButton" class="divider"></div>
    <div *ngIf="showClearAllButton" fxFlex="10" fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="row" class="clearAllDiv" fxLayoutAlign="center center">
            <div style="padding-left: 5px;">Clear All</div>
            <span class="clearAllCloseBtn" (click)="clearAllButtonClicked($event);">×</span>
        </div>
    </div>
    <div class="divider"></div>
    <div fxFlex="10" fxLayout="column" layout-gt-sm="row">
        <div fxLayout="row" fxFlex="100" layout-xs="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div>Total vehicles</div>
                <div class="totalVehiclesFont"><strong>{{totalInventoriesCount}}</strong></div>
            </div>
        </div>
    </div>
</mat-card>