import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCAvgSalesReportComponent }
  from './avg-sales-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
  {
    path: 'avgSalesReport',
    component: UCAvgSalesReportComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(inventorySummaryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCAvgSalesReportRoutingModule {

}
