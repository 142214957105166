import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCDeletedCertificationPageReportComponent }
    from './deleted-certification-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
    {
        path: 'deletedCertificationReport',
        component: UCDeletedCertificationPageReportComponent,
        canActivate: [AuthenticationGuard]
    },
    {
      path: 'deletedCertificationReport/:type',
      component: UCDeletedCertificationPageReportComponent,
      canActivate: [AuthenticationGuard]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(inventorySummaryRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})

export class UCDeletedCertificationReportRoutingModule {

}
