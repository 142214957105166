<mat-card fxFlex="100" fxFlex.xs="100" class="uc-menubar-panel">
    <div fxFlex="75" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
        <div>
            <button #btn1 [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected)"
                mat-button target="_blank" class="uc-menubar-item" (click)="navigateToRdrDetailScreen()">                 
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn1.disabled" [class.uc-menubar-item-icon-enabled]="!btn1.disabled">description</mat-icon>RDR Details
            </button>
        </div>
        <!--<div>
            <button #btn2 [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected)"
                mat-button target="_blank" class="uc-menubar-item" (click)="navigateToRdrReversalScreen()">                 
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn2.disabled" [class.uc-menubar-item-icon-enabled]="!btn2.disabled">settings_backup_restore</mat-icon>RDR Reversal
            </button>
        </div>-->
        <div *ngIf="sharedDataService.rdrScreenAccess">
            <button #btn4 [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected && selectedCertificationStatus!=='C')"
                mat-button target="_blank" class="uc-menubar-item" (click)="navigateToVehicleScreen()">               
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn4.disabled" [class.uc-menubar-item-icon-enabled]="!btn4.disabled">check_circle</mat-icon>Certify
            </button>
        </div>
        <!-- <div *ngIf="sharedDataService.rdrScreenAccess">
            <button #btn3 [disabled]="!(sharedDataService.dealerCode !== '' && sharedDataService.dealerCode !== undefined && isInventorySelected)"
                mat-button target="_blank" class="uc-menubar-item" (click)="deleteVehicle()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn3.disabled" [class.uc-menubar-item-icon-enabled]="!btn3.disabled">delete</mat-icon>Delete
            </button>
        </div> -->
    </div>
    <div *ngIf="!showClearAllButton" fxFlex="10"></div>
    <div *ngIf="showClearAllButton" class="divider"></div>
    <div *ngIf="showClearAllButton" fxFlex="10" fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="row" class="clearAllDiv" fxLayoutAlign="center center">
            <div style="padding-left: 5px;">Clear All</div>
            <span class="clearAllCloseBtn" (click)="clearAllButtonClicked($event);">×</span>
        </div>
    </div>
    <div class="divider"></div>
    <div fxFlex="15" fxLayout="column" layout-gt-sm="row">
        <div fxLayout="row" fxFlex="100" layout-xs="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div>Total vehicles</div>
                <div class="totalVehiclesFont"><strong>{{totalInventoriesCount}}</strong></div>
            </div>
        </div>
    </div>
</mat-card>