import {
    Component, ElementRef, EventEmitter, Input, OnInit, Output,
    ViewChild, ViewContainerRef, ViewEncapsulation
} from '@angular/core';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { IMyDpOptions } from 'mydatepicker';
import * as fromRoot from '../../reducers';
import { SharedDataService } from '../../services/common/shared-data.service';
import * as models from '../../services/model/models';
import { ValidationTypes } from '../../services/model/ValidatorTypes';
import * as viewModels from '../../services/view-model/view-models';

@Component({
    selector: 'uc-role-detail',
    templateUrl: 'role-detail.component.html',
    styleUrls: ['./role-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})

/***
 * This component is used to the show the Vehicle Detail screen
 */
export class RoleDetailComponent implements OnInit {

    @ViewChild('rdrDetailForm',  {static: false}) form;

    interiorTrim: string;

    // to display error message after click on Add, Add and Certify button
    isErrorMessageDisplay: boolean = false;

    // to display error message after click on Add, Add and Certify button
    isErrorMessageDisplayForCo: boolean = false;

    // to get update flag value from parent page
    @Input() updateFlag: boolean;

    // to get update flag value from parent page
    @Input() addFlag: boolean;

    // to get update flag value from parent page
    @Input() addCertifyFlag: boolean;

    // to get update flag value from parent page
    @Input() isValidPQSS: boolean;

    // to get update flag value from parent page
    @Input() isValidCarFax: boolean;

    // to get update flag value from parent page
    @Input() isValidForeignvin: boolean;

    @Input() isMandatoryForCertify: boolean;

    displayMsgForRetailDate: boolean = false;

    displayMsgForVin: boolean = false;

    displayMsgForComments: boolean = false;

    @Input() myDatePickerOptions: IMyDpOptions;

    isCertifyEligible: boolean = false;
    isForceCertifyEligible: boolean = false;

    isDateChanged: boolean = false;

    showPriceWarning: boolean = false;

    @Input() displayInvalidVin: boolean;

    @Input() displayInvalidDealerCode: boolean;

    @Input() showAdditionalDriver: boolean = false;

    @Input() isEmailMandatory: boolean = false;

    @Input() isCoEmailMandatory: boolean = false;

    @Input() isIndividualCustomer: boolean = false;

    @Input() actionMode: boolean = false;

    zipCode: string;

    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,3}$';

    phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    @Input() reversalFlag: boolean = false;

    moduleName: string;

    permission: string;

    dropdownStyle: string = 'dropdownDisabled';
    /***
   * To set the obtained VehicleDetailView object to _vehicleDetails
   */
    @Input('vehicleDetails')
    set vehicleDetails(value: viewModels.RoleDetailView) {
        // this._vehicleDetails = (<any>Object).assign({}, value);
        if (value !== null && value !== undefined && value.vehicleData !== null
            && value.vehicleData !== undefined) {

            // if(isNil(this._vehicleDetails.vehicleData.inEligibleFlag)){
            //     this._vehicleDetails.vehicleData.inEligibleFlag='Y';
            // }

            this._vehicleDetails = cloneDeep(value);

            // }
            if (isEmpty(value.vehicleData.role)) {
                this.isErrorMessageDisplay = false;
                this.form?.resetForm();
            }
        }

    }

    @Input('roleDetails')
    set roleDetails(value: viewModels.RoleDetailView) {
        // this._vehicleDetails = (<any>Object).assign({}, value);
        if (value !== null && value !== undefined) {

            // if(isNil(this._vehicleDetails.vehicleData.inEligibleFlag)){
            //     this._vehicleDetails.vehicleData.inEligibleFlag='Y';
            // }

            this._roleDetails =
                cloneDeep(value).filter(t => t.makeCode === this.sharedDataService.brandName);
        } else {
            this._roleDetails = new Array<any>();
        }

    }

    @Input() makeCode: string;

    @Input() certifiedFlag: string;

    @Input() forcecertifiedFlag: string;

    @Input() isForceCertified: boolean;

    // Event emitter to naviagete to new vehicle detail event
    @Output() navigateToNewVehicleDetailEvent = new EventEmitter<any>();

    // Event emitter to naviagete to rdr summary event
    @Output() navigateToRDRSummaryEvent = new EventEmitter<any>();

    // Event emitter to naviagete to get vehicle details event
    @Output() getVehicleDetailsEvent = new EventEmitter<any>();

    // Event emitter to naviagete to get details details event
    @Output() getDealerEvent = new EventEmitter<any>();

    // Event emitter to save vehicle
    @Output() saveVehicleEvent = new EventEmitter<any>();

    /***
 * To get the obtained VehicleDetailView object to _vehicleDetails
 */
    get rdrDetails() {
        return this._vehicleDetails;
    }

    @Input() saveButtonText: string;

    @Input() updateButtonText: string;

    @Input() warrantExpDateText: string;

    @Input() isCertify: boolean;

    acquiredMethod: string;

    columns2 = [
        { prop: 'code', name: 'Code' },
        { prop: 'name', name: 'Name' }
    ];

    roleItems: Array<any> =
        [
            { name: 'UCM-App-Toyota', value: 'UCM-App-Toyota', makeCode: 'TOYOTA' },
            { name: 'UCM-App-Lexus', value: 'UCM-App-Lexus', makeCode: 'LEXUS' },
            { name: 'UCM-App-Readonly', value: 'UCM-App-Readonly', makeCode: 'TOYOTA' },
            { name: 'UCM-App-Admin', value: 'UCM-App-Admin', makeCode: 'TOYOTA' },
            { name: 'UCM-App-Superadmin', value: 'UCM-App-Superadmin', makeCode: 'TOYOTA' }
        ].filter(t => t.makeCode === this.sharedDataService.brandName);

    moduleItems: Array<any> =
        [
            { name: 'INELIGIBLE VEHICLE', value: 'ineligiblevehicle', disabled: false },
            { name: 'DEALER PARTICIPANT', value: 'dealerparticipant', disabled: false },
            { name: 'CERTIFY', value: 'certify', disabled: false },
            { name: 'VEHICLE HISTORY', value: 'vehicle-history', disabled: false },
            { name: 'INVENTORY', value: 'inventory', disabled: false },
            { name: 'DEALER SEARCH', value: 'dealer-search', disabled: false },
            { name: 'RDR', value: 'rdr', disabled: false }
        ];

    permissionItems: Array<any> =
        [
            { name: 'READ-WRITE', value: 'RW', disabled: false },
            { name: 'READ', value: 'R', disabled: false }
        ];

    public rows2: Array<models.AccessoryDetails> = [];

    // To set and get the obtained VehicleDetailView object from reducer using this variable
    public _vehicleDetails: viewModels.RoleDetailView
        = <viewModels.RoleDetailView>new Object();

    // To set and get the obtained VehicleDetailView object from reducer using this variable
    public _roleDetails: Array<any>;


    public validationTypes: typeof ValidationTypes = ValidationTypes;

    /**
     * Constructor method to create new instance of VehicleDetailComponent
     */
    constructor(private viewContainerRef: ViewContainerRef,
        public router: Router, private store: Store<fromRoot.AppState>, private el: ElementRef,
        public sharedDataService: SharedDataService) {
        // To initialize _vehicleDetails and selectedVinIndex
        this._vehicleDetails = <viewModels.RoleDetailView>new Object();


    }


    /**
     * Init method to initialize state of VehicleDetailDetailComponent
     */
    ngOnInit() {
    }

    salesValueChanged(event: any) {
        if (!isNil(event.value)) {
            this.isErrorMessageDisplay = false;
            this.isErrorMessageDisplayForCo = false;
            if (event.value === '01' || event.value === '05') {
                this.isIndividualCustomer = true;
            } else {
                this.isIndividualCustomer = false;
            }
        }
    }

    valueChanged(event: any) {
        this.sharedDataService.isPageEdited = true;
    }

    /**
     * Method to emit the delete disclaimer event emitter
     */
    navigateToNewVehicleDetail(event: any) {
        this.navigateToNewVehicleDetailEvent.emit(event);
    }

    /**
     * Method to emit the delete disclaimer event emitter
     */
    navigateToRDRSumamry(event: any) {
        this.navigateToRDRSummaryEvent.emit(event);
    }

    checkPriceWarning(event: any) {
        this.showPriceWarning = false;
        this.sharedDataService.isPageEdited = true;
        if (event.keyCode >= 65 && event.keyCode <= 90) {
            this.sharedDataService.isPageEdited = true;
        } else if (event.keyCode >= 48 && event.keyCode <= 57) {
            this.sharedDataService.isPageEdited = true;
        }

    }

    saveRDR(event: any) {
        this.form.form.markAsPristine();
        this.isErrorMessageDisplay = true;
        // this.isMandatoryForCertify = true;
        this.displayMsgForVin = false;
        if (this.form.valid) {
            this.saveVehicleEvent.emit({
                vehicleData: event,
                moduleName: this.moduleName,
                permission: this.permission
            });
        } else if (event.rdrData.vin.length < 17) {
            this.displayMsgForVin = true;
        }
    }



    canShowErrorMessage(fieldName: string) {
        if (fieldName === 'retailDate') {
            this.displayMsgForRetailDate = true;
        }
    }

    pageEdited(event: any) {
        if (event.keyCode >= 65 && event.keyCode <= 90) {
            this.sharedDataService.isPageEdited = true;
        } else if (event.keyCode >= 48 && event.keyCode <= 57) {
            this.sharedDataService.isPageEdited = true;
        }
    }

    yearCheck(year: number): boolean {
        let currDate = new Date();
        let currYear = currDate.getFullYear();
        return currYear - year < 7 ? true : false;
    }

    emailOptions(event: any) {
        // this.isErrorMessageDisplay=false;
        this.isEmailMandatory = event.checked;
    }

    coEmailOptions(event: any) {
        // this.isErrorMessageDisplayForCo=false;
        this.isCoEmailMandatory = event.checked;
    }

    tologLength(event: any) {
        console.log(event.length);
    }

    getVehicleDetails(event: any) {
        this.isErrorMessageDisplay = false;
        if (!isEmpty(event) && !isNil(event)) {
            this.getVehicleDetailsEvent.emit(event);
        }
    }

    getPermission(event: any) {
        this.isErrorMessageDisplay = false;
        this.dropdownStyle = 'dropdownEnabled';
        if (!isEmpty(event) && !isNil(event)) {
            this._vehicleDetails.vehicleData.restrictedModules.forEach(t => {
                if (t.name === event) {
                    this.permission = t.permission;
                }
            });
        }
    }
}
