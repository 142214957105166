import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCTRACRetailSalesPageReportComponent }
    from './trac-vehicles-retail-sales-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
    {
        path: 'tracRetailSalesReport',
        component: UCTRACRetailSalesPageReportComponent,
        canActivate: [AuthenticationGuard]
    },
    {
      path: 'tracRetailSalesReport/:type',
      component: UCTRACRetailSalesPageReportComponent,
      canActivate: [AuthenticationGuard]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(inventorySummaryRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})

export class UCTRACRetailSalesReportRoutingModule {

}
