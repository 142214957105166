import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as models from '../../../services/model/models';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { SharedDataService } from '../../../services/common/shared-data.service';

@Component({
  selector: 'region-selection-vin-search-toolbar',
  templateUrl: 'region-selection-search-toolbar.component.html',
  styleUrls: ['./region-selection-search-toolbar.component.scss']
})

export class RegionSelectionSearchToolbarComponent {

  @Output() viewInventoryEvent = new EventEmitter<any>();

  @Output() findDealerEvent = new EventEmitter<any>();

  @Output() findVehicleEvent = new EventEmitter<any>();

  @Input() enteredDealerCode: string;

  @Output() filterDateEvent = new EventEmitter<any>();

  @Input() enteredvin: string;

  @Input() fromDate: string;

  @Input() toDate: string;

  @Input() regionSelection: string;

  @Input() reportType: string;

  @Input() reportSelection: string;
  @Input() certificationSelection: string;

  isSubmited: boolean = false;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    inline: false,
    openSelectorTopOfInput: false,
    showClearDateBtn: true,
    showInputField: true,
    alignSelectorRight: true,
    showSelectorArrow: false
  };

  filterMethoditems: Array<any> =
    [
      { name: 'Monthly', value: 'm', disabled: false },
      { name: 'Yearly', value: 'y', disabled: true }
    ];

  certificationMethoditems: Array<any> =
    [
      { name: 'Certified', value: 'C', disabled: false },
      { name: 'Non Certified', value: 'U', disabled: true }
    ];

  regionItems: Array<any>;

  @Input() isDateSelected: boolean = false;

  constructor(public sharedDataService: SharedDataService) {
    let regionArrayCopy = new Array<any>();
    regionArrayCopy.push({ name: 'ALL' });
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName) {
          let data = {
            name: element.areaRegionName,
            makeCode: element.brand
          };
          regionArrayCopy.push(data);
        }
      });
    }
    this.regionItems =
      regionArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);
  }

  viewInventory() {
    this.viewInventoryEvent.emit(this.enteredDealerCode);
  }

  findDealer() {
    this.findDealerEvent.emit();
  }

  findVehicle() {
    this.enteredvin = this.enteredvin.toUpperCase().trim();
    this.findVehicleEvent.emit(this.enteredvin);
  }

  filterValueChanged() {
    this.isSubmited = true;
    let dateValues = {
      filter: 'between',
      fromDate: this.fromDate,
      toDate: this.toDate
    };
    if (this.reportType === '8m') {
      let data = {
        region: this.regionSelection,
        reportType: this.reportSelection,
        certification : this.certificationSelection
      };
      this.filterDateEvent.emit(data);
    } else if (this.reportType === '9') {
      let data = {
        region: this.regionSelection,
        reportType: this.reportSelection,
        dealerCode: this.enteredDealerCode
      };
      this.filterDateEvent.emit(data);
    } else
      this.filterDateEvent.emit(this.reportType);
  }

  dateFilter(event: any, value: any) {

    if (value === 'fromDate') {
      this.fromDate = event.formatted;
    }

    if (value === 'toDate') {
      this.toDate = event.formatted;
    }

    if (this.fromDate !== undefined && this.toDate !== undefined) {
      this.isDateSelected = true;
    }

  }
}
