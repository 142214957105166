import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UCInventorySummary } from '../../../services/model';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import { SharedDataService } from '../../../services/common/shared-data.service';
import { ViewConstants } from '../../../shared/shared.constants';
import * as models from '../../../services/model/models';

@Component({
  selector: 'uc-inventory-same-day-cert-report',
  templateUrl: 'same-day-cert-report-grid.html',
  styleUrls: ['./same-day-cert-report-grid.scss']
})

/***
* This component is used to the show the inventories in the inventory summar page
*/
export class UCSameDayCertGridComponent {
  // Variable for grid column definitions
  @Input() columnDefs: any[] = [];
  // private variable to hold the row datas
  @Input() rows: Array<UCInventorySummary>;
  // Variable for sort columns list
  public sortColumnsList: Array<any> = [];
  // Event to emit on row select/unselect
  @Output() onClickedEvent = new EventEmitter<any>();
  // Event to emit when vin is clicked in the grid
  @Output() navigateToVehicleDetailEvent = new EventEmitter<any>();
  @Input() selectedMonth: string;
  @Input() selectedYear: string;
  @Input() regionSelection: string;
  @Input() districtSelection: string;
  // to clear the current sorting for all columns
  @Input('clearSort')
  set clearSort(val: boolean) {
    if (val) {
      this.sortColumnsList = [];
    }
  }

  @ViewChild('inventoryTable') inventoryTable: DatatableComponent;
  @Output() fetchObjectiveEvent = new EventEmitter<any>();
  @ViewChild('inputFile') myInputVariable: ElementRef;

  // Event to emit during sorting the datas
  @Output() applySortEvent = new EventEmitter<any>();
  // Event to apply the filters
  @Output() applyFilterEvent = new EventEmitter<any>();


  constructor(public sharedFunctionService: SharedFunctionService, public router: Router,
    public sharedDataService: SharedDataService) {
  }

  /**
   * Method to apply the sorting in the grid
   */
  applySort(columnName: string) {
    let sortOrder = ViewConstants.ASC;
    if (this.sortColumnsList.findIndex(x => x.columnName === columnName) > -1) {
      sortOrder = this.sortColumnsList.find(x => x.columnName === columnName)
        .sortOrder === ViewConstants.ASC ? ViewConstants.DESC : ViewConstants.ASC;
    }
    this.sortColumnsList = [];
    this.sortColumnsList.push({ columnName: columnName, sortOrder: sortOrder });
    this.applySortEvent.emit({ columnName: columnName, sortOrder: sortOrder });
  }

  applyFilter(event: any, columnName: string, filterType: models.FilterType) {
    //TODO: Skipping this functionality for angular update
    // if (!this.inventoryTable.offsetX)
    //   this.inventoryTable.offsetX = 0;
    let filter: models.Filter = {};
    filter.columnname = columnName;
    filter.filterValue = event.target.value;
    let array = String(event.target.value).split(' ');
    filter.filterType = filterType;
    this.applyFilterEvent.emit(filter);
  }

  /**
   * Grid Row Select/Unselect Event
   */
  onClicked(checkboxChange: any, row: any) {
    let data = {
      checkBoxChecked: checkboxChange.source.checked,
      row: row
    };
    this.onClickedEvent.emit(data);
  }



  navigateToVehicleDetailScreen(event: any) {
    let selectedVehicleRoutes: models.VehicleRoute = {};
    selectedVehicleRoutes.vin = event;
    this.sharedDataService.setSelectedVehicle(selectedVehicleRoutes);
    this.navigateToVehicleDetailEvent.emit(event);
    // this.router.navigate(['/vehicle/' + event + '/' + this.sharedDataService.dealerCode]);
  }
  fetchObjective() {
    let data = {
      month: this.selectedMonth,
      year: this.selectedYear,
      regionCode: this.regionSelection,
      district: this.districtSelection
    };
    this.fetchObjectiveEvent.emit(data);
    this.myInputVariable.nativeElement.value = '';
  }
}
