<div class="uc-sale-detail-dialog">   

     <div flex layout="row" class="uc-vehicle-detail-dialog-header">
        <div class="uc-vehicle-detail-dialog-title">
            <p class="uc-vehicle-detail-dialog-title-text">{{ title }}</p>
            <mat-icon class="uc-vehicle-detail-dialog-close-icon" (click)="dialogRef.close(true)">close</mat-icon>
        </div>
    </div>

    <div flex class="uc-vehicl-det-dialog-height">
        <div class="uc-vehicle-detail-page-margin">
            <uc-sale-detail-page [vin]="vin">
                
            </uc-sale-detail-page>
        </div>
    </div>

</div>