import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ValidationTypes } from '../../../services/model/ValidatorTypes';
import { ViewConstants } from '../../../shared/shared.constants';
import { UCCurrencyPricePipe } from '../../pipes/currency-price.pipe';

/**
 * Class used to validate the user entered inputs
 */
@Directive({
    selector: '[ddFieldValidator]'
})
export class UCMFieldValidatorDirective {
    // Input REGEX type to be used
    @Input('ddFieldValidator') ddFieldValidator: ValidationTypes;
    // REGEX for Numeric 7 Validator Type
    private NUMBER_7_REGEX: string = '^\\d{0,5}(\\.\\d{0,2})?$';
    // REGEX for Numeric 4 Validator Type
    private NUMBER_4_REGEX: string = '^\\d{0,2}(\\.\\d{0,2})?$';
    // REGEX for Numeric 7 with Plus and Minus sign Validator Type
    private NUMBER_7_PLUSMINUS_REGEX: string = '^[+-]?\\d{0,5}(\\.\\d{0,2})?$';
    // REGEX for Numeric 7 with $
    private NUMBER_7_WITHDOLLAR_REGEX: string = '^\\$?\\d{0,5}(\\.\\d{0,2})?$';
    // REGEX for Numeric 4 with Plus and Minus sign Validator Type
    private NUMBER_4_PLUSMINUS_REGEX: string = '^[+-]?\\d{0,2}(\\.\\d{0,2})?$';
    // REGEX for AlphaNumeric 3000 Validator Type
    private ALPHANUMERIC_3000_REGEX: string = '^[\\w\\W\\s]{0,3000}$';
    // REGEX for AlphaNumeric 500 Validator Type
    private ALPHANUMERIC_500_REGEX: string = '^[\\w\\W\\s]{0,500}$';
    // REGEX for AlphaNumeric 20 Validator Type
    private ALPHANUMERIC_20_REGEX: string = '^[\\w\\W\\s]{0,20}$';
    // REGEX for AlphaNumeric 14 Validator Type
    private ALPHANUMERIC_14_REGEX: string = '^[\\w\\d\\-]{0,14}$';
    // REGEX for AlphaNumeric 50 Validator Type
    private ALPHANUMERIC_50_REGEX: string = '^[\\w\\d\\-]{0,50}$';
    // REGEX for AlphaNumeric 24 Validator Type
    private ALPHANUMERIC_24_REGEX: string = '^[\\w\\d\\s-]{0,24}$';
    // REGEX for AlphaNumeric Validator Type
    private ALPHANUMERIC_REGEX: string = '^[a-zA-Z0-9\\s]*$';
    // REGEX for Numeric 7 with LESS and GREATER sign Validator Type
    private NUMBER_7_LESSGREATER_REGEX: string = '^[<>]?[NA]{0,2}?\\d{0,5}(\\.\\d{0,2})?$';
    // REGEX for Numeric 5 with LESS and GREATER sign Validator Type
    private NUMBERONLY_5_LESSGREATER_REGEX: string = '^[<>]?[NA]{0,2}?\\d{0,5}?$';

    // REGEX for AlphaNumeric 4 without space Validator Type
    private ALPHANUMERIC_4_WITHOUTSPACE_REGEX: string = '^[a-zA-Z0-9]{0,4}$';

    // REGEX for Numeric 3 Validator Type
    private NUMBERONLY_3_REGEX: string = '^\\d{0,3}?$';

    // REGEX for Numeric 4 Validator Type
    private NUMBERONLY_4_REGEX: string = '^\\d{0,4}?$';

    // REGEX for Numeric 5 Validator Type
    private NUMBERONLY_5_REGEX: string = '^\\d{0,5}?$';

    // REGEX for Numeric 7 Validator Type
    private NUMBERONLY_7_REGEX: string = '^\\d{0,7}?$';


    // REGEX for AlphaNumeric  without space Validator Type
    private ALPHANUMERIC_WITHOUTSPACE_REGEX: string = '^[a-zA-Z0-9]*$';

    // REGEX for AlphaNumeric 5 without space Validator Type
    private ALPHANUMERIC_5_WITHOUTSPACE_REGEX: string = '^[a-zA-Z0-9]{0,5}$';

    // REGEX for AlphaNumeric 3 without space Validator Type
    private ALPHANUMERIC_3_WITHOUTSPACE_REGEX: string = '^[a-zA-Z0-9]{0,3}$';

    // REGEX for Alphabet 1 Validator Type
    private ALPHABET_1_REGEX: string = '^[a-zA-Z]{0,1}$';
    // REGEX for Alphabet 2 Validator Type
    private ALPHABET_2_REGEX: string = '^[a-zA-Z*]{0,2}$';
    // REGEX for AlphaNumeric 5 without space Validator Type
    private ALPHANUMERIC_17_WITHOUTSPACE_REGEX: string = '^[a-zA-Z0-9]{0,17}$';

    // REGEX for AlphaNumeric along with special characters 10 Validator Type
    private ALPHANUMERIC_SPECIAL_10_REGEX: string = '^.{0,10}$';

    // REGEX for AlphaNumeric along with special characters 14 Validator Type
    private ALPHANUMERIC_SPECIAL_14_REGEX: string = '^.{0,14}$';

    // REGEX for AlphaNumeric along with special characters 30 Validator Type
    private ALPHANUMERIC_SPECIAL_30_REGEX: string = '^.{0,30}$';

    // REGEX for AlphaNumeric along with special characters and no white space 14 Validator Type
    private ALPHANUMERIC_SPECIAL_14_WITHOUTSPACE_REGEX: string = '^[a-zA-Z0-9\\-]{0,14}$';

    // Lower Limit 0.0000
    // Uppper Limit 1.0000
    // REGEX for Numeric 10 Validator Type
    private NUMBER_10_REGEX: string = '^(0?(\\.\\d{0,5})?|1(\\.0{0,5})?)$';

    // REGEX for Numeric values Validator Type
    private NUMBER_REGEX: string = '^[0-9]{0,10}(\\[0-9]{0,2})?$';
    // REGEX for Numeric values with 2 decimal places Validator Type
    // private NUMBER_WITH_DECIMAL_2_REGEX: string = '^[0-9]{0,10}(\.[0-9]{0,2})?$';
    private NUMBER_WITH_DECIMAL_2_REGEX: string = '^[0-9.]{0,10}(\\[0-9.]{0,2})?$';

    // REGEX for Integer values along with minus Validator Type
    private INTEGER_REGEX: string = '^[-]?\\d*$';

    // REGEX for 14 Characters
    private CHAR_14_REGEX: string = '^[A-Za-z]{0,14}$';

    // REGEX to allow only lesser and greater than symbol
    private LESSER_GREATER_SYMBOL = '^[<>]?\\d{0,5}(\\.\\d{0,2})?$';

    private EMAIL_REGEX: string = '^[a-z0-9._%+-]+@[a-z0-9-]+\\.[a-z]{2,3}$';

    private US_PHONE_REGEX = '^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$';


    // Constructor for Field Validator Directive
    constructor(private el: ElementRef,
        private readonly ucCurrencyPipe: UCCurrencyPricePipe

    ) { }

    // Host Listener for Key Down event
    @HostListener('keydown', ['$event']) onKeyDown(event) {
        let value = event.target.value;
        let e = <KeyboardEvent>event;


        if ([9, 27, 13, 16, 20].indexOf(e.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && e.ctrlKey === true) ||
            // Allow: Ctrl+C
            (e.keyCode === 67 && e.ctrlKey === true) ||
            // Allow: Ctrl+v
            (e.keyCode === 86 && e.ctrlKey === true) ||
            // Allow: Ctrl+X
            (e.keyCode === 88 && e.ctrlKey === true) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }


        let startPos: number = event.srcElement.selectionStart;
        let endPos: number = event.srcElement.selectionEnd;
        let appendValue: string = e.key;

        // To validate the final number for backspace and delete
        if (e.keyCode === 8) {
            startPos = startPos - 1;
            appendValue = '';
        } else if (e.keyCode === 46) {
            endPos = endPos + 1;
            appendValue = '';
        }

        if (value.length === startPos) {
            value += appendValue;
        } else {
            let tempValue: string = '';
            tempValue = value.substring(0, startPos) + appendValue;
            if (value.length > endPos) {
                tempValue += value.substring(endPos);
            }
            value = tempValue;
        }



        if (!this.isValidEntry(value, 'KEYDOWN', appendValue)) {
            e.preventDefault();
        }
    }

    // Host Listener for Paste event
    @HostListener('paste', ['$event']) onPaste(event) {
        let e = <KeyboardEvent>event;
        let pastedText = event.clipboardData.getData('text/plain');
        let value: string = event.srcElement.value;
        let startPos: number = event.srcElement.selectionStart;
        let endPos: number = event.srcElement.selectionEnd;
        if (value.length === startPos && startPos === endPos) {
            value += pastedText;
        } else if (startPos === 0 && startPos === endPos) {
            value = pastedText + value;
        } else {
            let tempValue: string = '';
            tempValue = value.substring(0, startPos) + pastedText;
            if (value.length > endPos) {
                tempValue += value.substring(endPos);
            }
            value = tempValue;
        }
        if (!this.isValidEntry(value, 'PASTE')) {
            e.preventDefault();
        }
    }

    // Validate the user input against the REGEX pattern
    public isValidEntry(value: any, operation: string, actualKey: string = ''): boolean {
        let isValid: boolean = true;
        let regEx: RegExp = null;
        let removeComma: boolean = false;
        let removeDot: boolean = false;
        if (this.ddFieldValidator === ValidationTypes.NUMBER_7) {
            regEx = new RegExp(this.NUMBER_7_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBER_4) {
            regEx = new RegExp(this.NUMBER_4_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBER_7_WITHPLUSMINUS) {
            regEx = new RegExp(this.NUMBER_7_PLUSMINUS_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBER_7_WITHDOLLAR) {
            regEx = new RegExp(this.NUMBER_7_WITHDOLLAR_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBER_4_WITHPLUSMINUS) {
            regEx = new RegExp(this.NUMBER_4_PLUSMINUS_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBERONLY_3) {
            regEx = new RegExp(this.NUMBERONLY_3_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBERONLY_4) {
            regEx = new RegExp(this.NUMBERONLY_4_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBERONLY_5) {
            regEx = new RegExp(this.NUMBERONLY_5_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBERONLY_7) {
            regEx = new RegExp(this.NUMBERONLY_7_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBER_7_LESSGREATER) {
            regEx = new RegExp(this.NUMBER_7_LESSGREATER_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.NUMBERONLY_5_LESSGREATER) {
            regEx = new RegExp(this.NUMBERONLY_5_LESSGREATER_REGEX);
            removeComma = true;
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_3000) {
            regEx = new RegExp(this.ALPHANUMERIC_3000_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_500) {
            regEx = new RegExp(this.ALPHANUMERIC_500_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_24) {
            regEx = new RegExp(this.ALPHANUMERIC_24_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_20) {
            regEx = new RegExp(this.ALPHANUMERIC_20_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_14) {
            regEx = new RegExp(this.ALPHANUMERIC_14_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_50) {
            regEx = new RegExp(this.ALPHANUMERIC_50_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC) {
            regEx = new RegExp(this.ALPHANUMERIC_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_WITHOUTSPACE) {
            regEx = new RegExp(this.ALPHANUMERIC_WITHOUTSPACE_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_4_WITHOUTSPACE) {
            regEx = new RegExp(this.ALPHANUMERIC_4_WITHOUTSPACE_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_5_WITHOUTSPACE) {
            regEx = new RegExp(this.ALPHANUMERIC_5_WITHOUTSPACE_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_17_WITHOUTSPACE) {
            regEx = new RegExp(this.ALPHANUMERIC_17_WITHOUTSPACE_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_3_WITHOUTSPACE) {
            regEx = new RegExp(this.ALPHANUMERIC_3_WITHOUTSPACE_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHABET_1) {
            regEx = new RegExp(this.ALPHABET_1_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHABET_2) {
            regEx = new RegExp(this.ALPHABET_2_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_SPECIAL_10) {
            regEx = new RegExp(this.ALPHANUMERIC_SPECIAL_10_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_SPECIAL_14) {
            regEx = new RegExp(this.ALPHANUMERIC_SPECIAL_14_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_SPECIAL_30) {
            regEx = new RegExp(this.ALPHANUMERIC_SPECIAL_30_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.ALPHANUMERIC_SPECIAL_14_WITHOUTSPACE) {
            regEx = new RegExp(this.ALPHANUMERIC_SPECIAL_14_WITHOUTSPACE_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.NUMBER_10) {
            regEx = new RegExp(this.NUMBER_10_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.NUMBER) {
            regEx = new RegExp(this.NUMBER_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.US_PHONE) {
            regEx = new RegExp(this.US_PHONE_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.NUMBER_WITH_DECIMAL_2) {
            regEx = new RegExp(this.NUMBER_WITH_DECIMAL_2_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.INTEGER) {
            regEx = new RegExp(this.INTEGER_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.CHAR_14) {
            regEx = new RegExp(this.CHAR_14_REGEX);
        } else if (this.ddFieldValidator === ValidationTypes.LESSER_GREATER_SYMBOL) {
            regEx = new RegExp(this.LESSER_GREATER_SYMBOL);
        } else if (this.ddFieldValidator === ValidationTypes.EMAIL) {
            regEx = new RegExp(this.EMAIL_REGEX);
        }

        if (removeComma) {
            let tempValue = value.replace(new RegExp(ViewConstants.COMMA, 'g'),
                ViewConstants.EMPTY_STRING);
            if (operation === 'KEYDOWN' ||
                (operation === 'PASTE' &&
                    value === this.ucCurrencyPipe.transform(value, false))) {
                value = tempValue;
            }
        }

        //   if (removeDot && actualKey === '.') {
        //     let tempValue = value.replace(new RegExp('.', 'g'),
        //         ViewConstants.EMPTY_STRING);
        //     value = tempValue;
        // }

        if (regEx && !regEx.test(value) || (removeComma === true && actualKey === ',')) {
            isValid = false;
        }
        return isValid;
    }
}
