




import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { DealerParticipationbService } from '../services/api/dealer-participation.service';

import * as detailActions from '../actions/dealer-participation.action';

import * as models from '../services/model/models';
import {map, catchError, switchMap} from 'rxjs/operators'

/**
 * Class used to handle the service interaction in the vehicle detail screen
 */
@Injectable()
export class DealerParticipantEffects {
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  saveDealerParticipation$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.SAVE_DEALER_PARTICIPANT_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.dealerParticipationbService.saveDealerParticipation(data)
        .pipe(map((vehicleData: models.DealerParticipant) =>
          new detailActions.SaveDealerParticitionSuccessAction(vehicleData))
        , catchError(error => of(new detailActions.SaveDealerParticitionFailAction(error))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  saveBuySell$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.SAVE_BUYSELL_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.dealerParticipationbService.saveBuySell(data)
        .pipe(map((vehicleData: any) =>
          new detailActions.SaveBuySellSuccessAction(vehicleData))
        ,catchError(error => of(new detailActions.SaveBuySellFailAction(error))))
    ));


  /**
   * Constructor method used to create new instance of VehicleDetailsEffects class
   */
  constructor(private actions$: Actions,
    private dealerParticipationbService: DealerParticipationbService) { }
}
