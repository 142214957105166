

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as viewModels from '../services/view-model/view-models';
import * as detailActions from '../actions/vehicle-detail.action';
import * as accountingdetailActions from '../actions/accounting-detail.action';
import * as models from '../services/model/models';
import { isNil, cloneDeep } from 'lodash';
import { SharedFunctionService } from '../services/common/shared-function.service';
import { ScreenMessages } from '../shared/master-data/messages';
import { ViewConstants } from '../shared/shared.constants';
/**
 * Interface used to maintain the state for the vehicle detail screen
 */
export interface AccountingDetailsState {
  accountingDetail: viewModels.AccountingDetailView;
}

/**
 * Variable used to store the initial state of VehicleDetailsState
 */
const initialState: AccountingDetailsState = {
  accountingDetail: null
};

/**
 * Method used to set the default initial state of VehicleDetailsState
 */
function setDefaulValue(): viewModels.AccountingDetailView {
  let accountingDetails: viewModels.AccountingDetailView =
    <viewModels.AccountingDetailView>new Object();
  accountingDetails.accountingData = {};
  accountingDetails.message = null;
  accountingDetails.pageType = '';
  return accountingDetails;
}

/**
 * Reducer method to handle the vehicle detail screen actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): AccountingDetailsState {
  switch (action.type) {
    case accountingdetailActions.actionTypes.INITIAL_LOAD_SUCCESS: {
      // return the Vehicle Detail data
      // let accountingData: models.AccountingDetails = cloneDeep(action.payload);
      let accountingDetailData = (<any>Object).assign({}, state.accountingDetail, {
        accountingData: cloneDeep(action.payload)
      });
      accountingDetailData.pageType = ViewConstants.UPDATED;
      accountingDetailData.message = null;
      return { accountingDetail: accountingDetailData };
    }
    case accountingdetailActions.actionTypes.INITIAL_LOAD_FAIL: {
      if (!isNil(action.payload.accountingData)) {
        let accountingDetailData = (<any>Object).assign({}, state.accountingDetail, {
          accountingData: { vin: action.payload.accountingData.vin },
          pageType: ViewConstants.NO_VIN_VEH_DETAILS,
          message: null
        });
        return { accountingDetail: accountingDetailData };
      } else if (action.payload === ScreenMessages.SERVICE_FAILURE_MESSAGE ||
        action.payload === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
        let message: models.Message = {};
        let errorResponse = cloneDeep(action.payload);
        message.message = errorResponse;
        message.type = models.MessageType.DANGER;
        let accountingDetailData: viewModels.AccountingDetailView = setDefaulValue();
        accountingDetailData.message = message;
        accountingDetailData.accountingData = null;
        return { accountingDetail: accountingDetailData };
      }

    }
    case accountingdetailActions.actionTypes.CLEAR_ALL_ACTION: {
      return { accountingDetail: setDefaulValue() };
    }
    case accountingdetailActions.actionTypes.GET_CURRENT_STATE_ACTION: {
      return state;
    }

    default:
      return state;
  }

}
/* tslint:enable:no-switch-case-fall-through */
/**
 * Method to get the state of VehicleDetailsState for vehicle detail screen
 */
export function getAccountingDetailsForSelectedVins(state: AccountingDetailsState) {
  return state.accountingDetail;
}

/**
 * Method used to frame the required state for the vehicle detail screen
 */
function formatDataforDetailsView(data: any): any {
  // this.vehicleDetails =  new Observable<viewModels.VehicleDetailView>();
  let accDetailData = <models.AccountingDetails>new Object();
  let accobj = <models.AccountingDetails>new Object();
  accDetailData.vin = data.vin;
  accDetailData.dealerCd = data.dealerCd;
  accDetailData.model = data.model;
  accDetailData.dealerName = null;
  accDetailData.dofu = null;
  accDetailData.roadsideexpDate = null;
  accDetailData.roadsideFee = null;
  accDetailData.warrantyexpDate = null;
  accDetailData.reversalDate = null;
  accDetailData.acquiredDate = null;
  accDetailData.acquiredMethod = null;
  accDetailData.acquiredDate = null;
  accDetailData.agreementNumber = null;
  accDetailData.agreementSubmittalDate = null;
  accDetailData.agreementInvoiceDate = null;
  accDetailData.planCode = null;
  accDetailData.applicationNumber = null;
  accDetailData.reversalDate = null;
  accDetailData.planAmount = null;
  accDetailData.billDate = null;
  accDetailData.billAmount = null;
  accDetailData.misinvoiceDate = null;
  accDetailData.reversalDate = null;
  let rdrDetailObj = <models.RdrDetail>new Object();
  rdrDetailObj.mileage = data.mileage;
  rdrDetailObj.retailDate = data.retailDate;
  rdrDetailObj.saleType = data.saleType;
  rdrDetailObj.reversalType = data.reversalType;
  rdrDetailObj.createdAt = data.createdAt;
  accDetailData.rdrDetail = cloneDeep(rdrDetailObj);
  let buyerObj = <models.Buyer>new Object();
  buyerObj.firstName = null;
  buyerObj.lastName = null;
  accDetailData.rdrDetail.buyer = cloneDeep(buyerObj);
  let modelObj = <models.Model>new Object();
  modelObj.modelDescription = null;
  modelObj.modelYear = null;
  accDetailData.model = cloneDeep(modelObj);
  let certObj = <models.CertificationDetails>new Object();
  certObj.certificationNumber = null;
  certObj.certificationDate = null;
  certObj.warrantyDescription = null;
  accDetailData.certification = cloneDeep(certObj);
  return accDetailData;
}







