




import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { SaleDetailsService } from '../services/api/sale-detail.service';

import * as detailActions from '../actions/sale-detail.action';

import * as models from '../services/model/models';
import {map, catchError, switchMap} from 'rxjs/operators'

/**
 * Class used to handle the service interaction in the vehicle detail screen
 */
@Injectable()
export class SaleDetailsEffects {
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getRdrByVins$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.SALE_INITIAL_LOAD)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.saleDetailsService.getSaleByVin(data)
      .pipe(map((rdrData: models.Rdr) =>
          new detailActions.SaleInitialLoadSuccessAction(rdrData))
          , catchError(error => of(new detailActions.SaleInitialLoadFailAction(
          { 'error': error, 'vehicleData': data }))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getRdrByVinblur$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.GET_SALE_BY_VIN)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.saleDetailsService.getSaleByVinblur(data)
      .pipe(map((rdrData: models.Rdr) =>
          new detailActions.GetSaleByVinSuccessAction(rdrData))
          , catchError(error => of(new detailActions.GetSaleByVinFailAction(
          { 'error': error, 'vehicleData': data }))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  updateRdr$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.UPDATE_SALE_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.saleDetailsService.updateRdr(data)
      .pipe(map((vehicleData: models.Rdr) =>
          new detailActions.UpdateSaleSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.UpdateSaleFailAction(error))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  reverseRdr$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.RDR_REVERSAL_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.saleDetailsService.reverseRdr(data)
      .pipe(map((vehicleData: models.Rdr) =>
          new detailActions.RdrReversalSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.RdrReversalFailAction(error))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getFIMangerData$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.FIMANAGER_LOAD_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.saleDetailsService.loadFIManager(data)
      .pipe(map((rdrData: any) =>
          new detailActions.FILoadSuccessAction(rdrData))
          , catchError(error => of(new detailActions.FILoadFailAction(
          { 'error': error, 'vehicleData': data }))))
    ));

  /**
   * Constructor method used to create new instance of VehicleDetailsEffects class
   */
  constructor(private actions$: Actions, private saleDetailsService: SaleDetailsService) { }
}
