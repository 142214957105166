import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { ViewConstants } from '../../../shared.constants';

@Injectable()
export class UCLayoutService {
  /**
   * variable to hold sidenav sources
   */
  public openSidenavSources: { [key: string]: Subject<any> } = {};
  /**
   * variable to hold open sidenav Observable
   */
  public openSidenavObservables: { [key: string]: Observable<any> } = {};
  /**
   * method for register sidenav
   */
  public registerSidenav(name: string): Observable<any> {
    if (this.openSidenavSources[name]) {
      throw ViewConstants.SIDENAV_NAME_REGISTERED_ALREADY;
    }
    this.openSidenavSources[name] = new Subject<any>();
    this.openSidenavObservables[name] = this.openSidenavSources[name].asObservable();
    return this.openSidenavObservables[name];
  }
  /**
   * method for open side nav
   */
  public openSideNav(name: string): void {
    if (this.openSidenavSources[name]) {
      this.openSidenavSources[name].next(undefined);
    }
  }
}
