import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCTopTCUVDealersReportRoutingModule }
  from './top-tcuv-dealers-report-routing.module';
import { UCTopTCUVDealersReportComponent }
  from './top-tcuv-dealers-report-page.component';
import { UCTopTCUVDealersGridComponent }
  from './top-tcuv-dealers-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCTopTCUVDealersReportRoutingModule
  ],
  exports: [
    UCTopTCUVDealersReportComponent
  ],
  declarations: [
    UCTopTCUVDealersReportComponent,
    UCTopTCUVDealersGridComponent
  ]
})

export class UCTopTCUVDealersReportModule {
}
