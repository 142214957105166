import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as models from '../../../services/model/models';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

@Component({
  selector: 'vehicle-report-vin-search-toolbar',
  templateUrl: 'vehicle-report-vin-search-toolbar.component.html',
  styleUrls: ['./vehicle-report-vin-search-toolbar.component.scss']
})

export class VehicleReportVinSearchToolbarComponent {

  @Output() viewInventoryEvent = new EventEmitter<any>();

  @Output() findDealerEvent = new EventEmitter<any>();

  @Output() findVehicleEvent = new EventEmitter<any>();

  @Input() enteredDealerCode: string;

  @Output() filterDateEvent = new EventEmitter<any>();

  @Input() enteredvin: string;

  @Input() fromDate: string;

  @Input() toDate: string;

  @Input() labelText: string = 'Certification Date';

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    inline: false,
    openSelectorTopOfInput: false,
    showClearDateBtn: true,
    showInputField: true,
    alignSelectorRight: true,
    showSelectorArrow: false
  };

  filterMethoditems: Array<any> =
    [
      { name: 'Show All', value: 'Show All', disabled: false },
      { name: 'Current Week', value: 'Current Week', disabled: false },
      { name: 'Current Month', value: 'Current Month', disabled: false },
      { name: 'Current Date', value: 'Current Date', disabled: true }
    ];

  @Input() isDateSelected: boolean = false;

  viewInventory() {
    this.viewInventoryEvent.emit(this.enteredDealerCode);
  }

  findDealer() {
    this.findDealerEvent.emit();
  }

  findVehicle() {
    this.enteredvin = this.enteredvin.toUpperCase().trim();
    this.findVehicleEvent.emit(this.enteredvin);
  }

  filterValueChanged() {
    let dateValues = {
      filter: 'between',
      fromDate: this.fromDate,
      toDate: this.toDate
    };
    this.filterDateEvent.emit(dateValues);
  }

  dateFilter(event: any, value: any) {

    if (value === 'fromDate') {
      this.fromDate = event.formatted;
    }

    if (value === 'toDate') {
      this.toDate = event.formatted;
    }

    if (this.fromDate !== undefined && this.toDate !== undefined) {
      this.isDateSelected = true;
    }

  }
}
