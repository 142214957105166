import { compose, createSelector } from '@ngrx/store';
import { ActionReducer, combineReducers } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { storeLogger } from 'ngrx-store-logger';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Observable } from 'rxjs';
import * as fromInventorysummary from './inventory-summary';
import * as fromAccountingsummary from './accounting-summary';
import * as fromRdrsummary from './rdr-summary';
import * as fromSalessummary from './sale-summary';
import * as fromMessageData from './message-data';
import * as fromDealerDetailsData from './dealer-detail';
import * as fromFeatureConfigData from './feature-config-details';
import * as fromTiereligibilityData from './tierElgibility';
import * as fromWarrantyContractData from './getWarranty-Contract-data';
import * as fromStaffMasterFinanceData from './staff-master-finance';
import * as fromStaffMasterSalesData from './staff-master-sales';
import * as fromUserDetailsData from './user-detail';
import * as fromDetail from './vehicle-detail';
import * as fromAccountingDetail from './accounting-detail';
import * as fromRdrDetail from './rdr-detail';
import * as fromSaleDetail from './sale-detail';
import * as fromVehicleDecertification from './vehicle-decertification';
import * as fromDealerParticipant from './dealer-participation';
import * as fromVehicleHistory from './vehicle-history';
import * as fromRoleDetail from './role-detail';
import * as fromVehicleReport from './vehicle-report';
import * as fromVehicleObjective from './vehicle-objective';

const modules = {

};

export interface AppState {
  inventorySummary: fromInventorysummary.UCInventoryState;
  accountingSummary: fromAccountingsummary.UCAccountingState;
  messageData: fromMessageData.MessageDataState;
  dealerDetails: fromDealerDetailsData.DealerDetailState;
  vehicleDetail: fromDetail.VehicleDetailsState;
  accountingDetail: fromAccountingDetail.AccountingDetailsState;
  rdrDetail: fromRdrDetail.RdrDetailsState;
  userDetail: fromUserDetailsData.UserDetailState;
  rdrSummary: fromRdrsummary.UCRdrState;
  salesSummary: fromSalessummary.UCSaleState;
  saleDetail: fromSaleDetail.SaleDetailsState;
  vehicleDecertification: fromVehicleDecertification.VehicleDecertificationsState;
  dealerPartcipant: fromDealerParticipant.DealerParticipationState;
  vehicleHistory: fromVehicleHistory.VehicleHistoryState;
  roleDetail: fromRoleDetail.RoleDetailState;
  vehicleReport: fromVehicleReport.UCReportState;
  gstSetData: fromVehicleReport.UCGstSetDataState;
  vehicleObjective: fromVehicleObjective.VehicleObjectiveState;
  featureConfigDetail: fromFeatureConfigData.FeatureConfigState;
  tierEligibilityDetail: fromTiereligibilityData.TireligibilityState;
  warrantyContarctDetail: fromWarrantyContractData.WarrantyContractState;
  staffMasterFinanceDetail: fromStaffMasterFinanceData.StaffMasterFinanceState;
  staffMasterSalesDetail: fromStaffMasterSalesData.StaffMasterSalesState;
}

export const reducers = {
  router: routerReducer,
  inventorySummary: fromInventorysummary.reducer,
  accountingSummary: fromAccountingsummary.reducer,
  messageData: fromMessageData.reducer,
  dealerDetails: fromDealerDetailsData.reducer,
  vehicleDetail: fromDetail.reducer,
  accountingDetail: fromAccountingDetail.reducer,
  rdrDetail: fromRdrDetail.reducer,
  userDetail: fromUserDetailsData.reducer,
  rdrSummary: fromRdrsummary.reducer,
  salesSummary: fromSalessummary.reducer,
  saleDetail: fromSaleDetail.reducer,
  vehicleDecertification: fromVehicleDecertification.reducer,
  dealerPartcipant: fromDealerParticipant.reducer,
  vehicleHistory: fromVehicleHistory.reducer,
  roleDetail: fromRoleDetail.reducer,
  vehicleReport: fromVehicleReport.reducer,
  gstSetData: fromVehicleReport.gstreducer,
  vehicleObjective: fromVehicleObjective.reducer,
  featureConfigDetail: fromFeatureConfigData.reducer,
  tierEligibilityDetail: fromTiereligibilityData.reducer,
  warrantyContarctDetail: fromWarrantyContractData.reducer,
  staffMasterFinanceDetail: fromStaffMasterFinanceData.reducer,
  staffMasterSalesDetail: fromStaffMasterSalesData.reducer,
};

// Development reducer
const developmentReducer = compose(storeFreeze, combineReducers)(reducers);


// Root reducer
export function rootReducer(state: any, action: any) {
  return developmentReducer(state, action);
}

// To fetch Vehicle details for Inventory Summary screen
export const getInventorySummary =
  compose(fromInventorysummary.getVehiclesForInventory, getInventorySummaryState);

// To fetch Vehicle details for Inventory Summary screen
export const getAccountingSummary =
  compose(fromAccountingsummary.getVehiclesForInventory, getAccountingSummaryState);

// To fetch Vehicle details for Inventory Summary screen
export const getRdrSummary =
  compose(fromRdrsummary.getVehiclesForRdr, getRdrSummaryState);

// To fetch Vehicle details for Inventory Summary screen
export const getSalesSummary =
  compose(fromSalessummary.getVehiclesForRdr, getSalesSummaryState);

// vehicle inventory module - state is set and vehicle summary are retrieved using root receiver
export function getInventorySummaryState(state: AppState) {
  return state.inventorySummary;
}

// vehicle inventory module - state is set and vehicle summary are retrieved using root receiver
export function getAccountingSummaryState(state: AppState) {
  return state.accountingSummary;
}

// vehicle inventory module - state is set and vehicle summary are retrieved using root receiver
export function getRdrSummaryState(state: AppState) {
  return state.rdrSummary;
}

// vehicle inventory module - state is set and vehicle summary are retrieved using root receiver
export function getSalesSummaryState(state: AppState) {
  return state.salesSummary;
}

// vehicle detail module - state is set and vehicle details are retrieved using root receiver
export function getVehicleDetailsForSelectedVinsState(state: AppState) {
  return state.vehicleDetail;
}

export function getAccountingDetailsForSelectedVinsState(state: AppState) {
  return state.accountingDetail;
}


export function getRdrDetailsForSelectedVinsState(state: AppState) {
  return state.rdrDetail;
}

export function getSaleDetailsForSelectedVinsState(state: AppState) {
  return state.saleDetail;
}
// To fetch the Vehicle details to be displayed in Vehicle Details screen
export const getVehicleByVin = compose(fromDetail.getVehicleDetailsForSelectedVins,
  getVehicleDetailsForSelectedVinsState);

export const getVehicleByVins = compose(fromAccountingDetail.getAccountingDetailsForSelectedVins,
  getAccountingDetailsForSelectedVinsState);

// To fetch the Vehicle details to be displayed in Vehicle Details screen
export const getRdrByVin = compose(fromRdrDetail.getRdrDetailsForSelectedVinsState,
  getRdrDetailsForSelectedVinsState);

export function getvehicleDecertificationState(state: AppState) {
  return state.vehicleDecertification;
}


// To fetch the Vehicle details to be displayed in Vehicle Details screen
export const getvehicleDecertification =
  compose(fromVehicleDecertification.getVehicleDetailsForSelectedVins,
    getvehicleDecertificationState);

export function getDealerParticipantState(state: AppState) {
  return state.dealerPartcipant;
}


// To fetch the Vehicle details to be displayed in Vehicle Details screen
export const getDealerParticipant = compose(fromDealerParticipant.getVehicleDetailsForSelectedVins,
  getDealerParticipantState);

export function getVehicleHistoryState(state: AppState) {
  return state.vehicleHistory;
}


// To fetch the Vehicle details to be displayed in Vehicle Details screen
export const getVehicleHistory = compose(fromVehicleHistory.getVehicleHistoryfromVin,
  getVehicleHistoryState);


export function getRoleDetailState(state: AppState) {
  return state.roleDetail;
}


// To fetch the Vehicle details to be displayed in Vehicle Details screen
export const getRoleDetail = compose(fromRoleDetail.getRoleDetailState,
  getRoleDetailState);

export function getVehicleReportState(state: AppState) {
  return state.vehicleReport;
}

export function getGSTSETDataState(state: AppState) {
  return state.gstSetData;
}

// To fetch the Vehicle details to be displayed in Vehicle Details screen
export const getVehicleReport = compose(fromVehicleReport.getVehicleReport,
  getVehicleReportState);


export const getGSTSETData = compose(fromVehicleReport.getVehicleReport,
  getGSTSETDataState);


export function getVehicleObjectiveState(state: AppState) {
  return state.vehicleObjective;
}


// To fetch the Vehicle details to be displayed in Vehicle Details screen
export const getVehicleObjective = compose(fromVehicleObjective.getVehicleObjective,
  getVehicleObjectiveState);

// To fetch the Vehicle details to be displayed in Vehicle Details screen
export const getSaleByVin = compose(fromSaleDetail.getSaleDetailsForSelectedVinsState,
  getSaleDetailsForSelectedVinsState);

// To fetch message for dispalying
export const getMessageData =
  compose(fromMessageData.getMessageData, getMessageDataState);

// message module - state is set and messages are retrieved using root receiver
export function getMessageDataState(state: AppState) {
  return state.messageData;
}

// to fetch dealer detail
export const getDealerData =
  compose(fromDealerDetailsData.getDealerDetail, getDealerDetailsState);

// to fetch user detail
export const getUserData =
  compose(fromUserDetailsData.getUserDetail, getUserDetailsState);



export function getDealerDetailsState(state: AppState) {
  return state.dealerDetails;
}


export function getUserDetailsState(state: AppState) {
  return state.userDetail;
}

// to fetch featureconfig detail
export const getFeatueConfigData =
  compose(fromFeatureConfigData.getFeatureConfigDetail, getfeatureConfigDetailsState);


export function getfeatureConfigDetailsState(state: AppState) {
  return state.featureConfigDetail;
}
// to fetch featureconfig detail
export const getTierEligibilityData =
  compose(fromTiereligibilityData.getTiereligibilityDetail, getTiereligibilityDetailsState);


export function getTiereligibilityDetailsState(state: AppState) {
  return state.tierEligibilityDetail;
}

// to fetch warrantyContarct data
export const getWarrantyContractData =
  compose(fromWarrantyContractData.WarrantyContractDetail, getWarrantyContractDetailsState);


export function getWarrantyContractDetailsState(state: AppState) {
  return state.warrantyContarctDetail;
}

// to fetch Staffmaster finance data
export const getStaffMasterFinanceData =
  compose(fromStaffMasterFinanceData.getStaffMasterFinanceDetail,
    getStaffMasterFinanceDetailsState);


export function getStaffMasterFinanceDetailsState(state: AppState) {
  return state.staffMasterFinanceDetail;
}

// to fetch Staffmaster Sales data
export const getStaffMasterSalesData =
  compose(fromStaffMasterSalesData.getStaffMasterSalesDetails, getStaffMasterSalesDetailsState);


export function getStaffMasterSalesDetailsState(state: AppState) {
  return state.staffMasterSalesDetail;
}
