<div class="uc-inventory-grid">
    <ngx-datatable #inventoryTable class='material expandable' [rows]="rows" [groupRowsBy]="'modelDesc'"
        [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="50" [footerHeight]="50" [rowHeight]="40" [limit]="10"
        [groupExpansionDefault]="true">
        <!-- Group Header Template -->
        <ngx-datatable-group-header [rowHeight]="50" #myGroupHeader (toggle)="onDetailToggle($event)">
            <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                <div style="padding-left:5px;">
                    <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
                        title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
                        <strong>CAR MODEL: {{group.value[0].modelDesc}}</strong>
                    </a>
                </div>
            </ng-template>
        </ngx-datatable-group-header>

        <ngx-datatable-column *ngFor="let columnDef of columnDefs" [name]="columnDef.key" [prop]="columnDef.fieldName"
            [width]="columnDef.columnWidth" [minWidth]="columnDef.columnMinWidth" [maxWidth]="columnDef.columnMaxWidth"
            [flexGrow]="columnDef.columnWidth">
            <ng-template let-model="model" ngx-datatable-header-template>
                <div class="datatable-header-cell-label">
                    <div fxLayout="column" class="dd-grid-label-align">
                        <div fxFlex="100" class="cursorP"
                            [class.dd-grid-sorting]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)===''"
                            [class.dd-grid-sorting-asc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList) === 'ASC'"
                            [class.dd-grid-sorting-desc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)==='DESC'"
                            [innerHTML]="columnDef.key"
                            (click)="columnDef.sortable !== 'true' || applySort(columnDef.fieldName)">
                        </div>
                        <div fxFlex>
                            <mat-form-field class="dd-inv-input-background">
                                <input matInput [(ngModel)]="columnDef.filterValue" *ngIf="columnDef.key !== 'VIN'"
                                    [ddFieldValidator]="columnDef.dataTypeformat"
                                    (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                                <input matInput [(ngModel)]="columnDef.filterValue" style="text-transform: uppercase;"
                                    *ngIf="columnDef.key === 'VIN'" [ddFieldValidator]="columnDef.dataTypeformat"
                                    (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div *ngIf="columnDef.key == 'DOFU' || columnDef.key =='CERTIFICATION DATE' || columnDef.key =='WARRANTY EXPIRATION'"
                    [innerHTML]="value|date :'MM-dd-yyyy'"></div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>