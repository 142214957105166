import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { UCRegionObjectiveRoutingModule }
    from './region-objective-routing.module';
import { UCRegionObjectivePageComponent }
    from './region-objective-page.component';
import { UCRegionObjectiveGridComponent }
    from './region-objective-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCRegionObjectiveRoutingModule
    ],
    exports: [
      UCRegionObjectivePageComponent
    ],
    declarations: [
      UCRegionObjectivePageComponent,
        UCRegionObjectiveGridComponent
    ]
})

export class UCRegionObjectiveModule {

}
