
'use strict';
import * as models from '../model/models';
import { SharedFunctionService } from '../common/shared-function.service';

// Vehicle Detail View Model
export interface VehicleDetailView {
    // Selected Vehicle detail
    // // Selected VIN data
    vehicleData?: models.Vehicle;
    // selectedModelVinStockData: string[];
    // // Current page
    // page: number;
    // // Selected VINs List
    // selectedVins: string[];
    // // Total DIO Price
    // totalDIOPrice: number;
    // // Total DIO Invoice Price
    // totalDIOInvoicePrice: number;
    // Message to be shown in the screen
    message?: models.Message;
   // Data formatter
    dataFormatter?: SharedFunctionService;
    // page type
    pageType?: string;
}
