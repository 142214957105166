import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCSalesComparisionModelYearReportRoutingModule }
  from './sales-comparision-modelyear-report-routing.module';
import { UCSalesComparisionModelYearPageReportComponent }
  from './sales-comparision-modelyear-report-page.component';
import { UCSalesComparisionModelYearGridComponent }
  from './sales-comparision-modelyear-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCSalesComparisionModelYearReportRoutingModule
  ],
  exports: [
    UCSalesComparisionModelYearPageReportComponent
  ],
  declarations: [
    UCSalesComparisionModelYearPageReportComponent,
    UCSalesComparisionModelYearGridComponent
  ]
})

export class UCSalesComparisionModelYearReportModule {
}
