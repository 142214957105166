



import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import * as inventorySummaryActions from '../actions/inventory-summary.action';
import { UCInventorySummaryService } from '../services/api/inventory-summary.service';
import { UCInventorySummary } from '../services/model/InventorySummary';
import {map, catchError, switchMap} from 'rxjs/operators'
/**
 * Class used to handle the service interaction in the inventory summary screen
 */
@Injectable()
export class UCInventorySummaryEffects {

  /**
   * Mehod used to get the inventory summary data on Initial Load
   */
  @Effect()
  getInventorySummary$: Observable<Action> = this.actions$
    .pipe(ofType(inventorySummaryActions.ACTION_TYPES.INITIAL_LOAD)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.uCInventoryService.getInventorySummary(data)
        .pipe(map((inventories: Array<UCInventorySummary>) =>
          new inventorySummaryActions.InitialLoadSuccessAction(inventories))
        , catchError(error => of(new inventorySummaryActions.InitialLoadFailureAction(error))))
    ));

  /**
   * Mehod used to get the inventory summary data on Initial Load
   */
  @Effect()
  getInventorySummaryReport$: Observable<Action> = this.actions$
    .pipe(ofType(inventorySummaryActions.ACTION_TYPES.GET_SUMMARY_REPORT_LOAD)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.uCInventoryService.getInventorySummaryReport(data)
        .pipe(map((inventories: Array<UCInventorySummary>) =>
          new inventorySummaryActions.GetSummaryReportSuccessAction(inventories))
        , catchError(error => of(new inventorySummaryActions.GetSummaryReportFailureAction(error))))
    ));
  /**
   * Constructor method used to create new instance of UCInventorySummaryEffects class
   */
  constructor(private actions$: Actions,
    private uCInventoryService: UCInventorySummaryService) {

  }
}
