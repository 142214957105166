import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
    static gridColumns = [

        {// vin
            key: ViewConstants.VIN, fieldName: 'vin', style: '', hasTemplate: 'true',
            sortable: 'true', template: '', columnWidth: '185', columnMinWidth: '185',
            columnMaxWidth: '185', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
            filterType: FilterType.CONTAINS
        },
         {// model
            key: ViewConstants.MODEL_NO, fieldName: 'model.modelNumber', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '180',
            columnMinWidth: '180', columnMaxWidth: '180', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// model
            key: ViewConstants.MODEL_DESC, fieldName: 'modelDesc', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '180',
            columnMinWidth: '180', columnMaxWidth: '180', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// model
            key: ViewConstants.MODEL_DESC, fieldName: 'model.modelDescription', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '180',
            columnMinWidth: '180', columnMaxWidth: '180', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// model
            key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '80',
            columnMinWidth: '80', columnMaxWidth: '80', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// sale date
            key: ViewConstants.SALE_DATE, fieldName: 'rdrDetail.retailDate',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '160',
            columnMinWidth: '160', columnMaxWidth: '160', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// Customer name
            key: ViewConstants.CUSTOMER, fieldName: 'rdrDetail.buyer.firstName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '140',
            columnMinWidth: '140', columnMaxWidth: '140', currencyField: 'false',
            filterValue: '', dataTypeformat: '', filterType: FilterType.STARTS_WITH
        },
        {// CERTIFICATION NO
            key: ViewConstants.CERTIFICATION_NUMBER, fieldName: 'certificationNumber',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
            columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN
        },
        {// WARRANTY
            key: ViewConstants.WARRANTY, fieldName: 'certification.warrantyDescription', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '160',
            columnMinWidth: '160', columnMaxWidth: '160', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        }

    ];
}
