<div class="uc-message-display">
    <mat-card *ngIf="message !== null && message !== undefined" class="uc-message-mat-card">
        <div fxLayout="row" fxLayoutAlign="space-between center" 
            [class]="'uc-message-display-container uc-message-display-dismissable uc-message-display-' + (getMessageType(message?.type) || 'warning')">
            <div [innerHTML]="message.message"></div>
            <div>
                <mat-icon class="close uc-message-display-close" (click)="closeMessage(message)">close</mat-icon>
            </div>
        </div>
    </mat-card>
</div>