'use strict';

export interface AccessoryDetails {
    // To Store Accessories Type
    accessssoriesType?: string;
    // To Store Accessories code
    accessssoriesCode?: string;
    // To Store Accessories Name
    accessssoriesName?: string;
}
