import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { isEmpty, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import * as inventorySummaryActions from './actions/inventory-summary.action';
import * as messageActions from './actions/message-data.action';
import * as rdrSummaryActions from './actions/rdr-summary.action';
import * as roleDetailAction from './actions/role-detail.action';
import * as saleSummaryActions from './actions/sale-summary.action';
import * as userDetail from './actions/user-detail.action';
import * as fromRoot from './reducers';
import { SharedDataService } from './services/common/shared-data.service';
import { SharedFunctionService } from './services/common/shared-function.service';
import * as models from './services/model/models';
import { DealerDetailView } from './services/view-model/dealer-detail-view';
import { RoleDetailView } from './services/view-model/role-detail-view';
import { UserDetailView } from './services/view-model/user-detail-view';
import { ScreenMessages } from './shared/master-data/messages';
import { ViewConstants } from './shared/shared.constants';
import { environment } from './tessconfig';
import { environment as appENV } from 'src/environments/environment';
import { InteractionRequiredAuthError } from 'msal';
@Component({
  selector: 'my-app',
  styleUrls: ['main.scss', './app.component.scss'],
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  // Route param subscriber to get the VIN to be loaded
  routeParamSubscriber: any;
  // Observable to obtain Message object
  messageData$: Observable<models.Message[]>;
  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<DealerDetailView>;

  // Observable to obtain UserDetailView object
  userDetailsData$: Observable<UserDetailView>;

  // Observable to obtain UserDetailView object
  roleDetailsData$: Observable<RoleDetailView>;

  responseCode: number;

  userType: string;

  applicationTitle: string;

  currentRole: string;

  // To indicate if user has timeout warning appeared or not
  isUserTimedOut: boolean = false;

  pathURL: string;

  idleState: string = '';

  invSummaryClass: string = 'uc-menubar-item mdbuttonactive';

  rdrSummaryClass: string = 'uc-menubar-item';

  salesSummaryClass: string = 'uc-menubar-item';

  invSummaryReportClass: string = 'uc-menubar-item';

  adminClass: string = 'uc-menubar-item';

  isShowDealerSection: boolean = true;

  isIframe = false;

  loggedIn = false;

  private subscription =  new Subscription();

  constructor(public sharedDataService: SharedDataService,
    private sharedFuncService: SharedFunctionService,
    private store: Store<fromRoot.AppState>,
    public router: Router,
    private msalSvc: MsalService, private broadcastService: BroadcastService) {

    const msalConfig = environment.msalConfig;
    const angularConfig = environment.angularConfig;
    this.messageData$ = store.select(fromRoot.getMessageData);
    this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
    this.userDetailsData$ = store.select(fromRoot.getUserData);
    this.roleDetailsData$ = store.select(fromRoot.getRoleDetail);
    this.msalSvc = new MsalService(msalConfig, null, router, broadcastService);

  }

  activateEvent(event) {
    if (appENV.ENV === 'development') {
      console.log('Activate Event:', event);
    }
  }

  deactivateEvent(event) {
    if (appENV.ENV === 'development') {
      console.log('Deactivate Event', event);
    }
  }

  ngOnInit() {

    this.isIframe = window !== window.parent && !window.opener;
    this.checkoutAccount();
    let account = this.msalSvc.getAccount();
    const roles: any = account ? account.idToken.roles : null;

    if (!isNil(roles)) {

      if (roles.indexOf(ViewConstants.CVA_APP_TOYOTA) > -1
        || roles.indexOf(ViewConstants.CVA_APP_SUPER_TOYOTA) > -1
        || roles.indexOf(
          ViewConstants.CVA_APP_READONLY_TOYOTA) > -1) {
        this.userType = 'TOYOTA';
        this.applicationTitle = ViewConstants.TOYOTA_TITLE;
      } else if (roles.indexOf(ViewConstants.CVA_APP_LEXUS) > -1
        || roles.indexOf(ViewConstants.CVA_APP_SUPER_LEXUS) > -1
        || roles.indexOf(ViewConstants.CVA_APP_READONLY_LEXUS) > -1) {
        this.userType = 'LEXUS';
        this.applicationTitle = ViewConstants.LEXUS_TITLE;
      } else if (roles.indexOf(
        ViewConstants.CVA_APP_SUPERADMIN_TMEX) > -1
        || roles.indexOf(ViewConstants.CVA_APP_ADMIN_TMEX) > -1
        || roles.indexOf(
          ViewConstants.CVA_APP_READONLY_TMEX) > -1) {
        this.userType = 'TOYOTA';
        this.sharedDataService.isTMEXUser = true;
        this.applicationTitle = ViewConstants.TOYOTA_TITLE;
      } else if (roles.indexOf(ViewConstants.UCM_APP_TOYOTA) > -1) {
        this.userType = 'TOYOTA';
        this.applicationTitle = ViewConstants.TOYOTA_TITLE;
      } else if (roles.indexOf(ViewConstants.UCM_APP_LEXUS) > -1) {
        this.userType = 'LEXUS';
        this.applicationTitle = ViewConstants.LEXUS_TITLE;
      }
      this.sharedDataService.brandName = this.userType;
      this.sharedDataService.applicationTitle = this.applicationTitle;

      this.sharedDataService.userName = account ? account.name : null;
      this.sharedDataService.userNameCopy =
        this.sharedFuncService.add3Dots(account ? account.name : '', 12);

      this.currentRole = this.findCurrentRole();

      this.updateRoleConfiguration();
      this.showRedirectError();

      // this.broadcastService.subscribe('msal:acquireTokenSuccess', payload => {
      //   console.log('Token Acquired Success');
      //   this.sharedDataService.accessToken = payload.idToken.rawIdToken;
      //   this.store.dispatch(new roleDetailAction.GetRoleConfigurationAction(this.currentRole));
      // });

    }

    /** Temporary code start */
    // this.userType = 'TOYOTA';
    // this.applicationTitle = ViewConstants.TOYOTA_TITLE;
    // this.sharedDataService.brandName = this.userType;
    // this.sharedDataService.applicationTitle = this.applicationTitle;
    // this.sharedDataService.userName = 'SANTHANAPAUL DHARMARAJ';
    // this.currentRole = ViewConstants.CVA_APP_SUPER_TOYOTA;
    /** Temporary code ends */

    this.messageData$.subscribe(data => {
      if (!isNil(data) && data.length > 0) {
        let layout: any = document.getElementsByClassName('mat-sidenav-content');
        this.responseCode = data[0].type;
        if (!isNil(layout) && layout.length > 0) {
          layout[0].scrollTop = 0;
        }
      }
    });

    console.log(window.location.pathname);
    this.pathURL = window.location.pathname.replace('/', '');

    this.dealerDetailsData$.subscribe(data => {
      if (!isNil(data) && data !== undefined && isNil(data.allDealers) &&
        this.sharedDataService.currentScreenName !== ViewConstants.DEALER_PARTICIPANT_SCREEN_NAME) {

        if (!isEmpty(data.currentDealer)) {
          if (data.currentDealer.dealerCode === '' || isNil(data.currentDealer.dealerCode)) {
            let message: models.Message = {};
            if (this.userType === 'TOYOTA') {
              message.message = ScreenMessages.INVALID_TOYOTA_DEALER_CODE;
            } else if (this.userType === 'LEXUS') {
              message.message = ScreenMessages.INVALID_LEXUS_DEALER_CODE;
            }

            message.message = ScreenMessages.INVALID_DEALER_CODE;
            message.type = models.MessageType.DANGER;
            this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
          } else {
            let dealerDetail: models.DealerDetailsModel = {};
            dealerDetail.dealerCode = data.currentDealer.dealerCode;
            dealerDetail.dealerName = data.currentDealer.dealerName;
            dealerDetail.city = data.currentDealer.city;
            dealerDetail.state = data.currentDealer.state;
            dealerDetail.areaRegionNo = data.currentDealer.areaRegionNo;
            dealerDetail.areaRegionName = data.currentDealer.areaRegionName;

            dealerDetail.brand = this.userType;

            this.sharedFuncService.setDealerDetails(dealerDetail);
            if (this.sharedDataService.pageTitle ===
              ViewConstants.INVENTORY_SUMMARY_SCREEN_NAME) {
              let invData = {
                dealerCd: data.currentDealer.dealerCode,
                from: 0
              };
              this.store.dispatch(
                new inventorySummaryActions.InitialLoadAction(invData));
            } else if (this.sharedDataService.pageTitle ===
              ViewConstants.RDR_SUMMARY_SCREEN_NAME) {
              this.store.dispatch(
                new rdrSummaryActions.InitialLoadAction(data.currentDealer.dealerCode));
            } else if (this.sharedDataService.pageTitle ===
              ViewConstants.SALE_SUMMARY_SCREEN_NAME) {
              let saledata: any = {};
              saledata.dealerCd = data.currentDealer.dealerCode;
              saledata.paginationCount = 0;
              saledata.type = 'InitialLoad';
              this.store.dispatch(
                new saleSummaryActions.InitialLoadAction(saledata));
              this.sharedDataService.paginationCount = 1;
              this.sharedDataService.isNextVisible = false;
            } else if (this.sharedDataService.pageTitle ===
              ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME) {
              this.store.dispatch(
                new inventorySummaryActions.GetSummaryReport(data.currentDealer.dealerCode));
            }
          }
        } else if (!isNil(data.message)) {
          let message: models.Message = {};
          message.message = data.message.message;
          message.type = models.MessageType.DANGER;
          this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
        } else {
          let message: models.Message = {};
          if (this.userType === 'TOYOTA') {
            message.message = ScreenMessages.INVALID_TOYOTA_DEALER_CODE;
          } else if (this.userType === 'LEXUS') {
            message.message = ScreenMessages.INVALID_LEXUS_DEALER_CODE;
          }
          message.type = models.MessageType.DANGER;
          this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
        }
      } else if (!isNil(data) && !isNil(data.message)) {
        let message: models.Message = {};
        message.message = data.message.message;
        message.type = models.MessageType.DANGER;
        this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
      }
    });

    this.userDetailsData$.subscribe(data => {
      console.log('accessTokendata' + data);
      if (!isNil(data) && data !== undefined) {
        if (!isNil(data.access_token)) {
          this.sharedDataService.accessToken = data.access_token;
          console.log('accessToken: ' + this.sharedDataService.accessToken);
          this.store.dispatch(new userDetail.GetPersonIdAction(account ? account.userName : null));
        }

        if (!isNil(data.extension_b7e46df902704c1c9c3a8dbf2645c73c_extensionAttribute3)) {
          this.sharedDataService.userId =
            data.extension_b7e46df902704c1c9c3a8dbf2645c73c_extensionAttribute3;
          console.log('userId: ' + this.sharedDataService.userId);
        }
      }
    });

    this.roleDetailsData$.subscribe(data => {
      if (!isNil(data) && data !== undefined && !isNil(data.vehicleData)
        && data.vehicleData !== undefined && data.vehicleData.restrictedModules !== undefined) {

        if (data.vehicleData.isActive === 'false') {
          this.router.navigate(['/unauthorized']);
        } else {
          data.vehicleData.restrictedModules.forEach(element => {
            if (element.name === 'ineligiblevehicle' && element.permission === 'RW') {
              this.sharedDataService.vehicleDecertificationAcccess = true;
            } else if (element.name === 'dealerparticipant' && element.permission === 'RW') {
              this.sharedDataService.dealerParticipantScreenAccess = true;
            } else if (element.name === 'inventory' && element.permission === 'RW') {
              this.sharedDataService.inventoryScreenAccess = true;
            } else if (element.name === 'rdr' && element.permission === 'RW') {
              this.sharedDataService.rdrScreenAccess = true;
            }
          });
        }
      }
    });
  }

  onCloseMessage(message: models.Message) {
    this.store.dispatch(new messageActions.CloseDisplayMessageAction(message));
  }


  findCurrentRole() {
    this.sharedDataService.adminScreenAccess = false;
    this.sharedDataService.superAdminScreenAccess = false;
    let account = this.msalSvc.getAccount();
    const roles: any = account ? account.idToken.roles : [];
    if (roles.indexOf(ViewConstants.CVA_APP_SUPER_TOYOTA) > -1) {
      this.sharedDataService.adminScreenAccess = true;
      this.sharedDataService.superAdminScreenAccess = true;
      return ViewConstants.CVA_APP_SUPER_TOYOTA;
    } else if
      (roles.indexOf(ViewConstants.CVA_APP_SUPER_LEXUS) > -1) {
      this.sharedDataService.adminScreenAccess = true;
      this.sharedDataService.superAdminScreenAccess = true;
      return ViewConstants.CVA_APP_SUPER_LEXUS;
    } else if (roles.indexOf(ViewConstants.CVA_APP_TOYOTA) > -1) {
      this.sharedDataService.adminScreenAccess = true;
      return ViewConstants.CVA_APP_TOYOTA;
    } else if (roles.indexOf(ViewConstants.CVA_APP_LEXUS) > -1) {
      this.sharedDataService.adminScreenAccess = true;
      return ViewConstants.CVA_APP_LEXUS;
    } else if
      (roles.indexOf(ViewConstants.CVA_APP_READONLY_TOYOTA) > -1) {
      return ViewConstants.CVA_APP_READONLY_TOYOTA;
    } else if
      (roles.indexOf(ViewConstants.CVA_APP_READONLY_LEXUS) > -1) {
      return ViewConstants.CVA_APP_READONLY_LEXUS;
    } else if (roles.indexOf(ViewConstants.UCM_APP_TOYOTA) > -1) {
      this.sharedDataService.adminScreenAccess = true;
      return ViewConstants.UCM_APP_TOYOTA;
    } else if (roles.indexOf(ViewConstants.UCM_APP_LEXUS) > -1) {
      this.sharedDataService.adminScreenAccess = true;
      return ViewConstants.UCM_APP_LEXUS;
    } else if (roles.indexOf(
      ViewConstants.CVA_APP_SUPERADMIN_TMEX) > -1) {
      this.sharedDataService.superAdminScreenAccess = true;
      this.sharedDataService.adminScreenAccess = true;
      return ViewConstants.CVA_APP_SUPERADMIN_TMEX;
    } else if (roles.indexOf(ViewConstants.CVA_APP_ADMIN_TMEX) > -1) {
      this.sharedDataService.adminScreenAccess = true;
      return ViewConstants.CVA_APP_ADMIN_TMEX;
    } else if (roles.indexOf(
      ViewConstants.CVA_APP_READONLY_TMEX) > -1) {
      this.sharedDataService.adminScreenAccess = true;
      return ViewConstants.CVA_APP_READONLY_TMEX;
    }
  }

  navigateToInventorySummary() {
    let inventorylink = ['/inventory'];
    this.router.navigate(inventorylink);
    this.invSummaryClass = 'uc-menubar-item mdbuttonactive';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.INVENTORY_SUMMARY_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  navigateToRDRSummary() {
    let inventorylink = ['/rdr'];
    this.router.navigate(inventorylink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item mdbuttonactive';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.RDR_SUMMARY_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  navigateToSalesSummary() {
    let inventorylink = ['/sales'];
    this.router.navigate(inventorylink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item mdbuttonactive';
    this.adminClass = 'uc-menubar-item';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.SALE_SUMMARY_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  navigateToTmexSalesReport() {
    let inventorylink = ['/tmexSalesReport'];
    this.router.navigate(inventorylink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item mdbuttonactive';
    this.adminClass = 'uc-menubar-item';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
    this.sharedDataService.currentMenuBarName = 'TCUV MONTHLY SALES';
    this.isShowDealerSection = true;
  }

  navigateToSaleReversalReport() {
    let inventorylink = ['/report'];
    this.router.navigate(inventorylink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item mdbuttonactive';
    this.adminClass = 'uc-menubar-item';
    this.sharedDataService.currentMenuBarName = 'TOYOTA MONTHLY SUMMARY';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
    this.isShowDealerSection = false;
  }

  navigateTovehicleDecertification() {
    let vehicleDecertificationlink = ['/vehicleDecertification'];
    this.router.navigate(vehicleDecertificationlink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item mdbuttonactive';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.ADMIN_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  navigateToDealerParticipant() {
    let vehicleDecertificationlink = ['/dealerParticipant'];
    this.router.navigate(vehicleDecertificationlink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item mdbuttonactive';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.ADMIN_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  navigateToRoleDetailScreen() {
    let vehicleDecertificationlink = ['/roleDetail'];
    this.router.navigate(vehicleDecertificationlink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item mdbuttonfocused';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.ADMIN_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  navigateToVehicleHistory() {
    let vehicleDecertificationlink = ['/vehicleHistory'];
    this.router.navigate(vehicleDecertificationlink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item mdbuttonactive';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.ADMIN_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  navigateToRegionObjective() {
    let vehicleDecertificationlink = ['/regionObjective'];
    this.router.navigate(vehicleDecertificationlink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item mdbuttonactive';
    this.sharedDataService.currentMenuBarName = 'REGION OBJECTIVE';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.ADMIN_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  navigateToDealerObjective() {
    let vehicleDecertificationlink = ['/dealerObjective'];
    this.router.navigate(vehicleDecertificationlink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item mdbuttonactive';
    this.sharedDataService.currentMenuBarName = 'DEALER OBJECTIVE';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.ADMIN_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  navigateToSalesTrend() {
    let vehicleDecertificationlink = ['/salesTrend'];
    this.router.navigate(vehicleDecertificationlink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item mdbuttonactive';
    this.sharedDataService.currentMenuBarName = 'SALES TREND BY REGION/NATIONAL';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.ADMIN_SCREEN_NAME);
    this.isShowDealerSection = true;
  }
  navigateToRegionDashboard() {
    let vehicleDecertificationlink = ['/regionDashboard'];
    this.router.navigate(vehicleDecertificationlink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item mdbuttonactive';
    this.sharedDataService.currentMenuBarName = 'REGION DASHBOARD';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.ADMIN_SCREEN_NAME);
    this.isShowDealerSection = true;
  }
  navigateToAccountingSummary() {
    let accountinglink = ['/accounting'];
    this.router.navigate(accountinglink);
    this.invSummaryClass = 'uc-menubar-item';
    this.rdrSummaryClass = 'uc-menubar-item';
    this.salesSummaryClass = 'uc-menubar-item';
    this.invSummaryReportClass = 'uc-menubar-item';
    this.adminClass = 'uc-menubar-item mdbuttonactive';
    this.sharedFuncService.setCurrentScreenName(ViewConstants.ACCOUNTING_SUMMARY_SCREEN_NAME);
    this.isShowDealerSection = true;
  }

  findDealer() {

  }

  logout() {
    if (this.msalSvc.getAccount() != null) {
      this.msalSvc.logout();
      return;
    }
  }

  checkoutAccount() {
    this.loggedIn = !!this.msalSvc.getAccount();
  }

  private updateRoleConfiguration() {
    this.msalSvc.acquireTokenSilent({ scopes: [environment.msalConfig.auth.clientId] })
    .then(authResponse => {
      console.log('Token acquired success - updateRoleConfiguration. Next expiring on - ', authResponse.expiresOn);
      this.setTokenToSharedService(authResponse.idToken.rawIdToken);
    }).catch( (error)=> {
      // call acquireTokenPopup in case of acquireTokenSilent failure due to interaction required
      if (error instanceof InteractionRequiredAuthError) {
        console.error('Token acquired failure - Interaction missing');
        this.msalSvc.acquireTokenPopup({ scopes: [environment.msalConfig.auth.clientId]}).then(
              (response)=> {
                this.setTokenToSharedService(response.idToken.rawIdToken);
              }).catch(function (error) {
                  console.log(error);
              });
      }else {
        this.msalSvc.loginRedirect();
      }
  });
  }

  private setTokenToSharedService(bearerToken: string) {
    console.log("Token is set to shared service");
    this.sharedDataService.accessToken = bearerToken;
    this.currentRole = this.findCurrentRole();
    this.store.dispatch(new roleDetailAction.GetRoleConfigurationAction(this.currentRole));
  }


  private showRedirectError() {
    this.msalSvc.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
    });
  }

  ngAfterViewInit() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.jsdelivr.net/npm/alasql';
    document.getElementsByClassName('additionalScripts')[0].appendChild(script);

    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//cdnjs.cloudflare.com/ajax/libs/xlsx/0.7.12/xlsx.core.min.js';
    document.getElementsByClassName('additionalScripts')[0].appendChild(script);
  }

  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
