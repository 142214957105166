




import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ActionBase } from '../actions/actionbase';
import { Observable, } from 'rxjs';
import { of } from 'rxjs';
import * as models from '../services/model/models';

import * as accountingDetailActions from '../actions/accounting-detail.action';
import { AccountingDetailsService } from '../services/api/accounting-detail.service';
import { AccountingDetails } from '../services/model/AccountingDetails';
import {map, catchError, switchMap} from 'rxjs/operators'
import { Action } from '@ngrx/store/src/models';
/**
 * Class used to handle the service interaction in the vehicle detail screen
 */
@Injectable()
export class AccountingDetailsEffects {

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getVehicleByVins$: Observable<Action> = this.actions$
    .pipe(ofType(accountingDetailActions.actionTypes.INITIAL_LOAD)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.accountingDetailsService.getVehicleByVins(data)
        .pipe(map((accounting: models.AccountingDetails) =>
          new accountingDetailActions.InitialLoadSuccessAction(accounting))
        , catchError(error => of(
          new accountingDetailActions.InitialLoadFailAction(
            { 'error': error, 'accounting': data })))
    )));

  /**
   * Constructor method used to create new instance of VehicleDetailsEffects class
   */
  constructor(private actions$: Actions,
    private accountingDetailsService: AccountingDetailsService) { }

}
