import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCInventoryPageReportComponent } from './inventory-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
  {
    path: 'inventoryReport',
    component: UCInventoryPageReportComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(inventorySummaryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCInventoryReportRoutingModule {

}
