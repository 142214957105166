import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// Region No
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '80',
      columnMinWidth: '80', columnMaxWidth: '80', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Region No
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Source
      key: ViewConstants.LAST_MONTH_RDR, fieldName: 'lastMonthRDR',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// De Certify reason
      key: ViewConstants.CURRENT_MONTH_RDR, fieldName: 'currentMonthRDR',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.SALES_DIFF, fieldName: 'salesDiff',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '220', columnMinWidth: '220',
      columnMaxWidth: '180', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
      tooltip: '(current Month RDR - Last Month RDR)'
    },

    {// De Certify reason
      key: ViewConstants.SALES_PERCENT, fieldName: 'salesPercent',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '220', columnMinWidth: '220',
      columnMaxWidth: '200', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
      tooltip: '(Current Month RDR / Last Month RDR) x 100'
    },
    {// Count
      key: ViewConstants.LAST_MONTH_CERTIFICATION, fieldName: 'lastMonthCertification',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// De Certify reason
      key: ViewConstants.CURRENT_MONTH_CERTIFICATION, fieldName: 'currentMonthCertification',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '220', columnMinWidth: '220',
      columnMaxWidth: '220', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.CERTIFICATION_DIFF, fieldName: 'certDiff',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '220', columnMinWidth: '220',
      columnMaxWidth: '180', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
      tooltip: '(current Month Certification - Last Month Certification)'
    },
    {// De Certify reason
      key: ViewConstants.CERTIFICATION_PERCENT, fieldName: 'certPercent',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '220', columnMinWidth: '220',
      columnMaxWidth: '200', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
      tooltip: '(Current Month Certification / Last Month Certification) x 100'
    },
    {// De Certify reason
      key: ViewConstants.CERTSALESRAITO, fieldName: 'certSalesRatio',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '220', columnMinWidth: '220',
      columnMaxWidth: '200', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
      tooltip: '(Current Month Certification / Current Month Certified RDR)'
    },
    {// De Certify reason
      key: ViewConstants.CURRENT_INVENTORY, fieldName: 'currentInventory',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.INVENTORY_LESSTHAN_60, fieldName: 'inventoryLessThan60Days',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
      tooltip: 'Inventory Less Than 60 Days'
    }
  ];
}
