import { Pipe, PipeTransform } from '@angular/core';
import { ViewConstants } from '../../shared/shared.constants';

/**
 * Class used as Pipe to transform Price into Normal Price
 * e.g : $30,000.10 to 30000.10
 */
@Pipe({ name: 'ddNormalPrice' })
export class UCNormalPricePipe implements PipeTransform {
    transform(value: string): number {
        if (!value) return 0;
        return parseFloat(value.replace(/\,/g, ViewConstants.EMPTY_STRING)
            .replace(/\$/g, ViewConstants.EMPTY_STRING));
    }
}

