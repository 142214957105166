import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCDSSRReportRoutingModule }
  from './dssr-report-routing.module';
import { UCDSSRReportPageComponent }
  from './dssr-report-page.component';
import { UCDSSRReportGridComponent }
  from './dssr-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCDSSRReportRoutingModule
  ],
  exports: [
    UCDSSRReportPageComponent
  ],
  declarations: [
    UCDSSRReportPageComponent,
    UCDSSRReportGridComponent
  ]
})

export class UCDSSRReportModule {
}
