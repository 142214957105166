import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';
import * as models from '../services/model/models';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

/**
 * Following action types are being used in the vehicle detail screen
 */
export const actionTypes = {
  GET_ROLE_CONFIGURATION: type('[ROLE_CONFIGURATION] get role configuration'),
  GET_ROLE_CONFIGURATION_SUCCESS: type('[ROLE_CONFIGURATION] get role configuration Success'),
  GET_ROLE_CONFIGURATION_FAIL: type('[ROLE_CONFIGURATION] get role configuration Fail'),
  GET_ROLES_BY_MAKECODE: type('[ROLE_CONFIGURATION] get roles by make code'),
  GET_ROLES_BY_MAKECODE_SUCCESS: type('[ROLE_CONFIGURATION] get roles by make code Success'),
  GET_ROLES_BY_MAKECODE_FAIL: type('[ROLE_CONFIGURATION] get roles by make code Fail'),
  SAVE_ROLE_CONFIGURATION_ACTION: type('[ROLE_CONFIGURATION] save role configuration'),
  SAVE_ROLE_CONFIGURATION_SUCESS_ACTION:
   type('[ROLE_CONFIGURATION] save role configuration success'),
  SAVE_ROLE_CONFIGURATION_FAIL_ACTION: type('[ROLE_CONFIGURATION] save role configuration failure'),
  CLEAR_ALL_ACTION: type('[ROLE_CONFIGURATION] clear all saved data'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetRoleConfigurationAction implements ActionBase {
  type = actionTypes.GET_ROLE_CONFIGURATION;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetRoleConfigurationSuccessAction implements ActionBase {
  type = actionTypes.GET_ROLE_CONFIGURATION_SUCCESS;

  constructor(public payload: models.Role) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetRoleConfigurationFailAction implements ActionBase {
  type = actionTypes.GET_ROLE_CONFIGURATION_FAIL;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetRolesByMakeCodeAction implements ActionBase {
  type = actionTypes.GET_ROLES_BY_MAKECODE;

  constructor() { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetRolesByMakeCodeSuccessAction implements ActionBase {
  type = actionTypes.GET_ROLES_BY_MAKECODE_SUCCESS;

  constructor(public payload: models.Role) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetRolesByMakeCodeFailAction implements ActionBase {
  type = actionTypes.GET_ROLES_BY_MAKECODE_FAIL;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the save decertification action
 */
export class SaveRoleConfigurationAction implements ActionBase {
  type = actionTypes.SAVE_ROLE_CONFIGURATION_ACTION;

  constructor(public payload: models.Role) { }
}

/**
 * Class used to handle the save decertification success action
 */
export class SaveRoleConfigurationSuccessAction implements ActionBase {
  type = actionTypes.SAVE_ROLE_CONFIGURATION_SUCESS_ACTION;

  constructor(public payload: models.Role) { }
}

/**
 * Class used to handle the save decertification fail action
 */
export class SaveRoleConfigurationFailAction implements ActionBase {
  type = actionTypes.SAVE_ROLE_CONFIGURATION_FAIL_ACTION;

  constructor(public payload: models.Role) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class ClearAllAction implements ActionBase {
  type = actionTypes.CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = GetRoleConfigurationAction
  | GetRoleConfigurationSuccessAction
  | GetRoleConfigurationFailAction
  | GetRolesByMakeCodeAction
  | GetRolesByMakeCodeSuccessAction
  | GetRolesByMakeCodeFailAction
  | SaveRoleConfigurationAction
  | SaveRoleConfigurationSuccessAction
  | SaveRoleConfigurationFailAction
  | ClearAllAction;
