import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// Region No
      key: ViewConstants.DEALER_REGION_STRING_COLUMN, fieldName: 'areaRegionNo',
      style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// DEALER NO
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    }, {// vin
      key: ViewConstants.VIN, fieldName: 'vin', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// Count
      key: ViewConstants.DELETED_DATE, fieldName: 'decertifiedAt',
      style: ' ', hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Source
      key: ViewConstants.LAST_UPDATED_BY, fieldName: 'updatedBy', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// De Certify reason
      key: ViewConstants.DE_CERTIFY_REASON, fieldName: 'deCertifyReason',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    }
  ];
}
