import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DealerDetailView } from '../../../services/view-model/dealer-detail-view';
import * as fromRoot from '../../../reducers';
import { Store } from '@ngrx/store';
import { isNil, cloneDeep } from 'lodash';
import { DealerSettingsJsonClass } from './json/DealerSettingsJson';
import * as dealerActions from '../../../actions/dealer-detail.action';
import { SharedDataService } from '../../../services/common/shared-data.service';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import { ViewConstants } from '../../../shared/shared.constants';
import * as models from '../../../services/model/models';

@Component({
  selector: 'dealer-details-dialog',
  templateUrl: 'dealer-details-dialog.component.html',
  styleUrls: ['dealer-details-dialog.component.scss']
})

export class DealerDetailsDialogComponent implements OnInit, OnDestroy {

  // Title to be displayed for the dialog
  public title: string;
  // Variable for grid column definitions
  public columnDefs: any[] = [];
  // Variable to hold the consuming component instance
  public parent: any;
  // Variable to hold the datas displayed in the grid
  public dealerDetailsGridData: models.DealerDetailsModel[];
  // Variable to hold the datas displayed in the grid
  public originalGridData: models.DealerDetailsModel[];
  // Variable to hold the datas displayed in the grid
  public filteredData: models.DealerDetailsModel[];
  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<DealerDetailView>;
  // Variable for sort columns list
  public sortColumnsList: Array<any> = [];
  // Variable to display/hide clear all button
  showClearAllBtn: boolean;
  // variable to hold the grid width
  gridFlex: string = '80';
  // Variable to hold the selected dealer code
  selectedDealerCode: string;
  // Variable to hold the action method in the consuming component
  selectAction: any;
  // Variable to enable/disable the select button
  disableSelect: boolean;
  // Array to hold the filters applied in the grid
  public filterArray: Array<models.Filter> = [];

  /**
   * constructor method to create new instance of DialogComponent
   */
  constructor(public dialogRef: MatDialogRef<DealerDetailsDialogComponent>,
    private store: Store<fromRoot.AppState>,
    public sharedDataService: SharedDataService,
    public sharedFuncService: SharedFunctionService) {
    this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
  }

  /**
   * OnInit
   */
  ngOnInit() {
    this.sharedFuncService.setCurrentScreenName(ViewConstants.DEALER_DETAILS_SCREEN_NAME);
    this.disableSelect = true;
    this.columnDefs = DealerSettingsJsonClass.gridColumns;
    this.dealerDetailsData$.subscribe(data => {
      if (!isNil(data) && data !== undefined) {
        this.refreshFilters();
        if (!isNil(data.allDealers) && data.allDealers.length > 0) {
          let dealerData;
          if (this.sharedDataService.isTMEXUser) {
            dealerData = data.allDealers.filter(e => e.areaRegionNo === '83');
          } else {
            dealerData = data.allDealers.filter(e => e.areaRegionNo !== '83');
          }
          this.dealerDetailsGridData = cloneDeep(dealerData);
          this.originalGridData = cloneDeep(dealerData);
        }
      }
    });
    this.store.dispatch(new dealerActions.LoadAllDealersAction({}));
  }

  refreshFilters() {
    this.showClearAllBtn = false;
    this.sortColumnsList = [];
    DealerSettingsJsonClass.gridColumns.forEach(mdCol => {
      mdCol.filterValue = '';
    });
  }

  clearAllButtonClicked(event: any) {
    this.gridFlex = '80';
    this.dealerDetailsGridData = cloneDeep(this.originalGridData);
    this.filteredData = cloneDeep(this.originalGridData);
    this.filterArray = [];
    this.refreshFilters();
  }

  /**
   * Method is used to invoke selectAction event to the consuming component
   */
  selectDealer() {
    this.dialogRef.close(true);
    this.selectAction(this.dialogRef);
  }

  ngOnDestroy() {
    let i = 0;
  }

  rowClicked(event: any, row: any) {
    if (!isNil(row) && !isNil(row.dealerCode) && !isNil(event)) {
      // if (event.checked) {
      //     this.disableSelect = false;
      //     this.selectedDealerCode = row.dealerCode;
      //     if (!isNil(this.dealerDetailsGridData)) {
      //         this.dealerDetailsGridData.forEach(dealer => {
      //             dealer.selected = false;
      //             if (dealer.dealerCode === row.dealerCode) {
      //                 dealer.selected = true;
      //             }
      //         })
      //     }
      // } else {
      //     this.disableSelect = true;
      //     row.selected = false;
      // }

      this.disableSelect = false;
      this.selectedDealerCode = row.dealerCode;
      if (!isNil(this.dealerDetailsGridData)) {
        this.dealerDetailsGridData.forEach(dealer => {
          dealer.selected = false;
          if (dealer.dealerCode === row.dealerCode) {
            dealer.selected = true;
          }
        });
      }
    } else {
      this.disableSelect = true;
      row.selected = false;
    }
  }

  closeClickEvent() {
    this.dialogRef.close(true);
  }

  /**
   * Method to apply the sorting in the grid
   */
  applySort(columnName: string) {
    let sortOrder = ViewConstants.ASC;
    if (this.sortColumnsList.findIndex(x => x.columnName === columnName) > -1) {
      sortOrder = this.sortColumnsList.find(x => x.columnName === columnName)
        .sortOrder === ViewConstants.ASC ? ViewConstants.DESC : ViewConstants.ASC;
    }
    this.sortColumnsList = [];
    this.sortColumnsList.push({ columnName: columnName, sortOrder: sortOrder });
    let event = { columnName: columnName, sortOrder: sortOrder };
    this.dealerDetailsGridData =
      cloneDeep(this.sharedFuncService.applySort(this.dealerDetailsGridData,
        event));
  }

  applyFilter(event: any, columnName: string) {
    let filter: models.Filter = {};
    filter.columnname = columnName;
    filter.filterValue = event.target.value;
    this.applyFinalFilter(filter);
  }

  applyFinalFilter(filter: models.Filter) {
    let tempFilter: models.Filter = null;
    let existingFilterInArrayIndex: number = 0;
    if (this.filterArray !== null && this.filterArray !== undefined) {
      this.filterArray.forEach(data => {
        if (data.columnname === filter.columnname) {
          tempFilter = data;
        }
        if (tempFilter === null) {
          existingFilterInArrayIndex++;
        }
      });
      if (tempFilter !== null && tempFilter !== undefined) {
        this.filterArray.splice(existingFilterInArrayIndex, 1);
      }
      if (filter.filterValue !== null && filter.filterValue !== undefined
        && filter.filterValue.trim() !== '') {
        tempFilter = filter;
        this.filterArray.push(tempFilter);
      }
      this.filteredData = cloneDeep(this.originalGridData);
      this.filterArray.forEach(arrayElement => {
        if (!isNil(arrayElement.filterValue) && arrayElement.filterValue !== undefined &&
          arrayElement.filterValue.trim() !== '') {
          this.applyContainsFilter(arrayElement);
        }
      });
      if (this.filterArray.length > 0) {
        this.showClearAllBtn = true;
      } else {
        this.showClearAllBtn = false;
      }
      this.dealerDetailsGridData = cloneDeep(this.filteredData);
    }
  }

  applyContainsFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(dealer => {
      return dealer[filterElement.columnname].toLowerCase().indexOf(filterValue) > -1;
    });
  }

}
