import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil } from 'lodash';
import { IMyDpOptions } from 'mydatepicker';
import { Observable } from 'rxjs';
import * as dealerActions from '../../../actions/dealer-detail.action';
import * as fromRoot from '../../../reducers';
import { SharedDataService } from '../../../services/common/shared-data.service';
import * as viewModels from '../../../services/view-model/view-models';
import { ReportData } from '../../master-data/reportData';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'region-dashboard-search-toolbar',
  templateUrl: 'region-dashboard-search-toolbar.component.html',
  styleUrls: ['./region-dashboard-search-toolbar.component.scss']
})

export class RegionDashboardSearchToolbarComponent implements OnInit {

  @Output() viewInventoryEvent = new EventEmitter<any>();

  @Output() findDealerEvent = new EventEmitter<any>();

  @Output() findVehicleEvent = new EventEmitter<any>();

  @Input() enteredDealerCode: string;

  @Output() filterDateEvent = new EventEmitter<any>();

  @Input() enteredvin: string;

  @Input() fromDate: string;

  @Input() toDate: string;

  @Input() labelText: string = 'Certification Date';

  @Input() regionSelection: string;

  @Input() districtSelection: string;

  monthList: Array<any> = [];
  yearList: Array<any> = [];
  selectedOpt = 'm';
  Frmdate = new FormControl(null);
  toDateYty = new FormControl(null);
  disableApplyYty = false;
  monthSelected = false;
  ytySelectedYear:number | null = null;

  @Input() selectedMonth: string;
  @Input() selectedYear: string;
  @Input() ytyTCUVFilter: boolean = false;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    inline: false,
    openSelectorTopOfInput: false,
    showClearDateBtn: true,
    showInputField: true,
    alignSelectorRight: true,
    showSelectorArrow: false
  };

  filterMethoditems: Array<any> =
    [
      { name: 'Show All', value: 'Show All', disabled: false },
      { name: 'Current Week', value: 'Current Week', disabled: false },
      { name: 'Current Month', value: 'Current Month', disabled: false },
      { name: 'Current Date', value: 'Current Date', disabled: true }
    ];

  @Input() isDateSelected: boolean = false;
  dealerDetailsData$: Observable<viewModels.DealerDetailView>;
  regionItems: Array<any>;
  districtItems: Array<any>;
  maxDate: Date;
  maxDateForFromYty: Date;
  minDate: Date;
  enableToDateForYty = false;

  constructor(private store: Store<fromRoot.AppState>,
    public sharedDataService: SharedDataService) {
    this.onDisableFuture();
    if (isNil(this.sharedDataService.dealerData)) {
      this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
    }

    if (isNil(this.sharedDataService.dealerData)) {
      this.store.dispatch(new dealerActions.LoadAllDealersAction({}));
      this.dealerDetailsData$.subscribe(data => {
        if (!isNil(data) && data !== undefined) {
          if (!isNil(data.allDealers) && data.allDealers.length > 0) {
            this.sharedDataService.dealerData = cloneDeep(data.allDealers);
            let regionArrayCopy2 = new Array<any>();
            regionArrayCopy2.push({ name: 'ALL' });

            if (this.sharedDataService.dealerData !== undefined) {
              this.sharedDataService.dealerData.forEach(element => {
                if (element.brand === this.sharedDataService.brandName &&
                  element.areaRegionNo !== '83') {
                  let dataReg = {
                    name: element.areaRegionName,
                    makeCode: element.brand
                  };
                  regionArrayCopy2.push(dataReg);
                }
              });
            }

            regionArrayCopy2 = this.sort_by_key(regionArrayCopy2, 'name');
            this.regionItems = regionArrayCopy2.map(item => item.name)
              .filter((item, i, ar) => ar.indexOf(item) === i);
            console.log(this.regionItems);

          }
        }
      });
    }
    let regionArrayCopy = new Array<any>();
    regionArrayCopy.push({ name: 'ALL' });
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName && element.areaRegionNo !== '83') {
          let data = {
            name: element.areaRegionName,
            makeCode: element.brand
          };
          regionArrayCopy.push(data);
        }
      });
    }
    regionArrayCopy = this.sort_by_key(regionArrayCopy, 'name');
    this.regionItems =
      regionArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);
    let districtArrayCopy = new Array<any>();
    districtArrayCopy.push({ name: 'ALL' });
    /* if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName) {
          let dataDist = {
            name: element.districtNo,
            makeCode: element.brand
          };
          districtArrayCopy.push(dataDist);
        }
      });
    } */
    districtArrayCopy = this.sort_by_key(districtArrayCopy, 'name');
    this.districtItems =
      districtArrayCopy.map(item => item.name)
        .filter((item, i, ar) => ar.indexOf(item) === i);
    let date = new Date();
    this.monthList = ReportData.monthDetails;
    for (let i = date.getFullYear() - 10; i <= date.getFullYear(); i++) {
      this.yearList.push(i.toString());
    }
  }

  ngOnInit(): void {
    if (this.ytyTCUVFilter) {
    this.enableToDateForYty = true;
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    this.maxDateForFromYty = new Date(currentYear, currentMonth, this.daysInMonth(currentMonth, currentYear));
    this.maxDate = new Date(currentYear, currentMonth, this.daysInMonth(currentMonth, currentYear));


      this.selectedOpt = 'y';
      this.selectedMonth = currentMonth + 1 + '';
      this.selectedYear = currentYear.toString();
      this.regionSelection = 'ALL'
      this.districtSelection = 'ALL'
      this.monthList = this.monthList.slice(0, currentMonth + 1);
      // this.filterValueChangedYTY();
    };

  }

  daysInMonth(month: number, year: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  selection(event: any) {
    this.selectedOpt = event.value;
    if(this.ytyTCUVFilter){
      this.Frmdate = new FormControl(null);
      this.toDateYty = new FormControl(null);
      this.enableToDateForYty = true;

      // if(this.selectedOpt === 'y')
      // this.filterValueChangedYTY();

    }
  }

  callApplyForYty(){
    if(this.selectedOpt === 'y' && this.ytyTCUVFilter)
    this.filterValueChangedYTY();
  }

  getDistricts(event: any) {
    console.log('55OnChange');
    console.log(event.value);

    let districtArrayCopy = new Array<any>();
    districtArrayCopy.push({ name: 'ALL' });
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName) {
          if (element.areaRegionName === event.value) {
            let data = {
              name: element.districtNo,
              makeCode: element.brand
            };
            districtArrayCopy.push(data);
          }
        }
      });
    }
    districtArrayCopy = this.sort_by_key(districtArrayCopy, 'name');
    this.districtItems =
      districtArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);
    this.districtSelection = 'ALL';

  }

  sort_by_key(array, key) {
    return array.sort(function (a, b) {
      if (b[key] === 'ALL') return 1;
      let x = a[key]; let y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  viewInventory() {
    this.viewInventoryEvent.emit(this.enteredDealerCode);
  }

  findDealer() {
    this.findDealerEvent.emit();
  }

  findVehicle() {
    this.enteredvin = this.enteredvin.toUpperCase().trim();
    this.findVehicleEvent.emit(this.enteredvin);
  }

  filterValueChanged() {
    let dateValues = {
      filter: 'between',
      fromDate: this.fromDate,
      toDate: this.toDate,
      region: this.regionSelection,
      district: this.districtSelection,
      month: this.selectedMonth,
      year: this.selectedYear,
      reportType: this.selectedOpt
    };

    this.filterDateEvent.emit(dateValues);

  }

  filterValueChangedYTY() {
    let dateValues = {
      filter: 'between',
      // fromDate: this.fromDate,
      // toDate: this.toDate,
      region: this.regionSelection,
      district: this.districtSelection,
      month: this.selectedMonth,
      year: this.selectedYear,
      reportType: this.selectedOpt
    };

    this.filterDateEvent.emit(dateValues);

  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  onDisableFuture() {
    let date = new Date();
    // Disable/enable dates from 5th forward
    date.setMonth(date.getMonth() + 1);
    let copy = this.getCopyOfOptions();
    copy.disableSince = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate() + 1
    };
    this.myDatePickerOptions = copy;
  }

  dateFilter(event: any, value: any) {

    if (value === 'fromDate') {
      this.fromDate = event.formatted;
    }

    if (value === 'toDate') {
      this.toDate = event.formatted;
    }

    if (value === 'regionSelection') {
      this.regionSelection = event.region;
      // if(this.selectedOpt === 'y' && this.ytyTCUVFilter)
      //  this.filterValueChangedYTY();
    }

    if (value === 'districtSelection') {
      this.districtSelection = event.district;
      // if(this.selectedOpt === 'y' && this.ytyTCUVFilter)
      //   this.filterValueChangedYTY();
    }
    if (value === 'selectedMonth') {
      this.selectedMonth = event.month;
    }

    if (value === 'selectedYear') {
      this.selectedYear = event.year;
    }

    if (this.fromDate !== undefined && this.toDate !== undefined) {
      this.isDateSelected = true;
    }

  }


  chosenYearHandler(normalizedYear: Moment, dateTypeSelected: string) {
   
    if(dateTypeSelected === 'fromDate') 
    {
      this.ytySelectedYear = normalizedYear.year();
      if(this.Frmdate?.value)
      this.Frmdate.setValue(null)
    }
    if (normalizedYear?.isValid() && this.monthSelected) {
      const formControl = dateTypeSelected === 'fromDate' ? this.Frmdate : this.toDateYty;
      const currentDate = formControl.value ? moment(formControl.value) : moment().startOf('month');
      const updatedDate = currentDate.year(normalizedYear.year()).startOf('month');
      formControl.setValue(updatedDate);
    }

  }
  

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, type: string) {
    if (normalizedMonth && normalizedMonth.isValid()) {
        const formControl = type === 'fromDate' ? this.Frmdate : this.toDateYty;
        // const currentDate = formControl.value ? moment(formControl.value) : moment().startOf('month');
        // const updatedDate = currentDate.month(normalizedMonth.month()).startOf('month');
        // const year = type === 'toDate' ? moment(this.Frmdate.value).year() : normalizedMonth.year();
        const year = type === 'fromDate' ? this.ytySelectedYear || moment().year() : moment(this.Frmdate.value).year();
        const updatedDate = moment().month(normalizedMonth.month()).year(year).startOf('month');
        
        formControl.setValue(updatedDate);
        this.monthSelected = true;

        if (type === 'fromDate') {
            // Update the fromDate in YYYY-MM format
            this.fromDate = updatedDate.format('YYYY-MM');
            this.enableToDateForYty = false;
            
            const [fromDateYear, fromDateMonth] = this.fromDate.split('-').map(Number);
            const _today = new Date();
            const _currentYear = _today.getFullYear();

            if (_currentYear !== fromDateYear) {
                this.maxDate = new Date(fromDateYear, 11, this.daysInMonth(11, fromDateYear));
            }
            this.minDate = new Date(fromDateYear, fromDateMonth - 1, this.daysInMonth(fromDateMonth - 1, fromDateYear));
            if(this.toDate){
              this.toDate = '';
              this.toDateYty.setValue(null);
            }
        } else {
            // Update the toDate in YYYY-MM format
            this.toDate = updatedDate.format('YYYY-MM');
        }

        if (this.toDateYty?.value) {
            const fDate = moment(this.Frmdate.value);
            const tDate = moment(this.toDateYty.value);
            this.disableApplyYty = fDate.isAfter(tDate);
        }

        datepicker.close();
    }
}

}
