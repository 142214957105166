'use strict';

// Model for holding Certification Details
export interface CertificationDetails {
    // to store plan code
    warrantyCode?: string;
    // to store certification status
    certificationStatus?: string;
    // to store the certification number
    certificationNumber?: string;
    // to store the force certification flag
    forceCertificationFlag?: string;
    // to store the certification date
    certificationDate?: string;
    // to store the warranty expiry date
    warrantyExpiryDate?: string;
    // Decertification reason
    deCertifyReason?: string;
    // technicianId
    technicianId?: string;
    // warrantyDescription
    warrantyDescription?: string;
    // planAmount
    planAmount?: string;
}
