import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCTmexSalesReportRoutingModule }
  from './tmex-sales-report-routing.module';
import { UCTmexSalesPageReportComponent } from './tmex-sales-report-page.component';
import { UCTmexSalesReportGridComponent }
  from './tmex-sales-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCTmexSalesReportRoutingModule
  ],
  exports: [
    UCTmexSalesPageReportComponent
  ],
  declarations: [
    UCTmexSalesPageReportComponent,
    UCTmexSalesReportGridComponent
  ]
})

export class UCTmexSalesReportModule {

}
