'use strict';

export interface Price {
  // to store acv Price
  acvPrice?: string;
  // to store retail Amount
  retailAmount?: string;
  // to store Dealer Trade Amount
  dealerTradeAmount?: string;
  // to store Recondition Cost
  reconditionCost?: string;
  // to store labor
  labor?: string;
  // to store customer
  customer?: string;
  // to store parts
  parts?: string;
  // to store total vehicle cost
  totalVehicleCost?: string;
}
