


import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import * as dealerDetailActions from '../actions/dealer-detail.action';
import {map, catchError, switchMap} from 'rxjs/operators'
import { DealerDetailService } from '../services/api/dealer-detail.service';

@Injectable()
export class DealerDetailEffects {
    @Effect()
    getDealerData$: Observable<Action> = this.actions$
        .pipe(ofType(dealerDetailActions.actionTypes.LOAD_DEALER_DETAIL)
        ,map((action: ActionBase) => action.payload)
        , switchMap(data =>
            this.dealerDetailService.getDealerDetails(data.dealerCode)
                .pipe(map((dealerDetail: any) => new
                    dealerDetailActions.LoadDealerSuccessAction(dealerDetail))
                , catchError(error => of(new
                    dealerDetailActions.LoadDealerFailAction(error))))
        ));

    @Effect()
    getAllDealersData$: Observable<Action> = this.actions$
        .pipe(ofType(dealerDetailActions.actionTypes.LOAD_ALL_DEALERS)
        ,map((action: ActionBase) => action.payload)
        , switchMap(data =>
            this.dealerDetailService.getAllDealers()
                .pipe(map((dealerDetails: any) => new
                    dealerDetailActions.LoadAllDealersSuccessAction(dealerDetails))
                , catchError(error => of(new
                    dealerDetailActions.LoadAllDealersFailureAction(error))))
        ));

    constructor(private actions$: Actions,
        private dealerDetailService: DealerDetailService) { }
}
