import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCLexusDealerParticipationReportRoutingModule }
  from './lexus-dealer-participation-report-routing.module';
import { UCLexusDealerParticipationReportComponent }
  from './lexus-dealer-participation-report-page.component';
import { UCLexusDealerParticipationGridComponent }
  from './lexus-dealer-participation-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCLexusDealerParticipationReportRoutingModule
  ],
  exports: [
    UCLexusDealerParticipationReportComponent
  ],
  declarations: [
    UCLexusDealerParticipationReportComponent,
    UCLexusDealerParticipationGridComponent
  ]
})

export class UCLexusDealerParticipationReportModule {
}
