import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCVehicleDailyRDRReportRoutingModule } from './vehicle-daily-rdr-report-routing.module';
import { UCVehicleDailyRDRPageReportComponent } from './vehicle-daily-rdr-report-page.component';
import { UCVehicleDailyRDRReportGridComponent } from './vehicle-daily-rdr-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCVehicleDailyRDRReportRoutingModule
  ],
  exports: [
    UCVehicleDailyRDRPageReportComponent
  ],
  declarations: [
    UCVehicleDailyRDRPageReportComponent,
    UCVehicleDailyRDRReportGridComponent
  ]
})

export class UCVehicleDailyRDRReportModule {

}
