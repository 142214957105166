<div fxFlex="100" fxLayout="row" class="dealer-vin-search">
    <mat-card fxFlex="row" fxFlex="30">
        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;">
            
                <mat-form-field style="width: 145px; height: 50px;">
                    <input #dCodeTxtBox matInput [(ngModel)]="enteredDealerCode" [ddFieldValidator]="8" placeholder="Enter dealer code" align="end">
                    <!--<mat-icon mdSuffix style="color: gray">search</mat-icon>-->
                </mat-form-field>
            
            <button [disabled]="dCodeTxtBox.value.length!==5" (click)="viewInventory();" mat-button mat-raised-button style="height: 38px">VIEW INVENTORY</button>
        </div>
    </mat-card>
    
</div>