

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { UserDetailView } from '../services/view-model/user-detail-view';
import { DealerDetailsModel } from '../services/model/DealerDetailsModel';
import * as userActions from '../actions/user-detail.action';
import { ViewConstants } from '../shared/shared.constants';
import { isNil, cloneDeep } from 'lodash';

/**
 * Interface used to maintain the state for the dealer details
 */
export interface UserDetailState {
    userDetails: UserDetailView;
}
/**
 * Variable used to store the initial state of Dealer Detail
 */
const initialState: UserDetailState = {
    userDetails: null
};
/**
 * Reducer method to handle the dealer detail actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): UserDetailState {
    switch (action.type) {
        case userActions.actionTypes.GET_PERSON_ID_SUCCESS: {
            let personDetailsData = (<any>Object).assign({}, state.userDetails, {
                    personData: cloneDeep(action.payload),
                });
            return { userDetails : personDetailsData.personData };
        }
        case userActions.actionTypes.GET_PERSON_ID_FAIL: {
            return { userDetails : undefined };
        }
        case userActions.actionTypes.GET_TOKEN_FOR_PERSON_SUCCESS: {
            let personDetailsData = (<any>Object).assign({}, state.userDetails, {
                    personData: cloneDeep(action.payload),
                });
            return { userDetails : personDetailsData.personData };
        }
        case userActions.actionTypes.GET_TOKEN_FOR_PERSON_FAIL: {
            return { userDetails : undefined };
        }
        default: {
            return state;
        }
    }
}
/* tslint:enable:no-switch-case-fall-through */

/**
 * Method to get the state of dealer details
 */
export function getUserDetail(state: UserDetailState) {
    return state.userDetails;
}


