import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';
import * as models from '../services/model/models';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

/**
 * Following action types are being used in the vehicle detail screen
 */
export const actionTypes = {
  LOAD_VIN_INFORMATION: type('[Vehicle] vehicle decertification vin information'),
  LOAD_VIN_INFORMATION_SUCCESS: type('[Vehicle] vehicle decertification vin information Success'),
  LOAD_VIN_INFORMATION_FAIL: type('[Vehicle] vehicle decertification vin information Fail'),
  SAVE_VEHICLE_ACTION: type('[Vehicle] save vehicle decertification'),
  SAVE_VEHICLE_SUCESS_ACTION: type('[Vehicle] save vehicle decertification success'),
  SAVE_VEHICLE_FAIL_ACTION: type('[Vehicle] save vehicle decertification failure'),
  CLEAR_ALL_ACTION: type('[Vehicle] clear all saved decertification data'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVinInformationAction implements ActionBase {
  type = actionTypes.LOAD_VIN_INFORMATION;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVinInformationSuccessAction implements ActionBase {
  type = actionTypes.LOAD_VIN_INFORMATION_SUCCESS;

  constructor(public payload: models.Decertification) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVinInformationFailAction implements ActionBase {
  type = actionTypes.LOAD_VIN_INFORMATION_FAIL;

  constructor(public payload: string) { }
}


/**
 * Class used to handle the save decertification action
 */
export class SaveDecertificationAction implements ActionBase {
  type = actionTypes.SAVE_VEHICLE_ACTION;

  constructor(public payload: models.Decertification) { }
}

/**
 * Class used to handle the save decertification success action
 */
export class SaveDecertificationSuccessAction implements ActionBase {
  type = actionTypes.SAVE_VEHICLE_SUCESS_ACTION;

  constructor(public payload: models.Decertification) { }
}

/**
 * Class used to handle the save decertification fail action
 */
export class SaveDecertificationFailAction implements ActionBase {
  type = actionTypes.SAVE_VEHICLE_FAIL_ACTION;

  constructor(public payload: models.Decertification) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class ClearAllAction implements ActionBase {
  type = actionTypes.CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = LoadVinInformationAction
  | LoadVinInformationSuccessAction
  | LoadVinInformationFailAction
  | SaveDecertificationAction
  | SaveDecertificationSuccessAction
  | SaveDecertificationFailAction
  | ClearAllAction;
