'use strict';
// Inspection Details
export interface Role {
  // to store dealerCd
  role?: string;
  // to store certDlrFlag
  isActive?: string;

  isActiveFlag?: boolean;
  // to store fuelCellDlrFlag
  permission?: string;

  moduleName?: string;

  restrictedModules?: Array<any>;

  makeCode?: string;

  updatedAt?: string;

  updatedBy?: string;

  createdAt?: string;

  createdBy?: string;
}
