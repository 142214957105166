import { Observable } from 'rxjs';
import { DealerDetailsDialogComponent } from './dealer-details-dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable, ViewContainerRef } from '@angular/core';
import * as models from '../../../services/model/models';
/**
 * This class is used to show the Dialog component in the screen
 */
@Injectable()
export class DealerDetailsDialogService {
  /**
   * constructor method to create new instance of Alert Dialog
   */
  constructor(public dialog: MatDialog) { }

  /**
   * Method to open the alert dialog in the page
   */
  public open(title: string, viewContainerRef: ViewContainerRef,
    parentComponentInstance?: any, applyAction?: any): Observable<boolean> {
    let dialogRef: MatDialogRef<DealerDetailsDialogComponent>;
    let config = new MatDialogConfig();
    config.disableClose = false;
    config.viewContainerRef = viewContainerRef;
    dialogRef = this.dialog.open(DealerDetailsDialogComponent, config);
    dialogRef.componentInstance.title = title;
    if (applyAction && applyAction !== '') {
      dialogRef.componentInstance.selectAction = applyAction;
    }
    if (parentComponentInstance !== null && parentComponentInstance !== undefined &&
      parentComponentInstance !== '') {
      dialogRef.componentInstance.parent = parentComponentInstance;
    }
    return dialogRef.afterClosed();
  }
}
