'use strict';

export interface Decertification {
  // to store VIN_NUMBER
  VIN_NUMBER?: string;
  // to store DECERTIFICATION_SOURCE_CODE
  DECERTIFICATION_SOURCE_CODE?: string;
  // to store DECERTIFICATION_REASON_CODE
  DECERTIFICATION_REASON_CODE?: string;
  // to store DECERTIFICATION_COMMENTS
  DECERTIFICATION_COMMENTS?: string;
  // to store DECERTIFICATION_ID
  DECERTIFICATION_ID?: string;
  // to store DECERTIFICATION_TIMESTAMP
  DECERTIFICATION_TIMESTAMP?: string;
  // to store BLOCK_CERTIFICATION_FLAG
  BLOCK_CERTIFICATION_FLAG?: string;
  // to store UNBLOCK_COMMENT
  UNBLOCK_COMMENT?: string;
  // to store UNBLOCK_ID
  UNBLOCK_ID?: string;
  // to store UNBLOCK_TIMESTAMP
  UNBLOCK_TIMESTAMP?: string;
  // to store DAMAGE_AMOUNT
  DAMAGE_AMOUNT?: string;
  // to store ACTIVE_FLAG
  ACTIVE_FLAG?: string;
  // to store LAST_UPDATED_ID
  LAST_UPDATED_ID?: string;
  // to store LAST_UPDATE_TIMESTAMP
  LAST_UPDATE_TIMESTAMP?: string;
  // to store InEligibleFlag
  InEligibleFlag?: string;
}
