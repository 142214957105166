<div class="dd-dialog-container" style="height: calc(100vh - 75px); width: calc(100vw - 55px)" fxFlex="100"
    fxLayout="column">
    <div class="uc-loading" *ngIf="sharedDataService?.showLoader">
        <img class="uc-loading-img" src="assets/icon/loader.svg" alt="Loading...">
    </div>
    <div fxLayout="row" fxFlex="10"
        style="background-color: rgb(242, 242, 242); border-top: 3px solid #c00; border-bottom: 2px solid rgb(226, 226, 226)">
        <span style="margin-left: 12px;" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">Select Dealer</span>
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
            <mat-icon style="margin-right: 8px; cursor: pointer" (click)="closeClickEvent()">close</mat-icon>
        </div>
    </div>
    <div fxFlex="5" *ngIf="showClearAllBtn" fxLayout="row" fxLayoutAlign="start center" style="margin-top: 5px">
        <div fxLayout="row"
            style="margin-left: 10px; padding: 5px 0px; border-radius: 15px; background-color: lightgray;"
            fxLayoutAlign="center center">
            <div style="padding-left: 5px;">Clear All</div>
            <span class="clearAllBtn" (click)="clearAllButtonClicked($event);"
                style="padding-left: 0.5rem; padding-right: 5px;">×</span>
        </div>
    </div>
    <!--<dealer-vin-search-toolbar fxFlex="10" style="padding-top: 10px"></dealer-vin-search-toolbar>-->
    <div fxFlex="90">
        <mat-card>
            <ngx-datatable #inventoryTable class='material striped dd-grid-size' [rows]='dealerDetailsGridData'
                [columnMode]="'force'" [headerHeight]="65" [footerHeight]="0" [rowHeight]="40" [scrollbarV]="true"
                [scrollbarH]="true" [externalSorting]="true">
                <ngx-datatable-column [width]="35" [minWidth]="35" [maxWidth]="50" [frozenLeft]="true">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <mat-radio-button [checked]="row['selected']"
                            (change)="rowClicked($event, row)">&nbsp;</mat-radio-button>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngFor="let columnDef of columnDefs" [name]="columnDef.key"
                    [prop]="columnDef.fieldName" [width]="columnDef.columnWidth" [minWidth]="columnDef.columnMinWidth"
                    [maxWidth]="columnDef.columnMaxWidth" [flexGrow]="columnDef.columnWidth">
                    <ng-template let-model="model" ngx-datatable-header-template>
                        <div class="datatable-header-cell-label">
                            <div fxLayout="column" class="dd-grid-label-align">
                                <div class="cursorP" fxFlex="100"
                                    [class.dd-grid-sorting]="columnDef.sortable === 'true' && sharedFuncService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)===''"
                                    [class.dd-grid-sorting-asc]="columnDef.sortable === 'true' && sharedFuncService.getColumnSortIcon(columnDef.fieldName, sortColumnsList) === 'ASC'"
                                    [class.dd-grid-sorting-desc]="columnDef.sortable === 'true' && sharedFuncService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)==='DESC'"
                                    [innerHTML]="columnDef.key"
                                    (click)="columnDef.sortable !== 'true' || applySort(columnDef.fieldName)">
                                </div>
                                <div fxFlex="100">
                                    <!--<mat-form-field>-->

                                    <mat-form-field class="dd-dealer-input-background">
                                        <input matInput [(ngModel)]="columnDef.filterValue"
                                            [ddFieldValidator]="columnDef.dataTypeformat"
                                            (keyup)="applyFilter($event, columnDef.fieldName)">
                                    </mat-form-field>
                                    <!--</mat-form-field>-->
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <div class="text-left" *ngIf="columnDef.key === 'DEALER NAME'">
                            <a class="dd-grid-hyperlink-field" routerLinkActive="active"
                                (click)="navigateToDealer(row)">{{value}}</a>
                        </div>
                        <div *ngIf="columnDef.key !== 'DEALER NAME'" [attr.class]="columnDef.styleClass"
                            [innerHTML]="value"></div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </mat-card>
    </div>
    <div fxFlex="10" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button mat-raised-button target="_blank" [disabled]="disableSelect" (click)="selectDealer();"
            class="addButton" style="margin-right: 5px; background-color: white">SELECT</button>
    </div>
</div>