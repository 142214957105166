import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCInventoryReportRoutingModule }
  from './inventory-report-routing.module';
import { UCInventoryPageReportComponent } from './inventory-report-page.component';
import { UCInventoryReportGridComponent }
  from './inventory-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCInventoryReportRoutingModule
  ],
  exports: [
    UCInventoryPageReportComponent
  ],
  declarations: [
    UCInventoryPageReportComponent,
    UCInventoryReportGridComponent
  ]
})

export class UCInventoryReportModule {

}
