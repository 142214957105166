<div class="margin1" fxFlex="100" fxLayout="column" fxLayoutAlign="space-around center">

  <div class="width100" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="start center">
      <strong>CVA REPORTS</strong>
    </div>
  </div>

  <div class="marginT1 width100" fxLayout="row" fxLayoutAlign="space-evenly start" fxLayoutGap="20">
    <div class="reportSection" fxFlex="30" fxLayout="column" fxLayoutAlign="center start">
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center">
        <label><strong>Sales</strong></label>
      </div>
      <div fxFlex="5" fxLayout="column"> </div>
      <div fxFlex="70" fxLayout="row">
        <mat-radio-group (change)="changeReportSelection('S')" aria-labelledby="example-radio-group-label"
          class="example-radio-group" [(ngModel)]="selectedSalesReport">
          <mat-radio-button [disabled]='report.disabled' class="example-radio-button"
            *ngFor="let report of salesReportsCopy" [value]="report.mode">
            {{report.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxFlex="5" fxLayout="column"> </div>
    </div>

    <div fxFlex="5" fxLayout="column"> </div>
    <div class="reportSection" fxFlex="30" fxLayout="column" fxLayoutAlign="center center">
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center">
        <label><strong>Accounting</strong></label>
      </div>
      <div fxFlex="5" fxLayout="column"></div>
      <div fxFlex="70" fxLayout="row">
        <mat-radio-group (change)="changeReportSelection('A')" aria-labelledby="example-radio-group-label"
          class="example-radio-group" [(ngModel)]="selectedAccountingReport">
          <mat-radio-button [disabled]='report.disabled' class="example-radio-button"
            *ngFor="let report of accountingReportsCopy" [value]="report.mode">
            <span>{{report.name}}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxFlex="5" fxLayout="column"></div>
    </div>

    <div fxFlex="5" fxLayout="column"></div>
    <div class="reportSection" fxFlex="30" fxLayout="column" fxLayoutAlign="center center">
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center">
        <label><strong>Inventory</strong></label>
      </div>
      <div fxFlex="5" fxLayout="column"></div>
      <div fxFlex="70" fxLayout="row">
        <mat-radio-group (change)="changeReportSelection('I')" aria-labelledby="example-radio-group-label"
          class="example-radio-group" [(ngModel)]="selectedInventoryReport">
          <mat-radio-button [disabled]='report.disabled' class="example-radio-button"
            *ngFor="let report of inventoryReportsCopy" [value]="report.mode">
            {{report.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxFlex="5" fxLayout="column"></div>
    </div>
  </div>

  <div class="marginT1 width100" fxLayout="row" fxLayoutAlign="end center">
    <div fxLayout="column" fxLayoutAlign="end center">
      <button [disabled]="(!selectedInventoryReport && !selectedSalesReport && !selectedAccountingReport)"
        (click)="proceedtoReportScreen()" mat-button mat-raised-button>PROCEED</button>
    </div>
  </div>
</div>