import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */


/**
 * Following action types are being used in the inventory summary screen
 */

export const ACTION_TYPES = {
  INITIAL_LOAD: type('[UCSalesSummary] Initiate load for sales details'),
  INITIAL_LOAD_SUCCESS: type('[UCSalesSummary] sales summary Success'),
  INITIAL_LOAD_FAIL: type('[UCSalesSummary] sales summary details Fail'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to load the Initial data for sales summary screen
 */
export class InitialLoadAction implements ActionBase {
  type = ACTION_TYPES.INITIAL_LOAD;
  constructor(public payload: any) { }
}
/**
 * Class used to handle the Success action for Initial Load method
 */
export class InitialLoadSuccessAction implements ActionBase {
  type = ACTION_TYPES.INITIAL_LOAD_SUCCESS;
  constructor(public payload: any) { }
}
/**
 * Class used to handle the Failure action for Initial Load method
 */
export class InitialLoadFailureAction implements ActionBase {
  type = ACTION_TYPES.INITIAL_LOAD_FAIL;
  constructor(public payload: any) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  InitialLoadAction
  | InitialLoadSuccessAction
  | InitialLoadFailureAction;

