<div fxFlex="100" fxLayout="row" class="dealer-vin-search layoutbglite">
    <mat-card fxFlex="row" fxFlex="10">
        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;">
            <button mat-button mat-raised-button (click)="findDealer();">FIND DEALER</button>
        </div>
    </mat-card>
    <mat-card fxFlex="row" fxFlex="25">
        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;">
            
                <mat-form-field style="width: 145px; height: 50px;">
                    <input #dCodeTxtBox matInput [(ngModel)]="enteredDealerCode" [ddFieldValidator]="8" placeholder="Enter dealer code" align="end">
                    <!--<mat-icon mdSuffix style="color: gray">search</mat-icon>-->
                </mat-form-field>
            
            <button [disabled]="dCodeTxtBox.value.length!==5" (click)="viewInventory();" mat-button mat-raised-button style="height: 38px">VIEW INVENTORY</button>
        </div>
    </mat-card>
    <mat-card fxFlex="row" fxFlex="25">
        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;">
            
                <mat-form-field style="height: 50px; width: 173px">
                    <input #vinTxtBox matInput style="text-transform: uppercase;" [(ngModel)]="enteredvin" placeholder="Enter VIN" align="end"
                    minlength="17" maxlength="17" [ddFieldValidator]="validationTypes.ALPHANUMERIC_17_WITHOUTSPACE">
                    <!--<mat-icon mdSuffix style="color: gray">search</mat-icon>-->
                </mat-form-field>
            
            <button [disabled]="vinTxtBox.value.length!==17" (click)="findVehicle();" mat-button mat-raised-button>FIND VEHICLE</button>
        </div>
    </mat-card>
</div>