import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// Region No
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '80',
      columnMinWidth: '80', columnMaxWidth: '80', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Region No
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Count
      key: ViewConstants.CERTIFIED_INVENTORY_MONTHLY, fieldName: 'monthlyCertifiedInventory',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Source
      key: ViewConstants.CERTIFIED_INVENTORY_YEARLY, fieldName: 'yearlyCertifiedInventory',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// De Certify reason
      key: ViewConstants.CERTIFIED_SALES_MONTHLY, fieldName: 'monthlyCertifiedSalesCount',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.CERTIFIED_SALES_YEARLY, fieldName: 'yearlyCertifiedSalesCount',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    }
  ];
}
