import { ActionBase } from '../actions/actionbase';
import { StaffMasterSalesView } from '../services/view-model/feature-config-view';
import * as stafMasterActions from '../actions/staffMaster-detail.action';
import { isNil, cloneDeep } from 'lodash';
import * as models from '../services/model/models';
import { StaffMasterSalesModel } from '../services/model/featureConfigModel';

/**
 * Interface used to maintain the state for the dealer details
 */
export interface StaffMasterSalesState {
  staffMasterSalesDetails: StaffMasterSalesView;
}
/**
 * Variable used to store the initial state of Dealer Detail
 */
const initialState: StaffMasterSalesState = {
  staffMasterSalesDetails: null
};

/**
 * Method used to set the default initial state of staffMasterSalesDetails
 */
function setDefaulValue(): StaffMasterSalesView {
  let staffMasterSalesDetails: StaffMasterSalesView = <StaffMasterSalesView>new Object();
  staffMasterSalesDetails.staffMasterSalesData = [];
  return staffMasterSalesDetails;
}
/**
 * Reducer method to handle the dealer detail actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): StaffMasterSalesState {
  switch (action.type) {
    case stafMasterActions.actionTypes.LOAD_STAFF_MASTER_SALES_SUCCESS: {
      let staffMasterSalesDetail: StaffMasterSalesModel = {};

      if (!isNil(action.payload)) {
        staffMasterSalesDetail = action.payload;
      }
      return {
        staffMasterSalesDetails: (<any>Object).assign({}, state.staffMasterSalesDetails,
          {
            staffMasterSalesData: staffMasterSalesDetail,
          })
      };
    }
    case stafMasterActions.actionTypes.LOAD_STAFF_MASTER_SALES_FAIL: {
      let staffMasterSalesDetail = [];
      let message: models.Message = {};
      message.message = 'Internal server error, kindly try after sometime';
      message.type = models.MessageType.WARNING;
      return {
        staffMasterSalesDetails: (<any>Object).assign({}, state.staffMasterSalesDetails,
          {
            message: message,
            staffMasterSalesData: staffMasterSalesDetail
          })
      };
    }

    case stafMasterActions.actionTypes.CLEAR_ALL_ACTION: {
      return { staffMasterSalesDetails: setDefaulValue() };
    }
    default: {
      return state;
    }
  }
}
/* tslint:enable:no-switch-case-fall-through */

/**
 * Method to get the state of dealer details
 */
export function getStaffMasterSalesDetails(state: StaffMasterSalesState) {
  return state.staffMasterSalesDetails;
}




