import { Injectable } from '@angular/core';
import { Headers,  RequestOptionsArgs, Response, URLSearchParams } from '@angular/http';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UCInventorySummaryJson } from '../../../mock-data/UCInventorySummaryJson';
import { HttpClient } from '../../shared/components/helpers/http-client';
import { SharedFunctionService } from '../common/shared-function.service';
import { UCInventorySummary } from '../model/InventorySummary';
import { forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
/**
 * To invoke service method to obtain the inventory response
 */
@Injectable()
export class UCInventorySummaryService {
    // To populate the default headers
    public defaultHeaders: Headers = new Headers();

    // To populate the Base Path
    //   protected basePath = 'http://localhost:3004/dealer-inventory';
    protected basePath = '/dealer-inventory';
    protected dealerInventoryBasePath = environment.DEALER_INVENTORY_SERVICE_URL;
    /**
     * Constructor method used to create new instance of VehicleInventoryService class
     */
    constructor(
        private sharedFuncService: SharedFunctionService
        , protected httpClient: HttpClient
    ) { }
    /**
     *
     * Returns all inventories created for the dealer
     * @param dealer Dealer code to filter by
     */
    public getInventorySummary(invData: any)
        : Observable<Array<UCInventorySummary>> {

        let queryParameters = new URLSearchParams();
        let requestArray: Array<any> = [];
        let requestInstance: any = {};

        let headerParams = this.defaultHeaders;
        // if (this.adalSvc.userInfo.authenticated) {
        //      headerParams.append('Authorization','Bearer ' + this.adalSvc.userInfo.token);
        // }
        let requestOptions: RequestOptionsArgs = {
            method: 'GET',
            headers: headerParams,
            search: queryParameters
        };
        let CAN_MOCK: boolean = false;
        if (CAN_MOCK) {
            let tempData = cloneDeep(UCInventorySummaryJson.inventories
                .filter(inv => inv.dealerCd === invData.dealerCd));
            return new Observable(observer => {
                observer.next(tempData);
                observer.complete();
            });
        } else {
            if (invData.length !== undefined) {
                invData.map(element => {
                    const path = this.dealerInventoryBasePath + element.dealerCd +
                        '/usedvehicles/search?from=' + element.from;
                    requestInstance = this.httpClient.get(path, requestOptions)
                    .pipe(map((response: any) => {
                            // Hiding loader
                            if (response.status === 204) {
                                return undefined;
                            } else {
                                return response.body;
                            }
                        }), catchError((err: any) => {
                            return this.sharedFuncService.handleError(err);
                        }));
                    requestArray.push(requestInstance);
                });
                return forkJoin(requestArray);
            } else {
                const path = this.dealerInventoryBasePath + invData.dealerCd +
                    '/usedvehicles/search?from=' + invData.from;
                return this.httpClient.get(path, requestOptions)
                    .pipe(map((response: any) => {
                        // Hiding loader
                        if (response.status === 204) {
                            return undefined;
                        } else {
                            return response.body;
                        }
                    }), catchError((err: any) => {
                        return this.sharedFuncService.handleError(err);
                    }));
            }
        }
    }
    /**
     *
     * Returns all inventories created for the dealer
     * @param dealer Dealer code to filter by
     */
    public getInventorySummaryReport(dealerCd: string)
        : Observable<Array<UCInventorySummary>> {
        const path = 'https://api.dev-usedcars.toyota.com/reports/fetch?makeCode=TOYOTA';

        let queryParameters = new URLSearchParams();

        let headerParams = this.defaultHeaders;
        // if (this.adalSvc.userInfo.authenticated) {
        //      headerParams.append('Authorization','Bearer ' + this.adalSvc.userInfo.token);
        // }
        let requestOptions: RequestOptionsArgs = {
            method: 'GET',
            headers: headerParams,
            search: queryParameters
        };
        let CAN_MOCK: boolean = false;
        if (CAN_MOCK) {
            let tempData = cloneDeep(UCInventorySummaryJson.inventories
                .filter(inv => inv.dealerCd === dealerCd));
            return new Observable(observer => {
                observer.next(tempData);
                observer.complete();
            });
        } else {
            return this.httpClient.get(path, requestOptions)
                .pipe(map((response) => {
                    // Hiding loader
                    if (response.status === 204) {
                        return undefined;
                    } else {
                        return response.body;
                    }
                }))
        }
    }
}
