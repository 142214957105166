import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as stafMasterActions from '../actions/staffMaster-detail.action';
import { StaffMasterDetailService } from '../services/api/staffMaster.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class StaffMasterEffects {

    @Effect()
    getStaffMasterFinanceData$: Observable<Action> = this.actions$
        .pipe(ofType(stafMasterActions.actionTypes.LOAD_STAFF_MASTER_FINANCE),
            map((action: ActionBase) => action.payload),
            switchMap(data =>
            this.staffMasterService.getStaffMasterFinanceDetails(data.dealerCode)
                .pipe(map((staffData: any) => new
                    stafMasterActions.LoadStaffMasterFinanceSuccesssAction(staffData)),
                    catchError(error => of(new
                    stafMasterActions.LoadStaffMasterFinanceFailAction(error))))
        ));

    @Effect()
    getStaffMasterSalesData$: Observable<Action> = this.actions$
        .pipe(ofType(stafMasterActions.actionTypes.LOAD_STAFF_MASTER_SALES),
            map((action: ActionBase) => action.payload),
            switchMap(data =>
            this.staffMasterService.getStaffMasterSalesDetails(data.dealerCode)
                .pipe(map((staffData: any) => new
                    stafMasterActions.LoadStaffMasterSalesSuccesssAction(staffData)),
                    catchError(error => of(new
                    stafMasterActions.LoadStaffMasterSalesFailAction(error))))
        ));

    constructor(private actions$: Actions,
        // private featureConfigService: FeatureConfigDetailService,
        private staffMasterService: StaffMasterDetailService,
    ) { }
}
