

import { ActionBase } from '../actions/actionbase';
import { TierEligibilityView } from '../services/view-model/feature-config-view';
import * as tierEligibilityActions from '../actions/feature-config-action';
import { isNil, cloneDeep } from 'lodash';
import * as models from '../services/model/models';
import { ScreenMessages } from '../shared/master-data/messages';
import { TiereligibilityModel } from '../services/model/featureConfigModel';

/**
 * Interface used to maintain the state for the dealer details
 */
export interface TireligibilityState {
  tireligibilityDetails: TierEligibilityView;
}
/**
 * Variable used to store the initial state of Dealer Detail
 */
const initialState: TireligibilityState = {
  tireligibilityDetails: null
};

/**
 * Method used to set the default initial state of featureConfigDetails
 */
function setDefaulValue(): TierEligibilityView {
  let tireligibilityDetails: TierEligibilityView = <TierEligibilityView>new Object();
  tireligibilityDetails.eligibleTiers = new Array;
  return tireligibilityDetails;
}
/**
 * Reducer method to handle the dealer detail actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): TireligibilityState {
  switch (action.type) {
    case tierEligibilityActions.actionTypes.lOAD_TIERELIGIBILITY_SUCCESS: {
      // let featureConfigDetail: featureConfigModel = {};
      let tierEligibilityDetail: TiereligibilityModel = {};
      if (!isNil(action.payload.eligibleTiers)) {
        // featureConfigDetail.isSilverProgramEligible = action.payload.response.trim();
        tierEligibilityDetail = action.payload.eligibleTiers;
        // tierEligibilityDetail = action.payload;
      }
      return {
        tireligibilityDetails: (<any>Object).assign({}, state.tireligibilityDetails,
          {
            eligibleTiers: tierEligibilityDetail,
          })
      };
    }
    case tierEligibilityActions.actionTypes.lOAD_TIERELIGIBILITY_FAIL: {
      // let featureConfigDetail: featureConfigModel = {};
      let featureConfigDetail = false;
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      if (errorResponse === ScreenMessages.SERVICE_FAILURE_MESSAGE ||
        errorResponse === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
        message.message = errorResponse;
        message.type = models.MessageType.DANGER;
        return {
          tireligibilityDetails: (<any>Object).assign({}, state.tireligibilityDetails,
            {
              message: message,
              eligibleTiers: featureConfigDetail
            })
        };
      } else {
        return {
          tireligibilityDetails: (<any>Object).assign({}, state.tireligibilityDetails,
            {
              eligibleTiers: featureConfigDetail
            })
        };
      }
    }

    case tierEligibilityActions.actionTypes.CLEAR_ALL_ACTION: {
      return { tireligibilityDetails: setDefaulValue() };
    }
    default: {
      return state;
    }
  }
}
/* tslint:enable:no-switch-case-fall-through */

/**
 * Method to get the state of dealer details
 */
export function getTiereligibilityDetail(state: TireligibilityState) {
  return state.tireligibilityDetails;
}




