'use strict';
// Import statement required for Vehicle
import * as Models from './models';

// Model for holding Vehicle Informations
export interface Vehicle {
  // To store VIN number
  vin?: string;
  // To store model details
  model?: Models.Model;
  // To store Dealer Code
  dealerCd?: string;
  // To store Dealer Name
  dealerName?: string;
  // To store Exterior Color
  extColor?: Models.Color;
  // To store Interior Color
  intColor?: Models.Color;
  // To store DOFU
  dofu?: string;
  // To store Accessories
  accessories?: Array<Models.AccessoryDetails>;
  // To store Inventory Status
  inventoryStatus?: string;
  // To store Acquired Date
  acquiredDate?: string;
  // To store Acquired Method
  acquiredMethod?: string;
  // To store Mileage
  mileage?: string;
  // To store price
  price?: Models.Price;
  // To store certification details
  certification?: Models.CertificationDetails;
  // To store certification details
  inspection?: Models.Inspection;
  // To store certification details
  repairOrder?: Models.RepairOrder;
  // To store Make Code
  makeCode?: string;
  // To store created at
  createdAt?: string;
  // To store updated at
  updatedAt?: string;
  // To store created by
  createdBy?: string;
  // To store updated by
  updatedBy?: string;
  // To store campaign status
  openCampaignStatus?: string;
  // car fax flag
  carFaxFlag?: string;
  // To store transmission type
  transmissionType?: string;
  // To store trim code
  trimCode?: string;
  // To store certificationFlag
  certificationFlag?: string;
  // To store registrationFlag
  registrationFlag?: string;
  // To store forceCertificationFlag
  forceCertificationFlag?: string;
  // to store the certification number
  certificationNumber?: string;
  // to store the certification status
  certificationStatus?: string;
  // to store decertification reason
  deCertifyReason?: string;
  // to store the foriegn vin status
  foreignVin?: string;
  // To store deCertifyFlag
  deCertifyFlag?: string;
  // To store comments
  comments?: string;
  // To store code
  code?: string;
  // To store desc
  desc?: string;
  // To store comments
  isEligibleVehicle?: string;

  InEligibleFlag?: string;
  // To store isTradedVehicle
  isTradedVehicle?: string;
  // To store decertificationComments
  decertificationComments?: string;
  // To store decertificationReasonCode
  decertificationReasonCode?: string;
  // To store decertificationSourceCode
  decertificationSourceCode?: string;
  // To store unblockComments
  unblockComments?: string;
  // To store unblockComments
  city?: string;
  // To store unblockComments
  state?: string;
  // To store unblockComments
  regionNo?: string;
  // To store unblockComments
  regionName?: string;

  isAllowOpenCampaign?: any;

  isAllowCarfax?: any;

  isAllowNsh?: any;

  sellArea?: string;

  certificationType?: string;
}
