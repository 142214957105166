import {
    Component, Input, OnDestroy, OnInit, ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty, isNil, isUndefined } from 'lodash';
import { IMyDpOptions } from 'mydatepicker';

import { Observable } from 'rxjs';
import * as dealerDetailActions from '../../actions/dealer-detail.action';
import * as messageActions from '../../actions/message-data.action';
import * as messageDataActions from '../../actions/message-data.action';
import * as rdrDetailActions from '../../actions/role-detail.action';
import * as fromRoot from '../../reducers';
import { SharedDataService } from '../../services/common/shared-data.service';
import { SharedFunctionService } from '../../services/common/shared-function.service';
import * as models from '../../services/model/models';
import { DealerDetailView } from '../../services/view-model/dealer-detail-view';
import * as viewModels from '../../services/view-model/view-models';
import { DialogService } from '../../shared/components/dialog/dialog.service';
import { ViewConstants } from '../../shared/shared.constants';
import * as moment from 'moment'

@Component({
    selector: 'uc-role-detail-page',
    templateUrl: 'role-detail-page.component.html'
})

/**
 * This component is used to show the Vehicle Detail screen
 */
export class RoleDetailPageComponent implements OnInit, OnDestroy {
    @Input() vin: string;
    // Route param subscriber to get the VIN to be loaded
    routeParamSubscriber: any;
    // To expand and collapse Expansion Panel
    expand: boolean = true;
    // Route param subscriber to get the vehicle detail to be loaded
    // to store update flag value
    public updateFlag: boolean;
    // to store add flag value
    public addFlag: boolean;
    // to store add & certify flag value
    public addCertifyFlag: boolean;
    // to store router text
    public routerText: string;
    // to store savebutton text
    public saveButtonText: string;
    // to store update button text
    public updateButtonText: string;

    // Observable to obtain VehicleDetailView object
    decertificationData$: Observable<viewModels.RoleDetailView>;
    // Observable to obtain DealerDetailView object
    dealerDetailsData$: Observable<DealerDetailView>;

    // Observable to obtain VehicleDetailView object
    vehicleDetails: viewModels.RoleDetailView = <viewModels.RoleDetailView>new Object();

    // Observable to obtain VehicleDetailView object
    reStorevehicleDetails: any = <any>new Object();

    // To set and get the obtained VehicleDetailView object from reducer using this variable
    public _vehicleDetails: viewModels.VehicleDetailView
        = <viewModels.VehicleDetailView>new Object();
    // to store makeCode
    makeCode: string;

    // to store certified vehcicle check value
    certifiedFlag: string;

    // to store certified vehcicle check value
    forcecertifiedFlag: string;

    warrantExpDateText: string;

    isCertify: boolean;

    isValidPQSS: boolean;

    isValidCarFax: boolean;

    isValidForeignvin: boolean;

    addMode: boolean;

    isForceCertified: boolean;

    roleItems: any;

    roleDetails: Array<any>;

    myDatePickerOptions: IMyDpOptions = {
        dateFormat: 'mm-dd-yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        inline: false,
        openSelectorTopOfInput: false,
        showClearDateBtn: true,
        showInputField: true,
        alignSelectorRight: true,
        showSelectorArrow: false
    };

    isMandatoryForCertify: boolean;

    // Variable to hold the datas displayed in the grid
    public allDealers: models.DealerDetailsModel[];

    displayInvalidVin: boolean;

    displayInvalidDealerCode: boolean;

    showAdditionalDriver: boolean = false;

    isEmailMandatory: boolean = false;

    isCoEmailMandatory: boolean = false;

    isIndividualCustomer: boolean = false;

    actionMode: string;

    reversalFlag: boolean = false;

    // Route param subscriber to add vehicle
    private addVehicleServiceSubscriber: any = null;

    private rdrDetailSubscriber: any;

    // private moment = require('moment');

    /**
     * constructor method to create new instance of VehicleDetailPageComponent
     */
    constructor(private store: Store<fromRoot.AppState>,
        private route: ActivatedRoute,
        private router: Router,
        public sharedDataService: SharedDataService,
        private viewContainerRef: ViewContainerRef,
        private sharedFuncService: SharedFunctionService,
        private dialogsService: DialogService
    ) {
        try {
            this.decertificationData$ = store.select(fromRoot.getRoleDetail);
        } catch (error) {

        } finally {

        }
    }
    /**
     * Init method to initialize state of VehicleDetailPageComponent
     */
    ngOnInit() {
        this.makeCode = '';
        this.onDisableFuture();
        this.onDisablePast();
        this.formatDataforDetailsView();
        this.isMandatoryForCertify = false;
        this.displayInvalidVin = false;
        this.displayInvalidDealerCode = false;
        this.isValidCarFax = true;
        this.isValidForeignvin = true;
        this.isValidPQSS = true;
        this.addFlag = false;
        this.addCertifyFlag = false;
        this.isCertify = true;
        this.certifiedFlag = '';
        this.forcecertifiedFlag = '';
        this.addMode = false;
        let vehData: models.Vehicle = <models.Vehicle>new Object();
        this.sharedFuncService.setCurrentScreenName(ViewConstants.VEHICLE_DETAIL_SCREEN_NAME);
        this.store.dispatch(new rdrDetailActions.ClearAllAction());
        this.store.dispatch(new rdrDetailActions.GetRolesByMakeCodeAction());
        this.routeParamSubscriber = this.route.params.subscribe(params => {
            if (!isNil(params['vin']) && !isUndefined(params['vin'])) {
                let vehicle: models.Vehicle = {};
                vehicle.vin = params['vin'];
                this.actionMode = params['action'];
                vehicle.dealerCd = this.sharedDataService.dealerCode;
                // this.store.dispatch(new rdrDetailActions.RdrInitialLoadAction(vehicle));
                this.vin = params['vin'];
                this.updateFlag = true;
                // this.showAdditionalDriver = true;
                this.routerText = 'Role Details';
                this.saveButtonText = 'UPDATE';
                this.brandAssign(this.makeCode, 'UPDATE', 'ADD NEW');

                vehData.vin = this.vin;
                vehData.dealerCd = params['dlrCd'];
                vehData.dealerCd = vehData.dealerCd.trim();
                if (vehData.dealerCd !== this.sharedDataService.dealerCode) {
                    this.setDealer(vehData.dealerCd);
                }

                if (this.actionMode === 'reversal') {
                    this.reversalFlag = true;
                    this.saveButtonText = 'REVERSE RDR';
                }
                // this.makeCode = this.sharedDataService.brandName;
                this.brandAssign(this.makeCode, '', '');

                // this.store.dispatch(new rdrDetailActions.GetCurrentState());
            } else {
                this.addAction(true, true);
            }
        });

        this.rdrDetailSubscriber = this.decertificationData$.subscribe((data) => {
            if (!isNil(data) && data !== undefined) {
                if (!isNil(data.message)) {
                    this.isMandatoryForCertify = false;
                    this.displayInvalidVin = false;
                    this.displayInvalidDealerCode = false;
                    if (data.message.message === 'Enter a valid VIN') {
                        this.addFlag = false;
                        this.addCertifyFlag = false;
                        this.forcecertifiedFlag = '';
                    }
                    if (data.pageType !== ViewConstants.DELETED) {
                        this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
                            data.message
                        ));
                    }

                    // if (data.pageType == ViewConstants.UPDATED) {
                    //     setTimeout(() => {
                    //         this.router.navigate(['/inventory']);
                    //         window.location.reload();
                    //     }, 2000);
                    // }

                } else if (!isNil(data.Items) && data.Items.length > 0) {
                    this.roleDetails = data.Items;
                } else if (data.pageType === ViewConstants.NO_VIN_VEH_DETAILS &&
                    this.addMode === true) {
                    this.addMode = false;
                    this.isMandatoryForCertify = false;
                    this.displayInvalidVin = false;
                    this.displayInvalidDealerCode = false;
                } else if (data !== undefined && data.pageType !== ViewConstants.FAIL &&
                    data.pageType !== ViewConstants.NO_VIN_VEH_DETAILS &&
                    !isNil(data.vehicleData)) {
                    if (!(data.pageType === ViewConstants.UPDATE && this.updateFlag === false)) {
                        this.vehicleDetails = cloneDeep(data);
                        this.vehicleDetails.vehicleData.isActiveFlag =
                            this.convertStringToBoolean(this.vehicleDetails.vehicleData.isActive);
                    }

                    this.onDisableFuture();
                    this.onDisablePast();
                    this.isValidPQSS = true;
                    this.isValidCarFax = true;
                    this.isValidForeignvin = true;
                }
            } else {
                this.formatDataforDetailsView();
            }
        });
    }

    /**
     * Method to redirect update screen for existing vin
     */
    updateVehicleConfirmed(event: any) {
        event.dialogRef.close(true);
        event.parent.vehicleDetails = cloneDeep(event.parent.reStorevehicleDetails);
        event.parent.updateFlag = true;
        event.parent.routerText = 'Vehicle Details';
        event.parent.saveButtonText = 'UPDATE';
        event.parent.updateButtonText = 'ADD NEW';
        event.parent.isMandatoryForCertify = false;
        event.parent.displayInvalidVin = false;
        event.parent.displayInvalidDealerCode = false;
        if (!isNil(event.parent.vehicleDetails))
            event.parent.vehicleDetails.pageType = '';

        if (!isNil(event.parent.vehicleDetails.vehicleData.certificationStatus)
            && event.parent.vehicleDetails.vehicleData.certificationStatus === 'C') {
            event.parent.certifiedFlag = 'Y';
            event.parent.forcecertifiedFlag = '';
        } else {
            event.parent.certifiedFlag = 'N';
            event.parent.forcecertifiedFlag = 'N';
        }

        if (event.parent.makeCode === 'TOYOTA')
            event.parent.warrantExpDateText = 'POWERTRAIN COVERAGE EXPIRY DATE';
        else if (event.parent.makeCode === 'LEXUS')
            event.parent.warrantExpDateText = 'EXTENSIVE COMPREHENSIVE COVERAGE EXPIRY DATE';
        else
            event.parent.warrantExpDateText = 'WARRANTY EXP DATE';

    }


    setDealer(dealerCodeVal: string) {
        // this.sharedDataService.dealerCode = dealerCodeVal;
        this.store.dispatch(new dealerDetailActions.LoadDealerAction({
            dealerCode: dealerCodeVal
        }));
    }

    addAction(freshPage: boolean, certify: boolean) {
        if (freshPage) {
            this.formatDataforDetailsView();
        }
        this.vin = '';
        this.isMandatoryForCertify = false;
        this.displayInvalidVin = false;
        this.displayInvalidDealerCode = false;
        this.updateFlag = false;
        this.routerText = 'Create RDR';
        this.saveButtonText = 'SUBMIT';

        // if (this.makeCode !== 'TOYOTA') {
        //     this.saveButtonText = 'ADD';
        //     this.updateButtonText = 'ADD & CERTIFY';
        // }
        // else {
        //     this.saveButtonText = 'ADD & CERTIFY';
        //     this.updateButtonText = 'ADD & CERTIFY';
        // }
        if (certify === false) {

            this.addCertifyFlag = false;

        }

    }

    /**
     * To obtain the vehicle details by passing VIN
     */
    getVehicleDetailsbyonblur(role: string) {
        this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
        this.store.dispatch(new rdrDetailActions.ClearAllAction());
        this.store.dispatch(new rdrDetailActions.GetRoleConfigurationAction(role));
    }

    /**
     * On Destory event handler to register the state and filter conditions
     * to store and to unsubscribe the subscriptions
     */
    ngOnDestroy() {
        try {
            if (this.routeParamSubscriber) {
                this.routeParamSubscriber.unsubscribe();
            }
            if (this.rdrDetailSubscriber) {
                this.rdrDetailSubscriber.unsubscribe();
            }
            this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
        } catch (error) {
            // this.logger.error(error);
        }
    }

    savedDetails(event: any) {
        let model: models.Message = {};
        model.message = 'Lorem Ipsum.';
        model.type = models.MessageType.DANGER;
    }

    navigateToInventoryScreen() {
        this.router.navigate(['/inventory/']);
    }

    navigateToVehicleDetailScreenNew(event: any) {
        this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
        if (this.updateFlag === true) {
            // this.addAction(true, true);
            this.router.navigate(['/rdrDetail/']);
        }
    }

    navigateToRdrSummary(event: any) {
        this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
        this.router.navigate(['/rdr/']);
    }

    deCertifyConfirmed(event: any) {
        event.dialogRef.close(true);
        event.parent.saveVehicleEvent(event.parent.reStorevehicleDetails);
        this.sharedDataService.isPageEdited = false;
    }

    validateInputData(vehicleData: models.Role): boolean {
        let isValid: boolean = true;
        if (isNil(vehicleData.role)) {
            isValid = false;
        } else if (isNil(vehicleData.moduleName)) {
            isValid = false;
        } else if (isNil(vehicleData.permission)) {
            isValid = false;
        }

        return isValid;
    }

    saveVehicleEvent(inputData: any) {

        this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
        this.addMode = false;
        let errorMessage;
        let roleConfigurationData: models.Role = cloneDeep(inputData.vehicleData.vehicleData);
        this.displayInvalidVin = false;
        this.displayInvalidDealerCode = false;
        roleConfigurationData.moduleName = inputData.moduleName;
        roleConfigurationData.permission = inputData.permission;
        roleConfigurationData.restrictedModules.forEach(row => {
            if (row.name === inputData.moduleName) {
                row.permission = inputData.permission;
            }
        });

        this.replaceEmptyToNull(roleConfigurationData);

        if (this.validateInputData(roleConfigurationData)) {
            this.sharedDataService.isPageEdited = false;
            let data = this.formatDecertificationData(roleConfigurationData);
            this.store.dispatch(new rdrDetailActions.SaveRoleConfigurationAction(data));
            this.addMode = true;
        }
    }

    replaceEmptyToNull(decertificationData: models.Role) {
        if (!isNil(decertificationData)) {
            if (isNil(decertificationData.role) || isEmpty(decertificationData.role)) {
                decertificationData.role = null;
            }
            if (isNil(decertificationData.isActive) || isEmpty(decertificationData.isActive)) {
                decertificationData.isActive = null;
            }
            if (isNil(decertificationData.permission) || isEmpty(decertificationData.permission)) {
                decertificationData.permission = null;
            }
            if (isNil(decertificationData.restrictedModules) ||
                isEmpty(decertificationData.restrictedModules)) {
                decertificationData.restrictedModules = new Array<string>();
            }
        }

        return decertificationData;
    }

    /**
     * Method used to display error message
     */
    errorMessageDisplay(messageString: string) {
        this.store.dispatch(new messageActions.SetDisplayMessageAction(
            <models.Message>{
                message: messageString,
                type: models.MessageType.DANGER
            }));
    }

    /**
     * Method used to frame the required state for the vehicle detail screen
     */
    formatDataforDetailsView(): viewModels.RoleDetailView {
        // this.vehicleDetails =  new Observable<viewModels.VehicleDetailView>();
        let vehicleDetailData = <viewModels.RoleDetailView>new Object();
        let rdrobj = <models.Role>new Object();
        vehicleDetailData.vehicleData = cloneDeep(rdrobj);
        vehicleDetailData.vehicleData.role = null;
        vehicleDetailData.vehicleData.isActive = null;
        vehicleDetailData.vehicleData.permission = null;
        vehicleDetailData.vehicleData.restrictedModules = new Array<string>();
        vehicleDetailData.vehicleData.isActiveFlag = null;
        this.vehicleDetails = cloneDeep(vehicleDetailData);
        return vehicleDetailData;
    }



    getDealerEvent(dealerCode) {
        this.displayInvalidDealerCode = false;
        if (this.allDealers.filter(e => e.dealerCode === dealerCode).length === 0) {
            this.displayInvalidDealerCode = true;
        }
    }

    brandAssign(brand: string, saveText: string, updateText: string) {
        if (brand === 'TOYOTA') {
            this.warrantExpDateText = 'POWERTRAIN COVERAGE EXPIRY DATE';
        } else if (brand === 'LEXUS') {
            this.warrantExpDateText = 'EXTENSIVE COMPREHENSIVE COVERAGE EXPIRY DATE';
        } else {
            this.warrantExpDateText = 'WARRANTY EXP DATE';
        }
        if (saveText !== '') {
            this.saveButtonText = saveText;
        }
        if (updateText !== '') {
            this.updateButtonText = updateText;
        }

    }

    getCopyOfOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions));
    }

    onDisableFuture() {
        let date = new Date();
        // Disable/enable dates from 5th forward
        date.setMonth(date.getMonth() + 1);
        let copy = this.getCopyOfOptions();
        copy.disableSince = {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate() + 1
        };
        this.myDatePickerOptions = copy;
    }

    onDisablePast() {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 1);
        date.setMonth(date.getMonth() + 1);
        date.setDate(date.getDate() - 1);

        let copy = this.getCopyOfOptions();
        copy.disableUntil = {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate()
        };
        this.myDatePickerOptions = copy;
    }

    updateChangeRedirection() {
        this.updateFlag = true;
        this.routerText = 'RDR Details';
        this.saveButtonText = 'UPDATE';
        this.updateButtonText = 'ADD NEW';
        this.vehicleDetails.pageType = '';
    }

    formatDecertificationData(decertification: models.Role) {
        let decertificationData = <models.Role>new Object();
        decertificationData.role = decertification.role;
        decertificationData.permission = decertification.permission;
        decertificationData.isActive = this.convertBooleanToString(decertification.isActiveFlag);
        decertificationData.restrictedModules = decertification.restrictedModules;
        decertificationData.makeCode = this.sharedDataService.brandName;
        decertificationData.createdBy = this.sharedDataService.userName;
        decertificationData.createdAt = moment(new Date().toString()).utc().toISOString();
        decertificationData.updatedBy = this.sharedDataService.userName;
        decertificationData.updatedAt = moment(new Date().toString()).utc().toISOString();
        return decertificationData;
    }

    convertBooleanToString(value: boolean): string {
        if (value === true) {
            return 'true';
        } else {
            return 'false';
        }
    }

    convertStringToBoolean(value: string): boolean {
        if (value === 'true') {
            return true;
        } else {
            return false;
        }
    }
}
