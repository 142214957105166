import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCTRACRetailSalesSummaryReportRoutingModule }
  from './trac-vehicles-retail-sales-summary-report-routing.module';
import { UCTRACRetailSalesSummaryPageReportComponent }
  from './trac-vehicles-retail-sales-summary-report-page.component';
import { UCTRACRetailSalesSummaryGridComponent }
  from './trac-vehicles-retail-sales-summary-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCTRACRetailSalesSummaryReportRoutingModule
  ],
  exports: [
    UCTRACRetailSalesSummaryPageReportComponent
  ],
  declarations: [
    UCTRACRetailSalesSummaryPageReportComponent,
    UCTRACRetailSalesSummaryGridComponent
  ]
})

export class UCTRACRetailSalesSummaryReportModule {
}
