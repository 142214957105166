import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { UCInventoryPageComponent }
//     from './features/uc-vehicle-summary/inventory-summary-page.component';
// import { UCRdrPageComponent } from './features/uc-rdr-summary/rdr-summary-page.component';
// import { UCSalesPageComponent } from './features/uc-sales-summary/sales-summary-page.component';
// import { VehicleDetailPageComponent }
//     from './features/uc-vehicle-detail/vehicle-detail-page.component';
import { UnauthorizedComponent } from './features/unauthorized/unauthorized.component';
import { AuthenticationGuard } from './services/common/auth-guard';
// import { RDRDetailPageComponent } from './features/uc-rdr-detail/rdr-detail-page.component';

const ucAppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/inventory',
    pathMatch: 'full',
   // canActivate: [AuthenticationGuard]
  },
  {
    path: 'inventory',
    // component: UCInventoryPageComponent,
    canActivate: [AuthenticationGuard],
    //loadChildren: './features/uc-vehicle-summary/inventory-summary.module#UCInventoryModule',
    loadChildren: () => import('./features/uc-vehicle-summary/inventory-summary.module').then(m => m.UCInventoryModule)
  },
  {
    path: 'rdr',
    // component: UCRdrPageComponent,
    //loadChildren: './features/uc-rdr-summary/rdr-summary.module#UCRdrModule',
    loadChildren: ()=> import('./features/uc-rdr-summary/rdr-summary.module').then(m => m.UCRdrModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'sales',
    loadChildren: ()=> import('./features/uc-sales-summary/sales-summary.module').then(m=> m.UCSalesModule),
    // component: UCSalesPageComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'vehicle',
    loadChildren: ()=> import('./features/uc-vehicle-detail/vehicle-detail.module').then(m=> m.VehicleDetailModule),
    // component: VehicleDetailPageComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'rdrDetail',
    loadChildren: ()=> import('./features/uc-rdr-detail/rdr-detail.module').then(m=> m.RDRDetailModule),
    // component: RDRDetailPageComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'salesTrend',
    // component: UCRdrPageComponent,
    loadChildren: ()=> import('./features/uc-sales-trend/sales-trend.module').then(m=> m.UCSalesTrendModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'regionDashboard',
    // component: UCRdrPageComponent,
    loadChildren: ()=> import('./features/uc-region-dashboard/region-dashboard.module').then(m=> m.UCRegionDashboardModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'accounting',
    // component: UCRdrPageComponent,
    loadChildren: ()=> import('./features/uc-accounting-summary/accounting-summary.module').then(m=> m.UCAccountingModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'accountingDetail',
    loadChildren: ()=> import('./features/uc-accounting-detail/accounting-detail.module').then(m=> m.AccountingDetailModule),
    // component: AccountingDetailPageComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ucAppRoutes, {
      preloadingStrategy: PreloadAllModules
    })
  ]
})

export class AppRoutingModule {

}
