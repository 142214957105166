import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCTmexSalesPageReportComponent } from './tmex-sales-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
  {
    path: 'tmexSalesReport',
    component: UCTmexSalesPageReportComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(inventorySummaryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCTmexSalesReportRoutingModule {

}
