import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */


/**
 * Following action types are being used in the inventory summary screen
 */

export const actionTypes = {
  GET_PERSON_ID: type('[GetPersonId] Initiate get person id details'),
  GET_PERSON_ID_SUCCESS: type('[GetPersonId] Initiate get person id success'),
  GET_PERSON_ID_FAIL: type('[GetPersonId] Initiate get person id failure'),
  GET_TOKEN_FOR_PERSON: type('[GetToken] Initiate get token for person details'),
  GET_TOKEN_FOR_PERSON_SUCCESS: type('[GetToken] Initiate get token for person success'),
  GET_TOKEN_FOR_PERSON_FAIL: type('[GetToken] Initiate get token for person failure')
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Method used to get person id details
 */
export class GetPersonIdAction implements ActionBase {
  type = actionTypes.GET_PERSON_ID;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id success
 */
export class GetPersonIdSuccessAction implements ActionBase {
  type = actionTypes.GET_PERSON_ID_SUCCESS;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id failure
 */
export class GetPersonIdFailureAction implements ActionBase {
  type = actionTypes.GET_PERSON_ID_FAIL;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id details
 */
export class GetTokenforPersonIdAction implements ActionBase {
  type = actionTypes.GET_TOKEN_FOR_PERSON;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id success
 */
export class GetTokenforPersonIdSuccessAction implements ActionBase {
  type = actionTypes.GET_TOKEN_FOR_PERSON_SUCCESS;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id failure
 */
export class GetTokenforPersonIdFailureAction implements ActionBase {
  type = actionTypes.GET_TOKEN_FOR_PERSON_FAIL;
  constructor(public payload: any) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Action
  = GetPersonIdAction
  | GetPersonIdSuccessAction
  | GetPersonIdFailureAction
  | GetTokenforPersonIdAction
  | GetTokenforPersonIdSuccessAction
  | GetTokenforPersonIdFailureAction;
