/***
 * Import statements required for Inventory Detail Service
 * for invoking services required for populating
 * all sections in the Vehicle Inventory Detail Screen
 */
import { Injectable } from '@angular/core';
import {  Response, Headers, RequestOptionsArgs, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
// TODO: correctly add the JSON so that it can be read
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedFunctionService } from '../common/shared-function.service';
import { HttpClient } from '../../shared/components/helpers/http-client';
import { ViewConstants } from '../../shared/shared.constants';
import { DealerDetailsJSON } from '../../../mock-data/DealerDetailsJSON';
import { isNil } from 'lodash';
import * as models from '../model/models';
import { environment } from 'src/environments/environment';
import {map, catchError} from 'rxjs/operators'

/***
 * Class that is used to invoke the Services for populating
 * all the sections of Vehicle Inventory Detail screen
 */
@Injectable()
export class UserService {
  public defaultHeaders: Headers = new Headers();
  protected getpersonIdServiceURL = environment.GET_PERSON_ID_SERVICE_URL;
  protected getpersonIdServiceTokenURL = environment.GET_PERSON_ID_TOKEN_SERVICE_URL;

  /***
   * Constructor of Inventory Detail Service to inject the angluar http
   */
  constructor(
    private sharedFuncService: SharedFunctionService, protected httpClient: HttpClient,
    private logger: Logger) { }

  public getUserId(email: string): Observable<any> {
    this.logger.log('Executing Get User Id method');
    const path = this.getpersonIdServiceURL + email + '?api-version=1.6';
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;

    let requestOptions: RequestOptionsArgs = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };
    let MOCK_SERVICE_USED;
    MOCK_SERVICE_USED = false;
    if (MOCK_SERVICE_USED === true) {
      let tempData: any = {};
      tempData = DealerDetailsJSON.dealerServiceResponse;
      return new Observable<any>(observer => {
        observer.next(tempData);
        observer.complete();
      });

    } else {
      return this.httpClient.getForUserId(path, requestOptions)
        .map((response) => {
          // Hiding loader
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }).catch((err: any) => {
          return this.sharedFuncService.handleError(err);
        });
    }
  }

  public getTokenForUserId(login: models.Login): Observable<any> {
    this.logger.log('Executing Get User Id method');
    const path = this.getpersonIdServiceTokenURL;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    console.log('get token service called');
    let requestOptions: RequestOptionsArgs = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };
    let MOCK_SERVICE_USED;
    MOCK_SERVICE_USED = false;
    if (MOCK_SERVICE_USED === true) {
      let tempData: any = {};
      tempData = DealerDetailsJSON.dealerServiceResponse;
      return new Observable<any>(observer => {
        observer.next(tempData);
        observer.complete();
      });

    } else {
      return this.httpClient.postforToken(path, login, requestOptions)
      .pipe(map((response: Response) => {
          console.log('get token service ended ' + response);
          // Hiding loader

            return response;
          
        }), catchError((err: any) => {
          return this.sharedFuncService.handleError(err);
        }));
    }
  }
}
