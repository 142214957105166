import { DealerDetailsModel } from '../app/services/model/DealerDetailsModel';

export class DealerDetailsJSON{
    static dealerDetails : Array<DealerDetailsModel> = [
        {
            dealerCode: '34089',
            dealerName: '34089_NAME',
            address: '34089_ADDRESS',
            city: '34089_CITY',
            state: 'CA',
            zip: '35203',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDWEST',
            districtNo: '19'
        },
        {
            dealerCode: '05064',
            dealerName: '05064_NAME',
            address: '05064_ADDRESS',
            city: '05064_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '04136',
            dealerName: '04136_NAME',
            address: '04136_ADDRESS',
            city: '04136_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '60102',
            dealerName: '60102_NAME',
            address: '60102_ADDRESS',
            city: '60102_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '60428',
            dealerName: '60428_NAME',
            address: '60428_ADDRESS',
            city: '60428_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '60104',
            dealerName: '60104_NAME',
            address: '60104_ADDRESS',
            city: '60104_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '29092',
            dealerName: '29092_NAME',
            address: '29092_ADDRESS',
            city: '29092_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '02031',
            dealerName: '02031_NAME',
            address: '02031_ADDRESS',
            city: '02031_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '62907',
            dealerName: '62907_NAME',
            address: '62907_ADDRESS',
            city: '62907_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '60405',
            dealerName: '60405_NAME',
            address: '60405_ADDRESS',
            city: '60405_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '04713',
            dealerName: '04713_NAME',
            address: '04713_ADDRESS',
            city: '04713_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '34044',
            dealerName: '34044_NAME',
            address: '34044_ADDRESS',
            city: '34044_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        },
        {
            dealerCode: '36082',
            dealerName: '36082_NAME',
            address: '36082_ADDRESS',
            city: '36082_CITY',
            state: 'CA',
            zip: '85705',
            phone: '(256)773-1819',
            brand: 'TOYOTA',
            areaRegionNo: '12345',
            areaRegionName: 'MIDEAST',
            districtNo: '18'
        }
    ]

    static dealerServiceResponse = 
    {
  "applicationArea": {
    "sender": {
      "creatorNameCode": {
        "value": "DIS"
      },
      "senderNameCode": {
        "value": "DIS"
      }
    }
  },
  "systemEnvironmentCode": "Production",
  "languageCode": "EN_US",
  "showDealerLocatorDataArea": {
    "dealerLocator": [
      {
        "dealerLocatorDetail": [
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "34089"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "34089_NAME"
                },
                "districtID": {
                  "value": "06"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "5207955565"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_toll_free"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_toll_free"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "5207955565"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_toll_free"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "700 West Wetmore Road"
                      },
                      "cityName": {
                        "value": "Tucson"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85705"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "When we say we're you best decision for anything automotive, we mean it. Precision Toyota of Tucson has served Tucson and its surrounding communities for over 60 years. Owned and operated by the same family since 1954, we take great pride in being a productive and vital part of our beautiful city.\n\nBut we're not only a vibrant presence in Southern Arizona. We're recognized as one of the nation's best Toyota dealerships, having earned Toyota's coveted \"President's Award\" for many consecutive years. This decoration goes to dealers who display high standards for customer satisfaction in all facets of the business, from service to parts to sales. What's more, we're one of only a handful of dealerships nationwide to have received all seven possible Toyota Recognition Awards.\n\nAs our awards and decades in the community show, our #1 goal is to leave customers completely satisfied. It's what makes Precision your best decision when buying or servicing any new or pre-owned vehicle. We look forward to serving you and, if we're so fortunate, exceeding your expectations and welcoming you to the Precision family.\n",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Award-winning Sales and Service",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Tucson's only Certified Collision Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "No-hassle, market-based pricing on all vehicles",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "#1 Volume Dealership in Tucson",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.pretoy.com"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02010@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02010@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.pretoy.com/finance/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8447392720"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Precision Toyota's award-winning Service Center performs some of the best automotive care available anywhere. Whether you need routine service, larger bodywork, or a single part to install yourself, our expert team is eager to help. And with one of the largest auto service facilities in the state, we perform our factory-certified maintenance and body repair work right here in-house.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "One of the largest Toyota Service Centers in the country",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Complimentary shuttle service and available rental cars",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Comfortable lounge with complimentary coffee and pastries, plus a Quiet Lounge",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Winner of multiple Toyota awards for customer satisfaction and customer service",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Tucson's only Certified Collision Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.pretoy.com/service/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.pretoy.com/service/toyota-tire-center/"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "gstewart@pretoy.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "sobray@pretoy.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Body Shop"
                    },
                    "typeCode": {
                      "value": "Body Shop"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "5203190290"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "ktaggart@pretoy.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.pretoy.com/used-vehicles/certified-pre-owned-vehicles/"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Precision Toyota is Tucson's highest-volume auto dealer and one of Southern Arizona's longest-operating dealerships. With over 800 vehicles in-stock and on-site, we're proud to be a premier source for all things Toyota and Scion, welcoming customers from Tucson, Oro Valley, Marana, Green Valley, Sierra Vista, and beyond.\n\nWe have a vast selection of New Toyota and Scion cars, trucks, and SUVs, plus a wide variety of reconditioned Used Vehicles, including many Toyota Certified Used Vehicles. Our friendly, no-pressure team is here to help you browse our inventory and answer any questions. We can't wait to serve you!",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Sales Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Friendly product specialists",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Sales Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "No-hassle, market-based pricing",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Sales Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Over 800 vehicles in-stock every day",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Sales Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Consecutive winner of Toyota 'President's Award' for customer satisfaction",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.pretoy.com/ad/"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.pretoy.com/new-vehicles/"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "sales@pretoy.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "700 West Wetmore Road"
                  },
                  "cityName": {
                    "value": "Tucson"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85705"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 32.286978,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -110.984209,
                  "unitCode": "DEGREE"
                }
              }
            },
            "dealerLogoURI": "/images/02010/02010_Logo.bmp",
            "dealerLocationImageURI": "/images/02010/02010_Photo.bmp",
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Body",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday"
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 510,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "05064"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "05064_NAME"
                },
                "districtID": {
                  "value": "01"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4808986000"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "4808986758"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_toll_free"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_toll_free"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4808986000"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_toll_free"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "1250 S. Gilbert Road"
                      },
                      "cityName": {
                        "value": "Chandler"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85286"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Big Two Toyota welcomes you to the buyatoyota.com website. Big Two has been servicing the Phoenix valley area for over 70 years, providing our customers with outstanding levels of satisfaction in sales and service. Winner of Toyota's most prestigious honor awarded to a dealer: THE TOYOTA'S PRESIDENT AWARD, demonstrates our commitment in delivering you the highest degree of service available anywhere. Whether it is through sales, service, or collision repair, our goal is clear, to bring you the best!",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Toyota's Presidents Award 13 years",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Toyota's Service Excellence Award 15 years",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Toyota's Parts Excellence Award 19 years",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.bigtwotoyota.com"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02016@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02016@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.bigtwotoyota.com/2.0/finance/index.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4808986010"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "You know you'll be in good hands when you choose to get your vehicle serviced and repaired at our service department.  The Certified Toyota Technicians at our repair center will quickly diagnose or service your vehicle with leading-edge diagnostic equipment and get you back on the road in no time.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.bigtwotoyota.com/2.0/service/appointments.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=E5EEFC7E-03DB-8D5A-B8CF4BA352DFA864"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "chris.henkel@bigtwo.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4808986020"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "anthony.olivas@bigtwo.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Body Shop"
                    },
                    "typeCode": {
                      "value": "Body Shop"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4808986031"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "bodyshop@bigtwo.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.bigtwotoyota.com/certified-inventory/index.htm"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4808986045"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {}
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.bigtwotoyota.com/specials/new.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.bigtwotoyota.com/2.0/sales/index.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "brett.henkel@bigtwo.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "1250 S. Gilbert Road"
                  },
                  "cityName": {
                    "value": "Chandler"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85286"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 33.287882,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -111.790917,
                  "unitCode": "DEGREE"
                }
              }
            },
            "dealerLogoURI": "/images/02016/02016_Logo.JPG",
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 960,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 960,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Body",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday"
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "04136"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "04136_NAME"
                },
                "districtID": {
                  "value": "06"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "5202968535"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "5202907200"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "5202968535"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "5202907200"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "7150 East 22nd Street"
                      },
                      "cityName": {
                        "value": "Tucson"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85710"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Welcome to family owned, Desert Toyota of Tucson! Part of the Tucson community since 1972. \nWe offer our customers an excellent selection of new and Pre-owned vehicles at volume discount prices, while continuing to exceed their expectations and overall satisfaction. At Desert Toyota of Tucson, we encourage you to personally visit our dealership and see for yourself why more people in Tucson prefer the \"Desert Deal\".",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.deserttoyota.com/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02021@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02021@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.deserttoyota.com/financing/index.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8883186464"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8883186464"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "At Desert Toyota of Tucson we offer a wide range of car repairs and service updates. These updates include general repairs and maintenance, Scion, and Toyota factory work and also warranty service on all Scion, and Toyota models, including Scion tC, xB, and Toyota 4Runner. Whether you need an oil change, transmission service, brake pads, or even engine repair, Desert Toyota of Tucson has you covered. If you are in the Tucson, Casas Adobes, Catalina Foothills, Oro Valley, or Green Valley area. One of our friendly and experienced service specialists is eagerly waiting to help you.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Parts Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.deserttoyota.com/schedule-service.html"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=4F4240F0-AC7A-A800-7903072C8A5E884C"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8883186464"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8883186464"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "5202968535"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.deserttoyota.com/certified-inventory/index.htm"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8882834010"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {
                          "value": "5202968535"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8882834010"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.deserttoyota.com/specials/index.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.deserttoyota.com/new-inventory/index.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "7150 East 22nd Street"
                  },
                  "cityName": {
                    "value": "Tucson"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85710"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 32.205846,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -110.839681,
                  "unitCode": "DEGREE"
                }
              }
            },
            "dealerLogoURI": "/images/02021/02021_Logo.JPG",
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Body",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday"
                  },
                  {
                    "dayOfWeekCode": "Tuesday"
                  },
                  {
                    "dayOfWeekCode": "Wednesday"
                  },
                  {
                    "dayOfWeekCode": "Thursday"
                  },
                  {
                    "dayOfWeekCode": "Friday"
                  },
                  {
                    "dayOfWeekCode": "Saturday"
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "60102"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "60102_NAME"
                },
                "districtID": {
                  "value": "01"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6238763400"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "6238763408"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_toll_free"
                        },
                        "completeNumber": {
                          "value": "8886860368"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6238763400"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "6238763408"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "8633 West Bell Road"
                      },
                      "cityName": {
                        "value": "Peoria"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85382"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Larry H. Miller Toyota Peoria is proud to serve the Peoria, Scottsdale, Tempe, Avondale and Phoenix area with quality vehicles. With models like the Camry, Corolla, Tacoma, Yaris, Venza, Tundra, RAV4 and Highlander, we have something for every taste and need. Come visit us to see our vehicles and take one for a test drive.\n\nIf you're looking for used cars in the Phoenix area, we can assist you there as well. Visit our used car inventory page to see our large collection of pre-owned vehicles.\n\nLarry H. Miller Toyota Peoria is not just a Peoria, AZ, Toyota dealership, we also provide a full line of services dedicated to our Arizona customers. From Toyota auto repair performed by expert mechanics, to OEM Toyota auto parts, to car loans, Larry H. Miller Toyota Peoria is the smart choice for your automotive needs.\n\nCome in and say hi! We're located at 8425 West Bell Road, Peoria, AZ 85382 or you can call us 866-748-4281.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Fleet Pricing Upfront!",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We treat you like family!",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "No hassle",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.larrymillertoyota.com/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02031@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02031@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.larrymillertoyota.com/financing/index.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6238763525"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6238763525"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "You know you'll be in good hands when you choose to get your vehicle serviced and repaired at our service department.  The Certified Toyota Technicians at our repair center will quickly diagnose or service your vehicle with leading-edge diagnostic equipment and get you back on the road in no time.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Express Lube",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Parts Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.larrymillertoyota.com/schedule-service-online.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=E41156EE-FF41-A6C5-87FC4DE7609089F8"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "rdepke@lhm.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6238763500"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6238763500"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "msingleton@lhm.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6238763542"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.larrymillertoyota.com/certified-inventory/index.htm"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6238763486"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6238763486"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.larrymillertoyota.com/featured-vehicles/new.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "https://www.larrymillertoyota.com/new-inventory/index.htm?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset=InventoryListing?reset"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "mhadley@lhm.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "8633 West Bell Road"
                  },
                  "cityName": {
                    "value": "Peoria"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85382"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 33.637577,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -112.244165,
                  "unitCode": "DEGREE"
                }
              }
            },
            "dealerLogoURI": "/images/02031/02031_Logo.jpg",
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "60428"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "60428_NAME"
                },
                "districtID": {
                  "value": "01"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6028630600"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "6025476818"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_toll_free"
                        },
                        "completeNumber": {
                          "value": "8883972221"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6028630600"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "6025476818"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "2020 West Bell Road"
                      },
                      "cityName": {
                        "value": "Phoenix"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85023"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Welcome to Bell Road Toyota. We are a family owned business that has been at the same location for 28 years. Our first priority is taking care of our customers with our award winning sales, service and parts staff. For your convenience we offer a on-site body shop that repairs all makes & models. We look forward to servicing all your automotive needs!",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Complimentary schuttle's for our service customers",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Childrens Play Area",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Free Coffee and snacks",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.bellroadtoyota.com/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02035@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02035@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.bellroadtoyota.com/get-approved/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6029930963"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6029930963"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "You know you'll be in good hands when you choose to get your vehicle serviced and repaired at our service department.  The Certified Toyota Technicians at our repair center will quickly diagnose or service your vehicle with leading-edge diagnostic equipment and get you back on the road in no time.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.bellroadtoyota.com/schedule-service/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=85F3E33D-9DFB-4CEF-B746B550251DF775"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "service@bellroadtoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6028631971"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6028631971"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "parts@bellroadtoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Body Shop"
                    },
                    "typeCode": {
                      "value": "Body Shop"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6028630600"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6028630600"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "parts@bellroadtoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6025476816"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.bellroadtoyota.com/certified-pre-owned-program/"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6022420912"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6028630600"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.bellroadtoyota.com/specials/"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.bellroadtoyota.com/inventory/new/"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "webleads@bellroadtoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "2020 West Bell Road"
                  },
                  "cityName": {
                    "value": "Phoenix"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85023"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 33.640536,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -112.103266,
                  "unitCode": "DEGREE"
                }
              }
            },
            "dealerLogoURI": "/images/02035/02035_Logo.GIF",
            "dealerLocationImageURI": "/images/02035/02035_Photo.jpg",
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Body",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday"
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "60104"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "60104_NAME"
                },
                "districtID": {
                  "value": "01"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6022642841"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_toll_free"
                        },
                        "completeNumber": {
                          "value": "8887428576"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6022642841"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "6022419066"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "1550 East Camelback Road"
                      },
                      "cityName": {
                        "value": "Phoenix"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85014"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "As a highly regarded Phoenix Toyota dealer, we pride ourselves on providing our customers with a large and varied selection of new and used cars. Whether you're looking for your next family sedan, van or SUV, Camelback Toyota offers a wide-ranging assortment of vehicles - no matter your budget. Browse our collection of new Toyota cars for sale in Phoenix to find some of the most popular Toyota models on the market today, including the 2016 Toyota Highlander and the 2016 Toyota Avalon. Also, be sure to check out the many Toyota financing options on several of our new car models.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Large Selection of New Toyota's",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Commitment to Customer Service and Satisfaction",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.camelbacktoyota.com/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02036@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02036@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.camelbacktoyota.com/financing/prequalification.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6022662611"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6022662611"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "You know you'll be in good hands when you choose to get your vehicle serviced and repaired at our service department.  The Certified Toyota Technicians at our repair center will quickly diagnose or service your vehicle with leading-edge diagnostic equipment and get you back on the road in no time.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Express Lube",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Parts Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.camelbacktoyota.com/service/appointment-form.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=92068CA7-B031-FDAD-2C0A65C39C3CABDE"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6022667377"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6022667377"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Body Shop"
                    },
                    "typeCode": {
                      "value": "Body Shop"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6022660197"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6022660197"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6022005580"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.camelbacktoyota.com/certified-inventory/index.htm"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6022308084"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6022308084"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.camelbacktoyota.com/featured-vehicles/new.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "https://www.camelbacktoyota.com/new-inventory/index.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "SALES@CAMELBACKTOYOTA.COM"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "1550 East Camelback Road"
                  },
                  "cityName": {
                    "value": "Phoenix"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85014"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 33.510145,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -112.047977,
                  "unitCode": "DEGREE"
                }
              }
            },
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 960,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 240,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Body",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 780,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "29092"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "29092_NAME"
                },
                "districtID": {
                  "value": "06"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9283441170"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "9283443944"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "9283441170"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "9283443944"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "889 E. 32nd Street"
                      },
                      "cityName": {
                        "value": "Yuma"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85365"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We have been in business here for over 40 years. Alexander Toyota loves the Yuma area, and there is nowhere else we would rather be. Let us serve you with a wonderful car buying experience. We have partnered with the Toyota brand to provide the Yuma area with some of the greatest vehicles ever made. We want you to find everything that you need right here and we will always serve you with a friendly attitude. We are a family owned facility, and we take pride in that fact. Everyone here is a part of a big family. We are your friends and neighbors, and we are able to supply you with the cars and trucks that you want and need. Let us work with you and we know you will not be disappointed.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Brand New State of the Art Facility",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Largest Inventory in Yuma County",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Toyota Express Maintenace",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "https://www.myalexandertoyota.com/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02037@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02037@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.myalexandertoyota.com/car-loans-in-yuma-az"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8882849392"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8882849392"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "You know you'll be in good hands when you choose to get your vehicle serviced and repaired at our service department.  The Certified Toyota Technicians at our repair center will quickly diagnose or service your vehicle with leading-edge diagnostic equipment and get you back on the road in no time.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Express Lube",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Parts Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.myalexandertoyota.com/schedule-car-maintenance-or-auto-repair-yuma-az"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=308EC589-DEC4-B9AC-3535E97301E24C20"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "dlong@myalexandertoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8887900229"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8887900229"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "ggarcia@myalexandertoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Body Shop"
                    },
                    "typeCode": {
                      "value": "Body Shop"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8887900229"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8887900229"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "ggarcia@myalexandertoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9283369539"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.myalexandertoyota.com/certified-used-cars-yuma-az"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8887306897"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {
                          "value": "9283441170"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8887306897"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.myalexandertoyota.com/new-car-incentives-and-rebates-yuma-az"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.myalexandertoyota.com/new-toyota-yuma-az"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "bwofford@myalexandertoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "889 E. 32nd Street"
                  },
                  "cityName": {
                    "value": "Yuma"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85365"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 32.668468,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -114.609974,
                  "unitCode": "DEGREE"
                }
              }
            },
            "dealerLogoURI": "/images/02037/02037_Logo.jpg",
            "dealerLocationImageURI": "/images/02037/02037_Photo.jpg",
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 960,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 900,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 390,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 900,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 960,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "02031"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "02031_NAME"
                },
                "districtID": {
                  "value": "06"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "9285375894"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "9285375894"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "1051 North Automall Parkway"
                      },
                      "cityName": {
                        "value": "Show Low"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85901"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Chaz Hatch third generation owner of Hatch Toyota wants your business.\n\nWe are commited to our customers and Community.\n\nWe wont lose a car deal over a dollar. Period.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We won't lose a deal over a dollar",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Brand NEW facility",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Service all makes and models",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.hatchtoyota.com"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02038@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02038@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.hatchtoyota.com/finance-department/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "You know you'll be in good hands when you choose to get your vehicle serviced and repaired at our service department.  The Certified Toyota Technicians at our repair center will quickly diagnose or service your vehicle with leading-edge diagnostic equipment and get you back on the road in no time.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Express Lube",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Parts Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.hatchtoyota.com/service-appointment/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=7C051C5D-9B33-F1E8-4C80AFD68F8D4E7F"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "cstearns@hatchtoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "tnelson@hatchtoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Body Shop"
                    },
                    "typeCode": {
                      "value": "Body Shop"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "cstearns@hatchtoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.hatchtoyota.com/search/used/tp/s:pr0/"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "9285375755"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.hatchtoyota.com/special-offers/"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.hatchtoyota.com/search/new/tp/s:pr0/"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "sales@hatchtoyota.dealerspace.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "1051 North Automall Parkway"
                  },
                  "cityName": {
                    "value": "Show Low"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85901"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 34.25896,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -110.01379,
                  "unitCode": "DEGREE"
                }
              }
            },
            "dealerLogoURI": "/images/02038/02038_Logo.jpg",
            "dealerLocationImageURI": "/images/02038/02038_Photo.jpg",
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "62907"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "62907_NAME"
                },
                "districtID": {
                  "value": "01"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4804203425"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "4805982440"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_toll_free"
                        },
                        "completeNumber": {
                          "value": "8888301243"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4804203425"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "4805982440"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "7970 South Autoplex Loop"
                      },
                      "cityName": {
                        "value": "Tempe"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85284"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "AutoNation Toyota Tempe has served thousands of satisfied Toyota customer's over the last 11 years. ",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Located at I10 & Elliot Road",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.autonationtoyotatempe.com"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02042@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02042@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4805982400"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4805982400"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "AutoNation Toyota Tempe is the Toyota dealership near you committed to making every client experience a positive experience worth remembering. AutoNation Toyota Tempe prioritizes your needs first and offers leasing, financing, purchasing, and maintenance services for your Toyota vehicle. The new or used Toyota in Tempe, AZ that you've been looking for is waiting for you on our dealership lots. AutoNation Toyota Tempe is located conveniently near Tempe, Phoenix, Mesa, Gilbert, and Chandler, making it the local Toyota dealership to turn to. ",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Parts Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.autonationtoyotatempe.com/ServiceApptForm.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=6B407F20-B37B-1095-EB4049341F29C52D"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "polichg@autonation.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4805982411"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4805982411"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "burroughsg@autonation.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4805982423"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4805982441"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4805982441"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "musarrac@autonation.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "7970 South Autoplex Loop"
                  },
                  "cityName": {
                    "value": "Tempe"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85284"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 33.344483,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -111.970289,
                  "unitCode": "DEGREE"
                }
              }
            },
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "60405"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "60405_NAME"
                },
                "districtID": {
                  "value": "01"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4807782200"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Phone800"
                        },
                        "completeNumber": {
                          "value": "8447414267"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "4807782340"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_toll_free"
                        },
                        "completeNumber": {
                          "value": "8448344169"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4807782200"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "4807782340"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "7701 E Frank Lloyd Wright"
                      },
                      "cityName": {
                        "value": "Scottsdale"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85260"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Right Toyota , a Toyota dealer in Scottsdale, Arizona was built with one mission - Provide amenities to guarantee customer satisfaction before, during, and after the sale of our great Toyota cars, trucks, and SUVS. Our Scottsdale Toyota dealership is one of the premier Toyota dealers in Scottsdale and in the state of Arizona. Our commitment to customer service is second to none. We offer one of the most comprehensive Toyota parts and Toyota service departments in Scottsdale, Arizona. Right Toyota provides Scottsdale, Arizona with an excellent Toyota Parts Team. Please be sure to visit us at 7701 E Frank Lloyd Wright Blvd.\n",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Righttoyota.com",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "fleet@righttoyota.com",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Internet/Fleet Department (480) 778-2290",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.righttoyota.com"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02044@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02044@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4807782293"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4807782293"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.righttoyota.com/finance/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4807782299"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4807782299"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Express Lube",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Parts Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.righttoyota.com/schedule-service/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=F7F859B8-D90C-1B56-AA2EDDA14AFB8B8C"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "jjones@righttoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4807782294"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4807782294"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "parts@righttoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Body Shop"
                    },
                    "typeCode": {
                      "value": "Body Shop"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4807782299"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "4807782299"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "jjones@righttoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "4807782216"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.righttoyota.com/inventory/?condition=certified"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8447414267"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {
                          "value": "8448344169"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8447414267"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.righttoyota.com/inventory/New-Specials/"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.righttoyota.com/inventory/new/"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "webleads@righttoyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "7701 E Frank Lloyd Wright"
                  },
                  "cityName": {
                    "value": "Scottsdale"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85260"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 33.634912,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -111.914062,
                  "unitCode": "DEGREE"
                }
              }
            },
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 960,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 960,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 540,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1200,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "04713"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "04713_NAME"
                },
                "districtID": {
                  "value": "03"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9287642886"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "9287642936"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "9287642886"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "9287642936"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "6510 Showplace Avenue"
                      },
                      "cityName": {
                        "value": "Lake Havasu City"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "86404"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Welcome to Anderson Toyota Scion and the Anderson Family. Anderson Toyota Scion has relocated in beautiful Lake Havasu City, Arizona in May 2009. Anderson Toyota Scion features a sprawling lot filled with a great selection of almost every new Toyota and Scion vehicle in production, along with an unbelievable selection of pre-owned vehicles also. Our service and parts department features 24 service bays including 2 Express Lube bays to accomodate all your needs. We here at Anderson's strive to meet all your expectations in every aspect of your vehicle purchase and future service needs.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Gorgeous New Dealership",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "State-of-the-art Service Department",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "12 Trained Sales Representatives ready to serve you",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.andersontoyotaaz.com"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02048@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02048@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.andersontoyotaaz.com/financing/index.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9287642886"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "9287642886"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "You know you'll be in good hands when you choose to get your vehicle serviced and repaired at our service department.  The Certified Toyota Technicians at our repair center will quickly diagnose or service your vehicle with leading-edge diagnostic equipment and get you back on the road in no time.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Express Lube",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Parts Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.andersontoyotaaz.com/service-page.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=54BFEB7D-A590-A034-9471D17373DDCBF1"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "cstrang@andersonauto.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9287642886"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "9287642886"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "cstrang@andersonauto.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Body Shop"
                    },
                    "typeCode": {
                      "value": "Body Shop"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "cstrang@andersonauto.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "9287642886"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.andersontoyotaaz.com/toyotacertifiedusedvehicles.htm"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8888242489"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {
                          "value": "9287642886"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8888242489"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.andersontoyotaaz.com/featured-vehicles/new.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.andersontoyotaaz.com/new-inventory/index.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "leads@andersonts.motosnap.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "6510 Showplace Avenue"
                  },
                  "cityName": {
                    "value": "Lake Havasu City"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "86404"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 34.573941,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -114.367423,
                  "unitCode": "DEGREE"
                }
              }
            },
            "dealerLogoURI": "/images/02048/02048_Logo.JPG",
            "dealerLocationImageURI": "/images/02048/02048_Photo.JPG",
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 960,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1110,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 450,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 960,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "34044"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "34044_NAME"
                },
                "districtID": {
                  "value": "06"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "5204588880"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "5204592303"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "5204588880"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "5204592303"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "2596 Fry Boulevard"
                      },
                      "cityName": {
                        "value": "Sierra Vista"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85635"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Welcome to the Sierra Toyota site!  Our commitment for great prices in SALES and SERVICE, while maintaining professionalism has ranked us #1 by Toyota customer surveys in  Total Satisfaction in both our SALES and SERVICE departments.  Our slogan,\"the feel good block\", means when you leave our store, you will feel good about the entire car buying experience. Give us a call, or stop in for a visit.  Our brand new state of the art dealership is a short drive from Tucson. Or visit our web site:  www.sierravistatoyota.com where our specials are listed!\n",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Service Dept Voted Best of the Best in Sierra Vista by our community!",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Detail Dept Voted Best of the Best in Siera Vista by our community 6 years running!",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      },
                      {
                        "title": {
                          "value": "Get To Know Us Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "2005 Better Business Bureau Business Ethics Award Winner",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.sierratoyota-az.com"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02049@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02049@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.sierratoyota-az.com/request/finance/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Sierra Toyota's Service Excellence department has a staff of certified technicians and leading-edge diagnostic equipment to ensure the reliability and longevity of every Toyota vehicle. \"Service with Care\"",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      },
                      {
                        "title": {
                          "value": "Service Bullet Points",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Parts Center",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Detail"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.sierratoyota-az.com/request/service-appointment/"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=B0BB3526-0148-9A87-3F1A14F596F098C8"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      },
                      {
                        "uriid": {
                          "value": "rob_shepherd@sierratoy.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "john_hocker@sierratoy.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "8883408696"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {
                          "value": "5204588880"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "8883408696"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.sierratoyota-az.com/incentives/"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.sierratoyota-az.com/vehicle/search/new/"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      },
                      {
                        "uriid": {
                          "value": "ross_leffelman@sierratoy.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "2596 Fry Boulevard"
                  },
                  "cityName": {
                    "value": "Sierra Vista"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85635"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 31.554111,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -110.273568,
                  "unitCode": "DEGREE"
                }
              }
            },
            "dealerLocationImageURI": "/images/02049/02049_Photo.JPG",
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 300,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 840,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1050,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 840,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 480,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1020,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          },
          {
            "lineID": {
              "value": "1"
            },
            "dealerParty": {
              "partyID": {
                "value": "36082"
              },
              "relationshipTypeCode": {
                "value": "Toyota"
              },
              "specifiedOrganization": {
                "companyName": {
                  "value": "36082_NAME"
                },
                "districtID": {
                  "value": "01"
                },
                "divisionCode": {
                  "value": "15",
                  "name": "Denver"
                },
                "primaryContact": [
                  {
                    "departmentName": {
                      "value": "Main Dealer"
                    },
                    "typeCode": {
                      "value": "Main Dealer"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6239367700"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "Fax"
                        },
                        "completeNumber": {
                          "value": "6239367771"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_toll_free"
                        },
                        "completeNumber": {
                          "value": "8888472021"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "bat_fax"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6239367700"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "to_fax"
                        },
                        "completeNumber": {
                          "value": "6239367771"
                        }
                      }
                    ],
                    "postalAddress": {
                      "lineOne": {
                        "value": "10005 West Papago Frwy."
                      },
                      "cityName": {
                        "value": "Avondale"
                      },
                      "countryID": "USA",
                      "postcode": {
                        "value": "85323"
                      },
                      "stateOrProvinceCountrySubDivisionID": {
                        "value": "AZ"
                      },
                      "privacy": {
                        "privacyIndicator": true,
                        "privacyTypeString": "Customer Facing"
                      }
                    },
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Get To Know Us Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "Our clients have high expectations for the cars they drive, and equally high expectations about the dealership professionals who serve them. Toyota vehicles are widely recognized to be among the best in quality, reliability, and value, just as Toyota dealers are well known for their award-winning commitment to customer satisfaction in both sales and service.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.avondaletoyota.com"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "Ecom02050@toyota.com"
                        },
                        "channelCode": {
                          "value": "Email"
                        },
                        "useCode": {
                          "value": "Ecom02050@toyota.com"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Finance"
                    },
                    "typeCode": {
                      "value": "Finance"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.avondaletoyota.com/financing/index.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Service"
                    },
                    "typeCode": {
                      "value": "Service"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6239367700"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6239367700"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Service Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "You know you'll be in good hands when you choose to get your vehicle serviced and repaired at our service department.  The Certified Toyota Technicians at our repair center will quickly diagnose or service your vehicle with leading-edge diagnostic equipment and get you back on the road in no time.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.avondaletoyota.com/schedule-service-appointment.htm"
                        },
                        "channelCode": {
                          "value": "Website"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.oemlr.com/?uid=F63AA48D-A630-8041-FBADA7380A33AC5F"
                        },
                        "channelCode": {
                          "value": "Tire Center"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Parts"
                    },
                    "typeCode": {
                      "value": "Parts"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6239369901"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6239369901"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "TRAC"
                    },
                    "typeCode": {
                      "value": "TRAC"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6239367770"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Certified Used"
                    },
                    "typeCode": {
                      "value": "Certified Used"
                    },
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.avondaletoyota.com/certified-inventory/index.htm"
                        },
                        "channelCode": {
                          "value": "Certified Used"
                        }
                      }
                    ]
                  },
                  {
                    "departmentName": {
                      "value": "Sales"
                    },
                    "typeCode": {
                      "value": "Sales"
                    },
                    "telephoneCommunication": [
                      {
                        "channelCode": {
                          "value": "Phone"
                        },
                        "completeNumber": {
                          "value": "6239367700"
                        }
                      },
                      {
                        "channelCode": {
                          "value": "tcom_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "bat_phone"
                        },
                        "completeNumber": {}
                      },
                      {
                        "channelCode": {
                          "value": "to_phone"
                        },
                        "completeNumber": {
                          "value": "6239367700"
                        }
                      }
                    ],
                    "additionalDetailDescription": [
                      {
                        "title": {
                          "value": "Sales Introduction",
                          "languageID": "1"
                        },
                        "description": [
                          {
                            "value": "We are always focused on delivering the highest quality sales experience to each and every customer.  Whether you're in the market for a new or certified used Toyota, we'll do our best to make your car buying experience a great one.",
                            "languageID": "1"
                          }
                        ],
                        "typeCode": {
                          "value": "Intro"
                        }
                      }
                    ],
                    "uricommunication": [
                      {
                        "uriid": {
                          "value": "http://www.avondaletoyota.com/local-toyota-incentives-in-arizona.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Specials"
                        }
                      },
                      {
                        "uriid": {
                          "value": "http://www.avondaletoyota.com/new-inventory/index.htm"
                        },
                        "channelCode": {
                          "value": "Dealer Inventory"
                        }
                      }
                    ]
                  }
                ],
                "postalAddress": {
                  "lineOne": {
                    "value": "10005 West Papago Frwy."
                  },
                  "cityName": {
                    "value": "Avondale"
                  },
                  "countryID": "USA",
                  "postcode": {
                    "value": "85323"
                  },
                  "stateOrProvinceCountrySubDivisionID": {
                    "value": "AZ"
                  }
                }
              }
            },
            "proximityMeasureGroup": {
              "proximityMeasureType": {
                "value": "Coordinates"
              },
              "geographicalCoordinate": {
                "latitudeMeasure": {
                  "value": 33.45996,
                  "unitCode": "DEGREE"
                },
                "longitudeMeasure": {
                  "value": -112.276184,
                  "unitCode": "DEGREE"
                }
              }
            },
            "hoursOfOperation": [
              {
                "hoursTypeCode": "General",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              },
              {
                "hoursTypeCode": "Service",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Parts",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1080,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday"
                  }
                ]
              },
              {
                "hoursTypeCode": "Sales",
                "daysOfWeek": [
                  {
                    "dayOfWeekCode": "Monday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Tuesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Wednesday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Thursday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Friday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Saturday",
                    "availabilityStartTimeMeasure": {
                      "value": 420,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1260,
                      "unitCode": "MINUTE"
                    }
                  },
                  {
                    "dayOfWeekCode": "Sunday",
                    "availabilityStartTimeMeasure": {
                      "value": 600,
                      "unitCode": "MINUTE"
                    },
                    "availabilityEndTimeMeasure": {
                      "value": 1140,
                      "unitCode": "MINUTE"
                    }
                  }
                ]
              }
            ],
            "makesCarried": [
              {
                "vehicleMake": {
                  "value": "Toyota"
                }
              }
            ]
          }
        ]
      }
    ],
    "dealerMetaData": [
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "34089"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "DealerSocket"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1317020400000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Dealer Inspire"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1433487600000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver"
                      },
                      "companyCode": {
                        "value": "DVR10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG096"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010016178"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "startDate": 1460617200000,
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1448092800000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "currentOwner": {
                "startDate": -117388800000
              }
            },
            "classification": {
              "salesClass": {
                "value": "8",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "05064"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "VinSolutions"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1507791600000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Dealer.com"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley"
                      },
                      "companyCode": {
                        "value": "PHX10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG130"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010219269"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1445929200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "currentOwner": {
                "startDate": -71946000000
              }
            },
            "classification": {
              "salesClass": {
                "value": "8",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "04136"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "DealerSocket"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1318489200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "DealerOn"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1480320000000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver"
                      },
                      "companyCode": {
                        "value": "DVR10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Group"
                },
                "organizationType": {
                  "value": "Dealer Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Berge Group"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG096"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010016178"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "startDate": 1409554800000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1446624000000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "currentOwner": {
                "startDate": 71481600000
              }
            },
            "classification": {
              "salesClass": {
                "value": "6",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "60102"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "DealerSocket"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1318230000000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Dealer.com"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley"
                      },
                      "companyCode": {
                        "value": "PHX10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Group"
                },
                "organizationType": {
                  "value": "Dealer Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Larry H. Miller Group"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG130"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010219269"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "startDate": 1328083200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1446015600000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "currentOwner": {
                "startDate": 338713200000
              }
            },
            "classification": {
              "salesClass": {
                "value": "8",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "60428"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "DealerSocket"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1488355200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Motorwebs"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1488355200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley"
                      },
                      "companyCode": {
                        "value": "PHX10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Group"
                },
                "organizationType": {
                  "value": "Dealer Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Keyes Group"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG130"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010219269"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SMRM Vendor"
                },
                "organizationType": {
                  "value": "SMRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "L2T Media"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1445929200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "currentOwner": {
                "startDate": 479116800000
              }
            },
            "classification": {
              "salesClass": {
                "value": "8",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "60104"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "DealerSocket"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1318316400000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Dealer.com"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley"
                      },
                      "companyCode": {
                        "value": "PHX10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Group"
                },
                "organizationType": {
                  "value": "Dealer Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Berkshire Hathaway Automotive"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG130"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010219269"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1461567600000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "previousOwner": [
                {
                  "party": [
                    {
                      "partyID": {
                        "value": "02036"
                      }
                    }
                  ]
                }
              ],
              "currentOwner": {
                "startDate": 585212400000
              }
            },
            "classification": {
              "salesClass": {
                "value": "8",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "29092"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "DealerSocket"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1317020400000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "DealerFire"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1445929200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver"
                      },
                      "companyCode": {
                        "value": "DVR10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG097"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010016178"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SMRM Vendor"
                },
                "organizationType": {
                  "value": "SMRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Friendemic"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "startDate": 1328083200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1446796800000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "previousOwner": [
                {
                  "party": [
                    {
                      "partyID": {
                        "value": "02030"
                      }
                    }
                  ]
                }
              ],
              "currentOwner": {
                "startDate": 597052800000
              }
            },
            "classification": {
              "salesClass": {
                "value": "4",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "02031"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Promax"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1440140400000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "DealereProcess"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver"
                      },
                      "companyCode": {
                        "value": "DVR10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG091"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010016178"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1445929200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "previousOwner": [
                {
                  "party": [
                    {
                      "partyID": {
                        "value": "02034"
                      }
                    }
                  ]
                }
              ],
              "currentOwner": {
                "startDate": 675414000000
              }
            },
            "classification": {
              "salesClass": {
                "value": "4",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "62907"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "AutoNation"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1317279600000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Dealer.com"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1467183600000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley"
                      },
                      "companyCode": {
                        "value": "PHX10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Group"
                },
                "organizationType": {
                  "value": "Dealer Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "AutoNation, Inc."
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG130"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010219269"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "startDate": 1370070000000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1471244400000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "previousOwner": [
                {
                  "party": [
                    {
                      "partyID": {
                        "value": "02039"
                      }
                    }
                  ]
                }
              ],
              "currentOwner": {
                "startDate": 888825600000
              }
            },
            "classification": {
              "salesClass": {
                "value": "8",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "60405"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "NonLms"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1476169200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Fox Dealer Interactive"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1436425200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley"
                      },
                      "companyCode": {
                        "value": "PHX10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Group"
                },
                "organizationType": {
                  "value": "Dealer Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Dave Wilson Automotive Group"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG130"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010219269"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Phoenix Valley TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1446624000000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "currentOwner": {
                "startDate": 932540400000
              }
            },
            "classification": {
              "salesClass": {
                "value": "8",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "04713"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "VinSolutions"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1317625200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Dealer.com"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1461654000000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver"
                      },
                      "companyCode": {
                        "value": "DVR10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG074"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010016178"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1448352000000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "previousOwner": [
                {
                  "party": [
                    {
                      "partyID": {
                        "value": "02045"
                      }
                    }
                  ]
                }
              ],
              "currentOwner": {
                "startDate": 1007107200000
              }
            },
            "classification": {
              "salesClass": {
                "value": "4",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "34044"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "ADP CRM iLeads"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1465887600000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Dealer.com"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1465369200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver"
                      },
                      "companyCode": {
                        "value": "DVR10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG066"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010016178"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "startDate": 1370070000000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1446188400000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "currentOwner": {
                "startDate": 567244800000
              }
            },
            "classification": {
              "salesClass": {
                "value": "3",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      },
      {
        "dealerMetaDataHeader": {
          "dealerParty": {
            "partyID": {
              "value": "36082"
            },
            "relationshipTypeCode": {
              "value": "Toyota"
            }
          }
        },
        "dealerMetaDataDetail": [
          {
            "organization": [
              {
                "code": {
                  "value": "CRM Vendor"
                },
                "organizationType": {
                  "value": "CRM Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "VinSolutions"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1486713600000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Website Vendor"
                },
                "organizationType": {
                  "value": "Website Vendor"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Dealer.com"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "startDate": 1472713200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Association"
                },
                "organizationType": {
                  "value": "TDA"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver"
                      },
                      "companyCode": {
                        "value": "DVR10"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dealer Pooling Group"
                },
                "organizationType": {
                  "value": "Dealer Pooling Group"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {},
                      "companyCode": {
                        "value": "DPG073"
                      }
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Accounting"
                },
                "organizationType": {
                  "value": "TDA Accounting"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "010016178"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDA Marketing"
                },
                "organizationType": {
                  "value": "TDA Marketing"
                },
                "party": [
                  {
                    "specifiedOrganization": {
                      "companyName": {
                        "value": "Denver TDA"
                      },
                      "companyCode": {}
                    }
                  }
                ],
                "participationIndicator": true
              }
            ],
            "additionalService": [
              {
                "code": {
                  "value": "TTC"
                },
                "name": {
                  "value": "Toyota Tire Center"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "SpanishSpeaking"
                },
                "name": {
                  "value": "Spanish Speaking"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TPP"
                },
                "name": {
                  "value": "Toyota Preferred Partner"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Dual"
                },
                "name": {
                  "value": "Dual"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "PartsCenter"
                },
                "name": {
                  "value": "Parts Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "Autotrader"
                },
                "name": {
                  "value": "Autotrader"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TCCC"
                },
                "name": {
                  "value": "Toyota Certified Collision Center"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceCenterOnly"
                },
                "name": {
                  "value": "Service Center Only"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginSales"
                },
                "name": {
                  "value": "Prius Plug-in Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PriusPluginService"
                },
                "name": {
                  "value": "Prius Plug-in Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVSales"
                },
                "name": {
                  "value": "RAV4 EV Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RAV4EVService"
                },
                "name": {
                  "value": "RAV4 EV Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDADataShare"
                },
                "name": {
                  "value": "TDA Data Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CollisionReplacement"
                },
                "name": {
                  "value": "Collision Replacement"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ServiceReplacement"
                },
                "name": {
                  "value": "Service Replacement"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "PhoneChatShare"
                },
                "name": {
                  "value": "Phone_Chat_Share"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "ExpressMaintenance"
                },
                "name": {
                  "value": "Toyota Express Maintenance"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "24HourDropOffforVehicleService"
                },
                "name": {
                  "value": "24 Hour Drop Off for Vehicle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CourtesyShuttleService"
                },
                "name": {
                  "value": "Courtesy Shuttle Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CertifiedFactoryTrainedTechnicians"
                },
                "name": {
                  "value": "Certified Factory Trained Technicians"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "StateoftheArtDiagnosticandTuneUpEquipment"
                },
                "name": {
                  "value": "State of the Art Diagnostic and Tune Up Equipment"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FullServiceDealership"
                },
                "name": {
                  "value": "Full Service Dealership"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "AutoDetailing"
                },
                "name": {
                  "value": "Auto Detailing"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineAppointmentScheduling"
                },
                "name": {
                  "value": "Online Appointment Scheduling"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "OnlineCustomer-to-DealerCommunication"
                },
                "name": {
                  "value": "Online Customer-to-Dealer Communication"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiService"
                },
                "name": {
                  "value": "Mirai Service"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "MiraiSales"
                },
                "name": {
                  "value": "Mirai Sales"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "FMP"
                },
                "name": {
                  "value": "First Mover Program (FMP)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTI"
                },
                "name": {
                  "value": "Real Time Inventory (RTI)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "eCommerce"
                },
                "name": {
                  "value": "eCommerce"
                },
                "participationIndicator": false
              }
            ],
            "certification": [
              {
                "code": {
                  "value": "CertifiedWebProvider"
                },
                "name": {
                  "value": "Certified Web Provider"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CertifiedCRM"
                },
                "name": {
                  "value": "Certified CRM"
                },
                "certificationIndicator": true
              },
              {
                "code": {
                  "value": "CAB"
                },
                "name": {
                  "value": "President's Cabinet Award"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "BOG"
                },
                "name": {
                  "value": "Board of Governors"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "PresidentsAward"
                },
                "name": {
                  "value": "President's Award for Customer First"
                },
                "certificationIndicator": false
              },
              {
                "code": {
                  "value": "LEEDCertification"
                },
                "name": {
                  "value": "LEED Certified Facility"
                },
                "certificationIndicator": false
              }
            ],
            "program": [
              {
                "code": {
                  "value": "TCUVInventory"
                },
                "name": {
                  "value": "TCUV Inventory"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TCUV"
                },
                "name": {
                  "value": "Toyota Certified Used Vehicles (TCUV)"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "BAT"
                },
                "name": {
                  "value": "BAT"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "DataSharingAgreement"
                },
                "name": {
                  "value": "Data Sharing Agreement"
                },
                "startDate": 1445929200000,
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "TDDS"
                },
                "name": {
                  "value": "TDDS Program Participation"
                },
                "participationIndicator": true
              },
              {
                "code": {
                  "value": "Rentacar"
                },
                "name": {
                  "value": "Rentacar"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RTRAC"
                },
                "name": {
                  "value": "Retail Toyota Rent-a-Car (RTRAC)"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "CommercialFleet"
                },
                "name": {
                  "value": "Commercial Fleet"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "LargeFleet"
                },
                "name": {
                  "value": "Fleet TED Delivery"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TryBeforeYouBuy"
                },
                "name": {
                  "value": "Try Before You Buy"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "RetailRental"
                },
                "name": {
                  "value": "Retail Rental"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "NoTDDS"
                },
                "name": {
                  "value": "No TDDS 3PL"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "TDAShow3PLData"
                },
                "name": {
                  "value": "TDA Show 3PL Data"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "corpChatLeads"
                },
                "name": {
                  "value": "Corporate Chat Leads"
                },
                "participationIndicator": false
              },
              {
                "code": {
                  "value": "crmLeads"
                },
                "name": {
                  "value": "Dealer CRM Leads"
                },
                "participationIndicator": false
              }
            ],
            "ownership": {
              "currentOwner": {
                "startDate": 576140400000
              }
            },
            "classification": {
              "salesClass": {
                "value": "1",
                "languageID": "1"
              },
              "dealerType": {
                "value": "P",
                "languageID": "1"
              }
            }
          }
        ]
      }
    ]
  }
};
}