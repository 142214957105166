import { NgModule } from '@angular/core';
import { UCSameDayCertRoutingModule }
  from './same-day-cert-report-routing.module';
import { UCSameDayCertPageReportComponent }
  from './same-day-cert-report-page';
import { SharedModule } from '../../../shared/shared.module';
import { UCSameDayCertGridComponent } from './same-day-cert-report-grid';

@NgModule({
  imports: [
    SharedModule,
    UCSameDayCertRoutingModule,
  ],
  exports: [
    UCSameDayCertPageReportComponent
    
  ],
  declarations: [
    UCSameDayCertPageReportComponent,
    UCSameDayCertGridComponent
  ]
})

export class UCSameDayCertReportModule {
}


