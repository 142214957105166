import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SharedDataService } from '../../../services/common/shared-data.service';

@Component({
    selector: 'uc-sales-menubar-panel',
    templateUrl: 'sales-menubar-panel.component.html',
    styleUrls: ['./sales-menubar-panel.component.scss']
})

export class UCSalesMenubarPanelComponent {
    @Input() isInventorySelected: boolean;

    @Input() selectedCertificationStatus: string;

    @Input() selectedRDRStatus: string;
    // Event to emit on navigating to vehicle detail screen
    @Output() navigateToSaleDetailEvent = new EventEmitter<number>();
    @Output() navigateToVehicleSummaryEvent = new EventEmitter<number>();
    @Output() navigateToRdrSummaryEvent = new EventEmitter<number>();
    @Output() navigateToSalesSummaryEvent = new EventEmitter<number>();
    @Output() navigateToVehicleDetailEvent = new EventEmitter<number>();
    @Output() navigateToSaleDetailEventNew = new EventEmitter<number>();
    // Event to emit when clear all button is clicked
    @Output() clearAllButtonEvent = new EventEmitter();
    // Boolean variable to show/hide the clear all button
    @Input() showClearAllButton: boolean;
    // Variable to display the total inventory count
    @Input() totalInventoriesCount: Number;

    // Event emitter to delete disclaimer
    @Output() deleteVehicleEvent = new EventEmitter<any>();

    constructor(public sharedDataService: SharedDataService) {

    }

    /**
     * Method to handle the navigation to vehicle detail screen
     */
    navigateToSaleDetailScreen() {
        this.navigateToSaleDetailEvent.emit();
    }

    /**
     * Method to handle the navigation to vehicle detail screen
     */
    navigateToSaleDetailScreenNew() {
        this.navigateToSaleDetailEventNew.emit();
    }
    /**
     * Method to handle when clear all button is clicked
     */
    clearAllButtonClicked(event: any) {
        this.clearAllButtonEvent.emit();
    }

    /**
     * Method to handle the navigation to vehicle detail screen
     */
    navigateToRdrReversalScreen() {
        this.navigateToSaleDetailEvent.emit(2);
    }

    /**
     * Method to emit the delete disclaimer event emitter
     */
    deleteVehicle() {
        this.deleteVehicleEvent.emit();
    }


    navigateToVehicleSummary() {
        this.navigateToVehicleSummaryEvent.emit();
    }

    navigateToRdrSummary() {
        this.navigateToRdrSummaryEvent.emit();
    }

    navigateToSalesSummary() {
        this.navigateToSalesSummaryEvent.emit();
    }

    /**
     * Method to handle the navigation to vehicle detail screen
     */
    navigateToVehicleScreen() {
        this.navigateToVehicleDetailEvent.emit(2);
    }

}
