<form name="rdrDetailForm" fxFlex="100" fxFlex.xs="100" fxLayout="column" autocomplete="off" #rdrDetailForm="ngForm">
    <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" style="height:8%">
        <!--<mat-card fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">-->
        <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
            <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
                <mat-card fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">
                    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                        <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                        </div>

                        <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
                            <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                <div fxFlex="5"></div>
                                <div fxFlex="20" class="dd-veh-summary-header">
                                    <span class="MandatorySymbol"> * </span>VIN
                                </div>

                                <div *ngIf="!updateFlag && actionMode !== 'add'" fxFlex="100" class="dd-veh-summary-value">
                                    <mat-form-field>
                                        <input matInput id="vinno" name="vinno" value="{{_vehicleDetails?.vin}}"
                                            [(ngModel)]="_vehicleDetails.vehicleData.VIN_NUMBER" #vinno="ngModel"
                                            style="text-transform: uppercase;" (blur)="getVehicleDetails($event.target.value)"
                                            minlength="17" [ddFieldValidator]="validationTypes.ALPHANUMERIC_17_WITHOUTSPACE"
                                            class="dd-inputValues" required oninvalid="this.setCustomValidity('Please Enter valid vin')"
                                            oninput="setCustomValidity('')" />
                                    <mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="vinno.value && this.displayMsgForVin"  align="start">VIN cannot be less than 17 digit</mat-hint>
                                    <mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="!vinno.value && (vinno.touched || this.isErrorMessageDisplay)"
                                             align="start">VIN cannot be blank</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="updateFlag || actionMode == 'add'" fxFlex class="dd-veh-summary-value dd-input-veh-valueText">
                                    {{_vehicleDetails?.vehicleData?.vin}}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                    </div>


                </mat-card>
            </div>
        </div>
    </div>

    <div style="height:20rem" fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" layout-md="column">
        <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
            <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
                <mat-card [ngClass]="blockSectionStyle"  fxFlex="50" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">
                    <mat-card-header>
                        <mat-card-title style="font-weight:bold">BLOCK</mat-card-title>
                    </mat-card-header>
                    <mat-card-content style="height:100%">
                        <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                            <div fxFlex="5"></div>
                                            <div fxFlex="35" class="dd-veh-summary-header">
                                                <span class="MandatorySymbol"> * </span>
                                                SOURCE
                                            </div>
                                            <div fxFlex="55" class="dd-veh-summary-value">
                                                <mat-select [ngClass]="blockDropdownStyle" style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.12) !important;max-width:100%;" class="mat-no-underline" 
                                                name="sourceCode" (selectionChange)="valueChanged($event)" [(ngModel)]="_vehicleDetails.vehicleData.DECERTIFICATION_SOURCE_CODE" #sourceCode="ngModel">
                                                    <mat-option [value]="">
                                                        -- select --
                                                    </mat-option>
                                                    <mat-option *ngFor="let i of sourceItems" [value]="i.value">{{i.name}}</mat-option>
                                                </mat-select>
                                                 <mat-error>
                                                    <span class="error-font" *ngIf="!_vehicleDetails.vehicleData.DECERTIFICATION_SOURCE_CODE && (this.isErrorMessageDisplay && _vehicleDetails.vehicleData.InEligibleFlag == 'N')">
                                                Source cannot be blank
                                            </span>
                                                </mat-error>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                            <div fxFlex="5"></div>
                                            <div fxFlex="35" class="dd-veh-summary-header">
                                                <span class="MandatorySymbol"> * </span>
                                                REASON
                                            </div>
                                            <div fxFlex="55" class="dd-veh-summary-value">
                                                <mat-select [ngClass]="blockDropdownStyle" style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.12) !important;max-width:100%;" class="mat-no-underline" 
                                                name="reasonCode" (selectionChange)="valueChanged($event)" [(ngModel)]="_vehicleDetails.vehicleData.DECERTIFICATION_REASON_CODE" #reasonCode="ngModel">
                                                    <mat-option [value]="">
                                                        -- select --
                                                    </mat-option>
                                                    <mat-option *ngFor="let i of reasonItems" [value]="i.value">{{i.name}}</mat-option>
                                                </mat-select>
                                                <mat-error>
                                                    <span class="error-font" *ngIf="!_vehicleDetails.vehicleData.DECERTIFICATION_REASON_CODE && (this.isErrorMessageDisplay && _vehicleDetails.vehicleData.InEligibleFlag == 'N')">
                                                Reason cannot be blank
                                            </span>
                                                </mat-error>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                            <div fxFlex="5"></div>
                                            <div fxFlex="35" class="dd-veh-summary-header">
                                                <span class="MandatorySymbol"> * </span> BLOCK COMMENTS
                                            </div>
                                            <div *ngIf="_vehicleDetails.vehicleData.InEligibleFlag == 'Y'" fxFlex="55" class="dd-veh-summary-value">
                                               <mat-form-field style="max-width:100%;display:block">
                                                    <input [readonly]="true" maxlength="28" matInput name="decertificationComments"
                                                        class="dd-inputValues" (keyup)="this.pageEdited($event);" [(ngModel)]="_vehicleDetails.vehicleData.DECERTIFICATION_COMMENTS" #decertificationComments="ngModel" />
                                                <mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="!decertificationComments.value && (this.isErrorMessageDisplay && _vehicleDetails.vehicleData.InEligibleFlag == 'N')"
                                                         align="start">Comments cannot be blank</mat-hint>
                                                </mat-form-field>
                                            </div>
                                            
                                            <div *ngIf="_vehicleDetails.vehicleData.InEligibleFlag != 'Y'" fxFlex="55" class="dd-veh-summary-value">
                                               <mat-form-field style="max-width:100%;display:block">
                                                    <input [readonly]="false" maxlength="28" matInput name="decertificationComments"
                                                        class="dd-inputValues" (keyup)="this.pageEdited($event);" [(ngModel)]="_vehicleDetails.vehicleData.DECERTIFICATION_COMMENTS" #decertificationComments="ngModel" />
                                                <mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="!decertificationComments.value && (this.isErrorMessageDisplay && _vehicleDetails.vehicleData.InEligibleFlag == 'N')"
                                                         align="start">Comments cannot be blank</mat-hint>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                            <div fxFlex="5"></div>
                                            <div fxFlex="35" class="dd-veh-summary-header">
                                                <span class="MandatorySymbol"> &nbsp;&nbsp; </span> DAMAGE DETAILS
                                            </div>
                                            <div *ngIf="_vehicleDetails.vehicleData.InEligibleFlag == 'Y'" fxFlex="55" class="dd-veh-summary-value">
                                                <mat-form-field style="max-width:100%;display:block">
                                                    <input [readonly]="true" (keyup)="this.pageEdited($event);"  maxlength="7" matInput name="damageAmount"
                                                        class="dd-inputValues" [ddFieldValidator]="validationTypes.NUMBER" 
                                                        [(ngModel)]="_vehicleDetails.vehicleData.DAMAGE_AMOUNT" #damageAmount="ngModel" />
                                                </mat-form-field>
                                            </div>
                                            
                                            <div *ngIf="_vehicleDetails.vehicleData.InEligibleFlag != 'Y'" fxFlex="55" class="dd-veh-summary-value">
                                                <mat-form-field style="max-width:100%;display:block">
                                                    <input [readonly]="false" (keyup)="this.pageEdited($event);" maxlength="7" matInput name="damageAmount"
                                                        class="dd-inputValues" [ddFieldValidator]="validationTypes.NUMBER" 
                                                        [(ngModel)]="_vehicleDetails.vehicleData.DAMAGE_AMOUNT" #damageAmount="ngModel" />
                                                </mat-form-field>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </mat-card-content>
                </mat-card>

                <mat-card [ngClass]="unblockSectionStyle" fxFlex="50" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">
                    <mat-card-header>
                        <mat-card-title style="font-weight:bold">UNBLOCK</mat-card-title>
                    </mat-card-header>
                    <mat-card-content style="height:100%">
                      <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                             <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                            <div fxFlex="5"></div>
                                            <div fxFlex="35" class="dd-veh-summary-header">
                                                <span class="MandatorySymbol"> * </span> UNBLOCK COMMENTS
                                            </div>
                                            <div *ngIf="inEligibleFlag" fxFlex="55" class="dd-veh-summary-value">
                                                <mat-form-field style="max-width:100%;display:block">
                                                    <input [readonly]="false" (keyup)="this.pageEdited($event);" maxlength="28" matInput name="unblockcomments"
                                                        class="dd-inputValues" [(ngModel)]="_vehicleDetails.vehicleData.UNBLOCK_COMMENT" #unblockcomments="ngModel" />
                                                 <mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="!unblockcomments.value && (this.isErrorMessageDisplay && _vehicleDetails.vehicleData.InEligibleFlag == 'Y')"
                                                         align="start">Comments cannot be blank</mat-hint>
                                                </mat-form-field>
                                            </div>
                                            
                                            <div *ngIf="_vehicleDetails.vehicleData.InEligibleFlag == 'N'" fxFlex="55" class="dd-veh-summary-value">
                                                <mat-form-field style="max-width:100%;display:block">
                                                    <input [readonly]="true" (keyup)="this.pageEdited($event);" maxlength="28" matInput name="unblockcomments"
                                                        class="dd-inputValues" [(ngModel)]="_vehicleDetails.vehicleData.UNBLOCK_COMMENT" #unblockcomments="ngModel" />
                                                 <mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="!unblockcomments.value && (this.isErrorMessageDisplay && _vehicleDetails.vehicleData.InEligibleFlag == 'Y')"
                                                         align="start">Comments cannot be blank</mat-hint>
                                                </mat-form-field>
                                            </div>
                                            
                                            <div *ngIf="_vehicleDetails.vehicleData.InEligibleFlag != 'N'" fxFlex="55" class="dd-veh-summary-value">
                                                <mat-form-field style="max-width:100%;display:block">
                                                    <input [readonly]="false" maxlength="28" matInput name="unblockcomments"
                                                        class="dd-inputValues" (keyup)="this.pageEdited($event);" [(ngModel)]="_vehicleDetails.vehicleData.UNBLOCK_COMMENT" #unblockcomments="ngModel" />
                                                 <mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="!unblockcomments.value && (this.isErrorMessageDisplay && _vehicleDetails.vehicleData.InEligibleFlag == 'Y')"
                                                         align="start">Comments cannot be blank</mat-hint>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                    </div>

                                </div>

                            </div>
                            
                            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                    </div>

                                </div>

                            </div>
                            
                             <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        

                                    </div>

                                </div>

                            </div>

                        </div>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <!--<mat-divider></mat-divider>-->

    <div fxLayout="row" fxLayoutAlign="end center" class="dd-bottom-container" style="margin-top: 0.6%;">
        <div style="padding-right: 5px;">
            <button [disabled]="!this.isValidVin" mat-button mat-raised-button class="dd-desription-save-button"
                type="submit" (click)="saveRDR(_vehicleDetails)">{{saveButtonText}}</button>
        </div>
    </div>
</form>