<div class="uc-inventory-grid" fxLayout="column">
    <mat-card fxLayout="column" fxFlex="65">
      <ngx-datatable #inventoryTable class='material striped dd-grid-size' [rows]='rows' [columnMode]="'force'"
        [headerHeight]="65" [footerHeight]="0" [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true"
        [externalSorting]="true">
        <ngx-datatable-column *ngFor="let columnDef of columnDefs" [name]="columnDef.key" [prop]="columnDef.fieldName"
          [width]="columnDef.columnWidth" [minWidth]="columnDef.columnMinWidth" [maxWidth]="columnDef.columnMaxWidth"
          [flexGrow]="columnDef.columnWidth">
          <ng-template let-model="model" ngx-datatable-header-template>
            <div class="datatable-header-cell-label">
              <div fxLayout="column" class="dd-grid-label-align">
                <div fxFlex="100" fxLayout="row">
                <div class="cursorP"
                  [class.dd-grid-sorting]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)===''"
                  [class.dd-grid-sorting-asc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList) === 'ASC'"
                  [class.dd-grid-sorting-desc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)==='DESC'"
                  [innerHTML]="columnDef.key" (click)="columnDef.sortable !== 'true' || applySort(columnDef.fieldName)">
                </div>
                <div *ngIf="columnDef.tooltip !== undefined" class="cursorP" [title]="columnDef.tooltip !== undefined? columnDef.tooltip:''">
                  <mat-icon class="font15">info</mat-icon>
              </div>
                </div>
                <div fxFlex>
                  <mat-form-field class="dd-inv-input-background" style="width:100%">
                    <!--<mat-input-container>-->
                    <input matInput [(ngModel)]="columnDef.filterValue" *ngIf="columnDef.key !== 'DEALER NAME'"
                      [ddFieldValidator]="columnDef.dataTypeformat"
                      (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                    <input matInput [(ngModel)]="columnDef.filterValue" style="text-transform: uppercase;"
                      *ngIf="columnDef.key === 'DEALER NAME'" [ddFieldValidator]="columnDef.dataTypeformat"
                      (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                    <!--</mat-input-container>-->
                  </mat-form-field>
                </div>
                <!-- <table>
                  <thead>
                    <tr>
                      <th>{{columnDef.parent}}</th>
                    </tr>
                    <tr>
                      <th>{{columnDef.key}}</th>
                    </tr>
                  </thead>
                </table> -->
                <!-- <div fxFlex="100">
                  <mat-form-field class="dd-inv-input-background">
                    <input matInput [(ngModel)]="columnDef.filterValue" *ngIf="columnDef.key !== 'VIN'"
                      [ddFieldValidator]="columnDef.dataTypeformat"
                      (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                    <input matInput [(ngModel)]="columnDef.filterValue" style="text-transform: uppercase;"
                      *ngIf="columnDef.key === 'VIN'" [ddFieldValidator]="columnDef.dataTypeformat"
                      (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                  </mat-form-field>
                </div> -->
              </div>
            </div>
          </ng-template>
  
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <div class="text-left"
              *ngIf="columnDef.key !== 'DEALER NAME' && columnDef.key !== 'DEALER #' && columnDef.key !=='COUNT' && columnDef.key !=='REGION' "
              [innerHTML]="value"></div>
            <div class="text-left"
              *ngIf="columnDef.key === 'DEALER NAME' || columnDef.key === 'DEALER #' || columnDef.key ==='COUNT' || columnDef.key ==='REGION'"
              [innerHTML]="value"></div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card>
  </div>