




import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { VehicleDecertificationService } from '../services/api/vehicle-decertification.service';

import * as detailActions from '../actions/vehicle-decertification.action';

import * as models from '../services/model/models';
import {map, catchError, switchMap} from 'rxjs/operators'

/**
 * Class used to handle the service interaction in the vehicle detail screen
 */
@Injectable()
export class VehicleDeCertificationEffects {
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getVinInfo$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.LOAD_VIN_INFORMATION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(vin =>
      this.vehicleDecertificationService.getVinInfo(vin)
      .pipe(map((vehicleData: any) =>
          new detailActions.LoadVinInformationSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.LoadVinInformationFailAction(error))))
    ));
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  saveVehicleDecertification$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.SAVE_VEHICLE_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleDecertificationService.saveVehicleDecertification(data)
      .pipe(map((vehicleData: models.Decertification) =>
          new detailActions.SaveDecertificationSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.SaveDecertificationFailAction(error))))
    ));


  /**
   * Constructor method used to create new instance of VehicleDetailsEffects class
   */
  constructor(private actions$: Actions,
    private vehicleDecertificationService: VehicleDecertificationService) { }
}
