
'use strict';
import * as models from '../model/models';
import { SharedFunctionService } from '../common/shared-function.service';

// Vehicle Detail View Model
export interface RoleDetailView {
  // Selected Vehicle detail
  // // Selected VIN data
  vehicleData?: models.Role;
  // selectedModelVinStockData: string[];
  message?: models.Message;
  // page type
  pageType?: string;

  Items?: any;
}
