import { Observable } from 'rxjs';
import { SaleDetailDialogComponent } from './sale-detail-dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable, ViewContainerRef } from '@angular/core';
// import * as models from '../../../services/model';
/**
 * Class that is defined as service to open the input dialog component in the screen
 */
@Injectable()
export class SaleDetailDialogService {

  /**
   * constructor method to create new instance of DdInputDialogService
   */
  constructor(public dialog: MatDialog) { }

  /**
   * Method to open the input dialog component as dialog in the page
   */

  public open(title: string,
    viewContainerRef: ViewContainerRef,
    vin: string,
    parentComponentInstance: any): Observable<boolean> {
    let dialogRef: MatDialogRef<SaleDetailDialogComponent>;
    let config = new MatDialogConfig();
    config.viewContainerRef = viewContainerRef;
    dialogRef = this.dialog.open(SaleDetailDialogComponent, config);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.parent = parentComponentInstance;
    dialogRef.componentInstance.vin = vin;
    return dialogRef.afterClosed();
  }
}
