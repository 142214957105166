import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCInventoryExceptionReportRoutingModule }
    from './inventory-exception-report-routing.module';
import { UCInventoryExceptionPageReportComponent }
    from './inventory-exception-report-page.component';
import { UCInventoryExceptionSummaryReportGridComponent }
    from './inventory-exception-report-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCInventoryExceptionReportRoutingModule
    ],
    exports: [
        UCInventoryExceptionPageReportComponent
    ],
    declarations: [
        UCInventoryExceptionPageReportComponent,
        UCInventoryExceptionSummaryReportGridComponent
    ]
})

export class UCInventoryExceptionReportModule {

}
