import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// dealer #
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '250', columnMinWidth: '250',
      columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.DEALER_DISTRICT_COLUMN, fieldName: 'districtNumber',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// model
      key: ViewConstants.DEALER_REGION_NAME_STRING_COLUMN, fieldName: 'regionName', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }
  ];
  static totalGridColumns = [
    {// dealer #
      key: ViewConstants.TYPE, fieldName: 'type', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer name
      key: ViewConstants.REGION_OR_DIST, fieldName: 'display', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '250', columnMinWidth: '250',
      columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }
  ];
}
