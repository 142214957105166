<div fxLayout="column">
  <inventory-report-search-toolbar   [labelText]="labelText" fxFlex="100"  (filterDateEvent)="reportLoad($event)"
    [enteredvin]="enteredvin" [regionSelection]="regionSelection" [districtSelection]="districtSelection" [enteredDealerCode]="enteredDealerCode"></inventory-report-search-toolbar>

  <uc-inventory-report-menubar-panel [isInventorySelected]="isInventorySelected"
    [selectedCertificationStatus]="selectedCertificationStatus" [selectedMakeCode]="selectedMakeCode"
    (clearAllButtonEvent)="clearAllFilters();" [showClearAllButton]="showClearAllButton"
    [totalInventoriesCount]="totalInventoriesCount" (exportPdfEvent)="downLoadPdf()"
    (exportExcelEvent)="downLoadXlsx()"></uc-inventory-report-menubar-panel>

  <uc-inventory-grid [columnDefs]="columnDefs" [rows]="rows" (applySortEvent)="applyInventorySort($event)"
    (applyFilterEvent)="ApplyFilter($event)" [clearSort]="clearSort" [totalSaleCount]="totalSaleCount"
    (countIncreaseEvent)="countIncreaseEvent($event)" (countDecreaseEvent)="countDecreaseEvent($event)">
  </uc-inventory-grid>
</div>
