import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCSalesConsultantRDRReportRoutingModule }
    from './sales-consultant-rdr-report-routing.module';
import { UCSalesConsultantRDRPageReportComponent }
    from './sales-consultant-rdr-report-page.component';
import { UCSalesConsultantRDRReportGridComponent }
    from './sales-consultant-rdr-report-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCSalesConsultantRDRReportRoutingModule
    ],
    exports: [
      UCSalesConsultantRDRPageReportComponent
    ],
    declarations: [
      UCSalesConsultantRDRPageReportComponent,
      UCSalesConsultantRDRReportGridComponent
    ]
})

export class UCSalesConsultantRDRReportModule {

}
