

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as inventorySummaryActions from '../actions/inventory-summary.action';
import { UCInventorySummaryView } from '../services/view-model/inventory-summary-view';
import { Message, MessageType } from '../services/model/Message';
import { UCInventorySummary } from '../services/model/InventorySummary';
import { isNil, cloneDeep } from 'lodash';

/**
 * Interface used to maintain the state for the inventory summary screen
 */
export interface UCInventoryState {
    inventoryData: UCInventorySummaryView;
}

/**
 * Varibale used to store the initial state of UCInventoryState
 */
const initialState: UCInventoryState = {
    inventoryData: null
};

/**
 * Method used to set the default initial state of UCInventoryState
 */
function setDefaulValue(): UCInventorySummaryView {
    let inventoryData: UCInventorySummaryView = <UCInventorySummaryView>new Object();
    inventoryData.inventories = [];
    inventoryData.message = null;
    return inventoryData;
}

/**
 * Reducer method to handle the inventory summary screen actions
 */

export function reducer(state = initialState, action: ActionBase): UCInventoryState {
    switch (action.type) {
        case inventorySummaryActions.ACTION_TYPES.INITIAL_LOAD: {
            return { inventoryData: setDefaulValue() };
        }
        case inventorySummaryActions.ACTION_TYPES.INITIAL_LOAD_SUCCESS: {
            let totcount;
            let invData;
            if (action.payload.length === undefined) {
                totcount = action.payload.count;
                invData = action.payload.esResponse;
            } else {
                totcount = action.payload[0].count;
                let response = [];
                action.payload.map(element => {
                    response = response.concat(element.esResponse);
                });
                invData = response;
            }
            let inventoryData = (<any>Object).assign({}, state.inventoryData,
                {
                    inventories: cloneDeep(tempFrameInventoryDetails(invData)),
                    count: totcount
                });
            return { inventoryData: inventoryData };
        }
        case inventorySummaryActions.ACTION_TYPES.INITIAL_LOAD_FAIL: {
            let message: Message = {};
            message.message = 'No vehicles available for selected dealer code.';
            message.type = MessageType.WARNING;
            let inventoryData = (<any>Object).assign({}, state.inventoryData,
                {
                    message: message
                });
            return { inventoryData: inventoryData };
        }
        case inventorySummaryActions.ACTION_TYPES.GET_SUMMARY_REPORT_SUCCESS: {
            let inventoryData = (<any>Object).assign({}, state.inventoryData,
                {
                    inventories: cloneDeep(tempFrameInventoryDetails(action.payload)),
                    message: null
                });
            return { inventoryData: inventoryData };
        }
        case inventorySummaryActions.ACTION_TYPES.GET_SUMMARY_REPORT_FAIL: {
            let message: Message = {};
            message.message = 'No vehicles available for selected dealer code.';
            message.type = MessageType.WARNING;
            let inventoryData = (<any>Object).assign({}, state.inventoryData,
                {
                    message: message
                });
            return { inventoryData: inventoryData };
        }
        default: {
            return state;
        }
    }
}

/**
 * Method to get the state of VehicleInventoryState for inventory summary screen
 */
export function getVehiclesForInventory(state: UCInventoryState) {
    return state.inventoryData;
}


// function frameInventoryDetails(inventories: UCInventorySummary[]): UCInventorySummary[] {
//     inventories = cloneDeep(inventories);
//     inventories.map(inventory => {
//         if (!isNil(inventory.accessoriesDetails) && inventory.accessoriesDetails !== undefined) {
//             inventory.accessories = '';
//             inventory.accessoriesDetails.map(acc => {
//                 if (!isNil(inventory.accessories) && inventory.accessories === '') {
//                     inventory.accessories += acc.accessoryCode.trim();
//                 } else {
//                     inventory.accessories += ' ' + acc.accessoryCode.trim();
//                 }
//             })
//         }
//     })
//     return inventories;
// }


function tempFrameInventoryDetails(inventories: any): UCInventorySummary[] {
    let returnArray: UCInventorySummary[] = [];
    inventories.map(inventory => {
        let obj: UCInventorySummary = {};
        let invObj = inventory._source;
        obj.vin = invObj.vin;
        obj.makeCode = invObj.makeCode;
        obj.model = {};
        if (!isNil(invObj.model)) {
            obj.model.modelNumber = invObj.model.modelNumber;
            obj.model.modelYear = invObj.model.modelYear;
            obj.model.modelDescription = invObj.model.modelDescription;
        }
        obj.dealerCd = invObj.dealerCd;
        obj.dis = invObj.daysInStock;
        obj.extColor = {};
        if (!isNil(invObj.extColor)) {
            obj.extColor.colorCd = invObj.extColor.colorCd;
            obj.extColor.colorDesc = invObj.extColor.colorDesc;
        }
        obj.intColor = {};
        if (!isNil(invObj.intColor)) {
            obj.intColor.colorCd = invObj.intColor.colorCd;
            obj.intColor.colorDesc = invObj.intColor.colorDesc;
        }
        obj.certification = {};
        if (!isNil(invObj.certification)) {
            obj.certification.certificationStatus = invObj.certification.certificationStatus;
            obj.certification.deCertifyReason = invObj.certification.deCertifyReason;
            obj.certification.warrantyCode = invObj.certification.warrantyCode;
            obj.certification.certificationNumber = invObj.certification.certificationNumber;
            obj.certification.certificationDate =
                convertDateDisplay(invObj.certification.certificationDate);
            obj.certification.warrantyExpiryDate =
                convertDateDisplay(invObj.certification.warrantyExpiryDate);
            if ((String(invObj.certification.forceCertificationFlag).toLowerCase() === 'false')
                || (String(invObj.certification.forceCertificationFlag).toLowerCase() === 'null')) {
                obj.certification.forceCertificationFlag = 'N';
            } else if
                (String(invObj.certification.forceCertificationFlag).toLowerCase() === 'true') {
                obj.certification.forceCertificationFlag = 'Y';
            } else {
                obj.certification.forceCertificationFlag =
                    invObj.certification.forceCertificationFlag;
            }
        }
        obj.price = {};
        if (!isNil(invObj.price)) {
            obj.price.acvPrice = invObj.price.acvPrice;
            obj.price.retailAmount = invObj.price.retailAmount;
        }
        if (String(invObj.openCampaignStatus).toLowerCase() === 'false') {
            obj.openCampaignStatus = 'N';
        } else if (String(invObj.openCampaignStatus).toLowerCase() === 'true') {
            obj.openCampaignStatus = 'Y';
        } else {
            obj.openCampaignStatus = invObj.openCampaignStatus;
        }
        obj.carFaxFlag = invObj.carFaxFlag;
        obj.acquiredDate = invObj.acquiredDate;
        obj.mileage = invObj.mileage;
        obj.dofu = invObj.dofu;
        obj.comments = invObj.comments;
        obj.deCertifyReason = invObj.deCertifyReason;
        obj.accessoriesDetails = invObj.accessories;
        obj.accessories = '';
        obj.certificationStatus = invObj.certificationStatus;
        obj.deCertifyReason = invObj.deCertifyReason;
        obj.certificationNumber = invObj.certificationNumber;
        if (!isNil(invObj) && !isNil(invObj.accessories) && invObj.accessories.length > 0) {
            invObj.accessories.map(acc => {
                if (!isNil(acc.accessssoriesCode)) {
                    if (!isNil(obj.accessories) && obj.accessories === '') {
                        obj.accessories += acc.accessssoriesCode.trim();
                    } else {
                        obj.accessories += ' ' + acc.accessssoriesCode.trim();
                    }
                }
            });
        }
        returnArray.push(obj);
    });
    return returnArray;
}

function convertDateDisplay(fromdate: any) {
    let date = new Date(fromdate);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return month + '-' + dt + '-' + year;
}

