import { NgModule } from '@angular/core';
import { VehicleHistoryPageComponent } from './vehicle-history-page.component';
import { VehicleHistoryComponent } from './vehicle-history.component';
import { SharedModule } from '../../shared/shared.module';
import { VehicleHistoryRoutingModule } from './vehicle-history-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [
    SharedModule,
    VehicleHistoryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    InfiniteScrollModule,
    MatExpansionModule
  ],
  exports: [
    VehicleHistoryComponent,
    VehicleHistoryPageComponent
  ],
  declarations: [
    VehicleHistoryComponent,
    VehicleHistoryPageComponent
  ]
})
export class VehicleHistoryModule {
}
