import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import * as messageDataActions from '../../../actions/message-data.action';
import * as vehicleReportActions from '../../../actions/vehicle-report.action';
import * as fromRoot from '../../../reducers';
import { SharedDataService } from '../../../services/common/shared-data.service';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import * as models from '../../../services/model/models';
import { UCInventorySummaryView } from '../../../services/view-model/inventory-summary-view';
import * as viewModels from '../../../services/view-model/view-models';
import { ViewConstants } from '../../../shared/shared.constants';
import { InventorySummaryGridColumns } from './json/UCInventorySummaryGridColumns';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment'
import * as alasql from 'alasql';

@Component({
    selector: 'uc-inventory',
    templateUrl: 'vehicle-daily-rdr-report-page.component.html'
})

export class UCVehicleDailyRDRPageReportComponent implements OnInit, OnDestroy {
    // Observable to save inventory screen data
    inventorySummaryData$: Observable<UCInventorySummaryView>;
    // Subscriber for Inventory Data
    inventorySummarySubscriber: any;
    // Array variable used to store columns
    public columnDefs: any[] = [];
    // Array variable to keep the original data for filter purpose
    originalData: Array<models.UCInventorySummary>;
    // Array variable to keep the filtered data for filter purpose
    filteredData: Array<models.UCInventorySummary>;
    // Array to hold the filters applied in the grid
    public filterArray: Array<models.Filter> = [];
    // Boolean to reveal whether the dealer code is selected or not
    isInventorySelected: boolean = false;
    // certificationStatus
    selectedCertificationStatus: string = '';
    // makeCode
    selectedMakeCode: string = '';
    // To store seleted vehicle index
    public selectedVehicleIndex: number = 0;
    // Boolean variable to show/hide the clear all button
    showClearAllButton: boolean;
    // Variable to display the inventories count
    totalInventoriesCount: Number = 0;
    // Dealer code entered in search toolbar
    enteredDealerCode: string;
    // vin entered in search toolbar
    enteredvin: string;
    // To hold the last sort event
    currentSortEvent: any;
    // to clear the current sorting for all columns
    clearSort: boolean = false;
    // Observable to obtain VehicleDetailView object
    vehicleDetails$: Observable<viewModels.VehicleDetailView>;
    // Observable to obtain RdrDetailView object
    rdrDetails$: Observable<viewModels.RdrDetailView>;
    // Private array variable to display the rows in grid
    private _rows: Array<models.UCInventorySummary>;
    // Property to set and get the rows
    get rows(): Array<models.UCInventorySummary> {
        return this._rows;
    }
    set rows(value: Array<models.UCInventorySummary>) {
        if (value.length === 1 && value[0].vin === '') {
            this.totalInventoriesCount = 0;
        } else {
            this.totalInventoriesCount = value.length;
        }
        this._rows = value;
    }
    // private moment = require('moment');
    // selected Disclaimer
    private row: models.UCInventorySummary = {};
    /**
     * Constructor for UCInventoryPageComponent
     */
    constructor(private store: Store<fromRoot.AppState>,
        public sharedFuncService: SharedFunctionService,
        public sharedDataService: SharedDataService,
        public router: Router,
        private viewContainerRef: ViewContainerRef
    ) {
        this.inventorySummaryData$ = store.select(fromRoot.getVehicleReport);
    }
    /**
     * ngOnInit
     */
    ngOnInit() {
        this.onLoad();
        this.inventorySummarySubscriber = this.inventorySummaryData$.subscribe((data) => {
            if (!isNil(data) && data !== undefined) {
                this.refreshFilters();
                this.enteredDealerCode = '';
                this.enteredDealerCode = (<any>Object).assign(this.enteredDealerCode, '');
                this.enteredvin = '';
                this.enteredvin = (<any>Object).assign(this.enteredvin, '');
                this.isInventorySelected = false;
                this.selectedCertificationStatus = '';
                this.selectedMakeCode = '';

                if (!isNil(data.message)) {
                    this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
                        data.message
                    ));
                } else {
                    if (data.inventories.length > 0) {
                        this.rows = cloneDeep(sortBy(data.inventories, 'VIN'));
                        this.originalData = cloneDeep(data.inventories);
                    } else {
                        this.rows = cloneDeep([]);
                    }
                }
            }
        });
    }

    reduceAdd(attr) {
        return function (p, v) {
            ++p.count;
            if (p.count === 1)
                p.sum = 0;
            p.sum += v[attr];
            p.avg = p.count ? p.sum / p.count : 0;
            return p;
        };
    }

    reduceAddModel() {
        return function (p, v) {
            ++p.count;
            p.desc = v.model.modelDescription;
            p.year = v.model.modelYear;
            return p;
        };
    }

    reduceRemoveModel() {
        return function (p, v) {
            --p.count;
            p.desc = v.model.modelDescription;
            p.year = v.model.modelYear;
            return p;
        };
    }

    reduceInitiaModel() {
        return {
            count: 0,
            desc: null,
            year: null
        };
    }

    reduceRemove(attr) {
        return function (p, v) {
            --p.count;
            p.sum -= v[attr];
            p.avg = p.count ? p.sum / p.count : 0;
            return p;
        };
    }

    reduceInitial() {
        return { count: 0, total: 0 };
    }

    orderModelValue(p) {
        return p.total;
    }

    orderValue(p) {
        return p.total;
    }
    /**
     * onLoad method
     */
    onLoad() {
        this.sharedFuncService.setCurrentScreenName(
            ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
        this.columnDefs = InventorySummaryGridColumns.gridColumns;
        this.sharedDataService.setSelectedVehicle('');
        this.store.dispatch(
            new vehicleReportActions.ClearAllAction());
        let report = {
            reportMode: '3',
            fromDate: null,
            toDate: null,
            makeCode: this.sharedDataService.brandName
        };
        this.store.dispatch(
            new vehicleReportActions.GetInventoryReport(report));
    }

    reportLoad(event: any) {
        this.store.dispatch(
            new vehicleReportActions.ClearAllAction());
        let report = {
            reportMode: '3',
            fromDate: event.fromDate,
            toDate: event.toDate,
            makeCode: this.sharedDataService.brandName
        };
        this.store.dispatch(
            new vehicleReportActions.GetInventoryReport(report));
    }

    refreshFilters() {
        this.showClearAllButton = false;
        if (!isNil(this.currentSortEvent)) {
            this.applyInventorySort(this.currentSortEvent);
        }
        this.clearSort = false;
        InventorySummaryGridColumns.gridColumns.forEach(column => {
            column.filterValue = '';
        });
    }

    applyInventorySort(event: any) {
        this.currentSortEvent = event;
        this.rows =
            cloneDeep(this.sharedFuncService.applySort(this.rows, event));
    }

    ApplyFilter(filter: models.Filter) {
        let tempFilter: models.Filter = null;
        let existingFilterInArrayIndex: number = 0;
        if (this.filterArray !== null && this.filterArray !== undefined) {
            this.filterArray.forEach(data => {
                if (data.filterType === filter.filterType
                    && data.columnname === filter.columnname) {
                    tempFilter = data;
                }
                if (tempFilter === null) {
                    existingFilterInArrayIndex++;
                }
            });
            if (tempFilter !== null && tempFilter !== undefined) {
                this.filterArray.splice(existingFilterInArrayIndex, 1);
            }
            if (filter.filterValue !== null && filter.filterValue !== undefined
                && filter.filterValue.trim() !== '') {
                tempFilter = filter;
                this.filterArray.push(tempFilter);
            }
            this.filteredData = cloneDeep(this.originalData);
            this.filterArray.forEach(arrayElement => {
                if (!isNil(arrayElement.filterValue) && arrayElement.filterValue !== undefined &&
                    arrayElement.filterValue.trim() !== '') {
                    if (arrayElement.filterType === models.FilterType.CONTAINS) {
                        this.applyContainsFilter(arrayElement);
                    } else if (arrayElement.filterType === models.FilterType.STARTS_WITH) {
                        this.applyStartsWithFilter(arrayElement);
                    } else if (arrayElement.filterType === models.FilterType.SPACES) {
                        this.applySpacesFilter(arrayElement);
                    } else if (arrayElement.filterType === models.FilterType.PRICE_COLUMN) {
                        this.applyPriceFilter(arrayElement);
                    } else if (arrayElement.filterType === models.FilterType.DATE_SYMBOL) {
                        this.applyDateFilter(arrayElement);
                    }
                }
            });
            if (this.filterArray.length > 0) {
                this.showClearAllButton = true;
            } else {
                this.showClearAllButton = false;
            }
            // if (this.filteredData.length === 0) {
            //     let test: models.UCInventorySummary = {};
            //     test.vin = '';
            //     this.filteredData.push(test);
            // }
            this.rows = cloneDeep(this.filteredData);
        }
    }

    applyContainsFilter(filterElement: models.Filter) {
        let filterValue = filterElement.filterValue.toLowerCase().trim();
        this.filteredData = this.filteredData.filter(inventory => {
            let propertyValue = String(this.accessProperties(inventory, filterElement.columnname));
            return !isNil(propertyValue) && propertyValue.toLowerCase().indexOf(filterValue) > -1;
        });
    }

    applyStartsWithFilter(filterElement: models.Filter) {
        let filterValue = filterElement.filterValue.toLowerCase().trim();
        this.filteredData = this.filteredData.filter(inventory => {
            let propertyValue = this.accessProperties(inventory, filterElement.columnname);
            return !isNil(propertyValue) &&
                String(propertyValue).toLowerCase()
                    .startsWith(filterValue);
        });
    }

    applySpacesFilter(filterElement: models.Filter) {
        let filterValue = filterElement.filterValue.toLowerCase().trim();
        this.filteredData = this.filteredData.filter(inventory => {
            return !isNil(inventory.accessoriesDetails)
                && inventory.accessoriesDetails !== undefined
                && this.accessoriesFilter(inventory.accessoriesDetails, filterValue);
        });
    }

    accessoriesFilter(accessories: models.AccessoryDetails[], value: string): boolean {
        let filterValues = String(value).split(' ');
        let returnValue = false;
        let positiveCount = 0;
        if (!isNil(accessories) && accessories.length > 0) {
            filterValues.forEach(filter => {
                if (accessories.filter(acc =>
                    acc.accessssoriesCode.trim().toLowerCase() === filter ||
                    acc.accessssoriesCode.trim().toLowerCase().startsWith(filter)).length > 0) {
                    positiveCount++;
                }
            });
        }
        if (positiveCount === filterValues.length) {
            return true;
        }
        return false;
    }

    applyPriceFilter(filterElement: models.Filter) {
        let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
        // if (fullFilterValue.length > 1) {
        let firstLetter = fullFilterValue.substr(0, 1);
        let filterVal = Number.parseFloat(fullFilterValue.substr(1, fullFilterValue.length));
        this.filteredData = this.filteredData.filter(inventory => {
            let propValue = this.accessProperties(inventory, filterElement.columnname);
            if (!isNil(propValue)) {
                if (firstLetter === '<') {
                    return Number.parseFloat(propValue) <= filterVal;
                } else if (firstLetter === '>') {
                    return Number.parseFloat(propValue) >= filterVal;
                } else if (firstLetter === '=') {
                    return Number.parseFloat(propValue) === filterVal;
                } else {
                    filterVal = Number.parseFloat(fullFilterValue.
                        substr(0, fullFilterValue.length));
                    return Number.parseFloat(propValue) === filterVal;
                }
            }
            return true;
        });
        // }
    }

    applyDateFilter(filterElement: models.Filter) {
        let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
        if (fullFilterValue.length > 1) {
            let firstLetter = fullFilterValue.substr(0, 1);
            let filterVal = fullFilterValue.substr(1, fullFilterValue.length);
            this.filteredData = this.filteredData.filter(inventory => {
                let propValue = this.accessProperties(inventory, filterElement.columnname);
                if (!isNil(propValue)) {
                    if (firstLetter === '<') {
                        let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
                        let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
                        if (d1) {
                            return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') <= d;
                        }
                    } else if (firstLetter === '>') {
                        let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
                        let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
                        if (d1) {
                            return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') >= d;
                        }

                    } else if (firstLetter === '=') {
                        let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
                        let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
                        if (d1) {
                            return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
                        }

                    } else {
                        filterVal = fullFilterValue.
                            substr(0, fullFilterValue.length);
                        let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
                        let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
                        if (d1) {
                            return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
                        }
                    }
                }
                return true;
            });
        }
    }

    accessProperties(object: any, str: string) {
        let propertiesString = str.split('.');
        let i = 0;
        propertiesString.forEach(val => {
            object = object[propertiesString[i]];
            i++;
        });
        return object;
    }

    clearAllFilters() {
        this.rows = cloneDeep(this.originalData);
        this.filteredData = cloneDeep(this.originalData);
        this.filterArray = [];
        this.refreshFilters();
    }

    // To print the Inventory grid data
    downLoadPdf() {
        let inventoryData = this.inventoryGridData();
        let doc = new jsPDF('landscape', 'mm', 'a2');
        doc.text('TCUV National Model Wise Monthly RDR Summary Report', 14, 20);
        doc.autoTable(inventoryData.columns, inventoryData.rows, {
            startY: 10,
            styles: { overflow: 'linebreak', fontSize: 13, columnWidth: 'auto' },
            columnStyles: { text: { columnWidth: 'auto' } }
        });
        doc.autoTable(inventoryData.columns, inventoryData.rows, {
            startY: 10,
            styles: { overflow: 'linebreak', fontSize: 13, columnWidth: 'auto' },
            columnStyles: { text: { columnWidth: 'auto' } }
        });
        doc.setPage(this.totalInventoriesCount);
        doc.save('VehicleDailyRDRReport.pdf');
        this.sharedDataService.showLoader = false;
    }

    // To download the xlsx in inventory screen
    downLoadXlsx() {
        let tempData: any;
        let inventoryDataNew: Array<any> = [];
        this.rows.forEach(row => {
            tempData = {};
            InventorySummaryGridColumns.gridColumns.forEach(column => {
                let columnField = this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
                tempData[column.key] = columnField;
            });
            inventoryDataNew.push(tempData);
        });
        let option = [{ sheetid: 'VehicleDailyRDRReport', header: true }];
        alasql('SELECT * INTO XLSX("VehicleDailyRDRReport.xlsx",?) FROM ?',
            [option, [inventoryDataNew]]);
        this.sharedDataService.showLoader = false;
    }

    // To download the csv in inventory screen
    downLoadCsv() {
        let tempData: any;
        let inventoryDataNew: Array<any> = [];
        this.rows.forEach(row => {
            tempData = {};
            InventorySummaryGridColumns.gridColumns.forEach(column => {
                let columnField = this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
                tempData[column.key] = columnField;
            });
            inventoryDataNew.push(tempData);
        });
        alasql('SELECT * INTO CSV("inventorySummary.csv",{headers:true, separator:","}) FROM ?',
            [inventoryDataNew]);
        this.sharedDataService.showLoader = false;

    }

    // To get inventory grid data
    inventoryGridData(): any {
        let columns: Array<any> = [];
        let rows: Array<any> = [];
        if (InventorySummaryGridColumns.gridColumns && this.rows) {
            InventorySummaryGridColumns.gridColumns.forEach(column => {
                columns.push(column.key);
            });
            let tempRows: Array<any> = [];
            this.rows.forEach(row => {
                tempRows = [];
                InventorySummaryGridColumns.gridColumns.forEach(column => {
                    let columnField =
                        this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
                    tempRows.push(columnField);
                });
                rows.push(tempRows);
            });
        }
        let temps: any = {};
        temps.rows = rows;
        temps.columns = columns;
        return temps;
    }


    ngOnDestroy() {
        if (this.inventorySummarySubscriber) {
            this.inventorySummarySubscriber.unsubscribe();
        }
        this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
        // this.inventorySummaryData$ = null;
    }

}
