
'use strict';
import * as models from '../model/models';
import { SharedFunctionService } from '../common/shared-function.service';

// Vehicle Detail View Model
export interface RdrDetailView {
  // // Selected VIN data
  rdrData?: models.Rdr;
  // Message to be shown in the screen
  message?: models.Message;
  // Data formatter
  dataFormatter?: SharedFunctionService;
  // page type
  pageType?: string;

  // // Selected VIN data
  staffData?: any;
}
