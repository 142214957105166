import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCRecallPageReportComponent } from './recall-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
  {
    path: 'recallReport',
    component: UCRecallPageReportComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(inventorySummaryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCRecallReportRoutingModule {

}
