import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCRegionalModelLineRDRReportRoutingModule }
  from './regional-model-line-rdr-report-routing.module';
import { UCRegionalModelLineRdrReportPageReportComponent }
  from './regional-model-line-rdr-report-page.component';
import { UCRegionalModelLineRdrReportGridComponent }
  from './regional-model-line-rdr-report-grid.component';
import { PercentPipe, CurrencyPipe } from '@angular/common';

@NgModule({
  imports: [
    SharedModule,
    UCRegionalModelLineRDRReportRoutingModule
  ],
  exports: [
    UCRegionalModelLineRdrReportPageReportComponent
  ],
  declarations: [
    UCRegionalModelLineRdrReportPageReportComponent,
    UCRegionalModelLineRdrReportGridComponent
  ],
  providers: [
    CurrencyPipe, PercentPipe
  ]
})

export class UCRegionalModelLineRDRReportModule {

}
