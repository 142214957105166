import { NgModule } from '@angular/core';
import { UCYTYTCUVSalesReportRoutingModule }
  from './yty-tcuv-sales-report-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { UCYTYTCUVSalesComparisonPageReportComponent } from './yty-tcuv-sales-report-page';
import { UCYTYTCUVSalesReportGridComponent } from './yty-tcuv-sales-report-grid';

@NgModule({
  imports: [
    SharedModule,
    UCYTYTCUVSalesReportRoutingModule
  ],
  exports: [
    UCYTYTCUVSalesComparisonPageReportComponent
  ],
  declarations: [
    UCYTYTCUVSalesComparisonPageReportComponent,
    UCYTYTCUVSalesReportGridComponent
  ]
})

export class UCYTYTCUVSalesReportModule {
}


