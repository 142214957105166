import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ScreenMessages } from '../../shared/master-data/messages';
import { SharedDataService } from '../../services/common/shared-data.service';


@Component({
  selector: 'dd-unauthorized',
  templateUrl: 'unauthorized.component.html',
  styleUrls: ['unauthorized.component.scss'],
  // animations: [routerTransition()],
  // host: { '[@routerTransition]': '' }
})
export class UnauthorizedComponent implements OnInit {

  // private location:Location
  constructor(private location: Location, public sharedDataService: SharedDataService) {

  }

  ngOnInit() {
  }

  // login() {
  //   // this.service.startSigninMainWindow();
  // }

  // goback() {
  //   // this.location.back();
  // }
}
