import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VehicleHistoryPageComponent } from './vehicle-history-page.component';
// import { AuthService } from '../../services/common/auth.service';
// import { AuthGuardService } from '../../services/common/auth-guard.service';
import { AuthenticationGuard } from '../../services/common/auth-guard';

const dealerParticipantRoutes: Routes = [
  {
    path: 'vehicleHistory',
    component: VehicleHistoryPageComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'vehicleHistory/:vin',
    component: VehicleHistoryPageComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(dealerParticipantRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class VehicleHistoryRoutingModule { }
