import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCAvgSalesReportRoutingModule }
  from './avg-sales-report-routing.module';
import { UCAvgSalesReportComponent }
  from './avg-sales-report-page.component';
import { UCAvgSalesReportGridComponent }
  from './avg-sales-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCAvgSalesReportRoutingModule
  ],
  exports: [
    UCAvgSalesReportComponent
  ],
  declarations: [
    UCAvgSalesReportComponent,
    UCAvgSalesReportGridComponent
  ]
})

export class UCAvgSalesReportModule {
}
