<div class="dd-dialog-container" fxLayout="column">
    <div class="dd-dialog-title">{{ title }}</div>
    <div [innerHTML]="message"></div>
    <div fxFlex fxLayout="row" fxLayoutAlign="space-around center" class="dd-button-space">
        <div *ngIf="dialogType === 'Acknowledge'">
            <button type="button" mat-raised-button (click)="dialogRef.close(false)">{{cancelActionText}}</button>
        </div>
        <div *ngIf="dialogType === 'OK-CANCEL'">
            <button type="button" mat-raised-button (click)="dialogRef.close(false)">{{cancelActionText}}</button>
        </div>
        <div *ngIf="dialogType === 'YES-NO'">
            <button type="button" mat-raised-button
                (click)="cancelAction({dialogRef: dialogRef, parent: parent })">{{cancelActionText}}</button>
        </div>
        <button *ngIf="dialogType != 'Acknowledge'" type="button" mat-raised-button
            (click)="okAction({dialogRef: dialogRef, parent: parent, comments: comments })">{{okActionText}}</button>
    </div>
</div>