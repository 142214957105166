import {
  Component, OnDestroy, Input, Output, EventEmitter,
  ChangeDetectionStrategy, ViewEncapsulation, AfterViewInit
} from '@angular/core';

import { Message, MessageType } from '../../../services/model/Message';

/***
 * Class that is defined as Component used to render the Message
 */
@Component({
  selector: 'uc-message-display',
  templateUrl: 'message-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './message-display.component.scss'
  ]
})
export class UCMessageComponent implements OnDestroy, AfterViewInit {
  /***
   * variable to hold Message
   */
  @Input() message: Message;

  /***
   * variable to hold dismissOnTimeout in millisceconds
   */
  @Input() dismissOnTimeout?: number;

  /***
   * variable to hold onCloseMessage event
   */
  @Output() onCloseMessage = new EventEmitter<Message>();

  // Variable is used to hild the reference of timeout for the message
  private timeOutRef: any;

  constructor() {

  }

  /***
   * AfterViewInit variable for Message
   * to initialize the instances of Message
   */
  public ngAfterViewInit() {
    if (this.dismissOnTimeout !== null && this.dismissOnTimeout !== undefined
      && this.dismissOnTimeout > 0) {
      this.timeOutRef = setTimeout(() => {
        this.closeMessage(this.message);
      }, this.dismissOnTimeout);
    }
  }

  /***
   * Destroy event for Message
   * to destroy the instances of Message
   */
  public ngOnDestroy() {

  }

  /***
   * closeMessage event for Message
   * to close the instances of Message
   */
  closeMessage(message: Message) {
    this.onCloseMessage.emit(message);
  }

  /***
   * method to get Message type as string
   */
  getMessageType(messageType: MessageType): string {
    return (messageType !== null && messageType !== undefined) ?
      MessageType[messageType].toLowerCase() :
      MessageType[MessageType.WARNING].toLowerCase();
  }
}
