

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as saleSummaryActions from '../actions/sale-summary.action';
import { UCRdrSummaryView } from '../services/view-model/rdr-summary-view';
import { Message, MessageType } from '../services/model/Message';
import { UCRDRSummary } from '../services/model/RdrSummary';
import { isNil, cloneDeep } from 'lodash';

/**
 * Interface used to maintain the state for the inventory summary screen
 */
export interface UCSaleState {
    rdrData: UCRdrSummaryView;
}

/**
 * Varibale used to store the initial state of UCInventoryState
 */
const initialState: UCSaleState = {
    rdrData: null
};

/**
 * Method used to set the default initial state of UCInventoryState
 */
function setDefaulValue(): UCRdrSummaryView {
    let inventoryData: UCRdrSummaryView = <UCRdrSummaryView>new Object();
    inventoryData.inventories = [];
    inventoryData.message = null;
    return inventoryData;
}

/**
 * Reducer method to handle the inventory summary screen actions
 */

export function reducer(state = initialState, action: ActionBase): UCSaleState {
    switch (action.type) {
        case saleSummaryActions.ACTION_TYPES.INITIAL_LOAD: {
            return { rdrData: setDefaulValue() };
        }
        case saleSummaryActions.ACTION_TYPES.INITIAL_LOAD_SUCCESS: {
            let inventoryData = (<any>Object).assign({}, state.rdrData,
                {
                    inventories: cloneDeep(tempFrameInventoryDetails(action.payload.esResponse))
                });
            return { rdrData: inventoryData };
        }
        case saleSummaryActions.ACTION_TYPES.INITIAL_LOAD_FAIL: {
            let message: Message = {};
            message.message = 'No vehicles available for selected dealer code.';
            message.type = MessageType.WARNING;
            let inventoryData = (<any>Object).assign({}, state.rdrData,
                {
                    message: message
                });
            return { rdrData: inventoryData };
        }
        default: {
            return state;
        }
    }
}

/**
 * Method to get the state of VehicleInventoryState for inventory summary screen
 */
export function getVehiclesForRdr(state: UCSaleState) {
    return state.rdrData;
}


function tempFrameInventoryDetails(inventories: Array<any>): UCRDRSummary[] {
    let returnArray: UCRDRSummary[] = [];

    inventories.filter(sale => !isNil(sale._source.rdrDetail)
        && !isNil(sale._source.rdrDetail.buyer)).forEach(inventory => {
            let obj: UCRDRSummary = {};
            let invObj;
            if (!isNil(inventory._source))
                invObj = inventory._source;
            else
                invObj = inventory;

            obj.vin = invObj.vin;
            obj.makeCode = invObj.makeCode;
            obj.model = {};
            if (!isNil(invObj.model)) {
                obj.model.modelNumber = invObj.model.modelNumber;
                obj.model.modelYear = invObj.model.modelYear;
                obj.model.modelDescription = invObj.model.modelDescription;
            }
            obj.dealerCd = invObj.dealerCd;
            obj.extColor = {};
            if (!isNil(invObj.extColor)) {
                obj.extColor.colorCd = invObj.extColor.colorCd;
                obj.extColor.colorDesc = invObj.extColor.colorDesc;
            }
            obj.intColor = {};
            if (!isNil(invObj.intColor)) {
                obj.intColor.colorCd = invObj.intColor.colorCd;
                obj.intColor.colorDesc = invObj.intColor.colorDesc;
            }
            obj.certification = {};
            if (!isNil(invObj.certification)) {
                obj.certification.certificationStatus = invObj.certification.certificationStatus;
                obj.certification.deCertifyReason = invObj.certification.deCertifyReason;
                obj.certification.warrantyCode = invObj.certification.warrantyCode;
                obj.certification.certificationNumber = invObj.certification.certificationNumber;
                obj.certification.certificationDate = invObj.certification.certificationDate;
                if ((String(invObj.certification.forceCertificationFlag).toLowerCase() === 'false')
                    || (String(invObj.certification.forceCertificationFlag).
                        toLowerCase() === 'null')) {
                    obj.certification.forceCertificationFlag = 'N';
                } else if (String(invObj.certification.forceCertificationFlag).
                    toLowerCase() === 'true') {
                    obj.certification.forceCertificationFlag = 'Y';
                } else {
                    obj.certification.forceCertificationFlag =
                        invObj.certification.forceCertificationFlag;
                }
            }
            obj.price = {};
            if (!isNil(invObj.price)) {
                obj.price.acvPrice = invObj.price.acvPrice;
                obj.price.retailAmount = invObj.price.retailAmount;
            }
            if (String(invObj.openCampaignStatus).toLowerCase() === 'false') {
                obj.openCampaignStatus = 'N';
            } else if (String(invObj.openCampaignStatus).toLowerCase() === 'true') {
                obj.openCampaignStatus = 'Y';
            } else {
                obj.openCampaignStatus = invObj.openCampaignStatus;
            }

            obj.rdrDetail = {};
            if (!isNil(invObj.rdrDetail)) {
                obj.rdrDetail.soldAmount = invObj.rdrDetail.soldAmount;
                obj.rdrDetail.rdrStatus = invObj.rdrDetail.rdrStatus;
                obj.rdrDetail.retailDate = invObj.rdrDetail.retailDate;
                if (!isNil(invObj.rdrDetail.warrantyContractNumber)) {
                    obj.rdrDetail.warrantyContractNumber = invObj.rdrDetail.warrantyContractNumber;
                }
                if (!isNil(invObj.rdrDetail.setFormNumber)) {
                    obj.rdrDetail.setFormNumber = invObj.rdrDetail.setFormNumber;
                }
                obj.rdrDetail.buyer = {};
                if (!isNil(invObj.rdrDetail.buyer) && !isNil(invObj.rdrDetail.buyer.firstName)) {
                    obj.rdrDetail.buyer.firstName =
                        invObj.rdrDetail.buyer.firstName + ' ' + invObj.rdrDetail.buyer.lastName;
                } else if (!isNil(invObj.rdrDetail.buyer) &&
                    !isNil(invObj.rdrDetail.buyer.lastName)) {
                    obj.rdrDetail.buyer.firstName = invObj.rdrDetail.buyer.lastName;
                } else {
                    obj.rdrDetail.buyer.firstName = '';
                }
            }

            obj.acquiredDate = invObj.acquiredDate;
            obj.mileage = invObj.mileage;
            obj.dofu = invObj.dofu;
            obj.accessoriesDetails = invObj.accessories;
            obj.accessories = '';
            obj.certificationStatus = invObj.certificationStatus;
            obj.certificationNumber = invObj.certificationNumber;
            obj.certificationType = !isNil(invObj.certificationType) ?
                invObj.certificationType : '';
            if (!isNil(invObj) && !isNil(invObj.accessories) && invObj.accessories.length > 0) {
                invObj.accessories.forEach(acc => {
                    if (!isNil(acc.accessssoriesCode)) {
                        if (!isNil(obj.accessories) && obj.accessories === '') {
                            obj.accessories += acc.accessssoriesCode.trim();
                        } else {
                            obj.accessories += ' ' + acc.accessssoriesCode.trim();
                        }
                    }
                });
            }
            returnArray.push(obj);
        });
    return returnArray;
}

