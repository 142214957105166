<div class="uc-inventory-grid">
  <mat-card>
    <!-- <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;margin-left: 2%">
      <label>Report Type</label>
    </div> -->
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="70" style="padding: 10px">
      <label fxFlex="10">Month</label>
      <mat-select fxFlex="70" style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:50%;"
        class="mat-no-underline" name="month" [(ngModel)]="selectedMonth" #month="ngModel">
        <mat-option [value]="">
          -- select --
        </mat-option>
        <mat-option *ngFor="let i of monthList" [value]="i.value">{{i.name}}</mat-option>
      </mat-select>

      <label fxFlex="10">Year</label>
      <mat-select fxFlex="70" style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:50%;"
        class="mat-no-underline" name="year" [(ngModel)]="selectedYear" #year="ngModel">
        <mat-option [value]="">
          -- select --
        </mat-option>
        <mat-option *ngFor="let i of yearList" [value]="i">{{i}}</mat-option>
      </mat-select>
    </div>
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30" style="padding: 5px;">
      <button (click)="fetchObjective();" mat-button mat-raised-button>Apply</button>
    </div>
  </mat-card>
  <mat-card>
    <ngx-datatable #inventoryTable class='material striped dd-grid-size' [rows]='rows' [columnMode]="'force'"
      [headerHeight]="65" [footerHeight]="0" [rowHeight]="40" [scrollbarV]="true" [scrollbarH]="true"
      [externalSorting]="true">
      <ngx-datatable-column *ngFor="let columnDef of columnDefs" [name]="columnDef.key" [prop]="columnDef.fieldName"
        [width]="columnDef.columnWidth" [minWidth]="columnDef.columnMinWidth" [maxWidth]="columnDef.columnMaxWidth"
        [flexGrow]="columnDef.columnWidth">
        <ng-template let-model="model" ngx-datatable-header-template>
          <div class="datatable-header-cell-label">
            <div fxLayout="column" class="dd-grid-label-align">
              <div fxFlex="100" class="cursorP"
                [class.dd-grid-sorting]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)===''"
                [class.dd-grid-sorting-asc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList) === 'ASC'"
                [class.dd-grid-sorting-desc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)==='DESC'"
                [innerHTML]="columnDef.key" (click)="columnDef.sortable !== 'true' || applySort(columnDef.fieldName)">
              </div>
              <div fxFlex>
                <mat-form-field class="dd-inv-input-background" style="width:100%">
                  <input matInput [(ngModel)]="columnDef.filterValue" *ngIf="columnDef.key !== 'VIN'"
                    [ddFieldValidator]="columnDef.dataTypeformat"
                    (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                  <input matInput [(ngModel)]="columnDef.filterValue" style="text-transform: uppercase;"
                    *ngIf="columnDef.key === 'VIN'" [ddFieldValidator]="columnDef.dataTypeformat"
                    (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template  ngx-datatable-cell-template  let-rowIndex="rowIndex" let-value="value" let-row="row">
          <input *ngIf="columnDef.key === 'OBJECTIVE' else default" style="width:100%" (keyup)="updateValue($event, 'objective', rowIndex, row)"
            [ddFieldValidator]="validationTypes.NUMBER_WITH_DECIMAL_2" type="text" [value]="value" />
          <ng-template #default class="text-left">{{value}}</ng-template>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </mat-card>
</div>
<div fxLayout="row" fxLayoutAlign="end center" class="dd-bottom-container" style="margin: 0.6% 0 0.6% 0;width:100%">
  <div style="padding-right: 5px;">
    <button mat-button mat-raised-button class="dd-desription-save-button" type="submit"
      (click)="saveRegionObjective()">SAVE</button>
  </div>
</div>
