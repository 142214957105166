import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// dealer #
      key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'areaRegionNo', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.DEALER_DISTRICT_COLUMN, fieldName: 'districtNo',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }, {// dealer #
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// dealer #
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.CONSULTANT_NAME, fieldName: 'salePersonId',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '185', columnMinWidth: '185',
      columnMaxWidth: '185', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS_WITH_NOTEQUAL
    },
    {// CERTIFICATION NO
      key: ViewConstants.UID, fieldName: 'salePersonUID',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// model
      key: ViewConstants.YTD_RDR, fieldName: 'yearlySalesCount', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// model
      key: ViewConstants.YTD_REV, fieldName: 'yearlyReversalCount', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '180',
      columnMinWidth: '180', columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// Plan Code
      key: ViewConstants.YTD_SALES, fieldName: 'yearlyTotal',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// whole sale
      key: ViewConstants.MTD_RDR, fieldName: 'monthlySalesCount',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// CERTIFIED BY
      key: ViewConstants.MTD_REV, fieldName: 'monthlyReversalCount', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// CERTIFICATION TYPE
      key: ViewConstants.MTD_SALES,
      fieldName: 'monthlyTotal', style: ' ',
      hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }
  ];
}
