import { UCInventorySummary } from '../app/services/model/InventorySummary';
export class UCInventorySummaryJson {
    static inventories: Array<UCInventorySummary> = [
        {
            vin: 'JTEBU5JR3H5413404',
            dis: '1915',
            model: {
                modelNumber: '8672',
                modelYear: 2017,
                modelDescription: '4Runner TFD Road'
            },
            accessoriesDetails: [
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'DN',
                    accessssoriesName: 'SR5 Parking Sonar & Navigation Package (V6 only)',
                    accessssoriesType: ''
                }
            ],
            makeCode: 'TOYOTA',
            extColor: {
                colorCd: '0083',
                colorDesc: 'BLACK'
            },
            intColor: {
                colorCd: '23',
                colorDesc: 'LIGHT YELLOW'
            },
            dofu: '03/09/2015',
            dealerCd: '04136',
            dealerName: 'Ehrlich Toyota',
            acquiredDate: '02/02/2014',
            mileage: 60000,
            price: {
                acvPrice: '201.56',
                retailAmount: '147.22',
                dealerTradeAmount: "15589",
                reconditionCost: "1546"
            },
            certification: {
                warrantyCode: 'MPM',
                certificationDate: '11/11/2016',
                certificationNumber: '0012457',
                certificationStatus: 'Failed'
            },
            openCampaignStatus: 'Y',
            comments: 'Available'
        },
        {
            vin: '4T4BF1FK6GR530502',
            dis: '2012',
            model: {
                modelNumber: '7894',
                modelYear: 2014,
                modelDescription: '4Runner TFD Road'
            },
            accessoriesDetails: [
                {
                    accessssoriesCode: 'FE',
                    accessssoriesName: '50 State Emissions',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'DN',
                    accessssoriesName: 'SR5 Parking Sonar & Navigation Package (V6 only)',
                    accessssoriesType: ''
                }
            ],
            makeCode: 'TOYOTA',
            extColor: {
                colorCd: '765',
                colorDesc: 'BLACK'                
            },
            intColor: {
                colorCd: '45',
                colorDesc: 'LIGHT YELLOW'
            },
            dofu: '03/09/2015',
            dealerCd: '04136',
            dealerName: 'Ehrlich Toyota',
            acquiredDate: '11/12/2013',
            mileage: 60000,
            price: {
                acvPrice: '222.56',
                retailAmount: '160.22',
                dealerTradeAmount: "15589",
                reconditionCost: "1546"
            },
            certification: {
                warrantyCode: 'MPM',
                certificationDate: '11/11/2016',
                certificationNumber: '0012457',
                certificationStatus: 'Failed'
            },
            openCampaignStatus: 'Y',
            comments: 'Available'
        },
        {
            vin: 'JTDKDTB36H5461480',
            dis: '1856',
            model: {
                modelNumber: '8672',
                modelYear: 2017,
                modelDescription: '4Runner TFD'
            },
            accessoriesDetails: [
                 {
                    accessssoriesCode: 'FE',
                    accessssoriesName: '50 State Emissions',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
            ],
            makeCode: 'TOYOTA',
            extColor: {
                colorCd: '085',
                colorDesc: 'GRAY'                
            },
            intColor: {
                colorCd: '23',
                colorDesc: 'LIGHT GRAY'
            },
            dofu: '09/08/2015',
            dealerCd: '05064',
            dealerName: 'Ehrlich Toyota',
            acquiredDate: '03/12/2016',
            mileage: 65000,
            price: {
                acvPrice: '238.66',
                retailAmount: '115.92',
                dealerTradeAmount: "15589",
                reconditionCost: "1546"
            },
            certification: {
                warrantyCode: 'MPG',
                certificationDate: '11/12/2016',
                certificationNumber: '0456781',
                certificationStatus: 'DeCertified'
            },
            openCampaignStatus: 'Y',
            comments: 'Available'
        },
        {
            vin: 'JTDKDTB3JDWL58765',
            dis: '1748',
            model: {
                modelNumber: '8672',
                modelYear: 2017,
                modelDescription: 'TFD Road'
            },
            accessoriesDetails: [
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'DN',
                    accessssoriesName: 'SR5 Parking Sonar & Navigation Package (V6 only)',
                    accessssoriesType: ''
                }
            ],
            makeCode: 'TOYOTA',
            extColor: {
                colorCd: '34',
                colorDesc: 'PINK'                
            },
            intColor: {
                colorCd: '565',
                colorDesc: 'RED'
            },
            dofu: '03/12/2015',
            dealerCd: '04136',
            dealerName: 'Joseph Toyota',
            acquiredDate: '02/09/2014',
            mileage: 70000,
            price: {
                acvPrice: '199.58',
                retailAmount: '125.68',
                dealerTradeAmount: "15589",
                reconditionCost: "1546"
            },
            certification: {
                warrantyCode: 'MPG',
                certificationDate: '05/12/2016',
                certificationNumber: '4578912',
                certificationStatus: 'Y'
            },
            openCampaignStatus: 'Y',
            comments: 'Available'
        },
        {
            vin: 'JTDKDTB3LEKF41298',
            dis: '1651',
            model: {
                modelNumber: '8672',
                modelYear: 2017,
                modelDescription: 'TFD'
            },
            accessoriesDetails: [
                {
                    accessssoriesCode: 'RT',
                    accessssoriesName: '',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'QW',
                    accessssoriesName: '',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'JV',
                    accessssoriesName: '',
                    accessssoriesType: ''
                }
            ],
            makeCode: 'TOYOTA',
            extColor: {
                colorCd: '454',
                colorDesc: 'GREEN'                
            },
            intColor: {
                colorCd: '234',
                colorDesc: 'RED'
            },
            dofu: '03/12/2015',
            dealerCd: '04136',
            dealerName: 'Joseph Toyota',
            acquiredDate: '02/09/2014',
            mileage: 70000,
            price: {
                acvPrice: '247.58',
                retailAmount: '156.75',
                dealerTradeAmount: "15589",
                reconditionCost: "1546"
            },
            certification: {
                warrantyCode: 'MPG',
                certificationDate: '03/27/2016',
                certificationNumber: '7845987',
                certificationStatus: 'N'
            },
            openCampaignStatus: 'Y',
            comments: 'Available'
        }
    ]

    // static inventories: Array<UCInventorySummary> = [
    //     {
    //         vin: 'JTDKDTB3XH1593989',
    //         dis: '177586',
    //         modelNo: '8672',
    //         modelDesc: '4Runner TFD Road',
    //         modelYear: 2017,
    //         accessories: 'FE AC OK EP',
    //         certificationStatus: 'Failed',
    //         retailPrice: '147.22',
    //         acvPrice: '201.56',
    //         warrantyCode: 'MPM',            
    //         openCampaignStatus: 'Y',
    //         certificatonNumber: '0012457',
    //         acquiredDate: '02/02/2014',
    //         mileage: 60000,
    //         dofu: '03/09/2015',
    //         certificationDate: '11/11/2016',
    //         comments: 'Available',
    //         dealerCd: '05064',            
    //         intColor: 'LIGHT YELLOW',
    //         extColor: 'BLACK'
    //     },
    //     {
    //         vin: 'JTDKDTB36H5461480',
    //         dis: '177552',
    //         modelNo: '8678',
    //         modelDesc: '4Runner TFD',
    //         modelYear: 2017,
    //         accessories: 'FE MR OP QD',
    //         certificationStatus: 'DeCertified',
    //         retailPrice: '187.87',
    //         acvPrice: '222.56',
    //         warrantyCode: 'MPG',            
    //         openCampaignStatus: 'N',
    //         certificatonNumber: '0025487',
    //         acquiredDate: '01/05/2014',
    //         mileage: 65000,
    //         dofu: '09/08/2015',
    //         certificationDate: '02/02/2016',
    //         comments: 'Available',
    //         dealerCd: '05064',            
    //         intColor: 'LIGHT GRAY',
    //         extColor: 'GRAY'
    //     },
    //     {
    //         vin: 'JTDKDTB3JDWL58765',
    //         dis: '177975',
    //         modelNo: '8672',
    //         modelDesc: 'TFD Road',
    //         modelYear: 2017,
    //         accessories: 'TY OD GF QW',
    //         certificationStatus: 'Yes',
    //         retailPrice: '158.22',
    //         acvPrice: '201.56',
    //         warrantyCode: 'MPM',            
    //         openCampaignStatus: 'Y',
    //         certificatonNumber: '0154787',
    //         acquiredDate: '02/09/2014',
    //         mileage: 70000,
    //         dofu: '03/12/2015',
    //         certificationDate: '04/15/2016',
    //         comments: 'Available',
    //         dealerCd: '05064',            
    //         intColor: 'LIGHT YELLOW',
    //         extColor: 'BLACK'
    //     },
    //     {
    //         vin: 'JTDKDTB3LEKF41298',
    //         dis: '177865',
    //         modelNo: '8672',
    //         modelDesc: 'TFD Road',
    //         modelYear: 2017,
    //         accessories: 'KY UI GF QW',
    //         certificationStatus: 'No',
    //         retailPrice: '200.22',
    //         acvPrice: '258.56',
    //         warrantyCode: 'MPM',            
    //         openCampaignStatus: 'Y',
    //         certificatonNumber: '5187945',
    //         acquiredDate: '08/15/2014',
    //         mileage: 70000,
    //         dofu: '11/15/2015',
    //         certificationDate: '11/19/2016',
    //         comments: 'Available',
    //         dealerCd: '05064',            
    //         intColor: 'CREAM',
    //         extColor: 'BLACK'
    //     }
    // ]
}