
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import * as accountingSummaryActions from '../actions/accounting-summary.action';
import { UCAccountingSummaryService } from '../services/api/accounting-summary.service';
import { UCAccountingSummary } from '../services/model/AccountingSummary';
import {map, catchError, switchMap} from 'rxjs/operators'
/**
 * Class used to handle the service interaction in the inventory summary screen
 */
@Injectable()
export class UCAccountingSummaryEffects {

  /**
   * Mehod used to get the inventory summary data on Initial Load
   */
  @Effect()
  getAccountingSummary$: Observable<Action> = this.actions$
    .pipe(ofType(accountingSummaryActions.ACTION_TYPES.INITIAL_LOAD)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.uCAccountingService.getAccountingSummary(data)
        .pipe(map((accounting: Array<UCAccountingSummary>) =>
          new accountingSummaryActions.InitialLoadSuccessAction(accounting))
        , catchError(error => of(new accountingSummaryActions.InitialLoadFailureAction(error))))
    ));
  /**
   * Constructor method used to create new instance of UCInventorySummaryEffects class
   */
  constructor(private actions$: Actions,
    private uCAccountingService: UCAccountingSummaryService) {

  }

}
