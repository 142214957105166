/***
 * Import statements required for Inventory Detail Service
 * for invoking services required for populating
 * all sections in the Vehicle Inventory Detail Screen
 */
import { Injectable } from '@angular/core';
// TODO: correctly add the JSON so that it can be read
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedFunctionService } from '../common/shared-function.service';
import { HttpClient } from '../../shared/components/helpers/http-client';
import { ViewConstants } from '../../shared/shared.constants';
import { Http, Response, Headers, RequestOptionsArgs, URLSearchParams } from '@angular/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


/***
 * Class that is used to invoke the Services for populating
 * all the sections of Vehicle Inventory Detail screen
 */
@Injectable()
export class StaffMasterDetailService {
    public defaultHeaders: Headers = new Headers();
    protected getStaffMasterFinanceURL = environment.GET_SMF_API_URL;
    protected getStaffMasterSalesURL = environment.GET_SMS_API_URL;


    /***
     * Constructor of Inventory Detail Service to inject the angluar http
     */
    constructor(
        private sharedFuncService: SharedFunctionService,
        protected httpClient: HttpClient) { }




    public getStaffMasterFinanceDetails(dealerCode: string): Observable<any> {
        // const path =  ServiceURL.DISURL;
        let path = this.getStaffMasterFinanceURL + '?dealerCd=' + dealerCode;
        let queryParameters = new URLSearchParams();
        let headerParams = this.defaultHeaders;

        if (!dealerCode) {
            let msg = ViewConstants.REQUIRED_PARAMETER_IS_NULL_OR_UNDEFINED
                .replace('{ServiceName}', 'Get Staff Master finance URL')
                .replace('{ParameterName}', 'dealerCode');
            return this.sharedFuncService.handleError(msg);
        }

        let requestOptions: RequestOptionsArgs = {
            method: 'GET',
            headers: headerParams,
            search: queryParameters
        };

        // TODO: To be Removed
        return this.httpClient.get(path, requestOptions)
            .pipe(map((response:any) => {
                if (response.status === 204) {
                    return undefined;
                } else {
                return response.body;
                }
            }),catchError((err: any) => {
                return this.sharedFuncService.handleError(err);
            }))

    }

    public getStaffMasterSalesDetails(dealerCode: string): Observable<any> {
        let path = this.getStaffMasterSalesURL + '?dealerCd=' + dealerCode;
        let queryParameters = new URLSearchParams();
        let headerParams = this.defaultHeaders;

        if (!dealerCode) {
            let msg = ViewConstants.REQUIRED_PARAMETER_IS_NULL_OR_UNDEFINED
                .replace('{ServiceName}', 'Get Staff Master finance URL')
                .replace('{ParameterName}', 'dealerCode');
            return this.sharedFuncService.handleError(msg);
        }

        let requestOptions: RequestOptionsArgs = {
            method: 'GET',
            headers: headerParams,
            search: queryParameters
        };

        // TODO: To be Removed
        return this.httpClient.get(path, requestOptions)
            .pipe(map((response:any) => {
                if (response.status === 204) {
                    return undefined;
                } else {
                    return response.body;
                }
            }),catchError((err: any) => {
                return this.sharedFuncService.handleError(err);
            }))

    }


}
