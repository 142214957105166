import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';
import * as models from '../services/model/models';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

/**
 * Following action types are being used in the vehicle detail screen
 */
export const actionTypes = {
  INITIAL_LOAD: type('[AccountingDetail] Initiate load for accounting details'),
  INITIAL_LOAD_SUCCESS: type('[AccountingDetail] accounting details Success'),
  INITIAL_LOAD_FAIL: type('[AccountingDetail] accounting details Fail'),
  LOAD_VIN_INFORMATION: type('[AccountingDetail] vehicle details vin information'),
  LOAD_VIN_INFORMATION_SUCCESS: type('[AccountingDetail] vehicle details vin information Success'),
  LOAD_VIN_INFORMATION_FAIL: type('[AccountingDetail] vehicle details vin information Fail'),
  CLEAR_ALL_ACTION: type('[AccountingDetail] clear all vehicle data'),
  GET_CURRENT_STATE_ACTION: type('[AccountingDetail] Get current action'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to load the Initial data for vehicle detail screen
 */
export class InitialLoadAction implements ActionBase {
  type = actionTypes.INITIAL_LOAD;

  constructor(public payload: models.AccountingDetails) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class InitialLoadSuccessAction implements ActionBase {
  type = actionTypes.INITIAL_LOAD_SUCCESS;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the Failure action for Initial Load method
 */
export class InitialLoadFailAction implements ActionBase {
  type = actionTypes.INITIAL_LOAD_FAIL;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVinInformationAction implements ActionBase {
  type = actionTypes.LOAD_VIN_INFORMATION;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVinInformationSuccessAction implements ActionBase {
  type = actionTypes.LOAD_VIN_INFORMATION_SUCCESS;

  constructor(public payload: models.AccountingDetails) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVinInformationFailAction implements ActionBase {
  type = actionTypes.LOAD_VIN_INFORMATION_FAIL;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class ClearAllAction implements ActionBase {
  type = actionTypes.CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetCurrentState implements ActionBase {
  type = actionTypes.GET_CURRENT_STATE_ACTION;

  constructor() { }
}


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = InitialLoadAction
  | InitialLoadSuccessAction
  | InitialLoadFailAction
  | LoadVinInformationAction
  | LoadVinInformationSuccessAction
  | LoadVinInformationFailAction;

