import { Vehicle } from '../app/services/model/Vehicle';
export class VinInfoJson {
    static inventories: Array<Vehicle> = [
        {
            vin: '2T2BZMCAXGC052113',
            model: {
                modelNumber: '1112',
                modelYear: 2016,
                modelDescription: 'RX 350 5-DOOR SUV 4x2'
            },
            accessories: [
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'DN',
                    accessssoriesName: 'SR5 Parking Sonar & Navigation Package (V6 only)',
                    accessssoriesType: ''
                }
            ],
            extColor: {
                colorCd: '0085',
                colorDesc: 'EMINENT WHITE'                
            },
            intColor: {
                colorCd: 'LB23',
                colorDesc: 'BLACK SHIMAMOKU'
            },
            dofu: '03/09/2015',
            dealerCd: '82521',
            openCampaignStatus: 'N',
            carFaxFlag: 'PASS',
            transmissionType:'',
            trimCode:''
        },
        {
            vin: '5TDXZ3DC5HS784422',
            model: {
                modelNumber: '7894',
                modelYear: 2014,
                modelDescription: '4Runner TFD Road'
            },
            accessories: [
                {
                    accessssoriesCode: 'FE',
                    accessssoriesName: '50 State Emissions',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'DN',
                    accessssoriesName: 'SR5 Parking Sonar & Navigation Package (V6 only)',
                    accessssoriesType: ''
                }
            ],
            extColor: {
                colorCd: '765',
                colorDesc: 'BLACK'                
            },
            intColor: {
                colorCd: '45',
                colorDesc: 'LIGHT YELLOW'
            },
            dofu: '03/09/2015',
            dealerCd: '05064',
            openCampaignStatus: 'N',
            carFaxFlag: 'PASS',
            transmissionType:'',
            trimCode:''
        },
        {
            vin: '2T3BFREV3HW616234',
            model: {
                modelNumber: '8672',
                modelYear: 2017,
                modelDescription: '4Runner TFD'
            },
            accessories: [
                 {
                    accessssoriesCode: 'FE',
                    accessssoriesName: '50 State Emissions',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
            ],
            extColor: {
                colorCd: '085',
                colorDesc: 'GRAY'                
            },
            intColor: {
                colorCd: '23',
                colorDesc: 'LIGHT GRAY'
            },
            dofu: '09/08/2015',
            dealerCd: '05064',
            openCampaignStatus: 'Y',
            carFaxFlag: 'Y',
            transmissionType:'',
            trimCode:''
        },
        {
            vin: 'JTDKDTB3JDWL58765',
            model: {
                modelNumber: '8672',
                modelYear: 2017,
                modelDescription: 'TFD Road'
            },
            accessories: [
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'DN',
                    accessssoriesName: 'SR5 Parking Sonar & Navigation Package (V6 only)',
                    accessssoriesType: ''
                }
            ],
            extColor: {
                colorCd: '34',
                colorDesc: 'PINK'                
            },
            intColor: {
                colorCd: '565',
                colorDesc: 'RED'
            },
            dofu: '03/12/2015',
            dealerCd: '04136',
            openCampaignStatus: 'Y',
            carFaxFlag: 'Y',
            transmissionType:'',
            trimCode:''
        },
        {
            vin: 'JTDKDTB3LEKF41299',
            model: {
                modelNumber: '8672',
                modelYear: 2017,
                modelDescription: 'TFD'
            },
            accessories: [
                {
                    accessssoriesCode: 'RT',
                    accessssoriesName: '',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'QW',
                    accessssoriesName: '',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'JV',
                    accessssoriesName: '',
                    accessssoriesType: ''
                }
            ],
            extColor: {
                colorCd: '454',
                colorDesc: 'GREEN'                
            },
            intColor: {
                colorCd: '234',
                colorDesc: 'RED'
            },
            dofu: '03/12/2015',
            dealerCd: '04136',
            openCampaignStatus: 'N',
            carFaxFlag: 'PASS',
            transmissionType:'',
            trimCode:''
        }
    ]

}