import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCInventoryExceptionReportRoutingModule }
    from './lexus-without-wholesales-report-routing.module';
import { UCLexusWithoutWholesalesPageReportComponent }
    from './lexus-without-wholesales-report-page.component';
import { UCLexusWithoutWholesalesReportGridComponent }
    from './lexus-without-wholesales-report-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCInventoryExceptionReportRoutingModule
    ],
    exports: [
        UCLexusWithoutWholesalesPageReportComponent
    ],
    declarations: [
        UCLexusWithoutWholesalesPageReportComponent,
        UCLexusWithoutWholesalesReportGridComponent
    ]
})

export class UCLexusWithoutWholesalesReportModule {

}
