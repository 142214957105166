import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCSalesOver65kReportPageComponent }
  from './sales-over65k-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
  {
    path: 'salesOver65k',
    component: UCSalesOver65kReportPageComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'salesOver65k/:type',
    component: UCSalesOver65kReportPageComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(inventorySummaryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCSalesOver65kReportRoutingModule {

}
