<div fxLayout="column">
    <vehicle-certification-report-search-toolbar fxFlex="100"(filterDateEvent)="reportLoad($event)"
         [enteredvin]="enteredvin" [enteredDealerCode]="enteredDealerCode" [regionSelection]="regionSelection" [districtSelection]="districtSelection"></vehicle-certification-report-search-toolbar>

    <uc-inventory-report-menubar-panel [isInventorySelected]="isInventorySelected" [selectedCertificationStatus]="selectedCertificationStatus" [selectedMakeCode]="selectedMakeCode"
        (clearAllButtonEvent)="clearAllFilters();" [showClearAllButton]="showClearAllButton" [totalInventoriesCount]="totalInventoriesCount"
        (exportPdfEvent)="downLoadPdf()" (exportExcelEvent)="downLoadXlsx()"></uc-inventory-report-menubar-panel>

    <uc-inventory-grid [columnDefs]="columnDefs" [columnDefsTotal]="columnDefsTotal" [dealerTotal]="dealerTotal"
        [rows]="rows" (applySortEvent)="applyInventorySort($event)" (applyFilterEvent)="ApplyFilter($event)"
        [clearSort]="clearSort" [totalSaleCount]="totalSaleCount" (countIncreaseEvent)="countIncreaseEvent($event)"
        (countDecreaseEvent)="countDecreaseEvent($event)">
    </uc-inventory-grid>
</div>

