export class UCInventorySummaryJson {
    static inventories: Array<any> = [
        {
            InEligibleFlag: 'N',
            city: 'EL MONTE',
            dealerCity: null,
            regionName: 'LOS ANGELES REGION',
            registered: 'false',
            certificationStatus: 'C',
            vinDealer: '4T4BF1FK6GR530502-04136',
            regionNo: '11',
            createdAt: '2018-12-06T04:08:47.775Z',
            acquiredDate: '12-05-2018',
            price: {
                dealerTradeAmount: null,
                acvPrice: '7000',
                retailAmount: '45000'
            },
            daysInStock: 1,
            repairOrder: {
                repairOrderNumber2: null,
                dateofSecondRepairOrder: null,
                dateofFirstRepairOrder: '2018-12-14T00:00:00.000Z',
                reconditionCost: null,
                repairOrderNumber1: '7657567'
            },
            makeCode: 'TOYOTA',
            vin: '4T4BF1FK6GR530502',
            model: {
                modelDescription: 'CAMRY 4-DOOR LE SEDAN',
                modelNumber: '2532',
                modelYear: 2016
            },
            extColor: {
                colorCd: '0040',
                colorDesc: 'SUPER WHITE'
            },
            dealerState: null,
            state: 'CA',
            updatedAt: '2018-12-06T03:49:41.720Z',
            mileage: '60000',
            inspection: {
                inspectionApprovedDate: '2018-12-05T00:00:00.000Z',
                inspectionFormNumber: '567567'
            },
            acquiredMethod: 'A',
            comments: null,
            unblockComments: null,
            dealerName: null,
            carFaxFlag: 'Y',
            inventoryStatus: 'D',
            dealerCd: '04136',
            certificationNumber: '9000355',
            retailDate: '2018-12-06',
            intColor: {
                colorCd: 'FC20',
                colorDesc: 'BLACK'
            },
            openCampaignStatus: 'N',
            dealerRegionName: null,
            certification: {
                certificationDate: '2018-12-06T03:49:41.720Z',
                warrantyExpiryDate: '2023-04-11',
                forceCertificationFlag: 'false',
                warrantyDescription: '7 YEARS/100,000 MILES',
                warrantyCode: 'MPM',
                planAmount: '0000300'
            },
            foreignVin: 'N',
            dofu: '2016-04-11',
            createdBy: '04136',
            rdrDetail: {
                financeInsuranceManagerId: 'DIRK',
                updatedBy: '04136',
                saleType: '03',
                dealerCd: '04136',
                retailDate: '2018-12-06',
                cobuyer: null,
                reversalType: null,
                reversalInd: null,
                buyer: {
                    lastName: 'sharma',
                    zipCode: '66666-6666',
                    extension: null,
                    city: 'ajmer',
                    homePhone: null,
                    addressLineOne: 'building',
                    emailPermissionInd: 'Y',
                    suffix: null,
                    firstName: null,
                    addressLineTwo: null,
                    middleName: null,
                    workPhone: null,
                    state: 'TENNESSEE',
                    salution: null,
                    email: null
                },
                createdAt: '2018-12-06T13:32:14.295Z',
                reversalReason: null,
                createdBy: '04136',
                rdrStatus: 'H',
                vin: '4T4BF1FK6GR530502',
                soldAmount: '45000',
                updatedAt: '2018-12-06T13:41:16.096Z',
                salesPersonId: 'Albert',
                mileage: '60000'
            }
        },
        {
            InEligibleFlag: 'N',
            city: 'EL MONTE',
            dealerCity: null,
            regionName: 'LOS ANGELES REGION',
            registered: 'false',
            certificationStatus: 'C',
            vinDealer: '4T4BF1FK6GR530502-04136',
            regionNo: '11',
            createdAt: '2018-12-06T04:08:47.775Z',
            acquiredDate: '12-05-2018',
            price: {
                dealerTradeAmount: null,
                acvPrice: '7000',
                retailAmount: '45000'
            },
            daysInStock: 1,
            repairOrder: {
                repairOrderNumber2: null,
                dateofSecondRepairOrder: null,
                dateofFirstRepairOrder: '2018-12-14T00:00:00.000Z',
                reconditionCost: null,
                repairOrderNumber1: '7657567'
            },
            makeCode: 'TOYOTA',
            vin: '2T3ZFREV3FW189553',
            model: {
                modelDescription: 'RAV4 LE FWD SUV',
                modelNumber: '4430',
                modelYear: 2015
            },
            extColor: {
                colorCd: '0040',
                colorDesc: 'SUPER WHITE'
            },
            dealerState: null,
            state: 'CA',
            updatedAt: '2018-12-06T03:49:41.720Z',
            mileage: '60000',
            inspection: {
                inspectionApprovedDate: '2018-12-05T00:00:00.000Z',
                inspectionFormNumber: '567567'
            },
            acquiredMethod: 'A',
            comments: null,
            unblockComments: null,
            dealerName: null,
            carFaxFlag: 'Y',
            inventoryStatus: 'S',
            dealerCd: '05064',
            certificationNumber: '9000355',
            retailDate: '2018-12-06',
            intColor: {
                colorCd: 'FC20',
                colorDesc: 'BLACK'
            },
            openCampaignStatus: 'N',
            dealerRegionName: null,
            certification: {
                certificationDate: '2018-12-06T03:49:41.720Z',
                warrantyExpiryDate: '2023-04-11',
                forceCertificationFlag: 'false',
                warrantyDescription: '7 YEARS/100,000 MILES',
                warrantyCode: 'MPM',
                planAmount: '0000300'
            },
            foreignVin: 'N',
            dofu: '2016-04-11',
            createdBy: '04136',
            rdrDetail: {
                financeInsuranceManagerId: 'DIRK',
                updatedBy: '04136',
                saleType: '03',
                dealerCd: '04136',
                retailDate: '2018-12-06',
                cobuyer: null,
                reversalType: null,
                reversalInd: null,
                buyer: {
                    lastName: 'sharma',
                    zipCode: '66666-6666',
                    extension: null,
                    city: 'ajmer',
                    homePhone: null,
                    addressLineOne: 'building',
                    emailPermissionInd: 'Y',
                    suffix: null,
                    firstName: null,
                    addressLineTwo: null,
                    middleName: null,
                    workPhone: null,
                    state: 'TENNESSEE',
                    salution: null,
                    email: null
                },
                createdAt: '2018-12-06T13:32:14.295Z',
                reversalReason: null,
                createdBy: '04136',
                rdrStatus: 'H',
                vin: '2T3ZFREV3FW189553',
                soldAmount: '45000',
                updatedAt: '2018-12-06T13:41:16.096Z',
                salesPersonId: 'Albert',
                mileage: '60000'
            }
        },
        {
            InEligibleFlag: 'N',
            city: 'EL MONTE',
            dealerCity: null,
            regionName: 'LOS ANGELES REGION',
            registered: 'false',
            certificationStatus: 'C',
            vinDealer: '4T1BK1EB1GU222161-24022',
            regionNo: '11',
            createdAt: '2018-12-06T04:08:47.775Z',
            acquiredDate: '12-05-2018',
            price: {
                dealerTradeAmount: null,
                acvPrice: '7000',
                retailAmount: '45000'
            },
            daysInStock: 1,
            repairOrder: {
                repairOrderNumber2: null,
                dateofSecondRepairOrder: null,
                dateofFirstRepairOrder: '2018-12-14T00:00:00.000Z',
                reconditionCost: null,
                repairOrderNumber1: '7657567'
            },
            makeCode: 'TOYOTA',
            vin: '4T1BK1EB1GU222161',
            model: {
                modelDescription: 'AVALON 4-DR LIMITED',
                modelNumber: '3554',
                modelYear: 2016
            },
            extColor: {
                colorCd: '0040',
                colorDesc: 'SUPER WHITE'
            },
            dealerState: null,
            state: 'CA',
            updatedAt: '2018-12-06T03:49:41.720Z',
            mileage: '60000',
            inspection: {
                inspectionApprovedDate: '2018-12-05T00:00:00.000Z',
                inspectionFormNumber: '567567'
            },
            acquiredMethod: 'A',
            comments: null,
            unblockComments: null,
            dealerName: null,
            carFaxFlag: 'Y',
            inventoryStatus: 'S',
            dealerCd: '05064',
            certificationNumber: '9000355',
            retailDate: '2018-12-06',
            intColor: {
                colorCd: 'FC20',
                colorDesc: 'BLACK'
            },
            openCampaignStatus: 'N',
            dealerRegionName: null,
            certification: {
                certificationDate: '2018-12-06T03:49:41.720Z',
                warrantyExpiryDate: '2023-04-11',
                forceCertificationFlag: 'false',
                warrantyDescription: '7 YEARS/100,000 MILES',
                warrantyCode: 'MPM',
                planAmount: '0000300'
            },
            foreignVin: 'N',
            dofu: '2016-04-11',
            createdBy: '04136',
            rdrDetail: {
                financeInsuranceManagerId: 'DIRK',
                updatedBy: '04136',
                saleType: '03',
                dealerCd: '24022',
                retailDate: '2018-12-06',
                cobuyer: null,
                reversalType: null,
                reversalInd: null,
                buyer: {
                    lastName: 'sharma',
                    zipCode: '66666-6666',
                    extension: null,
                    city: 'ajmer',
                    homePhone: null,
                    addressLineOne: 'building',
                    emailPermissionInd: 'Y',
                    suffix: null,
                    firstName: null,
                    addressLineTwo: null,
                    middleName: null,
                    workPhone: null,
                    state: 'TENNESSEE',
                    salution: null,
                    email: null
                },
                createdAt: '2018-12-06T13:32:14.295Z',
                reversalReason: null,
                createdBy: '04136',
                rdrStatus: 'H',
                vin: '4T1BK1EB1GU222161',
                soldAmount: '45000',
                updatedAt: '2018-12-06T13:41:16.096Z',
                salesPersonId: 'Albert',
                mileage: '60000'
            }
        }
    ];
}
