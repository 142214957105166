import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// Region No
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '80',
      columnMinWidth: '80', columnMaxWidth: '80', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Region No
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Region No
      key: ViewConstants.DEALER_DISTRICT_COLUMN, fieldName: 'districtNumber',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Region No
      key: ViewConstants.DEALER_REGION_NAME_STRING_COLUMN, fieldName: 'regionName',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '180',
      columnMinWidth: '180', columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// DEALER NO
      key: ViewConstants.MODEL_NO, fieldName: 'modelDescription', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer name
      key: ViewConstants.CERTIFIED_DAILY, fieldName: 'dailyCertifiedSalesCount', style: '',
      hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// Count
      key: ViewConstants.CERTIFIED_MONTHLY, fieldName: 'monthlyCertifiedSalesCount',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '140',
      columnMinWidth: '140', columnMaxWidth: '140', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Source
      key: ViewConstants.CERTIFIED_YEARLY, fieldName: 'yearlyCertifiedSalesCount', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '140', columnMinWidth: '140',
      columnMaxWidth: '140', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// De Certify reason
      key: ViewConstants.NON_CERTIFIED_DAILY, fieldName: 'dailyNonCertifiedSalesCount',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.NON_CERTIFIED_MONTHLY, fieldName: 'monthlyNonCertifiedSalesCount',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.NON_CERTIFIED_YEARLY, fieldName: 'yearlyNonCertifiedSalesCount',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.TOTAL_LEXUS_DAILY, fieldName: 'totalLexusDaily',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.TOTAL_LEXUS_MONTHLY, fieldName: 'totalLexusMonthly',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.TOTAL_LEXUS_YEARLY, fieldName: 'totalLexusYearly',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.MONTH_OBJ, fieldName: 'monthObj',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.PER_OBJ, fieldName: 'perMonthObj',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.WHOLESALE_DAILY, fieldName: 'dailyWholeSalesCount',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.WHOLESALE_MONTHLY, fieldName: 'monthlyWholeSalesCount',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.WHOLESALE_YEARLY, fieldName: 'yearlyWholeSalesCount',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.ON_HAND, fieldName: 'currentInventory',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.CERT, fieldName: 'certifiedInventory',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.PER_CERT_AVAIL, fieldName: 'avgcertAvail',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
      tooltip: '(Certified Inventory/Current Inventory) x 100'
    },
    {// De Certify reason
      key: ViewConstants.PER_RTL_AVAIL, fieldName: 'avgRTLAvail',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH,
      tooltip: 'Total Sales Monthly / (Total Sales Monthly + Current Inventory)'
    }
  ];
}
