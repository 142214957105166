import { Model } from './../../../../services/model/Model';
import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    { // Region No
      key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'areaRegionNo', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '80',
      columnMinWidth: '80', columnMaxWidth: '80', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.STARTS_WITH
    },
    {// Region Name
      key: ViewConstants.DEALER_REGION_NAME_STRING_COLUMN, fieldName: 'regionName',
      style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_4_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// dealer #
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer Name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '150', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.VIN, fieldName: 'vin',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '185', columnMinWidth: '185',
      columnMaxWidth: '185', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.MODEL_NUMBER,
      fieldName: 'model.modelNumber', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.MODEL_YEAR,
      fieldName: 'model.modelYear', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.INT_COLOR,
      fieldName: 'intColor.colorDesc', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// INSPECTION RO
      key: ViewConstants.EXT_COLOR,
      fieldName: 'extColor.colorDesc', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Retail Submitted
      key: ViewConstants.RETAIL_SUBMIT,
      fieldName: 'rdrDetail.createdAt', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Retail Date
      key: ViewConstants.SALE_DATE,
      fieldName: 'rdrDetail.retailDate', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Sales Code
      key: ViewConstants.SALES_CODE,
      fieldName: 'rdrDetail.saleType', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Certification Number
      key: ViewConstants.CERTIFICATION_NUMBER,
      fieldName: 'certificationNumber', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Accessories
      key: ViewConstants.ACCESSORIES,
      fieldName: 'accessories', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Customer Name
      key: ViewConstants.CUSTOMER_NAME,
      fieldName: 'rdrDetail.buyer.firstName', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Sales Person Id
      key: ViewConstants.SALES_PERSON,
      fieldName: 'rdrDetail.salesPersonId', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// F&I Manager
      key: ViewConstants.FI_MANAGER,
      fieldName: 'rdrDetail.financeInsuranceManagerId', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// F&I Manager
      key: ViewConstants.STOCK_NUMBER,
      fieldName: 'stockNumber', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }
  ];
}
