
'use strict';
import * as models from '../model/models';
import { SharedFunctionService } from '../common/shared-function.service';

// Vehicle Detail View Model
export interface DealerParticipantView {
    // Selected Vehicle detail
    // // Selected VIN data
    vehicleData?: models.DealerParticipant;
    // selectedModelVinStockData: string[];
    message?: models.Message;
    // page type
    pageType?: string;
}
