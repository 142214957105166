

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as viewModels from '../services/view-model/view-models';
import * as detailActions from '../actions/vehicle-decertification.action';
import * as models from '../services/model/models';
import { isNil, cloneDeep } from 'lodash';
import { SharedFunctionService } from '../services/common/shared-function.service';
import { ScreenMessages } from '../shared/master-data/messages';
import { ViewConstants } from '../shared/shared.constants';
/**
 * Interface used to maintain the state for the vehicle detail screen
 */
export interface VehicleDecertificationsState {
  vehicleDetail: viewModels.VehicleDetailView;
}

/**
 * Variable used to store the initial state of VehicleDetailsState
 */
const initialState: VehicleDecertificationsState = {
  vehicleDetail: null
};

/**
 * Method used to set the default initial state of VehicleDetailsState
 */
function setDefaulValue(): viewModels.VehicleDetailView {
  let vehicleDetails: viewModels.VehicleDetailView =
    <viewModels.VehicleDetailView>new Object();
  vehicleDetails.vehicleData = {};
  vehicleDetails.message = null;
  vehicleDetails.pageType = '';
  return vehicleDetails;
}

/**
 * Reducer method to handle the vehicle detail screen actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): VehicleDecertificationsState {
  switch (action.type) {

    case detailActions.actionTypes.LOAD_VIN_INFORMATION_SUCCESS: {
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: formatDataforDetailsView(cloneDeep(action.payload)),
        pageType: ViewConstants.ADD,
        message: null
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.LOAD_VIN_INFORMATION_FAIL: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      if (errorResponse.errorCode === 'BAD_REQUEST') {
        message.message = ScreenMessages.INTERNAL_SERVER_ERROR;
      } else {
        message.message = ScreenMessages.INVALID_VIN_NO;
      }
      message.type = models.MessageType.DANGER;
      let vehicleDetailData: viewModels.VehicleDetailView = setDefaulValue();
      vehicleDetailData.message = message;
      vehicleDetailData.vehicleData = cloneDeep(action.payload);
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.SAVE_VEHICLE_SUCESS_ACTION: {
      let message: models.Message = {};
      if (action.payload.makeCode === 'TOYOTA')
        message.message = ScreenMessages.ADD_NEW_VEHICLE_SUCCESS;
      else if ((action.payload.makeCode === 'LEXUS' ||
        action.payload.makeCode === 'LEX') &&
        action.payload.certificationFlag === 'false')
        message.message = ScreenMessages.ADD_NEW_VEHICLE_SUCCESS_LEXUS;
      else if ((action.payload.makeCode === 'LEXUS' ||
        action.payload.makeCode === 'LEX') &&
        action.payload.certificationFlag === 'true'
        && !isNil(action.payload.isTradedVehicle) &&
        action.payload.isTradedVehicle === 'true')
        message.message = ScreenMessages.ADD_NEW_VEHICLE_TRADE_SUCCESS_LEXUS;
      else
        message.message = ScreenMessages.UPDATE_RECORD_SUCCESS;

      message.type = models.MessageType.SUCCESS;

      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload),
        pageType: ViewConstants.ADD,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.SAVE_VEHICLE_FAIL_ACTION: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      let pageType;
      let tempVehicleData = cloneDeep(state.vehicleDetail);
      if (errorResponse.errorCode === 'CERTIFICATION_VALIDATION_FAILED') {
        if ((state.vehicleDetail.vehicleData.makeCode === 'LEXUS' ||
          state.vehicleDetail.vehicleData.makeCode === 'LEX')
          && (isNil(action.payload.isTradedVehicle) ||
            action.payload.isTradedVehicle === 'false')) {
          message.message =
            'Vehicle has been added but certification failed. '
            + cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle cannot be added. ' + cloneDeep(action.payload.errorDesc);
        }
        pageType = errorResponse.errorCode;
        if (!isNil(action.payload.isTradedVehicle)) {
          tempVehicleData.vehicleData.isTradedVehicle = action.payload.isTradedVehicle;
        }
        tempVehicleData =
          clearCertification(tempVehicleData, 'F', action.payload.deCertifyReason);
      } else {
        if (!isNil(action.payload.errorDesc)) {
          message.message = 'Vehicle cannot be added. ' + cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle cannot be added. Internal Server Error.';
        }
        pageType = ViewConstants.FAIL;
      }
      message.type = models.MessageType.DANGER;
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.CLEAR_ALL_ACTION: {
      return { vehicleDetail: setDefaulValue() };
    }

    default:
      return state;
  }

}
/* tslint:enable:no-switch-case-fall-through */
/**
 * Method to get the state of VehicleDetailsState for vehicle detail screen
 */
export function getVehicleDetailsForSelectedVins(state: VehicleDecertificationsState) {
  return state.vehicleDetail;
}


function clearCertification(tempVehicleData: viewModels.VehicleDetailView,
  certificationStatus: string, deCertifyReason: string): viewModels.VehicleDetailView {
  tempVehicleData.vehicleData.repairOrder = <models.RepairOrder>new Object();
  tempVehicleData.vehicleData.repairOrder.reconditionCost = null;
  tempVehicleData.vehicleData.repairOrder.repairOrderNumber1 = null;
  tempVehicleData.vehicleData.certificationNumber = '';
  tempVehicleData.vehicleData.certificationStatus = certificationStatus;
  tempVehicleData.vehicleData.deCertifyReason = deCertifyReason;
  if (!isNil(tempVehicleData.vehicleData.certification)) {
    tempVehicleData.vehicleData.certification.certificationDate = '';
    tempVehicleData.vehicleData.certification.warrantyCode = '';
    tempVehicleData.vehicleData.certification.warrantyExpiryDate = '';
    tempVehicleData.vehicleData.certification.forceCertificationFlag = '';
  }

  tempVehicleData.vehicleData.repairOrder = <models.RepairOrder>new Object();
  tempVehicleData.vehicleData.repairOrder.repairOrderNumber1 = null;
  tempVehicleData.vehicleData.repairOrder.reconditionalEndDate = null;
  tempVehicleData.vehicleData.repairOrder.dateofFirstRepairOrder = null;
  tempVehicleData.vehicleData.repairOrder.repairOrderNumber2 = null;
  tempVehicleData.vehicleData.repairOrder.dateofSecondRepairOrder = null;
  tempVehicleData.vehicleData.repairOrder.reconditionCost = null;
  tempVehicleData.vehicleData.inspection = <models.Inspection>new Object();
  tempVehicleData.vehicleData.inspection.inspectionApprovedDate = null;
  tempVehicleData.vehicleData.inspection.inspectionFormNumber = null;



  return tempVehicleData;
}
/**
 * Method used to frame the required state for the vehicle detail screen
 */
function formatDataforDetailsView(data: models.Vehicle): models.Decertification {
  let vehicleDetailData: any = setDefaulValue();
  if (data) {
    let decertificationObj = <models.Decertification>new Object();
    decertificationObj.VIN_NUMBER = data.vin;
    if (data.InEligibleFlag === 'Y') {
      decertificationObj.DECERTIFICATION_SOURCE_CODE = data.decertificationSourceCode;
      decertificationObj.DECERTIFICATION_REASON_CODE = data.decertificationReasonCode;
      decertificationObj.DECERTIFICATION_COMMENTS = data.decertificationComments;
      decertificationObj.UNBLOCK_COMMENT = null;
    } else {
      decertificationObj.DECERTIFICATION_SOURCE_CODE = null;
      decertificationObj.DECERTIFICATION_REASON_CODE = null;
      decertificationObj.DECERTIFICATION_COMMENTS = null;
      decertificationObj.UNBLOCK_COMMENT = data.unblockComments;
    }
    decertificationObj.DAMAGE_AMOUNT = null;
    decertificationObj.InEligibleFlag = data.InEligibleFlag;
    vehicleDetailData = decertificationObj;
  }
  return vehicleDetailData;
}

