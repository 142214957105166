<form name="rdrDetailForm" fxFlex="100" fxFlex.xs="100" fxLayout="column" autocomplete="off" #rdrDetailForm="ngForm">

    <div style="height:20rem" fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" layout-md="column">
        <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
            <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
                <mat-card fxFlex="50" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">
                    <mat-card-header>
                        <mat-card-title style="font-weight:bold">ROLE MANAGEMENT</mat-card-title>
                    </mat-card-header>
                    <mat-card-content style="height:100%">
                        <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                            <div fxFlex="5"></div>
                                            <div fxFlex="35" class="dd-veh-summary-header">
                                                <span class="MandatorySymbol"> * </span> USER ROLES
                                            </div>
                                            <div fxFlex="55" class="dd-veh-summary-value">
                                                <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.12) !important;max-width:100%;" class="mat-no-underline" name="rolename"
                                                    [(ngModel)]="_vehicleDetails.vehicleData.role" #rolename="ngModel" (selectionChange)="getVehicleDetails(_vehicleDetails.vehicleData.role)">
                                                    <mat-option [value]="">
                                                        -- select --
                                                    </mat-option>
                                                    <mat-option *ngFor="let i of _roleDetails" [value]="i.role">{{i.role}}</mat-option>
                                                </mat-select>
                                                <mat-error>
                                                    <span class="error-font" *ngIf="!this._vehicleDetails.vehicleData.role && (this.isErrorMessageDisplay)">
                                                Role cannot be blank
                                            </span>
                                                </mat-error>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                            <div fxFlex="5"></div>
                                            <div fxFlex="35" class="dd-veh-summary-header">
                                                <span class="MandatorySymbol"> * </span> RESTRICTED MODULE
                                            </div>
                                            <div fxFlex="55" class="dd-veh-summary-value">
                                                <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.12) !important;max-width:100%;" class="mat-no-underline"
                                                    name="buyerSal" (selectionChange)="getPermission(moduleName)"
                                                    [(ngModel)]="moduleName" #buyerSal="ngModel">
                                                    <!--<mat-select-trigger>
                                                        {{buyerSal.value ? buyerSal.value[0] : ''}}
                                                        <span *ngIf="buyerSal.value?.length > 1" class="example-additional-selection">
                                                            (+{{buyerSal.value.length - 1}} {{buyerSal.value?.length === 2 ? 'other' : 'others'}})
                                                        </span>
                                                    </mat-select-trigger>-->
                                                    <mat-option *ngFor="let i of this._vehicleDetails.vehicleData.restrictedModules" [value]="i.name">{{i.value}}</mat-option>
                                                </mat-select>
                                                <mat-error>
                                                    <span class="error-font" *ngIf="!moduleName && (this.isErrorMessageDisplay)">
                                                Restricted module cannot be blank
                                            </span>
                                                </mat-error>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                            <div fxFlex="5"></div>
                                            <div fxFlex="35" class="dd-veh-summary-header">
                                                <span class="MandatorySymbol"> * </span> PERMISSION
                                            </div>
                                            <div fxFlex="55" class="dd-veh-summary-value">
                                                <mat-select [ngClass]="dropdownStyle" style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.12) !important;max-width:100%;" class="mat-no-underline" name="permissionName"
                                                    [(ngModel)]="permission" #permissionName="ngModel">
                                                    <mat-option [value]="">
                                                        -- select --
                                                    </mat-option>
                                                    <mat-option *ngFor="let i of permissionItems" [value]="i.value">{{i.name}}</mat-option>
                                                </mat-select>
                                                <mat-error>
                                                    <span class="error-font" *ngIf="!permission && (this.isErrorMessageDisplay)">
                                                Permission cannot be blank
                                            </span>
                                                </mat-error>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                             <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                                    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                                            <div fxFlex="5"></div>
                                            <div fxFlex="35" class="dd-veh-summary-header">
                                                <span class="MandatorySymbol"> * </span> IS ACTIVE
                                            </div>
                                            <div fxFlex="55" class="dd-veh-summary-value">
                                                <mat-checkbox name="participationDealerFlg" [(ngModel)]="_vehicleDetails.vehicleData.isActiveFlag"
                                                    #participationDealerFlg="ngModel"
                                                    style="font-size:12px">
                                                </mat-checkbox>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </mat-card-content>
                </mat-card>

            </div>
        </div>
    </div>

    <!--<mat-divider></mat-divider>-->

    <div fxLayout="row" fxLayoutAlign="end center" class="dd-bottom-container" style="margin-top: 0.6%;width:51%">
        <div style="padding-right: 5px;">
            <button [disabled]="!rdrDetailForm.dirty && !isDateChanged" mat-button mat-raised-button class="dd-desription-save-button"
                type="submit" (click)="saveRDR(_vehicleDetails)">SAVE</button>
            <!--<button mat-button mat-raised-button class="dd-desription-save-button" type="submit" (click)="navigateToRDRSumamry(_rdrDetails)">CANCEL</button>-->
        </div>
    </div>
</form>