<form name="rdrDetailForm" fxFlex="100" fxFlex.xs="100" fxLayout="column" autocomplete="off" #rdrDetailForm="ngForm">
  <div class="minH35" fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column">
    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
      <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
        <mat-card fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
          class="dd-vehicle-summary-display">
          <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

            <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

            </div>

            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="20" class="dd-veh-summary-header">
                  <span class="MandatorySymbol"> * </span>VIN
                </div>

                <div *ngIf="!updateFlag" fxFlex="75" class="dd-veh-summary-value">
                  <mat-form-field>
                    <input matInput id="vinno" name="vinno" #vinno="ngModel" [(ngModel)]="_rdrDetails.rdrData.vin"
                      value="{{_rdrDetails?.vin}}" style="text-transform: uppercase;"
                      (blur)="getVehicleDetails($event.target.value)" minlength="17"
                      [ddFieldValidator]="validationTypes.ALPHANUMERIC_17_WITHOUTSPACE" class="dd-inputValues" required
                      oninvalid="this.setCustomValidity('Please Enter valid vin')" oninput="setCustomValidity('')"
                      (keyup)="this.pageEdited($event);" />
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                      *ngIf="!vinno.value && (vinno.touched || this.isErrorMessageDisplay)" align="start">VIN cannot be
                      blank</mat-hint>
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                      *ngIf="vinno.value && this.displayMsgForVin" align="start">VIN cannot be less than 17 digit
                    </mat-hint>
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                      *ngIf="vinno.value && this.makeCode==='TOYOTA' && this.displayInvalidVin" align="start">Enter a
                      valid Toyota VIN</mat-hint>
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                      *ngIf="vinno.value && (this.makeCode==='LEXUS' || this.makeCode==='LEX') && this.displayInvalidVin"
                      align="start">Enter a
                      valid Lexus VIN</mat-hint>

                    <mat-error
                      *ngIf="vinno!=='' && makeCode==='TOYOTA' && (!isValidCarFax || !isValidPQSS || !isValidForeignvin)">
                      <span class="error-font">
                        Vehicle cannot be certified
                      </span>
                    </mat-error>

                  </mat-form-field>
                </div>
                <div *ngIf="updateFlag" fxFlex="75" class="dd-veh-summary-value dd-input-veh-valueText">
                  {{_rdrDetails?.rdrData?.vin}}
                </div>
              </div>
            </div>

            <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

            </div>

            <div fxFlex="17" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="30" class="dd-veh-summary-header">
                  MODEL
                </div>
                <div fxFlex="65" class="dd-veh-summary-value dd-input-veh-valueText">
                  {{_rdrDetails?.rdrData?.model?.modelNumber}}
                </div>
              </div>
            </div>

            <div fxFlex="17" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="40" class="dd-veh-summary-header">
                  MODEL YEAR
                </div>
                <div fxFlex="55" class="dd-veh-summary-value dd-input-veh-valueText">
                  {{_rdrDetails?.rdrData?.model?.modelYear}}
                </div>
              </div>
            </div>

            <div fxFlex="17" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="45" class="dd-veh-summary-header">
                  DEALER CODE
                </div>
                <div fxFlex="55" class="dd-veh-summary-value dd-input-veh-valueText">
                  {{_rdrDetails?.rdrData?.dealerCd}}
                </div>
              </div>
            </div>

            <div fxFlex="17" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="40" class="dd-veh-summary-header">
                  CERTIFIED
                </div>
                <div fxFlex="60" class="dd-veh-summary-value dd-input-veh-valueText">
                  {{_rdrDetails?.rdrData?.certified}}
                </div>
              </div>
            </div>

          </div>

          <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

          </div>


        </mat-card>
      </div>
    </div>
  </div>

  <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" layout-md="column">
    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
      <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
        <mat-card *ngIf="!reversalFlag && !sharedDataService.isTMEXUser" [class.disabled]="reversalFlag" fxFlex="100"
          fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">
          <mat-card-header>
            <mat-card-title style="font-weight:bold">Sales Details</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>DATE OF SALE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <my-date-picker
                      [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                      name="retailDate" id="retailDate" [options]="myDatePickerOptions" [locale]="'en'"
                      [selDate]="_rdrDetails?.rdrData?.rdrDetail?.retailDate | date :'MM-dd-yyyy'"
                      (dateChanged)="onDateSelected($event,'retailDate')"  [disabled]="true"
                      (blur)="this.canShowErrorMessage('retailDate')"></my-date-picker>
                    <mat-error>
                      <span class="error-font"
                        *ngIf="!this._rdrDetails.rdrData.rdrDetail.retailDate && (this.isErrorMessageDisplay) && !reversalFlag">
                        Sale Date cannot be blank
                      </span>
                    </mat-error>
                  </div>
                </div>

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                    <span class="MandatorySymbol"> * </span>SALE MILEAGE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-form-field>
                      <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="mileage"
                        class="dd-inputValues" maxlength="6" [ddFieldValidator]="validationTypes.NUMBER"
                        [(ngModel)]="_rdrDetails.rdrData.rdrDetail.mileage" #mileage="ngModel"
                        (keyup)="this.pageEdited($event);" />
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="!mileage.value && (mileage.touched || this.isErrorMessageDisplay) && !reversalFlag"
                        align="start">Sale Mileage cannot be blank</mat-hint>
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="this.isErrorMessageDisplay && mileage.value && (mileage.value < 1) && !reversalFlag"
                        align="start">Mileage value should not be zero</mat-hint>
                      <!--<mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="makeCode=='TOYOTA' && (mileage.touched || this.isErrorMessageDisplay) && mileage.value && (mileage.value > 84999) && !isForceCertified"
                                                        align="start">Mileage is greater than certification limit, RDR will get deleted on
                                                        record submission</mat-hint >
                                                        <mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="makeCode!=='TOYOTA' && (mileage.touched || this.isErrorMessageDisplay) && mileage.value && (mileage.value > 69999) && !isForceCertified"
                                                            align="start">Mileage is greater than certification limit, RDR will get deleted
                                                            on record submission</mat-hint >-->
                    </mat-form-field>
                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-drop dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>F&I MANAGER
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">

                    <mat-select
                      [ngClass]="sharedDataService.rdrScreenAccess && (!reversalFlag || !_rdrDetails.rdrData.rdrDetail.financeInsuranceManagerId)?'dropdownEnabled':'dropdownDisabled'"
                      style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:180px !important;"
                      class="mat-no-underline" name="fAndimanager"
                      [(ngModel)]="_rdrDetails.rdrData.rdrDetail.financeInsuranceManagerId" #fAndimanager="ngModel"
                      (selectionChange)="valueChanged($event)">
                      <mat-option [value]="">
                        -- select --
                      </mat-option>
                      <mat-option *ngFor="let i of fIManagerItems" [value]="i.name">{{i.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                      <span class="error-font"
                        *ngIf="!this._rdrDetails.rdrData.rdrDetail.financeInsuranceManagerId && (this.isErrorMessageDisplay) && !reversalFlag">
                        F&I Manager cannot be blank
                      </span>
                    </mat-error>
                  </div>
                </div>

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>SALES PERSON
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">

                    <mat-select
                      [ngClass]="sharedDataService.rdrScreenAccess && (!reversalFlag || !_rdrDetails.rdrData.rdrDetail.salesPersonId)?'dropdownEnabled':'dropdownDisabled'"
                      style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:180px !important;"
                      class="mat-no-underline" name="salesPerson"
                      [(ngModel)]="_rdrDetails.rdrData.rdrDetail.salesPersonId" #salesPerson="ngModel">
                      <mat-option [value]="">
                        -- select --
                      </mat-option>
                      <mat-option *ngFor="let i of saleManagerItems" [value]="i.name">{{i.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                      <span class="error-font"
                        *ngIf="!this._rdrDetails.rdrData.rdrDetail.salesPersonId && (this.isErrorMessageDisplay) && !reversalFlag">
                        Sales Person cannot be blank
                      </span>
                    </mat-error>
                  </div>
                </div>
              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>SALES CODE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-select *ngIf = "isSETDealer"
                    style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:180px !important;"
                    class="mat-no-underline dropdownDisabled" name="salesCode" 
                    [(ngModel)]="_rdrDetails.rdrData.rdrDetail.saleType" #salesCode="ngModel">
                    <mat-option *ngFor="let i of salesCodeitemsSET" [value]="i.value">{{i.name}}</mat-option>
                  </mat-select>
                    <mat-select *ngIf = "!isSETDealer"
                      [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                      style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:180px !important;"
                      class="mat-no-underline" name="salesCode" (selectionChange)="salesValueChanged($event)"
                      [(ngModel)]="_rdrDetails.rdrData.rdrDetail.saleType" #salesCode="ngModel">
                      <mat-option [value]="">
                        -- select --
                      </mat-option>
                      <mat-option *ngFor="let i of salesCodeitems" [value]="i.value">{{i.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                      <span class="error-font"
                        *ngIf="!this._rdrDetails.rdrData.rdrDetail.saleType && (this.isErrorMessageDisplay) && !reversalFlag">
                        Sales Code cannot be blank
                      </span>
                    </mat-error>
                  </div>
                </div>

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                  class="dd-veh-summary-desc dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                    <span class="MandatorySymbol"> * </span> SALES PRICE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-form-field>
                      <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="salesPrice" maxlength="15"
                        [ddFieldValidator]="validationTypes.NUMBER_WITH_DECIMAL_2" class="dd-inputValues"
                        #salesPrice="ngModel" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.soldAmount" required
                        class="dd-inputValues" (keyup)="this.pageEdited($event);" />
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="!salesPrice.value && (this.isErrorMessageDisplay)" align="start">Sales
                        Price cannot be blank</mat-hint>
                      <!-- As part of scrum task 'UCM-1138' and 'UCM-1170', Business users asked to the remove the validations for acvPrice, retailPrice and salesPrice -->
                      <!-- <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf=" makeCode=='TOYOTA' && !sharedDataService.isTMEXUser && (this.isErrorMessageDisplay || salesPrice.touched) && salesPrice.value && (salesPrice.value < 7000 || salesPrice.value > 99000)"
                        align="start">Enter a valid price between $7,000 - $99,000</mat-hint > -->
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="(this.isErrorMessageDisplay || salesPrice.touched) && salesPrice.value && (salesPrice.value < 1)"
                        align="start">Enter a valid price</mat-hint>
                      <!-- As part of scrum task 'UCM-1138' and 'UCM-1170', Business users asked to the remove the validations for acvPrice, retailPrice and salesPrice -->
                      <!-- <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="makeCode!=='TOYOTA' && (this.isErrorMessageDisplay || salesPrice.touched) && salesPrice.value && (salesPrice.value < 4000 || salesPrice.value > 400000) && !reversalFlag"
                        align="start">Enter a valid price between $4,000 - $ 400,000</mat-hint > -->
                    </mat-form-field>
                  </div>

                </div>
              </div>

              <div *ngIf="!reversalFlag  && regionNo != '50'" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100"
                fxLayout="column">
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">

                </div>

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">

                </div>
              </div>

              <div *ngIf="!reversalFlag && regionNo === '50'" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100"
                fxLayout="column">
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">

                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>WARRANTY CONTRACT NO
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-form-field style="max-width:100%">
                      <input type="text" [readonly]="!sharedDataService.rdrScreenAccess" maxlength="12" matInput
                        name="buyerWarantyConNum" pattern="^([A-Z]{1})([0-9]{11})$" required
                        [(ngModel)]="_rdrDetails.rdrData.rdrDetail.warrantyContractNumber" #buyerWarantyConNum="ngModel"
                        (blur)="checkWarrantyContractNum($event)" class="dd-inputValues" />

                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="!buyerWarantyConNum.value && (this.isErrorMessageDisplay && isIndividualCustomer) && !reversalFlag"
                        align="start">warranty Contract Number cannot be blank</mat-hint>
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="(this.isErrorMessageDisplay || buyerWarantyConNum.touched) && buyerWarantyConNum.value && !isValidWarrantyContractNum && !reversalFlag"
                        align="start">Invalid warranty contract number</mat-hint>
                    </mat-form-field>
                  </div>

                </div>

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                  class="dd-veh-summary-desc dd-veh-header-summary-desc dd-warranty-con">
                  <div fxFlex="5"></div>
                  <div fxFlex="60" class="dd-veh-summary-header mTop4em">
                  </div>
                  <div fxFlex="40" class="dd-veh-summary-value">
                  </div>
                </div>
              </div>
              <!-- <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">

                </div> -->




            </div>
          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="reversalFlag || sharedDataService.isTMEXUser" [class.disabled]="reversalFlag" fxFlex="70"
          fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">
          <mat-card-header>
            <mat-card-title style="font-weight:bold">Sales Details</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>DATE OF SALE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <my-date-picker
                      [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                      name="retailDate" id="retailDate" [options]="myDatePickerOptions" [locale]="'en'"
                      [selDate]="_rdrDetails?.rdrData?.rdrDetail?.retailDate | date :'MM-dd-yyyy'"
                      (dateChanged)="onDateSelected($event,'retailDate')" [disabled]="true"
                      (blur)="this.canShowErrorMessage('retailDate')"></my-date-picker>
                    <mat-error>
                      <span class="error-font"
                        *ngIf="!this._rdrDetails.rdrData.rdrDetail.retailDate && (this.isErrorMessageDisplay) && !reversalFlag">
                        Sale Date cannot be blank
                      </span>
                    </mat-error>
                  </div>
                </div>

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                    <span class="MandatorySymbol"> * </span>SALE MILEAGE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-form-field>
                      <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="mileage"
                        class="dd-inputValues" maxlength="6" [ddFieldValidator]="validationTypes.NUMBER"
                        [(ngModel)]="_rdrDetails.rdrData.rdrDetail.mileage" #mileage="ngModel"
                        (keyup)="this.pageEdited($event);" />
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="!mileage.value && (mileage.touched || this.isErrorMessageDisplay) && !reversalFlag"
                        align="start">Sale Mileage cannot be blank</mat-hint>
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="this.isErrorMessageDisplay && mileage.value && (mileage.value < 1) && !reversalFlag"
                        align="start">Mileage value should not be zero</mat-hint>
                    </mat-form-field>
                  </div>

                </div>

                <div fxFlex="100" *ngIf="regionNo === '50'" fxLayoutAlign="center center" fxLayout="row"
                  fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-summary-desc-text dd-veh-header-summary-desc">

                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>WARRANTY CONTRACT NO
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-form-field style="max-width:100%">
                      <input type="text" [readonly]="!sharedDataService.rdrScreenAccess" maxlength="12" matInput
                        name="buyerWarantyConNum" pattern="^([A-Z]{1})([0-9]{11})$" required
                        [(ngModel)]="_rdrDetails.rdrData.rdrDetail.warrantyContractNumber" #buyerWarantyConNum="ngModel"
                        (blur)="checkWarrantyContractNum($event)" class="dd-inputValues" />

                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="!buyerWarantyConNum.value && (this.isErrorMessageDisplay && isIndividualCustomer) && !reversalFlag"
                        align="start">warranty Contract Number cannot be blank</mat-hint>
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="(this.isErrorMessageDisplay || buyerWarantyConNum.touched) && buyerWarantyConNum.value && !isValidWarrantyContractNum && !reversalFlag"
                        align="start">Invalid warranty contract number</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-drop dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>F&I MANAGER
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">

                    <mat-select
                      [ngClass]="sharedDataService.rdrScreenAccess && (!reversalFlag || !_rdrDetails.rdrData.rdrDetail.financeInsuranceManagerId)?'dropdownEnabled':'dropdownDisabled'"
                      style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:180px !important;"
                      class="mat-no-underline" name="fAndimanager"
                      [(ngModel)]="_rdrDetails.rdrData.rdrDetail.financeInsuranceManagerId" #fAndimanager="ngModel"
                      (selectionChange)="valueChanged($event)">
                      <mat-option [value]="">
                        -- select --
                      </mat-option>
                      <mat-option *ngFor="let i of fIManagerItems" [value]="i.name">{{i.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                      <span class="error-font"
                        *ngIf="!this._rdrDetails.rdrData.rdrDetail.financeInsuranceManagerId && (this.isErrorMessageDisplay) && !reversalFlag">
                        F&I Manager cannot be blank
                      </span>
                    </mat-error>
                  </div>
                </div>

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>SALES PERSON
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">

                    <mat-select
                      [ngClass]="sharedDataService.rdrScreenAccess && (!reversalFlag || !_rdrDetails.rdrData.rdrDetail.salesPersonId)?'dropdownEnabled':'dropdownDisabled'"
                      style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:180px !important;"
                      class="mat-no-underline" name="salesPerson"
                      [(ngModel)]="_rdrDetails.rdrData.rdrDetail.salesPersonId" #salesPerson="ngModel">
                      <mat-option [value]="">
                        -- select --
                      </mat-option>
                      <mat-option *ngFor="let i of saleManagerItems" [value]="i.name">{{i.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                      <span class="error-font"
                        *ngIf="!this._rdrDetails.rdrData.rdrDetail.salesPersonId && (this.isErrorMessageDisplay) && !reversalFlag">
                        Sales Person cannot be blank
                      </span>
                    </mat-error>
                  </div>
                </div>

                <div *ngIf="regionNo === '50'" fxFlex="100" fxLayoutAlign="center center" fxLayout="row"
                  fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span>SALES CODE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">

                    <mat-select
                      [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                      style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:180px !important;"
                      class="mat-no-underline" name="salesCode" (selectionChange)="salesValueChanged($event)"
                      [(ngModel)]="_rdrDetails.rdrData.rdrDetail.saleType" #salesCode="ngModel">
                      <mat-option [value]="">
                        -- select --
                      </mat-option>
                      <mat-option *ngFor="let i of salesCodeitems" [value]="i.value">{{i.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                      <span class="error-font"
                        *ngIf="!this._rdrDetails.rdrData.rdrDetail.saleType && (this.isErrorMessageDisplay) && !reversalFlag">
                        Sales Code cannot be blank
                      </span>
                    </mat-error>
                  </div>
                </div>

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                  class="dd-veh-summary-desc dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                    <span class="MandatorySymbol"> * </span> SALES PRICE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-form-field>
                      <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="salesPrice" maxlength="15"
                        [ddFieldValidator]="validationTypes.NUMBER_WITH_DECIMAL_2" class="dd-inputValues"
                        #salesPrice="ngModel" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.soldAmount" required
                        class="dd-inputValues" (keyup)="this.pageEdited($event);" />
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="!salesPrice.value && (this.isErrorMessageDisplay)" align="start">Sales Price cannot be
                        blank</mat-hint>
                      <!-- As part of scrum task 'UCM-1138' and 'UCM-1170', Business users asked to the remove the validations for acvPrice, retailPrice and salesPrice -->
                      <!-- <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf=" makeCode=='TOYOTA' && (this.isErrorMessageDisplay || salesPrice.touched) && salesPrice.value && (salesPrice.value < 7000 || salesPrice.value > 99000) && !reversalFlag"
                        align="start">Enter a valid price between $7,000 - $99,000</mat-hint >
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="makeCode!=='TOYOTA' && (this.isErrorMessageDisplay || salesPrice.touched) && salesPrice.value && (salesPrice.value < 4000 || salesPrice.value > 400000) && !reversalFlag"
                        align="start">Enter a valid price between $4,000 - $ 400,000</mat-hint > -->
                    </mat-form-field>
                  </div>

                </div>

                <div *ngIf="regionNo === '50'" fxFlex="100" fxLayoutAlign="center center" fxLayout="row"
                  fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                </div>
              </div>

              <div *ngIf="!reversalFlag && !sharedDataService.isTMEXUser && regionNo != '50'" fxFlex="100"
                fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">

                </div>

                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">

                </div>
              </div>

            </div>
          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="!reversalFlag && sharedDataService.isTMEXUser" fxFlex="25" fxLayout="column"
          fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">
          <mat-card-header>
            <mat-card-title style="font-weight:bold">&nbsp;&nbsp;</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
                <div fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="40" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span> ACV PRICE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-form-field>
                      <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="acvPrice"
                        class="dd-inputValues" #acvPrice="ngModel"
                        [(ngModel)]="_rdrDetails.rdrData.rdrDetail.price.acvPrice" class="dd-inputValues"
                        (keyup)="this.pageEdited($event);" />
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="!acvPrice.value && (acvPrice.touched || this.isErrorMessageDisplay)" align="start">ACV
                        price cannot be blank</mat-hint>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                  class="dd-veh-summary-desc dd-veh-header-summary-desc">
                  <div fxFlex="40" class="dd-veh-summary-header mTop4em">
                    <span class="MandatorySymbol"> &nbsp;&nbsp; </span> Total Vehicle Cost
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-form-field>
                      <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="rdrDescription"
                        class="dd-inputValues" #rdrDescription="ngModel"
                        [(ngModel)]="_rdrDetails.rdrData.rdrDetail.price.totalVehicleCost" class="dd-inputValues"
                        (keyup)="this.pageEdited($event);" />
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="!rdrDescription.value && (rdrDescription.touched || this.isErrorMessageDisplay)"
                        align="start">Total Vehicle Cost cannot be blank</mat-hint>
                    </mat-form-field>
                  </div>

                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="reversalFlag" fxFlex="30" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column"
          class="dd-vehicle-summary-display">
          <mat-card-header>
            <mat-card-title style="font-weight:bold">Reversal Details</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div [class.disabled]="reversedFlag" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
                <div fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                  class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                  <div fxFlex="40" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span> CODE
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">

                    <mat-select
                      [ngClass]="sharedDataService.rdrScreenAccess && !reversedFlag?'dropdownEnabled':'dropdownDisabled'"
                      style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:180px !important;"
                      class="mat-no-underline" name="revCode" title="{{_rdrDetails.rdrData.rdrDetail.reversalType}}"
                      [(ngModel)]="_rdrDetails.rdrData.rdrDetail.reversalType" #salesCode="ngModel">
                      <mat-option [value]="">
                        -- select --
                      </mat-option>
                      <mat-option *ngFor="let i of reversalCodeitems" [value]="i.value">{{i.name}}</mat-option>
                    </mat-select>
                    <mat-error>
                      <span class="error-font"
                        *ngIf="!this._rdrDetails.rdrData.rdrDetail.reversalType && (this.isErrorMessageDisplay)">
                        reversal Code cannot be blank
                      </span>
                    </mat-error>
                  </div>
                </div>

                <div fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                  class="dd-veh-summary-desc dd-veh-header-summary-desc">
                  <div fxFlex="40" class="dd-veh-summary-header mTop4em">
                    <span class="MandatorySymbol"> * </span> DESCRIPTION
                  </div>
                  <div fxFlex="60" class="dd-veh-summary-value">
                    <mat-form-field>
                      <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="rdrDescription"
                        class="dd-inputValues" #rdrDescription="ngModel"
                        [(ngModel)]="_rdrDetails.rdrData.rdrDetail.reversalReason" class="dd-inputValues"
                        (keyup)="this.pageEdited($event);" />
                      <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                        *ngIf="!rdrDescription.value && (rdrDescription.touched || this.isErrorMessageDisplay)"
                        align="start">Reversal Description cannot be blank</mat-hint>
                    </mat-form-field>
                  </div>

                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>


  <div *ngIf="sharedDataService.isTMEXUser" class="minH35" fxFlex="100" fxLayout="row" fxLayout.xs="column"
    fxLayout.sm="column" layout-md="column">
    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
      <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
        <mat-card fxFlex="reversalFlag?75:100" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column"
          class="dd-vehicle-summary-display">
          <mat-card-header>
            <mat-card-title style="font-weight:bold">Charges and Fees</mat-card-title>
          </mat-card-header>
          <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                  <span class="MandatorySymbol"> &nbsp;&nbsp; </span>LABOR
                </div>
                <div fxFlex="60" class="dd-veh-summary-value dd-input-veh-valueText">
                  {{_rdrDetails?.rdrData?.rdrDetail?.price?.labor}}
                </div>
              </div>

            </div>

            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="35" class="dd-veh-summary-header">
                  <span class="MandatorySymbol"> &nbsp;&nbsp; </span>PARTS
                </div>
                <div fxFlex="60" class="dd-veh-summary-value dd-input-veh-valueText">
                  {{_rdrDetails?.rdrData?.rdrDetail?.price?.parts}}
                </div>
              </div>
            </div>

            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                  <span class="MandatorySymbol"> * </span> WARRANTY
                </div>
                <div fxFlex="60" class="dd-veh-summary-value dd-input-veh-valueText">
                  {{_rdrDetails?.rdrData?.certification?.planAmount}}
                </div>
              </div>
            </div>


            <div *ngIf="!reversalFlag" fxFlex="24" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                class="dd-veh-summary-desc-text dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="45" class="dd-veh-summary-header">
                  <span class="MandatorySymbol"> &nbsp;&nbsp; </span> COMMENT
                </div>
                <div fxFlex="100" class="dd-veh-summary-value">
                  <mat-form-field>
                    <textarea [readonly]="!sharedDataService.rdrScreenAccess" matInput name="comments" maxlength="25"
                      class="text-area" #comments="ngModel" [(ngModel)]="_rdrDetails.rdrData.comment"
                      (keyup)="this.pageEdited($event);"> </textarea>
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }" *ngIf="displayMsgForComments"
                      align="start">Comment cannot be blank</mat-hint>
                  </mat-form-field>

                </div>
              </div>

              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                class="dd-veh-summary-desc-text dd-veh-header-summary-desc">

              </div>
            </div>

          </div>

        </mat-card>
      </div>
    </div>
  </div>

  <div [class.disabled]="reversalFlag" fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
    layout-md="column">
    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
      <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
        <mat-card fxFlex="50" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column"
          class="dd-vehicle-summary-display">
          <mat-card-header>
            <mat-card-title style="font-weight:bold">Customer Details</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header">
                        <span *ngIf="isIndividualCustomer" class="MandatorySymbol"> * </span>
                        <span *ngIf="!isIndividualCustomer" class="MandatorySymbol"> </span> SALUTATION
                      </div>
                      <div fxFlex="55" class="dd-veh-summary-value">
                        <mat-select
                          [ngClass]="sharedDataService.rdrScreenAccess && (!reversalFlag || !_rdrDetails.rdrData.rdrDetail.buyer.salution)?'dropdownEnabled':'dropdownDisabled'"
                          style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
                          class="mat-no-underline" name="buyerSal"
                          [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.salution" #buyerSal="ngModel">
                          <mat-option [value]="">
                            -- select --
                          </mat-option>
                          <mat-option *ngFor="let i of salutationitems" [value]="i.value">{{i.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                          <span class="error-font"
                            *ngIf="!this._rdrDetails.rdrData.rdrDetail.buyer.salution && (this.isErrorMessageDisplay && this.isIndividualCustomer) && !reversalFlag">
                            Salutation cannot be blank
                          </span>
                        </mat-error>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span *ngIf="isIndividualCustomer" class="MandatorySymbol"> * </span>
                        <span *ngIf="!isIndividualCustomer" class="MandatorySymbol"> </span> FIRST
                        NAME
                      </div>
                      <div *ngIf="!sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxFirstName" matInput
                            name="buyerFirstname" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.firstName"
                            #buyerFirstname="ngModel" class="dd-inputValues" [required]="isIndividualCustomer"
                            oninvalid="this.setCustomValidity('Please enter Retail Price')"
                            oninput="setCustomValidity('')" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!buyerFirstname.value || buyerFirstname.value.trim().length == 0) && (this.isErrorMessageDisplay && this.isIndividualCustomer) && !reversalFlag"
                            align="start">First name cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div *ngIf="sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxFirstName" matInput
                            name="buyerFirstname" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.firstName"
                            #buyerFirstname="ngModel" class="dd-inputValues"
                            oninvalid="this.setCustomValidity('Please enter Retail Price')"
                            oninput="setCustomValidity('')" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!buyerFirstname.value || buyerFirstname.value.trim().length == 0) && (this.isErrorMessageDisplay && this.isIndividualCustomer) && !reversalFlag"
                            align="start">First name cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> * </span>LAST NAME / CO. NAME
                      </div>
                      <div *ngIf="!sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxBuyerLastName" matInput
                            name="buyerLastname" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.lastName"
                            #buyerLastname="ngModel" class="dd-inputValues" required
                            oninvalid="this.setCustomValidity('Please enter Retail Price')"
                            oninput="setCustomValidity('')" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!buyerLastname.value || buyerLastname.value.trim().length == 0) && (buyerLastname.touched || this.isErrorMessageDisplay) && !reversalFlag"
                            align="start">Last name cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div *ngIf="sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxBuyerLastName" matInput
                            name="buyerLastname" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.lastName"
                            #buyerLastname="ngModel" class="dd-inputValues"
                            oninvalid="this.setCustomValidity('Please enter Retail Price')"
                            oninput="setCustomValidity('')" (keyup)="this.pageEdited($event);" required />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!buyerLastname.value || buyerLastname.value.trim().length == 0) && (buyerLastname.touched || this.isErrorMessageDisplay) && !reversalFlag"
                            align="start">Last name cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> &nbsp;&nbsp; </span> MIDDLE INITIAL
                      </div>
                      <div *ngIf="!sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" maxlength="40" matInput
                            style="width:80px!important" name="buyerMiddleinitial"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.middleName" #buyerMiddleinitial="ngModel"
                            class="dd-inputValues" oninput="setCustomValidity('')" (keyup)="this.pageEdited($event);" />
                        </mat-form-field>
                      </div>
                      <div *ngIf="sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput style="width:100%!important"
                            name="buyerMiddleinitial" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.middleName"
                            #buyerMiddleinitial="ngModel" class="dd-inputValues" oninput="setCustomValidity('')"
                            (keyup)="this.pageEdited($event);" />
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header">
                        <span class="MandatorySymbol"> &nbsp;&nbsp; </span> SUFFIX
                      </div>
                      <div fxFlex="55" class="dd-veh-summary-value">

                        <mat-select
                          [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                          style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:100%;"
                          class="mat-no-underline" name="buyerSuffix"
                          [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.suffix" #buyerSuffix="ngModel">
                          <mat-option [value]="">
                            -- select --
                          </mat-option>
                          <mat-option *ngFor="let i of suffixitems" [value]="i.value">{{i.name}}</mat-option>
                        </mat-select>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> * </span>ADDRESS
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxAddressLineOne" matInput
                            name="buyerAddress" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.addressLineOne"
                            #buyerAddress="ngModel" class="dd-inputValues dd-input-widt"
                            (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!buyerAddress.value || buyerAddress.value.trim().length == 0) && (buyerAddress.touched || this.isErrorMessageDisplay) && !reversalFlag"
                            align="start">Address cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> &nbsp;&nbsp; </span> APT. OR SUITE
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxAddressLineOne" matInput name="buyerApt"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.addressLineTwo" #buyerApt="ngModel"
                            class="dd-inputValues" (keyup)="this.pageEdited($event);" />
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>

                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5">
                        <span *ngIf="makeCode==='TOYOTA'"></span>
                      </div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> * </span>CITY
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">

                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxBuyerCity" matInput
                            name="buyerCity" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.city" #buyerCity="ngModel"
                            class="dd-inputValues" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!buyerCity.value || buyerCity.value.trim().length == 0) && (buyerCity.touched || this.isErrorMessageDisplay) && !reversalFlag"
                            align="start">City cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header">
                        <span class="MandatorySymbol"> * </span> STATE
                      </div>
                      <div fxFlex="55" class="dd-veh-summary-value">
                        <mat-select
                          [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                          style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
                          class="mat-no-underline" name="buyerState"
                          [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.state" #buyerState="ngModel">
                          <mat-option [value]="">
                            -- select --
                          </mat-option>
                          <mat-option *ngFor="let i of stateItems" [value]="i.value">{{i.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                          <span class="error-font"
                            *ngIf="!this._rdrDetails.rdrData.rdrDetail.buyer.state && (this.isErrorMessageDisplay) && !reversalFlag">
                            State cannot be blank
                          </span>
                        </mat-error>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> * </span>ZIP CODE
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="buyerZipcode"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.zipCode" (blur)="zipcodeChange($event)"
                            #buyerZipcode="ngModel" class="dd-inputValues dd-input-widt"
                            [textMask]="{mask: zipMask, guide: false}" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!buyerZipcode.value && (buyerZipcode.touched || this.isErrorMessageDisplay) && !reversalFlag"
                            align="start">Zipcode cannot be blank</mat-hint>
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(this.isErrorMessageDisplay || buyerZipcode.touched) && buyerZipcode.value && !(buyerZipcode.value.length == '5' || buyerZipcode.value.length == '6' || buyerZipcode.value.length == '10') && !reversalFlag"
                            align="start">Invalid zipcpde</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span *ngIf="isIndividualCustomer" class="MandatorySymbol"> * </span>
                        <span *ngIf="!isIndividualCustomer" class="MandatorySymbol"> </span>HOME
                        PHONE
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="buyerHomephone"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.homePhone" #buyerHomephone="ngModel"
                            class="dd-inputValues dd-input-widt"
                            [textMask]="{mask: phoneMask, guide: false, modelClean: true}" type="text"
                            (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!buyerHomephone.value && (this.isErrorMessageDisplay && isIndividualCustomer) && !reversalFlag"
                            align="start">Home Phone cannot be blank</mat-hint>
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(this.isErrorMessageDisplay || buyerHomephone.touched) && buyerHomephone.value && !(buyerHomephone.value.length == '10' || buyerHomephone.value.length == '14') && !reversalFlag"
                            align="start">Invalid Home phone number</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span *ngIf="isIndividualCustomer" class="MandatorySymbol"> * </span>
                        <span *ngIf="!isIndividualCustomer" class="MandatorySymbol"> </span> WORK
                        PHONE
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="buyerWork"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.workPhone" #buyerWork="ngModel"
                            class="dd-inputValues" [textMask]="{mask: phoneMask, guide: false}" type="text" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!buyerWork.value && (this.isErrorMessageDisplay && isIndividualCustomer) && !reversalFlag"
                            align="start">Work Phone cannot be blank</mat-hint>
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(this.isErrorMessageDisplay || buyerWork.touched) && buyerWork.value && !(buyerWork.value.length == '10' || buyerWork.value.length == '14') && !reversalFlag"
                            align="start">Invalid Work phone number</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> </span> EXT
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" maxlength="5" matInput name="buyerExt"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.extension" #buyerExt="ngModel"
                            [ddFieldValidator]="validationTypes.NUMBER" class="dd-inputValues"
                            oninput="setCustomValidity('')" (keyup)="this.pageEdited($event);" />
                          <!--<mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="!buyerExt.value && (this.isErrorMessageDisplay && isIndividualCustomer)"
                                                        align="start">Extention cannot be blank</mat-hint >-->
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span *ngIf="isEmailMandatory" class="MandatorySymbol"> &nbsp;&nbsp; </span>
                        <span *ngIf="!isEmailMandatory" class="MandatorySymbol"> * </span> EMAIL
                      </div>
                      <div *ngIf="!isEmailMandatory" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="buyerEmail"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.email" #buyerEmail="ngModel"
                            [maxlength]="maxEmail"
                            class="dd-inputValues" required (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!isEmailMandatory && (!buyerEmail.value || buyerEmail.value.trim().length == 0) && (buyerEmail.touched || this.isErrorMessageDisplay) && !reversalFlag"
                            align="start">Email cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div style="background:rgb(235, 235, 228);height:50%" *ngIf="isEmailMandatory" fxFlex="55"
                        class="dd-veh-summary-value dd-input-veh-valueText">
                        &nbsp;&nbsp;
                      </div>
                      <div *ngIf="!isEmailMandatory" fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">

                    </div>

                    <div fxFlex="5" fxLayoutAlign="left center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <mat-checkbox
                        [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                        name="isMandatoryEmail" [(ngModel)]="isEmailMandatory" (change)="emailOptions($event)"
                        style="padding-left:25px;font-size:12px">
                        Customer declined to provide email
                      </mat-checkbox>
                    </div>

                  </div>

                </div>

              </div>

              <div *ngIf="sharedDataService.isTMEXUser" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> *</span>RFC(TAX ID)
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input type="text" [readonly]="!sharedDataService.rdrScreenAccess" maxlength="13" matInput
                            name="buyerTaxId" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.taxid"
                            pattern="^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{10,}$" #buyerTaxId="ngModel" required
                            (blur)="checkMandatoryAlphaMandatoryNumeric($event,'buyer')" class="dd-inputValues" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!buyerTaxId.value || buyerTaxId.value.trim().length == 0) && (buyerTaxId.touched || this.isErrorMessageDisplay)"
                            align="start">TaxId cannot be blank</mat-hint>
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(this.isErrorMessageDisplay || buyerTaxId.touched) && buyerTaxId.value && !rfcTaxIdBuyer"
                            align="start">Invalid Tax Id</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> *</span> CONTACT
                      </div>
                      <div fxFlex="55" class="dd-veh-summary-value">
                        <mat-select [ngClass]="sharedDataService.rdrScreenAccess?'dropdownEnabled':'dropdownDisabled'"
                          style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
                          class="mat-no-underline" name="buyerContactMethod" required
                          [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.contactMethod" #buyerContactMethod="ngModel">
                          <mat-option [value]="">
                            -- select --
                          </mat-option>
                          <mat-option *ngFor="let i of contactMethods" [value]="i">{{i}}</mat-option>
                        </mat-select>
                        <mat-error>
                          <span class="error-font"
                            *ngIf="!this._rdrDetails.rdrData.rdrDetail.buyer.contactMethod && (this.isErrorMessageDisplay)">
                            Contact cannot be blank
                          </span>
                        </mat-error>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

              <div *ngIf="sharedDataService.isTMEXUser" fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="5" fxLayoutAlign="left center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <mat-checkbox [ngClass]="sharedDataService.rdrScreenAccess?'dropdownEnabled':'dropdownDisabled'"
                        name="isMandatoryData" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.customerConsent"
                        style="padding-left:25px;font-size:12px">
                        Customer declined to share the personal data
                      </mat-checkbox>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="showAdditionalDriver" fxFlex="50" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column"
          class="dd-vehicle-summary-display">
          <mat-card-header *ngIf="regionNo != '50'">
            <mat-card-title style="font-weight:bold"><button title="Remove Additional Driver" type="submit"
                (click)="hideAdditionalDriverSection()">X</button> Additional Driver</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header">
                        <span *ngIf="isIndividualCustomer" class="MandatorySymbol"> * </span>
                        <span *ngIf="!isIndividualCustomer" class="MandatorySymbol"> </span> SALUTATION
                      </div>
                      <div fxFlex="55" class="dd-veh-summary-value">
                        <mat-select
                          [ngClass]="sharedDataService.rdrScreenAccess && (!reversalFlag || !_rdrDetails.rdrData.rdrDetail.cobuyer.salution)?'dropdownEnabled':'dropdownDisabled'"
                          style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
                          class="mat-no-underline" name="coBuyerSal"
                          [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.salution" #coBuyerSal="ngModel">
                          <mat-option [value]="">
                            -- select --
                          </mat-option>
                          <mat-option *ngFor="let i of salutationitems" [value]="i.value">{{i.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                          <span class="error-font"
                            *ngIf="!this._rdrDetails.rdrData.rdrDetail.cobuyer.salution && (this.isErrorMessageDisplayForCo && this.isIndividualCustomer) && !reversalFlag">
                            Salutation cannot be blank
                          </span>
                        </mat-error>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span *ngIf="isIndividualCustomer" class="MandatorySymbol"> * </span>
                        <span *ngIf="!isIndividualCustomer" class="MandatorySymbol"> </span> FIRST
                        NAME
                      </div>
                      <div *ngIf="!sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxFirstName" matInput
                            name="coFirstname" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.firstName"
                            #coFirstname="ngModel" class="dd-inputValues" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!coFirstname.value && (this.isErrorMessageDisplayForCo && this.isIndividualCustomer) && !reversalFlag"
                            align="start">First name cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div *ngIf="sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxFirstName" matInput
                            name="coFirstname" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.firstName"
                            #coFirstname="ngModel" class="dd-inputValues" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!coFirstname.value && (this.isErrorMessageDisplayForCo && this.isIndividualCustomer) && !reversalFlag"
                            align="start">First name cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> * </span>LAST NAME / CO. NAME
                      </div>
                      <div *ngIf="!sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxBuyerLastName" matInput
                            name="coLastname" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.lastName"
                            #coLastname="ngModel" class="dd-inputValues" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!coLastname.value || coLastname.value.trim().length == 0) && this.isErrorMessageDisplayForCo && !reversalFlag"
                            align="start">Last name cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div *ngIf="sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="coLastname" [maxlength]="maxBuyerLastName"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.lastName" #coLastname="ngModel"
                            class="dd-inputValues" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!coLastname.value || coLastname.value.trim().length == 0) && this.isErrorMessageDisplayForCo && !reversalFlag"
                            align="start">Last name cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> &nbsp;&nbsp; </span> MIDDLE INITIAL
                      </div>
                      <div *ngIf="!sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" maxlength="40" matInput
                            style="width:80px!important" name="coMiddleinitial"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.middleName" #coMiddleinitial="ngModel"
                            class="dd-inputValues" oninput="setCustomValidity('')" (keyup)="this.pageEdited($event);" />
                        </mat-form-field>
                      </div>
                      <div *ngIf="sharedDataService.isTMEXUser" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput style="width:100%!important"
                            name="coMiddleinitial" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.middleName"
                            #coMiddleinitial="ngModel" class="dd-inputValues" oninput="setCustomValidity('')"
                            (keyup)="this.pageEdited($event);" />
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header">
                        <span class="MandatorySymbol"> &nbsp;&nbsp; </span> SUFFIX
                      </div>
                      <div fxFlex="55" class="dd-veh-summary-value">

                        <mat-select
                          [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                          style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;width:100%;"
                          class="mat-no-underline" name="coSuffix"
                          [(ngModel)]="_rdrDetails.rdrData.rdrDetail.buyer.cosuffix" #coSuffix="ngModel">
                          <mat-option [value]="">
                            -- select --
                          </mat-option>
                          <mat-option *ngFor="let i of suffixitems" [value]="i.value">{{i.name}}</mat-option>
                        </mat-select>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> * </span>ADDRESS
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxAddressLineOne" matInput
                            name="coAddress" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.addressLineOne"
                            #coAddress="ngModel" class="dd-inputValues dd-input-widt"
                            (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!coAddress.value || coAddress.value.trim().length == 0) && (this.isErrorMessageDisplayForCo) && !reversalFlag"
                            align="start">Address cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> &nbsp;&nbsp; </span> APT. OR SUITE
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxAddressLineOne" matInput name="coApt"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.addressLineTwo" #coApt="ngModel"
                            class="dd-inputValues" (keyup)="this.pageEdited($event);" />
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>

                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5">
                        <span *ngIf="makeCode==='TOYOTA'"></span>
                      </div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> * </span>CITY
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">

                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" [maxlength]="maxBuyerCity" matInput name="coCity"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.city" #coCity="ngModel"
                            class="dd-inputValues" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!coCity.value || coCity.value.trim().length == 0) && (this.isErrorMessageDisplayForCo) && !reversalFlag"
                            align="start">City cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header">
                        <span class="MandatorySymbol"> * </span> STATE
                      </div>
                      <div fxFlex="55" class="dd-veh-summary-value">
                        <mat-select
                          [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                          style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
                          class="mat-no-underline" name="coState"
                          [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.state" #coState="ngModel">
                          <mat-option [value]="">
                            -- select --
                          </mat-option>
                          <mat-option *ngFor="let i of stateItems" [value]="i.value">{{i.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                          <span class="error-font"
                            *ngIf="!this._rdrDetails.rdrData.rdrDetail.cobuyer.state && (this.isErrorMessageDisplayForCo) && !reversalFlag">
                            State cannot be blank
                          </span>
                        </mat-error>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> * </span>ZIP CODE
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="coZipcode"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.zipCode" #coZipcode="ngModel"
                            class="dd-inputValues dd-input-widt" [textMask]="{mask: zipMask, guide: false}"
                            (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!coZipcode.value && (this.isErrorMessageDisplayForCo) && !reversalFlag"
                            align="start">Zipcode cannot be blank</mat-hint>
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(this.isErrorMessageDisplay || coZipcode.touched) && coZipcode.value && !(coZipcode.value.length == '5' || coZipcode.value.length == '6' || coZipcode.value.length == '10') && !reversalFlag"
                            align="start">Invalid zipcode</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span *ngIf="isIndividualCustomer" class="MandatorySymbol"> * </span>
                        <span *ngIf="!isIndividualCustomer" class="MandatorySymbol"> </span>HOME
                        PHONE
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="coHomephone"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.homePhone" #coHomephone="ngModel"
                            class="dd-inputValues dd-input-widt" [textMask]="{mask: phoneMask, guide: false}"
                            type="text" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!coHomephone.value && (this.isErrorMessageDisplayForCo && isIndividualCustomer) && !reversalFlag"
                            align="start">Home Phone cannot be blank</mat-hint>
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(this.isErrorMessageDisplayForCo || coHomephone.touched) && coHomephone.value && !(coHomephone.value.length == '10' || coHomephone.value.length == '14') && !reversalFlag"
                            align="start">Invalid Home phone number</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span *ngIf="isIndividualCustomer" class="MandatorySymbol"> * </span>
                        <span *ngIf="!isIndividualCustomer" class="MandatorySymbol"> </span> WORK
                        PHONE
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="coWork"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.workPhone" #coWork="ngModel"
                            class="dd-inputValues" [textMask]="{mask: phoneMask, guide: false}" type="text" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!coWork.value && (this.isErrorMessageDisplayForCo && isIndividualCustomer) && !reversalFlag"
                            align="start">Work Phone cannot be blank</mat-hint>
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(this.isErrorMessageDisplayForCo || coWork.touched) && coWork.value && !(coWork.value.length == '10' || coWork.value.length == '14') && !reversalFlag"
                            align="start">Invalid Work phone number</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> </span> EXT
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" maxlength="5" matInput name="coExt"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.extension" #coExt="ngModel"
                            [ddFieldValidator]="validationTypes.NUMBER" class="dd-inputValues"
                            oninput="setCustomValidity('')" (keyup)="this.pageEdited($event);" />
                          <!--<mat-hint [ngStyle]="{'color': 'red','background': 'yellow'}" *ngIf="!coExt.value && (this.isErrorMessageDisplayForCo && isIndividualCustomer)"
                                                        align="start">Extention cannot be blank</mat-hint >-->
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span *ngIf="isCoEmailMandatory" class="MandatorySymbol"> &nbsp;&nbsp; </span>
                        <span *ngIf="!isCoEmailMandatory" class="MandatorySymbol"> * </span> EMAIL
                      </div>
                      <div *ngIf="!isCoEmailMandatory" fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input [readonly]="!sharedDataService.rdrScreenAccess" matInput name="coBuyerEmail"
                            [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.email" #coBuyerEmail="ngModel"
                            [maxlength]="maxEmail"
                            class="dd-inputValues" (keyup)="this.pageEdited($event);" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="!isCoEmailMandatory && (!coBuyerEmail.value || coBuyerEmail.value.trim().length == 0) && (this.isErrorMessageDisplayForCo) && !reversalFlag"
                            align="start">Email cannot be blank</mat-hint>
                        </mat-form-field>
                      </div>
                      <div style="background:rgb(235, 235, 228);height:50%" *ngIf="isCoEmailMandatory" fxFlex="55"
                        class="dd-veh-summary-value dd-input-veh-valueText">
                        &nbsp;&nbsp;
                      </div>
                      <div *ngIf="!isCoEmailMandatory" fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">

                    </div>

                    <div fxFlex="5" fxLayoutAlign="left center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <mat-checkbox
                        [ngClass]="sharedDataService.rdrScreenAccess && !reversalFlag?'dropdownEnabled':'dropdownDisabled'"
                        style="height:15px;width:15px" name="isMandatoryCoEmail" [(ngModel)]="isCoEmailMandatory"
                        (change)="coEmailOptions($event)" style="padding-left:25px;font-size:12px">
                        Customer declined to provide email
                      </mat-checkbox>
                    </div>

                  </div>

                </div>

              </div>

              <div *ngIf="sharedDataService.isTMEXUser" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> *</span>RFC(TAX ID)
                      </div>
                      <div fxFlex="50" class="dd-veh-summary-value">
                        <mat-form-field style="max-width:100%">
                          <input type="text" [readonly]="!sharedDataService.rdrScreenAccess" maxlength="13" matInput
                            name="cobuyerTaxId" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.taxid"
                            pattern="^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{10,}$" #cobuyerTaxId="ngModel" required
                            (blur)="checkMandatoryAlphaMandatoryNumeric($event,'cobuyer')" class="dd-inputValues" />
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(!cobuyerTaxId.value || cobuyerTaxId.value.trim().length == 0) && (cobuyerTaxId.touched || this.isErrorMessageDisplay)"
                            align="start">TaxId cannot be blank</mat-hint>
                          <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                            *ngIf="(this.isErrorMessageDisplay || cobuyerTaxId.touched) && cobuyerTaxId.value && !rfcTaxIdCoBuyer"
                            align="start">Invalid Tax Id</mat-hint>
                        </mat-form-field>
                      </div>
                      <div fxFlex="5" style="color:#c00">
                        <mat-icon class="dd-edit-icons dd-anchor">
                          mode_edit</mat-icon>
                      </div>
                    </div>

                    <div fxFlex="50" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <div fxFlex="5"></div>
                      <div fxFlex="35" class="dd-veh-summary-header mTop4em">
                        <span class="MandatorySymbol"> *</span> CONTACT
                      </div>
                      <div fxFlex="55" class="dd-veh-summary-value">
                        <mat-select [ngClass]="sharedDataService.rdrScreenAccess?'dropdownEnabled':'dropdownDisabled'"
                          style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
                          class="mat-no-underline" name="cobuyerContactMethod" required
                          [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.contactMethod"
                          #cobuyerContactMethod="ngModel">
                          <mat-option [value]="">
                            -- select --
                          </mat-option>
                          <mat-option *ngFor="let i of coBuyerContactMethods" [value]="i">{{i}}</mat-option>
                        </mat-select>
                        <mat-error>
                          <span class="error-font"
                            *ngIf="!this._rdrDetails.rdrData.rdrDetail.cobuyer.contactMethod && (this.isErrorMessageDisplay)">
                            Contact cannot be blank
                          </span>
                        </mat-error>
                      </div>

                    </div>

                  </div>

                </div>

              </div>
              <div *ngIf="sharedDataService.isTMEXUser" fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

                  <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                    <div fxFlex="5" fxLayoutAlign="left center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                      class="dd-veh-summary-desc dd-veh-header-summary-desc">
                      <mat-checkbox [ngClass]="sharedDataService.rdrScreenAccess?'dropdownEnabled':'dropdownDisabled'"
                        name="isMandatoryData" [(ngModel)]="_rdrDetails.rdrData.rdrDetail.cobuyer.customerConsent"
                        style="padding-left:25px;font-size:12px">
                        Customer declined to share the personal data
                      </mat-checkbox>
                    </div>

                  </div>

                </div>

              </div>
            </div>

          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="!showAdditionalDriver" fxFlex="50" fxLayoutAlign="center center" fxLayout="column"
          fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">
          <div fxLayout="row" fxLayoutAlign="center center" class="dd-bottom-container" style="margin-top: 0.6%;">
            <div style="padding-right: 5px;">
              <button mat-button mat-raised-button class="dd-desription-save-button" type="submit"
                *ngIf="regionNo != '50'" (click)="showAdditionalDriverSection()">+ ADDITIONAL DRIVER</button>
            </div>
          </div>
        </mat-card>

      </div>
    </div>
  </div>

  <!--<mat-divider></mat-divider>-->

  <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" class="dd-bottom-container" style="margin-top: 0.6%;">
    <div fxFlex="51">
    </div>
    <div fxFlex="49">
      <strong>(If Needed)</strong>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" class="dd-bottom-container sale-dtl-flex-container" style="margin-top: 0.6%;">
    <mat-checkbox *ngIf="sharedDataService.superAdminScreenAccess && !isValidPQSS" name="isPQSSValid"
        #isPQSSValid="ngModel" [(ngModel)]="isValidatePQSS" (change)="skipPQSSChange($event)"
        style="padding-left:25px;font-size:12px">
        SKIP OPEN CAMPAIGN
      </mat-checkbox> 
    <button *ngIf="sharedDataService.rdrScreenAccess && !reversedFlag"
        [disabled]="!rdrDetailForm.dirty && !isDateChanged" mat-button mat-raised-button
        class="dd-desription-save-button" type="submit" (click)="saveRDR(_rdrDetails)">{{saveButtonText}}</button>
      <button mat-button mat-raised-button class="dd-desription-save-button" type="submit"
        (click)="navigateToRDRSumamry(_rdrDetails)">CANCEL</button>
    </div>
</form>