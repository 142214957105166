'use strict';
import * as models from './models';

export interface Rdr {
  // Vehicle Identification Number
  vin?: string;
  // Dealer Code that the vehicle is allocated to
  dealerCd?: string;
  // model number
  model?: models.Model;
  // make Code
  makeCode?: string;
  // dealer name
  dealerName?: string;
  // exterior color
  extColor?: models.Color;
  // interior color
  intColor?: models.Color;
  // dofu
  dofu?: string;
  // open campaign status
  openCampaignStatus?: string;
  // inventory status
  inventoryStatus?: string;
  // acquired date
  acquiredDate?: string;
  // acquired method
  acquiredMethod?: string;
  // mileage
  mileage?: string;
  // price details
  price?: models.Price;
  // accessories details
  accessoriesDetails?: models.AccessoryDetails[];
  // certification status
  certificationStatus?: string;
  // car fax flag
  carFaxFlag?: string;
  // foreignVin flag
  foreignVin?: string;
  // certification status
  certificationNumber?: string;
  // repairOrder
  repairOrder?: models.RepairOrder;
  // inspection
  inspection?: models.Inspection;
  // certification details
  certification?: models.CertificationDetails;
  // stockNumber
  stockNumber?: string;
  // processingFee
  processingFee?: string;
  // accessoryComments
  accessoryComments?: string;
  // transmissionType
  transmissionType?: string;
  // rdrDetail
  rdrDetail?: models.RdrDetail;
  // createdAt
  createdAt?: string;
  // createdBy
  createdBy?: string;
  // updatedAt
  updatedAt?: string;
  // updatedBy
  updatedBy?: string;
  // comments
  comment?: string;
  // retailDate
  retailDate?: string;
  // certified
  certified?: string;
  // migrated
  migrated?: string;

  isAllowOpenCampaign?: any;

  isAllowCarfax?: any;

  sellArea?: string;

  isMigrateData?: string;

  regionNo?: string;

  certificationType?: string;
}
