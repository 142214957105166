import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    { // Region No
      key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'areaRegionNo', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '80',
      columnMinWidth: '80', columnMaxWidth: '80', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.STARTS_WITH
    },
    {// Region Name
      key: ViewConstants.DEALER_REGION_NAME_STRING_COLUMN, fieldName: 'regionName',
      style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_4_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// District
      key: ViewConstants.DISTRICT, fieldName: 'district', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '110',
      columnMinWidth: '110', columnMaxWidth: '110', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer #
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.VIN, fieldName: 'vin',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '185', columnMinWidth: '185',
      columnMaxWidth: '185', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// CERTIFICATION NO
      key: ViewConstants.CERTIFICATION_NUMBER, fieldName: 'certificationNumber',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// CERTIFICATION DATE
      key: ViewConstants.CERTIFICATION_DATE, fieldName: 'certification.certificationDate',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// model
      key: ViewConstants.MODEL_YEAR, fieldName: 'model.modelYear', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// model
      key: ViewConstants.MODEL_NO, fieldName: 'model.modelDescription', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// Plan Code
      key: ViewConstants.MILEAGE, fieldName: 'mileage',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// whole sale
      key: ViewConstants.WHOLESALE, fieldName: 'price.acvPrice',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// CERTIFIED BY
      key: ViewConstants.RETAIL_AMT, fieldName: 'price.retailAmount', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// CERTIFICATION TYPE
      key: ViewConstants.LAST_UPDATE,
      fieldName: 'updatedAt', style: ' ',
      hasTemplate: 'true',
      template: 'DD-MM-YYYY', sortable: 'true', columnWidth: '120', columnMinWidth: '120',
      columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// whole sale
      key: ViewConstants.LOT_DAYS, fieldName: 'lotDays',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// whole sale
      key: ViewConstants.STOCK, fieldName: 'stockNumber',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '160',
      columnMinWidth: '160', columnMaxWidth: '160', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
     {// reason
      key: ViewConstants.REASON, fieldName: 'reason',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '450',
      columnMinWidth: '450', columnMaxWidth: '450', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }
  ];
}
