<div fxFlex="100" fxLayout="row" class="dealer-vin-search layoutbglite">
  <mat-card fxLayout="row" fxFlex="100" fxLayoutAlign="space-around">
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="15" [ngClass]="{'marginBottom': ytyTCUVFilter}">
      <mat-radio-group (change)="selection($event)" [value]="selectedOpt">
        <mat-radio-button value="m" *ngIf="!ytyTCUVFilter">MTD</mat-radio-button>
        <mat-radio-button value="y">YTD</mat-radio-button>
        <mat-radio-button value="c">CUSTOM</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="selectedOpt === 'm' && !ytyTCUVFilter" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="35">
      <div fxFlex="10">
        <label>Month</label>
      </div>
      <div fxLayoutAlign="space-evenly center" fxLayout="column" fxFlex="40">
        <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100% !important;"
          class="mat-no-underline" name="month" [(ngModel)]="selectedMonth" #month="ngModel">
          <mat-option [value]=""> -- select -- </mat-option>
          <mat-option *ngFor="let i of monthList" [value]="i.value">{{i.name}}</mat-option>
        </mat-select>
      </div>
      <div fxFlex="10">
        <label>Year</label>
      </div>
      <div fxLayoutAlign="space-evenly center" fxLayout="row" fxFlex="20">
        <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100% !important;"
          class="mat-no-underline" name="year" [(ngModel)]="selectedYear" #year="ngModel">
          <mat-option [value]=""> -- select -- </mat-option>
          <mat-option *ngFor="let i of yearList" [value]="i">{{i}}</mat-option>
        </mat-select>
      </div>
    </div>

    <div *ngIf="selectedOpt === 'y' && !ytyTCUVFilter" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="10">
      <div fxFlex="30">
        <label>Year</label>
      </div>
      <div fxLayoutAlign="space-evenly center" fxLayout="row" fxFlex="70">
        <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100% !important;"
          class="mat-no-underline" name="year" [(ngModel)]="selectedYear" #year="ngModel">
          <mat-option [value]=""> -- select -- </mat-option>
          <mat-option *ngFor="let i of yearList" [value]="i">{{i}}</mat-option>
        </mat-select>
      </div>
    </div>

    <div *ngIf="selectedOpt === 'c' && !ytyTCUVFilter" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="40">
      <div fxFlex="10">
        <label>Custom Date</label>
      </div>
      <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="40" style="padding: 5px;">
        <my-date-picker style="width:100%" placeholder="From Date" name="retailDate" id="retailDate"
          [options]="myDatePickerOptions" [locale]="'en'" [selDate]="fromDate | date :'MM/dd/yyyy'"
          (dateChanged)="dateFilter($event,'fromDate')"></my-date-picker>
      </div>
      <div fxFlex="5"></div>
      <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="40" style="padding: 5px;">
        <my-date-picker style="width:100%" placeholder="To Date" name="retailDate" id="retailDate"
          [options]="myDatePickerOptions" [locale]="'en'" [selDate]="toDate | date :'MM/dd/yyyy'"
          (dateChanged)="dateFilter($event,'toDate')"></my-date-picker>
      </div>   
     
    </div>


    <div *ngIf="selectedOpt === 'c' && ytyTCUVFilter" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="40">
      <div fxFlex="10">
        <label>Custom Date</label>
      </div>
      <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="40" style="padding: 5px;">
            <mat-form-field>
          <input matInput [matDatepicker]="picker" [formControl]="Frmdate" [max]="maxDateForFromYty" placeholder="From Date" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker startView="multi-year" 
          (yearSelected)="chosenYearHandler($event, 'fromDate')"
          (monthSelected)="chosenMonthHandler($event, picker, 'fromDate')"
            ></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="5"></div>
      <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="40" style="padding: 5px;">
          <mat-form-field>
            <input matInput [matDatepicker]="dp" [formControl]="toDateYty" [max]="maxDate" [min]='minDate' placeholder="To Date"
            [disabled]='enableToDateForYty' readonly>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" 
            (yearSelected)="chosenYearHandler($event, 'toDate')"
            (monthSelected)="chosenMonthHandler($event, dp, 'toDate')"
            disable
              ></mat-datepicker>
          </mat-form-field>
      </div>
    </div>

    <div *ngIf="sharedDataService.brandName == 'TOYOTA'" fxLayoutAlign="center center" fxFlex="10">
      <label>Region Selection</label>
    </div>

    <div *ngIf="sharedDataService.brandName == 'LEXUS'" fxLayoutAlign="center center" fxFlex="10">
      <label>Area Selection</label>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="20">
      <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
        class="mat-no-underline" name="region" [(ngModel)]="regionSelection" #region="ngModel"
        (dateChanged)="dateFilter($event,'regionSelection')" (selectionChange)="getDistricts($event); callApplyForYty()">
        <mat-option [value]=""> -- select -- </mat-option>
        <mat-option *ngFor="let i of regionItems" [value]="i">{{i}}</mat-option>
      </mat-select>
    </div>

    <div fxFlex="5" fxLayoutAlign="center center">
      <label>District</label>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="10">
      <mat-select style="border-bottom: 1px SOLID rgba(0, 0, 0, 0.22) !important;max-width:100%;"
        class="mat-no-underline" name="district" [(ngModel)]="districtSelection" #district="ngModel"
        (dateChanged)="dateFilter($event,'districtSelection')">
        <mat-option [value]=""> -- select -- </mat-option>
        <mat-option *ngFor="let i of districtItems" [value]="i">{{i}}</mat-option>
      </mat-select>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="10">
      <button (click)="filterValueChanged();" mat-button mat-raised-button *ngIf="ytyTCUVFilter && selectedOpt !== 'y'"
      [disabled]="disableApplyYty">Apply</button>
      <button (click)="filterValueChanged();" mat-button mat-raised-button *ngIf="!ytyTCUVFilter">Apply</button>
    </div>
  </mat-card>

</div>