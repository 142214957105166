import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SharedDataService } from '../../../services/common/shared-data.service';

@Component({
    selector: 'uc-inventory-report-menubar-panel',
    templateUrl: 'inventory-report-menubar-panel.component.html',
    styleUrls: ['./inventory-report-menubar-panel.component.scss']
})

export class UCInventoryReportMenubarPanelComponent {
    @Input() isInventorySelected: boolean;

    @Input() selectedCertificationStatus: string;
    @Input() selectedMakeCode: string;
    // Event to emit on navigating to vehicle detail screen
    @Output() navigateToVehicleDetailEvent = new EventEmitter<number>();
    @Output() navigateToVehicleSummaryEvent = new EventEmitter<number>();
    @Output() navigateToRdrSummaryEvent = new EventEmitter<number>();
    @Output() navigateToSalesSummaryEvent = new EventEmitter<number>();

    @Output() navigateToVehicleDetailEventNew = new EventEmitter<number>();

    @Output() navigateToRdrDetailEventNew = new EventEmitter<number>();

    @Output() exportPdfEvent = new EventEmitter<number>();

    @Output() exportExcelEvent = new EventEmitter<number>();
    // Event to emit when clear all button is clicked
    @Output() clearAllButtonEvent = new EventEmitter();
    // Boolean variable to show/hide the clear all button
    @Input() showClearAllButton: boolean;
    // Variable to display the total inventory count
    @Input() totalInventoriesCount: any;

    // Event emitter to delete disclaimer
    @Output() deleteVehicleEvent = new EventEmitter<any>();

    constructor(public sharedDataService: SharedDataService) {

    }

    /**
     * Method to handle the navigation to vehicle detail screen
     */
    navigateToVehicleScreen() {
        this.navigateToVehicleDetailEvent.emit();
    }

    /**
     * Method to handle the navigation to vehicle detail screen
     */
    navigateToVehicleScreenNew() {
        this.navigateToVehicleDetailEventNew.emit();
    }
    /**
     * Method to handle when clear all button is clicked
     */
    clearAllButtonClicked(event: any) {
        this.clearAllButtonEvent.emit();
    }

    /**
     * Method to emit the delete disclaimer event emitter
     */
    deleteVehicle() {
        this.deleteVehicleEvent.emit();
    }

    navigateToVehicleSummary() {
        this.navigateToVehicleSummaryEvent.emit();
    }

    navigateToRdrSummary() {
        this.navigateToRdrSummaryEvent.emit();
    }

    navigateToSalesSummary() {
        this.navigateToSalesSummaryEvent.emit();
    }

    /**
     * Method to handle the navigation to vehicle detail screen
     */
    navigateToRdrDetailScreenNew() {
        this.navigateToRdrDetailEventNew.emit();
    }

    exportPdf() {
        this.exportPdfEvent.emit();
    }

    exportExcel() {
        this.exportExcelEvent.emit();
    }

}
