import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */


/**
 * Following action types are being used in the inventory summary screen
 */

export const actionTypes = {
  GET_REGION_OBJECTIVE: type('[RegionObjective] Get Region Objective'),
  GET_REGION_OBJECTIVE_SUCCESS: type('[RegionObjective] Get Region Objective success'),
  GET_REGION_OBJECTIVE_FAIL: type('[RegionObjective] Get Region Objective failure'),
  GET_DEALER_OBJECTIVE: type('[DealerObjective] Get Dealer Objective'),
  GET_DEALER_OBJECTIVE_SUCCESS: type('[DealerObjective] Get Dealer Objective success'),
  GET_DEALER_OBJECTIVE_FAIL: type('[DealerObjective] Get Dealer Objective failure'),
  SAVE_REGION_OBJECTIVE: type('[RegionObjective] Save Region Objective'),
  SAVE_REGION_OBJECTIVE_SUCCESS: type('[RegionObjective] Save Region Objective success'),
  SAVE_REGION_OBJECTIVE_FAIL: type('[RegionObjective] Save Region Objective failure'),
  SAVE_DEALER_OBJECTIVE: type('[DealerObjective] Save Dealer Objective'),
  SAVE_DEALER_OBJECTIVE_SUCCESS: type('[DealerObjective] Save Dealer Objective success'),
  SAVE_DEALER_OBJECTIVE_FAIL: type('[DealerObjective] Save Dealer Objective failure'),
  CLEAR_ALL_ACTION: type('[DealerObjective] clear all vehicle objective data'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Method used to get person id details
 */
export class GetRegionObjectiveAction implements ActionBase {
  type = actionTypes.GET_REGION_OBJECTIVE;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id success
 */
export class GetRegionObjectiveSuccessAction implements ActionBase {
  type = actionTypes.GET_REGION_OBJECTIVE_SUCCESS;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id failure
 */
export class GetRegionObjectiveFailureAction implements ActionBase {
  type = actionTypes.GET_REGION_OBJECTIVE_FAIL;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id details
 */
export class GetDealerObjectiveAction implements ActionBase {
  type = actionTypes.GET_DEALER_OBJECTIVE;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id success
 */
export class GetDealerObjectiveSuccessAction implements ActionBase {
  type = actionTypes.GET_DEALER_OBJECTIVE_SUCCESS;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id failure
 */
export class GetDealerObjectiveFailureAction implements ActionBase {
  type = actionTypes.GET_DEALER_OBJECTIVE_FAIL;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id details
 */
export class SaveRegionObjectiveAction implements ActionBase {
  type = actionTypes.SAVE_REGION_OBJECTIVE;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id success
 */
export class SaveRegionObjectiveSuccessAction implements ActionBase {
  type = actionTypes.SAVE_REGION_OBJECTIVE_SUCCESS;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id failure
 */
export class SaveRegionObjectiveFailureAction implements ActionBase {
  type = actionTypes.SAVE_REGION_OBJECTIVE_FAIL;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id details
 */
export class SaveDealerObjectiveAction implements ActionBase {
  type = actionTypes.SAVE_DEALER_OBJECTIVE;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id success
 */
export class SaveDealerObjectiveSuccessAction implements ActionBase {
  type = actionTypes.SAVE_DEALER_OBJECTIVE_SUCCESS;
  constructor(public payload: any) { }
}

/**
 * Method used to get person id failure
 */
export class SaveDealerObjectiveFailureAction implements ActionBase {
  type = actionTypes.SAVE_DEALER_OBJECTIVE_FAIL;
  constructor(public payload: any) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class ClearAllAction implements ActionBase {
  type = actionTypes.CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Action
  = GetRegionObjectiveAction
  | GetRegionObjectiveSuccessAction
  | GetRegionObjectiveFailureAction
  | GetDealerObjectiveAction
  | GetDealerObjectiveSuccessAction
  | GetDealerObjectiveFailureAction
  | SaveRegionObjectiveAction
  | SaveRegionObjectiveSuccessAction
  | SaveRegionObjectiveFailureAction
  | SaveDealerObjectiveAction
  | SaveDealerObjectiveSuccessAction
  | SaveDealerObjectiveFailureAction
  | ClearAllAction;
