

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as viewModels from '../services/view-model/view-models';
import * as detailActions from '../actions/vehicle-objective.action';
import * as models from '../services/model/models';
import { isNil, cloneDeep } from 'lodash';
import { SharedFunctionService } from '../services/common/shared-function.service';
import { ScreenMessages } from '../shared/master-data/messages';
import { ViewConstants } from '../shared/shared.constants';
/**
 * Interface used to maintain the state for the vehicle detail screen
 */
export interface VehicleObjectiveState {
  vehicleObjective: viewModels.VehicleObjectiveView;
}

/**
 * Variable used to store the initial state of VehicleDetailsState
 */
const initialState: VehicleObjectiveState = {
  vehicleObjective: null
};

/**
 * Method used to set the default initial state of VehicleDetailsState
 */
function setDefaulValue(): viewModels.VehicleObjectiveView {
  let vehicleDetails: viewModels.VehicleObjectiveView =
    <viewModels.VehicleObjectiveView>new Object();
  vehicleDetails.vehicleData = [];
  vehicleDetails.message = null;
  return vehicleDetails;
}

/**
 * Reducer method to handle the vehicle detail screen actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): VehicleObjectiveState {
  switch (action.type) {
    case detailActions.actionTypes.GET_DEALER_OBJECTIVE: {
      // return the Vehicle Detail data
      // return { vehicleDetail: setDefaulValue() };
      return state;
    }
    case detailActions.actionTypes.GET_DEALER_OBJECTIVE_SUCCESS: {
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleObjective, {
        vehicleData: cloneDeep(action.payload),
        pageType: 'DEALER',
        message: null
      });
      return { vehicleObjective: vehicleDetailData };
    }
    case detailActions.actionTypes.GET_DEALER_OBJECTIVE_FAIL: {
      if (!isNil(action.payload.vehicleData)) {
        let vehicleDetailData = (<any>Object).assign({}, state.vehicleObjective, {
          vehicleData: { vin: action.payload.vehicleData.vin },
          pageType: ViewConstants.NO_VIN_VEH_DETAILS,
          message: null
        });
        return { vehicleObjective: vehicleDetailData };
      }
    }
    case detailActions.actionTypes.GET_REGION_OBJECTIVE_SUCCESS: {
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleObjective, {
        vehicleData: cloneDeep(action.payload),
        pageType: 'REGION',
        message: null
      });
      return { vehicleObjective: vehicleDetailData };
    }
    case detailActions.actionTypes.GET_REGION_OBJECTIVE_FAIL: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      if (errorResponse.errorCode === 'BAD_REQUEST') {
        message.message = ScreenMessages.INTERNAL_SERVER_ERROR;
      } else if (errorResponse === ScreenMessages.SERVICE_FAILURE_MESSAGE ||
        errorResponse === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
        message.message = errorResponse;
      } else {
        message.message = ScreenMessages.NO_DATA;
      }
      message.type = models.MessageType.DANGER;
      let vehicleDetailData: viewModels.VehicleObjectiveView = setDefaulValue();
      vehicleDetailData.message = message;
      vehicleDetailData.vehicleData = null;
      return { vehicleObjective: vehicleDetailData };
    }
    case detailActions.actionTypes.SAVE_REGION_OBJECTIVE_SUCCESS: {
      let message: models.Message = {};
      message.message = ScreenMessages.UPDATE_RECORD_SUCCESS;
      message.type = models.MessageType.SUCCESS;
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleObjective, {
        vehicleData: cloneDeep(action.payload),
        pageType: ViewConstants.UPDATED,
        message: message
      });
      return { vehicleObjective: vehicleDetailData };
    }
    case detailActions.actionTypes.SAVE_DEALER_OBJECTIVE_FAIL: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      if (errorResponse.errorCode === 'BAD_REQUEST') {
        message.message = ScreenMessages.INTERNAL_SERVER_ERROR;
      } else if (errorResponse === ScreenMessages.SERVICE_FAILURE_MESSAGE ||
        errorResponse === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
        message.message = errorResponse;
      } else {
        message.message = ScreenMessages.NO_DATA;
      }
      message.type = models.MessageType.DANGER;
      let vehicleDetailData: viewModels.VehicleObjectiveView = setDefaulValue();
      vehicleDetailData.message = message;
      vehicleDetailData.vehicleData = null;
      return { vehicleObjective: vehicleDetailData };
    }
    case detailActions.actionTypes.SAVE_DEALER_OBJECTIVE_SUCCESS: {
      let message: models.Message = {};
      message.message = ScreenMessages.UPDATE_RECORD_SUCCESS;
      message.type = models.MessageType.SUCCESS;
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleObjective, {
        vehicleData: cloneDeep(action.payload),
        pageType: ViewConstants.UPDATED,
        message: message
      });
      return { vehicleObjective: vehicleDetailData };
    }
    case detailActions.actionTypes.SAVE_REGION_OBJECTIVE_FAIL: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      if (errorResponse.errorCode === 'BAD_REQUEST') {
        message.message = ScreenMessages.INTERNAL_SERVER_ERROR;
      } else if (errorResponse === ScreenMessages.SERVICE_FAILURE_MESSAGE ||
        errorResponse === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
        message.message = errorResponse;
      } else {
        message.message = ScreenMessages.NO_DATA;
      }
      message.type = models.MessageType.DANGER;
      let vehicleDetailData: viewModels.VehicleObjectiveView = setDefaulValue();
      vehicleDetailData.message = message;
      vehicleDetailData.vehicleData = null;
      return { vehicleObjective: vehicleDetailData };
    }
    case detailActions.actionTypes.CLEAR_ALL_ACTION: {
      return { vehicleObjective: setDefaulValue() };
    }

    default:
      return state;
  }

}
/* tslint:enable:no-switch-case-fall-through */
/**
 * Method to get the state of VehicleDetailsState for vehicle detail screen
 */
export function getVehicleObjective(state: VehicleObjectiveState) {
  return state.vehicleObjective;
}

