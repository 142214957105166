import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';
import * as models from '../services/model/models';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

/**
 * Following action types are being used in the vehicle detail screen
 */
export const actionTypes = {
  GET_VEHICLE_HISTORY: type('[VehicleHistory] get vehicle history details'),
  GET_VEHICLE_HISTORY_SUCCESS: type('[VehicleHistory] get vehicle history details Success'),
  GET_VEHICLE_HISTORY_FAIL: type('[VehicleHistory] get vehicle history details Fail'),
  CLEAR_ALL_ACTION: type('[VehicleHistory] clear all vehicle data'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to load the Initial data for vehicle detail screen
 */
export class GetVehicleHistoryAction implements ActionBase {
  type = actionTypes.GET_VEHICLE_HISTORY;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetVehicleHistorySuccessAction implements ActionBase {
  type = actionTypes.GET_VEHICLE_HISTORY_SUCCESS;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the Failure action for Initial Load method
 */
export class GetVehicleHistoryFailAction implements ActionBase {
  type = actionTypes.GET_VEHICLE_HISTORY_FAIL;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class ClearAllAction implements ActionBase {
  type = actionTypes.CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = GetVehicleHistoryAction
  | GetVehicleHistorySuccessAction
  | GetVehicleHistoryFailAction
  | ClearAllAction;
