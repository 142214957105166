




import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { RdrDetailsService } from '../services/api/rdr-detail.service';

import * as detailActions from '../actions/rdr-detail.action';

import * as models from '../services/model/models';
import {map, catchError, switchMap} from 'rxjs/operators'

/**
 * Class used to handle the service interaction in the vehicle detail screen
 */
@Injectable()
export class RdrDetailsEffects {
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getRdrByVins$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.RDR_INITIAL_LOAD)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.rdrDetailsService.getRdrByVin(data)
        .pipe(map((rdrData: models.Rdr) =>
          new detailActions.RdrInitialLoadSuccessAction(rdrData))
        , catchError(error => of(
          new detailActions.RdrInitialLoadFailAction(
            { 'error': error, 'vehicleData': data }))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getRdrByVinblur$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.GET_RDR_BY_VIN)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.rdrDetailsService.getRdrByVinblur(data)
      .pipe(map((rdrData: models.Rdr) =>
          new detailActions.GetRdrByVinSuccessAction(rdrData))
          , catchError(error => of(new detailActions.GetRdrByVinFailAction(
          { 'error': error, 'vehicleData': data }))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  addNewRdr$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.ADD_RDR_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.rdrDetailsService.saveRdr(data)
      .pipe(map((vehicleData: models.Rdr) =>
          new detailActions.AddNewRdrSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.AddNewRdrFailAction(error))))
    ));
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  updateRdr$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.UPDATE_RDR_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.rdrDetailsService.updateRdr(data)
      .pipe(map((vehicleData: models.Rdr) =>
          new detailActions.UpdateRdrSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.UpdateRdrFailAction(error))))
    ));

  /**
   * Method used to delete the vehicle
   */
  @Effect()
  deleteVehicle$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.DELETE_RDR_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.rdrDetailsService.deleteRdr(data)
      .pipe(map((vehicleData: models.Rdr) =>
          new detailActions.DeleteRdrSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.DeleteRdrFailAction(error))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  reverseRdr$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.RDR_REVERSAL_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.rdrDetailsService.reverseRdr(data)
      .pipe(map((vehicleData: models.Rdr) =>
          new detailActions.RdrReversalSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.RdrReversalFailAction(error))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getFIMangerData$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.FIMANAGER_LOAD_ACTION),
        map((action: ActionBase) => action.payload),
        switchMap(data =>
          this.rdrDetailsService.loadFIManager(data)
        .pipe(map((rdrData: any) =>
          new detailActions.FILoadSuccessAction(rdrData)),
            catchError(error => of(new detailActions.FILoadFailAction(
          { 'error': error, 'vehicleData': data })))
            ))
    );


  /**
   * Constructor method used to create new instance of VehicleDetailsEffects class
   */
  constructor(private actions$: Actions, private rdrDetailsService: RdrDetailsService) { }
}
