import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';
import * as models from '../services/model/models';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

/**
 * Following action types are being used in the vehicle detail screen
 */
export const actionTypes = {
  RDR_INITIAL_LOAD: type('[RDR] Initiate load for RDR details'),
  RDR_INITIAL_LOAD_SUCCESS: type('[RDR] RDR details Success'),
  RDR_INITIAL_LOAD_FAIL: type('[RDR] RDR details Fail'),
  GET_RDR_BY_VIN: type('[RDR] Get RDR details by Vin'),
  GET_RDR_BY_VIN_SUCCESS: type('[RDR] Get RDR details by Vin Success'),
  GET_RDR_BY_VIN_FAIL: type('[RDR] Get RDR details by Vin Fail'),
  RDR_CLEAR_ALL_ACTION: type('[RDR] clear all RDR data'),
  ADD_RDR_ACTION: type('[RDR] add new RDR'),
  ADD_RDR_SUCESS_ACTION: type('[RDR] add new RDR success'),
  ADD_RDR_FAIL_ACTION: type('[RDR] add new RDR failure'),
  UPDATE_RDR_ACTION: type('[RDR] update RDR'),
  UPDATE_RDR_SUCESS_ACTION: type('[RDR] update RDR success'),
  UPDATE_RDR_FAIL_ACTION: type('[RDR] update RDR failure'),
  DELETE_RDR_ACTION: type('[RDR] delete RDR'),
  DELETE_RDR_SUCESS_ACTION: type('[RDR] delete RDR success'),
  DELETE_RDR_FAIL_ACTION: type('[RDR] delete RDR failure'),
  RDR_REVERSAL_ACTION: type('[RDR] Reverse RDR'),
  RDR_REVERSAL_SUCESS_ACTION: type('[RDR] Reverse RDR success'),
  RDR_REVERSAL_FAIL_ACTION: type('[RDR] Reverse RDR failure'),
  FIMANAGER_LOAD_ACTION: type('[FIMANAGER] FIMANAGER Load'),
  FIMANAGER_LOAD_SUCESS_ACTION: type('[FIMANAGER] FIMANAGER Load success'),
  FIMANAGER_LOAD_FAIL_ACTION: type('[FIMANAGER] FIMANAGER Load failure')
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to load the Initial data for vehicle detail screen
 */
export class RdrInitialLoadAction implements ActionBase {
  type = actionTypes.RDR_INITIAL_LOAD;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class RdrInitialLoadSuccessAction implements ActionBase {
  type = actionTypes.RDR_INITIAL_LOAD_SUCCESS;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the Failure action for Initial Load method
 */
export class RdrInitialLoadFailAction implements ActionBase {
  type = actionTypes.RDR_INITIAL_LOAD_FAIL;

  constructor(public payload: any) { }
}



/**
 * Class used to handle the Success action for Initial Load method
 */
export class RdrClearAllAction implements ActionBase {
  type = actionTypes.RDR_CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Class used to handle the add new vehicle action
 */
export class AddNewRdrAction implements ActionBase {
  type = actionTypes.ADD_RDR_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the add new vehicle success action
 */
export class AddNewRdrSuccessAction implements ActionBase {
  type = actionTypes.ADD_RDR_SUCESS_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the add new vehicle fail action
 */
export class AddNewRdrFailAction implements ActionBase {
  type = actionTypes.ADD_RDR_FAIL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the update vehicle action
 */
export class UpdateRdrAction implements ActionBase {
  type = actionTypes.UPDATE_RDR_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the update vehicle success action
 */
export class UpdateRdrSuccessAction implements ActionBase {
  type = actionTypes.UPDATE_RDR_SUCESS_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the update vehicle fail action
 */
export class UpdateRdrFailAction implements ActionBase {
  type = actionTypes.UPDATE_RDR_FAIL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle action
 */
export class DeleteRdrAction implements ActionBase {
  type = actionTypes.DELETE_RDR_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle success action
 */
export class DeleteRdrSuccessAction implements ActionBase {
  type = actionTypes.DELETE_RDR_SUCESS_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle fail action
 */
export class DeleteRdrFailAction implements ActionBase {
  type = actionTypes.DELETE_RDR_FAIL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the get rdr by vin action
 */
export class GetRdrByVinAction implements ActionBase {
  type = actionTypes.GET_RDR_BY_VIN;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the get rdr by vin success action
 */
export class GetRdrByVinSuccessAction implements ActionBase {
  type = actionTypes.GET_RDR_BY_VIN_SUCCESS;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the get rdr by vin fail action
 */
export class GetRdrByVinFailAction implements ActionBase {
  type = actionTypes.GET_RDR_BY_VIN_FAIL;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the delete vehicle action
 */
export class RdrReversalAction implements ActionBase {
  type = actionTypes.RDR_REVERSAL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle success action
 */
export class  RdrReversalSuccessAction implements ActionBase {
  type = actionTypes.RDR_REVERSAL_SUCESS_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle fail action
 */
export class  RdrReversalFailAction implements ActionBase {
  type = actionTypes.RDR_REVERSAL_FAIL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle action
 */
export class FILoadAction implements ActionBase {
  type = actionTypes.FIMANAGER_LOAD_ACTION;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the delete vehicle success action
 */
export class  FILoadSuccessAction implements ActionBase {
  type = actionTypes.FIMANAGER_LOAD_SUCESS_ACTION;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the delete vehicle fail action
 */
export class  FILoadFailAction implements ActionBase {
  type = actionTypes.FIMANAGER_LOAD_FAIL_ACTION;

  constructor(public payload: any) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = RdrInitialLoadAction
  | RdrInitialLoadSuccessAction
  | RdrInitialLoadFailAction
  | AddNewRdrAction
  | AddNewRdrSuccessAction
  | AddNewRdrFailAction
  | UpdateRdrAction
  | UpdateRdrSuccessAction
  | UpdateRdrFailAction
  | DeleteRdrAction
  | DeleteRdrSuccessAction
  | DeleteRdrFailAction
  | GetRdrByVinAction
  | GetRdrByVinSuccessAction
  | GetRdrByVinFailAction
  | RdrReversalAction
  | RdrReversalSuccessAction
  | RdrReversalFailAction
  | FILoadAction
  | FILoadSuccessAction
  | FILoadFailAction
  ;
