import { MatDialogRef } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import * as models from '../../../services/model/models';

@Component({
  selector: 'dd-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This component is used to show alert dialog in the screen
 */
export class DialogComponent {
  /**
   * Title to be displayed for the dialog
   */
  public title: string;

  /**
   * Message content to be displayed
   */
  public message: string;

  /**
   * To ensure the OK OR OK & Cancel button displayed
   * type should be 'OK' OR 'OK-CANCEL'
   */
  public dialogType: string;

  /**
   * OK button caption to be displayed
   */
  public okActionText: string;

  /**
   * Cancel button caption to be displayed
   */
  public cancelActionText: string;

  /**
   * Variable to hold the consuming component instance
   */
  public parent: any;
  /**
   * Variable to hold comment
   */
  public comments: models.Comments;

  /**
   * constructor method to create new instance of DialogComponent
   */
  constructor(public dialogRef: MatDialogRef<DialogComponent>) {

  }


  /**
   * Method is used to invoke okAction event to the consuming component
   */
  okAction(event: any) {
    this.dialogRef.close(true);
  }
  /**
   * Method is used to invoke cancelAction event to the consuming component
   */
  cancelAction(event: any) {
    this.dialogRef.close(true);
  }
}
