// Model for holding Message
export interface Message {
    // To store unique Message id
    id?: number;
    // To store message
    message?: string;
    // To store Message type
    type?: MessageType;
}

// Model for holding Message Types
export enum MessageType {
    // To indicate if the message Message is for Success scenarios
    SUCCESS = 0,
    // To indicate if the message Message is for Failure scenarios
    DANGER = 1,
    // To indicate if the message Message is for Warning scenarios
    WARNING = 2
}
