

import { ActionBase } from '../actions/actionbase';
import * as viewModels from '../services/view-model/view-models';
import * as detailActions from '../actions/rdr-detail.action';
import * as models from '../services/model/models';
import { isNil, cloneDeep } from 'lodash';
import { ScreenMessages } from '../shared/master-data/messages';
import { ViewConstants } from '../shared/shared.constants';
/**
 * Interface used to maintain the state for the vehicle detail screen
 */
export interface RdrDetailsState {
  rdrDetail: viewModels.RdrDetailView;
}

/**
 * Variable used to store the initial state of VehicleDetailsState
 */
const initialState: RdrDetailsState = {
  rdrDetail: null
};

/**
 * Method used to set the default initial state of VehicleDetailsState
 */
function setDefaulValue(): viewModels.RdrDetailView {
  let vehicleDetails: viewModels.RdrDetailView =
    <viewModels.RdrDetailView>new Object();
  vehicleDetails.rdrData = {};
  vehicleDetails.message = null;
  vehicleDetails.pageType = '';
  return vehicleDetails;
}

/**
 * Reducer method to handle the vehicle detail screen actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): RdrDetailsState {
  switch (action.type) {
    case detailActions.actionTypes.RDR_INITIAL_LOAD: {
      // return the Vehicle Detail data
      // return { vehicleDetail: setDefaulValue() };
      return state;
    }
    case detailActions.actionTypes.RDR_INITIAL_LOAD_SUCCESS: {
      // return the Vehicle Detail data
      let vehicleData: models.Rdr = cloneDeep(action.payload);
      let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
        rdrData: cloneDeep(action.payload)
      });
      vehicleDetailData.pageType = ViewConstants.UPDATE;
      vehicleDetailData.message = null;
      return { rdrDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.RDR_INITIAL_LOAD_FAIL: {
      if (!isNil(action.payload.vehicleData)) {
        let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
          rdrData: { vin: action.payload.vehicleData.vin },
          pageType: ViewConstants.NO_VIN_VEH_DETAILS,
          message: null
        });
        return { rdrDetail: vehicleDetailData };
      }

    }

    case detailActions.actionTypes.GET_RDR_BY_VIN_SUCCESS: {
      let responseData = cloneDeep(action.payload);
      let vehicleDetailData;
      let message: models.Message = {};
      if (isNil(responseData.code)) {
        vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
          rdrData: formatDataforDetailsView(cloneDeep(action.payload)),
          pageType: ViewConstants.ADD,
          message: null
        });
      } else {
        message.message = ScreenMessages.UNCERTIFIED_TOYOTA_VEHICLE;
        message.type = models.MessageType.DANGER;
        vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
          rdrData: null,
          pageType: '',
          message: message
        });
      }

      return { rdrDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.GET_RDR_BY_VIN_FAIL: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      if (errorResponse.errorCode === 'BAD_REQUEST' || errorResponse.error === ScreenMessages.SERVICE_FAILURE_MESSAGE) {
        message.message = ScreenMessages.INTERNAL_SERVER_ERROR;
      } else {
        message.message = ScreenMessages.INVALID_VIN_NO; 
      }
      message.type = models.MessageType.DANGER;
      let vehicleDetailData: viewModels.VehicleDetailView = setDefaulValue();
      vehicleDetailData.message = message;
      vehicleDetailData.vehicleData = null;
      return { rdrDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.ADD_RDR_SUCESS_ACTION: {
      let message: models.Message = {};
      let tempVehicleData = cloneDeep(state.rdrDetail);
      tempVehicleData.vehicleData = cloneDeep(action.payload);
      let pageType;
      if (isNil(tempVehicleData.vehicleData.code) &&
        isNil(tempVehicleData.vehicleData.desc)) {
        if (action.payload.makeCode === 'TOYOTA')
          message.message = ScreenMessages.ADD_NEW_RDR_SUCCESS;
        else if ((action.payload.makeCode === 'LEXUS' ||
          action.payload.makeCode === 'LEX')
          && action.payload.certificationFlag === 'false')
          message.message = ScreenMessages.ADD_NEW_RDR_SUCCESS;
        else
          message.message = ScreenMessages.ADD_NEW_RDR_SUCCESS;

        message.type = models.MessageType.SUCCESS;
        pageType = ViewConstants.ADDED;
      } else {
        if (tempVehicleData.vehicleData.code === 'CERTIFICATION-DELETED') {
          message.type = models.MessageType.DANGER;
          message.message =
            'RDR cannot be created and certification also got deleted. ' +
            tempVehicleData.vehicleData.desc + '.';
        }
        pageType = tempVehicleData.vehicleData.code;
      }


      let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
        rdrData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });
      return { rdrDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.ADD_RDR_FAIL_ACTION: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      let pageType;
      let tempVehicleData = cloneDeep(state.rdrDetail);
      if (errorResponse.errorCode === 'CERTIFICATION_VALIDATION_FAILED') {
        if (state.rdrDetail.rdrData.makeCode === 'LEXUS' ||
          state.rdrDetail.rdrData.makeCode === 'LEX') {
          message.message = 'RDR has been created but certification failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'RDR cannot be created. ' +
            cloneDeep(action.payload.errorDesc);
        }
        pageType = errorResponse.errorCode;
      } else {
        if (!isNil(action.payload.errorDesc)) {
          message.message = 'RDR cannot be created. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'RDR cannot be created. Internal Server Error.';
        }
        pageType = ViewConstants.FAIL;
      }
      message.type = models.MessageType.DANGER;
      let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
        rdrData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });
      return { rdrDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.UPDATE_RDR_SUCESS_ACTION: {
      let message: models.Message = {};
      let tempVehicleData = cloneDeep(state.rdrDetail);
      tempVehicleData.vehicleData = cloneDeep(action.payload);
      let pageType;
      if (isNil(tempVehicleData.vehicleData.code) &&
        isNil(tempVehicleData.vehicleData.desc)) {
        message.message = ScreenMessages.UPDATE_RDR_SUCCESS;
        message.type = models.MessageType.SUCCESS;
        pageType = ViewConstants.UPDATED;
      } else {

        if (tempVehicleData.vehicleData.code === 'CERTIFICATION-DELETED') {
          message.type = models.MessageType.DANGER;
          message.message = 'RDR and certification got deleted.';
        }
        pageType = tempVehicleData.vehicleData.code;
      }
      let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
        rdrData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });
      return { rdrDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.UPDATE_RDR_FAIL_ACTION: {
      // return the Vehicle Detail data
      let message: models.Message = {};
      let vehicleDetailObj: models.Vehicle;
      let pageType;
      let errorResponse = cloneDeep(action.payload);
      message.type = models.MessageType.DANGER;

      let tempVehicleData = cloneDeep(state.rdrDetail);

      if (errorResponse.errorCode === 'CERTIFICATION-DELETED') {
        message.message = 'RDR and certification got deleted.';
        pageType = errorResponse.errorCode;
      } else if (errorResponse.errorCode === 'CERTIFICATION_VALIDATION_FAILED') {
        if (state.rdrDetail.rdrData.makeCode === 'LEXUS' ||
          state.rdrDetail.rdrData.makeCode === 'LEX') {
          message.message =
            'RDR has been updated successfully. But certification failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'RDR cannot be updated. ' +
            cloneDeep(action.payload.errorDesc);
        }
        pageType = errorResponse.errorCode;
      } else if (errorResponse.errorCode === 'RDR_VALIDATION_FAILED') {
        message.message = 'RDR cannot be updated. ' +
          cloneDeep(action.payload.errorDesc);
        pageType = errorResponse.errorCode;
      } else {
        if (!isNil(action.payload.errorDesc)) {
          message.message = 'RDR update failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'RDR update failed. Internal Server Error.';
        }

        pageType = ViewConstants.UPDATE_FAIL;
      }
      let vehicleDetailData;
      if (!isNil(tempVehicleData)) {
        vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
          rdrData: cloneDeep(tempVehicleData.rdrData),
          pageType: pageType,
          message: message
        });
      } else {
        vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
          rdrData: null,
          pageType: pageType,
          message: message
        });
      }

      return { rdrDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.DELETE_RDR_SUCESS_ACTION: {
      let message: models.Message = {};
      message.type = models.MessageType.SUCCESS;
      message.message = cloneDeep(action.payload.desc);
      let vehicleData: models.Vehicle = cloneDeep(state.rdrDetail);

      let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
        message: message,
        pageType: ViewConstants.DELETED
      });
      return { rdrDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.DELETE_RDR_FAIL_ACTION: {
      // return the Vehicle Detail data
      let message: models.Message = {};
      let vehicleDetailObj: models.Vehicle;
      let pageType;
      let errorResponse = cloneDeep(action.payload);

      message.type = models.MessageType.DANGER;
      let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
        rdrData: cloneDeep(state.rdrDetail),
        pageType: pageType,
        message: message
      });
      if (!isNil(action.payload.errorDesc)) {
        message.message = 'RDR delete failed. ' + cloneDeep(action.payload.errorDesc);
      } else {
        message.message = 'RDR delete failed. Internal Server Error.';
      }


      pageType = ViewConstants.DELETE_FAIL;
      vehicleDetailData.vehicleData.message = message;
      vehicleDetailData.vehicleData.pageType = pageType;
      return { rdrDetail: cloneDeep(vehicleDetailData.rdrData) };
    }

    case detailActions.actionTypes.RDR_REVERSAL_SUCESS_ACTION: {
      let message: models.Message = {};
      let tempVehicleData = cloneDeep(state.rdrDetail);
      tempVehicleData.vehicleData = cloneDeep(action.payload);
      let pageType;
      if (isNil(tempVehicleData.vehicleData.code) &&
        isNil(tempVehicleData.vehicleData.desc)) {
        message.message = ScreenMessages.REVERSE_RDR_SUCCESS;
        message.type = models.MessageType.SUCCESS;
        pageType = ViewConstants.UPDATED;
      } else {

        if (tempVehicleData.vehicleData.code === 'CERTIFICATION-DELETED') {
          message.type = models.MessageType.DANGER;
          message.message = 'RDR data updated but certification deleted. ' +
            tempVehicleData.vehicleData.desc + '.';
        }
        pageType = tempVehicleData.vehicleData.code;
      }
      let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
        rdrData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });
      return { rdrDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.RDR_REVERSAL_FAIL_ACTION: {
      // return the Vehicle Detail data
      let message: models.Message = {};
      let vehicleDetailObj: models.Vehicle;
      let pageType;
      let errorResponse = cloneDeep(action.payload);
      message.type = models.MessageType.DANGER;

      let tempVehicleData = cloneDeep(state.rdrDetail);

      if (errorResponse.errorCode === 'CERTIFICATION-DELETED') {
        message.message = 'RDR data updated but certification deleted. ' +
          cloneDeep(action.payload.errorDesc);
        pageType = errorResponse.errorCode;
      } else if (errorResponse.errorCode === 'CERTIFICATION_VALIDATION_FAILED') {
        if (state.rdrDetail.rdrData.makeCode === 'LEXUS' ||
          state.rdrDetail.rdrData.makeCode === 'LEX') {
          message.message =
            'RDR has been updated successfully. But certification failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'RDR cannot be updated. ' +
            cloneDeep(action.payload.errorDesc);
        }
        pageType = errorResponse.errorCode;
      } else if (errorResponse.errorCode === 'RDR_VALIDATION_FAILED') {
        message.message = 'RDR cannot be updated. ' +
          cloneDeep(action.payload.errorDesc);
        pageType = errorResponse.errorCode;
      } else {
        if (!isNil(action.payload.errorDesc)) {
          message.message = 'RDR update failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'RDR update failed. Internal Server Error.';
        }

        pageType = ViewConstants.UPDATE_FAIL;
      }
      let vehicleDetailData;
      if (!isNil(tempVehicleData)) {
        vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
          rdrData: cloneDeep(tempVehicleData.rdrData),
          pageType: pageType,
          message: message
        });
      } else {
        vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
          rdrData: null,
          pageType: pageType,
          message: message
        });
      }

      return { rdrDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.FIMANAGER_LOAD_SUCESS_ACTION: {
      let vehicleData: models.Rdr = cloneDeep(action.payload);
      let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
        staffData: cloneDeep(action.payload)
      });
      vehicleDetailData.pageType = ViewConstants.ADD;
      vehicleDetailData.message = null;
      return { rdrDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.FIMANAGER_LOAD_FAIL_ACTION: {
      if (!isNil(action.payload.vehicleData)) {
        let vehicleDetailData = (<any>Object).assign({}, state.rdrDetail, {
          staffData: null,
          pageType: ViewConstants.NO_VIN_VEH_DETAILS,
          message: null
        });
        return { rdrDetail: vehicleDetailData };
      }
    }

    case detailActions.actionTypes.RDR_CLEAR_ALL_ACTION: {
      return { rdrDetail: setDefaulValue() };
    }

    default:
      return state;
  }

}
/* tslint:enable:no-switch-case-fall-through */
/**
 * Method to get the state of VehicleDetailsState for vehicle detail screen
 */
export function getRdrDetailsForSelectedVinsState(state: RdrDetailsState) {
  return state.rdrDetail;
}

/**
 * Method used to frame the required state for the vehicle detail screen
 */
function formatDataforDetailsView(data: any): any {
  // this.vehicleDetails =  new Observable<viewModels.VehicleDetailView>();
  let rdrDetailData = <models.Rdr>new Object();
  let totalVehicleCost: number = 0;
  rdrDetailData.vin = data.vin;
  rdrDetailData.dealerCd = data.dealerCd;
  rdrDetailData.model = data.model;
  rdrDetailData.makeCode = data.makeCode;
  rdrDetailData.openCampaignStatus = data.openCampaignStatus;
  rdrDetailData.carFaxFlag = data.carFaxFlag;
  rdrDetailData.certificationNumber = data.certificationNumber;
  rdrDetailData.certificationType = data.certificationType ? data.certificationType : '';
  rdrDetailData.sellArea = data.sellArea;
  rdrDetailData.comment = data.comment;
  if (!isNil(data.certification) && !isNil(data.certification.forceCertificationFlag)) {
    rdrDetailData.certification = <models.CertificationDetails>new Object();
    rdrDetailData.certification.forceCertificationFlag =
      data.certification.forceCertificationFlag;
    rdrDetailData.certification.planAmount = data.certification.planAmount;
    totalVehicleCost += Number(data.certification.planAmount);
  }
  let rdrDetailObj = <models.RdrDetail>new Object();
  rdrDetailObj.financeInsuranceManagerId = null;
  rdrDetailObj.soldAmount = null;
  rdrDetailObj.salesPersonId = null;
  rdrDetailObj.saleType = '01';
  rdrDetailObj.mileage = data.mileage;
  rdrDetailObj.retailDate = null;
  if (!isNil(data.price) && !isNil(data.price.labor)) {
    rdrDetailObj.price = <models.Price>new Object();
    rdrDetailObj.price.labor = data.price.labor;
    rdrDetailObj.price.parts = data.price.parts;
    rdrDetailObj.price.acvPrice = data.price.acvPrice;
    totalVehicleCost +=
      Number(Number(data.price.labor) + Number(data.price.parts) + Number(data.price.acvPrice));
    rdrDetailObj.price.totalVehicleCost = totalVehicleCost.toFixed(1);
  }
  rdrDetailData.rdrDetail = cloneDeep(rdrDetailObj);
  let buyerObj = <models.Buyer>new Object();
  buyerObj.salution = null;
  buyerObj.firstName = null;
  buyerObj.lastName = null;
  buyerObj.zipCode = null;
  buyerObj.extension = null;
  buyerObj.city = null;
  buyerObj.homePhone = null;
  buyerObj.addressLineOne = null;
  buyerObj.emailPermissionInd = null;
  buyerObj.suffix = null;
  buyerObj.addressLineTwo = null;
  buyerObj.middleName = null;
  buyerObj.workPhone = null;
  buyerObj.state = null;
  buyerObj.email = null;
  rdrDetailData.rdrDetail.buyer = cloneDeep(buyerObj);
  rdrDetailData.rdrDetail.cobuyer = cloneDeep(buyerObj);
  return rdrDetailData;
}

