import { UCRDRSummary } from '../app/services/model/RdrSummary';
export class UCRDRSummaryJson {
    static inventories: Array<UCRDRSummary> = [
        {
            vin: 'JTEBU5JR3H5413404',
            model: {
                modelNumber: '8672',
                modelYear: 2017,
                modelDescription: '4Runner TFD Road'
            },
            accessoriesDetails: [
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'DN',
                    accessssoriesName: 'SR5 Parking Sonar & Navigation Package (V6 only)',
                    accessssoriesType: ''
                }
            ],
            makeCode: 'TOYOTA',
            extColor: {
                colorCd: '0083',
                colorDesc: 'BLACK'
            },
            intColor: {
                colorCd: '23',
                colorDesc: 'LIGHT YELLOW'
            },
            dofu: '03/09/2015',
            dealerCd: '04136',
            dealerName: 'Ehrlich Toyota',
            acquiredDate: '02/02/2014',
            price: {
                acvPrice: '201.56',
                retailAmount: '147.22',
                dealerTradeAmount: "15589",
            },
            certification: {
                warrantyCode: 'MPM',
                certificationDate: '11/11/2016',
                certificationNumber: '0012457',
                certificationStatus: 'Failed',
                forceCertificationFlag: 'false'
            },
            openCampaignStatus: 'Y',
            inventoryStatus: 'D',
            acquiredMethod: 'A',
            certificationStatus: 'C',
            carFaxFlag: 'Y',
            foreignVin: 'N',
            createdAt: '2018-05-11T06:00:27.007Z',
            updatedAt: '2018-05-16T22:06:07.046Z',
            updatedBy: '63109 ',
            createdBy: null,
            stockNumber: '14390',
            processingFee: '-1.07',
            transmissionType: 'A',
            rdrDetail: {
                financeInsuranceManagerId: 'Albert',
                dealerPaidAmount: '45000',
                retailDate: '07-03-2018',
                vehicleCost: '35000',
                buyer: {
                    lastName: 'Subbarayan',
                    zipCode: '603019',
                    extension: '+91',
                    city: 'Chennai',
                    homePhone: '8998794577',
                    addressLineOne: '6',
                    emailPermissionInd: 'N',
                    suffix: 'T',
                    firstName: 'Arun',
                    addressLineTwo: 'Big Lane',
                    middleName: 'M',
                    workPhone: '8967559766',
                    state: 'TamilNadu',
                    salution: 'Mr',
                    email: 'test@toyotacom'
                },
                mileage: '60000',
                saleType: '01',
                rdrStatus: 'R',
                soldAmount: '5000',
                reconditioningCost: '5000',
                salesPersonId: '50000',
                createdAt: '2018-05-11T06:00:27.007Z',
                updatedAt: '2018-05-16T22:06:07.046Z',
                updatedBy: '63109 ',
                createdBy: null,
            }
        },
        {
            vin: '4T4BF1FK6GR530502',
            model: {
                modelNumber: '7894',
                modelYear: 2014,
                modelDescription: '4Runner TFD Road'
            },
            accessoriesDetails: [
                {
                    accessssoriesCode: 'FE',
                    accessssoriesName: '50 State Emissions',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'MR',
                    accessssoriesName: 'Power tilt/slide moonroof with sunshade',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'D5',
                    accessssoriesName: 'Door Edge Guards',
                    accessssoriesType: ''
                },
                {
                    accessssoriesCode: 'DN',
                    accessssoriesName: 'SR5 Parking Sonar & Navigation Package (V6 only)',
                    accessssoriesType: ''
                }
            ],
            makeCode: 'TOYOTA',
            extColor: {
                colorCd: '765',
                colorDesc: 'BLACK'
            },
            intColor: {
                colorCd: '45',
                colorDesc: 'LIGHT YELLOW'
            },
            dofu: '03/09/2015',
            dealerCd: '04136',
            dealerName: 'Ehrlich Toyota',
            acquiredDate: '11/12/2013',
            price: {
                acvPrice: '222.56',
                retailAmount: '160.22',
                dealerTradeAmount: "15589",
                reconditionCost: "1546"
            },
            certification: {
                warrantyCode: 'MPM',
                certificationDate: '11/11/2016',
                certificationNumber: '0012457',
                certificationStatus: 'Failed'
            },
            openCampaignStatus: 'Y',
            inventoryStatus: 'D',
            acquiredMethod: 'A',
            certificationStatus: 'C',
            carFaxFlag: 'Y',
            foreignVin: 'N',
            createdAt: '2018-06-11T10:42:33.274Z',
            updatedAt: '2018-06-19T00:00:00.000Z',
            updatedBy: '63109',
            createdBy: null,
            certificationNumber: '6873551',
            stockNumber: '14440',
            processingFee: '-1.07',
            transmissionType: 'A',
            rdrDetail: {
                financeInsuranceManagerId: "Albert",
                updatedBy: "63109",
                dealerPaidAmount: "45000",
                retailDate: "07-03-2018",
                cobuyer: {
                    lastName: "Subbarayan",
                    zipCode: "603019",
                    extension: "+91",
                    city: "Chennai",
                    homePhone: "8998745677",
                    addressLineOne: "6",
                    emailPermissionInd: "Y",
                    suffix: "T",
                    firstName: "Senthil",
                    addressLineTwo: "Big Lane",
                    middleName: "M",
                    workPhone: "8967556766",
                    state: "TamilNadu",
                    salution: "Mr",
                    email: "senthil@toyota.com"
                },
                vehicleCost: "35000",
                buyer: {
                    lastName: "Subbarayan",
                    zipCode: "603019",
                    extension: "+91",
                    city: "Chennai",
                    homePhone: "8998794577",
                    addressLineOne: "6",
                    emailPermissionInd: "Y",
                    suffix: "T",
                    firstName: "Arun",
                    addressLineTwo: "Big Lane",
                    middleName: "M",
                    workPhone: "8967559766",
                    state: "TamilNadu",
                    salution: "Mr",
                    email: "arun@toyota.com"
                },
                mileage: "60000",
                createdAt: "2018-07-10T06:29:32.600Z",
                createdBy: "63109",
                saleType: "01",
                rdrStatus: "R",
                soldAmount: "50000",
                reconditioningCost: "7000",
                updatedAt: "2018-07-10T06:29:32.601Z",
                salesPersonId: "John"
            }
        }
    ]
}