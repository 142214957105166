import { ActionBase } from '../actions/actionbase';
import { StaffMasterFinanceView } from '../services/view-model/feature-config-view';
import * as stafMasterActions from '../actions/staffMaster-detail.action';
import { isNil, cloneDeep } from 'lodash';
import * as models from '../services/model/models';
import { ScreenMessages } from '../shared/master-data/messages';
import { StaffMasterFinanceModel } from '../services/model/featureConfigModel';

/**
 * Interface used to maintain the state for the dealer details
 */
export interface StaffMasterFinanceState {
  staffMasterFinanceDetails: StaffMasterFinanceView;
}
/**
 * Variable used to store the initial state of Dealer Detail
 */
const initialState: StaffMasterFinanceState = {
  staffMasterFinanceDetails: null
};

/**
 * Method used to set the default initial state of staffMasterFinanceDetails
 */
function setDefaulValue(): StaffMasterFinanceView {
  let staffMasterFinanceDetails: StaffMasterFinanceView = <StaffMasterFinanceView>new Object();
  staffMasterFinanceDetails.staffMasterFinanceData = [];
  return staffMasterFinanceDetails;
}
/**
 * Reducer method to handle the dealer detail actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): StaffMasterFinanceState {
  switch (action.type) {
    case stafMasterActions.actionTypes.LOAD_STAFF_MASTER_FINANCE_SUCCESS: {
      let staffMasterFinanceDetail: StaffMasterFinanceModel = {};
      if (!isNil(action.payload)) {
        staffMasterFinanceDetail = action.payload;
      }
      return {
        staffMasterFinanceDetails: (<any>Object).assign({}, state.staffMasterFinanceDetails,
          {
            staffMasterFinanceData: staffMasterFinanceDetail,
          })
      };
    }
    case stafMasterActions.actionTypes.LOAD_STAFF_MASTER_FINANCE_FAIL: {
      let staffMasterFinanceDetail = [];
      let message: models.Message = {};
      message.message = 'Internal server error, kindly try after sometime';
      message.type = models.MessageType.WARNING;
      return {
        staffMasterFinanceDetails: (<any>Object).assign({}, state.staffMasterFinanceDetails,
          {
            message: message,
            staffMasterFinanceData: staffMasterFinanceDetail
          })
      };
    }

    case stafMasterActions.actionTypes.CLEAR_ALL_ACTION: {
      return { staffMasterFinanceDetails: setDefaulValue() };
    }
    default: {
      return state;
    }
  }
}
/* tslint:enable:no-switch-case-fall-through */

/**
 * Method to get the state of dealer details
 */
export function getStaffMasterFinanceDetail(state: StaffMasterFinanceState) {
  return state.staffMasterFinanceDetails;
}




