import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */


/**
 * Following action types are being used in the inventory summary screen
 */

export const ACTION_TYPES = {
  GET_INVENTORY_REPORT_LOAD: type('[UCInventoryReport] Initiate load for inventory details'),
  GET_INVENTORY_REPORT_SUCCESS: type('[UCInventoryReport] inventory Success'),
  GET_INVENTORY_REPORT_FAIL: type('[UCInventoryReport] inventory details Fail'),
  CLEAR_ALL_ACTION: type('[UCInventoryReport] clear all vehicle report data'),
  GET_GST_SET_DATA: type('[UCInventoryReport] Load GST-SET data for Inventory details'),
  GET_GST_SET_DATA_SUCCESS: type('[UCInventoryReport] Load GST-SET data success'),
  GET_GST_SET_DATA_FAILURE: type('[UCInventoryReport] Load GST-SET data failure'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to load the Initial data for inventory summary screen
 */
export class GetInventoryReport implements ActionBase {
  type = ACTION_TYPES.GET_INVENTORY_REPORT_LOAD;
  constructor(public payload: any) { }
}
/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetInventoryReportSuccessAction implements ActionBase {
  type = ACTION_TYPES.GET_INVENTORY_REPORT_SUCCESS;
  constructor(public payload: any) { }
}
/**
 * Class used to handle the Failure action for Initial Load method
 */
export class GetInventoryReportFailureAction implements ActionBase {
  type = ACTION_TYPES.GET_INVENTORY_REPORT_FAIL;
  constructor(public payload: any) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class ClearAllAction implements ActionBase {
  type = ACTION_TYPES.CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Class used to load the GST-SET Data to the inventory screen
 */
export class GetGSTSETDataAction implements ActionBase {
  type = ACTION_TYPES.GET_GST_SET_DATA;

  constructor(public payload: any) { }
}

export class GetGSTSETDataSuccessAction implements ActionBase {
  type = ACTION_TYPES.GET_GST_SET_DATA_SUCCESS;

  constructor(public payload: any) { }
}

export class GetGSTSETDataFailureAction implements ActionBase {
  type = ACTION_TYPES.GET_GST_SET_DATA_FAILURE;

  constructor(public payload: any) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  GetInventoryReport
  | GetInventoryReportSuccessAction
  | GetInventoryReportFailureAction
  | ClearAllAction;

