import { NgModule } from '@angular/core';
import { DealerParticipantPageComponent } from './dealer-participant-page.component';
import { DealerParticipantComponent } from './dealer-participant.component';
import { SharedModule } from '../../shared/shared.module';
import { DealerParticipantRoutingModule } from './dealer-participant-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    SharedModule,
    DealerParticipantRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule
  ],
  exports: [
    DealerParticipantComponent,
    DealerParticipantPageComponent
  ],
  declarations: [
    DealerParticipantComponent,
    DealerParticipantPageComponent
  ]
})
export class DealerParticipantModule {
}
