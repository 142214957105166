import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCSalesConsultantRDRDetailReportRoutingModule }
    from './sales-consultant-rdr-detail-report-routing.module';
import { UCSalesConsultantRDRDetailPageReportComponent }
    from './sales-consultant-rdr-detail-report-page.component';
import { UCSalesConsultantRDRDetailReportGridComponent }
    from './sales-consultant-rdr-detail-report-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCSalesConsultantRDRDetailReportRoutingModule
    ],
    exports: [
      UCSalesConsultantRDRDetailPageReportComponent
    ],
    declarations: [
      UCSalesConsultantRDRDetailPageReportComponent,
      UCSalesConsultantRDRDetailReportGridComponent
    ]
})

export class UCSalesConsultantRDRDetailReportModule {

}
