

import { Observable } from 'rxjs';

import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as models from '../services/model/models';
import * as messageDataActions from '../actions/message-data.action';

/**
 * Interface used to maintain the state for the message Data
 */
export interface MessageDataState {
  messageData: models.Message[];
}
/**
 * Variable used to store the initial state of message Data
 */
const initialState: MessageDataState = {
  messageData: []
};
/**
 * Reducer method to handle the message Data load actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): MessageDataState {
  switch (action.type) {
    case messageDataActions.actionTypes.SET_DISPLAY_MESSAGE: {
      // Generate the Message ID randonmly
      let message = (<any>Object).assign({}, action.payload,
        { id: ((new Date().getTime() * 10000) + 621355968000000000) });
      return { messageData: [message] };
    }
    case messageDataActions.actionTypes.CLEAR_ALL_DISPLAY_MESSAGE: {
      return { messageData: [] };
    }
    case messageDataActions.actionTypes.CLOSE_DISPLAY_MESSAGE: {
      return {
        messageData: state.messageData.filter(message => {
          return message.id !== action.payload.id;
        })
      };
    }
    default: {
      return state;
    }
  }
}
/* tslint:enable:no-switch-case-fall-through */
/**
 * Method to get the state of message data
 */
export function getMessageData(state: MessageDataState) {
  return state.messageData;
}

