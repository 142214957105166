import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
    static mtdGridColumns = [
        {// region
            key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'regionNumber', style: '',
            hasTemplate: 'true',
            sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
            columnMaxWidth: '100', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
            filterType: FilterType.CONTAINS
        },
        {// regionName
            key: ViewConstants.REGION_NAME_COLUMN, fieldName: 'regionName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '185',
            columnMinWidth: '185', columnMaxWidth: '185', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
         {// DISTRICT
            key: ViewConstants.DISTRICT, fieldName: 'districtNumber', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
            columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealerName
            key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '200',
            columnMinWidth: '200', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealer#
            key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '150',
            columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// DAILY_CERTIFIED_SALES_COUNT
            key: ViewConstants.DAILY_CERTIFIED_SALES_COUNT, fieldName: 'dailyCertifiedSalesCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// silverDailyCertifiedSalesCount
            key: ViewConstants.SILVER_DAILY_CERTIFIED_SALES_COUNT,
            fieldName: 'silverDailyCertifiedSalesCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// goldDailyCertifiedSalesCount
            key: ViewConstants.GOLD_DAILY_CERTIFIED_SALES_COUNT,
            fieldName: 'goldDailyCertifiedSalesCount', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// certifiedSalesCount
            key: ViewConstants.CERTIFIED_SALES_COUNT, fieldName: 'certifiedSalesCount',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
            columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN
        },
        {// silverCertifiedSalesCount
            key: ViewConstants.SILVER_MONTHLY_CERTIFIED_SALES_COUNT, fieldName: 'silverCertifiedSalesCount',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '170', columnMinWidth: '170',
            columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN
        },
        {// goldCertifiedSalesCount
            key: ViewConstants.GOLD_MONTHLY_CERTIFIED_SALES_COUNT, fieldName: 'goldCertifiedSalesCount',
            style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '', dataTypeformat: '', filterType: FilterType.STARTS_WITH
        },
        {// dailyCertifiedInventory
            key: ViewConstants.DAILY_CERTIFIED_INVENTORY, fieldName: 'dailyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// silverDailyCertifiedInventory
            key: ViewConstants.SILVER_DAILY_CERTIFIED_INVENTORY, fieldName: 'silverDailyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// goldDailyCertifiedInventory
            key: ViewConstants.GOLD_DAILY_CERTIFIED_INVENTORY, fieldName: 'goldDailyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// monthlyCertifiedInventory
            key: ViewConstants.MONTHLY_CERTIFIED_INVENTORY, fieldName: 'certifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// silverMonthlyCertifiedInventory
            key: ViewConstants.SILVER_MONTHLY_CERTIFIED_INVENTORY, fieldName: 'silverCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// goldMonthlyCertifiedInventory
            key: ViewConstants.GOLD_MONTHLY_CERTIFIED_INVENTORY,
            fieldName: 'goldCertifiedInventory', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// INVENTORY_COUNT
            key: ViewConstants.INVENTORY_COUNT, fieldName: 'invCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// SILVER_INVENTORY_COUNT
            key: ViewConstants.SILVER_INVENTORY_COUNT, fieldName: 'silverInvCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// GOLD_INVENTORY_COUNT
            key: ViewConstants.GOLD_INVENTORY_COUNT, parent: ViewConstants.TOTAL,
            fieldName: 'goldInvCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },



    ];
    static ytdGridColumns = [


        {// region
            key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'regionNumber', style: '',
            hasTemplate: 'true',
            sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
            columnMaxWidth: '100', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
            filterType: FilterType.CONTAINS
        },
        {// regionName
            key: ViewConstants.REGION_NAME_COLUMN, fieldName: 'regionName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '185',
            columnMinWidth: '185', columnMaxWidth: '185', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
         {// DISTRICT
            key: ViewConstants.DISTRICT, fieldName: 'districtNumber', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
            columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealerName
            key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '200',
            columnMinWidth: '200', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealerName
            key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '150',
            columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// DAILY_CERTIFIED_SALES_COUNT
            key: ViewConstants.DAILY_CERTIFIED_SALES_COUNT, fieldName: 'dailyCertifiedSalesCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// silverDailyCertifiedSalesCount
            key: ViewConstants.SILVER_DAILY_CERTIFIED_SALES_COUNT, fieldName: 'silverDailyCertifiedSalesCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// goldDailyCertifiedSalesCount
            key: ViewConstants.GOLD_DAILY_CERTIFIED_SALES_COUNT, fieldName: 'goldDailyCertifiedSalesCount',
            style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// certifiedSalesCount
            key: ViewConstants.YTD_CERTIFIED_SALES_COUNT, fieldName: 'certifiedSalesCount',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
            columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN
        },
        {// silverCertifiedSalesCount
            key: ViewConstants.SILVER_YTD_CERTIFIED_SALES_COUNT, fieldName: 'silverCertifiedSalesCount',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '170', columnMinWidth: '170',
            columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN
        },
        {// goldCertifiedSalesCount
            key: ViewConstants.GOLD_YTD_CERTIFIED_SALES_COUNT, fieldName: 'goldCertifiedSalesCount',
            style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '', dataTypeformat: '', filterType: FilterType.STARTS_WITH
        },

        {// dailyCertifiedInventory
            key: ViewConstants.DAILY_CERTIFIED_INVENTORY, fieldName: 'dailyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// silverDailyCertifiedInventory
            key: ViewConstants.SILVER_DAILY_CERTIFIED_INVENTORY, fieldName: 'silverDailyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// goldDailyCertifiedInventory
            key: ViewConstants.GOLD_DAILY_CERTIFIED_INVENTORY,
            fieldName: 'goldDailyCertifiedInventory', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// YTD_CERTIFIED_INVENTORY
            key: ViewConstants.YTD_CERTIFIED_INVENTORY, fieldName: 'certifiedInventory', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// SILVER_YTD_CERTIFIED_INVENTORY
            key: ViewConstants.SILVER_YTD_CERTIFIED_INVENTORY,
            fieldName: 'silverCertifiedInventory', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// GOLD_YTD_CERTIFIED_INVENTORY
            key: ViewConstants.GOLD_YTD_CERTIFIED_INVENTORY, fieldName: 'goldCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// INVENTORY_COUNT
            key: ViewConstants.INVENTORY_COUNT, fieldName: 'invCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// SILVER_INVENTORY_COUNT
            key: ViewConstants.SILVER_INVENTORY_COUNT, fieldName: 'silverInvCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// GOLD_INVENTORY_COUNT
            key: ViewConstants.GOLD_INVENTORY_COUNT, fieldName: 'goldInvCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },

    ];
    static customGridColumns = [

        {// region
            key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'regionNumber', style: '',
            hasTemplate: 'true',
            sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
            columnMaxWidth: '100', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
            filterType: FilterType.CONTAINS
        },
        {// regionName
            key: ViewConstants.REGION_NAME_COLUMN, fieldName: 'regionName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '185',
            columnMinWidth: '185', columnMaxWidth: '185', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// DISTRICT
            key: ViewConstants.DISTRICT, fieldName: 'districtNumber', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
            columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealerName
            key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '200',
            columnMinWidth: '200', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealerCD
            key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '150',
            columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// DAILY_CERTIFIED_SALES_COUNT
            key: ViewConstants.DAILY_CERTIFIED_SALES_COUNT, fieldName: 'dailyCertifiedSalesCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// silverDailyCertifiedSalesCount
            key: ViewConstants.SILVER_DAILY_CERTIFIED_SALES_COUNT, fieldName: 'silverDailyCertifiedSalesCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// goldDailyCertifiedSalesCount
            key: ViewConstants.GOLD_DAILY_CERTIFIED_SALES_COUNT, fieldName: 'goldDailyCertifiedSalesCount',
            style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// certifiedSalesCount
            key: ViewConstants.CUSTOM_CERTIFIED_SALES_COUNT, fieldName: 'certifiedSalesCount',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
            columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN
        },
        {// silverCertifiedSalesCount
            key: ViewConstants.SILVER_MONTHLY_CERTIFIED_SALES_COUNT, fieldName: 'silverCertifiedSalesCount',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '170', columnMinWidth: '170',
            columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN
        },
        {// goldCertifiedSalesCount
            key: ViewConstants.GOLD_MONTHLY_CERTIFIED_SALES_COUNT,
            fieldName: 'goldCertifiedSalesCount', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '', dataTypeformat: '', filterType: FilterType.STARTS_WITH
        },
        {// dailyCertifiedInventory
            key: ViewConstants.DAILY_CERTIFIED_INVENTORY, fieldName: 'dailyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// silverDailyCertifiedInventory
            key: ViewConstants.SILVER_DAILY_CERTIFIED_INVENTORY, fieldName: 'silverDailyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// goldDailyCertifiedInventory
            key: ViewConstants.GOLD_DAILY_CERTIFIED_INVENTORY, fieldName: 'goldDailyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// CUSTOM_CERTIFIED_INVENTORY
            key: ViewConstants.CUSTOM_CERTIFIED_INVENTORY, fieldName: 'certifiedInventory', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// SILVER_CUSTOM_CERTIFIED_INVENTORY
            key: ViewConstants.SILVER_CUSTOM_CERTIFIED_INVENTORY, fieldName: 'silverCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// GOLD_CUSTOM_CERTIFIED_INVENTORY
            key: ViewConstants.GOLD_CUSTOM_CERTIFIED_INVENTORY,
            fieldName: 'goldCertifiedInventory', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// INVENTORY_COUNT
            key: ViewConstants.INVENTORY_COUNT, fieldName: 'invCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// SILVER_INVENTORY_COUNT
            key: ViewConstants.SILVER_INVENTORY_COUNT, fieldName: 'silverInvCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// GOLD_INVENTORY_COUNT
            key: ViewConstants.GOLD_INVENTORY_COUNT, parent: ViewConstants.TOTAL,
            fieldName: 'goldInvCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },

    ];
}
