/***
 * Import statements required for Inventory Detail Service
 * for invoking services required for populating
 * all sections in the Vehicle Inventory Detail Screen
 */
import { Injectable } from '@angular/core';
import { Headers, RequestOptionsArgs, Response, URLSearchParams } from '@angular/http';


import { Observable } from 'rxjs';
import * as models from '../model/models';
// TODO: correctly add the JSON so that it can be read
import { HttpClient } from '../../shared/components/helpers/http-client';
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedDataService } from '../common/shared-data.service';
// import { USE_MOCK_SERVICE } from '../constants';
import { isNil } from 'lodash';
import { SharedFunctionService } from '../common/shared-function.service';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
/***
 * Class that is used to invoke the Services for populating
 * all the sections of Vehicle Inventory Detail screen
 */
@Injectable()
export class VehicleHistoryService {
  public defaultHeaders: Headers = new Headers();
  protected basePath = environment.BASE_SERVICE_URL;
  protected getVinInfoServicePath = environment.GET_VIN_INFO_SERVICE_URL;
  protected getVehicleServicePath = environment.VEHICLE_HISTORY_SERVICE_URL;
  // To populate the Base Path
  // protected basePath = BASE_SERVICE_URL + '/dealer-inventory';
  /***
   * Constructor of Inventory Detail Service to inject the angluar http
   */
  constructor(private logger: Logger,
    public sharedDataService: SharedDataService,
    protected httpClient: HttpClient,
    private sharedFuncService: SharedFunctionService) { }

  /***
   * This method is used to get the Vehicle Inventory Detail data
   * for the given VIN number
   */
  public getVehicleHistoryByVin(vehicle: models.Vehicle): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    let path = this.getVehicleServicePath + '?vin=' + vehicle.vin;
    if (!isNil(vehicle.dealerCd)) {
      path = path + '&dealerCd=' + vehicle.dealerCd;
    }
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions: RequestOptionsArgs = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      // let vehicleSummary =
      //   UCInventorySummaryJson.inventories.filter(veh => veh.vin === vehicle.vin);
      // let vehicle = Object.assign({}, vehicleSummary);
      // return new Observable<models.Vehicle>(observer => {
      //   observer.next(vehicle);
      //   observer.complete();
      // });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
      .pipe(map((response: any) => {
          // this.logger.log(ViewConstants.VEHICLES_RESPONSE_RECEIVED_LOGGER + response);
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }), catchError((err: any) => {
          return this.sharedFuncService.handleError(err);
        }));
    }
  }
}
