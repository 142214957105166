import {
  Component, Input, OnDestroy, OnInit, ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty, isNil, isUndefined } from 'lodash';
import { IMyDpOptions } from 'mydatepicker';

import { Observable } from 'rxjs';
import * as dealerActions from '../../actions/dealer-detail.action';
import * as dealerDetailActions from '../../actions/dealer-detail.action';
import * as messageActions from '../../actions/message-data.action';
import * as messageDataActions from '../../actions/message-data.action';
import * as saleDetailActions from '../../actions/sale-detail.action';
import * as fromRoot from '../../reducers';
import { SharedDataService } from '../../services/common/shared-data.service';
import { SharedFunctionService } from '../../services/common/shared-function.service';
import * as models from '../../services/model/models';
import { DealerDetailView } from '../../services/view-model/dealer-detail-view';
import * as viewModels from '../../services/view-model/view-models';
import { DialogService } from '../../shared/components/dialog/dialog.service';
import { ScreenMessages } from '../../shared/master-data/messages';
import { ViewConstants } from '../../shared/shared.constants';
import { StaffMasterFinanceView, StaffMasterSalesView } from '../../services/view-model/feature-config-view';


@Component({
  selector: 'uc-sale-detail-page',
  templateUrl: 'sale-detail-page.component.html'
})

/**
 * This component is used to show the Vehicle Detail screen
 */
export class SaleDetailPageComponent implements OnInit, OnDestroy {
  @Input() vin: string;
  // Route param subscriber to get the VIN to be loaded
  routeParamSubscriber: any;
  // To expand and collapse Expansion Panel
  expand: boolean = true;
  // to store update flag value
  public updateFlag: boolean;
  // to store add flag value
  public addFlag: boolean;
  // to store add & certify flag value
  public addCertifyFlag: boolean;
  // to store router text
  public routerText: string;
  // to store savebutton text
  public saveButtonText: string;
  // to store update button text
  public updateButtonText: string;
  // Observable to obtain VehicleDetailView object
  rdreDetails$: Observable<viewModels.RdrDetailView>;
  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<DealerDetailView>;

  // Observable to obtain VehicleDetailView object
  rdrDetails: viewModels.RdrDetailView = <viewModels.RdrDetailView>new Object();

  // Observable to obtain VehicleDetailView object
  reStorevehicleDetails: any = <any>new Object();

  // To set and get the obtained VehicleDetailView object from reducer using this variable
  public _vehicleDetails: viewModels.VehicleDetailView
    = <viewModels.VehicleDetailView>new Object();
  // to store makeCode
  makeCode: string;

  // to store certified vehcicle check value
  certifiedFlag: string;

  // to store certified vehcicle check value
  forcecertifiedFlag: string;

  warrantExpDateText: string;

  isCertify: boolean;

  isValidPQSS: boolean;

  isValidCarFax: boolean;

  isValidForeignvin: boolean;

  addMode: boolean;

  reversalFlag: boolean = false;

  reversedFlag: boolean = false;

  actionMode: string;

  isForceCertified: boolean;

  oldRetailDate: any;

  isRetailModified: boolean;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    inline: false,
    openSelectorTopOfInput: false,
    showClearDateBtn: true,
    showInputField: true,
    alignSelectorRight: true,
    showSelectorArrow: false
  };

  isMandatoryForCertify: boolean;

  // Variable to hold the datas displayed in the grid
  public allDealers: models.DealerDetailsModel[];

  displayInvalidVin: boolean;

  displayInvalidDealerCode: boolean;

  showAdditionalDriver: boolean = false;

  isEmailMandatory: boolean = false;

  isCoEmailMandatory: boolean = false;

  isIndividualCustomer: boolean = false;

  fIManagerItems: Array<any>;

  saleManagerItems: Array<any>;

  getStaffMasterData$: Observable<StaffMasterFinanceView>;
  getStaffMasterSalesData$: Observable<StaffMasterSalesView>;

  // Route param subscriber to get the vehicle detail to be loaded
  private rdrDetailSubscriber: any;
  private staffDataFinanceSubscriber: any;
  private staffDataSalesSubscriber: any;
  /**
   * constructor method to create new instance of VehicleDetailPageComponent
   */
  constructor(private store: Store<fromRoot.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    public sharedDataService: SharedDataService,
    private viewContainerRef: ViewContainerRef,
    private sharedFuncService: SharedFunctionService,
    private dialogsService: DialogService
  ) {
    try {
      this.rdreDetails$ = store.select(fromRoot.getSaleByVin);
      this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
      this.getStaffMasterData$ = store.select(fromRoot.getStaffMasterFinanceData);
      this.getStaffMasterSalesData$ = store.select(fromRoot.getStaffMasterSalesData);
    } catch (error) {
    } finally {

    }
  }
  /**
   * Init method to initialize state of VehicleDetailPageComponent
   */
  ngOnInit() {
    this.makeCode = '';
    this.onDisableFuture();
    // this.onDisablePast();
    this.makeCode = this.sharedDataService.brandName;
    this.isMandatoryForCertify = false;
    this.displayInvalidVin = false;
    this.displayInvalidDealerCode = false;
    this.isValidCarFax = true;
    this.isValidForeignvin = true;
    this.isValidPQSS = true;
    this.addFlag = false;
    this.addCertifyFlag = false;
    this.isCertify = true;
    this.certifiedFlag = '';
    this.forcecertifiedFlag = '';
    this.addMode = false;
    let vehData: models.Vehicle = <models.Vehicle>new Object();
    this.sharedFuncService.setCurrentScreenName(ViewConstants.VEHICLE_DETAIL_SCREEN_NAME);
    this.store.dispatch(new saleDetailActions.FILoadAction(this.sharedDataService.dealerCode));
    this.routeParamSubscriber = this.route.params.subscribe(params => {
      if (!isNil(params['vin']) && !isUndefined(params['vin'])) {
        let selectedVehicles = this.sharedDataService.getSelectedVehicle();
        let vehicle: models.Rdr = {};
        vehicle.vin = params['vin'];
        this.actionMode = params['action'];
        vehicle.dealerCd = this.sharedDataService.dealerCode;
        vehicle.retailDate = selectedVehicles?.retailDate;
        // this.store.dispatch(new saleDetailActions.SaleInitialLoadAction(vehicle));
        this.vin = params['vin'];
        this.updateFlag = true;
        // this.showAdditionalDriver = true;
        this.routerText = 'Sale Details';
        this.saveButtonText = 'UPDATE';
        this.brandAssign(this.makeCode, 'UPDATE', 'ADD NEW');

        vehData.vin = this.vin;
        vehData.dealerCd = params['dlrCd'];
        vehData.dealerCd = vehData.dealerCd.trim();
        if (vehData.dealerCd !== this.sharedDataService.dealerCode) {
          this.setDealer(vehData.dealerCd);
        }

        if (this.actionMode === 'reversal') {
          this.reversalFlag = true;
          this.saveButtonText = 'REVERSE RDR';
        }

        if (this.actionMode === 'reversed') {
          this.reversedFlag = true;
          this.reversalFlag = true;
        }
        // this.makeCode = this.sharedDataService.brandName;
        this.brandAssign(this.makeCode, '', '');

        if (!isNil(this.sharedDataService.rdrData) &&
          !isNil(this.sharedDataService.rdrData.rdrData)) {
          this.rdrDetails =
            this.formatCertificationData(cloneDeep(this.sharedDataService.rdrData));
        } else {
          this.store.dispatch(new saleDetailActions.SaleInitialLoadAction(vehicle));
        }

        // this.store.dispatch(new rdrDetailActions.GetCurrentState());
      } else {
        this.addAction(true, true);
      }
    });

    // this.staffDataFinanceSubscriber = this.getStaffMasterData$.subscribe(data => {
    //   this.fIManagerItems = data?.staffMasterFinanceData?.length > 0 ? data.staffMasterFinanceData.map
    //     ((item: any) => ({
    //       name: `${item.firstName + ' ' + item.lastName}`,
    //       uid: item.uid,
    //       spinId: item.spinId,
    //     })) : [];
    // });

    // this.staffDataSalesSubscriber = this.getStaffMasterSalesData$.subscribe(data => {
    //   this.saleManagerItems = data?.staffMasterSalesData?.length > 0 ? data.staffMasterSalesData.map
    //     ((item: any) => ({
    //       name: `${item.firstName + ' ' + item.lastName}`,
    //       uid: item.uid,
    //       spinId: item.spinId,
    //     })) : [];
    // });

    this.rdrDetailSubscriber = this.rdreDetails$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        if (!isNil(data.message)) {
          this.isMandatoryForCertify = false;
          this.displayInvalidVin = false;
          this.displayInvalidDealerCode = false;
          if (data.message.message === 'Enter a valid VIN') {
            this.addFlag = false;
            this.addCertifyFlag = false;
            this.forcecertifiedFlag = '';
          }
          if (data.pageType !== ViewConstants.DELETED) {
            this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
              data.message
            ));
          }

          if (data.pageType === 'CERTIFICATION_VALIDATION_FAILED') {
            this.rdrDetails = this.formatCertificationData(cloneDeep(data));
            this.isMandatoryForCertify = false;
            this.sharedDataService.dealerCode = this.rdrDetails.rdrData.dealerCd;
            if (this.makeCode === 'LEXUS') {
              this.updateChangeRedirection();
              this.brandAssign(this.makeCode, '', '');
            }
          } else if (data.pageType === 'CERTIFICATION-DELETED') {
            this.rdrDetails = this.formatCertificationData(cloneDeep(data));
            this.certifiedFlag = 'N';
            this.forcecertifiedFlag = 'N';
            this.isMandatoryForCertify = false;
            this.displayInvalidVin = false;
            this.displayInvalidDealerCode = false;
            this.sharedDataService.dealerCode = this.rdrDetails.rdrData.dealerCd;
          } else if (data.pageType === ViewConstants.UPDATED) {
            this.sharedDataService.dealerCode = this.rdrDetails.rdrData.dealerCd;
          } else if (data.pageType === ViewConstants.CERTIFIED) {
            this.rdrDetails = this.formatCertificationData(cloneDeep(data));
            this.certifiedFlag = 'Y';
            this.forcecertifiedFlag = '';
            this.sharedDataService.dealerCode = this.rdrDetails.rdrData.dealerCd;
          } else if (data.pageType === ViewConstants.DECERTIFIED) {
            this.rdrDetails = this.formatCertificationData(cloneDeep(data));
            this.certifiedFlag = 'N';
            this.forcecertifiedFlag = 'N';
            this.sharedDataService.dealerCode = this.rdrDetails.rdrData.dealerCd;
          } else if (data.pageType === ViewConstants.ADDED) {
            this.rdrDetails = this.formatCertificationData(cloneDeep(data));
            this.sharedDataService.dealerCode = this.rdrDetails.rdrData.dealerCd;
            this.updateFlag = true;
            this.routerText = 'Sale Details';
            this.saveButtonText = 'UPDATE';
            this.updateButtonText = 'ADD NEW';
            this.rdrDetails.pageType = '';
          } 

        }else if(!isNil(data.staffData)){
          this.setSalesAndFinanceDropdown(data);
        }
         else if (!isNil(data.rdrData) && !isNil(data.rdrData.makeCode)
          && (data.rdrData.makeCode !== this.makeCode &&
            data.rdrData.makeCode !== this.makeCode.substr(0, 3))
          && data.rdrData.vin.length >= 17) {
          this.displayInvalidVin = true;
          this.formatDataforDetailsView();
          this.rdrDetails.rdrData.vin = data.rdrData.vin;
          this.rdrDetails.rdrData.makeCode = data.rdrData.makeCode;
        } else if (data.pageType === ViewConstants.NO_VIN_VEH_DETAILS &&
          this.addMode === true) {
          this.addMode = false;
          this.isMandatoryForCertify = false;
          this.displayInvalidVin = false;
          this.displayInvalidDealerCode = false;
        } else if (data !== undefined && data.pageType !== ViewConstants.FAIL &&
          data.pageType !== ViewConstants.NO_VIN_VEH_DETAILS && !isNil(data.rdrData)
          && (!isNil(data.rdrData.vin))) {
          this.validateSkipFlags(this.rdrDetails.rdrData);
          if (!(data.pageType === ViewConstants.UPDATE && this.updateFlag === false)) {
            this.rdrDetails = this.formatCertificationData(cloneDeep(data));
          }

          if (!isNil(data.rdrData) && !isNil(this.rdrDetails.rdrData.certificationStatus)
            && this.rdrDetails.rdrData.certificationStatus === 'C') {
            this.certifiedFlag = 'Y';
            this.forcecertifiedFlag = '';
          } else {
            this.certifiedFlag = 'N';
            this.forcecertifiedFlag = 'N';
          }

          // if (isNil(this.makeCode)) {
          //     this.makeCode = this.sharedDataService.brandName;
          //     this.brandAssign(this.makeCode, '', '');
          // }

          if (!isNil(data.rdrData) && !isUndefined(this.rdrDetails.rdrData.vin)) {
            this.addFlag = true;
            this.addCertifyFlag = true;
          }


          if (!isNil(data.rdrData) && data.pageType === ViewConstants.ADD) {
            
            this.addAction(false, this.isCertify);
            // this.forcecertifiedFlag = 'N';

            if (!isNil(this.sharedDataService.dealerCode)) {
              this.rdrDetails.rdrData.dealerCd = this.sharedDataService.dealerCode;
            } else {
              this.rdrDetails.rdrData.dealerCd = null;

            }
          } else if (data.pageType === ViewConstants.UPDATE &&
            this.updateFlag === false) {
            this.reStorevehicleDetails = this.formatCertificationData(cloneDeep(data));
            let test: boolean;
            this.dialogsService.open(ViewConstants.ADD_NEW_VEHICLE,
              ScreenMessages.VEHICLE_ADD_UPDATE_CONFIRMATION,
              ViewConstants.YES, ViewConstants.NO,
              ViewConstants.OK_CANCEL,
              this.viewContainerRef, this, this.updateVehicleConfirmed)
              .subscribe(res => test = res);
          }
        }
      } else {
        this.formatDataforDetailsView();
      }
    });
  }

  private setSalesAndFinanceDropdown(data: viewModels.RdrDetailView) {
    if(data.staffData) {
      this.saleManagerItems = [];
      this.fIManagerItems = [];
      data.staffData.forEach((staff)=>{
        if(staff.jobType === 'Finance'){
          this.fIManagerItems.push(staff);
        }else if(staff.jobType === 'Sales') {
          this.saleManagerItems.push(staff);
        }
      })
    }
    if (!isNil(this.fIManagerItems) || !isNil(this.saleManagerItems)) {
    if (this.fIManagerItems?.length > 0) {
      this.fIManagerItems.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    if (!isNil(data.rdrData) &&
      !isNil(data.rdrData.rdrDetail) &&
      !isNil(data.rdrData.rdrDetail.financeInsuranceManagerId) &&
      this.fIManagerItems.map((el) => el.name).
        indexOf(data.rdrData.rdrDetail.financeInsuranceManagerId) === -1) {
      this.fIManagerItems.push({
        name: data.rdrData.rdrDetail.financeInsuranceManagerId,
        spinId: '104' +
          data.rdrData.rdrDetail.financeInsuranceManagerUId
      });
    }
    // this.saleManagerItems = data.staffData.filter(t => t.jobType === 'Sales');
    if (this.saleManagerItems?.length > 0) {
      this.saleManagerItems.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }

    if (!isNil(data.rdrData) &&
      !isNil(data.rdrData.rdrDetail) &&
      !isNil(data.rdrData.rdrDetail.salesPersonId) &&
      this.saleManagerItems.map((el) => el.name).
        indexOf(data.rdrData.rdrDetail.salesPersonId) === -1) {
      this.saleManagerItems.push({
        name: data.rdrData.rdrDetail.salesPersonId,
        spinId: '104' +
          data.rdrData.rdrDetail.salesPersonUId
      });
    }
    }
  }

  fetchAllDealers() {
    this.dealerDetailsData$.subscribe(data => {
      if (!isNil(data) && data !== undefined) {
        if (!isNil(data.allDealers) && data.allDealers.length > 0) {
          this.allDealers = cloneDeep(data.allDealers);
        }
      }
    });
    this.store.dispatch(new dealerActions.LoadAllDealersAction({}));
  }




  /**
   * Method to redirect update screen for existing vin
   */
  updateVehicleConfirmed(event: any) {
    event.dialogRef.close(true);
    event.parent.vehicleDetails = cloneDeep(event.parent.reStorevehicleDetails);
    event.parent.updateFlag = true;
    event.parent.routerText = 'Sale Details';
    event.parent.saveButtonText = 'UPDATE';
    event.parent.updateButtonText = 'ADD NEW';
    event.parent.isMandatoryForCertify = false;
    event.parent.displayInvalidVin = false;
    event.parent.displayInvalidDealerCode = false;
    if (!isNil(event.parent.vehicleDetails))
      event.parent.vehicleDetails.pageType = '';

    if (!isNil(event.parent.vehicleDetails.vehicleData.certificationStatus)
      && event.parent.vehicleDetails.vehicleData.certificationStatus === 'C') {
      event.parent.certifiedFlag = 'Y';
      event.parent.forcecertifiedFlag = '';
    } else {
      event.parent.certifiedFlag = 'N';
      event.parent.forcecertifiedFlag = 'N';
    }

    if (event.parent.makeCode === 'TOYOTA')
      event.parent.warrantExpDateText = 'POWERTRAIN COVERAGE EXPIRY DATE';
    else if (event.parent.makeCode === 'LEXUS' ||
      event.parent.makeCode === 'LEX')
      event.parent.warrantExpDateText = 'EXTENSIVE COMPREHENSIVE COVERAGE EXPIRY DATE';
    else
      event.parent.warrantExpDateText = 'WARRANTY EXP DATE';

  }


  setDealer(dealerCodeVal: string) {
    this.sharedDataService.dealerCode = dealerCodeVal;
    this.store.dispatch(new dealerDetailActions.LoadDealerAction({
      dealerCode: dealerCodeVal
    }));
  }

  addAction(freshPage: boolean, certify: boolean) {
    if (freshPage) {
      this.formatDataforDetailsView();
    }
    this.vin = '';
    this.isMandatoryForCertify = false;
    this.displayInvalidVin = false;
    this.displayInvalidDealerCode = false;
    this.updateFlag = false;
    this.routerText = 'Create RDR';
    this.saveButtonText = 'SUBMIT';

    // if (this.makeCode !== 'TOYOTA') {
    //     this.saveButtonText = 'ADD';
    //     this.updateButtonText = 'ADD & CERTIFY';
    // }
    // else {
    //     this.saveButtonText = 'ADD & CERTIFY';
    //     this.updateButtonText = 'ADD & CERTIFY';
    // }
    if (certify === false) {

      this.addCertifyFlag = false;

    }

  }

  /**
   * On Destory event handler to register the state and filter conditions
   * to store and to unsubscribe the subscriptions
   */
  ngOnDestroy() {
    try {
      this.sharedDataService.rdrData = null;
      if (this.routeParamSubscriber) {
        this.routeParamSubscriber.unsubscribe();
      }
      if (this.rdrDetailSubscriber) {
        this.rdrDetailSubscriber.unsubscribe();
      }
      if (this.staffDataFinanceSubscriber) {
        this.staffDataFinanceSubscriber.unsubscribe();
      }
      if (this.staffDataSalesSubscriber) {
        this.staffDataSalesSubscriber.unsubscribe();
      }
      this.store.dispatch(new saleDetailActions.SaleClearAllAction());
      this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    } catch (error) {
      // this.logger.error(error);
    }
  }
  /**
   * To obtain the vehicle details by passing VIN
   */
  getVehicleDetails(vehilce: models.Rdr) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    if (!isEmpty(vehilce.vin)) {
      this.store.dispatch(new saleDetailActions.SaleInitialLoadAction(vehilce));
    }

    // this.store.dispatch(new detailActions.LoadVinInformationAction(vin));
  }

  /**
   * To obtain the vehicle details by passing VIN
   */
  getVehicleDetailsbyonblur(vin: string) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.store.dispatch(new saleDetailActions.GetSaleByVinAction(vin));
  }

  savedDetails(event: any) {
    let model: models.Message = {};
    model.message = 'Lorem Ipsum.';
    model.type = models.MessageType.DANGER;
  }

  navigateToInventoryScreen() {
    this.router.navigate(['/inventory/']);
  }

  navigateToVehicleDetailScreenNew(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    if (this.updateFlag === true) {
      // this.addAction(true, true);
      this.router.navigate(['/saleDetail/']);
    }
  }

  navigateToRdrSummary(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.router.navigate(['/sales/']);
  }

  deCertifyConfirmed(event: any) {
    event.dialogRef.close(true);
    event.parent.saveVehicleEvent(event.parent.reStorevehicleDetails);
    this.sharedDataService.isPageEdited = false;
  }

  validateInputData(vehicleData: models.Rdr, isIndividualCustomer: any): boolean {
    let isValid: boolean = true;
    // As part of scrum 'UCM Sprint 69', Business users asked to the remove
    // the validations for acvPrice, retailPrice and salesPrice //
    /* if (this.makeCode === 'TOYOTA' && !this.sharedDataService.isTMEXUser &&
      (Number(vehicleData.rdrDetail.soldAmount) < 7000 ||
        Number(vehicleData.rdrDetail.soldAmount) > 99000)) {
      isValid = false;
    } else if (this.makeCode !== 'TOYOTA' && (Number(vehicleData.rdrDetail.soldAmount) < 4000
    || Number(vehicleData.rdrDetail.soldAmount) > 400000) && !this.reversalFlag) {
    isValid = false;
    } else */
    if (this.makeCode === 'TOYOTA' &&
      (Number(vehicleData.rdrDetail.soldAmount) < 1)) {
      isValid = false;
    } else if ((Number(vehicleData.rdrDetail.mileage) < 1) && !this.reversalFlag) {
      isValid = false;
    } else if (isEmpty(vehicleData.comment)
    && vehicleData.isAllowOpenCampaign === 'true' && vehicleData.isAllowCarfax === 'true') {
    isValid = false;
    this.gotoTop();
  } else if (isNil(vehicleData.rdrDetail.buyer.salution) &&
      isIndividualCustomer && !this.reversalFlag) {
      isValid = false;
    } else if ((isNil(vehicleData.rdrDetail.buyer.firstName) ||
      vehicleData.rdrDetail.buyer.firstName.trim().length === 0)
      && isIndividualCustomer && !this.reversalFlag) {
      isValid = false;
    } else if ((isNil(vehicleData.rdrDetail.buyer.lastName) ||
      vehicleData.rdrDetail.buyer.lastName.trim().length === 0) && !this.reversalFlag) {
      isValid = false;
    } else if (isNil(vehicleData.rdrDetail.buyer.addressLineOne) && !this.reversalFlag) {
      isValid = false;
    } else if ((isNil(vehicleData.rdrDetail.buyer.city) ||
      vehicleData.rdrDetail.buyer.city.trim().length === 0) && !this.reversalFlag) {
      isValid = false;
    } else if (isNil(vehicleData.rdrDetail.buyer.state) && !this.reversalFlag) {
      isValid = false;
    } else if (isNil(vehicleData.rdrDetail.buyer.zipCode) && !this.reversalFlag) {
      isValid = false;
    } else if (!isNil(vehicleData.rdrDetail.buyer.zipCode) &&
      !(vehicleData.rdrDetail.buyer.zipCode.length === 5 ||
        vehicleData.rdrDetail.buyer.zipCode.length === 6 ||
        vehicleData.rdrDetail.buyer.zipCode.length === 9 ||
        vehicleData.rdrDetail.buyer.zipCode.length === 10) && !this.reversalFlag) {
      isValid = false;
    } else if (isNil(vehicleData.rdrDetail.buyer.homePhone)
      && isIndividualCustomer && !this.reversalFlag) {
      isValid = false;
    } else if (!isNil(vehicleData.rdrDetail.buyer.homePhone) &&
      !(vehicleData.rdrDetail.buyer.homePhone.length === 10 ||
        vehicleData.rdrDetail.buyer.homePhone.length === 14)) {
      isValid = false;
    } else if (isNil(vehicleData.rdrDetail.buyer.workPhone)
      && isIndividualCustomer && !this.reversalFlag) {
      isValid = false;
    } else if (!isNil(vehicleData.rdrDetail.buyer.workPhone) &&
      !(vehicleData.rdrDetail.buyer.workPhone.length === 10 ||
        vehicleData.rdrDetail.buyer.workPhone.length === 14) && !this.reversalFlag) {
      isValid = false;
    } else if (isNil(vehicleData.rdrDetail.buyer.email)
      && this.isEmailMandatory === false && !this.reversalFlag) {
      isValid = false;
    }

    if (!isNil(vehicleData.rdrDetail.cobuyer) && (!isNil(vehicleData.rdrDetail.cobuyer.lastName)
      || vehicleData.rdrDetail.cobuyer.city.trim().length > 0)) {

      if ((isNil(vehicleData.rdrDetail.cobuyer.lastName) ||
        vehicleData.rdrDetail.cobuyer.lastName.trim().length === 0) && !this.reversalFlag) {
        isValid = false;
      } else if (isNil(vehicleData.rdrDetail.cobuyer.addressLineOne) && !this.reversalFlag) {
        isValid = false;
      } else if ((isNil(vehicleData.rdrDetail.cobuyer.city) ||
        vehicleData.rdrDetail.cobuyer.city.trim().length === 0) && !this.reversalFlag) {
        isValid = false;
      } else if (isNil(vehicleData.rdrDetail.cobuyer.state) && !this.reversalFlag) {
        isValid = false;
      } else if (isNil(vehicleData.rdrDetail.cobuyer.zipCode) && !this.reversalFlag) {
        isValid = false;
      } else if (!isNil(vehicleData.rdrDetail.cobuyer.zipCode) &&
        !(vehicleData.rdrDetail.cobuyer.zipCode.length === 5 ||
          vehicleData.rdrDetail.cobuyer.zipCode.length === 6 ||
          vehicleData.rdrDetail.cobuyer.zipCode.length === 9 ||
          vehicleData.rdrDetail.cobuyer.zipCode.length === 10) && !this.reversalFlag) {
        isValid = false;
      } else if (isNil(vehicleData.rdrDetail.cobuyer.homePhone)
        && isIndividualCustomer && !this.reversalFlag) {
        isValid = false;
      } else if (!isNil(vehicleData.rdrDetail.cobuyer.homePhone) &&
        !(vehicleData.rdrDetail.cobuyer.homePhone.length === 10 ||
          vehicleData.rdrDetail.cobuyer.homePhone.length === 14) && !this.reversalFlag) {
        isValid = false;
      } else if (isNil(vehicleData.rdrDetail.cobuyer.workPhone)
        && isIndividualCustomer && !this.reversalFlag) {
        isValid = false;
      } else if (!isNil(vehicleData.rdrDetail.cobuyer.workPhone) &&
        !(vehicleData.rdrDetail.cobuyer.workPhone.length === 10 ||
          vehicleData.rdrDetail.cobuyer.workPhone.length === 14) && !this.reversalFlag) {
        isValid = false;
      } else if (isNil(vehicleData.rdrDetail.cobuyer.email && !this.reversalFlag) &&
        this.isCoEmailMandatory === false) {
        isValid = false;
      } else if (isNil(vehicleData.rdrDetail.cobuyer.salution)
        && isIndividualCustomer && !this.reversalFlag) {
        isValid = false;
      } else if (isNil(vehicleData.rdrDetail.cobuyer.firstName)
        && isIndividualCustomer && !this.reversalFlag) {
        isValid = false;
      }
    }

    if (this.makeCode === 'TOYOTA' && this.sharedDataService.isTMEXUser) {
      isValid = true;
    } else if (this.reversalFlag === true && isNil(vehicleData.rdrDetail.reversalType)) {
      isValid = false;
    } else if (this.reversalFlag === true && isNil(vehicleData.rdrDetail.reversalReason)) {
      isValid = false;
    }

    return isValid;
  }

  saveVehicleEvent(inputData: any) {

    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.addMode = false;
    let errorMessage;
    let vehicleData: models.Rdr = cloneDeep(inputData.vehicleData.rdrData);
    this.displayInvalidVin = false;
    this.displayInvalidDealerCode = false;
    this.isEmailMandatory = inputData.isEmailMandatory;
    this.isCoEmailMandatory = inputData.isCoEmailMandatory;
    this.isRetailModified = inputData.isRetailModified;
    this.oldRetailDate = inputData.oldRetailDate;
    vehicleData.isAllowOpenCampaign = inputData.isValidatePQSS.toString();
    if (inputData.additionalDriver === false) {
      vehicleData.rdrDetail.cobuyer = null;
    }

    this.replaceEmptyToNull(vehicleData);

    if (isNil(vehicleData.makeCode) && !isEmpty(this.sharedDataService.brandName)) {
      vehicleData.makeCode = this.sharedDataService.brandName;
    }


    if (this.validateInputData(vehicleData, inputData.isIndividualCustomer)) {

      if (isNil(vehicleData.comment)) {
        vehicleData.comment = null;
      }
      this.sharedDataService.isPageEdited = false;

      if (isNil(vehicleData.certificationNumber) && this.updateFlag === true) {
        vehicleData.certificationNumber = '';
      }

      if (this.updateFlag === true && this.reversalFlag ===
        false && inputData.registrationFlag === 'true') {
        vehicleData.updatedBy = this.sharedDataService.userName;
        this.isMandatoryForCertify = false;
        // this.store.dispatch(new rdrDetailActions.RestoreVehicleDataAction(vehicleData));
        let rdrData = this.formatRdrData(vehicleData);
        // this.store.dispatch(new rdrDetailActions.UpdateRdrAction(rdrData));
      } else if (this.updateFlag === true && this.reversalFlag === false &&
        inputData.registrationFlag === 'false' && inputData.deCertifyFlag === 'false') {
        vehicleData.updatedBy = this.sharedDataService.userName;
        this.isMandatoryForCertify = false;
        let rdrData = this.formatRdrData(vehicleData);
        // this.store.dispatch(new rdrDetailActions.RestoreVehicleDataAction(vehicleData));
        this.store.dispatch(new saleDetailActions.UpdateSaleAction(rdrData));
      } else if (this.updateFlag === true && this.reversalFlag === true) {
        vehicleData.updatedBy = this.sharedDataService.userName;
        this.isMandatoryForCertify = false;
        let rdrData = this.formatRdrData(vehicleData);
        this.store.dispatch(new saleDetailActions.RdrReversalAction(rdrData));
      } else {
        vehicleData.createdBy = this.sharedDataService.userName;
        let rdrData = this.formatRdrData(vehicleData);
        // this.store.dispatch(new rdrDetailActions.AddNewRdrAction(rdrData));
        this.rdrDetails.rdrData = cloneDeep(vehicleData);
        let vehicle: models.Rdr = {};
        vehicle.vin = vehicleData.vin;
        vehicle.dealerCd = vehicleData.dealerCd;
        this.addMode = true;

        // this.store.dispatch(new rdrDetailActions.RdrInitialLoadAction(vehicle));
      }

    }
  }

  replaceEmptyToNull(vehicleData: models.Rdr) {
    if (!isNil(vehicleData.rdrDetail.buyer)) {
      if (isNil(vehicleData.rdrDetail.buyer.salution) ||
        isEmpty(vehicleData.rdrDetail.buyer.salution)) {
        vehicleData.rdrDetail.buyer.salution = null;
      }
      if (isNil(vehicleData.rdrDetail.buyer.firstName) ||
        isEmpty(vehicleData.rdrDetail.buyer.firstName)) {
        vehicleData.rdrDetail.buyer.firstName = null;
      }
      if (isNil(vehicleData.rdrDetail.buyer.lastName) ||
        isEmpty(vehicleData.rdrDetail.buyer.lastName)) {
        vehicleData.rdrDetail.buyer.lastName = null;
      }
      if (isNil(vehicleData.rdrDetail.buyer.middleName) ||
        isEmpty(vehicleData.rdrDetail.buyer.middleName)) {
        vehicleData.rdrDetail.buyer.middleName = null;
      }
      if (isNil(vehicleData.rdrDetail.buyer.addressLineTwo) ||
        isEmpty(vehicleData.rdrDetail.buyer.addressLineTwo)) {
        vehicleData.rdrDetail.buyer.addressLineTwo = null;
      }
      if (isNil(vehicleData.rdrDetail.buyer.email) ||
        isEmpty(vehicleData.rdrDetail.buyer.email)) {
        vehicleData.rdrDetail.buyer.email = null;
      }
      if (isNil(vehicleData.rdrDetail.buyer.city) ||
        isEmpty(vehicleData.rdrDetail.buyer.city)) {
        vehicleData.rdrDetail.buyer.city = null;
      }
      if (isNil(vehicleData.rdrDetail.buyer.zipCode) ||
        isEmpty(vehicleData.rdrDetail.buyer.zipCode)) {
        vehicleData.rdrDetail.buyer.zipCode = null;
      }
      if (isNil(vehicleData.rdrDetail.buyer.homePhone) ||
        isEmpty(vehicleData.rdrDetail.buyer.homePhone)) {
        vehicleData.rdrDetail.buyer.homePhone = null;
      }
      if (isNil(vehicleData.rdrDetail.buyer.workPhone) ||
        isEmpty(vehicleData.rdrDetail.buyer.workPhone)) {
        vehicleData.rdrDetail.buyer.workPhone = null;
      }

      if (isNil(vehicleData.rdrDetail.buyer.extension) ||
        isEmpty(vehicleData.rdrDetail.buyer.extension)) {
        vehicleData.rdrDetail.buyer.extension = null;
      }

      if (isNil(vehicleData.rdrDetail.buyer.taxid) ||
        isEmpty(vehicleData.rdrDetail.buyer.taxid)) {
        vehicleData.rdrDetail.buyer.taxid = null;
      }

      if (isUndefined(vehicleData.rdrDetail.buyer.customerConsent)) {
        vehicleData.rdrDetail.buyer.customerConsent = null;
      }

      if (isNil(vehicleData.rdrDetail.buyer.contactMethod) ||
        isEmpty(vehicleData.rdrDetail.buyer.contactMethod)) {
        vehicleData.rdrDetail.buyer.contactMethod = null;
      }
    }

    if (!isNil(vehicleData.rdrDetail.cobuyer)) {
      if (isNil(vehicleData.rdrDetail.cobuyer.salution) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.salution)) {
        vehicleData.rdrDetail.cobuyer.salution = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.firstName) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.firstName)) {
        vehicleData.rdrDetail.cobuyer.firstName = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.lastName) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.lastName)) {
        vehicleData.rdrDetail.cobuyer.lastName = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.middleName) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.middleName)) {
        vehicleData.rdrDetail.cobuyer.middleName = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.addressLineOne) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.addressLineOne)) {
        vehicleData.rdrDetail.cobuyer.addressLineOne = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.addressLineTwo) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.addressLineTwo)) {
        vehicleData.rdrDetail.cobuyer.addressLineTwo = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.city) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.city)) {
        vehicleData.rdrDetail.cobuyer.city = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.zipCode) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.zipCode)) {
        vehicleData.rdrDetail.cobuyer.zipCode = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.homePhone) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.homePhone)) {
        vehicleData.rdrDetail.cobuyer.homePhone = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.workPhone) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.workPhone)) {
        vehicleData.rdrDetail.cobuyer.workPhone = null;
      }
      if (isNil(vehicleData.rdrDetail.cobuyer.email) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.email)) {
        vehicleData.rdrDetail.cobuyer.email = null;
      }

      if (isNil(vehicleData.rdrDetail.cobuyer.extension) ||
        isEmpty(vehicleData.rdrDetail.cobuyer.extension)) {
        vehicleData.rdrDetail.cobuyer.extension = null;
      }
    }

    return vehicleData;
  }

  /**
   * Method used to display error message
   */
  errorMessageDisplay(messageString: string) {
    this.store.dispatch(new messageActions.SetDisplayMessageAction(
      <models.Message>{
        message: messageString,
        type: models.MessageType.DANGER
      }));
  }

  /**
   * Method used to frame the required state for the vehicle detail screen
   */
  formatDataforDetailsView(): viewModels.RdrDetailView {
    // this.vehicleDetails =  new Observable<viewModels.VehicleDetailView>();
    let rdrDetailData = <viewModels.RdrDetailView>new Object();
    let rdrobj = <models.Rdr>new Object();
    rdrDetailData.rdrData = cloneDeep(rdrobj);
    rdrDetailData.rdrData.vin = null;
    rdrDetailData.rdrData.dealerCd = null;
    let rdrDetailObj = <models.RdrDetail>new Object();
    rdrDetailObj.financeInsuranceManagerId = null;
    rdrDetailObj.soldAmount = null;
    rdrDetailObj.salesPersonId = null;
    rdrDetailObj.mileage = null;
    rdrDetailObj.retailDate = null;
    rdrDetailData.rdrData.rdrDetail = cloneDeep(rdrDetailObj);
    let buyerObj = <models.Buyer>new Object();
    buyerObj.salution = null;
    buyerObj.firstName = null;
    buyerObj.lastName = null;
    buyerObj.zipCode = null;
    buyerObj.extension = null;
    buyerObj.city = null;
    buyerObj.homePhone = null;
    buyerObj.addressLineOne = null;
    buyerObj.emailPermissionInd = null;
    buyerObj.suffix = null;
    buyerObj.addressLineTwo = null;
    buyerObj.middleName = null;
    buyerObj.workPhone = null;
    buyerObj.state = null;
    buyerObj.email = null;
    rdrDetailData.rdrData.rdrDetail.buyer = cloneDeep(buyerObj);
    rdrDetailData.rdrData.rdrDetail.cobuyer = cloneDeep(buyerObj);
    this.rdrDetails = cloneDeep(rdrDetailData);
    return rdrDetailData;
  }



  getDealerEvent(dealerCode) {
    this.displayInvalidDealerCode = false;
    if (this.allDealers.filter(e => e.dealerCode === dealerCode).length === 0) {
      this.displayInvalidDealerCode = true;
    }
  }

  brandAssign(brand: string, saveText: string, updateText: string) {
    if (brand === 'TOYOTA') {
      this.warrantExpDateText = 'POWERTRAIN COVERAGE EXPIRY DATE';
    } else if (brand === 'LEXUS') {
      this.warrantExpDateText = 'EXTENSIVE COMPREHENSIVE COVERAGE EXPIRY DATE';
    } else {
      this.warrantExpDateText = 'WARRANTY EXP DATE';
    }
    if (saveText !== '') {
      this.saveButtonText = saveText;
    }
    if (updateText !== '') {
      this.updateButtonText = updateText;
    }

  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  onDisableFuture() {
    let date = new Date();
    // Disable/enable dates from 5th forward
    date.setMonth(date.getMonth() + 1);
    let copy = this.getCopyOfOptions();
    copy.disableSince = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate() + 1
    };
    this.myDatePickerOptions = copy;
  }

  onDisablePast() {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);

    let copy = this.getCopyOfOptions();
    copy.disableUntil = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate()
    };
    this.myDatePickerOptions = copy;
  }

  updateChangeRedirection() {
    this.updateFlag = true;
    this.routerText = 'Sale Details';
    this.saveButtonText = 'UPDATE';
    this.updateButtonText = 'ADD NEW';
    this.rdrDetails.pageType = '';
  }

  formatCertificationData(vehicleDetails: any) {
    if (isNil(vehicleDetails.rdrData.repairOrder)) {
      vehicleDetails.rdrData.repairOrder = <models.RepairOrder>new Object();
      vehicleDetails.rdrData.repairOrder.repairOrderNumber1 = null;
      vehicleDetails.rdrData.repairOrder.reconditionalEndDate = null;
      vehicleDetails.rdrData.repairOrder.dateofFirstRepairOrder = null;
      vehicleDetails.rdrData.repairOrder.repairOrderNumber2 = null;
      vehicleDetails.rdrData.repairOrder.dateofSecondRepairOrder = null;
    }

    if (isNil(vehicleDetails.rdrData.inspection)) {
      vehicleDetails.rdrData.inspection = <models.Inspection>new Object();
      vehicleDetails.rdrData.inspection.inspectionFormNumber = null;
      vehicleDetails.rdrData.inspection.inspectionApprovedDate = null;
    }
    if (!isUndefined(vehicleDetails.rdrData.price) &&
      isUndefined(vehicleDetails.rdrData.repairOrder.reconditionCost)) {
      vehicleDetails.rdrData.repairOrder.reconditionCost = null;
    }
    if (isNil(vehicleDetails.rdrData.comments)) {
      vehicleDetails.rdrData.comments = null;
    }

    if (isNil(vehicleDetails.rdrData.rdrDetail)) {
      let rdrDetailObj = <models.RdrDetail>new Object();
      rdrDetailObj = vehicleDetails.rdrData;
      vehicleDetails.rdrData.rdrDetail = rdrDetailObj;
      this.isEmailMandatory = this.convertStringToBoolean(
        vehicleDetails.rdrData.rdrDetail.buyer.emailPermissionInd);
    } else {
      this.isEmailMandatory = this.convertStringToBoolean(
        vehicleDetails.rdrData.rdrDetail.buyer.emailPermissionInd);
      if (vehicleDetails.rdrData.rdrDetail.saleType === '01' ||
        vehicleDetails.rdrData.rdrDetail.saleType === '05') {
        this.isIndividualCustomer = true;
      }
      let decCount =
        vehicleDetails.rdrData.rdrDetail.soldAmount.split('.')[1];
      if (!isNil(decCount) && decCount.length === 4) {
        vehicleDetails.rdrData.rdrDetail.soldAmount =
          Number(vehicleDetails.rdrData.rdrDetail.soldAmount).toFixed(2);
      }
    }

    if (!isNil(vehicleDetails.rdrData.certificationNumber))
      vehicleDetails.rdrData.certified = 'Y';
    else
      vehicleDetails.rdrData.certified = 'N';

    if (!isNil(vehicleDetails.rdrData.certification) &&
      vehicleDetails.rdrData.certification.forceCertificationFlag === 'true') {
      this.isForceCertified = true;
    } else {
      this.isForceCertified = false;
    }


    if (this.updateFlag === true) {
      if (isNil(vehicleDetails.rdrData.rdrDetail.cobuyer)) {
        let buyerObj = <models.Buyer>new Object();
        buyerObj.salution = null;
        buyerObj.firstName = null;
        buyerObj.lastName = null;
        buyerObj.zipCode = null;
        buyerObj.extension = null;
        buyerObj.city = null;
        buyerObj.homePhone = null;
        buyerObj.addressLineOne = null;
        buyerObj.emailPermissionInd = null;
        buyerObj.suffix = null;
        buyerObj.addressLineTwo = null;
        buyerObj.middleName = null;
        buyerObj.workPhone = null;
        buyerObj.state = null;
        buyerObj.email = null;
        vehicleDetails.rdrData.rdrDetail.cobuyer = cloneDeep(buyerObj);
      } else {
        this.isCoEmailMandatory =
          this.convertStringToBoolean(
            vehicleDetails.rdrData.rdrDetail.cobuyer.emailPermissionInd);
      }
    }
    return vehicleDetails;
  }

  add3Dots(stringVal, limit) {
    let dots = '...';
    if (stringVal.length > limit) {
      stringVal = stringVal.substring(0, limit) + dots;
    }
    return stringVal;
  }


  formatRdrData(rdr: models.Rdr) {
    let rdrDetailData = <models.RdrDetail>new Object();
    rdrDetailData.vin = rdr.vin;
    rdrDetailData.isUpdate = this.updateFlag.toString();
    rdrDetailData.makeCode = rdr.makeCode;
    rdrDetailData.isAllowOpenCampaign = rdr.isAllowOpenCampaign;
    rdrDetailData.price = rdr.rdrDetail.price;
    rdrDetailData.sellArea = rdr.sellArea;
    if (this.updateFlag === true) {
      rdrDetailData.updatedBy = rdr.updatedBy;
    } else {
      rdrDetailData.createdBy = rdr.createdBy;
    }
    if (this.reversalFlag === false) {
      rdrDetailData.isUpdate = this.updateFlag.toString();
    } else {
      rdrDetailData.isUpdate = 'false';
    }
    if (!isNil(rdr.dealerCd)) {
      rdrDetailData.dealerCd = rdr.dealerCd;
    } else {
      rdrDetailData.dealerCd = this.sharedDataService.dealerCode;
    }
    rdrDetailData.financeInsuranceManagerId = rdr.rdrDetail.financeInsuranceManagerId;
    rdrDetailData.regionNo = rdr.rdrDetail.regionNo;
    if (rdrDetailData.regionNo === '50') {
      rdrDetailData.warrantyContractNumber = rdr.rdrDetail.warrantyContractNumber;
      rdrDetailData.setFormNumber = rdr.rdrDetail.setFormNumber;
    }
    // rdrDetailData.financeInsuranceManagerUId =
    //     this.getFIManagerUID(rdr.rdrDetail.financeInsuranceManagerId).substring(3);
    if (!isNil(rdr.rdrDetail.financeInsuranceManagerId)) {
      rdrDetailData.financeInsuranceManagerUId =
        this.getFIManagerUID(rdr.rdrDetail.financeInsuranceManagerId).substring(3);
    } else {
      rdrDetailData.financeInsuranceManagerUId = rdr.rdrDetail.financeInsuranceManagerUId;
    }
    rdrDetailData.saleType = rdr.rdrDetail.saleType;
    rdrDetailData.mileage = rdr.rdrDetail.mileage;
    if (!isNil(rdr.rdrDetail) && !isNil(rdr.rdrDetail.rdrStatus)) {
      rdrDetailData.rdrStatus = rdr.rdrDetail.rdrStatus;
    }
    rdrDetailData.salesPersonId = rdr.rdrDetail.salesPersonId;
    // rdrDetailData.salesPersonUId =
    //     this.getSalesPersonUID(rdr.rdrDetail.salesPersonId).substring(3);
    if (!isNil(rdr.rdrDetail.salesPersonId)) {
      rdrDetailData.salesPersonUId =
        this.getSalesPersonUID(rdr.rdrDetail.salesPersonId).substring(3);
    } else {
      rdrDetailData.salesPersonUId = rdr.rdrDetail.salesPersonUId;
    }

    rdrDetailData.soldAmount = rdr.rdrDetail.soldAmount;
    rdrDetailData.dealerPaidAmount = rdr.rdrDetail.dealerPaidAmount;
    rdrDetailData.vehicleCost = rdr.rdrDetail.vehicleCost;
    if (this.isRetailModified === true) {
      rdrDetailData.retailDateChanged = 'Y';
      rdrDetailData.newRetailDate = rdr.rdrDetail.retailDate;
      rdrDetailData.retailDate = this.oldRetailDate;
    } else {
      rdrDetailData.retailDate = rdr.rdrDetail.retailDate;
    }
    rdrDetailData.reconditioningCost = rdr.rdrDetail.reconditioningCost;
    rdrDetailData.buyer = rdr.rdrDetail.buyer;
    if (this.reversalFlag === true) {
      rdrDetailData.reversalInd = this.convertBooleanToString(this.reversalFlag);
      rdrDetailData.reversalType = rdr.rdrDetail.reversalType;
      rdrDetailData.reversalReason = rdr.rdrDetail.reversalReason;
    }
    rdrDetailData.buyer.emailPermissionInd = this.convertBooleanToString(this.isEmailMandatory);
    if (!isNil(rdrDetailData.buyer.homePhone))
      rdrDetailData.buyer.homePhone = rdrDetailData.buyer.homePhone.replace(/\D+/g, '');
    if (!isNil(rdrDetailData.buyer.workPhone))
      rdrDetailData.buyer.workPhone = rdrDetailData.buyer.workPhone.replace(/\D+/g, '');
    if (rdrDetailData.buyer.emailPermissionInd === 'Y') {
      rdrDetailData.buyer.email = null;
    }
    if (rdrDetailData.buyer.zipCode.length === 6) {
      rdrDetailData.buyer.zipCode = rdrDetailData.buyer.zipCode.replace('-', '');
    }
    if (!isNil(rdr.rdrDetail.cobuyer) && !isNil(rdr.rdrDetail.cobuyer.lastName)
      && !isNil(rdr.rdrDetail.cobuyer.addressLineOne) && !isNil(rdr.rdrDetail.cobuyer.city)
      && !isNil(rdr.rdrDetail.cobuyer.zipCode) && !isNil(rdr.rdrDetail.cobuyer.state)) {
      rdrDetailData.cobuyer = rdr.rdrDetail.cobuyer;
      rdrDetailData.cobuyer.emailPermissionInd =
        this.convertBooleanToString(this.isCoEmailMandatory);
      if (!isNil(rdrDetailData.cobuyer.homePhone))
        rdrDetailData.cobuyer.homePhone =
          rdrDetailData.cobuyer.homePhone.replace(/\D+/g, '');
      if (!isNil(rdrDetailData.cobuyer.workPhone))
        rdrDetailData.cobuyer.workPhone =
          rdrDetailData.cobuyer.workPhone.replace(/\D+/g, '');
      if (rdrDetailData.cobuyer.emailPermissionInd === 'Y') {
        rdrDetailData.cobuyer.email = null;
      }
      if (rdrDetailData.cobuyer.zipCode.length === 6) {
        rdrDetailData.cobuyer.zipCode = rdrDetailData.cobuyer.zipCode.replace('-', '');
      }
    } else {
      rdrDetailData.cobuyer = null;
    }
    return rdrDetailData;
  }

  convertBooleanToString(value: boolean): string {
    if (value === true) {
      return 'Y';
    } else {
      return 'N';
    }
  }

  convertStringToBoolean(value: string): boolean {
    if (value === 'Y') {
      return true;
    } else {
      return false;
    }
  }

  zipcodeChangeEvent(event: any) {
    if (event.length > 5) {
      event = event.substr(0, 5) + '-' + event.substr(5);
    }
    if (!isNil(this.rdrDetails.rdrData) && !isNil(this.rdrDetails.rdrData.rdrDetail)
      && !isNil(this.rdrDetails.rdrData.rdrDetail.buyer)) {
      this.rdrDetails.rdrData.rdrDetail.buyer.zipCode = event;
    }
  }

  getFIManagerUID(id: string): string {
    let spinId: string;
    this.fIManagerItems.forEach(element => {
      if (element.name === id) {
        spinId = element.spinId;
      }
    });
    return spinId;
  }

  getSalesPersonUID(id: string): string {
    let spinId: string;
    this.saleManagerItems.forEach(element => {
      if (element.name === id) {
        spinId = element.spinId;
      }
    });
    return spinId;
  }

  gotoTop() {
    let layout: any = document.getElementsByClassName('mat-sidenav-content');
    if (!isNil(layout) && layout.length > 0) {
      layout[0].scrollTop = 0;
    }
  }

  validateSkipFlags(rdrData: any) {
    if (rdrData.openCampaignStatus === 'Y' ||
      rdrData.carFaxFlag === 'N' ||
      rdrData.foreignVin === 'Y') {
      this.isCertify = false;
      if (rdrData.openCampaignStatus === 'Y')
        this.isValidPQSS = false;
      if (rdrData.carFaxFlag === 'N')
        this.isValidCarFax = false;
      if (rdrData.foreignVin === 'Y')
        this.isValidForeignvin = false;
    }
  }
}
