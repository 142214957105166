import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';
import * as models from '../services/model/models';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

/**
 * Following action types are being used in the vehicle detail screen
 */
export const actionTypes = {
  INITIAL_LOAD: type('[Vehicle] Initiate load for vehicle details'),
  INITIAL_LOAD_SUCCESS: type('[Vehicle] vehicle details Success'),
  INITIAL_LOAD_FAIL: type('[Vehicle] vehicle details Fail'),
  LOAD_VIN_INFORMATION: type('[Vehicle] vehicle details vin information'),
  LOAD_VIN_INFORMATION_SUCCESS: type('[Vehicle] vehicle details vin information Success'),
  LOAD_VIN_INFORMATION_FAIL: type('[Vehicle] vehicle details vin information Fail'),
  CLEAR_ALL_ACTION: type('[Vehicle] clear all vehicle data'),
  GET_CURRENT_STATE_ACTION: type('[Vehicle] Get current action'),
  ADD_VEHICLE_ACTION: type('[Vehicle] add new vehicle'),
  ADD_VEHICLE_SUCESS_ACTION: type('[Vehicle] add new vehicle success'),
  ADD_VEHICLE_FAIL_ACTION: type('[Vehicle] add new vehicle failure'),
  UPDATE_VEHICLE_ACTION: type('[Vehicle] update vehicle'),
  UPDATE_VEHICLE_SUCESS_ACTION: type('[Vehicle] update vehicle success'),
  UPDATE_VEHICLE_FAIL_ACTION: type('[Vehicle] update vehicle failure'),
  UPDATE_CERTIFICATE_ACTION: type('[Vehicle] update certificate'),
  UPDATE_CERTIFICATE_SUCESS_ACTION: type('[Vehicle] update certificate success'),
  UPDATE_CERTIFICATE_FAIL_ACTION: type('[Vehicle] update certificate failure'),
  DELETE_VEHICLE_ACTION: type('[Vehicle] delete vehicle'),
  DELETE_VEHICLE_SUCESS_ACTION: type('[Vehicle] delete vehicle success'),
  DELETE_VEHICLE_FAIL_ACTION: type('[Vehicle] delete vehicle failure'),
  DECERTIFY_VEHICLE_ACTION: type('[Vehicle] decertify vehicle'),
  DECERTIFY_VEHICLE_SUCESS_ACTION: type('[Vehicle] decertify vehicle success'),
  DECERTIFY_VEHICLE_FAIL_ACTION: type('[Vehicle] decertify vehicle failure'),
  RESTORE_VEHICLE_DATA: type('[Vehicle] Restore vehicle data'),
  LOAD_VEHICLE_FOR_CERTIFY_ACTION: type('[Vehicle] load vehicle for certify vehicle'),
  LOAD_VEHICLE_FOR_CERTIFY_SUCESS_ACTION:
    type('[Vehicle] load vehicle for certify vehicle success'),
  LOAD_VEHICLE_FOR_CERTIFY_FAIL_ACTION: type('[Vehicle] load vehicle for certify vehicle failure'),
  LOAD_VEHICLE_FROM_SALE_ACTION: type('[Vehicle] load vehicle from sale vehicle'),
  LOAD_VEHICLE_FROM_SALE_SUCESS_ACTION: type('[Vehicle] load vehicle from sale vehicle success'),
  LOAD_VEHICLE_FROM_SALE_FAIL_ACTION: type('[Vehicle] load vehicle from sale vehicle failure'),
  UPDATE_CERTIFICATE_FROM_SALE_ACTION: type('[Vehicle] update certificate from sale'),
  UPDATE_CERTIFICATE_FROM_SALE_SUCESS_ACTION:
    type('[Vehicle] update certificate from sale success'),
  UPDATE_CERTIFICATE_FROM_SALE_FAIL_ACTION: type('[Vehicle] update certificate from sale failure'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to load the Initial data for vehicle detail screen
 */
export class InitialLoadAction implements ActionBase {
  type = actionTypes.INITIAL_LOAD;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class InitialLoadSuccessAction implements ActionBase {
  type = actionTypes.INITIAL_LOAD_SUCCESS;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the Failure action for Initial Load method
 */
export class InitialLoadFailAction implements ActionBase {
  type = actionTypes.INITIAL_LOAD_FAIL;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVinInformationAction implements ActionBase {
  type = actionTypes.LOAD_VIN_INFORMATION;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVinInformationSuccessAction implements ActionBase {
  type = actionTypes.LOAD_VIN_INFORMATION_SUCCESS;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVinInformationFailAction implements ActionBase {
  type = actionTypes.LOAD_VIN_INFORMATION_FAIL;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class ClearAllAction implements ActionBase {
  type = actionTypes.CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class GetCurrentState implements ActionBase {
  type = actionTypes.GET_CURRENT_STATE_ACTION;

  constructor() { }
}

/**
 * Class used to handle the add new vehicle action
 */
export class AddNewVehicleAction implements ActionBase {
  type = actionTypes.ADD_VEHICLE_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the add new vehicle success action
 */
export class AddNewVehicleSuccessAction implements ActionBase {
  type = actionTypes.ADD_VEHICLE_SUCESS_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the add new vehicle fail action
 */
export class AddNewVehicleFailAction implements ActionBase {
  type = actionTypes.ADD_VEHICLE_FAIL_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the update vehicle action
 */
export class UpdateVehicleAction implements ActionBase {
  type = actionTypes.UPDATE_VEHICLE_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the update vehicle success action
 */
export class UpdateVehicleSuccessAction implements ActionBase {
  type = actionTypes.UPDATE_VEHICLE_SUCESS_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the update vehicle fail action
 */
export class UpdateVehicleFailAction implements ActionBase {
  type = actionTypes.UPDATE_VEHICLE_FAIL_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the update vehicle action
 */
export class UpdateCertificateAction implements ActionBase {
  type = actionTypes.UPDATE_CERTIFICATE_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the update vehicle success action
 */
export class UpdateCertificateSuccessAction implements ActionBase {
  type = actionTypes.UPDATE_CERTIFICATE_SUCESS_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the update vehicle fail action
 */
export class UpdateCertificateFailAction implements ActionBase {
  type = actionTypes.UPDATE_CERTIFICATE_FAIL_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the delete vehicle action
 */
export class DeleteVehicleAction implements ActionBase {
  type = actionTypes.DELETE_VEHICLE_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the delete vehicle success action
 */
export class DeleteVehicleSuccessAction implements ActionBase {
  type = actionTypes.DELETE_VEHICLE_SUCESS_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the delete vehicle fail action
 */
export class DeleteVehicleFailAction implements ActionBase {
  type = actionTypes.DELETE_VEHICLE_FAIL_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the delete vehicle action
 */
export class DecertifyVehicleAction implements ActionBase {
  type = actionTypes.DECERTIFY_VEHICLE_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the delete vehicle success action
 */
export class DecertifyVehicleSuccessAction implements ActionBase {
  type = actionTypes.DECERTIFY_VEHICLE_SUCESS_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the delete vehicle fail action
 */
export class DecertifyVehicleFailAction implements ActionBase {
  type = actionTypes.DECERTIFY_VEHICLE_FAIL_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to load the Initial data for vehicle detail screen
 */
export class RestoreVehicleDataAction implements ActionBase {
  type = actionTypes.RESTORE_VEHICLE_DATA;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to load the Initial data for vehicle detail screen
 */
export class LoadVehicleForCertifyAction implements ActionBase {
  type = actionTypes.LOAD_VEHICLE_FOR_CERTIFY_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVehicleForCertifySuccessAction implements ActionBase {
  type = actionTypes.LOAD_VEHICLE_FOR_CERTIFY_SUCESS_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the Failure action for Initial Load method
 */
export class LoadVehicleForCertifyFailAction implements ActionBase {
  type = actionTypes.LOAD_VEHICLE_FOR_CERTIFY_FAIL_ACTION;

  constructor(public payload: any) { }
}

/**
 * Class used to load the Initial data for vehicle detail screen
 */
export class LoadVehicleFromSaleAction implements ActionBase {
  type = actionTypes.LOAD_VEHICLE_FROM_SALE_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class LoadVehicleFromSaleSuccessAction implements ActionBase {
  type = actionTypes.LOAD_VEHICLE_FROM_SALE_SUCESS_ACTION;

  constructor(public payload: models.Vehicle) { }
}

/**
 * Class used to handle the Failure action for Initial Load method
 */
export class LoadVehicleFromSaleFailAction implements ActionBase {
  type = actionTypes.LOAD_VEHICLE_FROM_SALE_FAIL_ACTION;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the update vehicle action
 */
export class UpdateCertificateFromSaleAction implements ActionBase {
  type = actionTypes.UPDATE_CERTIFICATE_FROM_SALE_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the update vehicle success action
 */
export class UpdateCertificateFromSaleSuccessAction implements ActionBase {
  type = actionTypes.UPDATE_CERTIFICATE_FROM_SALE_SUCESS_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the update vehicle fail action
 */
export class UpdateCertificateFromSaleFailAction implements ActionBase {
  type = actionTypes.UPDATE_CERTIFICATE_FROM_SALE_FAIL_ACTION;

  constructor(public payload: models.Rdr) { }
}
/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = InitialLoadAction
  | InitialLoadSuccessAction
  | InitialLoadFailAction
  | LoadVinInformationAction
  | LoadVinInformationSuccessAction
  | LoadVinInformationFailAction
  | AddNewVehicleAction
  | AddNewVehicleSuccessAction
  | AddNewVehicleFailAction
  | UpdateVehicleAction
  | UpdateVehicleSuccessAction
  | UpdateVehicleFailAction
  | UpdateCertificateAction
  | UpdateCertificateSuccessAction
  | UpdateCertificateFailAction
  | DeleteVehicleAction
  | DeleteVehicleSuccessAction
  | DeleteVehicleFailAction
  | DecertifyVehicleAction
  | DecertifyVehicleSuccessAction
  | DecertifyVehicleFailAction
  | RestoreVehicleDataAction
  | LoadVehicleForCertifyAction
  | LoadVehicleForCertifySuccessAction
  | LoadVehicleForCertifyFailAction
  | LoadVehicleFromSaleAction
  | LoadVehicleFromSaleSuccessAction
  | LoadVehicleFromSaleFailAction
  | UpdateCertificateFromSaleAction
  | UpdateCertificateFromSaleSuccessAction
  | UpdateCertificateFromSaleFailAction;
