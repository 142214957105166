import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UCInventorySummary } from '../../services/model/InventorySummary';
import { ViewConstants } from '../../shared/shared.constants';
import { SharedFunctionService } from '../../services/common/shared-function.service';
import { SharedDataService } from '../../services/common/shared-data.service';
import * as models from '../../services/model/models';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ValidationTypes } from '../../services/model/ValidatorTypes';
import { ReportData } from '../../shared/master-data/reportData';
import {  isNil } from 'lodash';

@Component({
  selector: 'uc-inventory-grid',
  templateUrl: 'dealer-objective-grid.component.html',
  styleUrls: ['./dealer-objective-grid.component.scss']
})

/***
 * This component is used to the show the inventories in the inventory summar page
 */
export class UCDealerObjectiveGridComponent {

  editing = {};
  // Variable for grid column definitions
  @Input() columnDefs: any[] = [];
  @Input() subGridColumnDefs: any[] = [];
  // private variable to hold the row datas
  _rows: Array<UCInventorySummary>;
  _rowsCopy: Array<UCInventorySummary>;
  // Variable for sort columns list
  public sortColumnsList: Array<any> = [];
  // Event to emit on row select/unselect
  @Output() onClickedEvent = new EventEmitter<any>();
  @Output() onUploadEvent = new EventEmitter<any>();
  monthList: Array<any> = [];
  yearList: Array<any> = [];

  @Input() selectedMonth: string;
  @Input() selectedYear: string;
  @Input() regionSelection: string;
  @Input() regionDisplay: string;
  @Input() objectiveCountCurrent: number = 0;
  @Input() objectiveCountRegional: number = 0;
  // Event to emit when vin is clicked in the grid
  @Output() navigateToVehicleDetailEvent = new EventEmitter<any>();
  // to clear the current sorting for all columns
  @Input('clearSort')
  set clearSort(val: boolean) {
    if (val) {
      this.sortColumnsList = [];
    }
  }
  @ViewChild('inventoryTable',  {static: false}) inventoryTable: DatatableComponent;

  @ViewChild('inputFile',  {static: false}) myInputVariable: ElementRef;

  public validationTypes: typeof ValidationTypes = ValidationTypes;


  // Input variable to store the rows
  @Input('rows')
  get rows(): Array<UCInventorySummary> {
    return this._rows;
  }
  set rows(value: Array<UCInventorySummary>) {
    this._rows = value;
  }

  @Input() regionItems: Array<any>;

  // Input variable to store the rows
  @Input('rowsCopy')
  get rowsCopy(): Array<UCInventorySummary> {
    return this._rowsCopy;
  }
  set rowsCopy(value: Array<UCInventorySummary>) {
    this._rowsCopy = value;
  }

  // Event to emit during sorting the datas
  @Output() applySortEvent = new EventEmitter<any>();
  // Event to apply the filters
  @Output() applyFilterEvent = new EventEmitter<any>();
  @Output() saveDealerObjectiveEvent = new EventEmitter<any>();
  @Output() fetchObjectiveEvent = new EventEmitter<any>();


  constructor(public sharedFunctionService: SharedFunctionService, public router: Router,
    public sharedDataService: SharedDataService) {
    let date = new Date();
    this.monthList = ReportData.monthDetails;
    for (let i = date.getFullYear() - 10; i < date.getFullYear() + 10; i++) {
      this.yearList.push(i.toString());
    }
  }

  /**
   * Method to apply the sorting in the grid
   */
  applySort(columnName: string) {
    let sortOrder = ViewConstants.ASC;
    if (this.sortColumnsList.findIndex(x => x.columnName === columnName) > -1) {
      sortOrder = this.sortColumnsList.find(x => x.columnName === columnName)
        .sortOrder === ViewConstants.ASC ? ViewConstants.DESC : ViewConstants.ASC;
    }
    this.sortColumnsList = [];
    this.sortColumnsList.push({ columnName: columnName, sortOrder: sortOrder });
    this.applySortEvent.emit({ columnName: columnName, sortOrder: sortOrder });
  }

  applyFilter(event: any, columnName: string, filterType: models.FilterType) {
    if (!this.inventoryTable.offset)
      this.inventoryTable.offset = 0;
    let filter: models.Filter = {};
    filter.columnname = columnName;
    filter.filterValue = event.target.value;
    let array = String(event.target.value).split(' ');
    filter.filterType = filterType;
    this.applyFilterEvent.emit(filter);
  }

  /**
   * Grid Row Select/Unselect Event
   */
  onClicked(checkboxChange: any, row: any) {
    let data = {
      checkBoxChecked: checkboxChange.source.checked,
      row: row
    };
    this.onClickedEvent.emit(data);
  }

  updateValue(event, cell, rowIndex, row) {
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = Number(event.target.value);
    this.rows = [...this.rows];
    this.totalGrouping();
    this.objectiveCountCurrent = this.countObjectiveCurrent(this.rows);
  }

  countObjectiveCurrent(objectiveArray: Array<any>): number {
    let objectiveCount: number = 0;
    objectiveArray.forEach(element => {
      if (element.regionName !== undefined) {
        objectiveCount += Number(element.objective);
      }
    });
    return objectiveCount;
  }

  totalGrouping() {
    const groupBy = key => array =>
      this.rows.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

    const groupByDistrict = groupBy('district');
    let districtGrouping = groupByDistrict(this.rows);
    let districtArray = [];
    let district1Count = 0;
    for (let j = 0; j < Object.keys(districtGrouping).length; j++) {
      if (Object.keys(districtGrouping)[j] !== 'undefined') {
        district1Count = 0;
        let data = {
          district: Object.keys(districtGrouping)[j],
          count: null
        };
        for (let i = 0; i < Object.values(districtGrouping)[j].length; i++) {
          if (!isNil(Object.values(districtGrouping)[j][i].objective) &&
            !isNil(Object.values(districtGrouping)[j][i].dealerCode)) {
            district1Count += Number(Object.values(districtGrouping)[j][i].objective);
          }
          data['count'] = district1Count;
        }
        districtArray.push(data);
      }
    }

    this.rows.forEach(element => {
      for (let i = 0; i < districtArray.length; i++) {
        if (element.dealerName !== undefined &&
          element.dealerName.search('Total District ' + districtArray[i].district) !== -1) {
          element.objective = districtArray[i].count;
        }
      }
    });
  }

  onFileChange(event: any) {
    this.onUploadEvent.emit(event);
  }

  navigateToVehicleDetailScreen(event: any) {
    let selectedVehicleRoutes: models.VehicleRoute = {};
    selectedVehicleRoutes.vin = event;
    this.sharedDataService.setSelectedVehicle(selectedVehicleRoutes);
    this.navigateToVehicleDetailEvent.emit(event);
    // this.router.navigate(['/vehicle/' + event + '/' + this.sharedDataService.dealerCode]);
  }

  saveDealerObjective() {
    this.saveDealerObjectiveEvent.emit();
    this.myInputVariable.nativeElement.value = '';
  }

  fetchObjective() {
    let data = {
      month: this.selectedMonth,
      year: this.selectedYear,
      regionCode: this.regionSelection
    };
    this.fetchObjectiveEvent.emit(data);
    if(this.myInputVariable?.nativeElement) {
      this.myInputVariable.nativeElement.value = '';
    }
  }
}
