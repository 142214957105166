import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DealerParticipantPageComponent } from './dealer-participant-page.component';
import { AuthenticationGuard } from '../../services/common/auth-guard';
// import { AuthService } from '../../services/common/auth.service';
// import { AuthGuardService } from '../../services/common/auth-guard.service';

const dealerParticipantRoutes: Routes = [
  {
    path: 'dealerParticipant',
    component: DealerParticipantPageComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(dealerParticipantRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class DealerParticipantRoutingModule { }
