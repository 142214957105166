import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { IdlePreload, IdlePreloadModule } from '@angularclass/idle-preload';
import { MaterialModule } from './shared/material.module';
import { AppRoutingModule } from './app-routing.module';

import { EffectsModule } from '@ngrx/effects';
// import { RouterStoreModule } from '@ngrx/router-store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { useLogMonitor } from '@ngrx/store-log-monitor';

import { reducers, rootReducer } from './reducers';
// import { UCInventoryModule } from './features/uc-vehicle-summary/inventory-summary.module';
// import { UCRdrModule } from './features/uc-rdr-summary/rdr-summary.module';
// import { UCSalesModule } from './features/uc-sales-summary/sales-summary.module';

import { SharedModule } from './shared/shared.module';
import { UCInventorySummaryEffects } from '../app/effects/inventory-summary.effect';
import { UCAccountingSummaryEffects } from '../app/effects/accounting-summary.effect';
import { AccountingDetailsEffects } from '../app/effects/accounting-detail.effect';
import { DealerDetailEffects } from '../app/effects/dealer-detail.effect';
import { UserDetailEffects } from '../app/effects/user-detail.effect';
// import { VehicleDetailModule } from './features/uc-vehicle-detail/vehicle-detail.module';
import { VehicleDetailsEffects } from '../app/effects/vehicle-detail.effect';
import { RdrDetailsEffects } from '../app/effects/rdr-detail.effect';
import { SaleDetailsEffects } from '../app/effects/sale-detail.effect';
import { VehicleDeCertificationEffects } from '../app/effects/vehicle-decertification.effect';
import { DealerParticipantEffects } from '../app/effects/dealer-participant.effect';
import { VehicleHistoryEffects } from '../app/effects/vehicle-history.effect';
import { RoleDetailEffects } from '../app/effects/role-detail.effect';
import { UCInventoryReportEffects } from '../app/effects/vehicle-report.effect';
import { UCRdrSummaryEffects } from '../app/effects/rdr-summary.effect';
import { UCSaleSummaryEffects } from '../app/effects/sale-summary.effect';
import { VehicleObjectiveEffects } from '../app/effects/vehicle-objective.effect';
import { UnauthorizedModule } from './features/unauthorized/unauthorized.module';
// import { RDRDetailModule } from './features/uc-rdr-detail/rdr-detail.module';
import { SaleDetailModule } from './features/uc-sale-detail/sale-detail.module';
import { VehicleDecertificationModule } from
  './features/uc-vehicle-decertification/vehicle-decertification.module';
import { RoleDetailModule } from './features/uc-role-detail/role-detail.module';
import { DealerParticipantModule } from
  './features/uc-dealer-participant/dealer-participant.module';
import { VehicleHistoryModule } from './features/uc-vehicle-history/vehicle-history.module';
import { UCDealerInventoryReportModule } from
  './features/uc-report/uc-vehicle-force-certification-report/dealer-inventory-report.module';
import { UCInventoryExceptionReportModule } from
  './features/uc-report/uc-inventory-exception-report/inventory-exception-report.module';
import { UCSaleReversalReportModule } from
  './features/uc-report/uc-deleted-certification-summary-report/sale-reversal-report.module';
import { UCVehicleCertificationReportModule } from
  './features/uc-report/uc-vehicles-certification-report/vehicle-certification-report.module';
import { UCVehicleDailyRDRReportModule } from
  './features/uc-report/uc-daily-rdr-report/vehicle-daily-rdr-report.module';
import { ReportModule } from
  './features/uc-report/report.module';
import { UCDeletedCertificationReportModule } from
  './features/uc-report/uc-deleted-certification-report/deleted-certification-report.module';
import { UCRegionalModelLineRDRReportModule } from
  './features/uc-report/uc-regional-model-line-rdr-report/regional-model-line-rdr-report.module';
import { UCSalesComparisionModelYearReportModule } from
  './features/uc-report/uc-sales-comparision-modelyear-report/sales-comparision-modelyear-report.module';
import { UCLexusPreownedSalesReportModule } from
  './features/uc-report/uc-lexus-preowned-daily-sales/lexus-preowned-sales-report.module';
import { UCLexusDealerParticipationReportModule } from
  './features/uc-report/uc-lexus-dealer-participation/lexus-dealer-participation-report.module';
import { UCTopTCUVDealersReportModule } from
  './features/uc-report/uc-top-tcuv-dealers/top-tcuv-dealers-report.module';
import { UCTRACRetailSalesReportModule } from
  './features/uc-report/uc-trac-vehicles-retail-sales-report/trac-vehicles-retail-sales-report.module';
import { UCTRACRetailSalesSummaryReportModule } from
  './features/uc-report/uc-trac-vehicles-retail-sales-summary-report/trac-vehicles-retail-sales-summary-report.module';
import { UCLexusWithoutWholesalesReportModule } from
  './features/uc-report/uc-lexus-without-wholesales-report/lexus-without-wholesales-report.module';
import { UCSalesConsultantRDRReportModule } from
  './features/uc-report/uc-sales-consultant-rdr-report/sales-consultant-rdr-report.module';
import { UCSalesConsultantRDRDetailReportModule } from
  './features/uc-report/uc-sales-consultant-rdr-detail-report/sales-consultant-rdr-detail-report.module';
import { UCSalesOver65kReportModule } from
  './features/uc-report/uc-sales-over65k-report/sales-over65k-report.module';
import { UCLexusDistrictSummaryReportModule } from
  './features/uc-report/uc-lexus-district-summary-report/lexus-district-summary-report.module';
import { UCDSSRReportModule } from
  './features/uc-report/uc-dssr-report/dssr-report.module';
import { UCLexusDailyRecapReportModule } from
  './features/uc-report/uc-lexus-daily-recap-report/lexus-daily-recap-report.module';
import { UCRegionObjectiveModule } from './features/uc-region-objective/region-objective.module';
import { UCDealerObjectiveModule } from './features/uc-dealer-objective/dealer-objective.module';
import { UCRecallReportModule } from
  './features/uc-report/uc-recall-report/recall-report.module';
import { UCInventoryReportModule } from
  './features/uc-report/uc-inventory-report/inventory-report.module';
import { UCAvgSalesReportModule } from
  './features/uc-report/uc-avg-sales-report/avg-sales-report.module';
import { UCRdrDataReportModule } from
  './features/uc-report/uc-rdr-data-report/rdr-data-report.module';
import { UCTmexSalesReportModule } from
  './features/uc-report/uc-tmex-sales-report/tmex-sales-report.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TextMaskModule } from 'angular2-text-mask';
import { FeatureConfigDetailEffects } from './effects/feature-config.effect';
import { UCDailySalesGoldSilverReportModule } from
  './features/uc-report/uc-daily-sales-gold-silver/daily-sales-gold-silver-report.module';
import { StaffMasterEffects } from './effects/staffMaster.effect';
import { UCYTYTCUVSalesReportModule } from './features/uc-report/uc-yty-tcuv-sales-comparison/yty-tcuv-sales-report.module';
import { UCSameDayCertReportModule } from './features/uc-report/uc-same-day-cert-report/same-day-cert-report.module';

// const STORE_DEV_TOOLS_IMPORTS = [];
// if (ENV === 'development' && !AOT &&
//   ['monitor', 'both'].includes(STORE_DEV_TOOLS) // set in constants.js file in project root
// ) STORE_DEV_TOOLS_IMPORTS.push(...[
//   StoreDevtoolsModule.instrumentStore({
//     monitor: useLogMonitor({
//       visible: true,
//       position: 'right'
//     })
//   })
// ]);

export const APP_IMPORTS = [
  EffectsModule.forRoot([UCInventorySummaryEffects,
    DealerDetailEffects,
    VehicleDetailsEffects,
    DealerDetailEffects,
    FeatureConfigDetailEffects,
    StaffMasterEffects,
    UserDetailEffects,
    RdrDetailsEffects,
    SaleDetailsEffects,
    VehicleDeCertificationEffects,
    DealerParticipantEffects,
    VehicleHistoryEffects,
    RoleDetailEffects,
    UCInventoryReportEffects,
    UCRdrSummaryEffects,
    UCSaleSummaryEffects,
    UCAccountingSummaryEffects,
    AccountingDetailsEffects,
    VehicleObjectiveEffects]),
  BrowserAnimationsModule,
  MaterialModule,
  ReactiveFormsModule,
  FormsModule,
  // RouterStoreModule.connectRouter(),
  StoreRouterConnectingModule,
  // StoreModule.provideStore(rootReducer),
  StoreModule.forRoot(reducers, { initialState: {}, runtimeChecks: {
    strictActionImmutability: false,
    strictStateImmutability: false
  } }),
  // STORE_DEV_TOOLS_IMPORTS,
  SharedModule.forRoot(),
  AppRoutingModule,
  // UCInventoryModule,
  // UCRdrModule,
  // UCSalesModule,
  // VehicleDetailModule,
  UnauthorizedModule,
  // RDRDetailModule,
  SaleDetailModule,
  VehicleDecertificationModule,
  RoleDetailModule,
  DealerParticipantModule,
  VehicleHistoryModule,
  FlexLayoutModule,
  InfiniteScrollModule,
  TextMaskModule,
  UCDealerInventoryReportModule,
  UCInventoryExceptionReportModule,
  UCSaleReversalReportModule,
  UCVehicleDailyRDRReportModule,
  UCDeletedCertificationReportModule,
  UCDailySalesGoldSilverReportModule,
  UCYTYTCUVSalesReportModule,
  UCSameDayCertReportModule,
  UCVehicleCertificationReportModule,
  UCRegionalModelLineRDRReportModule,
  UCSalesComparisionModelYearReportModule,
  UCLexusPreownedSalesReportModule,
  UCLexusDealerParticipationReportModule,
  UCTopTCUVDealersReportModule,
  UCTRACRetailSalesReportModule,
  UCTRACRetailSalesSummaryReportModule,
  UCLexusWithoutWholesalesReportModule,
  UCSalesConsultantRDRReportModule,
  UCSalesConsultantRDRDetailReportModule,
  UCSalesOver65kReportModule,
  UCLexusDistrictSummaryReportModule,
  UCDSSRReportModule,
  UCLexusDailyRecapReportModule,
  UCRegionObjectiveModule,
  UCDealerObjectiveModule,
  UCRecallReportModule,
  UCInventoryReportModule,
  UCAvgSalesReportModule,
  UCRdrDataReportModule,
  UCTmexSalesReportModule,
  ReportModule
];
