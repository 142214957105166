import {
  Component, Input, OnDestroy, OnInit, ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty, isNil, isUndefined } from 'lodash';
import { IMyDpOptions } from 'mydatepicker';

import { Observable } from 'rxjs';
import * as dealerDetailActions from '../../actions/dealer-detail.action';
import * as rdrDetailActions from '../../actions/dealer-participation.action';
import * as messageActions from '../../actions/message-data.action';
import * as messageDataActions from '../../actions/message-data.action';
import * as fromRoot from '../../reducers';
import { SharedDataService } from '../../services/common/shared-data.service';
import { SharedFunctionService } from '../../services/common/shared-function.service';
import * as models from '../../services/model/models';
import { DealerDetailView } from '../../services/view-model/dealer-detail-view';
import * as viewModels from '../../services/view-model/view-models';
import { DealerDetailsDialogService } from '../../shared/components/dealer-dialog/dealer-details-dialog.service';
import { DialogService } from '../../shared/components/dialog/dialog.service';
import { ScreenMessages } from '../../shared/master-data/messages';
import { ViewConstants } from '../../shared/shared.constants';

@Component({
  selector: 'uc-dealer-participant-page',
  templateUrl: 'dealer-participant-page.component.html'
})

/**
 * This component is used to show the Vehicle Detail screen
 */
export class DealerParticipantPageComponent implements OnInit, OnDestroy {
  @Input() vin: string;
  // Route param subscriber to get the VIN to be loaded
  routeParamSubscriber: any;
  // To expand and collapse Expansion Panel
  expand: boolean = true;
  // to store update flag value
  public updateFlag: boolean;
  // to store add flag value
  public addFlag: boolean;
  // to store add & certify flag value
  public addCertifyFlag: boolean;
  // to store router text
  public routerText: string;
  // to store savebutton text
  public saveButtonText: string;
  // to store update button text
  public updateButtonText: string;
  // Observable to obtain VehicleDetailView object
  decertificationData$: Observable<viewModels.VehicleDetailView>;
  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<DealerDetailView>;

  // Observable to obtain VehicleDetailView object
  vehicleDetails: viewModels.DealerParticipantView =
    <viewModels.DealerParticipantView>new Object();

  // Observable to obtain VehicleDetailView object
  reStorevehicleDetails: any = <any>new Object();

  // To set and get the obtained VehicleDetailView object from reducer using this variable
  public _vehicleDetails: viewModels.DealerParticipantView
    = <viewModels.DealerParticipantView>new Object();
  // to store makeCode
  makeCode: string;

  // to store certified vehcicle check value
  certifiedFlag: string;

  // to store certified vehcicle check value
  forcecertifiedFlag: string;

  warrantExpDateText: string;

  isCertify: boolean;

  isValidPQSS: boolean;

  isValidCarFax: boolean;

  isValidForeignvin: boolean;

  addMode: boolean;

  isForceCertified: boolean;

  oldDealerCd: string;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    inline: false,
    openSelectorTopOfInput: false,
    showClearDateBtn: true,
    showInputField: true,
    alignSelectorRight: true,
    showSelectorArrow: false
  };

  isMandatoryForCertify: boolean;

  // Variable to hold the datas displayed in the grid
  public allDealers: models.DealerDetailsModel[];

  displayInvalidVin: boolean;

  displayInvalidDealerCode: boolean;

  showAdditionalDriver: boolean = false;

  isEmailMandatory: boolean = false;

  isCoEmailMandatory: boolean = false;

  isIndividualCustomer: boolean = false;

  actionMode: string;

  reversalFlag: boolean = false;

  // Route param subscriber to get the vehicle detail to be loaded
  private rdrDetailSubscriber: any;

  /**
   * constructor method to create new instance of VehicleDetailPageComponent
   */
  constructor(private store: Store<fromRoot.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    public sharedDataService: SharedDataService,
    private viewContainerRef: ViewContainerRef,
    private sharedFuncService: SharedFunctionService,
    private dialogsService: DialogService,
    private dealerDetailsDialogService: DealerDetailsDialogService
  ) {
    try {
      this.decertificationData$ = store.select(fromRoot.getDealerParticipant);
      this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
      if (isNil(this.sharedDataService.dealerData)) {
        this.store.dispatch(new dealerDetailActions.LoadAllDealersAction({}));
      }
    } catch (error) {
    } finally {

    }
  }
  /**
   * Init method to initialize state of VehicleDetailPageComponent
   */
  ngOnInit() {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.formatDataforDetailsView();
    this.makeCode = '';
    this.onDisableFuture();
    this.onDisablePast();
    this.makeCode = this.sharedDataService.brandName;
    this.isMandatoryForCertify = false;
    this.displayInvalidVin = false;
    this.displayInvalidDealerCode = false;
    this.isValidCarFax = true;
    this.isValidForeignvin = true;
    this.isValidPQSS = true;
    this.addFlag = false;
    this.addCertifyFlag = false;
    this.isCertify = true;
    this.certifiedFlag = '';
    this.forcecertifiedFlag = '';
    this.addMode = false;
    let vehData: models.Vehicle = <models.Vehicle>new Object();
    this.sharedFuncService.setCurrentScreenName(ViewConstants.VEHICLE_DETAIL_SCREEN_NAME);
    this.store.dispatch(new dealerDetailActions.ClearAllAction());
    this.routeParamSubscriber = this.route.params.subscribe(params => {
      if (!isNil(params['vin']) && !isUndefined(params['vin'])) {
        let vehicle: models.Rdr = {};
        vehicle.vin = params['vin'];
        this.actionMode = params['action'];
        // vehicle.dealerCd = this.sharedDataService.dealerCode;
        // this.store.dispatch(new rdrDetailActions.RdrInitialLoadAction(vehicle));
        this.vin = params['vin'];
        this.updateFlag = true;
        this.routerText = 'RDR Details';
        this.saveButtonText = 'UPDATE';
        this.brandAssign(this.makeCode, 'UPDATE', 'ADD NEW');

        vehData.vin = this.vin;
        vehData.dealerCd = params['dlrCd'];
        vehData.dealerCd = vehData.dealerCd.trim();
        // if (vehData.dealerCd !== this.sharedDataService.dealerCode) {
        //     this.setDealer(vehData.dealerCd);
        // }

        if (this.actionMode === 'reversal') {
          this.reversalFlag = true;
          this.saveButtonText = 'REVERSE RDR';
        }
        // this.makeCode = this.sharedDataService.brandName;
        this.brandAssign(this.makeCode, '', '');
      } else {
        this.addAction(true, true);
      }
    });

    this.rdrDetailSubscriber = this.decertificationData$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        if (!isNil(data.message)) {
          this.isMandatoryForCertify = false;
          this.displayInvalidVin = false;
          this.displayInvalidDealerCode = false;
          if (data.message.message === 'Enter a valid VIN') {
            this.addFlag = false;
            this.addCertifyFlag = false;
            this.forcecertifiedFlag = '';
          }
          if (data.pageType !== ViewConstants.DELETED) {
            this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
              data.message
            ));
            // this.formatDataforDetailsView();
          }

          if (data.pageType === 'CERTIFICATION_VALIDATION_FAILED') {
            this.vehicleDetails = cloneDeep(data);
            this.isMandatoryForCertify = false;
            // this.sharedDataService.dealerCode =
            //     this.vehicleDetails.vehicleData.dealerCd;
            if (this.makeCode === 'LEXUS') {
              this.updateChangeRedirection();
              this.brandAssign(this.makeCode, '', '');
            }
          } else if (data.pageType === 'CERTIFICATION-DELETED') {
            this.vehicleDetails = cloneDeep(data);
            this.certifiedFlag = 'N';
            this.forcecertifiedFlag = 'N';
            this.isMandatoryForCertify = false;
            this.displayInvalidVin = false;
            this.displayInvalidDealerCode = false;
            // this.sharedDataService.dealerCode =
            //     this.vehicleDetails.vehicleData.dealerCd;
          } else if (data.pageType === ViewConstants.UPDATED) {
            this.sharedDataService.dealerCode =
              this.vehicleDetails.vehicleData.dealerCd;
          } else if (data.pageType === ViewConstants.CERTIFIED) {
            this.vehicleDetails = cloneDeep(data);
            this.certifiedFlag = 'Y';
            this.forcecertifiedFlag = '';
            // this.sharedDataService.dealerCode =
            //     this.vehicleDetails.vehicleData.dealerCd;
          } else if (data.pageType === ViewConstants.DECERTIFIED) {
            this.vehicleDetails = cloneDeep(data);
            this.certifiedFlag = 'N';
            this.forcecertifiedFlag = 'N';
            // this.sharedDataService.dealerCode =
            //     this.vehicleDetails.vehicleData.dealerCd;
          } else if (data.pageType === ViewConstants.ADDED) {
            this.vehicleDetails = cloneDeep(data);
            // this.sharedDataService.dealerCode =
            //     this.vehicleDetails.vehicleData.dealerCd;
            this.updateFlag = true;
            this.routerText = 'RDR Details';
            this.saveButtonText = 'UPDATE';
            this.updateButtonText = 'ADD NEW';
            this.vehicleDetails.pageType = '';
          }

        } else if (!isNil(data.vehicleData) && !isNil(data.vehicleData.makeCode) &&
          (data.vehicleData.makeCode !== this.makeCode &&
            data.vehicleData.makeCode !== this.makeCode.substr(0, 3))
          && data.vehicleData.vin.length >= 17) {
          this.displayInvalidVin = true;
          this.formatDataforDetailsView();
        } else if (data.pageType === ViewConstants.NO_VIN_VEH_DETAILS &&
          this.addMode === true) {
          this.addMode = false;
          this.isMandatoryForCertify = false;
          this.displayInvalidVin = false;
          this.displayInvalidDealerCode = false;
        } else if (data !== undefined && data.pageType !== ViewConstants.FAIL &&
          data.pageType !== ViewConstants.NO_VIN_VEH_DETAILS &&
          !isNil(data.vehicleData) && (!isNil(data.vehicleData.vin))) {
          if (!(data.pageType === ViewConstants.UPDATE && this.updateFlag === false)) {
            // if (this.sharedDataService.dealerCode === '') {
            //     this.setDealer(data.vehicleData.dealerCd);
            // }
            this.vehicleDetails = cloneDeep(data);
          }

          this.onDisableFuture();
          this.onDisablePast();
          this.isValidPQSS = true;
          this.isValidCarFax = true;
          this.isValidForeignvin = true;
        }
      } else {
        this.formatDataforDetailsView();
      }
    });

    this.dealerDetailsData$.subscribe(data => {
      if (!isNil(data) && data !== undefined && isNil(data.allDealers)) {

        if (!isEmpty(data.currentDealer)) {
          if (data.currentDealer.dealerCode !== '' ||
            !isNil(data.currentDealer.dealerCode)) {
            let dealerDetail: models.DealerDetailsModel = {};
            dealerDetail.dealerCode = data.currentDealer.dealerCode;
            dealerDetail.dealerName = data.currentDealer.dealerName;
            // this.sharedFuncService.setDealerDetails(dealerDetail);

            let dealerDetailData: models.DealerParticipant = {};
            dealerDetailData.dealerCd = data.currentDealer.dealerCode;
            dealerDetailData.certDlrBooleanFlag =
              this.convertStringToBoolean(data.currentDealer.certDlrFlag);
            dealerDetailData.fuelCellDlrBooleanFlag =
              this.convertStringToBoolean(data.currentDealer.fuelCellDlrFlag);
            dealerDetailData.updatedAt = data.currentDealer.updatedAt;
            dealerDetailData.comments = data.currentDealer.comments;

            this.formatDataforDetailsViewFromService(dealerDetailData);
          }
        }
      } else if (!isNil(data) && data !== undefined) {
        if (!isNil(data.allDealers) && data.allDealers.length > 0) {
          this.sharedDataService.dealerData = cloneDeep(data.allDealers);
        }
        //  else if (!isEmpty(data.currentDealer) || !isNil(data.currentDealer)) {
        //   let message: models.Message = {};
        //   message.message = ScreenMessages.INVALID_DEALER_CODE;
        //   message.type = models.MessageType.DANGER;
        //   this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
        // }
      }
    });
  }

  /**
   * Method to redirect update screen for existing vin
   */
  updateVehicleConfirmed(event: any) {
    event.dialogRef.close(true);
    event.parent.vehicleDetails = cloneDeep(event.parent.reStorevehicleDetails);
    event.parent.updateFlag = true;
    event.parent.routerText = 'Vehicle Details';
    event.parent.saveButtonText = 'UPDATE';
    event.parent.updateButtonText = 'ADD NEW';
    event.parent.isMandatoryForCertify = false;
    event.parent.displayInvalidVin = false;
    event.parent.displayInvalidDealerCode = false;
    if (!isNil(event.parent.vehicleDetails))
      event.parent.vehicleDetails.pageType = '';

    if (!isNil(event.parent.vehicleDetails.vehicleData.certificationStatus)
      && event.parent.vehicleDetails.vehicleData.certificationStatus === 'C') {
      event.parent.certifiedFlag = 'Y';
      event.parent.forcecertifiedFlag = '';
    } else {
      event.parent.certifiedFlag = 'N';
      event.parent.forcecertifiedFlag = 'N';
    }

    if (event.parent.makeCode === 'TOYOTA')
      event.parent.warrantExpDateText = 'POWERTRAIN COVERAGE EXPIRY DATE';
    else if (event.parent.makeCode === 'LEXUS')
      event.parent.warrantExpDateText = 'EXTENSIVE COMPREHENSIVE COVERAGE EXPIRY DATE';
    else
      event.parent.warrantExpDateText = 'WARRANTY EXP DATE';

  }


  setDealer(dealerCodeVal: string) {
    // this.sharedDataService.dealerCode = dealerCodeVal;
    this.store.dispatch(new dealerDetailActions.LoadDealerAction({
      dealerCode: dealerCodeVal
    }));
  }

  addAction(freshPage: boolean, certify: boolean) {
    if (freshPage) {
      this.formatDataforDetailsView();
    }
    this.vin = '';
    this.isMandatoryForCertify = false;
    this.displayInvalidVin = false;
    this.displayInvalidDealerCode = false;
    this.updateFlag = false;
    this.routerText = 'Create RDR';
    this.saveButtonText = 'SUBMIT';

    // if (this.makeCode !== 'TOYOTA') {
    //     this.saveButtonText = 'ADD';
    //     this.updateButtonText = 'ADD & CERTIFY';
    // }
    // else {
    //     this.saveButtonText = 'ADD & CERTIFY';
    //     this.updateButtonText = 'ADD & CERTIFY';
    // }
    if (certify === false) {

      this.addCertifyFlag = false;

    }

  }

  /**
   * On Destory event handler to register the state and filter conditions
   * to store and to unsubscribe the subscriptions
   */
  ngOnDestroy() {
    try {
      if (this.routeParamSubscriber) {
        this.routeParamSubscriber.unsubscribe();
      }
      if (this.rdrDetailSubscriber) {
        this.rdrDetailSubscriber.unsubscribe();
      }
      this.store.dispatch(new rdrDetailActions.ClearAllAction());
      this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    } catch (error) {
      // this.logger.error(error);
    }
  }

  /**
   * To obtain the vehicle details by passing VIN
   */
  getVehicleDetailsbyonblur(vin: string) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.store.dispatch(new dealerDetailActions.LoadDealerAction({
      dealerCode: vin
    }));
  }

  savedDetails(event: any) {
    let model: models.Message = {};
    model.message = 'Lorem Ipsum.';
    model.type = models.MessageType.DANGER;
  }

  navigateToInventoryScreen() {
    this.router.navigate(['/inventory/']);
  }

  navigateToVehicleDetailScreenNew(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    if (this.updateFlag === true) {
      // this.addAction(true, true);
      this.router.navigate(['/rdrDetail/']);
    }
  }

  navigateToRdrSummary(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.router.navigate(['/rdr/']);
  }

  deCertifyConfirmed(event: any) {
    event.dialogRef.close(true);
    event.parent.saveVehicleEvent(event.parent.reStorevehicleDetails);
    this.sharedDataService.isPageEdited = false;
  }

  validateInputData(vehicleData: models.DealerParticipant): boolean {
    let isValid: boolean = true;
    if (vehicleData.certDlrBooleanFlag === false && isNil(vehicleData.comments)) {
      isValid = false;
    }

    return isValid;
  }

  validateCarfaxInputData(fromDealer, toDealer): boolean {
    let isValid: boolean = true;
    let message: models.Message = {};
    if (isNil(this.sharedDataService.dealerData)) {
      this.dealerDetailsData$ = this.store.select(fromRoot.getDealerData);
    }
    let fromDealerData = this.sharedDataService.dealerData.filter
      (t => t.dealerCode === fromDealer);

    let toDealerData = this.sharedDataService.dealerData.filter
      (t => t.dealerCode === toDealer);

    if (fromDealer === toDealer) {
      isValid = false;
      message.message = ScreenMessages.SAME_DEALER_CODE;
      message.type = models.MessageType.DANGER;
      this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
    } else if (fromDealerData.length === 0) {
      isValid = false;
      message.message = ScreenMessages.INVALID_FROM_DEALER_CODE;
      message.type = models.MessageType.DANGER;
      this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
    } else if (toDealerData.length === 0) {
      isValid = false;
      message.message = ScreenMessages.INVALID_TO_DEALER_CODE;
      message.type = models.MessageType.DANGER;
      this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
    }

    return isValid;
  }

  saveVehicleEvent(inputData: any) {

    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.addMode = false;
    let decertificationData: models.DealerParticipant =
      cloneDeep(inputData.vehicleData.vehicleData);
    this.displayInvalidVin = false;
    this.displayInvalidDealerCode = false;
    this.isEmailMandatory = inputData.isEmailMandatory;
    this.isCoEmailMandatory = inputData.isCoEmailMandatory;

    this.replaceEmptyToNull(decertificationData);

    if (decertificationData.isBuySell) {
      if (this.validateCarfaxInputData(inputData.oldDealerCd, decertificationData.dealerCd)) {
        let dealerObj: any = {
          olddealerCd: inputData.oldDealerCd,
          newdealerCd: decertificationData.dealerCd
        };
        this.store.dispatch(new rdrDetailActions.SaveBuySellAction(dealerObj));
        this.addMode = true;
      }
    } else {
      if (this.validateInputData(decertificationData)) {
        this.sharedDataService.isPageEdited = false;
        let data = this.formatDecertificationData(decertificationData);
        this.store.dispatch(new rdrDetailActions.SaveDealerParticitionAction(data));
        this.addMode = true;
      }
    }

  }

  replaceEmptyToNull(decertificationData: models.DealerParticipant) {
    if (!isNil(decertificationData)) {
      if (isNil(decertificationData.comments) || isEmpty(decertificationData.comments)) {
        decertificationData.comments = null;
      }
    }

    return decertificationData;
  }

  /**
   * Method used to display error message
   */
  errorMessageDisplay(messageString: string) {
    this.store.dispatch(new messageActions.SetDisplayMessageAction(
      <models.Message>{
        message: messageString,
        type: models.MessageType.DANGER
      }));
  }

  /**
   * Method used to frame the required state for the vehicle detail screen
   */
  formatDataforDetailsView(): viewModels.DealerParticipantView {
    // this.vehicleDetails =  new Observable<viewModels.VehicleDetailView>();
    let vehicleDetailData = <viewModels.DealerParticipantView>new Object();
    let rdrobj = <models.Rdr>new Object();
    vehicleDetailData.vehicleData = cloneDeep(rdrobj);
    vehicleDetailData.vehicleData.certDlrFlag = null;
    vehicleDetailData.vehicleData.dealerCd = null;
    vehicleDetailData.vehicleData.fuelCellDlrFlag = null;
    // vehicleDetailData.vehicleData.isBuySell = null;
    // vehicleDetailData.vehicleData.oldDealerCd = null;
    this.vehicleDetails = cloneDeep(vehicleDetailData);
    return vehicleDetailData;
  }

  /**
   * Method used to frame the required state for the vehicle detail screen
   */
  formatDataforDetailsViewFromService(dealerData: models.DealerParticipant):
    viewModels.DealerParticipantView {
    // this.vehicleDetails =  new Observable<viewModels.VehicleDetailView>();
    let vehicleDetailData = <viewModels.DealerParticipantView>new Object();
    let rdrobj = <models.Rdr>new Object();
    vehicleDetailData.vehicleData = cloneDeep(rdrobj);
    vehicleDetailData.vehicleData.certDlrBooleanFlag = dealerData.certDlrBooleanFlag;
    vehicleDetailData.vehicleData.dealerCd = dealerData.dealerCd;
    vehicleDetailData.vehicleData.fuelCellDlrBooleanFlag = dealerData.fuelCellDlrBooleanFlag;
    vehicleDetailData.vehicleData.updatedAt = dealerData.updatedAt;
    vehicleDetailData.vehicleData.comments = dealerData.comments;
    // vehicleDetailData.vehicleData.oldDealerCd = null;
    this.vehicleDetails = cloneDeep(vehicleDetailData);
    return vehicleDetailData;
  }



  getDealerEvent(dealerCode) {
    this.displayInvalidDealerCode = false;
    if (this.allDealers.filter(e => e.dealerCode === dealerCode).length === 0) {
      this.displayInvalidDealerCode = true;
    }
  }

  brandAssign(brand: string, saveText: string, updateText: string) {
    if (brand === 'TOYOTA') {
      this.warrantExpDateText = 'POWERTRAIN COVERAGE EXPIRY DATE';
    } else if (brand === 'LEXUS') {
      this.warrantExpDateText = 'EXTENSIVE COMPREHENSIVE COVERAGE EXPIRY DATE';
    } else {
      this.warrantExpDateText = 'WARRANTY EXP DATE';
    }
    if (saveText !== '') {
      this.saveButtonText = saveText;
    }
    if (updateText !== '') {
      this.updateButtonText = updateText;
    }

  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  onDisableFuture() {
    let date = new Date();
    // Disable/enable dates from 5th forward
    date.setMonth(date.getMonth() + 1);
    let copy = this.getCopyOfOptions();
    copy.disableSince = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate() + 1
    };
    this.myDatePickerOptions = copy;
  }

  onDisablePast() {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);

    let copy = this.getCopyOfOptions();
    copy.disableUntil = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate()
    };
    this.myDatePickerOptions = copy;
  }

  updateChangeRedirection() {
    this.updateFlag = true;
    this.routerText = 'Vehicle Details';
    this.saveButtonText = 'UPDATE';
    this.updateButtonText = 'ADD NEW';
    this.vehicleDetails.pageType = '';
  }

  formatDecertificationData(dealerParticipant: models.DealerParticipant) {
    let decertificationData = <models.DealerParticipant>new Object();
    decertificationData.dealerCd = dealerParticipant.dealerCd;
    decertificationData.certDlrFlag =
      this.convertBooleanToString(dealerParticipant.certDlrBooleanFlag);
    decertificationData.fuelCellDlrFlag =
      this.convertBooleanToString(dealerParticipant.fuelCellDlrBooleanFlag);
    decertificationData.comments = dealerParticipant.comments;
    return decertificationData;
  }

  convertBooleanToString(value: boolean): string {
    if (!isNil(value) && value === true) {
      return 'Y';
    } else {
      return 'N';
    }
  }

  convertStringToBoolean(value: string): boolean {
    if (value === 'Y') {
      return true;
    } else {
      return false;
    }
  }

  findDealer() {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.dealerDetailsDialogService.open(
      ViewConstants.SELECT_DEALER_HEADER, this.viewContainerRef
      , this, this.loadDealerDetails).subscribe(() => {
        this.sharedFuncService.setCurrentScreenName(
          ViewConstants.INVENTORY_SUMMARY_SCREEN_NAME);
      });
  }

  findOldDealer() {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.dealerDetailsDialogService.open(
      ViewConstants.SELECT_DEALER_HEADER, this.viewContainerRef
      , this, this.loadOldDealerDetails).subscribe(() => {
        this.sharedFuncService.setCurrentScreenName(
          ViewConstants.INVENTORY_SUMMARY_SCREEN_NAME);
      });
  }

  loadDealerDetails(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    if (!isNil(event) && !isNil(event.componentInstance)) {
      event.componentInstance.parent.formatDataforDetailsView();
      event.componentInstance.parent.vehicleDetails.vehicleData.dealerCd =
        event.componentInstance.selectedDealerCode;
      event.componentInstance.parent.getVehicleDetailsbyonblur(
        event.componentInstance.selectedDealerCode);
    }
  }

  loadOldDealerDetails(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    if (!isNil(event) && !isNil(event.componentInstance)) {
      // event.componentInstance.parent.formatDataforDetailsView();
      event.componentInstance.parent.oldDealerCd =
        event.componentInstance.selectedDealerCode;
    }
  }
}
