
'use strict';
import * as models from '../model/models';
import { SharedFunctionService } from '../common/shared-function.service';

// Vehicle Detail View Model
export interface VehicleDecertificationView {
  // Selected Vehicle detail
  // // Selected VIN data
  vehicleData?: models.Decertification;

  message?: models.Message;

  // Data formatter
  dataFormatter?: SharedFunctionService;
  // page type
  pageType?: string;
}
