import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';
import * as models from '../services/model/models';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

/**
 * Following action types are being used in the vehicle detail screen
 */
export const actionTypes = {
  SAVE_DEALER_PARTICIPANT_ACTION:
    type('[DEALER_PARTICIPANT] save dealer participation'),
  SAVE_DEALER_PARTICIPANT_SUCESS_ACTION:
    type('[DEALER_PARTICIPANT] save dealer participation success'),
  SAVE_DEALER_PARTICIPANT_FAIL_ACTION:
    type('[DEALER_PARTICIPANT] save dealer participation failure'),
  SAVE_BUYSELL_ACTION:
    type('[BUYSELL] save buy sell'),
  SAVE_BUYSELL_SUCESS_ACTION:
    type('[BUYSELL] save buy sell success'),
  SAVE_BUYSELL_FAIL_ACTION:
    type('[BUYSELL] save buy sell failure'),
  CLEAR_ALL_ACTION:
    type('[DEALER_PARTICIPANT] clear all saved data'),
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */


/**
 * Class used to handle the save decertification action
 */
export class SaveDealerParticitionAction implements ActionBase {
  type = actionTypes.SAVE_DEALER_PARTICIPANT_ACTION;

  constructor(public payload: models.DealerParticipant) { }
}

/**
 * Class used to handle the save decertification success action
 */
export class SaveDealerParticitionSuccessAction implements ActionBase {
  type = actionTypes.SAVE_DEALER_PARTICIPANT_SUCESS_ACTION;

  constructor(public payload: models.DealerParticipant) { }
}

/**
 * Class used to handle the save decertification fail action
 */
export class SaveDealerParticitionFailAction implements ActionBase {
  type = actionTypes.SAVE_DEALER_PARTICIPANT_FAIL_ACTION;

  constructor(public payload: models.DealerParticipant) { }
}

/**
 * Class used to handle the save decertification action
 */
export class SaveBuySellAction implements ActionBase {
  type = actionTypes.SAVE_BUYSELL_ACTION;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the save decertification success action
 */
export class SaveBuySellSuccessAction implements ActionBase {
  type = actionTypes.SAVE_BUYSELL_SUCESS_ACTION;

  constructor(public payload: models.DealerParticipant) { }
}

/**
 * Class used to handle the save decertification fail action
 */
export class SaveBuySellFailAction implements ActionBase {
  type = actionTypes.SAVE_BUYSELL_FAIL_ACTION;

  constructor(public payload: models.DealerParticipant) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class ClearAllAction implements ActionBase {
  type = actionTypes.CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = SaveDealerParticitionAction
  | SaveDealerParticitionSuccessAction
  | SaveDealerParticitionFailAction
  | SaveBuySellAction
  | SaveBuySellSuccessAction
  | SaveBuySellFailAction
  | ClearAllAction;
