<div class="dd-history-scroll-wrapper">
  <div *ngIf="!updateFlag" fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column"
    style="height:8%">
    <!--<mat-card fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">-->
    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
      <!--<div fxFlex class="dd-veh-det-header">
                    <span class="dd-veh-det-header-model-description">{{_rdrDetails?.vehicleData?.year}}</span>
                    <span class="dd-veh-det-header-model-description" [innerHTML]="_rdrDetails?.vehicleData?.model?.marketingName"></span>
                </div>
                <div fxFlex class="dd-veh-det-header dd-veh-det-header-pad">
                    <span class="dd-veh-det-header-msrp-text">MSRP</span>
                    <span class="dd-veh-det-header-msrp-value">{{_rdrDetails?.vehicleData?.price?.baseMsrp | currency: 'USD': true: '1.2'}}</span>
                </div>-->
      <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
        <mat-card fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
          class="dd-vehicle-summary-display">
          <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

            <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

            </div>

            <div fxFlex="25" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="20" class="dd-veh-summary-header">
                  <span class="MandatorySymbol"> * </span>VIN
                </div>

                <div *ngIf="!updateFlag && actionMode !== 'add'" fxFlex class="dd-veh-summary-value">
                  <mat-form-field>
                    <input matInput id="vinno" name="vinno" #vinno="ngModel" [(ngModel)]="vinNo" value="{{vinNo}}"
                      style="text-transform: uppercase;" minlength="17"
                      [ddFieldValidator]="validationTypes.ALPHANUMERIC_17_WITHOUTSPACE" class="dd-inputValues" required
                      oninvalid="this.setCustomValidity('Please Enter valid vin')" oninput="setCustomValidity('')" />
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                      *ngIf="!vinno.value && (vinno.touched || this.isErrorMessageDisplay)"  align="start">VIN cannot be
                      blank</mat-hint>
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                      *ngIf="vinno.value && this.displayMsgForVin"  align="start">VIN cannot be less than 17 digit
                    </mat-hint>
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                      *ngIf="vinno.value && this.makeCode==='TOYOTA' && this.displayInvalidVin"  align="start">Enter a
                      valid Toyota VIN</mat-hint>
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                      *ngIf="vinno.value && (this.makeCode==='LEXUS' || this.makeCode==='LEX') && this.displayInvalidVin"
                       align="start">Enter a valid Lexus VIN</mat-hint>

                  </mat-form-field>
                </div>
              </div>
            </div>

            <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

            </div>

            <button (click)="getVehicleDetails(vinNo);" mat-button mat-raised-button
              style="height: 3rem;margin-top:0.6rem">View History</button>

            <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

            </div>

          </div>

          <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

          </div>


        </mat-card>
      </div>
    </div>
  </div>

  <div *ngIf="updateFlag" class="dd-scroll-content" infiniteScroll [infiniteScrollDistance]="1"
    [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="300" [scrollWindow]="false">
    <div *ngFor="let history of _vehicleDetails?.vehicleHistory" class="dd-vehicle-history" fxLayout="column">

      <mat-card fxFlex="100" fxLayout="row" class="dd-vehicle-info">
        <div fxFlex="100" fxFlex.gt-sm="85" fxLayout="column" class="dd-vehicle-det">
          <div fxFlex="50" fxLayout="row" class="dd-first-row">
            <div fxLayout="column" class="dd-vehicle-info-item vname" fxLayout="Column">
              <div [innerHTML]="history.model?.modelYear"></div>
              <div [innerHTML]="history.model?.modelDescription"></div>
            </div>
            <div class="dd-splitter dd-vehicle-info-item " fxLayout="row ">
              <div class="dd-splitter-space dd-vehicle-info-item " fxLayout="Column ">
                <div class="dd-heading-text ">Model</div>
                <div fxLayout="column " class="dd-grid-label-align-10 dd-details-margin "
                  [innerHTML]="history.model?.modelNumber ">
                </div>
              </div>
              <div class="dd-splitter-space dd-vehicle-info-item " fxLayout="Column ">
                <div class="dd-heading-text ">Dealer Code</div>
                <div fxLayout="column " class="dd-grid-label-align-10 dd-details-margin "
                  [innerHTML]="history.dealerCd ">
                </div>
              </div>
              <div class="dd-splitter-space dd-vehicle-info-item " fxLayout="Column ">
                <div class="dd-heading-text ">VIN</div>
                <div fxLayout="column" class="dd-body-min-width ">
                  <div class="text-center dd-grid-hyperlink-field " fxFlex>
                    <div>{{history.vin}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <div *ngFor="let events of history.events?.slice().reverse(); let i=index ">
        <mat-card fxLayout="row ">
          <div fxFlex="100 " fxLayout="column " class="dd-event-details ">
            <div fxFlex="row " class="dd-event-heading ">
              <div class="dd-event-heading-bg " fxLayout="column ">

                <div fxFlex="100 " fxLayout="column " class="dd-event-date " *ngIf="events?.date ">
                  <div class="dd-event-date-text ">{{events.date | date: 'dd'}}</div>
                  <div class="dd-event-month-text ">{{events.date | date: 'MMM'}}</div>
                  <div class="dd-event-year-text ">{{events.date | date: 'y'}}</div>
                </div>
              </div>
              <div fxFlex class="dd-time-text " fxLayoutAlign="start end ">
                <span>{{events.date | date: 'hh:mm a'}}</span>
                <span
                  *ngIf="(events.date | date: 'Z')==( 'GMT+5:30') || (events.date | date: 'Z')==( '+0530') ">(IT)</span>
                <span *ngIf="(events.date | date: 'Z')==( 'PST') || (events.date | date: 'Z')==( '-0800') ">(PT)</span>
                <span *ngIf="(events.date | date: 'Z')==( 'CST') || (events.date | date: 'Z')==( '-0600') ">(CT)</span>
              </div>
            </div>
            <div fxFlex="row ">
              <div class="dd-event-det-left-space " fxLayout="column ">
                &nbsp;
              </div>
              <div fxFlex class="dd-event-details-wrapper">
                <mat-accordion [multi]="true">
                  <mat-expansion-panel [disabled]="!(events?.value)">
                    <mat-expansion-panel-header [ngClass]="{ 'dd-event-border-top' : j> 0 }" [collapsedHeight]="'auto'"
                      [expandedHeight]="'auto'">
                      <div fxFlex="100" fxLayout="row">
                        <div fxFlex="25" class="dd-event-name">
                          <div *ngIf="events?.type">
                            {{events.type}}
                          </div>
                        </div>
                        <div fxFlex="15" class="dd-event-user">
                          <div *ngIf="events?.userId">
                            By {{events.userId}}
                          </div>
                        </div>
                        <div fxFlex="55" class="dd-event-det-content-text">
                          <div *ngIf="events?.value" [class.dd-dio-event-det]="(events?.value)"
                            [innerHTML]="events.value" class="dd-comment-history-ellipses">
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel-header>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

    </div>
  </div>
</div>
