import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportComponent } from './report.component';
// import { AuthService } from '../../services/common/auth.service';
// import { AuthGuardService } from '../../services/common/auth-guard.service';

const saleDetailRoutes: Routes = [
  {
    path: 'report',
    component: ReportComponent
    // canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(saleDetailRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class ReportRoutingModule { }
