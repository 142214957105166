'use strict';
// Import statement required for Vehicle
import * as Models from './models';

// Model Details
export interface RdrDetail {
  newRetailDate?: string;
  retailDateChanged?: string;
  // To store VIN number
  vin?: string;
  // To store model details
  model?: Models.Model;
  // To store Dealer Code
  dealerCd?: string;
  // to store retailDate
  retailDate?: string;
  // to store date of first repair order
  rdrStatus?: string;
  // to store financeInsuranceManagerId
  financeInsuranceManagerId?: string;
  // to store saleMileage
  mileage?: string;
  // to store date of saleType
  saleType?: string;
  // to store salesPersonId
  salesPersonId?: string;
  // to store soldAmount
  soldAmount?: string;
  // to store dealerPaidAmount
  dealerPaidAmount?: string;
  // to store vehicleCost
  vehicleCost?: string;
  // to store vehicleCost
  reconditioningCost?: string;
  // to store buyer object
  buyer?: Models.Buyer;
  // to store cobuyer object
  cobuyer?: Models.Buyer;
  // createdAt
  createdAt?: string;
  // createdBy
  createdBy?: string;
  // updatedAt
  updatedAt?: string;
  // updatedBy
  updatedBy?: string;
  // makeCode
  makeCode?: string;
  // isUpdate
  isUpdate?: string;
  // reversalInd
  reversalInd?: string;
  // reversalReason
  reversalReason?: string;
  // reversalType
  reversalType?: string;
  // reversalDate
  reversalDate?: string;
  // financeInsuranceManagerUId
  financeInsuranceManagerUId?: string;
  // salesPersonUId
  salesPersonUId?: string;
  // source
  source?: string;
  // isAllowOpenCampaign
  isAllowOpenCampaign?: any;
  // isAllowCarfax
  isAllowCarfax?: any;
  // comments
  comment?: string;

  price?: Models.Price;

  sellArea?: string;

  warrantyContractNumber?: string;
  setFormNumber?: string;

  regionNo?: string;

  certificationType?: string;
  sameDayCert?: string;
  saleMonth?: string;
  overlap?: string;
  rtVehicle?: string;
  saleDateToRdrDate?: string;
}
