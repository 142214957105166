<div fxFlex="100" fxLayout="row" class="dealer-vin-search layoutbglite">
  <mat-card fxFlex="row" fxFlex="50">
    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="18" style="padding: 5px;margin-left: 2%">
      <label>{{labelText}}</label>
    </div>
    <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="23" style="padding: 5px;">
      <my-date-picker style="width:100%" placeholder="From Date" name="retailDate" id="retailDate"
        [options]="myDatePickerOptions" [locale]="'en'" [selDate]="fromDate | date :'MM/dd/yyyy'"
        (dateChanged)="dateFilter($event,'fromDate')"></my-date-picker>
    </div>

    <div class="marginl10" fxLayoutAlign="space-around center" fxLayout="row" fxFlex="27" style="padding: 5px;">
      <my-date-picker style="width:100%" placeholder="To Date" name="retailDate" id="retailDate"
        [options]="myDatePickerOptions" [locale]="'en'" [selDate]="toDate | date :'MM/dd/yyyy'"
        (dateChanged)="dateFilter($event,'toDate')"></my-date-picker>
    </div>

    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="20" style="padding: 5px;">
      <button [disabled]="!isDateSelected" (click)="filterValueChanged();" mat-button mat-raised-button>Apply</button>
    </div>
  </mat-card>

</div>
