import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// dealer #
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// vin
      key: 'TYPE', fieldName: 'type',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '350', columnMinWidth: '350',
      columnMaxWidth: '350', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// model
      key: 'TOTAL', fieldName: 'total', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// CERTIFICATION NO
      key: ViewConstants.CERTIFIED, fieldName: 'certified',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// model
      key: ViewConstants.NON_CERTIFIED, fieldName: 'nonCertified', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    }
  ];
}
