'use strict';

// Color Details
export interface Color {
  // Color Code
  colorCd?: string;
  // Color Swatch URL
  colorSwatch?: string;
  // Color Marketing Description
  marketingName?: string;
  // Color Marketing Description
  colorHexCd?: string;
  // Color Display Name
  colorDesc?: string;
}
