



import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import * as rdrSummaryAction from '../actions/rdr-summary.action';
import { UCRdrSummaryService } from '../services/api/rdr-summary.service';
import { UCInventorySummary } from '../services/model/InventorySummary';
import {map, catchError, switchMap} from 'rxjs/operators'

/**
 * Class used to handle the service interaction in the inventory summary screen
 */
@Injectable()
export class UCRdrSummaryEffects {

  /**
   * Mehod used to get the inventory summary data on Initial Load
   */
  @Effect()
  getRdrSummary$: Observable<Action> = this.actions$
    .pipe(ofType(rdrSummaryAction.ACTION_TYPES.INITIAL_LOAD)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.uCRdrService.getInventorySummary(data)
      .pipe(map((inventories: Array<UCInventorySummary>) =>
          new rdrSummaryAction.InitialLoadSuccessAction(inventories))
          , catchError(error => of(new rdrSummaryAction.InitialLoadFailureAction(error))))
    ));
  /**
   * Constructor method used to create new instance of UCInventorySummaryEffects class
   */
  constructor(private actions$: Actions,
    private uCRdrService: UCRdrSummaryService) {

  }


}

