import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { UCDealerObjectiveRoutingModule }
    from './dealer-objective-routing.module';
import { UCDealerObjectivePageComponent }
    from './dealer-objective-page.component';
import { UCDealerObjectiveGridComponent }
    from './dealer-objective-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCDealerObjectiveRoutingModule
    ],
    exports: [
      UCDealerObjectivePageComponent
    ],
    declarations: [
      UCDealerObjectivePageComponent,
        UCDealerObjectiveGridComponent
    ]
})

export class UCDealerObjectiveModule {

}
