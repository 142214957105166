import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';
import * as models from '../services/model/models';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

/**
 * Following action types are being used in the vehicle detail screen
 */
export const actionTypes = {
  SALE_INITIAL_LOAD: type('[Sale] Initiate load for Sale details'),
  SALE_INITIAL_LOAD_SUCCESS: type('[Sale] Sale details Success'),
  SALE_INITIAL_LOAD_FAIL: type('[Sale] Sale details Fail'),
  GET_SALE_BY_VIN: type('[Sale] Get Sale details by Vin'),
  GET_SALE_BY_VIN_SUCCESS: type('[Sale] Get Sale details by Vin Success'),
  GET_SALE_BY_VIN_FAIL: type('[Sale] Get Sale details by Vin Fail'),
  SALE_CLEAR_ALL_ACTION: type('[Sale] clear all Sale data'),
  ADD_SALE_ACTION: type('[Sale] add new Sale'),
  ADD_SALE_SUCESS_ACTION: type('[Sale] add new Sale success'),
  ADD_SALE_FAIL_ACTION: type('[Sale] add new Sale failure'),
  UPDATE_SALE_ACTION: type('[Sale] update Sale'),
  UPDATE_SALE_SUCESS_ACTION: type('[Sale] update Sale success'),
  UPDATE_SALE_FAIL_ACTION: type('[Sale] update Sale failure'),
  DELETE_SALE_ACTION: type('[Sale] delete Sale'),
  DELETE_SALE_SUCESS_ACTION: type('[Sale] delete Sale success'),
  DELETE_SALE_FAIL_ACTION: type('[Sale] delete Sale failure'),
  RDR_REVERSAL_ACTION: type('[Sale] Reverse RDR'),
  RDR_REVERSAL_SUCESS_ACTION: type('[Sale] Reverse RDR success'),
  RDR_REVERSAL_FAIL_ACTION: type('[Sale] Reverse RDR failure'),
  FIMANAGER_LOAD_ACTION: type('[SaleManager] SaleManager Load'),
  FIMANAGER_LOAD_SUCESS_ACTION: type('[SaleManager] SaleManager Load success'),
  FIMANAGER_LOAD_FAIL_ACTION: type('[SaleManager] SaleManager Load failure')
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to load the Initial data for vehicle detail screen
 */
export class SaleInitialLoadAction implements ActionBase {
  type = actionTypes.SALE_INITIAL_LOAD;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the Success action for Initial Load method
 */
export class SaleInitialLoadSuccessAction implements ActionBase {
  type = actionTypes.SALE_INITIAL_LOAD_SUCCESS;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the Failure action for Initial Load method
 */
export class SaleInitialLoadFailAction implements ActionBase {
  type = actionTypes.SALE_INITIAL_LOAD_FAIL;

  constructor(public payload: any) { }
}



/**
 * Class used to handle the Success action for Initial Load method
 */
export class SaleClearAllAction implements ActionBase {
  type = actionTypes.SALE_CLEAR_ALL_ACTION;

  constructor() { }
}

/**
 * Class used to handle the add new vehicle action
 */
export class AddNewSaleAction implements ActionBase {
  type = actionTypes.ADD_SALE_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the add new vehicle success action
 */
export class AddNewSaleSuccessAction implements ActionBase {
  type = actionTypes.ADD_SALE_SUCESS_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the add new vehicle fail action
 */
export class AddNewSaleFailAction implements ActionBase {
  type = actionTypes.ADD_SALE_FAIL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the update vehicle action
 */
export class UpdateSaleAction implements ActionBase {
  type = actionTypes.UPDATE_SALE_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the update vehicle success action
 */
export class UpdateSaleSuccessAction implements ActionBase {
  type = actionTypes.UPDATE_SALE_SUCESS_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the update vehicle fail action
 */
export class UpdateSaleFailAction implements ActionBase {
  type = actionTypes.UPDATE_SALE_FAIL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle action
 */
export class DeleteSaleAction implements ActionBase {
  type = actionTypes.DELETE_SALE_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle success action
 */
export class DeleteSaleSuccessAction implements ActionBase {
  type = actionTypes.DELETE_SALE_SUCESS_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle fail action
 */
export class DeleteSaleFailAction implements ActionBase {
  type = actionTypes.DELETE_SALE_FAIL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the get rdr by vin action
 */
export class GetSaleByVinAction implements ActionBase {
  type = actionTypes.GET_SALE_BY_VIN;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the get rdr by vin success action
 */
export class GetSaleByVinSuccessAction implements ActionBase {
  type = actionTypes.GET_SALE_BY_VIN_SUCCESS;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the get rdr by vin fail action
 */
export class GetSaleByVinFailAction implements ActionBase {
  type = actionTypes.GET_SALE_BY_VIN_FAIL;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the delete vehicle action
 */
export class RdrReversalAction implements ActionBase {
  type = actionTypes.RDR_REVERSAL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle success action
 */
export class RdrReversalSuccessAction implements ActionBase {
  type = actionTypes.RDR_REVERSAL_SUCESS_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle fail action
 */
export class RdrReversalFailAction implements ActionBase {
  type = actionTypes.RDR_REVERSAL_FAIL_ACTION;

  constructor(public payload: models.Rdr) { }
}

/**
 * Class used to handle the delete vehicle action
 */
export class FILoadAction implements ActionBase {
  type = actionTypes.FIMANAGER_LOAD_ACTION;

  constructor(public payload: string) { }
}

/**
 * Class used to handle the delete vehicle success action
 */
export class FILoadSuccessAction implements ActionBase {
  type = actionTypes.FIMANAGER_LOAD_SUCESS_ACTION;

  constructor(public payload: any) { }
}

/**
 * Class used to handle the delete vehicle fail action
 */
export class FILoadFailAction implements ActionBase {
  type = actionTypes.FIMANAGER_LOAD_FAIL_ACTION;

  constructor(public payload: any) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = SaleInitialLoadAction
  | SaleInitialLoadSuccessAction
  | SaleInitialLoadFailAction
  | AddNewSaleAction
  | AddNewSaleSuccessAction
  | AddNewSaleFailAction
  | UpdateSaleAction
  | UpdateSaleSuccessAction
  | UpdateSaleFailAction
  | DeleteSaleAction
  | DeleteSaleSuccessAction
  | DeleteSaleFailAction
  | GetSaleByVinAction
  | GetSaleByVinSuccessAction
  | GetSaleByVinFailAction
  | RdrReversalAction
  | RdrReversalSuccessAction
  | RdrReversalFailAction
  | FILoadAction
  | FILoadSuccessAction
  | FILoadFailAction
  ;
