




import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { RoleDetailService } from '../services/api/role-detail.service';

import * as detailActions from '../actions/role-detail.action';

import * as models from '../services/model/models';
import {map, catchError, switchMap} from 'rxjs/operators'

/**
 * Class used to handle the service interaction in the vehicle detail screen
 */
@Injectable()
export class RoleDetailEffects {
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getRoleConfiguration$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.GET_ROLE_CONFIGURATION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(role =>
      this.roleDetailService.getRoleConfiguration(role)
      .pipe(map((vehicleData: any) =>
          new detailActions.GetRoleConfigurationSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.GetRoleConfigurationFailAction(error))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getRolesByMakeCode$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.GET_ROLES_BY_MAKECODE)
    ,map((action: ActionBase) => action.payload)
    , switchMap(role =>
      this.roleDetailService.getRolesByMakeCode()
      .pipe(map((vehicleData: any) =>
          new detailActions.GetRolesByMakeCodeSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.GetRolesByMakeCodeFailAction(error))))
    ));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  saveDealerParticipation$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.SAVE_ROLE_CONFIGURATION_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.roleDetailService.saveRoleConfiguration(data)
      .pipe(map((vehicleData: models.Role) =>
          new detailActions.SaveRoleConfigurationSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.SaveRoleConfigurationFailAction(error))))
    ));


  /**
   * Constructor method used to create new instance of VehicleDetailsEffects class
   */
  constructor(private actions$: Actions, private roleDetailService: RoleDetailService) { }
}
