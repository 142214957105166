import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */


/**
 * Following action types are being used in the inventory summary screen
 */

export const actionTypes = {
    LOAD_FEATURE_CONFIG_DETAIL: type('[featureConfigDetails] Initiate load for feature config'),
    LOAD_FEATURE_CONFIG_SUCCESS: type('[featureConfigDetails] feature config data loaded Success'),
    LOAD_FEATURE_CONFIG_FAIL: type('[featureConfigDetails] feature config data loaded Fail'),
    LOAD_TIER_ELIBILITY: type('[TIERELGIBILITY] Initiate load for TierElgibility'),
    lOAD_TIERELIGIBILITY_SUCCESS: type('[TIERELGIBILITY]  data loaded Success'),
    lOAD_TIERELIGIBILITY_FAIL: type('[TIERELGIBILITY]  data loading Failed'),
    LOAD_WARRANTY_CONTRACT_DATA: type('[getWarrantyContarctDetails] Initiate load for Warranty Wrapper'),
    LOAD_WARRANTY_CONTRACT_SUCCESS: type('[getWarrantyContarctDetails]  data loaded Success'),
    LOAD_WARRANTY_CONTRACT_DATA_FAIL: type('[getWarrantyContarctDetails]  data loading Failed'),
    CLEAR_ALL_ACTION: type('[featureConfigDetails] clear all saved data'),
};





export class LoadFeatureConfigAction implements ActionBase {
    type = actionTypes.LOAD_FEATURE_CONFIG_DETAIL;
    constructor(public payload: any) { }
}


export class LoadFeatureConfigSuccesssAction implements ActionBase {
    type = actionTypes.LOAD_FEATURE_CONFIG_SUCCESS;
    constructor(public payload: any) { }
}


export class LoadFeatureConfigFailAction implements ActionBase {
    type = actionTypes.LOAD_FEATURE_CONFIG_FAIL;
    constructor(public payload: any) { }
}

export class LoadTiereligiblityAction implements ActionBase {
    type = actionTypes.LOAD_TIER_ELIBILITY;
    constructor(public payload: any) { }
}


export class LoadTiereligiblitySuccesssAction implements ActionBase {
    type = actionTypes.lOAD_TIERELIGIBILITY_SUCCESS;
    constructor(public payload: any) { }
}


export class LoadTiereligiblityFailAction implements ActionBase {
    type = actionTypes.lOAD_TIERELIGIBILITY_FAIL;
    constructor(public payload: any) { }
}
export class LoadWarrantyContractDataAction implements ActionBase {
    type = actionTypes.LOAD_WARRANTY_CONTRACT_DATA;
    constructor(public payload: any) { }
}


export class LoadWarrantyContractDataSuccesssAction implements ActionBase {
    type = actionTypes.LOAD_WARRANTY_CONTRACT_SUCCESS;
    constructor(public payload: any) { }
}


export class LoadWarrantyContractDataFailAction implements ActionBase {
    type = actionTypes.LOAD_WARRANTY_CONTRACT_DATA_FAIL;
    constructor(public payload: any) { }
}

export class ClearAllAction implements ActionBase {
    type = actionTypes.CLEAR_ALL_ACTION;

    constructor() { }
}


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Action
    = LoadFeatureConfigAction
    | LoadFeatureConfigSuccesssAction
    | LoadFeatureConfigFailAction
    | LoadTiereligiblityAction
    | LoadTiereligiblitySuccesssAction
    | LoadTiereligiblityFailAction
    | LoadWarrantyContractDataAction
    | LoadWarrantyContractDataSuccesssAction
    | LoadWarrantyContractDataFailAction
    | ClearAllAction;
