import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../../services/common/auth-guard';
import { UCYTYTCUVSalesComparisonPageReportComponent } from './yty-tcuv-sales-report-page';

const ytyTCUVSalesComparisonRoutes: Routes = [
  {
    path: 'ytyTCUVSalesComparisonReport',
    component: UCYTYTCUVSalesComparisonPageReportComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ytyTCUVSalesComparisonRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCYTYTCUVSalesReportRoutingModule {

}


