// Import statements required for Inventory Summary
import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
let validationTypes: typeof ValidationTypes = ValidationTypes;
// Class for Inventory Summary
export class DealerSettingsJsonClass {
  // JSON structure for Grid Columns
  static gridColumns = [

    {
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCode', style: '', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '85', columnMinWidth: '85',
      columnMaxWidth: '130', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.NUMBERONLY_7, styleClass: 'text-center'
    },
    {
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: '',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
      columnMinWidth: '170', columnMaxWidth: '194', currencyField: 'false', filterValue: '',
      dataTypeformat: '', styleClass: 'text-left'
    },

    {
      key: ViewConstants.DEALER_ADDRESS_COLUMN, fieldName: 'address', style: '',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '185',
      columnMinWidth: '185', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', styleClass: 'text-left'
    },

    {
      key: ViewConstants.DEALER_CITY_COLUMN, fieldName: 'city', style: '',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '115',
      columnMinWidth: '115', columnMaxWidth: '175', currencyField: 'false', filterValue: '',
      dataTypeformat: '', styleClass: 'text-left'
    },

    {
      key: ViewConstants.DEALER_STATE_COLUMN, fieldName: 'state', style: '',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '55',
      columnMinWidth: '55', columnMaxWidth: '85', currencyField: 'false', filterValue: '',
      dataTypeformat: '', styleClass: 'text-center'
    },

    {
      key: ViewConstants.DEALER_ZIP_COLUMN, fieldName: 'zip', style: '', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '70', columnMinWidth: '70',
      columnMaxWidth: '70', currencyField: 'false', filterValue: '',
      dataTypeformat: '', styleClass: 'text-center'
    },

    {
      key: ViewConstants.DEALER_BRAND_COLUMN, fieldName: 'brand', style: '',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '80',
      columnMinWidth: '80', columnMaxWidth: '95', currencyField: 'false', filterValue: '',
      dataTypeformat: ''
    },

    {
      key: ViewConstants.DEALER_AREAREGION_NUM_COLUMN, fieldName: 'areaRegionNo', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '110',
      columnMinWidth: '110', columnMaxWidth: '160', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.NUMBERONLY_5, styleClass: 'text-center'
    },

    {
      key: ViewConstants.DEALER_AREAREGION_STRING_COLUMN, fieldName: 'areaRegionName', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '105',
      columnMinWidth: '105', columnMaxWidth: '175', currencyField: 'false', styleClass: 'text-left',
      filterValue: '', dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE
    },
    {
      key: ViewConstants.DEALER_DISTRICT_COLUMN, fieldName: 'districtNo',
      style: '', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '80', columnMinWidth: '80',
      columnMaxWidth: '90', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.NUMBERONLY_5, styleClass: 'text-center'
    }
  ];
}
