// Validation Types
export enum ValidationTypes {
    NUMBER_7,
    NUMBER_4,
    NUMBER_10,
    NUMBER_7_WITHPLUSMINUS,
    NUMBER_7_WITHDOLLAR,
    NUMBER_4_WITHPLUSMINUS,
    NUMBERONLY_3,
    NUMBERONLY_4,
    NUMBERONLY_5,
    NUMBERONLY_7,
    ALPHANUMERIC_3000,
    ALPHANUMERIC_500,
    ALPHANUMERIC_24,
    ALPHANUMERIC_20,
    ALPHANUMERIC_14,
    ALPHANUMERIC_50,
    ALPHANUMERIC,
    NUMBERONLY_5_LESSGREATER,
    NUMBER_7_LESSGREATER,
    ALPHANUMERIC_WITHOUTSPACE,
    ALPHANUMERIC_3_WITHOUTSPACE,
    ALPHANUMERIC_4_WITHOUTSPACE,
    ALPHANUMERIC_5_WITHOUTSPACE,
    ALPHANUMERIC_17_WITHOUTSPACE,
    ALPHABET_1,
    ALPHABET_2,
    ALPHANUMERIC_SPECIAL_10,
    ALPHANUMERIC_SPECIAL_14,
    ALPHANUMERIC_SPECIAL_30,
    ALPHANUMERIC_SPECIAL_14_WITHOUTSPACE,
    INTEGER,
    NUMBER,
    NUMBER_WITH_DECIMAL_2,
    CHAR_14,
    LESSER_GREATER_SYMBOL,
    EMAIL,
    US_PHONE
}
