/**
 * This module is the entry for your App.
 *
 * Make sure to use the 3 constant APP_ imports so you don't have to keep
 * track of your root app dependencies here. Only import directly in this file if
 * there is something that is specific to the environment.
 */

// import { IdlePreload, IdlePreloadModule } from '@angularclass/idle-preload';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { createInputTransfer, createNewHosts, removeNgStyles } from '@angularclass/hmr';
import { MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { NgIdleModule } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { AppComponent } from './app.component';
import { APP_DECLARATIONS } from './app.declarations';
import { APP_ENTRY_COMPONENTS } from './app.entry-components';
import { APP_IMPORTS } from './app.imports';
import { APP_PROVIDERS } from './app.providers';
import { AppState } from './reducers';
import { environment as MSALConfig } from './tessconfig';
import { take } from 'rxjs/operators';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { UCCurrencyPricePipe } from './shared/pipes/currency-price.pipe';
import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [
        AppComponent,
        APP_DECLARATIONS
    ],
    imports: [
        APP_IMPORTS,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        CommonModule,
        // DeprecatedI18NPipesModule,
        NgIdleModule.forRoot(),
        MsalModule.forRoot({...MSALConfig.msalConfig },{
          popUp: false,
          consentScopes: ['profile'],
          unprotectedResources: [],
          protectedResourceMap: [
            [`${environment.apiBaseUrl}`, ['profile']],
          ],
        },)
        // IdlePreloadModule.forRoot() // forRoot ensures the providers are only created once
    ],
    bootstrap: [AppComponent],
    exports: [AppComponent],
    providers: [APP_PROVIDERS, MsalService,DatePipe, UCCurrencyPricePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
          provide: MAT_MENU_DEFAULT_OPTIONS,
          useValue: {
            overlayPanelClass: 'uvi-mat-menu-overlay-panel'
          }
        }
    ]
})

export class AppModule {
  constructor(public appRef: ApplicationRef,
    private _store: Store<AppState>) { }

  hmrOnInit(store) {
    if (!store || !store.rootState) return;

    // restore state by dispatch a SET_ROOT_STATE action
    if (store.rootState) {
      this._store.dispatch({
        type: 'SET_ROOT_STATE',
        payload: store.rootState
      });
    }

    if ('restoreInputValues' in store) { store.restoreInputValues(); }
    this.appRef.tick();
    Object.keys(store).forEach(prop => delete store[prop]);
  }
  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    this._store.pipe(take(1)).subscribe(s => store.rootState = s);
    store.disposeOldHosts = createNewHosts(cmpLocation);
    store.restoreInputValues = createInputTransfer();
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
