'use strict';
// Inspection Details
export interface DealerParticipant {
  // to store dealerCd
  dealerCd?: string;
  // to store certDlrFlag
  certDlrFlag?: string;
  // to store fuelCellDlrFlag
  fuelCellDlrFlag?: string;

  certDlrBooleanFlag?: boolean;

  fuelCellDlrBooleanFlag?: boolean;

  comments?: string;

  updatedAt?: string;

  isBuySell?: boolean;

  oldDealerCd?: string;
}
