import { Injectable } from '@angular/core';
import * as fs from 'file-saver';
import * as FileSaver from 'file-saver';
import { isNil } from 'lodash';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as ExcelProper from 'exceljs';
import { ViewConstants } from '../../shared/shared.constants';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExportToExcelService {
  public worksheet: any;
  constructor() {

  }

  public exportAsExcelFile(reportData: any): void {
    const workbook: ExcelProper.Workbook = new Excel.Workbook();
    const totalHeader = reportData.totalHeader;

    if (!isNil(totalHeader)) {
      const totalWS = workbook.addWorksheet(reportData.fileName + '_T');

      let totalTitleRow =
        totalWS.addRow(['', '',
          ViewConstants.APPLICATION_TITLE_FOR_REPORT]);
      totalTitleRow.font = { size: 16, bold: true };

      totalWS.addRow(
        ['', '', '',
          reportData.title, '', '', '', '', '', '', '', '', '',
          'Report Generated Date', reportData.generationDate]);
      if (!isNil(reportData.range)) {
        totalWS.addRow(
          ['', '', '', '',
            reportData.range]);
        totalWS.mergeCells('E3:I3');
      }
      totalWS.addRow([]);

      totalWS.mergeCells('C1:M1');
      totalWS.mergeCells('D2:J2');

      let headerTotalRow = totalWS.addRow(totalHeader);
      headerTotalRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }
        };
      });
      totalWS.addRows(reportData.totalData);
      totalWS.addRow([]);
    }

    const worksheet = workbook.addWorksheet(reportData.fileName);

    if (!isNil(reportData)) {

      let titleRow =
        worksheet.addRow(['', '',
          ViewConstants.APPLICATION_TITLE_FOR_REPORT]);
      titleRow.font = { size: 16, bold: true };

      worksheet.addRow(
        ['', '', '',
          reportData.title, '', '', '', '', '', '', '', '', '',
          'Report Generated Date', reportData.generationDate]);
      if (!isNil(reportData.range)) {
        worksheet.addRow(
          ['', '', '', '',
            reportData.range]);
        worksheet.mergeCells('E3:I3');
      }
      worksheet.addRow([]);

      worksheet.mergeCells('C1:M1');
      worksheet.mergeCells('D2:J2');

      const header = reportData.header;

      // Add Header Row
      let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }
        };
      });

      const data = reportData.data;
      worksheet.addRows(data);
      worksheet.eachRow(function (row, rowNum) {
        row.eachCell(function (cell, cellNum) {
          /** cell.alignment not work */
          if (rowNum === 1) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center'
            };
          } else {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left'
            };
          }
        });
      });
      // tslint:disable-next-line:no-shadowed-variable
      workbook.xlsx.writeBuffer().then(data => {
        let blob = new Blob([data], { type: EXCEL_TYPE });
        FileSaver.saveAs(blob, reportData.fileName + '_Report' + EXCEL_EXTENSION);
      });

    }
  }


}
