

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as viewModels from '../services/view-model/view-models';
import * as detailActions from '../actions/role-detail.action';
import * as models from '../services/model/models';
import { isNil, cloneDeep } from 'lodash';
import { SharedFunctionService } from '../services/common/shared-function.service';
import { ScreenMessages } from '../shared/master-data/messages';
import { ViewConstants } from '../shared/shared.constants';
/**
 * Interface used to maintain the state for the vehicle detail screen
 */
export interface RoleDetailState {
  vehicleDetail: viewModels.RoleDetailView;
}

/**
 * Variable used to store the initial state of VehicleDetailsState
 */
const initialState: RoleDetailState = {
  vehicleDetail: null
};

/**
 * Method used to set the default initial state of VehicleDetailsState
 */
function setDefaulValue(): viewModels.RoleDetailView {
  let vehicleDetails: viewModels.RoleDetailView =
    <viewModels.RoleDetailView>new Object();
  vehicleDetails.vehicleData = {};
  vehicleDetails.message = null;
  vehicleDetails.pageType = '';
  return vehicleDetails;
}

/**
 * Reducer method to handle the vehicle detail screen actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): RoleDetailState {
  switch (action.type) {

    case detailActions.actionTypes.GET_ROLE_CONFIGURATION_SUCCESS: {
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: formatDataforDetailsView(cloneDeep(action.payload)),
        pageType: ViewConstants.ADD,
        message: null
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.GET_ROLE_CONFIGURATION_FAIL: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      message.message = ScreenMessages.INTERNAL_SERVER_ERROR;
      message.type = models.MessageType.DANGER;
      let vehicleDetailData: viewModels.VehicleDetailView = setDefaulValue();
      vehicleDetailData.message = message;
      vehicleDetailData.vehicleData = null;
      return { vehicleDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.GET_ROLES_BY_MAKECODE_SUCCESS: {
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload),
        pageType: ViewConstants.ADD,
        message: null
      });
      return { vehicleDetail: vehicleDetailData.vehicleData };
    }
    case detailActions.actionTypes.GET_ROLES_BY_MAKECODE_FAIL: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      message.message = ScreenMessages.INTERNAL_SERVER_ERROR;
      message.type = models.MessageType.DANGER;
      let vehicleDetailData: viewModels.VehicleDetailView = setDefaulValue();
      vehicleDetailData.message = message;
      vehicleDetailData.vehicleData = null;
      return { vehicleDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.SAVE_ROLE_CONFIGURATION_SUCESS_ACTION: {
      let message: models.Message = {};
      message.message = ScreenMessages.USER_RECORD_SUCCESS;

      message.type = models.MessageType.SUCCESS;

      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: null,
        pageType: ViewConstants.UPDATED,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.SAVE_ROLE_CONFIGURATION_FAIL_ACTION: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      let pageType;
      let tempVehicleData = cloneDeep(state.vehicleDetail);
      message.message = cloneDeep(action.payload.errorDesc);
      message.type = models.MessageType.DANGER;
      pageType = ViewConstants.UPDATE_FAIL;
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload),
        pageType: pageType,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.CLEAR_ALL_ACTION: {
      return { vehicleDetail: setDefaulValue() };
    }

    default:
      return state;
  }

}
/* tslint:enable:no-switch-case-fall-through */
/**
 * Method to get the state of VehicleDetailsState for vehicle detail screen
 */
export function getRoleDetailState(state: RoleDetailState) {
  return state.vehicleDetail;
}

/**
 * Method used to frame the required state for the vehicle detail screen
 */
function formatDataforDetailsView(data: models.Role): models.Role {
  let vehicleDetailData: any = setDefaulValue();
  if (data) {
    let roleObj = <models.Role>new Object();
    roleObj.role = data.role;
    roleObj.permission = data.permission;
    roleObj.isActive = data.isActive;
    roleObj.restrictedModules = data.restrictedModules;
    vehicleDetailData = roleObj;
  }
  return vehicleDetailData;
}

