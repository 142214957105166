<div fxLayout="column" layout-fill class="layoutbg">
    <mat-card>
        <mat-toolbar color="primary" class="dd-layout-toolbar">
            <!--<button *ngIf="brandName==='TOYOTA'" mat-button class="dd-menu" mat-icon-button><img style="height:100%" src="assets/TCUV.png"></button>
            <button *ngIf="brandName==='LEXUS'" mat-button class="dd-menu" mat-icon-button><img style="height:100%;border: 1px solid #dad3d3;" src="assets/Lcert.png"></button>-->
            <ng-content select="[toolbar-content]"></ng-content>

        </mat-toolbar>
    </mat-card>
    <!-- <mat-card>
        <mat-toolbar color="primary" class="dd-layout-toolbar">
            <ng-content select="[toolbar-content-sub]"></ng-content>
        </mat-toolbar>
    </mat-card> -->
    <mat-card>
        <div fxFlex="100" class="content mat-content">
            <ng-content></ng-content>
        </div>
    </mat-card>
</div>