import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class RegionalModelLineReportGridColumns {
  static gridColumns = [
    {// model
      key: ViewConstants.MODEL_NO, fieldName: 'model', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '180',
      columnMinWidth: '180', columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS,
    },
    {// modelYear
      key: ViewConstants.MODEL_YEAR, fieldName: 'modelYear',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// totalRDR
      key: ViewConstants.RDRS, fieldName: 'totalRDR', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.LESSER_GREATER_SYMBOL, filterType: FilterType.PRICE_COLUMN
    },
     {// mix %
      key: ViewConstants.MIXPERCENT, fieldName: 'mixPercent', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.CONTAINS,
      tooltip: '(Model Yearwise Total RDR / Modelwise Total RDR)'
    },
    {// averageRetailAmount
      key: ViewConstants.AVG_TRANS_PRICE,
      fieldName: 'avgTransPrice',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.LESSER_GREATER_SYMBOL, filterType: FilterType.CURRENCY_COLUMN
    },
    {// averageReconditionCost
      key: ViewConstants.AVG_RECON_COST,
      fieldName: 'avgReconCost', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '180',
      columnMinWidth: '180', columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.LESSER_GREATER_SYMBOL, filterType: FilterType.CURRENCY_COLUMN
    },
    {// avgturnrate
      key: ViewConstants.AVG_TURN_RATE,
      fieldName: 'avgTurnRate', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// current inventory
      key: ViewConstants.CURRENT_INVENTORY,
      fieldName: 'totalInventory', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// inventory mix
      key: ViewConstants.INVENTORYMIX, fieldName: 'inventoryMix', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
      filterType: FilterType.CONTAINS,
      tooltip: '(Model Yearwise Total Inventory / Modelwise Total Inventory)'
    }
  ];
}
