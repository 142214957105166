import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCLexusPreownedSalesReportRoutingModule }
  from './lexus-preowned-sales-report-routing.module';
import { UCLexusPreownedSalesReportComponent }
  from './lexus-preowned-sales-report-page.component';
import { UCLexusPreownedSalesGridComponent }
  from './lexus-preowned-sales-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCLexusPreownedSalesReportRoutingModule
  ],
  exports: [
    UCLexusPreownedSalesReportComponent
  ],
  declarations: [
    UCLexusPreownedSalesReportComponent,
    UCLexusPreownedSalesGridComponent
  ]
})

export class UCLexusPreownedSalesReportModule {
}
