import {
  Component, Input, OnInit, Output, ChangeDetectionStrategy,
  ViewEncapsulation, ViewContainerRef, EventEmitter, ViewChild, ElementRef
} from '@angular/core';

import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import * as viewModels from '../../services/view-model/view-models';
import * as models from '../../services/model/models';
import { isNil, cloneDeep, isEmpty } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as detailActions from '../../actions/vehicle-detail.action';
import { ValidationTypes } from '../../services/model/ValidatorTypes';
import { FormControl, FormControlDirective, FormControlName, Validators } from '@angular/forms';
import { SharedDataService } from '../../services/common/shared-data.service';

@Component({
  selector: 'uc-vehicle-decertification',
  templateUrl: 'vehicle-decertification.component.html',
  styleUrls: ['./vehicle-decertification.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/***
 * This component is used to the show the Vehicle Detail screen
 */
export class VehicleDecertificationComponent implements OnInit {

  @ViewChild('rdrDetailForm',  {static: false}) form;

  interiorTrim: string;

  // to display error message after click on Add, Add and Certify button
  isErrorMessageDisplay: boolean = false;

  // to display error message after click on Add, Add and Certify button
  isErrorMessageDisplayForCo: boolean = false;

  // to get update flag value from parent page
  @Input() updateFlag: boolean;

  // to get update flag value from parent page
  @Input() addFlag: boolean;

  // to get update flag value from parent page
  @Input() addCertifyFlag: boolean;

  // to get update flag value from parent page
  @Input() isValidPQSS: boolean;

  // to get update flag value from parent page
  @Input() isValidCarFax: boolean;

  // to get update flag value from parent page
  @Input() isValidVin: boolean;

  @Input() isMandatoryForCertify: boolean;

  displayMsgForRetailDate: boolean = false;

  displayMsgForVin: boolean = false;

  displayMsgForComments: boolean = false;

  @Input() myDatePickerOptions: IMyDpOptions;

  showPriceWarning: boolean = false;

  @Input() displayInvalidVin: boolean;

  @Input() displayInvalidDealerCode: boolean;

  @Input() showAdditionalDriver: boolean = false;

  @Input() isEmailMandatory: boolean = false;

  @Input() isCoEmailMandatory: boolean = false;

  @Input() isIndividualCustomer: boolean = false;

  @Input() actionMode: string = 'false';

  zipCode: string;

  isValid: boolean = false;

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,3}$';

  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  @Input() reversalFlag: boolean = false;

  blockSectionStyle: string;

  unblockSectionStyle: string;

  blockDropdownStyle: string;

  unblockDropdownStyle: string;

  sourceItems: Array<any> =
    [
      { name: 'INSPECTION', value: 'INSPECTION', disabled: false },
      { name: 'VEHICLE HISTORY REPORT', value: 'VEHICLE HISTORY REPORT', disabled: false },
      { name: 'TMNA BUYBACK', value: 'TMNA BUYBACK', disabled: false },
      { name: 'OTHER', value: 'OTHER', disabled: false }
    ];

  reasonItems: Array<any> =
    [
      { name: 'BUYBACK', value: 'BUYBACK', disabled: false },
      { name: 'EXCESSIVE DAMAGE', value: 'EXCESSIVE DAMAGE', disabled: false },
      { name: 'FIRE', value: 'FIRE', disabled: false },
      { name: 'FLOOD', value: 'FLOOD', disabled: false },
      { name: 'STRUCTURAL DAMAGE', value: 'STRUCTURAL DAMAGE', disabled: false },
      { name: 'HAIL', value: 'HAIL', disabled: false },
      { name: 'MECHANICAL', value: 'MECHANICAL', disabled: false },
      { name: 'MILEAGE DISCREPANCY', value: 'MILEAGE DISCREPANCY', disabled: false },
      { name: 'SAFETY', value: 'SAFETY', disabled: true },
      { name: 'OIL GELLING', value: 'OIL GELLING', disabled: true },
      { name: 'VEHICLE HISTORY REPORT', value: 'VEHICLE HISTORY REPORT', disabled: true },
      { name: 'OTHER', value: 'OTHER', disabled: true },
    ];

  toyotaRegionItems: Array<any> =
    [
      { name: 'BOS', value: 'BOS', disabled: false },
      { name: 'CATD', value: 'CATD', disabled: false },
      { name: 'CHI', value: 'CHI', disabled: true },
      { name: 'CINC', value: 'CINC', disabled: true },
      { name: 'DNV', value: 'DNV', disabled: true },
      { name: 'KC', value: 'KC', disabled: true },
      { name: 'LA', value: 'LA', disabled: true },
      { name: 'NY', value: 'NY', disabled: true },
      { name: 'PTL', value: 'PTL', disabled: true },
      { name: 'SF', value: 'SF', disabled: true },
      { name: 'TMS', value: 'TMS', disabled: true },
      { name: 'OTHER', value: 'OTHER', disabled: true },
    ];

  lexusRegionItems: Array<any> = [
    { name: 'EAST', value: 'EAST', disabled: false },
    { name: 'WEST', value: 'WEST', disabled: false },
    { name: 'CNTRL', value: 'CNTRL', disabled: false },
    { name: 'SOUTH', value: 'SOUTH', disabled: false },
  ];
  /***
 * To set the obtained VehicleDetailView object to _vehicleDetails
 */
  @Input('vehicleDetails')
  set vehicleDetails(value: viewModels.VehicleDecertificationView) {
    // this._vehicleDetails = (<any>Object).assign({}, value);
    if (value !== null && value !== undefined &&
      value.vehicleData !== null && value.vehicleData !== undefined) {

      // if (!isNil(value)) {
      if (!isNil(value) && isNil(value.vehicleData.VIN_NUMBER)) {
        value.vehicleData.VIN_NUMBER = '';
      }

      // if(isNil(this._vehicleDetails.vehicleData.inEligibleFlag)){
      //     this._vehicleDetails.vehicleData.inEligibleFlag='Y';
      // }
      this.isValid = true;

      this._vehicleDetails = cloneDeep(value);

      if (isEmpty(value.vehicleData.VIN_NUMBER)) {
        this.isErrorMessageDisplay = false;
        this.isErrorMessageDisplayForCo = false;
        this.form.resetForm();
      }

      if (isNil(this.inEligibleFlag)) {
        this.inEligibleFlag = this.convertStringToBoolean(value.vehicleData.InEligibleFlag);
      }

      if (value.vehicleData.InEligibleFlag === 'Y') {
        this.blockSectionStyle = 'bgDisabled';
        this.unblockSectionStyle = 'bgEnabled';
        this.blockDropdownStyle = 'dropdownDisabled';
        this.unblockDropdownStyle = 'dropdownEnabled';
      } else if (value.vehicleData.InEligibleFlag === 'N') {
        this.blockSectionStyle = 'bgEnabled';
        this.unblockSectionStyle = 'bgDisabled';
        this.blockDropdownStyle = 'dropdownEnabled';
        this.unblockDropdownStyle = 'dropdownDisabled';
      } else {
        this.blockSectionStyle = 'bgEnabled';
        this.unblockSectionStyle = 'bgEnabled';
        this.blockDropdownStyle = 'dropdownEnabled';
        this.unblockDropdownStyle = 'dropdownEnabled';
      }
    }

  }

  @Input() makeCode: string;

  @Input() certifiedFlag: string;

  @Input() forcecertifiedFlag: string;

  @Input() isForceCertified: boolean;

  // Event emitter to naviagete to new vehicle detail event
  @Output() navigateToNewVehicleDetailEvent = new EventEmitter<any>();

  // Event emitter to naviagete to rdr summary event
  @Output() navigateToRDRSummaryEvent = new EventEmitter<any>();

  // Event emitter to naviagete to get vehicle details event
  @Output() getVehicleDetailsEvent = new EventEmitter<any>();

  // Event emitter to naviagete to get details details event
  @Output() getDealerEvent = new EventEmitter<any>();

  // Event emitter to save vehicle
  @Output() saveVehicleEvent = new EventEmitter<any>();

  @Input() inEligibleFlag: boolean;


  /***
* To get the obtained VehicleDetailView object to _vehicleDetails
*/
  get vehicleDetails() {
    return this._vehicleDetails;
  }

  @Input() saveButtonText: string;

  @Input() updateButtonText: string;

  @Input() warrantExpDateText: string;

  @Input() isCertify: boolean;

  acquiredMethod: string;

  columns2 = [
    { prop: 'code', name: 'Code' },
    { prop: 'name', name: 'Name' }
  ];

  public rows2: Array<models.AccessoryDetails> = [];

  // To set and get the obtained VehicleDetailView object from reducer using this variable
  public _vehicleDetails: viewModels.VehicleDecertificationView
    = <viewModels.VehicleDecertificationView>new Object();

  public validationTypes: typeof ValidationTypes = ValidationTypes;

  /**
   * Constructor method to create new instance of VehicleDetailComponent
   */
  constructor(private viewContainerRef: ViewContainerRef,
    public router: Router, private store: Store<fromRoot.AppState>, private el: ElementRef,
    public sharedDataService: SharedDataService) {
    // To initialize _vehicleDetails and selectedVinIndex
    this._vehicleDetails = <viewModels.VehicleDecertificationView>new Object();

  }


  /**
   * Init method to initialize state of VehicleDetailDetailComponent
   */
  ngOnInit() {
  }

  valueChanged(event: any) {
    this.sharedDataService.isPageEdited = true;
  }

  /**
   * Method to emit the delete disclaimer event emitter
   */
  navigateToNewVehicleDetail(event: any) {
    this.navigateToNewVehicleDetailEvent.emit(event);
  }

  /**
   * Method to emit the delete disclaimer event emitter
   */
  navigateToRDRSumamry(event: any) {
    this.navigateToRDRSummaryEvent.emit(event);
  }

  getVehicleDetails(event: any) {
    if (!isEmpty(event)) {
      this.displayMsgForVin = false;
      this.isErrorMessageDisplay = false;
      if (event.length >= 17) {
        event = event.toUpperCase().trim();
        this.getVehicleDetailsEvent.emit(event);
      } else {
        this.displayMsgForVin = true;
      }
    }
  }

  checkPriceWarning(event: any) {
    this.showPriceWarning = false;
    this.sharedDataService.isPageEdited = true;
    if (event.keyCode >= 65 && event.keyCode <= 90) {
      this.sharedDataService.isPageEdited = true;
    } else if (event.keyCode >= 48 && event.keyCode <= 57) {
      this.sharedDataService.isPageEdited = true;
    }

  }

  saveRDR(event: any) {
    this.form.form.markAsPristine();
    this.isErrorMessageDisplay = true;
    this.displayMsgForComments = false;
    // this.isMandatoryForCertify = true;
    this.displayMsgForVin = false;
    if (this.form.valid) {
      this.saveVehicleEvent.emit({
        vehicleData: event,
        isEmailMandatory: this.isEmailMandatory,
        isCoEmailMandatory: this.isCoEmailMandatory,
        additionalDriver: this.showAdditionalDriver,
        certificationFlag: 'true',
        registrationFlag: 'false',
        forceCertificationFlag: 'true',
        deCertifyFlag: 'false',
        isIndividualCustomer: this.isIndividualCustomer
      });
    } else if (event.rdrData.vin.length < 17) {
      this.displayMsgForVin = true;
    }
  }



  canShowErrorMessage(fieldName: string) {
    if (fieldName === 'retailDate') {
      this.displayMsgForRetailDate = true;
    }
  }



  pageEdited(event: any) {
    if (event.keyCode >= 65 && event.keyCode <= 90) {
      this.sharedDataService.isPageEdited = true;
    } else if (event.keyCode >= 48 && event.keyCode <= 57) {
      this.sharedDataService.isPageEdited = true;
    }
  }

  yearCheck(year: number): boolean {
    let currDate = new Date();
    let currYear = currDate.getFullYear();
    return currYear - year < 7 ? true : false;
  }

  emailOptions(event: any) {
    // this.isErrorMessageDisplay=false;
    this.isEmailMandatory = event.checked;
  }

  coEmailOptions(event: any) {
    // this.isErrorMessageDisplayForCo=false;
    this.isCoEmailMandatory = event.checked;
  }

  tologLength(event: any) {
    console.log(event.length);
  }

  convertStringToBoolean(value: string): boolean {
    if (value === 'Y') {
      return true;
    } else {
      return false;
    }
  }
}
