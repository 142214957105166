import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ViewConstants } from '../../shared/shared.constants';
/**
 * Class used as Pipe to transform Price into Currency Price
 * e.g : 30000.10 to $30,000.10
 */
@Pipe({ name: 'ucCurrencyPrice' })
export class UCCurrencyPricePipe implements PipeTransform {
    constructor(
        private currencyPipe: CurrencyPipe
    ){}
    
    transform(value: string, isDollorSymbolRequired: boolean): string {
        if (!value) return value;
        let formattedPrice = this.currencyPipe.transform(value.toString()
            .replace(new RegExp(ViewConstants.USD_SYMBOL, 'g'), ViewConstants.EMPTY_STRING)
            .replace(new RegExp(ViewConstants.COMMA, 'g'), ViewConstants.EMPTY_STRING),
            ViewConstants.USD, true, '1.2');
        if (!isDollorSymbolRequired) {
            formattedPrice = formattedPrice.replace(ViewConstants.USD_SYMBOL,
                ViewConstants.EMPTY_STRING); // Remove $ symbol
        }
        return formattedPrice;
    }
}



