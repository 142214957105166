import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// Region No
      key: ViewConstants.DEALER_REGION_STRING_COLUMN, fieldName: 'areaRegionNo',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS_WITH_NOTEQUAL
    },
    {// DEALER NO
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS_WITH_NOTEQUAL
    },
    {// dealer name
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: '',
      hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '250', columnMinWidth: '250',
      columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS_WITH_NOTEQUAL
    },
    {// Count
      key: ViewConstants.DEALER_COUNT_STRING_COLUMN, fieldName: 'count',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS_WITH_NOTEQUAL
    }
  ];
}
