'use strict';
import * as Models from './models';

export interface UCAccountingSummary {
  // Dealer Code that the vehicle is allocated to
  dealerCd?: string;
  // sale date
  retailDate?: string;
  saleDate?: string;
  // rdr date
  rdrDate?: string;
  // sale mileage
  saleMileage?: string;
  // sale Code
  saleCode?: string;
  // RDR status
  rdrStatus?: string;
  // certification status
  certificationStatus?: string;
  // customer names
  customerName?: string;
  // Vehicle Identification Number
  vin?: string;
  // selected
  selected?: boolean;
  // rdrDetail
  rdrDetail?: Models.RdrDetail;
  // makeCode
  makeCode?: string;
}
