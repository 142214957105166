import { NgModule } from '@angular/core';
import { UCSalesReportGoldSilverwRoutingModule }
  from './daily-sales-gold-silver-report-routing.module';
import { UCDailySalesGoldSilverPageReportComponent }
  from './daily-sales-gold-silver-report-page';
import { UCSalesReportGoldSilverGridComponent } from './daily-sales-gold-silver-report-grid';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    UCSalesReportGoldSilverwRoutingModule
  ],
  exports: [
    UCDailySalesGoldSilverPageReportComponent
  ],
  declarations: [
    UCDailySalesGoldSilverPageReportComponent,
    UCSalesReportGoldSilverGridComponent
  ]
})

export class UCDailySalesGoldSilverReportModule {
}


