import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// areaRegionNo
      key: ViewConstants.DEALER_REGION_NUM_COLUMN, fieldName: 'areaRegionNo', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.DEALER_DISTRICT_COLUMN, fieldName: 'districtNo',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }, {// dealer #
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// dealerName
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// salePersonId
      key: ViewConstants.CONSULTANT_NAME, fieldName: 'salePersonId',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '185', columnMinWidth: '185',
      columnMaxWidth: '185', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS_WITH_NOTEQUAL
    },
    {// salePersonUID
      key: ViewConstants.UID, fieldName: 'salePersonUID',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// VIN
      key: ViewConstants.VIN, fieldName: 'vin',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '180', columnMinWidth: '180',
      columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// STATUS
      key: ViewConstants.STATUS, fieldName: 'status',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// certificationNumber
      key: ViewConstants.CERTIFICATION_NUMBER, fieldName: 'certificationNumber', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '180',
      columnMinWidth: '180', columnMaxWidth: '180', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// saleDate
      key: ViewConstants.SALE_DATE, fieldName: 'saleDate', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// rdrDate
      key: ViewConstants.RDR_DATE, fieldName: 'rdrDate',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// revDate
      key: ViewConstants.REVERSAL_DATE, fieldName: 'revDate',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '120', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }
  ];

  static totalGridColumns = [
    {// dealer name
      key: ViewConstants.REGION_OR_CONSULTANT, fieldName: 'display', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '250', columnMinWidth: '250',
      columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// rdr
      key: ViewConstants.RDR, fieldName: 'H', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// rev
      key: ViewConstants.REV, fieldName: 'V', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// rev
      key: ViewConstants.TOTAL, fieldName: 'total', style: '',
      hasTemplate: 'false',
      sortable: 'true', template: '', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }
  ];
}
