import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCLexusDealerParticipationReportComponent }
  from './lexus-dealer-participation-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
  {
    path: 'lexusDealerParticipationReport',
    component: UCLexusDealerParticipationReportComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'lexusDealerParticipationReport/:type',
    component: UCLexusDealerParticipationReportComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(inventorySummaryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCLexusDealerParticipationReportRoutingModule {

}
