

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as viewModels from '../services/view-model/view-models';
import * as detailActions from '../actions/vehicle-detail.action';
import * as models from '../services/model/models';
import { isNil, cloneDeep } from 'lodash';
import { SharedFunctionService } from '../services/common/shared-function.service';
import { ScreenMessages } from '../shared/master-data/messages';
import { ViewConstants } from '../shared/shared.constants';
/**
 * Interface used to maintain the state for the vehicle detail screen
 */
export interface VehicleDetailsState {
  vehicleDetail: viewModels.VehicleDetailView;
}

/**
 * Variable used to store the initial state of VehicleDetailsState
 */
const initialState: VehicleDetailsState = {
  vehicleDetail: null
};

/**
 * Method used to set the default initial state of VehicleDetailsState
 */
function setDefaulValue(): viewModels.VehicleDetailView {
  let vehicleDetails: viewModels.VehicleDetailView =
    <viewModels.VehicleDetailView>new Object();
  vehicleDetails.vehicleData = {};
  vehicleDetails.message = null;
  vehicleDetails.pageType = '';
  return vehicleDetails;
}

/**
 * Reducer method to handle the vehicle detail screen actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): VehicleDetailsState {
  switch (action.type) {
    case detailActions.actionTypes.INITIAL_LOAD: {
      // return the Vehicle Detail data
      // return { vehicleDetail: setDefaulValue() };
      return state;
    }
    case detailActions.actionTypes.INITIAL_LOAD_SUCCESS: {
      // return the Vehicle Detail data
      let vehicleData: models.Vehicle = cloneDeep(action.payload);
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload)
      });
      vehicleDetailData.pageType = ViewConstants.UPDATE;
      vehicleDetailData.message = null;
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.INITIAL_LOAD_FAIL: {
      if (!isNil(action.payload.vehicleData)) {
        let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
          vehicleData: { vin: action.payload.vehicleData.vin },
          pageType: ViewConstants.NO_VIN_VEH_DETAILS,
          message: null
        });
        return { vehicleDetail: vehicleDetailData };
      } else if (action.payload === ScreenMessages.SERVICE_FAILURE_MESSAGE ||
        action.payload === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
        let message: models.Message = {};
        let errorResponse = cloneDeep(action.payload);
        message.message = errorResponse;
        message.type = models.MessageType.DANGER;
        let vehicleDetailData: viewModels.VehicleDetailView = setDefaulValue();
        vehicleDetailData.message = message;
        vehicleDetailData.vehicleData = null;
        return { vehicleDetail: vehicleDetailData };
      }

    }
    case detailActions.actionTypes.LOAD_VIN_INFORMATION_SUCCESS: {
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: formatDataforDetailsView(cloneDeep(action.payload)),
        pageType: ViewConstants.ADD,
        message: null
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.LOAD_VIN_INFORMATION_FAIL: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      if (errorResponse.errorCode === 'BAD_REQUEST' || errorResponse.statusCode >=500) {
        message.message = ScreenMessages.INTERNAL_SERVER_ERROR;
      } else if (errorResponse === ScreenMessages.SERVICE_FAILURE_MESSAGE ||
        errorResponse === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
        message.message = errorResponse;
      } else {
        message.message = ScreenMessages.INVALID_VIN_NO;
      }
      message.type = models.MessageType.DANGER;
      let vehicleDetailData: viewModels.VehicleDetailView = setDefaulValue();
      vehicleDetailData.message = message;
      vehicleDetailData.vehicleData = null;
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.ADD_VEHICLE_SUCESS_ACTION: {
      let message: models.Message = {};
      if (action.payload.makeCode === 'TOYOTA')
        message.message = ScreenMessages.ADD_NEW_VEHICLE_SUCCESS;
      else if ((action.payload.makeCode === 'LEXUS' ||
        action.payload.makeCode === 'LEX') &&
        action.payload.certificationFlag === 'false')
        message.message = ScreenMessages.ADD_NEW_VEHICLE_SUCCESS_LEXUS;
      else if ((action.payload.makeCode === 'LEXUS' ||
        action.payload.makeCode === 'LEX') &&
        action.payload.certificationFlag === 'true'
        && !isNil(action.payload.isTradedVehicle) &&
        action.payload.isTradedVehicle === 'true')
        message.message = ScreenMessages.ADD_NEW_VEHICLE_TRADE_SUCCESS_LEXUS;
      else
        message.message = ScreenMessages.ADD_NEW_VEHICLE_CERTIFY_SUCCESS_LEXUS;

      message.type = models.MessageType.SUCCESS;

      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload),
        pageType: ViewConstants.ADDED,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.ADD_VEHICLE_FAIL_ACTION: {
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      let pageType;
      let tempVehicleData = cloneDeep(state.vehicleDetail);
      if (errorResponse.errorCode === 'CERTIFICATION_VALIDATION_FAILED') {
        if ((state.vehicleDetail.vehicleData.makeCode === 'LEXUS' ||
          state.vehicleDetail.vehicleData.makeCode === 'LEX') &&
          (isNil(action.payload.isTradedVehicle) ||
            action.payload.isTradedVehicle === 'false')) {
          message.message = 'Vehicle has been added but certification failed. '
            + cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle cannot be added. ' +
            cloneDeep(action.payload.errorDesc);
        }
        pageType = errorResponse.errorCode;
        if (!isNil(action.payload.isTradedVehicle)) {
          tempVehicleData.vehicleData.isTradedVehicle =
            action.payload.isTradedVehicle;
        }
        tempVehicleData =
          clearCertification(tempVehicleData, 'F', action.payload.deCertifyReason);
      } else {
        if (!isNil(action.payload.errorDesc)) {
          message.message = 'Vehicle cannot be added. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle cannot be added. Internal Server Error.';
        }
        pageType = ViewConstants.FAIL;
      }
      message.type = models.MessageType.DANGER;
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.UPDATE_VEHICLE_SUCESS_ACTION: {
      let message: models.Message = {};
      let tempVehicleData = cloneDeep(state.vehicleDetail);
      tempVehicleData.vehicleData = cloneDeep(action.payload);
      let pageType;
      if (isNil(tempVehicleData.vehicleData.code) &&
        isNil(tempVehicleData.vehicleData.desc)) {
        message.message = ScreenMessages.UPDATE_VEHICLE_SUCCESS;
        message.type = models.MessageType.SUCCESS;
        pageType = ViewConstants.UPDATED;
      } else {
        if (tempVehicleData.vehicleData.code === 'CERTIFICATION-DELETED') {
          message.type = models.MessageType.DANGER;
          message.message = 'Vehicle data updated but certification deleted. '
            + tempVehicleData.vehicleData.desc + '.';
          tempVehicleData = clearCertification(
            tempVehicleData, 'Z', tempVehicleData.vehicleData.deCertifyReason
          );
        }
        pageType = tempVehicleData.vehicleData.code;
      }
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.UPDATE_VEHICLE_FAIL_ACTION: {
      // return the Vehicle Detail data
      let message: models.Message = {};
      let vehicleDetailObj: models.Vehicle;
      let pageType;
      let errorResponse = cloneDeep(action.payload);
      message.type = models.MessageType.DANGER;

      let tempVehicleData = cloneDeep(state.vehicleDetail);

      if (errorResponse.errorCode === 'CERTIFICATION-DELETED') {
        message.message = 'Vehicle data updated but certification deleted. ' +
          cloneDeep(action.payload.errorDesc);
        pageType = errorResponse.errorCode;
        tempVehicleData =
          clearCertification(tempVehicleData, 'Z', action.payload.deCertifyReason);
      } else if (errorResponse.errorCode === 'CERTIFICATION_VALIDATION_FAILED') {
        if ((state.vehicleDetail.vehicleData.makeCode === 'LEXUS' ||
          state.vehicleDetail.vehicleData.makeCode === 'LEX')) {
          message.message =
            'Vehicle has been updated successfully. But certification failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle cannot be updated. ' +
            cloneDeep(action.payload.errorDesc);
        }
        pageType = errorResponse.errorCode;
        tempVehicleData =
          clearCertification(tempVehicleData, 'F', action.payload.deCertifyReason);
      } else {
        if (!isNil(action.payload.errorDesc)) {
          message.message =
            'Vehicle update failed. ' + cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle update failed. Internal Server Error.';
        }

        pageType = ViewConstants.UPDATE_FAIL;
      }

      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.UPDATE_CERTIFICATE_SUCESS_ACTION: {
      let message: models.Message = {};
      // let vehicleData: models.Vehicle = cloneDeep(state.vehicleDetail);
      message.message = ScreenMessages.UPDATE_VEHICLE_CERTIFICATION_SUCCESS;
      message.type = models.MessageType.SUCCESS;
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload),
        pageType: ViewConstants.CERTIFIED,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.UPDATE_CERTIFICATE_FAIL_ACTION: {
      let message: models.Message = {};
      let vehicleDetailObj: models.Vehicle;
      let pageType;
      let errorResponse = cloneDeep(action.payload);

      message.type = models.MessageType.DANGER;
      let tempVehicleData = cloneDeep(state.vehicleDetail);

      if (errorResponse.errorCode === 'CERTIFICATION-DELETED') {
        message.message = 'Vehicle data updated but certification deleted. ' +
          cloneDeep(action.payload.errorDesc);
        pageType = errorResponse.errorCode;
        tempVehicleData =
          clearCertification(tempVehicleData, 'Z', action.payload.deCertifyReason);
      } else if (errorResponse.errorCode === 'CERTIFICATION_VALIDATION_FAILED') {
        if ((state.vehicleDetail.vehicleData.makeCode === 'LEXUS' ||
          state.vehicleDetail.vehicleData.makeCode === 'LEX')) {
          message.message =
            'Vehicle has been updated successfully. But certification failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle cannot be updated. ' +
            cloneDeep(action.payload.errorDesc);
        }
        pageType = errorResponse.errorCode;
        tempVehicleData = clearCertification(
          tempVehicleData, 'F', action.payload.deCertifyReason
        );
      } else {
        if (!isNil(action.payload.errorDesc)) {
          message.message = 'Vehicle update failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle update failed. Internal Server Error.';
        }
        pageType = ViewConstants.CERTIFIED_FAIL;
      }

      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });

      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.DELETE_VEHICLE_SUCESS_ACTION: {
      let message: models.Message = {};
      message.type = models.MessageType.SUCCESS;
      message.message = cloneDeep(action.payload);
      let vehicleData: models.Vehicle = cloneDeep(state.vehicleDetail);

      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        message: message,
        pageType: ViewConstants.DELETED
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.DELETE_VEHICLE_FAIL_ACTION: {
      // return the Vehicle Detail data
      let message: models.Message = {};
      let vehicleDetailObj: models.Vehicle;
      let pageType;
      let errorResponse = cloneDeep(action.payload);
      let tempVehicleData = cloneDeep(state.vehicleDetail);
      message.type = models.MessageType.DANGER;

      if (!isNil(action.payload.errorDesc)) {
        message.message = 'Vehicle delete failed. ' +
          cloneDeep(action.payload.errorDesc);
      } else {
        message.message = 'Vehicle delete failed. Internal Server Error.';
      }

      pageType = ViewConstants.DELETE_FAIL;
      // vehicleDetailData.vehicleData.message = message;
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: null,
        pageType: pageType,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.DECERTIFY_VEHICLE_SUCESS_ACTION: {
      let message: models.Message = {};

      let tempVehicleData = cloneDeep(state.vehicleDetail);
      tempVehicleData.vehicleData = cloneDeep(action.payload);

      tempVehicleData = clearCertification(
        tempVehicleData, 'Z', tempVehicleData.vehicleData.deCertifyReason
      );
      message.message = ScreenMessages.DECERTIFY_VEHICLE_SUCCESS;
      message.type = models.MessageType.SUCCESS;
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(tempVehicleData.vehicleData),
        pageType: ViewConstants.DECERTIFIED,
        message: message
      });

      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.DECERTIFY_VEHICLE_FAIL_ACTION: {
      let message: models.Message = {};
      let vehicleDetailObj: models.Vehicle;
      let pageType;
      let errorResponse = cloneDeep(action.payload);


      message.type = models.MessageType.DANGER;
      let tempVehicleData = cloneDeep(state.vehicleDetail);

      if (errorResponse.errorCode === 'CERTIFICATION-DELETED') {
        message.message = 'Vehicle data updated but certification deleted. ' +
          cloneDeep(action.payload.errorDesc);
        pageType = errorResponse.errorCode;
        tempVehicleData = clearCertification(
          tempVehicleData, 'Z', action.payload.deCertifyReason
        );
      } else if (errorResponse.errorCode === 'CERTIFICATION_VALIDATION_FAILED') {
        if ((state.vehicleDetail.vehicleData.makeCode === 'LEXUS' ||
          state.vehicleDetail.vehicleData.makeCode === 'LEX')) {
          message.message =
            'Vehicle has been updated successfully. But certification failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle cannot be updated. ' +
            cloneDeep(action.payload.errorDesc);
        }
        pageType = errorResponse.errorCode;
        tempVehicleData = clearCertification(
          tempVehicleData, 'F', action.payload.deCertifyReason
        );
      } else {
        if (!isNil(action.payload.errorDesc)) {
          message.message =
            'Vehicle update failed. ' + cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle update failed. Internal Server Error.';
        }
        pageType = ViewConstants.UPDATE_FAIL;
      }
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.RESTORE_VEHICLE_DATA: {
      // return the Vehicle Detail data
      let vehicleData: models.Vehicle = cloneDeep(action.payload);
      vehicleData.updatedBy = '';
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload),
        message: null,
        pageType: null
      });
      return { vehicleDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.LOAD_VEHICLE_FOR_CERTIFY_SUCESS_ACTION: {
      // return the Vehicle Detail data
      let vehicleData: models.Rdr = cloneDeep(action.payload);
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload)
      });
      vehicleDetailData.pageType = ViewConstants.UPDATE;
      vehicleDetailData.message = null;
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.LOAD_VEHICLE_FOR_CERTIFY_FAIL_ACTION: {
      if (!isNil(action.payload.vehicleData)) {
        let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
          vehicleData: { vin: action.payload.vehicleData.vin },
          pageType: ViewConstants.NO_VIN_VEH_DETAILS,
          message: null
        });
        return { vehicleDetail: vehicleDetailData };
      }

    }
    case detailActions.actionTypes.LOAD_VEHICLE_FROM_SALE_SUCESS_ACTION: {
      // return the Vehicle Detail data
      let vehicleData: models.Rdr = cloneDeep(action.payload);
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload)
      });
      vehicleDetailData.pageType = ViewConstants.UPDATE;
      vehicleDetailData.message = null;
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.LOAD_VEHICLE_FROM_SALE_FAIL_ACTION: {
      if (!isNil(action.payload.vehicleData)) {
        let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
          vehicleData: { vin: action.payload.vehicleData.vin },
          pageType: ViewConstants.NO_VIN_VEH_DETAILS,
          message: null
        });
        return { vehicleDetail: vehicleDetailData };
      }

    }

    case detailActions.actionTypes.UPDATE_CERTIFICATE_FROM_SALE_SUCESS_ACTION: {
      let message: models.Message = {};
      // let vehicleData: models.Vehicle = cloneDeep(state.vehicleDetail);
      message.message = ScreenMessages.UPDATE_VEHICLE_CERTIFICATION_SUCCESS;
      message.type = models.MessageType.SUCCESS;
      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(action.payload),
        pageType: ViewConstants.CERTIFIED,
        message: message
      });
      return { vehicleDetail: vehicleDetailData };
    }
    case detailActions.actionTypes.UPDATE_CERTIFICATE_FROM_SALE_FAIL_ACTION: {
      let message: models.Message = {};
      let vehicleDetailObj: models.Vehicle;
      let pageType;
      let errorResponse = cloneDeep(action.payload);

      message.type = models.MessageType.DANGER;
      let tempVehicleData = cloneDeep(state.vehicleDetail);

      if (errorResponse.errorCode === 'CERTIFICATION-DELETED') {
        message.message = 'Vehicle data updated but certification deleted. ' +
          cloneDeep(action.payload.errorDesc);
        pageType = errorResponse.errorCode;
        tempVehicleData = clearCertification(
          tempVehicleData, 'Z', action.payload.deCertifyReason
        );
      } else if (errorResponse.errorCode === 'CERTIFICATION_VALIDATION_FAILED') {
        if ((state.vehicleDetail.vehicleData.makeCode === 'LEXUS' ||
          state.vehicleDetail.vehicleData.makeCode === 'LEX')) {
          message.message =
            'Vehicle has been updated successfully. But certification failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle cannot be updated. ' +
            cloneDeep(action.payload.errorDesc);
        }
        pageType = errorResponse.errorCode;
        tempVehicleData = clearCertification(
          tempVehicleData, 'F', action.payload.deCertifyReason
        );
      } else {
        if (!isNil(action.payload.errorDesc)) {
          message.message = 'Vehicle update failed. ' +
            cloneDeep(action.payload.errorDesc);
        } else {
          message.message = 'Vehicle update failed. Internal Server Error.';
        }
        pageType = ViewConstants.CERTIFIED_FAIL;
      }

      let vehicleDetailData = (<any>Object).assign({}, state.vehicleDetail, {
        vehicleData: cloneDeep(tempVehicleData.vehicleData),
        pageType: pageType,
        message: message
      });

      return { vehicleDetail: vehicleDetailData };
    }

    case detailActions.actionTypes.CLEAR_ALL_ACTION: {
      return { vehicleDetail: setDefaulValue() };
    }
    case detailActions.actionTypes.GET_CURRENT_STATE_ACTION: {
      return state;
    }

    default:
      return state;
  }

}
/* tslint:enable:no-switch-case-fall-through */
/**
 * Method to get the state of VehicleDetailsState for vehicle detail screen
 */
export function getVehicleDetailsForSelectedVins(state: VehicleDetailsState) {
  return state.vehicleDetail;
}


function clearCertification(tempVehicleData: viewModels.VehicleDetailView,
  certificationStatus: string, deCertifyReason: string): viewModels.VehicleDetailView {

  if (certificationStatus === 'Z') {
    tempVehicleData.vehicleData.repairOrder = <models.RepairOrder>new Object();
    tempVehicleData.vehicleData.repairOrder.reconditionCost = null;
    tempVehicleData.vehicleData.repairOrder.repairOrderNumber1 = null;
    tempVehicleData.vehicleData.repairOrder.reconditionalEndDate = null;
    tempVehicleData.vehicleData.repairOrder.dateofFirstRepairOrder = null;
    tempVehicleData.vehicleData.repairOrder.repairOrderNumber2 = null;
    tempVehicleData.vehicleData.repairOrder.dateofSecondRepairOrder = null;
    tempVehicleData.vehicleData.inspection = <models.Inspection>new Object();
    tempVehicleData.vehicleData.inspection.inspectionApprovedDate = null;
    tempVehicleData.vehicleData.inspection.inspectionFormNumber = null;
  }
  tempVehicleData.vehicleData.certificationNumber = '';
  tempVehicleData.vehicleData.certificationStatus = certificationStatus;
  tempVehicleData.vehicleData.deCertifyReason = deCertifyReason;
  if (!isNil(tempVehicleData.vehicleData.certification)) {
    tempVehicleData.vehicleData.certification.certificationDate = '';
    tempVehicleData.vehicleData.certification.warrantyCode = '';
    tempVehicleData.vehicleData.certification.warrantyExpiryDate = '';
    tempVehicleData.vehicleData.certification.forceCertificationFlag = '';
  }

  return tempVehicleData;
}
/**
 * Method used to frame the required state for the vehicle detail screen
 */
function formatDataforDetailsView(data: models.Vehicle): viewModels.VehicleDetailView {
  let vehicleDetailData: any = setDefaulValue();
  if (data) {
    vehicleDetailData.vehicleData.vin = data.vin;
    vehicleDetailData.vehicleData.dealerCd = data.dealerCd;
    vehicleDetailData.vehicleData.model = data.model;
    vehicleDetailData.vehicleData.accessories = data.accessories;
    vehicleDetailData.vehicleData.extColor = data.extColor;
    vehicleDetailData.vehicleData.intColor = data.intColor;
    vehicleDetailData.vehicleData.dofu = data.dofu;
    vehicleDetailData.vehicleData.openCampaignStatus = data.openCampaignStatus;
    vehicleDetailData.vehicleData.carFaxFlag = data.carFaxFlag;
    vehicleDetailData.vehicleData.foreignVin = data.foreignVin;
    vehicleDetailData.vehicleData.InEligibleFlag = data.InEligibleFlag;
    vehicleDetailData.vehicleData.unblockComments = data.unblockComments;
    vehicleDetailData.vehicleData.sellArea = data.sellArea;
    vehicleDetailData.vehicleData.comments = null;
    vehicleDetailData.vehicleData.inspection = <models.Inspection>new Object();
    let inspectionobj = <models.Inspection>new Object();
    inspectionobj.inspectionFormNumber = null;
    inspectionobj.inspectionApprovedDate = null;
    vehicleDetailData.vehicleData.inspection = inspectionobj;
    vehicleDetailData.vehicleData.price = <models.Price>new Object();
    let priceObj = <models.Price>new Object();
    priceObj.acvPrice = '';
    priceObj.dealerTradeAmount = null;
    priceObj.retailAmount = null;
    vehicleDetailData.vehicleData.price = priceObj;
    vehicleDetailData.vehicleData.acquiredDate = null;
    vehicleDetailData.vehicleData.acquiredMethod = null;
    vehicleDetailData.vehicleData.repairOrder = <models.RepairOrder>new Object();
    vehicleDetailData.vehicleData.repairOrder.repairOrderNumber1 = null;
    vehicleDetailData.vehicleData.repairOrder.repairOrderNumber2 = null;
    vehicleDetailData.vehicleData.repairOrder.reconditionCost = null;
    vehicleDetailData.vehicleData.repairOrder.dateofFirstRepairOrder = null;
    vehicleDetailData.vehicleData.repairOrder.dateofSecondRepairOrder = null;
    vehicleDetailData.vehicleData.makeCode = data.makeCode;
  }
  return vehicleDetailData.vehicleData;
}

