import { Observable } from 'rxjs';
import { DialogComponent } from './dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable, ViewContainerRef } from '@angular/core';
import * as models from '../../../services/model/models';
/**
 * This class is used to show the Dialog component in the screen
 */
@Injectable()
export class DialogService {

    /**
     * constructor method to create new instance of Alert Dialog
     */
    constructor(public dialog: MatDialog) { }

    /**
     * Method to open the alert dialog in the page
     */
    public open(title: string, message: string, okText: string, cancelText: string,
        dialogType: string, viewContainerRef: ViewContainerRef,
        parentComponentInstance?: any, applyAction?: any,
        cancelAction?: any): Observable<boolean> {

        let dialogRef: MatDialogRef<DialogComponent>;
        let config = new MatDialogConfig();
        config.viewContainerRef = viewContainerRef;

        dialogRef = this.dialog.open(DialogComponent, config);

        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.okActionText = okText;
        // dialogRef.componentInstance.comments = comments;
        dialogRef.componentInstance.cancelActionText = cancelText;
        dialogRef.componentInstance.dialogType = dialogType;
        dialogRef.disableClose = true;

        if (applyAction && applyAction !== '') {
            dialogRef.componentInstance.okAction = applyAction;
        }
        if (cancelAction && cancelAction !== '') {
            dialogRef.componentInstance.cancelAction = cancelAction;
        }
        if (parentComponentInstance !== null && parentComponentInstance !== undefined &&
            parentComponentInstance !== '') {
            dialogRef.componentInstance.parent = parentComponentInstance;
        }
        return dialogRef.afterClosed();
    }
}
