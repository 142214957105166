/***
 * Import statements required for Inventory Detail Service
 * for invoking services required for populating
 * all sections in the Vehicle Inventory Detail Screen
 */
import { Injectable } from '@angular/core';
import {  Response, Headers, RequestOptionsArgs, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
// TODO: correctly add the JSON so that it can be read
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedFunctionService } from '../common/shared-function.service';
import { HttpClient } from '../../shared/components/helpers/http-client';
import { ViewConstants } from '../../shared/shared.constants';
import { DealerDetailsJSON } from '../../../mock-data/DealerDetailsJSON';
import { isNil } from 'lodash';
import * as models from '../model/models';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

/***
 * Class that is used to invoke the Services for populating
 * all the sections of Vehicle Inventory Detail screen
 */
@Injectable()
export class DealerDetailService {
    public defaultHeaders: Headers = new Headers();
    protected getpersonIdServiceURL = environment.GET_PERSON_ID_SERVICE_URL;

    /***
     * Constructor of Inventory Detail Service to inject the angluar http
     */
    constructor(
        private sharedFuncService: SharedFunctionService, protected httpClient: HttpClient,
        private logger: Logger) { }

    /***
     * This method is used to get the Vehicle Inventory Detail data
     * for the given VIN number
     */
    public getDealerDetails(dealerCode: string): Observable<any> {
        this.logger.log('Executing Get dealer details method');
        // const path =  ServiceURL.DISURL;
        let path = environment.DIS_URL;
        let queryParameters = new URLSearchParams();
        let headerParams = this.defaultHeaders;

        if (!dealerCode) {
            let msg = ViewConstants.REQUIRED_PARAMETER_IS_NULL_OR_UNDEFINED
                .replace('{ServiceName}', 'Get Dealer Detail')
                .replace('{ParameterName}', 'dealerCode');
            return this.sharedFuncService.handleError(msg);
        } else {
            // queryParameters.set('dealerCode', String(dealerCode));
            path = path + '/' + dealerCode;
        }

        let requestOptions: RequestOptionsArgs = {
            method: 'GET',
            headers: headerParams,
            search: queryParameters
        };

        // TODO: To be Removed
        this.logger.log('Get Dealer Details Service invoked - ' + path);
        return this.httpClient.get(path, requestOptions)
        .pipe(map((response:HttpResponse<any>) => {
                this.logger.log(response);
                this.logger.log('Dealer Details Service response received ' + response);
                if (response.status === 204) {
                    return undefined;
                } else {
                    let dealers = response.body;
                    // dealers = dealers.Items.filter(e => e.dlrNo === dealerCode);
                    return dealers;
                }
            }), catchError((err: any) => {
                return this.sharedFuncService.handleError(err);
            }));

    }

    public getAllDealers(): Observable<any> {
        this.logger.log('Executing Get All Dealers method');
        const path = environment.DIS_URL;
        let queryParameters = new URLSearchParams();
        let headerParams = this.defaultHeaders;
        // // queryParameters.set('brandId', String(brandId));
        // queryParameters.set('searchMode', String('filterOnly'));
        // queryParameters.set('resultsFormat', String('JSON'));
        // // queryParameters.set('appKey', String(appKey));
        // queryParameters.set('resultsMode', String('starOnly'));

        let requestOptions: RequestOptionsArgs = {
            method: 'GET',
            headers: headerParams,
            search: queryParameters
        };
        let MOCK_SERVICE_USED;
        MOCK_SERVICE_USED = false;
        if (MOCK_SERVICE_USED === true) {
            let tempData: any = {};
            tempData = DealerDetailsJSON.dealerServiceResponse;
            return new Observable<any>(observer => {
                observer.next(tempData);
                observer.complete();
            });

        } else {
            return this.httpClient.get(path, requestOptions)
            .pipe(map((response: any) => {
                    // Hiding loader
                    if (response.status === 204) {
                       return undefined;
                    } else {
                        return response.body;
                    }
                }), catchError((err: any) => {
                    return this.sharedFuncService.handleError(err);
                }));
        }
    }
}
