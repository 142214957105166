import { CurrencyPipe } from '@angular/common';
import { MsalService } from '@azure/msal-angular';
import { AccountingDetailsService } from './services/api/accounting-detail.service';
import { UCAccountingSummaryService } from './services/api/accounting-summary.service';
import { DealerDetailService } from './services/api/dealer-detail.service';
import { DealerParticipationbService } from './services/api/dealer-participation.service';
import { FeatureConfigDetailService } from './services/api/fearure-config.service';
import { UCInventorySummaryService } from './services/api/inventory-summary.service';
import { RdrDetailsService } from './services/api/rdr-detail.service';
import { UCRdrSummaryService } from './services/api/rdr-summary.service';
import { RoleDetailService } from './services/api/role-detail.service';
import { SaleDetailsService } from './services/api/sale-detail.service';
import { UCSaleSummaryService } from './services/api/sale-summary.service';
import { UserService } from './services/api/user-detail.service';
import { VehicleDecertificationService } from './services/api/vehicle-decertification.service';
import { VehicleDetailsService } from './services/api/vehicle-detail.service';
import { VehicleHistoryService } from './services/api/vehicle-history.service';
import { VehicleObjectiveService } from './services/api/vehicle-objective.service';
import { UCInventoryReportService } from './services/api/vehicle-report.service';
import { AuthenticationGuard } from './services/common/auth-guard';
import { SharedDataService } from './services/common/shared-data.service';
import { SharedFunctionService } from './services/common/shared-function.service';
import { StaffMasterDetailService } from './services/api/staffMaster.service';

export const APP_PROVIDERS = [
  SharedDataService,
  SharedFunctionService,
  UCInventorySummaryService,
  UCAccountingSummaryService,
  DealerDetailService,
  FeatureConfigDetailService,
  StaffMasterDetailService,
  VehicleDetailsService,
  AccountingDetailsService,
  RdrDetailsService,
  SaleDetailsService,
  UCRdrSummaryService,
  UCSaleSummaryService,
  VehicleDecertificationService,
  DealerParticipationbService,
  VehicleHistoryService,
  RoleDetailService,
  UCInventoryReportService,
  VehicleObjectiveService,
  UserService,
  MsalService,
  AuthenticationGuard,
  CurrencyPipe
];
