


import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import * as userActions from '../actions/user-detail.action';
import { UserService } from '../services/api/user-detail.service';
import {map, catchError, switchMap} from 'rxjs/operators'

@Injectable()
export class UserDetailEffects {

  @Effect()
  loadPersonIdData$: Observable<Action> = this.actions$
    .pipe(ofType(userActions.actionTypes.GET_PERSON_ID)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.userService.getUserId(data)
      .pipe(map((dealerDetails: any) => new
          userActions.GetPersonIdSuccessAction(dealerDetails))
          , catchError(error => of(new
          userActions.GetPersonIdFailureAction(error))))
    ));

  @Effect()
  getTokenForPersonIdData$: Observable<Action> = this.actions$
    .pipe(ofType(userActions.actionTypes.GET_TOKEN_FOR_PERSON)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.userService.getTokenForUserId(data)
      .pipe(map((dealerDetails: any) => new
          userActions.GetTokenforPersonIdSuccessAction(dealerDetails))
          , catchError(error => of(new
          userActions.GetTokenforPersonIdFailureAction(error))))
    ));

  constructor(private actions$: Actions,
    private userService: UserService) { }
}
