import { ActionBase } from './actionbase';
import { type } from '../shared/components/helpers/util';
import * as models from '../services/model/models';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

/**
 * Following action types are being used for Messages
 */
export const actionTypes = {
    SET_DISPLAY_MESSAGE: type('[DisplayMessageData] set display message'),
    CLEAR_ALL_DISPLAY_MESSAGE: type('[DisplayMessageData] clear the display message'),
    CLOSE_DISPLAY_MESSAGE: type('[DisplayMessageData] close the display message')
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/**
 * Class used to set the display Message
 */
export class SetDisplayMessageAction implements ActionBase {
  type = actionTypes.SET_DISPLAY_MESSAGE;

  constructor(public payload: models.Message) { }
}
/**
 * Class used to close the display Message
 */
export class CloseDisplayMessageAction implements ActionBase {
  type = actionTypes.CLOSE_DISPLAY_MESSAGE;

  constructor(public payload: models.Message) { }
}
/**
 * Class used to clear the display Message
 */
export class ClearAllDisplayMessageAction implements ActionBase {
  type = actionTypes.CLEAR_ALL_DISPLAY_MESSAGE;

  constructor(public payload: models.Message) { }
}


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type actions
= SetDisplayMessageAction |
  CloseDisplayMessageAction |
  ClearAllDisplayMessageAction;

