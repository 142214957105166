

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import * as accountingSummaryActions from '../actions/accounting-summary.action';
import { UCAccountingSummaryView } from '../services/view-model/accounting-summary-view';
import { Message, MessageType } from '../services/model/Message';
import { UCAccountingSummary } from '../services/model/AccountingSummary';
import { isNil, cloneDeep } from 'lodash';
import { SharedFunctionService } from '../services/common/shared-function.service';

/**
 * Interface used to maintain the state for the accounting summary screen
 */
export interface UCAccountingState {
  accountingData: UCAccountingSummaryView;

}



/**
 * Varibale used to store the initial state of UCAccountingState
 */
const initialState: UCAccountingState = {
  accountingData: null
};

/**
 * Method used to set the default initial state of UCAccountingState
 */
function setDefaulValue(): UCAccountingSummaryView {
  let accountingData: UCAccountingSummaryView = <UCAccountingSummaryView>new Object();
  accountingData.accounting = [];
  accountingData.message = null;
  return accountingData;
}

/**
 * Reducer method to handle the accounting summary screen actions
 */

export function reducer(state = initialState, action: ActionBase): UCAccountingState {
  switch (action.type) {
    case accountingSummaryActions.ACTION_TYPES.INITIAL_LOAD: {
      return { accountingData: setDefaulValue() };
    }
    case accountingSummaryActions.ACTION_TYPES.INITIAL_LOAD_SUCCESS: {
      let accountingData = (<any>Object).assign({}, state.accountingData,
        {
          accounting: cloneDeep(tempFrameAccountingDetails(action.payload))
        });
      return { accountingData: accountingData };
    }
    case accountingSummaryActions.ACTION_TYPES.INITIAL_LOAD_FAIL: {
      let message: Message = {};
      message.message = 'No vehicles available for selected dealer code.';
      message.type = MessageType.WARNING;
      let accountingData = (<any>Object).assign({}, state.accountingData,
        {
          message: message
        });
      return { accountingData: accountingData };
    }

    default: {
      return state;
    }
  }
}

/**
 * Method to get the state of VehicleInventoryState for inventory summary screen
 */
export function getVehiclesForInventory(state: UCAccountingState) {
  return state.accountingData;
}


// function frameInventoryDetails(inventories: UCInventorySummary[]): UCInventorySummary[] {
//     inventories = cloneDeep(inventories);
//     inventories.map(inventory => {
//         if (!isNil(inventory.accessoriesDetails) && inventory.accessoriesDetails !== undefined) {
//             inventory.accessories = '';
//             inventory.accessoriesDetails.map(acc => {
//                 if (!isNil(inventory.accessories) && inventory.accessories === '') {
//                     inventory.accessories += acc.accessoryCode.trim();
//                 } else {
//                     inventory.accessories += ' ' + acc.accessoryCode.trim();
//                 }
//             })
//         }
//     })
//     return inventories;
// }


function tempFrameAccountingDetails(accounting: any): UCAccountingSummary[] {
  let returnArray: UCAccountingSummary[] = [];
  accounting.map(inventory => {
    let obj: UCAccountingSummary = {};
    let accObj = inventory._source;
    obj.vin = accObj.vin;
    obj.dealerCd = accObj.dealerCd;
    obj.saleDate = convertDateDisplayForRetailDate(accObj.rdrDetail.retailDate);
    obj.rdrDate = convertDateDisplayForRetailDate(accObj.rdrDetail.createdAt);
    obj.saleMileage = accObj.rdrDetail.mileage;
    obj.saleCode = accObj.rdrDetail.saleType;
    obj.rdrStatus = accObj.rdrDetail.rdrStatus;
    obj.certificationStatus = accObj.certificationStatus;
    obj.makeCode = accObj.makeCode;
    // obj.customerName =accObj.rdrDetail.buyer.firstName +" "+ accObj.rdrDetail.buyer.lastName ;
    obj.rdrDetail = {};
    obj.rdrDetail.buyer = {};
    if (!isNil(accObj.rdrDetail.buyer) && !isNil(accObj.rdrDetail.buyer.firstName)) {
      obj.rdrDetail.buyer.firstName =
        accObj.rdrDetail.buyer.firstName + ' ' + accObj.rdrDetail.buyer.lastName;
    } else {
      obj.rdrDetail.buyer.firstName = accObj.rdrDetail.buyer.lastName;
    }
    returnArray.push(obj);
  });
  return returnArray;
}

function convertDateDisplayForRetailDate(fromdate: any) {
  if (!isNil(fromdate)) {
    let date = new Date(fromdate).toISOString().substring(0, 10);
    let year = date.substr(0, 4);
    let month: any = date.substr(5, 2);
    let dt: any = date.substr(8, 2);

    return month + '-' + dt + '-' + year;
  } else return fromdate;

}

