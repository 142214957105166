import { environment as env } from "src/environments/environment";

export enum CacheLocation {
  SESSION_STORAGE = 'sessionStorage',
  LOCAL_STORAGE = 'localStorage'
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const environment = {
  production: false,
  angularConfig: {
    popUp: !isIE,
    consentScopes: [
      'user.read',
      'openid',
      'profile'
    ],
  },
  msalConfig: {
    auth: {
      clientId: env.CLIENT_ID,
      authority: 'https://login.microsoftonline.com/' + env.TENENT_ID,
      postLogoutRedirectUri: 'https://login.microsoftonline.com/logout.srf',
      redirectUri: env.appURL
    },
    cache: {
      cacheLocation: CacheLocation.SESSION_STORAGE,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    }
  }
};
