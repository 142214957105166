import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCSameDayCertPageReportComponent }
  from './same-day-cert-report-page';
import { AuthenticationGuard } from '../../../services/common/auth-guard';

const inventorySummaryRoutes: Routes = [
  {
    path: 'sameDayCertReport',
    component: UCSameDayCertPageReportComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(inventorySummaryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCSameDayCertRoutingModule {

}


