<form name="rdrDetailForm" fxFlex="100" fxFlex.xs="100" fxLayout="column" autocomplete="off" #rdrDetailForm="ngForm">

  <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" style="height:15%">
    <!--<mat-card fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-vehicle-summary-display">-->
    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
      <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
        <mat-card fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
          class="dd-vehicle-summary-display">
          <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

            <div fxFlex="5" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

            </div>

            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="45" class="dd-veh-summary-header">
                  <span class="MandatorySymbol"> * </span>DEALER NUMBER
                </div>

                <div fxFlex="50" class="dd-veh-summary-value">
                  <mat-form-field>
                    <input matInput id="dealerno" name="dealerno" style="text-transform: uppercase;"
                      (blur)="getVehicleDetails($event.target.value)" maxlength="5" minlength="5"
                      [ddFieldValidator]="validationTypes.NUMBER" class="dd-inputValues" required
                      oninvalid="this.setCustomValidity('Please Enter valid vin')" oninput="setCustomValidity('')"
                      [(ngModel)]="_vehicleDetails.vehicleData.dealerCd" #dealerno="ngModel"
                      (keyup)="this.pageEdited($event);" />
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                      *ngIf="!dealerno.value && (dealerno.touched || this.isErrorMessageDisplay)"  align="start">Dealer
                      Number cannot be blank</mat-hint>
                  </mat-form-field>
                </div>
              </div>

            </div>

          </div>

          <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;">
            <button mat-button mat-raised-button (click)="findDealer();">FIND DEALER</button>
          </div>


        </mat-card>
      </div>
    </div>
  </div>

  <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" layout-md="column">
    <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center">
      <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="dd-veh-det-pad-left">
        <mat-card fxFlex="50" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column"
          class="dd-vehicle-summary-display">
          <mat-card-header>
            <mat-card-title style="font-weight:bold">DEALER PARTICIPANT</mat-card-title>
          </mat-card-header>
          <mat-card-content style="height:100%">
            <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">

              <!-- <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column"> -->

              <!-- <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row"> -->
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="35" class="dd-veh-summary-header">
                  <span class="MandatorySymbol"> * </span> PARTICIPATION (CERT) FLAG
                </div>
                <div fxFlex="55" class="dd-veh-summary-value">
                  <mat-checkbox name="participationDealerFlg"
                    [(ngModel)]="_vehicleDetails.vehicleData.certDlrBooleanFlag" #participationDealerFlg="ngModel"
                    style="padding-left:25px;font-size:12px">
                  </mat-checkbox>
                </div>
              </div>

              <!-- </div> -->
              <!--
                </div>

              </div> -->

              <!-- <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column"> -->

              <!-- <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row"> -->
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="35" class="dd-veh-summary-header">
                  <span class="MandatorySymbol"> * </span> FUEL CELL DEALER FLAG
                </div>
                <div fxFlex="55" class="dd-veh-summary-value">
                  <mat-checkbox name="fuelCellDealerFlg"
                    [(ngModel)]="_vehicleDetails.vehicleData.fuelCellDlrBooleanFlag" #fuelCellDealerFlg="ngModel"
                    style="padding-left:25px;font-size:12px">
                  </mat-checkbox>
                </div>
              </div>

              <!-- </div> -->

              <!-- </div>

              </div> -->

              <!-- <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column"> -->

              <!-- <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row"> -->
              <div fxFlex="100" style="padding-top: 0.7rem;" fxLayoutAlign="center center" fxLayout="row"
                fxLayout.xs="row" fxLayout.sm="row" class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="35" class="dd-veh-summary-header">
                  <span class="MandatorySymbol"> &nbsp;&nbsp; </span> LAST UPDATED DATE
                </div>
                <div fxFlex="4"></div>
                <div fxFlex="51" class="dd-veh-summary-value dd-input-veh-valueText">
                  {{_vehicleDetails?.vehicleData?.updatedAt | date :'MM-dd-yyyy'}}
                </div>
              </div>

              <!-- </div> -->
              <!--
                </div>

              </div> -->

              <!-- <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column"> -->

              <!-- <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row"> -->
              <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                class="dd-veh-summary-desc dd-veh-header-summary-desc">
                <div fxFlex="5"></div>
                <div fxFlex="35" class="dd-veh-summary-header">
                  <span class="MandatorySymbol"> * </span> COMMENTS
                </div>
                <div fxFlex="4"></div>
                <div fxFlex="51" class="dd-veh-summary-value">
                  <mat-form-field>
                    <textarea matInput name="comments" maxlength="25" class="text-area" #comments="ngModel"
                      [(ngModel)]="_vehicleDetails.vehicleData.comments" (keyup)="this.pageEdited($event);"> </textarea>
                    <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }" *ngIf="displayMsgForComments"
                       align="start">Comment cannot be blank</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <!-- </div> -->
              <!-- </div>

              </div> -->

              <!-- <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">

                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column"> -->

              <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                  class="dd-veh-summary-desc dd-veh-header-summary-desc">
                  <div fxFlex="5"></div>
                  <div fxFlex="35" class="dd-veh-summary-header">
                    <span class="MandatorySymbol"> * </span> BUY SELL
                  </div>
                  <div fxFlex="55" class="dd-veh-summary-value">
                    <mat-checkbox name="isBuySell" [(ngModel)]="_vehicleDetails.vehicleData.isBuySell"
                      #isBuySell="ngModel" style="padding-left:25px;font-size:12px">
                    </mat-checkbox>
                  </div>
                </div>

              </div>
              <!--
                </div>

              </div> -->

              <div *ngIf="isBuySell.value" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row">
                <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column">
                  <div fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="row" fxLayout.sm="row"
                    class="dd-veh-summary-desc dd-veh-header-summary-desc">
                    <div fxFlex="5"></div>
                    <div fxFlex="35" class="dd-veh-summary-header">
                      <span class="MandatorySymbol"> * </span>OLD DEALER NUMBER
                    </div>

                    <div fxFlex="25" class="dd-veh-summary-value" style="padding-left:2rem;">
                      <mat-form-field>
                        <input matInput id="olddealerno" name="olddealerno"
                          (blur)="getOldDealerDetails($event.target.value)" style="text-transform: uppercase;"
                          maxlength="5" minlength="5" [ddFieldValidator]="validationTypes.NUMBER" class="dd-inputValues"
                          required oninvalid="this.setCustomValidity('Please Enter valid vin')"
                          oninput="setCustomValidity('')" [(ngModel)]="oldDealerCd" #olddealerno="ngModel"
                          (keyup)="this.pageEdited($event);" />
                        <mat-hint [ngStyle]="{'color': 'red' ,'background': 'yellow' }"
                          *ngIf="!olddealerno.value && (olddealerno.touched || this.isErrorMessageDisplay)"
                           align="start">
                          Dealer Number cannot be blank</mat-hint>
                      </mat-form-field>
                    </div>

                    <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="30">
                      <button mat-button mat-raised-button (click)="findOldDealer();">FIND DEALER</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </mat-card-content>
        </mat-card>

      </div>
    </div>
  </div>

  <!--<mat-divider></mat-divider>-->

  <div fxLayout="row" fxLayoutAlign="end center" class="dd-bottom-container dlr-participant-flex-container" style="margin-top: 0.6%;width:51%">
      <button mat-button mat-raised-button class="dd-desription-save-button" type="submit"
        (click)="saveRDR(_vehicleDetails)">SAVE</button>
      <button mat-button mat-raised-button class="dd-desription-save-button" type="submit"
        (click)="reset()">RESET</button>
      <!--<button mat-button mat-raised-button class="dd-desription-save-button" type="submit" (click)="navigateToRDRSumamry(_rdrDetails)">CANCEL</button>-->
  </div>
</form>
