<div fxLayout="column">
  <region-selection-vin-search-toolbar [fromDate]="fromDate" [toDate]="toDate"
   fxFlex="100" [reportType]="reportType" [regionSelection]="regionSelection"
   [reportSelection]="reportSelection" [certificationSelection]="certificationSelection"
    (findDealerEvent)="findDealer()" (filterDateEvent)="displayData($event)" [enteredDealerCode]="enteredDealerCode">
  </region-selection-vin-search-toolbar>

  <uc-inventory-report-menubar-panel [isInventorySelected]="isInventorySelected"
    [selectedCertificationStatus]="selectedCertificationStatus" [selectedMakeCode]="selectedMakeCode"
    (clearAllButtonEvent)="clearAllFilters();" [showClearAllButton]="showClearAllButton"
    [totalInventoriesCount]="totalInventoriesCount" (exportPdfEvent)="downLoadPdf()"
    (exportExcelEvent)="downLoadXlsx()"></uc-inventory-report-menubar-panel>

  <uc-regional-model-line-rdr-report-grid [columnDefs]="columnDefs" [rows]="rows"
    (applySortEvent)="applyInventorySort($event)" (applyFilterEvent)="ApplyFilter($event)" [clearSort]="clearSort">
  </uc-regional-model-line-rdr-report-grid>
</div>
