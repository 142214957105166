'use strict';
// import * as models from './models';

// Model Details
export interface Model {
  // model code
  modelNumber?: string;
  // model year
  modelYear?: number;
  // model description
  modelDescription?: string;
  modelSeries?: string;
}
