<div fxFlex="100" fxLayout="row" class="dealer-vin-search layoutbglite">
    <!--<mat-card flex="row" flex="10">
        <div layout-align="space-around center" layout="row" flex="100" style="padding: 5px;">
            <button mat-button mat-raised-button (click)="findDealer();">FIND DEALER</button>
        </div>
    </mat-card>-->
    <mat-card fxFlex="25">
        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;">

            <mat-form-field style="width: 145px; height: 50px;">
                <input #dCodeTxtBox matInput [(ngModel)]="enteredDealerCode" [ddFieldValidator]="8" placeholder="Enter dealer code" align="end">
                <!--<mat-icon mdSuffix style="color: gray">search</mat-icon>-->
            </mat-form-field>

            <button [disabled]="dCodeTxtBox.value.length!==5" (click)="viewInventory();" mat-button mat-raised-button style="height: 38px">View Sales</button>
        </div>
    </mat-card>

    <!-- <mat-card fxFlex="25">
        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="100" style="padding: 5px;">

            <mat-form-field style="height: 50px; width: 173px">
                <input #vinTxtBox matInput style="text-transform: uppercase;" [(ngModel)]="enteredvin" placeholder="Enter VIN" align="end"
                    [ddFieldValidator]="23">
            </mat-form-field>

            <button [disabled]="vinTxtBox.value.length!==17" (click)="findVehicle();"  mat-button mat-raised-button>Sale inquiry</button>
        </div>
    </mat-card> -->
    
   <mat-card fxFlex="40">
        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="40" style="padding: 5px;">

            <my-date-picker class="dpWidth"  placeholder="Sale From Date" name="retailDate" id="retailDate" [options]="myDatePickerOptions" [locale]="'en'"
                [selDate]="fromDate | date :'MM-dd-yyyy'" (dateChanged)="dateFilter($event,'fromDate')"></my-date-picker>


        </div>

        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="40" style="padding: 5px;">

            <my-date-picker class="dpWidth"  placeholder="Sale To Date" name="retailDate" id="retailDate" [options]="myDatePickerOptions" [locale]="'en'" [selDate]="toDate | date :'MM-dd-yyyy'"
                (dateChanged)="dateFilter($event,'toDate')"></my-date-picker>


        </div>

        <div fxLayoutAlign="space-around center" fxLayout="row" fxFlex="20" style="padding: 5px;">
            <button [disabled]="!isDateSelected" (click)="filterValueChanged();" mat-button mat-raised-button>Apply</button>
        </div>
    </mat-card>
</div>