import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCDeletedCertificationReportRoutingModule }
    from './deleted-certification-report-routing.module';
import { UCDeletedCertificationPageReportComponent }
    from './deleted-certification-report-page.component';
import { UCDeletedCertificationReportGridComponent }
    from './deleted-certification-report-grid.component';

@NgModule({
    imports: [
        SharedModule,
        UCDeletedCertificationReportRoutingModule
    ],
    exports: [
        UCDeletedCertificationPageReportComponent
    ],
    declarations: [
        UCDeletedCertificationPageReportComponent,
        UCDeletedCertificationReportGridComponent
    ]
})

export class UCDeletedCertificationReportModule {

}
