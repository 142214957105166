import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCSalesOver65kReportRoutingModule }
  from './sales-over65k-report-routing.module';
import { UCSalesOver65kReportPageComponent }
  from './sales-over65k-report-page.component';
import { UCSalesOver65kReportGridComponent }
  from './sales-over65k-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCSalesOver65kReportRoutingModule
  ],
  exports: [
    UCSalesOver65kReportPageComponent
  ],
  declarations: [
    UCSalesOver65kReportPageComponent,
    UCSalesOver65kReportGridComponent
  ]
})

export class UCSalesOver65kReportModule {
}
