




import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { VehicleHistoryService } from '../services/api/vehicle-history.service';

import * as detailActions from '../actions/vehicle-history.action';

import * as models from '../services/model/models';
import {map, catchError, switchMap} from 'rxjs/operators'

/**
 * Class used to handle the service interaction in the vehicle detail screen
 */
@Injectable()
export class VehicleHistoryEffects {
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getVehicleByVins$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.GET_VEHICLE_HISTORY)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleHistoryService.getVehicleHistoryByVin(data)
      .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.GetVehicleHistorySuccessAction(vehicleData))
          , catchError(error => of(new detailActions.GetVehicleHistoryFailAction(
          { 'error': error, 'vehicleData': data }))))
    ));


  /**
   * Constructor method used to create new instance of VehicleDetailsEffects class
   */
  constructor(private actions$: Actions, private vehicleHistoryService: VehicleHistoryService) { }
}
