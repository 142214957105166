<div fxLayout="column">
  <!-- <region-selection-vin-search-toolbar fxFlex="100" (filterDateEvent)="onLoad($event)"
  [enteredvin]="enteredvin" [reportType]="reportType" [enteredDealerCode]="enteredDealerCode"></region-selection-vin-search-toolbar> -->
  <region-dashboard-search-toolbar [labelText]="labelText" fxFlex="100" (filterDateEvent)="reportLoad($event)"
    [enteredvin]="enteredvin" [selectedMonth]="selectedMonth" [selectedYear]="selectedYear"
    [regionSelection]="regionSelection" [districtSelection]="districtSelection" [enteredDealerCode]="enteredDealerCode">
  </region-dashboard-search-toolbar>

  <uc-region-dashboard-menubar-panel (clearAllButtonEvent)="clearAllFilters();"
    [showClearAllButton]="showClearAllButton" [totalInventoriesCount]="totalInventoriesCount"
    (exportPdfEvent)="downLoadPdf()" (exportExcelEvent)="downLoadXlsx()">
  </uc-region-dashboard-menubar-panel>

  <!-- <uc-inventory-grid [columnDefs]="columnDefs" [rows]="rows" (applySortEvent)="applyInventorySort($event)"
    (applyFilterEvent)="ApplyFilter($event)" [clearSort]="clearSort">
  </uc-inventory-grid> -->
  <uc-inventory-grid-gold-silver [columnDefs]="columnDefs" [rows]="rows" (applySortEvent)="applyInventorySort($event)"
    (applyFilterEvent)="ApplyFilter($event)" [clearSort]="clearSort">
  </uc-inventory-grid-gold-silver>
</div>