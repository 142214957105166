


import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import * as featureConfigActions from '../actions/feature-config-action';
import { FeatureConfigDetailService } from '../services/api/fearure-config.service';
import {map, catchError, switchMap} from 'rxjs/operators'

@Injectable()
export class FeatureConfigDetailEffects {


    @Effect()
    getFeatureConfigData$: Observable<Action> = this.actions$
        .pipe(ofType(featureConfigActions.actionTypes.LOAD_FEATURE_CONFIG_DETAIL)
        ,map((action: ActionBase) => action.payload)
        , switchMap(data =>
            this.featureConfigService.getFeatureConfig(data.dealerCode)
                .pipe(map((featureConfigDetail: any) => new
                    featureConfigActions.LoadFeatureConfigSuccesssAction(featureConfigDetail))
                , catchError(error => of(new
                    featureConfigActions.LoadFeatureConfigFailAction(error))))
        ));

    @Effect()
    getTierelibilityData$: Observable<Action> = this.actions$
        .pipe(ofType(featureConfigActions.actionTypes.LOAD_TIER_ELIBILITY)
        ,map((action: ActionBase) => action.payload)
        , switchMap(data =>
            this.featureConfigService.getTierElgibility(data)
                .pipe(map((tierEligibilityData: any) => new
                    featureConfigActions.LoadTiereligiblitySuccesssAction(tierEligibilityData))
                , catchError(error => of(new
                    featureConfigActions.LoadTiereligiblityFailAction(error))))
        ));

    @Effect()
    getSETWarrantyContarctData$: Observable<Action> = this.actions$
        .pipe(ofType(featureConfigActions.actionTypes.LOAD_WARRANTY_CONTRACT_DATA),
            map((action: ActionBase) => action.payload),
            switchMap(data =>
            this.featureConfigService.getWarrantyContarctData(data)
                .pipe(map((warrantyConData: any) => new
                    featureConfigActions.LoadWarrantyContractDataSuccesssAction(warrantyConData)),
                catchError(error => of(new
                    featureConfigActions.LoadWarrantyContractDataFailAction(error)))
        )));

    constructor(private actions$: Actions,
        private featureConfigService: FeatureConfigDetailService,
    ) { }
}
