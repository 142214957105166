import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
    static ytdGridColumns = [
        {// regionName
            key: ViewConstants.REGION_NAME_COLUMN, fieldName: 'regionName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '185',
            columnMinWidth: '185', columnMaxWidth: '185', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
         {// DISTRICT
            key: ViewConstants.DISTRICT, fieldName: 'districtNumber', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
            columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.CONTAINS
        },
        {// dealerName
            key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '200',
            columnMinWidth: '200', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealer#
            key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '150',
            columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.CONTAINS
        },
        {// TOTAL_TCUV_OBJECTIVE
            key: ViewConstants.TOTAL_TCUV_OBJECTIVE, fieldName: 'currentYearObjective',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL,
            tooltip:'(Sum of monthly objectives)'
        },        
        {// PREVIOUS_YTD_SALES
            key: ViewConstants.PREVIOUS_YTD_SALES,
            fieldName: 'prevYearlyCertifiedSalesCount', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// YTD_SALES
            key: ViewConstants.YTD_SALES,
            fieldName: 'certifiedSalesCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// Sales ( + / -) ( YTD Sales - Previous YTD Sales)
            key: ViewConstants.SALES_DIFF, fieldName: 'salesChange',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
            columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN,
            tooltip: '(YTD Sales - Previous YTD Sales)'
        },
        {// Sales % [ (YTD Sales / Previous YTD Sales) * 100 ]
            key: ViewConstants.SALES_PERCENTAGE, fieldName: 'salesChangePercent',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '170', columnMinWidth: '170',
            columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN,
            tooltip: '(YTD Sales / Previous YTD Sales) * 100'
        },        
        {// PREVIOUS_YTD_CERTS
            key: ViewConstants.PREVIOUS_YTD_CERTS, fieldName: 'prevYearlyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// YTD_CERTS
            key: ViewConstants.YTD_CERTS, fieldName: 'certifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// Certification ( + / -) ( YTD Certs - Previous YTD Certs)
            key: ViewConstants.YTD_PV_CERTS, fieldName: 'certChange',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL,
            tooltip: '(YTD Certs - Previous YTD Certs)'
        },
        {// Certification % [ (YTD Certs / Previous YTD Certs) * 100 ]
            key: ViewConstants.CUSTOM_CERT_BY_PV, fieldName: 'certChangePercent',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '', dataTypeformat: '', filterType: FilterType.DATE_SYMBOL,
            tooltip:'(YTD Certs / Previous YTD Certs) * 100'
        },
        {// CURRENT_INVENTORY
            key: ViewConstants.CURRENT_INVENTORY, fieldName: 'invCount',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// PREVIOUS_YTD_INV
            key: ViewConstants.PREVIOUS_YTD_INV,
            fieldName: 'prevYearlyInvCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        ];
    static customGridColumns = [
        {// regionName
            key: ViewConstants.REGION_NAME_COLUMN, fieldName: 'regionName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '185',
            columnMinWidth: '185', columnMaxWidth: '185', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
         {// DISTRICT
            key: ViewConstants.DISTRICT, fieldName: 'districtNumber', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
            columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.CONTAINS
        },
        {// dealerName
            key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '200',
            columnMinWidth: '200', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
            dataTypeformat: validationTypes.ALPHANUMERIC_5_WITHOUTSPACE,
            filterType: FilterType.STARTS_WITH
        },
        {// dealer#
            key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
            hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '150',
            columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.CONTAINS
        },
        {// TOTAL_TCUV_OBJECTIVE
            key: ViewConstants.TOTAL_TCUV_OBJECTIVE, fieldName: 'currentYearObjective',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL,
            tooltip:'(Sum of monthly objectives)'
        },
        {// PY_CUSTOM_SALES
            key: ViewConstants.YTY_CUSTOM_SALES,
            fieldName: 'prevYearlyCertifiedSalesCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },       
        {// YTD_SALES
            key: ViewConstants.PY_CUSTOM_SALES,
            fieldName: 'certifiedSalesCount',
            style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// Sales ( + / -) ( YTD Sales - Previous YTD Sales)
            key: ViewConstants.SALES_DIFF, fieldName: 'salesChange',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '150', columnMinWidth: '150',
            columnMaxWidth: '150', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN,
            tooltip: '(Custom Sales PY - Custom Sales YTV)'
        },
        {// Sales % [ (YTD Sales / Previous YTD Sales) * 100 ]
            key: ViewConstants.SALES_PERCENTAGE, fieldName: 'salesChangePercent',
            style: ' ', hasTemplate: 'false',
            template: '', sortable: 'true', columnWidth: '170', columnMinWidth: '170',
            columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.PRICE_COLUMN,
            tooltip: '(Custom Sales PY / Custom Sales YTV) * 100'
        },        
        {// PREVIOUS_YTD_CERTS
            key: ViewConstants.YTV_CUSTOM_CERTS, fieldName: 'prevYearlyCertifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// YTD_CERTS
            key: ViewConstants.PY_CUSTOM_CERTS, fieldName: 'certifiedInventory',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// Certification ( + / -) ( YTD Certs - Previous YTD Certs)
            key: ViewConstants.YTD_PV_CERTS, fieldName: 'certChange',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL,
            tooltip: '(PY Custom Certs - YTV Custom Certs)'
        },
        {// Certification % [ (YTD Certs / Previous YTD Certs) * 100 ]
            key: ViewConstants.CUSTOM_CERT_BY_PV, fieldName: 'certChangePercent',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false',
            filterValue: '', dataTypeformat: '', filterType: FilterType.DATE_SYMBOL,
            tooltip:'(PY Custom Certs - YTV Custom Certs) * 100'
        },
        {// CURRENT_INVENTORY
            key: ViewConstants.CURRENT_INVENTORY, fieldName: 'invCount',
            style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
        {// PREVIOUS_YTD_INV
            key: ViewConstants.PREVIOUS_YTD_INV,
            fieldName: 'prevYearlyInvCount', style: ' ',
            hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '170',
            columnMinWidth: '170', columnMaxWidth: '170', currencyField: 'false', filterValue: '',
            dataTypeformat: '', filterType: FilterType.DATE_SYMBOL
        },
    ];
}
