<mat-sidenav-container fullscreen>
    <mat-sidenav #sideMenu>
        <div>
            <mat-toolbar class="uc-toolbar">
                <div fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="center" class="dd-hamburg-dealer-name-text">
                        <a (click)="toggleUserMenu()" class="md-body-1 md-toolbar-link">
                            <span>{{msalSvc.getAccount()?.name}}</span>
                            <br>
                        </a>
                    </div>
                    <br>

                    <div fxLayout="row" fxLayoutAlign="center" class="dd-hamburg-dealer-name-text">
                        <a (click)="toggleUserMenu()" class="mat-body-1 mat-toolbar-link colorWhite">
                            <span class="font16">USED CAR INVENTORY</span>
                            <br>
                        </a>
                    </div>

                    <div fxLayout="row" class="dd-version colorWhite" fxLayoutAlign="center">
                        <span>Ver: 1.0.1</span>
                    </div>
                </div>
            </mat-toolbar>
            <mat-nav-list>
                <a mat-list-item (click)="logoutClick()">
                    <mat-icon>exit_to_app</mat-icon> Logout
                </a>
            </mat-nav-list>
            <div *ngIf="!isShowUserMenu()">
                <ng-content select="[menu-items]"></ng-content>
                <mat-nav-list>
                    <mat-divider></mat-divider>
                    <mat-divider></mat-divider>
                </mat-nav-list>
            </div>
        </div>
    </mat-sidenav>
    <ng-content></ng-content>
</mat-sidenav-container>