

import { ActionBase } from '../actions/actionbase';
import { FeatureConfigView } from '../services/view-model/feature-config-view';
import * as featureConfigActions from '../actions/feature-config-action';
import { isNil, cloneDeep } from 'lodash';
import * as models from '../services/model/models';
import { ScreenMessages } from '../shared/master-data/messages';

/**
 * Interface used to maintain the state for the dealer details
 */
export interface FeatureConfigState {
  featureConfigDetails: FeatureConfigView;
}
/**
 * Variable used to store the initial state of Dealer Detail
 */
const initialState: FeatureConfigState = {
  featureConfigDetails: null
};

/**
 * Method used to set the default initial state of featureConfigDetails
 */
function setDefaulValue(): FeatureConfigView {
  let featureConfigDetails: FeatureConfigView = <FeatureConfigView>new Object();
  featureConfigDetails.silverPorgram = false;
  return featureConfigDetails;
}
/**
 * Reducer method to handle the dealer detail actions
 */

// Hack: Ignored tslint errors
/* tslint:disable:no-switch-case-fall-through */
export function reducer(state = initialState, action: ActionBase): FeatureConfigState {
  switch (action.type) {
    case featureConfigActions.actionTypes.LOAD_FEATURE_CONFIG_SUCCESS: {
      // let featureConfigDetail: featureConfigModel = {};
      let featureConfigDetail: boolean = false;

      if (!isNil(action.payload)) {
        // featureConfigDetail.isSilverProgramEligible = action.payload.response.trim();
        featureConfigDetail = action.payload.response;
        // featureConfigDetail = true;
      }
      return {
        featureConfigDetails: (<any>Object).assign({}, state.featureConfigDetails,
          {
            silverPorgram: featureConfigDetail,
          })
      };
    }
    case featureConfigActions.actionTypes.LOAD_FEATURE_CONFIG_FAIL: {
      let featureConfigDetail = false;
      let message: models.Message = {};
      let errorResponse = cloneDeep(action.payload);
      if (errorResponse === ScreenMessages.SERVICE_FAILURE_MESSAGE ||
        errorResponse === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
        message.message = errorResponse;
        message.type = models.MessageType.DANGER;
        return {
          featureConfigDetails: (<any>Object).assign({}, state.featureConfigDetails,
            {
              message: message,
              silverPorgram: featureConfigDetail
            })
        };
      } else {
        return {
          featureConfigDetails: (<any>Object).assign({}, state.featureConfigDetails,
            {
              silverPorgram: featureConfigDetail
            })
        };
      }
    }

    case featureConfigActions.actionTypes.CLEAR_ALL_ACTION: {
      return { featureConfigDetails: setDefaulValue() };
    }
    default: {
      return state;
    }
  }
}
/* tslint:enable:no-switch-case-fall-through */

/**
 * Method to get the state of dealer details
 */
export function getFeatureConfigDetail(state: FeatureConfigState) {
  return state.featureConfigDetails;
}




