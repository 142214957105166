<div fxLayout="column">
  <!-- <vehicle-report-vin-search-toolbar fxFlex="100"(filterDateEvent)="reportLoad($event)"
         [enteredvin]="enteredvin" [enteredDealerCode]="enteredDealerCode"></vehicle-report-vin-search-toolbar>
         -->
  <uc-objective-menubar-panel [isInventorySelected]="isInventorySelected"
    [selectedCertificationStatus]="selectedCertificationStatus" [selectedMakeCode]="selectedMakeCode"
    (clearAllButtonEvent)="clearAllFilters();" [showClearAllButton]="showClearAllButton"
    [totalInventoriesCount]="totalInventoriesCount" [isDownloadVisible]="isDownloadVisible" (exportPdfEvent)="downLoadPdf()"
    (exportExcelEvent)="downLoadXlsx()" (downloadEvent)="downLoadSampleXlsx()"></uc-objective-menubar-panel>

  <uc-inventory-grid [columnDefs]="columnDefs" [subGridColumnDefs]="subGridColumnDefs" [rows]="regionRowItems" [rowsCopy]="regionRowItemsCopy"
    (applySortEvent)="applyInventorySort($event)" (applyFilterEvent)="ApplyFilter($event)" (onUploadEvent)="onFileChange($event)"
    [clearSort]="clearSort" [selectedMonth]="selectedMonth" [regionItems]="regionItems" [selectedYear]="selectedYear" [regionSelection]="regionSelection"
    [regionDisplay]="regionDisplay" [objectiveCountCurrent]="objectiveCountCurrent" [objectiveCountRegional]="objectiveCountRegional"
     (fetchObjectiveEvent)="dataLoad($event)" (saveDealerObjectiveEvent)="saveDealerObjective()">
  </uc-inventory-grid>
</div>
