import {
  Component, Input, OnDestroy, OnInit, ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty, isNil, isUndefined } from 'lodash';
import { IMyDpOptions } from 'mydatepicker';

import { Observable } from 'rxjs';
import * as dealerDetailActions from '../../actions/dealer-detail.action';
import * as messageActions from '../../actions/message-data.action';
import * as messageDataActions from '../../actions/message-data.action';
import * as rdrDetailActions from '../../actions/vehicle-decertification.action';
import * as vehicleDetailActions from '../../actions/vehicle-detail.action';
import * as fromRoot from '../../reducers';
import { SharedDataService } from '../../services/common/shared-data.service';
import { SharedFunctionService } from '../../services/common/shared-function.service';
import * as models from '../../services/model/models';
import { DealerDetailView } from '../../services/view-model/dealer-detail-view';
import * as viewModels from '../../services/view-model/view-models';
import { DialogService } from '../../shared/components/dialog/dialog.service';
import { ScreenMessages } from '../../shared/master-data/messages';
import { ViewConstants } from '../../shared/shared.constants';
import * as moment from 'moment'

@Component({
  selector: 'uc-vehicle-decertification-page',
  templateUrl: 'vehicle-decertification-page.component.html'
})

/**
 * This component is used to show the Vehicle Detail screen
 */
export class VehicleDecertificationPageComponent implements OnInit, OnDestroy {
  @Input() vin: string;
  // Route param subscriber to get the VIN to be loaded
  routeParamSubscriber: any;
  // To expand and collapse Expansion Panel
  expand: boolean = true;
  // to store update flag value
  public updateFlag: boolean;
  // to store add flag value
  public addFlag: boolean;
  // to store add & certify flag value
  public addCertifyFlag: boolean;
  // to store router text
  public routerText: string;
  // to store savebutton text
  public saveButtonText: string;
  // to store update button text
  public updateButtonText: string;
  // Observable to obtain VehicleDetailView object
  decertificationData$: Observable<viewModels.VehicleDecertificationView>;
  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<DealerDetailView>;
  vehicleDetails$: Observable<viewModels.VehicleDetailView>;

  // Observable to obtain VehicleDetailView object
  vehicleDetails: viewModels.VehicleDecertificationView =
    <viewModels.VehicleDecertificationView>new Object();

  // Observable to obtain VehicleDetailView object
  reStorevehicleDetails: any = <any>new Object();

  vehicleInventoryDetails: viewModels.VehicleDetailView =
    <viewModels.VehicleDetailView>new Object();

  // To set and get the obtained VehicleDetailView object from reducer using this variable
  public _vehicleDetails: viewModels.VehicleDecertificationView
    = <viewModels.VehicleDecertificationView>new Object();
  // to store makeCode
  makeCode: string;

  // to store certified vehcicle check value
  certifiedFlag: string;

  // to store certified vehcicle check value
  forcecertifiedFlag: string;

  warrantExpDateText: string;

  isCertify: boolean;

  isValidPQSS: boolean;

  isValidCarFax: boolean;

  isValidVin: boolean;

  addMode: boolean;

  isForceCertified: boolean;

  inEligibleFlag: boolean;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    inline: false,
    openSelectorTopOfInput: false,
    showClearDateBtn: true,
    showInputField: true,
    alignSelectorRight: true,
    showSelectorArrow: false
  };

  isMandatoryForCertify: boolean;

  // Variable to hold the datas displayed in the grid
  public allDealers: models.DealerDetailsModel[];

  displayInvalidVin: boolean;

  displayInvalidDealerCode: boolean;

  showAdditionalDriver: boolean = false;

  isEmailMandatory: boolean = false;

  isCoEmailMandatory: boolean = false;

  isIndividualCustomer: boolean = false;

  actionMode: string;

  reversalFlag: boolean = false;

  // Route param subscriber to add vehicle
  private addVehicleServiceSubscriber: any = null;
  // Route param subscriber to get the vehicle detail to be loaded
  private rdrDetailSubscriber: any;

  private vehicleDetailSubscriber: any;

  // private moment = require('moment');

  /**
   * constructor method to create new instance of VehicleDetailPageComponent
   */
  constructor(private store: Store<fromRoot.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    public sharedDataService: SharedDataService,
    private viewContainerRef: ViewContainerRef,
    private sharedFuncService: SharedFunctionService,
    private dialogsService: DialogService,
    private msalSvc: MsalService
  ) {
    try {
      this.decertificationData$ = store.select(fromRoot.getvehicleDecertification);
      this.vehicleDetails$ = store.select(fromRoot.getVehicleByVin);
      this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
    } catch (error) {
    } finally {

    }
  }
  /**
   * Init method to initialize state of VehicleDetailPageComponent
   */
  ngOnInit() {
    this.makeCode = '';
    this.onDisableFuture();
    this.onDisablePast();
    this.makeCode = this.sharedDataService.brandName;
    this.isMandatoryForCertify = false;
    this.displayInvalidVin = false;
    this.displayInvalidDealerCode = false;
    this.isValidCarFax = true;
    this.isValidVin = false;
    this.isValidPQSS = true;
    this.addFlag = false;
    this.addCertifyFlag = false;
    this.isCertify = true;
    this.certifiedFlag = '';
    this.forcecertifiedFlag = '';
    this.addMode = false;
    let vehData: models.Vehicle = <models.Vehicle>new Object();
    this.sharedFuncService.setCurrentScreenName(ViewConstants.VEHICLE_DETAIL_SCREEN_NAME);
    this.store.dispatch(new rdrDetailActions.ClearAllAction());
    this.routeParamSubscriber = this.route.params.subscribe(params => {
      if (!isNil(params['vin']) && !isUndefined(params['vin'])) {
        let vehicle: models.Rdr = {};
        vehicle.vin = params['vin'];
        this.actionMode = params['action'];
        vehicle.dealerCd = this.sharedDataService.dealerCode;
        this.vin = params['vin'];
        this.updateFlag = true;
        // this.showAdditionalDriver = true;
        this.routerText = 'RDR Details';
        this.saveButtonText = 'UPDATE';
        this.brandAssign(this.makeCode, 'UPDATE', 'ADD NEW');

        vehData.vin = this.vin;
        vehData.dealerCd = params['dlrCd'];
        vehData.dealerCd = vehData.dealerCd.trim();
        if (vehData.dealerCd !== this.sharedDataService.dealerCode) {
          this.setDealer(vehData.dealerCd);
        }

        if (this.actionMode === 'reversal') {
          this.reversalFlag = true;
          this.saveButtonText = 'REVERSE RDR';
        }
        // this.makeCode = this.sharedDataService.brandName;
        this.brandAssign(this.makeCode, '', '');

        // this.store.dispatch(new rdrDetailActions.GetCurrentState());
      } else {
        this.addAction(true, true);
      }
    });

    this.vehicleDetailSubscriber = this.vehicleDetails$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        this.vehicleInventoryDetails = cloneDeep(data);
      }
    });

    this.rdrDetailSubscriber = this.decertificationData$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        if (!isNil(data.message)) {
          this.isMandatoryForCertify = false;
          this.displayInvalidVin = false;
          this.displayInvalidDealerCode = false;
          if (data.message.message === 'Enter a valid VIN') {
            this.addFlag = false;
            this.addCertifyFlag = false;
            this.forcecertifiedFlag = '';
          }
          if (data.pageType !== ViewConstants.DELETED) {
            this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
              data.message
            ));
            this.isValidVin = false;
          }

          if (data.pageType === 'CERTIFICATION_VALIDATION_FAILED') {
            this.vehicleDetails = cloneDeep(data);
            this.isMandatoryForCertify = false;
            if (this.makeCode === 'LEXUS' || this.makeCode === 'LEX') {
              this.updateChangeRedirection();
              this.brandAssign(this.makeCode, '', '');
            }
          } else if (data.pageType === 'CERTIFICATION-DELETED') {
            this.vehicleDetails = cloneDeep(data);
            this.certifiedFlag = 'N';
            this.forcecertifiedFlag = 'N';
            this.isMandatoryForCertify = false;
            this.displayInvalidVin = false;
            this.displayInvalidDealerCode = false;
          } else if (data.pageType === ViewConstants.CERTIFIED) {
            this.vehicleDetails = cloneDeep(data);
            this.certifiedFlag = 'Y';
            this.forcecertifiedFlag = '';
          } else if (data.pageType === ViewConstants.DECERTIFIED) {
            this.vehicleDetails = cloneDeep(data);
            this.certifiedFlag = 'N';
            this.forcecertifiedFlag = 'N';
          } else if (data.pageType === ViewConstants.ADDED) {
            this.vehicleDetails = cloneDeep(data);
            this.updateFlag = true;
            this.routerText = 'RDR Details';
            this.saveButtonText = 'UPDATE';
            this.updateButtonText = 'ADD NEW';
            this.vehicleDetails.pageType = '';
          }

        } else if (data.pageType === ViewConstants.NO_VIN_VEH_DETAILS && this.addMode === true) {
          this.addMode = false;
          this.isMandatoryForCertify = false;
          this.displayInvalidVin = false;
          this.displayInvalidDealerCode = false;
        } else if (data !== undefined && data.pageType !== ViewConstants.FAIL
          && data.pageType !== ViewConstants.NO_VIN_VEH_DETAILS
          && !isNil(data.vehicleData)) {
          if (!(data.pageType === ViewConstants.UPDATE && this.updateFlag === false)) {
            if (!isNil(data.vehicleData.VIN_NUMBER) &&
              !isEmpty(data.vehicleData.VIN_NUMBER)) {
              this.formatDataforDetailsView();
              this.vehicleDetails.vehicleData.VIN_NUMBER = data.vehicleData.VIN_NUMBER;
              this.vehicleDetails.vehicleData.InEligibleFlag = data.vehicleData.InEligibleFlag;
            }

            if (!isNil(this.vehicleDetails.vehicleData.InEligibleFlag)) {
              this.isValidVin = true;
              this.inEligibleFlag =
                this.convertStringToBoolean(this.vehicleDetails.vehicleData.InEligibleFlag);
              if (this.inEligibleFlag === true) {
                this.saveButtonText = 'UNBLOCK';
              } else {
                this.saveButtonText = 'BLOCK';
              }
            }

          }

          this.onDisableFuture();
          this.onDisablePast();
          this.isValidPQSS = true;
          this.isValidCarFax = true;

          // if (isNil(this.makeCode)) {
          //     this.makeCode = this.sharedDataService.brandName;
          //     this.brandAssign(this.makeCode, '', '');
          // }

          if (!isNil(data.vehicleData) &&
            !isUndefined(this.vehicleDetails.vehicleData.VIN_NUMBER)) {
            this.addFlag = true;
            this.addCertifyFlag = true;
          } else if (data.pageType === ViewConstants.UPDATE && this.updateFlag === false) {
            this.reStorevehicleDetails = cloneDeep(data);
            let test: boolean;
            this.dialogsService.open(ViewConstants.ADD_NEW_VEHICLE,
              ScreenMessages.VEHICLE_ADD_UPDATE_CONFIRMATION,
              ViewConstants.YES, ViewConstants.NO,
              ViewConstants.OK_CANCEL,
              this.viewContainerRef, this, this.updateVehicleConfirmed)
              .subscribe(res => test = res);
          }
        }
      }
    });
  }

  /**
   * Method to redirect update screen for existing vin
   */
  updateVehicleConfirmed(event: any) {
    event.dialogRef.close(true);
    event.parent.vehicleDetails = cloneDeep(event.parent.reStorevehicleDetails);
    event.parent.updateFlag = true;
    event.parent.routerText = 'Vehicle Details';
    event.parent.saveButtonText = 'UPDATE';
    event.parent.updateButtonText = 'ADD NEW';
    event.parent.isMandatoryForCertify = false;
    event.parent.displayInvalidVin = false;
    event.parent.displayInvalidDealerCode = false;
    if (!isNil(event.parent.vehicleDetails))
      event.parent.vehicleDetails.pageType = '';

    if (!isNil(event.parent.vehicleDetails.vehicleData.certificationStatus)
      && event.parent.vehicleDetails.vehicleData.certificationStatus === 'C') {
      event.parent.certifiedFlag = 'Y';
      event.parent.forcecertifiedFlag = '';
    } else {
      event.parent.certifiedFlag = 'N';
      event.parent.forcecertifiedFlag = 'N';
    }

    if (event.parent.makeCode === 'TOYOTA')
      event.parent.warrantExpDateText = 'POWERTRAIN COVERAGE EXPIRY DATE';
    else if (event.parent.makeCode === 'LEXUS')
      event.parent.warrantExpDateText = 'EXTENSIVE COMPREHENSIVE COVERAGE EXPIRY DATE';
    else
      event.parent.warrantExpDateText = 'WARRANTY EXP DATE';

  }


  setDealer(dealerCodeVal: string) {
    this.store.dispatch(new dealerDetailActions.LoadDealerAction({
      dealerCode: dealerCodeVal
    }));
  }

  addAction(freshPage: boolean, certify: boolean) {
    if (freshPage) {
      this.formatDataforDetailsView();
    }
    this.vin = '';
    this.isMandatoryForCertify = false;
    this.displayInvalidVin = false;
    this.displayInvalidDealerCode = false;
    this.updateFlag = false;
    this.saveButtonText = 'SUBMIT';
    if (certify === false) {

      this.addCertifyFlag = false;

    }

  }

  /**
   * On Destory event handler to register the state and filter conditions
   * to store and to unsubscribe the subscriptions
   */
  ngOnDestroy() {
    try {
      if (this.routeParamSubscriber) {
        this.routeParamSubscriber.unsubscribe();
      }
      if (this.rdrDetailSubscriber) {
        this.rdrDetailSubscriber.unsubscribe();
      }
      if (this.vehicleDetailSubscriber) {
        this.vehicleDetailSubscriber.unsubscribe();
      }
      this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    } catch (error) {
      // this.logger.error(error);
    }
  }

  /**
   * To obtain the vehicle details by passing VIN
   */
  getVehicleDetailsbyonblur(vin: string) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.store.dispatch(new rdrDetailActions.ClearAllAction());
    this.store.dispatch(new rdrDetailActions.LoadVinInformationAction(vin));
    let vehicle: models.Vehicle = {};
    vehicle.vin = vin;
    this.store.dispatch(new vehicleDetailActions.InitialLoadAction(vehicle));
  }

  savedDetails(event: any) {
    let model: models.Message = {};
    model.message = 'Lorem Ipsum.';
    model.type = models.MessageType.DANGER;
  }

  navigateToInventoryScreen() {
    this.router.navigate(['/inventory/']);
  }

  navigateToVehicleDetailScreenNew(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    if (this.updateFlag === true) {
      // this.addAction(true, true);
      this.router.navigate(['/rdrDetail/']);
    }
  }

  navigateToRdrSummary(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.router.navigate(['/rdr/']);
  }

  deCertifyConfirmed(event: any) {
    event.dialogRef.close(true);
    event.parent.saveVehicleEvent(event.parent.reStorevehicleDetails);
    this.sharedDataService.isPageEdited = false;
  }

  validateInputData(vehicleData: models.Decertification): boolean {
    let isValid: boolean = true;
    if (isNil(vehicleData.VIN_NUMBER)) {
      isValid = false;
    } else if (isNil(vehicleData.DECERTIFICATION_SOURCE_CODE)
      && vehicleData.InEligibleFlag === 'N') {
      isValid = false;
    } else if (isNil(vehicleData.DECERTIFICATION_REASON_CODE)
      && vehicleData.InEligibleFlag === 'N') {
      isValid = false;
    } else if (isNil(vehicleData.DECERTIFICATION_COMMENTS)
      && vehicleData.InEligibleFlag === 'N') {
      isValid = false;
    } else if (isNil(vehicleData.UNBLOCK_COMMENT) && vehicleData.InEligibleFlag === 'Y') {
      isValid = false;
    }

    return isValid;
  }

  saveVehicleEvent(inputData: any) {

    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.addMode = false;
    let errorMessage;
    let decertificationData: models.Decertification = cloneDeep(inputData.vehicleData.vehicleData);
    this.displayInvalidVin = false;
    this.displayInvalidDealerCode = false;
    this.isEmailMandatory = inputData.isEmailMandatory;
    this.isCoEmailMandatory = inputData.isCoEmailMandatory;

    this.replaceEmptyToNull(decertificationData);

    if (this.validateInputData(decertificationData)) {
      this.sharedDataService.isPageEdited = false;
      let data: any = {};
      data.decertificationData = this.formatDecertificationData(decertificationData);
      data.inventoryData = this.vehicleInventoryDetails.vehicleData;
      this.store.dispatch(new rdrDetailActions.SaveDecertificationAction(data));
      this.addMode = true;
    }
  }

  replaceEmptyToNull(decertificationData: models.Decertification) {
    if (!isNil(decertificationData)) {
      if (isNil(decertificationData.VIN_NUMBER) || isEmpty(decertificationData.VIN_NUMBER)) {
        decertificationData.VIN_NUMBER = null;
      }
      if (isNil(decertificationData.DECERTIFICATION_SOURCE_CODE) ||
        isEmpty(decertificationData.DECERTIFICATION_SOURCE_CODE)) {
        decertificationData.DECERTIFICATION_SOURCE_CODE = null;
      }
      if (isNil(decertificationData.DECERTIFICATION_REASON_CODE) ||
        isEmpty(decertificationData.DECERTIFICATION_REASON_CODE)) {
        decertificationData.DECERTIFICATION_REASON_CODE = null;
      }
      if (isNil(decertificationData.DECERTIFICATION_COMMENTS) ||
        isEmpty(decertificationData.DECERTIFICATION_COMMENTS)) {
        decertificationData.DECERTIFICATION_COMMENTS = null;
      }
      if (isNil(decertificationData.DECERTIFICATION_ID) ||
        isEmpty(decertificationData.DECERTIFICATION_ID)) {
        decertificationData.DECERTIFICATION_ID = null;
      }
      if (isNil(decertificationData.DECERTIFICATION_TIMESTAMP) ||
        isEmpty(decertificationData.DECERTIFICATION_TIMESTAMP)) {
        decertificationData.DECERTIFICATION_TIMESTAMP = null;
      }
      if (isNil(decertificationData.BLOCK_CERTIFICATION_FLAG) ||
        isEmpty(decertificationData.BLOCK_CERTIFICATION_FLAG)) {
        decertificationData.BLOCK_CERTIFICATION_FLAG = null;
      }
      if (isNil(decertificationData.UNBLOCK_COMMENT) ||
        isEmpty(decertificationData.UNBLOCK_COMMENT)) {
        decertificationData.UNBLOCK_COMMENT = null;
      }
      if (isNil(decertificationData.DAMAGE_AMOUNT) || isEmpty(decertificationData.DAMAGE_AMOUNT)) {
        decertificationData.DAMAGE_AMOUNT = null;
      }
    }

    return decertificationData;
  }

  /**
   * Method used to display error message
   */
  errorMessageDisplay(messageString: string) {
    this.store.dispatch(new messageActions.SetDisplayMessageAction(
      <models.Message>{
        message: messageString,
        type: models.MessageType.DANGER
      }));
  }

  /**
   * Method used to frame the required state for the vehicle detail screen
   */
  formatDataforDetailsView(): viewModels.VehicleDecertificationView {
    // this.vehicleDetails =  new Observable<viewModels.VehicleDetailView>();
    let rdrDetailData = <viewModels.VehicleDecertificationView>new Object();
    let rdrobj = <models.Rdr>new Object();
    rdrDetailData.vehicleData = cloneDeep(rdrobj);
    rdrDetailData.vehicleData.VIN_NUMBER = null;
    rdrDetailData.vehicleData.ACTIVE_FLAG = null;
    rdrDetailData.vehicleData.BLOCK_CERTIFICATION_FLAG = null;
    rdrDetailData.vehicleData.DAMAGE_AMOUNT = null;
    rdrDetailData.vehicleData.DECERTIFICATION_COMMENTS = null;
    rdrDetailData.vehicleData.DECERTIFICATION_ID = null;
    rdrDetailData.vehicleData.DECERTIFICATION_SOURCE_CODE = null;
    rdrDetailData.vehicleData.DECERTIFICATION_REASON_CODE = null;
    rdrDetailData.vehicleData.UNBLOCK_COMMENT = null;
    this.vehicleDetails = cloneDeep(rdrDetailData);
    return rdrDetailData;
  }



  getDealerEvent(dealerCode) {
    this.displayInvalidDealerCode = false;
    if (this.allDealers.filter(e => e.dealerCode === dealerCode).length === 0) {
      this.displayInvalidDealerCode = true;
    }
  }

  brandAssign(brand: string, saveText: string, updateText: string) {
    if (brand === 'TOYOTA') {
      this.warrantExpDateText = 'POWERTRAIN COVERAGE EXPIRY DATE';
    } else if (brand === 'LEXUS') {
      this.warrantExpDateText = 'EXTENSIVE COMPREHENSIVE COVERAGE EXPIRY DATE';
    } else {
      this.warrantExpDateText = 'WARRANTY EXP DATE';
    }
    if (saveText !== '') {
      this.saveButtonText = saveText;
    }
    if (updateText !== '') {
      this.updateButtonText = updateText;
    }

  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  onDisableFuture() {
    let date = new Date();
    // Disable/enable dates from 5th forward
    date.setMonth(date.getMonth() + 1);
    let copy = this.getCopyOfOptions();
    copy.disableSince = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate() + 1
    };
    this.myDatePickerOptions = copy;
  }

  onDisablePast() {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);

    let copy = this.getCopyOfOptions();
    copy.disableUntil = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate()
    };
    this.myDatePickerOptions = copy;
  }

  updateChangeRedirection() {
    this.updateFlag = true;
    this.routerText = 'Vehicle Details';
    this.saveButtonText = 'UPDATE';
    this.updateButtonText = 'ADD NEW';
    this.vehicleDetails.pageType = '';
  }

  formatDecertificationData(decertification: models.Decertification) {
    let date = new Date();
    let account = this.msalSvc.getAccount();
    let decertificationData = <models.Decertification>new Object();
    decertificationData.VIN_NUMBER = decertification.VIN_NUMBER;
    decertificationData.DECERTIFICATION_SOURCE_CODE = decertification.DECERTIFICATION_SOURCE_CODE;
    decertificationData.DECERTIFICATION_REASON_CODE = decertification.DECERTIFICATION_REASON_CODE;
    decertificationData.DECERTIFICATION_COMMENTS = decertification.DECERTIFICATION_COMMENTS;
    decertificationData.DECERTIFICATION_ID = account ? account.name : null;
    decertificationData.DECERTIFICATION_TIMESTAMP = moment(date).utc().toISOString();
    decertificationData.BLOCK_CERTIFICATION_FLAG =
      decertification.InEligibleFlag === 'N' ? 'Y' : 'N';
    decertificationData.UNBLOCK_COMMENT = decertification.UNBLOCK_COMMENT;
    decertificationData.UNBLOCK_ID = decertification.UNBLOCK_ID;
    decertificationData.UNBLOCK_TIMESTAMP = moment(date).utc().toISOString();
    decertificationData.DAMAGE_AMOUNT = decertification.DAMAGE_AMOUNT;
    decertificationData.ACTIVE_FLAG = 'Y';
    decertificationData.LAST_UPDATED_ID = account ? account.name : null;
    decertificationData.LAST_UPDATE_TIMESTAMP = moment(date).utc().toISOString();
    return decertificationData;
  }

  convertBooleanToString(value: boolean): string {
    if (value === true) {
      return 'Y';
    } else {
      return 'N';
    }
  }

  convertStringToBoolean(value: string): boolean {
    if (value === 'Y') {
      return true;
    } else {
      return false;
    }
  }
}
