import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCCertificationReportRoutingModule }
  from './vehicle-certification-report-routing.module';
import { UCCertificationPageReportComponent } from './vehicle-certification-report-page.component';
import { UCCertificationReportGridComponent }
  from './vehicle-certification-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCCertificationReportRoutingModule
  ],
  exports: [
    UCCertificationPageReportComponent
  ],
  declarations: [
    UCCertificationPageReportComponent,
    UCCertificationReportGridComponent
  ]
})

export class UCVehicleCertificationReportModule {

}
