'use strict';
import * as models from '../model/models';
import { DealerDetailsModel } from '../model/DealerDetailsModel';

// Dealer Detail View Model
export interface DealerDetailView {
    // Message to be shown in the screen
    message?: models.Message;
    // to hold the current dealer details
    currentDealer: DealerDetailsModel;
    // to hold the list of dealers
    allDealers: DealerDetailsModel[];
}
