import { ViewConstants } from '../../../shared/shared.constants';
import { ValidationTypes } from '../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// vin
      key: ViewConstants.DEALER_AREAREGION_STRING_COLUMN, fieldName: 'name',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '400', columnMinWidth: '400',
      columnMaxWidth: '400', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// obj
      key: 'OBJECTIVE', fieldName: 'objective',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '150', columnMinWidth: '150',
      columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    }
  ];
}
