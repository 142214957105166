import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCSaleReversalPageReportComponent } from './sale-reversal-report-page.component';
import { AuthenticationGuard } from '../../../services/common/auth-guard';


const inventorySummaryRoutes: Routes = [
    {
        path: 'deletedCertificationSummaryReport',
        component: UCSaleReversalPageReportComponent,
        canActivate: [AuthenticationGuard]
    },
    {
      path: 'deletedCertificationSummaryReport/:type',
      component: UCSaleReversalPageReportComponent,
      canActivate: [AuthenticationGuard]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(inventorySummaryRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})

export class UCSaleReversalReportRoutingModule {

}
