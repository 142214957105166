<mat-card fxFlex="100" fxFlex.xs="100" class="uc-menubar-panel">
    <div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
        <div style="box-sizing:border-box">
            <button #btn1 [disabled]="true" mat-button target="_blank" class="uc-menubar-item"
                (click)="navigateToVehicleScreen()">
                {{sharedDataService.currentMenuBarName}}
            </button>
        </div>
        <div *ngIf="totalInventoriesCount !== 0">
            <button #btn2 mat-button target="_blank" class="uc-menubar-item" (click)="exportPdf()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn2.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn2.disabled"><img src="assets/icon/pdf-24.png"
                        alt="pdf.png"></mat-icon>EXPORT PDF
            </button>
        </div>
        <div *ngIf="totalInventoriesCount !== 0">
            <button #btn3 mat-button target="_blank" class="uc-menubar-item" (click)="exportExcel()">
                <mat-icon [class.uc-menubar-item-icon-disabled]="btn3.disabled"
                    [class.uc-menubar-item-icon-enabled]="!btn3.disabled"><img src="assets/icon/excel-3-24.png"
                        alt="excel.png"></mat-icon>EXPORT EXCEL
            </button>
        </div>
    </div>
    <div *ngIf="!showClearAllButton" fxFlex="10"></div>
    <div *ngIf="showClearAllButton" class="divider"></div>
    <div *ngIf="showClearAllButton" fxFlex="10" fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="row" class="clearAllDiv" fxLayoutAlign="center center">
            <div style="padding-left: 5px;">Clear All</div>
            <span class="clearAllCloseBtn" (click)="clearAllButtonClicked($event);">×</span>
        </div>
    </div>
    <div class="divider"></div>
    <div fxFlex="15" fxLayout="column" layout-gt-sm="row">
        <div fxLayout="row" fxFlex="100" layout-xs="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div>Total Records</div>
                <div class="totalVehiclesFont"><strong>{{totalInventoriesCount}}</strong></div>
            </div>
        </div>
    </div>
</mat-card>