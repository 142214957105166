<div fxLayout="column">
    <!--<uc-menubar-panel (navigateToVehicleDetailEvent)="navigateToVehicleDetailScreen($event)"></uc-menubar-panel>-->
    <mat-card fxFlex="100" fxFlex-xs="100" class="uc-menubar-panel">
        <div fxLayout="row" layout-xs="column" layout-sm="column" layout-wrap>
            <!--<div flex-xs="100">
            <button mat-button target="_blank" class="uc-menubar-item" (click)="navigateToInventoryScreen()">
                <mat-icon class="uc-menubar-item-icon">
                    <img src="assets/icon/Icon-Vehicle-Detail.png"/>
                </mat-icon>Inventory Summary
            </button>
        </div>-->
            <div>
                <button mat-button target="_blank" class="uc-menubar-item mdbuttonfocused">
                    <mat-icon class="uc-menubar-item-icon">description</mat-icon>
                    <!--<mat-icon class="uc-menubar-item-icon" *ngIf="routerText!=='Vehicle Details'">
                    <img src="assets/icon/add_dios.png"/>
                </mat-icon>-->
                    {{routerText}}
                </button>


            </div>
        </div>
    </mat-card>
    <uc-sale-detail [updateFlag]="updateFlag" [addFlag]="addFlag" [addCertifyFlag]="addCertifyFlag"
        [rdrDetails]="rdrDetails" [makeCode]="makeCode" [saveButtonText]="saveButtonText"
        [updateButtonText]="updateButtonText" (getVehicleDetailsEvent)="getVehicleDetailsbyonblur($event)"
        (getDealerEvent)="getDealerEvent($event)"
        (navigateToNewVehicleDetailEvent)="navigateToVehicleDetailScreenNew($event)"
        (navigateToRDRSummaryEvent)="navigateToRdrSummary($event)" (saveVehicleEvent)="saveVehicleEvent($event)"
        [certifiedFlag]="certifiedFlag" [warrantExpDateText]="warrantExpDateText" [isCertify]="isCertify"
        [isValidPQSS]="isValidPQSS" [isValidCarFax]="isValidCarFax" [isValidForeignvin]="isValidForeignvin"
        [myDatePickerOptions]="myDatePickerOptions" [forcecertifiedFlag]="forcecertifiedFlag"
        [isMandatoryForCertify]="isMandatoryForCertify" [displayInvalidVin]="displayInvalidVin"
        [displayInvalidDealerCode]="displayInvalidDealerCode" [showAdditionalDriver]="showAdditionalDriver"
        [isEmailMandatory]="isEmailMandatory" [isCoEmailMandatory]="isCoEmailMandatory"
        [isIndividualCustomer]="isIndividualCustomer" [reversalFlag]="reversalFlag" [reversedFlag]="reversedFlag"
        [isForceCertified]="isForceCertified" (zipcodeChangeEvent)="zipcodeChangeEvent($event)"
        [fIManagerItems]="fIManagerItems" [saleManagerItems]="saleManagerItems" [oldRetailDate]="oldRetailDate"
        [isRetailModified]="isRetailModified">
    </uc-sale-detail>
</div>