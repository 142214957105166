'use strict';

// Model Details
export interface RepairOrder {
    // to store repair order number1
    repairOrderNumber1?: string;
    // to store date of first repair order
    dateofFirstRepairOrder?: string;
    // to store repair order number2
    repairOrderNumber2?: string;
    // to store date of second repair order
    dateofSecondRepairOrder?: string;
    // to store date of reconditional end date
    reconditionalEndDate?: string;
    // to store Recondition Cost
    reconditionCost?: string;
    // technicianId
    technicianId?: string;
}
