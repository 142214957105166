/***
 * Import statements required for Logger
 */
import { Injectable, Provider } from '@angular/core';
import { environment } from 'src/environments/environment';

/***
 * Class that is injectable for performing Logger operations
 */
@Injectable()
export class Logger {
  /***
   * This method is used to get Log under DEBUG category
   */
  get debug() {
    if (environment.MIN_LOG_LEVEL === 'DEBUG') {
      return console.log.bind(console);
    } else {
      return () => { };
    }
  }

  /***
   * This method is used to get Log under INFO category
   */
  get info() {
    if (environment.MIN_LOG_LEVEL === 'DEBUG' || environment.MIN_LOG_LEVEL === 'INFO') {
      return console.log.bind(console);
    } else {
      return () => { };
    }
  }

  /***
   * This method is used to get Log under LOG category
   */
  get log() {
    if (environment.MIN_LOG_LEVEL === 'DEBUG' || environment.MIN_LOG_LEVEL === 'INFO' || environment.MIN_LOG_LEVEL === 'LOG') {
      return console.log.bind(console);
    } else {
      return () => { };
    }
  }

  /***
   * This method is used to get Log under WARN category
   */
  get warn() {
    if (environment.MIN_LOG_LEVEL === 'DEBUG' || environment.MIN_LOG_LEVEL === 'INFO' || environment.MIN_LOG_LEVEL === 'LOG'
      || environment.MIN_LOG_LEVEL === 'WARN') {
      return console.warn.bind(console);
    } else {
      return () => { };
    }
  }

  /***
   * This method is used to get Log under ERROR category
   */
  get error() {
    if (environment.MIN_LOG_LEVEL === 'DEBUG' || environment.MIN_LOG_LEVEL === 'INFO' || environment.MIN_LOG_LEVEL === 'LOG'
      || environment.MIN_LOG_LEVEL === 'WARN' || environment.MIN_LOG_LEVEL === 'ERROR') {
      return console.error.bind(console);
    } else {
      return () => { };
    }
  }

  /***
   * This method is used to get Log for Group Start
   */
  get group() {
    return console.group.bind(console);
  }

  /***
   * This method is used to get Log for Group End
   */
  get groupEnd() {
    return console.groupEnd.bind(console);
  }
}

// Logging Providers
export let LOGGING_PROVIDERS: Provider[] = [
  //   provide(Logger, {useClass: Logger}),
  {
    'provide': Logger, useClass: Logger
  }
];
