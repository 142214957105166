/**
 * Class is used to hold all the screen success and failure messages
 */
export class ScreenMessages {
  // Message for No Information available
  static NO_INFO_MESSAGE: string = 'No Information available at this moment';
  // Message for Service failure
  static SERVICE_FAILURE_MESSAGE: string = 'Something went wrong, please try again later!';
  // Message for Session expiry
  static SESSION_EXPIRY_MESSAGE: string = 'Session Expired. Please login again!';
  // Message for Invalid dealer code
  static INVALID_DEALER_CODE: string = 'Invalid Dealer Code';
  // Message for Invalid vin number
  static INVALID_VIN_NO: string = 'Enter a valid VIN';

  static NO_DATA: string = 'No Data';

  // Message for Invalid vin number for audi trail
  static INVALID_AUDIT_VIN_NO: string = 'Audit trail doesnt exist for the VIN';
  // Message for add new vehilcle success
  static ADD_NEW_VEHICLE_SUCCESS: string = 'Vehicle Successfully added into the inventory.';
  // Message for add new vehilcle success for lexus
  static ADD_NEW_VEHICLE_CERTIFY_SUCCESS_LEXUS: string =
    'Vehicle has been certified successfully.';
  // Message for add new vehilcle success for lexus
  static ADD_NEW_VEHICLE_SUCCESS_LEXUS: string = 'Vehicle has been added successfully.';
  static BUY_SELL_NOTIFICATION: string =
    'Buy Sell Process Initiated. Please check email after some minutes.';

  // Message for add new vehilcle success for lexus
  static ADD_NEW_RDR_SUCCESS: string = 'RDR has been created successfully.';
  // Message for add new vehilcle trade success for lexus
  static ADD_NEW_VEHICLE_TRADE_SUCCESS_LEXUS: string =
    'Vehicle has beeen certified from trade successfully.';
  // Message for add new vehilcle success
  static ADD_NEW_VEHICLE_FAIL: string = 'Error occured. Vehicle not added into the inventory';
  // Message for add update vehilcle success
  static UPDATE_VEHICLE_SUCCESS: string = 'Vehicle data updated successfully.';
  // Message for add update RDR success
  static UPDATE_RDR_SUCCESS: string = 'RDR data updated successfully.';

  // Message for add update RDR success
  static UPDATE_RECORD_SUCCESS: string = 'Record updated successfully.';

  // Message for add update RDR success
  static DEALER_RECORD_SUCCESS: string = 'Successfully Processed.';
  // Message for add update RDR success
  static REVERSE_RDR_SUCCESS: string = 'RDR has been reversed successfully.';
  // Message for add update vehilcle success
  static UPDATE_VEHICLE_CERTIFICATION_SUCCESS: string =
    'Vehicle has been certified successfully.';

  // Message for decertify vehilcle success
  static DECERTIFY_VEHICLE_SUCCESS: string = 'Vehicle has been de-certified successfully.';
  // Message for add update vehilcle success
  static UPDATE_VEHICLE_FAIL: string = 'Error occured. Vehicle not updated into the inventory';
  // constant for data not modified on screen
  static NO_CHANGE_ON_PAGE: string = 'Data has not been modified';
  // Message for Dealer Code Required Field
  static REQUIRED_DEALER_CODE: string = 'Enter Dealer Code';
  // Message for Dealer Code Required Field
  static REQUIRED_ACV_PRICE: string = 'Enter ACV Price';
  // Message for Dealer Code Required Field
  static INVALID_ACV_PRICE: string = 'Enter a valid price between $4000-$999999';
  // Message for Dealer Code Required Field
  static REQUIRED_RETAIL_PRICE: string = 'Enter Retail Price';
  // Message for Dealer Code Required Field
  static INVALID_RETAIL_PRICE: string = 'Enter a valid price between $6,000 and $999999';
  // Message for Dealer Code Required Field
  static REQUIRED_TRADE_PRICE: string = 'Enter Trade Price';
  // Message for Dealer Code Required Field
  static REQUIRED_RECONDITION_COST: string = 'Enter Recondition Cost';
  // Message for Dealer Code Required Field
  static REQUIRED_ACQUIRED_DATE: string = 'Select Aquired Date';
  // Message for Dealer Code Required Field
  static REQUIRED_ACQUIRED_METHOD: string = 'Select Aquired Method';
  // Message for Dealer Code Required Field
  static REQUIRED_MILEAGE: string = 'Enter Mileage';
  // Message for Dealer Code Required Field
  static REQUIRED_INSPECTION_FORM_NO: string = 'Enter Inspection Form no';
  // Message for Dealer Code Required Field
  static REQUIRED_INSPECTION_DATE: string = 'Select Inspection Date';
  // Message for Dealer Code Required Field
  static REQUIRED_REPAIR_ORDER_NO1: string = 'Enter Repair Order Number 1';
  // Message for Dealer Code Required Field
  static REQUIRED_REPAIR_ORDER_DATE1: string = 'Select Repair Order Date Of Service';
  // Message for Dealer Code Required Field
  static REQUIRED_RECONDITION_END_DATE: string = 'Select Recondition End Date';

  // constant to hold confirmation message for deletion of disclaimer
  static VEHICLE_DELETE_CONFIRMATION: string =
    'Are you sure you want to delete this vehicle?';

  // constant to hold confirmation message for deletion of disclaimer
  static RDR_DELETE_CONFIRMATION: string =
    'Are you sure you want to delete this vehicle RDR?';
  // constant for confirmation message add/ update vehicle
  static VEHICLE_ADD_UPDATE_CONFIRMATION: string =
    'Vehicle is already added to the dealership. Do want to continue to update?';
  // constant for confirmation message DECERTIFY vehicle
  static VEHICLE_DE_CERTIFY_CONFIRMATION: string = 'Are you sure to de-certify the vehicle?';
  // Constants for  DISCARD_CHANGES
  static DISCARD_CHANGES = 'There is unsaved data which will be lost. Are you sure to proceed?';
  // Message for unathorized page
  static UNAUTHORIZED_MESSAGE: string =
    'Authorization Failed - You don’t have access to requested page';
  // Message for Invalid vin number
  static INVALID_TOYOTA_VIN_NO: string = 'Enter a valid Toyota VIN';
  // Message for Invalid vin number
  static INVALID_LEXUS_VIN_NO: string = 'Enter a valid Lexus VIN';
  // Message for Invalid dealer code
  static INVALID_TOYOTA_DEALER_CODE: string = 'Enter a valid Toyota dealer code';
  static SAME_DEALER_CODE: string = 'From and To Dealer code are should not same';
  // Message for Invalid dealer code
  static INVALID_LEXUS_DEALER_CODE: string = 'Enter a valid Lexus dealer code';
  static INVALID_FROM_DEALER_CODE: string = 'Enter a valid from dealer code';
  static INVALID_TO_DEALER_CODE: string = 'Enter a valid to dealer code';
  static INVALID_TIER_CERTIFICATION: string = 'Vehicle is no longer eligible for certification';
  static INTERNAL_SERVER_ERROR: string = 'Internal Service Error – please try again later.';

  static UNCERTIFIED_TOYOTA_VEHICLE: string = 'Cannot create RDR for uncertified Toyota vehicle';

  // constant for timeout warning message
  static TIMEOUT_WARNING_MESSAGE: string =
    'Your session going to expire soon. Click OK to continue';

  // Message for add update RDR success
  static USER_RECORD_SUCCESS: string =
    'Successfully Processed. User needs to login again for the changes to effect.';
}
