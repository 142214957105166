'use strict';
import * as models from './models';

export interface UCInventorySummary {
  sales?: number;
  certified?: any;
  type?: string;
  objective?: any;
  districtNo?: any;
  salePersonUID?: string;
  salePersonId?: string;
  length?: number;
  count?: any;
  isVisible?: boolean;
  avgNonCertPrice?: string;
  avgCertPrice?: string;
  avgTurnRate?: string;
  inventoryMix?: any;
  mixPercent?: any;
  avgReconCost?: any;
  avgTransPrice?: any;
  // Vehicle Identification Number
  vin?: string;
  // dis
  dis?: string;
  // make Code
  makeCode?: string;
  // sold to dealer
  soldToDealer?: string;
  // model number
  model?: models.Model;
  // accessories details
  accessoriesDetails?: models.AccessoryDetails[];
  // accessories names
  accessories?: string;
  // certification details
  certification?: models.CertificationDetails;
  // price details
  price?: models.Price;
  // open campaign status
  openCampaignStatus?: string;
  // acquired date
  acquiredDate?: string;
  // mileage
  mileage?: number;
  // dofu
  dofu?: string;
  // comments
  comments?: string;
  // decerify reason
  deCertifyReason?: string;
  // Dealer Code that the vehicle is allocated to
  dealerCd?: string;
  // dealer name
  dealerName?: string;
  // exterior color
  extColor?: models.Color;
  // interior color
  intColor?: models.Color;
  // selected
  selected?: boolean;
  // car fax flag
  carFaxFlag?: string;
  // certification status
  certificationStatus?: string;
  // certification status
  certificationNumber?: string;

  updatedAt?: string;

  rdrDetail?: models.RdrDetail;

  areaRegionNo?: string;

  regionName?: string;

  createdBy?: string;

  modelDesc?: string;

  monthObj?: number;

  totalLexusMonthly?: number;

  perMonthObj?: number;
  modelDescription?: string;
  dailyCertifiedSalesCount?: number;
  monthlyCertifiedSalesCount?: number;
  monthlyCertifiedReversalCount?: number;
  dailyCertifiedInventory?: number;
  monthlyCertifiedInventory?: number;
  inventoryLessThan60Days?: number;
  // certifiedInventory?: number;
  inventoryTotal?: number;
  currentMonthRegionObjective?: string;
  // Number.parseFloat(certificationObj).toFixed(0)
  certificationObj?: string;
  certificationObjective?: string;
  reginalPercent?: string;
  salesObjPercent?: number;
  salesObjective?: number;
  certObjPercent?: number;
  totalLexusDaily?: number;
  lexusTotalObj?: number;
  // totalLexusMonthly?: number;
  salesAvail?: number;
  certifiedInventory?: number;
  monthlyNonCertifiedSalesCount?: number;
  dailyNonCertifiedSalesCount?: number;
  certificationTotal?: number;
  totalInvCount?: number;
  yearlyCertifiedInventory?: number;
  yearlyCertifiedSalesCount?: number;
  customCertifiedInventory?: number;
  customCertifiedSalesCount?: number;

  goldDailyCertifiedSalesCount?: number;
  silverDailyCertifiedSalesCount?: number;
  certifiedSalesCount?: number;
  goldCertifiedSalesCount?: number;
  silverCertifiedSalesCount?: number;
  goldDailyCertifiedInventory?: number;
  silverDailyCertifiedInventory?: number;
  goldCertifiedInventory?: number;
  silverCertifiedInventory?: number;
  invCount?: number;
  goldInvCount?: number;
  silverInvCount?: number;
  districtNumber?: any;
  regionNumber?: any;
  certChange?: any;
  certChangePercent?: any;
  currentYearObjective?: any;
  prevYearlyCertifiedInventory?: any;
  prevYearlyCertifiedSalesCount?: any;
  prevYearlyInvCount?: any;
  salesChange?: any;
  salesChangePercent?: any;
  certAfterSaleCount?: any;
  certAfterSalePercent?: any;
  sameDayCertCount?: any;
  sameDayCertPercent?: any;
  totSalesCount?: any;
  totalPercent?: any;
}
