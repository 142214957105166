'use strict';

// Color Details
export interface Buyer {
  // Color Code
  salution?: string;
  // Color Swatch URL
  firstName?: string;
  // Color Marketing Description
  middleName?: string;
  // Color Marketing Description
  lastName?: string;
  // Color Display Name
  suffix?: string;
  cosuffix?: string;
  // Color Marketing Description
  addressLineOne?: string;
  // Color Marketing Description
  addressLineTwo?: string;
  // Color Display Name
  city?: string;
  // Color Display Name
  state?: string;
  // Color Display Name
  zipCode?: string;
  // Color Display Name
  homePhone?: string;
  // Color Display Name
  workPhone?: string;
  // Color Display Name
  extension?: string;
  // Color Display Name
  emailPermissionInd?: string;
  // Color Display Name
  email?: string;

  taxid?: string;

  contactMethod?: string;

  customerConsent?: boolean;

}
