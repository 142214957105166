'use strict';

export interface DealerDetailsModel {
  // Dealer code
  dealerCode?: string;
  // Dealer name
  dealerName?: string;
  // Dealer address
  address?: string;
  // Dealer city
  city?: string;
  // Dealer state
  state?: string;
  // Dealer zip code
  zip?: string;
  // Dealer phone number
  phone?: string;
  // Dealer brand
  brand?: string;
  // Dealer area/region number
  areaRegionNo?: string;
  // Dealer area/region name
  areaRegionName?: string;
  // Dealer district number
  districtNo?: string;
  // Dealer select
  selected?: boolean;

  certDlrFlag?: string;

  fuelCellDlrFlag?: string;

  updatedAt?: string;

  comments?: string;

  janObjective?: string;

  febObjective?: string;

  marObjective?: string;

  aprObjective?: string;

  mayObjective?: string;

  junObjective?: string;

  julObjective?: string;

  augObjective?: string;

  sepObjective?: string;

  octObjective?: string;

  novObjective?: string;

  decObjective?: string;

  terminatedFlag?: string;

  terminatedDate?: string;
}
