




import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { VehicleDetailsService } from '../services/api/vehicle-detail.service';

import * as detailActions from '../actions/vehicle-detail.action';

import * as models from '../services/model/models';
import {map, catchError, switchMap} from 'rxjs/operators'

/**
 * Class used to handle the service interaction in the vehicle detail screen
 */
@Injectable()
export class VehicleDetailsEffects {
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getVehicleByVins$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.INITIAL_LOAD)
    ,map((action: ActionBase) => action.payload)
    ,switchMap(data =>
      this.vehicleDetailsService.getVehicleByVin(data)
        .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.InitialLoadSuccessAction(vehicleData))
          , catchError(error => of(new detailActions.InitialLoadFailAction(
          { 'error': error, 'vehicleData': data })))
    )));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getVinInfo$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.LOAD_VIN_INFORMATION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(vin =>
      this.vehicleDetailsService.getVinInfo(vin)
        .pipe(map((vehicleData: any) =>
          new detailActions.LoadVinInformationSuccessAction(vehicleData))
        , catchError(error => of(new detailActions.LoadVinInformationFailAction(error)))
    )));
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  addNewVehicle$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.ADD_VEHICLE_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleDetailsService.saveVehicle(data)
        .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.AddNewVehicleSuccessAction(vehicleData))
        , catchError(error => of(new detailActions.AddNewVehicleFailAction(error)))
    )));
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  updateVehicle$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.UPDATE_VEHICLE_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleDetailsService.updateVehicle(data)
        .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.UpdateVehicleSuccessAction(vehicleData))
        , catchError(error => of(new detailActions.UpdateVehicleFailAction(error)))
    )));
  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  updateCertificate$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.UPDATE_CERTIFICATE_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleDetailsService.updateVehicle(data)
        .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.UpdateCertificateSuccessAction(vehicleData))
        , catchError(error => of(new detailActions.UpdateCertificateFailAction(error)))
    )));

  /**
   * Method used to delete the vehicle
   */
  @Effect()
  deleteVehicle$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.DELETE_VEHICLE_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleDetailsService.deleteVehicle(data)
        .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.DeleteVehicleSuccessAction(vehicleData))
        , catchError(error => of(new detailActions.DeleteVehicleFailAction(error)))
    )));

  /**
   * Method used to decertify the vehicle
   */
  @Effect()
  decertifyVehicle$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.DECERTIFY_VEHICLE_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleDetailsService.updateVehicle(data)
        .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.DecertifyVehicleSuccessAction(vehicleData))
        , catchError(error => of(new detailActions.DecertifyVehicleFailAction(error)))
    )));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getRdrByVins$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.LOAD_VEHICLE_FOR_CERTIFY_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleDetailsService.getRdrByVin(data)
        .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.LoadVehicleForCertifySuccessAction(vehicleData))
        , catchError(error => of(new detailActions.LoadVehicleForCertifyFailAction(
          { 'error': error, 'vehicleData': data })))
    )));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  getSaleByVins$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.LOAD_VEHICLE_FROM_SALE_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleDetailsService.getSaleByVin(data)
        .pipe(map((vehicleData: models.Vehicle) =>
          new detailActions.LoadVehicleFromSaleSuccessAction(vehicleData))
        , catchError(error => of(new detailActions.LoadVehicleFromSaleFailAction(
          { 'error': error, 'vehicleData': data })))
    )));

  /**
   * Method used to get the vehicle detail data on Initial Load
   */
  @Effect()
  updateCertificateFromSale$: Observable<Action> = this.actions$
    .pipe(ofType(detailActions.actionTypes.UPDATE_CERTIFICATE_FROM_SALE_ACTION)
    ,map((action: ActionBase) => action.payload)
    , switchMap(data =>
      this.vehicleDetailsService.certifyVehicleFromSale(data)
        .pipe(map((vehicleData: models.Rdr) =>
          new detailActions.UpdateCertificateFromSaleSuccessAction(vehicleData))
        , catchError(error => of(new detailActions.UpdateCertificateFromSaleFailAction(error)))
    )));


  /**
   * Constructor method used to create new instance of VehicleDetailsEffects class
   */
  constructor(private actions$: Actions, private vehicleDetailsService: VehicleDetailsService) { }
}
