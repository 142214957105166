import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCDealerInventoryReportRoutingModule } from './dealer-inventory-report-routing.module';
import { UCInventoryPageReportComponent } from './dealer-inventory-report-page.component';
import { UCInventorySummaryReportGridComponent } from './dealer-inventory-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCDealerInventoryReportRoutingModule
  ],
  exports: [
    UCInventoryPageReportComponent
  ],
  declarations: [
    UCInventoryPageReportComponent,
    UCInventorySummaryReportGridComponent
  ]
})

export class UCDealerInventoryReportModule {

}
