import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SharedDataService } from '../../../services/common/shared-data.service';

@Component({
  selector: 'uc-accounting-menubar-panel',
  templateUrl: 'accounting-menubar-panel.component.html',
  styleUrls: ['./accounting-menubar-panel.component.scss']
})

export class UCAccountingMenubarPanelComponent {
  @Input() isAccountingSelected: boolean;
  @Input() isDataVisible: boolean;

  @Input() selectedCertificationStatus: string;
  // Event to emit on navigating to accounting detail screen
  @Output() navigateToAccountingDetailEvent = new EventEmitter<number>();
  @Output() navigateToAccountingSummaryEvent = new EventEmitter<number>();
  @Output() navigateToAccountingDetailEventNew = new EventEmitter<number>();

  @Output() exportExcelEvent = new EventEmitter<number>();
  // Event to emit when clear all button is clicked
  @Output() clearAllButtonEvent = new EventEmitter();
  // Boolean variable to show/hide the clear all button
  @Input() showClearAllButton: boolean;
  // Variable to display the total accounting count
  @Input() totalAccountingCount: Number;

  constructor(public sharedDataService: SharedDataService) {

  }

  /**
   * Method to handle the navigation to accounting detail screen
   */
  navigateToAccountingDetailsScreen() {
    this.navigateToAccountingDetailEvent.emit();
  }

  /**
   * Method to handle the navigation to accounting detail screen
   */
  navigateToAccountingScreenNew() {
    this.navigateToAccountingDetailEventNew.emit();
  }

  /**
   * Method to handle when clear all button is clicked
   */
  clearAllButtonClicked(event: any) {
    this.clearAllButtonEvent.emit();
  }


  navigateToAccountingSummary() {
    this.navigateToAccountingSummaryEvent.emit();
  }

  exportExcel() {
    this.exportExcelEvent.emit();
  }

}
