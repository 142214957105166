<div fxLayout="column">
  <!-- <vehicle-report-vin-search-toolbar fxFlex="100"(filterDateEvent)="reportLoad($event)"
         [enteredvin]="enteredvin" [enteredDealerCode]="enteredDealerCode"></vehicle-report-vin-search-toolbar>
         -->
  <uc-objective-menubar-panel [isInventorySelected]="isInventorySelected"
    [selectedCertificationStatus]="selectedCertificationStatus" [selectedMakeCode]="selectedMakeCode"
    (clearAllButtonEvent)="clearAllFilters();" [showClearAllButton]="showClearAllButton"
    [totalInventoriesCount]="totalInventoriesCount" (exportPdfEvent)="downLoadPdf()"
    (exportExcelEvent)="downLoadXlsx()" [isDownloadVisible]="isDownloadVisible"></uc-objective-menubar-panel>

  <uc-inventory-grid [columnDefs]="columnDefs" [rows]="regionRowItems" (applySortEvent)="applyInventorySort($event)"
    (applyFilterEvent)="ApplyFilter($event)" [clearSort]="clearSort" [selectedMonth]="selectedMonth"
    [selectedYear]="selectedYear" (fetchObjectiveEvent)="dataLoad($event)" (saveRegionObjectiveEvent)="saveRegionObjective()">
  </uc-inventory-grid>
</div>
