import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// dealer #
      key: ViewConstants.VIN, fieldName: 'vin', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '250',
      columnMinWidth: '250', columnMaxWidth: '250', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// dealer #
      key: ViewConstants.DEALER_COLUMN, fieldName: 'dealerCd', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// dealer #
      key: ViewConstants.DEALER_NAME_COLUMN, fieldName: 'dealerName', style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// vin
      key: ViewConstants.DEALER_REGION_STRING_COLUMN, fieldName: 'regionName',
      style: '', hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '185', columnMinWidth: '185',
      columnMaxWidth: '185', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// CERTIFICATION NO
      key: ViewConstants.DEALER_DISTRICT_COLUMN, fieldName: 'districtNumber',
      style: ' ', hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// model
      key: ViewConstants.INV_STATUS, fieldName: 'invStatus', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// model
      key: ViewConstants.RDR_STATUS, fieldName: 'rdrStatus', style: ' ',
      hasTemplate: 'false', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '',
      filterType: FilterType.CONTAINS
    },
    {// Plan Code
      key: ViewConstants.COMMENT, fieldName: 'comment',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '100',
      columnMinWidth: '100', columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }, {// whole sale
      key: ViewConstants.CUSTOMER_NAME, fieldName: 'customerName',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '150',
      columnMinWidth: '150', columnMaxWidth: '150', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    }
  ];
}
