import { ViewConstants } from '../../../../shared/shared.constants';
import { ValidationTypes } from '../../../../services/model/ValidatorTypes';
import { Filter, FilterType } from '../../../../services/model/Filter';

let validationTypes: typeof ValidationTypes = ValidationTypes;

export class InventorySummaryGridColumns {
  static gridColumns = [
    {// Region No
      key: ViewConstants.SERIES_CODE, fieldName: 'model',
      style: ' ',
      hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '120',
      columnMinWidth: '120', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// DEALER NO
      key: ViewConstants.YEAR, fieldName: 'modelYear', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '100', columnMinWidth: '100',
      columnMaxWidth: '100', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// dealer name
      key: ViewConstants.CERTIFIED_SALES, fieldName: 'certifiedSales', style: '',
      hasTemplate: 'true',
      sortable: 'true', template: '', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'false', filterValue: '',
      dataTypeformat: validationTypes.ALPHANUMERIC_17_WITHOUTSPACE,
      filterType: FilterType.CONTAINS
    },
    {// Count
      key: ViewConstants.AVG_CERT_PRICE, fieldName: 'avgCertPrice',
      style: ' ', hasTemplate: 'true', template: '', sortable: 'true', columnWidth: '200',
      columnMinWidth: '200', columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// Source
      key: ViewConstants.NON_CERTIFIED_SALES, fieldName: 'nonCertifiedSales', style: ' ',
      hasTemplate: 'false',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'false', filterValue: '',
      dataTypeformat: '', filterType: FilterType.CONTAINS
    },
    {// De Certify reason
      key: ViewConstants.AVG_NON_CERT_PRICE, fieldName: 'avgNonCertPrice',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '200', columnMinWidth: '200',
      columnMaxWidth: '200', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    },
    {// De Certify reason
      key: ViewConstants.TOTAL_COUNT, fieldName: 'totalCount',
      style: ' ', hasTemplate: 'true',
      template: '', sortable: 'true', columnWidth: '160', columnMinWidth: '160',
      columnMaxWidth: '160', currencyField: 'true', filterValue: '',
      dataTypeformat: '', filterType: FilterType.STARTS_WITH
    }
  ];
}
