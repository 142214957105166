'use strict';
import { UCInventorySummary } from '../model/InventorySummary';
import { Message } from '../model/Message';

// Vehicle Summary View Model
export interface UCInventorySummaryView {
  endDate?: string;

  startDate?: string;
  // List of vehicles
  inventories: UCInventorySummary[];
  // GST-SET Datas
  GstData?: UCInventorySummary[];
  // message object
  message?: Message;

  count?: number;
}
