import { NgModule } from '@angular/core';
import { UnauthorizedComponent } from './unauthorized.component';
import { UnauthorizedRoutingModule } from './unauthorized-routing.module';

@NgModule({
  imports: [
    UnauthorizedRoutingModule,
  ],
  exports: [
    UnauthorizedComponent
  ],
  declarations: [UnauthorizedComponent],
  providers: [],
})
export class UnauthorizedModule {
}
