import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import * as messageDataActions from '../../../actions/message-data.action';
import * as vehicleReportActions from '../../../actions/vehicle-report.action';
import * as fromRoot from '../../../reducers';
import { ExportToExcelService } from '../../../services/common/excel-service';
import { SharedDataService } from '../../../services/common/shared-data.service';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import * as models from '../../../services/model/models';
import { DealerDetailView } from '../../../services/view-model/dealer-detail-view';
import { UCInventorySummaryView } from '../../../services/view-model/inventory-summary-view';
import * as viewModels from '../../../services/view-model/view-models';
import { ViewConstants } from '../../../shared/shared.constants';
import { InventorySummaryGridColumns } from './json/UCLexusDealerReportGridColumns';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment'
import * as alasql from 'alasql';

@Component({
  selector: 'uc-inventory',
  templateUrl: 'lexus-dealer-participation-report-page.component.html'
})

export class UCLexusDealerParticipationReportComponent implements OnInit, OnDestroy {
  // Observable to save inventory screen data
  inventorySummaryData$: Observable<UCInventorySummaryView>;
  // Subscriber for Inventory Data
  inventorySummarySubscriber: any;
  routeParamSubscriber: any;
  // Array variable used to store columns
  public columnDefs: any[] = [];
  // Array variable to keep the original data for filter purpose
  originalData: Array<models.UCInventorySummary>;
  // Array variable to keep the filtered data for filter purpose
  filteredData: Array<models.UCInventorySummary>;
  // Array to hold the filters applied in the grid
  public filterArray: Array<models.Filter> = [];
  // Boolean to reveal whether the dealer code is selected or not
  isInventorySelected: boolean = false;
  // certificationStatus
  selectedCertificationStatus: string = '';
  // makeCode
  selectedMakeCode: string = '';
  // To store seleted vehicle index
  public selectedVehicleIndex: number = 0;
  // Boolean variable to show/hide the clear all button
  showClearAllButton: boolean;
  // Variable to display the inventories count
  totalInventoriesCount: Number = 0;
  // Observable to obtain VehicleDetailView object
  vehicleDetails$: Observable<viewModels.VehicleDetailView>;
  // Observable to obtain RdrDetailView object
  rdrDetails$: Observable<viewModels.RdrDetailView>;
  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<DealerDetailView>;
  // Dealer code entered in search toolbar
  enteredDealerCode: string;
  // vin entered in search toolbar
  enteredvin: string;
  // To hold the last sort event
  currentSortEvent: any;
  // to clear the current sorting for all columns
  clearSort: boolean = false;

  reportType: string;

  fromDate: string;

  toDate: string;

  reportStartDate: string;

  reportEndDate: string;

  reportHeaderText: string = '';

  reportFileName: string = '';
  // Private array variable to display the rows in grid
  private _rows: Array<models.UCInventorySummary>;
  // Property to set and get the rows
  get rows(): Array<models.UCInventorySummary> {
    return this._rows;
  }
  set rows(value: Array<models.UCInventorySummary>) {
    if (value.length === 1 && value[0].vin === '') {
      this.totalInventoriesCount = 0;
    } else {
      this.totalInventoriesCount = value.length;
    }
    this._rows = value;
  }
  // private moment = require('moment');
  // selected Disclaimer
  private row: models.UCInventorySummary = {};
  /**
   * Constructor for UCInventoryPageComponent
   */
  constructor(private store: Store<fromRoot.AppState>,
    public sharedFuncService: SharedFunctionService,
    public sharedDataService: SharedDataService,
    public router: Router,
    public activeRouter: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private cb: CurrencyPipe,
    private exportToExcelService: ExportToExcelService) {
    // this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
    this.inventorySummaryData$ = store.select(fromRoot.getVehicleReport);
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    // this.routeParamSubscriber = this.activeRouter.params.subscribe(params => {
    //   if (!isNil(params['type'])) {
    //     this.reportType = params['type'];
    //     this.onLoad();
    //   }
    // });
    this.onLoad('m');
    this.inventorySummarySubscriber = this.inventorySummaryData$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        this.refreshFilters();
        this.enteredDealerCode = '';
        this.enteredDealerCode = (<any>Object).assign(this.enteredDealerCode, '');
        this.enteredvin = '';
        this.enteredvin = (<any>Object).assign(this.enteredvin, '');
        this.isInventorySelected = false;
        this.selectedCertificationStatus = '';
        this.selectedMakeCode = '';
        if (!isNil(data.message)) {
          this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
            data.message
          ));
        } else {
          if (data.inventories.length > 0) {
            let copyData = data.inventories;
            this.reportStartDate = data.startDate;
            this.reportEndDate = data.endDate;
            if (!isNil(this.sharedDataService.dealerData)) {
              copyData.forEach(element => {
                if (this.sharedDataService.dealerData
                  .filter(t => t.dealerCode === element.dealerCd).length > 0) {
                  element.dealerName = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode === element.dealerCd)[0].dealerName;
                  element.monthObj = this.getMonthlyObj(element);
                  if (element.totalLexusMonthly > 0)
                    element.perMonthObj =
                      (Number(element.monthObj) / element.totalLexusMonthly);
                  else element.perMonthObj = 0;
                } else {
                  element.dealerName = '';
                  element.areaRegionNo = '';
                }
              });
            }
            this.rows = cloneDeep(sortBy(copyData, 'VIN'));
            this.originalData = cloneDeep(data.inventories);
          } else {
            this.rows = cloneDeep([]);
          }
        }
      }
    });

  }
  /**
   * onLoad method
   */
  onLoad(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.reportType = event;
    let date = new Date();
    this.sharedFuncService.setCurrentScreenName(
      ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
    this.columnDefs = InventorySummaryGridColumns.gridColumns;
    this.sharedDataService.setSelectedVehicle('');
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    let report;
    if (this.reportType === 'm') {
      report = {
        reportMode: '14',
        currentMonth: this.sharedFuncService.getMonth(),
        currentYear: date.getFullYear(),
        makeCode: this.sharedDataService.brandName
      };
    } else if (this.reportType === 'y') {
      report = {
        reportMode: '12',
        currentYear: date.getFullYear(),
        makeCode: this.sharedDataService.brandName
      };
    }

    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
  }

  reportLoad(event: any) {
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    let report = {
      reportMode: '11',
      fromDate: event.fromDate,
      toDate: event.toDate,
      makeCode: this.sharedDataService.brandName
    };
    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
  }

  getMonthlyObj(element: any) {
    let date = new Date();
    let currentMonth = this.sharedFuncService.getMonth();
    if (currentMonth === 1) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].janObjective;
    } else if (currentMonth === 2) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].febObjective;
    } else if (currentMonth === 3) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].marObjective;
    } else if (currentMonth === 4) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].aprObjective;
    } else if (currentMonth === 5) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].mayObjective;
    } else if (currentMonth === 6) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].junObjective;
    } else if (currentMonth === 7) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].julObjective;
    } else if (currentMonth === 8) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].augObjective;
    } else if (currentMonth === 9) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].sepObjective;
    } else if (currentMonth === 10) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].octObjective;
    } else if (currentMonth === 11) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].novObjective;
    } else if (currentMonth === 12) {
      return this.sharedDataService.dealerData
        .filter(t => t.dealerCode === element.dealerCd)[0].decObjective;
    }
  }

  refreshFilters() {
    this.showClearAllButton = false;
    if (!isNil(this.currentSortEvent)) {
      this.applyInventorySort(this.currentSortEvent);
    }
    this.clearSort = false;
    InventorySummaryGridColumns.gridColumns.forEach(column => {
      column.filterValue = '';
    });
  }

  applyInventorySort(event: any) {
    this.currentSortEvent = event;
    this.rows =
      cloneDeep(this.sharedFuncService.applySort(this.rows, event));
  }

  ApplyFilter(filter: models.Filter) {
    let tempFilter: models.Filter = null;
    let existingFilterInArrayIndex: number = 0;
    if (this.filterArray !== null && this.filterArray !== undefined) {
      this.filterArray.forEach(data => {
        if (data.filterType === filter.filterType
          && data.columnname === filter.columnname) {
          tempFilter = data;
        }
        if (tempFilter === null) {
          existingFilterInArrayIndex++;
        }
      });
      if (tempFilter !== null && tempFilter !== undefined) {
        this.filterArray.splice(existingFilterInArrayIndex, 1);
      }
      if (filter.filterValue !== null && filter.filterValue !== undefined
        && filter.filterValue.trim() !== '') {
        tempFilter = filter;
        this.filterArray.push(tempFilter);
      }
      this.filteredData = cloneDeep(this.originalData);
      this.filterArray.forEach(arrayElement => {
        if (!isNil(arrayElement.filterValue) && arrayElement.filterValue !== undefined &&
          arrayElement.filterValue.trim() !== '') {
          if (arrayElement.filterType === models.FilterType.CONTAINS) {
            this.applyContainsFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.STARTS_WITH) {
            this.applyStartsWithFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.SPACES) {
            this.applySpacesFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.PRICE_COLUMN) {
            this.applyPriceFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.DATE_SYMBOL) {
            this.applyDateFilter(arrayElement);
          }
        }
      });
      if (this.filterArray.length > 0) {
        this.showClearAllButton = true;
      } else {
        this.showClearAllButton = false;
      }
      // if (this.filteredData.length === 0) {
      //     let test: models.UCInventorySummary = {};
      //     test.vin = '';
      //     this.filteredData.push(test);
      // }
      this.rows = cloneDeep(this.filteredData);
    }
  }

  applyContainsFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = String(this.accessProperties(inventory, filterElement.columnname));
      return !isNil(propertyValue) && propertyValue.toLowerCase().indexOf(filterValue) > -1;
    });
  }

  applyStartsWithFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = this.accessProperties(inventory, filterElement.columnname);
      return !isNil(propertyValue) &&
        String(propertyValue).toLowerCase()
          .startsWith(filterValue);
    });
  }

  applySpacesFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      return !isNil(inventory.accessoriesDetails) &&
        inventory.accessoriesDetails !== undefined
        && this.accessoriesFilter(inventory.accessoriesDetails, filterValue);
    });
  }

  accessoriesFilter(accessories: models.AccessoryDetails[], value: string): boolean {
    let filterValues = String(value).split(' ');
    let returnValue = false;
    let positiveCount = 0;
    if (!isNil(accessories) && accessories.length > 0) {
      filterValues.forEach(filter => {
        if (accessories.filter(acc =>
          acc.accessssoriesCode.trim().toLowerCase() === filter ||
          acc.accessssoriesCode.trim().toLowerCase().startsWith(filter)).length > 0) {
          positiveCount++;
        }
      });
    }
    if (positiveCount === filterValues.length) {
      return true;
    }
    return false;
  }

  applyPriceFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    // if (fullFilterValue.length > 1) {
    let firstLetter = fullFilterValue.substr(0, 1);
    let filterVal = Number.parseFloat(fullFilterValue.substr(1, fullFilterValue.length));
    this.filteredData = this.filteredData.filter(inventory => {
      let propValue = this.accessProperties(inventory, filterElement.columnname);
      if (!isNil(propValue)) {
        if (firstLetter === '<') {
          return Number.parseFloat(propValue) <= filterVal;
        } else if (firstLetter === '>') {
          return Number.parseFloat(propValue) >= filterVal;
        } else if (firstLetter === '=') {
          return Number.parseFloat(propValue) === filterVal;
        } else {
          filterVal = Number.parseFloat(fullFilterValue.
            substr(0, fullFilterValue.length));
          return Number.parseFloat(propValue) === filterVal;
        }
      }
      return true;
    });
    // }
  }

  applyDateFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    if (fullFilterValue.length > 1) {
      let firstLetter = fullFilterValue.substr(0, 1);
      let filterVal = fullFilterValue.substr(1, fullFilterValue.length);
      this.filteredData = this.filteredData.filter(inventory => {
        let propValue = this.accessProperties(inventory, filterElement.columnname);
        if (!isNil(propValue)) {
          if (firstLetter === '<') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') <= d;
            }
          } else if (firstLetter === '>') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') >= d;
            }

          } else if (firstLetter === '=') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }

          } else {
            filterVal = fullFilterValue.
              substr(0, fullFilterValue.length);
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }
          }
        }
        return true;
      });
    }
  }

  accessProperties(object: any, str: string) {
    let propertiesString = str.split('.');
    let i = 0;
    propertiesString.forEach(val => {
      object = object[propertiesString[i]];
      i++;
    });
    return object;
  }

  clearAllFilters() {
    this.rows = cloneDeep(this.originalData);
    this.filteredData = cloneDeep(this.originalData);
    this.filterArray = [];
    this.refreshFilters();
  }

  getFormattedTime() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let h = today.getHours();
    let mi = today.getMinutes();
    let s = today.getSeconds();
    return y + '' + m + '' + d + '' + h + '' + mi + '' + s;
  }

  getReportInitData() {
    if (this.reportType === 'y' && this.sharedDataService.brandName === 'LEXUS') {
      this.reportHeaderText
        = 'SUMMARY OF SALES & STOCK INFORMATION BY DEALERSHIP FOR EACH REGION FOR LEXUS DEALERS - [R20244]';
      this.reportFileName =
        'LexusDealerParticipationReport' + this.getFormattedTime();
    } else if (this.reportType === 'm' && this.sharedDataService.brandName === 'LEXUS') {
      this.reportHeaderText =
        'SUMMARY OF SALES & STOCK INFORMATION BY DEALERSHIP FOR EACH REGION FOR LEXUS DEALERS - [R20244]';
      this.reportFileName =
        'LexusDealerParticipationReport' + this.getFormattedTime();
    }
  }

  getFormattedDateForDisplay() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    return m + '/' + d + '/' + y;
  }

  getDateRange(type: string): string {
    let date = new Date();
    if (type === 'm') {
      return this.sharedFuncService.convertDateDisplay(this.reportStartDate)
        + ' THRU ' + this.sharedFuncService.convertDateDisplay(this.reportEndDate);
    } else if (type === 'y') {
      return '01/01/' + date.getFullYear() + ' THRU ' + '12/31/' + date.getFullYear();
    } else if (type === 'd') {
      return this.convertDateDisplay(date) + ' THRU ' + this.convertDateDisplay(date);
    }
  }

  convertDateDisplay(fromdate: any) {
    let date = new Date(fromdate);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return month + '/' + dt + '/' + year;
  }


  // To print the Inventory grid data
  downLoadPdf() {
    this.getReportInitData();
    this.reportHeaderText = this.reportHeaderText.toUpperCase();
    let inventoryData = this.inventoryGridData();
    let doc = new jsPDF('landscape', 'mm', 'a2');
    let totalPagesExp = '{total_pages_count_string}';
    doc.setFontSize(15);
    doc.text('Report Generated Date: ' + this.getFormattedDateForDisplay(), 500, 22);
    doc.setFontSize(20);
    doc.text(170, 15, ViewConstants.APPLICATION_TITLE_FOR_REPORT);
    doc.setFontSize(15);
    doc.text(180, 25, this.reportHeaderText);
    doc.text(230, 32, this.getDateRange('m'));


    let pageContent = function (data) {
      let str = 'Page ' + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' of ' + totalPagesExp;
      }
      let pageSize = doc.internal.pageSize;
      let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.setFontSize(12);
      doc.text(str, 5, pageHeight - 10);
    };

    doc.autoTable(inventoryData.columns, inventoryData.rows, {
      startY: 40,
      styles: { overflow: 'linebreak', fontSize: 13, columnWidth: 'auto' },
      columnStyles: { text: { columnWidth: 'auto' } },
      addPageContent: pageContent
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save(this.reportFileName + '.pdf');
    this.sharedDataService.showLoader = false;
  }

  // To download the xlsx in inventory screen
  downLoadXlsx() {
    this.getReportInitData();
    let exportData = {
      'title': this.reportHeaderText.toUpperCase(),
      'range': this.getDateRange('m'),
      'header': this.inventoryGridData().columns,
      'data': this.inventoryGridData().rows,
      'isTotalGrid': false,
      'totalHeader': null,
      'totalData': null,
      'generationDate': this.getFormattedDateForDisplay(),
      'fileName': 'LexusDivisionPreOwnedDailySalesReport'
    };
    this.exportToExcelService.exportAsExcelFile(exportData);
    this.sharedDataService.showLoader = false;
  }

  // To download the csv in inventory screen
  downLoadCsv() {
    let tempData: any;
    let inventoryDataNew: Array<any> = [];
    this.rows.forEach(row => {
      tempData = {};
      InventorySummaryGridColumns.gridColumns.forEach(column => {
        let columnField = this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
        tempData[column.key] = columnField;
      });
      inventoryDataNew.push(tempData);
    });
    alasql('SELECT * INTO CSV("inventorySummary.csv",{headers:true, separator:","}) FROM ?',
      [inventoryDataNew]);
    this.sharedDataService.showLoader = false;

  }

  // To get inventory grid data
  inventoryGridData(): any {
    let columns: Array<any> = [];
    let rows: Array<any> = [];
    if (InventorySummaryGridColumns.gridColumns && this.rows) {
      InventorySummaryGridColumns.gridColumns.forEach(column => {
        columns.push(column.key);
      });
      let tempRows: Array<any> = [];
      this.rows.forEach(row => {
        tempRows = [];
        InventorySummaryGridColumns.gridColumns.forEach(column => {
          let columnField = this.sharedFuncService.recurseObjProp(
            row, column.fieldName, 0);
          tempRows.push(columnField);
        });
        rows.push(tempRows);
      });
    }
    let temps: any = {};
    temps.rows = rows;
    temps.columns = columns;
    return temps;
  }


  ngOnDestroy() {
    if (this.inventorySummarySubscriber) {
      this.inventorySummarySubscriber.unsubscribe();
    }
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    // this.inventorySummaryData$ = null;
  }

}
