export class UCRDRReportJson {
  static count: number = 0;
  static data: Array<any> = [
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFNY5F14HX222989042222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T04:21:08.243Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23394.00',
          mileage: '31307'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9441028,
        makeCode: 'TOYOTA',
        vin: '5TFNY5F14HX222989',
        model: {
          modelDescription: 'TUNDRA 4X2 SR REG CAB LONG BED',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8228',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T22:59:39.295Z'
        }
      },
      sort: [
        1591676468243
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZRFH6HS217043042222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T04:18:39.442Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24991.00',
          mileage: '30675'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9494006,
        makeCode: 'TOYOTA',
        vin: '5TDZZRFH6HS217043',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6947',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:27:06.934Z'
        }
      },
      sort: [
        1591676319442
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK8HU211717042222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T04:17:20.202Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18792.00',
          mileage: '44228'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9451722,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK8HU211717',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T22:31:27.161Z'
        }
      },
      sort: [
        1591676240202
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP624687042222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T04:16:17.062Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '13347.00',
          mileage: '44425'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9487521,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP624687',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:18:21.360Z'
        }
      },
      sort: [
        1591676177062
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC9HS877640042222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T04:15:28.421Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '25991.00',
          mileage: '51962'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9480563,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC9HS877640',
        model: {
          modelDescription: 'SIENNA SE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5343',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T17:31:34.697Z'
        }
      },
      sort: [
        1591676128421
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0HD150207042222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T04:14:18.541Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22993.00',
          mileage: '46652'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9445171,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0HD150207',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-16T18:53:45.550Z'
        }
      },
      sort: [
        1591676058541
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU703589042222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T04:12:04.776Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16991.00',
          mileage: '29792'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9493851,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU703589',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:16:05.888Z'
        }
      },
      sort: [
        1591675924776
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN5GM021884042222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T04:10:19.435Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '29450.00',
          mileage: '33492'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9496384,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN5GM021884',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:04:16.170Z'
        }
      },
      sort: [
        1591675819435
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU718107360992020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T03:53:20.022Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15995.00',
          mileage: '36725'
        },
        sellArea: '120',
        dealerCd: '36099',
        certificationNumber: 9474519,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU718107',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:34:14.220Z'
        }
      },
      sort: [
        1591674800022
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU329147110352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T03:50:12.198Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18039.64',
          mileage: '29456'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9468138,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU329147',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T13:20:30.196Z'
        }
      },
      sort: [
        1591674612198
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV5JJ214215360992020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T03:49:15.619Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '31212'
        },
        sellArea: '220',
        dealerCd: '36099',
        certificationNumber: 9442012,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV5JJ214215',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T20:54:51.775Z'
        }
      },
      sort: [
        1591674555619
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU760147110302020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:49:53.728Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20598.00',
          mileage: '24856'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9467450,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU760147',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T20:19:53.533Z'
        }
      },
      sort: [
        1591670993728
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBY5F15JX703469110302020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:47:02.324Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '32816.00',
          mileage: '84558'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9496209,
        makeCode: 'TOYOTA',
        vin: '5TFBY5F15JX703469',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8352',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:26:20.096Z'
        }
      },
      sort: [
        1591670822324
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS400655461052020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:44:05.948Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '29997.00',
          mileage: '36051'
        },
        sellArea: '150',
        dealerCd: '46105',
        certificationNumber: 9478505,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS400655',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T00:01:00.663Z'
        }
      },
      sort: [
        1591670645948
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU236195461052020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:41:53.368Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17971.25',
          mileage: '24115'
        },
        sellArea: '150',
        dealerCd: '46105',
        certificationNumber: 9489697,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU236195',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T19:10:14.126Z'
        }
      },
      sort: [
        1591670513368
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE0LJ024662461052020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:39:53.067Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18469.00',
          mileage: '15826'
        },
        sellArea: '600',
        dealerCd: '46105',
        certificationNumber: 9488163,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE0LJ024662',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:57:58.207Z'
        }
      },
      sort: [
        1591670393067
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN8HX090673461052020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:37:58.868Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28953.00',
          mileage: '53200'
        },
        sellArea: '130',
        dealerCd: '46105',
        certificationNumber: 9489941,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN8HX090673',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7543',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T21:28:11.821Z'
        }
      },
      sort: [
        1591670278868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU746281461052020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:36:22.428Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17448.00',
          mileage: '22578'
        },
        sellArea: '130',
        dealerCd: '46105',
        certificationNumber: 9485419,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU746281',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:43:20.414Z'
        }
      },
      sort: [
        1591670182428
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC812985461052020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:34:17.189Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15551.00',
          mileage: '30866'
        },
        sellArea: '130',
        dealerCd: '46105',
        certificationNumber: 9498213,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC812985',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:41:29.327Z'
        }
      },
      sort: [
        1591670057189
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV6HJ137703150502020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:18:37.128Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '25637.00',
          mileage: '24413'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9501521,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV6HJ137703',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:01:15.253Z'
        }
      },
      sort: [
        1591669117128
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC4HS813571042902020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:12:19.346Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24225.00',
          mileage: '47114'
        },
        sellArea: '110',
        dealerCd: '04290',
        certificationNumber: 9478557,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC4HS813571',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T01:03:01.504Z'
        }
      },
      sort: [
        1591668739346
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV6HW330413042902020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:11:31.408Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21300.00',
          mileage: '23298'
        },
        sellArea: '110',
        dealerCd: '04290',
        certificationNumber: 9503750,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV6HW330413',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:42:48.066Z'
        }
      },
      sort: [
        1591668691408
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8JD228885040882020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:04:40.048Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22299.00',
          mileage: '26915'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9460384,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8JD228885',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T00:23:26.952Z'
        }
      },
      sort: [
        1591668280048
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU784971260312020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T02:01:24.488Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15395.00',
          mileage: '27040'
        },
        sellArea: '220',
        dealerCd: '26031',
        certificationNumber: 9476478,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU784971',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:01:54.219Z'
        }
      },
      sort: [
        1591668084488
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F16GX507042371382020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:54:51.527Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '26688.00',
          mileage: '71107'
        },
        sellArea: '800',
        dealerCd: '37138',
        certificationNumber: 9461953,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F16GX507042',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T15:37:53.791Z'
        }
      },
      sort: [
        1591667691527
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU402720045762020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:54:05.347Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17881.00',
          mileage: '41356'
        },
        sellArea: '110',
        dealerCd: '04576',
        certificationNumber: 9432265,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU402720',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T20:01:31.808Z'
        }
      },
      sort: [
        1591667645347
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV4HY167131045762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:53:46.667Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13749.00',
          mileage: '22284'
        },
        sellArea: '110',
        dealerCd: '04576',
        certificationNumber: 9485669,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV4HY167131',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:49:37.577Z'
        }
      },
      sort: [
        1591667626667
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC8HS154137371382020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:53:24.988Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '25988.00',
          mileage: '42386'
        },
        dealerCd: '37138',
        certificationNumber: 9348528,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC8HS154137',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-22T13:56:19.352Z'
        }
      },
      sort: [
        1591667604988
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV4JY329636110302020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:52:35.728Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14000.00',
          mileage: '23785'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9489119,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV4JY329636',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T15:05:46.361Z'
        }
      },
      sort: [
        1591667555728
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2JW759731110302020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:51:02.588Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '26853.00',
          mileage: '20914'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9496190,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2JW759731',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:17:13.683Z'
        }
      },
      sort: [
        1591667462588
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW600201260312020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:47:41.757Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20245.00',
          mileage: '27077'
        },
        sellArea: '150',
        dealerCd: '26031',
        certificationNumber: 9486372,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW600201',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:24:34.724Z'
        }
      },
      sort: [
        1591667261757
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1HK2JU004518060022020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:46:16.087Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '26999.0000',
          mileage: '13263'
        },
        sellArea: '160',
        dealerCd: '06002',
        certificationNumber: 9497206,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1HK2JU004518',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2554',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:50:33.466Z'
        }
      },
      sort: [
        1591667176087
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DU4F1959218060022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:46:13.347Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14999.0000',
          mileage: '53186'
        },
        sellArea: '160',
        dealerCd: '06002',
        certificationNumber: 9414854,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DU4F1959218',
        model: {
          modelDescription: 'PRIUS Two Model',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T11:51:25.253Z'
        }
      },
      sort: [
        1591667173347
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV7HW692953060022020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:46:11.088Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23899.00',
          mileage: '30131'
        },
        sellArea: '160',
        dealerCd: '06002',
        certificationNumber: 9491049,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV7HW692953',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T12:42:24.625Z'
        }
      },
      sort: [
        1591667171088
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4JC993401190722020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:38:34.367Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15400.00',
          mileage: '37438'
        },
        sellArea: '800',
        dealerCd: '19072',
        certificationNumber: 9503683,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4JC993401',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:11:51.727Z'
        }
      },
      sort: [
        1591666714367
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK3GR517190291142020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:37:06.067Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15900.00',
          mileage: '47506'
        },
        dealerCd: '29114',
        certificationNumber: 9389978,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK3GR517190',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T00:11:15.497Z'
        }
      },
      sort: [
        1591666626067
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU753484190722020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:36:20.107Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16372.00',
          mileage: '30378'
        },
        sellArea: '160',
        dealerCd: '19072',
        certificationNumber: 9503646,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU753484',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:57:22.324Z'
        }
      },
      sort: [
        1591666580107
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU9H3539387041042020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:34:16.987Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16792.00',
          mileage: '48291'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9478530,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU9H3539387',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T00:31:55.616Z'
        }
      },
      sort: [
        1591666456987
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU3FJ039173291142020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:29:24.087Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16800.00',
          mileage: '61073'
        },
        sellArea: '160',
        dealerCd: '29114',
        certificationNumber: 9495435,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU3FJ039173',
        model: {
          modelDescription: 'PRIUS v Three Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1245',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:23:32.295Z'
        }
      },
      sort: [
        1591666164087
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV7GJ093007041042020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:28:47.047Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18494.00',
          mileage: '33122'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9419947,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV7GJ093007',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T16:10:58.973Z'
        }
      },
      sort: [
        1591666127047
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9GP560794041042020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:24:33.747Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '13484.00',
          mileage: '30451'
        },
        sellArea: '220',
        dealerCd: '04104',
        certificationNumber: 9490226,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9GP560794',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T01:39:49.384Z'
        }
      },
      sort: [
        1591665873747
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC7HS851056041042020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:21:17.565Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '24992.00',
          mileage: '35392'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9476305,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC7HS851056',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:02:20.187Z'
        }
      },
      sort: [
        1591665677565
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9JU017421270202020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:20:48.947Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19988.00',
          mileage: '50905'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9488562,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9JU017421',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:13:28.902Z'
        }
      },
      sort: [
        1591665648947
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX7JR040432270202020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:20:03.808Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19365.50',
          mileage: '30383'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9467807,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX7JR040432',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T00:21:20.987Z'
        }
      },
      sort: [
        1591665603808
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV6KW003863270202020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:18:52.646Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23300.00',
          mileage: '25015'
        },
        sellArea: '600',
        dealerCd: '27020',
        certificationNumber: 9497590,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV6KW003863',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:15:10.174Z'
        }
      },
      sort: [
        1591665532646
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F19KX863610270202020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:18:11.245Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '38659.71',
          mileage: '9132'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9494831,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F19KX863610',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T00:31:09.952Z'
        }
      },
      sort: [
        1591665491245
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC3HS834738041042020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:18:07.745Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '32791.00',
          mileage: '13124'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9494157,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC3HS834738',
        model: {
          modelDescription: 'SIENNA LTD PREM FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5357',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:28:50.049Z'
        }
      },
      sort: [
        1591665487745
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBXXK1044230270202020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:17:04.007Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24533.00',
          mileage: '8060'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9459262,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBXXK1044230',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T22:45:24.130Z'
        }
      },
      sort: [
        1591665424007
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4GW527893360992020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:16:53.387Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21999.00',
          mileage: '50993'
        },
        sellArea: '110',
        dealerCd: '36099',
        certificationNumber: 9412793,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4GW527893',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-08T01:01:13.618Z'
        }
      },
      sort: [
        1591665413387
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE2LJ018367410832020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:16:29.705Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18360.00',
          mileage: '20738'
        },
        sellArea: '600',
        dealerCd: '41083',
        certificationNumber: 9433884,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE2LJ018367',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T15:58:10.336Z'
        }
      },
      sort: [
        1591665389705
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV5HW571972270202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:16:11.466Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20681.46',
          mileage: '39018'
        },
        sellArea: '210',
        dealerCd: '27020',
        certificationNumber: 9497571,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV5HW571972',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:09:49.407Z'
        }
      },
      sort: [
        1591665371466
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV6HJ701990410832020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:15:57.887Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16621.00',
          mileage: '66330'
        },
        sellArea: '220',
        dealerCd: '41083',
        certificationNumber: 9454348,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV6HJ701990',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T22:12:38.175Z'
        }
      },
      sort: [
        1591665357887
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX1JR011993041042020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:15:43.166Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20491.00',
          mileage: '18506'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9490229,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX1JR011993',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T01:45:17.816Z'
        }
      },
      sort: [
        1591665343166
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU405850190722020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:13:07.064Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16718.40',
          mileage: '23270'
        },
        sellArea: '160',
        dealerCd: '19072',
        certificationNumber: 9502498,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU405850',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T15:50:04.772Z'
        }
      },
      sort: [
        1591665187064
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F19FX420520270202020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:12:29.187Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '35488.00',
          mileage: '40149'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9499803,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F19FX420520',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8378',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:08:59.893Z'
        }
      },
      sort: [
        1591665149187
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV3KW009474270202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:11:32.667Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22067.00',
          mileage: '25286'
        },
        sellArea: '600',
        dealerCd: '27020',
        certificationNumber: 9486449,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV3KW009474',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:16:02.039Z'
        }
      },
      sort: [
        1591665092667
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3J5609610042322020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:10:56.086Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '38446.00',
          mileage: '22429'
        },
        sellArea: '150',
        dealerCd: '04232',
        certificationNumber: 9495603,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3J5609610',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:40:53.994Z'
        }
      },
      sort: [
        1591665056086
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC4HS858837270202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:10:05.325Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24888.00',
          mileage: '19018'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9499807,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC4HS858837',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:11:38.656Z'
        }
      },
      sort: [
        1591665005325
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV7HJ149335041042020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:09:27.725Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20891.00',
          mileage: '26008'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9496339,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV7HJ149335',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:39:30.127Z'
        }
      },
      sort: [
        1591664967725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV9HJ136693270202020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:09:20.085Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20666.34',
          mileage: '83895'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9493095,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV9HJ136693',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:48:02.430Z'
        }
      },
      sort: [
        1591664960085
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXJJ736096041042020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:06:19.105Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22991.00',
          mileage: '34959'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9478529,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXJJ736096',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T00:29:00.045Z'
        }
      },
      sort: [
        1591664779105
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS515348270202020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:05:29.926Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28988.00',
          mileage: '26486'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9497616,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS515348',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:25:42.497Z'
        }
      },
      sort: [
        1591664729926
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH5HS027489041042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T01:02:03.657Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '37991.00',
          mileage: '15853'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9496037,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH5HS027489',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:00:44.501Z'
        }
      },
      sort: [
        1591664523657
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU440707041042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:58:57.053Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17591.00',
          mileage: '21155'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9496297,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU440707',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:13:05.691Z'
        }
      },
      sort: [
        1591664337053
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMN1RFV7KD508598041042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:55:37.393Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26394.00',
          mileage: '18489'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9410479,
        makeCode: 'TOYOTA',
        vin: 'JTMN1RFV7KD508598',
        model: {
          modelDescription: 'RAV4 LIMITED AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T22:16:13.592Z'
        }
      },
      sort: [
        1591664137393
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXKW044248250602020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:43:33.724Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '26990.00',
          mileage: '17913'
        },
        sellArea: '150',
        dealerCd: '25060',
        certificationNumber: 9433072,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXKW044248',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T17:44:58.836Z'
        }
      },
      sort: [
        1591663413724
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3KS623608250602020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:40:48.305Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '36990.00',
          mileage: '7121'
        },
        sellArea: '130',
        dealerCd: '25060',
        certificationNumber: 9447158,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3KS623608',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T14:50:35.032Z'
        }
      },
      sort: [
        1591663248305
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC852220340892020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:38:46.544Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15888.00',
          mileage: '9775'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9503954,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC852220',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T23:29:32.478Z'
        }
      },
      sort: [
        1591663126544
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV4HY170644371812020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:38:21.389Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '12300.00',
          mileage: '16737'
        },
        dealerCd: '37181',
        certificationNumber: 9391270,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV4HY170644',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T21:11:55.979Z'
        }
      },
      sort: [
        1591663101389
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV7KW005851360842020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:35:53.451Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '36971.00',
          mileage: '15421'
        },
        dealerCd: '36084',
        certificationNumber: 9352344,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV7KW005851',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-24T16:38:17.530Z'
        }
      },
      sort: [
        1591662953451
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2FU013626110352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:35:47.800Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13900.00',
          mileage: '79161'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9487243,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2FU013626',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:40:37.080Z'
        }
      },
      sort: [
        1591662947800
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU424697371812020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:34:39.059Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16512.00',
          mileage: '24400'
        },
        sellArea: '800',
        dealerCd: '37181',
        certificationNumber: 9471136,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU424697',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:27:00.481Z'
        }
      },
      sort: [
        1591662879059
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMYFREV0JD127383044212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:40.299Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29999.0000',
          mileage: '8982'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9487283,
        makeCode: 'TOYOTA',
        vin: 'JTMYFREV0JD127383',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:57:00.346Z'
        }
      },
      sort: [
        1591662760299
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6FW406434044212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:37.620Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18999.0000',
          mileage: '75209'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9486957,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6FW406434',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:46:56.323Z'
        }
      },
      sort: [
        1591662757620
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6FP212983044212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:32.402Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13999.0000',
          mileage: '71174'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9486962,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6FP212983',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:48:30.527Z'
        }
      },
      sort: [
        1591662752402
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJY5G14LS176494044212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:30.083Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '61224.5500',
          mileage: '2821'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9451682,
        makeCode: 'TOYOTA',
        vin: '5TDJY5G14LS176494',
        model: {
          modelDescription: 'SEQUOIA 4X4 LTD 8-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7929',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T21:50:33.636Z'
        }
      },
      sort: [
        1591662750083
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7GC570191044212020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:27.882Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '13988.0000',
          mileage: '83594'
        },
        sellArea: '150',
        dealerCd: '04421',
        certificationNumber: 9438056,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7GC570191',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T22:01:25.138Z'
        }
      },
      sort: [
        1591662747882
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KC003693044212020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:25.023Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25977.0000',
          mileage: '19419'
        },
        dealerCd: '04421',
        certificationNumber: 9373168,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KC003693',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T22:54:07.702Z'
        }
      },
      sort: [
        1591662745023
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KC006437044212020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:22.222Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25977.0000',
          mileage: '23262'
        },
        dealerCd: '04421',
        certificationNumber: 9388921,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KC006437',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T15:43:51.994Z'
        }
      },
      sort: [
        1591662742222
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE7LJ003263044212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:19.541Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20988.0000',
          mileage: '20899'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9425829,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE7LJ003263',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T19:15:26.616Z'
        }
      },
      sort: [
        1591662739541
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK4JU504620044212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:16.843Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24999.0000',
          mileage: '25185'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9467468,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK4JU504620',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T20:28:03.693Z'
        }
      },
      sort: [
        1591662736843
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFU0K3012216044212020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:14.143Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '26999.0000',
          mileage: '7321'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9459103,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFU0K3012216',
        model: {
          modelDescription: 'PRIUS LE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1263',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T20:54:12.956Z'
        }
      },
      sort: [
        1591662734143
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXKW027028044212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:10.583Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25977.0000',
          mileage: '22872'
        },
        dealerCd: '04421',
        certificationNumber: 9380472,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXKW027028',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-13T23:00:56.937Z'
        }
      },
      sort: [
        1591662730583
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC4HS870986044212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:08.163Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '33977.0000',
          mileage: '16456'
        },
        dealerCd: '04421',
        certificationNumber: 9375643,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC4HS870986',
        model: {
          modelDescription: 'SIENNA LTD PREM FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5357',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-10T22:58:56.109Z'
        }
      },
      sort: [
        1591662728163
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9F5266311044212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:04.301Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30999.0000',
          mileage: '70178'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9497344,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9F5266311',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:39:37.434Z'
        }
      },
      sort: [
        1591662724301
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMW1RFV4KJ003348044212020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:32:01.463Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '24499.0000',
          mileage: '39825'
        },
        sellArea: '600',
        dealerCd: '04421',
        certificationNumber: 9475036,
        makeCode: 'TOYOTA',
        vin: 'JTMW1RFV4KJ003348',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:56:58.488Z'
        }
      },
      sort: [
        1591662721463
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMW1RFV3KD512636044212020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:31:59.083Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '28599.0000',
          mileage: '7469'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9427769,
        makeCode: 'TOYOTA',
        vin: 'JTMW1RFV3KD512636',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T22:20:34.836Z'
        }
      },
      sort: [
        1591662719083
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS431481044212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:31:56.490Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29999.0000',
          mileage: '50617'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9486211,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS431481',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:54:21.592Z'
        }
      },
      sort: [
        1591662716490
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK3J3049576044212020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:31:53.961Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20999.0000',
          mileage: '40910'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9431251,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK3J3049576',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T21:45:14.340Z'
        }
      },
      sort: [
        1591662713961
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEM5F12HX119419044212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:31:51.363Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '28977.0000',
          mileage: '20224'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9499830,
        makeCode: 'TOYOTA',
        vin: '5TFEM5F12HX119419',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8259',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:18:56.979Z'
        }
      },
      sort: [
        1591662711363
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV3KD506100044212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:31:47.841Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '26977.00',
          mileage: '27808'
        },
        dealerCd: '04421',
        certificationNumber: 9379983,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV3KD506100',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-13T19:45:52.400Z'
        }
      },
      sort: [
        1591662707841
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK6KU825910450132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:29:54.442Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26935.00',
          mileage: '16556'
        },
        sellArea: '800',
        dealerCd: '45013',
        certificationNumber: 9480846,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK6KU825910',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T20:18:13.004Z'
        }
      },
      sort: [
        1591662594442
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9J5524318450132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:26:56.662Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '36745.00',
          mileage: '18462'
        },
        sellArea: '800',
        dealerCd: '45013',
        certificationNumber: 9502118,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9J5524318',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T13:16:09.215Z'
        }
      },
      sort: [
        1591662416662
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC5HS771511040722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:24:38.899Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27390.00',
          mileage: '21080'
        },
        sellArea: '120',
        dealerCd: '04072',
        certificationNumber: 9500189,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC5HS771511',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:19:44.928Z'
        }
      },
      sort: [
        1591662278899
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU279752450132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:24:16.778Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14985.00',
          mileage: '43912'
        },
        sellArea: '800',
        dealerCd: '45013',
        certificationNumber: 9494275,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU279752',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:04:57.800Z'
        }
      },
      sort: [
        1591662256778
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F13EX054118360992020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:22:34.039Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26995.00',
          mileage: '45385'
        },
        sellArea: '130',
        dealerCd: '36099',
        certificationNumber: 9500483,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F13EX054118',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8340',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:29:32.447Z'
        }
      },
      sort: [
        1591662154039
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH0FS210892450132020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:20:37.857Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28627.00',
          mileage: '25869'
        },
        sellArea: '800',
        dealerCd: '45013',
        certificationNumber: 9494366,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH0FS210892',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:39:14.420Z'
        }
      },
      sort: [
        1591662037857
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU642191040722020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:19:18.035Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17700.00',
          mileage: '5858'
        },
        sellArea: '110',
        dealerCd: '04072',
        certificationNumber: 9497527,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU642191',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:49:17.442Z'
        }
      },
      sort: [
        1591661958035
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV5HD067463040722020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:16:46.773Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23900.00',
          mileage: '57555'
        },
        sellArea: '120',
        dealerCd: '04072',
        certificationNumber: 9486398,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV5HD067463',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:43:25.749Z'
        }
      },
      sort: [
        1591661806773
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS416641340932020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:16:37.193Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '27400.00',
          mileage: '40754'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9482026,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS416641',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:53:12.458Z'
        }
      },
      sort: [
        1591661797193
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC909794340932020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:15:45.054Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14542.54',
          mileage: '15139'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9452613,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC909794',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T21:26:09.213Z'
        }
      },
      sort: [
        1591661745054
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU214918340932020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:15:15.154Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19149.05',
          mileage: '32539'
        },
        sellArea: '500',
        dealerCd: '34093',
        certificationNumber: 9433955,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU214918',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T16:37:00.481Z'
        }
      },
      sort: [
        1591661715154
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6JJ717109370672020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:11:19.732Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19850.00',
          mileage: '36380'
        },
        sellArea: '800',
        dealerCd: '37067',
        certificationNumber: 9468856,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6JJ717109',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:09:34.205Z'
        }
      },
      sort: [
        1591661479732
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4HJ133512370672020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:10:40.850Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '40817'
        },
        dealerCd: '37067',
        certificationNumber: 9388519,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4HJ133512',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T11:51:56.737Z'
        }
      },
      sort: [
        1591661440850
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKK3DC7FS551363370672020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:10:00.831Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '55482'
        },
        sellArea: '800',
        dealerCd: '37067',
        certificationNumber: 9484168,
        makeCode: 'TOYOTA',
        vin: '5TDKK3DC7FS551363',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:13:35.332Z'
        }
      },
      sort: [
        1591661400831
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2HS852440370672020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:09:16.590Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '40804'
        },
        sellArea: '160',
        dealerCd: '37067',
        certificationNumber: 9482309,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2HS852440',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:19:04.363Z'
        }
      },
      sort: [
        1591661356590
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2FJ049265260212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:09:09.970Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17979.00',
          mileage: '38961'
        },
        sellArea: '230',
        dealerCd: '26021',
        certificationNumber: 9499309,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2FJ049265',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:32:42.326Z'
        }
      },
      sort: [
        1591661349970
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW603823260212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:07:03.550Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19747.50',
          mileage: '44493'
        },
        sellArea: '230',
        dealerCd: '26021',
        certificationNumber: 9473010,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW603823',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T18:24:39.201Z'
        }
      },
      sort: [
        1591661223550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5EN8GX046652341222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:06:19.887Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24990.00',
          mileage: '27699'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9485223,
        makeCode: 'TOYOTA',
        vin: '5TFSX5EN8GX046652',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7516',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:01:12.602Z'
        }
      },
      sort: [
        1591661179887
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN5HM038377043882020-04-17V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:03:27.433Z',
          retailDate: '2020-04-17',
          rdrStatus: 'V',
          soldAmount: '27022.83',
          mileage: '44176'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9476608,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN5HM038377',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:56:58.525Z'
        }
      },
      sort: [
        1591661007433
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC851697340942020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:03:15.727Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14000.00',
          mileage: '32984'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9497618,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC851697',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:26:01.000Z'
        }
      },
      sort: [
        1591660995727
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW650033480612020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:03:09.828Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20723.00',
          mileage: '22759'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9477401,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW650033',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:09:49.530Z'
        }
      },
      sort: [
        1591660989828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F14HX607583480612020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:02:33.165Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '35416.00',
          mileage: '17966'
        },
        sellArea: '500',
        dealerCd: '48061',
        certificationNumber: 9410481,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F14HX607583',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T22:18:27.274Z'
        }
      },
      sort: [
        1591660953165
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV5KD507295260212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:02:24.826Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24999.00',
          mileage: '28364'
        },
        sellArea: '600',
        dealerCd: '26021',
        certificationNumber: 9499299,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV5KD507295',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:27:16.771Z'
        }
      },
      sort: [
        1591660944826
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC853230480612020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:01:34.526Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16993.00',
          mileage: '20892'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9475831,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC853230',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T15:08:02.139Z'
        }
      },
      sort: [
        1591660894526
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU795049480612020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:01:07.146Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14382.00',
          mileage: '36535'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9491934,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU795049',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:45:55.299Z'
        }
      },
      sort: [
        1591660867146
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F19JX745142480612019-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-09T00:00:21.328Z',
          retailDate: '2019-06-08',
          rdrStatus: 'H',
          soldAmount: '36544.00',
          mileage: '2847'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9503972,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F19JX745142',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T23:53:17.132Z'
        }
      },
      sort: [
        1591660821328
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU783318040802020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:58:57.245Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15495.00',
          mileage: '31715'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9493152,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU783318',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:06:45.125Z'
        }
      },
      sort: [
        1591660737245
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXED073859340942020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:57:36.683Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14191.67',
          mileage: '74634'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9496934,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXED073859',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:32:01.112Z'
        }
      },
      sort: [
        1591660656683
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU685376040802020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:57:34.311Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '19656'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9492254,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU685376',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:20:20.987Z'
        }
      },
      sort: [
        1591660654311
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F17GX531789240532020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:54:37.762Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '25588.00',
          mileage: '60542'
        },
        sellArea: '230',
        dealerCd: '24053',
        certificationNumber: 9487305,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F17GX531789',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:01:44.766Z'
        }
      },
      sort: [
        1591660477762
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHJ700811340942020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:35.542Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19696.6700',
          mileage: '16363'
        },
        dealerCd: '34094',
        certificationNumber: 9390523,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHJ700811',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T15:47:35.489Z'
        }
      },
      sort: [
        1591660415542
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW653127340942020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:33.280Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23000.0000',
          mileage: '29296'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9480310,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW653127',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T15:27:03.333Z'
        }
      },
      sort: [
        1591660413280
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC782983340942020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:28.652Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15450.0000',
          mileage: '19902'
        },
        dealerCd: '34094',
        certificationNumber: 9457734,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC782983',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T18:43:11.080Z'
        }
      },
      sort: [
        1591660408652
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW603677340942020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:25.901Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23793.7000',
          mileage: '32422'
        },
        dealerCd: '34094',
        certificationNumber: 9385995,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW603677',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T18:39:14.561Z'
        }
      },
      sort: [
        1591660405901
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2HJ150732340942020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:23.021Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17201.6700',
          mileage: '39229'
        },
        sellArea: '800',
        dealerCd: '34094',
        certificationNumber: 9471943,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2HJ150732',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T23:30:39.422Z'
        }
      },
      sort: [
        1591660403021
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC893961340942020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:20.420Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15000.0000',
          mileage: '10282'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9453552,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC893961',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T17:42:07.289Z'
        }
      },
      sort: [
        1591660400420
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB3XG1116056340942020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:17.772Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13837.9600',
          mileage: '24952'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9466791,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB3XG1116056',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB ONE',
          modelSeries: 'PRIUS C',
          modelNumber: '1201',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T14:51:44.939Z'
        }
      },
      sort: [
        1591660397772
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC8LS025542340942020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:14.501Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26602.4600',
          mileage: '20823'
        },
        sellArea: '220',
        certificationNumber: 9458335,
        dealerCd: '34094',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        vin: '5TDKZ3DC8LS025542',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T13:10:57.487Z'
        }
      },
      sort: [
        1591660394501
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU3H3529831340942020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:11.940Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21989.0000',
          mileage: '8843'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9469758,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU3H3529831',
        model: {
          modelDescription: 'PRIUS FOUR TOURING',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1228',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T15:07:39.479Z'
        }
      },
      sort: [
        1591660391940
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC847825340942020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:09.314Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13521.0000',
          mileage: '29316'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9471903,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC847825',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T23:12:46.636Z'
        }
      },
      sort: [
        1591660389314
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU544349340942020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:53:06.700Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20699.00',
          mileage: '29332'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9495170,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXJU544349',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:28:52.621Z'
        }
      },
      sort: [
        1591660386700
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW544346480452020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:51:14.920Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18899.00',
          mileage: '25801'
        },
        dealerCd: '48045',
        certificationNumber: 9345090,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW544346',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-20T19:34:35.981Z'
        }
      },
      sort: [
        1591660274920
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F15KX825940050432020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:48:51.599Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '42811.00',
          mileage: '4354'
        },
        sellArea: '150',
        dealerCd: '05043',
        certificationNumber: 9476883,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F15KX825940',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T04:58:24.671Z'
        }
      },
      sort: [
        1591660131599
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV6HD132391371792020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:48:05.318Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21987.00',
          mileage: '18726'
        },
        dealerCd: '37179',
        certificationNumber: 9289367,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV6HD132391',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-13T16:21:13.872Z'
        }
      },
      sort: [
        1591660085318
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH2HS211046040802020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:42:42.134Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '37486'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9493168,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH2HS211046',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6950',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:09:11.243Z'
        }
      },
      sort: [
        1591659762134
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HD206380260312020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:42:19.554Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20395.00',
          mileage: '31930'
        },
        sellArea: '230',
        dealerCd: '26031',
        certificationNumber: 9467516,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HD206380',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T20:50:03.383Z'
        }
      },
      sort: [
        1591659739554
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2HW658411371742020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:41:35.974Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20700.00',
          mileage: '17843'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9493761,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2HW658411',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:20:13.896Z'
        }
      },
      sort: [
        1591659695974
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP5J3085738290862020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:39:45.593Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24990.00',
          mileage: '24853'
        },
        sellArea: '160',
        dealerCd: '29086',
        certificationNumber: 9414936,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP5J3085738',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T13:26:54.831Z'
        }
      },
      sort: [
        1591659585593
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV8KD012394260212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:39:15.412Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24699.80',
          mileage: '29628'
        },
        sellArea: '500',
        dealerCd: '26021',
        certificationNumber: 9499270,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV8KD012394',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:19:14.279Z'
        }
      },
      sort: [
        1591659555412
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU681785290622020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:38:54.952Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18700.00',
          mileage: '16924'
        },
        sellArea: '160',
        dealerCd: '29062',
        certificationNumber: 9503964,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU681785',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T23:36:57.621Z'
        }
      },
      sort: [
        1591659534952
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU727662370832020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:37:46.132Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19697.00',
          mileage: '35550'
        },
        dealerCd: '37083',
        certificationNumber: 9384151,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU727662',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T19:34:14.031Z'
        }
      },
      sort: [
        1591659466132
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU251812470272020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:37:39.112Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19489.00',
          mileage: '12774'
        },
        sellArea: '800',
        dealerCd: '47027',
        certificationNumber: 9482380,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU251812',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:51:27.208Z'
        }
      },
      sort: [
        1591659459112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV3HD079973045432020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:37:37.470Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23750.00',
          mileage: '44198'
        },
        dealerCd: '04543',
        certificationNumber: 9376532,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV3HD079973',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-11T16:58:08.959Z'
        }
      },
      sort: [
        1591659457470
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP639666290862020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:35:34.368Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16490.00',
          mileage: '22721'
        },
        sellArea: '160',
        dealerCd: '29086',
        certificationNumber: 9488968,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP639666',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T13:31:50.922Z'
        }
      },
      sort: [
        1591659334368
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU417915040802020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:33:52.730Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '27028'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9482651,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU417915',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T23:59:41.016Z'
        }
      },
      sort: [
        1591659232730
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN7HM021417480072020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:33:02.090Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '31299.00',
          mileage: '28579'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9481959,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN7HM021417',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7568',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:30:00.462Z'
        }
      },
      sort: [
        1591659182090
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMYFREVXGJ070703040802020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:32:55.029Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '73225'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9469158,
        makeCode: 'TOYOTA',
        vin: 'JTMYFREVXGJ070703',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T21:41:55.281Z'
        }
      },
      sort: [
        1591659175029
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4ED062600290622020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:32:16.847Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '70350'
        },
        sellArea: '170',
        dealerCd: '29062',
        certificationNumber: 9494714,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4ED062600',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:14:45.032Z'
        }
      },
      sort: [
        1591659136847
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU748828121452020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:30:16.227Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18990.00',
          mileage: '34488'
        },
        sellArea: '500',
        dealerCd: '12145',
        certificationNumber: 9457814,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU748828',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T19:48:37.906Z'
        }
      },
      sort: [
        1591659016227
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ120832121452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:29:33.628Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '21292'
        },
        dealerCd: '12145',
        certificationNumber: 9355921,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HJ120832',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-27T21:08:57.927Z'
        }
      },
      sort: [
        1591658973628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXJ5544030371812020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:29:26.708Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '33000.00',
          mileage: '39121'
        },
        sellArea: '800',
        dealerCd: '37181',
        certificationNumber: 9483721,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXJ5544030',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:25:08.775Z'
        }
      },
      sort: [
        1591658966708
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7HJ138897121452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:28:54.167Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '28780'
        },
        sellArea: '220',
        dealerCd: '12145',
        certificationNumber: 9492248,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7HJ138897',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:18:20.025Z'
        }
      },
      sort: [
        1591658934167
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9JW703036290622020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:28:27.946Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23388.81',
          mileage: '38639'
        },
        sellArea: '800',
        dealerCd: '29062',
        certificationNumber: 9424828,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9JW703036',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T11:02:24.993Z'
        }
      },
      sort: [
        1591658907946
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV6HW332045121452020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:28:06.766Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17977.00',
          mileage: '16396'
        },
        dealerCd: '12145',
        certificationNumber: 9355981,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV6HW332045',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-27T21:20:33.194Z'
        }
      },
      sort: [
        1591658886766
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV0HW366307045412020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:27:53.367Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21685.00',
          mileage: '21976'
        },
        sellArea: '120',
        dealerCd: '04541',
        certificationNumber: 9474771,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV0HW366307',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:46:04.233Z'
        }
      },
      sort: [
        1591658873367
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU256404040802020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:27:15.046Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18695.00',
          mileage: '18921'
        },
        sellArea: '600',
        dealerCd: '04080',
        certificationNumber: 9473640,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU256404',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T23:04:10.975Z'
        }
      },
      sort: [
        1591658835046
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU6L3106220020362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:26:50.926Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22986.00',
          mileage: '6469'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9484247,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU6L3106220',
        model: {
          modelDescription: 'PRIUS LE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:47:27.223Z'
        }
      },
      sort: [
        1591658810926
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU744187040802020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:26:31.226Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15995.00',
          mileage: '49903'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9473633,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU744187',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T23:02:18.377Z'
        }
      },
      sort: [
        1591658791226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3N1RFV6KC015371120772020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:25:31.444Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '9577'
        },
        sellArea: '210',
        dealerCd: '12077',
        certificationNumber: 9474494,
        makeCode: 'TOYOTA',
        vin: '2T3N1RFV6KC015371',
        model: {
          modelDescription: 'RAV4 LIMITED AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:19:14.609Z'
        }
      },
      sort: [
        1591658731444
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GNXKX154904020362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:25:24.565Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '27361.87',
          mileage: '23403'
        },
        sellArea: '600',
        dealerCd: '02036',
        certificationNumber: 9502336,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GNXKX154904',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T15:02:44.511Z'
        }
      },
      sort: [
        1591658724565
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP629838121452020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:25:23.160Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14477.00',
          mileage: '35856'
        },
        sellArea: '110',
        dealerCd: '12145',
        certificationNumber: 9492219,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP629838',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:11:20.767Z'
        }
      },
      sort: [
        1591658723160
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP598597121452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:24:50.700Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12995.00',
          mileage: '52814'
        },
        sellArea: '600',
        dealerCd: '12145',
        certificationNumber: 9492357,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP598597',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:38:08.481Z'
        }
      },
      sort: [
        1591658690700
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC834634121452020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:24:17.630Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13160.00',
          mileage: '28466'
        },
        sellArea: '210',
        dealerCd: '12145',
        certificationNumber: 9492209,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC834634',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:06:13.896Z'
        }
      },
      sort: [
        1591658657630
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDCRFH4GS015716480452020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:23:41.315Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '32995.00',
          mileage: '28492'
        },
        sellArea: '210',
        dealerCd: '48045',
        certificationNumber: 9495333,
        makeCode: 'TOYOTA',
        vin: '5TDDCRFH4GS015716',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:45:26.882Z'
        }
      },
      sort: [
        1591658621315
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE4LJ005460121452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:23:35.857Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16990.00',
          mileage: '4008'
        },
        sellArea: '210',
        dealerCd: '12145',
        certificationNumber: 9492301,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE4LJ005460',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:28:51.100Z'
        }
      },
      sort: [
        1591658615857
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3HW542709340262020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:23:22.996Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '49918'
        },
        sellArea: '220',
        dealerCd: '34026',
        certificationNumber: 9477286,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3HW542709',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T13:47:46.402Z'
        }
      },
      sort: [
        1591658602996
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU733682020362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:23:21.524Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20748.74',
          mileage: '29623'
        },
        sellArea: '600',
        dealerCd: '02036',
        certificationNumber: 9500272,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU733682',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:40:13.473Z'
        }
      },
      sort: [
        1591658601524
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9G5394307121452020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:22:55.282Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29777.00',
          mileage: '46018'
        },
        dealerCd: '12145',
        certificationNumber: 9338492,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9G5394307',
        model: {
          modelDescription: '4RUNNER 4X4 TRAIL PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-15T21:24:36.885Z'
        }
      },
      sort: [
        1591658575282
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU696613260312020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:22:08.475Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '36309'
        },
        sellArea: '600',
        dealerCd: '26031',
        certificationNumber: 9493194,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU696613',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:23:48.050Z'
        }
      },
      sort: [
        1591658528475
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE2LJ038280020362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:21:53.096Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16992.00',
          mileage: '25457'
        },
        sellArea: '500',
        dealerCd: '02036',
        certificationNumber: 9483176,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE2LJ038280',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:13:10.817Z'
        }
      },
      sort: [
        1591658513096
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU270767070092020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:20:52.095Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14636.00',
          mileage: '50276'
        },
        sellArea: '800',
        dealerCd: '07009',
        certificationNumber: 9414010,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU270767',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T19:36:21.267Z'
        }
      },
      sort: [
        1591658452095
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV9KW028411020362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:19:04.971Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24980.00',
          mileage: '27053'
        },
        sellArea: '500',
        dealerCd: '02036',
        certificationNumber: 9500246,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV9KW028411',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:33:20.316Z'
        }
      },
      sort: [
        1591658344971
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP574824410702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:12:11.607Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15995.00',
          mileage: '35445'
        },
        sellArea: '230',
        dealerCd: '41070',
        certificationNumber: 9457820,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP574824',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T19:53:15.935Z'
        }
      },
      sort: [
        1591657931607
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC828477410702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:09:57.804Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13995.00',
          mileage: '8954'
        },
        sellArea: '160',
        dealerCd: '41070',
        certificationNumber: 9478341,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC828477',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:03:54.297Z'
        }
      },
      sort: [
        1591657797804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV9HJ145956190502020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:09:57.016Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23388.00',
          mileage: '40581'
        },
        dealerCd: '19050',
        certificationNumber: 9123597,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV9HJ145956',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-08-27T16:21:32.427Z'
        }
      },
      sort: [
        1591657797016
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU301195040802020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:09:46.816Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17495.00',
          mileage: '31972'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9477672,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU301195',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:10:44.923Z'
        }
      },
      sort: [
        1591657786816
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3ED087293070082020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:09:04.396Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16388.00',
          mileage: '35678'
        },
        sellArea: '800',
        dealerCd: '07008',
        certificationNumber: 9475140,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3ED087293',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T22:05:05.970Z'
        }
      },
      sort: [
        1591657744396
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC864401040802020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:09:01.002Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '25122'
        },
        sellArea: '220',
        dealerCd: '04080',
        certificationNumber: 9486007,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC864401',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:54:03.650Z'
        }
      },
      sort: [
        1591657741002
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW662540371552020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:08:42.862Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18342.01',
          mileage: '29255'
        },
        sellArea: '800',
        dealerCd: '37155',
        certificationNumber: 9485023,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW662540',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T13:04:46.841Z'
        }
      },
      sort: [
        1591657722862
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DCXHS151238371062020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:08:07.701Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28000.00',
          mileage: '36420'
        },
        sellArea: '800',
        dealerCd: '37106',
        certificationNumber: 9475723,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DCXHS151238',
        model: {
          modelDescription: 'SIENNA XLE PREM AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5377',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T13:51:49.678Z'
        }
      },
      sort: [
        1591657687701
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3H5446905050342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:07:48.019Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33776.00',
          mileage: '31045'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9503907,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3H5446905',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T22:57:56.773Z'
        }
      },
      sort: [
        1591657668019
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0GP538697410702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:07:11.854Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '12554.00',
          mileage: '41332'
        },
        sellArea: '230',
        dealerCd: '41070',
        certificationNumber: 9457828,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0GP538697',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T19:57:26.481Z'
        }
      },
      sort: [
        1591657631854
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC923739070082020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:07:09.954Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14668.00',
          mileage: '23363'
        },
        sellArea: '160',
        dealerCd: '07008',
        certificationNumber: 9430514,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC923739',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T16:14:42.664Z'
        }
      },
      sort: [
        1591657629954
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU762707040802020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:05:44.394Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15515.00',
          mileage: '21805'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9482648,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU762707',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T23:56:52.334Z'
        }
      },
      sort: [
        1591657544394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC804728190502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:05:12.954Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14778.00',
          mileage: '25321'
        },
        sellArea: '800',
        dealerCd: '19050',
        certificationNumber: 9470814,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC804728',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T16:34:34.323Z'
        }
      },
      sort: [
        1591657512954
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HJ127065050342020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:04:54.094Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21420.2500',
          mileage: '33260'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9502332,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HJ127065',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T15:00:54.771Z'
        }
      },
      sort: [
        1591657494094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM098686050342020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:04:51.693Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34291.0000',
          mileage: '41494'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9502327,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM098686',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T14:58:19.515Z'
        }
      },
      sort: [
        1591657491693
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV9HJ132968040802020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:04:49.914Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18495.00',
          mileage: '34158'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9482650,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV9HJ132968',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T23:58:20.376Z'
        }
      },
      sort: [
        1591657489914
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HJ159416050342020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:04:49.039Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23330.67',
          mileage: '7868'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9502315,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HJ159416',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T14:55:58.173Z'
        }
      },
      sort: [
        1591657489039
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC840913070082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:03:41.976Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13977.00',
          mileage: '36052'
        },
        dealerCd: '07008',
        certificationNumber: 9430501,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC840913',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T16:06:52.867Z'
        }
      },
      sort: [
        1591657421976
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU790481070082020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:01:47.251Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15887.00',
          mileage: '49999'
        },
        sellArea: '800',
        dealerCd: '07008',
        certificationNumber: 9500368,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU790481',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:25:35.439Z'
        }
      },
      sort: [
        1591657307251
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH9GS299508450572020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:01:09.051Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29637.00',
          mileage: '36149'
        },
        sellArea: '800',
        dealerCd: '45057',
        certificationNumber: 9499108,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH9GS299508',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:35:57.366Z'
        }
      },
      sort: [
        1591657269051
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK9J3027565450572020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T23:00:26.892Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19920.00',
          mileage: '26540'
        },
        sellArea: '800',
        dealerCd: '45057',
        certificationNumber: 9452634,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK9J3027565',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T21:44:06.609Z'
        }
      },
      sort: [
        1591657226892
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3MWRFV9LW083113450572020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:58:38.909Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29600.00',
          mileage: '191'
        },
        sellArea: '800',
        dealerCd: '45057',
        certificationNumber: 9501416,
        makeCode: 'TOYOTA',
        vin: '2T3MWRFV9LW083113',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:44:39.990Z'
        }
      },
      sort: [
        1591657118909
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV7JJ732655450572020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:56:29.469Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18094.81',
          mileage: '49187'
        },
        sellArea: '800',
        dealerCd: '45057',
        certificationNumber: 9477737,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV7JJ732655',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:36:59.134Z'
        }
      },
      sort: [
        1591656989469
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F19GX509986270132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:55:37.607Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25494.00',
          mileage: '81067'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9500582,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F19GX509986',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T00:47:31.099Z'
        }
      },
      sort: [
        1591656937607
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN5GX032261450572020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:55:23.230Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '29000.00',
          mileage: '36817'
        },
        sellArea: '800',
        dealerCd: '45057',
        certificationNumber: 9487567,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN5GX032261',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:30:04.881Z'
        }
      },
      sort: [
        1591656923230
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7HJ148961260312020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:55:02.787Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18395.00',
          mileage: '47643'
        },
        sellArea: '210',
        dealerCd: '26031',
        certificationNumber: 9495486,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7HJ148961',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:46:47.512Z'
        }
      },
      sort: [
        1591656902787
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXKW031290270132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:54:00.427Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25549.00',
          mileage: '30579'
        },
        sellArea: '600',
        dealerCd: '27013',
        certificationNumber: 9488633,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXKW031290',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T01:18:07.043Z'
        }
      },
      sort: [
        1591656840427
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU357187041872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:50:06.523Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20960.00',
          mileage: '29507'
        },
        sellArea: '110',
        dealerCd: '04187',
        certificationNumber: 9460088,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU357187',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:22:06.293Z'
        }
      },
      sort: [
        1591656606523
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU235711041872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:49:07.225Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20503.00',
          mileage: '19727'
        },
        sellArea: '500',
        dealerCd: '04187',
        certificationNumber: 9499557,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU235711',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:44:46.523Z'
        }
      },
      sort: [
        1591656547225
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HJ117165280122020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:48:29.723Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20875.59',
          mileage: '26759'
        },
        sellArea: '160',
        dealerCd: '28012',
        certificationNumber: 9428582,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HJ117165',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T17:21:07.951Z'
        }
      },
      sort: [
        1591656509723
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH3HS420022371882020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:47:58.043Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24998.00',
          mileage: '36347'
        },
        sellArea: '160',
        dealerCd: '37188',
        certificationNumber: 9415390,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH3HS420022',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T17:44:53.636Z'
        }
      },
      sort: [
        1591656478043
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV6HW390656270222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:46:16.002Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23330.40',
          mileage: '31263'
        },
        sellArea: '120',
        dealerCd: '27022',
        certificationNumber: 9478479,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV6HW390656',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T23:28:08.135Z'
        }
      },
      sort: [
        1591656376002
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU756242270222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:45:29.021Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21420.34',
          mileage: '23892'
        },
        dealerCd: '27022',
        certificationNumber: 9388276,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU756242',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-19T22:38:54.051Z'
        }
      },
      sort: [
        1591656329021
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA13H8705934270222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:44:53.642Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19550.00',
          mileage: '46720'
        },
        sellArea: '150',
        dealerCd: '27022',
        certificationNumber: 9443959,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA13H8705934',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T18:00:29.209Z'
        }
      },
      sort: [
        1591656293642
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA13H8706484046492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:44:30.102Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18989.00',
          mileage: '19457'
        },
        sellArea: '110',
        dealerCd: '04649',
        certificationNumber: 9501686,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA13H8706484',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:56:27.812Z'
        }
      },
      sort: [
        1591656270102
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1LW086518270222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:44:19.001Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28994.00',
          mileage: '14936'
        },
        sellArea: '600',
        dealerCd: '27022',
        certificationNumber: 9466135,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1LW086518',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T20:37:06.711Z'
        }
      },
      sort: [
        1591656259001
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4LW086271270222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:43:38.681Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30249.00',
          mileage: '7376'
        },
        sellArea: '600',
        dealerCd: '27022',
        certificationNumber: 9464523,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4LW086271',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T17:54:57.367Z'
        }
      },
      sort: [
        1591656218681
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV0KW014865270222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:42:44.461Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26849.00',
          mileage: '28171'
        },
        sellArea: '600',
        dealerCd: '27022',
        certificationNumber: 9466170,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV0KW014865',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T20:48:56.205Z'
        }
      },
      sort: [
        1591656164461
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4GC714456410702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:41:26.639Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14555.00',
          mileage: '31555'
        },
        sellArea: '170',
        dealerCd: '41070',
        certificationNumber: 9469043,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4GC714456',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T20:31:27.218Z'
        }
      },
      sort: [
        1591656086639
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0HD109513371062020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:39:45.013Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22850.00',
          mileage: '32393'
        },
        sellArea: '800',
        dealerCd: '37106',
        certificationNumber: 9475688,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0HD109513',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T13:31:27.544Z'
        }
      },
      sort: [
        1591655985013
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU729907041192020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:39:24.809Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20991.00',
          mileage: '15171'
        },
        sellArea: '500',
        dealerCd: '04119',
        certificationNumber: 9501447,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU729907',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:09:07.703Z'
        }
      },
      sort: [
        1591655964809
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW621258210672020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:37:44.094Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '23176'
        },
        sellArea: '220',
        dealerCd: '21067',
        certificationNumber: 9496100,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW621258',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:36:30.125Z'
        }
      },
      sort: [
        1591655864094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9HW608289371062020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:37:14.391Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23836.00',
          mileage: '27168'
        },
        sellArea: '800',
        dealerCd: '37106',
        certificationNumber: 9489021,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9HW608289',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T14:00:45.215Z'
        }
      },
      sort: [
        1591655834391
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV7HJ111004040802020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:35:56.217Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '23929'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9500513,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV7HJ111004',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:49:44.917Z'
        }
      },
      sort: [
        1591655756217
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP601554040802020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:33:59.635Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14995.00',
          mileage: '19595'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9492269,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP601554',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:23:17.784Z'
        }
      },
      sort: [
        1591655639635
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC953238290712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:33:47.316Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14262.00',
          mileage: '21275'
        },
        dealerCd: '29071',
        certificationNumber: 9464312,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC953238',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T15:51:30.715Z'
        }
      },
      sort: [
        1591655627316
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3HM081921045362020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:31:46.293Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '29059.00',
          mileage: '31622'
        },
        sellArea: '110',
        dealerCd: '04536',
        certificationNumber: 9500463,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3HM081921',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:13:54.093Z'
        }
      },
      sort: [
        1591655506293
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN8GX019004370772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:30:28.913Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31630.00',
          mileage: '9588'
        },
        sellArea: '800',
        dealerCd: '37077',
        certificationNumber: 9499696,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN8GX019004',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:36:42.499Z'
        }
      },
      sort: [
        1591655428913
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS399772140442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:30:25.152Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29371.00',
          mileage: '31884'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9495538,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS399772',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:10:58.130Z'
        }
      },
      sort: [
        1591655425152
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU717151370772020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:29:58.914Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17300.0000',
          mileage: '15430'
        },
        sellArea: '160',
        dealerCd: '37077',
        certificationNumber: 9425418,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU717151',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T15:55:58.448Z'
        }
      },
      sort: [
        1591655398914
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU770699370772020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:29:56.334Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17500.0000',
          mileage: '18119'
        },
        sellArea: '800',
        dealerCd: '37077',
        certificationNumber: 9425425,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU770699',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T15:58:44.645Z'
        }
      },
      sort: [
        1591655396334
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV9JD231422370772020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:29:53.953Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22400.0000',
          mileage: '34047'
        },
        sellArea: '800',
        dealerCd: '37077',
        certificationNumber: 9502811,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV9JD231422',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T17:11:02.229Z'
        }
      },
      sort: [
        1591655393953
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV5HW363156140442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:28:33.133Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '27378'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9474187,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV5HW363156',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:04:44.371Z'
        }
      },
      sort: [
        1591655313133
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC944914210432020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:27:39.873Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14300.00',
          mileage: '43079'
        },
        sellArea: '220',
        dealerCd: '21043',
        certificationNumber: 9460819,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC944914',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1863',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:20:23.524Z'
        }
      },
      sort: [
        1591655259873
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV9JW485253290712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:26:34.931Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20508.30',
          mileage: '28555'
        },
        sellArea: '500',
        dealerCd: '29071',
        certificationNumber: 9501204,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV9JW485253',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:09:38.448Z'
        }
      },
      sort: [
        1591655194931
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK4HU221757140442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:26:29.470Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22977.00',
          mileage: '35752'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9436174,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK4HU221757',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-30T16:08:35.477Z'
        }
      },
      sort: [
        1591655189470
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV6JD174589140442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:25:27.010Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29499.00',
          mileage: '6638'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9485338,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV6JD174589',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:55:01.779Z'
        }
      },
      sort: [
        1591655127010
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU798953290712020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:25:13.612Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15533.00',
          mileage: '26239'
        },
        dealerCd: '29071',
        certificationNumber: 9370022,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU798953',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-05T22:31:51.475Z'
        }
      },
      sort: [
        1591655113612
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU222485290712020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:23:18.909Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20947.00',
          mileage: '11119'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9491873,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU222485',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:30:36.408Z'
        }
      },
      sort: [
        1591654998909
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK2GR551038140442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:23:13.308Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14961.00',
          mileage: '45411'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9487310,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK2GR551038',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:03:21.350Z'
        }
      },
      sort: [
        1591654993308
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0LC090612220542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:22:20.548Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26500.00',
          mileage: '4076'
        },
        sellArea: '500',
        dealerCd: '22054',
        certificationNumber: 9495871,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0LC090612',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:07:06.443Z'
        }
      },
      sort: [
        1591654940548
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DU8E1741359470282020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:22:01.230Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '13991.00',
          mileage: '58160'
        },
        sellArea: '800',
        dealerCd: '47028',
        certificationNumber: 9502413,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DU8E1741359',
        model: {
          modelDescription: 'PRIUS Two Model',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T15:26:38.511Z'
        }
      },
      sort: [
        1591654921230
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV3KC028602470252020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:21:47.528Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24997.00',
          mileage: '16825'
        },
        sellArea: '500',
        dealerCd: '47025',
        certificationNumber: 9503311,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV3KC028602',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T19:08:47.885Z'
        }
      },
      sort: [
        1591654907528
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU789979290712020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:21:45.114Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14896.51',
          mileage: '20095'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9414498,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU789979',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T23:13:35.363Z'
        }
      },
      sort: [
        1591654905114
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F18GX501233240262020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:21:43.137Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '32687.00',
          mileage: '38550'
        },
        sellArea: '230',
        dealerCd: '24026',
        certificationNumber: 9484331,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F18GX501233',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:33:55.820Z'
        }
      },
      sort: [
        1591654903137
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXGP520661140442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:21:07.335Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16977.00',
          mileage: '27305'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9502574,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXGP520661',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:05:28.455Z'
        }
      },
      sort: [
        1591654867335
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN5KM097260043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:20:46.595Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32983.00',
          mileage: '17557'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9503649,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN5KM097260',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:58:52.664Z'
        }
      },
      sort: [
        1591654846595
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8K5612567140442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:19:08.396Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32677.00',
          mileage: '25743'
        },
        sellArea: '600',
        dealerCd: '14044',
        certificationNumber: 9492105,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8K5612567',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:29:34.386Z'
        }
      },
      sort: [
        1591654748396
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC776890410702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:18:53.576Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '39767'
        },
        sellArea: '220',
        dealerCd: '41070',
        certificationNumber: 9476618,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC776890',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:06:30.077Z'
        }
      },
      sort: [
        1591654733576
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU782585290712020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:18:51.513Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16048.00',
          mileage: '36582'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9420793,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU782585',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T22:07:05.482Z'
        }
      },
      sort: [
        1591654731513
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKK3DC8ES462223140442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:18:08.952Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15999.00',
          mileage: '79995'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9495569,
        makeCode: 'TOYOTA',
        vin: '5TDKK3DC8ES462223',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:24:20.346Z'
        }
      },
      sort: [
        1591654688952
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP641816410702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:16:55.012Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13944.00',
          mileage: '12511'
        },
        sellArea: '600',
        dealerCd: '41070',
        certificationNumber: 9459051,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP641816',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T20:21:17.533Z'
        }
      },
      sort: [
        1591654615012
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV9HW369724046362020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:16:08.272Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19698.20',
          mileage: '34621'
        },
        sellArea: '120',
        dealerCd: '04636',
        certificationNumber: 9486084,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV9HW369724',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:49:14.078Z'
        }
      },
      sort: [
        1591654568272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV3HD126896046362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:14:41.362Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25999.00',
          mileage: '38090'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9491894,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV3HD126896',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:35:50.064Z'
        }
      },
      sort: [
        1591654481362
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP623923410702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:14:22.310Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '11255.00',
          mileage: '49976'
        },
        sellArea: '150',
        dealerCd: '41070',
        certificationNumber: 9493862,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP623923',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:20:35.529Z'
        }
      },
      sort: [
        1591654462310
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV3HD101827043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:14:13.930Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16737.00',
          mileage: '44663'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9503644,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV3HD101827',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:57:10.102Z'
        }
      },
      sort: [
        1591654453930
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP617345410702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:12:35.307Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17995.00',
          mileage: '31667'
        },
        sellArea: '220',
        dealerCd: '41070',
        certificationNumber: 9464735,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP617345',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T19:53:40.852Z'
        }
      },
      sort: [
        1591654355307
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HJ139714220542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:11:25.727Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18326.00',
          mileage: '26202'
        },
        sellArea: '230',
        dealerCd: '22054',
        certificationNumber: 9491094,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HJ139714',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T13:29:46.274Z'
        }
      },
      sort: [
        1591654285727
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU795456130612020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:08:37.425Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18358.00',
          mileage: '23100'
        },
        sellArea: '500',
        dealerCd: '13061',
        certificationNumber: 9503243,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU795456',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T18:53:30.948Z'
        }
      },
      sort: [
        1591654117425
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ145457311312020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:08:35.345Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '38360'
        },
        dealerCd: '31131',
        certificationNumber: 9380582,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ145457',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T00:16:24.705Z'
        }
      },
      sort: [
        1591654115345
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV3HJ705251043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:07:20.784Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19678.00',
          mileage: '44239'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9503640,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV3HJ705251',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:55:28.240Z'
        }
      },
      sort: [
        1591654040784
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP725901046492020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:07:13.245Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '13588.00',
          mileage: '35773'
        },
        sellArea: '110',
        dealerCd: '04649',
        certificationNumber: 9445668,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP725901',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T21:53:32.149Z'
        }
      },
      sort: [
        1591654033245
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV2HJ715009046492020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:06:28.742Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17300.00',
          mileage: '22980'
        },
        sellArea: '110',
        dealerCd: '04649',
        certificationNumber: 9479831,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV2HJ715009',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-24T02:00:01.620Z'
        }
      },
      sort: [
        1591653988742
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK8GR555417020162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:04:32.141Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15520.00',
          mileage: '28537'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9464500,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK8GR555417',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T17:41:20.350Z'
        }
      },
      sort: [
        1591653872141
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE8LP037887043092020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:04:09.482Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16998.00',
          mileage: '6745'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9491860,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE8LP037887',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:26:06.695Z'
        }
      },
      sort: [
        1591653849482
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH4HS433919260212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:02:46.110Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '35620'
        },
        sellArea: '230',
        dealerCd: '26021',
        certificationNumber: 9498383,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH4HS433919',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T00:11:22.910Z'
        }
      },
      sort: [
        1591653766110
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK0JU158802020162020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:02:24.337Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25311.00',
          mileage: '27614'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9483733,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK0JU158802',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:28:01.241Z'
        }
      },
      sort: [
        1591653744337
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU062345190702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:01:45.939Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19499.00',
          mileage: '17511'
        },
        sellArea: '800',
        dealerCd: '19070',
        certificationNumber: 9433821,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU062345',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T15:13:39.861Z'
        }
      },
      sort: [
        1591653705939
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU443280020162020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:01:21.179Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '39003'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9495755,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU443280',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:29:02.886Z'
        }
      },
      sort: [
        1591653681179
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU049495260212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:00:47.818Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22876.00',
          mileage: '12594'
        },
        sellArea: '230',
        dealerCd: '26021',
        certificationNumber: 9485806,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU049495',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:27:54.717Z'
        }
      },
      sort: [
        1591653647818
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F1XHX675820371382020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:00:32.478Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '30988.00',
          mileage: '36372'
        },
        sellArea: '800',
        dealerCd: '37138',
        certificationNumber: 9476391,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F1XHX675820',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:27:14.328Z'
        }
      },
      sort: [
        1591653632478
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F10HX639480270132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T22:00:11.077Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34999.00',
          mileage: '41983'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9473015,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F10HX639480',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T18:26:41.597Z'
        }
      },
      sort: [
        1591653611077
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV2HY168133020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:59:50.117Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '12481.00',
          mileage: '34193'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9486412,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV2HY168133',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:49:30.795Z'
        }
      },
      sort: [
        1591653590117
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP682162043092020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:59:44.338Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '13988.00',
          mileage: '66501'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9500404,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP682162',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:42:50.517Z'
        }
      },
      sort: [
        1591653584338
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV6HD087498220542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:59:33.218Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23334.00',
          mileage: '34227'
        },
        sellArea: '220',
        dealerCd: '22054',
        certificationNumber: 9487085,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV6HD087498',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:42:52.265Z'
        }
      },
      sort: [
        1591653573218
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV8HY194882020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:59:29.057Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12381.00',
          mileage: '48024'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9476476,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV8HY194882',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:01:22.097Z'
        }
      },
      sort: [
        1591653569057
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK0J3018754020162020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:58:52.476Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19009.65',
          mileage: '16109'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9487879,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK0J3018754',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:29:36.530Z'
        }
      },
      sort: [
        1591653532476
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRY5F11FX178688020502020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:58:51.654Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23981.00',
          mileage: '69922'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9486378,
        makeCode: 'TOYOTA',
        vin: '5TFRY5F11FX178688',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DBL CAB 5.7L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8241',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:29:33.555Z'
        }
      },
      sort: [
        1591653531654
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV0KW019512371182020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:58:35.833Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '29155.00',
          mileage: '6164'
        },
        sellArea: '800',
        dealerCd: '37118',
        certificationNumber: 9503734,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV0KW019512',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:36:38.935Z'
        }
      },
      sort: [
        1591653515833
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU784130043092020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:58:16.292Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17488.00',
          mileage: '24406'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9483382,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU784130',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:10:47.754Z'
        }
      },
      sort: [
        1591653496292
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEW5F16HX224037020502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:58:14.938Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29981.00',
          mileage: '67666'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9486434,
        makeCode: 'TOYOTA',
        vin: '5TFEW5F16HX224037',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8263',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:00:50.693Z'
        }
      },
      sort: [
        1591653494938
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU794100270132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:57:59.076Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15758.00',
          mileage: '34530'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9461089,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU794100',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T18:05:07.582Z'
        }
      },
      sort: [
        1591653479076
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK8FR483522020162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:57:36.789Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15109.59',
          mileage: '46897'
        },
        sellArea: '130',
        dealerCd: '02016',
        certificationNumber: 9448113,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK8FR483522',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T18:38:55.263Z'
        }
      },
      sort: [
        1591653456789
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEY5F10JX238130020502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:57:23.949Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '33481.00',
          mileage: '35423'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9493447,
        makeCode: 'TOYOTA',
        vin: '5TFEY5F10JX238130',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8261',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T04:20:14.552Z'
        }
      },
      sort: [
        1591653443949
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1HC908573311312020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:56:52.433Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '27485'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9496919,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1HC908573',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:22:44.751Z'
        }
      },
      sort: [
        1591653412433
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN5GM015017020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:56:42.695Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25981.00',
          mileage: '43716'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9497967,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN5GM015017',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:05:43.428Z'
        }
      },
      sort: [
        1591653402695
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN6KX148758043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:56:19.255Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26988.00',
          mileage: '24638'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9503634,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN6KX148758',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:53:11.821Z'
        }
      },
      sort: [
        1591653379255
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU274151020162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:55:33.135Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21411.00',
          mileage: '6808'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9498056,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU274151',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:32:44.626Z'
        }
      },
      sort: [
        1591653333135
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRZ5CN6GX019400020502020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:55:32.312Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '29981.00',
          mileage: '8577'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9501506,
        makeCode: 'TOYOTA',
        vin: '5TFRZ5CN6GX019400',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7128',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:52:13.668Z'
        }
      },
      sort: [
        1591653332312
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN4GM021391020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:55:03.191Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29981.00',
          mileage: '31897'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9501485,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN4GM021391',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:41:56.400Z'
        }
      },
      sort: [
        1591653303191
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4HJ147569310112020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:54:10.431Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '22189'
        },
        sellArea: '160',
        dealerCd: '31011',
        certificationNumber: 9395196,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4HJ147569',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T15:54:28.119Z'
        }
      },
      sort: [
        1591653250431
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW587596480302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:54:04.771Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21900.00',
          mileage: '10730'
        },
        sellArea: '210',
        dealerCd: '48030',
        certificationNumber: 9503692,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW587596',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:17:32.440Z'
        }
      },
      sort: [
        1591653244771
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC848137020162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:53:58.970Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13900.00',
          mileage: '38669'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9493225,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC848137',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:39:11.254Z'
        }
      },
      sort: [
        1591653238970
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7KM257165020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:53:04.908Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '35281.00',
          mileage: '17568'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9497896,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7KM257165',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:51:02.032Z'
        }
      },
      sort: [
        1591653184908
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMW1RFVXKD002817190612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:52:32.347Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22062.64',
          mileage: '25219'
        },
        sellArea: '500',
        dealerCd: '19061',
        certificationNumber: 9485743,
        makeCode: 'TOYOTA',
        vin: 'JTMW1RFVXKD002817',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:08:14.226Z'
        }
      },
      sort: [
        1591653152347
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC8HS830809020502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:52:23.530Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26481.00',
          mileage: '45612'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9476462,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC8HS830809',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:58:29.973Z'
        }
      },
      sort: [
        1591653143530
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU702036310712020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:52:20.568Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17495.00',
          mileage: '18863'
        },
        sellArea: '160',
        dealerCd: '31071',
        certificationNumber: 9494325,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU702036',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:25:08.773Z'
        }
      },
      sort: [
        1591653140568
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW596804310112020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:52:11.269Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20589.00',
          mileage: '36717'
        },
        sellArea: '160',
        dealerCd: '31011',
        certificationNumber: 9409743,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW596804',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T15:40:44.430Z'
        }
      },
      sort: [
        1591653131269
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTLZE4FEXFJ073676020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:51:56.487Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13981.00',
          mileage: '47012'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9469930,
        makeCode: 'TOYOTA',
        vin: 'JTLZE4FEXFJ073676',
        model: {
          modelDescription: 'SCION xB 5-DOOR',
          modelSeries: 'XBSCION',
          modelNumber: '6202',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T18:45:10.324Z'
        }
      },
      sort: [
        1591653116487
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0H5467758260212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:51:49.126Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '31900.00',
          mileage: '18145'
        },
        sellArea: '230',
        dealerCd: '26021',
        certificationNumber: 9466118,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0H5467758',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T20:31:46.954Z'
        }
      },
      sort: [
        1591653109126
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV8FW191866020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:51:17.647Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18981.00',
          mileage: '30188'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9476410,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV8FW191866',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:35:47.575Z'
        }
      },
      sort: [
        1591653077647
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV2GJ092679020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:50:40.127Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19481.00',
          mileage: '62158'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9489543,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV2GJ092679',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:51:59.727Z'
        }
      },
      sort: [
        1591653040127
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU684761370772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:50:36.390Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16100.0000',
          mileage: '37813'
        },
        sellArea: '800',
        dealerCd: '37077',
        certificationNumber: 9481893,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU684761',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:05:36.385Z'
        }
      },
      sort: [
        1591653036390
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN1KM096025043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:50:36.089Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34783.00',
          mileage: '26118'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9503620,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN1KM096025',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:50:46.586Z'
        }
      },
      sort: [
        1591653036089
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH8HS376504370772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:50:34.093Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25100.0000',
          mileage: '21607'
        },
        sellArea: '160',
        dealerCd: '37077',
        certificationNumber: 9419767,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH8HS376504',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T14:44:59.320Z'
        }
      },
      sort: [
        1591653034093
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC851524220542020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:50:33.389Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '13727.00',
          mileage: '27198'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9492901,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC851524',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:00:44.595Z'
        }
      },
      sort: [
        1591653033389
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU250878370772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:50:31.533Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17700.00',
          mileage: '4776'
        },
        sellArea: '800',
        dealerCd: '37077',
        certificationNumber: 9425414,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU250878',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T15:53:02.222Z'
        }
      },
      sort: [
        1591653031533
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4EP164463020162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:50:30.131Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '11711.00',
          mileage: '75525'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9484209,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4EP164463',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1863',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:34:14.915Z'
        }
      },
      sort: [
        1591653030131
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREVXHJ707174020502020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:50:02.790Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18281.00',
          mileage: '31571'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9486422,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREVXHJ707174',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:54:03.502Z'
        }
      },
      sort: [
        1591653002790
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3LC092984310112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:49:57.868Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26740.00',
          mileage: '4405'
        },
        sellArea: '500',
        dealerCd: '31011',
        certificationNumber: 9500931,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3LC092984',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T13:38:37.824Z'
        }
      },
      sort: [
        1591652997868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP5K3116908043092020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:49:47.329Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '27283.00',
          mileage: '11229'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9422613,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP5K3116908',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T17:58:01.096Z'
        }
      },
      sort: [
        1591652987329
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW552979020502020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:49:28.594Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20981.00',
          mileage: '39931'
        },
        sellArea: '120',
        dealerCd: '02050',
        certificationNumber: 9501516,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW552979',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:58:48.269Z'
        }
      },
      sort: [
        1591652968594
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV4HJ130466020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:48:47.553Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20581.00',
          mileage: '21027'
        },
        sellArea: '120',
        dealerCd: '02050',
        certificationNumber: 9476309,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV4HJ130466',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:03:13.108Z'
        }
      },
      sort: [
        1591652927553
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC864135310112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:48:44.956Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14100.00',
          mileage: '16007'
        },
        sellArea: '170',
        dealerCd: '31011',
        certificationNumber: 9499302,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC864135',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:29:27.549Z'
        }
      },
      sort: [
        1591652924956
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6HJ132166370832020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:48:42.708Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17999.00',
          mileage: '37927'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9495310,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6HJ132166',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:35:55.516Z'
        }
      },
      sort: [
        1591652922708
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV9HD099231020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:48:22.767Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20481.00',
          mileage: '17942'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9486401,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV9HD099231',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:45:00.316Z'
        }
      },
      sort: [
        1591652902767
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6K5736756020162020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:48:14.029Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '36987.00',
          mileage: '2983'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9419006,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6K5736756',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T20:04:01.291Z'
        }
      },
      sort: [
        1591652894029
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDCRFH2FS013641311892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:47:58.108Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24995.00',
          mileage: '60492'
        },
        dealerCd: '31189',
        certificationNumber: 9301754,
        makeCode: 'TOYOTA',
        vin: '5TDDCRFH2FS013641',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-21T15:56:32.662Z'
        }
      },
      sort: [
        1591652878108
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJJREVXHD097237211182020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:47:53.408Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22898.00',
          mileage: '36739'
        },
        sellArea: '220',
        dealerCd: '21118',
        certificationNumber: 9503745,
        makeCode: 'TOYOTA',
        vin: 'JTMJJREVXHD097237',
        model: {
          modelDescription: 'RAV4 HYBRID SE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:41:41.879Z'
        }
      },
      sort: [
        1591652873408
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU253909310112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:47:47.406Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17990.00',
          mileage: '15180'
        },
        sellArea: '500',
        dealerCd: '31011',
        certificationNumber: 9479581,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU253909',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T20:52:49.007Z'
        }
      },
      sort: [
        1591652867406
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW573236020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:47:36.828Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18981.00',
          mileage: '67642'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9486386,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW573236',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:35:14.398Z'
        }
      },
      sort: [
        1591652856828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB35G1135081360972020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:47:23.447Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '12434'
        },
        sellArea: '130',
        dealerCd: '36097',
        certificationNumber: 9476392,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB35G1135081',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB FOUR',
          modelSeries: 'PRIUS C',
          modelNumber: '1207',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:27:48.158Z'
        }
      },
      sort: [
        1591652843447
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV5HJ711594340062020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:47:16.486Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '33759'
        },
        sellArea: '220',
        dealerCd: '34006',
        certificationNumber: 9485130,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV5HJ711594',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:28:43.337Z'
        }
      },
      sort: [
        1591652836486
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBXXKR099783470212020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:47:12.567Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '25'
        },
        sellArea: '190',
        dealerCd: '47021',
        certificationNumber: 9498169,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBXXKR099783',
        model: {
          modelDescription: 'C-HR LE',
          modelSeries: 'CHR',
          modelNumber: '2402',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:18:34.256Z'
        }
      },
      sort: [
        1591652832567
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKK3DC6FS599260043092020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:47:00.647Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17995.00',
          mileage: '52469'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9445710,
        makeCode: 'TOYOTA',
        vin: '5TDKK3DC6FS599260',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T22:47:42.997Z'
        }
      },
      sort: [
        1591652820647
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV5KC010946310112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:46:46.607Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23990.00',
          mileage: '20389'
        },
        dealerCd: '31011',
        certificationNumber: 9374242,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV5KC010946',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-10T13:39:54.729Z'
        }
      },
      sort: [
        1591652806607
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW642681020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:46:33.267Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18781.00',
          mileage: '35975'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9459317,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW642681',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T00:01:46.045Z'
        }
      },
      sort: [
        1591652793267
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2KC014808360972020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:46:30.546Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26999.00',
          mileage: '17604'
        },
        sellArea: '500',
        dealerCd: '36097',
        certificationNumber: 9432475,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2KC014808',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T22:04:58.607Z'
        }
      },
      sort: [
        1591652790546
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV4HW332464020502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:45:43.966Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18381.00',
          mileage: '44252'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9466291,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV4HW332464',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T21:56:00.911Z'
        }
      },
      sort: [
        1591652743966
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUU4EN1FX116115440222020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:45:22.267Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '35893'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9499445,
        makeCode: 'TOYOTA',
        vin: '5TFUU4EN1FX116115',
        model: {
          modelDescription: 'TACOMA 4X4 ACCESS CAB V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7554',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:07:34.065Z'
        }
      },
      sort: [
        1591652722267
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRWRFV1KD026406310112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:45:13.025Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30990.00',
          mileage: '6'
        },
        sellArea: '190',
        dealerCd: '31011',
        certificationNumber: 9501646,
        makeCode: 'TOYOTA',
        vin: 'JTMRWRFV1KD026406',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:14:35.426Z'
        }
      },
      sort: [
        1591652713025
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREVXHJ126869020502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:44:54.604Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17981.00',
          mileage: '58645'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9497928,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREVXHJ126869',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:58:02.253Z'
        }
      },
      sort: [
        1591652694604
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW591348311892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:44:20.945Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17995.00',
          mileage: '33006'
        },
        dealerCd: '31189',
        certificationNumber: 9361198,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW591348',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-30T17:08:54.459Z'
        }
      },
      sort: [
        1591652660945
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6H5428396470282020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:43:44.066Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '33891.00',
          mileage: '47613'
        },
        dealerCd: '47028',
        certificationNumber: 9381302,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6H5428396',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T16:25:47.432Z'
        }
      },
      sort: [
        1591652624066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX0JR010771043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:43:39.323Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20983.00',
          mileage: '22739'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9503602,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX0JR010771',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:47:35.933Z'
        }
      },
      sort: [
        1591652619323
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP1H3023652043092020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:43:38.565Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21998.00',
          mileage: '31199'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9485754,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP1H3023652',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:11:37.681Z'
        }
      },
      sort: [
        1591652618565
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV8GD027172440222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:43:22.546Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '84798'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9456557,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV8GD027172',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T14:36:01.586Z'
        }
      },
      sort: [
        1591652602546
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW566643311892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:43:16.764Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19490.00',
          mileage: '22527'
        },
        sellArea: '160',
        dealerCd: '31189',
        certificationNumber: 9422818,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW566643',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T19:45:35.647Z'
        }
      },
      sort: [
        1591652596764
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV5GD039435440222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:42:40.764Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '56839'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9421989,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV5GD039435',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T12:17:47.175Z'
        }
      },
      sort: [
        1591652560764
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU439266043592020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:42:36.325Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16385.00',
          mileage: '13107'
        },
        sellArea: '110',
        dealerCd: '04359',
        certificationNumber: 9479612,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU439266',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T21:11:47.913Z'
        }
      },
      sort: [
        1591652556325
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW635801311892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:42:15.725Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '26586'
        },
        sellArea: '160',
        dealerCd: '31189',
        certificationNumber: 9481886,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW635801',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:01:09.244Z'
        }
      },
      sort: [
        1591652535725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV5KC025320020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:42:03.643Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25981.00',
          mileage: '21097'
        },
        sellArea: '500',
        dealerCd: '02050',
        certificationNumber: 9476348,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV5KC025320',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:15:11.384Z'
        }
      },
      sort: [
        1591652523643
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9ED053859440222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:42:01.424Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '11000.00',
          mileage: '83727'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9493990,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9ED053859',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:21:02.230Z'
        }
      },
      sort: [
        1591652521424
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU238984310112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:41:48.084Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17790.00',
          mileage: '22471'
        },
        sellArea: '500',
        dealerCd: '31011',
        certificationNumber: 9479553,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU238984',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T20:41:02.516Z'
        }
      },
      sort: [
        1591652508084
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW570179180352020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:41:45.824Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18591.76',
          mileage: '27690'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9491052,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW570179',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T12:45:28.864Z'
        }
      },
      sort: [
        1591652505824
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW668273311602020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:41:42.264Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18736.00',
          mileage: '37714'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9503739,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW668273',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:39:33.337Z'
        }
      },
      sort: [
        1591652502264
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8HW664702180352020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:41:14.645Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '35011'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9497118,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8HW664702',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:08:52.376Z'
        }
      },
      sort: [
        1591652474645
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW637951440222020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:41:08.683Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '30741'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9485780,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW637951',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:16:03.589Z'
        }
      },
      sort: [
        1591652468683
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9LC092049020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:40:37.804Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29481.00',
          mileage: '3194'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9501511,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9LC092049',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:55:59.491Z'
        }
      },
      sort: [
        1591652437804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RWRFV8KW017902440222020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:40:30.963Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '28900.00',
          mileage: '14807'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9497414,
        makeCode: 'TOYOTA',
        vin: '2T3RWRFV8KW017902',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:15:36.923Z'
        }
      },
      sort: [
        1591652430963
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F10FX430068250652020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:40:28.222Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35601.00',
          mileage: '30316'
        },
        sellArea: '130',
        certificationNumber: 9481499,
        dealerCd: '25065',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'TUNDRA 4X4 LTD CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2015
        },
        vin: '5TFHY5F10FX430068',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:18:34.351Z'
        }
      },
      sort: [
        1591652428222
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV5GJ078991043092020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:40:00.221Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15988.00',
          mileage: '78066'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9496026,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV5GJ078991',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:57:27.118Z'
        }
      },
      sort: [
        1591652400221
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE5LJ026603310112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:39:57.102Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16990.00',
          mileage: '11077'
        },
        sellArea: '500',
        dealerCd: '31011',
        certificationNumber: 9460617,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE5LJ026603',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T13:08:23.901Z'
        }
      },
      sort: [
        1591652397102
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXGC523415440222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:39:39.860Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '12250.00',
          mileage: '53325'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9497316,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXGC523415',
        model: {
          modelDescription: 'COROLLA LE PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1854',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:31:00.705Z'
        }
      },
      sort: [
        1591652379860
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE7K3000987330252020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:39:20.600Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17900.00',
          mileage: '4745'
        },
        sellArea: '230',
        dealerCd: '33025',
        certificationNumber: 9503656,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE7K3000987',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:02:56.928Z'
        }
      },
      sort: [
        1591652360600
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0LC090982020502020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:39:07.362Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '29481.00',
          mileage: '3430'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9501512,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0LC090982',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:56:48.188Z'
        }
      },
      sort: [
        1591652347362
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV8HW644669330252020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:38:58.881Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21450.00',
          mileage: '42754'
        },
        sellArea: '230',
        dealerCd: '33025',
        certificationNumber: 9477361,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV8HW644669',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T14:39:28.975Z'
        }
      },
      sort: [
        1591652338881
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F1XHX071082310112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:38:44.001Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28489.00',
          mileage: '42588'
        },
        dealerCd: '31011',
        certificationNumber: 9370456,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F1XHX071082',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8340',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-06T13:27:28.821Z'
        }
      },
      sort: [
        1591652324001
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0LC091873020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:38:34.702Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29781.00',
          mileage: '1543'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9501523,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0LC091873',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:01:46.869Z'
        }
      },
      sort: [
        1591652314702
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX7KR082133290752020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:38:23.400Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23149.00',
          mileage: '6509'
        },
        sellArea: '160',
        dealerCd: '29075',
        certificationNumber: 9418689,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX7KR082133',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T17:24:01.728Z'
        }
      },
      sort: [
        1591652303400
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFHXGS347407290752020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:37:44.939Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31854.00',
          mileage: '29003'
        },
        sellArea: '160',
        dealerCd: '29075',
        certificationNumber: 9426861,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFHXGS347407',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T15:10:14.747Z'
        }
      },
      sort: [
        1591652264939
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RWRFV7LW079213020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:37:27.420Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31981.00',
          mileage: '2534'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9501492,
        makeCode: 'TOYOTA',
        vin: '2T3RWRFV7LW079213',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:43:43.302Z'
        }
      },
      sort: [
        1591652247420
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN9KX190918310112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:36:58.878Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29250.00',
          mileage: '13524'
        },
        dealerCd: '31011',
        certificationNumber: 9330788,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN9KX190918',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-10T18:07:12.099Z'
        }
      },
      sort: [
        1591652218878
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKKRFH5GS504541020502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:36:58.382Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21481.00',
          mileage: '79611'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9486432,
        makeCode: 'TOYOTA',
        vin: '5TDKKRFH5GS504541',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:58:53.470Z'
        }
      },
      sort: [
        1591652218382
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS403708290752020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:36:04.223Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26660.00',
          mileage: '36461'
        },
        sellArea: '160',
        dealerCd: '29075',
        certificationNumber: 9485378,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS403708',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:21:20.563Z'
        }
      },
      sort: [
        1591652164223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1H5420688020442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:35:58.263Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29699.00',
          mileage: '52350'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9495078,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1H5420688',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T11:49:47.275Z'
        }
      },
      sort: [
        1591652158263
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HJ117645043092020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:35:57.456Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19988.00',
          mileage: '45218'
        },
        dealerCd: '04309',
        certificationNumber: 9366977,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HJ117645',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-03T20:25:20.008Z'
        }
      },
      sort: [
        1591652157456
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH9HS398309020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:35:48.477Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32981.00',
          mileage: '31962'
        },
        sellArea: '210',
        dealerCd: '02050',
        certificationNumber: 9486415,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH9HS398309',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:51:10.121Z'
        }
      },
      sort: [
        1591652148477
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH9HS435012020502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:35:18.718Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23481.00',
          mileage: '32678'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9486417,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH9HS435012',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:51:59.161Z'
        }
      },
      sort: [
        1591652118718
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK0ER418999020442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:35:13.842Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13499.00',
          mileage: '67782'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9493502,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK0ER418999',
        model: {
          modelDescription: 'CAMRY 2014.5 L Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2514',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T11:08:53.442Z'
        }
      },
      sort: [
        1591652113842
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ127375291072020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:35:07.452Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22022.00',
          mileage: '21022'
        },
        sellArea: '160',
        dealerCd: '29107',
        certificationNumber: 9461963,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HJ127375',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T16:12:31.506Z'
        }
      },
      sort: [
        1591652107452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA17H8706701020442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:34:52.202Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22785.00',
          mileage: '31288'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9496776,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA17H8706701',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T11:21:28.602Z'
        }
      },
      sort: [
        1591652092202
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0FD154935045632020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:34:51.877Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17900.00',
          mileage: '48142'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9474624,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0FD154935',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:29:25.371Z'
        }
      },
      sort: [
        1591652091877
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DCXHS825194290752020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:34:43.136Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29749.00',
          mileage: '47946'
        },
        sellArea: '160',
        dealerCd: '29075',
        certificationNumber: 9422322,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DCXHS825194',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T15:54:49.789Z'
        }
      },
      sort: [
        1591652083136
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BPRHE8GC588481110222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:34:35.896Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13725.00',
          mileage: '43652'
        },
        sellArea: '130',
        dealerCd: '11022',
        certificationNumber: 9474865,
        makeCode: 'TOYOTA',
        vin: '2T1BPRHE8GC588481',
        model: {
          modelDescription: 'COROLLA LE ECO',
          modelSeries: 'COROLLA',
          modelNumber: '1872',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:24:31.841Z'
        }
      },
      sort: [
        1591652075896
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX3JR020548020442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:34:29.257Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18210.00',
          mileage: '41434'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9400249,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX3JR020548',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T11:36:56.763Z'
        }
      },
      sort: [
        1591652069257
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH3GS320193360992020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:34:11.696Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '48373'
        },
        sellArea: '130',
        dealerCd: '36099',
        certificationNumber: 9482640,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH3GS320193',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T23:44:17.517Z'
        }
      },
      sort: [
        1591652051696
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F1XHX601321020442020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:34:06.725Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '36500.00',
          mileage: '52993'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9486843,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F1XHX601321',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T12:08:09.548Z'
        }
      },
      sort: [
        1591652046725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6LC090288311602020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:33:28.602Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28403.00',
          mileage: '3875'
        },
        sellArea: '500',
        dealerCd: '31160',
        certificationNumber: 9503723,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6LC090288',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:29:50.892Z'
        }
      },
      sort: [
        1591652008602
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV8HW367797020442020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:33:28.135Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19323.00',
          mileage: '61280'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9444217,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV8HW367797',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T14:30:01.038Z'
        }
      },
      sort: [
        1591652008135
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU4BF3EK177381020502020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:33:25.956Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '30981.00',
          mileage: '58787'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9501494,
        makeCode: 'TOYOTA',
        vin: 'JTEBU4BF3EK177381',
        model: {
          modelDescription: 'FJ CRUISER 4x4 SUV',
          modelSeries: 'FJ CRUISER',
          modelNumber: '4703',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:44:40.666Z'
        }
      },
      sort: [
        1591652005956
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH6KS072593020442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:33:10.576Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '42800.00',
          mileage: '4975'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9475609,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH6KS072593',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T11:36:54.569Z'
        }
      },
      sort: [
        1591651990576
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV8HW320829043092020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:33:09.180Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23988.00',
          mileage: '41243'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9491872,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV8HW320829',
        model: {
          modelDescription: 'RAV4 Platinum FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4474',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:30:19.004Z'
        }
      },
      sort: [
        1591651989180
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4GC585926020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:32:54.437Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '11981.00',
          mileage: '68146'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9501533,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4GC585926',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:05:25.001Z'
        }
      },
      sort: [
        1591651974437
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5K5705000020442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:32:48.416Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '38999.00',
          mileage: '17079'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9496732,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5K5705000',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T11:17:52.052Z'
        }
      },
      sort: [
        1591651968416
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8GJ059476291072020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:32:33.035Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17786.15',
          mileage: '42235'
        },
        sellArea: '800',
        dealerCd: '29107',
        certificationNumber: 9417544,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8GJ059476',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T20:55:30.945Z'
        }
      },
      sort: [
        1591651953035
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC896885020502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:32:19.615Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13981.00',
          mileage: '45100'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9489537,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC896885',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:50:36.355Z'
        }
      },
      sort: [
        1591651939615
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH2GS255115020442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:32:06.716Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23350.00',
          mileage: '66431'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9475608,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH2GS255115',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T11:33:12.001Z'
        }
      },
      sort: [
        1591651926716
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC941089020502020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:31:49.795Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '13981.00',
          mileage: '51850'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9497985,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC941089',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:09:37.712Z'
        }
      },
      sort: [
        1591651909795
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0GC737233310112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:31:49.121Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15367.00',
          mileage: '39842'
        },
        sellArea: '160',
        dealerCd: '31011',
        certificationNumber: 9472326,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0GC737233',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T12:43:54.039Z'
        }
      },
      sort: [
        1591651909121
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE6LP087705020442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:31:31.700Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18575.00',
          mileage: '10181'
        },
        sellArea: '600',
        dealerCd: '02044',
        certificationNumber: 9496833,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE6LP087705',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T11:41:08.801Z'
        }
      },
      sort: [
        1591651891700
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC902065020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:31:22.421Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14981.00',
          mileage: '12514'
        },
        sellArea: '170',
        dealerCd: '02050',
        certificationNumber: 9489606,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC902065',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:10:42.700Z'
        }
      },
      sort: [
        1591651882421
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU739280020442020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:31:10.240Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21651.00',
          mileage: '32703'
        },
        sellArea: '500',
        dealerCd: '02044',
        certificationNumber: 9482978,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU739280',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T11:40:48.290Z'
        }
      },
      sort: [
        1591651870240
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE7LP014643020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:30:56.559Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19481.00',
          mileage: '14459'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9461901,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE7LP014643',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T14:17:49.308Z'
        }
      },
      sort: [
        1591651856559
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKY5G12LS074275020442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:30:47.340Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '50253.47',
          mileage: '12577'
        },
        sellArea: '500',
        dealerCd: '02044',
        certificationNumber: 9477194,
        makeCode: 'TOYOTA',
        vin: '5TDKY5G12LS074275',
        model: {
          modelDescription: 'SEQUOIA 4X2 LTD 8-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7927',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T12:05:32.907Z'
        }
      },
      sort: [
        1591651847340
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HJ704029291072020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:30:42.762Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17948.28',
          mileage: '28565'
        },
        sellArea: '160',
        dealerCd: '29107',
        certificationNumber: 9470388,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HJ704029',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T13:37:20.021Z'
        }
      },
      sort: [
        1591651842762
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HJ143860110012020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:30:00.741Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22999.00',
          mileage: '33873'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9484189,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HJ143860',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:25:28.752Z'
        }
      },
      sort: [
        1591651800741
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU405946020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:29:47.879Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17682.00',
          mileage: '22456'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9486403,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU405946',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:45:51.830Z'
        }
      },
      sort: [
        1591651787879
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN4HM021231480282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:29:47.134Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28699.00',
          mileage: '36486'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9464688,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN4HM021231',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T19:22:52.160Z'
        }
      },
      sort: [
        1591651787134
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HD211649311742020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:29:32.253Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19250.00',
          mileage: '31478'
        },
        sellArea: '160',
        dealerCd: '31174',
        certificationNumber: 9495401,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HD211649',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:13:46.411Z'
        }
      },
      sort: [
        1591651772253
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE6LP015841020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:29:07.652Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17481.00',
          mileage: '11956'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9501527,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE6LP015841',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:03:15.690Z'
        }
      },
      sort: [
        1591651747652
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU229723020442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:28:53.272Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21600.00',
          mileage: '29082'
        },
        sellArea: '500',
        dealerCd: '02044',
        certificationNumber: 9480073,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU229723',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T11:52:56.287Z'
        }
      },
      sort: [
        1591651733272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC952476020442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:28:23.153Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15863.00',
          mileage: '16349'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9503169,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC952476',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T18:35:47.175Z'
        }
      },
      sort: [
        1591651703153
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU755463020502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:27:59.451Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14981.00',
          mileage: '35466'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9486400,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU755463',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:44:06.891Z'
        }
      },
      sort: [
        1591651679451
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHJ138683311482020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:27:38.591Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20145.00',
          mileage: '19951'
        },
        sellArea: '160',
        dealerCd: '31148',
        certificationNumber: 9499588,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHJ138683',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:57:17.691Z'
        }
      },
      sort: [
        1591651658591
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW655643311602020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:27:12.251Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19918.00',
          mileage: '18596'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9503708,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW655643',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:22:12.969Z'
        }
      },
      sort: [
        1591651632251
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F16HX661161020442020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:26:58.950Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '46899.00',
          mileage: '50000'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9498630,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F16HX661161',
        model: {
          modelDescription: 'TUNDRA TRD PRO CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8373',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T11:16:24.465Z'
        }
      },
      sort: [
        1591651618950
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU120971270132020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:26:11.810Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19758.00',
          mileage: '39002'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9465824,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU120971',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:54:44.832Z'
        }
      },
      sort: [
        1591651571810
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV5GD058041110012020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:26:01.309Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19998.00',
          mileage: '75239'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9476605,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV5GD058041',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:51:47.397Z'
        }
      },
      sort: [
        1591651561309
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU752342020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:25:52.069Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16281.00',
          mileage: '24865'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9476257,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU752342',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:46:16.037Z'
        }
      },
      sort: [
        1591651552069
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU395179020502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:25:24.490Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16282.00',
          mileage: '35151'
        },
        sellArea: '120',
        dealerCd: '02050',
        certificationNumber: 9476267,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU395179',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:49:12.546Z'
        }
      },
      sort: [
        1591651524490
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1H5436678270132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:24:57.850Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31825.00',
          mileage: '46524'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9482382,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1H5436678',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:51:59.687Z'
        }
      },
      sort: [
        1591651497850
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU393956020502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:24:57.178Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16981.00',
          mileage: '30684'
        },
        sellArea: '120',
        dealerCd: '02050',
        certificationNumber: 9461904,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU393956',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T14:20:24.370Z'
        }
      },
      sort: [
        1591651497178
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DWRFVXKW024196121572020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:24:21.819Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '35150.01',
          mileage: '14222'
        },
        sellArea: '210',
        dealerCd: '12157',
        certificationNumber: 9475050,
        makeCode: 'TOYOTA',
        vin: '2T3DWRFVXKW024196',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T21:04:14.929Z'
        }
      },
      sort: [
        1591651461819
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS411674340262020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:23:59.318Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27400.00',
          mileage: '43530'
        },
        sellArea: '220',
        dealerCd: '34026',
        certificationNumber: 9422314,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS411674',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T15:51:21.267Z'
        }
      },
      sort: [
        1591651439318
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW661893220472020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:23:46.698Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '22382'
        },
        sellArea: '210',
        dealerCd: '22047',
        certificationNumber: 9497318,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW661893',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:31:16.137Z'
        }
      },
      sort: [
        1591651426698
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1K5714163270132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:23:30.218Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '38985.00',
          mileage: '5224'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9485783,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1K5714163',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:16:47.125Z'
        }
      },
      sort: [
        1591651410218
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC827677340262020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:23:05.499Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '15656'
        },
        sellArea: '220',
        dealerCd: '34026',
        certificationNumber: 9471437,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC827677',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T20:16:49.206Z'
        }
      },
      sort: [
        1591651385499
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BPRHE2HC853834110012020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:20:59.317Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '20139'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9494588,
        makeCode: 'TOYOTA',
        vin: '2T1BPRHE2HC853834',
        model: {
          modelDescription: 'COROLLA LE ECO',
          modelSeries: 'COROLLA',
          modelNumber: '1872',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:57:17.146Z'
        }
      },
      sort: [
        1591651259317
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4JW838528311602020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:19:28.056Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21511.00',
          mileage: '11366'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9503693,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4JW838528',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:17:35.807Z'
        }
      },
      sort: [
        1591651168056
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU792051311602020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:18:53.136Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15683.00',
          mileage: '33140'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9503648,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU792051',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:57:58.783Z'
        }
      },
      sort: [
        1591651133136
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN8KX196936280122020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:18:11.234Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '29850.00',
          mileage: '15374'
        },
        sellArea: '500',
        dealerCd: '28012',
        certificationNumber: 9417186,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN8KX196936',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T18:41:48.488Z'
        }
      },
      sort: [
        1591651091234
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU751375020502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:17:37.515Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16481.00',
          mileage: '35561'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9486407,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU751375',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:47:31.595Z'
        }
      },
      sort: [
        1591651057515
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU294561020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:17:09.455Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16281.00',
          mileage: '36512'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9486377,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU294561',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:28:37.569Z'
        }
      },
      sort: [
        1591651029455
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC0HS177026361032020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:16:52.135Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24444.00',
          mileage: '37630'
        },
        sellArea: '150',
        dealerCd: '36103',
        certificationNumber: 9503687,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC0HS177026',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:15:11.299Z'
        }
      },
      sort: [
        1591651012135
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1JU616569020502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:16:28.315Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19482.00',
          mileage: '38744'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9486436,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1JU616569',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:02:43.034Z'
        }
      },
      sort: [
        1591650988315
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW594310480272020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:16:03.255Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18898.00',
          mileage: '29441'
        },
        sellArea: '160',
        dealerCd: '48027',
        certificationNumber: 9486163,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW594310',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:20:13.502Z'
        }
      },
      sort: [
        1591650963255
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN8KM067409190712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:15:33.175Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33299.00',
          mileage: '16166'
        },
        sellArea: '800',
        dealerCd: '19071',
        certificationNumber: 9500464,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN8KM067409',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:16:37.578Z'
        }
      },
      sort: [
        1591650933175
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7G5344375110012020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:14:55.893Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27808.00',
          mileage: '59874'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9494604,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7G5344375',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:04:01.151Z'
        }
      },
      sort: [
        1591650895893
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX1JR004669121572020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:14:35.960Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17880.00',
          mileage: '41737'
        },
        sellArea: '210',
        dealerCd: '12157',
        certificationNumber: 9452502,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX1JR004669',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T19:37:59.247Z'
        }
      },
      sort: [
        1591650875960
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU641617060422020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:14:27.341Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '16581.00',
          mileage: '32932'
        },
        sellArea: '160',
        dealerCd: '06042',
        certificationNumber: 9501258,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU641617',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:38:46.398Z'
        }
      },
      sort: [
        1591650867341
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV1HJ117822121122020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:12:24.194Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '19083'
        },
        sellArea: '500',
        dealerCd: '12112',
        certificationNumber: 9481564,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV1HJ117822',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:44:27.541Z'
        }
      },
      sort: [
        1591650744194
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU667367450572020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:12:04.052Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16450.00',
          mileage: '10940'
        },
        sellArea: '800',
        dealerCd: '45057',
        certificationNumber: 9472489,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU667367',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T14:41:26.903Z'
        }
      },
      sort: [
        1591650724052
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW609837311602020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:11:15.472Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18450.00',
          mileage: '29729'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9503664,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW609837',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T21:06:13.473Z'
        }
      },
      sort: [
        1591650675472
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3HP696517260312020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:10:27.574Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15201.00',
          mileage: '24245'
        },
        sellArea: '230',
        dealerCd: '26031',
        certificationNumber: 9502819,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3HP696517',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T17:11:56.271Z'
        }
      },
      sort: [
        1591650627574
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1JU134295020502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:09:44.571Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19581.00',
          mileage: '27866'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9489516,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1JU134295',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:47:52.570Z'
        }
      },
      sort: [
        1591650584571
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5HW629664110012020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:09:20.431Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19994.00',
          mileage: '25020'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9492218,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5HW629664',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:10:47.549Z'
        }
      },
      sort: [
        1591650560431
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HKXJ3043015020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:08:34.811Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19481.00',
          mileage: '37032'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9489552,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HKXJ3043015',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:53:39.512Z'
        }
      },
      sort: [
        1591650514811
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F19KX863560050432020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:08:34.555Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '38401.00',
          mileage: '4451'
        },
        sellArea: '150',
        dealerCd: '05043',
        certificationNumber: 9498456,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F19KX863560',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T02:58:05.478Z'
        }
      },
      sort: [
        1591650514555
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU119707020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:07:58.494Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17582.00',
          mileage: '44460'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9469929,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU119707',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T18:44:22.941Z'
        }
      },
      sort: [
        1591650478494
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3FP272347250652020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:07:56.396Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '11062.38',
          mileage: '51492'
        },
        sellArea: '500',
        dealerCd: '25065',
        certificationNumber: 9487132,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3FP272347',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:04:45.060Z'
        }
      },
      sort: [
        1591650476396
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU279605020502020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:07:17.396Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21481.00',
          mileage: '22162'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9486388,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU279605',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:36:55.941Z'
        }
      },
      sort: [
        1591650437396
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU811467020502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:06:48.774Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21181.00',
          mileage: '29516'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9476374,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU811467',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:21:30.484Z'
        }
      },
      sort: [
        1591650408774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU791368160042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:06:25.814Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23340.00',
          mileage: '19920'
        },
        sellArea: '500',
        dealerCd: '16004',
        certificationNumber: 9422062,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU791368',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T13:40:38.148Z'
        }
      },
      sort: [
        1591650385814
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU733064020502020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:05:49.113Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21281.00',
          mileage: '26871'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9476381,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU733064',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:24:07.147Z'
        }
      },
      sort: [
        1591650349113
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0GM035638050432020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:05:47.030Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '28401.00',
          mileage: '51347'
        },
        sellArea: '150',
        dealerCd: '05043',
        certificationNumber: 9484643,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0GM035638',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T02:56:13.634Z'
        }
      },
      sort: [
        1591650347030
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU734537020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:05:12.673Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21181.00',
          mileage: '29520'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9478084,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU734537',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T20:01:55.761Z'
        }
      },
      sort: [
        1591650312673
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU745753020502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:04:43.889Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21281.00',
          mileage: '28328'
        },
        sellArea: '500',
        dealerCd: '02050',
        certificationNumber: 9476390,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU745753',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:26:52.574Z'
        }
      },
      sort: [
        1591650283889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU733769020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:04:02.690Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20981.00',
          mileage: '31741'
        },
        sellArea: '500',
        dealerCd: '02050',
        certificationNumber: 9478080,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU733769',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T20:01:09.744Z'
        }
      },
      sort: [
        1591650242690
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU214880020502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:03:20.089Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20681.00',
          mileage: '30966'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9467699,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU214880',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T22:44:06.804Z'
        }
      },
      sort: [
        1591650200089
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK3LU880136340962020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:02:13.868Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '24374.25',
          mileage: '3903'
        },
        sellArea: '220',
        dealerCd: '34096',
        certificationNumber: 9443926,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK3LU880136',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T17:26:11.687Z'
        }
      },
      sort: [
        1591650133868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU740347020502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:01:13.448Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20681.00',
          mileage: '36780'
        },
        sellArea: '500',
        dealerCd: '02050',
        certificationNumber: 9486390,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU740347',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:37:41.826Z'
        }
      },
      sort: [
        1591650073448
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5552903041632020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:00:51.409Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '36959.00',
          mileage: '35604'
        },
        sellArea: '150',
        dealerCd: '04163',
        certificationNumber: 9503073,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5552903',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T18:08:28.284Z'
        }
      },
      sort: [
        1591650051409
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU214009020502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:00:45.986Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20981.00',
          mileage: '33250'
        },
        sellArea: '500',
        dealerCd: '02050',
        certificationNumber: 9476387,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU214009',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:25:35.193Z'
        }
      },
      sort: [
        1591650045986
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU794028020502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T21:00:08.609Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20981.00',
          mileage: '29317'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9467702,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU794028',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T22:45:43.147Z'
        }
      },
      sort: [
        1591650008609
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU198144020502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:59:03.648Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19981.00',
          mileage: '8729'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9497901,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU198144',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:51:54.030Z'
        }
      },
      sort: [
        1591649943648
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU171600020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:58:31.747Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21281.00',
          mileage: '27046'
        },
        sellArea: '600',
        dealerCd: '02050',
        certificationNumber: 9478069,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU171600',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:57:16.150Z'
        }
      },
      sort: [
        1591649911747
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ162405400112020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:57:13.206Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '18096.00',
          mileage: '25921'
        },
        dealerCd: '40011',
        certificationNumber: 9240141,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ162405',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-11T21:23:02.719Z'
        }
      },
      sort: [
        1591649833206
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK4KU293430020502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:56:28.406Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26981.00',
          mileage: '31261'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9497977,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK4KU293430',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:07:51.512Z'
        }
      },
      sort: [
        1591649788406
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBXXK1030182020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:55:40.026Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21581.00',
          mileage: '11365'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9497913,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBXXK1030182',
        model: {
          modelDescription: 'C-HR LIMITED',
          modelSeries: 'CHR',
          modelNumber: '2408',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:55:15.812Z'
        }
      },
      sort: [
        1591649740026
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV9JD161111400112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:55:32.046Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27474.60',
          mileage: '45480'
        },
        sellArea: '230',
        dealerCd: '40011',
        certificationNumber: 9443758,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV9JD161111',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T14:02:59.743Z'
        }
      },
      sort: [
        1591649732046
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA16H8702171020502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:54:45.106Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19981.00',
          mileage: '37439'
        },
        sellArea: '110',
        dealerCd: '02050',
        certificationNumber: 9486397,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA16H8702171',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:43:10.289Z'
        }
      },
      sort: [
        1591649685106
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV7HY182352450552020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:54:24.387Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13000.0000',
          mileage: '22137'
        },
        sellArea: '800',
        dealerCd: '45055',
        certificationNumber: 9466086,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV7HY182352',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T20:18:35.924Z'
        }
      },
      sort: [
        1591649664387
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN7HM035222041632020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:54:21.484Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '34995.00',
          mileage: '32076'
        },
        sellArea: '150',
        dealerCd: '04163',
        certificationNumber: 9499930,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN7HM035222',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7568',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:00:00.105Z'
        }
      },
      sort: [
        1591649661484
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9FU106448450552020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:54:17.725Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15000.0000',
          mileage: '45323'
        },
        sellArea: '800',
        dealerCd: '45055',
        certificationNumber: 9494136,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9FU106448',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:18:42.436Z'
        }
      },
      sort: [
        1591649657725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN0HX073653200192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:54:15.464Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27250.00',
          mileage: '50805'
        },
        dealerCd: '20019',
        certificationNumber: 9369185,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN0HX073653',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7559',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-05T15:30:23.037Z'
        }
      },
      sort: [
        1591649655464
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU662277450552020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:54:09.944Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14200.0000',
          mileage: '37468'
        },
        sellArea: '160',
        dealerCd: '45055',
        certificationNumber: 9467435,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU662277',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T20:12:57.847Z'
        }
      },
      sort: [
        1591649649944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN5LX219994450552020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:53:59.924Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '44000.0000',
          mileage: '3517'
        },
        sellArea: '800',
        dealerCd: '45055',
        certificationNumber: 9474834,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN5LX219994',
        model: {
          modelDescription: 'TACOMA TRD PRO 4X4 DBL CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7597',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:11:04.140Z'
        }
      },
      sort: [
        1591649639924
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV2HJ129927450552020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:53:53.142Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17000.0000',
          mileage: '44304'
        },
        sellArea: '800',
        dealerCd: '45055',
        certificationNumber: 9502798,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV2HJ129927',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T17:05:58.189Z'
        }
      },
      sort: [
        1591649633142
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV4HJ143404400112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:53:00.035Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28290.60',
          mileage: '9369'
        },
        sellArea: '230',
        dealerCd: '40011',
        certificationNumber: 9491556,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV4HJ143404',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:16:13.998Z'
        }
      },
      sort: [
        1591649580035
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3GW440119450792020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:52:56.341Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17995.0000',
          mileage: '45137'
        },
        dealerCd: '45079',
        certificationNumber: 9378272,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3GW440119',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-12T19:55:17.339Z'
        }
      },
      sort: [
        1591649576341
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV8HJ139846450792020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:52:48.762Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19995.0000',
          mileage: '31518'
        },
        sellArea: '800',
        dealerCd: '45079',
        certificationNumber: 9501251,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV8HJ139846',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:37:20.937Z'
        }
      },
      sort: [
        1591649568762
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFHXHS025186450792020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:52:40.742Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '26600.0000',
          mileage: '74363'
        },
        sellArea: '800',
        dealerCd: '45079',
        certificationNumber: 9435555,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFHXHS025186',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T17:21:37.126Z'
        }
      },
      sort: [
        1591649560742
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7K5657046020502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:51:47.420Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '33981.00',
          mileage: '14945'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9489659,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7K5657046',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:47:13.895Z'
        }
      },
      sort: [
        1591649507420
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP679013041632020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:51:41.322Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16595.00',
          mileage: '24554'
        },
        sellArea: '120',
        dealerCd: '04163',
        certificationNumber: 9497409,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP679013',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:12:34.895Z'
        }
      },
      sort: [
        1591649501322
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU716457480212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:51:34.002Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17988.00',
          mileage: '28840'
        },
        dealerCd: '48021',
        certificationNumber: 9220407,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU716457',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-29T17:04:59.177Z'
        }
      },
      sort: [
        1591649494002
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH0ES010483400112020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:51:20.921Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22975.00',
          mileage: '62820'
        },
        sellArea: '230',
        dealerCd: '40011',
        certificationNumber: 9458388,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH0ES010483',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T13:45:44.865Z'
        }
      },
      sort: [
        1591649480921
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU387415340962020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:50:43.240Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15700.00',
          mileage: '29570'
        },
        sellArea: '220',
        dealerCd: '34096',
        certificationNumber: 9493977,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU387415',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:13:28.491Z'
        }
      },
      sort: [
        1591649443240
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1K5639450020502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:50:36.279Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '38985.00',
          mileage: '31398'
        },
        sellArea: '150',
        dealerCd: '02050',
        certificationNumber: 9486433,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1K5639450',
        model: {
          modelDescription: '4RUNNER 4X4 NIGHTSHADE V6',
          modelSeries: '4RUNNER',
          modelNumber: '8669',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:59:52.795Z'
        }
      },
      sort: [
        1591649436279
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK6LU309076340962020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:49:52.918Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23959.79',
          mileage: '2655'
        },
        sellArea: '220',
        dealerCd: '34096',
        certificationNumber: 9443924,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK6LU309076',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T17:23:20.623Z'
        }
      },
      sort: [
        1591649392918
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV5JJ202778400112020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:49:29.704Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '21625.00',
          mileage: '14305'
        },
        dealerCd: '40011',
        certificationNumber: 9292078,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV5JJ202778',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-16T15:07:40.698Z'
        }
      },
      sort: [
        1591649369704
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC956805380242020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:49:20.385Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '27470'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9499248,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC956805',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:13:31.477Z'
        }
      },
      sort: [
        1591649360385
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV3GD197161480212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:49:12.123Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19488.00',
          mileage: '63503'
        },
        sellArea: '210',
        dealerCd: '48021',
        certificationNumber: 9495619,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV3GD197161',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:51:34.837Z'
        }
      },
      sort: [
        1591649352123
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8G5305147291032020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:49:01.364Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26000.0000',
          mileage: '52450'
        },
        sellArea: '160',
        dealerCd: '29103',
        certificationNumber: 9432754,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8G5305147',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T13:44:31.171Z'
        }
      },
      sort: [
        1591649341364
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH0GS288495291032020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:48:58.622Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24495.0000',
          mileage: '40056'
        },
        sellArea: '160',
        dealerCd: '29103',
        certificationNumber: 9427191,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH0GS288495',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T17:40:02.359Z'
        }
      },
      sort: [
        1591649338622
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS382148291032020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:48:56.344Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34721.0000',
          mileage: '3968'
        },
        sellArea: '160',
        dealerCd: '29103',
        certificationNumber: 9487496,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS382148',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:10:28.351Z'
        }
      },
      sort: [
        1591649336344
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4JJ183379160052020-05-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:48:26.184Z',
          retailDate: '2020-05-13',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '26437'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9464174,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4JJ183379',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T14:38:23.174Z'
        }
      },
      sort: [
        1591649306184
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS416145400112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:47:25.503Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30672.00',
          mileage: '36419'
        },
        sellArea: '230',
        dealerCd: '40011',
        certificationNumber: 9458391,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS416145',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T13:50:07.509Z'
        }
      },
      sort: [
        1591649245503
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F13HX595474480212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:47:00.817Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30738.00',
          mileage: '70054'
        },
        sellArea: '230',
        dealerCd: '48021',
        certificationNumber: 9463588,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F13HX595474',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T21:06:38.976Z'
        }
      },
      sort: [
        1591649220817
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC844167201162020-03-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:46:36.418Z',
          retailDate: '2020-03-09',
          rdrStatus: 'H',
          soldAmount: '15899.00',
          mileage: '16111'
        },
        dealerCd: '20116',
        certificationNumber: 9503586,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC844167',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:42:33.059Z'
        }
      },
      sort: [
        1591649196418
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU746309121572020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:45:55.555Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12425.00',
          mileage: '43425'
        },
        sellArea: '210',
        dealerCd: '12157',
        certificationNumber: 9494787,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU746309',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T23:15:27.262Z'
        }
      },
      sort: [
        1591649155555
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7JC971537041632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:45:25.276Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16495.00',
          mileage: '17857'
        },
        sellArea: '120',
        dealerCd: '04163',
        certificationNumber: 9478428,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7JC971537',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:51:48.240Z'
        }
      },
      sort: [
        1591649125276
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH5HS417297400112020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:45:20.055Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '30475.00',
          mileage: '30557'
        },
        sellArea: '230',
        dealerCd: '40011',
        certificationNumber: 9455120,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH5HS417297',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T13:14:04.499Z'
        }
      },
      sort: [
        1591649120055
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP680150400112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:43:59.894Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16975.00',
          mileage: '9503'
        },
        dealerCd: '40011',
        certificationNumber: 9495571,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP680150',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:24:35.980Z'
        }
      },
      sort: [
        1591649039894
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU354811480212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:43:43.215Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15488.00',
          mileage: '47948'
        },
        sellArea: '210',
        dealerCd: '48021',
        certificationNumber: 9438664,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU354811',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T16:29:27.926Z'
        }
      },
      sort: [
        1591649023215
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE3LJ014065380242020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:43:05.275Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20891.00',
          mileage: '1310'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9492527,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE3LJ014065',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:22:55.893Z'
        }
      },
      sort: [
        1591648985275
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK3JU519875160052020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:42:55.493Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '24300.00',
          mileage: '24222'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9418940,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK3JU519875',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T19:20:11.806Z'
        }
      },
      sort: [
        1591648975493
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZ3DCXHS834885042502020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:42:32.733Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '30769'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9481979,
        makeCode: 'TOYOTA',
        vin: '5TDZZ3DCXHS834885',
        model: {
          modelDescription: 'SIENNA L 3.5L FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5328',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:36:03.624Z'
        }
      },
      sort: [
        1591648952733
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5EN8HX055756460782020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:39:45.512Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21971.96',
          mileage: '27408'
        },
        sellArea: '130',
        dealerCd: '46078',
        certificationNumber: 9498275,
        makeCode: 'TOYOTA',
        vin: '5TFSX5EN8HX055756',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7516',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T22:33:58.127Z'
        }
      },
      sort: [
        1591648785512
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1EU429701400112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:39:21.831Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14974.00',
          mileage: '59579'
        },
        sellArea: '230',
        dealerCd: '40011',
        certificationNumber: 9501389,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1EU429701',
        model: {
          modelDescription: 'CAMRY 2014.5 SE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:18:23.255Z'
        }
      },
      sort: [
        1591648761831
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB7FU181534480212020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:39:16.650Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17988.00',
          mileage: '30319'
        },
        dealerCd: '48021',
        certificationNumber: 9483485,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB7FU181534',
        model: {
          modelDescription: 'AVALON',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: '2015'
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:42:20.470Z'
        }
      },
      sort: [
        1591648756650
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDW5G14HS151895160042020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:38:56.709Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '47224.00',
          mileage: '35150'
        },
        sellArea: '220',
        dealerCd: '16004',
        certificationNumber: 9499873,
        makeCode: 'TOYOTA',
        vin: '5TDDW5G14HS151895',
        model: {
          modelDescription: 'SEQUOIA 4X4 PLAT 7-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7944',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:35:53.555Z'
        }
      },
      sort: [
        1591648736709
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1K5622177160052020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:38:52.191Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '35735.00',
          mileage: '16612'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9463108,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1K5622177',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T17:57:50.678Z'
        }
      },
      sort: [
        1591648732191
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3HM078968480282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:38:47.291Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31698.00',
          mileage: '16181'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9438663,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3HM078968',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T16:27:02.365Z'
        }
      },
      sort: [
        1591648727291
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5ANXKX209049460782020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:38:15.871Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '32220.00',
          mileage: '4714'
        },
        sellArea: '130',
        dealerCd: '46078',
        certificationNumber: 9461000,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5ANXKX209049',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7559',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T17:15:42.228Z'
        }
      },
      sort: [
        1591648695871
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN2EM145378400112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:37:29.649Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23950.00',
          mileage: '77151'
        },
        sellArea: '210',
        dealerCd: '40011',
        certificationNumber: 9501540,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN2EM145378',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:08:21.719Z'
        }
      },
      sort: [
        1591648649649
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH5HS027144160052020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:37:28.758Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '28056'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9425270,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH5HS027144',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T14:50:45.908Z'
        }
      },
      sort: [
        1591648648758
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DC9FS559135460782020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:36:14.659Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20991.00',
          mileage: '39234'
        },
        sellArea: '110',
        dealerCd: '46078',
        certificationNumber: 9476307,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DC9FS559135',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:02:53.606Z'
        }
      },
      sort: [
        1591648574659
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV5JJ196392120772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:36:04.699Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '12251'
        },
        sellArea: '210',
        dealerCd: '12077',
        certificationNumber: 9503561,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV5JJ196392',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:32:01.424Z'
        }
      },
      sort: [
        1591648564699
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC767863201162020-02-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:36:00.418Z',
          retailDate: '2020-02-12',
          rdrStatus: 'H',
          soldAmount: '15998.00',
          mileage: '22888'
        },
        dealerCd: '20116',
        certificationNumber: 9503562,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC767863',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:32:56.824Z'
        }
      },
      sort: [
        1591648560418
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMEWRFVXKD506717020552020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:35:45.418Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '38900.00',
          mileage: '3275'
        },
        sellArea: '130',
        dealerCd: '02055',
        certificationNumber: 9503567,
        makeCode: 'TOYOTA',
        vin: 'JTMEWRFVXKD506717',
        model: {
          modelDescription: 'RAV4 HYBRID XSE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:33:57.047Z'
        }
      },
      sort: [
        1591648545418
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8GM027061311602020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:35:27.279Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27628.00',
          mileage: '28331'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9412444,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8GM027061',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T17:22:54.477Z'
        }
      },
      sort: [
        1591648527279
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV2HJ145118120422020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:33:39.238Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23900.00',
          mileage: '28479'
        },
        sellArea: '230',
        dealerCd: '12042',
        certificationNumber: 9498998,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV2HJ145118',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:05:44.237Z'
        }
      },
      sort: [
        1591648419238
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV5KY517731160052020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:33:03.957Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '13988.00',
          mileage: '5340'
        },
        sellArea: '210',
        dealerCd: '16005',
        certificationNumber: 9415629,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV5KY517731',
        model: {
          modelDescription: 'YARIS Sedan 4Dr.Sedan L 6MT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6267',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T19:23:46.852Z'
        }
      },
      sort: [
        1591648383957
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBZV9GY112526045082020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:32:37.378Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '9745.00',
          mileage: '40100'
        },
        sellArea: '110',
        dealerCd: '04508',
        certificationNumber: 9462287,
        makeCode: 'TOYOTA',
        vin: '3MYDLBZV9GY112526',
        model: {
          modelDescription: 'SCION iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6263',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-10T01:42:11.047Z'
        }
      },
      sort: [
        1591648357378
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV7FW169418340892020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:32:35.197Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14700.00',
          mileage: '62345'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9477245,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV7FW169418',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T13:25:03.247Z'
        }
      },
      sort: [
        1591648355197
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMEWRFV5KJ022830460782020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:32:35.042Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '35641.00',
          mileage: '8161'
        },
        sellArea: '130',
        dealerCd: '46078',
        certificationNumber: 9495579,
        makeCode: 'TOYOTA',
        vin: 'JTMEWRFV5KJ022830',
        model: {
          modelDescription: 'RAV4 HYBRID XSE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:28:11.742Z'
        }
      },
      sort: [
        1591648355042
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMYFREV1HJ710198045082020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:31:59.456Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23726.00',
          mileage: '19182'
        },
        sellArea: '110',
        dealerCd: '04508',
        certificationNumber: 9499573,
        makeCode: 'TOYOTA',
        vin: 'JTMYFREV1HJ710198',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:53:07.088Z'
        }
      },
      sort: [
        1591648319456
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV4HJ714639045082020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:31:36.616Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18746.00',
          mileage: '35910'
        },
        sellArea: '110',
        dealerCd: '04508',
        certificationNumber: 9473170,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV4HJ714639',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:33:11.018Z'
        }
      },
      sort: [
        1591648296616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH3HS377908480212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:31:20.625Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '34988.00',
          mileage: '7823'
        },
        sellArea: '210',
        dealerCd: '48021',
        certificationNumber: 9463157,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH3HS377908',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T18:24:30.372Z'
        }
      },
      sort: [
        1591648280625
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0JW768489045082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:31:10.925Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23963.00',
          mileage: '29300'
        },
        sellArea: '110',
        dealerCd: '04508',
        certificationNumber: 9458122,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0JW768489',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T02:46:38.824Z'
        }
      },
      sort: [
        1591648270925
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU5H3038435045082020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:30:46.983Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18595.00',
          mileage: '36278'
        },
        sellArea: '110',
        dealerCd: '04508',
        certificationNumber: 9443866,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU5H3038435',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T16:13:53.666Z'
        }
      },
      sort: [
        1591648246983
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS377060460782020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:30:41.584Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29911.00',
          mileage: '44895'
        },
        sellArea: '150',
        dealerCd: '46078',
        certificationNumber: 9413391,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS377060',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T14:54:32.485Z'
        }
      },
      sort: [
        1591648241584
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP725815045082020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:30:17.042Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14942.00',
          mileage: '20936'
        },
        sellArea: '110',
        dealerCd: '04508',
        certificationNumber: 9479321,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP725815',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T18:30:50.430Z'
        }
      },
      sort: [
        1591648217042
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAZ5CN8HX053868043442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:29:24.535Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31983.00',
          mileage: '33673'
        },
        sellArea: '110',
        dealerCd: '04344',
        certificationNumber: 9496473,
        makeCode: 'TOYOTA',
        vin: '5TFAZ5CN8HX053868',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:21:46.165Z'
        }
      },
      sort: [
        1591648164535
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F10JX695800480212020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:28:48.255Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35488.00',
          mileage: '26336'
        },
        sellArea: '210',
        dealerCd: '48021',
        certificationNumber: 9496222,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F10JX695800',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:34:54.632Z'
        }
      },
      sort: [
        1591648128255
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3JW724499460782020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:28:35.334Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26911.00',
          mileage: '16582'
        },
        sellArea: '130',
        dealerCd: '46078',
        certificationNumber: 9494369,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3JW724499',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:41:31.665Z'
        }
      },
      sort: [
        1591648115334
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7HJ701060371122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:28:08.554Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '17696'
        },
        sellArea: '800',
        dealerCd: '37112',
        certificationNumber: 9500011,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7HJ701060',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:24:17.900Z'
        }
      },
      sort: [
        1591648088554
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS413920260242020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:28:05.114Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27833.00',
          mileage: '26412'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9420377,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS413920',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T19:19:48.215Z'
        }
      },
      sort: [
        1591648085114
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9F5272951220302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:28:02.035Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26500.00',
          mileage: '58550'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9481358,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9F5272951',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T13:13:22.395Z'
        }
      },
      sort: [
        1591648082035
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7KM240768460782020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:27:07.761Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '30911.00',
          mileage: '22868'
        },
        dealerCd: '46078',
        certificationNumber: 9389137,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7KM240768',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T17:15:10.728Z'
        }
      },
      sort: [
        1591648027761
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAEXLP014935460782020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:26:01.878Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16913.15',
          mileage: '13119'
        },
        sellArea: '150',
        dealerCd: '46078',
        certificationNumber: 9466919,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAEXLP014935',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T15:51:15.996Z'
        }
      },
      sort: [
        1591647961878
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9JU616903160052020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:25:37.681Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '10756'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9483870,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9JU616903',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:31:51.998Z'
        }
      },
      sort: [
        1591647937681
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9H5437335460782020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:24:30.899Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '36511.00',
          mileage: '45358'
        },
        sellArea: '130',
        dealerCd: '46078',
        certificationNumber: 9476321,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9H5437335',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:06:42.410Z'
        }
      },
      sort: [
        1591647870899
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN9KX188148060362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:23:56.460Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '43000.00',
          mileage: '1191'
        },
        sellArea: '170',
        dealerCd: '06036',
        certificationNumber: 9427432,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN9KX188148',
        model: {
          modelDescription: 'TACOMA TRD PRO 4X4 DBL CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7598',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T19:21:10.407Z'
        }
      },
      sort: [
        1591647836460
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRWRFV8KD023499180352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:23:06.859Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '11'
        },
        sellArea: '190',
        dealerCd: '18035',
        certificationNumber: 9503537,
        makeCode: 'TOYOTA',
        vin: 'JTMRWRFV8KD023499',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:19:06.249Z'
        }
      },
      sort: [
        1591647786859
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3JW808355260242020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:22:43.077Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19399.00',
          mileage: '27766'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9434782,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3JW808355',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T15:31:36.448Z'
        }
      },
      sort: [
        1591647763077
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC785595410702020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:22:25.558Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15992.00',
          mileage: '25621'
        },
        sellArea: '130',
        dealerCd: '41070',
        certificationNumber: 9484090,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC785595',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:39:39.948Z'
        }
      },
      sort: [
        1591647745558
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP613497041502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:21:16.177Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15995.00',
          mileage: '25231'
        },
        sellArea: '110',
        dealerCd: '04150',
        certificationNumber: 9478400,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP613497',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:34:21.457Z'
        }
      },
      sort: [
        1591647676177
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU739803160052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:20:14.863Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17985.00',
          mileage: '43512'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9418910,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU739803',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T19:08:28.898Z'
        }
      },
      sort: [
        1591647614863
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU688158260242020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:18:05.901Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19750.00',
          mileage: '40425'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9481657,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU688158',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:17:55.515Z'
        }
      },
      sort: [
        1591647485901
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN4KM075622371122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:16:56.078Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '33527.77',
          mileage: '3206'
        },
        sellArea: '800',
        dealerCd: '37112',
        certificationNumber: 9494304,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN4KM075622',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7568',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:16:27.576Z'
        }
      },
      sort: [
        1591647416078
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH9FS169858340892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:16:34.492Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23994.00',
          mileage: '47285'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9485530,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH9FS169858',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:13:22.600Z'
        }
      },
      sort: [
        1591647394492
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV9HW581551160052020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:15:42.072Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22588.00',
          mileage: '30528'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9495676,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV9HW581551',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:07:03.509Z'
        }
      },
      sort: [
        1591647342072
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRWRFV4KD024925180352020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:15:42.000Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '10'
        },
        sellArea: '190',
        dealerCd: '18035',
        certificationNumber: 9503515,
        makeCode: 'TOYOTA',
        vin: 'JTMRWRFV4KD024925',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:11:28.664Z'
        }
      },
      sort: [
        1591647342000
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4JJ194687371122020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:15:23.340Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19471.53',
          mileage: '35624'
        },
        sellArea: '800',
        dealerCd: '37112',
        certificationNumber: 9458668,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4JJ194687',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T17:10:08.595Z'
        }
      },
      sort: [
        1591647323340
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK6FR451152121572020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:15:22.311Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14992.01',
          mileage: '22220'
        },
        sellArea: '210',
        dealerCd: '12157',
        certificationNumber: 9455732,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK6FR451152',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T21:13:52.266Z'
        }
      },
      sort: [
        1591647322311
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXJJ739032371122020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:14:11.688Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29100.00',
          mileage: '18067'
        },
        sellArea: '800',
        dealerCd: '37112',
        certificationNumber: 9482458,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXJJ739032',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:38:27.527Z'
        }
      },
      sort: [
        1591647251688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMG1RFV5KD034997371122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:13:04.237Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24245.00',
          mileage: '3814'
        },
        sellArea: '800',
        dealerCd: '37112',
        certificationNumber: 9494410,
        makeCode: 'TOYOTA',
        vin: 'JTMG1RFV5KD034997',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:58:30.153Z'
        }
      },
      sort: [
        1591647184237
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAEXLJ002868371122020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:10:17.409Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19668.00',
          mileage: '15696'
        },
        sellArea: '800',
        dealerCd: '37112',
        certificationNumber: 9415824,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAEXLJ002868',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T20:31:28.803Z'
        }
      },
      sort: [
        1591647017409
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA19K8701555260242020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:10:06.836Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22400.00',
          mileage: '13734'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9487259,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA19K8701555',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:47:11.284Z'
        }
      },
      sort: [
        1591647006836
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE2LJ045262371122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:09:17.676Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '23764'
        },
        sellArea: '500',
        dealerCd: '37112',
        certificationNumber: 9415589,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE2LJ045262',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T18:57:55.500Z'
        }
      },
      sort: [
        1591646957676
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHW5F11JX776290330302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:09:00.636Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '42130.00',
          mileage: '19890'
        },
        sellArea: '230',
        dealerCd: '33030',
        certificationNumber: 9469151,
        makeCode: 'TOYOTA',
        vin: '5TFHW5F11JX776290',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8374',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T21:37:29.522Z'
        }
      },
      sort: [
        1591646940636
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRWRFV5KD023038180352020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:08:07.835Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '215'
        },
        sellArea: '190',
        dealerCd: '18035',
        certificationNumber: 9503498,
        makeCode: 'TOYOTA',
        vin: 'JTMRWRFV5KD023038',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T20:03:41.098Z'
        }
      },
      sort: [
        1591646887835
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV6JW433126045542020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:08:00.134Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20977.0000',
          mileage: '50998'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9484305,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV6JW433126',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:17:29.161Z'
        }
      },
      sort: [
        1591646880134
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU204545371122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:59.109Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25395.00',
          mileage: '38856'
        },
        sellArea: '600',
        dealerCd: '37112',
        certificationNumber: 9501614,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU204545',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:52:09.396Z'
        }
      },
      sort: [
        1591646879109
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV2HW329459045542020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:57.253Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23973.0000',
          mileage: '37903'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9411742,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV2HW329459',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T21:02:23.388Z'
        }
      },
      sort: [
        1591646877253
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH1HS030445045542020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:54.774Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23749.0000',
          mileage: '40420'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9482586,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH1HS030445',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T22:51:35.000Z'
        }
      },
      sort: [
        1591646874774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5JU051615045542020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:52.434Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22945.0000',
          mileage: '26036'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9414208,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5JU051615',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T20:55:00.755Z'
        }
      },
      sort: [
        1591646872434
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJJREV1JD212930045542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:49.254Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27874.0000',
          mileage: '38314'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9457809,
        makeCode: 'TOYOTA',
        vin: 'JTMJJREV1JD212930',
        model: {
          modelDescription: 'RAV4 HYBRID SE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T19:43:25.206Z'
        }
      },
      sort: [
        1591646869254
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP642332045542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:46.134Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14855.0000',
          mileage: '37650'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9479557,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP642332',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T20:42:13.286Z'
        }
      },
      sort: [
        1591646866134
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREVXHW355833045542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:43.655Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21217.0000',
          mileage: '16759'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9493900,
        makeCode: 'TOYOTA',
        vin: '2T3WFREVXHW355833',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:38:35.516Z'
        }
      },
      sort: [
        1591646863655
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC1HS895114045542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:40.449Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25461.0000',
          mileage: '40807'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9494700,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC1HS895114',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:06:22.489Z'
        }
      },
      sort: [
        1591646860449
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU8H3036551045542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:37.854Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19984.0000',
          mileage: '44551'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9407646,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU8H3036551',
        model: {
          modelDescription: 'PRIUS THREE TOURING',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1226',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T22:19:00.850Z'
        }
      },
      sort: [
        1591646857854
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ130266311122020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:37.009Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '17646.00',
          mileage: '42442'
        },
        sellArea: '160',
        dealerCd: '31112',
        certificationNumber: 9393279,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ130266',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T16:00:48.209Z'
        }
      },
      sort: [
        1591646857009
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFPXH3022046045542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:34.689Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21984.0000',
          mileage: '49229'
        },
        dealerCd: '04554',
        certificationNumber: 9367312,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFPXH3022046',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-03T22:48:36.161Z'
        }
      },
      sort: [
        1591646854689
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F15JX731040045542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:07:31.666Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '38831.00',
          mileage: '37234'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9484467,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F15JX731040',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:30:24.974Z'
        }
      },
      sort: [
        1591646851666
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC8HS171777340252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:05:23.906Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28569.11',
          mileage: '11854'
        },
        sellArea: '220',
        dealerCd: '34025',
        certificationNumber: 9493611,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC8HS171777',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T13:16:01.441Z'
        }
      },
      sort: [
        1591646723906
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN4HX057962311122020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:05:07.364Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27499.00',
          mileage: '35974'
        },
        sellArea: '160',
        dealerCd: '31112',
        certificationNumber: 9442422,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN4HX057962',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7543',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T17:39:24.119Z'
        }
      },
      sort: [
        1591646707364
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW593956340252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:04:41.544Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '28606'
        },
        sellArea: '160',
        dealerCd: '34025',
        certificationNumber: 9493615,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW593956',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T13:18:44.480Z'
        }
      },
      sort: [
        1591646681544
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0JW720281180352020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:04:06.725Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19991.00',
          mileage: '33893'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9501220,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0JW720281',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:17:58.791Z'
        }
      },
      sort: [
        1591646646725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HJ111881371742020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:03:43.664Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19090.87',
          mileage: '46107'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9475647,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HJ111881',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T13:00:24.170Z'
        }
      },
      sort: [
        1591646623664
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3A1RFV2KW010925340252020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:03:34.966Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29150.00',
          mileage: '7600'
        },
        sellArea: '220',
        dealerCd: '34025',
        certificationNumber: 9493623,
        makeCode: 'TOYOTA',
        vin: '2T3A1RFV2KW010925',
        model: {
          modelDescription: 'RAV4 XLE PREM AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4478',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T13:25:22.229Z'
        }
      },
      sort: [
        1591646614966
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV8HJ124263180352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:03:31.605Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '29709'
        },
        sellArea: '160',
        dealerCd: '18035',
        certificationNumber: 9497072,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV8HJ124263',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:44:59.488Z'
        }
      },
      sort: [
        1591646611605
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW630409480452020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:03:10.593Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '34490'
        },
        sellArea: '210',
        dealerCd: '48045',
        certificationNumber: 9451613,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW630409',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T20:33:06.273Z'
        }
      },
      sort: [
        1591646590593
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW641025371742020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:02:57.030Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20699.00',
          mileage: '34469'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9494671,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW641025',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:44:58.963Z'
        }
      },
      sort: [
        1591646577030
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH3HS374742311122020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:02:51.925Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24559.00',
          mileage: '38027'
        },
        dealerCd: '31112',
        certificationNumber: 9338397,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH3HS374742',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-15T20:45:04.880Z'
        }
      },
      sort: [
        1591646571925
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV0HW336723180352020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:02:48.645Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18605.00',
          mileage: '13259'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9404695,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV0HW336723',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T16:52:09.428Z'
        }
      },
      sort: [
        1591646568645
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9HM095712340672020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:02:32.405Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '29988.00',
          mileage: '67276'
        },
        sellArea: '220',
        dealerCd: '34067',
        certificationNumber: 9444809,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9HM095712',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-15T18:36:26.798Z'
        }
      },
      sort: [
        1591646552405
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV6KY505913260242020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:02:18.925Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14201.00',
          mileage: '13001'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9464788,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV6KY505913',
        model: {
          modelDescription: 'YARIS Sedan 4Dr.Sedan XLE6AT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6268',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T20:16:51.962Z'
        }
      },
      sort: [
        1591646538925
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9LC090270180352020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:02:02.725Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '2600'
        },
        sellArea: '500',
        dealerCd: '18035',
        certificationNumber: 9471079,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9LC090270',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:03:33.199Z'
        }
      },
      sort: [
        1591646522725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV0KW019709340252020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:01:38.924Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22559.00',
          mileage: '27048'
        },
        sellArea: '500',
        dealerCd: '34025',
        certificationNumber: 9494348,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV0KW019709',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:31:17.120Z'
        }
      },
      sort: [
        1591646498924
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK5J3003599340252020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:00:44.344Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20006.07',
          mileage: '17400'
        },
        sellArea: '220',
        dealerCd: '34025',
        certificationNumber: 9495673,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK5J3003599',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:06:20.451Z'
        }
      },
      sort: [
        1591646444344
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2HJ143506180352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:00:36.646Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20493.00',
          mileage: '15457'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9477787,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2HJ143506',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:05:27.725Z'
        }
      },
      sort: [
        1591646436646
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC799639311122020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:00:20.184Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13600.00',
          mileage: '30569'
        },
        sellArea: '160',
        dealerCd: '31112',
        certificationNumber: 9465664,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC799639',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:29:17.310Z'
        }
      },
      sort: [
        1591646420184
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH6HS379409340892020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T20:00:08.384Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '30821.00',
          mileage: '38111'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9394275,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH6HS379409',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T22:00:06.599Z'
        }
      },
      sort: [
        1591646408384
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F11HX629390050672020-06-04V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:59:56.124Z',
          retailDate: '2020-06-04',
          rdrStatus: 'V',
          soldAmount: '30798.00',
          mileage: '52112'
        },
        sellArea: '120',
        dealerCd: '05067',
        certificationNumber: 9486297,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F11HX629390',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:38:13.407Z'
        }
      },
      sort: [
        1591646396124
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK8KU211523340252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:59:46.483Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24829.07',
          mileage: '25802'
        },
        sellArea: '220',
        dealerCd: '34025',
        certificationNumber: 9481533,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK8KU211523',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:30:35.373Z'
        }
      },
      sort: [
        1591646386483
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HD209139180352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:59:17.203Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19923.00',
          mileage: '30115'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9502487,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HD209139',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T15:47:31.449Z'
        }
      },
      sort: [
        1591646357203
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHW5F13HX642200260242020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:58:56.222Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '34900.00',
          mileage: '21341'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9471296,
        makeCode: 'TOYOTA',
        vin: '5TFHW5F13HX642200',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8374',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T19:22:42.914Z'
        }
      },
      sort: [
        1591646336222
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV7KW007198020512020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:58:53.444Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23900.00',
          mileage: '28284'
        },
        sellArea: '600',
        dealerCd: '02051',
        certificationNumber: 9494779,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV7KW007198',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T23:05:05.940Z'
        }
      },
      sort: [
        1591646333444
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU178628340252020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:58:26.324Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17715.00',
          mileage: '32128'
        },
        dealerCd: '34025',
        certificationNumber: 9347349,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU178628',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-21T19:39:50.435Z'
        }
      },
      sort: [
        1591646306324
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KC007246311122020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:58:00.084Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '24150.00',
          mileage: '17078'
        },
        sellArea: '500',
        dealerCd: '31112',
        certificationNumber: 9425369,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KC007246',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T15:31:54.572Z'
        }
      },
      sort: [
        1591646280084
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV3GW454308480652020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:57:47.424Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21246.00',
          mileage: '55343'
        },
        sellArea: '800',
        dealerCd: '48065',
        certificationNumber: 9497028,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV3GW454308',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:26:40.887Z'
        }
      },
      sort: [
        1591646267424
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU211174340252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:57:25.723Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20934.95',
          mileage: '8945'
        },
        sellArea: '220',
        dealerCd: '34025',
        certificationNumber: 9493624,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU211174',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T13:27:46.053Z'
        }
      },
      sort: [
        1591646245723
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU424043311922020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:57:20.024Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '16928'
        },
        sellArea: '160',
        dealerCd: '31192',
        certificationNumber: 9503352,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU424043',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T19:19:44.240Z'
        }
      },
      sort: [
        1591646240024
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DCXLS022383020512020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:56:55.741Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25900.00',
          mileage: '29086'
        },
        sellArea: '500',
        dealerCd: '02051',
        certificationNumber: 9446796,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DCXLS022383',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T21:05:01.020Z'
        }
      },
      sort: [
        1591646215741
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXFU885147260242020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:56:09.301Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13352.60',
          mileage: '75745'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9477568,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXFU885147',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T16:31:56.093Z'
        }
      },
      sort: [
        1591646169301
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU406612340932020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:55:45.062Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15423.00',
          mileage: '40572'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9484420,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU406612',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:10:05.699Z'
        }
      },
      sort: [
        1591646145062
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB3GU242282200962020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:54:34.926Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '36019'
        },
        dealerCd: '20096',
        certificationNumber: 9503424,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB3GU242282',
        model: {
          modelDescription: 'AVALON 4-DR XLE PREMIUM',
          modelSeries: 'AVALON',
          modelNumber: '3546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T19:40:23.459Z'
        }
      },
      sort: [
        1591646074926
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS439058260242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:53:54.926Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28732.00',
          mileage: '49223'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9451529,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS439058',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T19:38:25.045Z'
        }
      },
      sort: [
        1591646034926
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC7LS025063260242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:52:30.726Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29400.00',
          mileage: '24440'
        },
        sellArea: '500',
        dealerCd: '26024',
        certificationNumber: 9483951,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC7LS025063',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:01:28.856Z'
        }
      },
      sort: [
        1591645950726
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU788539220302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:52:10.105Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17990.00',
          mileage: '16560'
        },
        sellArea: '500',
        dealerCd: '22030',
        certificationNumber: 9479023,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU788539',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T15:12:09.121Z'
        }
      },
      sort: [
        1591645930105
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F16JX716603150502020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:51:47.905Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '28250.00',
          mileage: '62948'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9503452,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F16JX716603',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T19:48:16.864Z'
        }
      },
      sort: [
        1591645907905
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMBZ5DN1HM010692160052020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:51:06.845Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27128.00',
          mileage: '66114'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9457721,
        makeCode: 'TOYOTA',
        vin: '3TMBZ5DN1HM010692',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DBL CAB LONG BED',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7172',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T18:35:05.741Z'
        }
      },
      sort: [
        1591645866845
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ124476311122020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:50:39.986Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '19495.00',
          mileage: '21529'
        },
        sellArea: '160',
        dealerCd: '31112',
        certificationNumber: 9393286,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ124476',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T16:02:50.093Z'
        }
      },
      sort: [
        1591645839986
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3N1RFV0KW075869260242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:50:13.264Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '32312.00',
          mileage: '5137'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9487245,
        makeCode: 'TOYOTA',
        vin: '2T3N1RFV0KW075869',
        model: {
          modelDescription: 'RAV4 LIMITED AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:41:28.794Z'
        }
      },
      sort: [
        1591645813264
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS394236210652020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:49:25.403Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29000.00',
          mileage: '22713'
        },
        sellArea: '160',
        dealerCd: '21065',
        certificationNumber: 9485293,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS394236',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:38:45.219Z'
        }
      },
      sort: [
        1591645765403
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU739750460422020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:49:14.263Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18595.00',
          mileage: '30444'
        },
        sellArea: '130',
        dealerCd: '46042',
        certificationNumber: 9497787,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU739750',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:21:47.871Z'
        }
      },
      sort: [
        1591645754263
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN0HM026510160052020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:48:55.893Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29827.00',
          mileage: '58942'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9499446,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN0HM026510',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7568',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:07:34.915Z'
        }
      },
      sort: [
        1591645735893
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1GC640638200962020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:48:52.182Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12495.00',
          mileage: '59186'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9503439,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1GC640638',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T19:43:26.657Z'
        }
      },
      sort: [
        1591645732182
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS435743311122020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:48:42.723Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '27887.00',
          mileage: '14493'
        },
        sellArea: '160',
        dealerCd: '31112',
        certificationNumber: 9461005,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS435743',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T17:18:14.749Z'
        }
      },
      sort: [
        1591645722723
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7J5504293260242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:48:40.284Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '37753.00',
          mileage: '14600'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9464802,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7J5504293',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T20:21:04.108Z'
        }
      },
      sort: [
        1591645720284
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV7JJ717693311832020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:48:23.785Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24290.0000',
          mileage: '30527'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9487494,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV7JJ717693',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:07:57.171Z'
        }
      },
      sort: [
        1591645703785
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC9HS792169311832020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:48:21.386Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22600.00',
          mileage: '63594'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9422831,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC9HS792169',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T19:52:47.284Z'
        }
      },
      sort: [
        1591645701386
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7LC092437210652020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:47:41.145Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27664.00',
          mileage: '4244'
        },
        sellArea: '500',
        dealerCd: '21065',
        certificationNumber: 9476564,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7LC092437',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:34:20.847Z'
        }
      },
      sort: [
        1591645661145
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFU4K3010789311122020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:46:45.585Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '22977.00',
          mileage: '14266'
        },
        sellArea: '160',
        dealerCd: '31112',
        certificationNumber: 9453834,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFU4K3010789',
        model: {
          modelDescription: 'PRIUS LE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1263',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T19:39:57.120Z'
        }
      },
      sort: [
        1591645605585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HJ140695460422020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:46:43.683Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22495.00',
          mileage: '21230'
        },
        sellArea: '130',
        dealerCd: '46042',
        certificationNumber: 9471925,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HJ140695',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T23:22:35.821Z'
        }
      },
      sort: [
        1591645603683
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU8K3102572210652020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:45:40.022Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22999.00',
          mileage: '5386'
        },
        sellArea: '220',
        dealerCd: '21065',
        certificationNumber: 9444292,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU8K3102572',
        model: {
          modelDescription: 'PRIUS LE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T16:51:34.124Z'
        }
      },
      sort: [
        1591645540022
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU216247480272020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:45:31.506Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20698.00',
          mileage: '25398'
        },
        sellArea: '600',
        dealerCd: '48027',
        certificationNumber: 9481820,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU216247',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T17:29:55.646Z'
        }
      },
      sort: [
        1591645531506
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU768672150502020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:45:27.000Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '30247'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9466440,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU768672',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T02:24:40.958Z'
        }
      },
      sort: [
        1591645527000
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3JC991039250672020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:44:23.241Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '14488.00',
          mileage: '39394'
        },
        sellArea: '130',
        dealerCd: '25067',
        certificationNumber: 9472061,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3JC991039',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T10:37:04.801Z'
        }
      },
      sort: [
        1591645463241
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9JW734342260242020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:44:14.721Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21900.00',
          mileage: '20328'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9459604,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9JW734342',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T15:29:42.316Z'
        }
      },
      sort: [
        1591645454721
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU808652160052020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:43:49.141Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14110.00',
          mileage: '33349'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9488007,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU808652',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:05:49.379Z'
        }
      },
      sort: [
        1591645429141
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU684611460422020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:43:01.114Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17888.00',
          mileage: '10215'
        },
        dealerCd: '46042',
        certificationNumber: 9387459,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU684611',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-19T16:42:41.857Z'
        }
      },
      sort: [
        1591645381114
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC7HS818123260242020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:42:46.839Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '27300.00',
          mileage: '31573'
        },
        sellArea: '160',
        dealerCd: '26024',
        certificationNumber: 9483975,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC7HS818123',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:09:30.326Z'
        }
      },
      sort: [
        1591645366839
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU580388250672020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:42:12.359Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '18418.00',
          mileage: '45775'
        },
        dealerCd: '25067',
        certificationNumber: 9317950,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU580388',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-02T09:10:24.310Z'
        }
      },
      sort: [
        1591645332359
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0H5410055250672020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:41:00.918Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '33851.00',
          mileage: '24553'
        },
        sellArea: '130',
        dealerCd: '25067',
        certificationNumber: 9444612,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0H5410055',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-15T11:40:27.932Z'
        }
      },
      sort: [
        1591645260918
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW617929311802020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:40:02.159Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '27396'
        },
        sellArea: '160',
        dealerCd: '31180',
        certificationNumber: 9439097,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW617929',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T14:31:28.034Z'
        }
      },
      sort: [
        1591645202159
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9J5587032250672020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:39:39.018Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '29992.00',
          mileage: '38630'
        },
        dealerCd: '25067',
        certificationNumber: 9187468,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9J5587032',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-07T18:27:35.003Z'
        }
      },
      sort: [
        1591645179018
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN7FM194335260242020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:38:36.579Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '28800.00',
          mileage: '54817'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9489768,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN7FM194335',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T20:03:10.134Z'
        }
      },
      sort: [
        1591645116579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ139589210652020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:36:29.175Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20965.00',
          mileage: '36677'
        },
        sellArea: '800',
        dealerCd: '21065',
        certificationNumber: 9486154,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ139589',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:17:07.643Z'
        }
      },
      sort: [
        1591644989175
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8GM001396260242020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:36:05.830Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '33103.00',
          mileage: '23935'
        },
        sellArea: '210',
        dealerCd: '26024',
        certificationNumber: 9487162,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8GM001396',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:15:36.530Z'
        }
      },
      sort: [
        1591644965830
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX4JR019585340412019-12-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:35:36.235Z',
          retailDate: '2019-12-30',
          rdrStatus: 'H',
          soldAmount: '24523.00',
          mileage: '21856'
        },
        dealerCd: '34041',
        certificationNumber: 9297191,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX4JR019585',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-18T20:05:21.348Z'
        }
      },
      sort: [
        1591644936235
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1JW699313201382020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:35:15.476Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '25778.00',
          mileage: '16265'
        },
        sellArea: '170',
        dealerCd: '20138',
        certificationNumber: 9496041,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1JW699313',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:01:36.933Z'
        }
      },
      sort: [
        1591644915476
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU713120160052020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:35:14.719Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19734.00',
          mileage: '23173'
        },
        dealerCd: '16005',
        certificationNumber: 9374047,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU713120',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-08T23:44:09.586Z'
        }
      },
      sort: [
        1591644914719
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN7FM197266371182020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:34:52.020Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24800.00',
          mileage: '33021'
        },
        sellArea: '800',
        dealerCd: '37118',
        certificationNumber: 9503353,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN7FM197266',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T19:19:54.159Z'
        }
      },
      sort: [
        1591644892020
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ127099460422020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:34:43.420Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23204.02',
          mileage: '33225'
        },
        sellArea: '130',
        dealerCd: '46042',
        certificationNumber: 9463074,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ127099',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T17:42:19.369Z'
        }
      },
      sort: [
        1591644883420
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F15JX712214280292020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:34:28.181Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '36995.00',
          mileage: '14941'
        },
        sellArea: '170',
        dealerCd: '28029',
        certificationNumber: 9494209,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F15JX712214',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:44:03.932Z'
        }
      },
      sort: [
        1591644868181
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU040335260242020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:33:56.658Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '29540'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9456518,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXJU040335',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T14:06:25.572Z'
        }
      },
      sort: [
        1591644836658
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4HW651640410832020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:33:32.099Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21590.00',
          mileage: '7716'
        },
        sellArea: '220',
        dealerCd: '41083',
        certificationNumber: 9503393,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4HW651640',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T19:31:18.670Z'
        }
      },
      sort: [
        1591644812099
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU841774430222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:32:15.639Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21990.00',
          mileage: '15951'
        },
        sellArea: '150',
        dealerCd: '43022',
        certificationNumber: 9484135,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU841774',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:01:00.211Z'
        }
      },
      sort: [
        1591644735639
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS425125260242020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:31:59.610Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '26632.00',
          mileage: '32252'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9464779,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS425125',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T20:12:47.560Z'
        }
      },
      sort: [
        1591644719610
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RWRFV3KW039404460422020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:31:28.038Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31995.00',
          mileage: '8860'
        },
        sellArea: '130',
        dealerCd: '46042',
        certificationNumber: 9483741,
        makeCode: 'TOYOTA',
        vin: '2T3RWRFV3KW039404',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:30:53.182Z'
        }
      },
      sort: [
        1591644688038
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS399583260242020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:30:20.796Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '28400.00',
          mileage: '36631'
        },
        sellArea: '230',
        dealerCd: '26024',
        certificationNumber: 9464770,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS399583',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T20:10:50.198Z'
        }
      },
      sort: [
        1591644620796
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMF1RFV3KD023797340412020-04-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:30:19.532Z',
          retailDate: '2020-04-25',
          rdrStatus: 'H',
          soldAmount: '21468.00',
          mileage: '14844'
        },
        sellArea: '220',
        dealerCd: '34041',
        certificationNumber: 9453356,
        makeCode: 'TOYOTA',
        vin: 'JTMF1RFV3KD023797',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T16:11:26.154Z'
        }
      },
      sort: [
        1591644619532
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB32G1139976160052020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:28:44.870Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '11700.50',
          mileage: '22874'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9483926,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB32G1139976',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:50:42.696Z'
        }
      },
      sort: [
        1591644524870
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU349321450792020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:28:04.516Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15400.0000',
          mileage: '31071'
        },
        sellArea: '210',
        dealerCd: '45079',
        certificationNumber: 9435502,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU349321',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T15:16:11.150Z'
        }
      },
      sort: [
        1591644484516
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7HW600021340412020-03-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:27:30.914Z',
          retailDate: '2020-03-17',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '33253'
        },
        sellArea: '210',
        dealerCd: '34041',
        certificationNumber: 9430634,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7HW600021',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T17:11:54.307Z'
        }
      },
      sort: [
        1591644450914
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB2FU178749410502020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:27:25.599Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '16450.25',
          mileage: '39010'
        },
        sellArea: '220',
        dealerCd: '41050',
        certificationNumber: 9470980,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB2FU178749',
        model: {
          modelDescription: 'AVALON 4-DR XLE PREMIUM',
          modelSeries: 'AVALON',
          modelNumber: '3546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T17:22:45.729Z'
        }
      },
      sort: [
        1591644445599
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW619998121442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:26:59.351Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18470.00',
          mileage: '21256'
        },
        sellArea: '210',
        dealerCd: '12144',
        certificationNumber: 9500211,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW619998',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:24:54.491Z'
        }
      },
      sort: [
        1591644419351
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE7LJ023198200962020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:26:52.868Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '17069'
        },
        sellArea: '500',
        dealerCd: '20096',
        certificationNumber: 9474805,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE7LJ023198',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:58:30.814Z'
        }
      },
      sort: [
        1591644412868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU742241480652020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:26:46.108Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16991.00',
          mileage: '6665'
        },
        sellArea: '210',
        dealerCd: '48065',
        certificationNumber: 9445435,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU742241',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T16:14:52.283Z'
        }
      },
      sort: [
        1591644406108
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU741770410502020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:25:54.094Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '17810.00',
          mileage: '27857'
        },
        sellArea: '220',
        dealerCd: '41050',
        certificationNumber: 9451957,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU741770',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T13:31:49.058Z'
        }
      },
      sort: [
        1591644354094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV2HD143432340412020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:24:39.048Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '33462'
        },
        sellArea: '220',
        dealerCd: '34041',
        certificationNumber: 9502641,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV2HD143432',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:23:01.219Z'
        }
      },
      sort: [
        1591644279048
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU636796121442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:24:27.031Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19890.00',
          mileage: '23855'
        },
        sellArea: '210',
        dealerCd: '12144',
        certificationNumber: 9494285,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU636796',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:08:20.966Z'
        }
      },
      sort: [
        1591644267031
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4JW850985460422020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:24:00.668Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '13028'
        },
        sellArea: '130',
        dealerCd: '46042',
        certificationNumber: 9497922,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4JW850985',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:56:51.370Z'
        }
      },
      sort: [
        1591644240668
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV3JJ255262120422020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:23:34.628Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27975.00',
          mileage: '14767'
        },
        sellArea: '210',
        dealerCd: '12042',
        certificationNumber: 9450060,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV3JJ255262',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T15:57:23.460Z'
        }
      },
      sort: [
        1591644214628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK4HU205400121442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:23:02.409Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17789.00',
          mileage: '41452'
        },
        sellArea: '160',
        dealerCd: '12144',
        certificationNumber: 9481604,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK4HU205400',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:57:10.704Z'
        }
      },
      sort: [
        1591644182409
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU9J3604146210732020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:22:58.648Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '19891'
        },
        sellArea: '220',
        dealerCd: '21073',
        certificationNumber: 9493725,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU9J3604146',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:54:20.938Z'
        }
      },
      sort: [
        1591644178648
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0GP520510410502020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:22:56.029Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '15238.00',
          mileage: '30222'
        },
        sellArea: '220',
        dealerCd: '41050',
        certificationNumber: 9474920,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0GP520510',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:53:38.060Z'
        }
      },
      sort: [
        1591644176029
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS514831160052020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:22:12.227Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30988.00',
          mileage: '34242'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9495680,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS514831',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:08:38.873Z'
        }
      },
      sort: [
        1591644132227
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW551144340412020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:21:10.226Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '24450.00',
          mileage: '29233'
        },
        sellArea: '220',
        dealerCd: '34041',
        certificationNumber: 9502678,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW551144',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:33:32.906Z'
        }
      },
      sort: [
        1591644070226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH4HS380042120422020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:20:30.796Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32975.00',
          mileage: '29723'
        },
        sellArea: '210',
        dealerCd: '12042',
        certificationNumber: 9495379,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH4HS380042',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:06:32.668Z'
        }
      },
      sort: [
        1591644030796
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW581506200962020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:20:30.432Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '7741'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9483085,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW581506',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T13:23:37.008Z'
        }
      },
      sort: [
        1591644030432
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2HJ129282201142020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:20:00.186Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '16076'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9497224,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2HJ129282',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:54:55.648Z'
        }
      },
      sort: [
        1591644000186
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN1HX093306450792020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:19:42.646Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24000.0000',
          mileage: '35905'
        },
        sellArea: '110',
        dealerCd: '45079',
        certificationNumber: 9501319,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN1HX093306',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T17:35:13.363Z'
        }
      },
      sort: [
        1591643982646
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXFW272200450792020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:19:35.125Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18500.0000',
          mileage: '43593'
        },
        sellArea: '800',
        dealerCd: '45079',
        certificationNumber: 9502897,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXFW272200',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T17:25:18.710Z'
        }
      },
      sort: [
        1591643975125
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F18HX638992460422020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:19:13.524Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '35995.00',
          mileage: '23243'
        },
        sellArea: '130',
        dealerCd: '46042',
        certificationNumber: 9483701,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F18HX638992',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:15:31.772Z'
        }
      },
      sort: [
        1591643953524
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS410813130772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:19:08.147Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24360.77',
          mileage: '32352'
        },
        sellArea: '210',
        dealerCd: '13077',
        certificationNumber: 9503328,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS410813',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T19:13:05.203Z'
        }
      },
      sort: [
        1591643948147
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6FU056978450792020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:19:01.785Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13000.0000',
          mileage: '50531'
        },
        sellArea: '800',
        dealerCd: '45079',
        certificationNumber: 9503141,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6FU056978',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T18:28:34.661Z'
        }
      },
      sort: [
        1591643941785
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS391417450792020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:18:53.266Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25930.0000',
          mileage: '44924'
        },
        sellArea: '800',
        dealerCd: '45079',
        certificationNumber: 9503114,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS391417',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T18:20:24.919Z'
        }
      },
      sort: [
        1591643933266
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS416151410502020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:18:32.804Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '25500.00',
          mileage: '32622'
        },
        sellArea: '220',
        dealerCd: '41050',
        certificationNumber: 9475171,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS416151',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T22:28:33.917Z'
        }
      },
      sort: [
        1591643912804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4J5573121360492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:18:05.584Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '34688.00',
          mileage: '31287'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9458069,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4J5573121',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T23:25:29.712Z'
        }
      },
      sort: [
        1591643885584
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS387760340412020-04-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:17:16.945Z',
          retailDate: '2020-04-21',
          rdrStatus: 'H',
          soldAmount: '27000.00',
          mileage: '35548'
        },
        sellArea: '220',
        dealerCd: '34041',
        certificationNumber: 9462406,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS387760',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-10T20:26:34.378Z'
        }
      },
      sort: [
        1591643836945
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HD202711041192020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:17:05.065Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20255.00',
          mileage: '30409'
        },
        sellArea: '120',
        dealerCd: '04119',
        certificationNumber: 9452168,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HD202711',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T16:15:47.647Z'
        }
      },
      sort: [
        1591643825065
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU746423360492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:16:50.323Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21124.00',
          mileage: '11385'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9460336,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU746423',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T22:55:53.025Z'
        }
      },
      sort: [
        1591643810323
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU171460120422020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:16:40.063Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20975.00',
          mileage: '32073'
        },
        sellArea: '600',
        dealerCd: '12042',
        certificationNumber: 9417690,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU171460',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T22:28:18.186Z'
        }
      },
      sort: [
        1591643800063
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU340181120422020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:15:54.262Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17700.00',
          mileage: '31907'
        },
        sellArea: '210',
        dealerCd: '12042',
        certificationNumber: 9408298,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU340181',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T15:05:56.602Z'
        }
      },
      sort: [
        1591643754262
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV3HJ706643041192020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:15:45.223Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18992.00',
          mileage: '27210'
        },
        sellArea: '120',
        dealerCd: '04119',
        certificationNumber: 9482685,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV3HJ706643',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T01:44:00.836Z'
        }
      },
      sort: [
        1591643745223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH0GS224860410502020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:15:07.461Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '27632.40',
          mileage: '44002'
        },
        sellArea: '800',
        dealerCd: '41050',
        certificationNumber: 9474900,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH0GS224860',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:39:57.324Z'
        }
      },
      sort: [
        1591643707461
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK9JU033670360492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:14:54.577Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24436.00',
          mileage: '18969'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9440141,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK9JU033670',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-03T21:55:20.988Z'
        }
      },
      sort: [
        1591643694577
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KC014869360492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:13:50.943Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '13702'
        },
        sellArea: '600',
        dealerCd: '36049',
        certificationNumber: 9488171,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KC014869',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:59:54.487Z'
        }
      },
      sort: [
        1591643630943
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU717648460422020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:13:08.082Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22887.00',
          mileage: '10979'
        },
        sellArea: '130',
        dealerCd: '46042',
        certificationNumber: 9409934,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU717648',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T17:37:26.998Z'
        }
      },
      sort: [
        1591643588082
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV9KW020396410502020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:12:52.760Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '20293.00',
          mileage: '35430'
        },
        sellArea: '500',
        dealerCd: '41050',
        certificationNumber: 9422609,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV9KW020396',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T17:55:19.997Z'
        }
      },
      sort: [
        1591643572760
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV2HW593960190422020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:12:42.122Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23498.55',
          mileage: '53716'
        },
        sellArea: '800',
        dealerCd: '19042',
        certificationNumber: 9456682,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV2HW593960',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T16:08:00.880Z'
        }
      },
      sort: [
        1591643562122
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV5HJ714690360492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:11:52.382Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24851.83',
          mileage: '30264'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9488083,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV5HJ714690',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:27:59.000Z'
        }
      },
      sort: [
        1591643512382
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1HW684962410502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:11:50.581Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22274.75',
          mileage: '21583'
        },
        sellArea: '220',
        dealerCd: '41050',
        certificationNumber: 9471566,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1HW684962',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T21:09:45.636Z'
        }
      },
      sort: [
        1591643510581
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC820951190422020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:11:45.160Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14699.00',
          mileage: '33355'
        },
        sellArea: '800',
        dealerCd: '19042',
        certificationNumber: 9493717,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC820951',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:47:36.849Z'
        }
      },
      sort: [
        1591643505160
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU330136480652020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:10:47.141Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14858.00',
          mileage: '31387'
        },
        sellArea: '210',
        dealerCd: '48065',
        certificationNumber: 9445439,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU330136',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T16:16:35.710Z'
        }
      },
      sort: [
        1591643447141
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV9HW580528260292020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:10:42.908Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23945.00',
          mileage: '14604'
        },
        sellArea: '230',
        dealerCd: '26029',
        certificationNumber: 9476244,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV9HW580528',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:40:56.211Z'
        }
      },
      sort: [
        1591643442908
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV6HW338259360492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:10:38.762Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23996.00',
          mileage: '60170'
        },
        sellArea: '110',
        dealerCd: '36049',
        certificationNumber: 9475224,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV6HW338259',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T23:01:38.367Z'
        }
      },
      sort: [
        1591643438762
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW641956410502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:10:16.827Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23903.51',
          mileage: '15310'
        },
        sellArea: '220',
        dealerCd: '41050',
        certificationNumber: 9479388,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW641956',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T19:20:27.886Z'
        }
      },
      sort: [
        1591643416827
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV6HY174212220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:09:22.686Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '11997.00',
          mileage: '34982'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9495355,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV6HY174212',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:54:30.123Z'
        }
      },
      sort: [
        1591643362686
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM101566220412020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:08:54.741Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29130.00',
          mileage: '51192'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9460834,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM101566',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:36:24.362Z'
        }
      },
      sort: [
        1591643334741
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH3HS387144260292020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:08:46.388Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31480.00',
          mileage: '46229'
        },
        sellArea: '230',
        dealerCd: '26029',
        certificationNumber: 9408825,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH3HS387144',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T19:46:58.351Z'
        }
      },
      sort: [
        1591643326388
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTKJF5C75GJ019648220412020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:08:30.628Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13498.00',
          mileage: '60649'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9440969,
        makeCode: 'TOYOTA',
        vin: 'JTKJF5C75GJ019648',
        model: {
          modelDescription: 'SCION tC 3 DOOR LIFTBACK',
          modelSeries: 'SCION TC',
          modelNumber: '6222',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T21:02:34.473Z'
        }
      },
      sort: [
        1591643310628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV2GD026142220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:08:05.066Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '75227'
        },
        sellArea: '190',
        dealerCd: '22041',
        certificationNumber: 9492290,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV2GD026142',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:27:25.849Z'
        }
      },
      sort: [
        1591643285066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU785083480452020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:07:35.147Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '14684.00',
          mileage: '31098'
        },
        sellArea: '210',
        dealerCd: '48045',
        certificationNumber: 9413877,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU785083',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T18:46:39.227Z'
        }
      },
      sort: [
        1591643255147
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7JU517125260292020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:07:22.977Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18975.00',
          mileage: '28745'
        },
        sellArea: '230',
        dealerCd: '26029',
        certificationNumber: 9476400,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7JU517125',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:31:25.717Z'
        }
      },
      sort: [
        1591643242977
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV2HD124644360402020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:07:16.887Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25598.00',
          mileage: '5124'
        },
        sellArea: '130',
        dealerCd: '36040',
        certificationNumber: 9500445,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV2HD124644',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:04:40.889Z'
        }
      },
      sort: [
        1591643236887
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC786918450942020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:07:12.178Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13997.00',
          mileage: '35600'
        },
        sellArea: '800',
        dealerCd: '45094',
        certificationNumber: 9484297,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC786918',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:11:40.921Z'
        }
      },
      sort: [
        1591643232178
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV5HJ101314410502020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:07:03.339Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '16980.00',
          mileage: '32075'
        },
        sellArea: '220',
        dealerCd: '41050',
        certificationNumber: 9471510,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV5HJ101314',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T20:36:06.626Z'
        }
      },
      sort: [
        1591643223339
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5ANXHX069187410502020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:06:00.458Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '23988.31',
          mileage: '71137'
        },
        sellArea: '500',
        dealerCd: '41050',
        certificationNumber: 9471521,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5ANXHX069187',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T20:43:27.867Z'
        }
      },
      sort: [
        1591643160458
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM079920050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:05:29.518Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30581.00',
          mileage: '44147'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9497823,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM079920',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:30:00.879Z'
        }
      },
      sort: [
        1591643129518
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6HJ714316043562020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:04:31.904Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22777.92',
          mileage: '34733'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9496062,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6HJ714316',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:11:30.810Z'
        }
      },
      sort: [
        1591643071904
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU765706270242020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T19:03:00.303Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21863.13',
          mileage: '30426'
        },
        sellArea: '150',
        dealerCd: '27024',
        certificationNumber: 9441815,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU765706',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T15:48:18.172Z'
        }
      },
      sort: [
        1591642980303
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV6HD132112310692020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:59:54.683Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23495.00',
          mileage: '20234'
        },
        sellArea: '160',
        dealerCd: '31069',
        certificationNumber: 9487853,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV6HD132112',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:20:13.460Z'
        }
      },
      sort: [
        1591642794683
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP712941043742020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:59:44.563Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15699.0000',
          mileage: '28975'
        },
        sellArea: '120',
        dealerCd: '04374',
        certificationNumber: 9492192,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP712941',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:00:12.294Z'
        }
      },
      sort: [
        1591642784563
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU8H3040113043742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:59:39.443Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18999.0000',
          mileage: '41459'
        },
        sellArea: '120',
        dealerCd: '04374',
        certificationNumber: 9447500,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU8H3040113',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T19:57:48.317Z'
        }
      },
      sort: [
        1591642779443
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HD216296043742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:59:36.795Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24994.0000',
          mileage: '27669'
        },
        sellArea: '120',
        dealerCd: '04374',
        certificationNumber: 9487060,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HD216296',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:30:13.796Z'
        }
      },
      sort: [
        1591642776795
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBEXK3059032043742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:59:34.293Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20549.0000',
          mileage: '20239'
        },
        sellArea: '120',
        dealerCd: '04374',
        certificationNumber: 9439522,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBEXK3059032',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T22:56:11.235Z'
        }
      },
      sort: [
        1591642774293
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRZ5CN3GX016597043742020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:59:31.933Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23999.00',
          mileage: '19934'
        },
        sellArea: '120',
        dealerCd: '04374',
        certificationNumber: 9466293,
        makeCode: 'TOYOTA',
        vin: '5TFRZ5CN3GX016597',
        model: {
          modelDescription: 'TACOMA SR5 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7126',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T21:56:31.329Z'
        }
      },
      sort: [
        1591642771933
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3MWRFVXKW003428480462020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:58:54.833Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '27037.64',
          mileage: '6863'
        },
        dealerCd: '48046',
        certificationNumber: 9373759,
        makeCode: 'TOYOTA',
        vin: '2T3MWRFVXKW003428',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-08T17:33:08.366Z'
        }
      },
      sort: [
        1591642734833
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU699582043562020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:58:40.886Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19728.00',
          mileage: '38646'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9432350,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU699582',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T20:54:35.919Z'
        }
      },
      sort: [
        1591642720886
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8K5621401470272020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:58:34.872Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '36489.00',
          mileage: '23293'
        },
        sellArea: '800',
        dealerCd: '47027',
        certificationNumber: 9498105,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8K5621401',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:52:14.261Z'
        }
      },
      sort: [
        1591642714872
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW674578480362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:56:51.954Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18898.00',
          mileage: '30777'
        },
        sellArea: '210',
        dealerCd: '48036',
        certificationNumber: 9484130,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW674578',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:58:08.672Z'
        }
      },
      sort: [
        1591642611954
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW628233371582020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:56:46.232Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19902.39',
          mileage: '22954'
        },
        sellArea: '800',
        dealerCd: '37158',
        certificationNumber: 9456881,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW628233',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T18:37:38.125Z'
        }
      },
      sort: [
        1591642606232
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU100462480072020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:56:14.741Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19950.00',
          mileage: '20261'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9494305,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU100462',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:16:29.273Z'
        }
      },
      sort: [
        1591642574741
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV9KY519904371012020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:54:31.351Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13991.00',
          mileage: '13710'
        },
        sellArea: '800',
        dealerCd: '37101',
        certificationNumber: 9503228,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV9KY519904',
        model: {
          modelDescription: 'YARIS Sedan 4Dr.Sedan LE 6AT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T18:49:52.471Z'
        }
      },
      sort: [
        1591642471351
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH7HS510354046362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:53:16.609Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '29599.00',
          mileage: '34471'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9479770,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH7HS510354',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6950',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T23:20:11.594Z'
        }
      },
      sort: [
        1591642396609
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW670568480362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:52:47.750Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23304.00',
          mileage: '21598'
        },
        sellArea: '210',
        dealerCd: '48036',
        certificationNumber: 9488120,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW670568',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:43:03.523Z'
        }
      },
      sort: [
        1591642367750
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP664489046362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:51:57.830Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '39082'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9501900,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP664489',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T15:10:35.143Z'
        }
      },
      sort: [
        1591642317830
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KW006226450702020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:51:15.688Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22650.00',
          mileage: '38659'
        },
        sellArea: '500',
        dealerCd: '45070',
        certificationNumber: 9475673,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KW006226',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T13:20:55.242Z'
        }
      },
      sort: [
        1591642275688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH7HS515019046362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:51:04.529Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25599.00',
          mileage: '55061'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9498442,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH7HS515019',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T01:37:44.825Z'
        }
      },
      sort: [
        1591642264529
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU741842043562020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:50:57.569Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22675.83',
          mileage: '36907'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9432362,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU741842',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T21:01:30.445Z'
        }
      },
      sort: [
        1591642257569
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMA1RFVXKD008532280122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:49:34.098Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28997.60',
          mileage: '9543'
        },
        sellArea: '170',
        dealerCd: '28012',
        certificationNumber: 9501047,
        makeCode: 'TOYOTA',
        vin: 'JTMA1RFVXKD008532',
        model: {
          modelDescription: 'RAV4 XLE PREM AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4478',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T14:33:07.225Z'
        }
      },
      sort: [
        1591642174098
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU302605046362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:49:28.708Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17899.00',
          mileage: '19676'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9479795,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU302605',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-24T00:11:41.915Z'
        }
      },
      sort: [
        1591642168708
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK5J3006941480072020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:49:02.808Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19789.00',
          mileage: '20372'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9475736,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK5J3006941',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T14:01:25.192Z'
        }
      },
      sort: [
        1591642142808
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS384569480462020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:48:34.058Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28984.00',
          mileage: '38181'
        },
        sellArea: '210',
        dealerCd: '48046',
        certificationNumber: 9421451,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS384569',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T15:30:54.129Z'
        }
      },
      sort: [
        1591642114058
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV4HJ135213046362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:47:56.767Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17898.77',
          mileage: '47765'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9498436,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV4HJ135213',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T01:20:27.755Z'
        }
      },
      sort: [
        1591642076767
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HJ110713340892020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:47:53.128Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '29677'
        },
        dealerCd: '34089',
        certificationNumber: 9329185,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HJ110713',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-09T20:00:46.117Z'
        }
      },
      sort: [
        1591642073128
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC6LS035325480462020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:47:39.227Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '26731.00',
          mileage: '10101'
        },
        sellArea: '210',
        dealerCd: '48046',
        certificationNumber: 9457415,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC6LS035325',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T14:43:24.668Z'
        }
      },
      sort: [
        1591642059227
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN1KX203284371582020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:47:30.967Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29999.00',
          mileage: '7506'
        },
        sellArea: '800',
        dealerCd: '37158',
        certificationNumber: 9473174,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN1KX203284',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:34:37.237Z'
        }
      },
      sort: [
        1591642050967
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU799262340062020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:47:19.447Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19007.00',
          mileage: '24893'
        },
        sellArea: '220',
        dealerCd: '34006',
        certificationNumber: 9474471,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU799262',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:08:04.738Z'
        }
      },
      sort: [
        1591642039447
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4K5679909340412020-02-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:47:15.248Z',
          retailDate: '2020-02-26',
          rdrStatus: 'H',
          soldAmount: '32000.00',
          mileage: '1317'
        },
        dealerCd: '34041',
        certificationNumber: 9336061,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4K5679909',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-14T15:41:07.430Z'
        }
      },
      sort: [
        1591642035248
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP677452046362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:46:48.717Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '14299.00',
          mileage: '26650'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9501890,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP677452',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T14:59:47.852Z'
        }
      },
      sort: [
        1591642008717
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW578251042922020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:46:13.795Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18988.00',
          mileage: '47026'
        },
        sellArea: '160',
        dealerCd: '04292',
        certificationNumber: 9439257,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW578251',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T17:30:05.987Z'
        }
      },
      sort: [
        1591641973795
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HJ145551220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:45:40.982Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19998.00',
          mileage: '53092'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9491488,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HJ145551',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:50:38.523Z'
        }
      },
      sort: [
        1591641940982
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ109991121632020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:45:30.123Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '40136'
        },
        sellArea: '210',
        dealerCd: '12163',
        certificationNumber: 9499849,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ109991',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:26:23.365Z'
        }
      },
      sort: [
        1591641930123
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HD217514220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:45:16.823Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '27115'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9480431,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HD217514',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T16:32:18.226Z'
        }
      },
      sort: [
        1591641916823
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV2KW003656042922020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:45:12.022Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25799.75',
          mileage: '29803'
        },
        sellArea: '600',
        dealerCd: '04292',
        certificationNumber: 9485753,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV2KW003656',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:11:35.808Z'
        }
      },
      sort: [
        1591641912022
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ708020220412020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:44:52.621Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20450.00',
          mileage: '24460'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9486224,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ708020',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:05:36.845Z'
        }
      },
      sort: [
        1591641892621
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3K5667427042922020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:44:38.241Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '37988.00',
          mileage: '29192'
        },
        sellArea: '600',
        dealerCd: '04292',
        certificationNumber: 9493016,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3K5667427',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:27:19.746Z'
        }
      },
      sort: [
        1591641878241
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV9FW178947043562020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:44:28.342Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17988.00',
          mileage: '35728'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9495993,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV9FW178947',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:47:03.714Z'
        }
      },
      sort: [
        1591641868342
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV7HW594156220412020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:44:21.000Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19685.00',
          mileage: '30161'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9412338,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV7HW594156',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T16:27:07.011Z'
        }
      },
      sort: [
        1591641861000
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5HW588310220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:43:47.742Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18413.00',
          mileage: '29569'
        },
        sellArea: '160',
        dealerCd: '22041',
        certificationNumber: 9495658,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5HW588310',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:02:54.109Z'
        }
      },
      sort: [
        1591641827742
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU770611040872020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:43:45.083Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16388.00',
          mileage: '34095'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9448386,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU770611',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T00:06:56.373Z'
        }
      },
      sort: [
        1591641825083
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KC009029311312020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:43:43.605Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26499.00',
          mileage: '20211'
        },
        sellArea: '500',
        dealerCd: '31131',
        certificationNumber: 9497277,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KC009029',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:18:09.481Z'
        }
      },
      sort: [
        1591641823605
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN6HX046980340412019-12-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:43:43.241Z',
          retailDate: '2019-12-10',
          rdrStatus: 'H',
          soldAmount: '34700.00',
          mileage: '42675'
        },
        dealerCd: '34041',
        certificationNumber: 9383883,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN6HX046980',
        model: {
          modelDescription: 'TACOMA TRD PRO 4X4 DBL CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7598',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T17:32:09.582Z'
        }
      },
      sort: [
        1591641823241
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5HW568915220412020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:43:21.863Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18277.00',
          mileage: '48447'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9482426,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5HW568915',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:18:08.511Z'
        }
      },
      sort: [
        1591641801863
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW634550220412020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:42:09.624Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19970.00',
          mileage: '28617'
        },
        sellArea: '800',
        dealerCd: '22041',
        certificationNumber: 9482315,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW634550',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:19:54.169Z'
        }
      },
      sort: [
        1591641729624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW619487220412020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:41:39.663Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19339.00',
          mileage: '23788'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9485421,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW619487',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:43:43.076Z'
        }
      },
      sort: [
        1591641699663
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HJ151303220412020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:41:09.004Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18803.00',
          mileage: '29424'
        },
        sellArea: '160',
        dealerCd: '22041',
        certificationNumber: 9482437,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HJ151303',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:23:52.195Z'
        }
      },
      sort: [
        1591641669004
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR6J5184706043562020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:40:57.127Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25450.00',
          mileage: '20569'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9499482,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR6J5184706',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8642',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:21:22.199Z'
        }
      },
      sort: [
        1591641657127
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH4HS525880040872020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:40:43.242Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26883.00',
          mileage: '65629'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9460048,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH4HS525880',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6950',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:03:52.201Z'
        }
      },
      sort: [
        1591641643242
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1GP563639042922020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:40:41.064Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14988.00',
          mileage: '43092'
        },
        sellArea: '120',
        dealerCd: '04292',
        certificationNumber: 9477827,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1GP563639',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:23:03.648Z'
        }
      },
      sort: [
        1591641641064
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH8HS387714220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:40:35.604Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25598.00',
          mileage: '13208'
        },
        sellArea: '160',
        dealerCd: '22041',
        certificationNumber: 9485280,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH8HS387714',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:33:26.598Z'
        }
      },
      sort: [
        1591641635604
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH4HS405979220412020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:40:05.019Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24406.00',
          mileage: '55824'
        },
        sellArea: '500',
        dealerCd: '22041',
        certificationNumber: 9460821,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH4HS405979',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:20:56.723Z'
        }
      },
      sort: [
        1591641605019
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU793927042922020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:39:55.956Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23988.00',
          mileage: '16721'
        },
        sellArea: '120',
        dealerCd: '04292',
        certificationNumber: 9497502,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU793927',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:42:54.210Z'
        }
      },
      sort: [
        1591641595956
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS447691220412020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:39:33.341Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28674.00',
          mileage: '34850'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9492398,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS447691',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:47:07.876Z'
        }
      },
      sort: [
        1591641573341
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZRFH8HS217125042922020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:39:12.243Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28988.00',
          mileage: '36163'
        },
        sellArea: '120',
        dealerCd: '04292',
        certificationNumber: 9454381,
        makeCode: 'TOYOTA',
        vin: '5TDZZRFH8HS217125',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6947',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T22:18:14.085Z'
        }
      },
      sort: [
        1591641552243
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS401845220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:38:57.789Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '33952'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9467721,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS401845',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T22:58:31.849Z'
        }
      },
      sort: [
        1591641537789
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS395377042922020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:38:47.002Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24988.00',
          mileage: '68295'
        },
        sellArea: '160',
        dealerCd: '04292',
        certificationNumber: 9439280,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS395377',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T17:57:42.521Z'
        }
      },
      sort: [
        1591641527002
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW638183480282020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:38:41.062Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19483.00',
          mileage: '36736'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9480393,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW638183',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T16:13:59.063Z'
        }
      },
      sort: [
        1591641521062
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH1JS047471220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:38:27.283Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34630.00',
          mileage: '47852'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9449405,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH1JS047471',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-25T15:46:24.141Z'
        }
      },
      sort: [
        1591641507283
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS419172220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:37:25.502Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28910.00',
          mileage: '34878'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9476749,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS419172',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T22:53:46.698Z'
        }
      },
      sort: [
        1591641445502
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP636584190592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:37:25.257Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14900.00',
          mileage: '11986'
        },
        sellArea: '160',
        dealerCd: '19059',
        certificationNumber: 9501676,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP636584',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:44:33.729Z'
        }
      },
      sort: [
        1591641445257
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F13HX628399110352020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:37:13.437Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '35085.00',
          mileage: '30038'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9491412,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F13HX628399',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:26:47.217Z'
        }
      },
      sort: [
        1591641433437
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH9KS716699220412020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:36:28.702Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30159.00',
          mileage: '16075'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9476775,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH9KS716699',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T23:13:43.510Z'
        }
      },
      sort: [
        1591641388702
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0GC574616220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:35:57.759Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12435.00',
          mileage: '33321'
        },
        sellArea: '160',
        dealerCd: '22041',
        certificationNumber: 9491672,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0GC574616',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:40:21.648Z'
        }
      },
      sort: [
        1591641357759
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5GP544379220412020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:35:31.220Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13994.00',
          mileage: '23626'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9491617,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5GP544379',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:29:05.664Z'
        }
      },
      sort: [
        1591641331220
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV2KC019429361022020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:35:17.979Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25498.00',
          mileage: '10131'
        },
        sellArea: '130',
        dealerCd: '36102',
        certificationNumber: 9488015,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV2KC019429',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:07:41.822Z'
        }
      },
      sort: [
        1591641317979
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK2GU200095220412020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:35:06.641Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '50031'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9468654,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK2GU200095',
        model: {
          modelDescription: 'CAMRY HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T17:43:24.275Z'
        }
      },
      sort: [
        1591641306641
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3JW789872361022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:34:41.340Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18898.47',
          mileage: '41829'
        },
        sellArea: '600',
        dealerCd: '36102',
        certificationNumber: 9439917,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3JW789872',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-03T18:09:53.440Z'
        }
      },
      sort: [
        1591641281340
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK9HU229076220412020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:34:36.990Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18805.00',
          mileage: '20354'
        },
        sellArea: '160',
        dealerCd: '22041',
        certificationNumber: 9485294,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK9HU229076',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:39:24.682Z'
        }
      },
      sort: [
        1591641276990
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU423549220412020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:34:08.651Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19900.00',
          mileage: '33223'
        },
        sellArea: '800',
        dealerCd: '22041',
        certificationNumber: 9485183,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU423549',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:44:45.899Z'
        }
      },
      sort: [
        1591641248651
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU767796361022020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:34:05.650Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18274.95',
          mileage: '7812'
        },
        sellArea: '800',
        dealerCd: '36102',
        certificationNumber: 9478222,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU767796',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:13:47.604Z'
        }
      },
      sort: [
        1591641245650
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU543484220412020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:33:46.192Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17302.35',
          mileage: '14326'
        },
        sellArea: '210',
        dealerCd: '22041',
        certificationNumber: 9464348,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU543484',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T16:06:28.126Z'
        }
      },
      sort: [
        1591641226192
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB33F1090219360942020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:33:32.211Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '45843'
        },
        sellArea: '130',
        dealerCd: '36094',
        certificationNumber: 9493822,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB33F1090219',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:58:28.961Z'
        }
      },
      sort: [
        1591641212211
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREVXJJ177694361022020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:33:31.714Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25800.00',
          mileage: '39926'
        },
        sellArea: '130',
        dealerCd: '36102',
        certificationNumber: 9487701,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREVXJJ177694',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:27:55.327Z'
        }
      },
      sort: [
        1591641211714
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU690323110092020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:33:27.474Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20641.00',
          mileage: '29664'
        },
        sellArea: '600',
        certificationNumber: 9454070,
        dealerCd: '11009',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        vin: '4T1B11HK7KU690323',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T20:49:59.103Z'
        }
      },
      sort: [
        1591641207474
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU785563110352020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:33:26.732Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16800.00',
          mileage: '41104'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9472493,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU785563',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T14:42:37.492Z'
        }
      },
      sort: [
        1591641206732
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU241824220412020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:33:09.214Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18800.00',
          mileage: '19571'
        },
        sellArea: '500',
        dealerCd: '22041',
        certificationNumber: 9429710,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU241824',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T15:50:31.003Z'
        }
      },
      sort: [
        1591641189214
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE1LJ015196280292020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:32:55.894Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '20217.00',
          mileage: '432'
        },
        sellArea: '170',
        dealerCd: '28029',
        certificationNumber: 9476367,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE1LJ015196',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:20:26.984Z'
        }
      },
      sort: [
        1591641175894
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0HS832364311922020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:32:53.195Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23769.00',
          mileage: '32168'
        },
        sellArea: '160',
        dealerCd: '31192',
        certificationNumber: 9503137,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC0HS832364',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T18:27:07.358Z'
        }
      },
      sort: [
        1591641173195
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU730607220412020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:32:40.132Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20587.00',
          mileage: '32584'
        },
        sellArea: '500',
        dealerCd: '22041',
        certificationNumber: 9429718,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU730607',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T15:53:30.549Z'
        }
      },
      sort: [
        1591641160132
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HKXJ3036646020512020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:32:19.093Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '27727'
        },
        sellArea: '150',
        dealerCd: '02051',
        certificationNumber: 9452715,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HKXJ3036646',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T22:44:08.677Z'
        }
      },
      sort: [
        1591641139093
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN9KM109152043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:32:09.753Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '33983.00',
          mileage: '18972'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9443131,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN9KM109152',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-10T19:42:03.950Z'
        }
      },
      sort: [
        1591641129753
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2JW845749361022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:32:01.173Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25498.00',
          mileage: '23618'
        },
        sellArea: '130',
        dealerCd: '36102',
        certificationNumber: 9488000,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2JW845749',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:02:53.383Z'
        }
      },
      sort: [
        1591641121173
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7JW814058371882020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:31:48.452Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '20998.00',
          mileage: '36077'
        },
        dealerCd: '37188',
        certificationNumber: 9193824,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7JW814058',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-11T14:41:59.925Z'
        }
      },
      sort: [
        1591641108452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC860961480362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:31:29.371Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13477.00',
          mileage: '35556'
        },
        sellArea: '210',
        dealerCd: '48036',
        certificationNumber: 9488023,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC860961',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:09:27.744Z'
        }
      },
      sort: [
        1591641089371
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU814818361022020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:31:22.972Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17650.00',
          mileage: '9550'
        },
        sellArea: '130',
        dealerCd: '36102',
        certificationNumber: 9464456,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU814818',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T17:16:39.398Z'
        }
      },
      sort: [
        1591641082972
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU779861110092020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:30:54.353Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21400.00',
          mileage: '28222'
        },
        sellArea: '500',
        dealerCd: '11009',
        certificationNumber: 9492390,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU779861',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:45:18.313Z'
        }
      },
      sort: [
        1591641054353
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2LM332954361022020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:30:45.170Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '41398.00',
          mileage: '253'
        },
        sellArea: '130',
        dealerCd: '36102',
        certificationNumber: 9487445,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2LM332954',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:49:31.722Z'
        }
      },
      sort: [
        1591641045170
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU061569045432020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:30:43.911Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22806.88',
          mileage: '15844'
        },
        dealerCd: '04543',
        certificationNumber: 9390986,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU061569',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T19:07:51.766Z'
        }
      },
      sort: [
        1591641043911
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN9LM125269020532020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:29:57.769Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '30490.00',
          mileage: '3943'
        },
        sellArea: '150',
        dealerCd: '02053',
        certificationNumber: 9503120,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN9LM125269',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T18:22:12.777Z'
        }
      },
      sort: [
        1591640997769
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS400522220302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:29:54.230Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28490.00',
          mileage: '39040'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9497438,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS400522',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:22:51.522Z'
        }
      },
      sort: [
        1591640994230
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN1HM027201020512020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:29:41.330Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '49682'
        },
        sellArea: '500',
        dealerCd: '02051',
        certificationNumber: 9494772,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN1HM027201',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T23:01:02.600Z'
        }
      },
      sort: [
        1591640981330
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU728707121592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:29:12.690Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18868.89',
          mileage: '46707'
        },
        sellArea: '600',
        dealerCd: '12159',
        certificationNumber: 9473032,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU728707',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T18:32:59.260Z'
        }
      },
      sort: [
        1591640952690
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3KW060744110092020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:28:44.768Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25988.00',
          mileage: '19748'
        },
        sellArea: '600',
        dealerCd: '11009',
        certificationNumber: 9425856,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3KW060744',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T19:27:07.883Z'
        }
      },
      sort: [
        1591640924768
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW692277201412020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:28:37.769Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '23409.00',
          mileage: '38290'
        },
        sellArea: '170',
        dealerCd: '20141',
        certificationNumber: 9418443,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW692277',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T15:34:22.271Z'
        }
      },
      sort: [
        1591640917769
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6FD132258121592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:28:30.249Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14968.00',
          mileage: '77028'
        },
        sellArea: '210',
        dealerCd: '12159',
        certificationNumber: 9501726,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6FD132258',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T22:33:20.771Z'
        }
      },
      sort: [
        1591640910249
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU779226480652020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:28:01.288Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18345.00',
          mileage: '20981'
        },
        sellArea: '210',
        dealerCd: '48065',
        certificationNumber: 9445423,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU779226',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T16:09:18.632Z'
        }
      },
      sort: [
        1591640881288
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3HM062995361022020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:27:40.386Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '31498.00',
          mileage: '54751'
        },
        sellArea: '130',
        dealerCd: '36102',
        certificationNumber: 9500469,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3HM062995',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:18:54.577Z'
        }
      },
      sort: [
        1591640860386
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0GM025739201412020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:27:34.028Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '29289.00',
          mileage: '51396'
        },
        sellArea: '160',
        dealerCd: '20141',
        certificationNumber: 9422197,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0GM025739',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T14:52:24.379Z'
        }
      },
      sort: [
        1591640854028
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0G5304512045432020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:27:27.188Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32693.04',
          mileage: '33797'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9498445,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0G5304512',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T02:22:34.258Z'
        }
      },
      sort: [
        1591640847188
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV1GW281300361022020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:27:01.429Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '71316'
        },
        sellArea: '500',
        dealerCd: '36102',
        certificationNumber: 9500432,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV1GW281300',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:58:11.063Z'
        }
      },
      sort: [
        1591640821429
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMEWRFV5KJ005008361022020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:26:23.307Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '34645.96',
          mileage: '27690'
        },
        sellArea: '130',
        dealerCd: '36102',
        certificationNumber: 9461049,
        makeCode: 'TOYOTA',
        vin: 'JTMEWRFV5KJ005008',
        model: {
          modelDescription: 'RAV4 HYBRID XSE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T17:38:14.872Z'
        }
      },
      sort: [
        1591640783307
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXK3DC6FS529909480362020-05-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:25:45.487Z',
          retailDate: '2020-05-07',
          rdrStatus: 'H',
          soldAmount: '22872.38',
          mileage: '43995'
        },
        sellArea: '210',
        dealerCd: '48036',
        certificationNumber: 9442381,
        makeCode: 'TOYOTA',
        vin: '5TDXK3DC6FS529909',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T16:40:14.480Z'
        }
      },
      sort: [
        1591640745487
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW596046311312020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:25:30.348Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21399.00',
          mileage: '3822'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9501051,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW596046',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T14:34:23.628Z'
        }
      },
      sort: [
        1591640730348
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDBVRBD6HA002100045432020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:25:11.348Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16888.00',
          mileage: '36425'
        },
        dealerCd: '04543',
        certificationNumber: 9376643,
        makeCode: 'TOYOTA',
        vin: 'JTDBVRBD6HA002100',
        model: {
          modelDescription: 'MIRAI 4-DOOR SEDAN',
          modelSeries: 'MIRAI',
          modelNumber: '3000',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-11T17:53:45.156Z'
        }
      },
      sort: [
        1591640711348
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2GW423636201412020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:24:58.948Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '18193.00',
          mileage: '37974'
        },
        sellArea: '130',
        dealerCd: '20141',
        certificationNumber: 9394219,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2GW423636',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T21:43:28.032Z'
        }
      },
      sort: [
        1591640698948
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZRFH0HS229561043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:24:31.687Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26388.00',
          mileage: '37121'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9495959,
        makeCode: 'TOYOTA',
        vin: '5TDZZRFH0HS229561',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6947',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:39:29.847Z'
        }
      },
      sort: [
        1591640671687
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBEXLJ017173040872020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:24:27.247Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22088.00',
          mileage: '13410'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9412151,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBEXLJ017173',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T23:50:31.363Z'
        }
      },
      sort: [
        1591640667247
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7L5753728044122020-05-11H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:24:26.746Z',
          retailDate: '2020-05-11',
          rdrStatus: 'H',
          soldAmount: '37664.00',
          mileage: '4896'
        },
        sellArea: '120',
        dealerCd: '04412',
        certificationNumber: 9503111,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7L5753728',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T18:19:37.779Z'
        }
      },
      sort: [
        1591640666746
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5EN9HX052901201412020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:24:04.907Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '39669'
        },
        sellArea: '170',
        dealerCd: '20141',
        certificationNumber: 9422188,
        makeCode: 'TOYOTA',
        vin: '5TFSX5EN9HX052901',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7516',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T14:48:33.712Z'
        }
      },
      sort: [
        1591640644907
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU431119130752020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:23:43.748Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15951.00',
          mileage: '27596'
        },
        sellArea: '210',
        dealerCd: '13075',
        certificationNumber: 9486075,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU431119',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:39:37.636Z'
        }
      },
      sort: [
        1591640623748
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN2KX192879060362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:23:32.066Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29815.00',
          mileage: '15173'
        },
        sellArea: '160',
        dealerCd: '06036',
        certificationNumber: 9399223,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN2KX192879',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T18:50:40.608Z'
        }
      },
      sort: [
        1591640612066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1H5422540060492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:23:07.185Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34500.00',
          mileage: '30533'
        },
        sellArea: '160',
        certificationNumber: 9474341,
        dealerCd: '06049',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2017
        },
        vin: 'JTEBU5JR1H5422540',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T15:25:36.611Z'
        }
      },
      sort: [
        1591640587185
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRWRFV4KD033401190622020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:22:34.445Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26419.00',
          mileage: '29634'
        },
        sellArea: '800',
        dealerCd: '19062',
        certificationNumber: 9492164,
        makeCode: 'TOYOTA',
        vin: 'JTMRWRFV4KD033401',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:52:37.841Z'
        }
      },
      sort: [
        1591640554445
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA13H9706172020512020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:21:33.824Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19900.00',
          mileage: '46194'
        },
        sellArea: '150',
        dealerCd: '02051',
        certificationNumber: 9483719,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA13H9706172',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6253',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:24:08.613Z'
        }
      },
      sort: [
        1591640493824
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS391835201412020-05-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:21:22.405Z',
          retailDate: '2020-05-13',
          rdrStatus: 'H',
          soldAmount: '27514.00',
          mileage: '23144'
        },
        sellArea: '170',
        dealerCd: '20141',
        certificationNumber: 9430375,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS391835',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T14:57:22.071Z'
        }
      },
      sort: [
        1591640482405
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU4K3098584040872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:20:46.726Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25288.00',
          mileage: '4664'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9475295,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU4K3098584',
        model: {
          modelDescription: 'PRIUS XLE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T00:13:47.330Z'
        }
      },
      sort: [
        1591640446726
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW613512201412020-05-11H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:20:36.357Z',
          retailDate: '2020-05-11',
          rdrStatus: 'H',
          soldAmount: '18409.00',
          mileage: '29899'
        },
        sellArea: '170',
        dealerCd: '20141',
        certificationNumber: 9430366,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW613512',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T14:54:19.707Z'
        }
      },
      sort: [
        1591640436357
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH5GS328115480362020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:20:27.765Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '24939.00',
          mileage: '72621'
        },
        sellArea: '210',
        dealerCd: '48036',
        certificationNumber: 9455234,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH5GS328115',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T14:54:28.073Z'
        }
      },
      sort: [
        1591640427765
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK9KU515002480462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:20:07.384Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25912.00',
          mileage: '5265'
        },
        sellArea: '210',
        dealerCd: '48046',
        certificationNumber: 9415067,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK9KU515002',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T14:42:20.618Z'
        }
      },
      sort: [
        1591640407384
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5HW657240371812020-05-02V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:19:52.448Z',
          retailDate: '2020-05-02',
          rdrStatus: 'V',
          soldAmount: '20900.00',
          mileage: '19928'
        },
        sellArea: '160',
        dealerCd: '37181',
        certificationNumber: 9453328,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5HW657240',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T15:55:40.101Z'
        }
      },
      sort: [
        1591640392448
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9HM084550201412020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:19:35.623Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '29078.00',
          mileage: '37594'
        },
        dealerCd: '20141',
        certificationNumber: 9386044,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9HM084550',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T19:14:34.460Z'
        }
      },
      sort: [
        1591640375623
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6JJ743093045432020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:19:34.244Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21300.00',
          mileage: '20762'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9496353,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6JJ743093',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:48:37.538Z'
        }
      },
      sort: [
        1591640374244
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0K5635244020512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:18:47.858Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29900.00',
          mileage: '33723'
        },
        sellArea: '500',
        dealerCd: '02051',
        certificationNumber: 9434785,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0K5635244',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T15:33:25.931Z'
        }
      },
      sort: [
        1591640327858
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2LS038698430222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:18:40.864Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '27070'
        },
        sellArea: '150',
        dealerCd: '43022',
        certificationNumber: 9493854,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2LS038698',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:17:57.064Z'
        }
      },
      sort: [
        1591640320864
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBY5G18HS152145045432020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:17:12.724Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '40888.00',
          mileage: '36112'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9479091,
        makeCode: 'TOYOTA',
        vin: '5TDBY5G18HS152145',
        model: {
          modelDescription: 'SEQUOIA 4X4 SR5 8-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7919',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T16:18:43.387Z'
        }
      },
      sort: [
        1591640232724
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DU9E0369326040872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:17:03.984Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13988.00',
          mileage: '43608'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9495342,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DU9E0369326',
        model: {
          modelDescription: 'PRIUS Two Model',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:49:30.120Z'
        }
      },
      sort: [
        1591640223984
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HJ120841201412020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:16:54.102Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '16895.00',
          mileage: '23914'
        },
        sellArea: '170',
        dealerCd: '20141',
        certificationNumber: 9430345,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HJ120841',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T14:46:30.074Z'
        }
      },
      sort: [
        1591640214102
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV1HJ136397048192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:16:41.504Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22485.00',
          mileage: '38032'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9488284,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV1HJ136397',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:38:49.380Z'
        }
      },
      sort: [
        1591640201504
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN9HX087362380312020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:16:36.964Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '37040.00',
          mileage: '40010'
        },
        sellArea: '170',
        dealerCd: '38031',
        certificationNumber: 9481299,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN9HX087362',
        model: {
          modelDescription: 'TACOMA TRD PRO 4X4 DBL CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7598',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T12:06:33.985Z'
        }
      },
      sort: [
        1591640196964
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMH1RFV5KJ007398020532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:16:24.503Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22982.00',
          mileage: '13478'
        },
        sellArea: '150',
        dealerCd: '02053',
        certificationNumber: 9481967,
        makeCode: 'TOYOTA',
        vin: 'JTMH1RFV5KJ007398',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:33:27.486Z'
        }
      },
      sort: [
        1591640184503
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU415930430222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:16:05.603Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17490.00',
          mileage: '39563'
        },
        sellArea: '150',
        dealerCd: '43022',
        certificationNumber: 9413342,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU415930',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T14:29:15.897Z'
        }
      },
      sort: [
        1591640165603
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP700471240522020-01-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:16:01.942Z',
          retailDate: '2020-01-06',
          rdrStatus: 'H',
          soldAmount: '16700.00',
          mileage: '10393'
        },
        dealerCd: '24052',
        certificationNumber: 9499220,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP700471',
        model: {
          modelDescription: 'COROLLA XLE',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:06:18.114Z'
        }
      },
      sort: [
        1591640161942
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU737235048192020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:15:47.303Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25070.00',
          mileage: '16874'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9457926,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU737235',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T20:49:55.434Z'
        }
      },
      sort: [
        1591640147303
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX8JR007861020362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:15:35.863Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18480.00',
          mileage: '21212'
        },
        sellArea: '210',
        dealerCd: '02036',
        certificationNumber: 9484230,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX8JR007861',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:41:49.899Z'
        }
      },
      sort: [
        1591640135863
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK4J3034682020532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:15:24.203Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18953.00',
          mileage: '31444'
        },
        sellArea: '150',
        dealerCd: '02053',
        certificationNumber: 9438933,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK4J3034682',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T22:55:24.699Z'
        }
      },
      sort: [
        1591640124203
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFTU4GN5FX084228020512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:15:20.201Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19900.00',
          mileage: '79343'
        },
        sellArea: '150',
        dealerCd: '02051',
        certificationNumber: 9499904,
        makeCode: 'TOYOTA',
        vin: '5TFTU4GN5FX084228',
        model: {
          modelDescription: 'TACOMA PRERUNNER 4X2 ACCESS CAB V6',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7164',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:47:17.488Z'
        }
      },
      sort: [
        1591640120201
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC1HS858333045432020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:15:14.222Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23350.00',
          mileage: '29081'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9476840,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC1HS858333',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T00:25:31.394Z'
        }
      },
      sort: [
        1591640114222
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV6KW032352048192020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:14:53.583Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '27327.00',
          mileage: '24432'
        },
        sellArea: '500',
        dealerCd: '04819',
        certificationNumber: 9420826,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV6KW032352',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T22:22:28.082Z'
        }
      },
      sort: [
        1591640093583
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC924343020362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:14:17.212Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14951.00',
          mileage: '31846'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9491769,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC924343',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:01:06.959Z'
        }
      },
      sort: [
        1591640057212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC8HS797763048192020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:13:44.462Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '26975.00',
          mileage: '43519'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9495672,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC8HS797763',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:06:13.168Z'
        }
      },
      sort: [
        1591640024462
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4G5319627045432020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:13:12.042Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32300.00',
          mileage: '51347'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9492456,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4G5319627',
        model: {
          modelDescription: '4RUNNER 4X4 TRAIL PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:59:55.290Z'
        }
      },
      sort: [
        1591639992042
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV8GJ088608020362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:12:59.943Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16696.26',
          mileage: '68921'
        },
        sellArea: '110',
        dealerCd: '02036',
        certificationNumber: 9487181,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV8GJ088608',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:22:18.414Z'
        }
      },
      sort: [
        1591639979943
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN3HM026162048192020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:12:54.860Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '32800.00',
          mileage: '46165'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9500347,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN3HM026162',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:16:48.136Z'
        }
      },
      sort: [
        1591639974860
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU791980020512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:12:21.361Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20900.00',
          mileage: '17624'
        },
        sellArea: '500',
        dealerCd: '02051',
        certificationNumber: 9475209,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU791980',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T22:52:22.885Z'
        }
      },
      sort: [
        1591639941361
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU388035341222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:12:11.100Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17755.00',
          mileage: '20025'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9485229,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU388035',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:05:23.635Z'
        }
      },
      sort: [
        1591639931100
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN2HX093489040872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:11:39.935Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26987.00',
          mileage: '33980'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9500314,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN2HX093489',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:58:06.181Z'
        }
      },
      sort: [
        1591639899935
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6LM298081048192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:11:39.059Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '41790.00',
          mileage: '38032'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9500375,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6LM298081',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:29:14.882Z'
        }
      },
      sort: [
        1591639899059
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC5KS015835020362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:11:15.657Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27399.00',
          mileage: '28865'
        },
        sellArea: '500',
        dealerCd: '02036',
        certificationNumber: 9487122,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC5KS015835',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:58:53.795Z'
        }
      },
      sort: [
        1591639875657
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1M11AK8LU862489330252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:11:02.158Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23000.00',
          mileage: '1222'
        },
        sellArea: '230',
        dealerCd: '33025',
        certificationNumber: 9492680,
        makeCode: 'TOYOTA',
        vin: '4T1M11AK8LU862489',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:10:34.944Z'
        }
      },
      sort: [
        1591639862158
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDBVRBDXGA000994045432020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:10:18.918Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19800.00',
          mileage: '35232'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9422537,
        makeCode: 'TOYOTA',
        vin: 'JTDBVRBDXGA000994',
        model: {
          modelDescription: 'MIRAI 4-DOOR SEDAN',
          modelSeries: 'MIRAI',
          modelNumber: '3000',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T17:25:26.799Z'
        }
      },
      sort: [
        1591639818918
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU197588020512020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:10:18.057Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19728.00',
          mileage: '21263'
        },
        sellArea: '500',
        dealerCd: '02051',
        certificationNumber: 9487540,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU197588',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:25:12.619Z'
        }
      },
      sort: [
        1591639818057
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBGRFH3JS048970480362020-05-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:09:49.240Z',
          retailDate: '2020-05-06',
          rdrStatus: 'H',
          soldAmount: '29257.00',
          mileage: '29596'
        },
        sellArea: '500',
        dealerCd: '48036',
        certificationNumber: 9453629,
        makeCode: 'TOYOTA',
        vin: '5TDBGRFH3JS048970',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LE-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6964',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T18:08:07.076Z'
        }
      },
      sort: [
        1591639789240
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KC009631043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:09:41.602Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25747.00',
          mileage: '22794'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9502933,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KC009631',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T17:34:35.570Z'
        }
      },
      sort: [
        1591639781602
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV2HW360845020362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:09:12.221Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18150.00',
          mileage: '27072'
        },
        sellArea: '120',
        dealerCd: '02036',
        certificationNumber: 9483212,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV2HW360845',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:24:31.221Z'
        }
      },
      sort: [
        1591639752221
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMJU4GN9FM177430040872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:08:51.240Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24999.00',
          mileage: '40461'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9500141,
        makeCode: 'TOYOTA',
        vin: '3TMJU4GN9FM177430',
        model: {
          modelDescription: 'TACOMA PRERUNNER DOUBLE CAB 4X2 V6',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7188',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:06:57.604Z'
        }
      },
      sort: [
        1591639731240
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6H5437874048192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:08:24.638Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '39995.00',
          mileage: '17941'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9495632,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6H5437874',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:56:06.823Z'
        }
      },
      sort: [
        1591639704638
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB39J1604437045432020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:08:01.820Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18900.75',
          mileage: '16314'
        },
        dealerCd: '04543',
        certificationNumber: 9388183,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB39J1604437',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB THREE',
          modelSeries: 'PRIUS C',
          modelNumber: '1205',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-19T21:52:14.329Z'
        }
      },
      sort: [
        1591639681820
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXK3DC6FS556897020362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:07:29.960Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18998.00',
          mileage: '75984'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9473618,
        makeCode: 'TOYOTA',
        vin: '5TDXK3DC6FS556897',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T22:50:33.032Z'
        }
      },
      sort: [
        1591639649960
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0JP747297040872020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:05:29.978Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15638.00',
          mileage: '21245'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9458540,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0JP747297',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T15:37:33.142Z'
        }
      },
      sort: [
        1591639529978
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV6KW038510020362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:05:27.540Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24057.80',
          mileage: '19487'
        },
        sellArea: '600',
        dealerCd: '02036',
        certificationNumber: 9486021,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV6KW038510',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:04:52.564Z'
        }
      },
      sort: [
        1591639527540
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRY5F13JX233862020362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:04:01.457Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '29563.00',
          mileage: '39000'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9500235,
        makeCode: 'TOYOTA',
        vin: '5TFRY5F13JX233862',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8241',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:30:42.533Z'
        }
      },
      sort: [
        1591639441457
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1GU234958048192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:04:01.033Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15292.25',
          mileage: '62561'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9488299,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1GU234958',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:43:02.961Z'
        }
      },
      sort: [
        1591639441033
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU392768048192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:02:29.131Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16750.00',
          mileage: '25856'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9460078,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU392768',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:18:55.047Z'
        }
      },
      sort: [
        1591639349131
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV3KC020265020362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:01:47.757Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22777.00',
          mileage: '14835'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9488221,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV3KC020265',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:18:22.702Z'
        }
      },
      sort: [
        1591639307757
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU9E3324642040872020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:01:39.696Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15835.00',
          mileage: '56977'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9482675,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU9E3324642',
        model: {
          modelDescription: 'PRIUS v Five Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1249',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T00:44:59.642Z'
        }
      },
      sort: [
        1591639299696
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3HM083782048192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:00:39.318Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31240.00',
          mileage: '37929'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9487141,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3HM083782',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:08:06.724Z'
        }
      },
      sort: [
        1591639239318
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2GU262994020362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T18:00:38.231Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14511.11',
          mileage: '40391'
        },
        sellArea: '600',
        dealerCd: '02036',
        certificationNumber: 9495965,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2GU262994',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:40:39.347Z'
        }
      },
      sort: [
        1591639238231
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV5JW410856020362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:59:41.496Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18899.00',
          mileage: '46150'
        },
        sellArea: '500',
        dealerCd: '02036',
        certificationNumber: 9490468,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV5JW410856',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T16:59:50.759Z'
        }
      },
      sort: [
        1591639181496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW597128291022020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:59:27.516Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20246.00',
          mileage: '25188'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9497809,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW597128',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:26:19.758Z'
        }
      },
      sort: [
        1591639167516
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX8K1029855048192020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:59:23.734Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22142.29',
          mileage: '13079'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9476568,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX8K1029855',
        model: {
          modelDescription: 'C-HR LE',
          modelSeries: 'CHR',
          modelNumber: '2402',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:34:41.804Z'
        }
      },
      sort: [
        1591639163734
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC6HS781300450912020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:59:11.076Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20980.00',
          mileage: '31629'
        },
        sellArea: '160',
        dealerCd: '45091',
        certificationNumber: 9474151,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC6HS781300',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T13:43:20.657Z'
        }
      },
      sort: [
        1591639151076
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE0K3009854450912020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:58:25.815Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '1951'
        },
        sellArea: '800',
        dealerCd: '45091',
        certificationNumber: 9484557,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE0K3009854',
        model: {
          modelDescription: 'COROLLA HATCHBACK XSE 5DrHATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6274',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T23:27:36.027Z'
        }
      },
      sort: [
        1591639105815
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM106842060362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:57:56.095Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32495.00',
          mileage: '11145'
        },
        sellArea: '160',
        dealerCd: '06036',
        certificationNumber: 9492025,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM106842',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:07:58.268Z'
        }
      },
      sort: [
        1591639076095
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3NFREV6GW260213240712020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:56:23.574Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19276.0000',
          mileage: '48748'
        },
        sellArea: '230',
        dealerCd: '24071',
        certificationNumber: 9487728,
        makeCode: 'TOYOTA',
        vin: '2T3NFREV6GW260213',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:38:46.160Z'
        }
      },
      sort: [
        1591638983574
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU0J3550013240712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:56:20.935Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22963.0000',
          mileage: '33626'
        },
        sellArea: '230',
        dealerCd: '24071',
        certificationNumber: 9462878,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU0J3550013',
        model: {
          modelDescription: 'PRIUS FOUR TOURING',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1228',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T16:02:51.961Z'
        }
      },
      sort: [
        1591638980935
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9JU101061240712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:56:18.515Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19995.0000',
          mileage: '41476'
        },
        sellArea: '230',
        dealerCd: '24071',
        certificationNumber: 9487716,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9JU101061',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:34:04.912Z'
        }
      },
      sort: [
        1591638978515
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9J5573647240712020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:56:14.675Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '38165.0000',
          mileage: '31710'
        },
        sellArea: '600',
        dealerCd: '24071',
        certificationNumber: 9487820,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9J5573647',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:07:54.663Z'
        }
      },
      sort: [
        1591638974675
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV0HD104225240712020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:56:10.995Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25551.0000',
          mileage: '34865'
        },
        sellArea: '220',
        dealerCd: '24071',
        certificationNumber: 9495653,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV0HD104225',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:01:50.572Z'
        }
      },
      sort: [
        1591638970995
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH7HS384831240712020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:56:07.933Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '34520.0000',
          mileage: '33987'
        },
        sellArea: '800',
        dealerCd: '24071',
        certificationNumber: 9487776,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH7HS384831',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:52:58.739Z'
        }
      },
      sort: [
        1591638967933
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV5GJ080609240712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:56:03.467Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17988.0000',
          mileage: '61499'
        },
        sellArea: '230',
        dealerCd: '24071',
        certificationNumber: 9474926,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV5GJ080609',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:59:49.142Z'
        }
      },
      sort: [
        1591638963467
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU3J3066577240712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:55:58.467Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25980.00',
          mileage: '14821'
        },
        sellArea: '230',
        dealerCd: '24071',
        certificationNumber: 9492841,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU3J3066577',
        model: {
          modelDescription: 'PRIUS FOUR',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1227',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:50:59.133Z'
        }
      },
      sort: [
        1591638958467
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN7HX094374060362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:55:39.516Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30995.00',
          mileage: '34190'
        },
        sellArea: '160',
        dealerCd: '06036',
        certificationNumber: 9501100,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN7HX094374',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7543',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T14:55:32.814Z'
        }
      },
      sort: [
        1591638939516
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE9HJ543496040872020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:55:37.386Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14788.00',
          mileage: '17020'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9445284,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE9HJ543496',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-16T23:29:22.614Z'
        }
      },
      sort: [
        1591638937386
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4HW627791480362020-05-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:55:02.805Z',
          retailDate: '2020-05-06',
          rdrStatus: 'H',
          soldAmount: '20037.00',
          mileage: '54668'
        },
        sellArea: '210',
        dealerCd: '48036',
        certificationNumber: 9444439,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4HW627791',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T20:28:01.186Z'
        }
      },
      sort: [
        1591638902805
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3G1RFV3LW087069470232020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:54:57.545Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '27491.00',
          mileage: '1198'
        },
        sellArea: '800',
        dealerCd: '47023',
        certificationNumber: 9498835,
        makeCode: 'TOYOTA',
        vin: '2T3G1RFV3LW087069',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:00:13.198Z'
        }
      },
      sort: [
        1591638897545
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW652144291022020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:54:47.565Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '19950.00',
          mileage: '31260'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9497238,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW652144',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:00:27.649Z'
        }
      },
      sort: [
        1591638887565
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU269991450912020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:53:41.634Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17995.00',
          mileage: '13306'
        },
        sellArea: '800',
        dealerCd: '45091',
        certificationNumber: 9475287,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU269991',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T00:01:49.910Z'
        }
      },
      sort: [
        1591638821634
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5JS485343340892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:53:02.472Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27900.00',
          mileage: '65851'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9465834,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5JS485343',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:01:07.653Z'
        }
      },
      sort: [
        1591638782472
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZRFH1HS206368043562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:53:00.094Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27325.00',
          mileage: '30010'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9499599,
        makeCode: 'TOYOTA',
        vin: '5TDZZRFH1HS206368',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6946',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:00:45.752Z'
        }
      },
      sort: [
        1591638780094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3HP663632040872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:52:08.984Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '12638.29',
          mileage: '43816'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9442623,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3HP663632',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1863',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T21:30:54.007Z'
        }
      },
      sort: [
        1591638728984
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV1HJ712714480652020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:51:03.817Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24091.00',
          mileage: '20163'
        },
        sellArea: '210',
        dealerCd: '48065',
        certificationNumber: 9495411,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV1HJ712714',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:16:02.072Z'
        }
      },
      sort: [
        1591638663817
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ136424160492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:48:57.503Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17980.00',
          mileage: '23633'
        },
        sellArea: '800',
        dealerCd: '16049',
        certificationNumber: 9487013,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ136424',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:09:34.638Z'
        }
      },
      sort: [
        1591638537503
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2HD209857311752020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:48:50.241Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21285.63',
          mileage: '36313'
        },
        sellArea: '160',
        dealerCd: '31175',
        certificationNumber: 9471234,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2HD209857',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:59:17.177Z'
        }
      },
      sort: [
        1591638530241
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ702688311752020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:45:44.469Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17424.00',
          mileage: '24909'
        },
        sellArea: '160',
        dealerCd: '31175',
        certificationNumber: 9495407,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ702688',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:15:20.493Z'
        }
      },
      sort: [
        1591638344469
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7JD171240047532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:45:10.868Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23999.00',
          mileage: '45888'
        },
        sellArea: '120',
        dealerCd: '04753',
        certificationNumber: 9455323,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7JD171240',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T16:15:06.027Z'
        }
      },
      sort: [
        1591638310868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK1JU093541060402020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:44:53.409Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24987.00',
          mileage: '38262'
        },
        sellArea: '160',
        dealerCd: '06040',
        certificationNumber: 9473133,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK1JU093541',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:16:12.447Z'
        }
      },
      sort: [
        1591638293409
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC0HS807783040872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:44:41.090Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28138.00',
          mileage: '36395'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9501177,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC0HS807783',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T15:56:40.759Z'
        }
      },
      sort: [
        1591638281090
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC876564371532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:43:31.598Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '25688'
        },
        sellArea: '800',
        dealerCd: '37153',
        certificationNumber: 9502847,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC876564',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T17:16:37.469Z'
        }
      },
      sort: [
        1591638211598
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU714716047532020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:43:12.697Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17749.00',
          mileage: '32201'
        },
        sellArea: '120',
        dealerCd: '04753',
        certificationNumber: 9407847,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU714716',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T00:44:04.717Z'
        }
      },
      sort: [
        1591638192697
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HJ152346060402020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:41:45.557Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20999.00',
          mileage: '47702'
        },
        sellArea: '160',
        dealerCd: '06040',
        certificationNumber: 9428237,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HJ152346',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T13:57:50.763Z'
        }
      },
      sort: [
        1591638105557
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2LS033677311752020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:40:24.368Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29520.00',
          mileage: '20476'
        },
        dealerCd: '31175',
        certificationNumber: 9358321,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2LS033677',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-29T00:00:12.941Z'
        }
      },
      sort: [
        1591638024368
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC777543020552020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:39:59.215Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14836.00',
          mileage: '33692'
        },
        sellArea: '130',
        dealerCd: '02055',
        certificationNumber: 9501408,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC777543',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:38:46.887Z'
        }
      },
      sort: [
        1591637999215
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU218652047532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:39:37.816Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20097.00',
          mileage: '34670'
        },
        sellArea: '500',
        dealerCd: '04753',
        certificationNumber: 9423192,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU218652',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T23:49:03.823Z'
        }
      },
      sort: [
        1591637977816
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBY5G18HS153716360842020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:39:34.936Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26895.00',
          mileage: '40166'
        },
        sellArea: '150',
        dealerCd: '36084',
        certificationNumber: 9493299,
        makeCode: 'TOYOTA',
        vin: '5TDBY5G18HS153716',
        model: {
          modelDescription: 'SEQUOIA 4X4 SR5 8-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7919',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T00:05:26.162Z'
        }
      },
      sort: [
        1591637974936
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HD200070311752020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:38:25.847Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22003.00',
          mileage: '26896'
        },
        sellArea: '160',
        dealerCd: '31175',
        certificationNumber: 9471100,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HD200070',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:11:01.777Z'
        }
      },
      sort: [
        1591637905847
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW629384020512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:38:23.467Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '50887'
        },
        sellArea: '150',
        dealerCd: '02051',
        certificationNumber: 9483746,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW629384',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:32:42.743Z'
        }
      },
      sort: [
        1591637903467
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX0KR076478311922020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:37:52.728Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '21490.00',
          mileage: '4447'
        },
        sellArea: '160',
        dealerCd: '31192',
        certificationNumber: 9413123,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX0KR076478',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-08T21:45:01.439Z'
        }
      },
      sort: [
        1591637872728
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0HM096330050672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:37:35.587Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31999.00',
          mileage: '15793'
        },
        sellArea: '220',
        dealerCd: '05067',
        certificationNumber: 9501650,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0HM096330',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:16:48.891Z'
        }
      },
      sort: [
        1591637855587
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV7HW608663290872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:36:50.860Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23400.00',
          mileage: '17445'
        },
        dealerCd: '29087',
        certificationNumber: 9387603,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV7HW608663',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-19T17:57:22.855Z'
        }
      },
      sort: [
        1591637810860
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH6HS436084311752020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:35:30.267Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24935.00',
          mileage: '35592'
        },
        sellArea: '160',
        dealerCd: '31175',
        certificationNumber: 9497358,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH6HS436084',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:44:37.296Z'
        }
      },
      sort: [
        1591637730267
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU215879160492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:35:22.312Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20990.00',
          mileage: '35350'
        },
        sellArea: '500',
        dealerCd: '16049',
        certificationNumber: 9501243,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU215879',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:33:13.256Z'
        }
      },
      sort: [
        1591637722312
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV3HJ702392220302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:35:12.233Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22985.00',
          mileage: '46968'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9442326,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV3HJ702392',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T15:57:36.055Z'
        }
      },
      sort: [
        1591637712233
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ130440120742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:34:06.126Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '25589'
        },
        sellArea: '210',
        dealerCd: '12074',
        certificationNumber: 9461368,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HJ130440',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T20:58:15.552Z'
        }
      },
      sort: [
        1591637646126
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV1HJ708764311752020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:33:56.487Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19528.00',
          mileage: '28198'
        },
        sellArea: '160',
        dealerCd: '31175',
        certificationNumber: 9497443,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV1HJ708764',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:24:08.542Z'
        }
      },
      sort: [
        1591637636487
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BNXHM017460440132020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:33:52.229Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '56400'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9496887,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BNXHM017460',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T12:59:55.313Z'
        }
      },
      sort: [
        1591637632229
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS433315047532020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:33:38.646Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26474.00',
          mileage: '34155'
        },
        sellArea: '120',
        dealerCd: '04753',
        certificationNumber: 9500549,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS433315',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T00:10:19.018Z'
        }
      },
      sort: [
        1591637618646
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7J5550173360842020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:33:10.990Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '36800.00',
          mileage: '29521'
        },
        dealerCd: '36084',
        certificationNumber: 9228446,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7J5550173',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-01T18:15:05.641Z'
        }
      },
      sort: [
        1591637590990
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC777152480462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:32:51.349Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25912.00',
          mileage: '54011'
        },
        sellArea: '210',
        dealerCd: '48046',
        certificationNumber: 9449432,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC777152',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-25T16:39:48.981Z'
        }
      },
      sort: [
        1591637571349
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV9HD082926480652020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:32:49.587Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25459.71',
          mileage: '32809'
        },
        sellArea: '220',
        dealerCd: '48065',
        certificationNumber: 9502919,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV9HD082926',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T17:29:51.539Z'
        }
      },
      sort: [
        1591637569587
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0KS609519290872020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:32:33.387Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '36498.00',
          mileage: '3873'
        },
        sellArea: '160',
        dealerCd: '29087',
        certificationNumber: 9402736,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0KS609519',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T15:01:59.332Z'
        }
      },
      sort: [
        1591637553387
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU455051050672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:32:24.287Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14655.8600',
          mileage: '55515'
        },
        sellArea: '500',
        dealerCd: '05067',
        certificationNumber: 9500423,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU455051',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:54:20.421Z'
        }
      },
      sort: [
        1591637544287
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU795411311752020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:32:19.927Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17514.00',
          mileage: '15021'
        },
        sellArea: '160',
        dealerCd: '31175',
        certificationNumber: 9502291,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU795411',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T14:46:32.716Z'
        }
      },
      sort: [
        1591637539927
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ701562050672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:32:19.590Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19497.1300',
          mileage: '31723'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9478293,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ701562',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:45:32.563Z'
        }
      },
      sort: [
        1591637539590
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2G5372228050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:32:13.551Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27481.0000',
          mileage: '55146'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9498257,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2G5372228',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T22:14:47.706Z'
        }
      },
      sort: [
        1591637533551
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC777561120742020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:32:11.683Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15495.00',
          mileage: '28731'
        },
        sellArea: '210',
        dealerCd: '12074',
        certificationNumber: 9494594,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC777561',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:58:29.444Z'
        }
      },
      sort: [
        1591637531683
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3JC988450050672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:32:10.070Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13981.00',
          mileage: '16264'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9501660,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3JC988450',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:27:40.914Z'
        }
      },
      sort: [
        1591637530070
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU697801047532020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:30:40.910Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16998.00',
          mileage: '42828'
        },
        sellArea: '120',
        dealerCd: '04753',
        certificationNumber: 9420945,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU697801',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T23:44:21.936Z'
        }
      },
      sort: [
        1591637440910
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH3GS345138341222020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:28:25.248Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '28795.00',
          mileage: '44092'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9425794,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH3GS345138',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T18:59:07.205Z'
        }
      },
      sort: [
        1591637305248
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6KS626177240842020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:27:00.567Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '35791.00',
          mileage: '4075'
        },
        sellArea: '230',
        dealerCd: '24084',
        certificationNumber: 9478981,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6KS626177',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T14:27:14.047Z'
        }
      },
      sort: [
        1591637220567
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HJ129526060402020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:26:46.467Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21300.00',
          mileage: '37124'
        },
        dealerCd: '06040',
        certificationNumber: 9326704,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HJ129526',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-08T15:53:45.229Z'
        }
      },
      sort: [
        1591637206467
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8HW633689480272020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:25:51.226Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19198.00',
          mileage: '25373'
        },
        sellArea: '210',
        dealerCd: '48027',
        certificationNumber: 9459819,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8HW633689',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T17:52:05.603Z'
        }
      },
      sort: [
        1591637151226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV5JW429744450622020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:24:51.205Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22889.00',
          mileage: '56217'
        },
        sellArea: '500',
        dealerCd: '45062',
        certificationNumber: 9498940,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV5JW429744',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:41:35.106Z'
        }
      },
      sort: [
        1591637091205
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0EU378627290872020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:24:49.581Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '12500.00',
          mileage: '56941'
        },
        sellArea: '160',
        dealerCd: '29087',
        certificationNumber: 9473337,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0EU378627',
        model: {
          modelDescription: 'CAMRY 2014.5 SE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T20:33:28.857Z'
        }
      },
      sort: [
        1591637089581
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5FP244131046762020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:24:48.626Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15013.00',
          mileage: '60995'
        },
        sellArea: '120',
        dealerCd: '04676',
        certificationNumber: 9488559,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5FP244131',
        model: {
          modelDescription: 'COROLLA S PREMIUM',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:12:32.583Z'
        }
      },
      sort: [
        1591637088626
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DCXHS171184060402020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:24:17.847Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24991.00',
          mileage: '64924'
        },
        sellArea: '160',
        dealerCd: '06040',
        certificationNumber: 9460042,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DCXHS171184',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:01:42.452Z'
        }
      },
      sort: [
        1591637057847
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5JJ729282311922020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:23:44.645Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '24995.00',
          mileage: '17758'
        },
        sellArea: '160',
        dealerCd: '31192',
        certificationNumber: 9427831,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5JJ729282',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T23:19:19.946Z'
        }
      },
      sort: [
        1591637024645
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU3G3519380211102020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:23:32.206Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '34121'
        },
        sellArea: '220',
        dealerCd: '21110',
        certificationNumber: 9460709,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU3G3519380',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T14:11:25.961Z'
        }
      },
      sort: [
        1591637012206
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE6LP076638046762020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:23:14.528Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21684.00',
          mileage: '6047'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9497466,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE6LP076638',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:31:07.725Z'
        }
      },
      sort: [
        1591636994528
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1J5583492360992020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:22:17.066Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '34995.00',
          mileage: '31681'
        },
        sellArea: '130',
        dealerCd: '36099',
        certificationNumber: 9473711,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1J5583492',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T23:38:14.905Z'
        }
      },
      sort: [
        1591636937066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN2FM202213290872020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:19:59.966Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '27250.00',
          mileage: '31982'
        },
        dealerCd: '29087',
        certificationNumber: 9277640,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN2FM202213',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-05T14:34:28.717Z'
        }
      },
      sort: [
        1591636799966
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV0JJ735145340412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:17:43.845Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '16240'
        },
        sellArea: '220',
        dealerCd: '34041',
        certificationNumber: 9492242,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV0JJ735145',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:16:45.285Z'
        }
      },
      sort: [
        1591636663845
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW603262450552020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:17:39.226Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17500.0000',
          mileage: '35249'
        },
        sellArea: '160',
        dealerCd: '45055',
        certificationNumber: 9497562,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW603262',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:07:52.188Z'
        }
      },
      sort: [
        1591636659226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HJ152771450552020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:17:29.485Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18500.0000',
          mileage: '25919'
        },
        sellArea: '160',
        dealerCd: '45055',
        certificationNumber: 9497385,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HJ152771',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:02:06.688Z'
        }
      },
      sort: [
        1591636649485
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4JU024325450552020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:17:19.305Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21500.0000',
          mileage: '22265'
        },
        sellArea: '800',
        dealerCd: '45055',
        certificationNumber: 9440044,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4JU024325',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-03T19:56:50.496Z'
        }
      },
      sort: [
        1591636639305
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F1XHX599516311802020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:16:17.005Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '34000.00',
          mileage: '14665'
        },
        sellArea: '160',
        dealerCd: '31180',
        certificationNumber: 9485589,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F1XHX599516',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:29:57.206Z'
        }
      },
      sort: [
        1591636577005
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU795362290872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:15:07.744Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22900.00',
          mileage: '2922'
        },
        sellArea: '160',
        dealerCd: '29087',
        certificationNumber: 9418453,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU795362',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T15:41:43.149Z'
        }
      },
      sort: [
        1591636507744
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0J5599697050672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:14:53.664Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33097.0000',
          mileage: '25899'
        },
        sellArea: '130',
        dealerCd: '05067',
        certificationNumber: 9487551,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0J5599697',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:27:02.160Z'
        }
      },
      sort: [
        1591636493664
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW606019050672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:14:51.123Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18496.0300',
          mileage: '32198'
        },
        sellArea: '210',
        dealerCd: '05067',
        certificationNumber: 9463258,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW606019',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T19:02:22.163Z'
        }
      },
      sort: [
        1591636491123
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F16JX724653050672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:14:48.226Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '36981.0000',
          mileage: '19405'
        },
        sellArea: '230',
        dealerCd: '05067',
        certificationNumber: 9499251,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F16JX724653',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:14:10.527Z'
        }
      },
      sort: [
        1591636488226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW645946050672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:14:45.684Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20981.0000',
          mileage: '24965'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9499555,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW645946',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:44:24.623Z'
        }
      },
      sort: [
        1591636485684
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2HJ117973050672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:14:43.425Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19996.6400',
          mileage: '12346'
        },
        sellArea: '160',
        dealerCd: '05067',
        certificationNumber: 9487415,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2HJ117973',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:37:52.294Z'
        }
      },
      sort: [
        1591636483425
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW599963050672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:14:41.125Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21479.4900',
          mileage: '18578'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9500613,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW599963',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T01:58:42.898Z'
        }
      },
      sort: [
        1591636481125
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN2HX078888050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:14:38.705Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '32481.0000',
          mileage: '22106'
        },
        sellArea: '160',
        dealerCd: '05067',
        certificationNumber: 9498395,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN2HX078888',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T00:21:35.057Z'
        }
      },
      sort: [
        1591636478705
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV7HW684410050672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:14:36.225Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18980.00',
          mileage: '28200'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9487391,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV7HW684410',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:29:00.705Z'
        }
      },
      sort: [
        1591636476225
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU341936340412020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:11:37.124Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16028.00',
          mileage: '32900'
        },
        sellArea: '220',
        dealerCd: '34041',
        certificationNumber: 9485255,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU341936',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:17:52.586Z'
        }
      },
      sort: [
        1591636297124
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW586216201282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:11:09.004Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '35208'
        },
        sellArea: '160',
        dealerCd: '20128',
        certificationNumber: 9398819,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW586216',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T16:21:50.874Z'
        }
      },
      sort: [
        1591636269004
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMKU4HN5FM045820047532020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:10:01.925Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20944.05',
          mileage: '70997'
        },
        sellArea: '120',
        dealerCd: '04753',
        certificationNumber: 9489375,
        makeCode: 'TOYOTA',
        vin: '3TMKU4HN5FM045820',
        model: {
          modelDescription: 'TACOMA PRERUNNER L/B DBL CAB 4X2 V6 L/B',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7190',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:02:27.015Z'
        }
      },
      sort: [
        1591636201925
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK0KU298236290872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:09:45.423Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '10505'
        },
        sellArea: '160',
        dealerCd: '29087',
        certificationNumber: 9472965,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK0KU298236',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T17:59:32.308Z'
        }
      },
      sort: [
        1591636185423
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU347787042632020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:07:57.104Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '16590.00',
          mileage: '22425'
        },
        sellArea: '110',
        dealerCd: '04263',
        certificationNumber: 9459861,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU347787',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:09:06.689Z'
        }
      },
      sort: [
        1591636077104
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU272874040392020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:07:36.600Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17993.00',
          mileage: '11225'
        },
        dealerCd: '04039',
        certificationNumber: 9364589,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU272874',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-31T22:51:23.609Z'
        }
      },
      sort: [
        1591636056600
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6JU158902042632020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:07:09.705Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22445.00',
          mileage: '23784'
        },
        sellArea: '110',
        dealerCd: '04263',
        certificationNumber: 9488055,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6JU158902',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:15:38.053Z'
        }
      },
      sort: [
        1591636029705
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4JW841587470282020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:07:03.543Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '24750.00',
          mileage: '14648'
        },
        sellArea: '800',
        dealerCd: '47028',
        certificationNumber: 9494580,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4JW841587',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:53:04.261Z'
        }
      },
      sort: [
        1591636023543
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHD210219260312020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:07:00.378Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19895.00',
          mileage: '32500'
        },
        sellArea: '230',
        dealerCd: '26031',
        certificationNumber: 9473595,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHD210219',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T22:39:54.907Z'
        }
      },
      sort: [
        1591636020378
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX8K1026471040392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:07:00.365Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '9073'
        },
        sellArea: '130',
        dealerCd: '04039',
        certificationNumber: 9420093,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX8K1026471',
        model: {
          modelDescription: 'C-HR LE',
          modelSeries: 'CHR',
          modelNumber: '2402',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T17:08:30.737Z'
        }
      },
      sort: [
        1591636020365
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV0JD131681160442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:06:43.236Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17800.00',
          mileage: '43128'
        },
        sellArea: '500',
        dealerCd: '16044',
        certificationNumber: 9466038,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV0JD131681',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T20:04:14.592Z'
        }
      },
      sort: [
        1591636003236
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV6HY163064040392020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:06:15.537Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13455.02',
          mileage: '30912'
        },
        sellArea: '130',
        dealerCd: '04039',
        certificationNumber: 9414465,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV6HY163064',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T22:47:05.996Z'
        }
      },
      sort: [
        1591635975537
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRZ5CN0GX013236040392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:05:27.157Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21629.00',
          mileage: '58847'
        },
        sellArea: '120',
        dealerCd: '04039',
        certificationNumber: 9496432,
        makeCode: 'TOYOTA',
        vin: '5TFRZ5CN0GX013236',
        model: {
          modelDescription: 'TACOMA SR5 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7126',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:58:13.343Z'
        }
      },
      sort: [
        1591635927157
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR2J5189711042632020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:04:59.137Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '37490.00',
          mileage: '7431'
        },
        sellArea: '110',
        dealerCd: '04263',
        certificationNumber: 9494670,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR2J5189711',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8646',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:43:53.948Z'
        }
      },
      sort: [
        1591635899137
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV3JD113190041222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:04:56.478Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24950.00',
          mileage: '7085'
        },
        sellArea: '110',
        dealerCd: '04122',
        certificationNumber: 9424398,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV3JD113190',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T20:31:03.467Z'
        }
      },
      sort: [
        1591635896478
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRX5GN6HX090405040392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:04:50.819Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22900.00',
          mileage: '26767'
        },
        sellArea: '130',
        dealerCd: '04039',
        certificationNumber: 9493079,
        makeCode: 'TOYOTA',
        vin: '5TFRX5GN6HX090405',
        model: {
          modelDescription: 'TACOMA SR 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7162',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:43:11.934Z'
        }
      },
      sort: [
        1591635890819
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXJW772436040512020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:04:49.983Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20800.00',
          mileage: '36111'
        },
        sellArea: '120',
        dealerCd: '04051',
        certificationNumber: 9458793,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXJW772436',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T18:06:31.443Z'
        }
      },
      sort: [
        1591635889983
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV7HW547175290872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:04:48.097Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16946.00',
          mileage: '30490'
        },
        sellArea: '160',
        dealerCd: '29087',
        certificationNumber: 9483536,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV7HW547175',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:00:13.899Z'
        }
      },
      sort: [
        1591635888097
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW673527330252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:04:47.304Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20900.00',
          mileage: '12309'
        },
        sellArea: '230',
        dealerCd: '33025',
        certificationNumber: 9498260,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW673527',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T22:15:30.600Z'
        }
      },
      sort: [
        1591635887304
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV3KW009393040512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:04:18.637Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21700.00',
          mileage: '27824'
        },
        sellArea: '600',
        dealerCd: '04051',
        certificationNumber: 9444431,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV3KW009393',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T20:18:44.203Z'
        }
      },
      sort: [
        1591635858637
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV3JW474863042632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:04:12.523Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '35946'
        },
        sellArea: '500',
        dealerCd: '04263',
        certificationNumber: 9460983,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV3JW474863',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T17:05:05.823Z'
        }
      },
      sort: [
        1591635852523
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFP4RCEXLP026050040392020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:04:09.879Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '5648'
        },
        sellArea: '120',
        dealerCd: '04039',
        certificationNumber: 9500521,
        makeCode: 'TOYOTA',
        vin: '5YFP4RCEXLP026050',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:53:45.938Z'
        }
      },
      sort: [
        1591635849879
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5GC727149311922020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:03:45.417Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '12890.00',
          mileage: '66159'
        },
        sellArea: '160',
        dealerCd: '31192',
        certificationNumber: 9502756,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5GC727149',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:55:33.086Z'
        }
      },
      sort: [
        1591635825417
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2FC393659200872019-08-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:02:27.897Z',
          retailDate: '2019-08-04',
          rdrStatus: 'H',
          soldAmount: '14877.00',
          mileage: '50150'
        },
        sellArea: '170',
        dealerCd: '20087',
        certificationNumber: 9502765,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2FC393659',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:57:31.446Z'
        }
      },
      sort: [
        1591635747897
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8JW706727110012020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:01:43.355Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '22999.00',
          mileage: '25587'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9492205,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8JW706727',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:05:49.236Z'
        }
      },
      sort: [
        1591635703355
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDCRFH2GS016251041222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:01:02.157Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34150.00',
          mileage: '48654'
        },
        dealerCd: '04122',
        certificationNumber: 9314780,
        makeCode: 'TOYOTA',
        vin: '5TDDCRFH2GS016251',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-31T17:06:28.961Z'
        }
      },
      sort: [
        1591635662157
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV9KW022379290872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:00:48.316Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '4085'
        },
        dealerCd: '29087',
        certificationNumber: 9373623,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV9KW022379',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-08T15:22:22.519Z'
        }
      },
      sort: [
        1591635648316
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH5HS387802201162020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T17:00:22.897Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25988.00',
          mileage: '25070'
        },
        sellArea: '170',
        dealerCd: '20116',
        certificationNumber: 9414878,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH5HS387802',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T12:36:49.136Z'
        }
      },
      sort: [
        1591635622897
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5HW641957220302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:58:59.836Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19900.00',
          mileage: '26745'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9447269,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV5HW641957',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T16:30:28.213Z'
        }
      },
      sort: [
        1591635539836
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH1JS490687201162020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:58:45.316Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30796.00',
          mileage: '8046'
        },
        sellArea: '170',
        dealerCd: '20116',
        certificationNumber: 9487097,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH1JS490687',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:47:48.406Z'
        }
      },
      sort: [
        1591635525316
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC3LS038883190632020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:57:03.436Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '32570.00',
          mileage: '3016'
        },
        sellArea: '800',
        dealerCd: '19063',
        certificationNumber: 9457396,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC3LS038883',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T14:21:07.927Z'
        }
      },
      sort: [
        1591635423436
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH6GS241200041222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:56:56.844Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24300.00',
          mileage: '66677'
        },
        sellArea: '120',
        dealerCd: '04122',
        certificationNumber: 9477640,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH6GS241200',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:00:23.964Z'
        }
      },
      sort: [
        1591635416844
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV3JJ189964190632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:56:32.397Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17491.00',
          mileage: '51590'
        },
        sellArea: '500',
        dealerCd: '19063',
        certificationNumber: 9468626,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV3JJ189964',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T17:37:30.580Z'
        }
      },
      sort: [
        1591635392397
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH8GS312725190632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:55:53.036Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20691.00',
          mileage: '52996'
        },
        sellArea: '800',
        dealerCd: '19063',
        certificationNumber: 9492692,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH8GS312725',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:14:12.644Z'
        }
      },
      sort: [
        1591635353036
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4GC494302190632020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:55:30.043Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '12991.00',
          mileage: '61655'
        },
        sellArea: '800',
        dealerCd: '19063',
        certificationNumber: 9495814,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4GC494302',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:48:14.092Z'
        }
      },
      sort: [
        1591635330043
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE0LJ063162190632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:54:56.164Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15945.44',
          mileage: '14552'
        },
        sellArea: '800',
        dealerCd: '19063',
        certificationNumber: 9499531,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE0LJ063162',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:35:06.815Z'
        }
      },
      sort: [
        1591635296164
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDFPRAE5LJ009358040512020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:52:37.503Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18600.00',
          mileage: '7938'
        },
        sellArea: '120',
        dealerCd: '04051',
        certificationNumber: 9420172,
        makeCode: 'TOYOTA',
        vin: 'JTDFPRAE5LJ009358',
        model: {
          modelDescription: 'COROLLA XLE',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T17:49:26.183Z'
        }
      },
      sort: [
        1591635157503
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7JU155538360842020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:52:33.562Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21971.00',
          mileage: '28653'
        },
        sellArea: '130',
        dealerCd: '36084',
        certificationNumber: 9478360,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7JU155538',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:13:03.263Z'
        }
      },
      sort: [
        1591635153562
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE1LP016193047372020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:51:56.623Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18998.00',
          mileage: '12076'
        },
        sellArea: '600',
        dealerCd: '04737',
        certificationNumber: 9469313,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE1LP016193',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T23:40:18.963Z'
        }
      },
      sort: [
        1591635116623
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0L5751755040512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:51:49.617Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '39800.00',
          mileage: '6941'
        },
        sellArea: '120',
        dealerCd: '04051',
        certificationNumber: 9497573,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0L5751755',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:09:59.814Z'
        }
      },
      sort: [
        1591635109617
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFU1K3004867201162020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:51:45.303Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22900.00',
          mileage: '15552'
        },
        sellArea: '170',
        dealerCd: '20116',
        certificationNumber: 9487045,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFU1K3004867',
        model: {
          modelDescription: 'PRIUS XLE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1265',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:24:04.850Z'
        }
      },
      sort: [
        1591635105303
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1HK3JU002504410562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:49:52.434Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25991.00',
          mileage: '44529'
        },
        sellArea: '220',
        dealerCd: '41056',
        certificationNumber: 9498729,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1HK3JU002504',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T12:55:07.905Z'
        }
      },
      sort: [
        1591634992434
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMF1RFV0KJ005209201162020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:49:20.536Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '21386.00',
          mileage: '21013'
        },
        sellArea: '170',
        dealerCd: '20116',
        certificationNumber: 9487057,
        makeCode: 'TOYOTA',
        vin: 'JTMF1RFV0KJ005209',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:27:41.635Z'
        }
      },
      sort: [
        1591634960536
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH8GS270455200932020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:48:54.205Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26786.00',
          mileage: '36749'
        },
        sellArea: '170',
        dealerCd: '20093',
        certificationNumber: 9410964,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH8GS270455',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T13:22:25.361Z'
        }
      },
      sort: [
        1591634934205
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMYFREV8HJ111525042012020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:46:57.055Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '45267'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9495859,
        makeCode: 'TOYOTA',
        vin: 'JTMYFREV8HJ111525',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:04:33.621Z'
        }
      },
      sort: [
        1591634817055
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU412213340412020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:46:52.734Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '16826.00',
          mileage: '27792'
        },
        sellArea: '220',
        dealerCd: '34041',
        certificationNumber: 9492443,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU412213',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:57:59.196Z'
        }
      },
      sort: [
        1591634812734
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX5K1033863121552020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:46:41.035Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21181.00',
          mileage: '10794'
        },
        sellArea: '210',
        dealerCd: '12155',
        certificationNumber: 9457083,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX5K1033863',
        model: {
          modelDescription: 'C-HR LIMITED',
          modelSeries: 'CHR',
          modelNumber: '2408',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T21:53:14.988Z'
        }
      },
      sort: [
        1591634801035
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV9HW367162042012020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:46:04.656Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19499.00',
          mileage: '26071'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9419908,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV9HW367162',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T15:49:55.066Z'
        }
      },
      sort: [
        1591634764656
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F11HX640731210722020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:45:57.335Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31077.00',
          mileage: '33447'
        },
        dealerCd: '21072',
        certificationNumber: 9382088,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F11HX640731',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T22:37:05.600Z'
        }
      },
      sort: [
        1591634757335
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU276110042012020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:45:09.854Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22200.00',
          mileage: '20729'
        },
        sellArea: '600',
        dealerCd: '04201',
        certificationNumber: 9433176,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU276110',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T18:47:33.456Z'
        }
      },
      sort: [
        1591634709854
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKKRFHXGS500999047262020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:45:08.361Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26691.00',
          mileage: '31834'
        },
        sellArea: '110',
        dealerCd: '04726',
        certificationNumber: 9488355,
        makeCode: 'TOYOTA',
        vin: '5TDKKRFHXGS500999',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T22:03:56.617Z'
        }
      },
      sort: [
        1591634708361
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4H5417073060432020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:44:57.003Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30389.00',
          mileage: '27034'
        },
        sellArea: '160',
        dealerCd: '06043',
        certificationNumber: 9445402,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4H5417073',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T15:17:55.521Z'
        }
      },
      sort: [
        1591634697003
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR7G5132137047262020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:44:49.932Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29791.00',
          mileage: '32304'
        },
        sellArea: '110',
        dealerCd: '04726',
        certificationNumber: 9479695,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR7G5132137',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8642',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T22:13:44.497Z'
        }
      },
      sort: [
        1591634689932
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS395135311712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:44:28.923Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29000.00',
          mileage: '23621'
        },
        sellArea: '160',
        dealerCd: '31171',
        certificationNumber: 9497813,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS395135',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:26:58.498Z'
        }
      },
      sort: [
        1591634668923
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP596782201142020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:44:23.895Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '19169'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9501396,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP596782',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:27:53.347Z'
        }
      },
      sort: [
        1591634663895
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC7HS799448042832020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:44:10.434Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18788.00',
          mileage: '80924'
        },
        sellArea: '110',
        dealerCd: '04283',
        certificationNumber: 9462039,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC7HS799448',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T18:11:07.745Z'
        }
      },
      sort: [
        1591634650434
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4JW720245060432020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:44:05.455Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26061.79',
          mileage: '21484'
        },
        sellArea: '160',
        dealerCd: '06043',
        certificationNumber: 9413532,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4JW720245',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T15:50:56.681Z'
        }
      },
      sort: [
        1591634645455
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV8HD060285041592020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:43:50.915Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23000.00',
          mileage: '23974'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9470475,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV8HD060285',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T14:34:27.754Z'
        }
      },
      sort: [
        1591634630915
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP691784040612020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:43:47.114Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '14956.00',
          mileage: '56495'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9498262,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP691784',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T22:17:03.081Z'
        }
      },
      sort: [
        1591634627114
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU826929060432020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:43:19.594Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20509.78',
          mileage: '6529'
        },
        sellArea: '160',
        dealerCd: '06043',
        certificationNumber: 9472924,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU826929',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T17:50:09.807Z'
        }
      },
      sort: [
        1591634599594
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP618229042832020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:43:08.815Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14650.00',
          mileage: '26918'
        },
        sellArea: '110',
        dealerCd: '04283',
        certificationNumber: 9474619,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP618229',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:27:11.007Z'
        }
      },
      sort: [
        1591634588815
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN8HX089110042012020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:43:07.881Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '67256'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9485282,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN8HX089110',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:33:33.697Z'
        }
      },
      sort: [
        1591634587881
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV6HD095007040332020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:42:43.622Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19988.00',
          mileage: '26417'
        },
        sellArea: '110',
        dealerCd: '04033',
        certificationNumber: 9487452,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV6HD095007',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:53:42.561Z'
        }
      },
      sort: [
        1591634563622
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2JJ239558190052020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:42:42.295Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22998.00',
          mileage: '22234'
        },
        sellArea: '800',
        dealerCd: '19005',
        certificationNumber: 9493741,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2JJ239558',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:12:20.153Z'
        }
      },
      sort: [
        1591634562295
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV2HW368671042832020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:42:09.776Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19288.00',
          mileage: '32049'
        },
        sellArea: '110',
        dealerCd: '04283',
        certificationNumber: 9474629,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV2HW368671',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:33:28.636Z'
        }
      },
      sort: [
        1591634529776
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK9LU908134042012020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:42:05.415Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '1940'
        },
        sellArea: '500',
        dealerCd: '04201',
        certificationNumber: 9495874,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK9LU908134',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:08:37.527Z'
        }
      },
      sort: [
        1591634525415
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE7HJ541097041592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:41:53.480Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17635.00',
          mileage: '20701'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9497304,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE7HJ541097',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:27:32.648Z'
        }
      },
      sort: [
        1591634513480
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW609819450532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:41:50.203Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19990.00',
          mileage: '66406'
        },
        sellArea: '800',
        dealerCd: '45053',
        certificationNumber: 9496109,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW609819',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:41:57.849Z'
        }
      },
      sort: [
        1591634510203
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV2KD500532480652020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:41:47.561Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26539.00',
          mileage: '26763'
        },
        sellArea: '210',
        dealerCd: '48065',
        certificationNumber: 9499745,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV2KD500532',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:51:24.421Z'
        }
      },
      sort: [
        1591634507561
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8HS803405041592020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:41:27.223Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '29978.00',
          mileage: '39963'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9481415,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8HS803405',
        model: {
          modelDescription: 'SIENNA LTD PREM FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5357',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T14:10:07.084Z'
        }
      },
      sort: [
        1591634487223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFPXH3057587190052020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:41:22.901Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23360.37',
          mileage: '22229'
        },
        sellArea: '800',
        dealerCd: '19005',
        certificationNumber: 9451245,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFPXH3057587',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T16:31:36.399Z'
        }
      },
      sort: [
        1591634482901
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21FB8KU002748042012020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:41:18.220Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25440.75',
          mileage: '65209'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9485575,
        makeCode: 'TOYOTA',
        vin: '4T1B21FB8KU002748',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR XSE',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3507',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:25:26.323Z'
        }
      },
      sort: [
        1591634478220
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4JU587472042832020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:41:14.381Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19800.00',
          mileage: '40539'
        },
        sellArea: '110',
        dealerCd: '04283',
        certificationNumber: 9442418,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4JU587472',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T17:31:47.884Z'
        }
      },
      sort: [
        1591634474381
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV4HD083322050542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:41:03.374Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25370.68',
          mileage: '55169'
        },
        sellArea: '800',
        dealerCd: '05054',
        certificationNumber: 9467250,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV4HD083322',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:17:18.572Z'
        }
      },
      sort: [
        1591634463374
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6GC641123041592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:41:00.315Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15085.00',
          mileage: '80958'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9475832,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6GC641123',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T15:08:14.526Z'
        }
      },
      sort: [
        1591634460315
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH9FS161594311162020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:54.634Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24750.00',
          mileage: '60403'
        },
        sellArea: '160',
        dealerCd: '31116',
        certificationNumber: 9483795,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH9FS161594',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:56:44.321Z'
        }
      },
      sort: [
        1591634454634
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU115642040612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:50.164Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20688.0000',
          mileage: '38637'
        },
        sellArea: '600',
        dealerCd: '04061',
        certificationNumber: 9427859,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU115642',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T23:59:12.708Z'
        }
      },
      sort: [
        1591634450164
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7JW730175040612020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:47.695Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23000.0000',
          mileage: '48298'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9488609,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7JW730175',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:44:46.382Z'
        }
      },
      sort: [
        1591634447695
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV0HD105420040612020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:45.231Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21531.0000',
          mileage: '31379'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9498284,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV0HD105420',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T22:40:47.815Z'
        }
      },
      sort: [
        1591634445231
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6HP696737040612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:40.927Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15534.0000',
          mileage: '34200'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9486300,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6HP696737',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:40:01.553Z'
        }
      },
      sort: [
        1591634440927
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV5HJ708767040612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:38.132Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19941.0000',
          mileage: '41575'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9482644,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV5HJ708767',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T23:54:50.196Z'
        }
      },
      sort: [
        1591634438132
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV9HJ716027040612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:35.735Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19634.0000',
          mileage: '34133'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9498349,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV9HJ716027',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T23:35:47.006Z'
        }
      },
      sort: [
        1591634435735
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4GP498379040612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:33.414Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13542.0000',
          mileage: '42520'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9485878,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4GP498379',
        model: {
          modelDescription: 'COROLLA LE PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1854',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:50:25.494Z'
        }
      },
      sort: [
        1591634433414
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP673922040612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:30.893Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15533.0000',
          mileage: '33337'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9487875,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP673922',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:27:35.907Z'
        }
      },
      sort: [
        1591634430893
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV8JD127120040612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:27.954Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25999.00',
          mileage: '19177'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9402262,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV8JD127120',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T00:23:37.488Z'
        }
      },
      sort: [
        1591634427954
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5G5398368050542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:27.689Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31901.00',
          mileage: '34267'
        },
        sellArea: '150',
        dealerCd: '05054',
        certificationNumber: 9492990,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5G5398368',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:21:50.284Z'
        }
      },
      sort: [
        1591634427689
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX8J1007966041592020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:23.329Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22940.00',
          mileage: '7210'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9465447,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX8J1007966',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T14:29:58.382Z'
        }
      },
      sort: [
        1591634423329
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6HM086224280202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:19.609Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26725.00',
          mileage: '38441'
        },
        sellArea: '170',
        dealerCd: '28020',
        certificationNumber: 9495158,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6HM086224',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:14:19.329Z'
        }
      },
      sort: [
        1591634419609
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU788638220302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:40:08.529Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17485.00',
          mileage: '21486'
        },
        sellArea: '600',
        dealerCd: '22030',
        certificationNumber: 9406761,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU788638',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T13:36:31.177Z'
        }
      },
      sort: [
        1591634408529
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV0KJ002346050542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:39:50.097Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25499.97',
          mileage: '11974'
        },
        sellArea: '120',
        dealerCd: '05054',
        certificationNumber: 9489983,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV0KJ002346',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T22:03:38.795Z'
        }
      },
      sort: [
        1591634390097
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU698795041592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:39:35.215Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18295.00',
          mileage: '19126'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9423926,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU698795',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T17:15:55.736Z'
        }
      },
      sort: [
        1591634375215
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F18FX470872050542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:39:16.073Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31902.00',
          mileage: '69363'
        },
        sellArea: '600',
        dealerCd: '05054',
        certificationNumber: 9496342,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F18FX470872',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:40:18.728Z'
        }
      },
      sort: [
        1591634356073
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA17H8700476041592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:38:59.875Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25835.00',
          mileage: '32184'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9415244,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA17H8700476',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T16:17:34.637Z'
        }
      },
      sort: [
        1591634339875
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMN1RFV1KD511772450532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:38:54.194Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31999.00',
          mileage: '10352'
        },
        sellArea: '800',
        dealerCd: '45053',
        certificationNumber: 9446648,
        makeCode: 'TOYOTA',
        vin: 'JTMN1RFV1KD511772',
        model: {
          modelDescription: 'RAV4 LIMITED AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T17:27:54.032Z'
        }
      },
      sort: [
        1591634334194
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KW058793050542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:38:44.268Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '27052'
        },
        sellArea: '600',
        dealerCd: '05054',
        certificationNumber: 9440604,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KW058793',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T14:43:07.832Z'
        }
      },
      sort: [
        1591634324268
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB6GU238355042012020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:38:22.109Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '56213'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9498338,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB6GU238355',
        model: {
          modelDescription: 'AVALON 4-DR TOURING',
          modelSeries: 'AVALON',
          modelNumber: '3548',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T23:22:59.052Z'
        }
      },
      sort: [
        1591634302109
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMF1RFV3KJ005933201162020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:37:58.754Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '27504'
        },
        sellArea: '170',
        dealerCd: '20116',
        certificationNumber: 9502680,
        makeCode: 'TOYOTA',
        vin: 'JTMF1RFV3KJ005933',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:33:57.269Z'
        }
      },
      sort: [
        1591634278754
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU373800180372020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:37:31.335Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '15777.00',
          mileage: '30947'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9502676,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU373800',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:33:15.041Z'
        }
      },
      sort: [
        1591634251335
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH7JS050648050542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:37:28.713Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '36900.00',
          mileage: '21706'
        },
        sellArea: '150',
        dealerCd: '05054',
        certificationNumber: 9497102,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH7JS050648',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:03:12.676Z'
        }
      },
      sort: [
        1591634248713
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU448877042012020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:37:21.374Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '50871'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9485542,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU448877',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:17:22.358Z'
        }
      },
      sort: [
        1591634241374
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH7GS305275140462020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:36:15.668Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28987.00',
          mileage: '43817'
        },
        sellArea: '230',
        dealerCd: '14046',
        certificationNumber: 9502634,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH7GS305275',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:21:46.461Z'
        }
      },
      sort: [
        1591634175668
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE0HJ548103042832020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:36:10.935Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15988.00',
          mileage: '13911'
        },
        sellArea: '110',
        dealerCd: '04283',
        certificationNumber: 9489666,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE0HJ548103',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:49:48.236Z'
        }
      },
      sort: [
        1591634170935
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH5GS341403290872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:36:06.792Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25998.00',
          mileage: '41307'
        },
        dealerCd: '29087',
        certificationNumber: 9301345,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH5GS341403',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-21T00:06:57.607Z'
        }
      },
      sort: [
        1591634166792
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV9KW046391311712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:35:28.135Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26245.00',
          mileage: '14398'
        },
        sellArea: '160',
        dealerCd: '31171',
        certificationNumber: 9400992,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV9KW046391',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T17:00:57.226Z'
        }
      },
      sort: [
        1591634128135
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC835143042012020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:35:20.414Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16900.00',
          mileage: '21414'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9475936,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC835143',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:13:13.628Z'
        }
      },
      sort: [
        1591634120414
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN0KX207203210722020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:34:46.494Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31890.00',
          mileage: '9130'
        },
        dealerCd: '21072',
        certificationNumber: 9367987,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN0KX207203',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T16:29:35.286Z'
        }
      },
      sort: [
        1591634086494
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU405348200932020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:34:30.694Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '16377'
        },
        sellArea: '170',
        dealerCd: '20093',
        certificationNumber: 9487919,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU405348',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:44:24.252Z'
        }
      },
      sort: [
        1591634070694
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC2HS848488200932020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:32:44.332Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23900.00',
          mileage: '23481'
        },
        sellArea: '170',
        dealerCd: '20093',
        certificationNumber: 9488019,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC2HS848488',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:08:21.462Z'
        }
      },
      sort: [
        1591633964332
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS390114290872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:32:01.913Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29500.00',
          mileage: '35875'
        },
        sellArea: '160',
        dealerCd: '29087',
        certificationNumber: 9402256,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS390114',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T00:19:17.450Z'
        }
      },
      sort: [
        1591633921913
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU256659450532020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:31:55.673Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '23177'
        },
        sellArea: '500',
        dealerCd: '45053',
        certificationNumber: 9452024,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU256659',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T14:30:28.829Z'
        }
      },
      sort: [
        1591633915673
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU425726311832020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:30:43.804Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15990.0000',
          mileage: '28004'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9484255,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU425726',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:50:06.339Z'
        }
      },
      sort: [
        1591633843804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU353166311832020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:30:41.004Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '14992.0000',
          mileage: '49114'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9419823,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU353166',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T15:03:56.429Z'
        }
      },
      sort: [
        1591633841004
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU667429311832020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:30:38.803Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '14493.00',
          mileage: '62554'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9408496,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU667429',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T16:56:34.332Z'
        }
      },
      sort: [
        1591633838803
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU723579450532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:30:29.544Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '37507'
        },
        sellArea: '500',
        dealerCd: '45053',
        certificationNumber: 9496123,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU723579',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:47:22.796Z'
        }
      },
      sort: [
        1591633829544
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3HM106915150562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:28:34.944Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30747.00',
          mileage: '28830'
        },
        sellArea: '230',
        dealerCd: '15056',
        certificationNumber: 9498918,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3HM106915',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:34:08.597Z'
        }
      },
      sort: [
        1591633714944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5HW679186180332020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:28:33.792Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20495.00',
          mileage: '57935'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9501462,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV5HW679186',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:23:58.632Z'
        }
      },
      sort: [
        1591633713792
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV0HW683529120902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:28:21.493Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23888.00',
          mileage: '14375'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9499463,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV0HW683529',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:14:31.805Z'
        }
      },
      sort: [
        1591633701493
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH6JS038328121552020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:27:56.011Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '38000.00',
          mileage: '59101'
        },
        sellArea: '210',
        dealerCd: '12155',
        certificationNumber: 9464853,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH6JS038328',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T20:36:46.749Z'
        }
      },
      sort: [
        1591633676011
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV2JW475485045282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:26:56.572Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '47691'
        },
        sellArea: '600',
        dealerCd: '04528',
        certificationNumber: 9483645,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV2JW475485',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:47:13.217Z'
        }
      },
      sort: [
        1591633616572
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE6LJ019809120902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:26:32.552Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20496.00',
          mileage: '1060'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9438826,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE6LJ019809',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T19:32:40.776Z'
        }
      },
      sort: [
        1591633592552
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F15GX535947045282020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:26:31.622Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '32149.00',
          mileage: '47908'
        },
        sellArea: '110',
        dealerCd: '04528',
        certificationNumber: 9477572,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F15GX535947',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T16:33:00.361Z'
        }
      },
      sort: [
        1591633591622
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV6KW069377311712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:26:13.563Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31580.72',
          mileage: '14672'
        },
        sellArea: '160',
        dealerCd: '31171',
        certificationNumber: 9462889,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV6KW069377',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T16:06:37.101Z'
        }
      },
      sort: [
        1591633573563
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU036462045282020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:25:59.162Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '51205'
        },
        sellArea: '120',
        dealerCd: '04528',
        certificationNumber: 9432400,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU036462',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T21:27:49.226Z'
        }
      },
      sort: [
        1591633559162
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2HW573827150562020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:25:54.363Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '42536'
        },
        sellArea: '210',
        dealerCd: '15056',
        certificationNumber: 9463016,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2HW573827',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T17:02:09.789Z'
        }
      },
      sort: [
        1591633554363
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV6HD097765490312020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:25:11.142Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26954.00',
          mileage: '36096'
        },
        sellArea: '130',
        dealerCd: '49031',
        certificationNumber: 9497033,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV6HD097765',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:27:37.525Z'
        }
      },
      sort: [
        1591633511142
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3JJ194615371552020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:24:53.442Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21588.00',
          mileage: '25088'
        },
        sellArea: '800',
        dealerCd: '37155',
        certificationNumber: 9484994,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3JJ194615',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T12:34:22.518Z'
        }
      },
      sort: [
        1591633493442
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU749529210732020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:24:36.469Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '23818'
        },
        sellArea: '220',
        dealerCd: '21073',
        certificationNumber: 9472318,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU749529',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T12:31:34.455Z'
        }
      },
      sort: [
        1591633476469
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE6LJ025217371552020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:24:01.101Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17888.00',
          mileage: '15448'
        },
        sellArea: '500',
        dealerCd: '37155',
        certificationNumber: 9474123,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE6LJ025217',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T13:08:29.619Z'
        }
      },
      sort: [
        1591633441101
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV2HJ707895120902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:22:48.331Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18226.00',
          mileage: '41816'
        },
        sellArea: '220',
        dealerCd: '12090',
        certificationNumber: 9494290,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV2HJ707895',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:10:11.444Z'
        }
      },
      sort: [
        1591633368331
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0HW654438180372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:21:22.820Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16999.00',
          mileage: '64382'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9502626,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0HW654438',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:19:28.238Z'
        }
      },
      sort: [
        1591633282820
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5FC463526220302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:20:09.216Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14490.00',
          mileage: '11680'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9502611,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5FC463526',
        model: {
          modelDescription: 'COROLLA LE PREMIUM',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:16:04.935Z'
        }
      },
      sort: [
        1591633209216
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFVXKW008578120902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:20:05.178Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22988.00',
          mileage: '29632'
        },
        sellArea: '600',
        dealerCd: '12090',
        certificationNumber: 9443895,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFVXKW008578',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T16:42:12.656Z'
        }
      },
      sort: [
        1591633205178
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU428158043442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:19:56.578Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17588.00',
          mileage: '15822'
        },
        sellArea: '110',
        dealerCd: '04344',
        certificationNumber: 9483810,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU428158',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:03:56.939Z'
        }
      },
      sort: [
        1591633196578
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFHXHS525379043442020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:18:22.539Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '27804.00',
          mileage: '43608'
        },
        sellArea: '110',
        dealerCd: '04344',
        certificationNumber: 9420556,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFHXHS525379',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T20:28:26.827Z'
        }
      },
      sort: [
        1591633102539
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ170595340932020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:18:21.611Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20181.00',
          mileage: '24773'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9475130,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ170595',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T21:50:22.906Z'
        }
      },
      sort: [
        1591633101611
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC893700470212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:18:10.452Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '44095'
        },
        sellArea: '800',
        dealerCd: '47021',
        certificationNumber: 9492961,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC893700',
        model: {
          modelDescription: 'COROLLA XLE',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:17:18.021Z'
        }
      },
      sort: [
        1591633090452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F18HX662306311012020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:18:07.793Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '37995.00',
          mileage: '26282'
        },
        sellArea: '160',
        dealerCd: '31101',
        certificationNumber: 9497111,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F18HX662306',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:07:17.556Z'
        }
      },
      sort: [
        1591633087793
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2HW642595180372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:17:57.730Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20790.50',
          mileage: '31649'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9474990,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2HW642595',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:32:22.295Z'
        }
      },
      sort: [
        1591633077730
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS388816120902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:17:33.251Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27997.00',
          mileage: '37392'
        },
        sellArea: '160',
        certificationNumber: 9494343,
        dealerCd: '12090',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        vin: '5TDJZRFH5HS388816',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:30:30.276Z'
        }
      },
      sort: [
        1591633053251
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW595872180372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:16:22.771Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24478.00',
          mileage: '18356'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9502604,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW595872',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:14:43.436Z'
        }
      },
      sort: [
        1591632982771
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS513535120902020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:15:24.951Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '25988.00',
          mileage: '40262'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9421394,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS513535',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T14:35:02.225Z'
        }
      },
      sort: [
        1591632924951
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9EU398150041362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:14:54.196Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '12688.0000',
          mileage: '78001'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9452410,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9EU398150',
        model: {
          modelDescription: 'CAMRY 2014.5 SE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T18:31:30.815Z'
        }
      },
      sort: [
        1591632894196
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV4HW387691041362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:14:51.177Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20482.0000',
          mileage: '27599'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9461352,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV4HW387691',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T20:44:13.303Z'
        }
      },
      sort: [
        1591632891177
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE9JJ568002041362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:14:48.565Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16488.0000',
          mileage: '12030'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9461350,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE9JJ568002',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T20:43:17.820Z'
        }
      },
      sort: [
        1591632888565
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFHXHS031075041362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:14:46.116Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24454.0000',
          mileage: '17945'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9494473,
        makeCode: 'TOYOTA',
        vin: '5TDZARFHXHS031075',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:27:17.567Z'
        }
      },
      sort: [
        1591632886116
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CNXHM042389041362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:14:43.496Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27488.00',
          mileage: '63156'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9500145,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CNXHM042389',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:08:18.906Z'
        }
      },
      sort: [
        1591632883496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F1XJX077230180332020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:14:06.875Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30295.00',
          mileage: '34329'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9500493,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F1XJX077230',
        model: {
          modelDescription: 'TUNDRA 4X4 SR DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8339',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:36:49.093Z'
        }
      },
      sort: [
        1591632846875
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F10JX732405311012020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:13:50.620Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '39600.00',
          mileage: '17472'
        },
        sellArea: '160',
        dealerCd: '31101',
        certificationNumber: 9497065,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F10JX732405',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:40:05.947Z'
        }
      },
      sort: [
        1591632830620
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4HJ119609180372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:12:35.950Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17999.00',
          mileage: '41298'
        },
        dealerCd: '18037',
        certificationNumber: 9383586,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4HJ119609',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T15:12:51.218Z'
        }
      },
      sort: [
        1591632755950
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU241869121022020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:12:14.951Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18384.10',
          mileage: '20405'
        },
        sellArea: '500',
        dealerCd: '12102',
        certificationNumber: 9486478,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU241869',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T01:01:44.045Z'
        }
      },
      sort: [
        1591632734951
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEM5F19HX121460043532020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:12:04.452Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '29686.00',
          mileage: '30423'
        },
        sellArea: '110',
        dealerCd: '04353',
        certificationNumber: 9483818,
        makeCode: 'TOYOTA',
        vin: '5TFEM5F19HX121460',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8259',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:06:24.349Z'
        }
      },
      sort: [
        1591632724452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU731265311912020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:12:04.115Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21655.00',
          mileage: '16755'
        },
        sellArea: '160',
        dealerCd: '31191',
        certificationNumber: 9429831,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU731265',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T18:12:49.477Z'
        }
      },
      sort: [
        1591632724115
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5JU645959041362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:57.065Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19488.0000',
          mileage: '21770'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9459306,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5JU645959',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T23:53:16.158Z'
        }
      },
      sort: [
        1591632717065
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK0J3015059310192020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:56.310Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19800.00',
          mileage: '48984'
        },
        sellArea: '160',
        dealerCd: '31019',
        certificationNumber: 9416666,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK0J3015059',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T15:09:09.076Z'
        }
      },
      sort: [
        1591632716310
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV8GD056333041362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:54.475Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21277.5600',
          mileage: '25209'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9459304,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV8GD056333',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T23:52:03.758Z'
        }
      },
      sort: [
        1591632714475
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5KP948713041362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:51.134Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15788.0000',
          mileage: '14464'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9450427,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5KP948713',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T19:25:56.723Z'
        }
      },
      sort: [
        1591632711134
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV3KW015274041362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:48.674Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21788.0000',
          mileage: '27957'
        },
        sellArea: '600',
        dealerCd: '04136',
        certificationNumber: 9433069,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV3KW015274',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T17:43:39.739Z'
        }
      },
      sort: [
        1591632708674
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU200089041362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:45.895Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23582.0000',
          mileage: '30757'
        },
        sellArea: '600',
        dealerCd: '04136',
        certificationNumber: 9433120,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU200089',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T18:09:45.405Z'
        }
      },
      sort: [
        1591632705895
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS454302180372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:44.215Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27423.00',
          mileage: '46445'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9474254,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS454302',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:47:56.791Z'
        }
      },
      sort: [
        1591632704215
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP675902041362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:43.451Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18207.00',
          mileage: '20090'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9450465,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP675902',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T19:59:54.938Z'
        }
      },
      sort: [
        1591632703451
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC9HS775300121382020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:26.230Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '35501'
        },
        sellArea: '210',
        dealerCd: '12138',
        certificationNumber: 9458720,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC9HS775300',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T17:32:39.883Z'
        }
      },
      sort: [
        1591632686230
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS386705311912020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:11:14.112Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29254.00',
          mileage: '31890'
        },
        sellArea: '160',
        dealerCd: '31191',
        certificationNumber: 9422688,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS386705',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T18:38:20.981Z'
        }
      },
      sort: [
        1591632674112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV8GW467231310782020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:10:46.790Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21963.00',
          mileage: '39125'
        },
        dealerCd: '31078',
        certificationNumber: 9154915,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV8GW467231',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-09-17T13:44:08.922Z'
        }
      },
      sort: [
        1591632646790
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HD211471180372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:10:39.631Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20960.00',
          mileage: '32471'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9474981,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HD211471',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:27:46.656Z'
        }
      },
      sort: [
        1591632639631
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP673624047132020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:10:29.111Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17559.00',
          mileage: '8627'
        },
        sellArea: '110',
        dealerCd: '04713',
        certificationNumber: 9500319,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP673624',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:59:55.146Z'
        }
      },
      sort: [
        1591632629111
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV1HW354571047132020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:10:00.790Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20268.00',
          mileage: '32562'
        },
        sellArea: '110',
        dealerCd: '04713',
        certificationNumber: 9462369,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV1HW354571',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-10T18:09:57.944Z'
        }
      },
      sort: [
        1591632600790
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV4HY159319045362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:09:51.030Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12005.00',
          mileage: '32774'
        },
        sellArea: '110',
        dealerCd: '04536',
        certificationNumber: 9500377,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV4HY159319',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:31:09.867Z'
        }
      },
      sort: [
        1591632591030
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP690803047132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:09:27.589Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16279.00',
          mileage: '23978'
        },
        sellArea: '120',
        dealerCd: '04713',
        certificationNumber: 9490978,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP690803',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T23:45:25.431Z'
        }
      },
      sort: [
        1591632567589
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU755373310192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:58.071Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '5019'
        },
        sellArea: '160',
        dealerCd: '31019',
        certificationNumber: 9474213,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU755373',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:28:28.420Z'
        }
      },
      sort: [
        1591632538071
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV4HD074636047132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:55.304Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24941.00',
          mileage: '28354'
        },
        sellArea: '110',
        dealerCd: '04713',
        certificationNumber: 9487506,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV4HD074636',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:13:49.014Z'
        }
      },
      sort: [
        1591632535304
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU344002041362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:49.693Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16488.0000',
          mileage: '37108'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9399665,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU344002',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T21:24:45.233Z'
        }
      },
      sort: [
        1591632529693
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2HS838831041362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:47.193Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25588.0000',
          mileage: '31661'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9465100,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2HS838831',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T00:19:45.711Z'
        }
      },
      sort: [
        1591632527193
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU3H3026426041362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:43.292Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18987.0000',
          mileage: '37751'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9411846,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU3H3026426',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T21:45:15.362Z'
        }
      },
      sort: [
        1591632523292
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8GU188416041362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:40.592Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15788.0000',
          mileage: '32097'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9411857,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8GU188416',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T21:49:35.804Z'
        }
      },
      sort: [
        1591632520592
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP687080041362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:37.853Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16225.8800',
          mileage: '27607'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9494022,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP687080',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:32:49.639Z'
        }
      },
      sort: [
        1591632517853
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F12HX638681041362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:35.374Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '36187.0000',
          mileage: '35960'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9500084,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F12HX638681',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:50:29.639Z'
        }
      },
      sort: [
        1591632515374
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE6LJ030739041362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:32.910Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20387.0000',
          mileage: '13005'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9399849,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE6LJ030739',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T22:19:35.476Z'
        }
      },
      sort: [
        1591632512910
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV1HJ140287120902020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:32.853Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '16970.00',
          mileage: '35561'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9453511,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV1HJ140287',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T17:20:56.181Z'
        }
      },
      sort: [
        1591632512853
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU421139041362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:30.333Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18182.0000',
          mileage: '35379'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9399859,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU421139',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T22:21:01.197Z'
        }
      },
      sort: [
        1591632510333
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDBVRBD5HA002072041362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:27.990Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15988.0000',
          mileage: '45328'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9494008,
        makeCode: 'TOYOTA',
        vin: 'JTDBVRBD5HA002072',
        model: {
          modelDescription: 'MIRAI 4-DOOR SEDAN',
          modelSeries: 'MIRAI',
          modelNumber: '3000',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:27:43.594Z'
        }
      },
      sort: [
        1591632507990
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH7GS019657041362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:25.390Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23583.0000',
          mileage: '41152'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9494040,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH7GS019657',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:39:32.144Z'
        }
      },
      sort: [
        1591632505390
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU286197041362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:22.970Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18787.00',
          mileage: '33349'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9494069,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU286197',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:51:33.083Z'
        }
      },
      sort: [
        1591632502970
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH3FS081465047132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:08:12.591Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25946.00',
          mileage: '78984'
        },
        sellArea: '150',
        dealerCd: '04713',
        certificationNumber: 9476449,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH3FS081465',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:53:15.870Z'
        }
      },
      sort: [
        1591632492591
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU230940121022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:07:43.150Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18690.00',
          mileage: '38976'
        },
        sellArea: '600',
        dealerCd: '12102',
        certificationNumber: 9502561,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU230940',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T16:02:50.317Z'
        }
      },
      sort: [
        1591632463150
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRY5F19FX188305047132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:07:35.210Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27516.00',
          mileage: '43649'
        },
        sellArea: '110',
        dealerCd: '04713',
        certificationNumber: 9487618,
        makeCode: 'TOYOTA',
        vin: '5TFRY5F19FX188305',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DBL CAB 5.7L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8241',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:53:56.464Z'
        }
      },
      sort: [
        1591632455210
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F1XGX557318121262020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:07:09.552Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '30800.00',
          mileage: '50355'
        },
        dealerCd: '12126',
        certificationNumber: 9342091,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F1XGX557318',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-17T17:30:44.945Z'
        }
      },
      sort: [
        1591632429552
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC8HS164330047132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:06:59.932Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31416.00',
          mileage: '38535'
        },
        sellArea: '110',
        dealerCd: '04713',
        certificationNumber: 9472048,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC8HS164330',
        model: {
          modelDescription: 'SIENNA LTD PREM AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5387',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T00:55:47.277Z'
        }
      },
      sort: [
        1591632419932
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU775353121122020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:05:57.212Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '17400.00',
          mileage: '28214'
        },
        sellArea: '500',
        dealerCd: '12112',
        certificationNumber: 9473519,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU775353',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T21:43:25.046Z'
        }
      },
      sort: [
        1591632357212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV3HW325041045362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:05:44.593Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '34443'
        },
        sellArea: '110',
        dealerCd: '04536',
        certificationNumber: 9478350,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV3HW325041',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:07:15.641Z'
        }
      },
      sort: [
        1591632344593
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F14LX880655121122020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:05:31.492Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '36350.00',
          mileage: '8146'
        },
        sellArea: '210',
        dealerCd: '12112',
        certificationNumber: 9401027,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F14LX880655',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T17:09:38.274Z'
        }
      },
      sort: [
        1591632331492
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV5HD120856460502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:05:15.951Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26643.00',
          mileage: '21657'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9481631,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV5HD120856',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:06:51.451Z'
        }
      },
      sort: [
        1591632315951
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW616403480272020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:03:43.550Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23195.00',
          mileage: '35101'
        },
        sellArea: '220',
        dealerCd: '48027',
        certificationNumber: 9486212,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW616403',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:54:28.193Z'
        }
      },
      sort: [
        1591632223550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2JD240708460502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:02:58.132Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24100.00',
          mileage: '6185'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9476465,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2JD240708',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:58:44.914Z'
        }
      },
      sort: [
        1591632178132
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZRFHXHS208213045362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:02:57.309Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31024.00',
          mileage: '38805'
        },
        sellArea: '110',
        dealerCd: '04536',
        certificationNumber: 9488294,
        makeCode: 'TOYOTA',
        vin: '5TDYZRFHXHS208213',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6954',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:42:15.182Z'
        }
      },
      sort: [
        1591632177309
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW543846201282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:02:35.689Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19991.00',
          mileage: '46863'
        },
        dealerCd: '20128',
        certificationNumber: 9337694,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW543846',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-15T16:42:29.905Z'
        }
      },
      sort: [
        1591632155689
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV2HW349016041672020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:01:42.410Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20900.00',
          mileage: '43281'
        },
        dealerCd: '04167',
        certificationNumber: 9391571,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV2HW349016',
        model: {
          modelDescription: 'RAV4 Platinum FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4474',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T23:45:05.718Z'
        }
      },
      sort: [
        1591632102410
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS394241460502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:00:56.589Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28773.52',
          mileage: '36011'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9492699,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS394241',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:15:06.027Z'
        }
      },
      sort: [
        1591632056589
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH0HS417210260312020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:00:42.049Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24395.00',
          mileage: '43792'
        },
        sellArea: '210',
        dealerCd: '26031',
        certificationNumber: 9486336,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH0HS417210',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:01:47.115Z'
        }
      },
      sort: [
        1591632042049
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HJ709214130442020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:00:36.209Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18390.00',
          mileage: '41420'
        },
        sellArea: '220',
        dealerCd: '13044',
        certificationNumber: 9445401,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HJ709214',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T15:13:59.517Z'
        }
      },
      sort: [
        1591632036209
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE2LP015487410412020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:00:25.229Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18472.00',
          mileage: '21399'
        },
        sellArea: '220',
        dealerCd: '41041',
        certificationNumber: 9502526,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE2LP015487',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T15:54:57.533Z'
        }
      },
      sort: [
        1591632025229
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN5HM028285041672020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:00:24.332Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '24645.00',
          mileage: '67654'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9461422,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN5HM028285',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T21:40:48.928Z'
        }
      },
      sort: [
        1591632024332
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC4HS791026311312020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T16:00:17.269Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25499.00',
          mileage: '50666'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9472451,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC4HS791026',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T14:15:37.782Z'
        }
      },
      sort: [
        1591632017269
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU3H3055215041672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:58:27.489Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17782.00',
          mileage: '33607'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9451808,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU3H3055215',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T00:13:42.913Z'
        }
      },
      sort: [
        1591631907489
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU291595121262020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:58:22.729Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '23250.00',
          mileage: '12253'
        },
        sellArea: '210',
        dealerCd: '12126',
        certificationNumber: 9460653,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU291595',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T13:31:07.257Z'
        }
      },
      sort: [
        1591631902729
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN9HX102740045362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:58:14.450Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27324.00',
          mileage: '14230'
        },
        sellArea: '110',
        dealerCd: '04536',
        certificationNumber: 9494141,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN9HX102740',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:24:18.676Z'
        }
      },
      sort: [
        1591631894450
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU547651130692020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:58:11.668Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19898.17',
          mileage: '56307'
        },
        sellArea: '210',
        dealerCd: '13069',
        certificationNumber: 9499217,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU547651',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:06:02.294Z'
        }
      },
      sort: [
        1591631891668
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREVXHD120917470232020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:57:28.887Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24422.00',
          mileage: '37110'
        },
        sellArea: '800',
        dealerCd: '47023',
        certificationNumber: 9498784,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREVXHD120917',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:35:21.781Z'
        }
      },
      sort: [
        1591631848887
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU793146041672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:57:18.249Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17650.00',
          mileage: '26298'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9467227,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU793146',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:07:52.442Z'
        }
      },
      sort: [
        1591631838249
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH7HS408416410832020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:56:32.748Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28999.00',
          mileage: '32691'
        },
        sellArea: '220',
        dealerCd: '41083',
        certificationNumber: 9483352,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH7HS408416',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:05:25.692Z'
        }
      },
      sort: [
        1591631792748
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7JU112754041672020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:56:13.167Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '24204'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9459899,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7JU112754',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:27:20.294Z'
        }
      },
      sort: [
        1591631773167
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE7LJ029762410832020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:55:37.408Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19600.00',
          mileage: '3615'
        },
        sellArea: '220',
        dealerCd: '41083',
        certificationNumber: 9467299,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE7LJ029762',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:47:28.151Z'
        }
      },
      sort: [
        1591631737408
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8KS008717130692020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:55:12.607Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27681.43',
          mileage: '31604'
        },
        sellArea: '210',
        dealerCd: '13069',
        certificationNumber: 9470826,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8KS008717',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T16:39:03.912Z'
        }
      },
      sort: [
        1591631712607
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU641926410832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:54:45.146Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19095.00',
          mileage: '17916'
        },
        sellArea: '220',
        dealerCd: '41083',
        certificationNumber: 9475035,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU641926',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:56:32.825Z'
        }
      },
      sort: [
        1591631685146
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2KM261172130532020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:53:56.767Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29990.00',
          mileage: '3759'
        },
        dealerCd: '13053',
        certificationNumber: 9191976,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2KM261172',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-10T13:17:57.839Z'
        }
      },
      sort: [
        1591631636767
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5J5549846410832020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:53:49.208Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '33394.72',
          mileage: '37781'
        },
        sellArea: '220',
        dealerCd: '41083',
        certificationNumber: 9483370,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5J5549846',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:09:34.043Z'
        }
      },
      sort: [
        1591631629208
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB2GU200377210432020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:53:42.088Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '78335'
        },
        sellArea: '220',
        dealerCd: '21043',
        certificationNumber: 9502136,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB2GU200377',
        model: {
          modelDescription: 'AVALON 4-DR LIMITED',
          modelSeries: 'AVALON',
          modelNumber: '3554',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T13:29:15.504Z'
        }
      },
      sort: [
        1591631622088
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4LM338626110362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:53:38.268Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32991.00',
          mileage: '18'
        },
        sellArea: '130',
        dealerCd: '11036',
        certificationNumber: 9499519,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4LM338626',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:31:16.735Z'
        }
      },
      sort: [
        1591631618268
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9HW647805410832020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:53:06.290Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21790.00',
          mileage: '47860'
        },
        sellArea: '220',
        dealerCd: '41083',
        certificationNumber: 9467309,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9HW647805',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:51:41.571Z'
        }
      },
      sort: [
        1591631586290
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8LM329847110362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:52:47.048Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32991.00',
          mileage: '351'
        },
        sellArea: '130',
        dealerCd: '11036',
        certificationNumber: 9499548,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8LM329847',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:41:04.482Z'
        }
      },
      sort: [
        1591631567048
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU026076220532020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:52:05.648Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21595.00',
          mileage: '14468'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9482306,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU026076',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:17:47.785Z'
        }
      },
      sort: [
        1591631525648
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV9JW515125020482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:51:02.706Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '19735'
        },
        sellArea: '150',
        dealerCd: '02048',
        certificationNumber: 9496301,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV9JW515125',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:15:16.372Z'
        }
      },
      sort: [
        1591631462706
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE9HJ544177220532020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:50:58.287Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16162.00',
          mileage: '19103'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9469915,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE9HJ544177',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T18:22:57.347Z'
        }
      },
      sort: [
        1591631458287
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F15LX876860410832020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:50:57.585Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '45500.00',
          mileage: '14465'
        },
        sellArea: '220',
        dealerCd: '41083',
        certificationNumber: 9485850,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F15LX876860',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:43:51.754Z'
        }
      },
      sort: [
        1591631457585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F10JX692423047392020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:50:17.535Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '36998.00',
          mileage: '23421'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9501716,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F10JX692423',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T22:16:49.623Z'
        }
      },
      sort: [
        1591631417535
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8K5707467110362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:50:06.044Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '42991.00',
          mileage: '2809'
        },
        sellArea: '130',
        dealerCd: '11036',
        certificationNumber: 9498274,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8K5707467',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T22:26:41.006Z'
        }
      },
      sort: [
        1591631406044
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE8HJ543053201012020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:50:00.525Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15764.00',
          mileage: '19337'
        },
        sellArea: '170',
        dealerCd: '20101',
        certificationNumber: 9479195,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE8HJ543053',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T17:13:46.381Z'
        }
      },
      sort: [
        1591631400525
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW600617050112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:49:53.204Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23150.00',
          mileage: '52792'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9495792,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW600617',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:39:24.234Z'
        }
      },
      sort: [
        1591631393204
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH2GS504458220532020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:49:45.524Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24602.00',
          mileage: '62348'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9445576,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH2GS504458',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T19:19:01.073Z'
        }
      },
      sort: [
        1591631385524
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2KS008065130692020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:49:45.109Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26850.00',
          mileage: '30802'
        },
        sellArea: '210',
        dealerCd: '13069',
        certificationNumber: 9440958,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2KS008065',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T20:41:58.828Z'
        }
      },
      sort: [
        1591631385109
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ713329020482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:49:26.107Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18095.00',
          mileage: '75713'
        },
        sellArea: '150',
        dealerCd: '02048',
        certificationNumber: 9464870,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ713329',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T20:48:54.457Z'
        }
      },
      sort: [
        1591631366107
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6F5260790050112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:49:24.467Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27000.00',
          mileage: '43411'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9483058,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6F5260790',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T13:02:09.103Z'
        }
      },
      sort: [
        1591631364467
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEM5F12HX119016047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:49:15.664Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '33983.00',
          mileage: '25469'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9463119,
        makeCode: 'TOYOTA',
        vin: '5TFEM5F12HX119016',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8259',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T18:01:59.218Z'
        }
      },
      sort: [
        1591631355664
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21HK7KU010112050112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:48:47.504Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24693.00',
          mileage: '22580'
        },
        sellArea: '110',
        dealerCd: '05011',
        certificationNumber: 9462295,
        makeCode: 'TOYOTA',
        vin: '4T1B21HK7KU010112',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-10T13:24:35.481Z'
        }
      },
      sort: [
        1591631327504
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC6KS011342130692020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:48:43.323Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26553.43',
          mileage: '35531'
        },
        sellArea: '210',
        dealerCd: '13069',
        certificationNumber: 9441968,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC6KS011342',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T19:39:18.231Z'
        }
      },
      sort: [
        1591631323323
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU205313042822020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:48:39.124Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '35560'
        },
        sellArea: '500',
        dealerCd: '04282',
        certificationNumber: 9431284,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU205313',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T21:56:03.044Z'
        }
      },
      sort: [
        1591631319124
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5JU598528311662020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:48:35.624Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '20613.00',
          mileage: '10193'
        },
        sellArea: '160',
        dealerCd: '31166',
        certificationNumber: 9430082,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5JU598528',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-22T16:36:47.277Z'
        }
      },
      sort: [
        1591631315624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN2HX100893047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:48:25.564Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '24793.00',
          mileage: '65277'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9501636,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN2HX100893',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:08:21.604Z'
        }
      },
      sort: [
        1591631305564
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU809331042822020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:48:16.663Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21498.00',
          mileage: '18297'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9494135,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU809331',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:17:57.532Z'
        }
      },
      sort: [
        1591631296663
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU434596480652020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:48:16.408Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14947.17',
          mileage: '41597'
        },
        sellArea: '210',
        dealerCd: '48065',
        certificationNumber: 9458834,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU434596',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T18:24:43.480Z'
        }
      },
      sort: [
        1591631296408
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8HW605763220532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:48:14.544Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '32650'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9397696,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8HW605763',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T20:52:41.794Z'
        }
      },
      sort: [
        1591631294544
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2H5447074050112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:48:09.084Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30292.00',
          mileage: '42784'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9449419,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2H5447074',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-25T16:15:10.632Z'
        }
      },
      sort: [
        1591631289084
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMBZ5DN4HM009519047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:47:33.162Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '27516.49',
          mileage: '32635'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9488311,
        makeCode: 'TOYOTA',
        vin: '3TMBZ5DN4HM009519',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DBL CAB LONG BED',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7170',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:46:11.309Z'
        }
      },
      sort: [
        1591631253162
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7K5655040050112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:47:22.944Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31891.00',
          mileage: '35567'
        },
        sellArea: '500',
        dealerCd: '05011',
        certificationNumber: 9485520,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7K5655040',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:11:30.593Z'
        }
      },
      sort: [
        1591631242944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB3HU250092020482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:47:06.543Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21086.86',
          mileage: '32329'
        },
        sellArea: '150',
        dealerCd: '02048',
        certificationNumber: 9497953,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB3HU250092',
        model: {
          modelDescription: 'AVALON 4-DR TOURING',
          modelSeries: 'AVALON',
          modelNumber: '3548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:02:46.884Z'
        }
      },
      sort: [
        1591631226543
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5K5646692050112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:46:52.984Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29791.00',
          mileage: '35688'
        },
        sellArea: '500',
        dealerCd: '05011',
        certificationNumber: 9485094,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5K5646692',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:06:53.244Z'
        }
      },
      sort: [
        1591631212984
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA15G8701821047392020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:46:28.763Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15998.00',
          mileage: '55334'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9438709,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA15G8701821',
        model: {
          modelDescription: 'SCION FR-S 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T17:24:29.695Z'
        }
      },
      sort: [
        1591631188763
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1FK1EU552474050112020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:46:11.402Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '16990.00',
          mileage: '27474'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9483031,
        makeCode: 'TOYOTA',
        vin: '4T1BK1FK1EU552474',
        model: {
          modelDescription: 'CAMRY 2014.5 SE V6 Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T12:48:11.548Z'
        }
      },
      sort: [
        1591631171402
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW577840290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:45:51.162Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18000.0000',
          mileage: '35229'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9467257,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW577840',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:20:23.974Z'
        }
      },
      sort: [
        1591631151162
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE6LJ026898290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:45:48.922Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16602.0000',
          mileage: '15378'
        },
        sellArea: '500',
        dealerCd: '29025',
        certificationNumber: 9482268,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE6LJ026898',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:01:45.900Z'
        }
      },
      sort: [
        1591631148922
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV0GJ099416290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:45:46.203Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19500.0000',
          mileage: '39790'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9479417,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV0GJ099416',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T19:46:00.810Z'
        }
      },
      sort: [
        1591631146203
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMY1RFV8KD511748290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:45:43.083Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27995.0000',
          mileage: '11059'
        },
        sellArea: '190',
        dealerCd: '29025',
        certificationNumber: 9479444,
        makeCode: 'TOYOTA',
        vin: 'JTMY1RFV8KD511748',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T19:56:57.493Z'
        }
      },
      sort: [
        1591631143083
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV1HJ136674290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:45:40.443Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17400.00',
          mileage: '31482'
        },
        sellArea: '150',
        dealerCd: '29025',
        certificationNumber: 9482276,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV1HJ136674',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:04:52.802Z'
        }
      },
      sort: [
        1591631140443
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV1JD115861047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:45:26.659Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '26288.00',
          mileage: '23210'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9463131,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV1JD115861',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T18:07:53.725Z'
        }
      },
      sort: [
        1591631126659
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV7LW057822020482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:44:56.302Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28692.00',
          mileage: '5330'
        },
        sellArea: '150',
        dealerCd: '02048',
        certificationNumber: 9495643,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV7LW057822',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:01:01.185Z'
        }
      },
      sort: [
        1591631096302
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6H5438975311922020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:44:17.763Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '35995.00',
          mileage: '21871'
        },
        sellArea: '160',
        dealerCd: '31192',
        certificationNumber: 9411087,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6H5438975',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T14:43:05.600Z'
        }
      },
      sort: [
        1591631057763
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2JW751077047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:44:11.421Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25998.00',
          mileage: '13872'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9474531,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2JW751077',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:46:13.954Z'
        }
      },
      sort: [
        1591631051421
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREVXHD105988047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:43:03.295Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23093.00',
          mileage: '24845'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9488290,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREVXHD105988',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:40:40.520Z'
        }
      },
      sort: [
        1591630983295
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE0K3060724047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:41:57.541Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18998.00',
          mileage: '4838'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9500090,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE0K3060724',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:52:22.517Z'
        }
      },
      sort: [
        1591630917541
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH4FS161509311662020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:41:46.281Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '43562'
        },
        sellArea: '160',
        dealerCd: '31166',
        certificationNumber: 9456803,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH4FS161509',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T17:26:30.964Z'
        }
      },
      sort: [
        1591630906281
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP692401047392020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:41:05.841Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17093.00',
          mileage: '38346'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9497284,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP692401',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:20:00.507Z'
        }
      },
      sort: [
        1591630865841
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE5LJ036922047392020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:40:02.580Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21604.18',
          mileage: '10842'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9500083,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE5LJ036922',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:49:46.303Z'
        }
      },
      sort: [
        1591630802580
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP631498047392020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:39:08.358Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14998.00',
          mileage: '39142'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9501626,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP631498',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:59:17.654Z'
        }
      },
      sort: [
        1591630748358
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZ3DC2HS812766220532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:38:13.099Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23543.00',
          mileage: '14205'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9433385,
        makeCode: 'TOYOTA',
        vin: '5TDZZ3DC2HS812766',
        model: {
          modelDescription: 'SIENNA L 3.5L FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5328',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T21:03:46.634Z'
        }
      },
      sort: [
        1591630693099
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE7LP057970047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:38:06.560Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17998.00',
          mileage: '5888'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9501596,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE7LP057970',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:46:09.586Z'
        }
      },
      sort: [
        1591630686560
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK0JU113651047392020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:37:06.543Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23998.00',
          mileage: '29795'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9500040,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK0JU113651',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:34:33.047Z'
        }
      },
      sort: [
        1591630626543
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE5K3028514060532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:37:06.406Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16250.00',
          mileage: '9830'
        },
        dealerCd: '06053',
        certificationNumber: 9280635,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE5K3028514',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-07T18:08:19.126Z'
        }
      },
      sort: [
        1591630626406
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM064289150562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:36:09.846Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27487.00',
          mileage: '33446'
        },
        sellArea: '230',
        dealerCd: '15056',
        certificationNumber: 9495166,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM064289',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:27:47.864Z'
        }
      },
      sort: [
        1591630569846
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU390999047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:35:46.198Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18200.00',
          mileage: '39180'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9501631,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU390999',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:03:06.417Z'
        }
      },
      sort: [
        1591630546198
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3KC027440480272020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:35:41.497Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24498.00',
          mileage: '21627'
        },
        sellArea: '600',
        dealerCd: '48027',
        certificationNumber: 9474500,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3KC027440',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:24:21.254Z'
        }
      },
      sort: [
        1591630541497
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU783811047392020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:34:19.186Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15998.00',
          mileage: '35096'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9490451,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU783811',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T16:47:25.110Z'
        }
      },
      sort: [
        1591630459186
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU589968150562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:32:45.596Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18583.00',
          mileage: '46515'
        },
        sellArea: '500',
        dealerCd: '15056',
        certificationNumber: 9487446,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXJU589968',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:49:56.305Z'
        }
      },
      sort: [
        1591630365596
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW582924220532020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:32:43.196Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17995.00',
          mileage: '40778'
        },
        dealerCd: '22053',
        certificationNumber: 9344572,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW582924',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-20T16:03:11.021Z'
        }
      },
      sort: [
        1591630363196
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU687153041372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:36.554Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20990.0000',
          mileage: '40576'
        },
        sellArea: '500',
        dealerCd: '04137',
        certificationNumber: 9404904,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU687153',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T18:26:34.766Z'
        }
      },
      sort: [
        1591630296554
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX4JR030425041372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:34.116Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17899.0000',
          mileage: '23487'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9451113,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX4JR030425',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T15:35:47.732Z'
        }
      },
      sort: [
        1591630294116
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1GU165379041372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:31.504Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13899.0000',
          mileage: '51984'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9464809,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1GU165379',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T20:22:54.035Z'
        }
      },
      sort: [
        1591630291504
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5523059041372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:28.836Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32800.0000',
          mileage: '19233'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9499365,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5523059',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:48:16.831Z'
        }
      },
      sort: [
        1591630288836
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC1HS808750041372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:25.889Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26891.3100',
          mileage: '28962'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9499391,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC1HS808750',
        model: {
          modelDescription: 'SIENNA SE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5343',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:54:30.077Z'
        }
      },
      sort: [
        1591630285889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBXXJR030431041372020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:23.586Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17899.0000',
          mileage: '23111'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9451102,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBXXJR030431',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T15:31:32.579Z'
        }
      },
      sort: [
        1591630283586
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE4HJ532504220532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:23.037Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13695.00',
          mileage: '27626'
        },
        dealerCd: '22053',
        certificationNumber: 9373533,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE4HJ532504',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6273',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-08T13:50:58.283Z'
        }
      },
      sort: [
        1591630283037
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREVXJW492225041372020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:21.004Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19399.0000',
          mileage: '13355'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9456698,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREVXJW492225',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T16:17:42.705Z'
        }
      },
      sort: [
        1591630281004
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU7H3040149041372020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:18.444Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18599.0000',
          mileage: '16558'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9471000,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU7H3040149',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T17:32:05.790Z'
        }
      },
      sort: [
        1591630278444
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3JP745205041372020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:15.804Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16100.0000',
          mileage: '35144'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9482429,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3JP745205',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:19:28.888Z'
        }
      },
      sort: [
        1591630275804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP608647041372020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:31:13.065Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '13100.00',
          mileage: '46964'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9493951,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP608647',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:00:28.043Z'
        }
      },
      sort: [
        1591630273065
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXJW857129220212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:30:55.284Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22191.00',
          mileage: '20693'
        },
        sellArea: '210',
        dealerCd: '22021',
        certificationNumber: 9501271,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXJW857129',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:46:37.264Z'
        }
      },
      sort: [
        1591630255284
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV4KW020923150562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:30:34.105Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29197.00',
          mileage: '14400'
        },
        sellArea: '230',
        dealerCd: '15056',
        certificationNumber: 9498893,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV4KW020923',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:24:03.260Z'
        }
      },
      sort: [
        1591630234105
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU768304220212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:29:57.226Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18641.00',
          mileage: '35414'
        },
        sellArea: '210',
        dealerCd: '22021',
        certificationNumber: 9501277,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU768304',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:54:14.307Z'
        }
      },
      sort: [
        1591630197226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3JW797856220532020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:29:33.774Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20208.45',
          mileage: '44495'
        },
        dealerCd: '22053',
        certificationNumber: 9145539,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3JW797856',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-09-11T01:03:01.050Z'
        }
      },
      sort: [
        1591630173774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0LM290011430192020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:29:18.649Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '41350.00',
          mileage: '5223'
        },
        sellArea: '150',
        dealerCd: '43019',
        certificationNumber: 9497829,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0LM290011',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:31:20.622Z'
        }
      },
      sort: [
        1591630158649
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV7HW360209410832020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:28:54.433Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18179.00',
          mileage: '40836'
        },
        sellArea: '220',
        dealerCd: '41083',
        certificationNumber: 9467307,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV7HW360209',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:49:58.731Z'
        }
      },
      sort: [
        1591630134433
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH1GS260477220532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:28:13.674Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '37475'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9482263,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH1GS260477',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:55:51.500Z'
        }
      },
      sort: [
        1591630093674
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV9HJ115572150532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:27:07.974Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17128.00',
          mileage: '42570'
        },
        sellArea: '110',
        dealerCd: '15053',
        certificationNumber: 9493934,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV9HJ115572',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:52:51.072Z'
        }
      },
      sort: [
        1591630027974
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZ3DC3HS819497220532020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:27:02.594Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23343.00',
          mileage: '14950'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9434869,
        makeCode: 'TOYOTA',
        vin: '5TDZZ3DC3HS819497',
        model: {
          modelDescription: 'SIENNA L 3.5L FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5328',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T16:42:32.006Z'
        }
      },
      sort: [
        1591630022594
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1HK2JU501925220532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:25:38.634Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23444.00',
          mileage: '28134'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9489191,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1HK2JU501925',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2554',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T15:41:42.412Z'
        }
      },
      sort: [
        1591629938634
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP680281150532020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:24:45.293Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '15300.00',
          mileage: '22075'
        },
        sellArea: '230',
        dealerCd: '15053',
        certificationNumber: 9481613,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP680281',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:01:29.489Z'
        }
      },
      sort: [
        1591629885293
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE3LP014672043592020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:24:43.025Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18047.00',
          mileage: '13568'
        },
        sellArea: '600',
        dealerCd: '04359',
        certificationNumber: 9479122,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE3LP014672',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T16:48:24.203Z'
        }
      },
      sort: [
        1591629883025
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS420797430192020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:24:04.232Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30800.00',
          mileage: '21860'
        },
        sellArea: '150',
        dealerCd: '43019',
        certificationNumber: 9492125,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS420797',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:37:48.962Z'
        }
      },
      sort: [
        1591629844232
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU253009043592020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:23:43.706Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17316.00',
          mileage: '23520'
        },
        sellArea: '600',
        dealerCd: '04359',
        certificationNumber: 9479200,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU253009',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T17:17:21.287Z'
        }
      },
      sort: [
        1591629823706
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7HJ155473220532020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:23:29.185Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '35855'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9484458,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7HJ155473',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:28:51.531Z'
        }
      },
      sort: [
        1591629809185
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFP4RCEXLP009281220532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:22:04.884Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17995.00',
          mileage: '5738'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9489199,
        makeCode: 'TOYOTA',
        vin: '5YFP4RCEXLP009281',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T15:44:37.351Z'
        }
      },
      sort: [
        1591629724884
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F19LX885544260312020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:22:00.984Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '36395.00',
          mileage: '4324'
        },
        sellArea: '230',
        dealerCd: '26031',
        certificationNumber: 9497915,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F19LX885544',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:55:43.199Z'
        }
      },
      sort: [
        1591629720984
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN9KX154750270242020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:21:16.043Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30985.00',
          mileage: '8093'
        },
        sellArea: '150',
        dealerCd: '27024',
        certificationNumber: 9499093,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN9KX154750',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:30:54.626Z'
        }
      },
      sort: [
        1591629676043
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5FW398898220532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:21:02.863Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17495.00',
          mileage: '59777'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9478947,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5FW398898',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T13:53:27.159Z'
        }
      },
      sort: [
        1591629662863
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH1HS377445121352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:20:09.123Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24300.00',
          mileage: '31630'
        },
        dealerCd: '12135',
        certificationNumber: 9388400,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH1HS377445',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T00:07:14.452Z'
        }
      },
      sort: [
        1591629609123
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21HK6JU006180043592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:19:27.683Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21100.00',
          mileage: '40789'
        },
        sellArea: '110',
        dealerCd: '04359',
        certificationNumber: 9412138,
        makeCode: 'TOYOTA',
        vin: '4T1B21HK6JU006180',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T23:39:24.721Z'
        }
      },
      sort: [
        1591629567683
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREVXHJ709457220532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:19:23.521Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18532.00',
          mileage: '24997'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9444818,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREVXHJ709457',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-15T18:48:38.677Z'
        }
      },
      sort: [
        1591629563521
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHJ704180150532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:18:46.063Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17822.00',
          mileage: '42409'
        },
        sellArea: '230',
        dealerCd: '15053',
        certificationNumber: 9493943,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHJ704180',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:57:03.214Z'
        }
      },
      sort: [
        1591629526063
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU335699043592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:18:43.643Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16042.78',
          mileage: '27537'
        },
        sellArea: '110',
        dealerCd: '04359',
        certificationNumber: 9489084,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU335699',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T14:40:54.292Z'
        }
      },
      sort: [
        1591629523643
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F19HX674521020362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:18:24.982Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '37575.00',
          mileage: '34000'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9500312,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F19HX674521',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:57:28.461Z'
        }
      },
      sort: [
        1591629504982
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE3LP009768220532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:17:32.981Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '12549'
        },
        sellArea: '600',
        dealerCd: '22053',
        certificationNumber: 9469943,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE3LP009768',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T18:55:21.589Z'
        }
      },
      sort: [
        1591629452981
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE4JJ569445043592020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:17:14.720Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14100.00',
          mileage: '30370'
        },
        sellArea: '110',
        dealerCd: '04359',
        certificationNumber: 9409014,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE4JJ569445',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T21:21:30.548Z'
        }
      },
      sort: [
        1591629434720
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV5HJ126813480492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:16:30.881Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16045.00',
          mileage: '34760'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9473447,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV5HJ126813',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T21:13:04.949Z'
        }
      },
      sort: [
        1591629390881
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN4HM049385150532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:15:51.680Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29328.00',
          mileage: '24747'
        },
        sellArea: '110',
        dealerCd: '15053',
        certificationNumber: 9485979,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN4HM049385',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:38:03.532Z'
        }
      },
      sort: [
        1591629351680
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F11HX584327220532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:14:49.672Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32995.00',
          mileage: '24285'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9469888,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F11HX584327',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T18:01:38.881Z'
        }
      },
      sort: [
        1591629289672
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4JD222579470232020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:13:43.020Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21461.00',
          mileage: '27727'
        },
        sellArea: '800',
        dealerCd: '47023',
        certificationNumber: 9498846,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4JD222579',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:05:35.856Z'
        }
      },
      sort: [
        1591629223020
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW574268220532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:13:20.339Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20043.00',
          mileage: '45113'
        },
        dealerCd: '22053',
        certificationNumber: 9358846,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW574268',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-29T15:38:59.250Z'
        }
      },
      sort: [
        1591629200339
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3JW756093150532020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:12:19.737Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '21610.00',
          mileage: '27443'
        },
        sellArea: '230',
        dealerCd: '15053',
        certificationNumber: 9502300,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3JW756093',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T14:48:21.553Z'
        }
      },
      sort: [
        1591629139737
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW642987480492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:11:51.258Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22720.00',
          mileage: '24455'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9492790,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW642987',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:42:11.116Z'
        }
      },
      sort: [
        1591629111258
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KW043098220532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:11:38.858Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24644.00',
          mileage: '16959'
        },
        sellArea: '600',
        dealerCd: '22053',
        certificationNumber: 9500987,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KW043098',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T14:09:55.532Z'
        }
      },
      sort: [
        1591629098858
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS516991480302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:11:31.895Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '38140'
        },
        sellArea: '210',
        dealerCd: '48030',
        certificationNumber: 9502225,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS516991',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T14:16:43.618Z'
        }
      },
      sort: [
        1591629091895
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3FU103187121022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:11:12.497Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '12684.11',
          mileage: '64325'
        },
        sellArea: '500',
        dealerCd: '12102',
        certificationNumber: 9498409,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3FU103187',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T00:37:57.026Z'
        }
      },
      sort: [
        1591629072497
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7JW747378371542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:10:23.878Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22957.00',
          mileage: '23555'
        },
        sellArea: '800',
        dealerCd: '37154',
        certificationNumber: 9482990,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7JW747378',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T12:22:28.956Z'
        }
      },
      sort: [
        1591629023878
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV0HD213594121122020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:09:10.254Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '19409'
        },
        sellArea: '220',
        dealerCd: '12112',
        certificationNumber: 9502221,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV0HD213594',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T14:14:07.216Z'
        }
      },
      sort: [
        1591628950254
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0KM243303240772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:08:40.337Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '37243.00',
          mileage: '5339'
        },
        sellArea: '230',
        dealerCd: '24077',
        certificationNumber: 9501720,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0KM243303',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T22:18:07.743Z'
        }
      },
      sort: [
        1591628920337
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE1JJ562727121022020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:08:29.095Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16385.11',
          mileage: '16102'
        },
        sellArea: '210',
        dealerCd: '12102',
        certificationNumber: 9470141,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE1JJ562727',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T23:03:14.408Z'
        }
      },
      sort: [
        1591628909095
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3JW819631121002020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:07:31.815Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '51265'
        },
        sellArea: '500',
        dealerCd: '12100',
        certificationNumber: 9399736,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3JW819631',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T21:44:07.011Z'
        }
      },
      sort: [
        1591628851815
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS403660121022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:06:46.615Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29372.11',
          mileage: '41946'
        },
        sellArea: '210',
        dealerCd: '12102',
        certificationNumber: 9427913,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS403660',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T02:11:27.508Z'
        }
      },
      sort: [
        1591628806615
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9HM062984121372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:05:41.374Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30839.00',
          mileage: '44305'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9456174,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9HM062984',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T20:05:49.802Z'
        }
      },
      sort: [
        1591628741374
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4GM014744371762020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:05:24.411Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '28499.00',
          mileage: '38512'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9464314,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4GM014744',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T15:52:36.514Z'
        }
      },
      sort: [
        1591628724411
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0GD019731121372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:05:09.613Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18299.00',
          mileage: '53575'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9409775,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0GD019731',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T15:56:26.424Z'
        }
      },
      sort: [
        1591628709613
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU740234311312020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:05:02.534Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16893.50',
          mileage: '32408'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9496054,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU740234',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:08:12.410Z'
        }
      },
      sort: [
        1591628702534
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6FD051969121372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:04:09.892Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17300.00',
          mileage: '34014'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9468277,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6FD051969',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T15:00:50.694Z'
        }
      },
      sort: [
        1591628649892
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5HW630358121022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:04:04.112Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19382.65',
          mileage: '34629'
        },
        sellArea: '210',
        dealerCd: '12102',
        certificationNumber: 9500550,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV5HW630358',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T00:11:54.411Z'
        }
      },
      sort: [
        1591628644112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC862013121372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:02:56.471Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '12164.97',
          mileage: '62079'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9483691,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC862013',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:09:26.630Z'
        }
      },
      sort: [
        1591628576471
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFVXKW011519240672020-04-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:01:53.908Z',
          retailDate: '2020-04-20',
          rdrStatus: 'H',
          soldAmount: '26437.00',
          mileage: '11107'
        },
        sellArea: '600',
        dealerCd: '24067',
        certificationNumber: 9502317,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFVXKW011519',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T14:56:08.792Z'
        }
      },
      sort: [
        1591628513908
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV2LY705477121352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:01:38.209Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15892.00',
          mileage: '3108'
        },
        sellArea: '210',
        dealerCd: '12135',
        certificationNumber: 9470268,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV2LY705477',
        model: {
          modelDescription: 'YARIS 4Dr.Sedan LE 6AT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-17T19:46:13.990Z'
        }
      },
      sort: [
        1591628498209
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS508752371542020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:01:30.671Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28899.00',
          mileage: '44606'
        },
        sellArea: '160',
        dealerCd: '37154',
        certificationNumber: 9430140,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS508752',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T11:45:14.514Z'
        }
      },
      sort: [
        1591628490671
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH4HS444161371542020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:00:50.130Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '34969.00',
          mileage: '22975'
        },
        sellArea: '800',
        dealerCd: '37154',
        certificationNumber: 9396723,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH4HS444161',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T13:31:20.089Z'
        }
      },
      sort: [
        1591628450130
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC925701121372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:00:50.062Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13455.00',
          mileage: '57189'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9457454,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC925701',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T15:17:26.326Z'
        }
      },
      sort: [
        1591628450062
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV7JW733622371792020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:00:43.982Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '26834.00',
          mileage: '12444'
        },
        sellArea: '800',
        dealerCd: '37179',
        certificationNumber: 9494680,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV7JW733622',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:51:32.048Z'
        }
      },
      sort: [
        1591628443982
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV7HW608511371542020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T15:00:14.721Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19113.63',
          mileage: '38102'
        },
        sellArea: '800',
        dealerCd: '37154',
        certificationNumber: 9456615,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV7HW608511',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T15:04:07.602Z'
        }
      },
      sort: [
        1591628414721
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW639555480492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:59:49.281Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '22097'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9496175,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW639555',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:09:58.934Z'
        }
      },
      sort: [
        1591628389281
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7J5501216430262020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:58:37.206Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '36984.00',
          mileage: '28546'
        },
        sellArea: '150',
        dealerCd: '43026',
        certificationNumber: 9474698,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7J5501216',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:13:50.626Z'
        }
      },
      sort: [
        1591628317206
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH1GS244275410212020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:57:35.540Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23785.00',
          mileage: '60144'
        },
        sellArea: '190',
        dealerCd: '41021',
        certificationNumber: 9486118,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH1GS244275',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:59:45.127Z'
        }
      },
      sort: [
        1591628255540
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW596559060222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:57:18.780Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '44598'
        },
        sellArea: '160',
        dealerCd: '06022',
        certificationNumber: 9408593,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW596559',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T17:39:02.010Z'
        }
      },
      sort: [
        1591628238780
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DCXHS770889410212020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:56:50.250Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28294.00',
          mileage: '35078'
        },
        sellArea: '160',
        dealerCd: '41021',
        certificationNumber: 9480169,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DCXHS770889',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T14:06:52.604Z'
        }
      },
      sort: [
        1591628210250
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB30F1105582130492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:56:39.078Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '47081'
        },
        sellArea: '190',
        dealerCd: '13049',
        certificationNumber: 9420465,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB30F1105582',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB FOUR',
          modelSeries: 'PRIUS C',
          modelNumber: '1207',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T19:54:20.993Z'
        }
      },
      sort: [
        1591628199078
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV4HD100236480492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:56:35.380Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18875.00',
          mileage: '34399'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9485363,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV4HD100236',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:07:33.614Z'
        }
      },
      sort: [
        1591628195380
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC835487371542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:55:58.519Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15555.00',
          mileage: '31767'
        },
        sellArea: '800',
        dealerCd: '37154',
        certificationNumber: 9485356,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC835487',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:03:10.878Z'
        }
      },
      sort: [
        1591628158519
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC802367371012020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:55:04.338Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '14288.00',
          mileage: '19740'
        },
        sellArea: '800',
        dealerCd: '37101',
        certificationNumber: 9493674,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC802367',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:11:21.461Z'
        }
      },
      sort: [
        1591628104338
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFJU4GN3EX063836120862020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:54:39.538Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21800.00',
          mileage: '57172'
        },
        sellArea: '110',
        dealerCd: '12086',
        certificationNumber: 9500370,
        makeCode: 'TOYOTA',
        vin: '5TFJU4GN3EX063836',
        model: {
          modelDescription: 'TACOMA PRERUNNER DOUBLE CAB 4X2 V6',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7188',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:26:41.404Z'
        }
      },
      sort: [
        1591628079538
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU512791041082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:54:36.119Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22767.0000',
          mileage: '19373'
        },
        sellArea: '150',
        dealerCd: '04108',
        certificationNumber: 9431841,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU512791',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T16:18:55.851Z'
        }
      },
      sort: [
        1591628076119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5HW571490041082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:54:33.720Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19804.0000',
          mileage: '34842'
        },
        sellArea: '110',
        dealerCd: '04108',
        certificationNumber: 9453346,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV5HW571490',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T16:04:56.487Z'
        }
      },
      sort: [
        1591628073720
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5HW651602130492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:54:32.959Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '30205'
        },
        sellArea: '210',
        dealerCd: '13049',
        certificationNumber: 9470059,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5HW651602',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T20:53:59.239Z'
        }
      },
      sort: [
        1591628072959
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TYRX5GN4LT000509041082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:54:30.786Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24880.83',
          mileage: '367'
        },
        sellArea: '110',
        dealerCd: '04108',
        certificationNumber: 9492259,
        makeCode: 'TOYOTA',
        vin: '3TYRX5GN4LT000509',
        model: {
          modelDescription: 'TACOMA SR 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7162',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:21:17.505Z'
        }
      },
      sort: [
        1591628070786
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4GJ092637371542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:54:00.994Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18495.00',
          mileage: '16723'
        },
        sellArea: '800',
        dealerCd: '37154',
        certificationNumber: 9495821,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4GJ092637',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:51:09.796Z'
        }
      },
      sort: [
        1591628040994
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS423586480282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:53:30.303Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28649.30',
          mileage: '33811'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9474406,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS423586',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T15:47:02.719Z'
        }
      },
      sort: [
        1591628010303
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6LC076861130492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:53:09.683Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26980.00',
          mileage: '2243'
        },
        sellArea: '500',
        dealerCd: '13049',
        certificationNumber: 9486069,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6LC076861',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:34:15.488Z'
        }
      },
      sort: [
        1591627989683
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HJ700170371542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:53:01.624Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21449.00',
          mileage: '37043'
        },
        sellArea: '160',
        dealerCd: '37154',
        certificationNumber: 9484978,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HJ700170',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T12:19:21.567Z'
        }
      },
      sort: [
        1591627981624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV6HW677215480492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:52:50.885Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22988.00',
          mileage: '27973'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9495756,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV6HW677215',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:29:09.588Z'
        }
      },
      sort: [
        1591627970885
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV1KC049832371542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:52:23.544Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30796.00',
          mileage: '10360'
        },
        sellArea: '800',
        dealerCd: '37154',
        certificationNumber: 9494026,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV1KC049832',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:33:40.968Z'
        }
      },
      sort: [
        1591627943544
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0LC064589130492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:52:17.544Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26980.00',
          mileage: '7690'
        },
        sellArea: '500',
        dealerCd: '13049',
        certificationNumber: 9486076,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0LC064589',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:40:07.291Z'
        }
      },
      sort: [
        1591627937544
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV4HD086768130492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:51:15.763Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23980.00',
          mileage: '31758'
        },
        sellArea: '210',
        dealerCd: '13049',
        certificationNumber: 9496203,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV4HD086768',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:25:04.946Z'
        }
      },
      sort: [
        1591627875763
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV4JD250896440162020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:51:14.617Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23750.00',
          mileage: '27022'
        },
        sellArea: '170',
        dealerCd: '44016',
        certificationNumber: 9397662,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV4JD250896',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T20:33:21.019Z'
        }
      },
      sort: [
        1591627874617
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW601735371542020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:51:02.317Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '24969.00',
          mileage: '39692'
        },
        sellArea: '800',
        dealerCd: '37154',
        certificationNumber: 9398594,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW601735',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T14:46:07.643Z'
        }
      },
      sort: [
        1591627862317
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU298616120862020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:50:36.216Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13999.00',
          mileage: '54212'
        },
        sellArea: '210',
        dealerCd: '12086',
        certificationNumber: 9455620,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU298616',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T19:24:56.924Z'
        }
      },
      sort: [
        1591627836216
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ150686130492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:49:46.937Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19899.00',
          mileage: '39134'
        },
        sellArea: '210',
        certificationNumber: 9496247,
        dealerCd: '13049',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        vin: 'JTMRFREV1HJ150686',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:48:37.625Z'
        }
      },
      sort: [
        1591627786937
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE2HJ539239480492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:49:39.097Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14223.00',
          mileage: '25266'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9424734,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE2HJ539239',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T22:54:08.844Z'
        }
      },
      sort: [
        1591627779097
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU690012310972020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:49:04.218Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '19288.73',
          mileage: '36851'
        },
        sellArea: '500',
        dealerCd: '31097',
        certificationNumber: 9459967,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU690012',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T19:03:36.344Z'
        }
      },
      sort: [
        1591627744218
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1HC891435130492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:48:35.284Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14300.00',
          mileage: '20486'
        },
        sellArea: '160',
        dealerCd: '13049',
        certificationNumber: 9496233,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1HC891435',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:41:40.340Z'
        }
      },
      sort: [
        1591627715284
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU311493121502020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:48:21.285Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18485.00',
          mileage: '43321'
        },
        sellArea: '220',
        dealerCd: '12150',
        certificationNumber: 9483590,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU311493',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:23:10.229Z'
        }
      },
      sort: [
        1591627701285
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5HW579007120862020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:48:16.985Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18981.73',
          mileage: '43527'
        },
        sellArea: '800',
        dealerCd: '12086',
        certificationNumber: 9453477,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV5HW579007',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T17:10:12.974Z'
        }
      },
      sort: [
        1591627696985
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3GW483353121502020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:47:43.785Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '20985.00',
          mileage: '45708'
        },
        sellArea: '600',
        dealerCd: '12150',
        certificationNumber: 9483544,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3GW483353',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:03:52.239Z'
        }
      },
      sort: [
        1591627663785
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0K5704966120862020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:47:27.864Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '33999.00',
          mileage: '10527'
        },
        sellArea: '210',
        dealerCd: '12086',
        certificationNumber: 9440970,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0K5704966',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T21:03:47.166Z'
        }
      },
      sort: [
        1591627647864
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHD219759130492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:47:26.915Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20399.00',
          mileage: '24213'
        },
        sellArea: '210',
        dealerCd: '13049',
        certificationNumber: 9498176,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHD219759',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:22:46.457Z'
        }
      },
      sort: [
        1591627646915
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0LS024377310972020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:47:26.500Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '33179.00',
          mileage: '26416'
        },
        sellArea: '500',
        dealerCd: '31097',
        certificationNumber: 9459856,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC0LS024377',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:07:05.648Z'
        }
      },
      sort: [
        1591627646500
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV7HW594450121502020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:46:56.059Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '15700.00',
          mileage: '26469'
        },
        sellArea: '160',
        dealerCd: '12150',
        certificationNumber: 9483555,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV7HW594450',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:08:06.103Z'
        }
      },
      sort: [
        1591627616059
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5GM033111480282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:46:33.839Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30563.00',
          mileage: '10964'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9440275,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5GM033111',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-04T14:34:38.797Z'
        }
      },
      sort: [
        1591627593839
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU206082120862020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:46:31.119Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21030.00',
          mileage: '31233'
        },
        dealerCd: '12086',
        certificationNumber: 9368659,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU206082',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T22:46:40.856Z'
        }
      },
      sort: [
        1591627591119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK6JU130745121352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:46:12.494Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27899.00',
          mileage: '20230'
        },
        sellArea: '600',
        dealerCd: '12135',
        certificationNumber: 9445417,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK6JU130745',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T15:54:39.504Z'
        }
      },
      sort: [
        1591627572494
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS428591121502020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:45:52.235Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '24655.72',
          mileage: '35754'
        },
        sellArea: '210',
        dealerCd: '12150',
        certificationNumber: 9483539,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS428591',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:01:35.041Z'
        }
      },
      sort: [
        1591627552235
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV1HW365518121502020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:45:16.774Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '18990.00',
          mileage: '26818'
        },
        sellArea: '210',
        dealerCd: '12150',
        certificationNumber: 9483588,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV1HW365518',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:21:46.736Z'
        }
      },
      sort: [
        1591627516774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU643186310972020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:45:07.599Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26950.00',
          mileage: '36940'
        },
        sellArea: '500',
        dealerCd: '31097',
        certificationNumber: 9459863,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXJU643186',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:10:24.866Z'
        }
      },
      sort: [
        1591627507599
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFHXHS514897040132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:45:04.059Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30592.00',
          mileage: '30039'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9495322,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFHXHS514897',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6950',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:40:20.681Z'
        }
      },
      sort: [
        1591627504059
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV5HD218905440162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:45:04.035Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22900.00',
          mileage: '26308'
        },
        sellArea: '170',
        dealerCd: '44016',
        certificationNumber: 9477706,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV5HD218905',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:26:11.368Z'
        }
      },
      sort: [
        1591627504035
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH3FS205981311162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:44:55.775Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21970.00',
          mileage: '53999'
        },
        sellArea: '170',
        dealerCd: '31116',
        certificationNumber: 9471515,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH3FS205981',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T20:39:29.224Z'
        }
      },
      sort: [
        1591627495775
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU244018121502020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:44:23.468Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '19085.00',
          mileage: '19842'
        },
        sellArea: '500',
        dealerCd: '12150',
        certificationNumber: 9483569,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU244018',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:14:04.850Z'
        }
      },
      sort: [
        1591627463468
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK6LU910052040132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:44:23.239Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22592.00',
          mileage: '7448'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9462800,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK6LU910052',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:33:04.450Z'
        }
      },
      sort: [
        1591627463239
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC939153121502020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:43:58.759Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '16300.00',
          mileage: '44591'
        },
        sellArea: '220',
        dealerCd: '12150',
        certificationNumber: 9483565,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC939153',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:11:50.025Z'
        }
      },
      sort: [
        1591627438759
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH4HS376167480492020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:43:58.613Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30587.00',
          mileage: '24606'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9439454,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH4HS376167',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T21:06:06.703Z'
        }
      },
      sort: [
        1591627438613
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE2HJ547597040132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:43:30.394Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15282.00',
          mileage: '19652'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9474240,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE2HJ547597',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:40:34.268Z'
        }
      },
      sort: [
        1591627410394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK2HU216041121502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:43:16.774Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18250.00',
          mileage: '36297'
        },
        sellArea: '210',
        dealerCd: '12150',
        certificationNumber: 9483515,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK2HU216041',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:54:23.776Z'
        }
      },
      sort: [
        1591627396774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW636673440162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:42:58.574Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19600.00',
          mileage: '37184'
        },
        sellArea: '170',
        dealerCd: '44016',
        certificationNumber: 9485605,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW636673',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:33:37.527Z'
        }
      },
      sort: [
        1591627378574
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8JC001244291082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:42:55.232Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15936.65',
          mileage: '16895'
        },
        sellArea: '160',
        dealerCd: '29108',
        certificationNumber: 9450073,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8JC001244',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T16:03:41.189Z'
        }
      },
      sort: [
        1591627375232
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU265413121502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:42:44.552Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18090.00',
          mileage: '25995'
        },
        sellArea: '210',
        dealerCd: '12150',
        certificationNumber: 9443914,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU265413',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T17:11:55.439Z'
        }
      },
      sort: [
        1591627364552
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK1LU868700040132020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:42:37.551Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22892.00',
          mileage: '6757'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9462813,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK1LU868700',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:37:37.873Z'
        }
      },
      sort: [
        1591627357551
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AKXLU332568040132020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:41:55.135Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23392.00',
          mileage: '3653'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9465507,
        makeCode: 'TOYOTA',
        vin: '4T1G11AKXLU332568',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:03:01.274Z'
        }
      },
      sort: [
        1591627315135
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HJ706262121502020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:41:42.271Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '19595.00',
          mileage: '35839'
        },
        sellArea: '800',
        dealerCd: '12150',
        certificationNumber: 9483519,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HJ706262',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:55:42.377Z'
        }
      },
      sort: [
        1591627302271
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV1HW370608046882020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:41:39.094Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20981.00',
          mileage: '32349'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9492763,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV1HW370608',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:33:20.549Z'
        }
      },
      sort: [
        1591627299094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB36F1089405040132020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:41:10.014Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '9571.00',
          mileage: '43025'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9498983,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB36F1089405',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:55:42.475Z'
        }
      },
      sort: [
        1591627270014
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F19HX647870210722020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:41:05.546Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '31500.00',
          mileage: '54709'
        },
        sellArea: '600',
        dealerCd: '21072',
        certificationNumber: 9489165,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F19HX647870',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T15:28:48.026Z'
        }
      },
      sort: [
        1591627265546
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU769510046882020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:41:05.016Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17081.00',
          mileage: '21158'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9487139,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU769510',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:07:12.620Z'
        }
      },
      sort: [
        1591627265016
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU733225121352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:40:53.476Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15468.00',
          mileage: '21675'
        },
        sellArea: '210',
        dealerCd: '12135',
        certificationNumber: 9483600,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU733225',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:27:56.450Z'
        }
      },
      sort: [
        1591627253476
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV5HJ134722046882020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:40:32.394Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21981.00',
          mileage: '24251'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9487130,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV5HJ134722',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:04:26.765Z'
        }
      },
      sort: [
        1591627232394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU290955046882020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:40:01.596Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18881.00',
          mileage: '26987'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9487174,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU290955',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:19:16.831Z'
        }
      },
      sort: [
        1591627201596
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2JW755826121502020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:39:47.976Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18508.57',
          mileage: '9686'
        },
        sellArea: '210',
        dealerCd: '12150',
        certificationNumber: 9483529,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2JW755826',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:58:58.818Z'
        }
      },
      sort: [
        1591627187976
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV6HD211025480492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:39:37.852Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21434.00',
          mileage: '36062'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9422187,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV6HD211025',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T14:47:03.731Z'
        }
      },
      sort: [
        1591627177852
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU8HJ065335046882020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:39:31.716Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22481.00',
          mileage: '31050'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9484416,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU8HJ065335',
        model: {
          modelDescription: 'Prius v Two Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1243',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:09:07.778Z'
        }
      },
      sort: [
        1591627171716
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV1HW661054220532020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:39:20.255Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '23092'
        },
        sellArea: '210',
        dealerCd: '22053',
        certificationNumber: 9502257,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV1HW661054',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T14:35:36.734Z'
        }
      },
      sort: [
        1591627160255
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH8HS424687121502020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:39:17.335Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '29000.00',
          mileage: '37204'
        },
        sellArea: '210',
        dealerCd: '12150',
        certificationNumber: 9462796,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH8HS424687',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:30:35.598Z'
        }
      },
      sort: [
        1591627157335
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC7HS802283046882020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:39:03.495Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '24981.00',
          mileage: '23141'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9487153,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC7HS802283',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:13:09.067Z'
        }
      },
      sort: [
        1591627143495
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU226869046882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:38:26.594Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21482.00',
          mileage: '30214'
        },
        sellArea: '500',
        dealerCd: '04688',
        certificationNumber: 9461406,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU226869',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T21:30:59.793Z'
        }
      },
      sort: [
        1591627106594
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5FP342348121502020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:38:24.208Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '13500.00',
          mileage: '58826'
        },
        sellArea: '600',
        dealerCd: '12150',
        certificationNumber: 9483578,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5FP342348',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:17:31.707Z'
        }
      },
      sort: [
        1591627104208
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH9FS113111121112020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:38:12.613Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23495.00',
          mileage: '82383'
        },
        sellArea: '210',
        dealerCd: '12111',
        certificationNumber: 9496396,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH9FS113111',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:16:43.567Z'
        }
      },
      sort: [
        1591627092613
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU258414480282020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:37:57.005Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18178.00',
          mileage: '14006'
        },
        dealerCd: '48028',
        certificationNumber: 9372587,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU258414',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T18:47:40.536Z'
        }
      },
      sort: [
        1591627077005
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU667474046882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:37:55.874Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16981.00',
          mileage: '44285'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9494215,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU667474',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:44:40.238Z'
        }
      },
      sort: [
        1591627075874
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC7HS816660040132020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:37:53.715Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '26392.00',
          mileage: '35208'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483268,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC7HS816660',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:40:30.836Z'
        }
      },
      sort: [
        1591627073715
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMGZ5AN6GM007831180332020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:37:37.374Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28795.00',
          mileage: '76590'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9500183,
        makeCode: 'TOYOTA',
        vin: '3TMGZ5AN6GM007831',
        model: {
          modelDescription: 'TACOMA LIMITED 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7582',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:17:43.809Z'
        }
      },
      sort: [
        1591627057374
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK8KU186316046882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:37:19.925Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26481.00',
          mileage: '31600'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9484507,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK8KU186316',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:53:20.247Z'
        }
      },
      sort: [
        1591627039925
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK0HU221108046882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:36:50.766Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18582.00',
          mileage: '75236'
        },
        sellArea: '150',
        dealerCd: '04688',
        certificationNumber: 9460192,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK0HU221108',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T21:08:59.797Z'
        }
      },
      sort: [
        1591627010766
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F13GX540502311762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:36:27.556Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27999.00',
          mileage: '67245'
        },
        dealerCd: '31176',
        certificationNumber: 9389659,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F13GX540502',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T21:19:29.826Z'
        }
      },
      sort: [
        1591626987556
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP682497040132020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:36:24.633Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '14792.00',
          mileage: '45752'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9495305,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP682497',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:35:00.314Z'
        }
      },
      sort: [
        1591626984633
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6JW852186046882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:36:22.166Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22981.00',
          mileage: '2483'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9496230,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6JW852186',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:39:46.099Z'
        }
      },
      sort: [
        1591626982166
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2GW512458480492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:36:07.266Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15901.00',
          mileage: '76738'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9446529,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2GW512458',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T13:58:43.210Z'
        }
      },
      sort: [
        1591626967266
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXKW029491046882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:35:51.087Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25882.00',
          mileage: '35456'
        },
        sellArea: '600',
        dealerCd: '04688',
        certificationNumber: 9458062,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXKW029491',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T23:16:40.823Z'
        }
      },
      sort: [
        1591626951087
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV2HJ704885046882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:35:16.905Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20982.00',
          mileage: '33055'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9476184,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV2HJ704885',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:19:53.365Z'
        }
      },
      sort: [
        1591626916905
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP664436040132020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:35:13.552Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15992.00',
          mileage: '25355'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497888,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP664436',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:48:58.450Z'
        }
      },
      sort: [
        1591626913552
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV5HD124986046882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:34:44.752Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24148.00',
          mileage: '30992'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9482526,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV5HD124986',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T22:15:04.449Z'
        }
      },
      sort: [
        1591626884752
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV5HW315787040132020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:34:19.671Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17792.00',
          mileage: '50400'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9474199,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV5HW315787',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:16:24.472Z'
        }
      },
      sort: [
        1591626859671
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP619753046882020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:34:04.791Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15981.00',
          mileage: '33719'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9482476,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP619753',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:54:23.421Z'
        }
      },
      sort: [
        1591626844791
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH4HS440604440162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:33:55.731Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25600.00',
          mileage: '29670'
        },
        sellArea: '170',
        dealerCd: '44016',
        certificationNumber: 9497160,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH4HS440604',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:28:09.242Z'
        }
      },
      sort: [
        1591626835731
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH4GS353093121502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:33:28.431Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23985.00',
          mileage: '32274'
        },
        sellArea: '210',
        dealerCd: '12150',
        certificationNumber: 9455904,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH4GS353093',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T01:08:45.561Z'
        }
      },
      sort: [
        1591626808431
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE3LP016230046882020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:33:24.711Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19281.00',
          mileage: '16765'
        },
        sellArea: '600',
        dealerCd: '04688',
        certificationNumber: 9478283,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE3LP016230',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:39:56.121Z'
        }
      },
      sort: [
        1591626804711
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW628530130712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:33:11.530Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22771.00',
          mileage: '33553'
        },
        sellArea: '210',
        dealerCd: '13071',
        certificationNumber: 9499965,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW628530',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:09:37.393Z'
        }
      },
      sort: [
        1591626791530
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBZV1GY109829046882020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:32:43.890Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '10481.00',
          mileage: '75212'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9471519,
        makeCode: 'TOYOTA',
        vin: '3MYDLBZV1GY109829',
        model: {
          modelDescription: 'SCION iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T20:42:33.724Z'
        }
      },
      sort: [
        1591626763890
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU617924480492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:32:02.310Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19337.00',
          mileage: '23842'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9499775,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU617924',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:58:15.550Z'
        }
      },
      sort: [
        1591626722310
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU788839291082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:30:46.466Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '15457'
        },
        sellArea: '160',
        dealerCd: '29108',
        certificationNumber: 9485022,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU788839',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T13:03:58.579Z'
        }
      },
      sort: [
        1591626646466
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV9JW434817240782020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:30:36.229Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20987.00',
          mileage: '12852'
        },
        sellArea: '230',
        dealerCd: '24078',
        certificationNumber: 9501382,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV9JW434817',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:13:51.732Z'
        }
      },
      sort: [
        1591626636229
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU761435291082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:29:51.639Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19222.00',
          mileage: '12885'
        },
        sellArea: '160',
        dealerCd: '29108',
        certificationNumber: 9413497,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU761435',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T15:36:10.216Z'
        }
      },
      sort: [
        1591626591639
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV3HW338811121502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:29:21.185Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16990.00',
          mileage: '52941'
        },
        sellArea: '110',
        dealerCd: '12150',
        certificationNumber: 9468342,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV3HW338811',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T15:30:16.690Z'
        }
      },
      sort: [
        1591626561185
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU655590480492020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:28:37.667Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17214.00',
          mileage: '21313'
        },
        dealerCd: '48049',
        certificationNumber: 9372493,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU655590',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T17:52:59.004Z'
        }
      },
      sort: [
        1591626517667
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6GC637914370362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:26:03.688Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13200.00',
          mileage: '34988'
        },
        sellArea: '800',
        dealerCd: '37036',
        certificationNumber: 9500865,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6GC637914',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T12:30:27.808Z'
        }
      },
      sort: [
        1591626363688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW628459220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:24:57.086Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19165.00',
          mileage: '29383'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9456540,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW628459',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T14:23:25.911Z'
        }
      },
      sort: [
        1591626297086
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN5KX146290110092020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:24:35.145Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25987.00',
          mileage: '25313'
        },
        sellArea: '600',
        dealerCd: '11009',
        certificationNumber: 9468227,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN5KX146290',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T14:31:17.302Z'
        }
      },
      sort: [
        1591626275145
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ154501480272020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:24:08.747Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18896.00',
          mileage: '28828'
        },
        sellArea: '210',
        dealerCd: '48027',
        certificationNumber: 9456846,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ154501',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T18:06:02.514Z'
        }
      },
      sort: [
        1591626248747
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP3J3103556291012020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:23:32.468Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '10516'
        },
        dealerCd: '29101',
        certificationNumber: 9283563,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP3J3103556',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-10T15:21:10.647Z'
        }
      },
      sort: [
        1591626212468
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HD207495290082020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:23:12.947Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18989.00',
          mileage: '27748'
        },
        sellArea: '160',
        dealerCd: '29008',
        certificationNumber: 9431714,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HD207495',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T14:38:24.394Z'
        }
      },
      sort: [
        1591626192947
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH6GS291857290082020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:22:16.867Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23177.00',
          mileage: '37394'
        },
        sellArea: '160',
        dealerCd: '29008',
        certificationNumber: 9420692,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH6GS291857',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T21:29:43.421Z'
        }
      },
      sort: [
        1591626136867
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU702306190712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:17:03.828Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '22029'
        },
        sellArea: '800',
        dealerCd: '19071',
        certificationNumber: 9499115,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU702306',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:37:40.072Z'
        }
      },
      sort: [
        1591625823828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F11FX419782240672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:15:11.528Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33200.00',
          mileage: '54469'
        },
        sellArea: '600',
        dealerCd: '24067',
        certificationNumber: 9494619,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F11FX419782',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8378',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:12:38.766Z'
        }
      },
      sort: [
        1591625711528
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV9KD011187311762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:15:01.800Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27999.00',
          mileage: '21725'
        },
        dealerCd: '31176',
        certificationNumber: 9340743,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV9KD011187',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-16T21:44:50.218Z'
        }
      },
      sort: [
        1591625701800
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU812675160512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:10:56.037Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20999.00',
          mileage: '6733'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9502200,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU812675',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T14:06:56.337Z'
        }
      },
      sort: [
        1591625456037
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB38H1591514120712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:10:03.388Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14000.00',
          mileage: '45360'
        },
        sellArea: '210',
        dealerCd: '12071',
        certificationNumber: 9430579,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB38H1591514',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T16:41:41.677Z'
        }
      },
      sort: [
        1591625403388
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB6GU203976120712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:09:22.828Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14995.00',
          mileage: '73768'
        },
        sellArea: '600',
        dealerCd: '12071',
        certificationNumber: 9448020,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB6GU203976',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T16:40:29.191Z'
        }
      },
      sort: [
        1591625362828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HJ702132291082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:08:06.047Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24122.98',
          mileage: '29647'
        },
        dealerCd: '29108',
        certificationNumber: 9374853,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HJ702132',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-10T17:56:46.805Z'
        }
      },
      sort: [
        1591625286047
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV2HJ128034480272020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:07:09.342Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17798.00',
          mileage: '32156'
        },
        sellArea: '210',
        dealerCd: '48027',
        certificationNumber: 9474734,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV2HJ128034',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:30:18.667Z'
        }
      },
      sort: [
        1591625229342
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU640297041542020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:06:57.027Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17596.00',
          mileage: '31549'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9420729,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU640297',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T21:43:41.920Z'
        }
      },
      sort: [
        1591625217027
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU256299190522020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:04:19.703Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17995.0000',
          mileage: '21000'
        },
        sellArea: '500',
        dealerCd: '19052',
        certificationNumber: 9472703,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU256299',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T16:14:50.603Z'
        }
      },
      sort: [
        1591625059703
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC011307190522020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:04:17.382Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24295.0000',
          mileage: '22286'
        },
        sellArea: '500',
        dealerCd: '19052',
        certificationNumber: 9489494,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC011307',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:42:31.508Z'
        }
      },
      sort: [
        1591625057382
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC918727190522020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:04:15.048Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12995.0000',
          mileage: '27246'
        },
        sellArea: '800',
        dealerCd: '19052',
        certificationNumber: 9496329,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC918727',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:34:12.246Z'
        }
      },
      sort: [
        1591625055048
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV6HD112491190522020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:04:12.589Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23995.0000',
          mileage: '31287'
        },
        sellArea: '800',
        dealerCd: '19052',
        certificationNumber: 9462877,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV6HD112491',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T16:02:00.776Z'
        }
      },
      sort: [
        1591625052589
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4HW659429190522020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:04:09.889Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20595.0000',
          mileage: '23683'
        },
        sellArea: '160',
        certificationNumber: 9496436,
        dealerCd: '19052',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        vin: '2T3RFREV4HW659429',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:02:19.567Z'
        }
      },
      sort: [
        1591625049889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KC007586190522020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:04:07.449Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23800.0000',
          mileage: '16418'
        },
        dealerCd: '19052',
        certificationNumber: 9381164,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KC007586',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T15:41:18.784Z'
        }
      },
      sort: [
        1591625047449
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HD211700190522020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:04:02.987Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20809.0000',
          mileage: '29726'
        },
        sellArea: '160',
        dealerCd: '19052',
        certificationNumber: 9496438,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HD211700',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:05:09.898Z'
        }
      },
      sort: [
        1591625042987
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0GJ104055190522020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:04:00.628Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18314.0000',
          mileage: '27926'
        },
        sellArea: '160',
        dealerCd: '19052',
        certificationNumber: 9492540,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0GJ104055',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:26:55.574Z'
        }
      },
      sort: [
        1591625040628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3KW056094190522020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:03:58.089Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '24295.00',
          mileage: '21872'
        },
        sellArea: '600',
        dealerCd: '19052',
        certificationNumber: 9489582,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3KW056094',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:03:14.671Z'
        }
      },
      sort: [
        1591625038089
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1JW816954121002020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:01:15.749Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21195.00',
          mileage: '43290'
        },
        dealerCd: '12100',
        certificationNumber: 9368797,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1JW816954',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-05T00:18:34.646Z'
        }
      },
      sort: [
        1591624875749
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DCXLS046533121262020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T14:01:13.910Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '36240.00',
          mileage: '16057'
        },
        sellArea: '210',
        dealerCd: '12126',
        certificationNumber: 9444481,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DCXLS046533',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T21:52:23.416Z'
        }
      },
      sort: [
        1591624873910
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8GP543310160512020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:58:47.737Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '14300.00',
          mileage: '25501'
        },
        dealerCd: '16051',
        certificationNumber: 9502183,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8GP543310',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T13:55:34.765Z'
        }
      },
      sort: [
        1591624727737
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU6HJ066063121602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:56:49.425Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18665.0000',
          mileage: '35626'
        },
        sellArea: '500',
        dealerCd: '12160',
        certificationNumber: 9396468,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU6HJ066063',
        model: {
          modelDescription: 'PRIUS v Three Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1245',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T01:27:46.479Z'
        }
      },
      sort: [
        1591624609425
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV1HJ706738121602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:56:39.507Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16503.7100',
          mileage: '34419'
        },
        sellArea: '210',
        dealerCd: '12160',
        certificationNumber: 9474264,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV1HJ706738',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:54:25.651Z'
        }
      },
      sort: [
        1591624599507
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV1HD118852121602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:56:27.706Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20920.00',
          mileage: '19714'
        },
        sellArea: '210',
        dealerCd: '12160',
        certificationNumber: 9501753,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV1HD118852',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T23:25:55.603Z'
        }
      },
      sort: [
        1591624587706
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV2KY526693410302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:56:09.125Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16389.00',
          mileage: '5636'
        },
        sellArea: '220',
        dealerCd: '41030',
        certificationNumber: 9441349,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV2KY526693',
        model: {
          modelDescription: 'YARIS Sedan 4Dr.Sedan XLE6AT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6268',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-07T17:55:57.462Z'
        }
      },
      sort: [
        1591624569125
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS430275480612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:55:56.706Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28500.00',
          mileage: '43645'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9477409,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS430275',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:18:22.591Z'
        }
      },
      sort: [
        1591624556706
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ112365291082020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:55:13.206Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '20848'
        },
        sellArea: '160',
        dealerCd: '29108',
        certificationNumber: 9496963,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ112365',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:50:00.477Z'
        }
      },
      sort: [
        1591624513206
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE4LJ004340410302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:55:11.570Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19125.00',
          mileage: '16059'
        },
        sellArea: '220',
        dealerCd: '41030',
        certificationNumber: 9439213,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE4LJ004340',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T16:43:21.641Z'
        }
      },
      sort: [
        1591624511570
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE3LJ004717410302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:54:12.324Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20050.00',
          mileage: '15377'
        },
        sellArea: '220',
        dealerCd: '41030',
        certificationNumber: 9439143,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE3LJ004717',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T15:43:05.992Z'
        }
      },
      sort: [
        1591624452324
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV5JD171527140462020-06-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:52:42.083Z',
          retailDate: '2020-06-08',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '31408'
        },
        dealerCd: '14046',
        certificationNumber: 9176993,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV5JD171527',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-09-30T18:06:52.328Z'
        }
      },
      sort: [
        1591624362083
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HJ712580450922020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:51:19.063Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18278.00',
          mileage: '23157'
        },
        sellArea: '160',
        dealerCd: '45092',
        certificationNumber: 9501140,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HJ712580',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T15:30:33.266Z'
        }
      },
      sort: [
        1591624279063
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC858445450922020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:49:32.677Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '14000.00',
          mileage: '22889'
        },
        sellArea: '800',
        dealerCd: '45092',
        certificationNumber: 9501145,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC858445',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T15:32:24.608Z'
        }
      },
      sort: [
        1591624172677
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8GM010681371942020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:48:31.980Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26700.00',
          mileage: '37405'
        },
        sellArea: '800',
        dealerCd: '37194',
        certificationNumber: 9494668,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8GM010681',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:43:32.089Z'
        }
      },
      sort: [
        1591624111980
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV2KD514172480322020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:43:05.031Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25990.00',
          mileage: '6043'
        },
        sellArea: '800',
        dealerCd: '48032',
        certificationNumber: 9452786,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV2KD514172',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T23:42:37.820Z'
        }
      },
      sort: [
        1591623785031
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU387563121352020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:41:09.272Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15498.00',
          mileage: '20771'
        },
        sellArea: '210',
        dealerCd: '12135',
        certificationNumber: 9475899,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU387563',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T15:49:39.378Z'
        }
      },
      sort: [
        1591623669272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC3LS024556430252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:39:44.871Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24285.00',
          mileage: '29653'
        },
        sellArea: '500',
        dealerCd: '43025',
        certificationNumber: 9502146,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC3LS024556',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T13:36:50.681Z'
        }
      },
      sort: [
        1591623584871
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJK3DC4GS129807201282020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:35:42.112Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20892.00',
          mileage: '47827'
        },
        sellArea: '170',
        dealerCd: '20128',
        certificationNumber: 9456666,
        makeCode: 'TOYOTA',
        vin: '5TDJK3DC4GS129807',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T15:57:24.462Z'
        }
      },
      sort: [
        1591623342112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU384349371502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:34:44.351Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15991.00',
          mileage: '20628'
        },
        sellArea: '800',
        dealerCd: '37150',
        certificationNumber: 9414070,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU384349',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T20:06:58.365Z'
        }
      },
      sort: [
        1591623284351
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5G5300326371502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:34:05.985Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26994.00',
          mileage: '50783'
        },
        sellArea: '800',
        dealerCd: '37150',
        certificationNumber: 9401207,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5G5300326',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T18:05:16.846Z'
        }
      },
      sort: [
        1591623245985
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7HD095626371502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:33:27.172Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22994.00',
          mileage: '28355'
        },
        sellArea: '800',
        dealerCd: '37150',
        certificationNumber: 9409008,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7HD095626',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T21:18:28.066Z'
        }
      },
      sort: [
        1591623207172
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN9EM161528371502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:32:33.751Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25805.00',
          mileage: '60889'
        },
        sellArea: '130',
        dealerCd: '37150',
        certificationNumber: 9414204,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN9EM161528',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T20:53:36.549Z'
        }
      },
      sort: [
        1591623153751
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2GU228229371502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:31:23.299Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15987.00',
          mileage: '38740'
        },
        sellArea: '160',
        dealerCd: '37150',
        certificationNumber: 9401904,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2GU228229',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T21:55:17.558Z'
        }
      },
      sort: [
        1591623083299
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS386915371502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:30:45.531Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28989.00',
          mileage: '35944'
        },
        sellArea: '800',
        dealerCd: '37150',
        certificationNumber: 9427351,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS386915',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T18:36:29.548Z'
        }
      },
      sort: [
        1591623045531
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN6HM014782380312020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:30:00.711Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31772.63',
          mileage: '47322'
        },
        sellArea: '170',
        dealerCd: '38031',
        certificationNumber: 9502086,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN6HM014782',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7568',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T12:34:47.042Z'
        }
      },
      sort: [
        1591623000711
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU707534371502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:29:39.212Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15880.00',
          mileage: '36390'
        },
        sellArea: '800',
        dealerCd: '37150',
        certificationNumber: 9434657,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU707534',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T14:08:16.022Z'
        }
      },
      sort: [
        1591622979212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK5GR577505371502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:28:53.251Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14894.00',
          mileage: '31140'
        },
        sellArea: '160',
        dealerCd: '37150',
        certificationNumber: 9403244,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK5GR577505',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T20:32:18.535Z'
        }
      },
      sort: [
        1591622933251
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV6HW626118370662020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:28:25.810Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25200.00',
          mileage: '11937'
        },
        sellArea: '800',
        dealerCd: '37066',
        certificationNumber: 9418920,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV6HW626118',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T19:12:31.840Z'
        }
      },
      sort: [
        1591622905810
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH8HS419702340442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:28:24.227Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32996.00',
          mileage: '19807'
        },
        sellArea: '170',
        dealerCd: '34044',
        certificationNumber: 9495438,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH8HS419702',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:25:02.644Z'
        }
      },
      sort: [
        1591622904227
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU657679371502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:27:21.173Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15959.00',
          mileage: '47516'
        },
        sellArea: '800',
        dealerCd: '37150',
        certificationNumber: 9409586,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU657679',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T12:53:35.976Z'
        }
      },
      sort: [
        1591622841173
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX1K1037182370662020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:27:10.371Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17999.00',
          mileage: '22526'
        },
        sellArea: '500',
        dealerCd: '37066',
        certificationNumber: 9396736,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX1K1037182',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T13:50:11.117Z'
        }
      },
      sort: [
        1591622830371
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU452546370662020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:25:59.753Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '26877'
        },
        dealerCd: '37066',
        certificationNumber: 9319246,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU452546',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-02T20:01:23.761Z'
        }
      },
      sort: [
        1591622759753
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU432102291062020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:25:20.113Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '12059'
        },
        sellArea: '160',
        dealerCd: '29106',
        certificationNumber: 9489485,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU432102',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:39:15.744Z'
        }
      },
      sort: [
        1591622720113
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE0LJ025150450882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:24:52.813Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17530.00',
          mileage: '10770'
        },
        sellArea: '500',
        dealerCd: '45088',
        certificationNumber: 9465540,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE0LJ025150',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:23:16.442Z'
        }
      },
      sort: [
        1591622692813
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV5KC016634370662020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:24:12.532Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26346.00',
          mileage: '14640'
        },
        sellArea: '500',
        dealerCd: '37066',
        certificationNumber: 9420143,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV5KC016634',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T17:34:26.774Z'
        }
      },
      sort: [
        1591622652532
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW606943370662020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:22:15.751Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19172.97',
          mileage: '13746'
        },
        sellArea: '800',
        dealerCd: '37066',
        certificationNumber: 9477940,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW606943',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:15:13.547Z'
        }
      },
      sort: [
        1591622535751
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUU4EN6FX133427291062020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:22:05.772Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '50347'
        },
        sellArea: '160',
        dealerCd: '29106',
        certificationNumber: 9489128,
        makeCode: 'TOYOTA',
        vin: '5TFUU4EN6FX133427',
        model: {
          modelDescription: 'TACOMA 4X4 ACCESS CAB V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7554',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T15:09:09.394Z'
        }
      },
      sort: [
        1591622525772
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU689702370662020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:20:56.593Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '32501'
        },
        dealerCd: '37066',
        certificationNumber: 9334085,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU689702',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-13T17:30:28.836Z'
        }
      },
      sort: [
        1591622456593
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3HW577797370662020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:20:08.432Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26234.00',
          mileage: '6037'
        },
        sellArea: '800',
        dealerCd: '37066',
        certificationNumber: 9487119,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3HW577797',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:56:30.956Z'
        }
      },
      sort: [
        1591622408432
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC4KS013273291062020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:19:13.214Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26192.00',
          mileage: '19107'
        },
        sellArea: '160',
        dealerCd: '29106',
        certificationNumber: 9489373,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC4KS013273',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:02:10.731Z'
        }
      },
      sort: [
        1591622353214
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HJ151281371092020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:18:24.254Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '17903.00',
          mileage: '43239'
        },
        sellArea: '800',
        dealerCd: '37109',
        certificationNumber: 9481389,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HJ151281',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T13:39:17.266Z'
        }
      },
      sort: [
        1591622304254
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE5LJ022504291062020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:16:03.372Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16102.00',
          mileage: '15293'
        },
        sellArea: '500',
        dealerCd: '29106',
        certificationNumber: 9489450,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE5LJ022504',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:26:08.176Z'
        }
      },
      sort: [
        1591622163372
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7JJ219727190712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:15:57.912Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18781.00',
          mileage: '26351'
        },
        sellArea: '800',
        dealerCd: '19071',
        certificationNumber: 9501666,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7JJ219727',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:31:28.337Z'
        }
      },
      sort: [
        1591622157912
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW591592220562020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:15:57.506Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18934.00',
          mileage: '41305'
        },
        sellArea: '210',
        dealerCd: '22056',
        certificationNumber: 9498906,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW591592',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:27:54.113Z'
        }
      },
      sort: [
        1591622157506
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6JU061456130702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:14:59.387Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17733.00',
          mileage: '18663'
        },
        sellArea: '210',
        dealerCd: '13070',
        certificationNumber: 9480088,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6JU061456',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T13:03:07.509Z'
        }
      },
      sort: [
        1591622099387
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW616375220562020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:14:41.452Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '24172'
        },
        sellArea: '160',
        dealerCd: '22056',
        certificationNumber: 9499829,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW616375',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:18:52.879Z'
        }
      },
      sort: [
        1591622081452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV3KW024919130702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:14:38.773Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24174.00',
          mileage: '16294'
        },
        sellArea: '500',
        dealerCd: '13070',
        certificationNumber: 9499056,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV3KW024919',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:23:39.423Z'
        }
      },
      sort: [
        1591622078773
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX2K1028555130702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:14:08.913Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18395.00',
          mileage: '15910'
        },
        sellArea: '220',
        dealerCd: '13070',
        certificationNumber: 9477753,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX2K1028555',
        model: {
          modelDescription: 'C-HR LE',
          modelSeries: 'CHR',
          modelNumber: '2402',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:46:30.303Z'
        }
      },
      sort: [
        1591622048913
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHJ143737220562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:13:59.793Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18990.00',
          mileage: '20082'
        },
        sellArea: '220',
        dealerCd: '22056',
        certificationNumber: 9497816,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHJ143737',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:28:12.359Z'
        }
      },
      sort: [
        1591622039793
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU660128130702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:13:39.013Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17894.00',
          mileage: '11918'
        },
        sellArea: '500',
        dealerCd: '13070',
        certificationNumber: 9475963,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU660128',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:30:09.159Z'
        }
      },
      sort: [
        1591622019013
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HD211397290992020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:13:18.133Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20927.00',
          mileage: '35360'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9429643,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HD211397',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T15:14:37.074Z'
        }
      },
      sort: [
        1591621998133
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC829679291062020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:13:16.387Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13500.00',
          mileage: '12154'
        },
        sellArea: '160',
        dealerCd: '29106',
        certificationNumber: 9489478,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC829679',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:35:31.003Z'
        }
      },
      sort: [
        1591621996387
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV6JJ729656220562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:13:15.833Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26496.00',
          mileage: '14728'
        },
        sellArea: '210',
        dealerCd: '22056',
        certificationNumber: 9497854,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV6JJ729656',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:38:57.464Z'
        }
      },
      sort: [
        1591621995833
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV9HJ129216130702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:12:59.933Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19780.00',
          mileage: '23573'
        },
        sellArea: '500',
        dealerCd: '13070',
        certificationNumber: 9501232,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV9HJ129216',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:27:14.855Z'
        }
      },
      sort: [
        1591621979933
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC896668290992020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:12:46.473Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16495.00',
          mileage: '13291'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9460672,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC896668',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T13:39:49.781Z'
        }
      },
      sort: [
        1591621966473
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE2LJ002084290992020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:11:31.093Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18750.34',
          mileage: '10315'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9417708,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE2LJ002084',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T22:38:35.989Z'
        }
      },
      sort: [
        1591621891093
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS417777290992020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:10:54.313Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30995.00',
          mileage: '29890'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9445353,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS417777',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T13:25:58.583Z'
        }
      },
      sort: [
        1591621854313
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREVXHW364044190712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:10:38.313Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19199.00',
          mileage: '21117'
        },
        sellArea: '160',
        dealerCd: '19071',
        certificationNumber: 9499112,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREVXHW364044',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:37:23.247Z'
        }
      },
      sort: [
        1591621838313
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HJ709052371092020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:10:30.973Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20903.00',
          mileage: '25861'
        },
        sellArea: '800',
        dealerCd: '37109',
        certificationNumber: 9502088,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HJ709052',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-08T12:38:00.217Z'
        }
      },
      sort: [
        1591621830973
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV4HD202028440142020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:09:37.373Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25532.00',
          mileage: '24931'
        },
        sellArea: '160',
        dealerCd: '44014',
        certificationNumber: 9432038,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV4HD202028',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T18:05:16.037Z'
        }
      },
      sort: [
        1591621777373
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3A1RFV1LW085388190712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:07:47.212Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31999.00',
          mileage: '6131'
        },
        sellArea: '800',
        dealerCd: '19071',
        certificationNumber: 9500475,
        makeCode: 'TOYOTA',
        vin: '2T3A1RFV1LW085388',
        model: {
          modelDescription: 'RAV4 XLE PREM AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4478',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:23:24.000Z'
        }
      },
      sort: [
        1591621667212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN2GX004983371092020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:07:08.494Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '27903.00',
          mileage: '51772'
        },
        sellArea: '800',
        dealerCd: '37109',
        certificationNumber: 9475811,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN2GX004983',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T14:51:16.184Z'
        }
      },
      sort: [
        1591621628494
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5JU569059160652020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:07:06.844Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17990.00',
          mileage: '40995'
        },
        sellArea: '220',
        dealerCd: '16065',
        certificationNumber: 9491302,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5JU569059',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T14:43:58.927Z'
        }
      },
      sort: [
        1591621626844
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN0HM021047371482020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:07:02.585Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29298.00',
          mileage: '33184'
        },
        sellArea: '800',
        dealerCd: '37148',
        certificationNumber: 9413885,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN0HM021047',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T18:52:26.591Z'
        }
      },
      sort: [
        1591621622585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ143594371482020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:06:30.544Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18992.00',
          mileage: '22781'
        },
        sellArea: '800',
        dealerCd: '37148',
        certificationNumber: 9483456,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ143594',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:32:40.426Z'
        }
      },
      sort: [
        1591621590544
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9J5493605371482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:05:45.043Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '36299.00',
          mileage: '17819'
        },
        sellArea: '800',
        dealerCd: '37148',
        certificationNumber: 9412180,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9J5493605',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T00:05:46.872Z'
        }
      },
      sort: [
        1591621545043
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH3FS198034371482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:05:10.823Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18299.00',
          mileage: '80972'
        },
        sellArea: '160',
        dealerCd: '37148',
        certificationNumber: 9415828,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH3FS198034',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T20:32:39.525Z'
        }
      },
      sort: [
        1591621510823
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH5KS741664371482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:04:37.680Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31697.00',
          mileage: '4011'
        },
        sellArea: '800',
        dealerCd: '37148',
        certificationNumber: 9439177,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH5KS741664',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T16:19:37.056Z'
        }
      },
      sort: [
        1591621477680
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DCXKS017277371092020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:04:34.631Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28903.00',
          mileage: '11025'
        },
        dealerCd: '37109',
        certificationNumber: 9385566,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DCXKS017277',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T15:11:48.557Z'
        }
      },
      sort: [
        1591621474631
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ146852371092020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:04:06.393Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19503.00',
          mileage: '35396'
        },
        sellArea: '800',
        dealerCd: '37109',
        certificationNumber: 9495149,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ146852',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:58:35.811Z'
        }
      },
      sort: [
        1591621446393
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH8GS506912450862020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:03:56.240Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25714.00',
          mileage: '32927'
        },
        sellArea: '800',
        dealerCd: '45086',
        certificationNumber: 9495186,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH8GS506912',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:38:36.313Z'
        }
      },
      sort: [
        1591621436240
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP681955430252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:02:58.662Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14797.0000',
          mileage: '35372'
        },
        sellArea: '120',
        dealerCd: '43025',
        certificationNumber: 9473189,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP681955',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:43:03.687Z'
        }
      },
      sort: [
        1591621378662
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU793174430252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:02:56.162Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17615.0000',
          mileage: '18966'
        },
        sellArea: '150',
        dealerCd: '43025',
        certificationNumber: 9466420,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU793174',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T00:42:59.552Z'
        }
      },
      sort: [
        1591621376162
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV1JW472879430252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:02:53.242Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17396.00',
          mileage: '44250'
        },
        sellArea: '600',
        dealerCd: '43025',
        certificationNumber: 9473152,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV1JW472879',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:26:14.693Z'
        }
      },
      sort: [
        1591621373242
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8HM093840160612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T13:02:00.862Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29672.00',
          mileage: '24541'
        },
        sellArea: '210',
        dealerCd: '16061',
        certificationNumber: 9491116,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8HM093840',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T13:41:11.961Z'
        }
      },
      sort: [
        1591621320862
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5ANXHM070849440142020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:59:07.701Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '35819'
        },
        sellArea: '170',
        dealerCd: '44014',
        certificationNumber: 9426749,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5ANXHM070849',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T14:25:43.369Z'
        }
      },
      sort: [
        1591621147701
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH1HS027979043642020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:57:29.401Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23937.00',
          mileage: '38724'
        },
        sellArea: '120',
        certificationNumber: 9416903,
        dealerCd: '04364',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        vin: '5TDZARFH1HS027979',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T16:50:26.498Z'
        }
      },
      sort: [
        1591621049401
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4HD198987371492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:56:18.001Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19263.73',
          mileage: '44150'
        },
        dealerCd: '37149',
        certificationNumber: 9341558,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4HD198987',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-17T13:56:29.994Z'
        }
      },
      sort: [
        1591620978001
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP623166371872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:55:08.719Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14744.00',
          mileage: '25680'
        },
        sellArea: '800',
        dealerCd: '37187',
        certificationNumber: 9496990,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP623166',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:04:48.402Z'
        }
      },
      sort: [
        1591620908719
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV1HW392804043642020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:55:00.140Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20688.00',
          mileage: '35557'
        },
        sellArea: '120',
        dealerCd: '04364',
        certificationNumber: 9412473,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV1HW392804',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T17:45:01.003Z'
        }
      },
      sort: [
        1591620900140
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN5HM020492371492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:54:46.760Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29740.00',
          mileage: '31685'
        },
        sellArea: '800',
        dealerCd: '37149',
        certificationNumber: 9496870,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN5HM020492',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T12:46:17.791Z'
        }
      },
      sort: [
        1591620886760
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN8HM019675371872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:53:12.219Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28840.00',
          mileage: '24034'
        },
        sellArea: '800',
        dealerCd: '37187',
        certificationNumber: 9474150,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN8HM019675',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T13:43:07.121Z'
        }
      },
      sort: [
        1591620792219
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW572951371492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:53:10.853Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18806.00',
          mileage: '36018'
        },
        dealerCd: '37149',
        certificationNumber: 9276300,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW572951',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-04T16:42:23.200Z'
        }
      },
      sort: [
        1591620790853
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC8KS010487043642020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:53:07.155Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25369.00',
          mileage: '24300'
        },
        sellArea: '600',
        dealerCd: '04364',
        certificationNumber: 9412456,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC8KS010487',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T17:31:32.938Z'
        }
      },
      sort: [
        1591620787155
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW612037371492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:51:39.316Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20985.00',
          mileage: '11051'
        },
        sellArea: '160',
        dealerCd: '37149',
        certificationNumber: 9477208,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW612037',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T12:28:13.558Z'
        }
      },
      sort: [
        1591620699316
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV1JW445892043642020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:51:29.435Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17563.00',
          mileage: '46427'
        },
        sellArea: '500',
        dealerCd: '04364',
        certificationNumber: 9412438,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV1JW445892',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T17:19:30.575Z'
        }
      },
      sort: [
        1591620689435
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9JU553592371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:51:08.636Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '32683'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9464309,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9JU553592',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T15:51:07.933Z'
        }
      },
      sort: [
        1591620668636
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HD210132371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:50:27.525Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16534.15',
          mileage: '21613'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9471196,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HD210132',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:44:22.802Z'
        }
      },
      sort: [
        1591620627525
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU655814043642020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:50:19.076Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19256.00',
          mileage: '42992'
        },
        sellArea: '120',
        dealerCd: '04364',
        certificationNumber: 9400951,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU655814',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T16:48:57.037Z'
        }
      },
      sort: [
        1591620619076
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5JW845536371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:49:31.717Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '13163'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9464347,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5JW845536',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T16:06:26.746Z'
        }
      },
      sort: [
        1591620571717
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DCXHS147416371492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:49:15.798Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26907.00',
          mileage: '30401'
        },
        dealerCd: '37149',
        certificationNumber: 9258293,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DCXHS147416',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-22T20:41:15.498Z'
        }
      },
      sort: [
        1591620555798
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC784297371762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:49:03.716Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '41361'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9501147,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC784297',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T15:35:06.228Z'
        }
      },
      sort: [
        1591620543716
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV6JD130652043642020-03-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:48:18.576Z',
          retailDate: '2020-03-22',
          rdrStatus: 'H',
          soldAmount: '23963.00',
          mileage: '22274'
        },
        sellArea: '120',
        dealerCd: '04364',
        certificationNumber: 9430780,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV6JD130652',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T17:58:32.027Z'
        }
      },
      sort: [
        1591620498576
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1HK9JU002118371762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:48:10.236Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26499.00',
          mileage: '18144'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9474248,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1HK9JU002118',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:44:15.694Z'
        }
      },
      sort: [
        1591620490236
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KC007280371762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:47:36.937Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25999.00',
          mileage: '16616'
        },
        sellArea: '600',
        dealerCd: '37176',
        certificationNumber: 9415281,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KC007280',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T16:35:45.949Z'
        }
      },
      sort: [
        1591620456937
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN7HM026178371762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:47:12.776Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31326.00',
          mileage: '19443'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9474256,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN7HM026178',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:49:27.289Z'
        }
      },
      sort: [
        1591620432776
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8HJ116634371492020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:47:10.077Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18337.29',
          mileage: '23444'
        },
        dealerCd: '37149',
        certificationNumber: 9339545,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8HJ116634',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-16T14:39:52.693Z'
        }
      },
      sort: [
        1591620430077
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ701030371762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:46:44.177Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20131.00',
          mileage: '38358'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9501282,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ701030',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:57:03.126Z'
        }
      },
      sort: [
        1591620404177
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8HJ139024371762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:46:17.676Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '20750'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9495209,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8HJ139024',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:49:14.581Z'
        }
      },
      sort: [
        1591620377676
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB30J1618856043642020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:46:17.175Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17562.00',
          mileage: '15622'
        },
        dealerCd: '04364',
        certificationNumber: 9375060,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB30J1618856',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB ONE',
          modelSeries: 'PRIUS C',
          modelNumber: '1201',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-10T19:32:43.385Z'
        }
      },
      sort: [
        1591620377175
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS390018371492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:45:35.315Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30475.00',
          mileage: '23037'
        },
        sellArea: '160',
        dealerCd: '37149',
        certificationNumber: 9499931,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS390018',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:00:01.247Z'
        }
      },
      sort: [
        1591620335315
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE8LJ024098201402020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:45:07.614Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17450.00',
          mileage: '13128'
        },
        sellArea: '500',
        certificationNumber: 9492779,
        dealerCd: '20140',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        vin: 'JTDS4RCE8LJ024098',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:39:31.013Z'
        }
      },
      sort: [
        1591620307614
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV1HD112324371842020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:44:28.716Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24481.00',
          mileage: '26274'
        },
        sellArea: '800',
        dealerCd: '37184',
        certificationNumber: 9409966,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV1HD112324',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T17:53:35.862Z'
        }
      },
      sort: [
        1591620268716
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW638030201402020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:44:24.556Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19295.00',
          mileage: '35961'
        },
        sellArea: '170',
        dealerCd: '20140',
        certificationNumber: 9487484,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW638030',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:04:50.547Z'
        }
      },
      sort: [
        1591620264556
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN4KM077466371492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:43:54.497Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31729.00',
          mileage: '12476'
        },
        sellArea: '800',
        dealerCd: '37149',
        certificationNumber: 9483920,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN4KM077466',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:48:28.080Z'
        }
      },
      sort: [
        1591620234497
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FKXGR561218371492020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:41:52.154Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14298.00',
          mileage: '48562'
        },
        sellArea: '800',
        dealerCd: '37149',
        certificationNumber: 9468087,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FKXGR561218',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T12:08:14.735Z'
        }
      },
      sort: [
        1591620112154
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU764861201402020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:41:30.094Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15495.00',
          mileage: '32926'
        },
        sellArea: '170',
        dealerCd: '20140',
        certificationNumber: 9471618,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU764861',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T21:26:36.723Z'
        }
      },
      sort: [
        1591620090094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM093476371722020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:30:26.490Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29999.00',
          mileage: '19268'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9468129,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM093476',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T13:03:37.638Z'
        }
      },
      sort: [
        1591619426490
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW626812371722020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:30:02.411Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16999.00',
          mileage: '28071'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9469101,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW626812',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T21:05:46.116Z'
        }
      },
      sort: [
        1591619402411
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3A1RFV4KW046826371722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:29:37.031Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28500.00',
          mileage: '2410'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9491486,
        makeCode: 'TOYOTA',
        vin: '2T3A1RFV4KW046826',
        model: {
          modelDescription: 'RAV4 XLE PREM AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4478',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:50:24.268Z'
        }
      },
      sort: [
        1591619377031
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ174560371722020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:28:21.291Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20999.00',
          mileage: '33073'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9492162,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ174560',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:52:28.217Z'
        }
      },
      sort: [
        1591619301291
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HJ705910371722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:27:23.809Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '54265'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9481733,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HJ705910',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:53:47.810Z'
        }
      },
      sort: [
        1591619243809
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU414700160512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:27:20.949Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '20341'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9420126,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU414700',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T17:24:38.444Z'
        }
      },
      sort: [
        1591619240949
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC889283371722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:26:54.829Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14048.78',
          mileage: '49107'
        },
        sellArea: '160',
        certificationNumber: 9464220,
        dealerCd: '37172',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        vin: '2T1BURHE5HC889283',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T15:09:27.223Z'
        }
      },
      sort: [
        1591619214829
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU785016371722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:26:33.889Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17491.00',
          mileage: '19167'
        },
        sellArea: '500',
        dealerCd: '37172',
        certificationNumber: 9460975,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU785016',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T17:00:39.418Z'
        }
      },
      sort: [
        1591619193889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU736438371722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:26:11.470Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16902.00',
          mileage: '18614'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9399038,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU736438',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T17:42:49.599Z'
        }
      },
      sort: [
        1591619171470
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS435735371722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:25:47.850Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28000.00',
          mileage: '34121'
        },
        sellArea: '160',
        dealerCd: '37172',
        certificationNumber: 9497716,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS435735',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:56:33.756Z'
        }
      },
      sort: [
        1591619147850
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3JW815692371722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:25:29.709Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21991.00',
          mileage: '40040'
        },
        dealerCd: '37172',
        certificationNumber: 9268448,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3JW815692',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-29T20:56:20.513Z'
        }
      },
      sort: [
        1591619129709
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU810259160512020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:25:19.008Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '37225'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9432247,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU810259',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T19:47:23.529Z'
        }
      },
      sort: [
        1591619119008
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC944978371722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:25:10.209Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12999.00',
          mileage: '57035'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9470678,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC944978',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:56:37.883Z'
        }
      },
      sort: [
        1591619110209
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXGW526852160512020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:23:08.968Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '29829'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9458426,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXGW526852',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T14:26:20.352Z'
        }
      },
      sort: [
        1591618988968
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREVXHW360336160512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:21:15.065Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19050.00',
          mileage: '33204'
        },
        sellArea: '500',
        dealerCd: '16051',
        certificationNumber: 9410242,
        makeCode: 'TOYOTA',
        vin: '2T3WFREVXHW360336',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T20:02:44.223Z'
        }
      },
      sort: [
        1591618875065
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3GW507375160512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:20:02.526Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20600.00',
          mileage: '30518'
        },
        sellArea: '160',
        dealerCd: '16051',
        certificationNumber: 9485535,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3GW507375',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:14:13.196Z'
        }
      },
      sort: [
        1591618802526
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV2HJ116131160512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:18:41.868Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20278.00',
          mileage: '8929'
        },
        sellArea: '500',
        dealerCd: '16051',
        certificationNumber: 9401464,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV2HJ116131',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T19:43:12.385Z'
        }
      },
      sort: [
        1591618721868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HJ115458371322020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:16:42.986Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19290.0000',
          mileage: '32623'
        },
        sellArea: '800',
        dealerCd: '37132',
        certificationNumber: 9476068,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HJ115458',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T17:23:28.285Z'
        }
      },
      sort: [
        1591618602986
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE5HJ540238371322020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:16:40.066Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14990.0000',
          mileage: '21707'
        },
        sellArea: '800',
        dealerCd: '37132',
        certificationNumber: 9497017,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE5HJ540238',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:22:25.786Z'
        }
      },
      sort: [
        1591618600066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC2HS846790371322020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:16:36.885Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20000.0000',
          mileage: '52527'
        },
        sellArea: '800',
        dealerCd: '37132',
        certificationNumber: 9476059,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC2HS846790',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T17:20:30.951Z'
        }
      },
      sort: [
        1591618596885
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4JW801381371322020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:16:34.304Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19980.0000',
          mileage: '36406'
        },
        sellArea: '500',
        dealerCd: '37132',
        certificationNumber: 9476063,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4JW801381',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T17:21:51.486Z'
        }
      },
      sort: [
        1591618594304
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F18GX532847371322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:16:31.826Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28495.0000',
          mileage: '66660'
        },
        sellArea: '800',
        dealerCd: '37132',
        certificationNumber: 9487193,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F18GX532847',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:23:52.719Z'
        }
      },
      sort: [
        1591618591826
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS511423371322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:16:29.704Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27690.0000',
          mileage: '45735'
        },
        sellArea: '800',
        dealerCd: '37132',
        certificationNumber: 9497036,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS511423',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:28:16.705Z'
        }
      },
      sort: [
        1591618589704
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV0HJ124591371322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:16:27.351Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22990.0000',
          mileage: '35389'
        },
        sellArea: '800',
        dealerCd: '37132',
        certificationNumber: 9468809,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV0HJ124591',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:46:19.451Z'
        }
      },
      sort: [
        1591618587351
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN7HM017853371322020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:16:24.707Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28260.0000',
          mileage: '40850'
        },
        dealerCd: '37132',
        certificationNumber: 9314142,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN7HM017853',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-31T13:20:20.985Z'
        }
      },
      sort: [
        1591618584707
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2KC003601371322020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:16:22.066Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24442.0000',
          mileage: '19137'
        },
        sellArea: '500',
        dealerCd: '37132',
        certificationNumber: 9400561,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2KC003601',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T14:32:48.543Z'
        }
      },
      sort: [
        1591618582066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH6HS423344160512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:15:02.424Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30586.00',
          mileage: '47129'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9486852,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH6HS423344',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T12:22:24.043Z'
        }
      },
      sort: [
        1591618502424
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV4JJ744284160512020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:13:42.003Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '24697'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9483877,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV4JJ744284',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:34:11.118Z'
        }
      },
      sort: [
        1591618422003
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC4HS817792280292020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T12:10:50.545Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23367.00',
          mileage: '26505'
        },
        sellArea: '160',
        dealerCd: '28029',
        certificationNumber: 9464900,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC4HS817792',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T21:11:25.191Z'
        }
      },
      sort: [
        1591618250545
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU0J3547371160042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:56:49.697Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24045.00',
          mileage: '28312'
        },
        sellArea: '220',
        dealerCd: '16004',
        certificationNumber: 9498705,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU0J3547371',
        model: {
          modelDescription: 'PRIUS THREE TOURING',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1226',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T12:33:30.959Z'
        }
      },
      sort: [
        1591617409697
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDW5G16HS149405410702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:23:57.207Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '45995.00',
          mileage: '32863'
        },
        sellArea: '220',
        dealerCd: '41070',
        certificationNumber: 9442590,
        makeCode: 'TOYOTA',
        vin: '5TDDW5G16HS149405',
        model: {
          modelDescription: 'SEQUOIA 4X4 PLAT 7-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7944',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T20:51:08.717Z'
        }
      },
      sort: [
        1591615437207
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU772898410702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:22:44.105Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19987.00',
          mileage: '14863'
        },
        dealerCd: '41070',
        certificationNumber: 9352792,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU772898',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-24T19:45:09.905Z'
        }
      },
      sort: [
        1591615364105
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HJ705903410702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:22:03.630Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21120.00',
          mileage: '39217'
        },
        sellArea: '210',
        dealerCd: '41070',
        certificationNumber: 9452577,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HJ705903',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T20:51:22.155Z'
        }
      },
      sort: [
        1591615323630
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS400866410702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:21:22.792Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '36339'
        },
        sellArea: '230',
        dealerCd: '41070',
        certificationNumber: 9459950,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS400866',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:57:04.662Z'
        }
      },
      sort: [
        1591615282792
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV3HW353406410702020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:20:51.612Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21005.00',
          mileage: '37833'
        },
        sellArea: '600',
        dealerCd: '41070',
        certificationNumber: 9460189,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV3HW353406',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T21:07:42.295Z'
        }
      },
      sort: [
        1591615251612
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR2H5153849410702020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:20:02.211Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32992.00',
          mileage: '43547'
        },
        sellArea: '600',
        dealerCd: '41070',
        certificationNumber: 9442579,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR2H5153849',
        model: {
          modelDescription: '4RUNNER 4X2 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8648',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T20:43:19.578Z'
        }
      },
      sort: [
        1591615202211
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN6LM125519410702020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:19:24.231Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31492.00',
          mileage: '2752'
        },
        sellArea: '220',
        dealerCd: '41070',
        certificationNumber: 9483836,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN6LM125519',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:13:57.300Z'
        }
      },
      sort: [
        1591615164231
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW630306280242020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:16:06.528Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18090.00',
          mileage: '26617'
        },
        sellArea: '170',
        dealerCd: '28024',
        certificationNumber: 9480049,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW630306',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T11:18:44.408Z'
        }
      },
      sort: [
        1591614966528
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW556150280242020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:15:08.751Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16848.00',
          mileage: '47169'
        },
        sellArea: '170',
        dealerCd: '28024',
        certificationNumber: 9480644,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW556150',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T18:08:10.892Z'
        }
      },
      sort: [
        1591614908751
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV5JJ205647280242020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:11:43.847Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20244.80',
          mileage: '16053'
        },
        sellArea: '170',
        dealerCd: '28024',
        certificationNumber: 9483987,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV5JJ205647',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:12:27.482Z'
        }
      },
      sort: [
        1591614703847
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS412021280242020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T11:08:49.964Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27262.00',
          mileage: '36105'
        },
        sellArea: '160',
        dealerCd: '28024',
        certificationNumber: 9463824,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS412021',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T10:41:48.951Z'
        }
      },
      sort: [
        1591614529964
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DCXHS795366042822020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T04:04:26.130Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26998.00',
          mileage: '19793'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9484391,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DCXHS795366',
        model: {
          modelDescription: 'SIENNA SE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5343',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:59:14.372Z'
        }
      },
      sort: [
        1591589066130
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK6LU891695042822020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T04:01:11.388Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '6804'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9471035,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK6LU891695',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T17:40:25.594Z'
        }
      },
      sort: [
        1591588871388
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU297595042822020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T03:59:24.506Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '13176'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9481909,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU297595',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:12:46.046Z'
        }
      },
      sort: [
        1591588764506
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU5G3023705048192020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T03:49:33.739Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18694.00',
          mileage: '58098'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9460296,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU5G3023705',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T22:08:32.901Z'
        }
      },
      sort: [
        1591588173739
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DCXES523551460712020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T03:28:06.742Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20891.00',
          mileage: '50575'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9499598,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DCXES523551',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:00:41.533Z'
        }
      },
      sort: [
        1591586886742
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KW028026460712020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T03:25:23.579Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25900.00',
          mileage: '10139'
        },
        sellArea: '500',
        dealerCd: '46071',
        certificationNumber: 9451644,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KW028026',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T21:03:30.287Z'
        }
      },
      sort: [
        1591586723579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU763511460712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T03:22:44.017Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17760.00',
          mileage: '22307'
        },
        sellArea: '150',
        dealerCd: '46071',
        certificationNumber: 9494151,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU763511',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:27:49.519Z'
        }
      },
      sort: [
        1591586564017
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU688172460712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T03:19:54.918Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19248.00',
          mileage: '33564'
        },
        sellArea: '500',
        dealerCd: '46071',
        certificationNumber: 9444081,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU688172',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T21:32:31.378Z'
        }
      },
      sort: [
        1591586394918
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV2HD110548460872020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T02:06:25.719Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25018.00',
          mileage: '47706'
        },
        sellArea: '130',
        dealerCd: '46087',
        certificationNumber: 9476633,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV2HD110548',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:16:38.841Z'
        }
      },
      sort: [
        1591581985719
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEM5F10JX126939042222020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T01:55:01.715Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '31950.00',
          mileage: '45368'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9493393,
        makeCode: 'TOYOTA',
        vin: '5TFEM5F10JX126939',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8259',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T01:02:18.719Z'
        }
      },
      sort: [
        1591581301715
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFUXG3500726460872020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T01:49:52.649Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19768.00',
          mileage: '40574'
        },
        sellArea: '130',
        dealerCd: '46087',
        certificationNumber: 9496442,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFUXG3500726',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:07:43.477Z'
        }
      },
      sort: [
        1591580992649
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH6HS510989042222020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T01:47:38.885Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '26896.00',
          mileage: '40930'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9407124,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH6HS510989',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T17:52:26.787Z'
        }
      },
      sort: [
        1591580858885
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP666268042222020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T01:43:16.101Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '13898.00',
          mileage: '28942'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9494521,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP666268',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:38:25.656Z'
        }
      },
      sort: [
        1591580596101
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH1FS206995460872020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T01:38:36.777Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '27758.07',
          mileage: '55701'
        },
        sellArea: '130',
        dealerCd: '46087',
        certificationNumber: 9494873,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH1FS206995',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T02:44:41.494Z'
        }
      },
      sort: [
        1591580316777
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC752422460872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T01:31:34.068Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13200.00',
          mileage: '21844'
        },
        sellArea: '130',
        dealerCd: '46087',
        certificationNumber: 9493427,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC752422',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T01:39:10.287Z'
        }
      },
      sort: [
        1591579894068
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE5K3030506460872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T01:25:57.783Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18484.00',
          mileage: '10639'
        },
        sellArea: '130',
        dealerCd: '46087',
        certificationNumber: 9461657,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE5K3030506',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T03:42:01.956Z'
        }
      },
      sort: [
        1591579557783
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV0LW062358041192020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T01:18:25.574Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23245.00',
          mileage: '8474'
        },
        sellArea: '110',
        dealerCd: '04119',
        certificationNumber: 9479807,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV0LW062358',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-24T00:37:06.804Z'
        }
      },
      sort: [
        1591579105574
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXG5401846046822020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:56:59.264Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '31877.00',
          mileage: '54362'
        },
        sellArea: '150',
        dealerCd: '04682',
        certificationNumber: 9499777,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXG5401846',
        model: {
          modelDescription: '4RUNNER 4X4 TRAIL PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:59:45.508Z'
        }
      },
      sort: [
        1591577819264
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE2LJ006734045632020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:52:27.200Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '10463'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9419200,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE2LJ006734',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T21:55:25.326Z'
        }
      },
      sort: [
        1591577547200
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9HM056280110302020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:39:25.992Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '32515.00',
          mileage: '43753'
        },
        sellArea: '220',
        dealerCd: '11030',
        certificationNumber: 9496229,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9HM056280',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:38:12.955Z'
        }
      },
      sort: [
        1591576765992
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV0KW007513042222020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:38:38.552Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '25591.00',
          mileage: '29131'
        },
        sellArea: '500',
        dealerCd: '04222',
        certificationNumber: 9478097,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV0KW007513',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T20:11:48.706Z'
        }
      },
      sort: [
        1591576718552
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRM5F16HX110529042222020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:36:57.632Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '24750.00',
          mileage: '70550'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9494127,
        makeCode: 'TOYOTA',
        vin: '5TFRM5F16HX110529',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8240',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:14:27.131Z'
        }
      },
      sort: [
        1591576617632
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE5LP014933042222020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:36:19.051Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19991.00',
          mileage: '14950'
        },
        sellArea: '600',
        dealerCd: '04222',
        certificationNumber: 9478577,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE5LP014933',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T01:21:32.473Z'
        }
      },
      sort: [
        1591576579051
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB6FU173943042222020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:35:08.431Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '15592.00',
          mileage: '65673'
        },
        sellArea: '500',
        dealerCd: '04222',
        certificationNumber: 9483886,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB6FU173943',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:36:12.221Z'
        }
      },
      sort: [
        1591576508431
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3KM257454020522020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:20:38.582Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '34999.00',
          mileage: '21656'
        },
        sellArea: '600',
        dealerCd: '02052',
        certificationNumber: 9502030,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3KM257454',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T23:43:57.762Z'
        }
      },
      sort: [
        1591575638582
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFLU4EN2EX105652020522020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:17:53.978Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24999.00',
          mileage: '81142'
        },
        sellArea: '170',
        dealerCd: '02052',
        certificationNumber: 9502032,
        makeCode: 'TOYOTA',
        vin: '5TFLU4EN2EX105652',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7593',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T23:46:17.844Z'
        }
      },
      sort: [
        1591575473978
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV5KW007961042222020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:16:34.019Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '22491.00',
          mileage: '30339'
        },
        sellArea: '500',
        dealerCd: '04222',
        certificationNumber: 9480602,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV5KW007961',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T17:52:54.811Z'
        }
      },
      sort: [
        1591575394019
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV7KW003894042222020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:15:46.059Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '32194'
        },
        sellArea: '500',
        dealerCd: '04222',
        certificationNumber: 9468895,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV7KW003894',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:26:20.758Z'
        }
      },
      sort: [
        1591575346059
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW598743020522020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:14:05.197Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21300.00',
          mileage: '22345'
        },
        sellArea: '150',
        dealerCd: '02052',
        certificationNumber: 9502033,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW598743',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T23:48:26.804Z'
        }
      },
      sort: [
        1591575245197
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F19HX606751020522020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:10:32.236Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '39289.59',
          mileage: '28386'
        },
        sellArea: '110',
        dealerCd: '02052',
        certificationNumber: 9502034,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F19HX606751',
        model: {
          modelDescription: 'TUNDRA 4X4 PLATINUM CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8375',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T23:50:16.447Z'
        }
      },
      sort: [
        1591575032236
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV2HW605699045632020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:07:28.973Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20800.00',
          mileage: '55025'
        },
        sellArea: '150',
        dealerCd: '04563',
        certificationNumber: 9396325,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV2HW605699',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T23:49:19.755Z'
        }
      },
      sort: [
        1591574848973
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP617397020522020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:03:53.193Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14910.00',
          mileage: '22548'
        },
        sellArea: '150',
        dealerCd: '02052',
        certificationNumber: 9502035,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP617397',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T23:52:27.754Z'
        }
      },
      sort: [
        1591574633193
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN0HM029617020522020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-08T00:00:11.088Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '33000.00',
          mileage: '35513'
        },
        sellArea: '150',
        dealerCd: '02052',
        certificationNumber: 9502036,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN0HM029617',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7568',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T23:56:21.514Z'
        }
      },
      sort: [
        1591574411088
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV7KW009414041872020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:55:10.366Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23988.00',
          mileage: '13659'
        },
        sellArea: '600',
        dealerCd: '04187',
        certificationNumber: 9493801,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV7KW009414',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:45:45.722Z'
        }
      },
      sort: [
        1591574110366
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F1XJX726626042902020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:51:19.445Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '37995.00',
          mileage: '41280'
        },
        sellArea: '110',
        dealerCd: '04290',
        certificationNumber: 9499593,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F1XJX726626',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:59:17.833Z'
        }
      },
      sort: [
        1591573879445
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV1JW459971042902020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:49:05.144Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '20250.00',
          mileage: '45186'
        },
        sellArea: '500',
        dealerCd: '04290',
        certificationNumber: 9501797,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV1JW459971',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T00:29:16.816Z'
        }
      },
      sort: [
        1591573745144
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3JU109432042902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:47:56.722Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '21760'
        },
        sellArea: '110',
        dealerCd: '04290',
        certificationNumber: 9499529,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3JU109432',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:34:16.338Z'
        }
      },
      sort: [
        1591573676722
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE7K3038607020552020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:47:13.760Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21292.00',
          mileage: '9406'
        },
        sellArea: '120',
        dealerCd: '02055',
        certificationNumber: 9480557,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE7K3038607',
        model: {
          modelDescription: 'COROLLA HATCHBACK XSE 5DrHATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6274',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T17:25:55.237Z'
        }
      },
      sort: [
        1591573633760
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F18HX634575020522020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:40:47.537Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '43589.00',
          mileage: '35779'
        },
        sellArea: '600',
        dealerCd: '02052',
        certificationNumber: 9502028,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F18HX634575',
        model: {
          modelDescription: 'TUNDRA TRD PRO CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8373',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T23:02:51.846Z'
        }
      },
      sort: [
        1591573247537
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV7KW062086020522020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:23:53.247Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22799.61',
          mileage: '18646'
        },
        sellArea: '150',
        dealerCd: '02052',
        certificationNumber: 9456115,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV7KW062086',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T18:16:19.368Z'
        }
      },
      sort: [
        1591572233247
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH8HS414295020522020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:10:41.010Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25149.00',
          mileage: '44987'
        },
        sellArea: '120',
        dealerCd: '02052',
        certificationNumber: 9488563,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH8HS414295',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:13:29.744Z'
        }
      },
      sort: [
        1591571441010
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC2HS817189150502020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:09:26.949Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '21300.00',
          mileage: '51174'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9412227,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC2HS817189',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T00:50:55.902Z'
        }
      },
      sort: [
        1591571366949
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU4H3048807150502020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T23:08:24.728Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '17330.00',
          mileage: '43535'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9443801,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU4H3048807',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T14:50:17.490Z'
        }
      },
      sort: [
        1591571304728
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0JD254916360852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:53:18.861Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '11294'
        },
        sellArea: '130',
        dealerCd: '36085',
        certificationNumber: 9498354,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0JD254916',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T23:39:45.021Z'
        }
      },
      sort: [
        1591570398861
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F18KX849455360852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:49:46.778Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '40995.00',
          mileage: '11296'
        },
        sellArea: '130',
        dealerCd: '36085',
        certificationNumber: 9482642,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F18KX849455',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T23:50:29.113Z'
        }
      },
      sort: [
        1591570186778
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE1HJ539507041542020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:39:33.892Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '14888.00',
          mileage: '29134'
        },
        sellArea: '130',
        dealerCd: '04154',
        certificationNumber: 9427244,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE1HJ539507',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6273',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T17:58:35.513Z'
        }
      },
      sort: [
        1591569573892
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC850469360722020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:38:55.985Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '15997.00',
          mileage: '32580'
        },
        sellArea: '130',
        dealerCd: '36072',
        certificationNumber: 9482636,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC850469',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T23:36:31.146Z'
        }
      },
      sort: [
        1591569535985
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR7G5134289041542020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:38:33.790Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '31578.00',
          mileage: '43780'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9493970,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR7G5134289',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8646',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:10:02.425Z'
        }
      },
      sort: [
        1591569513790
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBXXJR031031041542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:37:52.751Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19665.00',
          mileage: '10246'
        },
        dealerCd: '04154',
        certificationNumber: 9389192,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBXXJR031031',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T17:30:38.477Z'
        }
      },
      sort: [
        1591569472751
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU9J3062806041542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:37:30.450Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22397.00',
          mileage: '33949'
        },
        sellArea: '120',
        dealerCd: '04154',
        certificationNumber: 9418638,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU9J3062806',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T17:10:33.324Z'
        }
      },
      sort: [
        1591569450450
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU4GJ055271041542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:37:08.191Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22788.00',
          mileage: '28780'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9494280,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU4GJ055271',
        model: {
          modelDescription: 'PRIUS v Five Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1249',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:06:33.312Z'
        }
      },
      sort: [
        1591569428191
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRWRFV3KD012040041542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:36:45.091Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28997.00',
          mileage: '15066'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9495306,
        makeCode: 'TOYOTA',
        vin: 'JTMRWRFV3KD012040',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:35:08.960Z'
        }
      },
      sort: [
        1591569405091
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK5JU502116041542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:36:08.350Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23637.00',
          mileage: '31514'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9433358,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK5JU502116',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T20:49:29.333Z'
        }
      },
      sort: [
        1591569368350
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0HS804354041542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:35:43.120Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25300.00',
          mileage: '27564'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9393963,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC0HS804354',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T20:25:33.814Z'
        }
      },
      sort: [
        1591569343120
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F15EX376928041542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:35:02.810Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '35391.00',
          mileage: '79483'
        },
        dealerCd: '04154',
        certificationNumber: 9384236,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F15EX376928',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8376',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T20:05:24.856Z'
        }
      },
      sort: [
        1591569302810
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAEXLP024624041542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:34:06.610Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16798.00',
          mileage: '9201'
        },
        dealerCd: '04154',
        certificationNumber: 9377021,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAEXLP024624',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-11T20:25:19.321Z'
        }
      },
      sort: [
        1591569246610
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN0KX192962041542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:33:17.048Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '42313.31',
          mileage: '6758'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9501478,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN0KX192962',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7545',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:38:30.718Z'
        }
      },
      sort: [
        1591569197048
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV0HY160323047372020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:25:09.245Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '9998.00',
          mileage: '78413'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9494777,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV0HY160323',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T23:03:38.518Z'
        }
      },
      sort: [
        1591568709245
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRM5F16JX125201047372020-05-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:24:05.743Z',
          retailDate: '2020-05-10',
          rdrStatus: 'H',
          soldAmount: '22998.00',
          mileage: '41058'
        },
        sellArea: '600',
        dealerCd: '04737',
        certificationNumber: 9464844,
        makeCode: 'TOYOTA',
        vin: '5TFRM5F16JX125201',
        model: {
          modelDescription: 'TUNDRA 4X2 SR DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8239',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T20:33:29.388Z'
        }
      },
      sort: [
        1591568645743
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0HS834048047372020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:23:36.337Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '23698.00',
          mileage: '36985'
        },
        sellArea: '120',
        dealerCd: '04737',
        certificationNumber: 9488350,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC0HS834048',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T22:02:06.876Z'
        }
      },
      sort: [
        1591568616337
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK6GR583054047372020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T22:22:37.183Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '12998.00',
          mileage: '57605'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9497167,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK6GR583054',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:30:50.899Z'
        }
      },
      sort: [
        1591568557183
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV1HD092771040882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:46:08.383Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24288.00',
          mileage: '23788'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9451828,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV1HD092771',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T01:14:10.001Z'
        }
      },
      sort: [
        1591566368383
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3MWRFV4KW037333040882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:45:13.824Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26288.00',
          mileage: '30899'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9490550,
        makeCode: 'TOYOTA',
        vin: '2T3MWRFV4KW037333',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T18:06:35.999Z'
        }
      },
      sort: [
        1591566313824
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC9HS857392040882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:44:31.322Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30888.00',
          mileage: '33770'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9484052,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC9HS857392',
        model: {
          modelDescription: 'SIENNA LTD PREM FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5357',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:28:17.040Z'
        }
      },
      sort: [
        1591566271322
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV8HD112143040882020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:40:17.480Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22488.00',
          mileage: '49987'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9460397,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV8HD112143',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T00:45:15.805Z'
        }
      },
      sort: [
        1591566017480
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV9JW456108040882020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:39:18.279Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22988.00',
          mileage: '7385'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9451824,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV9JW456108',
        model: {
          modelDescription: 'RAV4 Adventure FWD',
          modelSeries: 'RAV 4X2',
          modelNumber: '4445',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T00:44:25.816Z'
        }
      },
      sort: [
        1591565958279
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE1HJ535909040882020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:38:50.479Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '14990.00',
          mileage: '35198'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9499675,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE1HJ535909',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:28:20.627Z'
        }
      },
      sort: [
        1591565930479
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KW069118290902020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:33:33.056Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '26000.00',
          mileage: '5886'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9478578,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KW069118',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T01:21:51.292Z'
        }
      },
      sort: [
        1591565613056
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS391808290902020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:31:36.216Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '36000.00',
          mileage: '9132'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9469235,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS391808',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T22:35:36.112Z'
        }
      },
      sort: [
        1591565496216
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU425383290902020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:30:32.695Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18098.00',
          mileage: '18405'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9427129,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU425383',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T17:07:37.202Z'
        }
      },
      sort: [
        1591565432695
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KC027417290902020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:29:25.373Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '7110'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9446277,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KC027417',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-20T19:51:30.029Z'
        }
      },
      sort: [
        1591565365373
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDFPRAE4LJ038592290902020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:28:07.954Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21750.00',
          mileage: '3123'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9448134,
        makeCode: 'TOYOTA',
        vin: 'JTDFPRAE4LJ038592',
        model: {
          modelDescription: 'COROLLA XLE',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T19:03:05.741Z'
        }
      },
      sort: [
        1591565287954
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH1HS365179290902020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:27:09.213Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25600.00',
          mileage: '35504'
        },
        dealerCd: '29090',
        certificationNumber: 9368723,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH1HS365179',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T23:27:33.050Z'
        }
      },
      sort: [
        1591565229213
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC806363290902020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:25:58.334Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '7729'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9469108,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC806363',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T21:11:42.720Z'
        }
      },
      sort: [
        1591565158334
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3JW757311290902020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:25:05.720Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20891.00',
          mileage: '33177'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9445253,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3JW757311',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-16T21:27:20.258Z'
        }
      },
      sort: [
        1591565105720
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH1FS155260290902020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:24:15.510Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28000.00',
          mileage: '22326'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9427163,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH1FS155260',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T17:29:58.434Z'
        }
      },
      sort: [
        1591565055510
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFHXGS228687290902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:23:00.510Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29998.00',
          mileage: '52135'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9448267,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFHXGS228687',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T21:13:20.202Z'
        }
      },
      sort: [
        1591564980510
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HD204552290902020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:22:06.509Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25999.00',
          mileage: '24863'
        },
        dealerCd: '29090',
        certificationNumber: 9390290,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HD204552',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T13:51:27.569Z'
        }
      },
      sort: [
        1591564926509
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ123278290902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:19:53.889Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22763.00',
          mileage: '34244'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9469167,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ123278',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T21:48:16.023Z'
        }
      },
      sort: [
        1591564793889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS383170290902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:18:45.587Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34000.00',
          mileage: '29571'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9469211,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS383170',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T22:15:27.861Z'
        }
      },
      sort: [
        1591564725587
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2LS022811290902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:17:32.966Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '33850.00',
          mileage: '21725'
        },
        sellArea: '600',
        dealerCd: '29090',
        certificationNumber: 9427067,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2LS022811',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T16:38:52.512Z'
        }
      },
      sort: [
        1591564652966
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS417785290902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:16:41.805Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29000.00',
          mileage: '22079'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9500577,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS417785',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T00:42:30.195Z'
        }
      },
      sort: [
        1591564601805
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ700583290902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:10:39.738Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '29970'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9469252,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ700583',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T22:47:36.554Z'
        }
      },
      sort: [
        1591564239738
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK8LU348171040962020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:10:00.758Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21490.00',
          mileage: '2023'
        },
        sellArea: '110',
        certificationNumber: 9454293,
        dealerCd: '04096',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        vin: '4T1G11AK8LU348171',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T21:58:14.048Z'
        }
      },
      sort: [
        1591564200758
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW615247290902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:09:17.616Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21999.00',
          mileage: '27279'
        },
        sellArea: '800',
        dealerCd: '29090',
        certificationNumber: 9500562,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW615247',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T00:25:11.190Z'
        }
      },
      sort: [
        1591564157616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK7JU065856040962020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:08:56.778Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23488.00',
          mileage: '21810'
        },
        sellArea: '110',
        dealerCd: '04096',
        certificationNumber: 9478348,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK7JU065856',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:06:02.297Z'
        }
      },
      sort: [
        1591564136778
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW557258290902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:08:10.997Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22998.00',
          mileage: '26404'
        },
        dealerCd: '29090',
        certificationNumber: 9368399,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW557258',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T20:21:46.002Z'
        }
      },
      sort: [
        1591564090997
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC028978290902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T21:07:23.937Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '7405'
        },
        sellArea: '160',
        dealerCd: '29090',
        certificationNumber: 9478544,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC028978',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T00:50:12.502Z'
        }
      },
      sort: [
        1591564043937
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU0L3106696040962020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T20:40:28.921Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22790.00',
          mileage: '8756'
        },
        sellArea: '110',
        dealerCd: '04096',
        certificationNumber: 9457067,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU0L3106696',
        model: {
          modelDescription: 'PRIUS XLE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T21:38:43.662Z'
        }
      },
      sort: [
        1591562428921
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU272650070092020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T20:13:13.082Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '16888.00',
          mileage: '33141'
        },
        sellArea: '160',
        dealerCd: '07009',
        certificationNumber: 9433922,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU272650',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T16:09:58.599Z'
        }
      },
      sort: [
        1591560793082
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ130312070092020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T20:11:10.698Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '19888.00',
          mileage: '25722'
        },
        sellArea: '800',
        dealerCd: '07009',
        certificationNumber: 9414392,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ130312',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T21:59:44.991Z'
        }
      },
      sort: [
        1591560670698
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5JW852095020422020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:32:09.976Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '25558.00',
          mileage: '12858'
        },
        sellArea: '150',
        dealerCd: '02042',
        certificationNumber: 9424740,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV5JW852095',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T23:05:11.140Z'
        }
      },
      sort: [
        1591558329976
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3FW357161110282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:25:05.729Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16399.00',
          mileage: '64341'
        },
        sellArea: '130',
        dealerCd: '11028',
        certificationNumber: 9485746,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3FW357161',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:08:59.167Z'
        }
      },
      sort: [
        1591557905729
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU130577110282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:23:04.710Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '14040'
        },
        sellArea: '130',
        dealerCd: '11028',
        certificationNumber: 9476766,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU130577',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T23:06:38.144Z'
        }
      },
      sort: [
        1591557784710
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6HM070864450702020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:17:31.085Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30330.90',
          mileage: '31419'
        },
        sellArea: '170',
        dealerCd: '45070',
        certificationNumber: 9495544,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6HM070864',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:13:13.362Z'
        }
      },
      sort: [
        1591557451085
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK0J3024487020422020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:15:20.664Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18690.00',
          mileage: '8576'
        },
        sellArea: '150',
        dealerCd: '02042',
        certificationNumber: 9443128,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK0J3024487',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-10T19:38:55.646Z'
        }
      },
      sort: [
        1591557320664
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREVXHW370278020422020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:13:49.322Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18941.95',
          mileage: '27292'
        },
        sellArea: '150',
        dealerCd: '02042',
        certificationNumber: 9457228,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREVXHW370278',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T05:01:54.927Z'
        }
      },
      sort: [
        1591557229322
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE8HJ541836460892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:11:25.528Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '14875'
        },
        sellArea: '130',
        dealerCd: '46089',
        certificationNumber: 9477892,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE8HJ541836',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:49:46.914Z'
        }
      },
      sort: [
        1591557085528
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU374300460892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:07:14.628Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16990.00',
          mileage: '6947'
        },
        sellArea: '600',
        dealerCd: '46089',
        certificationNumber: 9396115,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU374300',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T21:56:38.502Z'
        }
      },
      sort: [
        1591556834628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU803919046362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:06:38.589Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '30885'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9422710,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU803919',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T18:49:02.327Z'
        }
      },
      sort: [
        1591556798589
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH4HS512899046362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:05:31.101Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26989.00',
          mileage: '45169'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9467580,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH4HS512899',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T21:37:18.286Z'
        }
      },
      sort: [
        1591556731101
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV1HJ129515046362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:04:30.147Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '22587'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9486266,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV1HJ129515',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:28:26.108Z'
        }
      },
      sort: [
        1591556670147
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH7HS518261046362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:03:14.647Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28499.00',
          mileage: '41419'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9463185,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH7HS518261',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T18:32:11.209Z'
        }
      },
      sort: [
        1591556594647
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP608510046362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:02:06.825Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14599.00',
          mileage: '23468'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9492897,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP608510',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:00:07.976Z'
        }
      },
      sort: [
        1591556526825
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV1HD103580046362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T19:01:09.768Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20890.00',
          mileage: '20284'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9501418,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV1HD103580',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:44:55.189Z'
        }
      },
      sort: [
        1591556469768
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3EWRFV7KW018800460892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:59:52.707Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34999.00',
          mileage: '7739'
        },
        sellArea: '130',
        certificationNumber: 9486316,
        dealerCd: '46089',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 HYBRID XSE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2019
        },
        vin: '2T3EWRFV7KW018800',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:48:11.594Z'
        }
      },
      sort: [
        1591556392707
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC856675046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:58:36.086Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13999.00',
          mileage: '40407'
        },
        sellArea: '150',
        dealerCd: '04636',
        certificationNumber: 9492928,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC856675',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:07:19.165Z'
        }
      },
      sort: [
        1591556316086
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV7HD103790046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:57:33.967Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22598.90',
          mileage: '25770'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9488614,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV7HD103790',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:52:47.029Z'
        }
      },
      sort: [
        1591556253967
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV2HJ126655046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:56:28.866Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18997.69',
          mileage: '26518'
        },
        sellArea: '220',
        dealerCd: '04636',
        certificationNumber: 9485591,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV2HJ126655',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:30:04.873Z'
        }
      },
      sort: [
        1591556188866
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU773226046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:55:38.666Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16399.00',
          mileage: '27920'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9487419,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU773226',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:39:44.333Z'
        }
      },
      sort: [
        1591556138666
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KW030929460892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:52:31.726Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24990.00',
          mileage: '23239'
        },
        sellArea: '500',
        dealerCd: '46089',
        certificationNumber: 9394113,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KW030929',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T21:10:26.616Z'
        }
      },
      sort: [
        1591555951726
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH4HS204356046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:51:59.944Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27999.00',
          mileage: '39233'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9425832,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH4HS204356',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T19:17:08.417Z'
        }
      },
      sort: [
        1591555919944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE0K3004735460892020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:50:05.005Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17690.00',
          mileage: '28080'
        },
        dealerCd: '46089',
        certificationNumber: 9361762,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE0K3004735',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-30T20:41:28.025Z'
        }
      },
      sort: [
        1591555805005
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV7HW320909046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:49:33.865Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18595.45',
          mileage: '47186'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9479761,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV7HW320909',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T23:05:05.930Z'
        }
      },
      sort: [
        1591555773865
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU772193460892020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:48:07.824Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '22971'
        },
        dealerCd: '46089',
        certificationNumber: 9373050,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU772193',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T21:59:38.370Z'
        }
      },
      sort: [
        1591555687824
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU422856046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:47:15.444Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16998.28',
          mileage: '33366'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9496976,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU422856',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:55:22.461Z'
        }
      },
      sort: [
        1591555635444
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU786371020422020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:46:20.803Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20495.00',
          mileage: '27113'
        },
        sellArea: '500',
        dealerCd: '02042',
        certificationNumber: 9477933,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU786371',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:11:24.006Z'
        }
      },
      sort: [
        1591555580803
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU1H3531903460892020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:34:32.602Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22250.00',
          mileage: '25523'
        },
        dealerCd: '46089',
        certificationNumber: 9371428,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU1H3531903',
        model: {
          modelDescription: 'PRIUS FOUR',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1227',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-06T21:33:00.804Z'
        }
      },
      sort: [
        1591554872602
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8G5340299460892020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:31:50.741Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33000.00',
          mileage: '26957'
        },
        sellArea: '130',
        dealerCd: '46089',
        certificationNumber: 9486270,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8G5340299',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:29:36.401Z'
        }
      },
      sort: [
        1591554710741
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2J5537945460892020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:30:30.300Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '34000.00',
          mileage: '35546'
        },
        dealerCd: '46089',
        certificationNumber: 9363225,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2J5537945',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-31T17:37:35.517Z'
        }
      },
      sort: [
        1591554630300
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV2EW183462460892020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:29:26.800Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '51406'
        },
        sellArea: '120',
        dealerCd: '46089',
        certificationNumber: 9486310,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV2EW183462',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:44:30.898Z'
        }
      },
      sort: [
        1591554566800
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC2LS036204046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:26:59.620Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28799.00',
          mileage: '43235'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9493731,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC2LS036204',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:02:58.115Z'
        }
      },
      sort: [
        1591554419620
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV7HJ131708046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:24:56.699Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '33820'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9488368,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV7HJ131708',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T22:06:18.622Z'
        }
      },
      sort: [
        1591554296699
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP601221046362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:24:01.558Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '24414'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9491943,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP601221',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:48:06.298Z'
        }
      },
      sort: [
        1591554241558
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAZ5CN4GX018338046762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:14:33.598Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28974.00',
          mileage: '49948'
        },
        sellArea: '120',
        dealerCd: '04676',
        certificationNumber: 9488553,
        makeCode: 'TOYOTA',
        vin: '5TFAZ5CN4GX018338',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:09:57.069Z'
        }
      },
      sort: [
        1591553673598
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH3HS440111046762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:12:49.315Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28384.00',
          mileage: '22157'
        },
        sellArea: '120',
        dealerCd: '04676',
        certificationNumber: 9486165,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH3HS440111',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:20:33.223Z'
        }
      },
      sort: [
        1591553569315
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU352085046762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:12:13.217Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17998.00',
          mileage: '33004'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9407773,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU352085',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T23:44:49.539Z'
        }
      },
      sort: [
        1591553533217
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA19H9702837046762020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:11:51.436Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22588.00',
          mileage: '21304'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9501951,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA19H9702837',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6253',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T17:18:52.700Z'
        }
      },
      sort: [
        1591553511436
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN6HM040767046362020-06-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:08:23.056Z',
          retailDate: '2020-06-07',
          rdrStatus: 'H',
          soldAmount: '28599.00',
          mileage: '29086'
        },
        sellArea: '150',
        dealerCd: '04636',
        certificationNumber: 9494091,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN6HM040767',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:00:19.221Z'
        }
      },
      sort: [
        1591553303056
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREVXHD079303044542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:06:20.956Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '34200'
        },
        sellArea: '120',
        dealerCd: '04454',
        certificationNumber: 9494642,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREVXHD079303',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:25:18.803Z'
        }
      },
      sort: [
        1591553180956
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU455017047372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:05:32.816Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13998.00',
          mileage: '77963'
        },
        sellArea: '500',
        dealerCd: '04737',
        certificationNumber: 9480436,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU455017',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T16:35:36.062Z'
        }
      },
      sort: [
        1591553132816
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU733658047372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:05:04.013Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14998.00',
          mileage: '38859'
        },
        sellArea: '600',
        dealerCd: '04737',
        certificationNumber: 9483602,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU733658',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:28:57.132Z'
        }
      },
      sort: [
        1591553104013
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU9H3028696044542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:04:44.656Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19697.00',
          mileage: '14081'
        },
        sellArea: '120',
        dealerCd: '04454',
        certificationNumber: 9500129,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU9H3028696',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:03:05.841Z'
        }
      },
      sort: [
        1591553084656
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC5HS848222047372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:04:24.836Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25250.00',
          mileage: '39434'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9491690,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC5HS848222',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:43:57.948Z'
        }
      },
      sort: [
        1591553064836
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC6HS856805047372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:03:51.800Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19650.00',
          mileage: '45675'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9483596,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC6HS856805',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:26:33.828Z'
        }
      },
      sort: [
        1591553031800
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8GP420672047372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:02:57.514Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12998.00',
          mileage: '30292'
        },
        sellArea: '120',
        dealerCd: '04737',
        certificationNumber: 9491698,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8GP420672',
        model: {
          modelDescription: 'COROLLA LE PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1854',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:46:25.853Z'
        }
      },
      sort: [
        1591552977514
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXJW780330044542020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:02:47.933Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20496.00',
          mileage: '25317'
        },
        sellArea: '120',
        dealerCd: '04454',
        certificationNumber: 9410168,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXJW780330',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T19:23:30.351Z'
        }
      },
      sort: [
        1591552967933
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21HK5JU505987110302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T18:02:32.413Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28166.00',
          mileage: '19543'
        },
        sellArea: '110',
        dealerCd: '11030',
        certificationNumber: 9483530,
        makeCode: 'TOYOTA',
        vin: '4T1B21HK5JU505987',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:59:08.599Z'
        }
      },
      sort: [
        1591552952413
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU759927110302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T17:08:09.201Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19939.00',
          mileage: '14917'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9452329,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU759927',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T17:55:17.221Z'
        }
      },
      sort: [
        1591549689201
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4GM029762042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T17:05:08.561Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '33991.00',
          mileage: '33880'
        },
        sellArea: '120',
        dealerCd: '04222',
        certificationNumber: 9500471,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4GM029762',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:20:55.457Z'
        }
      },
      sort: [
        1591549508561
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7HM085955042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T17:03:27.421Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32991.00',
          mileage: '40644'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9495775,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7HM085955',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:33:34.970Z'
        }
      },
      sort: [
        1591549407421
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU344791042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T17:01:50.119Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17791.00',
          mileage: '26570'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9485654,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU344791',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:46:24.714Z'
        }
      },
      sort: [
        1591549310119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK6J3020797042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T17:00:46.119Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18991.00',
          mileage: '37517'
        },
        sellArea: '800',
        dealerCd: '04222',
        certificationNumber: 9485558,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK6J3020797',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:20:36.238Z'
        }
      },
      sort: [
        1591549246119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6H5418323461032019-11-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T17:00:42.557Z',
          retailDate: '2019-11-24',
          rdrStatus: 'H',
          soldAmount: '32999.00',
          mileage: '36908'
        },
        sellArea: '130',
        dealerCd: '46103',
        certificationNumber: 9494002,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6H5418323',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:25:17.235Z'
        }
      },
      sort: [
        1591549242557
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2JD229272110352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:57:07.816Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23132.00',
          mileage: '14889'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9493779,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2JD229272',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:32:49.823Z'
        }
      },
      sort: [
        1591549027816
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV1HY152649110352020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:56:34.695Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '10500.00',
          mileage: '38612'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9463597,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV1HY152649',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T21:12:22.361Z'
        }
      },
      sort: [
        1591548994695
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU760051110302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:53:00.134Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '25812'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9459171,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU760051',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T21:31:38.505Z'
        }
      },
      sort: [
        1591548780134
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU658212160502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:49:27.353Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17240.00',
          mileage: '24189'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9398118,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU658212',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T23:59:31.531Z'
        }
      },
      sort: [
        1591548567353
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH4ES044696371852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:49:22.612Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21600.00',
          mileage: '73107'
        },
        sellArea: '800',
        dealerCd: '37185',
        certificationNumber: 9468829,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH4ES044696',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:56:16.634Z'
        }
      },
      sort: [
        1591548562612
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4HM075707371852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:46:17.310Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28430.00',
          mileage: '35389'
        },
        sellArea: '800',
        dealerCd: '37185',
        certificationNumber: 9457328,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4HM075707',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T12:50:53.594Z'
        }
      },
      sort: [
        1591548377310
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F14JX730578160502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:45:28.730Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '21465'
        },
        dealerCd: '16050',
        certificationNumber: 9389161,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F14JX730578',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T17:21:43.350Z'
        }
      },
      sort: [
        1591548328730
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU301213371852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:45:00.469Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15996.16',
          mileage: '29027'
        },
        sellArea: '160',
        dealerCd: '37185',
        certificationNumber: 9399892,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU301213',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T22:34:30.874Z'
        }
      },
      sort: [
        1591548300469
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6JW804914371852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:44:02.547Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19122.13',
          mileage: '37100'
        },
        dealerCd: '37185',
        certificationNumber: 9359313,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6JW804914',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-29T18:11:18.789Z'
        }
      },
      sort: [
        1591548242547
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4K5670708371852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:43:23.707Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '35975.00',
          mileage: '7615'
        },
        sellArea: '800',
        dealerCd: '37185',
        certificationNumber: 9500902,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4K5670708',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T13:17:32.566Z'
        }
      },
      sort: [
        1591548203707
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6HP680103160502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:43:19.288Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14315.00',
          mileage: '20631'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9470004,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6HP680103',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T19:54:15.359Z'
        }
      },
      sort: [
        1591548199288
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9FW323699371852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:42:18.468Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15697.83',
          mileage: '78993'
        },
        sellArea: '800',
        dealerCd: '37185',
        certificationNumber: 9487801,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9FW323699',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:02:22.560Z'
        }
      },
      sort: [
        1591548138468
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV1HD207562160502020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:41:16.407Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '21940.00',
          mileage: '34211'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9444303,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV1HD207562',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T17:05:43.007Z'
        }
      },
      sort: [
        1591548076407
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9JW822906371852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:40:20.587Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19632.93',
          mileage: '37383'
        },
        dealerCd: '37185',
        certificationNumber: 9359154,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9JW822906',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-29T17:40:36.990Z'
        }
      },
      sort: [
        1591548020587
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F13JX720182160502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:39:55.407Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '39500.00',
          mileage: '24933'
        },
        sellArea: '800',
        dealerCd: '16050',
        certificationNumber: 9469999,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F13JX720182',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T19:52:20.098Z'
        }
      },
      sort: [
        1591547995407
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC889102160502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:28:10.840Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14750.00',
          mileage: '24851'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9501925,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC889102',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T16:26:16.077Z'
        }
      },
      sort: [
        1591547290840
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6L5738377201452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T16:08:48.468Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '35000.00',
          mileage: '12863'
        },
        sellArea: '500',
        dealerCd: '20145',
        certificationNumber: 9457929,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6L5738377',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T20:51:54.284Z'
        }
      },
      sort: [
        1591546128468
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP2K3115392040702020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T15:59:36.502Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '27499.00',
          mileage: '11876'
        },
        sellArea: '120',
        dealerCd: '04070',
        certificationNumber: 9443610,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP2K3115392',
        model: {
          modelDescription: 'PRIUS PRIME ADVANCED',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1239',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-11T18:33:15.120Z'
        }
      },
      sort: [
        1591545576502
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC3HS801352201452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T15:58:52.782Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24397.00',
          mileage: '44001'
        },
        dealerCd: '20145',
        certificationNumber: 9335321,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC3HS801352',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-13T23:21:49.789Z'
        }
      },
      sort: [
        1591545532782
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8L5793347040702020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T15:58:29.420Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '43899.00',
          mileage: '1498'
        },
        sellArea: '120',
        dealerCd: '04070',
        certificationNumber: 9474914,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8L5793347',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:45:29.673Z'
        }
      },
      sort: [
        1591545509420
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV4JW418461040702020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T15:57:29.882Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19899.00',
          mileage: '25495'
        },
        sellArea: '120',
        dealerCd: '04070',
        certificationNumber: 9479547,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV4JW418461',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T20:37:34.215Z'
        }
      },
      sort: [
        1591545449882
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0HD122553040702020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T15:55:57.659Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24899.00',
          mileage: '24672'
        },
        sellArea: '120',
        dealerCd: '04070',
        certificationNumber: 9479129,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0HD122553',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T16:52:13.474Z'
        }
      },
      sort: [
        1591545357659
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU281817190262020-05-23V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T15:28:15.129Z',
          retailDate: '2020-05-23',
          rdrStatus: 'V',
          soldAmount: '21243.00',
          mileage: '3365'
        },
        sellArea: '800',
        dealerCd: '19026',
        certificationNumber: 9470702,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU281817',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T16:03:31.251Z'
        }
      },
      sort: [
        1591543695129
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4FD116366371742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:44:02.842Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15799.54',
          mileage: '36686'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9485265,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4FD116366',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:23:00.066Z'
        }
      },
      sort: [
        1591541042842
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3LW087671371252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:39:37.020Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26000.00',
          mileage: '4656'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9497910,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3LW087671',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:54:19.566Z'
        }
      },
      sort: [
        1591540777020
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7HJ151603371742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:37:51.231Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18596.25',
          mileage: '27844'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9477844,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7HJ151603',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:27:22.065Z'
        }
      },
      sort: [
        1591540671231
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDGZRBH2LS503297371252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:37:30.618Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '36262.00',
          mileage: '39362'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9483013,
        makeCode: 'TOYOTA',
        vin: '5TDGZRBH2LS503297',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: null,
          modelNumber: '6953',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T12:39:42.266Z'
        }
      },
      sort: [
        1591540650618
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH8GS344103371252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:33:50.156Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25495.00',
          mileage: '39358'
        },
        sellArea: '160',
        dealerCd: '37125',
        certificationNumber: 9402755,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH8GS344103',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T15:08:38.816Z'
        }
      },
      sort: [
        1591540430156
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HJ152059371252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:31:30.354Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18993.00',
          mileage: '13657'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9487792,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HJ152059',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:59:00.972Z'
        }
      },
      sort: [
        1591540290354
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F12HX652793371252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:29:11.855Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '36202.00',
          mileage: '29978'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9481313,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F12HX652793',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 EDITION CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T12:37:25.893Z'
        }
      },
      sort: [
        1591540151855
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2KC009771371252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:26:00.773Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23699.00',
          mileage: '23243'
        },
        dealerCd: '37125',
        certificationNumber: 9367066,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2KC009771',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-03T21:14:26.762Z'
        }
      },
      sort: [
        1591539960773
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F19HX654389371252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:24:04.073Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29000.00',
          mileage: '31718'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9491051,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F19HX654389',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T12:45:02.605Z'
        }
      },
      sort: [
        1591539844073
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5JU624514371252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:21:47.991Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19998.00',
          mileage: '14572'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9486860,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5JU624514',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T12:27:32.825Z'
        }
      },
      sort: [
        1591539707991
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0HW659641371742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:20:50.490Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19117.63',
          mileage: '34970'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9497980,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0HW659641',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:09:09.950Z'
        }
      },
      sort: [
        1591539650490
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREVXHD214958371252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T14:19:38.928Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22994.00',
          mileage: '33828'
        },
        sellArea: '160',
        dealerCd: '37125',
        certificationNumber: 9396717,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREVXHD214958',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T13:12:15.003Z'
        }
      },
      sort: [
        1591539578928
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HJ700854450892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T13:49:33.475Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21457.11',
          mileage: '16759'
        },
        sellArea: '160',
        dealerCd: '45089',
        certificationNumber: 9500400,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HJ700854',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:41:36.615Z'
        }
      },
      sort: [
        1591537773475
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2JW716752450892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T13:49:00.633Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18987.00',
          mileage: '50226'
        },
        sellArea: '800',
        dealerCd: '45089',
        certificationNumber: 9501149,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2JW716752',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T15:35:23.590Z'
        }
      },
      sort: [
        1591537740633
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HJ701209450892020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T13:48:31.695Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18862.33',
          mileage: '24370'
        },
        sellArea: '800',
        dealerCd: '45089',
        certificationNumber: 9500138,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HJ701209',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:05:56.023Z'
        }
      },
      sort: [
        1591537711695
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXG5302041450892020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T13:47:58.335Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30752.75',
          mileage: '60863'
        },
        sellArea: '800',
        dealerCd: '45089',
        certificationNumber: 9467618,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXG5302041',
        model: {
          modelDescription: '4RUNNER 4X4 TRAIL PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T22:05:34.963Z'
        }
      },
      sort: [
        1591537678335
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BPRHE5HC782595041192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T03:58:26.789Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15288.00',
          mileage: '7729'
        },
        sellArea: '120',
        dealerCd: '04119',
        certificationNumber: 9498444,
        makeCode: 'TOYOTA',
        vin: '2T1BPRHE5HC782595',
        model: {
          modelDescription: 'COROLLA LE ECO',
          modelSeries: 'COROLLA',
          modelNumber: '1872',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T01:44:10.858Z'
        }
      },
      sort: [
        1591502306789
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1K61AK3LU338210041192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T03:20:04.056Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '39884.00',
          mileage: '1584'
        },
        sellArea: '120',
        dealerCd: '04119',
        certificationNumber: 9501671,
        makeCode: 'TOYOTA',
        vin: '4T1K61AK3LU338210',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:39:34.261Z'
        }
      },
      sort: [
        1591500004056
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK1LU892432041192020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T03:16:44.792Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25306.00',
          mileage: '5954'
        },
        sellArea: '110',
        dealerCd: '04119',
        certificationNumber: 9479804,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK1LU892432',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-24T00:30:52.439Z'
        }
      },
      sort: [
        1591499804792
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE3LP062082041192020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T03:13:49.535Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '8800'
        },
        sellArea: '110',
        dealerCd: '04119',
        certificationNumber: 9479800,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE3LP062082',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-24T00:28:36.046Z'
        }
      },
      sort: [
        1591499629535
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2FJ040485110302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T02:59:44.855Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18919.00',
          mileage: '37914'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9496234,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2FJ040485',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:42:00.179Z'
        }
      },
      sort: [
        1591498784855
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK1GR519679110302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T02:55:57.594Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16918.00',
          mileage: '44326'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9483516,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK1GR519679',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:54:31.439Z'
        }
      },
      sort: [
        1591498557594
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFUXG3006594045632020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T02:14:13.274Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17900.00',
          mileage: '53827'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9467085,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFUXG3006594',
        model: {
          modelDescription: 'PRIUS FOUR',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1227',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T17:01:30.104Z'
        }
      },
      sort: [
        1591496053274
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC2HS153663190602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T02:05:41.747Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22998.00',
          mileage: '55200'
        },
        sellArea: '160',
        dealerCd: '19060',
        certificationNumber: 9488446,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC2HS153663',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T22:53:39.019Z'
        }
      },
      sort: [
        1591495541747
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC774574190602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T02:03:03.715Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13998.00',
          mileage: '28427'
        },
        sellArea: '160',
        dealerCd: '19060',
        certificationNumber: 9439231,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC774574',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T17:01:55.691Z'
        }
      },
      sort: [
        1591495383715
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN5LX219302300342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:32:12.435Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '43999.00',
          mileage: '5071'
        },
        sellArea: '150',
        dealerCd: '30034',
        certificationNumber: 9501811,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN5LX219302',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T01:29:05.219Z'
        }
      },
      sort: [
        1591493532435
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDZ5BN2LX049211300342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:19:19.219Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '43999.00',
          mileage: '4375'
        },
        sellArea: '150',
        dealerCd: '30034',
        certificationNumber: 9501808,
        makeCode: 'TOYOTA',
        vin: '5TFDZ5BN2LX049211',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-07T01:15:21.353Z'
        }
      },
      sort: [
        1591492759219
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0GD188151270202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:16:01.046Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16987.68',
          mileage: '28909'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9479733,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0GD188151',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T22:43:40.536Z'
        }
      },
      sort: [
        1591492561046
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH8HS431736041872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:15:15.895Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32995.00',
          mileage: '33259'
        },
        sellArea: '110',
        dealerCd: '04187',
        certificationNumber: 9485555,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH8HS431736',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:19:53.000Z'
        }
      },
      sort: [
        1591492515895
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN5HM051405270202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:15:02.195Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28539.75',
          mileage: '24779'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9497664,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN5HM051405',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:38:56.947Z'
        }
      },
      sort: [
        1591492502195
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7HD062268270202020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:14:07.194Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22238.00',
          mileage: '50788'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9458108,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7HD062268',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T01:17:53.203Z'
        }
      },
      sort: [
        1591492447194
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDFARAH5LS001226270202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:13:20.172Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '47910.77',
          mileage: '897'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9494850,
        makeCode: 'TOYOTA',
        vin: '5TDFARAH5LS001226',
        model: {
          modelDescription: 'HIGHLANDER HYBRD PLATINUM-L4 FWD',
          modelSeries: null,
          modelNumber: '6963',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T00:53:44.145Z'
        }
      },
      sort: [
        1591492400172
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH0JS529141270202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:12:11.671Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28968.55',
          mileage: '23256'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9493066,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH0JS529141',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:39:05.356Z'
        }
      },
      sort: [
        1591492331671
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFVXKW016154270202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:11:23.970Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25613.00',
          mileage: '25940'
        },
        sellArea: '500',
        dealerCd: '27020',
        certificationNumber: 9486454,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFVXKW016154',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:21:20.013Z'
        }
      },
      sort: [
        1591492283970
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU7K3075798043032020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:10:40.509Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19832.00',
          mileage: '20201'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9415434,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU7K3075798',
        model: {
          modelDescription: 'PRIUS LE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T18:12:19.529Z'
        }
      },
      sort: [
        1591492240509
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN0HM027240270202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:10:37.070Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27988.00',
          mileage: '45211'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9497648,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN0HM027240',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:33:30.932Z'
        }
      },
      sort: [
        1591492237070
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV5HJ112297043032020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:09:53.567Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18957.00',
          mileage: '29579'
        },
        dealerCd: '04303',
        certificationNumber: 9385777,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV5HJ112297',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T16:45:50.383Z'
        }
      },
      sort: [
        1591492193567
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV6HW390558270202020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:09:38.009Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19488.00',
          mileage: '26926'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9492935,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV6HW390558',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:09:25.967Z'
        }
      },
      sort: [
        1591492178009
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV2KW015676270202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:08:40.126Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23432.23',
          mileage: '25727'
        },
        sellArea: '500',
        dealerCd: '27020',
        certificationNumber: 9479721,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV2KW015676',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T22:31:33.269Z'
        }
      },
      sort: [
        1591492120126
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7J5556989270202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:07:48.547Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '36988.00',
          mileage: '30477'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9494835,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7J5556989',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T00:39:34.862Z'
        }
      },
      sort: [
        1591492068547
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5596609110302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:06:09.944Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34929.00',
          mileage: '19159'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9446836,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5596609',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T21:57:53.014Z'
        }
      },
      sort: [
        1591491969944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8EW175744060022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T01:05:27.341Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15999.00',
          mileage: '50652'
        },
        sellArea: '170',
        dealerCd: '06002',
        certificationNumber: 9498717,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8EW175744',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T12:43:39.083Z'
        }
      },
      sort: [
        1591491927341
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU1K3078440042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:58:59.376Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '26576'
        },
        sellArea: '500',
        dealerCd: '04222',
        certificationNumber: 9432230,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU1K3078440',
        model: {
          modelDescription: 'PRIUS LE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T19:38:01.592Z'
        }
      },
      sort: [
        1591491539376
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFJU4GN3FX083960042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:52:41.787Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27791.00',
          mileage: '37208'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9480429,
        makeCode: 'TOYOTA',
        vin: '5TFJU4GN3FX083960',
        model: {
          modelDescription: 'TACOMA PRERUNNER DOUBLE CAB 4X2 V6',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7188',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T16:32:02.941Z'
        }
      },
      sort: [
        1591491161787
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU4H3543060042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:51:33.806Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20491.00',
          mileage: '23422'
        },
        sellArea: '110',
        certificationNumber: 9480668,
        dealerCd: '04222',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        vin: 'JTDKARFU4H3543060',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T18:23:42.155Z'
        }
      },
      sort: [
        1591491093806
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDCRFHXFS012799300342020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:35:10.524Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '60781'
        },
        sellArea: '150',
        dealerCd: '30034',
        certificationNumber: 9501741,
        makeCode: 'TOYOTA',
        vin: '5TDDCRFHXFS012799',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T23:06:48.251Z'
        }
      },
      sort: [
        1591490110524
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE1K3013329300342020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:33:46.063Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '16941'
        },
        sellArea: '150',
        dealerCd: '30034',
        certificationNumber: 9479703,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE1K3013329',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T22:19:58.641Z'
        }
      },
      sort: [
        1591490026063
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F15JX685998300342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:31:07.359Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '40999.00',
          mileage: '22401'
        },
        sellArea: '150',
        dealerCd: '30034',
        certificationNumber: 9490162,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F15JX685998',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T23:55:10.148Z'
        }
      },
      sort: [
        1591489867359
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN4KM069240300342020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:25:45.972Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '43999.00',
          mileage: '17480'
        },
        sellArea: '150',
        dealerCd: '30034',
        certificationNumber: 9490148,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN4KM069240',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7568',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T23:40:06.995Z'
        }
      },
      sort: [
        1591489545972
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU691883300342020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:23:36.808Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21996.00',
          mileage: '9766'
        },
        sellArea: '120',
        dealerCd: '30034',
        certificationNumber: 9443631,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU691883',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-11T19:14:12.443Z'
        }
      },
      sort: [
        1591489416808
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK2HU218081042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:16:21.398Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18991.00',
          mileage: '39271'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9484087,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK2HU218081',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:39:03.288Z'
        }
      },
      sort: [
        1591488981398
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS399079120742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:14:17.036Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29488.00',
          mileage: '35956'
        },
        sellArea: '210',
        dealerCd: '12074',
        certificationNumber: 9427590,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS399079',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T20:36:49.530Z'
        }
      },
      sort: [
        1591488857036
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F14HX642831110302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-07T00:09:26.368Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '37861.00',
          mileage: '25144'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9501744,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F14HX642831',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T23:13:04.232Z'
        }
      },
      sort: [
        1591488566368
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV2HJ132412190722020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:55:39.011Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17395.00',
          mileage: '68139'
        },
        sellArea: '800',
        dealerCd: '19072',
        certificationNumber: 9501729,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV2HJ132412',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T22:41:38.913Z'
        }
      },
      sort: [
        1591487739011
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC931984220462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:43:12.696Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15485.00',
          mileage: '14059'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9465546,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC931984',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:24:59.886Z'
        }
      },
      sort: [
        1591486992696
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFJU4GN8FX088166042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:28:22.226Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25991.00',
          mileage: '41297'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9495818,
        makeCode: 'TOYOTA',
        vin: '5TFJU4GN8FX088166',
        model: {
          modelDescription: 'TACOMA PRERUNNER DOUBLE CAB 4X2 V6',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7188',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:49:44.274Z'
        }
      },
      sort: [
        1591486102226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV7KW026883042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:26:52.572Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24700.00',
          mileage: '16420'
        },
        sellArea: '600',
        dealerCd: '04222',
        certificationNumber: 9432932,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV7KW026883',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T16:25:27.950Z'
        }
      },
      sort: [
        1591486012572
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV9HJ131029042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:24:10.789Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18996.00',
          mileage: '36291'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9417771,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV9HJ131029',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T23:08:01.477Z'
        }
      },
      sort: [
        1591485850789
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS427975460712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:23:32.069Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28532.00',
          mileage: '16745'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9471580,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS427975',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T21:13:52.211Z'
        }
      },
      sort: [
        1591485812069
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HD204194371252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:23:10.028Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '38604'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9491048,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HD204194',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T12:41:29.118Z'
        }
      },
      sort: [
        1591485790028
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7JU523930460712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:21:46.768Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20132.00',
          mileage: '24173'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9498271,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7JU523930',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T22:23:13.744Z'
        }
      },
      sort: [
        1591485706768
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0GU251203042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:21:16.108Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15591.00',
          mileage: '28771'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9473705,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0GU251203',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T23:34:13.881Z'
        }
      },
      sort: [
        1591485676108
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3GD197344291142020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:18:34.567Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15700.00',
          mileage: '70833'
        },
        sellArea: '160',
        dealerCd: '29114',
        certificationNumber: 9466596,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3GD197344',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T12:43:59.667Z'
        }
      },
      sort: [
        1591485514567
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2LM299700300342020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:18:26.409Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '37750.00',
          mileage: '6152'
        },
        sellArea: '150',
        dealerCd: '30034',
        certificationNumber: 9501743,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2LM299700',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T23:12:49.972Z'
        }
      },
      sort: [
        1591485506409
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV8KW026469042222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:17:53.166Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24495.00',
          mileage: '19271'
        },
        sellArea: '600',
        dealerCd: '04222',
        certificationNumber: 9432182,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV8KW026469',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T19:10:49.374Z'
        }
      },
      sort: [
        1591485473166
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU170919410562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:15:30.185Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19746.81',
          mileage: '40217'
        },
        sellArea: '500',
        dealerCd: '41056',
        certificationNumber: 9486120,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU170919',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:00:11.109Z'
        }
      },
      sort: [
        1591485330185
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2HW684958371252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:10:23.382Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17181.69',
          mileage: '35700'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9476680,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2HW684958',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:32:24.411Z'
        }
      },
      sort: [
        1591485023382
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KW064217110112020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:10:21.063Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26800.00',
          mileage: '13605'
        },
        sellArea: '600',
        dealerCd: '11011',
        certificationNumber: 9445837,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KW064217',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-18T14:52:12.153Z'
        }
      },
      sort: [
        1591485021063
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU813408470322020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:10:04.842Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '2057'
        },
        sellArea: '800',
        dealerCd: '47032',
        certificationNumber: 9448897,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU813408',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T18:35:43.689Z'
        }
      },
      sort: [
        1591485004842
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHD210108370832020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:03:32.437Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18913.00',
          mileage: '30480'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9472985,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHD210108',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T18:09:28.070Z'
        }
      },
      sort: [
        1591484612437
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW598098180362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:02:57.279Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21900.00',
          mileage: '26627'
        },
        sellArea: '170',
        dealerCd: '18036',
        certificationNumber: 9492260,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW598098',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:21:19.950Z'
        }
      },
      sort: [
        1591484577279
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5EN0HX055542480622020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T23:02:15.658Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22346.00',
          mileage: '56391'
        },
        sellArea: '210',
        dealerCd: '48062',
        certificationNumber: 9492578,
        makeCode: 'TOYOTA',
        vin: '5TFSX5EN0HX055542',
        model: {
          modelDescription: 'TACOMA SR 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7513',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:39:56.657Z'
        }
      },
      sort: [
        1591484535658
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW589738220472020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:58:04.392Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '32144'
        },
        sellArea: '210',
        dealerCd: '22047',
        certificationNumber: 9497210,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW589738',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:52:09.365Z'
        }
      },
      sort: [
        1591484284392
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH4FS211305370832020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:55:01.052Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23384.00',
          mileage: '63866'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9476225,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH4FS211305',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:34:25.017Z'
        }
      },
      sort: [
        1591484101052
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW675062180362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:54:50.632Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '18937'
        },
        sellArea: '170',
        dealerCd: '18036',
        certificationNumber: 9492334,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW675062',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:34:15.558Z'
        }
      },
      sort: [
        1591484090632
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV0GJ083774043032020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:54:21.252Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16054.00',
          mileage: '48907'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9424420,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV0GJ083774',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T20:45:37.820Z'
        }
      },
      sort: [
        1591484061252
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ139428220462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:53:15.631Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19735.00',
          mileage: '37110'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9422453,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HJ139428',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T16:43:46.980Z'
        }
      },
      sort: [
        1591483995631
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8HJ133644400212020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:52:41.009Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20490.00',
          mileage: '15401'
        },
        sellArea: '230',
        dealerCd: '40021',
        certificationNumber: 9457885,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8HJ133644',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T20:31:43.979Z'
        }
      },
      sort: [
        1591483961009
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9HW591333220462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:51:57.750Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24485.00',
          mileage: '28653'
        },
        dealerCd: '22046',
        certificationNumber: 9374412,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9HW591333',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-10T14:59:48.799Z'
        }
      },
      sort: [
        1591483917750
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HKXJU029580400212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:51:49.330Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24495.00',
          mileage: '12150'
        },
        sellArea: '150',
        dealerCd: '40021',
        certificationNumber: 9393404,
        makeCode: 'TOYOTA',
        vin: '4T1B61HKXJU029580',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T16:45:58.843Z'
        }
      },
      sort: [
        1591483909330
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6HP629992050432020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:51:18.269Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15001.00',
          mileage: '39335'
        },
        sellArea: '110',
        dealerCd: '05043',
        certificationNumber: 9493198,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6HP629992',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:26:13.791Z'
        }
      },
      sort: [
        1591483878269
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC841665370832020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:45:31.585Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14542.00',
          mileage: '33970'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9501233,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC841665',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:27:21.193Z'
        }
      },
      sort: [
        1591483531585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0HD143516050462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:38:57.879Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25164.00',
          mileage: '32180'
        },
        sellArea: '150',
        dealerCd: '05046',
        certificationNumber: 9499709,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0HD143516',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:39:55.017Z'
        }
      },
      sort: [
        1591483137879
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX0K1045368240612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:36:56.279Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19180.00',
          mileage: '3812'
        },
        sellArea: '230',
        dealerCd: '24061',
        certificationNumber: 9396374,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX0K1045368',
        model: {
          modelDescription: 'C-HR LE',
          modelSeries: 'CHR',
          modelNumber: '2402',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T00:29:20.702Z'
        }
      },
      sort: [
        1591483016279
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXK5697203201452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:30:22.214Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31997.00',
          mileage: '14258'
        },
        sellArea: '500',
        dealerCd: '20145',
        certificationNumber: 9447309,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXK5697203',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T17:07:37.085Z'
        }
      },
      sort: [
        1591482622214
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC5LS025217370672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:29:31.873Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29300.00',
          mileage: '25080'
        },
        sellArea: '500',
        dealerCd: '37067',
        certificationNumber: 9428133,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC5LS025217',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T11:34:29.759Z'
        }
      },
      sort: [
        1591482571873
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HJ702731370672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:29:02.593Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18800.00',
          mileage: '18287'
        },
        sellArea: '800',
        dealerCd: '37067',
        certificationNumber: 9477319,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HJ702731',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T14:14:02.527Z'
        }
      },
      sort: [
        1591482542593
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3NFREV0GW252978043442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:28:36.173Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19988.00',
          mileage: '59047'
        },
        sellArea: '110',
        dealerCd: '04344',
        certificationNumber: 9487473,
        makeCode: 'TOYOTA',
        vin: '2T3NFREV0GW252978',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:01:09.346Z'
        }
      },
      sort: [
        1591482516173
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU506369370672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:28:33.373Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19800.00',
          mileage: '12948'
        },
        sellArea: '160',
        dealerCd: '37067',
        certificationNumber: 9428238,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU506369',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T13:58:22.900Z'
        }
      },
      sort: [
        1591482513373
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0JD180541050342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:25:57.951Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22857.7300',
          mileage: '50082'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9488460,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0JD180541',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T23:00:16.263Z'
        }
      },
      sort: [
        1591482357951
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW662883050342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:25:55.529Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20956.0000',
          mileage: '32702'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9488455,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW662883',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T22:57:52.723Z'
        }
      },
      sort: [
        1591482355529
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFHPRAE8LP036137050342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:25:52.271Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18182.00',
          mileage: '3286'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9465548,
        makeCode: 'TOYOTA',
        vin: '5YFHPRAE8LP036137',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:26:13.766Z'
        }
      },
      sort: [
        1591482352271
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3HP617587043442020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:25:48.971Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17778.00',
          mileage: '21272'
        },
        sellArea: '110',
        dealerCd: '04344',
        certificationNumber: 9482447,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3HP617587',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:28:08.397Z'
        }
      },
      sort: [
        1591482348971
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH2HS430811045632020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:20:11.347Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30900.00',
          mileage: '36798'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9497783,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH2HS430811',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:20:46.451Z'
        }
      },
      sort: [
        1591482011347
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F15GX505963470252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:17:40.426Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34093.00',
          mileage: '50005'
        },
        sellArea: '800',
        dealerCd: '47025',
        certificationNumber: 9497182,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F15GX505963',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:37:58.960Z'
        }
      },
      sort: [
        1591481860426
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV3KW035896361002020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:11:52.241Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '30499.59',
          mileage: '5598'
        },
        sellArea: '130',
        dealerCd: '36100',
        certificationNumber: 9420637,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV3KW035896',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T20:55:11.720Z'
        }
      },
      sort: [
        1591481512241
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV2JY302192041672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:06:30.697Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13500.00',
          mileage: '21651'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9489719,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV2JY302192',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T19:38:36.500Z'
        }
      },
      sort: [
        1591481190697
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH0GS307742290752020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:06:05.618Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27749.00',
          mileage: '31196'
        },
        sellArea: '160',
        dealerCd: '29075',
        certificationNumber: 9422318,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH0GS307742',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T15:52:35.209Z'
        }
      },
      sort: [
        1591481165618
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN6HM041093041672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:04:55.155Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29995.00',
          mileage: '34249'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9486443,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN6HM041093',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:10:36.584Z'
        }
      },
      sort: [
        1591481095155
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKKRFH1FS065563041672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:03:34.396Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19890.00',
          mileage: '78506'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9486466,
        makeCode: 'TOYOTA',
        vin: '5TDKKRFH1FS065563',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:40:35.112Z'
        }
      },
      sort: [
        1591481014396
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH0GS023128041672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:00:28.713Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20545.00',
          mileage: '53584'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9463802,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH0GS023128',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T00:31:29.977Z'
        }
      },
      sort: [
        1591480828713
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU657722290622020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T22:00:19.253Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '31825'
        },
        sellArea: '160',
        dealerCd: '29062',
        certificationNumber: 9501681,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU657722',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:51:36.413Z'
        }
      },
      sort: [
        1591480819253
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6HM119061410812020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:56:04.451Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31543.00',
          mileage: '37269'
        },
        sellArea: '220',
        dealerCd: '41081',
        certificationNumber: 9498168,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6HM119061',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:18:11.675Z'
        }
      },
      sort: [
        1591480564451
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC961028290752020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:55:35.568Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14154.00',
          mileage: '39610'
        },
        sellArea: '160',
        dealerCd: '29075',
        certificationNumber: 9497044,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC961028',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:31:39.528Z'
        }
      },
      sort: [
        1591480535568
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9JJ259385290752020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:54:50.710Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23226.29',
          mileage: '16435'
        },
        sellArea: '160',
        dealerCd: '29075',
        certificationNumber: 9428496,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9JJ259385',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T16:36:10.036Z'
        }
      },
      sort: [
        1591480490710
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1JJ185959041672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:54:25.130Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '24101'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9489726,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1JJ185959',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T19:41:59.983Z'
        }
      },
      sort: [
        1591480465130
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS453632290752020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:54:09.888Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30902.68',
          mileage: '19453'
        },
        sellArea: '160',
        dealerCd: '29075',
        certificationNumber: 9410617,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS453632',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T23:52:43.968Z'
        }
      },
      sort: [
        1591480449888
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC5LS024455290752020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:48:21.945Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32959.00',
          mileage: '21637'
        },
        sellArea: '500',
        dealerCd: '29075',
        certificationNumber: 9422278,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC5LS024455',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T15:37:53.300Z'
        }
      },
      sort: [
        1591480101945
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW597230290752020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:47:45.845Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23895.00',
          mileage: '31968'
        },
        dealerCd: '29075',
        certificationNumber: 9384561,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW597230',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T21:44:27.153Z'
        }
      },
      sort: [
        1591480065845
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7GJ091210290622020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:47:28.625Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21575.26',
          mileage: '36332'
        },
        dealerCd: '29062',
        certificationNumber: 9390099,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7GJ091210',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T11:26:57.800Z'
        }
      },
      sort: [
        1591480048625
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV2HJ704480120852020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:47:02.364Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '20521'
        },
        sellArea: '210',
        dealerCd: '12085',
        certificationNumber: 9498007,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV2HJ704480',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:15:58.495Z'
        }
      },
      sort: [
        1591480022364
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3JU641666290622020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:46:46.363Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18571.00',
          mileage: '30719'
        },
        sellArea: '600',
        dealerCd: '29062',
        certificationNumber: 9501675,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3JU641666',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:44:14.685Z'
        }
      },
      sort: [
        1591480006363
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HJ153502120852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:46:23.323Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '33828'
        },
        sellArea: '210',
        dealerCd: '12085',
        certificationNumber: 9497286,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HJ153502',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:20:42.042Z'
        }
      },
      sort: [
        1591479983323
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KC007106120852020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:45:38.184Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24650.00',
          mileage: '18750'
        },
        sellArea: '600',
        dealerCd: '12085',
        certificationNumber: 9497272,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KC007106',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:15:23.158Z'
        }
      },
      sort: [
        1591479938184
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4JC972922371382020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:43:39.740Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13688.00',
          mileage: '43053'
        },
        sellArea: '500',
        dealerCd: '37138',
        certificationNumber: 9476771,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4JC972922',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T23:10:33.172Z'
        }
      },
      sort: [
        1591479819740
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4FD125908110352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:41:34.119Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15400.00',
          mileage: '72255'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9492233,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4FD125908',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:13:49.447Z'
        }
      },
      sort: [
        1591479694119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH4GS238828220472020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:39:09.739Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22999.00',
          mileage: '70302'
        },
        sellArea: '210',
        dealerCd: '22047',
        certificationNumber: 9484216,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH4GS238828',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:36:28.277Z'
        }
      },
      sort: [
        1591479549739
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE7LJ035794290622020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:36:31.556Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17229.00',
          mileage: '16969'
        },
        dealerCd: '29062',
        certificationNumber: 9387034,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE7LJ035794',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-19T12:17:14.506Z'
        }
      },
      sort: [
        1591479391556
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREVXJD186427240742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:35:13.444Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23178.00',
          mileage: '22256'
        },
        dealerCd: '24074',
        certificationNumber: 9380431,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREVXJD186427',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-13T22:40:54.067Z'
        }
      },
      sort: [
        1591479313444
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2HW593463240742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:32:46.544Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19762.00',
          mileage: '40719'
        },
        sellArea: '160',
        dealerCd: '24074',
        certificationNumber: 9420743,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2HW593463',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T21:47:44.381Z'
        }
      },
      sort: [
        1591479166544
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW611589240742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:32:10.245Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19821.00',
          mileage: '44014'
        },
        sellArea: '160',
        dealerCd: '24074',
        certificationNumber: 9420696,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW611589',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T21:32:37.741Z'
        }
      },
      sort: [
        1591479130245
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV7KW051096240742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:31:27.347Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28698.00',
          mileage: '14582'
        },
        sellArea: '500',
        dealerCd: '24074',
        certificationNumber: 9392293,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV7KW051096',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-22T19:18:16.294Z'
        }
      },
      sort: [
        1591479087347
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYVXJY311349290622020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:31:24.707Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13049.00',
          mileage: '15889'
        },
        sellArea: '600',
        dealerCd: '29062',
        certificationNumber: 9416207,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYVXJY311349',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T10:56:55.149Z'
        }
      },
      sort: [
        1591479084707
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJW5G11FS119308240452020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:30:35.380Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31801.00',
          mileage: '49967'
        },
        sellArea: '230',
        dealerCd: '24045',
        certificationNumber: 9448153,
        makeCode: 'TOYOTA',
        vin: '5TDJW5G11FS119308',
        model: {
          modelDescription: 'SEQUOIA 4X4 LTD 8-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7942',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T19:19:48.775Z'
        }
      },
      sort: [
        1591479035380
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC5HS801224240742020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:30:32.107Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25813.00',
          mileage: '31583'
        },
        sellArea: '160',
        dealerCd: '24074',
        certificationNumber: 9500273,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC5HS801224',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:40:32.191Z'
        }
      },
      sort: [
        1591479032107
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV5HW396810047392020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:28:47.206Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19998.00',
          mileage: '42453'
        },
        sellArea: '500',
        dealerCd: '04739',
        certificationNumber: 9501657,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV5HW396810',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:25:48.255Z'
        }
      },
      sort: [
        1591478927206
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW577682280122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:28:36.986Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21139.30',
          mileage: '28348'
        },
        dealerCd: '28012',
        certificationNumber: 9382693,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW577682',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T15:46:16.136Z'
        }
      },
      sort: [
        1591478916986
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2G5382077220462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:25:09.984Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27700.00',
          mileage: '58261'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9487217,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2G5382077',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:31:27.834Z'
        }
      },
      sort: [
        1591478709984
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC918498220462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:24:03.622Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14985.00',
          mileage: '11126'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9485147,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC918498',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:33:53.542Z'
        }
      },
      sort: [
        1591478643622
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8JJ198547070092020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:23:43.501Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19567.00',
          mileage: '23920'
        },
        sellArea: '800',
        dealerCd: '07009',
        certificationNumber: 9477963,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8JJ198547',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:23:50.247Z'
        }
      },
      sort: [
        1591478623501
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0HW615591220462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:23:25.580Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19985.00',
          mileage: '35940'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9430451,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0HW615591',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T15:43:40.988Z'
        }
      },
      sort: [
        1591478605580
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYVXHY173919290862020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:23:15.760Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14590.00',
          mileage: '16645'
        },
        sellArea: '160',
        dealerCd: '29086',
        certificationNumber: 9425155,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYVXHY173919',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T14:08:59.225Z'
        }
      },
      sort: [
        1591478595760
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW634047220462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:22:46.019Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19895.00',
          mileage: '32620'
        },
        sellArea: '230',
        dealerCd: '22046',
        certificationNumber: 9396203,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW634047',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T22:33:55.921Z'
        }
      },
      sort: [
        1591478566019
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4HM071995340982020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:18:34.217Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '33321'
        },
        sellArea: '220',
        dealerCd: '34098',
        certificationNumber: 9486947,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4HM071995',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:42:37.982Z'
        }
      },
      sort: [
        1591478314217
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW603837340982020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:17:53.977Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '8224'
        },
        sellArea: '220',
        dealerCd: '34098',
        certificationNumber: 9501351,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW603837',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:03:15.666Z'
        }
      },
      sort: [
        1591478273977
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HJ154694340982020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:17:22.676Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '20156'
        },
        sellArea: '220',
        dealerCd: '34098',
        certificationNumber: 9497180,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HJ154694',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:37:45.447Z'
        }
      },
      sort: [
        1591478242676
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN4KX194973160482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:15:56.915Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '33113.00',
          mileage: '13693'
        },
        dealerCd: '16048',
        certificationNumber: 9356296,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN4KX194973',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-27T22:29:59.921Z'
        }
      },
      sort: [
        1591478156915
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRY5F17FX178677460712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:14:22.134Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26451.00',
          mileage: '64537'
        },
        sellArea: '600',
        dealerCd: '46071',
        certificationNumber: 9500389,
        makeCode: 'TOYOTA',
        vin: '5TFRY5F17FX178677',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DBL CAB 5.7L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8241',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:36:16.108Z'
        }
      },
      sort: [
        1591478062134
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH4GS274177160482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:14:06.554Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23900.00',
          mileage: '80566'
        },
        sellArea: '220',
        dealerCd: '16048',
        certificationNumber: 9461396,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH4GS274177',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T21:21:56.208Z'
        }
      },
      sort: [
        1591478046554
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU5H3034821043032020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:10:38.711Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17303.00',
          mileage: '38499'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9417238,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU5H3034821',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T18:59:22.163Z'
        }
      },
      sort: [
        1591477838711
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK4J3018370041672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:09:56.851Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17895.00',
          mileage: '43115'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9451805,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK4J3018370',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T00:07:22.262Z'
        }
      },
      sort: [
        1591477796851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2H5443235430162020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:09:45.529Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34498.00',
          mileage: '31802'
        },
        sellArea: '150',
        dealerCd: '43016',
        certificationNumber: 9490098,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2H5443235',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T23:15:18.299Z'
        }
      },
      sort: [
        1591477785529
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5EN8HX057863460712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:09:31.669Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24581.00',
          mileage: '15311'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9494158,
        makeCode: 'TOYOTA',
        vin: '5TFSX5EN8HX057863',
        model: {
          modelDescription: 'TACOMA SR 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7514',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:28:59.501Z'
        }
      },
      sort: [
        1591477771669
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7FU903491470282020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:08:50.530Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16750.00',
          mileage: '51409'
        },
        sellArea: '800',
        dealerCd: '47028',
        certificationNumber: 9482102,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7FU903491',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:12:42.098Z'
        }
      },
      sort: [
        1591477730530
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HJ702809371182020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:08:01.069Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20990.00',
          mileage: '23820'
        },
        sellArea: '800',
        dealerCd: '37118',
        certificationNumber: 9500328,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HJ702809',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:02:33.644Z'
        }
      },
      sort: [
        1591477681069
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8GW479482200512019-11-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:06:47.826Z',
          retailDate: '2019-11-02',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '35945'
        },
        sellArea: '170',
        dealerCd: '20051',
        certificationNumber: 9501628,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8GW479482',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T21:00:32.919Z'
        }
      },
      sort: [
        1591477607826
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BPRHEXEC053262370672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:06:10.367Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13977.00',
          mileage: '18042'
        },
        sellArea: '800',
        dealerCd: '37067',
        certificationNumber: 9467286,
        makeCode: 'TOYOTA',
        vin: '2T1BPRHEXEC053262',
        model: {
          modelDescription: 'COROLLA LE ECO',
          modelSeries: 'COROLLA',
          modelNumber: '1872',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:39:23.441Z'
        }
      },
      sort: [
        1591477570367
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU677690190262020-05-11H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:06:01.808Z',
          retailDate: '2020-05-11',
          rdrStatus: 'H',
          soldAmount: '14994.00',
          mileage: '49267'
        },
        sellArea: '800',
        dealerCd: '19026',
        certificationNumber: 9428623,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU677690',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T17:31:25.855Z'
        }
      },
      sort: [
        1591477561808
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5ANXHM083777370672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:05:25.166Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29000.00',
          mileage: '36643'
        },
        sellArea: '800',
        dealerCd: '37067',
        certificationNumber: 9497770,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5ANXHM083777',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:16:51.277Z'
        }
      },
      sort: [
        1591477525166
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBY5G16KS172725460712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:04:00.426Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '41138.96',
          mileage: '21005'
        },
        sellArea: '150',
        dealerCd: '46071',
        certificationNumber: 9493206,
        makeCode: 'TOYOTA',
        vin: '5TDBY5G16KS172725',
        model: {
          modelDescription: 'SEQUOIA 4X4 SR5 8-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7919',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:30:05.092Z'
        }
      },
      sort: [
        1591477440426
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP3L3123115190262020-05-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:03:49.725Z',
          retailDate: '2020-05-07',
          rdrStatus: 'H',
          soldAmount: '25494.00',
          mileage: '15550'
        },
        sellArea: '800',
        dealerCd: '19026',
        certificationNumber: 9435534,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP3L3123115',
        model: {
          modelDescription: 'PRIUS PRIME XLE',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T15:57:30.370Z'
        }
      },
      sort: [
        1591477429725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KW042050240772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:02:57.084Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '24319'
        },
        sellArea: '600',
        dealerCd: '24077',
        certificationNumber: 9428792,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KW042050',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T18:43:28.007Z'
        }
      },
      sort: [
        1591477377084
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV9KW012256190262020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:02:09.477Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23820.00',
          mileage: '28719'
        },
        sellArea: '500',
        dealerCd: '19026',
        certificationNumber: 9488277,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV9KW012256',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:36:27.138Z'
        }
      },
      sort: [
        1591477329477
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU173830370672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:02:08.597Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22330.00',
          mileage: '35105'
        },
        sellArea: '600',
        dealerCd: '37067',
        certificationNumber: 9485438,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU173830',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:51:19.384Z'
        }
      },
      sort: [
        1591477328597
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC837700370672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:01:16.578Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14411.90',
          mileage: '21684'
        },
        sellArea: '800',
        dealerCd: '37067',
        certificationNumber: 9468886,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC837700',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:21:43.798Z'
        }
      },
      sort: [
        1591477276578
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU399483220472020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:00:55.637Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15999.00',
          mileage: '33012'
        },
        sellArea: '210',
        dealerCd: '22047',
        certificationNumber: 9484120,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU399483',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:51:48.772Z'
        }
      },
      sort: [
        1591477255637
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH3GS264463370672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:00:16.037Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25500.00',
          mileage: '60965'
        },
        sellArea: '800',
        dealerCd: '37067',
        certificationNumber: 9413140,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH3GS264463',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T10:30:35.581Z'
        }
      },
      sort: [
        1591477216037
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DU0F0476198190262020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T21:00:06.495Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '15795.00',
          mileage: '43978'
        },
        sellArea: '800',
        dealerCd: '19026',
        certificationNumber: 9441226,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DU0F0476198',
        model: {
          modelDescription: 'PRIUS Four Model',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1227',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-07T14:58:57.202Z'
        }
      },
      sort: [
        1591477206495
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HJ134631240772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:59:35.097Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20301.00',
          mileage: '26098'
        },
        sellArea: '160',
        dealerCd: '24077',
        certificationNumber: 9501259,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HJ134631',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:38:52.459Z'
        }
      },
      sort: [
        1591477175097
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4JD243173370672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:59:11.795Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '15663'
        },
        sellArea: '800',
        dealerCd: '37067',
        certificationNumber: 9485416,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4JD243173',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:40:53.658Z'
        }
      },
      sort: [
        1591477151795
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS403304460712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:58:53.215Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29500.00',
          mileage: '31592'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9494144,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS403304',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:24:59.939Z'
        }
      },
      sort: [
        1591477133215
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU367789190262020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:58:29.138Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '14750.00',
          mileage: '53538'
        },
        sellArea: '160',
        dealerCd: '19026',
        certificationNumber: 9419278,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU367789',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T22:35:49.100Z'
        }
      },
      sort: [
        1591477109138
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5JC981368370672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:58:08.657Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '30648'
        },
        sellArea: '800',
        dealerCd: '37067',
        certificationNumber: 9485556,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5JC981368',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:20:05.677Z'
        }
      },
      sort: [
        1591477088657
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV5KW034310190262020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:57:50.837Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '23989.00',
          mileage: '32522'
        },
        sellArea: '500',
        dealerCd: '19026',
        certificationNumber: 9470686,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV5KW034310',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:57:57.086Z'
        }
      },
      sort: [
        1591477070837
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS402365200512020-02-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:57:42.355Z',
          retailDate: '2020-02-22',
          rdrStatus: 'H',
          soldAmount: '28450.00',
          mileage: '38405'
        },
        sellArea: '170',
        dealerCd: '20051',
        certificationNumber: 9501619,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS402365',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:55:04.994Z'
        }
      },
      sort: [
        1591477062355
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HD198221240772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:56:16.674Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18551.00',
          mileage: '40579'
        },
        sellArea: '210',
        dealerCd: '24077',
        certificationNumber: 9501352,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HD198221',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:03:25.208Z'
        }
      },
      sort: [
        1591476976674
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8HS846982240612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:55:09.055Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28794.50',
          mileage: '20474'
        },
        sellArea: '800',
        dealerCd: '24061',
        certificationNumber: 9477209,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8HS846982',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T12:30:07.977Z'
        }
      },
      sort: [
        1591476909055
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU103717200512019-07-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:54:04.854Z',
          retailDate: '2019-07-18',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '30766'
        },
        sellArea: '600',
        dealerCd: '20051',
        certificationNumber: 9501600,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU103717',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:47:42.934Z'
        }
      },
      sort: [
        1591476844854
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH3HS384292310692020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:53:36.254Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29500.00',
          mileage: '33084'
        },
        sellArea: '160',
        dealerCd: '31069',
        certificationNumber: 9404924,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH3HS384292',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T18:37:48.232Z'
        }
      },
      sort: [
        1591476816254
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN3KX186962190262020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:52:59.655Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '27776.00',
          mileage: '31657'
        },
        sellArea: '500',
        dealerCd: '19026',
        certificationNumber: 9419257,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN3KX186962',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T22:25:37.451Z'
        }
      },
      sort: [
        1591476779655
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV8HJ707934150502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:52:09.454Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18308.00',
          mileage: '34352'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9460846,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV8HJ707934',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:46:26.292Z'
        }
      },
      sort: [
        1591476729454
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC945554291022020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:52:08.073Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15692.00',
          mileage: '8801'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9501452,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC945554',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:14:18.165Z'
        }
      },
      sort: [
        1591476728073
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU258707240772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:52:06.173Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18081.00',
          mileage: '19667'
        },
        sellArea: '600',
        dealerCd: '24077',
        certificationNumber: 9411630,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU258707',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T19:58:25.550Z'
        }
      },
      sort: [
        1591476726173
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3HW588637190262020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:51:52.015Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '20878.00',
          mileage: '57649'
        },
        sellArea: '160',
        dealerCd: '19026',
        certificationNumber: 9435539,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3HW588637',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T16:23:17.719Z'
        }
      },
      sort: [
        1591476712015
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE4LJ023966150502020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:51:01.454Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17300.00',
          mileage: '16064'
        },
        sellArea: '600',
        dealerCd: '15050',
        certificationNumber: 9473647,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE4LJ023966',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T23:07:38.403Z'
        }
      },
      sort: [
        1591476661454
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU766219200512020-01-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:49:29.013Z',
          retailDate: '2020-01-05',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '6223'
        },
        sellArea: '170',
        dealerCd: '20051',
        certificationNumber: 9501566,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU766219',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:27:14.553Z'
        }
      },
      sort: [
        1591476569013
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU776117041872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:47:47.373Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21988.00',
          mileage: '21787'
        },
        sellArea: '600',
        dealerCd: '04187',
        certificationNumber: 9485531,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU776117',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:13:38.474Z'
        }
      },
      sort: [
        1591476467373
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU254119041872020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:47:25.853Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21988.00',
          mileage: '24185'
        },
        sellArea: '600',
        dealerCd: '04187',
        certificationNumber: 9498156,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU254119',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:13:46.228Z'
        }
      },
      sort: [
        1591476445853
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU791651160562020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:46:45.252Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16774.15',
          mileage: '24723'
        },
        sellArea: '220',
        dealerCd: '16056',
        certificationNumber: 9494219,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU791651',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:45:25.012Z'
        }
      },
      sort: [
        1591476405252
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7J5557351160562020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:45:47.523Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '35876.42',
          mileage: '11613'
        },
        sellArea: '220',
        dealerCd: '16056',
        certificationNumber: 9434659,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7J5557351',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T14:09:13.843Z'
        }
      },
      sort: [
        1591476347523
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA16H8701179270212020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:44:10.850Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '21975.00',
          mileage: '14102'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9474559,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA16H8701179',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:03:26.789Z'
        }
      },
      sort: [
        1591476250850
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHJ713302240772020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:43:27.171Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18398.00',
          mileage: '34195'
        },
        sellArea: '210',
        dealerCd: '24077',
        certificationNumber: 9481932,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHJ713302',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:21:12.810Z'
        }
      },
      sort: [
        1591476207171
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV9JD179740480072020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:43:12.989Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26500.00',
          mileage: '22151'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9497688,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV9JD179740',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:44:17.269Z'
        }
      },
      sort: [
        1591476192989
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW585779240772020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:42:51.151Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18038.00',
          mileage: '31178'
        },
        sellArea: '220',
        dealerCd: '24077',
        certificationNumber: 9481914,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW585779',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:15:12.928Z'
        }
      },
      sort: [
        1591476171151
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV5JJ232761210672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:42:21.909Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24990.00',
          mileage: '19315'
        },
        sellArea: '220',
        dealerCd: '21067',
        certificationNumber: 9497728,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV5JJ232761',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:00:30.559Z'
        }
      },
      sort: [
        1591476141909
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0JD257234240772020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:42:12.049Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20608.00',
          mileage: '11192'
        },
        sellArea: '230',
        dealerCd: '24077',
        certificationNumber: 9482054,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0JD257234',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:02:18.894Z'
        }
      },
      sort: [
        1591476132049
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KC029116240772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:41:25.350Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24529.00',
          mileage: '24939'
        },
        sellArea: '600',
        dealerCd: '24077',
        certificationNumber: 9447480,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KC029116',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T19:37:29.071Z'
        }
      },
      sort: [
        1591476085350
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC9KS009249200512019-10-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:41:20.190Z',
          retailDate: '2019-10-29',
          rdrStatus: 'H',
          soldAmount: '26000.00',
          mileage: '14521'
        },
        sellArea: '150',
        dealerCd: '20051',
        certificationNumber: 9501567,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC9KS009249',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:27:57.797Z'
        }
      },
      sort: [
        1591476080190
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYVXKY523198270212020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:39:25.328Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '15777.00',
          mileage: '7348'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9464929,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYVXKY523198',
        model: {
          modelDescription: 'YARIS Sedan 4Dr.Sedan LE 6AT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T21:39:25.120Z'
        }
      },
      sort: [
        1591475965328
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH7JS037177341202020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:38:40.308Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23900.00',
          mileage: '33457'
        },
        sellArea: '220',
        dealerCd: '34120',
        certificationNumber: 9495090,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH7JS037177',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:05:15.848Z'
        }
      },
      sort: [
        1591475920308
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KW045222240772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:38:23.688Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24001.00',
          mileage: '28061'
        },
        sellArea: '600',
        dealerCd: '24077',
        certificationNumber: 9428766,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KW045222',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T18:32:59.519Z'
        }
      },
      sort: [
        1591475903688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7LC094088140462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:38:06.207Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '3689'
        },
        sellArea: '500',
        dealerCd: '14046',
        certificationNumber: 9477329,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7LC094088',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T14:18:28.746Z'
        }
      },
      sort: [
        1591475886207
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN7HX077697341202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:37:30.828Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28900.00',
          mileage: '11078'
        },
        sellArea: '220',
        dealerCd: '34120',
        certificationNumber: 9478010,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN7HX077697',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7562',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:39:11.116Z'
        }
      },
      sort: [
        1591475850828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC803045240772020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:37:06.828Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13500.00',
          mileage: '37985'
        },
        sellArea: '210',
        dealerCd: '24077',
        certificationNumber: 9483201,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC803045',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:21:59.121Z'
        }
      },
      sort: [
        1591475826828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP704029240772020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:36:27.188Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13801.00',
          mileage: '23441'
        },
        sellArea: '210',
        dealerCd: '24077',
        certificationNumber: 9481878,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP704029',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T17:58:30.023Z'
        }
      },
      sort: [
        1591475787188
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK5J3069439240772020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:35:35.667Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18171.00',
          mileage: '18003'
        },
        sellArea: '230',
        dealerCd: '24077',
        certificationNumber: 9475261,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK5J3069439',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T23:24:08.557Z'
        }
      },
      sort: [
        1591475735667
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU760562240772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:34:41.526Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17300.00',
          mileage: '33876'
        },
        sellArea: '500',
        dealerCd: '24077',
        certificationNumber: 9422571,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU760562',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T17:40:17.227Z'
        }
      },
      sort: [
        1591475681526
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRX5GN8GX071286240772020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:34:06.586Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '35727'
        },
        sellArea: '230',
        dealerCd: '24077',
        certificationNumber: 9501429,
        makeCode: 'TOYOTA',
        vin: '5TFRX5GN8GX071286',
        model: {
          modelDescription: 'TACOMA SR5 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7122',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:52:16.354Z'
        }
      },
      sort: [
        1591475646586
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC9LS230296371792020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:32:46.906Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34358.00',
          mileage: '8969'
        },
        dealerCd: '37179',
        certificationNumber: 9336715,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC9LS230296',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-14T19:41:44.861Z'
        }
      },
      sort: [
        1591475566906
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV7HW585893210672020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:30:51.405Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18990.00',
          mileage: '20275'
        },
        sellArea: '220',
        dealerCd: '21067',
        certificationNumber: 9491495,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV7HW585893',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:52:39.303Z'
        }
      },
      sort: [
        1591475451405
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV9JJ160372270212020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:30:33.366Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19425.00',
          mileage: '20651'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9449461,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV9JJ160372',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-25T17:12:30.005Z'
        }
      },
      sort: [
        1591475433366
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0J5567753460712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:24:43.257Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '36000.00',
          mileage: '26004'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9471590,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0J5567753',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T21:18:09.893Z'
        }
      },
      sort: [
        1591475083257
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV7KW016587270212020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:23:00.023Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '23777.00',
          mileage: '8984'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9464653,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV7KW016587',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T19:06:22.583Z'
        }
      },
      sort: [
        1591474980023
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV8HD128939360992020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:22:33.584Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '55067'
        },
        sellArea: '130',
        dealerCd: '36099',
        certificationNumber: 9435564,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV8HD128939',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T17:33:42.551Z'
        }
      },
      sort: [
        1591474953584
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV1HW353940040562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:20:00.260Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '31617'
        },
        sellArea: '120',
        dealerCd: '04056',
        certificationNumber: 9456117,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV1HW353940',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T18:17:28.032Z'
        }
      },
      sort: [
        1591474800260
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH0GS336982200512019-10-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:18:51.800Z',
          retailDate: '2019-10-26',
          rdrStatus: 'H',
          soldAmount: '30999.00',
          mileage: '21449'
        },
        sellArea: '170',
        dealerCd: '20051',
        certificationNumber: 9501554,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH0GS336982',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T20:14:47.852Z'
        }
      },
      sort: [
        1591474731800
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU211887270212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:18:10.866Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20991.00',
          mileage: '14189'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9490153,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU211887',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T23:46:32.773Z'
        }
      },
      sort: [
        1591474690866
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFHPRAE4LP007511480072020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:14:16.699Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15703.98',
          mileage: '10912'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9497846,
        makeCode: 'TOYOTA',
        vin: '5YFHPRAE4LP007511',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:36:41.921Z'
        }
      },
      sort: [
        1591474456699
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU762054410562020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:13:16.599Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18992.00',
          mileage: '40295'
        },
        sellArea: '600',
        dealerCd: '41056',
        certificationNumber: 9476464,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU762054',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:58:34.896Z'
        }
      },
      sort: [
        1591474396599
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV7KY523224270212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:13:13.738Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '14777.00',
          mileage: '3287'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9485930,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV7KY523224',
        model: {
          modelDescription: 'YARIS Sedan 4Dr.Sedan LE 6AT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:10:47.871Z'
        }
      },
      sort: [
        1591474393738
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1GC705813410812020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:12:03.319Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14721.00',
          mileage: '29218'
        },
        dealerCd: '41081',
        certificationNumber: 9452128,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1GC705813',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T15:38:20.331Z'
        }
      },
      sort: [
        1591474323319
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F13JX736651410562020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:11:40.658Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '37989.14',
          mileage: '52009'
        },
        sellArea: '220',
        dealerCd: '41056',
        certificationNumber: 9496927,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F13JX736651',
        model: {
          modelDescription: 'TUNDRA 4X4 PLATINUM CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8377',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:28:10.129Z'
        }
      },
      sort: [
        1591474300658
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F13JX694944110302020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:10:53.097Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '42418.00',
          mileage: '23887'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9489117,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F13JX694944',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T15:04:29.997Z'
        }
      },
      sort: [
        1591474253097
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1L11AK7LU857800410602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:08:20.697Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '5391'
        },
        sellArea: '220',
        dealerCd: '41060',
        certificationNumber: 9501508,
        makeCode: 'TOYOTA',
        vin: '4T1L11AK7LU857800',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:53:25.907Z'
        }
      },
      sort: [
        1591474100697
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW636534220462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T20:01:08.474Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24985.00',
          mileage: '16485'
        },
        sellArea: '800',
        dealerCd: '22046',
        certificationNumber: 9409315,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW636534',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T00:25:49.645Z'
        }
      },
      sort: [
        1591473668474
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN6KM076805040702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:59:23.294Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31899.00',
          mileage: '11178'
        },
        sellArea: '120',
        dealerCd: '04070',
        certificationNumber: 9501501,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN6KM076805',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:48:39.684Z'
        }
      },
      sort: [
        1591473563294
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH6GS356036049902020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:56:55.833Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29900.00',
          mileage: '32877'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9480543,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH6GS356036',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T17:20:16.892Z'
        }
      },
      sort: [
        1591473415833
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV6HY147639340932020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:55:34.850Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '12840.00',
          mileage: '16024'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9479193,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV6HY147639',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T17:13:28.518Z'
        }
      },
      sort: [
        1591473334850
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV7HW574940340932020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:54:50.372Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21553.00',
          mileage: '25821'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9482478,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV7HW574940',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:54:32.156Z'
        }
      },
      sort: [
        1591473290372
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV3HY181215340262020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:53:46.780Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13000.00',
          mileage: '16071'
        },
        sellArea: '220',
        dealerCd: '34026',
        certificationNumber: 9456593,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV3HY181215',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T14:51:28.795Z'
        }
      },
      sort: [
        1591473226780
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV1HJ720363049902020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:53:11.910Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20429.00',
          mileage: '21436'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9427689,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV1HJ720363',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T21:28:41.953Z'
        }
      },
      sort: [
        1591473191910
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJJREVXHD104588250602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:53:05.748Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26990.00',
          mileage: '38329'
        },
        sellArea: '110',
        dealerCd: '25060',
        certificationNumber: 9500500,
        makeCode: 'TOYOTA',
        vin: 'JTMJJREVXHD104588',
        model: {
          modelDescription: 'RAV4 HYBRID SE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T23:42:37.011Z'
        }
      },
      sort: [
        1591473185748
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC3LS025054460712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:51:54.029Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32148.00',
          mileage: '13924'
        },
        sellArea: '500',
        dealerCd: '46071',
        certificationNumber: 9433368,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC3LS025054',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T20:54:17.075Z'
        }
      },
      sort: [
        1591473114029
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ154304250602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:50:56.968Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21990.00',
          mileage: '26707'
        },
        sellArea: '130',
        dealerCd: '25060',
        certificationNumber: 9496090,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ154304',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:31:14.665Z'
        }
      },
      sort: [
        1591473056968
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU4H3560602270212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:50:26.151Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19985.00',
          mileage: '15182'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9495645,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU4H3560602',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:01:07.052Z'
        }
      },
      sort: [
        1591473026151
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU284588270212020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:48:11.807Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18777.00',
          mileage: '57'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9486411,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU284588',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:49:28.481Z'
        }
      },
      sort: [
        1591472891807
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4J5530981250602020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:47:58.450Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '38389.00',
          mileage: '22378'
        },
        sellArea: '600',
        dealerCd: '25060',
        certificationNumber: 9500539,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4J5530981',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T00:01:47.068Z'
        }
      },
      sort: [
        1591472878450
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW628172130682020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:47:33.788Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21200.00',
          mileage: '44815'
        },
        sellArea: '210',
        dealerCd: '13068',
        certificationNumber: 9494227,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW628172',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:47:25.961Z'
        }
      },
      sort: [
        1591472853788
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6HJ721380130682020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:46:22.330Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16988.00',
          mileage: '34308'
        },
        sellArea: '210',
        dealerCd: '13068',
        certificationNumber: 9487337,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6HJ721380',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:13:54.781Z'
        }
      },
      sort: [
        1591472782330
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS514444130682020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:45:29.427Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27988.00',
          mileage: '31790'
        },
        sellArea: '210',
        dealerCd: '13068',
        certificationNumber: 9482539,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS514444',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T22:27:14.062Z'
        }
      },
      sort: [
        1591472729427
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN8HM029611049902020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:44:22.387Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27900.00',
          mileage: '21914'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9501481,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN8HM029611',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:40:19.420Z'
        }
      },
      sort: [
        1591472662387
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC4LS048607270212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:44:21.500Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26591.00',
          mileage: '16603'
        },
        sellArea: '500',
        dealerCd: '27021',
        certificationNumber: 9436652,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC4LS048607',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-30T20:52:40.942Z'
        }
      },
      sort: [
        1591472661500
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KW031427130682020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:42:50.717Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23988.00',
          mileage: '30374'
        },
        sellArea: '600',
        dealerCd: '13068',
        certificationNumber: 9471386,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KW031427',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T20:00:00.051Z'
        }
      },
      sort: [
        1591472570717
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN0HM027690470182020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:42:12.874Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '34285.00',
          mileage: '12478'
        },
        sellArea: '800',
        dealerCd: '47018',
        certificationNumber: 9501158,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN0HM027690',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T15:41:34.694Z'
        }
      },
      sort: [
        1591472532874
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP642836270212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:41:45.565Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13895.00',
          mileage: '48284'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9498333,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP642836',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T23:18:32.154Z'
        }
      },
      sort: [
        1591472505565
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS375007130682020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:40:36.285Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28005.00',
          mileage: '30172'
        },
        sellArea: '170',
        dealerCd: '13068',
        certificationNumber: 9482534,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS375007',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T22:22:33.525Z'
        }
      },
      sort: [
        1591472436285
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS396037130682020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:39:42.575Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27438.00',
          mileage: '29571'
        },
        sellArea: '170',
        dealerCd: '13068',
        certificationNumber: 9487379,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS396037',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:25:24.508Z'
        }
      },
      sort: [
        1591472382575
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU739295050642020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:39:02.894Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20888.00',
          mileage: '30011'
        },
        sellArea: '600',
        dealerCd: '05064',
        certificationNumber: 9500322,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU739295',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:00:16.025Z'
        }
      },
      sort: [
        1591472342894
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS389248130682020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:38:45.094Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27975.00',
          mileage: '24509'
        },
        sellArea: '170',
        dealerCd: '13068',
        certificationNumber: 9481908,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS389248',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:12:25.147Z'
        }
      },
      sort: [
        1591472325094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU359685291022020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:37:10.031Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15411.32',
          mileage: '20835'
        },
        dealerCd: '29102',
        certificationNumber: 9373814,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU359685',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-08T18:21:34.578Z'
        }
      },
      sort: [
        1591472230031
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU737683270212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:36:46.930Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20588.00',
          mileage: '30510'
        },
        sellArea: '500',
        dealerCd: '27021',
        certificationNumber: 9486406,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU737683',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:47:03.674Z'
        }
      },
      sort: [
        1591472206930
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP694869040702020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:36:17.109Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15799.00',
          mileage: '22995'
        },
        sellArea: '120',
        dealerCd: '04070',
        certificationNumber: 9501470,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP694869',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T19:32:46.278Z'
        }
      },
      sort: [
        1591472177109
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DCXHS183593291022020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:35:38.311Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25859.00',
          mileage: '19646'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9501254,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DCXHS183593',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:37:43.039Z'
        }
      },
      sort: [
        1591472138311
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3FP259727044122020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:32:53.728Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '12840.52',
          mileage: '17761'
        },
        sellArea: '120',
        dealerCd: '04412',
        certificationNumber: 9485577,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3FP259727',
        model: {
          modelDescription: 'COROLLA S PREMIUM',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:25:39.341Z'
        }
      },
      sort: [
        1591471973728
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8JW802890480272020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:29:31.402Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18198.00',
          mileage: '68027'
        },
        sellArea: '500',
        dealerCd: '48027',
        certificationNumber: 9470898,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8JW802890',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T17:00:03.305Z'
        }
      },
      sort: [
        1591471771402
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV3GW451568341222020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:25:10.725Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19695.00',
          mileage: '42201'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9467584,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV3GW451568',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T21:38:58.523Z'
        }
      },
      sort: [
        1591471510725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1HW623546290692020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:25:07.403Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '33026'
        },
        sellArea: '160',
        dealerCd: '29069',
        certificationNumber: 9474878,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1HW623546',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:32:25.349Z'
        }
      },
      sort: [
        1591471507403
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2JW711355311852020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:24:22.164Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20700.00',
          mileage: '34017'
        },
        sellArea: '160',
        dealerCd: '31185',
        certificationNumber: 9411383,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2JW711355',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T17:36:00.167Z'
        }
      },
      sort: [
        1591471462164
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU188913290692020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:24:11.045Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21385.00',
          mileage: '35981'
        },
        sellArea: '500',
        dealerCd: '29069',
        certificationNumber: 9427436,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU188913',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T19:23:09.027Z'
        }
      },
      sort: [
        1591471451045
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV7JJ250209290692020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:23:34.465Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25368.00',
          mileage: '20317'
        },
        sellArea: '160',
        dealerCd: '29069',
        certificationNumber: 9485424,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV7JJ250209',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:46:07.339Z'
        }
      },
      sort: [
        1591471414465
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1JW711445290692020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:22:38.123Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17895.00',
          mileage: '35217'
        },
        sellArea: '160',
        dealerCd: '29069',
        certificationNumber: 9457808,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1JW711445',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T19:43:17.227Z'
        }
      },
      sort: [
        1591471358123
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMW1RFV1KJ001959290692020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:22:10.382Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21576.00',
          mileage: '24941'
        },
        sellArea: '500',
        dealerCd: '29069',
        certificationNumber: 9396732,
        makeCode: 'TOYOTA',
        vin: 'JTMW1RFV1KJ001959',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T13:46:27.675Z'
        }
      },
      sort: [
        1591471330382
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU690122290692020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:21:33.462Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18233.68',
          mileage: '36274'
        },
        sellArea: '500',
        dealerCd: '29069',
        certificationNumber: 9495820,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU690122',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:50:59.853Z'
        }
      },
      sort: [
        1591471293462
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU690832290692020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:20:56.580Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17612.00',
          mileage: '34506'
        },
        sellArea: '500',
        dealerCd: '29069',
        certificationNumber: 9429504,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU690832',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T13:46:08.734Z'
        }
      },
      sort: [
        1591471256580
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1HW669149190452020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:17:25.299Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23900.00',
          mileage: '11800'
        },
        sellArea: '800',
        dealerCd: '19045',
        certificationNumber: 9495448,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1HW669149',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:27:41.397Z'
        }
      },
      sort: [
        1591471045299
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS516014190452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:16:52.057Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '29200.00',
          mileage: '19744'
        },
        sellArea: '800',
        dealerCd: '19045',
        certificationNumber: 9495421,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS516014',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:18:21.273Z'
        }
      },
      sort: [
        1591471012057
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS511486480072020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:16:24.496Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27188.00',
          mileage: '46131'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9446671,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS511486',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T18:05:54.818Z'
        }
      },
      sort: [
        1591470984496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU3H3579982190452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:15:50.356Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18317.92',
          mileage: '27926'
        },
        sellArea: '800',
        dealerCd: '19045',
        certificationNumber: 9401396,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU3H3579982',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T19:21:55.416Z'
        }
      },
      sort: [
        1591470950356
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1HC920044270212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:14:37.036Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '12985.00',
          mileage: '39343'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9495888,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1HC920044',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:11:23.108Z'
        }
      },
      sort: [
        1591470877036
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS417979480072020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:13:49.236Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25400.00',
          mileage: '32923'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9498987,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS417979',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:57:30.073Z'
        }
      },
      sort: [
        1591470829236
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV0KY524179220462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:13:09.975Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '11785.00',
          mileage: '34256'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9487222,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV0KY524179',
        model: {
          modelDescription: 'YARIS Sedan 4Dr.Sedan LE 6AT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:33:05.888Z'
        }
      },
      sort: [
        1591470789975
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM077563380242020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:13:05.375Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30601.00',
          mileage: '25427'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9496492,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM077563',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:43:04.916Z'
        }
      },
      sort: [
        1591470785375
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7GP463447270212020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:11:31.592Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '11668.00',
          mileage: '55479'
        },
        sellArea: '110',
        dealerCd: '27021',
        certificationNumber: 9495667,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7GP463447',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:05:25.068Z'
        }
      },
      sort: [
        1591470691592
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6HM076440280212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:10:10.168Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '31494.00',
          mileage: '35843'
        },
        sellArea: '170',
        dealerCd: '28021',
        certificationNumber: 9467534,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6HM076440',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T20:58:46.589Z'
        }
      },
      sort: [
        1591470610168
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8HW569962280212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:07:44.531Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20741.00',
          mileage: '24875'
        },
        sellArea: '160',
        dealerCd: '28021',
        certificationNumber: 9497098,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8HW569962',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:02:15.616Z'
        }
      },
      sort: [
        1591470464531
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW616856480272020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:07:42.509Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20398.00',
          mileage: '32530'
        },
        sellArea: '220',
        dealerCd: '48027',
        certificationNumber: 9486179,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW616856',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:29:43.133Z'
        }
      },
      sort: [
        1591470462509
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU806696280212020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:06:24.229Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '4325'
        },
        sellArea: '170',
        dealerCd: '28021',
        certificationNumber: 9498048,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU806696',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:28:35.663Z'
        }
      },
      sort: [
        1591470384229
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8HJ136396291052020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:03:14.447Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18799.00',
          mileage: '36284'
        },
        sellArea: '160',
        dealerCd: '29105',
        certificationNumber: 9499951,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8HJ136396',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:05:49.708Z'
        }
      },
      sort: [
        1591470194447
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU116359371752020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:02:12.483Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '21592.00',
          mileage: '32408'
        },
        sellArea: '800',
        dealerCd: '37175',
        certificationNumber: 9501438,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXJU116359',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:58:18.560Z'
        }
      },
      sort: [
        1591470132483
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW598400371752020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:01:27.584Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '34249'
        },
        sellArea: '800',
        dealerCd: '37175',
        certificationNumber: 9501439,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW598400',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T18:58:21.537Z'
        }
      },
      sort: [
        1591470087584
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7GM032039280122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T19:01:14.725Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26500.00',
          mileage: '43341'
        },
        sellArea: '170',
        dealerCd: '28012',
        certificationNumber: 9476463,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7GM032039',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:58:31.541Z'
        }
      },
      sort: [
        1591470074725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV0JW485187044542020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:55:33.760Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '21495.00',
          mileage: '28514'
        },
        sellArea: '500',
        dealerCd: '04454',
        certificationNumber: 9444436,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV0JW485187',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T20:24:47.503Z'
        }
      },
      sort: [
        1591469733760
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9EU442826371612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:53:54.918Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '11000.00',
          mileage: '51153'
        },
        sellArea: '800',
        dealerCd: '37161',
        certificationNumber: 9421644,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9EU442826',
        model: {
          modelDescription: 'CAMRY 2014.5 LE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T19:42:58.843Z'
        }
      },
      sort: [
        1591469634918
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS415361180342020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:53:47.559Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '28299.00',
          mileage: '42608'
        },
        sellArea: '170',
        dealerCd: '18034',
        certificationNumber: 9488935,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS415361',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T12:51:50.188Z'
        }
      },
      sort: [
        1591469627559
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFUXK3002843211042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:53:41.158Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22100.34',
          mileage: '14628'
        },
        sellArea: '220',
        dealerCd: '21104',
        certificationNumber: 9451449,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFUXK3002843',
        model: {
          modelDescription: 'PRIUS LE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1263',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T18:26:37.463Z'
        }
      },
      sort: [
        1591469621158
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK0HU206737044542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:53:37.688Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20993.00',
          mileage: '40777'
        },
        sellArea: '110',
        dealerCd: '04454',
        certificationNumber: 9402079,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK0HU206737',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T22:53:45.647Z'
        }
      },
      sort: [
        1591469617688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC760274470252020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:53:07.858Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16997.0000',
          mileage: '21919'
        },
        sellArea: '220',
        dealerCd: '47025',
        certificationNumber: 9501314,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC760274',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T17:33:50.941Z'
        }
      },
      sort: [
        1591469587858
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F16JX723738380242020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:52:55.918Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '36700.00',
          mileage: '28219'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9496501,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F16JX723738',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:49:06.122Z'
        }
      },
      sort: [
        1591469575918
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DCXHS843159044542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:52:31.758Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23799.00',
          mileage: '48438'
        },
        sellArea: '110',
        dealerCd: '04454',
        certificationNumber: 9444416,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DCXHS843159',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T19:48:02.250Z'
        }
      },
      sort: [
        1591469551758
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH0HS391109440132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:51:42.278Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '34156'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9487806,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH0HS391109',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:04:31.859Z'
        }
      },
      sort: [
        1591469502278
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU350972044542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:51:22.299Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15596.00',
          mileage: '41682'
        },
        sellArea: '110',
        dealerCd: '04454',
        certificationNumber: 9415772,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU350972',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T20:16:11.001Z'
        }
      },
      sort: [
        1591469482299
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU637938371612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:50:43.456Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17023.00',
          mileage: '27111'
        },
        dealerCd: '37161',
        certificationNumber: 9298275,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU637938',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-19T14:24:10.232Z'
        }
      },
      sort: [
        1591469443456
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9J5493118380242020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:49:33.195Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '34991.00',
          mileage: '36373'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9492158,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9J5493118',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:50:39.802Z'
        }
      },
      sort: [
        1591469373195
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV0HJ131501044542020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:49:16.636Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18299.00',
          mileage: '32591'
        },
        sellArea: '110',
        dealerCd: '04454',
        certificationNumber: 9420369,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV0HJ131501',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T19:17:23.434Z'
        }
      },
      sort: [
        1591469356636
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW621597380242020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:47:15.754Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23292.09',
          mileage: '16595'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9496526,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW621597',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T00:12:48.395Z'
        }
      },
      sort: [
        1591469235754
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HJ120925371612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:46:04.034Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '39559'
        },
        dealerCd: '37161',
        certificationNumber: 9370143,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HJ120925',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-05T23:38:50.956Z'
        }
      },
      sort: [
        1591469164034
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC5HS159926044542020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:43:49.671Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31395.00',
          mileage: '25745'
        },
        sellArea: '120',
        dealerCd: '04454',
        certificationNumber: 9399023,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC5HS159926',
        model: {
          modelDescription: 'SIENNA LTD PREM AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5387',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T17:39:12.797Z'
        }
      },
      sort: [
        1591469029671
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV1HJ120203311922020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:42:02.649Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '11273'
        },
        sellArea: '160',
        dealerCd: '31192',
        certificationNumber: 9427857,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV1HJ120203',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T23:57:44.286Z'
        }
      },
      sort: [
        1591468922649
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7H5407962140462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:41:55.129Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '33995.00',
          mileage: '35686'
        },
        sellArea: '230',
        dealerCd: '14046',
        certificationNumber: 9497500,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7H5407962',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:41:01.180Z'
        }
      },
      sort: [
        1591468915129
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5ANXLX222924490222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:39:52.868Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '34993.00',
          mileage: '3508'
        },
        sellArea: '150',
        dealerCd: '49022',
        certificationNumber: 9469897,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5ANXLX222924',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T18:08:44.067Z'
        }
      },
      sort: [
        1591468792868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH0HS417479290372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:36:24.786Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25444.00',
          mileage: '19849'
        },
        sellArea: '160',
        dealerCd: '29037',
        certificationNumber: 9465560,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH0HS417479',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:34:53.531Z'
        }
      },
      sort: [
        1591468584786
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HD212492340932020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:36:13.886Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20990.00',
          mileage: '25353'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9489907,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HD212492',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T20:57:22.432Z'
        }
      },
      sort: [
        1591468573886
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV8KD009107340932020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:35:30.624Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25100.00',
          mileage: '17418'
        },
        sellArea: '800',
        dealerCd: '34093',
        certificationNumber: 9498238,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV8KD009107',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:51:59.029Z'
        }
      },
      sort: [
        1591468530624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH0HS429793310712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:26:22.038Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '39509'
        },
        sellArea: '160',
        dealerCd: '31071',
        certificationNumber: 9484993,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH0HS429793',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T12:34:17.294Z'
        }
      },
      sort: [
        1591467982038
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC951705291002020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:26:04.695Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15890.00',
          mileage: '11775'
        },
        sellArea: '160',
        dealerCd: '29100',
        certificationNumber: 9501272,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC951705',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:46:59.221Z'
        }
      },
      sort: [
        1591467964695
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ120196310712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:25:10.436Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '20495.00',
          mileage: '35959'
        },
        sellArea: '160',
        dealerCd: '31071',
        certificationNumber: 9497567,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ120196',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:08:47.307Z'
        }
      },
      sort: [
        1591467910436
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC2HS861158310712020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:22:20.519Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23495.00',
          mileage: '29473'
        },
        sellArea: '160',
        dealerCd: '31071',
        certificationNumber: 9487171,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC2HS861158',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:18:22.613Z'
        }
      },
      sort: [
        1591467740519
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4HD202987310712020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:20:39.192Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20495.00',
          mileage: '24736'
        },
        sellArea: '160',
        dealerCd: '31071',
        certificationNumber: 9494321,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4HD202987',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:23:53.913Z'
        }
      },
      sort: [
        1591467639192
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW616653290372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:17:57.769Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24374.00',
          mileage: '25336'
        },
        sellArea: '160',
        dealerCd: '29037',
        certificationNumber: 9393833,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW616653',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T19:23:39.143Z'
        }
      },
      sort: [
        1591467477769
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU5GJ050810050462020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:15:54.149Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17496.00',
          mileage: '52484'
        },
        sellArea: '150',
        dealerCd: '05046',
        certificationNumber: 9455664,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU5GJ050810',
        model: {
          modelDescription: 'Prius v Two Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1243',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T20:05:08.067Z'
        }
      },
      sort: [
        1591467354149
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE7LJ016932040332020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:15:12.706Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18288.00',
          mileage: '15451'
        },
        sellArea: '110',
        dealerCd: '04033',
        certificationNumber: 9476814,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE7LJ016932',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T00:07:06.057Z'
        }
      },
      sort: [
        1591467312706
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV0HD148399291142020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:14:44.008Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24900.00',
          mileage: '25494'
        },
        sellArea: '160',
        dealerCd: '29114',
        certificationNumber: 9495444,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV0HD148399',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:26:20.416Z'
        }
      },
      sort: [
        1591467284008
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DCXHS166421311352020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:12:47.026Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '26865.00',
          mileage: '26383'
        },
        sellArea: '160',
        dealerCd: '31135',
        certificationNumber: 9463406,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DCXHS166421',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T20:02:53.265Z'
        }
      },
      sort: [
        1591467167026
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS384215480612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:12:20.934Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27866.00',
          mileage: '20239'
        },
        sellArea: '160',
        dealerCd: '48061',
        certificationNumber: 9421453,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS384215',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T15:31:55.852Z'
        }
      },
      sort: [
        1591467140934
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU370461480612020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:11:56.825Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '15682.00',
          mileage: '42081'
        },
        sellArea: '220',
        dealerCd: '48061',
        certificationNumber: 9487805,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU370461',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:04:06.798Z'
        }
      },
      sort: [
        1591467116825
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU4G3002900290372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:11:23.504Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17700.00',
          mileage: '35651'
        },
        dealerCd: '29037',
        certificationNumber: 9346404,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU4G3002900',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-21T12:48:46.272Z'
        }
      },
      sort: [
        1591467083504
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HD210001311892020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:08:42.562Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19975.97',
          mileage: '23456'
        },
        sellArea: '160',
        dealerCd: '31189',
        certificationNumber: 9501154,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HD210001',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T15:38:56.530Z'
        }
      },
      sort: [
        1591466922562
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5HW601539311892020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:06:16.662Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18758.00',
          mileage: '22425'
        },
        sellArea: '160',
        dealerCd: '31189',
        certificationNumber: 9501079,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5HW601539',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T14:47:06.947Z'
        }
      },
      sort: [
        1591466776662
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU743914210682020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:05:45.158Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15238.72',
          mileage: '51557'
        },
        dealerCd: '21068',
        certificationNumber: 9342158,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU743914',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-17T17:55:47.258Z'
        }
      },
      sort: [
        1591466745158
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU690092210682020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:04:56.638Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20513.00',
          mileage: '6703'
        },
        dealerCd: '21068',
        certificationNumber: 9383992,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU690092',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T18:26:23.428Z'
        }
      },
      sort: [
        1591466696638
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5GW426336311802020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:03:38.078Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '17900.50',
          mileage: '16708'
        },
        sellArea: '160',
        dealerCd: '31180',
        certificationNumber: 9491385,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5GW426336',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:14:01.542Z'
        }
      },
      sort: [
        1591466618078
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW560779311892020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:03:23.742Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21295.00',
          mileage: '31798'
        },
        dealerCd: '31189',
        certificationNumber: 9317579,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW560779',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-01T17:31:49.615Z'
        }
      },
      sort: [
        1591466603742
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW638487311892020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T18:01:06.741Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18256.67',
          mileage: '48233'
        },
        dealerCd: '31189',
        certificationNumber: 9361122,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW638487',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-30T16:51:42.684Z'
        }
      },
      sort: [
        1591466466741
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE5LJ023345311892020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:58:48.640Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17295.00',
          mileage: '14842'
        },
        sellArea: '500',
        dealerCd: '31189',
        certificationNumber: 9409955,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE5LJ023345',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T17:49:09.737Z'
        }
      },
      sort: [
        1591466328640
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJGRFHXHS028723360852020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:47:31.363Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27705.00',
          mileage: '45309'
        },
        sellArea: '130',
        dealerCd: '36085',
        certificationNumber: 9494662,
        makeCode: 'TOYOTA',
        vin: '5TDJGRFHXHS028723',
        model: {
          modelDescription: 'HIGHLANDER HYBRD XLE-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6965',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:39:14.671Z'
        }
      },
      sort: [
        1591465651363
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW651483160512020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:45:54.288Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21999.00',
          mileage: '63652'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9498726,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW651483',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T12:54:26.366Z'
        }
      },
      sort: [
        1591465554288
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREVXGD015606280122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:44:07.801Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18198.90',
          mileage: '64394'
        },
        sellArea: '170',
        dealerCd: '28012',
        certificationNumber: 9501260,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREVXGD015606',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:39:32.228Z'
        }
      },
      sort: [
        1591465447801
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE0LP026897160512020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:44:01.182Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16999.00',
          mileage: '5164'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9475662,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE0LP026897',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T13:12:56.849Z'
        }
      },
      sort: [
        1591465441182
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU759736047342020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:42:44.061Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21595.00',
          mileage: '33992'
        },
        sellArea: '500',
        dealerCd: '04734',
        certificationNumber: 9428967,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU759736',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T19:46:07.090Z'
        }
      },
      sort: [
        1591465364061
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU381257160512020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:42:34.681Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21300.00',
          mileage: '15648'
        },
        sellArea: '500',
        dealerCd: '16051',
        certificationNumber: 9494407,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU381257',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:56:28.351Z'
        }
      },
      sort: [
        1591465354681
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6JJ210968480322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:41:57.481Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '25389'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9496499,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6JJ210968',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:48:53.520Z'
        }
      },
      sort: [
        1591465317481
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCEXLJ023146311892020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:41:13.460Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15870.00',
          mileage: '18272'
        },
        sellArea: '500',
        dealerCd: '31189',
        certificationNumber: 9424304,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCEXLJ023146',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T19:56:36.853Z'
        }
      },
      sort: [
        1591465273460
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3K1RFV7KC031811480322020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:41:12.029Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23200.00',
          mileage: '2667'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9455810,
        makeCode: 'TOYOTA',
        vin: '2T3K1RFV7KC031811',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T22:59:58.697Z'
        }
      },
      sort: [
        1591465272029
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREVXHW347179160512020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:41:04.710Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20999.00',
          mileage: '24792'
        },
        sellArea: '500',
        dealerCd: '16051',
        certificationNumber: 9495940,
        makeCode: 'TOYOTA',
        vin: '2T3WFREVXHW347179',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:32:52.745Z'
        }
      },
      sort: [
        1591465264710
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2LS021416311892020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:40:00.948Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32990.00',
          mileage: '9230'
        },
        sellArea: '160',
        dealerCd: '31189',
        certificationNumber: 9438625,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2LS021416',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T15:36:34.600Z'
        }
      },
      sort: [
        1591465200948
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2KC051535480322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:39:10.089Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28090.00',
          mileage: '6309'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9456669,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2KC051535',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T15:58:35.980Z'
        }
      },
      sort: [
        1591465150089
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3EWRFV1LW082056480322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:38:35.349Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '36500.00',
          mileage: '523'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9500409,
        makeCode: 'TOYOTA',
        vin: '2T3EWRFV1LW082056',
        model: {
          modelDescription: 'RAV4 HYBRID XSE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:45:39.358Z'
        }
      },
      sort: [
        1591465115349
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC6HS809239160512020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:38:33.998Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21999.00',
          mileage: '43892'
        },
        sellArea: '500',
        dealerCd: '16051',
        certificationNumber: 9476124,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC6HS809239',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T17:43:46.271Z'
        }
      },
      sort: [
        1591465113998
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFHXGS312340290372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:38:21.839Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24400.00',
          mileage: '49046'
        },
        sellArea: '160',
        dealerCd: '29037',
        certificationNumber: 9417541,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFHXGS312340',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T20:53:50.606Z'
        }
      },
      sort: [
        1591465101839
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU180701311892020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:38:18.619Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24358.00',
          mileage: '12797'
        },
        sellArea: '160',
        dealerCd: '31189',
        certificationNumber: 9409356,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU180701',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T01:32:59.697Z'
        }
      },
      sort: [
        1591465098619
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE2HJ538706480322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:38:08.919Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '20352'
        },
        sellArea: '220',
        dealerCd: '48032',
        certificationNumber: 9476412,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE2HJ538706',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:36:30.117Z'
        }
      },
      sort: [
        1591465088919
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXKC009677311892020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:37:30.980Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25740.00',
          mileage: '20291'
        },
        sellArea: '500',
        dealerCd: '31189',
        certificationNumber: 9432159,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXKC009677',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T18:56:08.525Z'
        }
      },
      sort: [
        1591465050980
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK6JU045968480322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:37:18.938Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25512.00',
          mileage: '12659'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9460833,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK6JU045968',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:34:44.661Z'
        }
      },
      sort: [
        1591465038938
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV2GD028463340062020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:36:01.818Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '51531'
        },
        dealerCd: '34006',
        certificationNumber: 8237111,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV2GD028463',
        model: {
          modelDescription: 'RAV4 HYBRID',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: '2016'
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-08-05T00:00:00.000Z'
        }
      },
      sort: [
        1591464961818
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU245434290812020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:33:21.556Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22095.00',
          mileage: '8779'
        },
        sellArea: '160',
        dealerCd: '29081',
        certificationNumber: 9469832,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU245434',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T16:56:27.094Z'
        }
      },
      sort: [
        1591464801556
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU440533050112020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:33:16.697Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15993.00',
          mileage: '28640'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9453177,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU440533',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T15:03:49.070Z'
        }
      },
      sort: [
        1591464796697
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS513905050112020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:32:39.896Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29250.00',
          mileage: '26112'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9483821,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS513905',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:07:06.661Z'
        }
      },
      sort: [
        1591464759896
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS423413050112020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:31:36.776Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29591.00',
          mileage: '32533'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9477607,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS423413',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T16:48:24.738Z'
        }
      },
      sort: [
        1591464696776
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW557621050112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:30:54.756Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19591.00',
          mileage: '30025'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9490397,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW557621',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T14:59:18.180Z'
        }
      },
      sort: [
        1591464654756
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1FU110218050112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:30:19.917Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13141.00',
          mileage: '70411'
        },
        sellArea: '800',
        dealerCd: '05011',
        certificationNumber: 9496886,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1FU110218',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T12:58:16.562Z'
        }
      },
      sort: [
        1591464619917
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDHPRAE6LJ002072160512020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:30:14.875Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '8601'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9481305,
        makeCode: 'TOYOTA',
        vin: 'JTDHPRAE6LJ002072',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T12:20:35.373Z'
        }
      },
      sort: [
        1591464614875
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HJ159315050112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:29:52.616Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21790.00',
          mileage: '28749'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9483831,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HJ159315',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:11:52.019Z'
        }
      },
      sort: [
        1591464592616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9HM086976050112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:29:11.096Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '31991.00',
          mileage: '49393'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9496893,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9HM086976',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:03:51.153Z'
        }
      },
      sort: [
        1591464551096
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8HJ704596050112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:28:36.796Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18991.00',
          mileage: '30747'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9476027,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8HJ704596',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T17:08:23.073Z'
        }
      },
      sort: [
        1591464516796
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU800737160512020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:28:27.096Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19595.00',
          mileage: '18384'
        },
        sellArea: '500',
        dealerCd: '16051',
        certificationNumber: 9497172,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU800737',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:33:17.302Z'
        }
      },
      sort: [
        1591464507096
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6KS627717050112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:27:14.694Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33791.00',
          mileage: '16215'
        },
        sellArea: '150',
        dealerCd: '05011',
        certificationNumber: 9495907,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6KS627717',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:19:20.792Z'
        }
      },
      sort: [
        1591464434694
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMCY7AJ7K4083153160512020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:27:12.503Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '71500.00',
          mileage: '13611'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9474443,
        makeCode: 'TOYOTA',
        vin: 'JTMCY7AJ7K4083153',
        model: {
          modelDescription: 'LAND CRUISER 4WD SUV V8',
          modelSeries: 'LAND CRUISER',
          modelNumber: '6156',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T15:59:14.252Z'
        }
      },
      sort: [
        1591464432503
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC7LS025130050112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:26:08.223Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24494.00',
          mileage: '23226'
        },
        sellArea: '600',
        dealerCd: '05011',
        certificationNumber: 9418125,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC7LS025130',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T12:23:40.808Z'
        }
      },
      sort: [
        1591464368223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB2EU100423160512020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:25:39.022Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14800.00',
          mileage: '71097'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9453555,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB2EU100423',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T17:43:43.690Z'
        }
      },
      sort: [
        1591464339022
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC1KS227049500122020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:25:10.443Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32789.00',
          mileage: '1207'
        },
        dealerCd: '50012',
        certificationNumber: 9194163,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC1KS227049',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-11T17:19:29.385Z'
        }
      },
      sort: [
        1591464310443
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV0KW055748500122020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:24:36.624Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '32325.00',
          mileage: '4699'
        },
        sellArea: '130',
        dealerCd: '50012',
        certificationNumber: 9427052,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV0KW055748',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T16:33:34.771Z'
        }
      },
      sort: [
        1591464276624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB3HU256930160512020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:24:07.782Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17149.00',
          mileage: '63068'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9467391,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB3HU256930',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T19:42:06.684Z'
        }
      },
      sort: [
        1591464247782
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU353827341262020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:23:33.123Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14726.00',
          mileage: '39503'
        },
        dealerCd: '34126',
        certificationNumber: 9368020,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU353827',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T16:49:09.869Z'
        }
      },
      sort: [
        1591464213123
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV1HD208983341262020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:23:00.360Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23701.00',
          mileage: '30121'
        },
        sellArea: '160',
        dealerCd: '34126',
        certificationNumber: 9481319,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV1HD208983',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T12:42:51.559Z'
        }
      },
      sort: [
        1591464180360
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU412874341262020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:22:32.530Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15726.00',
          mileage: '37660'
        },
        sellArea: '220',
        dealerCd: '34126',
        certificationNumber: 9466632,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU412874',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T13:30:41.667Z'
        }
      },
      sort: [
        1591464152530
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE9JJ569005280292020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:22:31.592Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '16975.00',
          mileage: '12890'
        },
        sellArea: '170',
        dealerCd: '28029',
        certificationNumber: 9474078,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE9JJ569005',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T12:13:39.830Z'
        }
      },
      sort: [
        1591464151592
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMF1RFV7KD005822060362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:19:54.340Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22877.00',
          mileage: '23612'
        },
        sellArea: '170',
        dealerCd: '06036',
        certificationNumber: 9426091,
        makeCode: 'TOYOTA',
        vin: 'JTMF1RFV7KD005822',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T21:07:01.558Z'
        }
      },
      sort: [
        1591463994340
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS377092291042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:19:52.100Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28257.78',
          mileage: '35005'
        },
        dealerCd: '29104',
        certificationNumber: 9381852,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS377092',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T20:48:03.703Z'
        }
      },
      sort: [
        1591463992100
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8HS846013291042020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:18:14.380Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26987.00',
          mileage: '15042'
        },
        sellArea: '800',
        dealerCd: '29104',
        certificationNumber: 9494641,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8HS846013',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:25:16.804Z'
        }
      },
      sort: [
        1591463894380
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU358952291042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:16:23.336Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17086.00',
          mileage: '14330'
        },
        sellArea: '160',
        dealerCd: '29104',
        certificationNumber: 9424567,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU358952',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T21:36:21.803Z'
        }
      },
      sort: [
        1591463783336
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HJ132130291042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:15:12.338Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20084.59',
          mileage: '34342'
        },
        sellArea: '160',
        dealerCd: '29104',
        certificationNumber: 9395812,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HJ132130',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T19:58:23.822Z'
        }
      },
      sort: [
        1591463712338
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXK3DC4ES510130049902020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:14:14.118Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '16994.00',
          mileage: '69668'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9420678,
        makeCode: 'TOYOTA',
        vin: '5TDXK3DC4ES510130',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T21:18:45.600Z'
        }
      },
      sort: [
        1591463654118
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN6GX020099291042020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:14:00.876Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28186.00',
          mileage: '44982'
        },
        sellArea: '800',
        dealerCd: '29104',
        certificationNumber: 9477949,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN6GX020099',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7543',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:19:14.436Z'
        }
      },
      sort: [
        1591463640876
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KW045052290812020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:13:42.436Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24687.00',
          mileage: '7729'
        },
        sellArea: '160',
        dealerCd: '29081',
        certificationNumber: 9477318,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KW045052',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T14:14:00.486Z'
        }
      },
      sort: [
        1591463622436
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM074937291042020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:12:04.928Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29449.00',
          mileage: '34401'
        },
        sellArea: '800',
        dealerCd: '29104',
        certificationNumber: 9474514,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM074937',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:31:08.142Z'
        }
      },
      sort: [
        1591463524928
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN4HX086916042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:11:17.248Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26992.00',
          mileage: '18029'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9409248,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN4HX086916',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T23:16:00.891Z'
        }
      },
      sort: [
        1591463477248
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV4HD093154290812020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T17:11:12.388Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '19499.00',
          mileage: '60522'
        },
        sellArea: '160',
        dealerCd: '29081',
        certificationNumber: 9500909,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV4HD093154',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T13:21:24.188Z'
        }
      },
      sort: [
        1591463472388
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU329248311322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:56:26.681Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17793.89',
          mileage: '30648'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9499747,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU329248',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:51:48.981Z'
        }
      },
      sort: [
        1591462586681
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREVXHD089920060372020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:52:39.985Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22700.00',
          mileage: '45924'
        },
        sellArea: '160',
        dealerCd: '06037',
        certificationNumber: 9413423,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREVXHD089920',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T15:08:34.059Z'
        }
      },
      sort: [
        1591462359985
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F18GX064971060372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:51:48.885Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21900.00',
          mileage: '64338'
        },
        dealerCd: '06037',
        certificationNumber: 9382766,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F18GX064971',
        model: {
          modelDescription: 'TUNDRA 4X4 SR DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8339',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T16:47:43.818Z'
        }
      },
      sort: [
        1591462308885
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HJ162882311322020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:51:29.326Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '22287'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9501262,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HJ162882',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T16:40:14.745Z'
        }
      },
      sort: [
        1591462289326
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMGZ5ANXHM059853121382020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:49:15.845Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '16850'
        },
        sellArea: '210',
        dealerCd: '12138',
        certificationNumber: 9496513,
        makeCode: 'TOYOTA',
        vin: '3TMGZ5ANXHM059853',
        model: {
          modelDescription: 'TACOMA LIMITED 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7582',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:58:52.147Z'
        }
      },
      sort: [
        1591462155845
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC5HS830342060362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:47:45.003Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22267.00',
          mileage: '35522'
        },
        sellArea: '170',
        dealerCd: '06036',
        certificationNumber: 9443531,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC5HS830342',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-11T15:03:07.195Z'
        }
      },
      sort: [
        1591462065003
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8JJ748524160482020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:45:51.001Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '22313.00',
          mileage: '7831'
        },
        sellArea: '220',
        dealerCd: '16048',
        certificationNumber: 9485160,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8JJ748524',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:37:39.140Z'
        }
      },
      sort: [
        1591461951001
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU727778160482020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:42:33.559Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20900.00',
          mileage: '32238'
        },
        dealerCd: '16048',
        certificationNumber: 9376355,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU727778',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-11T15:44:32.733Z'
        }
      },
      sort: [
        1591461753559
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW646875311312020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:42:16.679Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21499.00',
          mileage: '26033'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9495620,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW646875',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:51:42.562Z'
        }
      },
      sort: [
        1591461736679
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC6LS035142160482020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:41:05.180Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32999.00',
          mileage: '21695'
        },
        sellArea: '600',
        dealerCd: '16048',
        certificationNumber: 9394052,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC6LS035142',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T20:58:26.994Z'
        }
      },
      sort: [
        1591461665180
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV1KW005263220342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:37:27.931Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31980.00',
          mileage: '14991'
        },
        sellArea: '210',
        dealerCd: '22034',
        certificationNumber: 9425614,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV1KW005263',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T17:21:42.196Z'
        }
      },
      sort: [
        1591461447931
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN8FM187961240742020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:36:22.239Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24860.00',
          mileage: '60776'
        },
        sellArea: '230',
        dealerCd: '24074',
        certificationNumber: 9445943,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN8FM187961',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-18T20:10:30.029Z'
        }
      },
      sort: [
        1591461382239
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM068262240742020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:35:50.157Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31164.00',
          mileage: '17089'
        },
        sellArea: '230',
        dealerCd: '24074',
        certificationNumber: 9479176,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM068262',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T17:07:45.260Z'
        }
      },
      sort: [
        1591461350157
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC898567291022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:34:04.076Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14153.00',
          mileage: '7244'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9499402,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC898567',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:56:16.834Z'
        }
      },
      sort: [
        1591461244076
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV6HW685332311322020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:34:02.891Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21795.00',
          mileage: '34762'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9486994,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV6HW685332',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:02:20.054Z'
        }
      },
      sort: [
        1591461242891
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV7JW465938291022020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:32:38.890Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18789.00',
          mileage: '41214'
        },
        sellArea: '500',
        dealerCd: '29102',
        certificationNumber: 9407014,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV7JW465938',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T16:45:43.784Z'
        }
      },
      sort: [
        1591461158890
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU805306291022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:32:38.462Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '15366'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9499948,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU805306',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:05:06.567Z'
        }
      },
      sort: [
        1591461158462
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU742754043442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:32:36.462Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17219.00',
          mileage: '36078'
        },
        sellArea: '110',
        dealerCd: '04344',
        certificationNumber: 9418695,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU742754',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T17:26:05.366Z'
        }
      },
      sort: [
        1591461156462
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW594293220342020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:31:44.502Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '24999.00',
          mileage: '34588'
        },
        sellArea: '210',
        dealerCd: '22034',
        certificationNumber: 9458788,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW594293',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T18:04:00.083Z'
        }
      },
      sort: [
        1591461104502
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV1HJ116166291022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:31:12.223Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18270.00',
          mileage: '17928'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9498038,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV1HJ116166',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:25:52.921Z'
        }
      },
      sort: [
        1591461072223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KW043559291022020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:29:32.672Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23399.00',
          mileage: '25841'
        },
        sellArea: '600',
        dealerCd: '29102',
        certificationNumber: 9406866,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KW043559',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T15:04:21.581Z'
        }
      },
      sort: [
        1591460972672
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ131658311322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:29:02.901Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18099.54',
          mileage: '26120'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9430081,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ131658',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-22T16:08:30.968Z'
        }
      },
      sort: [
        1591460942901
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV7HW315481046762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:28:04.842Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19640.00',
          mileage: '41670'
        },
        sellArea: '120',
        dealerCd: '04676',
        certificationNumber: 9461632,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV7HW315481',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T01:09:28.572Z'
        }
      },
      sort: [
        1591460884842
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F11KX830214046762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:27:37.521Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '40684.00',
          mileage: '12028'
        },
        sellArea: '120',
        dealerCd: '04676',
        certificationNumber: 9500320,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F11KX830214',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:00:06.183Z'
        }
      },
      sort: [
        1591460857521
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HD210306291022020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:27:28.940Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19599.66',
          mileage: '23177'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9499413,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HD210306',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:59:10.414Z'
        }
      },
      sort: [
        1591460848940
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK9LU892419046762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:27:14.880Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23048.00',
          mileage: '6543'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9497478,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK9LU892419',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:32:45.296Z'
        }
      },
      sort: [
        1591460834880
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREVXHW621314311322020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:25:47.960Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '23605.00',
          mileage: '39285'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9457499,
        makeCode: 'TOYOTA',
        vin: '2T3JFREVXHW621314',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T16:26:38.782Z'
        }
      },
      sort: [
        1591460747960
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV9JD171093480452020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:25:08.188Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '21799.00',
          mileage: '45793'
        },
        sellArea: '210',
        dealerCd: '48045',
        certificationNumber: 9469783,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV9JD171093',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T15:35:23.675Z'
        }
      },
      sort: [
        1591460708188
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV3HW685548311322020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:19:33.004Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '21305.00',
          mileage: '46407'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9455604,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV3HW685548',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T19:10:30.799Z'
        }
      },
      sort: [
        1591460373004
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN3KM069505049902020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:19:28.725Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '37500.00',
          mileage: '7074'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9480425,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN3KM069505',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T16:30:26.349Z'
        }
      },
      sort: [
        1591460368725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5HW641507311742020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:12:34.380Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18490.00',
          mileage: '34469'
        },
        sellArea: '160',
        dealerCd: '31174',
        certificationNumber: 9495146,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5HW641507',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:57:15.968Z'
        }
      },
      sort: [
        1591459954380
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFUXH3558790042782020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:11:51.441Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19799.00',
          mileage: '35153'
        },
        sellArea: '110',
        dealerCd: '04278',
        certificationNumber: 9488159,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFUXH3558790',
        model: {
          modelDescription: 'PRIUS ONE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1221',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:57:09.784Z'
        }
      },
      sort: [
        1591459911441
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE7K3043130042782020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:09:11.560Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20184.00',
          mileage: '17610'
        },
        sellArea: '110',
        dealerCd: '04278',
        certificationNumber: 9478269,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE7K3043130',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:35:07.877Z'
        }
      },
      sort: [
        1591459751560
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN5KX149593049902020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:07:17.280Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '24834.00',
          mileage: '18448'
        },
        sellArea: '500',
        dealerCd: '04990',
        certificationNumber: 9420786,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN5KX149593',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T22:03:41.242Z'
        }
      },
      sort: [
        1591459637280
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB3XF1105346290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:06:40.159Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15111.00',
          mileage: '26302'
        },
        sellArea: '190',
        dealerCd: '29025',
        certificationNumber: 9493765,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB3XF1105346',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB FOUR',
          modelSeries: 'PRIUS C',
          modelNumber: '1207',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:22:19.595Z'
        }
      },
      sort: [
        1591459600159
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS388378290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:05:32.457Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '23803'
        },
        dealerCd: '29025',
        certificationNumber: 9382325,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS388378',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T00:41:40.669Z'
        }
      },
      sort: [
        1591459532457
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV0HY155106290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:04:45.738Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14600.00',
          mileage: '21160'
        },
        dealerCd: '29025',
        certificationNumber: 9309358,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV0HY155106',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-27T22:57:24.130Z'
        }
      },
      sort: [
        1591459485738
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KC018646290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:03:53.257Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '16651'
        },
        sellArea: '600',
        dealerCd: '29025',
        certificationNumber: 9409198,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KC018646',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T22:58:53.203Z'
        }
      },
      sort: [
        1591459433257
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRWRFV5KD021869240782020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:02:52.537Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30500.00',
          mileage: '9'
        },
        sellArea: '190',
        dealerCd: '24078',
        certificationNumber: 9500387,
        makeCode: 'TOYOTA',
        vin: 'JTMRWRFV5KD021869',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:34:50.729Z'
        }
      },
      sort: [
        1591459372537
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV4HJ132196290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:02:17.598Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16900.00',
          mileage: '26714'
        },
        sellArea: '210',
        dealerCd: '29025',
        certificationNumber: 9485852,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV4HJ132196',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:44:02.748Z'
        }
      },
      sort: [
        1591459337598
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV3HJ145198047392020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:01:56.917Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18998.00',
          mileage: '36335'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9474529,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV3HJ145198',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:43:44.971Z'
        }
      },
      sort: [
        1591459316917
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN5KM096691049902020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T16:00:50.376Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '30817.00',
          mileage: '24650'
        },
        sellArea: '500',
        dealerCd: '04990',
        certificationNumber: 9427679,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN5KM096691',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T21:19:56.966Z'
        }
      },
      sort: [
        1591459250376
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ138151060472020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:59:59.975Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20721.00',
          mileage: '33262'
        },
        dealerCd: '06047',
        certificationNumber: 9373157,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HJ138151',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T22:47:27.220Z'
        }
      },
      sort: [
        1591459199975
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU684051047392020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:59:45.237Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17998.00',
          mileage: '32801'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9488315,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU684051',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:48:23.608Z'
        }
      },
      sort: [
        1591459185237
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F10GX526039060472020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:58:56.394Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32522.00',
          mileage: '69000'
        },
        sellArea: '800',
        dealerCd: '06047',
        certificationNumber: 9414166,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F10GX526039',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T20:41:33.004Z'
        }
      },
      sort: [
        1591459136394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRWRFV8KD508676240782020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:58:48.515Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30301.00',
          mileage: '8'
        },
        sellArea: '190',
        dealerCd: '24078',
        certificationNumber: 9498124,
        makeCode: 'TOYOTA',
        vin: 'JTMRWRFV8KD508676',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:59:22.481Z'
        }
      },
      sort: [
        1591459128515
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU668345045282020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:58:08.394Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19743.00',
          mileage: '17553'
        },
        sellArea: '110',
        dealerCd: '04528',
        certificationNumber: 9497441,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU668345',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:23:30.020Z'
        }
      },
      sort: [
        1591459088394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU728328045282020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:57:47.475Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '27622'
        },
        sellArea: '500',
        dealerCd: '04528',
        certificationNumber: 9487231,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU728328',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:36:22.051Z'
        }
      },
      sort: [
        1591459067475
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU350496047392020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:56:58.914Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19693.00',
          mileage: '3240'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9479604,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU350496',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T21:06:18.853Z'
        }
      },
      sort: [
        1591459018914
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1FK0HU581923045282020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:56:54.454Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '31384'
        },
        sellArea: '110',
        dealerCd: '04528',
        certificationNumber: 9455783,
        makeCode: 'TOYOTA',
        vin: '4T1BK1FK0HU581923',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T22:29:49.855Z'
        }
      },
      sort: [
        1591459014454
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU759492047392020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:55:57.934Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16723.00',
          mileage: '24590'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9479593,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU759492',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T20:58:31.669Z'
        }
      },
      sort: [
        1591458957934
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC746982340062020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:55:41.094Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '14695.00',
          mileage: '32053'
        },
        sellArea: '160',
        dealerCd: '34006',
        certificationNumber: 9485163,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC746982',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:38:47.604Z'
        }
      },
      sort: [
        1591458941094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB4JU268137047392020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:55:02.876Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26250.00',
          mileage: '12999'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9497276,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB4JU268137',
        model: {
          modelDescription: 'AVALON 4-DR LIMITED',
          modelSeries: 'AVALON',
          modelNumber: '3554',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:17:42.660Z'
        }
      },
      sort: [
        1591458902876
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN5HM018113180342020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:53:57.572Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27490.00',
          mileage: '45741'
        },
        dealerCd: '18034',
        certificationNumber: 9369168,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN5HM018113',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-05T15:19:41.853Z'
        }
      },
      sort: [
        1591458837572
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMBZ5DN0HM009078049902020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:53:44.098Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '28257.00',
          mileage: '21397'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9488571,
        makeCode: 'TOYOTA',
        vin: '3TMBZ5DN0HM009078',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DBL CAB LONG BED',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7172',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:17:09.627Z'
        }
      },
      sort: [
        1591458824098
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFJX4GN4EX032036049902020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:49:58.995Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '25900.00',
          mileage: '62139'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9420732,
        makeCode: 'TOYOTA',
        vin: '5TFJX4GN4EX032036',
        model: {
          modelDescription: 'TACOMA PRERUNNER DOUBLE CAB 4X2',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7186',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T21:44:34.541Z'
        }
      },
      sort: [
        1591458598995
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS425954291142020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:47:09.871Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27800.00',
          mileage: '45243'
        },
        sellArea: '160',
        dealerCd: '29114',
        certificationNumber: 9501160,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS425954',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T15:42:27.691Z'
        }
      },
      sort: [
        1591458429871
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC888960280122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:44:24.211Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13407.00',
          mileage: '43162'
        },
        sellArea: '170',
        dealerCd: '28012',
        certificationNumber: 9500989,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC888960',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T14:10:23.712Z'
        }
      },
      sort: [
        1591458264211
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMF1RFV4KD501306240632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:42:50.708Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21998.00',
          mileage: '40340'
        },
        sellArea: '500',
        dealerCd: '24063',
        certificationNumber: 9499501,
        makeCode: 'TOYOTA',
        vin: 'JTMF1RFV4KD501306',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:26:51.993Z'
        }
      },
      sort: [
        1591458170708
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE7JJ562649480072020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:41:25.209Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13802.00',
          mileage: '33298'
        },
        sellArea: '210',
        certificationNumber: 9464144,
        dealerCd: '48007',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6273',
          modelYear: 2018
        },
        vin: 'JTNKARJE7JJ562649',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T14:08:14.150Z'
        }
      },
      sort: [
        1591458085209
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3JD239244070092020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:36:54.306Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22959.00',
          mileage: '15255'
        },
        sellArea: '800',
        dealerCd: '07009',
        certificationNumber: 9477929,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3JD239244',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:08:14.808Z'
        }
      },
      sort: [
        1591457814306
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F16EX058437311762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:33:45.932Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25553.36',
          mileage: '58603'
        },
        sellArea: '160',
        dealerCd: '31176',
        certificationNumber: 9500891,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F16EX058437',
        model: {
          modelDescription: 'TUNDRA 4X4 SR DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8339',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T13:09:42.142Z'
        }
      },
      sort: [
        1591457625932
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV6HW567037240742020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:31:37.282Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21357.00',
          mileage: '51624'
        },
        sellArea: '160',
        dealerCd: '24074',
        certificationNumber: 9395963,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV6HW567037',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T21:04:08.970Z'
        }
      },
      sort: [
        1591457497282
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHW5F11JX679834160502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:31:33.540Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '34440.00',
          mileage: '69461'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9409837,
        makeCode: 'TOYOTA',
        vin: '5TFHW5F11JX679834',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8374',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T16:35:19.015Z'
        }
      },
      sort: [
        1591457493540
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ113121240742020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:30:29.641Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20081.00',
          mileage: '32437'
        },
        sellArea: '160',
        dealerCd: '24074',
        certificationNumber: 9403219,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ113121',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T20:11:44.881Z'
        }
      },
      sort: [
        1591457429641
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HD199448240742020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:29:43.798Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20131.00',
          mileage: '44889'
        },
        dealerCd: '24074',
        certificationNumber: 9386583,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HD199448',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T23:26:55.440Z'
        }
      },
      sort: [
        1591457383798
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KC011760240742020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:29:07.379Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26223.00',
          mileage: '17603'
        },
        sellArea: '500',
        dealerCd: '24074',
        certificationNumber: 9490065,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KC011760',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T22:54:05.183Z'
        }
      },
      sort: [
        1591457347379
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HD205195160502020-05-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:26:50.556Z',
          retailDate: '2020-05-01',
          rdrStatus: 'H',
          soldAmount: '18950.00',
          mileage: '31521'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9470008,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HD205195',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T19:56:24.562Z'
        }
      },
      sort: [
        1591457210556
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS423562220472020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:24:28.475Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '27699.00',
          mileage: '41758'
        },
        sellArea: '210',
        dealerCd: '22047',
        certificationNumber: 9484038,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS423562',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:25:41.477Z'
        }
      },
      sort: [
        1591457068475
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HD214255470252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:24:19.656Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22997.00',
          mileage: '24903'
        },
        sellArea: '800',
        dealerCd: '47025',
        certificationNumber: 9499510,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HD214255',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:28:28.274Z'
        }
      },
      sort: [
        1591457059656
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV7HD118302160502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:23:24.880Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23200.00',
          mileage: '26008'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9469989,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV7HD118302',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T19:45:36.794Z'
        }
      },
      sort: [
        1591457004880
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HD209589440132020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:22:44.601Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '32589'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9493444,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HD209589',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T02:41:50.190Z'
        }
      },
      sort: [
        1591456964601
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC855541160502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:19:50.627Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16450.00',
          mileage: '16064'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9470022,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC855541',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T20:10:43.450Z'
        }
      },
      sort: [
        1591456790627
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ143865311052020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:14:06.536Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20998.00',
          mileage: '21940'
        },
        sellArea: '160',
        dealerCd: '31105',
        certificationNumber: 9476707,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ143865',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:51:02.081Z'
        }
      },
      sort: [
        1591456446536
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV1JW457900160502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:13:40.896Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19800.00',
          mileage: '17922'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9475975,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV1JW457900',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:36:47.459Z'
        }
      },
      sort: [
        1591456420896
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2JJ198574160502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:11:04.294Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22300.00',
          mileage: '30661'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9466338,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2JJ198574',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T22:35:26.784Z'
        }
      },
      sort: [
        1591456264294
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2JC994529160502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:09:45.074Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16900.00',
          mileage: '38570'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9452419,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2JC994529',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T18:37:41.237Z'
        }
      },
      sort: [
        1591456185074
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN4EM153126340962020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:08:06.734Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24997.00',
          mileage: '73669'
        },
        sellArea: '220',
        dealerCd: '34096',
        certificationNumber: 9493947,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN4EM153126',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:58:01.365Z'
        }
      },
      sort: [
        1591456086734
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4J5580781340962020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:05:47.326Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33000.00',
          mileage: '29693'
        },
        sellArea: '220',
        dealerCd: '34096',
        certificationNumber: 9415429,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4J5580781',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T18:10:42.648Z'
        }
      },
      sort: [
        1591455947326
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1EBXJU064775340962020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:04:57.611Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27597.00',
          mileage: '20286'
        },
        sellArea: '220',
        dealerCd: '34096',
        certificationNumber: 9450415,
        makeCode: 'TOYOTA',
        vin: '4T1BD1EBXJU064775',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T19:15:22.380Z'
        }
      },
      sort: [
        1591455897611
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9HM086198370772020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:02:58.891Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31900.00',
          mileage: '10355'
        },
        sellArea: '800',
        dealerCd: '37077',
        certificationNumber: 9465574,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9HM086198',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:44:20.215Z'
        }
      },
      sort: [
        1591455778891
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN3FM199399371742020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:01:58.271Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24499.00',
          mileage: '66069'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9498001,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN3FM199399',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:14:07.684Z'
        }
      },
      sort: [
        1591455718271
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC017723370772020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T15:01:29.510Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24000.0000',
          mileage: '12949'
        },
        sellArea: '500',
        dealerCd: '37077',
        certificationNumber: 9458541,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC017723',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T15:39:28.125Z'
        }
      },
      sort: [
        1591455689510
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE9LP051622470232020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:59:26.228Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17558.00',
          mileage: '4995'
        },
        sellArea: '800',
        dealerCd: '47023',
        certificationNumber: 9426708,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE9LP051622',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T13:59:09.298Z'
        }
      },
      sort: [
        1591455566228
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8HJ137077160502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:56:30.348Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19950.00',
          mileage: '21081'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9466335,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8HJ137077',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T22:32:29.379Z'
        }
      },
      sort: [
        1591455390348
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5HW642171160502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:54:37.488Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '16281'
        },
        sellArea: '220',
        dealerCd: '16050',
        certificationNumber: 9469984,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV5HW642171',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T19:40:42.873Z'
        }
      },
      sort: [
        1591455277488
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDW5G16JS155601430262020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:54:32.587Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '49185.00',
          mileage: '32373'
        },
        sellArea: '150',
        dealerCd: '43026',
        certificationNumber: 9492594,
        makeCode: 'TOYOTA',
        vin: '5TDDW5G16JS155601',
        model: {
          modelDescription: 'SEQUOIA 4X4 PLAT 7-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7944',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:44:04.197Z'
        }
      },
      sort: [
        1591455272587
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7HW647436470232020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:52:50.165Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '25639'
        },
        sellArea: '800',
        dealerCd: '47023',
        certificationNumber: 9498804,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7HW647436',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:43:56.347Z'
        }
      },
      sort: [
        1591455170165
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS512686160502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:52:24.025Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28650.00',
          mileage: '50792'
        },
        sellArea: '160',
        dealerCd: '16050',
        certificationNumber: 9417838,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS512686',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T23:42:18.950Z'
        }
      },
      sort: [
        1591455144025
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH0FS210071371742020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:52:08.428Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24420.58',
          mileage: '37962'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9494677,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH0FS210071',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:47:59.089Z'
        }
      },
      sort: [
        1591455128428
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6HJ129736210672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:51:29.944Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15971.00',
          mileage: '29269'
        },
        sellArea: '220',
        dealerCd: '21067',
        certificationNumber: 9489851,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6HJ129736',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T20:29:50.888Z'
        }
      },
      sort: [
        1591455089944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0H5422903310112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:49:36.601Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27989.00',
          mileage: '54078'
        },
        dealerCd: '31011',
        certificationNumber: 9361323,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0H5422903',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-30T18:00:38.970Z'
        }
      },
      sort: [
        1591454976601
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0FW384879440242020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:48:56.661Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17026.00',
          mileage: '51605'
        },
        sellArea: '170',
        dealerCd: '44024',
        certificationNumber: 9493678,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0FW384879',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:13:03.656Z'
        }
      },
      sort: [
        1591454936661
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUX4EN0FX031094210432020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:48:02.081Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20496.57',
          mileage: '28839'
        },
        sellArea: '220',
        dealerCd: '21043',
        certificationNumber: 9498776,
        makeCode: 'TOYOTA',
        vin: '5TFUX4EN0FX031094',
        model: {
          modelDescription: 'TACOMA 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7514',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:30:20.598Z'
        }
      },
      sort: [
        1591454882081
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KC003679310112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:46:07.420Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23990.00',
          mileage: '26903'
        },
        dealerCd: '31011',
        certificationNumber: 9267359,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KC003679',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-29T16:03:28.098Z'
        }
      },
      sort: [
        1591454767420
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW642531310112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:43:50.778Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '16556'
        },
        sellArea: '160',
        dealerCd: '31011',
        certificationNumber: 9480117,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW642531',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T13:34:10.181Z'
        }
      },
      sort: [
        1591454630778
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F11KX843836250642020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:43:07.278Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '36990.00',
          mileage: '13853'
        },
        sellArea: '130',
        dealerCd: '25064',
        certificationNumber: 9483131,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F11KX843836',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T13:55:57.662Z'
        }
      },
      sort: [
        1591454587278
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE4LJ027550310112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:41:06.515Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16990.00',
          mileage: '13472'
        },
        sellArea: '500',
        dealerCd: '31011',
        certificationNumber: 9486915,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE4LJ027550',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:14:08.808Z'
        }
      },
      sort: [
        1591454466515
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXG5337033310112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:39:34.693Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25489.00',
          mileage: '39808'
        },
        dealerCd: '31011',
        certificationNumber: 9219796,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXG5337033',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-29T12:20:12.519Z'
        }
      },
      sort: [
        1591454374693
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW572447480452020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:37:28.272Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18488.75',
          mileage: '47268'
        },
        dealerCd: '48045',
        certificationNumber: 9347733,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW572447',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-21T21:32:44.247Z'
        }
      },
      sort: [
        1591454248272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN1KX196096310112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:37:08.853Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29995.00',
          mileage: '10940'
        },
        dealerCd: '31011',
        certificationNumber: 9348494,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN1KX196096',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-22T13:10:56.538Z'
        }
      },
      sort: [
        1591454228853
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC5HS165342060022020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:37:02.759Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23499.00',
          mileage: '35553'
        },
        sellArea: '160',
        dealerCd: '06002',
        certificationNumber: 9409585,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC5HS165342',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T12:33:39.139Z'
        }
      },
      sort: [
        1591454222759
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2KW042834310112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:35:32.819Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23990.00',
          mileage: '16038'
        },
        dealerCd: '31011',
        certificationNumber: 9366541,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2KW042834',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-03T15:46:08.046Z'
        }
      },
      sort: [
        1591454132819
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1HKXJU501252480452020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:35:21.618Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24299.00',
          mileage: '21707'
        },
        sellArea: '210',
        dealerCd: '48045',
        certificationNumber: 9461405,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1HKXJU501252',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2554',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T21:30:54.751Z'
        }
      },
      sort: [
        1591454121618
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS421085480452020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:33:27.018Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '27991'
        },
        sellArea: '210',
        dealerCd: '48045',
        certificationNumber: 9444288,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS421085',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T16:44:01.771Z'
        }
      },
      sort: [
        1591454007018
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW583851121362020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:33:13.417Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '18721.00',
          mileage: '30441'
        },
        sellArea: '210',
        dealerCd: '12136',
        certificationNumber: 9474537,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW583851',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:50:39.217Z'
        }
      },
      sort: [
        1591453993417
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7JW749406310112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:31:39.077Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21987.00',
          mileage: '30630'
        },
        sellArea: '160',
        certificationNumber: 9408863,
        dealerCd: '31011',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        vin: '2T3RFREV7JW749406',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T20:05:46.983Z'
        }
      },
      sort: [
        1591453899077
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH1HS392883201452020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:31:11.417Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22697.00',
          mileage: '45201'
        },
        sellArea: '170',
        dealerCd: '20145',
        certificationNumber: 9437473,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH1HS392883',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T17:11:45.707Z'
        }
      },
      sort: [
        1591453871417
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0HW557904310112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:30:12.095Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18989.00',
          mileage: '38006'
        },
        dealerCd: '31011',
        certificationNumber: 9186694,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0HW557904',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-07T12:59:48.401Z'
        }
      },
      sort: [
        1591453812095
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KC003058310112020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:28:55.535Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23990.00',
          mileage: '23140'
        },
        sellArea: '500',
        dealerCd: '31011',
        certificationNumber: 9479567,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KC003058',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T20:47:35.520Z'
        }
      },
      sort: [
        1591453735535
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU790239310112020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:27:59.195Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17990.00',
          mileage: '21500'
        },
        sellArea: '500',
        dealerCd: '31011',
        certificationNumber: 9458894,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU790239',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T18:53:37.478Z'
        }
      },
      sort: [
        1591453679195
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2K5646200300392020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:27:06.935Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31748.00',
          mileage: '38258'
        },
        sellArea: '500',
        dealerCd: '30039',
        certificationNumber: 9458794,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2K5646200',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T18:06:50.941Z'
        }
      },
      sort: [
        1591453626935
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV4LC047685300392020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:25:59.435Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27843.00',
          mileage: '6134'
        },
        sellArea: '110',
        dealerCd: '30039',
        certificationNumber: 9487518,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV4LC047685',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:18:03.956Z'
        }
      },
      sort: [
        1591453559435
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH6FS132433311662020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:23:42.672Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '22371.00',
          mileage: '36310'
        },
        sellArea: '160',
        dealerCd: '31166',
        certificationNumber: 9456872,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH6FS132433',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T18:23:34.358Z'
        }
      },
      sort: [
        1591453422672
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTKJF5C74F3094408311662020-05-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:21:54.751Z',
          retailDate: '2020-05-06',
          rdrStatus: 'H',
          soldAmount: '12195.00',
          mileage: '35692'
        },
        sellArea: '160',
        dealerCd: '31166',
        certificationNumber: 9399918,
        makeCode: 'TOYOTA',
        vin: 'JTKJF5C74F3094408',
        model: {
          modelDescription: 'SCION tC 3 DOOR LIFTBACK',
          modelSeries: 'SCION TC',
          modelNumber: '6222',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T22:42:27.305Z'
        }
      },
      sort: [
        1591453314751
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV7HW652453210672020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:21:36.932Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24995.00',
          mileage: '12765'
        },
        sellArea: '220',
        dealerCd: '21067',
        certificationNumber: 9489766,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV7HW652453',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T20:02:39.974Z'
        }
      },
      sort: [
        1591453296932
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE3LJ051314180342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:14:17.129Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17699.00',
          mileage: '878'
        },
        sellArea: '170',
        dealerCd: '18034',
        certificationNumber: 9445358,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE3LJ051314',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T13:32:56.816Z'
        }
      },
      sort: [
        1591452857129
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS449353190562020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:14:10.389Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29103.00',
          mileage: '21992'
        },
        sellArea: '160',
        dealerCd: '19056',
        certificationNumber: 9451784,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS449353',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T23:02:08.643Z'
        }
      },
      sort: [
        1591452850389
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3JW815508180342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:13:58.008Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25999.00',
          mileage: '24624'
        },
        sellArea: '170',
        dealerCd: '18034',
        certificationNumber: 9493886,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3JW815508',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:31:06.808Z'
        }
      },
      sort: [
        1591452838008
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFUXK3012238180342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:13:36.288Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23499.00',
          mileage: '2184'
        },
        sellArea: '170',
        dealerCd: '18034',
        certificationNumber: 9442470,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFUXK3012238',
        model: {
          modelDescription: 'PRIUS LE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1263',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T18:29:39.934Z'
        }
      },
      sort: [
        1591452816288
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3KC041449180342020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:13:07.688Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26999.00',
          mileage: '6255'
        },
        sellArea: '170',
        dealerCd: '18034',
        certificationNumber: 9496974,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3KC041449',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:54:25.740Z'
        }
      },
      sort: [
        1591452787688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU717828190562020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:12:45.748Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16300.00',
          mileage: '32496'
        },
        sellArea: '160',
        dealerCd: '19056',
        certificationNumber: 9409027,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU717828',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T21:28:33.249Z'
        }
      },
      sort: [
        1591452765748
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU137724450162020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:09:01.846Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '17905'
        },
        sellArea: '800',
        dealerCd: '45016',
        certificationNumber: 9496167,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU137724',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:07:06.779Z'
        }
      },
      sort: [
        1591452541846
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5GM004496280122020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:08:44.565Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '25700.00',
          mileage: '55977'
        },
        sellArea: '170',
        dealerCd: '28012',
        certificationNumber: 9468562,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5GM004496',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T17:06:03.660Z'
        }
      },
      sort: [
        1591452524565
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV2HW626734470212020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:08:41.586Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22780.30',
          mileage: '34874'
        },
        sellArea: '220',
        dealerCd: '47021',
        certificationNumber: 9476733,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV2HW626734',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T22:26:33.040Z'
        }
      },
      sort: [
        1591452521586
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8JD229407450162020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:08:26.746Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '12185'
        },
        sellArea: '800',
        dealerCd: '45016',
        certificationNumber: 9484163,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8JD229407',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:12:44.021Z'
        }
      },
      sort: [
        1591452506746
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F11JX747695480612020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:07:17.146Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '40991.00',
          mileage: '17226'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9497878,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F11JX747695',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8378',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:46:50.147Z'
        }
      },
      sort: [
        1591452437146
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU634596480612020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:06:54.805Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15905.57',
          mileage: '60380'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9465686,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU634596',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:41:14.836Z'
        }
      },
      sort: [
        1591452414805
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F13JX730162370832020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:06:31.805Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '31790.00',
          mileage: '9247'
        },
        dealerCd: '37083',
        certificationNumber: 9385696,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F13JX730162',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T16:08:47.455Z'
        }
      },
      sort: [
        1591452391805
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS389098450162020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:05:30.405Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28690.00',
          mileage: '39260'
        },
        sellArea: '160',
        dealerCd: '45016',
        certificationNumber: 9422983,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS389098',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T21:30:33.643Z'
        }
      },
      sort: [
        1591452330405
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW627434370832020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:05:22.586Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23314.00',
          mileage: '33393'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9466986,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW627434',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T16:21:31.250Z'
        }
      },
      sort: [
        1591452322586
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8JJ215279450162020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T14:02:51.503Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20245.00',
          mileage: '37741'
        },
        sellArea: '800',
        dealerCd: '45016',
        certificationNumber: 9489088,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8JJ215279',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T14:43:35.301Z'
        }
      },
      sort: [
        1591452171503
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN3HX107136201452020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:59:04.563Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29347.00',
          mileage: '24382'
        },
        dealerCd: '20145',
        certificationNumber: 9303405,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN3HX107136',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-23T18:53:09.062Z'
        }
      },
      sort: [
        1591451944563
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0HD083866371942020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:58:49.860Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21774.02',
          mileage: '42237'
        },
        dealerCd: '37194',
        certificationNumber: 9352966,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0HD083866',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-24T20:44:00.689Z'
        }
      },
      sort: [
        1591451929860
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FKXHU201190150502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:50:37.877Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '58627'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9485826,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FKXHU201190',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:36:40.868Z'
        }
      },
      sort: [
        1591451437877
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU715340150502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:49:25.038Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16149.70',
          mileage: '61787'
        },
        sellArea: '500',
        dealerCd: '15050',
        certificationNumber: 9466438,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU715340',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T02:13:51.460Z'
        }
      },
      sort: [
        1591451365038
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2J5495129450162020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:42:21.775Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '35400.00',
          mileage: '24510'
        },
        sellArea: '800',
        dealerCd: '45016',
        certificationNumber: 9451216,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2J5495129',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T16:21:15.316Z'
        }
      },
      sort: [
        1591450941775
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8JW736681190722020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:41:53.395Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24694.00',
          mileage: '35163'
        },
        sellArea: '800',
        dealerCd: '19072',
        certificationNumber: 9500566,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8JW736681',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T00:28:08.064Z'
        }
      },
      sort: [
        1591450913395
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH5HS370806450162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:40:32.293Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26750.00',
          mileage: '39524'
        },
        sellArea: '210',
        dealerCd: '45016',
        certificationNumber: 9421285,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH5HS370806',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T13:42:56.428Z'
        }
      },
      sort: [
        1591450832293
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8GP432577450162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:39:58.373Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13874.00',
          mileage: '44043'
        },
        sellArea: '220',
        dealerCd: '45016',
        certificationNumber: 9498212,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8GP432577',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:41:07.180Z'
        }
      },
      sort: [
        1591450798373
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU681009450162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:38:47.373Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19890.00',
          mileage: '7887'
        },
        sellArea: '800',
        dealerCd: '45016',
        certificationNumber: 9466671,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU681009',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T13:51:32.152Z'
        }
      },
      sort: [
        1591450727373
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW596457200062020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:37:50.933Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16598.00',
          mileage: '51044'
        },
        sellArea: '170',
        dealerCd: '20006',
        certificationNumber: 9393087,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW596457',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T14:29:48.110Z'
        }
      },
      sort: [
        1591450670933
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV5KW050910450162020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:37:40.152Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '14594'
        },
        sellArea: '800',
        dealerCd: '45016',
        certificationNumber: 9494367,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV5KW050910',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:39:22.041Z'
        }
      },
      sort: [
        1591450660152
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC3HS856075450162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:35:45.550Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25974.00',
          mileage: '45255'
        },
        sellArea: '160',
        dealerCd: '45016',
        certificationNumber: 9435692,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC3HS856075',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T21:10:19.542Z'
        }
      },
      sort: [
        1591450545550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HJ145080200062020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:35:36.889Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16997.00',
          mileage: '45530'
        },
        sellArea: '170',
        dealerCd: '20006',
        certificationNumber: 9410443,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HJ145080',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T22:02:19.424Z'
        }
      },
      sort: [
        1591450536889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2JW712747291082020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:35:10.732Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23922.00',
          mileage: '14278'
        },
        sellArea: '160',
        dealerCd: '29108',
        certificationNumber: 9419602,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2JW712747',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T13:22:30.198Z'
        }
      },
      sort: [
        1591450510732
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS509633450162020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:35:04.331Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30885.00',
          mileage: '35971'
        },
        sellArea: '160',
        dealerCd: '45016',
        certificationNumber: 9420744,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS509633',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T21:47:45.859Z'
        }
      },
      sort: [
        1591450504331
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC868982121602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:30:12.789Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '13994.00',
          mileage: '22767'
        },
        sellArea: '210',
        dealerCd: '12160',
        certificationNumber: 9499800,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC868982',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:07:58.731Z'
        }
      },
      sort: [
        1591450212789
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE0HJ538168340442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:29:42.709Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14785.00',
          mileage: '35462'
        },
        sellArea: '220',
        dealerCd: '34044',
        certificationNumber: 9495304,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE0HJ538168',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:34:56.877Z'
        }
      },
      sort: [
        1591450182709
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV1HD113130121602020-06-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:28:18.849Z',
          retailDate: '2020-06-06',
          rdrStatus: 'H',
          soldAmount: '23597.00',
          mileage: '19379'
        },
        sellArea: '210',
        dealerCd: '12160',
        certificationNumber: 9498285,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV1HD113130',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T22:40:54.052Z'
        }
      },
      sort: [
        1591450098849
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC849836340442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:24:58.704Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15594.00',
          mileage: '24629'
        },
        sellArea: '220',
        dealerCd: '34044',
        certificationNumber: 9495358,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC849836',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:56:29.384Z'
        }
      },
      sort: [
        1591449898704
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS369664200062020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:22:13.487Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24998.00',
          mileage: '40115'
        },
        dealerCd: '20006',
        certificationNumber: 9385472,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS369664',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T13:58:37.204Z'
        }
      },
      sort: [
        1591449733487
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS401540311902020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:21:16.425Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '29100.00',
          mileage: '13267'
        },
        sellArea: '160',
        dealerCd: '31190',
        certificationNumber: 9500892,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS401540',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T13:11:53.284Z'
        }
      },
      sort: [
        1591449676425
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN7KX209322450862020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:21:07.295Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33996.00',
          mileage: '6292'
        },
        sellArea: '800',
        dealerCd: '45086',
        certificationNumber: 9495168,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN7KX209322',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:28:08.867Z'
        }
      },
      sort: [
        1591449667295
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU665648200062020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:20:57.635Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15493.00',
          mileage: '19265'
        },
        sellArea: '170',
        dealerCd: '20006',
        certificationNumber: 9472418,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU665648',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T13:52:33.459Z'
        }
      },
      sort: [
        1591449657635
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV6HW313401450862020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:20:00.593Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16921.00',
          mileage: '31365'
        },
        dealerCd: '45086',
        certificationNumber: 9385555,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV6HW313401',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T15:05:48.841Z'
        }
      },
      sort: [
        1591449600593
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXKW029703290862020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:19:33.272Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '25529'
        },
        dealerCd: '29086',
        certificationNumber: 9366707,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXKW029703',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-03T17:30:43.383Z'
        }
      },
      sort: [
        1591449573272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV7JD234610140522020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:17:18.134Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25993.00',
          mileage: '34698'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9496107,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV7JD234610',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:40:14.946Z'
        }
      },
      sort: [
        1591449438134
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU3GJ041913140522020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:16:22.987Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17991.00',
          mileage: '65486'
        },
        sellArea: '600',
        dealerCd: '14052',
        certificationNumber: 9467456,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU3GJ041913',
        model: {
          modelDescription: 'PRIUS v Four Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1247',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T20:21:44.310Z'
        }
      },
      sort: [
        1591449382987
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS416482140522020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:15:29.273Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28291.00',
          mileage: '40029'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9495870,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS416482',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:06:58.387Z'
        }
      },
      sort: [
        1591449329273
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYVXHY152729341072020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:14:02.711Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '12850.00',
          mileage: '11200'
        },
        dealerCd: '34107',
        certificationNumber: 9389347,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYVXHY152729',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T18:47:45.874Z'
        }
      },
      sort: [
        1591449242711
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1F11AK5LU301711450352020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:08:20.284Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29592.00',
          mileage: '6450'
        },
        sellArea: '800',
        dealerCd: '45035',
        certificationNumber: 9440265,
        makeCode: 'TOYOTA',
        vin: '4T1F11AK5LU301711',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-04T13:59:36.404Z'
        }
      },
      sort: [
        1591448900284
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC7HS870772450352020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T13:02:27.085Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28000.00',
          mileage: '39319'
        },
        sellArea: '800',
        dealerCd: '45035',
        certificationNumber: 9424198,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC7HS870772',
        model: {
          modelDescription: 'SIENNA LTD PREM FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5357',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T19:15:36.019Z'
        }
      },
      sort: [
        1591448547085
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW672768201272020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T12:53:22.924Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '25949'
        },
        sellArea: '170',
        dealerCd: '20127',
        certificationNumber: 9496858,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW672768',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T12:35:11.453Z'
        }
      },
      sort: [
        1591448002924
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS393920480622020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T12:50:32.804Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27900.00',
          mileage: '40723'
        },
        sellArea: '210',
        dealerCd: '48062',
        certificationNumber: 9498805,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS393920',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:44:17.724Z'
        }
      },
      sort: [
        1591447832804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3NFREV8HW336547340062020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T12:38:55.939Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '25701'
        },
        sellArea: '500',
        dealerCd: '34006',
        certificationNumber: 9433277,
        makeCode: 'TOYOTA',
        vin: '2T3NFREV8HW336547',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T20:03:14.056Z'
        }
      },
      sort: [
        1591447135939
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU746514160612020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T12:30:14.576Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16433.00',
          mileage: '23089'
        },
        sellArea: '220',
        dealerCd: '16061',
        certificationNumber: 9474569,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU746514',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:08:17.293Z'
        }
      },
      sort: [
        1591446614576
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5GC627844370362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T12:23:16.774Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13475.00',
          mileage: '37168'
        },
        sellArea: '800',
        dealerCd: '37036',
        certificationNumber: 9499178,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5GC627844',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:56:49.976Z'
        }
      },
      sort: [
        1591446196774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0HW543498370832020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T11:55:24.488Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19394.00',
          mileage: '32485'
        },
        dealerCd: '37083',
        certificationNumber: 9320927,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0HW543498',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-03T16:01:55.017Z'
        }
      },
      sort: [
        1591444524488
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV9HD078675280292020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T11:19:25.698Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25999.00',
          mileage: '24957'
        },
        sellArea: '170',
        dealerCd: '28029',
        certificationNumber: 9455699,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV9HD078675',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T20:36:02.997Z'
        }
      },
      sort: [
        1591442365698
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HJ138044280292020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T11:18:50.240Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '26767'
        },
        dealerCd: '28029',
        certificationNumber: 9388702,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HJ138044',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T12:38:03.094Z'
        }
      },
      sort: [
        1591442330240
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ143030280292020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T11:17:58.140Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '31145'
        },
        sellArea: '170',
        dealerCd: '28029',
        certificationNumber: 9471715,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ143030',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T21:53:16.288Z'
        }
      },
      sort: [
        1591442278140
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRM5F13HX120029042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T05:05:57.541Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26592.00',
          mileage: '22967'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9455550,
        makeCode: 'TOYOTA',
        vin: '5TFRM5F13HX120029',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8240',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T18:38:44.423Z'
        }
      },
      sort: [
        1591419957541
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFFY5F18JX230121042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T05:04:19.722Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33991.00',
          mileage: '73423'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9495766,
        makeCode: 'TOYOTA',
        vin: '5TFFY5F18JX230121',
        model: {
          modelDescription: 'TUNDRA 4X2 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8272',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:31:52.629Z'
        }
      },
      sort: [
        1591419859722
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7GM033580042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T05:02:44.521Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30650.00',
          mileage: '24270'
        },
        sellArea: '120',
        dealerCd: '04222',
        certificationNumber: 9495654,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7GM033580',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:01:55.064Z'
        }
      },
      sort: [
        1591419764521
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV5JW484360042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T05:01:26.483Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20495.00',
          mileage: '34961'
        },
        sellArea: '500',
        dealerCd: '04222',
        certificationNumber: 9427105,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV5JW484360',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T16:52:16.072Z'
        }
      },
      sort: [
        1591419686483
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH0HS373962042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T05:00:01.281Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33650.00',
          mileage: '22039'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9476275,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH0HS373962',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:52:28.502Z'
        }
      },
      sort: [
        1591419601281
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV7HW329523042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T04:58:49.181Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24750.00',
          mileage: '20086'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9495804,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV7HW329523',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:45:27.235Z'
        }
      },
      sort: [
        1591419529181
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH8HS512680042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T04:57:31.783Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28750.00',
          mileage: '41750'
        },
        sellArea: '150',
        dealerCd: '04222',
        certificationNumber: 9476768,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH8HS512680',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T23:07:57.327Z'
        }
      },
      sort: [
        1591419451783
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP630001042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T04:56:22.602Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14991.00',
          mileage: '34517'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9494861,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP630001',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T01:10:24.454Z'
        }
      },
      sort: [
        1591419382602
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU202348041872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:54:09.199Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20605.00',
          mileage: '20239'
        },
        sellArea: '600',
        dealerCd: '04187',
        certificationNumber: 9479320,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU202348',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T18:30:14.844Z'
        }
      },
      sort: [
        1591415649199
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE9LP006995043032020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:46:49.112Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17979.00',
          mileage: '9151'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9415426,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE9LP006995',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T18:09:40.390Z'
        }
      },
      sort: [
        1591415209112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU2H3535572043032020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:46:08.489Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17498.00',
          mileage: '30227'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9424450,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU2H3535572',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T20:57:41.231Z'
        }
      },
      sort: [
        1591415168489
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMYFREV3HJ128314043032020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:45:05.978Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21952.00',
          mileage: '10528'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9417150,
        makeCode: 'TOYOTA',
        vin: 'JTMYFREV3HJ128314',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T18:21:30.623Z'
        }
      },
      sort: [
        1591415105978
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU3H3035045043032020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:44:07.067Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17949.00',
          mileage: '24540'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9415409,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU3H3035045',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T17:58:02.763Z'
        }
      },
      sort: [
        1591415047067
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU757128043032020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:38:23.360Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14720.00',
          mileage: '31207'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9496172,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU757128',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:07:44.554Z'
        }
      },
      sort: [
        1591414703360
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6HJ120972043032020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:37:25.441Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18426.00',
          mileage: '19688'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9402073,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6HJ120972',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T22:52:34.749Z'
        }
      },
      sort: [
        1591414645441
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21HK2KU519623043032020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:36:28.399Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25337.00',
          mileage: '18640'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9415397,
        makeCode: 'TOYOTA',
        vin: '4T1B21HK2KU519623',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T17:48:36.175Z'
        }
      },
      sort: [
        1591414588399
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU8H3545763043032020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:35:39.817Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17987.00',
          mileage: '34058'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9431046,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU8H3545763',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T20:05:49.743Z'
        }
      },
      sort: [
        1591414539817
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU8H3041541043032020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:34:47.014Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18497.00',
          mileage: '11782'
        },
        sellArea: '110',
        dealerCd: '04303',
        certificationNumber: 9474945,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU8H3041541',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:07:45.167Z'
        }
      },
      sort: [
        1591414487014
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3K1RFVXKW033986040562020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:33:49.195Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22758.00',
          mileage: '11465'
        },
        sellArea: '120',
        dealerCd: '04056',
        certificationNumber: 9456218,
        makeCode: 'TOYOTA',
        vin: '2T3K1RFVXKW033986',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T21:15:09.848Z'
        }
      },
      sort: [
        1591414429195
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDK3DC2FS123175045632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:20:12.403Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24887.00',
          mileage: '35266'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9461496,
        makeCode: 'TOYOTA',
        vin: '5TDDK3DC2FS123175',
        model: {
          modelDescription: 'SIENNA LTD 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5386',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T22:25:45.613Z'
        }
      },
      sort: [
        1591413612403
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH6HS029039045632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T03:07:36.928Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '42586'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9490083,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH6HS029039',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T23:02:06.172Z'
        }
      },
      sort: [
        1591412856928
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU742090190722020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T02:44:27.223Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14860.00',
          mileage: '47945'
        },
        sellArea: '160',
        dealerCd: '19072',
        certificationNumber: 9500563,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU742090',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-06T00:25:42.750Z'
        }
      },
      sort: [
        1591411467223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1JU520201041872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T02:40:33.860Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20775.00',
          mileage: '34794'
        },
        sellArea: '110',
        dealerCd: '04187',
        certificationNumber: 9487654,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1JU520201',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:08:58.893Z'
        }
      },
      sort: [
        1591411233860
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6K5668393042902020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T02:13:11.711Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '34000.00',
          mileage: '24222'
        },
        sellArea: '600',
        dealerCd: '04290',
        certificationNumber: 9496019,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6K5668393',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:55:49.385Z'
        }
      },
      sort: [
        1591409591711
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU574529360722020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T02:03:28.519Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '23447'
        },
        sellArea: '130',
        dealerCd: '36072',
        certificationNumber: 9443992,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU574529',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T18:45:00.296Z'
        }
      },
      sort: [
        1591409008519
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK5GR566438220472020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:57:36.896Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '27853'
        },
        dealerCd: '22047',
        certificationNumber: 9348941,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK5GR566438',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-22T17:27:00.268Z'
        }
      },
      sort: [
        1591408656896
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F18GX526388270132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:42:36.111Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28298.00',
          mileage: '58139'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9476847,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F18GX526388',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T00:33:49.701Z'
        }
      },
      sort: [
        1591407756111
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9J5533424270202020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:40:51.550Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '38776.20',
          mileage: '16126'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9499814,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9J5533424',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:14:01.439Z'
        }
      },
      sort: [
        1591407651550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5L5752349270202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:39:54.888Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '34470.00',
          mileage: '7661'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9499787,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5L5752349',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:03:25.910Z'
        }
      },
      sort: [
        1591407594888
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ708196110302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:37:01.048Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22551.00',
          mileage: '30639'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9496248,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ708196',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:48:45.342Z'
        }
      },
      sort: [
        1591407421048
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV5HJ150168220472020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:33:22.547Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23398.00',
          mileage: '19314'
        },
        sellArea: '160',
        dealerCd: '22047',
        certificationNumber: 9484127,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV5HJ150168',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:56:52.877Z'
        }
      },
      sort: [
        1591407202547
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH1HS382217220472020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:30:58.967Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30999.00',
          mileage: '21962'
        },
        sellArea: '160',
        dealerCd: '22047',
        certificationNumber: 9494636,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH1HS382217',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:22:41.943Z'
        }
      },
      sort: [
        1591407058967
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS388240360992020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:04:53.660Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29995.00',
          mileage: '26128'
        },
        sellArea: '120',
        dealerCd: '36099',
        certificationNumber: 9459218,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS388240',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T21:57:46.040Z'
        }
      },
      sort: [
        1591405493660
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW609824311792020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:04:09.319Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '25275.78',
          mileage: '24573'
        },
        sellArea: '160',
        dealerCd: '31179',
        certificationNumber: 9412085,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW609824',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T23:18:19.194Z'
        }
      },
      sort: [
        1591405449319
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21FB2KU007072311792020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T01:00:44.399Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '29304.90',
          mileage: '19651'
        },
        sellArea: '160',
        dealerCd: '31179',
        certificationNumber: 9481682,
        makeCode: 'TOYOTA',
        vin: '4T1B21FB2KU007072',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:27:08.055Z'
        }
      },
      sort: [
        1591405244399
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN7HX116207311792020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:58:47.097Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '30489.00',
          mileage: '8892'
        },
        dealerCd: '31179',
        certificationNumber: 9386509,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN7HX116207',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T22:43:12.791Z'
        }
      },
      sort: [
        1591405127097
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJGRFH3KS075454250642020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:56:20.377Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '38275.00',
          mileage: '7998'
        },
        sellArea: '130',
        dealerCd: '25064',
        certificationNumber: 9471167,
        makeCode: 'TOYOTA',
        vin: '5TDJGRFH3KS075454',
        model: {
          modelDescription: 'HIGHLANDER HYBRD XLE-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6965',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:34:24.299Z'
        }
      },
      sort: [
        1591404980377
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7GJ102754250642020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:54:28.137Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21485.00',
          mileage: '40948'
        },
        sellArea: '130',
        dealerCd: '25064',
        certificationNumber: 9487478,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7GJ102754',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:03:00.754Z'
        }
      },
      sort: [
        1591404868137
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6KM257884360802020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:45:25.714Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '32277.00',
          mileage: '21069'
        },
        sellArea: '500',
        dealerCd: '36080',
        certificationNumber: 9487113,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6KM257884',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:54:25.934Z'
        }
      },
      sort: [
        1591404325714
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8HS813450360802020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:44:02.152Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '28997.00',
          mileage: '18540'
        },
        sellArea: '130',
        dealerCd: '36080',
        certificationNumber: 9481684,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8HS813450',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:27:43.735Z'
        }
      },
      sort: [
        1591404242152
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5ANXHM118768361002020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:27:18.589Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30750.00',
          mileage: '20286'
        },
        sellArea: '130',
        dealerCd: '36100',
        certificationNumber: 9476713,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5ANXHM118768',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:53:54.827Z'
        }
      },
      sort: [
        1591403238589
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN9KM096273043442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:27:02.987Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32483.00',
          mileage: '25043'
        },
        sellArea: '500',
        dealerCd: '04344',
        certificationNumber: 9420643,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN9KM096273',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T20:57:35.001Z'
        }
      },
      sort: [
        1591403222987
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3N1RFV3KW066681220462020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:18:09.464Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33985.00',
          mileage: '5852'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9471373,
        makeCode: 'TOYOTA',
        vin: '2T3N1RFV3KW066681',
        model: {
          modelDescription: 'RAV4 LIMITED AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T19:55:06.410Z'
        }
      },
      sort: [
        1591402689464
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXGC673749070092020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:10:19.062Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14372.00',
          mileage: '46897'
        },
        sellArea: '800',
        dealerCd: '07009',
        certificationNumber: 9492329,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXGC673749',
        model: {
          modelDescription: 'COROLLA S PREMIUM',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:33:41.599Z'
        }
      },
      sort: [
        1591402219062
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC3HS820578220542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:02:14.279Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24945.00',
          mileage: '45010'
        },
        sellArea: '230',
        certificationNumber: 9471766,
        dealerCd: '22054',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        vin: '5TDYZ3DC3HS820578',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T22:12:56.484Z'
        }
      },
      sort: [
        1591401734279
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU749993360852020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:01:46.759Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19991.00',
          mileage: '12792'
        },
        sellArea: '130',
        dealerCd: '36085',
        certificationNumber: 9442440,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU749993',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T17:56:07.068Z'
        }
      },
      sort: [
        1591401706759
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC6HS151561220542020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-06T00:01:01.841Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24645.00',
          mileage: '46129'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9443518,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC6HS151561',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-11T14:52:07.294Z'
        }
      },
      sort: [
        1591401661841
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F15HX620954049902020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:54:42.118Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '36207.00',
          mileage: '38320'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9469887,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F15HX620954',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 EDITION CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T18:00:24.257Z'
        }
      },
      sort: [
        1591401282118
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK8ER362018042922020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:54:39.117Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '12900.00',
          mileage: '60018'
        },
        sellArea: '120',
        dealerCd: '04292',
        certificationNumber: 9485677,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK8ER362018',
        model: {
          modelDescription: 'CAMRY 2014.5 LE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:51:13.863Z'
        }
      },
      sort: [
        1591401279117
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK9JU011880042922020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:54:06.548Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25988.00',
          mileage: '31069'
        },
        sellArea: '120',
        certificationNumber: 9493092,
        dealerCd: '04292',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        vin: '4T1B61HK9JU011880',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:47:23.255Z'
        }
      },
      sort: [
        1591401246548
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0FD135253371552020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:52:13.017Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '20454'
        },
        sellArea: '800',
        dealerCd: '37155',
        certificationNumber: 9474132,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0FD135253',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T13:22:34.036Z'
        }
      },
      sort: [
        1591401133017
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV9JW467039460712020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:51:45.783Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21948.00',
          mileage: '15578'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9407399,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV9JW467039',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T20:12:45.063Z'
        }
      },
      sort: [
        1591401105783
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC869216371552020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:51:35.082Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14480.00',
          mileage: '26327'
        },
        sellArea: '800',
        certificationNumber: 9474163,
        dealerCd: '37155',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        vin: '2T1BURHE0HC869216',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T13:48:39.847Z'
        }
      },
      sort: [
        1591401095082
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3MWRFV7KW022003371552020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:51:09.484Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25700.00',
          mileage: '3995'
        },
        sellArea: '800',
        dealerCd: '37155',
        certificationNumber: 9485016,
        makeCode: 'TOYOTA',
        vin: '2T3MWRFV7KW022003',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T12:58:44.594Z'
        }
      },
      sort: [
        1591401069484
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV5KC016195460712020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:50:12.763Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26114.00',
          mileage: '20316'
        },
        sellArea: '500',
        dealerCd: '46071',
        certificationNumber: 9446352,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV5KC016195',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-20T21:15:52.807Z'
        }
      },
      sort: [
        1591401012763
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDHPRAEXLJ012443460712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:48:27.561Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19248.00',
          mileage: '7169'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9397822,
        makeCode: 'TOYOTA',
        vin: 'JTDHPRAEXLJ012443',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T21:39:49.026Z'
        }
      },
      sort: [
        1591400907561
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU731659460712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:47:14.078Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19271.00',
          mileage: '9553'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9494148,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU731659',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:26:26.927Z'
        }
      },
      sort: [
        1591400834078
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HJ141662250602020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:46:40.858Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22029.00',
          mileage: '41707'
        },
        sellArea: '130',
        dealerCd: '25060',
        certificationNumber: 9493229,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HJ141662',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:40:59.540Z'
        }
      },
      sort: [
        1591400800858
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1KZ1AK7LU033560460712020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:45:58.977Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33212.00',
          mileage: '4225'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9466205,
        makeCode: 'TOYOTA',
        vin: '4T1KZ1AK7LU033560',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T21:08:18.693Z'
        }
      },
      sort: [
        1591400758977
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F14HX072292290712020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:44:53.194Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28000.00',
          mileage: '32968'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9492681,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F14HX072292',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8340',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:10:46.003Z'
        }
      },
      sort: [
        1591400693194
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFGZ5AN4HX089649250602020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:44:46.015Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '34990.00',
          mileage: '11208'
        },
        sellArea: '190',
        dealerCd: '25060',
        certificationNumber: 9500064,
        makeCode: 'TOYOTA',
        vin: '5TFGZ5AN4HX089649',
        model: {
          modelDescription: 'TACOMA LIMITED 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7582',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:43:32.252Z'
        }
      },
      sort: [
        1591400686015
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4K5638342460712020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:44:28.476Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '39701.00',
          mileage: '16747'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9494198,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4K5638342',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:41:13.532Z'
        }
      },
      sort: [
        1591400668476
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV3GD028018220462020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:43:53.272Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21485.00',
          mileage: '21636'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9400672,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV3GD028018',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T15:17:20.713Z'
        }
      },
      sort: [
        1591400633272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1HW622431220462020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:43:03.431Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23885.00',
          mileage: '18758'
        },
        dealerCd: '22046',
        certificationNumber: 9382014,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1HW622431',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T22:07:43.531Z'
        }
      },
      sort: [
        1591400583431
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAE12L9750182460712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:42:57.912Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29748.00',
          mileage: '6958'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9430883,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAE12L9750182',
        model: {
          modelDescription: '86 HAKONE EDITION',
          modelSeries: '86',
          modelNumber: '6259',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T18:35:33.887Z'
        }
      },
      sort: [
        1591400577912
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU746671290712020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:41:13.074Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17269.00',
          mileage: '12015'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9493270,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU746671',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:53:46.022Z'
        }
      },
      sort: [
        1591400473074
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN6KX155998290712020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:39:36.786Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25195.00',
          mileage: '5025'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9488929,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN6KX155998',
        model: {
          modelDescription: 'TACOMA SR 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7186',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T12:40:36.221Z'
        }
      },
      sort: [
        1591400376786
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN6HX106042110302020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:38:24.109Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29683.00',
          mileage: '37674'
        },
        sellArea: '800',
        dealerCd: '11030',
        certificationNumber: 9496223,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN6HX106042',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:34:57.419Z'
        }
      },
      sort: [
        1591400304109
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS379936290712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:37:46.742Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29293.00',
          mileage: '31973'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9420595,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS379936',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T20:43:28.355Z'
        }
      },
      sort: [
        1591400266742
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1F5253469290712020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:35:00.188Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '28000.00',
          mileage: '67020'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9496506,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1F5253469',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:52:14.424Z'
        }
      },
      sort: [
        1591400100188
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8HJ136690290712020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:34:15.848Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16674.06',
          mileage: '40902'
        },
        sellArea: '800',
        dealerCd: '29071',
        certificationNumber: 9438832,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8HJ136690',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T19:36:13.354Z'
        }
      },
      sort: [
        1591400055848
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV7KW030280043092020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:34:01.988Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22988.00',
          mileage: '11604'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9500369,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV7KW030280',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:26:18.903Z'
        }
      },
      sort: [
        1591400041988
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3JU040256290712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:30:09.946Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21997.20',
          mileage: '14312'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9488915,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3JU040256',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T12:18:12.308Z'
        }
      },
      sort: [
        1591399809946
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC8HS796977290712020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:28:58.087Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21555.00',
          mileage: '30634'
        },
        dealerCd: '29071',
        certificationNumber: 9331058,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC8HS796977',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-10T19:37:58.676Z'
        }
      },
      sort: [
        1591399738087
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3KM256014020482020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:28:54.147Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '36906.77',
          mileage: '6810'
        },
        sellArea: '500',
        dealerCd: '02048',
        certificationNumber: 9494413,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3KM256014',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:59:47.094Z'
        }
      },
      sort: [
        1591399734147
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU283502020482020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:28:02.146Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18755.79',
          mileage: '10478'
        },
        sellArea: '150',
        dealerCd: '02048',
        certificationNumber: 9484431,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU283502',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:17:34.683Z'
        }
      },
      sort: [
        1591399682146
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN2HM019381290712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:26:15.105Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27926.00',
          mileage: '31752'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9480591,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN2HM019381',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T17:47:13.370Z'
        }
      },
      sort: [
        1591399575105
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ707016290712020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:24:39.226Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19383.74',
          mileage: '40586'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9489109,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ707016',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T14:58:07.435Z'
        }
      },
      sort: [
        1591399479226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HJ133027371382020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:22:01.146Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19988.00',
          mileage: '41908'
        },
        dealerCd: '37138',
        certificationNumber: 9389669,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HJ133027',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T21:24:33.149Z'
        }
      },
      sort: [
        1591399321146
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU691206040612020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:19:52.485Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23734.00',
          mileage: '34833'
        },
        sellArea: '600',
        dealerCd: '04061',
        certificationNumber: 9420981,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU691206',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T00:31:20.690Z'
        }
      },
      sort: [
        1591399192485
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV1LW060819020442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:14:41.464Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26274.00',
          mileage: '13552'
        },
        sellArea: '500',
        dealerCd: '02044',
        certificationNumber: 9470314,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV1LW060819',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T11:55:45.880Z'
        }
      },
      sort: [
        1591398881464
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB2HU258068020442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:14:22.044Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24999.00',
          mileage: '18002'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9490233,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB2HU258068',
        model: {
          modelDescription: 'AVALON 4-DR XLE PREMIUM',
          modelSeries: 'AVALON',
          modelNumber: '3546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T13:56:24.929Z'
        }
      },
      sort: [
        1591398862044
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV7LW061375020442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:14:02.765Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29999.00',
          mileage: '12369'
        },
        sellArea: '500',
        dealerCd: '02044',
        certificationNumber: 9474063,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV7LW061375',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T11:57:33.011Z'
        }
      },
      sort: [
        1591398842765
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV1HJ123665020442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:13:36.144Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19497.00',
          mileage: '44477'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9468086,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV1HJ123665',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T11:59:55.419Z'
        }
      },
      sort: [
        1591398816144
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU210497020442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:13:17.124Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20086.50',
          mileage: '34089'
        },
        sellArea: '600',
        dealerCd: '02044',
        certificationNumber: 9480078,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU210497',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T12:10:29.812Z'
        }
      },
      sort: [
        1591398797124
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU704039020442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:13:03.024Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15830.00',
          mileage: '20327'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9496834,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU704039',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T11:44:39.585Z'
        }
      },
      sort: [
        1591398783024
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6K5722601020442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:12:46.944Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31433.87',
          mileage: '9900'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9496812,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6K5722601',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T11:24:47.621Z'
        }
      },
      sort: [
        1591398766944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV3HW337302020442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:12:20.938Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20988.81',
          mileage: '43691'
        },
        dealerCd: '02044',
        certificationNumber: 9385169,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV3HW337302',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T11:44:09.682Z'
        }
      },
      sort: [
        1591398740938
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH1HS024886020442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:11:58.197Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '71389'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9414860,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH1HS024886',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T12:11:31.419Z'
        }
      },
      sort: [
        1591398718197
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK9KU296582020442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:11:31.403Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26577.36',
          mileage: '7751'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9495071,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK9KU296582',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T11:41:13.042Z'
        }
      },
      sort: [
        1591398691403
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW635683020442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:11:07.507Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23857.00',
          mileage: '23939'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9484944,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW635683',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T11:49:41.812Z'
        }
      },
      sort: [
        1591398667507
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKKRFH0GS155563041362020-06-03V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:11:06.579Z',
          retailDate: '2020-06-03',
          rdrStatus: 'V',
          soldAmount: '29500.00',
          mileage: '66090'
        },
        sellArea: '190',
        dealerCd: '04136',
        certificationNumber: 9497449,
        makeCode: 'TOYOTA',
        vin: '5TDKKRFH0GS155563',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:27:09.312Z'
        }
      },
      sort: [
        1591398666579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREVXHJ135202043092020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:10:35.464Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17385.68',
          mileage: '36678'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9478349,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREVXHJ135202',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:06:39.978Z'
        }
      },
      sort: [
        1591398635464
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU734532020442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:10:33.463Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21940.00',
          mileage: '30874'
        },
        sellArea: '500',
        dealerCd: '02044',
        certificationNumber: 9480075,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU734532',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T11:59:36.832Z'
        }
      },
      sort: [
        1591398633463
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC6HS859679043092020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T23:07:11.364Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24988.00',
          mileage: '37367'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9500360,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC6HS859679',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:23:07.679Z'
        }
      },
      sort: [
        1591398431364
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP650108043092020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:59:09.322Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15988.00',
          mileage: '4635'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9478340,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP650108',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:03:22.056Z'
        }
      },
      sort: [
        1591397949322
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0LM308958110362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:59:03.802Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '40791.00',
          mileage: '1529'
        },
        sellArea: '130',
        dealerCd: '11036',
        certificationNumber: 9498242,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0LM308958',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:57:21.690Z'
        }
      },
      sort: [
        1591397943802
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV3JD238772110362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:58:16.402Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24991.00',
          mileage: '22797'
        },
        sellArea: '130',
        dealerCd: '11036',
        certificationNumber: 9495566,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV3JD238772',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:22:37.405Z'
        }
      },
      sort: [
        1591397896402
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5ANXKX209024180332020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:55:28.301Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32997.00',
          mileage: '4867'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9407161,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5ANXKX209024',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T18:10:58.654Z'
        }
      },
      sort: [
        1591397728301
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK0ER400874020552020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:55:13.701Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13149.00',
          mileage: '41052'
        },
        sellArea: '150',
        dealerCd: '02055',
        certificationNumber: 9500417,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK0ER400874',
        model: {
          modelDescription: 'CAMRY 2014.5 LE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:52:14.109Z'
        }
      },
      sort: [
        1591397713701
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2K5639246110222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:54:59.481Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '40500.00',
          mileage: '23301'
        },
        sellArea: '150',
        dealerCd: '11022',
        certificationNumber: 9482442,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2K5639246',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:25:31.718Z'
        }
      },
      sort: [
        1591397699481
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6JU132557043092020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:54:04.653Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18488.00',
          mileage: '21554'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9495932,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6JU132557',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:30:39.167Z'
        }
      },
      sort: [
        1591397644653
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN1HX060866040562020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:46:35.120Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29999.00',
          mileage: '40234'
        },
        sellArea: '120',
        dealerCd: '04056',
        certificationNumber: 9474846,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN1HX060866',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7543',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:15:15.142Z'
        }
      },
      sort: [
        1591397195120
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3KW021832430162020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:39:50.359Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '26687.00',
          mileage: '9855'
        },
        sellArea: '120',
        dealerCd: '43016',
        certificationNumber: 9490130,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3KW021832',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T23:31:17.487Z'
        }
      },
      sort: [
        1591396790359
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP0H3033234430262020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:33:22.038Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21033.00',
          mileage: '43047'
        },
        sellArea: '150',
        dealerCd: '43026',
        certificationNumber: 9452501,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP0H3033234',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T19:37:37.306Z'
        }
      },
      sort: [
        1591396402038
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV9HD082666190602020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:33:06.018Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24998.00',
          mileage: '15051'
        },
        dealerCd: '19060',
        certificationNumber: 9372267,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV9HD082666',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T16:07:24.532Z'
        }
      },
      sort: [
        1591396386018
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE0LP014130430262020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:29:21.397Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16988.00',
          mileage: '16248'
        },
        sellArea: '600',
        dealerCd: '43026',
        certificationNumber: 9483633,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE0LP014130',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:43:18.199Z'
        }
      },
      sort: [
        1591396161397
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE2LJ031023020372020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:23:55.196Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18987.00',
          mileage: '25742'
        },
        sellArea: '600',
        dealerCd: '02037',
        certificationNumber: 9496371,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE2LJ031023',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:58:50.917Z'
        }
      },
      sort: [
        1591395835196
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1GU544785360402020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:16:57.296Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17300.00',
          mileage: '14002'
        },
        sellArea: '130',
        dealerCd: '36040',
        certificationNumber: 9499835,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1GU544785',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:20:59.242Z'
        }
      },
      sort: [
        1591395417296
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW626145201402020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:10:22.323Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17495.00',
          mileage: '35699'
        },
        sellArea: '170',
        dealerCd: '20140',
        certificationNumber: 9471582,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW626145',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T21:14:36.193Z'
        }
      },
      sort: [
        1591395022323
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW648573311602020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T22:06:15.874Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '20913.00',
          mileage: '13181'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9500329,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW648573',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T22:03:52.587Z'
        }
      },
      sort: [
        1591394775874
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC6LS037490220542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:58:05.470Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28499.00',
          mileage: '15019'
        },
        sellArea: '600',
        dealerCd: '22054',
        certificationNumber: 9408967,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC6LS037490',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T20:47:52.790Z'
        }
      },
      sort: [
        1591394285470
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5502910470272020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:56:24.630Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31989.00',
          mileage: '47544'
        },
        sellArea: '800',
        dealerCd: '47027',
        certificationNumber: 9499177,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5502910',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:56:45.674Z'
        }
      },
      sort: [
        1591394184630
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB38G1132806150502020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:56:07.492Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '26275'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9499405,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB38G1132806',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB ONE',
          modelSeries: 'PRIUS C',
          modelNumber: '1201',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:57:33.000Z'
        }
      },
      sort: [
        1591394167492
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HJ152014340262020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:55:25.809Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '29638'
        },
        sellArea: '220',
        dealerCd: '34026',
        certificationNumber: 9485269,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HJ152014',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:26:26.252Z'
        }
      },
      sort: [
        1591394125809
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HJ132720130532020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:54:51.011Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '32042'
        },
        sellArea: '210',
        dealerCd: '13053',
        certificationNumber: 9483610,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HJ132720',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:34:18.871Z'
        }
      },
      sort: [
        1591394091011
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU403462310692020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:53:01.592Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16998.00',
          mileage: '19016'
        },
        sellArea: '160',
        dealerCd: '31069',
        certificationNumber: 9462861,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU403462',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:52:54.634Z'
        }
      },
      sort: [
        1591393981592
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMF1RFV7KJ004252370832020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:50:38.709Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25300.00',
          mileage: '10776'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9428278,
        makeCode: 'TOYOTA',
        vin: 'JTMF1RFV7KJ004252',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T14:36:24.524Z'
        }
      },
      sort: [
        1591393838709
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW590985210742020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:47:55.350Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21933.00',
          mileage: '28048'
        },
        sellArea: '220',
        dealerCd: '21074',
        certificationNumber: 9399796,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW590985',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T22:01:39.032Z'
        }
      },
      sort: [
        1591393675350
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9K5696382211012020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:47:44.768Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33250.00',
          mileage: '8029'
        },
        sellArea: '220',
        dealerCd: '21101',
        certificationNumber: 9400903,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9K5696382',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T16:36:34.562Z'
        }
      },
      sort: [
        1591393664768
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ139870210742020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:45:16.854Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19210.15',
          mileage: '26653'
        },
        sellArea: '210',
        dealerCd: '21074',
        certificationNumber: 9433119,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ139870',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T18:09:42.628Z'
        }
      },
      sort: [
        1591393516854
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6GP426468043562020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:44:30.113Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15983.00',
          mileage: '52121'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9500051,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6GP426468',
        model: {
          modelDescription: 'COROLLA S PREMIUM',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:39:31.357Z'
        }
      },
      sort: [
        1591393470113
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5J5574620121532020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:43:57.793Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29837.00',
          mileage: '27451'
        },
        dealerCd: '12153',
        certificationNumber: 9386095,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5J5574620',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T19:42:27.115Z'
        }
      },
      sort: [
        1591393437793
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC4HS164552211012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:42:32.833Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28552.47',
          mileage: '36728'
        },
        sellArea: '220',
        dealerCd: '21101',
        certificationNumber: 9424025,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC4HS164552',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T17:56:08.367Z'
        }
      },
      sort: [
        1591393352833
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC7LS032606220542020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:42:28.692Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28924.00',
          mileage: '24643'
        },
        sellArea: '600',
        dealerCd: '22054',
        certificationNumber: 9411121,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC7LS032606',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T15:17:08.848Z'
        }
      },
      sort: [
        1591393348692
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV5KC011739210742020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:42:00.772Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26432.00',
          mileage: '16444'
        },
        sellArea: '600',
        dealerCd: '21074',
        certificationNumber: 9394306,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV5KC011739',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T22:08:15.042Z'
        }
      },
      sort: [
        1591393320772
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK2HU218372220542020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:38:49.471Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '55129'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9442221,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK2HU218372',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T13:56:01.577Z'
        }
      },
      sort: [
        1591393129471
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0J5525471210742020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:36:33.090Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33211.00',
          mileage: '27643'
        },
        sellArea: '220',
        dealerCd: '21074',
        certificationNumber: 9485695,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0J5525471',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:56:10.382Z'
        }
      },
      sort: [
        1591392993090
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5517374210742020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:34:05.929Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '34711.00',
          mileage: '39195'
        },
        sellArea: '220',
        dealerCd: '21074',
        certificationNumber: 9477456,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5517374',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:43:36.384Z'
        }
      },
      sort: [
        1591392845929
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV6GJ077663220542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:33:44.953Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19969.00',
          mileage: '69691'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9464849,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV6GJ077663',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T20:35:28.546Z'
        }
      },
      sort: [
        1591392824953
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC6KS017468043562020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:33:43.007Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22870.00',
          mileage: '22449'
        },
        sellArea: '500',
        certificationNumber: 9500044,
        dealerCd: '04356',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2019
        },
        vin: '5TDKZ3DC6KS017468',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:36:38.828Z'
        }
      },
      sort: [
        1591392823007
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1C11AK8LU884570046762020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:30:40.926Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23998.00',
          mileage: '1255'
        },
        sellArea: '120',
        dealerCd: '04676',
        certificationNumber: 9500228,
        makeCode: 'TOYOTA',
        vin: '4T1C11AK8LU884570',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:28:40.942Z'
        }
      },
      sort: [
        1591392640926
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBZV3GY144548043562020-05-30V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:27:50.921Z',
          retailDate: '2020-05-30',
          rdrStatus: 'V',
          soldAmount: '11583.00',
          mileage: '52369'
        },
        sellArea: '130',
        dealerCd: '04356',
        certificationNumber: 9407501,
        makeCode: 'TOYOTA',
        vin: '3MYDLBZV3GY144548',
        model: {
          modelDescription: 'SCION iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6263',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T21:10:54.012Z'
        }
      },
      sort: [
        1591392470921
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE6LP042148043562020-05-25V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:27:04.023Z',
          retailDate: '2020-05-25',
          rdrStatus: 'V',
          soldAmount: '16150.00',
          mileage: '16190'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9452369,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE6LP042148',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T18:14:24.828Z'
        }
      },
      sort: [
        1591392424023
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8GC684507480532020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:25:51.621Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14495.00',
          mileage: '39951'
        },
        dealerCd: '48053',
        certificationNumber: 9454024,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8GC684507',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T20:38:19.311Z'
        }
      },
      sort: [
        1591392351621
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU405257047392020-06-01V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:25:38.090Z',
          retailDate: '2020-06-01',
          rdrStatus: 'V',
          soldAmount: '15998.00',
          mileage: '37144'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9425990,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU405257',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T20:21:13.857Z'
        }
      },
      sort: [
        1591392338090
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6HM048265290082020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:19:32.219Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '33478'
        },
        sellArea: '160',
        dealerCd: '29008',
        certificationNumber: 9411262,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6HM048265',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T16:20:05.433Z'
        }
      },
      sort: [
        1591391972219
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN8HX084092470322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:18:53.121Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '30891.00',
          mileage: '6912'
        },
        sellArea: '800',
        dealerCd: '47032',
        certificationNumber: 9496362,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN8HX084092',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7562',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:54:56.877Z'
        }
      },
      sort: [
        1591391933121
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN5HX112477060292020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:18:46.002Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29368.00',
          mileage: '19341'
        },
        sellArea: '160',
        dealerCd: '06029',
        certificationNumber: 9497083,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN5HX112477',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7562',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:51:58.253Z'
        }
      },
      sort: [
        1591391926002
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1EB8EU029240060292020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:17:42.197Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16200.00',
          mileage: '80701'
        },
        sellArea: '160',
        dealerCd: '06029',
        certificationNumber: 9492375,
        makeCode: 'TOYOTA',
        vin: '4T1BD1EB8EU029240',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:42:02.835Z'
        }
      },
      sort: [
        1591391862197
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8GJ070804311852020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:17:36.856Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17400.00',
          mileage: '21748'
        },
        sellArea: '160',
        dealerCd: '31185',
        certificationNumber: 9481797,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8GJ070804',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T17:21:13.064Z'
        }
      },
      sort: [
        1591391856856
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7K5670539410822020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:15:35.459Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '36189'
        },
        sellArea: '500',
        dealerCd: '41082',
        certificationNumber: 9480369,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7K5670539',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T16:02:26.794Z'
        }
      },
      sort: [
        1591391735459
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW637454410822020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:14:08.520Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '19250.00',
          mileage: '29264'
        },
        sellArea: '800',
        dealerCd: '41082',
        certificationNumber: 9467121,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW637454',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T17:14:39.891Z'
        }
      },
      sort: [
        1591391648520
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP616652460762020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:13:07.399Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20775.17',
          mileage: '25237'
        },
        sellArea: '130',
        dealerCd: '46076',
        certificationNumber: 9472866,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP616652',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T17:34:06.256Z'
        }
      },
      sort: [
        1591391587399
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN9LX214152310882020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:13:02.740Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32495.00',
          mileage: '3985'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9500155,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN9LX214152',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T21:09:53.602Z'
        }
      },
      sort: [
        1591391582740
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV6HW601834440132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:12:57.079Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22765.00',
          mileage: '29406'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9483932,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV6HW601834',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:52:36.936Z'
        }
      },
      sort: [
        1591391577079
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXK5671751410822020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:11:49.678Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '32250.00',
          mileage: '25997'
        },
        sellArea: '500',
        dealerCd: '41082',
        certificationNumber: 9486061,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXK5671751',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:30:40.143Z'
        }
      },
      sort: [
        1591391509678
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX7JR012422290082020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:09:44.477Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18872.00',
          mileage: '21990'
        },
        sellArea: '170',
        dealerCd: '29008',
        certificationNumber: 9429091,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX7JR012422',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T21:12:31.283Z'
        }
      },
      sort: [
        1591391384477
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX5J1003504311562020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:09:27.817Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18304.00',
          mileage: '4836'
        },
        dealerCd: '31156',
        certificationNumber: 9370000,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX5J1003504',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-05T22:20:29.886Z'
        }
      },
      sort: [
        1591391367817
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK8LU874719042822020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:08:57.177Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23998.0000',
          mileage: '6643'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9472722,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK8LU874719',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T16:22:30.230Z'
        }
      },
      sort: [
        1591391337177
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN3HM033470042822020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:08:52.737Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25995.0000',
          mileage: '51593'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9488274,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN3HM033470',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:35:06.714Z'
        }
      },
      sort: [
        1591391332737
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK8LU329703042822020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:08:50.317Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23998.00',
          mileage: '8324'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9494129,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK8LU329703',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:14:45.890Z'
        }
      },
      sort: [
        1591391330317
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2K5656449410632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:08:05.415Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '35000.00',
          mileage: '26985'
        },
        sellArea: '220',
        dealerCd: '41063',
        certificationNumber: 9448137,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2K5656449',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T19:05:33.117Z'
        }
      },
      sort: [
        1591391285415
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HD212756410822020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:06:50.292Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '47710'
        },
        sellArea: '220',
        dealerCd: '41082',
        certificationNumber: 9477853,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HD212756',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:33:30.751Z'
        }
      },
      sort: [
        1591391210292
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU684452410632020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:06:18.252Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19473.50',
          mileage: '38634'
        },
        sellArea: '500',
        dealerCd: '41063',
        certificationNumber: 9440857,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU684452',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T18:11:48.539Z'
        }
      },
      sort: [
        1591391178252
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8HM074771220542020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:05:40.692Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27734.00',
          mileage: '34367'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9470361,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8HM074771',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T13:15:53.988Z'
        }
      },
      sort: [
        1591391140692
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH2HS393906410822020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:05:12.430Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '30975.00',
          mileage: '35055'
        },
        sellArea: '170',
        dealerCd: '41082',
        certificationNumber: 9465874,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH2HS393906',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:30:20.514Z'
        }
      },
      sort: [
        1591391112430
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HJ142455440132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:04:54.791Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '32299'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9481998,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HJ142455',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:42:03.369Z'
        }
      },
      sort: [
        1591391094791
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21FB3KU003189360992020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:03:31.952Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '33995.00',
          mileage: '17835'
        },
        sellArea: '130',
        dealerCd: '36099',
        certificationNumber: 9482665,
        makeCode: 'TOYOTA',
        vin: '4T1B21FB3KU003189',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T00:26:30.471Z'
        }
      },
      sort: [
        1591391011952
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5578806410822020-05-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:03:27.114Z',
          retailDate: '2020-05-13',
          rdrStatus: 'H',
          soldAmount: '31149.00',
          mileage: '26431'
        },
        sellArea: '220',
        dealerCd: '41082',
        certificationNumber: 9447496,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5578806',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T19:55:17.622Z'
        }
      },
      sort: [
        1591391007114
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS424997050432020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T21:00:50.253Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '31366.33',
          mileage: '17938'
        },
        sellArea: '150',
        dealerCd: '05043',
        certificationNumber: 9484640,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS424997',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T02:26:50.697Z'
        }
      },
      sort: [
        1591390850253
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM057271110282020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:58:27.732Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '43843'
        },
        sellArea: '130',
        dealerCd: '11028',
        certificationNumber: 9483869,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM057271',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:30:43.544Z'
        }
      },
      sort: [
        1591390707732
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS510491360992020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:55:42.410Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '42311'
        },
        sellArea: '150',
        dealerCd: '36099',
        certificationNumber: 9472007,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS510491',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T00:00:56.533Z'
        }
      },
      sort: [
        1591390542410
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS393186060322020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:53:44.529Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29487.00',
          mileage: '33993'
        },
        sellArea: '160',
        dealerCd: '06032',
        certificationNumber: 9412477,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS393186',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T17:46:37.960Z'
        }
      },
      sort: [
        1591390424529
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9JW764869240362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:50:13.508Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25990.00',
          mileage: '29308'
        },
        sellArea: '230',
        dealerCd: '24036',
        certificationNumber: 9497025,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9JW764869',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:25:00.907Z'
        }
      },
      sort: [
        1591390213508
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS404472121532020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:49:39.728Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26897.00',
          mileage: '53286'
        },
        sellArea: '230',
        dealerCd: '12153',
        certificationNumber: 9486988,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS404472',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:59:29.252Z'
        }
      },
      sort: [
        1591390179728
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F1XKX868217270132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:48:01.626Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '41997.00',
          mileage: '9637'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9485799,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F1XKX868217',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:24:24.811Z'
        }
      },
      sort: [
        1591390081626
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH0HS407169220542020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:47:55.327Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27983.00',
          mileage: '50821'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9485490,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH0HS407169',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:05:07.150Z'
        }
      },
      sort: [
        1591390075327
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1HC854322460762020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:47:12.865Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '17188.05',
          mileage: '10951'
        },
        sellArea: '130',
        dealerCd: '46076',
        certificationNumber: 9472862,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1HC854322',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T17:32:18.276Z'
        }
      },
      sort: [
        1591390032865
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW681849311802020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:46:03.926Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '15288'
        },
        sellArea: '160',
        dealerCd: '31180',
        certificationNumber: 9498898,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW681849',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:25:05.630Z'
        }
      },
      sort: [
        1591389963926
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV5KC014818270132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:46:00.066Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25728.00',
          mileage: '28042'
        },
        sellArea: '600',
        dealerCd: '27013',
        certificationNumber: 9476851,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV5KC014818',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T00:40:38.945Z'
        }
      },
      sort: [
        1591389960066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC942532361032020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:45:53.806Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13997.00',
          mileage: '39018'
        },
        sellArea: '130',
        dealerCd: '36103',
        certificationNumber: 9500065,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC942532',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:43:43.131Z'
        }
      },
      sort: [
        1591389953806
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2JC996085450272020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:44:30.223Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13550.00',
          mileage: '17304'
        },
        sellArea: '800',
        dealerCd: '45027',
        certificationNumber: 9492659,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2JC996085',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:05:31.523Z'
        }
      },
      sort: [
        1591389870223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FKXFR452305270132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:44:20.585Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13797.00',
          mileage: '41756'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9485942,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FKXFR452305',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:16:19.634Z'
        }
      },
      sort: [
        1591389860585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU006086450272020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:43:37.443Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18109.92',
          mileage: '31427'
        },
        sellArea: '800',
        certificationNumber: 9459144,
        dealerCd: '45027',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        vin: '4T1B11HKXJU006086',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T21:19:25.447Z'
        }
      },
      sort: [
        1591389817443
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS402483220542020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:43:23.584Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26299.00',
          mileage: '36954'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9487071,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS402483',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:33:28.320Z'
        }
      },
      sort: [
        1591389803584
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU253371450272020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:43:11.503Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16993.87',
          mileage: '18358'
        },
        dealerCd: '45027',
        certificationNumber: 9367526,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU253371',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T01:04:19.455Z'
        }
      },
      sort: [
        1591389791503
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1DZ1FB2LU041857450272020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:42:09.505Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '35996.00',
          mileage: '9282'
        },
        sellArea: '800',
        dealerCd: '45027',
        certificationNumber: 9484204,
        makeCode: 'TOYOTA',
        vin: '4T1DZ1FB2LU041857',
        model: {
          modelDescription: 'AVALON 4-DR LIMITED',
          modelSeries: 'AVALON',
          modelNumber: '3554',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:32:52.154Z'
        }
      },
      sort: [
        1591389729505
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP9H3017419311322020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:41:42.823Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '23208'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9461302,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP9H3017419',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T20:06:05.934Z'
        }
      },
      sort: [
        1591389702823
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3GD184593450272020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:41:28.783Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18301.56',
          mileage: '41130'
        },
        dealerCd: '45027',
        certificationNumber: 9384935,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3GD184593',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T00:00:45.624Z'
        }
      },
      sort: [
        1591389688783
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU1H3532887450272020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:40:47.825Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '23266'
        },
        sellArea: '800',
        dealerCd: '45027',
        certificationNumber: 9423763,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU1H3532887',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T16:10:51.206Z'
        }
      },
      sort: [
        1591389647825
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU814326450272020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:39:58.083Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18696.00',
          mileage: '4870'
        },
        sellArea: '800',
        dealerCd: '45027',
        certificationNumber: 9445434,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU814326',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T16:14:42.762Z'
        }
      },
      sort: [
        1591389598083
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH4FS220750070092020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:39:53.284Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22391.00',
          mileage: '59061'
        },
        sellArea: '800',
        dealerCd: '07009',
        certificationNumber: 9497828,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH4FS220750',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:31:07.141Z'
        }
      },
      sort: [
        1591389593284
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW665500070092020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:38:22.383Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20604.50',
          mileage: '35861'
        },
        sellArea: '800',
        dealerCd: '07009',
        certificationNumber: 9466903,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW665500',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T15:41:51.751Z'
        }
      },
      sort: [
        1591389502383
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS423100311322020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:36:45.541Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '26900.00',
          mileage: '28129'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9469715,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS423100',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T14:28:08.049Z'
        }
      },
      sort: [
        1591389405541
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F16HX615770220472020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:36:07.621Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '37000.00',
          mileage: '23177'
        },
        dealerCd: '22047',
        certificationNumber: 9379971,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F16HX615770',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8378',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-13T19:40:28.717Z'
        }
      },
      sort: [
        1591389367621
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV6JD235750220472020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:34:38.721Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24599.00',
          mileage: '18637'
        },
        dealerCd: '22047',
        certificationNumber: 9384257,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV6JD235750',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T20:18:12.560Z'
        }
      },
      sort: [
        1591389278721
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFHXHS420320311322020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:31:25.379Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '30600.00',
          mileage: '23457'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9488982,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFHXHS420320',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T13:45:06.840Z'
        }
      },
      sort: [
        1591389085379
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU387871311762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:31:11.319Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20900.00',
          mileage: '12239'
        },
        sellArea: '160',
        dealerCd: '31176',
        certificationNumber: 9424753,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU387871',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T23:18:59.250Z'
        }
      },
      sort: [
        1591389071319
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMEWRFV7KD507582220542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:29:07.997Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33419.00',
          mileage: '21684'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9486979,
        makeCode: 'TOYOTA',
        vin: 'JTMEWRFV7KD507582',
        model: {
          modelDescription: 'RAV4 HYBRID XSE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:55:39.348Z'
        }
      },
      sort: [
        1591388947997
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1FK1HU584359311322020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:26:40.216Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '30553'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9488992,
        makeCode: 'TOYOTA',
        vin: '4T1BK1FK1HU584359',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2554',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T13:49:43.479Z'
        }
      },
      sort: [
        1591388800216
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ118936201262020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:26:36.176Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19997.08',
          mileage: '30572'
        },
        sellArea: '170',
        dealerCd: '20126',
        certificationNumber: 9500001,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ118936',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:21:37.278Z'
        }
      },
      sort: [
        1591388796176
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK2LU871363042822020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:25:27.495Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22998.00',
          mileage: '7195'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9467598,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK2LU871363',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T21:51:32.733Z'
        }
      },
      sort: [
        1591388727495
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4GP417073044212020-05-25V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:24:54.867Z',
          retailDate: '2020-05-25',
          rdrStatus: 'V',
          soldAmount: '15955.00',
          mileage: '26934'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9424207,
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'COROLLA LE PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1854',
          modelYear: 2016
        },
        vin: '5YFBURHE4GP417073',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T19:20:07.711Z'
        }
      },
      sort: [
        1591388694867
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV0HD093043047532020-04-29V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:23:35.967Z',
          retailDate: '2020-04-29',
          rdrStatus: 'V',
          soldAmount: '17888.00',
          mileage: '82181'
        },
        sellArea: '120',
        dealerCd: '04753',
        certificationNumber: 9407889,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV0HD093043',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T01:12:52.380Z'
        }
      },
      sort: [
        1591388615967
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB34G1134360047532020-04-11V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:22:40.940Z',
          retailDate: '2020-04-11',
          rdrStatus: 'V',
          soldAmount: '15999.00',
          mileage: '12136'
        },
        sellArea: '120',
        dealerCd: '04753',
        certificationNumber: 9423182,
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2016
        },
        vin: 'JTDKDTB34G1134360',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T23:44:51.320Z'
        }
      },
      sort: [
        1591388560940
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC4HS186277311352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:21:32.937Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24800.00',
          mileage: '20786'
        },
        sellArea: '160',
        dealerCd: '31135',
        certificationNumber: 9463389,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC4HS186277',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T19:58:55.043Z'
        }
      },
      sort: [
        1591388492937
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN9HM040164041502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:16:29.855Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '17054'
        },
        sellArea: '110',
        dealerCd: '04150',
        certificationNumber: 9447257,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN9HM040164',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T16:20:09.571Z'
        }
      },
      sort: [
        1591388189855
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV8HJ103206041502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:15:51.555Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '14409'
        },
        sellArea: '120',
        dealerCd: '04150',
        certificationNumber: 9496398,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV8HJ103206',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:18:24.772Z'
        }
      },
      sort: [
        1591388151555
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F19KX846578311322020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:15:09.335Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '37900.00',
          mileage: '15629'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9487043,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F19KX846578',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:23:59.468Z'
        }
      },
      sort: [
        1591388109335
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREVXJJ191164041502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:15:03.315Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20873.14',
          mileage: '17925'
        },
        sellArea: '110',
        dealerCd: '04150',
        certificationNumber: 9410633,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREVXJJ191164',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T00:03:54.783Z'
        }
      },
      sort: [
        1591388103315
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK6FU147139220542020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:14:01.394Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17299.00',
          mileage: '26818'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9487018,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK6FU147139',
        model: {
          modelDescription: 'CAMRY HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:14:17.246Z'
        }
      },
      sort: [
        1591388041394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRY5F11EX168886020362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:11:30.513Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21994.00',
          mileage: '78073'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9497584,
        makeCode: 'TOYOTA',
        vin: '5TFRY5F11EX168886',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DBL CAB 5.7L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8241',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:12:47.729Z'
        }
      },
      sort: [
        1591387890513
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU237186041872020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:10:53.114Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21988.00',
          mileage: '21522'
        },
        sellArea: '600',
        dealerCd: '04187',
        certificationNumber: 9495689,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU237186',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:09:48.372Z'
        }
      },
      sort: [
        1591387853114
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXEU403758200962020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:10:39.594Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '12495.00',
          mileage: '54124'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9499945,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXEU403758',
        model: {
          modelDescription: 'CAMRY 2014.5 LE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T20:04:08.649Z'
        }
      },
      sort: [
        1591387839594
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV4HW377383410702020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:09:43.253Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24787.00',
          mileage: '37202'
        },
        sellArea: '600',
        dealerCd: '41070',
        certificationNumber: 9448934,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV4HW377383',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T19:07:08.595Z'
        }
      },
      sort: [
        1591387783253
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN8HM017585311802020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:09:09.132Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28911.00',
          mileage: '43564'
        },
        sellArea: '170',
        dealerCd: '31180',
        certificationNumber: 9468443,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN8HM017585',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T16:08:34.265Z'
        }
      },
      sort: [
        1591387749132
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH4GS244438311322020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:08:00.987Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '19800.00',
          mileage: '63750'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9418432,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH4GS244438',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T15:29:15.390Z'
        }
      },
      sort: [
        1591387680987
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP649666040132020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:07:19.785Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15292.00',
          mileage: '28893'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9495284,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP649666',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:28:08.176Z'
        }
      },
      sort: [
        1591387639785
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW620157220542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:04:34.651Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18330.00',
          mileage: '30958'
        },
        sellArea: '210',
        dealerCd: '22054',
        certificationNumber: 9489382,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW620157',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:03:49.549Z'
        }
      },
      sort: [
        1591387474651
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS370674341292020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:04:03.993Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '25250.00',
          mileage: '45700'
        },
        sellArea: '220',
        dealerCd: '34129',
        certificationNumber: 9497188,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS370674',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:41:41.961Z'
        }
      },
      sort: [
        1591387443993
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7GM039055042222020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:03:14.511Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '30991.00',
          mileage: '50668'
        },
        sellArea: '120',
        dealerCd: '04222',
        certificationNumber: 9499236,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7GM039055',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:09:24.366Z'
        }
      },
      sort: [
        1591387394511
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV5HD121754040132020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:02:15.790Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19648.00',
          mileage: '58303'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9495291,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV5HD121754',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:31:13.636Z'
        }
      },
      sort: [
        1591387335790
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HJ149963201142020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:01:59.811Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '39749'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9479016,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HJ149963',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T15:07:56.336Z'
        }
      },
      sort: [
        1591387319811
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP9H3041994201142020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:01:17.949Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22800.00',
          mileage: '8821'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9487630,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP9H3041994',
        model: {
          modelDescription: 'PRIUS PRIME ADVANCED',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1239',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:58:40.448Z'
        }
      },
      sort: [
        1591387277949
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS397906201142020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:00:36.270Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29500.00',
          mileage: '33429'
        },
        sellArea: '160',
        dealerCd: '20114',
        certificationNumber: 9425344,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS397906',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T15:23:03.907Z'
        }
      },
      sort: [
        1591387236270
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9H5417120311192020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T20:00:09.510Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27988.00',
          mileage: '25467'
        },
        sellArea: '160',
        dealerCd: '31119',
        certificationNumber: 9411099,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9H5417120',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T14:55:42.943Z'
        }
      },
      sort: [
        1591387209510
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU742460043562020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:59:49.339Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19486.37',
          mileage: '37015'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9431167,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU742460',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T21:12:13.327Z'
        }
      },
      sort: [
        1591387189339
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU436574201142020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:59:21.929Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15800.00',
          mileage: '35680'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9479018,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU436574',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T15:09:21.460Z'
        }
      },
      sort: [
        1591387161929
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU755320045542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:58:30.359Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22485.00',
          mileage: '28373'
        },
        sellArea: '500',
        dealerCd: '04554',
        certificationNumber: 9489567,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU755320',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:58:24.853Z'
        }
      },
      sort: [
        1591387110359
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HJ150362201142020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:56:46.301Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18875.00',
          mileage: '10804'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9481534,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HJ150362',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:30:43.833Z'
        }
      },
      sort: [
        1591387006301
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0K5694195110352020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:56:32.919Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '35945.00',
          mileage: '14028'
        },
        sellArea: '150',
        dealerCd: '11035',
        certificationNumber: 9467052,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0K5694195',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T16:42:21.078Z'
        }
      },
      sort: [
        1591386992919
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU102101311192020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:56:22.520Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15998.00',
          mileage: '44004'
        },
        sellArea: '500',
        dealerCd: '31119',
        certificationNumber: 9432774,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU102101',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T14:08:36.056Z'
        }
      },
      sort: [
        1591386982520
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2GJ066859201142020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:56:03.099Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '25651'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9482040,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2GJ066859',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:58:32.071Z'
        }
      },
      sort: [
        1591386963099
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS509607201142020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:55:09.577Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23500.00',
          mileage: '70025'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9488926,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS509607',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T12:33:12.036Z'
        }
      },
      sort: [
        1591386909577
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KC006941311192020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:54:37.960Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '11438'
        },
        sellArea: '500',
        dealerCd: '31119',
        certificationNumber: 9423458,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KC006941',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T12:20:41.920Z'
        }
      },
      sort: [
        1591386877960
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN9HX070802201142020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:53:00.079Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26075.00',
          mileage: '29448'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9487625,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN9HX070802',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:56:41.412Z'
        }
      },
      sort: [
        1591386780079
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC908213050302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:52:37.659Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15400.00',
          mileage: '30806'
        },
        sellArea: '150',
        dealerCd: '05030',
        certificationNumber: 9499859,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC908213',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:27:50.624Z'
        }
      },
      sort: [
        1591386757659
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS382685201142020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:52:14.979Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28600.00',
          mileage: '35351'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9408345,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS382685',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T15:38:21.836Z'
        }
      },
      sort: [
        1591386734979
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0JW775409042222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:52:00.837Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '23591.00',
          mileage: '20787'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9485528,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0JW775409',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:13:18.716Z'
        }
      },
      sort: [
        1591386720837
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5JS491627311832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:51:33.858Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26693.0000',
          mileage: '49888'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9411521,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5JS491627',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T18:53:02.135Z'
        }
      },
      sort: [
        1591386693858
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS418857311832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:51:31.278Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25994.00',
          mileage: '55178'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9408243,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS418857',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T14:24:36.481Z'
        }
      },
      sort: [
        1591386691278
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS414326371182020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:51:18.158Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24990.00',
          mileage: '48235'
        },
        sellArea: '800',
        dealerCd: '37118',
        certificationNumber: 9493587,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS414326',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T12:31:17.864Z'
        }
      },
      sort: [
        1591386678158
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU776283311192020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:51:05.356Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19495.00',
          mileage: '27539'
        },
        sellArea: '600',
        dealerCd: '31119',
        certificationNumber: 9482298,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU776283',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:12:55.983Z'
        }
      },
      sort: [
        1591386665356
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU751958201142020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:50:32.977Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '34057'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9421975,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU751958',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T11:21:54.766Z'
        }
      },
      sort: [
        1591386632977
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP659284040722020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:49:08.616Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16495.00',
          mileage: '17683'
        },
        sellArea: '120',
        dealerCd: '04072',
        certificationNumber: 9473622,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP659284',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T22:52:53.574Z'
        }
      },
      sort: [
        1591386548616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8HW625270201142020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:46:37.235Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '22109'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9475624,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8HW625270',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T12:24:50.172Z'
        }
      },
      sort: [
        1591386397235
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC4HS160016130492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:45:53.655Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23980.00',
          mileage: '73329'
        },
        certificationNumber: 9374727,
        dealerCd: '13049',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'SIENNA XLE PREM AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5377',
          modelYear: 2017
        },
        vin: '5TDDZ3DC4HS160016',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-10T17:11:15.603Z'
        }
      },
      sort: [
        1591386353655
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS404998130492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:44:24.352Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29399.00',
          mileage: '30413'
        },
        sellArea: '220',
        dealerCd: '13049',
        certificationNumber: 9412733,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS404998',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T22:22:48.912Z'
        }
      },
      sort: [
        1591386264352
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP651045040772020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:43:29.855Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16403.04',
          mileage: '11354'
        },
        sellArea: '120',
        dealerCd: '04077',
        certificationNumber: 9467394,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP651045',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T19:46:22.507Z'
        }
      },
      sort: [
        1591386209855
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC2LS024466130492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:42:45.054Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25980.00',
          mileage: '22429'
        },
        sellArea: '600',
        dealerCd: '13049',
        certificationNumber: 9458349,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC2LS024466',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T13:18:25.595Z'
        }
      },
      sort: [
        1591386165054
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB8JU270134440222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:42:40.255Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22302.00',
          mileage: '10340'
        },
        sellArea: '170',
        certificationNumber: 9463992,
        dealerCd: '44022',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'AVALON 4-DR XLE PREMIUM',
          modelSeries: 'AVALON',
          modelNumber: '3546',
          modelYear: 2018
        },
        vin: '4T1BK1EB8JU270134',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T11:26:56.750Z'
        }
      },
      sort: [
        1591386160255
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3GM026772440222020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:41:19.753Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '73369'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9474117,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3GM026772',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T12:57:05.991Z'
        }
      },
      sort: [
        1591386079753
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN1HX091174440222020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:40:14.532Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '28500.00',
          mileage: '14159'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9482051,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN1HX091174',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:01:40.176Z'
        }
      },
      sort: [
        1591386014532
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN2KX201354440222020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:39:35.410Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '7624'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9452906,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN2KX201354',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T11:32:19.392Z'
        }
      },
      sort: [
        1591385975410
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP639108040772020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:39:30.152Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '14476.00',
          mileage: '11872'
        },
        sellArea: '110',
        dealerCd: '04077',
        certificationNumber: 9484468,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP639108',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:31:16.034Z'
        }
      },
      sort: [
        1591385970152
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW629251440222020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:38:46.830Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '14557'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9493733,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW629251',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:03:27.713Z'
        }
      },
      sort: [
        1591385926830
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3FU934995220382020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:37:34.889Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '12500.00',
          mileage: '51865'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9497306,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3FU934995',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:28:19.848Z'
        }
      },
      sort: [
        1591385854889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV9FW186608130492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:36:54.149Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15799.00',
          mileage: '45415'
        },
        sellArea: '210',
        dealerCd: '13049',
        certificationNumber: 9472633,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV9FW186608',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T15:49:30.991Z'
        }
      },
      sort: [
        1591385814149
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS402970050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:35:31.689Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30981.0000',
          mileage: '15639'
        },
        sellArea: '230',
        dealerCd: '05067',
        certificationNumber: 9496416,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS402970',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:46:06.226Z'
        }
      },
      sort: [
        1591385731689
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1K5629033050672020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:35:29.008Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '35799.0000',
          mileage: '24812'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9476810,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1K5629033',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T23:59:23.534Z'
        }
      },
      sort: [
        1591385729008
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAEXLJ058020130492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:35:28.082Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14995.00',
          mileage: '28799'
        },
        sellArea: '210',
        dealerCd: '13049',
        certificationNumber: 9486078,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAEXLJ058020',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:43:45.454Z'
        }
      },
      sort: [
        1591385728082
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW641823050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:35:26.142Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19980.0300',
          mileage: '34565'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9487438,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW641823',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:46:32.454Z'
        }
      },
      sort: [
        1591385726142
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0J5550242050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:35:23.687Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '32481.0000',
          mileage: '22467'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9498326,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0J5550242',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T23:14:51.932Z'
        }
      },
      sort: [
        1591385723687
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5J5507547050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:35:21.229Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '35981.0000',
          mileage: '32975'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9498053,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5J5507547',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:30:09.922Z'
        }
      },
      sort: [
        1591385721229
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN4GX029691220382020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:35:15.550Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '55780'
        },
        sellArea: '220',
        dealerCd: '22038',
        certificationNumber: 9467341,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN4GX029691',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T19:16:24.829Z'
        }
      },
      sort: [
        1591385715550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ147385140152020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:35:10.888Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '27830'
        },
        sellArea: '210',
        dealerCd: '14015',
        certificationNumber: 9474775,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ147385',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:48:56.335Z'
        }
      },
      sort: [
        1591385710888
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T3BK3BB9FU115669050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:34:54.128Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19481.0000',
          mileage: '52628'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9493192,
        makeCode: 'TOYOTA',
        vin: '4T3BK3BB9FU115669',
        model: {
          modelDescription: 'VENZA Limited V6 AWD',
          modelSeries: 'VENZA',
          modelNumber: '2846',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:23:07.087Z'
        }
      },
      sort: [
        1591385694128
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7HJ704721050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:34:51.548Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18280.2800',
          mileage: '29525'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9494483,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7HJ704721',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:29:37.771Z'
        }
      },
      sort: [
        1591385691548
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HJ138799050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:34:48.849Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20479.6200',
          mileage: '31729'
        },
        sellArea: '210',
        dealerCd: '05067',
        certificationNumber: 9494613,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HJ138799',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:09:31.998Z'
        }
      },
      sort: [
        1591385688849
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5ANXHM120374050672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:34:46.388Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29981.00',
          mileage: '32737'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9498062,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5ANXHM120374',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:34:34.333Z'
        }
      },
      sort: [
        1591385686388
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU152843220382020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:34:29.148Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20790.00',
          mileage: '22274'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9411710,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU152843',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T20:47:18.398Z'
        }
      },
      sort: [
        1591385669148
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE9LP032732042822020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:34:02.188Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '14156'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9471410,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE9LP032732',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T20:09:47.027Z'
        }
      },
      sort: [
        1591385642188
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6GM037250220382020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:33:47.467Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26995.00',
          mileage: '62063'
        },
        sellArea: '230',
        dealerCd: '22038',
        certificationNumber: 9475803,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6GM037250',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T14:45:40.099Z'
        }
      },
      sort: [
        1591385627467
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DCXFS595867370942020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:32:48.048Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21089.00',
          mileage: '73096'
        },
        sellArea: '800',
        dealerCd: '37094',
        certificationNumber: 9400450,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DCXFS595867',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T13:21:10.365Z'
        }
      },
      sort: [
        1591385568048
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3KW049968220382020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:32:44.160Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25495.00',
          mileage: '11803'
        },
        sellArea: '150',
        dealerCd: '22038',
        certificationNumber: 9460825,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3KW049968',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:23:29.056Z'
        }
      },
      sort: [
        1591385564160
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMW1RFV1KD018369130702020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:32:17.540Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27847.00',
          mileage: '11882'
        },
        sellArea: '500',
        dealerCd: '13070',
        certificationNumber: 9499852,
        makeCode: 'TOYOTA',
        vin: 'JTMW1RFV1KD018369',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T19:26:48.842Z'
        }
      },
      sort: [
        1591385537540
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0HS829447371482020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:29:16.138Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24999.00',
          mileage: '52792'
        },
        dealerCd: '37148',
        certificationNumber: 9350006,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC0HS829447',
        model: {
          modelDescription: 'SIENNA LTD PREM FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5357',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-23T01:21:34.094Z'
        }
      },
      sort: [
        1591385356138
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN5GX015178371482020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:28:33.460Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '28499.00',
          mileage: '15571'
        },
        sellArea: '170',
        dealerCd: '37148',
        certificationNumber: 9409913,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN5GX015178',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7559',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T17:27:20.414Z'
        }
      },
      sort: [
        1591385313460
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV5GW498763200932020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:28:11.419Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19911.00',
          mileage: '20430'
        },
        sellArea: '170',
        dealerCd: '20093',
        certificationNumber: 9410976,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV5GW498763',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T13:38:21.365Z'
        }
      },
      sort: [
        1591385291419
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFVXKC032655310882020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:27:49.879Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '3171'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9445002,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFVXKC032655',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-16T13:48:09.922Z'
        }
      },
      sort: [
        1591385269879
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW643007160052020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:27:08.118Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21627.00',
          mileage: '20283'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9488073,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW643007',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:24:30.356Z'
        }
      },
      sort: [
        1591385228118
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU733895042822020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:26:04.519Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23998.00',
          mileage: '35535'
        },
        sellArea: '500',
        dealerCd: '04282',
        certificationNumber: 9415518,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU733895',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T18:34:25.828Z'
        }
      },
      sort: [
        1591385164519
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW546335200932020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:25:42.538Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20611.00',
          mileage: '16722'
        },
        sellArea: '170',
        dealerCd: '20093',
        certificationNumber: 9410990,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW546335',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T13:58:24.332Z'
        }
      },
      sort: [
        1591385142538
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV7HJ709834311912020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:24:41.658Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22999.00',
          mileage: '13742'
        },
        sellArea: '160',
        dealerCd: '31191',
        certificationNumber: 9422426,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV7HJ709834',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T16:33:24.779Z'
        }
      },
      sort: [
        1591385081658
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9GM032592121122020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:24:15.775Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26950.00',
          mileage: '68720'
        },
        sellArea: '210',
        dealerCd: '12112',
        certificationNumber: 9494683,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9GM032592',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:55:16.490Z'
        }
      },
      sort: [
        1591385055775
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KC010739200932020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:23:55.678Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26700.00',
          mileage: '14065'
        },
        sellArea: '500',
        dealerCd: '20093',
        certificationNumber: 9410982,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KC010739',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T13:51:40.748Z'
        }
      },
      sort: [
        1591385035678
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV7HJ134443042822020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:23:19.218Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '35614'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9469203,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV7HJ134443',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T22:12:09.680Z'
        }
      },
      sort: [
        1591384999218
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1HK3KU509646290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:23:12.978Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28154.00',
          mileage: '14652'
        },
        sellArea: '190',
        dealerCd: '29025',
        certificationNumber: 9449998,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1HK3KU509646',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T15:04:33.998Z'
        }
      },
      sort: [
        1591384992978
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC848042200932020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:22:30.096Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13714.00',
          mileage: '29876'
        },
        sellArea: '170',
        dealerCd: '20093',
        certificationNumber: 9487948,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC848042',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:50:24.438Z'
        }
      },
      sort: [
        1591384950096
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS398282290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:21:54.278Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '16848'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9467260,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS398282',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:21:28.735Z'
        }
      },
      sort: [
        1591384914278
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV6HJ124071160052020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:21:12.437Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21872.50',
          mileage: '8857'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9494310,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV6HJ124071',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:20:02.298Z'
        }
      },
      sort: [
        1591384872437
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU9H3564077042822020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:20:46.076Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18998.00',
          mileage: '60174'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9457700,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU9H3564077',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T18:28:48.726Z'
        }
      },
      sort: [
        1591384846076
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5G5383899290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:20:42.838Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30900.00',
          mileage: '23446'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9438498,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5G5383899',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T13:55:16.351Z'
        }
      },
      sort: [
        1591384842838
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ117724290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:18:55.397Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '34845'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9491757,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ117724',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:58:21.279Z'
        }
      },
      sort: [
        1591384735397
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU441310270242020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:17:31.336Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18210.10',
          mileage: '32704'
        },
        sellArea: '150',
        dealerCd: '27024',
        certificationNumber: 9485337,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU441310',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:53:47.230Z'
        }
      },
      sort: [
        1591384651336
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE1LP080886290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:17:10.235Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15400.00',
          mileage: '5459'
        },
        sellArea: '500',
        dealerCd: '29025',
        certificationNumber: 9462089,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE1LP080886',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T18:50:17.799Z'
        }
      },
      sort: [
        1591384630235
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU021570040132020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:13:35.684Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '18992.00',
          mileage: '39922'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483219,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU021570',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:26:20.073Z'
        }
      },
      sort: [
        1591384415684
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS391050371852020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:13:25.394Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28942.00',
          mileage: '31051'
        },
        sellArea: '160',
        dealerCd: '37185',
        certificationNumber: 9403080,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS391050',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T18:18:17.738Z'
        }
      },
      sort: [
        1591384405394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9HW600953311322020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:12:49.755Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '22605.00',
          mileage: '34963'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9409628,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9HW600953',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T14:26:01.920Z'
        }
      },
      sort: [
        1591384369755
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5J5597525371852020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:12:28.452Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '34864.50',
          mileage: '24352'
        },
        sellArea: '800',
        dealerCd: '37185',
        certificationNumber: 9407289,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5J5597525',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T19:11:46.202Z'
        }
      },
      sort: [
        1591384348452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV7HW395599040772020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:12:09.434Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23471.00',
          mileage: '31999'
        },
        sellArea: '190',
        dealerCd: '04077',
        certificationNumber: 9418918,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV7HW395599',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T19:11:45.349Z'
        }
      },
      sort: [
        1591384329434
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE0LP015326041812020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:11:20.534Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18800.00',
          mileage: '12447'
        },
        sellArea: '600',
        dealerCd: '04181',
        certificationNumber: 9461499,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE0LP015326',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T22:29:17.137Z'
        }
      },
      sort: [
        1591384280534
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU436633046382020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:11:17.434Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '15495.00',
          mileage: '33266'
        },
        sellArea: '110',
        certificationNumber: 9472952,
        dealerCd: '04638',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        vin: '4T1BF1FK6HU436633',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T17:56:14.063Z'
        }
      },
      sort: [
        1591384277434
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU719571040772020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:11:09.112Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16976.00',
          mileage: '30666'
        },
        sellArea: '120',
        dealerCd: '04077',
        certificationNumber: 9476430,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU719571',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:43:45.421Z'
        }
      },
      sort: [
        1591384269112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2HW637476371852020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:10:59.852Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19400.00',
          mileage: '27121'
        },
        sellArea: '800',
        dealerCd: '37185',
        certificationNumber: 9476076,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2HW637476',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T17:25:35.601Z'
        }
      },
      sort: [
        1591384259852
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV0HW376528046382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:10:49.534Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19495.00',
          mileage: '28228'
        },
        sellArea: '110',
        dealerCd: '04638',
        certificationNumber: 9463517,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV0HW376528',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T20:30:36.844Z'
        }
      },
      sort: [
        1591384249534
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU271867041812020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:10:25.313Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19800.00',
          mileage: '16945'
        },
        sellArea: '150',
        dealerCd: '04181',
        certificationNumber: 9480912,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU271867',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T20:52:37.012Z'
        }
      },
      sort: [
        1591384225313
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU4G3014994311952020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:10:08.853Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '17468.00',
          mileage: '24706'
        },
        sellArea: '160',
        dealerCd: '31195',
        certificationNumber: 9409901,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU4G3014994',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T17:15:12.170Z'
        }
      },
      sort: [
        1591384208853
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KC014743041812020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:09:56.254Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25500.00',
          mileage: '20875'
        },
        sellArea: '150',
        dealerCd: '04181',
        certificationNumber: 9427597,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KC014743',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T20:40:24.013Z'
        }
      },
      sort: [
        1591384196254
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV0HW360259040772020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:09:46.252Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17976.00',
          mileage: '26583'
        },
        sellArea: '120',
        dealerCd: '04077',
        certificationNumber: 9484457,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV0HW360259',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:28:22.556Z'
        }
      },
      sort: [
        1591384186252
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFHXHS208752040132020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:09:23.314Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '28852.30',
          mileage: '25563'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483154,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFHXHS208752',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:06:05.332Z'
        }
      },
      sort: [
        1591384163314
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU275309041812020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:08:45.274Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18600.00',
          mileage: '16948'
        },
        sellArea: '150',
        dealerCd: '04181',
        certificationNumber: 9480923,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU275309',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T21:06:20.266Z'
        }
      },
      sort: [
        1591384125274
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9FU070163040772020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:08:30.913Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '14976.00',
          mileage: '65764'
        },
        sellArea: '120',
        dealerCd: '04077',
        certificationNumber: 9461548,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9FU070163',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T23:15:48.598Z'
        }
      },
      sort: [
        1591384110913
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW631622311952020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:06:18.971Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '18550.00',
          mileage: '27169'
        },
        sellArea: '160',
        dealerCd: '31195',
        certificationNumber: 9409892,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW631622',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T17:09:43.685Z'
        }
      },
      sort: [
        1591383978971
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH3FS172940041872020-02-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:05:02.231Z',
          retailDate: '2020-02-27',
          rdrStatus: 'H',
          soldAmount: '23451.00',
          mileage: '58211'
        },
        sellArea: '500',
        dealerCd: '04187',
        certificationNumber: 9499733,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH3FS172940',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:48:55.760Z'
        }
      },
      sort: [
        1591383902231
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS407444470212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:04:31.311Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28536.00',
          mileage: '34504'
        },
        sellArea: '160',
        dealerCd: '47021',
        certificationNumber: 9494660,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS407444',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:38:11.286Z'
        }
      },
      sort: [
        1591383871311
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU382326042012020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:03:38.251Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15391.57',
          mileage: '32723'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9485473,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU382326',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:01:28.327Z'
        }
      },
      sort: [
        1591383818251
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ129402291132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:02:30.230Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '16538'
        },
        dealerCd: '29113',
        certificationNumber: 9383664,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ129402',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T16:00:49.762Z'
        }
      },
      sort: [
        1591383750230
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC808482311952020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:01:57.952Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13998.00',
          mileage: '34133'
        },
        sellArea: '160',
        dealerCd: '31195',
        certificationNumber: 9451088,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC808482',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T15:21:16.835Z'
        }
      },
      sort: [
        1591383717952
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW573982291072020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:01:40.590Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26805.00',
          mileage: '21029'
        },
        sellArea: '160',
        dealerCd: '29107',
        certificationNumber: 9496018,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW573982',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:55:23.717Z'
        }
      },
      sort: [
        1591383700590
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU8H3543866042012020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:01:33.550Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20300.00',
          mileage: '27649'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9444266,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU8H3543866',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T15:54:28.289Z'
        }
      },
      sort: [
        1591383693550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFFY5F18JX238963040132020-04-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:01:23.710Z',
          retailDate: '2020-04-30',
          rdrStatus: 'H',
          soldAmount: '36192.00',
          mileage: '25695'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9499768,
        makeCode: 'TOYOTA',
        vin: '5TFFY5F18JX238963',
        model: {
          modelDescription: 'TUNDRA 4X2 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8272',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:56:44.666Z'
        }
      },
      sort: [
        1591383683710
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU023843291132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:01:16.927Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '14879'
        },
        sellArea: '160',
        dealerCd: '29113',
        certificationNumber: 9495505,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXJU023843',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:55:19.624Z'
        }
      },
      sort: [
        1591383676927
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6HP623156371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T19:00:14.910Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '17006'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9499689,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6HP623156',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:34:06.155Z'
        }
      },
      sort: [
        1591383614910
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC867758371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:59:03.269Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '25439'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9499693,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC867758',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:36:14.212Z'
        }
      },
      sort: [
        1591383543269
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0HD152071291072020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:59:00.429Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30278.63',
          mileage: '36890'
        },
        sellArea: '160',
        dealerCd: '29107',
        certificationNumber: 9496077,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0HD152071',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:20:03.936Z'
        }
      },
      sort: [
        1591383540429
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC799615371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:56:56.268Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '13999.00',
          mileage: '29053'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9499700,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC799615',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:38:10.574Z'
        }
      },
      sort: [
        1591383416268
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU309366291072020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:56:35.155Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '17637.00',
          mileage: '34357'
        },
        sellArea: '160',
        dealerCd: '29107',
        certificationNumber: 9468931,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU309366',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:41:35.027Z'
        }
      },
      sort: [
        1591383395155
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC847207371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:55:10.807Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '26019'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9499704,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC847207',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:39:34.293Z'
        }
      },
      sort: [
        1591383310807
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5JW743121310392020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:54:23.625Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '30578'
        },
        sellArea: '160',
        certificationNumber: 9491880,
        dealerCd: '31039',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        vin: '2T3RFREV5JW743121',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:32:43.395Z'
        }
      },
      sort: [
        1591383263625
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP0H3000556046382020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:53:49.104Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '20463.00',
          mileage: '37037'
        },
        sellArea: '110',
        dealerCd: '04638',
        certificationNumber: 9463511,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP0H3000556',
        model: {
          modelDescription: 'PRIUS PRIME ADVANCED',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1239',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T20:28:01.965Z'
        }
      },
      sort: [
        1591383229104
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU700770291072020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:53:28.610Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18039.00',
          mileage: '9249'
        },
        sellArea: '160',
        dealerCd: '29107',
        certificationNumber: 9422717,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU700770',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T18:52:59.791Z'
        }
      },
      sort: [
        1591383208610
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBY5F10HX630327060402020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:53:06.904Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '33075.00',
          mileage: '34433'
        },
        sellArea: '160',
        dealerCd: '06040',
        certificationNumber: 9429000,
        makeCode: 'TOYOTA',
        vin: '5TFBY5F10HX630327',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8352',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T19:57:23.974Z'
        }
      },
      sort: [
        1591383186904
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR6G5132503046382020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:52:46.523Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '28495.00',
          mileage: '53448'
        },
        sellArea: '110',
        dealerCd: '04638',
        certificationNumber: 9478331,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR6G5132503',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8646',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:00:47.277Z'
        }
      },
      sort: [
        1591383166523
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KW044025371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:52:00.805Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '15055'
        },
        sellArea: '500',
        dealerCd: '37176',
        certificationNumber: 9499721,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KW044025',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:44:19.817Z'
        }
      },
      sort: [
        1591383120805
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU348806046382020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:51:57.871Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17494.00',
          mileage: '40526'
        },
        sellArea: '110',
        dealerCd: '04638',
        certificationNumber: 9499739,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU348806',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:50:02.921Z'
        }
      },
      sort: [
        1591383117871
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN0HX053810371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:50:53.743Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24559.00',
          mileage: '47171'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9499716,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN0HX053810',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:41:14.838Z'
        }
      },
      sort: [
        1591383053743
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5JU049654041872020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:49:59.721Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19988.00',
          mileage: '39782'
        },
        sellArea: '110',
        dealerCd: '04187',
        certificationNumber: 9495605,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5JU049654',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:41:59.721Z'
        }
      },
      sort: [
        1591382999721
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEY5F13FX178156041872020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:48:44.509Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29988.00',
          mileage: '48178'
        },
        sellArea: '110',
        dealerCd: '04187',
        certificationNumber: 9485578,
        makeCode: 'TOYOTA',
        vin: '5TFEY5F13FX178156',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8261',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:25:40.181Z'
        }
      },
      sort: [
        1591382924509
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH8GS347633041872020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:47:06.753Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25499.00',
          mileage: '18694'
        },
        sellArea: '110',
        dealerCd: '04187',
        certificationNumber: 9498174,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH8GS347633',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:21:35.474Z'
        }
      },
      sort: [
        1591382826753
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2KS018305140442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:44:55.798Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29471.00',
          mileage: '41927'
        },
        sellArea: '600',
        dealerCd: '14044',
        certificationNumber: 9495550,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2KS018305',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:17:37.578Z'
        }
      },
      sort: [
        1591382695798
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BPRHE2EC016819311322020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:43:49.797Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '12000.00',
          mileage: '49236'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9499699,
        makeCode: 'TOYOTA',
        vin: '2T1BPRHE2EC016819',
        model: {
          modelDescription: 'COROLLA LE ECO PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1874',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:38:09.013Z'
        }
      },
      sort: [
        1591382629797
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW641818140442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:43:13.357Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28194.00',
          mileage: '31073'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9470758,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW641818',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T16:17:05.703Z'
        }
      },
      sort: [
        1591382593357
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXEU810480220302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:42:29.636Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13000.00',
          mileage: '53599'
        },
        sellArea: '210',
        certificationNumber: 9483325,
        dealerCd: '22030',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 2014.5 SE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2014
        },
        vin: '4T1BF1FKXEU810480',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:58:06.268Z'
        }
      },
      sort: [
        1591382549636
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV7JW835011450352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:42:27.377Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19033.35',
          mileage: '44487'
        },
        sellArea: '800',
        dealerCd: '45035',
        certificationNumber: 9499708,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV7JW835011',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:39:50.694Z'
        }
      },
      sort: [
        1591382547377
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KC015318460812020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:38:21.114Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26488.00',
          mileage: '12222'
        },
        dealerCd: '46081',
        certificationNumber: 9368733,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KC015318',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T23:32:30.330Z'
        }
      },
      sort: [
        1591382301114
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXKW023030046382020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:37:43.213Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25994.00',
          mileage: '9219'
        },
        sellArea: '120',
        dealerCd: '04638',
        certificationNumber: 9499690,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXKW023030',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:35:15.094Z'
        }
      },
      sort: [
        1591382263213
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV9HW613379130462020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:36:49.545Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21434.00',
          mileage: '43764'
        },
        sellArea: '220',
        dealerCd: '13046',
        certificationNumber: 9431734,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV9HW613379',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T15:00:09.908Z'
        }
      },
      sort: [
        1591382209545
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU9G3008589480532020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:36:23.912Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19850.00',
          mileage: '6982'
        },
        dealerCd: '48053',
        certificationNumber: 9242365,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU9G3008589',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-12T23:12:11.780Z'
        }
      },
      sort: [
        1591382183912
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK1KU199005020162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:35:51.831Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28311.00',
          mileage: '30001'
        },
        sellArea: '600',
        dealerCd: '02016',
        certificationNumber: 9499666,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK1KU199005',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:25:09.965Z'
        }
      },
      sort: [
        1591382151831
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH3JS484964480532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:33:33.249Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '33995.00',
          mileage: '29778'
        },
        dealerCd: '48053',
        certificationNumber: 9354120,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH3JS484964',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-25T20:42:32.321Z'
        }
      },
      sort: [
        1591382013249
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS441192311322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:32:00.828Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27800.00',
          mileage: '34896'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9469724,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS441192',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T14:34:00.037Z'
        }
      },
      sort: [
        1591381920828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMBZ5DN6HM009263046382020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:31:37.247Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29995.00',
          mileage: '25399'
        },
        sellArea: '110',
        dealerCd: '04638',
        certificationNumber: 9499673,
        makeCode: 'TOYOTA',
        vin: '3TMBZ5DN6HM009263',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DBL CAB LONG BED',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7172',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:27:55.030Z'
        }
      },
      sort: [
        1591381897247
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9LC069175410572020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:29:42.405Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27472.00',
          mileage: '7289'
        },
        sellArea: '500',
        dealerCd: '41057',
        certificationNumber: 9460140,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9LC069175',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:45:06.683Z'
        }
      },
      sort: [
        1591381782405
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4LC093125461052020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:24:41.425Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28930.89',
          mileage: '4175'
        },
        sellArea: '600',
        dealerCd: '46105',
        certificationNumber: 9490222,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4LC093125',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T01:26:33.075Z'
        }
      },
      sort: [
        1591381481425
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU774551480282020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:23:29.282Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15199.00',
          mileage: '60878'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9477903,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU774551',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:52:14.538Z'
        }
      },
      sort: [
        1591381409282
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3HP609604461052020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:22:31.563Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14966.07',
          mileage: '24301'
        },
        sellArea: '120',
        dealerCd: '46105',
        certificationNumber: 9488415,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3HP609604',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T22:33:39.814Z'
        }
      },
      sort: [
        1591381351563
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW642857110352020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:21:46.103Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20085.00',
          mileage: '20666'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9493609,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW642857',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T13:07:39.770Z'
        }
      },
      sort: [
        1591381306103
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV5FW207201020532020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:20:09.602Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16202.33',
          mileage: '63142'
        },
        sellArea: '500',
        dealerCd: '02053',
        certificationNumber: 9471210,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV5FW207201',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:50:58.297Z'
        }
      },
      sort: [
        1591381209602
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH7HS023802461052020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:20:08.498Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '36549.00',
          mileage: '34325'
        },
        sellArea: '130',
        dealerCd: '46105',
        certificationNumber: 9489923,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH7HS023802',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T21:12:37.145Z'
        }
      },
      sort: [
        1591381208498
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMBZ5DN4HM011061040962020-04-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:18:56.017Z',
          retailDate: '2020-04-26',
          rdrStatus: 'H',
          soldAmount: '33995.00',
          mileage: '15418'
        },
        sellArea: '110',
        dealerCd: '04096',
        certificationNumber: 9452811,
        makeCode: 'TOYOTA',
        vin: '3TMBZ5DN4HM011061',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DBL CAB LONG BED',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7172',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T00:52:15.644Z'
        }
      },
      sort: [
        1591381136017
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV2KW032610020372020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:18:44.577Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26895.00',
          mileage: '20528'
        },
        sellArea: '500',
        dealerCd: '02037',
        certificationNumber: 9484623,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV2KW032610',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T01:16:39.786Z'
        }
      },
      sort: [
        1591381124577
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC860733461052020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:17:35.486Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15967.00',
          mileage: '23500'
        },
        sellArea: '130',
        dealerCd: '46105',
        certificationNumber: 9488597,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC860733',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:37:35.747Z'
        }
      },
      sort: [
        1591381055486
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV6GJ064086020532020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:15:48.593Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22584.00',
          mileage: '58521'
        },
        sellArea: '150',
        dealerCd: '02053',
        certificationNumber: 9441938,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV6GJ064086',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T18:42:55.158Z'
        }
      },
      sort: [
        1591380948593
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU245172461052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:15:10.254Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19367.00',
          mileage: '21317'
        },
        sellArea: '150',
        dealerCd: '46105',
        certificationNumber: 9461063,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU245172',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T17:45:29.955Z'
        }
      },
      sort: [
        1591380910254
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU659700020532020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:14:52.432Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16037.00',
          mileage: '28941'
        },
        sellArea: '150',
        dealerCd: '02053',
        certificationNumber: 9492306,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU659700',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:29:35.379Z'
        }
      },
      sort: [
        1591380892432
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK9JU003397180362020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:14:07.012Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '26873'
        },
        sellArea: '500',
        dealerCd: '18036',
        certificationNumber: 9492018,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK9JU003397',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:04:48.888Z'
        }
      },
      sort: [
        1591380847012
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK0JU662802020532020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:14:01.852Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23270.00',
          mileage: '46435'
        },
        sellArea: '150',
        dealerCd: '02053',
        certificationNumber: 9422735,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK0JU662802',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T18:58:36.154Z'
        }
      },
      sort: [
        1591380841852
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1K5616248340892020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:13:18.031Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '32795.00',
          mileage: '42473'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9478017,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1K5616248',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:41:45.443Z'
        }
      },
      sort: [
        1591380798031
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU809417461052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:12:51.502Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16970.86',
          mileage: '16843'
        },
        sellArea: '150',
        dealerCd: '46105',
        certificationNumber: 9488636,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU809417',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T01:28:07.442Z'
        }
      },
      sort: [
        1591380771502
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5JW812240461052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:12:02.261Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27801.43',
          mileage: '14617'
        },
        sellArea: '130',
        dealerCd: '46105',
        certificationNumber: 9485694,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5JW812240',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:55:42.901Z'
        }
      },
      sort: [
        1591380722261
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KC020549311322020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:11:58.701Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '24300.00',
          mileage: '18248'
        },
        sellArea: '500',
        dealerCd: '31132',
        certificationNumber: 9442564,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KC020549',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T20:28:26.082Z'
        }
      },
      sort: [
        1591380718701
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE3LP103066040962020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:11:41.061Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '18131.44',
          mileage: '1879'
        },
        sellArea: '110',
        dealerCd: '04096',
        certificationNumber: 9460099,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE3LP103066',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:29:00.333Z'
        }
      },
      sort: [
        1591380701061
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJJREV5HD116549461052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:11:00.501Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27673.25',
          mileage: '31295'
        },
        sellArea: '130',
        dealerCd: '46105',
        certificationNumber: 9455692,
        makeCode: 'TOYOTA',
        vin: 'JTMJJREV5HD116549',
        model: {
          modelDescription: 'RAV4 HYBRID SE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T20:32:43.832Z'
        }
      },
      sort: [
        1591380660501
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV0KW031505201452020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:10:40.079Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21347.00',
          mileage: '28426'
        },
        sellArea: '500',
        dealerCd: '20145',
        certificationNumber: 9432193,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV0KW031505',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T19:16:20.528Z'
        }
      },
      sort: [
        1591380640079
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV5LC052488040962020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:10:26.800Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22890.00',
          mileage: '1910'
        },
        sellArea: '110',
        dealerCd: '04096',
        certificationNumber: 9454250,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV5LC052488',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T21:43:25.973Z'
        }
      },
      sort: [
        1591380626800
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV5JW437312041362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:10:14.341Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19089.00',
          mileage: '11548'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9499298,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV5JW437312',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:27:02.582Z'
        }
      },
      sort: [
        1591380614341
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP616618461052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:09:42.858Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14945.00',
          mileage: '31224'
        },
        sellArea: '130',
        dealerCd: '46105',
        certificationNumber: 9494730,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP616618',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:25:48.934Z'
        }
      },
      sort: [
        1591380582858
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU7H3046484040962020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:09:26.118Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19055.00',
          mileage: '23665'
        },
        sellArea: '110',
        dealerCd: '04096',
        certificationNumber: 9443999,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU7H3046484',
        model: {
          modelDescription: 'PRIUS ONE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1221',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T18:59:01.159Z'
        }
      },
      sort: [
        1591380566118
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE0LP090034040962020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:08:28.212Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15990.00',
          mileage: '3763'
        },
        sellArea: '110',
        dealerCd: '04096',
        certificationNumber: 9465073,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE0LP090034',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T23:35:16.152Z'
        }
      },
      sort: [
        1591380508212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU504928461052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:08:16.579Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18969.40',
          mileage: '39597'
        },
        sellArea: '130',
        certificationNumber: 9492373,
        dealerCd: '46105',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        vin: '4T1B11HK2JU504928',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:41:45.495Z'
        }
      },
      sort: [
        1591380496579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4G5396689450352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:08:11.960Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30555.00',
          mileage: '20501'
        },
        sellArea: '160',
        dealerCd: '45035',
        certificationNumber: 9499617,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4G5396689',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T18:06:05.194Z'
        }
      },
      sort: [
        1591380491960
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU196935270202020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:07:56.118Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22883.00',
          mileage: '28027'
        },
        sellArea: '500',
        certificationNumber: 9481051,
        dealerCd: '27020',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        vin: '4T1B11HK0KU196935',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T23:28:20.663Z'
        }
      },
      sort: [
        1591380476118
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK6LU918314040962020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:07:25.511Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23190.00',
          mileage: '1165'
        },
        sellArea: '110',
        dealerCd: '04096',
        certificationNumber: 9457007,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK6LU918314',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T20:51:10.607Z'
        }
      },
      sort: [
        1591380445511
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU655453270202020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:07:10.618Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13314.84',
          mileage: '53099'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9471938,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU655453',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T23:28:54.832Z'
        }
      },
      sort: [
        1591380430618
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6JU592687270202020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:06:28.436Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20488.00',
          mileage: '24446'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9492996,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6JU592687',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:22:52.510Z'
        }
      },
      sort: [
        1591380388436
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHW5F14HX670782160052020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:06:25.578Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '39450.00',
          mileage: '16531'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9445135,
        makeCode: 'TOYOTA',
        vin: '5TFHW5F14HX670782',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8374',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-16T17:49:49.489Z'
        }
      },
      sort: [
        1591380385578
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3C1RFV6KW051468043442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:05:52.098Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '32988.00',
          mileage: '6435'
        },
        sellArea: '110',
        dealerCd: '04344',
        certificationNumber: 9481530,
        makeCode: 'TOYOTA',
        vin: '2T3C1RFV6KW051468',
        model: {
          modelDescription: 'RAV4 XLE PREM FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4477',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:29:43.609Z'
        }
      },
      sort: [
        1591380352098
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRX5GN0HX091744041362020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:05:39.577Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '22189.00',
          mileage: '11105'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9451796,
        makeCode: 'TOYOTA',
        vin: '5TFRX5GN0HX091744',
        model: {
          modelDescription: 'TACOMA SR 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7162',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T23:54:57.530Z'
        }
      },
      sort: [
        1591380339577
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1L5741123270202020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:05:27.616Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '35410.00',
          mileage: '7528'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9486446,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1L5741123',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:12:54.133Z'
        }
      },
      sort: [
        1591380327616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX9K1018962270202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:04:37.156Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22283.00',
          mileage: '23244'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9471946,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX9K1018962',
        model: {
          modelDescription: 'C-HR LE',
          modelSeries: 'CHR',
          modelNumber: '2402',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T23:31:57.103Z'
        }
      },
      sort: [
        1591380277156
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F10JX680158270202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:03:42.116Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '41988.00',
          mileage: '21471'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9494838,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F10JX680158',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T00:42:10.839Z'
        }
      },
      sort: [
        1591380222116
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU0G3021988270202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T18:02:55.594Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15861.00',
          mileage: '56384'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9479768,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU0G3021988',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T23:15:17.593Z'
        }
      },
      sort: [
        1591380175594
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU2H3036526450502020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:57:49.272Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '34777'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9495127,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU2H3036526',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:42:01.151Z'
        }
      },
      sort: [
        1591379869272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F12FX437382160052020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:57:28.571Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28275.50',
          mileage: '61876'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9487743,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F12FX437382',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:43:24.546Z'
        }
      },
      sort: [
        1591379848571
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH9HS386452220302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:56:07.412Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29485.00',
          mileage: '44909'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9442334,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH9HS386452',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T16:04:30.737Z'
        }
      },
      sort: [
        1591379767412
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4K5624327160052020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:54:19.130Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '39000.00',
          mileage: '21309'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9473158,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4K5624327',
        model: {
          modelDescription: '4RUNNER 4X4 NIGHTSHADE V6',
          modelSeries: '4RUNNER',
          modelNumber: '8669',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:29:23.263Z'
        }
      },
      sort: [
        1591379659130
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU368253480282020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:51:36.476Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16199.00',
          mileage: '35902'
        },
        sellArea: '220',
        dealerCd: '48028',
        certificationNumber: 9474751,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU368253',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:35:44.211Z'
        }
      },
      sort: [
        1591379496476
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU767325121002020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:49:23.947Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '16194.80',
          mileage: '14327'
        },
        sellArea: '210',
        dealerCd: '12100',
        certificationNumber: 9467110,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU767325',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T17:09:07.495Z'
        }
      },
      sort: [
        1591379363947
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5EP011560040562020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:48:30.933Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '12999.00',
          mileage: '47932'
        },
        sellArea: '110',
        dealerCd: '04056',
        certificationNumber: 9489648,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5EP011560',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:40:34.711Z'
        }
      },
      sort: [
        1591379310933
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU236214041402020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:45:41.551Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19096.00',
          mileage: '11369'
        },
        sellArea: '120',
        dealerCd: '04140',
        certificationNumber: 9494108,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU236214',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:09:24.246Z'
        }
      },
      sort: [
        1591379141551
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8HM087665290992020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:44:56.470Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '29505.00',
          mileage: '9150'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9466162,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8HM087665',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T20:45:41.465Z'
        }
      },
      sort: [
        1591379096470
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC837302290992020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:44:16.890Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16578.00',
          mileage: '35120'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9429705,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC837302',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T15:44:23.121Z'
        }
      },
      sort: [
        1591379056890
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH6GS506763210952020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:43:58.350Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27490.00',
          mileage: '32633'
        },
        sellArea: '210',
        dealerCd: '21095',
        certificationNumber: 9422577,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH6GS506763',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T17:41:50.769Z'
        }
      },
      sort: [
        1591379038350
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0LS028798290992020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:43:27.929Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '31480.00',
          mileage: '21575'
        },
        sellArea: '500',
        certificationNumber: 9412596,
        dealerCd: '29099',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        vin: '5TDYZ3DC0LS028798',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T19:57:18.475Z'
        }
      },
      sort: [
        1591379007929
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DC6GS741389450502020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:43:27.683Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '53866'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9494201,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DC6GS741389',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:42:21.710Z'
        }
      },
      sort: [
        1591379007683
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU632231160052020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:43:12.383Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18635.00',
          mileage: '30684'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9477822,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU632231',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:20:27.446Z'
        }
      },
      sort: [
        1591378992383
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRY5F16JX232317150122020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:42:44.961Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '28900.00',
          mileage: '32873'
        },
        sellArea: '500',
        dealerCd: '15012',
        certificationNumber: 9444736,
        makeCode: 'TOYOTA',
        vin: '5TFRY5F16JX232317',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8241',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-15T16:12:21.503Z'
        }
      },
      sort: [
        1591378964961
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX0JR020460450502020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:42:43.081Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '29710'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9494193,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX0JR020460',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:39:42.451Z'
        }
      },
      sort: [
        1591378963081
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9JW819262290992020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:42:30.482Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '28830'
        },
        dealerCd: '29099',
        certificationNumber: 9334480,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9JW819262',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-13T18:55:13.566Z'
        }
      },
      sort: [
        1591378950482
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP620780450302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:42:29.070Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14350.00',
          mileage: '25018'
        },
        sellArea: '800',
        dealerCd: '45030',
        certificationNumber: 9481894,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP620780',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:05:43.622Z'
        }
      },
      sort: [
        1591378949070
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE7LJ025193310972020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:41:50.270Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25159.00',
          mileage: '9599'
        },
        sellArea: '500',
        dealerCd: '31097',
        certificationNumber: 9459877,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE7LJ025193',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:17:33.012Z'
        }
      },
      sort: [
        1591378910270
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU641792290992020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:41:46.600Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19941.00',
          mileage: '37067'
        },
        sellArea: '600',
        dealerCd: '29099',
        certificationNumber: 9418567,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU641792',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T16:43:07.358Z'
        }
      },
      sort: [
        1591378906600
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE8K3049616290992020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:41:01.762Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18247.00',
          mileage: '11040'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9418430,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE8K3049616',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T15:28:13.689Z'
        }
      },
      sort: [
        1591378861762
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW603332290992020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:40:26.701Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21644.00',
          mileage: '23533'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9412615,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW603332',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T20:26:25.109Z'
        }
      },
      sort: [
        1591378826701
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9JW748631450552020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:39:42.941Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23750.0000',
          mileage: '17185'
        },
        sellArea: '800',
        dealerCd: '45055',
        certificationNumber: 9459924,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9JW748631',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:45:23.038Z'
        }
      },
      sort: [
        1591378782941
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH0JS494097450552020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:39:35.020Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25000.0000',
          mileage: '47835'
        },
        sellArea: '800',
        dealerCd: '45055',
        certificationNumber: 9426355,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH0JS494097',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T23:40:56.397Z'
        }
      },
      sort: [
        1591378775020
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV6HW362890311542020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:39:26.140Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18989.0000',
          mileage: '10784'
        },
        sellArea: '160',
        dealerCd: '31154',
        certificationNumber: 9499479,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV6HW362890',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:19:59.920Z'
        }
      },
      sort: [
        1591378766140
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU750437311542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:39:23.641Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19763.0000',
          mileage: '32949'
        },
        sellArea: '160',
        dealerCd: '31154',
        certificationNumber: 9499453,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU750437',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:09:35.296Z'
        }
      },
      sort: [
        1591378763641
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS392832311542020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:39:20.939Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32263.0000',
          mileage: '22498'
        },
        sellArea: '160',
        dealerCd: '31154',
        certificationNumber: 9455441,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS392832',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T17:44:18.066Z'
        }
      },
      sort: [
        1591378760939
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3GC624196311542020-03-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:39:18.799Z',
          retailDate: '2020-03-06',
          rdrStatus: 'H',
          soldAmount: '16695.0000',
          mileage: '46510'
        },
        sellArea: '160',
        dealerCd: '31154',
        certificationNumber: 9499466,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3GC624196',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:15:19.555Z'
        }
      },
      sort: [
        1591378758799
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T3BK3BB5FU118391311542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:39:15.960Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19313.0000',
          mileage: '71451'
        },
        sellArea: '160',
        dealerCd: '31154',
        certificationNumber: 9420341,
        makeCode: 'TOYOTA',
        vin: '4T3BK3BB5FU118391',
        model: {
          modelDescription: 'VENZA XLE V6 AWD',
          modelSeries: 'VENZA',
          modelNumber: '2836',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T19:05:31.372Z'
        }
      },
      sort: [
        1591378755960
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU755358311542020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:39:13.000Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17495.0000',
          mileage: '40251'
        },
        dealerCd: '31154',
        certificationNumber: 9372697,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU755358',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T19:32:12.643Z'
        }
      },
      sort: [
        1591378753000
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU184421311542020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:39:10.478Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20488.0000',
          mileage: '22247'
        },
        dealerCd: '31154',
        certificationNumber: 9388727,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU184421',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T13:28:22.190Z'
        }
      },
      sort: [
        1591378750478
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3HW625220160052020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:38:34.673Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24995.00',
          mileage: '20482'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9483943,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3HW625220',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:56:37.044Z'
        }
      },
      sort: [
        1591378714673
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU716796160442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:36:58.157Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20483.50',
          mileage: '35203'
        },
        sellArea: '500',
        dealerCd: '16044',
        certificationNumber: 9459584,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU716796',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T15:18:22.446Z'
        }
      },
      sort: [
        1591378618157
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5GM033755201372020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:35:54.396Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '24288.00',
          mileage: '30305'
        },
        dealerCd: '20137',
        certificationNumber: 9382750,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5GM033755',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T16:34:21.718Z'
        }
      },
      sort: [
        1591378554396
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN8FM194490371762020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:35:48.718Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21525.00',
          mileage: '60086'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9498711,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN8FM194490',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T12:41:19.987Z'
        }
      },
      sort: [
        1591378548718
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV5HJ700679020482020-05-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:34:51.017Z',
          retailDate: '2020-05-01',
          rdrStatus: 'H',
          soldAmount: '17395.00',
          mileage: '27098'
        },
        sellArea: '150',
        dealerCd: '02048',
        certificationNumber: 9499502,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV5HJ700679',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T17:26:55.971Z'
        }
      },
      sort: [
        1591378491017
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH7GS263867160442020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:31:10.255Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '25200.00',
          mileage: '29923'
        },
        sellArea: '220',
        dealerCd: '16044',
        certificationNumber: 9462805,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH7GS263867',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:34:40.131Z'
        }
      },
      sort: [
        1591378270255
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RWRFV6KW001181440162020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:27:22.472Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '27700.00',
          mileage: '16050'
        },
        sellArea: '170',
        dealerCd: '44016',
        certificationNumber: 9495627,
        makeCode: 'TOYOTA',
        vin: '2T3RWRFV6KW001181',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:54:34.500Z'
        }
      },
      sort: [
        1591378042472
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5EN3HX052750201372020-04-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:26:54.010Z',
          retailDate: '2020-04-23',
          rdrStatus: 'H',
          soldAmount: '22855.00',
          mileage: '22555'
        },
        sellArea: '170',
        dealerCd: '20137',
        certificationNumber: 9413860,
        makeCode: 'TOYOTA',
        vin: '5TFSX5EN3HX052750',
        model: {
          modelDescription: 'TACOMA SR 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7514',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T18:41:40.509Z'
        }
      },
      sort: [
        1591378014010
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMA1RFV7KJ003113200192020-03-18V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:25:00.328Z',
          retailDate: '2020-03-18',
          rdrStatus: 'V',
          soldAmount: '27995.00',
          mileage: '9171'
        },
        sellArea: '210',
        dealerCd: '20019',
        certificationNumber: 9422394,
        makeCode: 'TOYOTA',
        vin: 'JTMA1RFV7KJ003113',
        model: {
          modelDescription: 'RAV4 XLE PREM AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4478',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T16:20:00.438Z'
        }
      },
      sort: [
        1591377900328
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU730177160052020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:24:23.751Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17988.00',
          mileage: '12417'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9397631,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU730177',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T20:17:14.386Z'
        }
      },
      sort: [
        1591377863751
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HD213636371812020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:17:47.508Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '23995'
        },
        sellArea: '800',
        dealerCd: '37181',
        certificationNumber: 9473137,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HD213636',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:19:28.305Z'
        }
      },
      sort: [
        1591377467508
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN5HX063863220302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:16:55.735Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24485.00',
          mileage: '47992'
        },
        sellArea: '160',
        dealerCd: '22030',
        certificationNumber: 9451160,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN5HX063863',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T15:57:43.423Z'
        }
      },
      sort: [
        1591377415735
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC007399048192020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:13:36.144Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23300.00',
          mileage: '24780'
        },
        sellArea: '500',
        dealerCd: '04819',
        certificationNumber: 9397476,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC007399',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T19:04:35.326Z'
        }
      },
      sort: [
        1591377216144
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW550627201372020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:12:20.021Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17345.00',
          mileage: '28999'
        },
        dealerCd: '20137',
        certificationNumber: 9382753,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW550627',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T16:37:25.196Z'
        }
      },
      sort: [
        1591377140021
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV5HD118282020362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:12:15.402Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25012.79',
          mileage: '25086'
        },
        sellArea: '230',
        dealerCd: '02036',
        certificationNumber: 9495412,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV5HD118282',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:16:15.201Z'
        }
      },
      sort: [
        1591377135402
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYKRFH3FS113705020362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:10:59.922Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23820.27',
          mileage: '50902'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9485553,
        makeCode: 'TOYOTA',
        vin: '5TDYKRFH3FS113705',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6955',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:19:31.006Z'
        }
      },
      sort: [
        1591377059922
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV3HW660116121002020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:10:52.542Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '24295.00',
          mileage: '12659'
        },
        sellArea: '210',
        dealerCd: '12100',
        certificationNumber: 9467047,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV3HW660116',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T16:41:59.959Z'
        }
      },
      sort: [
        1591377052542
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC962527450552020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:10:12.702Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '13250.00',
          mileage: '32962'
        },
        sellArea: '800',
        dealerCd: '45055',
        certificationNumber: 9497585,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC962527',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:13:03.889Z'
        }
      },
      sort: [
        1591377012702
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F11GX569237440202020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:09:44.782Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '32000.00',
          mileage: '49761'
        },
        sellArea: '160',
        dealerCd: '44020',
        certificationNumber: 9433673,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F11GX569237',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T13:27:19.083Z'
        }
      },
      sort: [
        1591376984782
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5G5281454360492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:09:12.822Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27180.00',
          mileage: '82820'
        },
        sellArea: '600',
        dealerCd: '36049',
        certificationNumber: 9463647,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5G5281454',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T21:50:22.217Z'
        }
      },
      sort: [
        1591376952822
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F15FX466228480072020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:08:58.819Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27502.00',
          mileage: '41843'
        },
        sellArea: '210',
        certificationNumber: 9481395,
        dealerCd: '48007',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DBL CAB 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2015
        },
        vin: '5TFUW5F15FX466228',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T13:57:39.691Z'
        }
      },
      sort: [
        1591376938819
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREVXJJ202731020362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:08:27.579Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18723.42',
          mileage: '55372'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9491752,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREVXJJ202731',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:57:21.258Z'
        }
      },
      sort: [
        1591376907579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW584674371812020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:08:10.320Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19900.00',
          mileage: '29106'
        },
        dealerCd: '37181',
        certificationNumber: 9368187,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW584674',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T18:16:54.967Z'
        }
      },
      sort: [
        1591376890320
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH4HS415461440142020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:07:10.020Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23876.00',
          mileage: '35247'
        },
        sellArea: '170',
        dealerCd: '44014',
        certificationNumber: 9495307,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH4HS415461',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:35:16.898Z'
        }
      },
      sort: [
        1591376830020
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1EBXJU061942360492020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:04:41.499Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '28479.31',
          mileage: '22877'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9468721,
        makeCode: 'TOYOTA',
        vin: '4T1BD1EBXJU061942',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:11:25.278Z'
        }
      },
      sort: [
        1591376681499
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU805127201372020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:04:03.399Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '14998.00',
          mileage: '46129'
        },
        sellArea: '170',
        dealerCd: '20137',
        certificationNumber: 9474371,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU805127',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T15:35:29.451Z'
        }
      },
      sort: [
        1591376643399
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7HD118192121382020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:03:58.496Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '22999.30',
          mileage: '32962'
        },
        sellArea: '210',
        dealerCd: '12138',
        certificationNumber: 9477642,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7HD118192',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:00:38.684Z'
        }
      },
      sort: [
        1591376638496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE4LP105103220302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:03:34.336Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16495.00',
          mileage: '3926'
        },
        sellArea: '600',
        dealerCd: '22030',
        certificationNumber: 9470339,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE4LP105103',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T13:00:36.782Z'
        }
      },
      sort: [
        1591376614336
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV7HY161985040392020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:03:30.677Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13988.00',
          mileage: '25743'
        },
        sellArea: '120',
        dealerCd: '04039',
        certificationNumber: 9434399,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV7HY161985',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T22:16:13.485Z'
        }
      },
      sort: [
        1591376610677
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX8L1063232360492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:02:56.837Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20733.00',
          mileage: '649'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9444877,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX8L1063232',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-15T20:38:39.628Z'
        }
      },
      sort: [
        1591376576837
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9LC068561360492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:01:04.995Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30149.00',
          mileage: '3208'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9483714,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9LC068561',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:22:18.254Z'
        }
      },
      sort: [
        1591376464995
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU8G3009526060432020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:01:02.634Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '16437.37',
          mileage: '49979'
        },
        dealerCd: '06043',
        certificationNumber: 9332428,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU8G3009526',
        model: {
          modelDescription: 'PRIUS TWO ECO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1224',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-11T15:46:59.748Z'
        }
      },
      sort: [
        1591376462634
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN1KX205279060432020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T17:00:23.873Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31611.00',
          mileage: '7311'
        },
        sellArea: '160',
        dealerCd: '06043',
        certificationNumber: 9469991,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN1KX205279',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T19:47:20.458Z'
        }
      },
      sort: [
        1591376423873
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW565792360492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:58:12.213Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27150.00',
          mileage: '56243'
        },
        sellArea: '600',
        dealerCd: '36049',
        certificationNumber: 9441430,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW565792',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-07T18:56:56.225Z'
        }
      },
      sort: [
        1591376292213
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KW059390048192020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:57:45.814Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26989.00',
          mileage: '23426'
        },
        sellArea: '600',
        dealerCd: '04819',
        certificationNumber: 9460843,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KW059390',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:46:01.510Z'
        }
      },
      sort: [
        1591376265814
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CNXKM098033360492020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:57:03.970Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '32497.00',
          mileage: '24713'
        },
        sellArea: '600',
        dealerCd: '36049',
        certificationNumber: 9496140,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CNXKM098033',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:53:27.825Z'
        }
      },
      sort: [
        1591376223970
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4K5699500270132020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:55:08.111Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '46999.00',
          mileage: '6808'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9461100,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4K5699500',
        model: {
          modelDescription: '4RUNNER 4X4 NIGHTSHADE V6',
          modelSeries: '4RUNNER',
          modelNumber: '8669',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T18:16:09.281Z'
        }
      },
      sort: [
        1591376108111
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F13LX890500360492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:54:48.171Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '43855.31',
          mileage: '2503'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9473427,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F13LX890500',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T21:01:53.640Z'
        }
      },
      sort: [
        1591376088171
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU740968201372020-04-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:54:27.810Z',
          retailDate: '2020-04-22',
          rdrStatus: 'H',
          soldAmount: '16714.00',
          mileage: '12140'
        },
        sellArea: '170',
        dealerCd: '20137',
        certificationNumber: 9398134,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU740968',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T00:12:57.293Z'
        }
      },
      sort: [
        1591376067810
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV7JW496720020162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:53:04.611Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20911.00',
          mileage: '24822'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9487927,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV7JW496720',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:45:41.592Z'
        }
      },
      sort: [
        1591375984611
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXJ5609331461022020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:52:54.130Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30960.00',
          mileage: '22812'
        },
        sellArea: '130',
        dealerCd: '46102',
        certificationNumber: 9496578,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXJ5609331',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T02:01:52.853Z'
        }
      },
      sort: [
        1591375974130
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU773256340362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:52:27.810Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17895.0000',
          mileage: '24365'
        },
        sellArea: '220',
        dealerCd: '34036',
        certificationNumber: 9477823,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU773256',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:22:30.064Z'
        }
      },
      sort: [
        1591375947810
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU705821340362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:52:24.872Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14600.0000',
          mileage: '32767'
        },
        sellArea: '220',
        certificationNumber: 9410048,
        dealerCd: '34036',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        vin: '4T1BF1FK5HU705821',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T18:27:35.700Z'
        }
      },
      sort: [
        1591375944872
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ145043340362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:52:21.804Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17600.0000',
          mileage: '30745'
        },
        dealerCd: '34036',
        certificationNumber: 9384407,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ145043',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T21:11:38.178Z'
        }
      },
      sort: [
        1591375941804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HJ136820340362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:52:18.930Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17512.01',
          mileage: '29983'
        },
        sellArea: '220',
        dealerCd: '34036',
        certificationNumber: 9460236,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HJ136820',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T21:35:08.179Z'
        }
      },
      sort: [
        1591375938930
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1L5769343461022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:52:00.010Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '42968.00',
          mileage: '9092'
        },
        sellArea: '130',
        dealerCd: '46102',
        certificationNumber: 9496433,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1L5769343',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:58:17.214Z'
        }
      },
      sort: [
        1591375920010
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CNXHM042246020162020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:51:12.514Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29811.00',
          mileage: '34871'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9498072,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CNXHM042246',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:37:48.095Z'
        }
      },
      sort: [
        1591375872514
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU686577201372020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:50:08.350Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '16556.00',
          mileage: '17899'
        },
        sellArea: '170',
        dealerCd: '20137',
        certificationNumber: 9474959,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU686577',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:14:27.511Z'
        }
      },
      sort: [
        1591375808350
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KW024815250612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:49:31.550Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26993.00',
          mileage: '25394'
        },
        dealerCd: '25061',
        certificationNumber: 9327587,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KW024815',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-08T21:12:27.336Z'
        }
      },
      sort: [
        1591375771550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEY5F1XHX215657041352020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:49:13.569Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '34995.00',
          mileage: '19438'
        },
        sellArea: '110',
        dealerCd: '04135',
        certificationNumber: 9486169,
        makeCode: 'TOYOTA',
        vin: '5TFEY5F1XHX215657',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8261',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:22:06.326Z'
        }
      },
      sort: [
        1591375753569
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV3HW585322340892020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:48:50.068Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21595.00',
          mileage: '35849'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9495301,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV3HW585322',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:34:33.080Z'
        }
      },
      sort: [
        1591375730068
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3JD223674250612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:48:47.871Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23991.00',
          mileage: '15804'
        },
        sellArea: '130',
        dealerCd: '25061',
        certificationNumber: 9497885,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3JD223674',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:48:04.932Z'
        }
      },
      sort: [
        1591375727871
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU549236140462020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:48:45.851Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '12500'
        },
        sellArea: '230',
        dealerCd: '14046',
        certificationNumber: 9477886,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU549236',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:47:27.415Z'
        }
      },
      sort: [
        1591375725851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDT4RCE9LJ009887041352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:48:15.551Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '5561'
        },
        sellArea: '110',
        dealerCd: '04135',
        certificationNumber: 9488486,
        makeCode: 'TOYOTA',
        vin: 'JTDT4RCE9LJ009887',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T23:22:54.118Z'
        }
      },
      sort: [
        1591375695551
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP656733270242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:46:47.612Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16988.00',
          mileage: '10020'
        },
        sellArea: '110',
        dealerCd: '27024',
        certificationNumber: 9497121,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP656733',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:09:25.717Z'
        }
      },
      sort: [
        1591375607612
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2GW438623050302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:46:38.370Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20400.00',
          mileage: '38421'
        },
        sellArea: '150',
        dealerCd: '05030',
        certificationNumber: 9460138,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2GW438623',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:42:50.341Z'
        }
      },
      sort: [
        1591375598370
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE9LJ026894310972020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:46:31.931Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23199.00',
          mileage: '11089'
        },
        sellArea: '500',
        dealerCd: '31097',
        certificationNumber: 9459945,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE9LJ026894',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:54:44.918Z'
        }
      },
      sort: [
        1591375591931
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KC008851041352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:46:24.652Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25219.20',
          mileage: '17913'
        },
        sellArea: '500',
        dealerCd: '04135',
        certificationNumber: 9416147,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KC008851',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T23:31:32.069Z'
        }
      },
      sort: [
        1591375584652
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ148721050302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:45:37.245Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19400.00',
          mileage: '62389'
        },
        sellArea: '150',
        certificationNumber: 9460129,
        dealerCd: '05030',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        vin: 'JTMBFREV9HJ148721',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:39:48.797Z'
        }
      },
      sort: [
        1591375537245
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV6HY173254041352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:44:31.731Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13450.00',
          mileage: '29851'
        },
        sellArea: '110',
        dealerCd: '04135',
        certificationNumber: 9484452,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV6HY173254',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:26:14.829Z'
        }
      },
      sort: [
        1591375471731
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F13JX735508250612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:44:04.072Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '43825.00',
          mileage: '23793'
        },
        sellArea: '130',
        dealerCd: '25061',
        certificationNumber: 9434042,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F13JX735508',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T17:46:07.072Z'
        }
      },
      sort: [
        1591375444072
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK4J3033807041352020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:43:29.851Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18688.00',
          mileage: '23865'
        },
        sellArea: '170',
        dealerCd: '04135',
        certificationNumber: 9414570,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK4J3033807',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T23:51:26.855Z'
        }
      },
      sort: [
        1591375409851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN4KX154588160612020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:43:07.571Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25287.00',
          mileage: '6931'
        },
        sellArea: '220',
        dealerCd: '16061',
        certificationNumber: 9494208,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN4KX154588',
        model: {
          modelDescription: 'TACOMA SR 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7186',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:43:40.398Z'
        }
      },
      sort: [
        1591375387571
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU754269270242020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:42:35.169Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21989.00',
          mileage: '30213'
        },
        sellArea: '500',
        dealerCd: '27024',
        certificationNumber: 9491436,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU754269',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:33:25.173Z'
        }
      },
      sort: [
        1591375355169
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5JU019277041352020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:42:25.471Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20388.00',
          mileage: '33109'
        },
        sellArea: '110',
        dealerCd: '04135',
        certificationNumber: 9410686,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5JU019277',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T00:42:57.819Z'
        }
      },
      sort: [
        1591375345471
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW556841160612020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:41:38.932Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19341.98',
          mileage: '35909'
        },
        sellArea: '800',
        dealerCd: '16061',
        certificationNumber: 9485554,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW556841',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:19:35.817Z'
        }
      },
      sort: [
        1591375298932
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU6G3518656041352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:41:10.490Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17488.00',
          mileage: '40506'
        },
        sellArea: '110',
        dealerCd: '04135',
        certificationNumber: 9430926,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU6G3518656',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T19:02:01.036Z'
        }
      },
      sort: [
        1591375270490
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4ENXFM186424461032020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:41:07.489Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27397.19',
          mileage: '64183'
        },
        sellArea: '130',
        dealerCd: '46103',
        certificationNumber: 9483826,
        makeCode: 'TOYOTA',
        vin: '3TMLU4ENXFM186424',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:09:14.697Z'
        }
      },
      sort: [
        1591375267489
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BNXHM022044440142020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:41:05.845Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26288.99',
          mileage: '60686'
        },
        sellArea: '170',
        dealerCd: '44014',
        certificationNumber: 9499099,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BNXHM022044',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:32:38.304Z'
        }
      },
      sort: [
        1591375265845
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU9H3052984041352020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:39:44.941Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '18788.00',
          mileage: '42309'
        },
        dealerCd: '04135',
        certificationNumber: 9351771,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU9H3052984',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-24T01:46:18.876Z'
        }
      },
      sort: [
        1591375184941
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU7K3087059461032020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:39:32.361Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23750.00',
          mileage: '4088'
        },
        sellArea: '500',
        certificationNumber: 9493889,
        dealerCd: '46103',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'PRIUS LE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2019
        },
        vin: 'JTDKARFU7K3087059',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:32:30.668Z'
        }
      },
      sort: [
        1591375172361
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDHPRAE7LJ017017470282020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:39:23.401Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19750.00',
          mileage: '18118'
        },
        sellArea: '500',
        dealerCd: '47028',
        certificationNumber: 9479535,
        makeCode: 'TOYOTA',
        vin: 'JTDHPRAE7LJ017017',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T20:33:12.611Z'
        }
      },
      sort: [
        1591375163401
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV6JD133454300412020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:36:16.061Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21986.00',
          mileage: '15901'
        },
        sellArea: '600',
        dealerCd: '30041',
        certificationNumber: 9491785,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV6JD133454',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:05:55.119Z'
        }
      },
      sort: [
        1591374976061
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMH1RFV7KD004503300412020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:33:46.636Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '21561.00',
          mileage: '27265'
        },
        sellArea: '600',
        dealerCd: '30041',
        certificationNumber: 9484545,
        makeCode: 'TOYOTA',
        vin: 'JTMH1RFV7KD004503',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T23:14:45.198Z'
        }
      },
      sort: [
        1591374826636
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU794112210722020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:32:42.656Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18960.00',
          mileage: '41115'
        },
        dealerCd: '21072',
        certificationNumber: 9382952,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU794112',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T19:58:25.742Z'
        }
      },
      sort: [
        1591374762656
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV2KY507481190052020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:32:24.278Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13998.00',
          mileage: '24650'
        },
        sellArea: '800',
        dealerCd: '19005',
        certificationNumber: 9422159,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV2KY507481',
        model: {
          modelDescription: 'YARIS Sedan 4Dr.Sedan LE 6AT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T14:36:10.826Z'
        }
      },
      sort: [
        1591374744278
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV6KD041778190052020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:31:36.697Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26998.00',
          mileage: '8657'
        },
        sellArea: '800',
        dealerCd: '19005',
        certificationNumber: 9483462,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV6KD041778',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:33:45.953Z'
        }
      },
      sort: [
        1591374696697
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU754141310972020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:29:17.096Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21888.00',
          mileage: '15527'
        },
        sellArea: '800',
        dealerCd: '31097',
        certificationNumber: 9459929,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU754141',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:49:03.480Z'
        }
      },
      sort: [
        1591374557096
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7JW737000210722020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:28:03.694Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22975.00',
          mileage: '23626'
        },
        sellArea: '220',
        dealerCd: '21072',
        certificationNumber: 9443890,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7JW737000',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T16:35:06.050Z'
        }
      },
      sort: [
        1591374483694
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV5JJ184300047372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:26:30.312Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19998.00',
          mileage: '22314'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9476285,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV5JJ184300',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:57:17.381Z'
        }
      },
      sort: [
        1591374390312
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU093240310972020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:26:11.272Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '25004.00',
          mileage: '26140'
        },
        sellArea: '800',
        dealerCd: '31097',
        certificationNumber: 9459871,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU093240',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:14:40.907Z'
        }
      },
      sort: [
        1591374371272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK4GU196308047372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:26:02.013Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15498.00',
          mileage: '74913'
        },
        sellArea: '500',
        dealerCd: '04737',
        certificationNumber: 9468737,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK4GU196308',
        model: {
          modelDescription: 'CAMRY HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:16:54.885Z'
        }
      },
      sort: [
        1591374362013
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC5HS153773041042020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:25:35.174Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23994.00',
          mileage: '36508'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9406015,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC5HS153773',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T23:00:07.291Z'
        }
      },
      sort: [
        1591374335174
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE7LJ007493047372020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:25:22.771Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17999.00',
          mileage: '25345'
        },
        sellArea: '500',
        dealerCd: '04737',
        certificationNumber: 9487917,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE7LJ007493',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:43:42.969Z'
        }
      },
      sort: [
        1591374322771
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE9LJ019332047372020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:24:57.871Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18998.00',
          mileage: '15354'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9487947,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE9LJ019332',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:50:16.761Z'
        }
      },
      sort: [
        1591374297871
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6JP762158040612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:24:08.892Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16541.0000',
          mileage: '41500'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9488615,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6JP762158',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:52:59.614Z'
        }
      },
      sort: [
        1591374248892
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMW1RFV7KD004251040612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:24:03.751Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27909.00',
          mileage: '9407'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9465101,
        makeCode: 'TOYOTA',
        vin: 'JTMW1RFV7KD004251',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T00:20:47.811Z'
        }
      },
      sort: [
        1591374243751
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU388002480282020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:18:13.410Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17443.00',
          mileage: '34909'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9477807,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU388002',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:14:22.429Z'
        }
      },
      sort: [
        1591373893410
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7JD223144440132020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:17:30.651Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '45385'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9485981,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7JD223144',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:38:35.296Z'
        }
      },
      sort: [
        1591373850651
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK9J3043474140152020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:16:52.031Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18820.00',
          mileage: '21100'
        },
        sellArea: '210',
        dealerCd: '14015',
        certificationNumber: 9452213,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK9J3043474',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T16:46:39.707Z'
        }
      },
      sort: [
        1591373812031
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV5HW658254180372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:16:02.932Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22439.00',
          mileage: '27260'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9499239,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV5HW658254',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:10:56.358Z'
        }
      },
      sort: [
        1591373762932
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2HS863289140152020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:15:57.971Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '26995.00',
          mileage: '48344'
        },
        sellArea: '230',
        dealerCd: '14015',
        certificationNumber: 9494021,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2HS863289',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:32:33.537Z'
        }
      },
      sort: [
        1591373757971
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN8GM016985043332020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:15:47.071Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26995.00',
          mileage: '64321'
        },
        sellArea: '110',
        dealerCd: '04333',
        certificationNumber: 9481050,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN8GM016985',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T23:23:07.301Z'
        }
      },
      sort: [
        1591373747071
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0HW630415140152020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:15:10.532Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17600.00',
          mileage: '32687'
        },
        sellArea: '210',
        dealerCd: '14015',
        certificationNumber: 9465523,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0HW630415',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:11:00.896Z'
        }
      },
      sort: [
        1591373710532
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW604432140152020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:14:34.491Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18200.00',
          mileage: '44844'
        },
        sellArea: '220',
        dealerCd: '14015',
        certificationNumber: 9474906,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW604432',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:42:18.625Z'
        }
      },
      sort: [
        1591373674491
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F12EX392510140152020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:14:04.411Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28200.00',
          mileage: '71733'
        },
        sellArea: '230',
        dealerCd: '14015',
        certificationNumber: 9497818,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F12EX392510',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:28:42.477Z'
        }
      },
      sort: [
        1591373644411
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH9HS030063045432020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:13:39.490Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22900.00',
          mileage: '44620'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9486323,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH9HS030063',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:51:14.048Z'
        }
      },
      sort: [
        1591373619490
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1HW566748220302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:09:28.220Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19985.00',
          mileage: '68196'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9416878,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1HW566748',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T16:42:19.257Z'
        }
      },
      sort: [
        1591373368220
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEM5F10HX118320045432020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:08:01.380Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30888.00',
          mileage: '16315'
        },
        dealerCd: '04543',
        certificationNumber: 9370922,
        makeCode: 'TOYOTA',
        vin: '5TFEM5F10HX118320',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8259',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-06T17:27:45.009Z'
        }
      },
      sort: [
        1591373281380
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK5HU223680120902020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:07:47.960Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '15291'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9400192,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK5HU223680',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T01:36:00.417Z'
        }
      },
      sort: [
        1591373267960
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS370808190262020-05-07V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:05:25.980Z',
          retailDate: '2020-05-07',
          rdrStatus: 'V',
          soldAmount: '26897.00',
          mileage: '40574'
        },
        sellArea: '160',
        dealerCd: '19026',
        certificationNumber: 9435542,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS370808',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T16:36:25.734Z'
        }
      },
      sort: [
        1591373125980
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV0JD172630450352020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:03:30.781Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '27450.19',
          mileage: '23868'
        },
        sellArea: '800',
        dealerCd: '45035',
        certificationNumber: 9499194,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV0JD172630',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T16:01:04.336Z'
        }
      },
      sort: [
        1591373010781
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2EC226250470322020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:01:52.540Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '12500.00',
          mileage: '59610'
        },
        sellArea: '150',
        dealerCd: '47032',
        certificationNumber: 9495296,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2EC226250',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:32:18.756Z'
        }
      },
      sort: [
        1591372912540
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP621538480612020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:01:15.320Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '14423.00',
          mileage: '44960'
        },
        dealerCd: '48061',
        certificationNumber: 9499076,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP621538',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:28:31.944Z'
        }
      },
      sort: [
        1591372875320
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFHXES054567020512020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T16:00:09.140Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21900.00',
          mileage: '76401'
        },
        sellArea: '130',
        dealerCd: '02051',
        certificationNumber: 9494769,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFHXES054567',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:58:10.979Z'
        }
      },
      sort: [
        1591372809140
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHD209524201012020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:59:36.599Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21694.00',
          mileage: '33072'
        },
        sellArea: '170',
        dealerCd: '20101',
        certificationNumber: 9495682,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHD209524',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:08:44.451Z'
        }
      },
      sort: [
        1591372776599
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV9HW365332041082020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:59:31.129Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19605.19',
          mileage: '47935'
        },
        sellArea: '110',
        dealerCd: '04108',
        certificationNumber: 9423733,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV9HW365332',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T15:57:12.780Z'
        }
      },
      sort: [
        1591372771129
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV3ED079494201272020-05-11H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:58:47.159Z',
          retailDate: '2020-05-11',
          rdrStatus: 'H',
          soldAmount: '16980',
          mileage: '47575'
        },
        sellArea: '170',
        dealerCd: '20127',
        certificationNumber: 9471209,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV3ED079494',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:50:54.988Z'
        }
      },
      sort: [
        1591372727159
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW598041310972020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:58:27.791Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '20389.00',
          mileage: '21340'
        },
        sellArea: '160',
        dealerCd: '31097',
        certificationNumber: 9409658,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW598041',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T14:51:30.728Z'
        }
      },
      sort: [
        1591372707791
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS399424190702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:58:11.610Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26627.00',
          mileage: '42614'
        },
        sellArea: '160',
        dealerCd: '19070',
        certificationNumber: 9469105,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS399424',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T21:10:02.996Z'
        }
      },
      sort: [
        1591372691610
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU693559291012020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:57:03.032Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15027.82',
          mileage: '44612'
        },
        dealerCd: '29101',
        certificationNumber: 9333505,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU693559',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-13T14:08:35.291Z'
        }
      },
      sort: [
        1591372623032
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU701654310972020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:55:21.352Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22078.00',
          mileage: '37032'
        },
        sellArea: '500',
        dealerCd: '31097',
        certificationNumber: 9406775,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU701654',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T13:47:49.460Z'
        }
      },
      sort: [
        1591372521352
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC851270201372020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:53:41.120Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14480.00',
          mileage: '31271'
        },
        sellArea: '170',
        dealerCd: '20137',
        certificationNumber: 9499067,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC851270',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:26:24.501Z'
        }
      },
      sort: [
        1591372421120
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP637104140442020-03-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:50:23.371Z',
          retailDate: '2020-03-05',
          rdrStatus: 'H',
          soldAmount: '12988.00',
          mileage: '56690'
        },
        dealerCd: '14044',
        certificationNumber: 9499148,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP637104',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:44:55.271Z'
        }
      },
      sort: [
        1591372223371
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK0GU192014201112020-05-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:49:58.471Z',
          retailDate: '2020-05-06',
          rdrStatus: 'H',
          soldAmount: '19257.00',
          mileage: '21167'
        },
        sellArea: '170',
        dealerCd: '20111',
        certificationNumber: 9499104,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK0GU192014',
        model: {
          modelDescription: 'CAMRY HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:34:55.887Z'
        }
      },
      sort: [
        1591372198471
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F1XEX371933041632020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:49:10.952Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29988.00',
          mileage: '74935'
        },
        sellArea: '120',
        dealerCd: '04163',
        certificationNumber: 9497294,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F1XEX371933',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:23:31.887Z'
        }
      },
      sort: [
        1591372150952
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHD209264201372020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:49:04.613Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19543.00',
          mileage: '34907'
        },
        sellArea: '170',
        dealerCd: '20137',
        certificationNumber: 9494049,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHD209264',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:43:48.416Z'
        }
      },
      sort: [
        1591372144613
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5HW610367220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:47:38.072Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19485.00',
          mileage: '36316'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9442331,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV5HW610367',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T16:02:06.376Z'
        }
      },
      sort: [
        1591372058072
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2HJ703055201112020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:46:57.450Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17400.00',
          mileage: '37693'
        },
        dealerCd: '20111',
        certificationNumber: 9239322,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2HJ703055',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-11T15:42:47.194Z'
        }
      },
      sort: [
        1591372017450
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F11GX065931201112020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:45:55.253Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26944.00',
          mileage: '44165'
        },
        dealerCd: '20111',
        certificationNumber: 9372692,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F11GX065931',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8340',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T19:30:22.006Z'
        }
      },
      sort: [
        1591371955253
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU383367120862020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:45:06.912Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15802.07',
          mileage: '13346'
        },
        sellArea: '210',
        dealerCd: '12086',
        certificationNumber: 9498074,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU383367',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:38:50.111Z'
        }
      },
      sort: [
        1591371906912
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3G5348357120862020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:44:38.591Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29498.00',
          mileage: '35250'
        },
        sellArea: '210',
        dealerCd: '12086',
        certificationNumber: 9392569,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3G5348357',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-22T22:51:41.453Z'
        }
      },
      sort: [
        1591371878591
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC5LS039411120862020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:44:11.313Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32666.07',
          mileage: '2939'
        },
        sellArea: '210',
        dealerCd: '12086',
        certificationNumber: 9482411,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC5LS039411',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:10:32.580Z'
        }
      },
      sort: [
        1591371851313
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK3GR551906341222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:43:57.784Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14988.00',
          mileage: '38496'
        },
        sellArea: '160',
        dealerCd: '34122',
        certificationNumber: 9498834,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK3GR551906',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:59:38.980Z'
        }
      },
      sort: [
        1591371837784
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH0GS505852120862020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:43:37.532Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26749.00',
          mileage: '38525'
        },
        certificationNumber: 9386294,
        dealerCd: '12086',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        vin: '5TDJKRFH0GS505852',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T21:01:45.713Z'
        }
      },
      sort: [
        1591371817532
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HD208677120862020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:43:03.672Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20516.00',
          mileage: '34508'
        },
        sellArea: '210',
        dealerCd: '12086',
        certificationNumber: 9492455,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HD208677',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:59:52.775Z'
        }
      },
      sort: [
        1591371783672
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU366955201112020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:42:18.472Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15800.00',
          mileage: '43797'
        },
        sellArea: '170',
        dealerCd: '20111',
        certificationNumber: 9486945,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU366955',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:39:49.538Z'
        }
      },
      sort: [
        1591371738472
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMMU4FN8FM085935371842020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:41:15.351Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '24933',
          mileage: '53552'
        },
        sellArea: '800',
        dealerCd: '37184',
        certificationNumber: 9499123,
        makeCode: 'TOYOTA',
        vin: '3TMMU4FN8FM085935',
        model: {
          modelDescription: 'TACOMA DBL CAB 4X4 V6 L/B',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7596',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:39:13.726Z'
        }
      },
      sort: [
        1591371675351
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV6HD101999046362020-05-27V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:41:12.884Z',
          retailDate: '2020-05-27',
          rdrStatus: 'V',
          soldAmount: '19699.00',
          mileage: '36945'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9454580,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV6HD101999',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T23:06:12.489Z'
        }
      },
      sort: [
        1591371672884
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS392603240842020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:41:08.870Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24548.33',
          mileage: '39980'
        },
        sellArea: '210',
        dealerCd: '24084',
        certificationNumber: 9470853,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS392603',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T16:47:31.885Z'
        }
      },
      sort: [
        1591371668870
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4JU673042201112020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:40:30.550Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18950.00',
          mileage: '36563'
        },
        sellArea: '170',
        dealerCd: '20111',
        certificationNumber: 9497392,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4JU673042',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:03:58.652Z'
        }
      },
      sort: [
        1591371630550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9GM012987280242020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:39:55.773Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26239.00',
          mileage: '54040'
        },
        sellArea: '170',
        dealerCd: '28024',
        certificationNumber: 9462291,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9GM012987',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-10T13:04:36.365Z'
        }
      },
      sort: [
        1591371595773
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU049097291012020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:39:29.212Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '21900.00',
          mileage: '12814'
        },
        sellArea: '160',
        dealerCd: '29101',
        certificationNumber: 9498783,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXJU049097',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:35:11.160Z'
        }
      },
      sort: [
        1591371569212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW622103280242020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:38:58.311Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19258.00',
          mileage: '24194'
        },
        sellArea: '160',
        dealerCd: '28024',
        certificationNumber: 9460711,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW622103',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T14:12:46.162Z'
        }
      },
      sort: [
        1591371538311
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDBVRBD2JA003900041362020-05-04V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:38:55.587Z',
          retailDate: '2020-05-04',
          rdrStatus: 'V',
          soldAmount: '17288.00',
          mileage: '13713'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9422566,
        makeCode: 'TOYOTA',
        vin: 'JTDBVRBD2JA003900',
        model: {
          modelDescription: 'MIRAI 4-DOOR SEDAN',
          modelSeries: 'MIRAI',
          modelNumber: '3000',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T17:37:56.467Z'
        }
      },
      sort: [
        1591371535587
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC934662280242020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:37:44.671Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14210.00',
          mileage: '21987'
        },
        sellArea: '160',
        dealerCd: '28024',
        certificationNumber: 9497883,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC934662',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:47:53.051Z'
        }
      },
      sort: [
        1591371464671
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU434493047392020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:37:34.331Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17998.00',
          mileage: '16231'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9488328,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU434493',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:52:53.329Z'
        }
      },
      sort: [
        1591371454331
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7JU535446450502020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:51.939Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '18500.0000',
          mileage: '54508'
        },
        sellArea: '500',
        dealerCd: '45050',
        certificationNumber: 9495117,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7JU535446',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:36:26.045Z'
        }
      },
      sort: [
        1591371411939
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU9HJ068261450502020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:49.539Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '18000.0000',
          mileage: '78702'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9483238,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU9HJ068261',
        model: {
          modelDescription: 'PRIUS v Five Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1249',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:30:42.709Z'
        }
      },
      sort: [
        1591371409539
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KC015257043442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:49.132Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26988.00',
          mileage: '24790'
        },
        sellArea: '500',
        certificationNumber: 9411625,
        dealerCd: '04344',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        vin: '2T3P1RFV7KC015257',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T19:55:11.985Z'
        }
      },
      sort: [
        1591371409132
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK3ER367059450502020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:45.099Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '10000.0000',
          mileage: '81520'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9495104,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK3ER367059',
        model: {
          modelDescription: 'CAMRY 2014.5 LE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:17:50.883Z'
        }
      },
      sort: [
        1591371405099
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN0GM007633450502020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:40.077Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '25900.0000',
          mileage: '58014'
        },
        sellArea: '170',
        dealerCd: '45050',
        certificationNumber: 9494185,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN0GM007633',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:35:29.585Z'
        }
      },
      sort: [
        1591371400077
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F15KX841913450502020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:37.559Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '43000.0000',
          mileage: '19998'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9486014,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F15KX841913',
        model: {
          modelDescription: 'TUNDRA TRD PRO CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8371',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:02:17.613Z'
        }
      },
      sort: [
        1591371397559
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB8HU257281450502020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:35.119Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '23000.0000',
          mileage: '11910'
        },
        sellArea: '160',
        dealerCd: '45050',
        certificationNumber: 9493924,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB8HU257281',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:49:50.671Z'
        }
      },
      sort: [
        1591371395119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV3HJ711339450502020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:32.778Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '17000.0000',
          mileage: '31452'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9495140,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV3HJ711339',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:49:58.987Z'
        }
      },
      sort: [
        1591371392778
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB7HU244392047392020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:31.672Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25787.00',
          mileage: '31942'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9479510,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB7HU244392',
        model: {
          modelDescription: 'AVALON 4-DR LIMITED',
          modelSeries: 'AVALON',
          modelNumber: '3554',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T20:26:10.471Z'
        }
      },
      sort: [
        1591371391672
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6FU050968450502020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:30.178Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '11500.0000',
          mileage: '80185'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9495360,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6FU050968',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:59:11.751Z'
        }
      },
      sort: [
        1591371390178
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS387166450502020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:27.798Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '26440.0000',
          mileage: '33900'
        },
        sellArea: '160',
        dealerCd: '45050',
        certificationNumber: 9495114,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS387166',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:31:07.838Z'
        }
      },
      sort: [
        1591371387798
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH5HS404064450502020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:25.301Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23500.0000',
          mileage: '33851'
        },
        sellArea: '160',
        dealerCd: '45050',
        certificationNumber: 9495112,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH5HS404064',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:27:05.176Z'
        }
      },
      sort: [
        1591371385301
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU786795450502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:20.460Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20657.0000',
          mileage: '29670'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9495369,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU786795',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:02:50.766Z'
        }
      },
      sort: [
        1591371380460
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFU4K3000165450502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:18.158Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24100.0000',
          mileage: '21271'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9450587,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFU4K3000165',
        model: {
          modelDescription: 'PRIUS XLE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1265',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T21:17:32.060Z'
        }
      },
      sort: [
        1591371378158
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXGU566946450502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:15.299Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13000.0000',
          mileage: '51760'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9495389,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXGU566946',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:09:30.132Z'
        }
      },
      sort: [
        1591371375299
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV1HD092561450502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:13.099Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20693.0000',
          mileage: '33421'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9415530,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV1HD092561',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T18:38:54.019Z'
        }
      },
      sort: [
        1591371373099
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV1HJ125238450502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:10.464Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17500.0000',
          mileage: '18920'
        },
        sellArea: '160',
        dealerCd: '45050',
        certificationNumber: 9418148,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV1HJ125238',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T13:05:01.002Z'
        }
      },
      sort: [
        1591371370464
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB33H1591520371752020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:09.632Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '20718.83',
          mileage: '26625'
        },
        sellArea: '160',
        dealerCd: '37175',
        certificationNumber: 9499083,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB33H1591520',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:29:39.871Z'
        }
      },
      sort: [
        1591371369632
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU355934450502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:36:08.219Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '35615'
        },
        sellArea: '800',
        dealerCd: '45050',
        certificationNumber: 9495556,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU355934',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:19:40.858Z'
        }
      },
      sort: [
        1591371368219
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU734031330252020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:35:16.499Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '19900.00',
          mileage: '3858'
        },
        sellArea: '230',
        dealerCd: '33025',
        certificationNumber: 9499066,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU734031',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T15:26:17.881Z'
        }
      },
      sort: [
        1591371316499
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE5LP030677043442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:34:59.579Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20988.00',
          mileage: '5133'
        },
        sellArea: '110',
        dealerCd: '04344',
        certificationNumber: 9478417,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE5LP030677',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:42:08.120Z'
        }
      },
      sort: [
        1591371299579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU728899043442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:34:14.179Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22764.00',
          mileage: '32330'
        },
        sellArea: '500',
        dealerCd: '04344',
        certificationNumber: 9425503,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU728899',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T16:34:50.445Z'
        }
      },
      sort: [
        1591371254179
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7JW814870240632020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:28:08.879Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20289.00',
          mileage: '35918'
        },
        dealerCd: '24063',
        certificationNumber: 9336158,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7JW814870',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-14T16:27:24.553Z'
        }
      },
      sort: [
        1591370888879
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU673900070082020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:25:08.278Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21968.00',
          mileage: '8259'
        },
        sellArea: '800',
        dealerCd: '07008',
        certificationNumber: 9497529,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU673900',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:49:47.298Z'
        }
      },
      sort: [
        1591370708278
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7HD078678480302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:23:05.538Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '40566'
        },
        sellArea: '210',
        dealerCd: '48030',
        certificationNumber: 9498952,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7HD078678',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:45:09.285Z'
        }
      },
      sort: [
        1591370585538
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ122887121632020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:19:08.919Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '38887'
        },
        sellArea: '230',
        dealerCd: '12163',
        certificationNumber: 9392362,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ122887',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-22T19:54:13.427Z'
        }
      },
      sort: [
        1591370348919
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6JW849563121632020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:17:53.380Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '18062'
        },
        sellArea: '210',
        dealerCd: '12163',
        certificationNumber: 9446745,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6JW849563',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T20:20:44.567Z'
        }
      },
      sort: [
        1591370273380
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2JD236366190692020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:17:50.219Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '3573'
        },
        sellArea: '800',
        dealerCd: '19069',
        certificationNumber: 9460736,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2JD236366',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T14:31:21.713Z'
        }
      },
      sort: [
        1591370270219
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW653871130702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:16:51.893Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22363.00',
          mileage: '40194'
        },
        sellArea: '160',
        dealerCd: '13070',
        certificationNumber: 9470606,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW653871',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:30:31.154Z'
        }
      },
      sort: [
        1591370211893
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBW5F16GX500444130702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:16:30.578Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30046.00',
          mileage: '47025'
        },
        dealerCd: '13070',
        certificationNumber: 9301035,
        makeCode: 'TOYOTA',
        vin: '5TFBW5F16GX500444',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DBL CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8354',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-20T22:02:08.272Z'
        }
      },
      sort: [
        1591370190578
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6JW738379190692020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:16:30.093Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18996.00',
          mileage: '25748'
        },
        sellArea: '800',
        dealerCd: '19069',
        certificationNumber: 9469339,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6JW738379',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T00:14:59.588Z'
        }
      },
      sort: [
        1591370190093
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3H5423608130702020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:15:59.851Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30672.00',
          mileage: '44044'
        },
        sellArea: '800',
        dealerCd: '13070',
        certificationNumber: 9440659,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3H5423608',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T15:32:35.624Z'
        }
      },
      sort: [
        1591370159851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4FW352498130702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:15:29.133Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16872.00',
          mileage: '56653'
        },
        sellArea: '210',
        dealerCd: '13070',
        certificationNumber: 9467129,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4FW352498',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T17:17:54.830Z'
        }
      },
      sort: [
        1591370129133
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU707959130702020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:15:01.993Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '24356'
        },
        sellArea: '500',
        dealerCd: '13070',
        certificationNumber: 9467376,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU707959',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T19:34:14.961Z'
        }
      },
      sort: [
        1591370101993
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU6H3536031046882020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:14:30.653Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19981.00',
          mileage: '54813'
        },
        sellArea: '150',
        dealerCd: '04688',
        certificationNumber: 9454633,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU6H3536031',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T23:18:33.766Z'
        }
      },
      sort: [
        1591370070653
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS398388410212020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:13:26.838Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25926.78',
          mileage: '50397'
        },
        sellArea: '160',
        dealerCd: '41021',
        certificationNumber: 9494525,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS398388',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:39:25.043Z'
        }
      },
      sort: [
        1591370006838
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK0HU218368121632020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:12:27.979Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '20285.82',
          mileage: '19687'
        },
        sellArea: '210',
        dealerCd: '12163',
        certificationNumber: 9435435,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK0HU218368',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T13:29:39.779Z'
        }
      },
      sort: [
        1591369947979
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX0JR038473045282020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:10:28.198Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20481.00',
          mileage: '34766'
        },
        sellArea: '110',
        dealerCd: '04528',
        certificationNumber: 9483659,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX0JR038473',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:54:01.219Z'
        }
      },
      sort: [
        1591369828198
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8G5311711340262020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:10:01.257Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29433.96',
          mileage: '48877'
        },
        sellArea: '500',
        dealerCd: '34026',
        certificationNumber: 9497001,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8G5311711',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:11:35.023Z'
        }
      },
      sort: [
        1591369801257
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV9FD060037045282020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:09:54.519Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '47279'
        },
        sellArea: '110',
        dealerCd: '04528',
        certificationNumber: 9487634,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV9FD060037',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:00:22.348Z'
        }
      },
      sort: [
        1591369794519
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0K5678448360852020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:09:04.294Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '32482.00',
          mileage: '25826'
        },
        sellArea: '500',
        dealerCd: '36085',
        certificationNumber: 9436759,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0K5678448',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-30T22:28:10.090Z'
        }
      },
      sort: [
        1591369744294
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP632631041362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:08:01.321Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13588.00',
          mileage: '32310'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9450416,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP632631',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T19:16:34.301Z'
        }
      },
      sort: [
        1591369681321
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXGU234876371142020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:07:54.334Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13688.00',
          mileage: '46912'
        },
        sellArea: '160',
        dealerCd: '37114',
        certificationNumber: 9483966,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXGU234876',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:06:38.238Z'
        }
      },
      sort: [
        1591369674334
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP2H3006522041362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:06:48.993Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21588.0000',
          mileage: '20918'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9426354,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP2H3006522',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T23:40:44.179Z'
        }
      },
      sort: [
        1591369608993
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX0K1041790041362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:06:46.753Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20882.0000',
          mileage: '17097'
        },
        sellArea: '600',
        dealerCd: '04136',
        certificationNumber: 9423058,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX0K1041790',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T22:05:52.940Z'
        }
      },
      sort: [
        1591369606753
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP647308041362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:06:43.093Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15888.0000',
          mileage: '34776'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9450453,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP647308',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T19:51:16.670Z'
        }
      },
      sort: [
        1591369603093
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV0HW350598041362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:06:40.753Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24187.0000',
          mileage: '11270'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9431276,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV0HW350598',
        model: {
          modelDescription: 'RAV4 Platinum FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4474',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T21:53:13.160Z'
        }
      },
      sort: [
        1591369600753
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX6K1041373041362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:06:38.314Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18988.0000',
          mileage: '18617'
        },
        sellArea: '600',
        dealerCd: '04136',
        certificationNumber: 9399660,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX6K1041373',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T21:23:11.830Z'
        }
      },
      sort: [
        1591369598314
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU1J3597600041362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:06:32.533Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17387.00',
          mileage: '44622'
        },
        dealerCd: '04136',
        certificationNumber: 9351497,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU1J3597600',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-23T22:51:10.567Z'
        }
      },
      sort: [
        1591369592533
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK3LU306507371142020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:06:04.785Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '24655.00',
          mileage: '2218'
        },
        sellArea: '800',
        dealerCd: '37114',
        certificationNumber: 9425571,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK3LU306507',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T17:02:56.894Z'
        }
      },
      sort: [
        1591369564785
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM107061480652020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:05:16.531Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '34790.00',
          mileage: '27992'
        },
        sellArea: '170',
        dealerCd: '48065',
        certificationNumber: 9497023,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM107061',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:24:46.783Z'
        }
      },
      sort: [
        1591369516531
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5ANXHM101694470232020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:04:17.731Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '27776.00',
          mileage: '52860'
        },
        sellArea: '800',
        dealerCd: '47023',
        certificationNumber: 9498911,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5ANXHM101694',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:30:43.753Z'
        }
      },
      sort: [
        1591369457731
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0JW761509450792020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:03:58.511Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '14851'
        },
        sellArea: '800',
        dealerCd: '45079',
        certificationNumber: 9396297,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0JW761509',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T23:34:34.341Z'
        }
      },
      sort: [
        1591369438511
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F12FX440262180332020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:02:31.491Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '81726'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9497460,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F12FX440262',
        model: {
          modelDescription: 'TUNDRA 4X4 LTD CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:29:58.764Z'
        }
      },
      sort: [
        1591369351491
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFJU4GN8EX060186450552020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:01:44.993Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '60176'
        },
        sellArea: '500',
        dealerCd: '45055',
        certificationNumber: 9414271,
        makeCode: 'TOYOTA',
        vin: '5TFJU4GN8EX060186',
        model: {
          modelDescription: 'TACOMA PRERUNNER DOUBLE CAB 4X2 V6',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7188',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T21:15:27.466Z'
        }
      },
      sort: [
        1591369304993
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE5LP007447430262020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T15:00:24.751Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16099.00',
          mileage: '18373'
        },
        sellArea: '150',
        dealerCd: '43026',
        certificationNumber: 9442355,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE5LP007447',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T16:20:05.897Z'
        }
      },
      sort: [
        1591369224751
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK0HU221853450552020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:58:34.553Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16800.00',
          mileage: '25810'
        },
        sellArea: '800',
        dealerCd: '45055',
        certificationNumber: 9409352,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK0HU221853',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T01:24:09.655Z'
        }
      },
      sort: [
        1591369114553
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU452826040132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:58:31.239Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16292.00',
          mileage: '36820'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483249,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU452826',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:33:26.255Z'
        }
      },
      sort: [
        1591369111239
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH8HS029535040132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:57:48.577Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21892.00',
          mileage: '47075'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9487228,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH8HS029535',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:35:30.930Z'
        }
      },
      sort: [
        1591369068577
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1NZ1AK4LU037546040132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:56:54.658Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '32992.00',
          mileage: '1971'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497317,
        makeCode: 'TOYOTA',
        vin: '4T1NZ1AK4LU037546',
        model: {
          modelDescription: 'CAMRY 4-DOOR TRD V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2549',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:31:10.071Z'
        }
      },
      sort: [
        1591369014658
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV8HJ136328180372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:56:06.158Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '32644'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9497641,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV8HJ136328',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:30:36.062Z'
        }
      },
      sort: [
        1591368966158
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ144923180372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:55:24.218Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '12950'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9465406,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ144923',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T13:35:34.786Z'
        }
      },
      sort: [
        1591368924218
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5FU482841201272020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:55:22.098Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '12210.00',
          mileage: '50143'
        },
        sellArea: '170',
        dealerCd: '20127',
        certificationNumber: 9485971,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5FU482841',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:36:12.614Z'
        }
      },
      sort: [
        1591368922098
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV6HW647535201272020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:53:45.691Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22978.00',
          mileage: '26036'
        },
        sellArea: '170',
        dealerCd: '20127',
        certificationNumber: 9494084,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV6HW647535',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:55:47.516Z'
        }
      },
      sort: [
        1591368825691
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU7G3002602041372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:52:48.658Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16899.0000',
          mileage: '34388'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9445419,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU7G3002602',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T15:58:02.296Z'
        }
      },
      sort: [
        1591368768658
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV0HW369983041372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:52:46.398Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21000.0000',
          mileage: '15309'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9482379,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV0HW369983',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:51:24.484Z'
        }
      },
      sort: [
        1591368766398
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV6KW008402041372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:52:43.798Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21375.00',
          mileage: '26191'
        },
        sellArea: '600',
        dealerCd: '04137',
        certificationNumber: 9491807,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV6KW008402',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:10:36.425Z'
        }
      },
      sort: [
        1591368763798
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV5HW386954020352020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:51:22.379Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '15534'
        },
        sellArea: '110',
        dealerCd: '02035',
        certificationNumber: 9494871,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV5HW386954',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T01:39:11.867Z'
        }
      },
      sort: [
        1591368682379
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM106744180332020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:51:11.896Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '31995.00',
          mileage: '20330'
        },
        dealerCd: '18033',
        certificationNumber: 9372821,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM106744',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T20:16:29.856Z'
        }
      },
      sort: [
        1591368671896
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW663061211182020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:49:51.918Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18499.00',
          mileage: '7738'
        },
        sellArea: '220',
        dealerCd: '21118',
        certificationNumber: 9498959,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW663061',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:48:07.986Z'
        }
      },
      sort: [
        1591368591918
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0HM057964020352020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:49:31.496Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28400.00',
          mileage: '43708'
        },
        sellArea: '230',
        dealerCd: '02035',
        certificationNumber: 9496567,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0HM057964',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T00:55:42.403Z'
        }
      },
      sort: [
        1591368571496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW591312220302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:47:11.378Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18490.00',
          mileage: '27523'
        },
        sellArea: '800',
        dealerCd: '22030',
        certificationNumber: 9487218,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW591312',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:31:30.207Z'
        }
      },
      sort: [
        1591368431378
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV7JW516500020352020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:47:02.718Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21595.00',
          mileage: '24047'
        },
        sellArea: '150',
        dealerCd: '02035',
        certificationNumber: 9429894,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV7JW516500',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T19:35:40.340Z'
        }
      },
      sort: [
        1591368422718
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU659872110012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:45:26.857Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15396.00',
          mileage: '56586'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9459683,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU659872',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T16:21:54.702Z'
        }
      },
      sort: [
        1591368326857
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0K5669104240632020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:42:59.439Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29489.00',
          mileage: '27075'
        },
        sellArea: '500',
        dealerCd: '24063',
        certificationNumber: 9477483,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0K5669104',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:49:43.104Z'
        }
      },
      sort: [
        1591368179439
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUU4EN1EX100558371812020-05-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:39:25.357Z',
          retailDate: '2020-05-13',
          rdrStatus: 'H',
          soldAmount: '21400',
          mileage: '39825'
        },
        sellArea: '800',
        dealerCd: '37181',
        certificationNumber: 9498931,
        makeCode: 'TOYOTA',
        vin: '5TFUU4EN1EX100558',
        model: {
          modelDescription: 'TACOMA 4X4 ACCESS CAB V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7554',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:37:25.240Z'
        }
      },
      sort: [
        1591367965357
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH6HS392605180332020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:37:46.778Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25505.00',
          mileage: '30524'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9477801,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH6HS392605',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:13:05.410Z'
        }
      },
      sort: [
        1591367866778
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK2JU120293110012020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:36:54.156Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26999.00',
          mileage: '22375'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9465611,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK2JU120293',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:02:49.859Z'
        }
      },
      sort: [
        1591367814156
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMMU4FN9FM086995190472020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:35:55.398Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '26199',
          mileage: '44124'
        },
        sellArea: '800',
        dealerCd: '19047',
        certificationNumber: 9498917,
        makeCode: 'TOYOTA',
        vin: '3TMMU4FN9FM086995',
        model: {
          modelDescription: 'TACOMA DBL CAB 4X4 V6 L/B',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7596',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:33:40.014Z'
        }
      },
      sort: [
        1591367755398
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3HM074404311312020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:33:45.737Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29499.00',
          mileage: '30727'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9497543,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3HM074404',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:59:00.161Z'
        }
      },
      sort: [
        1591367625737
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUU4EN0FX133942450702020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:32:10.896Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '21470',
          mileage: '67557'
        },
        sellArea: '800',
        dealerCd: '45070',
        certificationNumber: 9498910,
        makeCode: 'TOYOTA',
        vin: '5TFUU4EN0FX133942',
        model: {
          modelDescription: 'TACOMA 4X4 ACCESS CAB V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7554',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:29:55.079Z'
        }
      },
      sort: [
        1591367530896
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH7GS504133450912020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:31:26.757Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24980.00',
          mileage: '33780'
        },
        sellArea: '160',
        dealerCd: '45091',
        certificationNumber: 9486461,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH7GS504133',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:31:00.279Z'
        }
      },
      sort: [
        1591367486757
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS412669220352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:28:31.477Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26919.00',
          mileage: '58742'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9422043,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS412669',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T13:26:06.503Z'
        }
      },
      sort: [
        1591367311477
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HJ109003220352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:27:47.438Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '59723'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9455537,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HJ109003',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T18:33:13.918Z'
        }
      },
      sort: [
        1591367267438
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8HS841944341212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:27:06.678Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25900.00',
          mileage: '27010'
        },
        sellArea: '220',
        dealerCd: '34121',
        certificationNumber: 9497864,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8HS841944',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:41:14.531Z'
        }
      },
      sort: [
        1591367226678
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP0H3034688041542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:27:00.298Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21988.00',
          mileage: '38349'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9433352,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP0H3034688',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T20:45:15.689Z'
        }
      },
      sort: [
        1591367220298
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU286995041542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:26:35.937Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22987.00',
          mileage: '7590'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9437817,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU286995',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T19:43:51.874Z'
        }
      },
      sort: [
        1591367195937
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV3GJ063385060402020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:26:15.778Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19987.00',
          mileage: '16991'
        },
        sellArea: '160',
        dealerCd: '06040',
        certificationNumber: 9485385,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV3GJ063385',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:23:51.143Z'
        }
      },
      sort: [
        1591367175778
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU760667041542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:25:20.637Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15277.27',
          mileage: '49223'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9450215,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU760667',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T17:14:40.422Z'
        }
      },
      sort: [
        1591367120637
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU810060041542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:24:59.577Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16996.00',
          mileage: '26655'
        },
        sellArea: '110',
        certificationNumber: 9407137,
        dealerCd: '04154',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        vin: '4T1BF1FK4HU810060',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T17:59:00.366Z'
        }
      },
      sort: [
        1591367099577
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMH1RFV0KD509685041542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:24:36.038Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23704.00',
          mileage: '17864'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9461257,
        makeCode: 'TOYOTA',
        vin: 'JTMH1RFV0KD509685',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T19:38:47.272Z'
        }
      },
      sort: [
        1591367076038
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU326005041542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:24:08.418Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18057.00',
          mileage: '27451'
        },
        dealerCd: '04154',
        certificationNumber: 9378328,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU326005',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-12T20:12:16.239Z'
        }
      },
      sort: [
        1591367048418
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7GP534355041542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:23:33.837Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15597.00',
          mileage: '33314'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9485921,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7GP534355',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:08:28.229Z'
        }
      },
      sort: [
        1591367013837
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH5HS032778341292019-11-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:22:27.617Z',
          retailDate: '2019-11-02',
          rdrStatus: 'H',
          soldAmount: '22981',
          mileage: '36450'
        },
        sellArea: '220',
        dealerCd: '34129',
        certificationNumber: 9498882,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH5HS032778',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:20:26.829Z'
        }
      },
      sort: [
        1591366947617
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV1HJ147393201262020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:20:48.116Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22647.19',
          mileage: '32140'
        },
        sellArea: '160',
        dealerCd: '20126',
        certificationNumber: 9498874,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV1HJ147393',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:17:09.247Z'
        }
      },
      sort: [
        1591366848116
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW623678341212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:20:04.777Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18750.00',
          mileage: '47563'
        },
        sellArea: '220',
        dealerCd: '34121',
        certificationNumber: 9465455,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW623678',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T14:34:56.848Z'
        }
      },
      sort: [
        1591366804777
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F1XKX816778341292019-10-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:18:48.117Z',
          retailDate: '2019-10-30',
          rdrStatus: 'H',
          soldAmount: '47981',
          mileage: '14318'
        },
        sellArea: '220',
        dealerCd: '34129',
        certificationNumber: 9498872,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F1XKX816778',
        model: {
          modelDescription: 'TUNDRA TRD PRO CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8371',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:16:32.044Z'
        }
      },
      sort: [
        1591366728117
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DCXLS035273160522020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:16:45.537Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '30500.00',
          mileage: '20837'
        },
        sellArea: '600',
        dealerCd: '16052',
        certificationNumber: 9413981,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DCXLS035273',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T19:27:34.757Z'
        }
      },
      sort: [
        1591366605537
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6GP490025341292019-10-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:15:01.239Z',
          retailDate: '2019-10-29',
          rdrStatus: 'H',
          soldAmount: '15431',
          mileage: '33659'
        },
        sellArea: '220',
        dealerCd: '34129',
        certificationNumber: 9498863,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6GP490025',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1863',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:12:58.204Z'
        }
      },
      sort: [
        1591366501239
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU765994160522020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:14:43.018Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '21475.75',
          mileage: '11067'
        },
        sellArea: '220',
        dealerCd: '16052',
        certificationNumber: 9477673,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU765994',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:11:23.365Z'
        }
      },
      sort: [
        1591366483018
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMH1RFV9KD504419450922020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:14:38.672Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20243.0000',
          mileage: '29438'
        },
        sellArea: '500',
        dealerCd: '45092',
        certificationNumber: 9493690,
        makeCode: 'TOYOTA',
        vin: 'JTMH1RFV9KD504419',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:28:55.051Z'
        }
      },
      sort: [
        1591366478672
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV5GJ094960450922020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:14:35.732Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19980.00',
          mileage: '32077'
        },
        sellArea: '160',
        dealerCd: '45092',
        certificationNumber: 9493913,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV5GJ094960',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:44:07.551Z'
        }
      },
      sort: [
        1591366475732
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC795973110012020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:14:22.832Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16908.00',
          mileage: '29763'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9460171,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC795973',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:54:40.728Z'
        }
      },
      sort: [
        1591366462832
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE8LP046900201262020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:13:28.232Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17498.00',
          mileage: '2612'
        },
        sellArea: '170',
        dealerCd: '20126',
        certificationNumber: 9429553,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE8LP046900',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T14:29:00.814Z'
        }
      },
      sort: [
        1591366408232
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC889287410812020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:13:04.012Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '20086'
        },
        dealerCd: '41081',
        certificationNumber: 9449438,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC889287',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-25T16:48:47.363Z'
        }
      },
      sort: [
        1591366384012
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH0HS028114341292019-10-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:11:24.611Z',
          retailDate: '2019-10-29',
          rdrStatus: 'H',
          soldAmount: '28431',
          mileage: '74472'
        },
        sellArea: '220',
        dealerCd: '34129',
        certificationNumber: 9498854,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH0HS028114',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:09:15.803Z'
        }
      },
      sort: [
        1591366284611
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0GC715880341212020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:10:38.651Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '11000.00',
          mileage: '23918'
        },
        sellArea: '220',
        dealerCd: '34121',
        certificationNumber: 9471539,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0GC715880',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T20:52:24.871Z'
        }
      },
      sort: [
        1591366238651
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HD213639480652020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:10:28.749Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20803.43',
          mileage: '30059'
        },
        sellArea: '210',
        dealerCd: '48065',
        certificationNumber: 9495400,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HD213639',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:13:34.931Z'
        }
      },
      sort: [
        1591366228749
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRM5F18GX099287341292019-10-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:07:55.210Z',
          retailDate: '2019-10-24',
          rdrStatus: 'H',
          soldAmount: '20431',
          mileage: '73105'
        },
        sellArea: '220',
        dealerCd: '34129',
        certificationNumber: 9498836,
        makeCode: 'TOYOTA',
        vin: '5TFRM5F18GX099287',
        model: {
          modelDescription: 'TUNDRA 4X2 SR DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8239',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T14:00:22.978Z'
        }
      },
      sort: [
        1591366075210
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6H5442945060402020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:07:24.188Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '9311'
        },
        sellArea: '160',
        dealerCd: '06040',
        certificationNumber: 9494535,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6H5442945',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:40:38.082Z'
        }
      },
      sort: [
        1591366044188
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU777121060402020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:05:13.688Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '23188'
        },
        sellArea: '160',
        dealerCd: '06040',
        certificationNumber: 9485493,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU777121',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:05:59.412Z'
        }
      },
      sort: [
        1591365913688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ130663190592020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T14:04:36.588Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18998.00',
          mileage: '79186'
        },
        sellArea: '800',
        dealerCd: '19059',
        certificationNumber: 9473109,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ130663',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:06:43.502Z'
        }
      },
      sort: [
        1591365876588
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4G5319966341292019-10-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:58:52.863Z',
          retailDate: '2019-10-10',
          rdrStatus: 'H',
          soldAmount: '24770',
          mileage: '60315'
        },
        sellArea: '220',
        dealerCd: '34129',
        certificationNumber: 9498831,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4G5319966',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:56:15.398Z'
        }
      },
      sort: [
        1591365532863
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC862457341292019-09-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:54:52.722Z',
          retailDate: '2019-09-07',
          rdrStatus: 'H',
          soldAmount: '16431',
          mileage: '24584'
        },
        sellArea: '220',
        dealerCd: '34129',
        certificationNumber: 9498826,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC862457',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:52:55.240Z'
        }
      },
      sort: [
        1591365292722
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC860662371942020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:50:57.258Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16272.00',
          mileage: '14941'
        },
        sellArea: '800',
        dealerCd: '37194',
        certificationNumber: 9451257,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC860662',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T16:36:03.843Z'
        }
      },
      sort: [
        1591365057258
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV5HW609039480072020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:49:06.580Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20590.00',
          mileage: '19177'
        },
        sellArea: '210',
        certificationNumber: 9475793,
        dealerCd: '48007',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        vin: '2T3RFREV5HW609039',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T14:35:08.472Z'
        }
      },
      sort: [
        1591364946580
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV8HJ703735480652020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:48:52.038Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '49465.00',
          mileage: '11779'
        },
        sellArea: '220',
        dealerCd: '48065',
        certificationNumber: 9495380,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV8HJ703735',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:06:35.195Z'
        }
      },
      sort: [
        1591364932038
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP725410140462020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:47:54.458Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15995.00',
          mileage: '10998'
        },
        sellArea: '230',
        dealerCd: '14046',
        certificationNumber: 9462697,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP725410',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T14:45:37.138Z'
        }
      },
      sort: [
        1591364874458
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3JW774156140462020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:47:09.618Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '33200'
        },
        sellArea: '230',
        dealerCd: '14046',
        certificationNumber: 9487034,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3JW774156',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:20:49.027Z'
        }
      },
      sort: [
        1591364829618
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN7HX085850480072020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:46:38.754Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27699.00',
          mileage: '22297'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9483080,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN7HX085850',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T13:18:52.074Z'
        }
      },
      sort: [
        1591364798754
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU702948240672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:45:17.608Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19624.00',
          mileage: '26990'
        },
        sellArea: '220',
        dealerCd: '24067',
        certificationNumber: 9494206,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU702948',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:43:29.831Z'
        }
      },
      sort: [
        1591364717608
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KW032185130462020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:42:34.018Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24556.00',
          mileage: '28911'
        },
        sellArea: '600',
        dealerCd: '13046',
        certificationNumber: 9493625,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KW032185',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T13:28:33.431Z'
        }
      },
      sort: [
        1591364554018
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU317577130462020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:40:03.330Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '22976'
        },
        sellArea: '160',
        dealerCd: '13046',
        certificationNumber: 9472551,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU317577',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T15:10:26.161Z'
        }
      },
      sort: [
        1591364403330
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU280898042832020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:38:47.089Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16484.00',
          mileage: '37788'
        },
        sellArea: '110',
        certificationNumber: 9421340,
        dealerCd: '04283',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        vin: '4T1BF1FK6HU280898',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T14:11:26.639Z'
        }
      },
      sort: [
        1591364327089
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1FKXHU578365370832020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:37:55.789Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23111.00',
          mileage: '26319'
        },
        sellArea: '160',
        dealerCd: '37083',
        certificationNumber: 9409826,
        makeCode: 'TOYOTA',
        vin: '4T1BK1FKXHU578365',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2554',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T16:29:20.776Z'
        }
      },
      sort: [
        1591364275789
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS410730371942020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:37:50.030Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '31777.00',
          mileage: '17901'
        },
        sellArea: '160',
        dealerCd: '37194',
        certificationNumber: 9411797,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS410730',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T21:17:48.587Z'
        }
      },
      sort: [
        1591364270030
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC792154370832020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:37:26.928Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15989.00',
          mileage: '26377'
        },
        dealerCd: '37083',
        certificationNumber: 9457936,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC792154',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T20:54:47.805Z'
        }
      },
      sort: [
        1591364246928
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC878732291042020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:35:54.548Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13878.00',
          mileage: '31515'
        },
        sellArea: '800',
        dealerCd: '29104',
        certificationNumber: 9474838,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC878732',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:12:07.102Z'
        }
      },
      sort: [
        1591364154548
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU103058041542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:34:37.418Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19358.86',
          mileage: '54811'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9496949,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU103058',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:42:46.810Z'
        }
      },
      sort: [
        1591364077418
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KC008251291042020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:34:32.986Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24885.52',
          mileage: '15252'
        },
        dealerCd: '29104',
        certificationNumber: 9343495,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KC008251',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-18T14:15:38.222Z'
        }
      },
      sort: [
        1591364072986
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS427417480652020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:32:32.183Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28876.00',
          mileage: '38482'
        },
        sellArea: '210',
        certificationNumber: 9495405,
        dealerCd: '48065',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        vin: '5TDJZRFH1HS427417',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:14:53.071Z'
        }
      },
      sort: [
        1591363952183
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH5GS253533211202020-06-05H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:24:45.116Z',
          retailDate: '2020-06-05',
          rdrStatus: 'H',
          soldAmount: '27691.00',
          mileage: '39754'
        },
        sellArea: '160',
        dealerCd: '21120',
        certificationNumber: 9498753,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH5GS253533',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:18:20.410Z'
        }
      },
      sort: [
        1591363485116
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH6HS451684291082020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:17:31.051Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24181.80',
          mileage: '37981'
        },
        sellArea: '160',
        dealerCd: '29108',
        certificationNumber: 9496966,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH6HS451684',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:51:24.580Z'
        }
      },
      sort: [
        1591363051051
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1C11AK5LU888656371482020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:14:58.513Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21492.00',
          mileage: '3346'
        },
        sellArea: '800',
        dealerCd: '37148',
        certificationNumber: 9467360,
        makeCode: 'TOYOTA',
        vin: '4T1C11AK5LU888656',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T19:27:39.294Z'
        }
      },
      sort: [
        1591362898513
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6GW536408371512020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:08:41.311Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '72458'
        },
        sellArea: '800',
        dealerCd: '37151',
        certificationNumber: 9498737,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6GW536408',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-05T13:05:41.307Z'
        }
      },
      sort: [
        1591362521311
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU767035291082020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:07:39.611Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13941.77',
          mileage: '67331'
        },
        sellArea: '160',
        dealerCd: '29108',
        certificationNumber: 9496999,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU767035',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:09:56.250Z'
        }
      },
      sort: [
        1591362459611
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC868982121602020-05-30V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:07:03.046Z',
          retailDate: '2020-05-30',
          rdrStatus: 'V',
          soldAmount: '12000.00',
          mileage: '22768'
        },
        sellArea: '210',
        dealerCd: '12160',
        certificationNumber: 9441926,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC868982',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T18:25:39.561Z'
        }
      },
      sort: [
        1591362423046
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU812715340892020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:06:24.710Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '4317'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9470556,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU812715',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:13:08.438Z'
        }
      },
      sort: [
        1591362384710
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW613414210952020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:04:26.598Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23923.00',
          mileage: '51719'
        },
        sellArea: '800',
        dealerCd: '21095',
        certificationNumber: 9415430,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW613414',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T18:11:18.269Z'
        }
      },
      sort: [
        1591362266598
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP621692260312020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:04:04.477Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '12895.00',
          mileage: '43358'
        },
        sellArea: '230',
        dealerCd: '26031',
        certificationNumber: 9496057,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP621692',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:09:23.119Z'
        }
      },
      sort: [
        1591362244477
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RWRFVXKW031901201012020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:01:31.578Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '32680.59',
          mileage: '39'
        },
        sellArea: '190',
        dealerCd: '20101',
        certificationNumber: 9497346,
        makeCode: 'TOYOTA',
        vin: '2T3RWRFVXKW031901',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:40:20.539Z'
        }
      },
      sort: [
        1591362091578
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU217054311312020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T13:00:26.599Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24499.00',
          mileage: '6888'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9447149,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU217054',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T14:40:50.859Z'
        }
      },
      sort: [
        1591362026599
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMCY7AJ6G4045615410702020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:59:08.918Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '55487.00',
          mileage: '45734'
        },
        sellArea: '230',
        dealerCd: '41070',
        certificationNumber: 9399495,
        makeCode: 'TOYOTA',
        vin: 'JTMCY7AJ6G4045615',
        model: {
          modelDescription: 'LAND CRUISER 4WD SUV V8',
          modelSeries: 'LAND CRUISER',
          modelNumber: '6156',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T20:22:54.256Z'
        }
      },
      sort: [
        1591361948918
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JRXG5145352410702020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:58:41.818Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30992.00',
          mileage: '37078'
        },
        sellArea: '600',
        dealerCd: '41070',
        certificationNumber: 9411531,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JRXG5145352',
        model: {
          modelDescription: '4RUNNER 4X2 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8648',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T19:00:04.897Z'
        }
      },
      sort: [
        1591361921818
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU288665121022020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:58:37.259Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '17802.00',
          mileage: '21720'
        },
        sellArea: '210',
        dealerCd: '12102',
        certificationNumber: 9497576,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU288665',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:10:59.619Z'
        }
      },
      sort: [
        1591361917259
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV6JW835190190502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:58:21.538Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24978.00',
          mileage: '13335'
        },
        dealerCd: '19050',
        certificationNumber: 9144449,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV6JW835190',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-09-10T17:28:13.708Z'
        }
      },
      sort: [
        1591361901538
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC800970410702020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:58:20.428Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14992.85',
          mileage: '34113'
        },
        sellArea: '160',
        dealerCd: '41070',
        certificationNumber: 9478272,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC800970',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:36:25.500Z'
        }
      },
      sort: [
        1591361900428
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX1KR089563450402020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:57:57.147Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19500.0000',
          mileage: '5613'
        },
        sellArea: '800',
        dealerCd: '45040',
        certificationNumber: 9451922,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX1KR089563',
        model: {
          modelDescription: 'C-HR LE',
          modelSeries: 'CHR',
          modelNumber: '2402',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T12:38:32.748Z'
        }
      },
      sort: [
        1591361877147
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1L11AK5LU326482450402020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:57:53.287Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21000.0000',
          mileage: '1181'
        },
        sellArea: '800',
        dealerCd: '45040',
        certificationNumber: 9439364,
        makeCode: 'TOYOTA',
        vin: '4T1L11AK5LU326482',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T19:23:07.091Z'
        }
      },
      sort: [
        1591361873287
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV8KC005641410702020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:57:50.217Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22992.00',
          mileage: '19082'
        },
        sellArea: '600',
        dealerCd: '41070',
        certificationNumber: 9416776,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV8KC005641',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T15:56:25.758Z'
        }
      },
      sort: [
        1591361870217
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZRFH7KS370453450402020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:57:47.559Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '37373.47',
          mileage: '1638'
        },
        sellArea: '800',
        dealerCd: '45040',
        certificationNumber: 9464076,
        makeCode: 'TOYOTA',
        vin: '5TDYZRFH7KS370453',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6954',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T13:19:25.623Z'
        }
      },
      sort: [
        1591361867559
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH1HS513542410702020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:57:23.579Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29992.00',
          mileage: '39531'
        },
        sellArea: '600',
        dealerCd: '41070',
        certificationNumber: 9402760,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH1HS513542',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6950',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T15:10:17.498Z'
        }
      },
      sort: [
        1591361843579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6K5673044121022020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:55:29.015Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30252.29',
          mileage: '20765'
        },
        sellArea: '500',
        dealerCd: '12102',
        certificationNumber: 9498149,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6K5673044',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:11:39.570Z'
        }
      },
      sort: [
        1591361729015
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU271200121022020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:53:08.818Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16982.30',
          mileage: '39242'
        },
        sellArea: '210',
        dealerCd: '12102',
        certificationNumber: 9472049,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU271200',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T00:55:57.479Z'
        }
      },
      sort: [
        1591361588818
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HD210566311312020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:49:49.020Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19499.00',
          mileage: '57497'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9487691,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HD210566',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:23:40.596Z'
        }
      },
      sort: [
        1591361389020
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV2HJ711056450862020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:46:12.621Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '25515'
        },
        sellArea: '800',
        dealerCd: '45086',
        certificationNumber: 9495228,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV2HJ711056',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:58:57.462Z'
        }
      },
      sort: [
        1591361172621
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4GM035299311312020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:31:38.224Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25999.00',
          mileage: '51621'
        },
        sellArea: '800',
        dealerCd: '31131',
        certificationNumber: 9481699,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4GM035299',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:35:12.781Z'
        }
      },
      sort: [
        1591360298224
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU331101371722020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:22:14.704Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17391.00',
          mileage: '44961'
        },
        dealerCd: '37172',
        certificationNumber: 9336328,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU331101',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-14T17:29:17.181Z'
        }
      },
      sort: [
        1591359734704
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV4HD094160371492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:21:06.945Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21890.12',
          mileage: '19039'
        },
        dealerCd: '37149',
        certificationNumber: 9328843,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV4HD094160',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-09T17:48:17.143Z'
        }
      },
      sort: [
        1591359666945
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3LM307772371722020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:20:30.924Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '35491.00',
          mileage: '3355'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9492667,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3LM307772',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:07:03.946Z'
        }
      },
      sort: [
        1591359630924
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC748258371492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:18:53.864Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15347.00',
          mileage: '36014'
        },
        sellArea: '220',
        dealerCd: '37149',
        certificationNumber: 9486903,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC748258',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:03:58.256Z'
        }
      },
      sort: [
        1591359533864
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV6HJ704637311312020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T12:17:35.446Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24999.00',
          mileage: '24322'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9470757,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV6HJ704637',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T16:16:43.483Z'
        }
      },
      sort: [
        1591359455446
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU380355220472020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T11:49:31.809Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16899.00',
          mileage: '17341'
        },
        sellArea: '210',
        dealerCd: '22047',
        certificationNumber: 9497305,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU380355',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:27:57.033Z'
        }
      },
      sort: [
        1591357771809
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMJU4GN6FM189941042222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T03:18:49.502Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24991.00',
          mileage: '55577'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9497975,
        makeCode: 'TOYOTA',
        vin: '3TMJU4GN6FM189941',
        model: {
          modelDescription: 'TACOMA PRERUNNER DOUBLE CAB 4X2 V6',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7188',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:07:25.653Z'
        }
      },
      sort: [
        1591327129502
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU373633042222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T03:16:42.934Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16591.00',
          mileage: '30735'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9478142,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU373633',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T20:35:21.262Z'
        }
      },
      sort: [
        1591327002934
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV5HJ710211042222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T03:13:30.077Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19991.00',
          mileage: '35135'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9424045,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV5HJ710211',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T18:07:12.809Z'
        }
      },
      sort: [
        1591326810077
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV5HD097613042222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T03:07:12.854Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '38560'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9480392,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV5HD097613',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T16:12:16.661Z'
        }
      },
      sort: [
        1591326432854
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV3HJ136076042222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T03:01:36.430Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18891.00',
          mileage: '33920'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9494859,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV3HJ136076',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T01:07:30.015Z'
        }
      },
      sort: [
        1591326096430
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN7KX192876500132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T02:56:09.388Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '37396.00',
          mileage: '13495'
        },
        sellArea: '130',
        dealerCd: '50013',
        certificationNumber: 9446754,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN7KX192876',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T20:30:53.053Z'
        }
      },
      sort: [
        1591325769388
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU0H3034342040882020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T02:51:44.805Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17988.0000',
          mileage: '51873'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9426395,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU0H3034342',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T01:11:33.835Z'
        }
      },
      sort: [
        1591325504805
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8HW640903040882020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T02:51:42.148Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19990.00',
          mileage: '29127'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9497622,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8HW640903',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:26:35.599Z'
        }
      },
      sort: [
        1591325502148
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DC8ES436232044212020-05-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T02:47:35.263Z',
          retailDate: '2020-05-13',
          rdrStatus: 'H',
          soldAmount: '24624.61',
          mileage: '48300'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9492595,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DC8ES436232',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:44:10.938Z'
        }
      },
      sort: [
        1591325255263
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH7HS382321044212020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T02:43:05.775Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '40510.28',
          mileage: '33483'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9492605,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH7HS382321',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:46:34.481Z'
        }
      },
      sort: [
        1591324985775
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN8HX103689480322020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T02:35:43.297Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27999.00',
          mileage: '29317'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9496392,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN8HX103689',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:15:18.636Z'
        }
      },
      sort: [
        1591324543297
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW653901480322020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T02:35:07.075Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17989.00',
          mileage: '26954'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9460878,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW653901',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T16:03:57.423Z'
        }
      },
      sort: [
        1591324507075
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU792924480322020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T02:29:55.573Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16800.00',
          mileage: '15295'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9445190,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU792924',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-16T19:42:44.085Z'
        }
      },
      sort: [
        1591324195573
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBEXK3023812480322020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T02:29:25.233Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '17591'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9455284,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBEXK3023812',
        model: {
          modelDescription: 'COROLLA HATCHBACK XSE 5DrHATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6274',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T15:42:57.840Z'
        }
      },
      sort: [
        1591324165233
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HJ174298110302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:58:16.838Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23442.00',
          mileage: '17598'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9485830,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HJ174298',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:38:44.206Z'
        }
      },
      sort: [
        1591322296838
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK0KU514644270202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:54:56.921Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24988.00',
          mileage: '9267'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9486460,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK0KU514644',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:30:19.456Z'
        }
      },
      sort: [
        1591322096921
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK7JU025129270202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:54:08.320Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25222.00',
          mileage: '22740'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9478034,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK7JU025129',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:46:05.518Z'
        }
      },
      sort: [
        1591322048320
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV8HW368741270202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:49:06.638Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20210.32',
          mileage: '28806'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9492944,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV8HW368741',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:11:54.479Z'
        }
      },
      sort: [
        1591321746638
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV8KW013066270202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:48:16.618Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24488.00',
          mileage: '15037'
        },
        sellArea: '600',
        dealerCd: '27020',
        certificationNumber: 9479717,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV8KW013066',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T22:29:04.725Z'
        }
      },
      sort: [
        1591321696618
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW647799270202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:47:10.037Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23383.00',
          mileage: '12297'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9492955,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW647799',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:14:16.100Z'
        }
      },
      sort: [
        1591321630037
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F10HX615115270132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:46:29.855Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '33871.00',
          mileage: '57777'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9482057,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F10HX615115',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:02:45.233Z'
        }
      },
      sort: [
        1591321589855
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU196856270202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:45:26.616Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20476.00',
          mileage: '28069'
        },
        sellArea: '500',
        dealerCd: '27020',
        certificationNumber: 9481054,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU196856',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T23:30:47.767Z'
        }
      },
      sort: [
        1591321526616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3HP684934270132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:45:12.956Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14734.00',
          mileage: '22005'
        },
        sellArea: '110',
        dealerCd: '27013',
        certificationNumber: 9496534,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3HP684934',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T00:14:51.514Z'
        }
      },
      sort: [
        1591321512956
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE0LP079342270202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:36:31.632Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20183.00',
          mileage: '4635'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9482671,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE0LP079342',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T00:39:53.436Z'
        }
      },
      sort: [
        1591320991632
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV5JJ744203270202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:35:37.293Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25685.00',
          mileage: '11684'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9487980,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV5JJ744203',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:58:40.909Z'
        }
      },
      sort: [
        1591320937293
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFPXJ3100086040332020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:35:02.653Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23500.00',
          mileage: '17648'
        },
        sellArea: '110',
        dealerCd: '04033',
        certificationNumber: 9460381,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFPXJ3100086',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T00:21:33.568Z'
        }
      },
      sort: [
        1591320902653
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DCXHS883750270202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:34:38.491Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21679.04',
          mileage: '33435'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9481060,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DCXHS883750',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T23:38:43.631Z'
        }
      },
      sort: [
        1591320878491
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP571584040332020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:34:09.372Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14868.00',
          mileage: '15973'
        },
        sellArea: '110',
        dealerCd: '04033',
        certificationNumber: 9456246,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP571584',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T22:07:18.429Z'
        }
      },
      sort: [
        1591320849372
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1FB1KU035778270202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:33:37.512Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '35846.56',
          mileage: '11783'
        },
        sellArea: '120',
        dealerCd: '27020',
        certificationNumber: 9497631,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1FB1KU035778',
        model: {
          modelDescription: 'AVALON 4-DR LIMITED',
          modelSeries: 'AVALON',
          modelNumber: '3554',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:28:08.980Z'
        }
      },
      sort: [
        1591320817512
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8H5464588371812020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:30:30.512Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28970.00',
          mileage: '50600'
        },
        sellArea: '800',
        dealerCd: '37181',
        certificationNumber: 9470636,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8H5464588',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:44:06.810Z'
        }
      },
      sort: [
        1591320630512
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2K5734941371812020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:27:31.849Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '38900.00',
          mileage: '1240'
        },
        sellArea: '220',
        dealerCd: '37181',
        certificationNumber: 9494587,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2K5734941',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:56:55.573Z'
        }
      },
      sort: [
        1591320451849
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV1HW351282042322020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:07:28.100Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19750.00',
          mileage: '49940'
        },
        sellArea: '120',
        dealerCd: '04232',
        certificationNumber: 9456101,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV1HW351282',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T17:43:20.607Z'
        }
      },
      sort: [
        1591319248100
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5ANXKM236455042902020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:05:01.159Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '33266.00',
          mileage: '26357'
        },
        sellArea: '500',
        dealerCd: '04290',
        certificationNumber: 9478581,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5ANXKM236455',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T01:34:17.135Z'
        }
      },
      sort: [
        1591319101159
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN1KM107539042902020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:04:07.939Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '18302'
        },
        sellArea: '110',
        dealerCd: '04290',
        certificationNumber: 9474830,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN1KM107539',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:09:11.677Z'
        }
      },
      sort: [
        1591319047939
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2J5588801042902020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:03:16.498Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '40942.00',
          mileage: '8136'
        },
        sellArea: '110',
        dealerCd: '04290',
        certificationNumber: 9434160,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2J5588801',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T19:25:11.221Z'
        }
      },
      sort: [
        1591318996498
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK9LU874213042902020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T01:01:29.396Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '5277'
        },
        sellArea: '110',
        dealerCd: '04290',
        certificationNumber: 9484610,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK9LU874213',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T00:34:02.841Z'
        }
      },
      sort: [
        1591318889396
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU112076042902020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:59:57.997Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18482.05',
          mileage: '35043'
        },
        sellArea: '600',
        dealerCd: '04290',
        certificationNumber: 9488586,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU112076',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:23:51.087Z'
        }
      },
      sort: [
        1591318797997
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV7HW588365150502020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:55:56.755Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23000.00',
          mileage: '46451'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9473601,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV7HW588365',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T22:42:07.185Z'
        }
      },
      sort: [
        1591318556755
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS442371371742020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:51:53.993Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28100.00',
          mileage: '34537'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9406884,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS442371',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T15:15:13.086Z'
        }
      },
      sort: [
        1591318313993
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW593748371742020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:51:14.614Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22112.74',
          mileage: '31502'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9483094,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW593748',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T13:29:51.452Z'
        }
      },
      sort: [
        1591318274614
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV8HW358830040612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:45:52.352Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19963.00',
          mileage: '63431'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9488588,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV8HW358830',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:26:44.397Z'
        }
      },
      sort: [
        1591317952352
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9HW590828050342020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:34:21.425Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22805.0000',
          mileage: '27183'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9437214,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9HW590828',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T14:48:35.099Z'
        }
      },
      sort: [
        1591317261425
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW579812050342020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:34:18.584Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25384.0000',
          mileage: '25891'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9497148,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW579812',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:23:08.728Z'
        }
      },
      sort: [
        1591317258584
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS515050050342020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:34:15.786Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28692.00',
          mileage: '44456'
        },
        sellArea: '150',
        dealerCd: '05034',
        certificationNumber: 9483333,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS515050',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:00:27.469Z'
        }
      },
      sort: [
        1591317255786
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F18HX621996371382020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:28:12.864Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29888.00',
          mileage: '45970'
        },
        sellArea: '800',
        dealerCd: '37138',
        certificationNumber: 9491240,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F18HX621996',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T14:18:06.043Z'
        }
      },
      sort: [
        1591316892864
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU382700270222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:24:14.880Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19076.00',
          mileage: '30378'
        },
        sellArea: '150',
        dealerCd: '27022',
        certificationNumber: 9429020,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU382700',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T20:05:36.911Z'
        }
      },
      sort: [
        1591316654880
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU066166330302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:23:48.501Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20765.18',
          mileage: '9071'
        },
        sellArea: '230',
        dealerCd: '33030',
        certificationNumber: 9474268,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU066166',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:55:35.073Z'
        }
      },
      sort: [
        1591316628501
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4JJ252618371182020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:22:11.993Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17890.00',
          mileage: '50233'
        },
        sellArea: '800',
        dealerCd: '37118',
        certificationNumber: 9492143,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4JJ252618',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:45:54.100Z'
        }
      },
      sort: [
        1591316531993
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8HS838400270222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:21:52.102Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25262.91',
          mileage: '37674'
        },
        sellArea: '110',
        dealerCd: '27022',
        certificationNumber: 9447992,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8HS838400',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T16:17:37.123Z'
        }
      },
      sort: [
        1591316512102
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH6HS408219270222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:20:50.741Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24999.00',
          mileage: '35591'
        },
        sellArea: '150',
        dealerCd: '27022',
        certificationNumber: 9493087,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH6HS408219',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:45:40.614Z'
        }
      },
      sort: [
        1591316450741
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV5HW388710270222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:19:51.433Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20999.00',
          mileage: '32490'
        },
        sellArea: '150',
        dealerCd: '27022',
        certificationNumber: 9487759,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV5HW388710',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:48:19.735Z'
        }
      },
      sort: [
        1591316391433
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMGZ5AN7HM086489371182020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:19:06.418Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '34160'
        },
        sellArea: '800',
        dealerCd: '37118',
        certificationNumber: 9496903,
        makeCode: 'TOYOTA',
        vin: '3TMGZ5AN7HM086489',
        model: {
          modelDescription: 'TACOMA LIMITED 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7582',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:10:53.872Z'
        }
      },
      sort: [
        1591316346418
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7FU108070270222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:16:35.639Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17316.00',
          mileage: '7511'
        },
        sellArea: '150',
        dealerCd: '27022',
        certificationNumber: 9493108,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7FU108070',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:52:57.539Z'
        }
      },
      sort: [
        1591316195639
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV2KW001298270222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:15:58.317Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18455.14',
          mileage: '66283'
        },
        sellArea: '150',
        dealerCd: '27022',
        certificationNumber: 9464530,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV2KW001298',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T18:00:41.103Z'
        }
      },
      sort: [
        1591316158317
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE2K3052558371382020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:14:55.756Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17100.00',
          mileage: '17657'
        },
        sellArea: '800',
        dealerCd: '37138',
        certificationNumber: 9497015,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE2K3052558',
        model: {
          modelDescription: 'COROLLA HATCHBACK XSE 5DrHATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6275',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:22:22.006Z'
        }
      },
      sort: [
        1591316095756
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0J5533358361042020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-05T00:12:05.079Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '34870.00',
          mileage: '31909'
        },
        sellArea: '130',
        dealerCd: '36104',
        certificationNumber: 9496481,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0J5533358',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:29:00.189Z'
        }
      },
      sort: [
        1591315925079
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU161961190602020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:59:28.111Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19998.00',
          mileage: '32881'
        },
        dealerCd: '19060',
        certificationNumber: 9372257,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU161961',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T16:04:44.028Z'
        }
      },
      sort: [
        1591315168111
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F15JX722650270222020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:56:28.449Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '38942.88',
          mileage: '45272'
        },
        sellArea: '150',
        dealerCd: '27022',
        certificationNumber: 9493097,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F15JX722650',
        model: {
          modelDescription: 'TUNDRA 4X4 PLATINUM CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8375',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:48:18.518Z'
        }
      },
      sort: [
        1591314988449
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU111803270222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:55:50.190Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18652.17',
          mileage: '22215'
        },
        sellArea: '150',
        dealerCd: '27022',
        certificationNumber: 9487735,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU111803',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:40:53.784Z'
        }
      },
      sort: [
        1591314950190
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5G5397219190602020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:55:09.068Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30998.00',
          mileage: '36102'
        },
        dealerCd: '19060',
        certificationNumber: 9391040,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5G5397219',
        model: {
          modelDescription: '4RUNNER 4X4 TRAIL PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T19:28:08.433Z'
        }
      },
      sort: [
        1591314909068
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV9HD095482250602020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:46:05.844Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23990.00',
          mileage: '28828'
        },
        sellArea: '600',
        dealerCd: '25060',
        certificationNumber: 9496415,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV9HD095482',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:45:29.386Z'
        }
      },
      sort: [
        1591314365844
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU441006130752020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:46:02.624Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18209.00',
          mileage: '19397'
        },
        sellArea: '220',
        dealerCd: '13075',
        certificationNumber: 9419025,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU441006',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T20:13:31.291Z'
        }
      },
      sort: [
        1591314362624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9FW390299250602020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:45:11.422Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18490.00',
          mileage: '42728'
        },
        sellArea: '170',
        dealerCd: '25060',
        certificationNumber: 9493281,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9FW390299',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:57:48.902Z'
        }
      },
      sort: [
        1591314311422
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE3HJ544305110302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:45:02.483Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15515.00',
          mileage: '32887'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9476327,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE3HJ544305',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:08:21.492Z'
        }
      },
      sort: [
        1591314302483
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HJ143810341222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:30:50.157Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20255.00',
          mileage: '21792'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9485199,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HJ143810',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:50:45.628Z'
        }
      },
      sort: [
        1591313450157
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH3HS477191340062020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:25:09.892Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24995.00',
          mileage: '16365'
        },
        sellArea: '220',
        dealerCd: '34006',
        certificationNumber: 9492800,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH3HS477191',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:43:46.893Z'
        }
      },
      sort: [
        1591313109892
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC875293480612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:24:55.212Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14322.00',
          mileage: '39848'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9468731,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC875293',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:14:16.941Z'
        }
      },
      sort: [
        1591313095212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU351610480612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:24:35.731Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15780.00',
          mileage: '35578'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9405246,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU351610',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T19:58:28.427Z'
        }
      },
      sort: [
        1591313075731
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8KS010323042652020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:24:00.811Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '28485.84',
          mileage: '26820'
        },
        sellArea: '600',
        dealerCd: '04265',
        certificationNumber: 9468945,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8KS010323',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:46:43.270Z'
        }
      },
      sort: [
        1591313040811
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN5HM018015371872020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:16:42.128Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30452.00',
          mileage: '15567'
        },
        sellArea: '800',
        dealerCd: '37187',
        certificationNumber: 9498057,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN5HM018015',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:32:50.586Z'
        }
      },
      sort: [
        1591312602128
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH1GS502913121382020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:15:00.207Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25500.00',
          mileage: '36410'
        },
        sellArea: '210',
        dealerCd: '12138',
        certificationNumber: 9392135,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH1GS502913',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-22T17:07:18.300Z'
        }
      },
      sort: [
        1591312500207
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC2HS802434360822020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:14:54.229Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22978.00',
          mileage: '32445'
        },
        sellArea: '130',
        dealerCd: '36082',
        certificationNumber: 9438217,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC2HS802434',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T23:39:51.880Z'
        }
      },
      sort: [
        1591312494229
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU187096360822020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:10:55.905Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19204.00',
          mileage: '15987'
        },
        sellArea: '130',
        dealerCd: '36082',
        certificationNumber: 9469019,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU187096',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T20:21:04.877Z'
        }
      },
      sort: [
        1591312255905
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KC015500240532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T23:08:37.105Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24988.00',
          mileage: '21782'
        },
        sellArea: '600',
        dealerCd: '24053',
        certificationNumber: 9411908,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KC015500',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T22:19:59.263Z'
        }
      },
      sort: [
        1591312117105
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHJ700999290372020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:55:51.253Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18600.00',
          mileage: '24722'
        },
        sellArea: '160',
        dealerCd: '29037',
        certificationNumber: 9431601,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHJ700999',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T13:15:08.036Z'
        }
      },
      sort: [
        1591311351253
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2GU596460360952020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:54:23.614Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '40181'
        },
        sellArea: '130',
        dealerCd: '36095',
        certificationNumber: 9458118,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2GU596460',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T02:23:49.825Z'
        }
      },
      sort: [
        1591311263614
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXK3DC8FS636668211012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:49:53.351Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25299.00',
          mileage: '31912'
        },
        sellArea: '190',
        dealerCd: '21101',
        certificationNumber: 9421643,
        makeCode: 'TOYOTA',
        vin: '5TDXK3DC8FS636668',
        model: {
          modelDescription: 'SIENNA SE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5343',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T19:37:53.845Z'
        }
      },
      sort: [
        1591310993351
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4HW610229340942020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:49:22.568Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19660.41',
          mileage: '30741'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9412619,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4HW610229',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T20:30:28.031Z'
        }
      },
      sort: [
        1591310962568
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRX5GNXGX059124043622020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:47:51.309Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '11200'
        },
        sellArea: '120',
        dealerCd: '04362',
        certificationNumber: 9481972,
        makeCode: 'TOYOTA',
        vin: '5TFRX5GNXGX059124',
        model: {
          modelDescription: 'TACOMA SR5 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7122',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:34:08.185Z'
        }
      },
      sort: [
        1591310871309
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5ANXHM077638340932020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:43:47.505Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29990.00',
          mileage: '14026'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9467576,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5ANXHM077638',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T21:32:20.224Z'
        }
      },
      sort: [
        1591310627505
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F18KX840545110362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:43:33.187Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '46991.00',
          mileage: '10873'
        },
        sellArea: '130',
        dealerCd: '11036',
        certificationNumber: 9478416,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F18KX840545',
        model: {
          modelDescription: 'TUNDRA TRD PRO CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8371',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:41:53.820Z'
        }
      },
      sort: [
        1591310613187
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F11JX767491330252020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:39:44.382Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '43851'
        },
        sellArea: '230',
        dealerCd: '33025',
        certificationNumber: 9467223,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F11JX767491',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:05:31.342Z'
        }
      },
      sort: [
        1591310384382
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC9HS789021440222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:34:49.357Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19614.00',
          mileage: '39126'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9457459,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC9HS789021',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T15:26:21.037Z'
        }
      },
      sort: [
        1591310089357
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW566803440222020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:34:06.486Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '21990.00',
          mileage: '47376'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9483673,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW566803',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:00:12.462Z'
        }
      },
      sort: [
        1591310046486
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2GM043420340942020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:33:20.226Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24950.0000',
          mileage: '65455'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9481365,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2GM043420',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T13:18:36.278Z'
        }
      },
      sort: [
        1591310000226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK4JU500244340942020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:33:17.786Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21898.1800',
          mileage: '18456'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9447845,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK4JU500244',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T13:44:54.585Z'
        }
      },
      sort: [
        1591309997786
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU723558340942020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:33:15.406Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15718.0000',
          mileage: '42898'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9440002,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU723558',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-03T19:14:58.805Z'
        }
      },
      sort: [
        1591309995406
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW644177340942020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:33:12.867Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17994.89',
          mileage: '27260'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9448300,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW644177',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T21:42:57.277Z'
        }
      },
      sort: [
        1591309992867
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV5HJ709125440222020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:32:47.867Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '16190.00',
          mileage: '35313'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9451896,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV5HJ709125',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T11:46:19.606Z'
        }
      },
      sort: [
        1591309967867
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB31E1071960440222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:31:16.106Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '8277.00',
          mileage: '75224'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9493985,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB31E1071960',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:18:25.873Z'
        }
      },
      sort: [
        1591309876106
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW635138440222020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:30:37.366Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '18331.00',
          mileage: '35633'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9462611,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW635138',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T14:17:17.541Z'
        }
      },
      sort: [
        1591309837366
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1F11AK1LU901870340932020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:30:27.085Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26389.72',
          mileage: '2748'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9457038,
        makeCode: 'TOYOTA',
        vin: '4T1F11AK1LU901870',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T21:13:30.362Z'
        }
      },
      sort: [
        1591309827085
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV5HD214947440222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:30:06.404Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '22172'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9493891,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV5HD214947',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:33:52.810Z'
        }
      },
      sort: [
        1591309806404
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9GM011581440222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:28:33.525Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '70737'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9486830,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9GM011581',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T11:44:32.419Z'
        }
      },
      sort: [
        1591309713525
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJGRFH9HS029152440222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:28:20.225Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '26197'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9482082,
        makeCode: 'TOYOTA',
        vin: '5TDJGRFH9HS029152',
        model: {
          modelDescription: 'HIGHLANDER HYBRD XLE-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6965',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:09:30.424Z'
        }
      },
      sort: [
        1591309700225
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5EN9GX045977440222020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:28:03.904Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '54814'
        },
        sellArea: '600',
        dealerCd: '44022',
        certificationNumber: 9474109,
        makeCode: 'TOYOTA',
        vin: '5TFSX5EN9GX045977',
        model: {
          modelDescription: 'TACOMA SR 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7514',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T12:49:46.821Z'
        }
      },
      sort: [
        1591309683904
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F19JX741375280362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:27:51.924Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '31891.00',
          mileage: '27313'
        },
        sellArea: '170',
        dealerCd: '28036',
        certificationNumber: 9482448,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F19JX741375',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:28:11.239Z'
        }
      },
      sort: [
        1591309671924
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB5GU198656440222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:27:43.984Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '35985'
        },
        sellArea: '170',
        dealerCd: '44022',
        certificationNumber: 9463993,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB5GU198656',
        model: {
          modelDescription: 'AVALON 4-DR XLE PREMIUM',
          modelSeries: 'AVALON',
          modelNumber: '3546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T11:29:47.046Z'
        }
      },
      sort: [
        1591309663984
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK7GR581409280362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:26:20.083Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14290.60',
          mileage: '30043'
        },
        sellArea: '170',
        dealerCd: '28036',
        certificationNumber: 9488061,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK7GR581409',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:19:15.478Z'
        }
      },
      sort: [
        1591309580083
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU321218280362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:25:23.461Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15991.72',
          mileage: '34832'
        },
        sellArea: '170',
        dealerCd: '28036',
        certificationNumber: 9475867,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU321218',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T15:28:57.291Z'
        }
      },
      sort: [
        1591309523461
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU788645280362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:24:43.802Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18991.00',
          mileage: '16294'
        },
        sellArea: '500',
        dealerCd: '28036',
        certificationNumber: 9488068,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU788645',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:22:17.982Z'
        }
      },
      sort: [
        1591309483802
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9JP739036470252020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:15:29.218Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16697.00',
          mileage: '9187'
        },
        sellArea: '500',
        dealerCd: '47025',
        certificationNumber: 9470516,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9JP739036',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:01:56.305Z'
        }
      },
      sort: [
        1591308929218
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP721897047722020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:15:26.502Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '11750.00',
          mileage: '45231'
        },
        sellArea: '110',
        dealerCd: '04772',
        certificationNumber: 9485856,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP721897',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:45:19.095Z'
        }
      },
      sort: [
        1591308926502
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP0J3076834047722020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:14:51.003Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21550.00',
          mileage: '25859'
        },
        sellArea: '120',
        dealerCd: '04772',
        certificationNumber: 9420873,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP0J3076834',
        model: {
          modelDescription: 'PRIUS PRIME PLUS',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1235',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T22:47:59.690Z'
        }
      },
      sort: [
        1591308891003
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4K5714383310692020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:13:00.223Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '43600.00',
          mileage: '11296'
        },
        sellArea: '160',
        dealerCd: '31069',
        certificationNumber: 9417298,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4K5714383',
        model: {
          modelDescription: '4RUNNER 4X4 TRD PRO V6',
          modelSeries: '4RUNNER',
          modelNumber: '8674',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T19:18:43.234Z'
        }
      },
      sort: [
        1591308780223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ147897371842020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:11:16.583Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20455.00',
          mileage: '34157'
        },
        sellArea: '800',
        dealerCd: '37184',
        certificationNumber: 9481317,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ147897',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T12:41:55.272Z'
        }
      },
      sort: [
        1591308676583
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV9HW592372310392020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:09:53.302Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '22480.00',
          mileage: '28407'
        },
        sellArea: '160',
        dealerCd: '31039',
        certificationNumber: 9484505,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV9HW592372',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:51:41.010Z'
        }
      },
      sort: [
        1591308593302
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS392330121342020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:09:41.403Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26700.00',
          mileage: '43193'
        },
        sellArea: '210',
        dealerCd: '12134',
        certificationNumber: 9445677,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS392330',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T22:08:44.596Z'
        }
      },
      sort: [
        1591308581403
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3G1RFV9LW104618121342020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:03:55.182Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25545.47',
          mileage: '58'
        },
        sellArea: '210',
        dealerCd: '12134',
        certificationNumber: 9497144,
        makeCode: 'TOYOTA',
        vin: '2T3G1RFV9LW104618',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:21:20.602Z'
        }
      },
      sort: [
        1591308235182
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH7GS349552311052020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:02:02.162Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28150.00',
          mileage: '17487'
        },
        sellArea: '160',
        dealerCd: '31105',
        certificationNumber: 9429866,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH7GS349552',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T18:47:40.901Z'
        }
      },
      sort: [
        1591308122162
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HJ707990371812020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:00:36.624Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '30297'
        },
        sellArea: '800',
        dealerCd: '37181',
        certificationNumber: 9481826,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HJ707990',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T17:33:31.409Z'
        }
      },
      sort: [
        1591308036624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV9HW655597341222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T22:00:04.039Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22989.00',
          mileage: '23688'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9495162,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV9HW655597',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:24:54.601Z'
        }
      },
      sort: [
        1591308004039
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV3JY323715040792020-05-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:57:08.117Z',
          retailDate: '2020-05-10',
          rdrStatus: 'H',
          soldAmount: '14995.00',
          mileage: '20284'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9471877,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV3JY323715',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T23:00:15.911Z'
        }
      },
      sort: [
        1591307828117
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV4GW533423291052020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:56:23.183Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '38291'
        },
        sellArea: '160',
        dealerCd: '29105',
        certificationNumber: 9420148,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV4GW533423',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T17:41:05.318Z'
        }
      },
      sort: [
        1591307783183
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU268517371842020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:54:23.223Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17866.00',
          mileage: '26962'
        },
        sellArea: '160',
        dealerCd: '37184',
        certificationNumber: 9471064,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU268517',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T17:53:08.194Z'
        }
      },
      sort: [
        1591307663223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW574737291052020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:53:09.784Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '17845.00',
          mileage: '31658'
        },
        sellArea: '160',
        dealerCd: '29105',
        certificationNumber: 9429050,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW574737',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T20:34:26.547Z'
        }
      },
      sort: [
        1591307589784
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3G1RFV8KW074459361032020-04-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:52:53.844Z',
          retailDate: '2020-04-09',
          rdrStatus: 'H',
          soldAmount: '24987.00',
          mileage: '4878'
        },
        sellArea: '130',
        dealerCd: '36103',
        certificationNumber: 9498235,
        makeCode: 'TOYOTA',
        vin: '2T3G1RFV8KW074459',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:51:09.307Z'
        }
      },
      sort: [
        1591307573844
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE1LP030143290622020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:52:11.604Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17999.00',
          mileage: '2553'
        },
        sellArea: '800',
        dealerCd: '29062',
        certificationNumber: 9498229,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE1LP030143',
        model: {
          modelDescription: 'COROLLA SE NIGHTSHADE',
          modelSeries: 'COROLLA',
          modelNumber: '1868',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T21:49:37.365Z'
        }
      },
      sort: [
        1591307531604
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN8GM004107311852020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:52:07.982Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27387.00',
          mileage: '58768'
        },
        sellArea: '160',
        dealerCd: '31185',
        certificationNumber: 9427534,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN8GM004107',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T20:10:57.132Z'
        }
      },
      sort: [
        1591307527982
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7GC555660040792020-05-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:51:36.425Z',
          retailDate: '2020-05-07',
          rdrStatus: 'H',
          soldAmount: '13388.00',
          mileage: '35837'
        },
        sellArea: '500',
        dealerCd: '04079',
        certificationNumber: 9472043,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7GC555660',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T00:43:54.510Z'
        }
      },
      sort: [
        1591307496425
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW585678291052020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:50:16.103Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '17882.00',
          mileage: '29585'
        },
        sellArea: '160',
        dealerCd: '29105',
        certificationNumber: 9463558,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW585678',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T20:50:57.480Z'
        }
      },
      sort: [
        1591307416103
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC876372480282020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:49:46.143Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14911.00',
          mileage: '29101'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9494753,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC876372',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:45:51.535Z'
        }
      },
      sort: [
        1591307386143
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV2HJ163671440132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:47:52.482Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '41651'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9484988,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV2HJ163671',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T12:30:22.453Z'
        }
      },
      sort: [
        1591307272482
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW657168291052020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:47:03.903Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '17918.00',
          mileage: '30438'
        },
        sellArea: '160',
        dealerCd: '29105',
        certificationNumber: 9429054,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW657168',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T20:36:49.886Z'
        }
      },
      sort: [
        1591307223903
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV2KW043695291052020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:44:06.724Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '21337.00',
          mileage: '8752'
        },
        sellArea: '160',
        dealerCd: '29105',
        certificationNumber: 9428365,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV2KW043695',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T15:31:14.676Z'
        }
      },
      sort: [
        1591307046724
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KC014745460812020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:44:00.064Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26488.00',
          mileage: '10403'
        },
        sellArea: '600',
        dealerCd: '46081',
        certificationNumber: 9407802,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KC014745',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T00:09:05.145Z'
        }
      },
      sort: [
        1591307040064
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP686469121122020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:43:20.944Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '13375.68',
          mileage: '42684'
        },
        dealerCd: '12112',
        certificationNumber: 9455367,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP686469',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T16:48:39.415Z'
        }
      },
      sort: [
        1591307000944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB8GU232900341232020-04-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:37:50.485Z',
          retailDate: '2020-04-30',
          rdrStatus: 'H',
          soldAmount: '21998.00',
          mileage: '26178'
        },
        sellArea: '220',
        dealerCd: '34123',
        certificationNumber: 9454537,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB8GU232900',
        model: {
          modelDescription: 'AVALON 4-DR TOURING',
          modelSeries: 'AVALON',
          modelNumber: '3548',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T22:56:09.122Z'
        }
      },
      sort: [
        1591306670485
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU781825040792020-05-11H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:37:21.125Z',
          retailDate: '2020-05-11',
          rdrStatus: 'H',
          soldAmount: '20609.00',
          mileage: '8335'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9415899,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU781825',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T21:05:20.491Z'
        }
      },
      sort: [
        1591306641125
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6H5414739341232020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:36:10.505Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '32998.00',
          mileage: '28689'
        },
        sellArea: '220',
        dealerCd: '34123',
        certificationNumber: 9397435,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6H5414739',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T18:45:38.201Z'
        }
      },
      sort: [
        1591306570505
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4JU104949040792020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:35:48.486Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '19988.00',
          mileage: '21370'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9430912,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4JU104949',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T18:51:53.911Z'
        }
      },
      sort: [
        1591306548486
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV7HY170671040792020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:34:52.044Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '14288.00',
          mileage: '27337'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9432280,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV7HY170671',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T20:10:00.248Z'
        }
      },
      sort: [
        1591306492044
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC783559250642020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:34:50.304Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16490.00',
          mileage: '29405'
        },
        sellArea: '130',
        dealerCd: '25064',
        certificationNumber: 9487983,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC783559',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:59:02.854Z'
        }
      },
      sort: [
        1591306490304
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV2HJ148665480612020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:33:46.729Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22913.00',
          mileage: '28661'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9446168,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV2HJ148665',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-20T17:54:40.926Z'
        }
      },
      sort: [
        1591306426729
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYVXHY166503040792020-05-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:33:46.265Z',
          retailDate: '2020-05-02',
          rdrStatus: 'H',
          soldAmount: '12578.00',
          mileage: '21094'
        },
        dealerCd: '04079',
        certificationNumber: 9390891,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYVXHY166503',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T18:16:44.698Z'
        }
      },
      sort: [
        1591306426265
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8GJ087876220472020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:33:41.406Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16799.00',
          mileage: '30323'
        },
        dealerCd: '22047',
        certificationNumber: 9215739,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8GJ087876',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-25T21:31:28.054Z'
        }
      },
      sort: [
        1591306421406
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV6HW383399040792020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:32:25.818Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '20900.00',
          mileage: '32345'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9472040,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV6HW383399',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T00:38:36.686Z'
        }
      },
      sort: [
        1591306345818
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH9HS187473040792020-05-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:31:13.602Z',
          retailDate: '2020-05-07',
          rdrStatus: 'H',
          soldAmount: '30320.00',
          mileage: '36077'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9422948,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH9HS187473',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T20:57:12.874Z'
        }
      },
      sort: [
        1591306273602
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9HM091160040792020-05-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:29:31.175Z',
          retailDate: '2020-05-07',
          rdrStatus: 'H',
          soldAmount: '30188.00',
          mileage: '42412'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9422925,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9HM091160',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T20:40:49.614Z'
        }
      },
      sort: [
        1591306171175
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN9HM026359040792020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:28:13.225Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '26988.00',
          mileage: '40568'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9412183,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN9HM026359',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T00:06:56.549Z'
        }
      },
      sort: [
        1591306093225
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'VNKKTUD31GA067603040792020-05-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:26:51.366Z',
          retailDate: '2020-05-03',
          rdrStatus: 'H',
          soldAmount: '8600.00',
          mileage: '67863'
        },
        sellArea: '500',
        dealerCd: '04079',
        certificationNumber: 9427661,
        makeCode: 'TOYOTA',
        vin: 'VNKKTUD31GA067603',
        model: {
          modelDescription: 'YARIS 5-DR L LIFTBACK',
          modelSeries: 'YARIS LIFTBACK',
          modelNumber: '1462',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T21:05:53.586Z'
        }
      },
      sort: [
        1591306011366
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU2G3004237040792020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:25:42.865Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '51189'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9430903,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU2G3004237',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T18:47:10.169Z'
        }
      },
      sort: [
        1591305942865
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU228777180362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:25:38.165Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23500.00',
          mileage: '32086'
        },
        sellArea: '600',
        dealerCd: '18036',
        certificationNumber: 9492059,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU228777',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:17:52.548Z'
        }
      },
      sort: [
        1591305938165
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFJU4GN7FX064750040792020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:23:35.299Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '25288.00',
          mileage: '37519'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9408443,
        makeCode: 'TOYOTA',
        vin: '5TFJU4GN7FX064750',
        model: {
          modelDescription: 'TACOMA PRERUNNER DOUBLE CAB 4X2 V6',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7188',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T16:32:25.139Z'
        }
      },
      sort: [
        1591305815299
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KC005432180362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:23:19.239Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '27305'
        },
        sellArea: '600',
        dealerCd: '18036',
        certificationNumber: 9492472,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KC005432',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:03:42.369Z'
        }
      },
      sort: [
        1591305799239
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKK3DC7FS618799040792020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:22:35.038Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '16188.00',
          mileage: '79951'
        },
        sellArea: '600',
        dealerCd: '04079',
        certificationNumber: 9418701,
        makeCode: 'TOYOTA',
        vin: '5TDKK3DC7FS618799',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T17:27:55.007Z'
        }
      },
      sort: [
        1591305755038
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV3HW609098341222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:22:00.177Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21988.00',
          mileage: '32522'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9485213,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV3HW609098',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:54:54.749Z'
        }
      },
      sort: [
        1591305720177
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXH5463216040792020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:21:07.578Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '31435.55',
          mileage: '37342'
        },
        sellArea: '110',
        dealerCd: '04079',
        certificationNumber: 9454448,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXH5463216',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T22:33:56.112Z'
        }
      },
      sort: [
        1591305667578
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1FK9HU582780040792020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:20:17.196Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20388.00',
          mileage: '30257'
        },
        dealerCd: '04079',
        certificationNumber: 9389113,
        makeCode: 'TOYOTA',
        vin: '4T1BK1FK9HU582780',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2554',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T17:06:16.782Z'
        }
      },
      sort: [
        1591305617196
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBJV3LY700492311672020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:19:39.958Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '2604'
        },
        sellArea: '160',
        dealerCd: '31167',
        certificationNumber: 9497907,
        makeCode: 'TOYOTA',
        vin: '3MYDLBJV3LY700492',
        model: {
          modelDescription: 'YARIS HATCHBACK 5DR HATCHBACK LE',
          modelSeries: 'YARIS LIFTBACK',
          modelNumber: '1466',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:53:58.142Z'
        }
      },
      sort: [
        1591305579958
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH6HS453069140152020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:19:12.676Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '29798.00',
          mileage: '27343'
        },
        sellArea: '230',
        dealerCd: '14015',
        certificationNumber: 9470643,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH6HS453069',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:46:09.630Z'
        }
      },
      sort: [
        1591305552676
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5J5571071430222020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:18:33.917Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '33559.00',
          mileage: '23927'
        },
        sellArea: '150',
        dealerCd: '43022',
        certificationNumber: 9493729,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5J5571071',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:57:17.134Z'
        }
      },
      sort: [
        1591305513917
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU435605430222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:17:47.537Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19250.00',
          mileage: '12213'
        },
        sellArea: '150',
        dealerCd: '43022',
        certificationNumber: 9495589,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU435605',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:32:56.685Z'
        }
      },
      sort: [
        1591305467537
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFV0LD049473060022020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:14:55.456Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27999.00',
          mileage: '2241'
        },
        sellArea: '160',
        dealerCd: '06002',
        certificationNumber: 9432962,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFV0LD049473',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T16:38:03.896Z'
        }
      },
      sort: [
        1591305295456
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F19JX749840330302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:14:13.895Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '34990.00',
          mileage: '16450'
        },
        sellArea: '230',
        dealerCd: '33030',
        certificationNumber: 9424317,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F19JX749840',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T20:01:54.720Z'
        }
      },
      sort: [
        1591305253895
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE0LP016086040802020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:12:37.515Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17992.9600',
          mileage: '14900'
        },
        sellArea: '600',
        dealerCd: '04080',
        certificationNumber: 9469163,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE0LP016086',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T21:45:17.660Z'
        }
      },
      sort: [
        1591305157515
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP697889040802020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:12:34.096Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15695.0000',
          mileage: '19606'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9486004,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP697889',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:51:12.223Z'
        }
      },
      sort: [
        1591305154096
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP637989040802020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:12:31.534Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15493.0000',
          mileage: '23810'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9485925,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP637989',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:09:41.709Z'
        }
      },
      sort: [
        1591305151534
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU415312040802020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:12:28.835Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15495.00',
          mileage: '50422'
        },
        sellArea: '110',
        dealerCd: '04080',
        certificationNumber: 9476816,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU415312',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T00:11:12.790Z'
        }
      },
      sort: [
        1591305148835
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3HW614623330302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:07:07.851Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24890.00',
          mileage: '13191'
        },
        sellArea: '230',
        dealerCd: '33030',
        certificationNumber: 9491613,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3HW614623',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:28:49.023Z'
        }
      },
      sort: [
        1591304827851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7J5528786480282020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:06:54.292Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32858.00',
          mileage: '51688'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9456865,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7J5528786',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T18:19:24.760Z'
        }
      },
      sort: [
        1591304814292
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3GP541528041222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:05:24.932Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14450.00',
          mileage: '19573'
        },
        sellArea: '110',
        dealerCd: '04122',
        certificationNumber: 9487873,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3GP541528',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:27:06.149Z'
        }
      },
      sort: [
        1591304724932
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0K5682256020482020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:04:35.072Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '41620.56',
          mileage: '2423'
        },
        sellArea: '500',
        dealerCd: '02048',
        certificationNumber: 9497723,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0K5682256',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:59:25.761Z'
        }
      },
      sort: [
        1591304675072
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5KU823077410572020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:03:51.371Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22352.00',
          mileage: '10715'
        },
        sellArea: '500',
        dealerCd: '41057',
        certificationNumber: 9471096,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5KU823077',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:09:22.778Z'
        }
      },
      sort: [
        1591304631371
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6LC066184020162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:03:41.870Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33496.35',
          mileage: '10818'
        },
        sellArea: '600',
        dealerCd: '02016',
        certificationNumber: 9487855,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6LC066184',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:20:37.800Z'
        }
      },
      sort: [
        1591304621870
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1JM121404201012020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:02:44.891Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28375.00',
          mileage: '10130'
        },
        sellArea: '170',
        dealerCd: '20101',
        certificationNumber: 9418781,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1JM121404',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T17:59:33.515Z'
        }
      },
      sort: [
        1591304564891
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA18H8706268041222020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:01:33.718Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20950.00',
          mileage: '22331'
        },
        sellArea: '120',
        dealerCd: '04122',
        certificationNumber: 9424382,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA18H8706268',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T20:26:26.543Z'
        }
      },
      sort: [
        1591304493718
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW600961201012020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:01:19.347Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18791.00',
          mileage: '35781'
        },
        sellArea: '170',
        dealerCd: '20101',
        certificationNumber: 9413929,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW600961',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T19:12:07.789Z'
        }
      },
      sort: [
        1591304479347
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2LW086527020162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:01:03.986Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29706.00',
          mileage: '9495'
        },
        sellArea: '600',
        dealerCd: '02016',
        certificationNumber: 9484099,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2LW086527',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:43:56.291Z'
        }
      },
      sort: [
        1591304463986
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ702350201012020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T21:00:00.007Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '20928.00',
          mileage: '29785'
        },
        sellArea: '170',
        dealerCd: '20101',
        certificationNumber: 9461211,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HJ702350',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T19:22:08.046Z'
        }
      },
      sort: [
        1591304400007
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE3LP077214020162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:59:24.227Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19411.00',
          mileage: '7563'
        },
        sellArea: '600',
        dealerCd: '02016',
        certificationNumber: 9486173,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE3LP077214',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:22:51.124Z'
        }
      },
      sort: [
        1591304364227
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS448792201012020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:58:44.008Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '30099.00',
          mileage: '29104'
        },
        sellArea: '170',
        dealerCd: '20101',
        certificationNumber: 9492769,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS448792',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:35:52.390Z'
        }
      },
      sort: [
        1591304324008
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE7HJ541892020162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:57:41.128Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16202.33',
          mileage: '21418'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9444047,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE7HJ541892',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6273',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T20:26:49.283Z'
        }
      },
      sort: [
        1591304261128
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK2LU910761049152020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:54:07.888Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27445.00',
          mileage: '7356'
        },
        sellArea: '110',
        dealerCd: '04915',
        certificationNumber: 9490218,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK2LU910761',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T00:48:46.352Z'
        }
      },
      sort: [
        1591304047888
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5ANXHX060476371512020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:53:29.388Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30900.00',
          mileage: '31546'
        },
        sellArea: '800',
        dealerCd: '37151',
        certificationNumber: 9464627,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5ANXHX060476',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T18:53:32.448Z'
        }
      },
      sort: [
        1591304009388
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F13HX613501201012020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:53:02.986Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '31499.00',
          mileage: '25556'
        },
        sellArea: '170',
        dealerCd: '20101',
        certificationNumber: 9464146,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F13HX613501',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T14:10:40.574Z'
        }
      },
      sort: [
        1591303982986
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP5H3016316310692020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:52:59.246Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18499.00',
          mileage: '33589'
        },
        dealerCd: '31069',
        certificationNumber: 9314297,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP5H3016316',
        model: {
          modelDescription: 'PRIUS PRIME PLUS',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1235',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-31T14:42:18.324Z'
        }
      },
      sort: [
        1591303979246
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F13JX716116020162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:52:06.547Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35911.00',
          mileage: '32587'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9498061,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F13JX716116',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:34:31.193Z'
        }
      },
      sort: [
        1591303926547
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH6HS020860050702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:48:16.647Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31088.00',
          mileage: '60294'
        },
        sellArea: '150',
        dealerCd: '05070',
        certificationNumber: 9444261,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH6HS020860',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T15:43:12.650Z'
        }
      },
      sort: [
        1591303696647
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK9JU511750311562020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:48:06.968Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24569.00',
          mileage: '28395'
        },
        sellArea: '160',
        dealerCd: '31156',
        certificationNumber: 9424110,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK9JU511750',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T18:47:44.499Z'
        }
      },
      sort: [
        1591303686968
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXG5396289311362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:48:04.576Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31990.00',
          mileage: '20339'
        },
        sellArea: '160',
        dealerCd: '31136',
        certificationNumber: 9464952,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXG5396289',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T21:58:59.118Z'
        }
      },
      sort: [
        1591303684576
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU802676380242020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:47:23.468Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17991.00',
          mileage: '14592'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9489043,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU802676',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T14:18:08.440Z'
        }
      },
      sort: [
        1591303643468
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH2KS719288371492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:46:15.728Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '40590.00',
          mileage: '3501'
        },
        dealerCd: '37149',
        certificationNumber: 9368400,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH2KS719288',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T20:21:58.202Z'
        }
      },
      sort: [
        1591303575728
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5GU187501046882020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:44:29.708Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14482.00',
          mileage: '67778'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9452230,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5GU187501',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T17:09:22.193Z'
        }
      },
      sort: [
        1591303469708
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ141857210652020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:44:03.008Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21250.00',
          mileage: '20869'
        },
        sellArea: '160',
        dealerCd: '21065',
        certificationNumber: 9476682,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HJ141857',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:33:43.670Z'
        }
      },
      sort: [
        1591303443008
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1EB0HU058008480302020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:42:51.367Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '24995.00',
          mileage: '21869'
        },
        sellArea: '210',
        dealerCd: '48030',
        certificationNumber: 9498067,
        makeCode: 'TOYOTA',
        vin: '4T1BD1EB0HU058008',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:36:54.287Z'
        }
      },
      sort: [
        1591303371367
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV2HY172554270132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:41:27.149Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '11998.00',
          mileage: '47560'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9494554,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV2HY172554',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:44:27.039Z'
        }
      },
      sort: [
        1591303287149
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1HW587776210652020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:41:05.949Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24999.00',
          mileage: '34369'
        },
        sellArea: '210',
        dealerCd: '21065',
        certificationNumber: 9401267,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1HW587776',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T18:28:02.333Z'
        }
      },
      sort: [
        1591303265949
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC890966371742020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:40:11.669Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14752.00',
          mileage: '15150'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9498065,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC890966',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:36:04.787Z'
        }
      },
      sort: [
        1591303211669
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS433274260212020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:39:29.329Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '27999.00',
          mileage: '42789'
        },
        sellArea: '230',
        dealerCd: '26021',
        certificationNumber: 9487510,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS433274',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:15:52.615Z'
        }
      },
      sort: [
        1591303169329
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP689372040132020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:37:26.270Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '14992.00',
          mileage: '38796'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497842,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP689372',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:35:53.481Z'
        }
      },
      sort: [
        1591303046270
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS428430120902020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:37:15.630Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26997.00',
          mileage: '39210'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9491141,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS428430',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T13:49:07.307Z'
        }
      },
      sort: [
        1591303035630
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU774645120902020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:35:54.529Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19888.00',
          mileage: '23089'
        },
        sellArea: '500',
        dealerCd: '12090',
        certificationNumber: 9419976,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU774645',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T16:17:02.438Z'
        }
      },
      sort: [
        1591302954529
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP673129040132020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:35:07.890Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '14792.00',
          mileage: '18683'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497790,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP673129',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:22:33.619Z'
        }
      },
      sort: [
        1591302907890
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW630956120902020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:34:28.329Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19275.00',
          mileage: '19269'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9491497,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW630956',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:53:05.723Z'
        }
      },
      sort: [
        1591302868329
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV8HD147632140442020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:34:16.868Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22370.74',
          mileage: '18448'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9492117,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV8HD147632',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:34:59.221Z'
        }
      },
      sort: [
        1591302856868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV5KC016987480272020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:33:57.787Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24559.00',
          mileage: '24689'
        },
        sellArea: '600',
        dealerCd: '48027',
        certificationNumber: 9492360,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV5KC016987',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:38:20.243Z'
        }
      },
      sort: [
        1591302837787
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP675661040132020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:33:16.207Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '15692.00',
          mileage: '19556'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497785,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP675661',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:21:15.362Z'
        }
      },
      sort: [
        1591302796207
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH1HS422456120902020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:33:09.948Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29916.00',
          mileage: '16815'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9487087,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH1HS422456',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T14:43:58.766Z'
        }
      },
      sort: [
        1591302789948
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS511233340932020-03-08V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:32:39.056Z',
          retailDate: '2020-03-08',
          rdrStatus: 'V',
          soldAmount: '30989.00',
          mileage: '34265'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9418234,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS511233',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T14:09:19.855Z'
        }
      },
      sort: [
        1591302759056
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW591291120902020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:31:46.628Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19888.00',
          mileage: '33630'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9475954,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW591291',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:26:15.317Z'
        }
      },
      sort: [
        1591302706628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW652353140442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:30:42.870Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21477.00',
          mileage: '27370'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9432954,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW652353',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T16:36:16.380Z'
        }
      },
      sort: [
        1591302642870
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6HP687164040132020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:30:39.757Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '12992.00',
          mileage: '60003'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497780,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6HP687164',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:19:18.660Z'
        }
      },
      sort: [
        1591302639757
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3HW582420200192020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:30:34.377Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23800.00',
          mileage: '26376'
        },
        dealerCd: '20019',
        certificationNumber: 9391121,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3HW582420',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T20:00:03.725Z'
        }
      },
      sort: [
        1591302634377
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP688830040132020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:28:28.995Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '15592.00',
          mileage: '33865'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497730,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP688830',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:00:55.101Z'
        }
      },
      sort: [
        1591302508995
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV1HW657568311052020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:27:25.835Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23500.00',
          mileage: '19275'
        },
        sellArea: '160',
        dealerCd: '31105',
        certificationNumber: 9421928,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV1HW657568',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-15T15:56:00.990Z'
        }
      },
      sort: [
        1591302445835
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW623122311052020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:25:23.714Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24400.00',
          mileage: '30143'
        },
        sellArea: '160',
        dealerCd: '31105',
        certificationNumber: 9416176,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW623122',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T00:09:30.548Z'
        }
      },
      sort: [
        1591302323714
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6EP153013220302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:23:14.992Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '10000.00',
          mileage: '79363'
        },
        sellArea: '230',
        dealerCd: '22030',
        certificationNumber: 9483320,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6EP153013',
        model: {
          modelDescription: 'COROLLA LE PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1854',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:56:26.143Z'
        }
      },
      sort: [
        1591302194992
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F17KX834364190712020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:19:51.413Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '39099.00',
          mileage: '9085'
        },
        sellArea: '800',
        dealerCd: '19071',
        certificationNumber: 9487897,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F17KX834364',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:36:46.223Z'
        }
      },
      sort: [
        1591301991413
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5EN1HX053587460502020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:18:15.891Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '22542.00',
          mileage: '20978'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9497992,
        makeCode: 'TOYOTA',
        vin: '5TFSX5EN1HX053587',
        model: {
          modelDescription: 'TACOMA SR 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7514',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:11:22.932Z'
        }
      },
      sort: [
        1591301895891
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC899343060262020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:17:19.811Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13000.00',
          mileage: '34450'
        },
        sellArea: '160',
        dealerCd: '06026',
        certificationNumber: 9491235,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC899343',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T14:16:15.265Z'
        }
      },
      sort: [
        1591301839811
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU440163480302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:16:45.371Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '27219'
        },
        sellArea: '210',
        dealerCd: '48030',
        certificationNumber: 9497997,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU440163',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T20:12:59.696Z'
        }
      },
      sort: [
        1591301805371
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU184382160042020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:16:36.630Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21686.00',
          mileage: '33215'
        },
        sellArea: '500',
        dealerCd: '16004',
        certificationNumber: 9422068,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU184382',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T13:44:06.446Z'
        }
      },
      sort: [
        1591301796630
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP679067040132020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:14:33.791Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14492.00',
          mileage: '28408'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497722,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP679067',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:58:59.160Z'
        }
      },
      sort: [
        1591301673791
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC5LS057763120902020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:13:28.789Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '32867.00',
          mileage: '3450'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9487200,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC5LS057763',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:25:12.157Z'
        }
      },
      sort: [
        1591301608789
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV1HJ130651380312020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:11:51.709Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '16761'
        },
        dealerCd: '38031',
        certificationNumber: 9361530,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV1HJ130651',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-30T19:27:38.010Z'
        }
      },
      sort: [
        1591301511709
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP630103040132020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:11:09.588Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '15992.00',
          mileage: '22163'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497715,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP630103',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:56:32.418Z'
        }
      },
      sort: [
        1591301469588
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV1HD081916430202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:10:08.086Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22996.00',
          mileage: '38040'
        },
        sellArea: '150',
        dealerCd: '43020',
        certificationNumber: 9489600,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV1HD081916',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:08:38.977Z'
        }
      },
      sort: [
        1591301408086
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN1KX187947470282020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:09:56.828Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '33677.00',
          mileage: '13214'
        },
        sellArea: '800',
        dealerCd: '47028',
        certificationNumber: 9479652,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN1KX187947',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7543',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T21:45:29.814Z'
        }
      },
      sort: [
        1591301396828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN3GX010615311392020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:09:35.513Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29480.00',
          mileage: '40896'
        },
        sellArea: '160',
        dealerCd: '31139',
        certificationNumber: 9493812,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN3GX010615',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7545',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:54:46.808Z'
        }
      },
      sort: [
        1591301375513
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRM5F12HX118529046492020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:08:29.053Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24927.00',
          mileage: '45526'
        },
        sellArea: '110',
        dealerCd: '04649',
        certificationNumber: 9421809,
        makeCode: 'TOYOTA',
        vin: '5TFRM5F12HX118529',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8240',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T23:50:15.542Z'
        }
      },
      sort: [
        1591301309053
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KC009068430202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:08:18.774Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24418.00',
          mileage: '15863'
        },
        sellArea: '500',
        dealerCd: '43020',
        certificationNumber: 9420028,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KC009068',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T16:36:34.950Z'
        }
      },
      sort: [
        1591301298774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3JU555919046492020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:07:46.892Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '19783'
        },
        dealerCd: '04649',
        certificationNumber: 9377256,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3JU555919',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-11T22:43:23.142Z'
        }
      },
      sort: [
        1591301266892
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4GW416248130442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:07:44.696Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17690.00',
          mileage: '42788'
        },
        dealerCd: '13044',
        certificationNumber: 9353794,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4GW416248',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-25T15:07:59.301Z'
        }
      },
      sort: [
        1591301264696
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9JU598337430202020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:07:19.069Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18702.00',
          mileage: '33919'
        },
        sellArea: '150',
        dealerCd: '43020',
        certificationNumber: 9451407,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9JU598337',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T17:55:56.822Z'
        }
      },
      sort: [
        1591301239069
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW634439190612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:06:12.929Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '34396'
        },
        sellArea: '160',
        dealerCd: '19061',
        certificationNumber: 9474950,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW634439',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:10:02.686Z'
        }
      },
      sort: [
        1591301172929
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV9HJ130539046492020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:06:00.649Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19898.00',
          mileage: '46122'
        },
        sellArea: '110',
        dealerCd: '04649',
        certificationNumber: 9479821,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV9HJ130539',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-24T01:42:54.438Z'
        }
      },
      sort: [
        1591301160649
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KW059826430202020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:05:48.471Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23987.00',
          mileage: '25426'
        },
        sellArea: '600',
        dealerCd: '43020',
        certificationNumber: 9437477,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KW059826',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T17:15:33.173Z'
        }
      },
      sort: [
        1591301148471
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP678428040132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:05:37.508Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15492.00',
          mileage: '29453'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497710,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP678428',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:53:06.555Z'
        }
      },
      sort: [
        1591301137508
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9HW616456220342020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:05:20.388Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23000.00',
          mileage: '38087'
        },
        sellArea: '210',
        dealerCd: '22034',
        certificationNumber: 9493771,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9HW616456',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:25:10.678Z'
        }
      },
      sort: [
        1591301120388
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU2HJ071602190612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:04:55.228Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20227.00',
          mileage: '47201'
        },
        sellArea: '800',
        dealerCd: '19061',
        certificationNumber: 9475658,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU2HJ071602',
        model: {
          modelDescription: 'Prius v Two Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1243',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T13:07:56.149Z'
        }
      },
      sort: [
        1591301095228
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS371654190612020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:03:29.926Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26720.00',
          mileage: '35394'
        },
        sellArea: '800',
        dealerCd: '19061',
        certificationNumber: 9440106,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS371654',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-03T21:04:46.759Z'
        }
      },
      sort: [
        1591301009926
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8FJ013922180342020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:03:14.826Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15399.00',
          mileage: '71363'
        },
        sellArea: '160',
        dealerCd: '18034',
        certificationNumber: 9495503,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8FJ013922',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:53:46.001Z'
        }
      },
      sort: [
        1591300994826
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDFPRAEXLJ000316045542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:02:52.204Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21655.0000',
          mileage: '8965'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9476746,
        makeCode: 'TOYOTA',
        vin: 'JTDFPRAEXLJ000316',
        model: {
          modelDescription: 'COROLLA XLE',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T22:49:59.611Z'
        }
      },
      sort: [
        1591300972204
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU434480045542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:02:49.825Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17981.0000',
          mileage: '24234'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9486031,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU434480',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:08:17.081Z'
        }
      },
      sort: [
        1591300969825
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE5HJ533600045542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:02:47.385Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15867.00',
          mileage: '37322'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9455212,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE5HJ533600',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T14:30:36.384Z'
        }
      },
      sort: [
        1591300967385
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW605889120902020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:02:21.204Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18996.00',
          mileage: '33104'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9415408,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW605889',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T17:56:21.801Z'
        }
      },
      sort: [
        1591300941204
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2FP280598040132020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:01:51.924Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '10992.00',
          mileage: '69952'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497706,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2FP280598',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:51:27.559Z'
        }
      },
      sort: [
        1591300911924
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU255579120902020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:01:27.991Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18988.00',
          mileage: '15999'
        },
        sellArea: '500',
        dealerCd: '12090',
        certificationNumber: 9441804,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU255579',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T15:33:49.985Z'
        }
      },
      sort: [
        1591300887991
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KW063349430202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T20:01:00.375Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24545.00',
          mileage: '19980'
        },
        sellArea: '600',
        dealerCd: '43020',
        certificationNumber: 9434338,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KW063349',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T21:37:07.164Z'
        }
      },
      sort: [
        1591300860375
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH6HS424428430202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:59:47.182Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31538.00',
          mileage: '42444'
        },
        sellArea: '230',
        dealerCd: '43020',
        certificationNumber: 9493864,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH6HS424428',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:21:53.313Z'
        }
      },
      sort: [
        1591300787182
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6HP671062040132020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:59:20.781Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '15592.00',
          mileage: '19917'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497703,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6HP671062',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:50:26.039Z'
        }
      },
      sort: [
        1591300760781
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDGZRAH6LS502199040132020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:57:10.398Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '37592.00',
          mileage: '1402'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497700,
        makeCode: 'TOYOTA',
        vin: '5TDGZRAH6LS502199',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: null,
          modelNumber: '6951',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:49:15.554Z'
        }
      },
      sort: [
        1591300630398
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJEXHJ537836430202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:57:05.356Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13200.00',
          mileage: '47833'
        },
        sellArea: '110',
        dealerCd: '43020',
        certificationNumber: 9452334,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJEXHJ537836',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T17:55:58.064Z'
        }
      },
      sort: [
        1591300625356
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS372778440132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:56:56.458Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28500.00',
          mileage: '25727'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9485098,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS372778',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:07:20.705Z'
        }
      },
      sort: [
        1591300616458
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK1HU213275430202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:56:06.005Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16354.00',
          mileage: '50203'
        },
        sellArea: '150',
        dealerCd: '43020',
        certificationNumber: 9444869,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK1HU213275',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-15T20:10:35.759Z'
        }
      },
      sort: [
        1591300566005
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP684649040132020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:54:30.255Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '14392.00',
          mileage: '30987'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497698,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP684649',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:48:12.012Z'
        }
      },
      sort: [
        1591300470255
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE5LJ011409190212020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:54:23.874Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '18488.00',
          mileage: '5889'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9441880,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE5LJ011409',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T17:17:15.068Z'
        }
      },
      sort: [
        1591300463874
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU724493430202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:53:07.592Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18347.00',
          mileage: '29960'
        },
        sellArea: '500',
        dealerCd: '43020',
        certificationNumber: 9436720,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU724493',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-30T21:40:06.403Z'
        }
      },
      sort: [
        1591300387592
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE7LP015713020352020-05-30V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:52:28.839Z',
          retailDate: '2020-05-30',
          rdrStatus: 'V',
          soldAmount: '16300.00',
          mileage: '15602'
        },
        sellArea: '600',
        dealerCd: '02035',
        certificationNumber: 9461550,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE7LP015713',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T23:19:23.340Z'
        }
      },
      sort: [
        1591300348839
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV8EW186818220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:52:27.292Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '82971'
        },
        sellArea: '230',
        dealerCd: '22030',
        certificationNumber: 9497879,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV8EW186818',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:46:52.587Z'
        }
      },
      sort: [
        1591300347292
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE5K3057544190212020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:52:12.051Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '17488.00',
          mileage: '5706'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9441872,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE5K3057544',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T17:07:30.372Z'
        }
      },
      sort: [
        1591300332051
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4LM304380190212020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:50:40.611Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '32688.00',
          mileage: '1466'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9463443,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4LM304380',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T20:12:27.425Z'
        }
      },
      sort: [
        1591300240611
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU0H3032980370832020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:50:08.708Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19686.00',
          mileage: '45630'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9422847,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU0H3032980',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T20:01:51.702Z'
        }
      },
      sort: [
        1591300208708
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXJ5514476460502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:47:43.906Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '37500.00',
          mileage: '32160'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9399793,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXJ5514476',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T22:01:12.371Z'
        }
      },
      sort: [
        1591300063906
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU2G3504773190212020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:47:35.155Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '16788.00',
          mileage: '36287'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9465757,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU2G3504773',
        model: {
          modelDescription: 'PRIUS TWO ECO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1224',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:18:58.232Z'
        }
      },
      sort: [
        1591300055155
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RWRFV5KW016304060322020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:46:37.966Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '27699.00',
          mileage: '6084'
        },
        sellArea: '160',
        dealerCd: '06032',
        certificationNumber: 9468638,
        makeCode: 'TOYOTA',
        vin: '2T3RWRFV5KW016304',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T17:39:00.134Z'
        }
      },
      sort: [
        1591299997966
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0F5275138460502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:46:09.138Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32000.00',
          mileage: '55914'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9476455,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0F5275138',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:56:00.035Z'
        }
      },
      sort: [
        1591299969138
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMCY7AJ5H4053898190212020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:45:13.883Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '54988.00',
          mileage: '53170'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9463533,
        makeCode: 'TOYOTA',
        vin: 'JTMCY7AJ5H4053898',
        model: {
          modelDescription: 'LAND CRUISER 4WD SUV V8',
          modelSeries: 'LAND CRUISER',
          modelNumber: '6156',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T20:35:57.905Z'
        }
      },
      sort: [
        1591299913883
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC866159460502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:43:57.514Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15623.00',
          mileage: '23327'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9496380,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC866159',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:03:25.002Z'
        }
      },
      sort: [
        1591299837514
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC5LS038653190212020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:43:37.173Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '28588.00',
          mileage: '2146'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9461108,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC5LS038653',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T18:23:16.271Z'
        }
      },
      sort: [
        1591299817173
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN3HM023553190212020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:42:21.834Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '26788.00',
          mileage: '43673'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9445821,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN3HM023553',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-18T13:44:54.715Z'
        }
      },
      sort: [
        1591299741834
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP6H3051916201412020-05-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:38:28.113Z',
          retailDate: '2020-05-07',
          rdrStatus: 'H',
          soldAmount: '20783.00',
          mileage: '28084'
        },
        dealerCd: '20141',
        certificationNumber: 9350831,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP6H3051916',
        model: {
          modelDescription: 'PRIUS PRIME PLUS',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1235',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-23T18:13:48.139Z'
        }
      },
      sort: [
        1591299508113
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE6LJ002699060322020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:38:20.751Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20987.00',
          mileage: '6079'
        },
        sellArea: '160',
        dealerCd: '06032',
        certificationNumber: 9468499,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE6LJ002699',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T16:39:00.153Z'
        }
      },
      sort: [
        1591299500751
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB32K1621243190212020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:36:43.170Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '16300.00',
          mileage: '12748'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9465707,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB32K1621243',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB L',
          modelSeries: 'PRIUS C',
          modelNumber: '1201',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:51:22.209Z'
        }
      },
      sort: [
        1591299403170
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9JW740819160042020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:35:41.452Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21653.00',
          mileage: '50120'
        },
        sellArea: '600',
        dealerCd: '16004',
        certificationNumber: 9395199,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9JW740819',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T15:56:50.642Z'
        }
      },
      sort: [
        1591299341452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HD200413190212020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:35:12.112Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '17788.00',
          mileage: '47735'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9465849,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HD200413',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:13:21.433Z'
        }
      },
      sort: [
        1591299312112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH4HS421759450702020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:34:52.511Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '31587'
        },
        sellArea: '160',
        dealerCd: '45070',
        certificationNumber: 9474815,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH4HS421759',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:01:46.002Z'
        }
      },
      sort: [
        1591299292511
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHD205795480282020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:33:54.289Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20198.00',
          mileage: '42792'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9437104,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHD205795',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T13:47:26.702Z'
        }
      },
      sort: [
        1591299234289
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE7K3044584371502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:33:27.852Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17788.00',
          mileage: '5932'
        },
        dealerCd: '37150',
        certificationNumber: 9371184,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE7K3044584',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-06T19:36:45.113Z'
        }
      },
      sort: [
        1591299207852
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7FC327186190212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:33:25.589Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '11988.00',
          mileage: '33228'
        },
        sellArea: '800',
        dealerCd: '19021',
        certificationNumber: 9497797,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7FC327186',
        model: {
          modelDescription: 'COROLLA S GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1862',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:24:08.314Z'
        }
      },
      sort: [
        1591299205589
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU3HJ064089371502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:32:30.327Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22978.00',
          mileage: '17263'
        },
        sellArea: '800',
        dealerCd: '37150',
        certificationNumber: 9426837,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU3HJ064089',
        model: {
          modelDescription: 'PRIUS v Three Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1245',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T14:56:22.086Z'
        }
      },
      sort: [
        1591299150327
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUX4EN6FX038793280122020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:31:52.807Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19997.00',
          mileage: '54164'
        },
        sellArea: '170',
        dealerCd: '28012',
        certificationNumber: 9458544,
        makeCode: 'TOYOTA',
        vin: '5TFUX4EN6FX038793',
        model: {
          modelDescription: 'TACOMA 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7514',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T15:45:03.467Z'
        }
      },
      sort: [
        1591299112807
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV7HW609450460502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:30:39.264Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22506.00',
          mileage: '9015'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9406026,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV7HW609450',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T23:04:04.233Z'
        }
      },
      sort: [
        1591299039264
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREVXJJ188207220302020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:30:37.062Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24241.75',
          mileage: '44695'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9497807,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREVXJJ188207',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T19:25:41.453Z'
        }
      },
      sort: [
        1591299037062
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK2FR481636371502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:28:20.543Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13902.00',
          mileage: '55464'
        },
        sellArea: '800',
        dealerCd: '37150',
        certificationNumber: 9465853,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK2FR481636',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:17:18.891Z'
        }
      },
      sort: [
        1591298900543
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK6JU040527160482020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:27:14.201Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '26900.00',
          mileage: '11317'
        },
        sellArea: '220',
        dealerCd: '16048',
        certificationNumber: 9463586,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK6JU040527',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T21:05:20.712Z'
        }
      },
      sort: [
        1591298834201
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK3HU207719341212020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:26:38.000Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17187.00',
          mileage: '45280'
        },
        dealerCd: '34121',
        certificationNumber: 8197942,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK3HU207719',
        model: {
          modelDescription: 'CAMRY HYBRID',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: '2017'
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-06-07T00:00:00.000Z'
        }
      },
      sort: [
        1591298798000
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS396654360992020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:24:19.538Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '30995.00',
          mileage: '14705'
        },
        sellArea: '120',
        dealerCd: '36099',
        certificationNumber: 9462711,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS396654',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T14:50:57.041Z'
        }
      },
      sort: [
        1591298659538
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU419877041592020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:22:33.154Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17029.00',
          mileage: '34424'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9481572,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU419877',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:46:44.601Z'
        }
      },
      sort: [
        1591298553154
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4HM073133201412020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:22:03.894Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '27488.00',
          mileage: '34937'
        },
        sellArea: '170',
        dealerCd: '20141',
        certificationNumber: 9418476,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4HM073133',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T15:56:33.694Z'
        }
      },
      sort: [
        1591298523894
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFUXJ3602776480622020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:21:22.494Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19408.58',
          mileage: '29377'
        },
        sellArea: '210',
        dealerCd: '48062',
        certificationNumber: 9477597,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFUXJ3602776',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T16:42:14.197Z'
        }
      },
      sort: [
        1591298482494
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN8KX191249201412020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:21:01.293Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '28900.00',
          mileage: '14206'
        },
        sellArea: '500',
        dealerCd: '20141',
        certificationNumber: 9418459,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN8KX191249',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T15:44:31.112Z'
        }
      },
      sort: [
        1591298461293
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE9K3045770371492020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:20:47.791Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '5246'
        },
        sellArea: '800',
        dealerCd: '37149',
        certificationNumber: 9399124,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE9K3045770',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T18:19:06.613Z'
        }
      },
      sort: [
        1591298447791
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F11JX684086371532020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:19:15.172Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '35995.00',
          mileage: '25984'
        },
        sellArea: '800',
        dealerCd: '37153',
        certificationNumber: 9474303,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F11JX684086',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T15:07:17.798Z'
        }
      },
      sort: [
        1591298355172
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV6HJ136115140482020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:18:40.008Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24685.37',
          mileage: '20942'
        },
        sellArea: '230',
        dealerCd: '14048',
        certificationNumber: 9485607,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV6HJ136115',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:34:16.186Z'
        }
      },
      sort: [
        1591298320008
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE0LJ008324450352020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:18:26.109Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '19190.00',
          mileage: '5579'
        },
        sellArea: '800',
        dealerCd: '45035',
        certificationNumber: 9443544,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE0LJ008324',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-11T15:17:46.437Z'
        }
      },
      sort: [
        1591298306109
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS386236450352020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:17:58.889Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29069.00',
          mileage: '39342'
        },
        sellArea: '160',
        dealerCd: '45035',
        certificationNumber: 9475972,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS386236',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:34:11.241Z'
        }
      },
      sort: [
        1591298278889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1J5573142450352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:17:41.409Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '32590.00',
          mileage: '21545'
        },
        sellArea: '150',
        dealerCd: '45035',
        certificationNumber: 9448080,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1J5573142',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T18:15:08.474Z'
        }
      },
      sort: [
        1591298261409
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV7HJ113674450352020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:17:23.641Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '34879'
        },
        sellArea: '800',
        dealerCd: '45035',
        certificationNumber: 9475981,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV7HJ113674',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:39:16.142Z'
        }
      },
      sort: [
        1591298243641
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KW042243371142020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:17:21.834Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24255.00',
          mileage: '22224'
        },
        dealerCd: '37114',
        certificationNumber: 9358879,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KW042243',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-29T15:52:19.348Z'
        }
      },
      sort: [
        1591298241834
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU1K3082276200962020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:16:47.992Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '22495.00',
          mileage: '1849'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9428375,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU1K3082276',
        model: {
          modelDescription: 'PRIUS LE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T15:36:41.300Z'
        }
      },
      sort: [
        1591298207992
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC933608360992020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:15:20.813Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13495.00',
          mileage: '47886'
        },
        sellArea: '130',
        dealerCd: '36099',
        certificationNumber: 9494744,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC933608',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:35:27.596Z'
        }
      },
      sort: [
        1591298120813
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV8KW031333160492020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:14:59.892Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20990.00',
          mileage: '35605'
        },
        sellArea: '500',
        dealerCd: '16049',
        certificationNumber: 9486975,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV8KW031333',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:53:51.028Z'
        }
      },
      sort: [
        1591298099892
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMCY7AJ5G4045427190602020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:14:56.572Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '54998.00',
          mileage: '37553'
        },
        sellArea: '800',
        dealerCd: '19060',
        certificationNumber: 9458929,
        makeCode: 'TOYOTA',
        vin: 'JTMCY7AJ5G4045427',
        model: {
          modelDescription: 'LAND CRUISER 4WD SUV V8',
          modelSeries: 'LAND CRUISER',
          modelNumber: '6156',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T19:08:27.739Z'
        }
      },
      sort: [
        1591298096572
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS446838200962020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:14:46.514Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '36239'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9483092,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS446838',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T13:28:23.323Z'
        }
      },
      sort: [
        1591298086514
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE8LJ025798371142020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:14:32.352Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16400.00',
          mileage: '7743'
        },
        sellArea: '500',
        dealerCd: '37114',
        certificationNumber: 9485322,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE8LJ025798',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:47:33.468Z'
        }
      },
      sort: [
        1591298072352
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE9LJ025521371142020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:14:00.912Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16977.00',
          mileage: '7303'
        },
        sellArea: '500',
        dealerCd: '37114',
        certificationNumber: 9493273,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE9LJ025521',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:55:01.702Z'
        }
      },
      sort: [
        1591298040912
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK3JU528608340892020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:13:38.390Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21835.00',
          mileage: '25184'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9483997,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK3JU528608',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:15:06.108Z'
        }
      },
      sort: [
        1591298018390
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP615897041592020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:13:14.991Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17785.57',
          mileage: '59993'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9450222,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP615897',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T17:17:41.066Z'
        }
      },
      sort: [
        1591297994991
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU828553371142020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:12:35.511Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19113.00',
          mileage: '1426'
        },
        sellArea: '160',
        dealerCd: '37114',
        certificationNumber: 9480305,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU828553',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T15:21:37.947Z'
        }
      },
      sort: [
        1591297955511
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU790986371142020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:12:01.471Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17425.00',
          mileage: '17432'
        },
        sellArea: '500',
        dealerCd: '37114',
        certificationNumber: 9492293,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU790986',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:28:16.163Z'
        }
      },
      sort: [
        1591297921471
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU242888371142020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:11:30.411Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21555.00',
          mileage: '11901'
        },
        sellArea: '800',
        dealerCd: '37114',
        certificationNumber: 9398672,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU242888',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T15:15:55.149Z'
        }
      },
      sort: [
        1591297890411
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW563425200962020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:10:42.658Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '27367'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9475625,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW563425',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T12:25:57.996Z'
        }
      },
      sort: [
        1591297842658
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC8LS023886220382020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:10:33.609Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27718.00',
          mileage: '24257'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9418958,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC8LS023886',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T19:27:12.542Z'
        }
      },
      sort: [
        1591297833609
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7HJ128077220382020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:10:00.769Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '33126'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9476592,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7HJ128077',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:43:16.970Z'
        }
      },
      sort: [
        1591297800769
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0JW788565360992020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:09:20.169Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '42651'
        },
        sellArea: '600',
        dealerCd: '36099',
        certificationNumber: 9472032,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0JW788565',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T00:21:06.747Z'
        }
      },
      sort: [
        1591297760169
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU748718060322020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:09:17.150Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19887.00',
          mileage: '7810'
        },
        sellArea: '160',
        dealerCd: '06032',
        certificationNumber: 9468773,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU748718',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:31:14.556Z'
        }
      },
      sort: [
        1591297757150
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KW037954220382020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:09:14.248Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23219.00',
          mileage: '28032'
        },
        sellArea: '600',
        dealerCd: '22038',
        certificationNumber: 9399334,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KW037954',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T19:40:35.993Z'
        }
      },
      sort: [
        1591297754248
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KW059506220382020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:08:39.268Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23940.84',
          mileage: '11770'
        },
        sellArea: '150',
        dealerCd: '22038',
        certificationNumber: 9487899,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KW059506',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:37:27.960Z'
        }
      },
      sort: [
        1591297719268
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC835977220382020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:08:01.288Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14518.00',
          mileage: '17772'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9451144,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC835977',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T15:48:15.036Z'
        }
      },
      sort: [
        1591297681288
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW606919220382020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:07:28.127Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20100.00',
          mileage: '27103'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9411357,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW606919',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T17:12:15.937Z'
        }
      },
      sort: [
        1591297648127
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW594283220382020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:06:28.028Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16195.00',
          mileage: '74964'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9497569,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW594283',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:09:19.354Z'
        }
      },
      sort: [
        1591297588028
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4FC392724220382020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:04:15.326Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '12618.00',
          mileage: '42322'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9451153,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4FC392724',
        model: {
          modelDescription: 'COROLLA S GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1862',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T15:54:20.844Z'
        }
      },
      sort: [
        1591297455326
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH1HS374814310692020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:04:07.726Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28998.00',
          mileage: '35168'
        },
        dealerCd: '31069',
        certificationNumber: 9378405,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH1HS374814',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-12T20:43:18.023Z'
        }
      },
      sort: [
        1591297447726
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DCXHS838422220382020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:03:23.845Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22718.00',
          mileage: '32109'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9433133,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DCXHS838422',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T18:20:05.734Z'
        }
      },
      sort: [
        1591297403845
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4HJ130531220352020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:03:08.206Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '19313.62',
          mileage: '49687'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9466871,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4HJ130531',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T15:29:05.710Z'
        }
      },
      sort: [
        1591297388206
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU4H3551365020422020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:02:59.428Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18498.00',
          mileage: '30375'
        },
        sellArea: '150',
        dealerCd: '02042',
        certificationNumber: 9468915,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU4H3551365',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:35:50.422Z'
        }
      },
      sort: [
        1591297379428
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH4HS372577220352020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:02:26.846Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '25999.00',
          mileage: '30824'
        },
        sellArea: '230',
        dealerCd: '22035',
        certificationNumber: 9459651,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH4HS372577',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T16:05:08.787Z'
        }
      },
      sort: [
        1591297346846
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAEXLJ041590020362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:01:44.285Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17074.93',
          mileage: '21452'
        },
        sellArea: '500',
        dealerCd: '02036',
        certificationNumber: 9494245,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAEXLJ041590',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:53:45.379Z'
        }
      },
      sort: [
        1591297304285
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU421910049902020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:01:35.684Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15478.00',
          mileage: '30654'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9403424,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU421910',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T22:35:45.798Z'
        }
      },
      sort: [
        1591297295684
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0HW619785220352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:01:33.847Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '31231'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9437554,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0HW619785',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T17:59:27.959Z'
        }
      },
      sort: [
        1591297293847
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HJ704068220352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:00:52.727Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20499.00',
          mileage: '28468'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9464628,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HJ704068',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T18:54:07.653Z'
        }
      },
      sort: [
        1591297252727
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU438918220352020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T19:00:11.066Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '15800.00',
          mileage: '18794'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9464621,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU438918',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T18:50:14.908Z'
        }
      },
      sort: [
        1591297211066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7JU008202220352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:59:27.944Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22919.00',
          mileage: '32775'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9425110,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7JU008202',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T14:03:20.045Z'
        }
      },
      sort: [
        1591297167944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC856953220352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:58:59.144Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14919.00',
          mileage: '9226'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9425094,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC856953',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T14:01:08.346Z'
        }
      },
      sort: [
        1591297139144
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX2JR021920220352020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:58:31.305Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '18250.00',
          mileage: '33710'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9473112,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX2JR021920',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:07:21.858Z'
        }
      },
      sort: [
        1591297111305
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXJW785830220352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:57:47.103Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '25919.00',
          mileage: '29621'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9422042,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXJW785830',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T13:24:14.323Z'
        }
      },
      sort: [
        1591297067103
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH5KS732821310882020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:57:23.503Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32500.00',
          mileage: '4704'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9444353,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH5KS732821',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T17:59:46.970Z'
        }
      },
      sort: [
        1591297043503
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4HJ709051220352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:57:15.763Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '32094'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9433814,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4HJ709051',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T15:08:30.698Z'
        }
      },
      sort: [
        1591297035763
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC025790220352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:56:43.223Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '28173.71',
          mileage: '8150'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9432803,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC025790',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T14:39:57.156Z'
        }
      },
      sort: [
        1591297003223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH2HS366284220352020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:56:02.879Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '25462'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9464634,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH2HS366284',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T18:57:06.453Z'
        }
      },
      sort: [
        1591296962879
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV5HJ145582220352020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:55:13.282Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23000.00',
          mileage: '32932'
        },
        sellArea: '210',
        dealerCd: '22035',
        certificationNumber: 9466862,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV5HJ145582',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T15:24:38.932Z'
        }
      },
      sort: [
        1591296913282
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW695554020552020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:54:30.220Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20149.00',
          mileage: '10685'
        },
        sellArea: '800',
        dealerCd: '02055',
        certificationNumber: 9497708,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW695554',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:52:30.296Z'
        }
      },
      sort: [
        1591296870220
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW590746311802020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:54:10.879Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19900.00',
          mileage: '26447'
        },
        sellArea: '160',
        dealerCd: '31180',
        certificationNumber: 9492460,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW590746',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:00:30.815Z'
        }
      },
      sort: [
        1591296850879
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ121921121382020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:53:05.737Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21894.81',
          mileage: '14751'
        },
        dealerCd: '12138',
        certificationNumber: 9380615,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ121921',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T00:40:28.128Z'
        }
      },
      sort: [
        1591296785737
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV1HJ715020041092020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:52:47.316Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '19688.00',
          mileage: '26741'
        },
        sellArea: '110',
        dealerCd: '04109',
        certificationNumber: 9478295,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV1HJ715020',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:48:09.027Z'
        }
      },
      sort: [
        1591296767316
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE4JJ570451041092020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:51:40.616Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '24474'
        },
        sellArea: '110',
        dealerCd: '04109',
        certificationNumber: 9451178,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE4JJ570451',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T16:05:08.107Z'
        }
      },
      sort: [
        1591296700616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC909628120822020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:51:04.034Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '13469.00',
          mileage: '28700'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9483998,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC909628',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:15:39.818Z'
        }
      },
      sort: [
        1591296664034
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU727198220462020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:49:34.134Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '16485.00',
          mileage: '30604'
        },
        dealerCd: '22046',
        certificationNumber: 9349624,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU727198',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-22T21:26:35.016Z'
        }
      },
      sort: [
        1591296574134
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6JU052935190712020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:47:45.891Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '17594.00',
          mileage: '31597'
        },
        sellArea: '800',
        dealerCd: '19071',
        certificationNumber: 9485425,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6JU052935',
        model: {
          modelDescription: 'CAMRY 4-DOOR L SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2514',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:46:51.481Z'
        }
      },
      sort: [
        1591296465891
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP646076040132020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:47:10.289Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '13892.00',
          mileage: '58327'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497692,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP646076',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:45:01.376Z'
        }
      },
      sort: [
        1591296430289
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9HM052262042922020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:46:07.967Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32750.00',
          mileage: '45295'
        },
        sellArea: '120',
        dealerCd: '04292',
        certificationNumber: 9484559,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9HM052262',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T23:28:47.565Z'
        }
      },
      sort: [
        1591296367967
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ129791280122020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:45:31.881Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19499.92',
          mileage: '40716'
        },
        dealerCd: '28012',
        certificationNumber: 9389262,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ129791',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T18:01:23.740Z'
        }
      },
      sort: [
        1591296331881
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HJ145478311742020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:41:56.583Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22371.00',
          mileage: '18859'
        },
        sellArea: '160',
        dealerCd: '31174',
        certificationNumber: 9494099,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HJ145478',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:03:25.748Z'
        }
      },
      sort: [
        1591296116583
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC3HS801500043622020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:41:14.602Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24998.00',
          mileage: '44689'
        },
        sellArea: '120',
        dealerCd: '04362',
        certificationNumber: 9407282,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC3HS801500',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T19:07:03.580Z'
        }
      },
      sort: [
        1591296074602
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMBZ5DN6KM021131042502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:36:47.458Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32095.00',
          mileage: '11337'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9442388,
        makeCode: 'TOYOTA',
        vin: '3TMBZ5DN6KM021131',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DBL CAB LONG BED',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7172',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T16:51:57.641Z'
        }
      },
      sort: [
        1591295807458
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV1KW018584121022020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:35:50.256Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '19450.00',
          mileage: '57997'
        },
        sellArea: '500',
        dealerCd: '12102',
        certificationNumber: 9497597,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV1KW018584',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:19:18.560Z'
        }
      },
      sort: [
        1591295750256
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU737635291032020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:35:34.691Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16250.0000',
          mileage: '9718'
        },
        sellArea: '160',
        dealerCd: '29103',
        certificationNumber: 9483991,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU737635',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:13:22.157Z'
        }
      },
      sort: [
        1591295734691
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU329118291032020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:35:32.152Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17200.0000',
          mileage: '9291'
        },
        sellArea: '160',
        dealerCd: '29103',
        certificationNumber: 9427184,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU329118',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T17:36:39.876Z'
        }
      },
      sort: [
        1591295732152
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU243525291032020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:35:29.592Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17500.0000',
          mileage: '22134'
        },
        sellArea: '500',
        dealerCd: '29103',
        certificationNumber: 9474969,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU243525',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:20:06.248Z'
        }
      },
      sort: [
        1591295729592
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC7KS016667291032020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:35:26.452Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '24995.0000',
          mileage: '17431'
        },
        dealerCd: '29103',
        certificationNumber: 9340508,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC7KS016667',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-16T20:30:08.470Z'
        }
      },
      sort: [
        1591295726452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU0H3032672291032020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:35:23.772Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '18395.0000',
          mileage: '41827'
        },
        sellArea: '160',
        dealerCd: '29103',
        certificationNumber: 9427242,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU0H3032672',
        model: {
          modelDescription: 'PRIUS FOUR',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1227',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T17:57:01.976Z'
        }
      },
      sort: [
        1591295723772
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1HW612000291032020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:35:21.151Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25145.1700',
          mileage: '28887'
        },
        sellArea: '160',
        dealerCd: '29103',
        certificationNumber: 9411384,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1HW612000',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T17:36:06.225Z'
        }
      },
      sort: [
        1591295721151
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3HP657653043622020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:32:35.610Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '13998.00',
          mileage: '52411'
        },
        sellArea: '120',
        dealerCd: '04362',
        certificationNumber: 9487683,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3HP657653',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:21:14.718Z'
        }
      },
      sort: [
        1591295555610
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV6JW458444121022020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:29:57.050Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '17682.00',
          mileage: '46752'
        },
        sellArea: '600',
        dealerCd: '12102',
        certificationNumber: 9497606,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV6JW458444',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:23:04.095Z'
        }
      },
      sort: [
        1591295397050
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK2LU908542046762020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:28:31.249Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25998.00',
          mileage: '5422'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9486160,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK2LU908542',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:18:53.062Z'
        }
      },
      sort: [
        1591295311249
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE9LP042502040762020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:26:58.229Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17900.00',
          mileage: '11664'
        },
        sellArea: '110',
        dealerCd: '04076',
        certificationNumber: 9465926,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE9LP042502',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:56:07.616Z'
        }
      },
      sort: [
        1591295218229
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAEXLJ094693046762020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:26:48.629Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18117.00',
          mileage: '1499'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9456194,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAEXLJ094693',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T20:27:18.940Z'
        }
      },
      sort: [
        1591295208629
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAEXLP050625046762020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:26:33.227Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17674.44',
          mileage: '7874'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9477505,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAEXLP050625',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:56:34.549Z'
        }
      },
      sort: [
        1591295193227
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN8HM042875043622020-05-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:26:20.169Z',
          retailDate: '2020-05-13',
          rdrStatus: 'H',
          soldAmount: '28899.02',
          mileage: '36777'
        },
        sellArea: '120',
        dealerCd: '04362',
        certificationNumber: 9457695,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN8HM042875',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T18:28:01.243Z'
        }
      },
      sort: [
        1591295180169
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC838048311352020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:26:17.908Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15265.00',
          mileage: '6240'
        },
        sellArea: '160',
        dealerCd: '31135',
        certificationNumber: 9497596,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC838048',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T18:18:48.915Z'
        }
      },
      sort: [
        1591295177908
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV1LC054528046762020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:26:08.989Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24750.00',
          mileage: '2929'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9466399,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV1LC054528',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T23:49:33.636Z'
        }
      },
      sort: [
        1591295168989
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX3L1071321046762020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:25:47.687Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23999.00',
          mileage: '1454'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9456160,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX3L1071321',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T19:44:52.827Z'
        }
      },
      sort: [
        1591295147687
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU758604040762020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:25:37.369Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15796.84',
          mileage: '31023'
        },
        sellArea: '110',
        dealerCd: '04076',
        certificationNumber: 9477877,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU758604',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:42:13.872Z'
        }
      },
      sort: [
        1591295137369
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK9GR562294046762020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:25:32.409Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15793.00',
          mileage: '44063'
        },
        sellArea: '600',
        dealerCd: '04676',
        certificationNumber: 9488558,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK9GR562294',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:11:37.963Z'
        }
      },
      sort: [
        1591295132409
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4F5246242040722020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:24:45.048Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25200.00',
          mileage: '49435'
        },
        sellArea: '170',
        dealerCd: '04072',
        certificationNumber: 9492682,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4F5246242',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:10:49.848Z'
        }
      },
      sort: [
        1591295085048
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK8J3020462160482020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:24:42.328Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20900.00',
          mileage: '16328'
        },
        sellArea: '220',
        dealerCd: '16048',
        certificationNumber: 9424863,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK8J3020462',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T12:43:07.491Z'
        }
      },
      sort: [
        1591295082328
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE1LP032594040762020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:23:57.505Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '11529'
        },
        sellArea: '110',
        dealerCd: '04076',
        certificationNumber: 9465922,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE1LP032594',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:54:00.976Z'
        }
      },
      sort: [
        1591295037505
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX9KR076804180332020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:23:28.107Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '14633'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9472988,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX9KR076804',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T18:09:57.695Z'
        }
      },
      sort: [
        1591295008107
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU376007160172020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:23:19.080Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17300.00',
          mileage: '22233'
        },
        sellArea: '500',
        dealerCd: '16017',
        certificationNumber: 9477888,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU376007',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:47:43.195Z'
        }
      },
      sort: [
        1591294999080
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0LM313965043622020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:23:15.782Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '37898.00',
          mileage: '13263'
        },
        sellArea: '500',
        dealerCd: '04362',
        certificationNumber: 9481928,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0LM313965',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:19:59.792Z'
        }
      },
      sort: [
        1591294995782
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9JS487239480282020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:22:31.518Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '33194.00',
          mileage: '10971'
        },
        dealerCd: '48028',
        certificationNumber: 9377188,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9JS487239',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-11T21:46:46.178Z'
        }
      },
      sort: [
        1591294951518
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5J5594205220462020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:21:57.411Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35485.00',
          mileage: '20260'
        },
        dealerCd: '22046',
        certificationNumber: 9391009,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5J5594205',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T19:15:54.455Z'
        }
      },
      sort: [
        1591294917411
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR9H5156540040412020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:21:53.256Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30741.00',
          mileage: '35511'
        },
        sellArea: '110',
        dealerCd: '04041',
        certificationNumber: 9394431,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR9H5156540',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8642',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T22:57:31.261Z'
        }
      },
      sort: [
        1591294913256
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDFPRAE3LJ011335040412020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:19:53.096Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24990.00',
          mileage: '4865'
        },
        sellArea: '110',
        dealerCd: '04041',
        certificationNumber: 9494103,
        makeCode: 'TOYOTA',
        vin: 'JTDFPRAE3LJ011335',
        model: {
          modelDescription: 'COROLLA XLE',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:06:01.104Z'
        }
      },
      sort: [
        1591294793096
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU520292360852020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:19:48.775Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18637.26',
          mileage: '22364'
        },
        sellArea: '130',
        dealerCd: '36085',
        certificationNumber: 9473280,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU520292',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T20:16:15.188Z'
        }
      },
      sort: [
        1591294788775
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFHXGS240891360852020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:18:43.673Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25500.00',
          mileage: '37841'
        },
        sellArea: '160',
        dealerCd: '36085',
        certificationNumber: 9451627,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFHXGS240891',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T20:46:10.945Z'
        }
      },
      sort: [
        1591294723673
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK7J3040542040412020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:18:35.874Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19941.00',
          mileage: '20700'
        },
        dealerCd: '04041',
        certificationNumber: 9391325,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK7J3040542',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T21:42:10.933Z'
        }
      },
      sort: [
        1591294715874
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8LM300106360852020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:17:23.779Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30300.00',
          mileage: '1147'
        },
        sellArea: '130',
        dealerCd: '36085',
        certificationNumber: 9493278,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8LM300106',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:56:32.642Z'
        }
      },
      sort: [
        1591294643779
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN6KX192223360852020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:16:39.099Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '35995.00',
          mileage: '11987'
        },
        sellArea: '130',
        dealerCd: '36085',
        certificationNumber: 9493304,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN6KX192223',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T00:07:48.686Z'
        }
      },
      sort: [
        1591294599099
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV3HY191128340372020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:16:07.220Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14159.00',
          mileage: '3467'
        },
        sellArea: '220',
        dealerCd: '34037',
        certificationNumber: 9472648,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV3HY191128',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T15:56:03.832Z'
        }
      },
      sort: [
        1591294567220
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN3HX100506440132020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:15:45.192Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '28799.00',
          mileage: '22874'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9481601,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN3HX100506',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7562',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:56:31.786Z'
        }
      },
      sort: [
        1591294545192
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6HJ127100340372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:15:21.458Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18430.65',
          mileage: '12651'
        },
        sellArea: '220',
        dealerCd: '34037',
        certificationNumber: 9472675,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6HJ127100',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T16:05:36.318Z'
        }
      },
      sort: [
        1591294521458
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBW5F18JX751103160172020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:14:53.798Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '38884.00',
          mileage: '7145'
        },
        dealerCd: '16017',
        certificationNumber: 9189242,
        makeCode: 'TOYOTA',
        vin: '5TFBW5F18JX751103',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DBL CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8354',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-08T17:32:43.539Z'
        }
      },
      sort: [
        1591294493798
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE8HJ533204311352020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:13:52.498Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14845.00',
          mileage: '19728'
        },
        dealerCd: '31135',
        certificationNumber: 9385916,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE8HJ533204',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T17:56:32.887Z'
        }
      },
      sort: [
        1591294432498
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHD208096311352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:11:50.058Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20700.00',
          mileage: '15862'
        },
        dealerCd: '31135',
        certificationNumber: 9390059,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHD208096',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T01:30:05.038Z'
        }
      },
      sort: [
        1591294310058
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4GU550645160172020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:11:17.656Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14700.00',
          mileage: '46370'
        },
        sellArea: '220',
        dealerCd: '16017',
        certificationNumber: 9459463,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4GU550645',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T12:15:19.906Z'
        }
      },
      sort: [
        1591294277656
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS428412480612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:06:34.934Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28884.00',
          mileage: '34250'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9458578,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS428412',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T16:08:47.699Z'
        }
      },
      sort: [
        1591293994934
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC903493043882020-02-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:06:14.455Z',
          retailDate: '2020-02-26',
          rdrStatus: 'H',
          soldAmount: '12995.00',
          mileage: '64400'
        },
        sellArea: '600',
        dealerCd: '04388',
        certificationNumber: 9497070,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC903493',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:42:33.368Z'
        }
      },
      sort: [
        1591293974455
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC819381043882020-02-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T18:02:29.694Z',
          retailDate: '2020-02-03',
          rdrStatus: 'H',
          soldAmount: '16540.36',
          mileage: '14590'
        },
        sellArea: '500',
        dealerCd: '04388',
        certificationNumber: 9497074,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC819381',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:46:04.813Z'
        }
      },
      sort: [
        1591293749694
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP729304043882020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:56:22.092Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15995.00',
          mileage: '19029'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9491431,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP729304',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:32:12.962Z'
        }
      },
      sort: [
        1591293382092
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1H5473469440132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:52:59.030Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '29200'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9495198,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1H5473469',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:44:10.019Z'
        }
      },
      sort: [
        1591293179030
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH3HS516183041362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:52:23.112Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31500.00',
          mileage: '40269'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9497493,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH3HS516183',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6950',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:37:02.471Z'
        }
      },
      sort: [
        1591293143112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU139021043882020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:49:57.956Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19495.00',
          mileage: '67971'
        },
        sellArea: '120',
        dealerCd: '04388',
        certificationNumber: 9485368,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU139021',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:12:34.581Z'
        }
      },
      sort: [
        1591292997956
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN7HX084184461022020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:48:57.799Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '29900.00',
          mileage: '40452'
        },
        sellArea: '800',
        dealerCd: '46102',
        certificationNumber: 9479293,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN7HX084184',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T18:14:12.356Z'
        }
      },
      sort: [
        1591292937799
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0HD107678461022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:48:12.289Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22976.00',
          mileage: '18047'
        },
        dealerCd: '46102',
        certificationNumber: 9372940,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0HD107678',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T21:05:50.004Z'
        }
      },
      sort: [
        1591292892289
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HJ115657461022020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:47:28.290Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '21439'
        },
        sellArea: '130',
        dealerCd: '46102',
        certificationNumber: 9458025,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HJ115657',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T22:21:15.516Z'
        }
      },
      sort: [
        1591292848290
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV1HD208629461022020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:46:52.969Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '39344'
        },
        sellArea: '130',
        dealerCd: '46102',
        certificationNumber: 9449006,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV1HD208629',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T20:32:13.414Z'
        }
      },
      sort: [
        1591292812969
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP624977140412020-01-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:44:47.848Z',
          retailDate: '2020-01-24',
          rdrStatus: 'H',
          soldAmount: '14623.00',
          mileage: '25782'
        },
        dealerCd: '14041',
        certificationNumber: 9459671,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP624977',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T16:17:29.299Z'
        }
      },
      sort: [
        1591292687848
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE9K3038897210722020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:43:04.266Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18488.00',
          mileage: '4902'
        },
        sellArea: '220',
        dealerCd: '21072',
        certificationNumber: 9471746,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE9K3038897',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T22:05:35.357Z'
        }
      },
      sort: [
        1591292584266
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6GW508915310242020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:41:56.907Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15495.00',
          mileage: '67755'
        },
        sellArea: '160',
        dealerCd: '31024',
        certificationNumber: 9495107,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6GW508915',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:20:37.645Z'
        }
      },
      sort: [
        1591292516907
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3FW357282160052020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:41:44.526Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18495.00',
          mileage: '60824'
        },
        sellArea: '800',
        dealerCd: '16005',
        certificationNumber: 9497435,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3FW357282',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:21:49.942Z'
        }
      },
      sort: [
        1591292504526
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP669271140412020-01-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:41:10.367Z',
          retailDate: '2020-01-24',
          rdrStatus: 'H',
          soldAmount: '14205.00',
          mileage: '31604'
        },
        dealerCd: '14041',
        certificationNumber: 9459840,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP669271',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T18:00:37.603Z'
        }
      },
      sort: [
        1591292470367
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV6KW006584340852020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:40:22.226Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21167.00',
          mileage: '22827'
        },
        sellArea: '220',
        dealerCd: '34085',
        certificationNumber: 9482235,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV6KW006584',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:44:33.642Z'
        }
      },
      sort: [
        1591292422226
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN7FM202272190682020-04-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:39:27.767Z',
          retailDate: '2020-04-29',
          rdrStatus: 'H',
          soldAmount: '22297.00',
          mileage: '57319'
        },
        dealerCd: '19068',
        certificationNumber: 9495854,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN7FM202272',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:02:20.461Z'
        }
      },
      sort: [
        1591292367767
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN6HX058191371382019-12-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:38:59.166Z',
          retailDate: '2019-12-19',
          rdrStatus: 'H',
          soldAmount: '31488.00',
          mileage: '11452'
        },
        sellArea: '800',
        dealerCd: '37138',
        certificationNumber: 9497475,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN6HX058191',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:32:33.671Z'
        }
      },
      sort: [
        1591292339166
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN5KX203485311352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:38:45.867Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30700.00',
          mileage: '14952'
        },
        sellArea: '600',
        dealerCd: '31135',
        certificationNumber: 9463471,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN5KX203485',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T20:17:22.170Z'
        }
      },
      sort: [
        1591292325867
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU230427043882020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:38:44.469Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '40025'
        },
        sellArea: '500',
        dealerCd: '04388',
        certificationNumber: 9481616,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU230427',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:02:49.170Z'
        }
      },
      sort: [
        1591292324469
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV5JJ232234370832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:37:38.625Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20998.00',
          mileage: '20027'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9459066,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV5JJ232234',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T20:33:58.136Z'
        }
      },
      sort: [
        1591292258625
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH8FS144000280122020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:36:24.570Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21300.00',
          mileage: '63668'
        },
        sellArea: '170',
        dealerCd: '28012',
        certificationNumber: 9476451,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH8FS144000',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:54:27.360Z'
        }
      },
      sort: [
        1591292184570
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RWRFV1KW007468461022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:32:34.145Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29150.00',
          mileage: '11000'
        },
        sellArea: '130',
        dealerCd: '46102',
        certificationNumber: 9494111,
        makeCode: 'TOYOTA',
        vin: '2T3RWRFV1KW007468',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:10:19.690Z'
        }
      },
      sort: [
        1591291954145
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KC009328121582020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:32:03.584Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '16451'
        },
        dealerCd: '12158',
        certificationNumber: 9372495,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KC009328',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T17:54:02.961Z'
        }
      },
      sort: [
        1591291923584
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HJ710893060532020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:30:52.261Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '37477'
        },
        sellArea: '160',
        dealerCd: '06053',
        certificationNumber: 9495204,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HJ710893',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:48:03.000Z'
        }
      },
      sort: [
        1591291852261
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW594830140412020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:29:56.665Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '22518.00',
          mileage: '21358'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9447461,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW594830',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T19:27:21.302Z'
        }
      },
      sort: [
        1591291796665
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV8HJ126675340852020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:29:21.524Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17711.00',
          mileage: '21331'
        },
        dealerCd: '34085',
        certificationNumber: 9377689,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV8HJ126675',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-12T16:05:05.830Z'
        }
      },
      sort: [
        1591291761524
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS358599461022020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:29:05.044Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '29999.00',
          mileage: '22929'
        },
        sellArea: '130',
        dealerCd: '46102',
        certificationNumber: 9448758,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS358599',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T17:02:55.420Z'
        }
      },
      sort: [
        1591291745044
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5ANXLM298942121582020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:28:29.504Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '36394.00',
          mileage: '2075'
        },
        dealerCd: '12158',
        certificationNumber: 9353285,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5ANXLM298942',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-24T23:12:34.487Z'
        }
      },
      sort: [
        1591291709504
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE9JJ556948461022020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:28:12.343Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '15495.00',
          mileage: '19552'
        },
        dealerCd: '46102',
        certificationNumber: 9383121,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE9JJ556948',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T22:15:09.233Z'
        }
      },
      sort: [
        1591291692343
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC853967461022020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:27:20.483Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14687.00',
          mileage: '28783'
        },
        sellArea: '130',
        dealerCd: '46102',
        certificationNumber: 9467091,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC853967',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T17:02:55.565Z'
        }
      },
      sort: [
        1591291640483
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC783155461022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:26:39.403Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13900.00',
          mileage: '29078'
        },
        sellArea: '130',
        dealerCd: '46102',
        certificationNumber: 9489633,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC783155',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:27:49.708Z'
        }
      },
      sort: [
        1591291599403
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU325949461022020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:25:43.041Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '16922'
        },
        sellArea: '130',
        dealerCd: '46102',
        certificationNumber: 9478484,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU325949',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T23:32:18.388Z'
        }
      },
      sort: [
        1591291543041
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE0LP044512120772020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:25:19.122Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17994.00',
          mileage: '780'
        },
        sellArea: '210',
        dealerCd: '12077',
        certificationNumber: 9497426,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE0LP044512',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T17:19:22.226Z'
        }
      },
      sort: [
        1591291519122
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC3HS848879041362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:25:09.500Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25788.0000',
          mileage: '25217'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9465099,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC3HS848879',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T00:18:32.109Z'
        }
      },
      sort: [
        1591291509500
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXK3DC3GS738364041362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:25:06.263Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22387.0000',
          mileage: '79707'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9469837,
        makeCode: 'TOYOTA',
        vin: '5TDXK3DC3GS738364',
        model: {
          modelDescription: 'SIENNA SE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5343',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T17:10:44.150Z'
        }
      },
      sort: [
        1591291506263
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP630454041362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:25:02.542Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15288.0000',
          mileage: '34713'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9450528,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP630454',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T20:37:49.988Z'
        }
      },
      sort: [
        1591291502542
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0KP947601041362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:25:00.102Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16087.0000',
          mileage: '17707'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9433469,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0KP947601',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T23:04:54.783Z'
        }
      },
      sort: [
        1591291500102
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN6HM046651041362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:24:57.622Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27468.0000',
          mileage: '54236'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9494079,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN6HM046651',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:54:44.675Z'
        }
      },
      sort: [
        1591291497622
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMW1RFV2KD027047041362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:24:54.881Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27294.00',
          mileage: '7600'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9478555,
        makeCode: 'TOYOTA',
        vin: 'JTMW1RFV2KD027047',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T01:01:53.878Z'
        }
      },
      sort: [
        1591291494881
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV3HY180470340852020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:23:57.302Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '11449.00',
          mileage: '28519'
        },
        sellArea: '220',
        dealerCd: '34085',
        certificationNumber: 9465868,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV3HY180470',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:26:29.836Z'
        }
      },
      sort: [
        1591291437302
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV2HW632451340852020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:20:14.680Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24628.00',
          mileage: '17126'
        },
        sellArea: '220',
        dealerCd: '34085',
        certificationNumber: 9465754,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV2HW632451',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:18:03.012Z'
        }
      },
      sort: [
        1591291214680
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV3HY174913121372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:17:55.399Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '11599.00',
          mileage: '36372'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9468333,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV3HY174913',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T15:24:12.548Z'
        }
      },
      sort: [
        1591291075399
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6HJ137027270242020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:17:44.479Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17350.00',
          mileage: '29508'
        },
        sellArea: '150',
        dealerCd: '27024',
        certificationNumber: 9480520,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6HJ137027',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T17:14:07.952Z'
        }
      },
      sort: [
        1591291064479
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW541699121372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:15:35.778Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22599.00',
          mileage: '44971'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9457567,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW541699',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T17:18:16.258Z'
        }
      },
      sort: [
        1591290935778
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RWRFV9KW006388250642020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:14:08.858Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29990.00',
          mileage: '10842'
        },
        sellArea: '130',
        dealerCd: '25064',
        certificationNumber: 9491816,
        makeCode: 'TOYOTA',
        vin: '2T3RWRFV9KW006388',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:12:13.887Z'
        }
      },
      sort: [
        1591290848858
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4HW615172121372020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:13:51.036Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20894.00',
          mileage: '36427'
        },
        sellArea: '220',
        dealerCd: '12137',
        certificationNumber: 9457470,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4HW615172',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T15:42:14.923Z'
        }
      },
      sort: [
        1591290831036
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ155149480392020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:13:21.916Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17250.00',
          mileage: '28879'
        },
        sellArea: '210',
        dealerCd: '48039',
        certificationNumber: 9488118,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ155149',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:42:14.836Z'
        }
      },
      sort: [
        1591290801916
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2HJ162492121372020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:13:13.944Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19250.00',
          mileage: '17472'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9468427,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2HJ162492',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T16:01:40.361Z'
        }
      },
      sort: [
        1591290793944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW591599371842020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:11:23.577Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18544.00',
          mileage: '39314'
        },
        sellArea: '800',
        dealerCd: '37184',
        certificationNumber: 9473305,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW591599',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T20:20:49.712Z'
        }
      },
      sort: [
        1591290683577
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F13JX710249110302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:10:47.656Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '34594.00',
          mileage: '42704'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9496186,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F13JX710249',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:14:00.937Z'
        }
      },
      sort: [
        1591290647656
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA19H9705396480392020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:05:43.733Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '15674'
        },
        sellArea: '210',
        dealerCd: '48039',
        certificationNumber: 9487519,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA19H9705396',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6253',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:18:16.264Z'
        }
      },
      sort: [
        1591290343733
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9GM024816340852020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:03:15.773Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '75235'
        },
        sellArea: '220',
        dealerCd: '34085',
        certificationNumber: 9494829,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9GM024816',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T00:11:28.159Z'
        }
      },
      sort: [
        1591290195773
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3J5521432410012020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:03:13.713Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '35950.00',
          mileage: '22129'
        },
        sellArea: '220',
        dealerCd: '41001',
        certificationNumber: 9494693,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3J5521432',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:02:02.049Z'
        }
      },
      sort: [
        1591290193713
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV2HW367956121372020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:02:24.854Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18799.00',
          mileage: '34316'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9457491,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV2HW367956',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T16:16:50.085Z'
        }
      },
      sort: [
        1591290144854
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9JU095441048192020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:02:04.413Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20795.00',
          mileage: '40120'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9467108,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9JU095441',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T17:08:55.430Z'
        }
      },
      sort: [
        1591290124413
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS509315480392020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:00:49.694Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '26250.00',
          mileage: '38303'
        },
        sellArea: '210',
        dealerCd: '48039',
        certificationNumber: 9470798,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS509315',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T16:27:23.701Z'
        }
      },
      sort: [
        1591290049694
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU748857340892020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T17:00:41.433Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '21700.00',
          mileage: '7686'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9418435,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU748857',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T15:29:40.587Z'
        }
      },
      sort: [
        1591290041433
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJGRFH2HS030076341222020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:59:20.579Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '26819.00',
          mileage: '46819'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9432802,
        makeCode: 'TOYOTA',
        vin: '5TDJGRFH2HS030076',
        model: {
          modelDescription: 'HIGHLANDER HYBRD XLE-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6965',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T14:39:55.031Z'
        }
      },
      sort: [
        1591289960579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV5HJ705110340852020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:59:09.532Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18700.00',
          mileage: '17653'
        },
        sellArea: '210',
        dealerCd: '34085',
        certificationNumber: 9487247,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV5HJ705110',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:42:33.834Z'
        }
      },
      sort: [
        1591289949532
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV6LC043225121532020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:58:01.792Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '25988.00',
          mileage: '12956'
        },
        sellArea: '210',
        dealerCd: '12153',
        certificationNumber: 9473516,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV6LC043225',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T21:42:07.945Z'
        }
      },
      sort: [
        1591289881792
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC918607480392020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:55:21.851Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13596.00',
          mileage: '26684'
        },
        sellArea: '210',
        dealerCd: '48039',
        certificationNumber: 9476345,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC918607',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:14:42.220Z'
        }
      },
      sort: [
        1591289721851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB37K1620279040512020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:52:12.670Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19700.00',
          mileage: '9669'
        },
        sellArea: '120',
        dealerCd: '04051',
        certificationNumber: 9481689,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB37K1620279',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB L',
          modelSeries: 'PRIUS C',
          modelNumber: '1201',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:29:19.735Z'
        }
      },
      sort: [
        1591289532670
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW567576201272020-05-13V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:49:00.089Z',
          retailDate: '2020-05-13',
          rdrStatus: 'V',
          soldAmount: '17970.00',
          mileage: '27050'
        },
        sellArea: '170',
        dealerCd: '20127',
        certificationNumber: 9408461,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW567576',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T16:41:12.063Z'
        }
      },
      sort: [
        1591289340089
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXH5424965340852020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:48:49.888Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32899.00',
          mileage: '21998'
        },
        sellArea: '160',
        dealerCd: '34085',
        certificationNumber: 9458792,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXH5424965',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T18:06:30.237Z'
        }
      },
      sort: [
        1591289329888
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HD205315140412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:48:10.488Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18076.00',
          mileage: '41455'
        },
        dealerCd: '14041',
        certificationNumber: 9370848,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HD205315',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-06T16:54:58.525Z'
        }
      },
      sort: [
        1591289290488
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2KC012525340852020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:47:42.668Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24311.00',
          mileage: '21306'
        },
        sellArea: '500',
        dealerCd: '34085',
        certificationNumber: 9393135,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2KC012525',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T14:58:40.464Z'
        }
      },
      sort: [
        1591289262668
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREVXHJ132447041222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:46:33.926Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20450.00',
          mileage: '32404'
        },
        sellArea: '110',
        dealerCd: '04122',
        certificationNumber: 9487777,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREVXHJ132447',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:53:10.066Z'
        }
      },
      sort: [
        1591289193926
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DWRFVXKW016860340852020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:46:30.308Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '33866'
        },
        sellArea: '220',
        dealerCd: '34085',
        certificationNumber: 9493057,
        makeCode: 'TOYOTA',
        vin: '2T3DWRFVXKW016860',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:35:03.172Z'
        }
      },
      sort: [
        1591289190308
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6FC325672140412020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:45:07.568Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '11309.00',
          mileage: '42705'
        },
        sellArea: '500',
        dealerCd: '14041',
        certificationNumber: 9458846,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6FC325672',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T18:29:01.144Z'
        }
      },
      sort: [
        1591289107568
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5HW613013480392020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:43:12.764Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18397.58',
          mileage: '10752'
        },
        sellArea: '210',
        dealerCd: '48039',
        certificationNumber: 9473118,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5HW613013',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:09:53.619Z'
        }
      },
      sort: [
        1591288992764
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN9HX090052043742020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:41:34.862Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26611.0000',
          mileage: '27340'
        },
        sellArea: '120',
        dealerCd: '04374',
        certificationNumber: 9496165,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN9HX090052',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:06:21.294Z'
        }
      },
      sort: [
        1591288894862
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC956892043742020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:41:32.342Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15875.00',
          mileage: '12033'
        },
        sellArea: '130',
        dealerCd: '04374',
        certificationNumber: 9482470,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC956892',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:48:47.372Z'
        }
      },
      sort: [
        1591288892342
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV9KW056557500122020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:38:58.180Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '34789.00',
          mileage: '4304'
        },
        sellArea: '130',
        dealerCd: '50012',
        certificationNumber: 9440140,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV9KW056557',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-03T21:54:32.024Z'
        }
      },
      sort: [
        1591288738180
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F16EX055151340852020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:38:39.446Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '18701'
        },
        sellArea: '220',
        dealerCd: '34085',
        certificationNumber: 9463180,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F16EX055151',
        model: {
          modelDescription: 'TUNDRA 4X4 SR DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8339',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T18:30:40.342Z'
        }
      },
      sort: [
        1591288719446
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8H5425550050672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:36:26.900Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30581.0000',
          mileage: '30293'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9487423,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8H5425550',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:40:47.889Z'
        }
      },
      sort: [
        1591288586900
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK9J3038808050672020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:36:24.381Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17981.0000',
          mileage: '35148'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9493181,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK9J3038808',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T23:14:52.485Z'
        }
      },
      sort: [
        1591288584381
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHW5F1XHX638550050672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:36:21.800Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '34981.0000',
          mileage: '40092'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9496422,
        makeCode: 'TOYOTA',
        vin: '5TFHW5F1XHX638550',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8374',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:51:59.868Z'
        }
      },
      sort: [
        1591288581800
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FKXHU209130050672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:36:19.461Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19154.38',
          mileage: '40559'
        },
        sellArea: '110',
        dealerCd: '05067',
        certificationNumber: 9496410,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FKXHU209130',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:38:47.559Z'
        }
      },
      sort: [
        1591288579461
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH6GS313218140412020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:36:03.180Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '27039.00',
          mileage: '54927'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9476204,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH6GS313218',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:30:19.339Z'
        }
      },
      sort: [
        1591288563180
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DU4F1931631340852020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:35:59.601Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15646.00',
          mileage: '35887'
        },
        sellArea: '220',
        dealerCd: '34085',
        certificationNumber: 9485155,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DU4F1931631',
        model: {
          modelDescription: 'PRIUS Two Model',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:35:37.600Z'
        }
      },
      sort: [
        1591288559601
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9EC180495020512020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:34:10.578Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '12500.00',
          mileage: '69796'
        },
        sellArea: '800',
        dealerCd: '02051',
        certificationNumber: 9460350,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9EC180495',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T23:11:39.386Z'
        }
      },
      sort: [
        1591288450578
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC918106480392020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:34:04.960Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '12690.65',
          mileage: '35565'
        },
        sellArea: '210',
        dealerCd: '48039',
        certificationNumber: 9483439,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC918106',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:27:15.247Z'
        }
      },
      sort: [
        1591288444960
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC7HS883193340852020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:33:53.380Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22912.00',
          mileage: '77705'
        },
        sellArea: '220',
        dealerCd: '34085',
        certificationNumber: 9493067,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC7HS883193',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:39:08.051Z'
        }
      },
      sort: [
        1591288433380
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS427718140412020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:32:35.055Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '30500.00',
          mileage: '35316'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9484199,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS427718',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:30:21.955Z'
        }
      },
      sort: [
        1591288355055
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXJW842365311802020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:32:12.307Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25999.00',
          mileage: '15752'
        },
        sellArea: '160',
        dealerCd: '31180',
        certificationNumber: 9466693,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXJW842365',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T14:04:09.735Z'
        }
      },
      sort: [
        1591288332307
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU329210020512020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:32:09.863Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13900.00',
          mileage: '49512'
        },
        sellArea: '150',
        dealerCd: '02051',
        certificationNumber: 9438844,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU329210',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T19:45:35.326Z'
        }
      },
      sort: [
        1591288329863
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU248259040612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:31:14.203Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19520.00',
          mileage: '20556'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9487694,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU248259',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:23:57.004Z'
        }
      },
      sort: [
        1591288274203
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX6JR055214020512020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:30:35.216Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19069.00',
          mileage: '19311'
        },
        sellArea: '150',
        dealerCd: '02051',
        certificationNumber: 9476781,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX6JR055214',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T23:22:11.079Z'
        }
      },
      sort: [
        1591288235216
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU206677020512020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:29:36.559Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '28718'
        },
        sellArea: '500',
        dealerCd: '02051',
        certificationNumber: 9477795,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU206677',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:10:45.146Z'
        }
      },
      sort: [
        1591288176559
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6EW166315440142020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:28:55.483Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '67702'
        },
        sellArea: '170',
        dealerCd: '44014',
        certificationNumber: 9497291,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6EW166315',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:21:26.143Z'
        }
      },
      sort: [
        1591288135483
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0HM098871480462020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:28:48.881Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30750.00',
          mileage: '28826'
        },
        sellArea: '210',
        dealerCd: '48046',
        certificationNumber: 9440327,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0HM098871',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-04T18:11:36.856Z'
        }
      },
      sort: [
        1591288128881
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4HW610585140412020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:25:48.521Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '17098.00',
          mileage: '66644'
        },
        sellArea: '210',
        dealerCd: '14041',
        certificationNumber: 9432116,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4HW610585',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T18:37:55.462Z'
        }
      },
      sort: [
        1591287948521
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE5K3000423180362020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:25:20.461Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '17300.00',
          mileage: '32151'
        },
        sellArea: '170',
        dealerCd: '18036',
        certificationNumber: 9492119,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE5K3000423',
        model: {
          modelDescription: 'COROLLA HATCHBACK XSE 5DrHATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6275',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:35:40.902Z'
        }
      },
      sort: [
        1591287920461
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU739589040132020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:23:50.359Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '15992.00',
          mileage: '36554'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497289,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU739589',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:21:06.664Z'
        }
      },
      sort: [
        1591287830359
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2GC638851220302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:23:08.100Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '12990.00',
          mileage: '49299'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9495130,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2GC638851',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:43:47.632Z'
        }
      },
      sort: [
        1591287788100
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5H5427918480392020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:23:03.201Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '29639.00',
          mileage: '55110'
        },
        sellArea: '210',
        dealerCd: '48039',
        certificationNumber: 9468293,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5H5427918',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T15:08:09.079Z'
        }
      },
      sort: [
        1591287783201
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP633096040132020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:19:46.698Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '15592.00',
          mileage: '20798'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9497275,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP633096',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T16:17:04.699Z'
        }
      },
      sort: [
        1591287586698
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC9LS243109110222020-04-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:18:18.437Z',
          retailDate: '2020-04-25',
          rdrStatus: 'H',
          soldAmount: '37893.00',
          mileage: '2988'
        },
        sellArea: '130',
        dealerCd: '11022',
        certificationNumber: 9458044,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC9LS243109',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T22:44:22.775Z'
        }
      },
      sort: [
        1591287498437
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU379559210722020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:17:28.399Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15788.08',
          mileage: '34747'
        },
        sellArea: '220',
        dealerCd: '21072',
        certificationNumber: 9483919,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU379559',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:48:22.341Z'
        }
      },
      sort: [
        1591287448399
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7JJ176555121372020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:17:19.930Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19899.00',
          mileage: '15340'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9457599,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7JJ176555',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T17:38:44.978Z'
        }
      },
      sort: [
        1591287439930
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN6HX089493045432020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:15:41.032Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '7689'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9474589,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN6HX089493',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:17:29.919Z'
        }
      },
      sort: [
        1591287341032
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU724562121372020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:15:11.031Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '16298.00',
          mileage: '30345'
        },
        dealerCd: '12137',
        certificationNumber: 9386567,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU724562',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T23:18:10.119Z'
        }
      },
      sort: [
        1591287311031
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV9JD214248361022020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:14:12.851Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24641.00',
          mileage: '74850'
        },
        sellArea: '130',
        dealerCd: '36102',
        certificationNumber: 9448655,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV9JD214248',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T15:56:14.060Z'
        }
      },
      sort: [
        1591287252851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU555351121372020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:13:57.768Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '18399.00',
          mileage: '28540'
        },
        sellArea: '210',
        dealerCd: '12137',
        certificationNumber: 9414552,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU555351',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T23:43:22.427Z'
        }
      },
      sort: [
        1591287237768
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5FP352622140412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:13:54.350Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '9800.00',
          mileage: '69605'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9458855,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5FP352622',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T18:33:19.383Z'
        }
      },
      sort: [
        1591287234350
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS414619140412020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:10:39.529Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '26328.00',
          mileage: '46037'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9471062,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS414619',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T17:52:39.861Z'
        }
      },
      sort: [
        1591287039529
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KW027469250612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:09:21.549Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26543.00',
          mileage: '21783'
        },
        dealerCd: '25061',
        certificationNumber: 9218231,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KW027469',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-28T17:38:22.895Z'
        }
      },
      sort: [
        1591286961549
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK7LU308762040132020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:08:29.588Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23392.00',
          mileage: '4397'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9462836,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK7LU308762',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:46:14.613Z'
        }
      },
      sort: [
        1591286909588
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXK3DC3FS679864361022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:07:34.488Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23900.00',
          mileage: '47777'
        },
        sellArea: '120',
        dealerCd: '36102',
        certificationNumber: 9478278,
        makeCode: 'TOYOTA',
        vin: '5TDXK3DC3FS679864',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:39:23.479Z'
        }
      },
      sort: [
        1591286854488
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV3HW366637040132020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:07:28.508Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17792.00',
          mileage: '36994'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9495320,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV3HW366637',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:39:26.137Z'
        }
      },
      sort: [
        1591286848508
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMEWRFV9LJ032665361022020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:06:46.368Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '35998.00',
          mileage: '7715'
        },
        sellArea: '130',
        dealerCd: '36102',
        certificationNumber: 9487394,
        makeCode: 'TOYOTA',
        vin: 'JTMEWRFV9LJ032665',
        model: {
          modelDescription: 'RAV4 HYBRID XSE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:29:42.133Z'
        }
      },
      sort: [
        1591286806368
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6HP647408045432020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:06:30.769Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '32573'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9494139,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6HP647408',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:23:42.098Z'
        }
      },
      sort: [
        1591286790769
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0KM243950020352020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:04:32.568Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33000.00',
          mileage: '18820'
        },
        sellArea: '150',
        dealerCd: '02035',
        certificationNumber: 9495785,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0KM243950',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:37:16.479Z'
        }
      },
      sort: [
        1591286672568
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB37G1590577020352020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:02:47.106Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17354.00',
          mileage: '9408'
        },
        sellArea: '110',
        dealerCd: '02035',
        certificationNumber: 9479787,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB37G1590577',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T23:51:46.114Z'
        }
      },
      sort: [
        1591286567106
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV8GW538525371912020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:02:41.666Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20599.00',
          mileage: '36993'
        },
        dealerCd: '37191',
        certificationNumber: 9297530,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV8GW538525',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-18T21:49:25.013Z'
        }
      },
      sort: [
        1591286561666
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV8KW032160121452020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:02:20.006Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '26025'
        },
        sellArea: '500',
        dealerCd: '12145',
        certificationNumber: 9489293,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV8KW032160',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T16:29:47.380Z'
        }
      },
      sort: [
        1591286540006
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV0HD073046220302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:02:13.186Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '31454'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9398512,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV0HD073046',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T14:12:36.967Z'
        }
      },
      sort: [
        1591286533186
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1JW808434340982020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:01:17.585Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18475.00',
          mileage: '44782'
        },
        sellArea: '500',
        dealerCd: '34098',
        certificationNumber: 9481430,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1JW808434',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T14:27:01.076Z'
        }
      },
      sort: [
        1591286477585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0H5418575371912020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:01:13.865Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31998.00',
          mileage: '29911'
        },
        sellArea: '800',
        dealerCd: '37191',
        certificationNumber: 9483952,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0H5418575',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:02:25.013Z'
        }
      },
      sort: [
        1591286473865
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX0JR039963020352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:00:35.806Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '7492'
        },
        sellArea: '150',
        dealerCd: '02035',
        certificationNumber: 9442101,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX0JR039963',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T00:35:39.384Z'
        }
      },
      sort: [
        1591286435806
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE5HJ539168340982020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T16:00:24.106Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '15115'
        },
        sellArea: '220',
        dealerCd: '34098',
        certificationNumber: 9443516,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE5HJ539168',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-11T14:42:40.171Z'
        }
      },
      sort: [
        1591286424106
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F16HX071936371912020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:59:38.705Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27998.00',
          mileage: '40841'
        },
        sellArea: '800',
        dealerCd: '37191',
        certificationNumber: 9483940,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F16HX071936',
        model: {
          modelDescription: 'TUNDRA 4X4 SR DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8339',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:56:05.745Z'
        }
      },
      sort: [
        1591286378705
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS412252210432020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:58:34.244Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22929.00',
          mileage: '58485'
        },
        sellArea: '220',
        dealerCd: '21043',
        certificationNumber: 9482261,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS412252',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:54:37.475Z'
        }
      },
      sort: [
        1591286314244
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1JU595836020352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:58:22.845Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22395.78',
          mileage: '28684'
        },
        sellArea: '150',
        dealerCd: '02035',
        certificationNumber: 9475324,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1JU595836',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T01:11:28.332Z'
        }
      },
      sort: [
        1591286302845
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXJC975193340982020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:58:08.585Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '14000.00',
          mileage: '15602'
        },
        sellArea: '220',
        dealerCd: '34098',
        certificationNumber: 9477337,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXJC975193',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T14:20:32.366Z'
        }
      },
      sort: [
        1591286288585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HKXJU128710121262020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:56:39.424Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '24537.00',
          mileage: '16338'
        },
        dealerCd: '12126',
        certificationNumber: 9181763,
        makeCode: 'TOYOTA',
        vin: '4T1B61HKXJU128710',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-02T17:41:16.195Z'
        }
      },
      sort: [
        1591286199424
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW695322340982020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:56:08.243Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18700.00',
          mileage: '9812'
        },
        sellArea: '220',
        dealerCd: '34098',
        certificationNumber: 9464596,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW695322',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T18:35:17.786Z'
        }
      },
      sort: [
        1591286168243
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV9HD106667340262020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:55:46.203Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '28691'
        },
        sellArea: '220',
        dealerCd: '34026',
        certificationNumber: 9471522,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV9HD106667',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T20:43:41.367Z'
        }
      },
      sort: [
        1591286146203
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU703814340262020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:55:16.223Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18272.15',
          mileage: '3601'
        },
        sellArea: '800',
        dealerCd: '34026',
        certificationNumber: 9477449,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU703814',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:40:36.102Z'
        }
      },
      sort: [
        1591286116223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HJ145125440142020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:54:44.004Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '29277'
        },
        sellArea: '170',
        dealerCd: '44014',
        certificationNumber: 9497197,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HJ145125',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:46:30.544Z'
        }
      },
      sort: [
        1591286084004
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6GP564480043092020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:54:40.842Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '12635.00',
          mileage: '28841'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9476288,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6GP564480',
        model: {
          modelDescription: 'COROLLA LE PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1854',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:57:28.238Z'
        }
      },
      sort: [
        1591286080842
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3JW721040340982020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:53:57.982Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '16072'
        },
        sellArea: '220',
        dealerCd: '34098',
        certificationNumber: 9442322,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3JW721040',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T15:49:43.437Z'
        }
      },
      sort: [
        1591286037982
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP8H3063548043092020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:51:45.282Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '20988.00',
          mileage: '44479'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9491846,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP8H3063548',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:19:34.908Z'
        }
      },
      sort: [
        1591285905282
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE9LP037302043092020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:49:24.742Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '16488.00',
          mileage: '8022'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9485777,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE9LP037302',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:15:07.665Z'
        }
      },
      sort: [
        1591285764742
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFLU4EN9FX138262120772020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:48:48.923Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '41566'
        },
        sellArea: '210',
        dealerCd: '12077',
        certificationNumber: 9486006,
        makeCode: 'TOYOTA',
        vin: '5TFLU4EN9FX138262',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:53:30.828Z'
        }
      },
      sort: [
        1591285728923
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC871149043092020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:47:46.900Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '12988.00',
          mileage: '64522'
        },
        sellArea: '600',
        dealerCd: '04309',
        certificationNumber: 9483412,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC871149',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:19:56.141Z'
        }
      },
      sort: [
        1591285666900
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK8HU205044043092020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:45:50.240Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '73197'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9445697,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK8HU205044',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T22:33:32.669Z'
        }
      },
      sort: [
        1591285550240
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3C1RFV3LW067466047392020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:44:31.379Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28998.00',
          mileage: '1247'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9463101,
        makeCode: 'TOYOTA',
        vin: '2T3C1RFV3LW067466',
        model: {
          modelDescription: 'RAV4 XLE PREM FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4477',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T17:56:43.457Z'
        }
      },
      sort: [
        1591285471379
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV3HW362669047392020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:43:29.878Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22148.00',
          mileage: '13371'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9474540,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV3HW362669',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:52:06.498Z'
        }
      },
      sort: [
        1591285409878
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5ENXHX054334340982020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:43:27.898Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23200.00',
          mileage: '20811'
        },
        sellArea: '220',
        dealerCd: '34098',
        certificationNumber: 9439464,
        makeCode: 'TOYOTA',
        vin: '5TFSX5ENXHX054334',
        model: {
          modelDescription: 'TACOMA SR 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7514',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T21:22:55.233Z'
        }
      },
      sort: [
        1591285407898
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU8HJ068817043092020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:42:28.499Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20988.00',
          mileage: '42870'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9470664,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU8HJ068817',
        model: {
          modelDescription: 'Prius v Two Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1243',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:53:53.379Z'
        }
      },
      sort: [
        1591285348499
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1L11AK1LU869764310882020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:41:17.219Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '2519'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9497058,
        makeCode: 'TOYOTA',
        vin: '4T1L11AK1LU869764',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:35:31.627Z'
        }
      },
      sort: [
        1591285277219
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KW063329440132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:41:07.059Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '20067'
        },
        sellArea: '600',
        dealerCd: '44013',
        certificationNumber: 9481434,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KW063329',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T14:27:51.855Z'
        }
      },
      sort: [
        1591285267059
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS402760220302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:40:57.897Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28475.00',
          mileage: '26988'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9393234,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS402760',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T15:41:50.435Z'
        }
      },
      sort: [
        1591285257897
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV3KW020197043092020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:40:24.517Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21988.00',
          mileage: '13122'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9432097,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV3KW020197',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T18:29:12.000Z'
        }
      },
      sort: [
        1591285224517
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU722631371752020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:38:54.518Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '34834'
        },
        sellArea: '600',
        dealerCd: '37175',
        certificationNumber: 9440534,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU722631',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T12:54:26.791Z'
        }
      },
      sort: [
        1591285134518
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DCXHS784887043092020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:38:13.436Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22788.00',
          mileage: '46336'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9452757,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DCXHS784887',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T23:18:17.217Z'
        }
      },
      sort: [
        1591285093436
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE7LJ047296020362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:37:33.356Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17478.00',
          mileage: '27343'
        },
        sellArea: '500',
        dealerCd: '02036',
        certificationNumber: 9495426,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE7LJ047296',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:20:20.093Z'
        }
      },
      sort: [
        1591285053356
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1GC686468020362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:35:01.035Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13898.00',
          mileage: '21617'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9491759,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1GC686468',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:58:35.344Z'
        }
      },
      sort: [
        1591284901035
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F14LX898024490312020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:34:26.796Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '57313.99',
          mileage: '7395'
        },
        sellArea: '150',
        dealerCd: '49031',
        certificationNumber: 9493946,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F14LX898024',
        model: {
          modelDescription: 'TUNDRA TRD PRO CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8371',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:57:46.613Z'
        }
      },
      sort: [
        1591284866796
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTKJF5C75GJ020623201282020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:29:29.333Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15238.00',
          mileage: '21198'
        },
        dealerCd: '20128',
        certificationNumber: 9272044,
        makeCode: 'TOYOTA',
        vin: 'JTKJF5C75GJ020623',
        model: {
          modelDescription: 'SCION tC 3 DOOR LIFTBACK',
          modelSeries: 'SCION TC',
          modelNumber: '6222',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-02T16:28:23.620Z'
        }
      },
      sort: [
        1591284569333
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F14HX644409430262020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:26:44.072Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '38561.00',
          mileage: '32886'
        },
        sellArea: '230',
        dealerCd: '43026',
        certificationNumber: 9492622,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F14HX644409',
        model: {
          modelDescription: 'TUNDRA 4X4 PLATINUM CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8377',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:50:21.519Z'
        }
      },
      sort: [
        1591284404072
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU622340371752020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:25:21.293Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '21375.00',
          mileage: '25201'
        },
        sellArea: '800',
        dealerCd: '37175',
        certificationNumber: 9469737,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXJU622340',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T14:44:31.141Z'
        }
      },
      sort: [
        1591284321293
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW647946440142020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:25:19.101Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22000.00',
          mileage: '22700'
        },
        sellArea: '170',
        dealerCd: '44014',
        certificationNumber: 9497123,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW647946',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T15:09:51.475Z'
        }
      },
      sort: [
        1591284319101
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1FB1KU035599043592020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:21:51.259Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24965.00',
          mileage: '21824'
        },
        sellArea: '500',
        dealerCd: '04359',
        certificationNumber: 9478207,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1FB1KU035599',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:08:09.738Z'
        }
      },
      sort: [
        1591284111259
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1FK0GU573321046882020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:21:20.758Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19981.00',
          mileage: '23031'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9476760,
        makeCode: 'TOYOTA',
        vin: '4T1BK1FK0GU573321',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2554',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T23:03:52.246Z'
        }
      },
      sort: [
        1591284080758
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DU4E0380010041632020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:21:01.258Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13995.00',
          mileage: '32957'
        },
        sellArea: '120',
        dealerCd: '04163',
        certificationNumber: 9487509,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DU4E0380010',
        model: {
          modelDescription: 'PRIUS Two Model',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:15:46.479Z'
        }
      },
      sort: [
        1591284061258
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE4LJ102254020552020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:20:41.181Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '869'
        },
        sellArea: '150',
        dealerCd: '02055',
        certificationNumber: 9494766,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE4LJ102254',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:55:00.637Z'
        }
      },
      sort: [
        1591284041181
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE5LJ011281046882020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:20:15.998Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20982.00',
          mileage: '13154'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9460121,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE5LJ011281',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:37:34.237Z'
        }
      },
      sort: [
        1591284015998
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC863404201282020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:20:13.050Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '11289.00',
          mileage: '21311'
        },
        sellArea: '170',
        dealerCd: '20128',
        certificationNumber: 9491457,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC863404',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:41:35.018Z'
        }
      },
      sort: [
        1591284013050
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE1LP044611043092020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:19:34.489Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '21488.00',
          mileage: '1005'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9445682,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE1LP044611',
        model: {
          modelDescription: 'COROLLA SE NIGHTSHADE',
          modelSeries: 'COROLLA',
          modelNumber: '1868',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T22:21:44.345Z'
        }
      },
      sort: [
        1591283974489
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0JW724685371752020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:18:59.527Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '21999.00',
          mileage: '23580'
        },
        dealerCd: '37175',
        certificationNumber: 9379694,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0JW724685',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-13T17:28:53.785Z'
        }
      },
      sort: [
        1591283939527
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW637076371752020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:18:42.449Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '19998.00',
          mileage: '22423'
        },
        dealerCd: '37175',
        certificationNumber: 9377600,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW637076',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-12T15:00:49.778Z'
        }
      },
      sort: [
        1591283922449
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHW5F10FX416788430192020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:17:12.949Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35151.50',
          mileage: '45823'
        },
        sellArea: '150',
        dealerCd: '43019',
        certificationNumber: 9494265,
        makeCode: 'TOYOTA',
        vin: '5TFHW5F10FX416788',
        model: {
          modelDescription: 'TUNDRA 4X4 LTD CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8374',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:59:51.211Z'
        }
      },
      sort: [
        1591283832949
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU669162043092020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:17:09.228Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15988.00',
          mileage: '51978'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9453716,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU669162',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T18:47:42.610Z'
        }
      },
      sort: [
        1591283829228
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC7HS164516020552020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:17:06.188Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25804.00',
          mileage: '21950'
        },
        sellArea: '150',
        dealerCd: '02055',
        certificationNumber: 9487377,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC7HS164516',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T16:24:40.700Z'
        }
      },
      sort: [
        1591283826188
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDFPRAE6LJ019039410302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:16:43.469Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20780.00',
          mileage: '15230'
        },
        sellArea: '220',
        dealerCd: '41030',
        certificationNumber: 9444294,
        makeCode: 'TOYOTA',
        vin: 'JTDFPRAE6LJ019039',
        model: {
          modelDescription: 'COROLLA XLE',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T16:59:36.788Z'
        }
      },
      sort: [
        1591283803469
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3KM261763410302020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:15:28.688Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '37982.50',
          mileage: '11435'
        },
        sellArea: '220',
        dealerCd: '41030',
        certificationNumber: 9481512,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3KM261763',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:22:43.188Z'
        }
      },
      sort: [
        1591283728688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN7HX056316410302020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:14:37.367Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25682.45',
          mileage: '39660'
        },
        sellArea: '220',
        dealerCd: '41030',
        certificationNumber: 9462585,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN7HX056316',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T14:08:22.575Z'
        }
      },
      sort: [
        1591283677367
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH5HS026592042012020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:12:47.505Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24599.00',
          mileage: '33406'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9419165,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH5HS026592',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T21:25:56.774Z'
        }
      },
      sort: [
        1591283567505
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5572228042012020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:11:25.136Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '36000.00',
          mileage: '11911'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9474588,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5572228',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:17:25.109Z'
        }
      },
      sort: [
        1591283485136
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8K5728142043332020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:11:03.406Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '48995.00',
          mileage: '7860'
        },
        sellArea: '110',
        dealerCd: '04333',
        certificationNumber: 9484499,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8K5728142',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:47:28.105Z'
        }
      },
      sort: [
        1591283463406
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU4J3064317045282020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:09:47.367Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22767.00',
          mileage: '21918'
        },
        sellArea: '110',
        dealerCd: '04528',
        certificationNumber: 9473565,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU4J3064317',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T22:19:53.817Z'
        }
      },
      sort: [
        1591283387367
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6GW464432045282020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T15:09:24.966Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18699.00',
          mileage: '57651'
        },
        sellArea: '210',
        dealerCd: '04528',
        certificationNumber: 9468692,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6GW464432',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T17:56:05.485Z'
        }
      },
      sort: [
        1591283364966
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS371268290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:57:26.832Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27477.00',
          mileage: '20964'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9482355,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS371268',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:43:00.759Z'
        }
      },
      sort: [
        1591282646832
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW570442290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:56:39.892Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17600.00',
          mileage: '45244'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9434048,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW570442',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T17:48:12.553Z'
        }
      },
      sort: [
        1591282599892
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV1HY161545311322020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:55:47.331Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '12000.00',
          mileage: '33683'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9430080,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV1HY161545',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-22T16:06:09.748Z'
        }
      },
      sort: [
        1591282547331
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV4HY158655290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:53:20.851Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '11250.00',
          mileage: '30115'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9485855,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV4HY158655',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:45:05.668Z'
        }
      },
      sort: [
        1591282400851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7LC103193120902020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:53:05.633Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26965.00',
          mileage: '7'
        },
        sellArea: '210',
        dealerCd: '12090',
        certificationNumber: 9497075,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7LC103193',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:47:06.709Z'
        }
      },
      sort: [
        1591282385633
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE8LJ024523290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:51:58.331Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17111.00',
          mileage: '18003'
        },
        sellArea: '500',
        dealerCd: '29025',
        certificationNumber: 9462101,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE8LJ024523',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T19:04:09.543Z'
        }
      },
      sort: [
        1591282318331
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC794206371482020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:51:45.972Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15491.00',
          mileage: '20348'
        },
        sellArea: '800',
        dealerCd: '37148',
        certificationNumber: 9483237,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC794206',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:30:29.709Z'
        }
      },
      sort: [
        1591282305972
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV5HD119101210432020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:50:52.772Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '44289'
        },
        sellArea: '220',
        dealerCd: '21043',
        certificationNumber: 9482267,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV5HD119101',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:01:13.039Z'
        }
      },
      sort: [
        1591282252772
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4FW269678200062020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:50:21.191Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15400.00',
          mileage: '71830'
        },
        sellArea: '170',
        dealerCd: '20006',
        certificationNumber: 9481443,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4FW269678',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T14:38:29.610Z'
        }
      },
      sort: [
        1591282221191
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ119776371482020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:50:04.949Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19699.00',
          mileage: '32991'
        },
        dealerCd: '37148',
        certificationNumber: 9390821,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HJ119776',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T17:54:27.303Z'
        }
      },
      sort: [
        1591282204949
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS512493290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:50:03.049Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28311.00',
          mileage: '11729'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9462125,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS512493',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T19:31:14.006Z'
        }
      },
      sort: [
        1591282203049
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7L5742681310882020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:48:31.791Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35500.00',
          mileage: '5693'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9491992,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7L5742681',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:58:28.874Z'
        }
      },
      sort: [
        1591282111791
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6JJ747306410012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:48:26.451Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19125.00',
          mileage: '25584'
        },
        sellArea: '500',
        dealerCd: '41001',
        certificationNumber: 9464226,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6JJ747306',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T15:12:04.143Z'
        }
      },
      sort: [
        1591282106451
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F19KX855278220302020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:45:45.790Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '40000.00',
          mileage: '4955'
        },
        sellArea: '150',
        dealerCd: '22030',
        certificationNumber: 9491059,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F19KX855278',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T12:58:32.435Z'
        }
      },
      sort: [
        1591281945790
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFHXHS373975311322020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:44:50.069Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22100.00',
          mileage: '36722'
        },
        sellArea: '160',
        dealerCd: '31132',
        certificationNumber: 9457505,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFHXHS373975',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T16:31:30.213Z'
        }
      },
      sort: [
        1591281890069
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE5GJ514298210432020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:44:35.550Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13100.00',
          mileage: '41259'
        },
        sellArea: '220',
        dealerCd: '21043',
        certificationNumber: 9395363,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE5GJ514298',
        model: {
          modelDescription: 'SCION iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T17:05:05.187Z'
        }
      },
      sort: [
        1591281875550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW662305190522020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:43:46.569Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '18995.0000',
          mileage: '14279'
        },
        sellArea: '800',
        dealerCd: '19052',
        certificationNumber: 9497012,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW662305',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:20:08.203Z'
        }
      },
      sort: [
        1591281826569
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1FB0KU032550190522020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:43:44.068Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '24995.0000',
          mileage: '21026'
        },
        sellArea: '500',
        dealerCd: '19052',
        certificationNumber: 9497011,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1FB0KU032550',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:18:18.142Z'
        }
      },
      sort: [
        1591281824068
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV4JD155393190522020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:43:41.788Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27295.00',
          mileage: '16485'
        },
        sellArea: '800',
        dealerCd: '19052',
        certificationNumber: 9497006,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV4JD155393',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:15:03.262Z'
        }
      },
      sort: [
        1591281821788
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2FW333657200872020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:43:00.689Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '15699.00',
          mileage: '45703'
        },
        sellArea: '170',
        dealerCd: '20087',
        certificationNumber: 9419008,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2FW333657',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T20:05:05.449Z'
        }
      },
      sort: [
        1591281780689
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHJ113184210322020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:41:10.850Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19724.00',
          mileage: '30400'
        },
        sellArea: '130',
        dealerCd: '21032',
        certificationNumber: 9489612,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHJ113184',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:12:24.038Z'
        }
      },
      sort: [
        1591281670850
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4JD228446200872020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:39:40.610Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '20817.00',
          mileage: '34580'
        },
        sellArea: '170',
        dealerCd: '20087',
        certificationNumber: 9428899,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4JD228446',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T19:20:17.754Z'
        }
      },
      sort: [
        1591281580610
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ145172311052020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:39:28.867Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19998.00',
          mileage: '7485'
        },
        sellArea: '160',
        dealerCd: '31105',
        certificationNumber: 9496421,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ145172',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:51:31.017Z'
        }
      },
      sort: [
        1591281568867
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS420535311192020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:38:49.647Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27848.61',
          mileage: '31371'
        },
        sellArea: '160',
        dealerCd: '31119',
        certificationNumber: 9477508,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS420535',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:57:02.367Z'
        }
      },
      sort: [
        1591281529647
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F14GX536106200872020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:38:32.889Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '34700.00',
          mileage: '41365'
        },
        sellArea: '170',
        dealerCd: '20087',
        certificationNumber: 9428922,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F14GX536106',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 EDITION CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8376',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T19:25:06.296Z'
        }
      },
      sort: [
        1591281512889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KC008950201402020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:37:55.053Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23800.00',
          mileage: '17905'
        },
        sellArea: '500',
        dealerCd: '20140',
        certificationNumber: 9492753,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KC008950',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:28:55.354Z'
        }
      },
      sort: [
        1591281475053
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2KC007180311192020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:37:10.814Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23625.00',
          mileage: '11625'
        },
        sellArea: '600',
        dealerCd: '31119',
        certificationNumber: 9415648,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2KC007180',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T19:34:01.434Z'
        }
      },
      sort: [
        1591281430814
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KC018263311192020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:35:31.733Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24995.00',
          mileage: '15481'
        },
        sellArea: '600',
        dealerCd: '31119',
        certificationNumber: 9430315,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KC018263',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T14:28:07.154Z'
        }
      },
      sort: [
        1591281331733
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9JW814854050492020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:34:55.552Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19998.48',
          mileage: '47032'
        },
        dealerCd: '05049',
        certificationNumber: 9384943,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9JW814854',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T00:03:39.128Z'
        }
      },
      sort: [
        1591281295552
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU335423311192020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:33:12.692Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14995.00',
          mileage: '26288'
        },
        sellArea: '160',
        dealerCd: '31119',
        certificationNumber: 9432680,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU335423',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T11:23:06.109Z'
        }
      },
      sort: [
        1591281192692
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV9FJ046847140462020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:30:38.911Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18410.00',
          mileage: '47710'
        },
        sellArea: '230',
        dealerCd: '14046',
        certificationNumber: 9495630,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV9FJ046847',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:55:31.625Z'
        }
      },
      sort: [
        1591281038911
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP689801371742020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:29:57.290Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15490.00',
          mileage: '28034'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9481968,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP689801',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:33:34.583Z'
        }
      },
      sort: [
        1591280997290
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAEXLP106143140462020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:29:56.927Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '19595.00',
          mileage: '2044'
        },
        sellArea: '600',
        dealerCd: '14046',
        certificationNumber: 9475828,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAEXLP106143',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T15:05:59.781Z'
        }
      },
      sort: [
        1591280996927
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BPRHE6GC600319140462020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:29:10.410Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15434.00',
          mileage: '32355'
        },
        dealerCd: '14046',
        certificationNumber: 9453574,
        makeCode: 'TOYOTA',
        vin: '2T1BPRHE6GC600319',
        model: {
          modelDescription: 'COROLLA',
          modelSeries: 'COROLLA',
          modelNumber: '1872',
          modelYear: '2016'
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T17:50:59.184Z'
        }
      },
      sort: [
        1591280950410
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5FW365111311192020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:29:08.410Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '66927'
        },
        sellArea: '160',
        dealerCd: '31119',
        certificationNumber: 9450922,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5FW365111',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T11:48:21.402Z'
        }
      },
      sort: [
        1591280948410
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC938803440132020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:26:23.550Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '9900'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9495238,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC938803',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:04:20.424Z'
        }
      },
      sort: [
        1591280783550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMCY7AJ3K4077513371252020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:24:44.127Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '66995.00',
          mileage: '15179'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9450649,
        makeCode: 'TOYOTA',
        vin: 'JTMCY7AJ3K4077513',
        model: {
          modelDescription: 'LAND CRUISER 4WD SUV V8',
          modelSeries: 'LAND CRUISER',
          modelNumber: '6156',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T21:40:12.209Z'
        }
      },
      sort: [
        1591280684127
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5KS732460310882020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:23:58.609Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35495.00',
          mileage: '5586'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9497014,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5KS732460',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T14:21:30.864Z'
        }
      },
      sort: [
        1591280638609
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6GP558176340262020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:23:50.646Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '14098.00',
          mileage: '16665'
        },
        sellArea: '220',
        dealerCd: '34026',
        certificationNumber: 9477279,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6GP558176',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T13:43:27.860Z'
        }
      },
      sort: [
        1591280630646
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5ANXLM291005340262020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:22:03.588Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '28000.00',
          mileage: '864'
        },
        sellArea: '220',
        dealerCd: '34026',
        certificationNumber: 9456607,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5ANXLM291005',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T15:00:39.519Z'
        }
      },
      sort: [
        1591280523588
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9JW796775190722020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:21:13.526Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19882.00',
          mileage: '47460'
        },
        sellArea: '600',
        dealerCd: '19072',
        certificationNumber: 9495872,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9JW796775',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:07:55.427Z'
        }
      },
      sort: [
        1591280473526
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3JU509779180332020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:20:18.605Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19990.00',
          mileage: '68093'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9496443,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3JU509779',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T23:07:59.797Z'
        }
      },
      sort: [
        1591280418605
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP656459340262020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:20:15.507Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13500.00',
          mileage: '58040'
        },
        sellArea: '220',
        dealerCd: '34026',
        certificationNumber: 9477460,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP656459',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:44:17.144Z'
        }
      },
      sort: [
        1591280415507
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS370168340262020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:18:22.066Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '27000.00',
          mileage: '64755'
        },
        sellArea: '800',
        dealerCd: '34026',
        certificationNumber: 9422327,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS370168',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T15:55:44.973Z'
        }
      },
      sort: [
        1591280302066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0H5437272440142020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:18:00.984Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31322.00',
          mileage: '32507'
        },
        sellArea: '170',
        dealerCd: '44014',
        certificationNumber: 9495244,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0H5437272',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:08:42.185Z'
        }
      },
      sort: [
        1591280280984
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXJ5490793180332020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:14:33.022Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '37995.00',
          mileage: '34448'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9472983,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXJ5490793',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T18:07:02.687Z'
        }
      },
      sort: [
        1591280073022
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU4H3538389470232020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:14:21.564Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18299.00',
          mileage: '47919'
        },
        sellArea: '800',
        dealerCd: '47023',
        certificationNumber: 9459501,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU4H3538389',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T13:32:50.826Z'
        }
      },
      sort: [
        1591280061564
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN6FM205972140152020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:13:49.482Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24920.00',
          mileage: '57468'
        },
        sellArea: '230',
        dealerCd: '14015',
        certificationNumber: 9477303,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN6FM205972',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T14:08:06.778Z'
        }
      },
      sort: [
        1591280029482
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU786150201112020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:13:12.903Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '15989.00',
          mileage: '33894'
        },
        sellArea: '170',
        dealerCd: '20111',
        certificationNumber: 9488957,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU786150',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T13:21:05.732Z'
        }
      },
      sort: [
        1591279992903
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM057052440142020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:12:12.244Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30500.00',
          mileage: '30508'
        },
        dealerCd: '44014',
        certificationNumber: 9333677,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM057052',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-13T15:36:17.360Z'
        }
      },
      sort: [
        1591279932244
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1L11AK3LU300737450402020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:10:53.242Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20500.0000',
          mileage: '2832'
        },
        sellArea: '800',
        dealerCd: '45040',
        certificationNumber: 9427024,
        makeCode: 'TOYOTA',
        vin: '4T1L11AK3LU300737',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T16:23:31.610Z'
        }
      },
      sort: [
        1591279853242
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5H5424064450402020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:10:50.543Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '31073.5400',
          mileage: '19872'
        },
        sellArea: '800',
        dealerCd: '45040',
        certificationNumber: 9485354,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5H5424064',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:00:39.158Z'
        }
      },
      sort: [
        1591279850543
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV9HD206642130442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:10:46.500Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23890.00',
          mileage: '19845'
        },
        sellArea: '210',
        dealerCd: '13044',
        certificationNumber: 9453111,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV9HD206642',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T14:24:42.316Z'
        }
      },
      sort: [
        1591279846500
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC898793450402020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:10:44.661Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13982.0000',
          mileage: '51388'
        },
        sellArea: '150',
        dealerCd: '45040',
        certificationNumber: 9475636,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC898793',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T12:36:36.263Z'
        }
      },
      sort: [
        1591279844661
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW593862450402020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:10:42.141Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19477.0000',
          mileage: '34450'
        },
        sellArea: '800',
        dealerCd: '45040',
        certificationNumber: 9475859,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW593862',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T15:24:33.047Z'
        }
      },
      sort: [
        1591279842141
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV0GD090433450402020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:10:39.860Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18988.00',
          mileage: '32500'
        },
        sellArea: '800',
        dealerCd: '45040',
        certificationNumber: 9477472,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV0GD090433',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:46:58.649Z'
        }
      },
      sort: [
        1591279839860
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1EB9FU037087410812020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:09:59.820Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19262.96',
          mileage: '41292'
        },
        sellArea: '800',
        dealerCd: '41081',
        certificationNumber: 9470913,
        makeCode: 'TOYOTA',
        vin: '4T1BD1EB9FU037087',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T17:05:18.868Z'
        }
      },
      sort: [
        1591279799820
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBY5F16HX592019470232020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:07:21.321Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31474.25',
          mileage: '53427'
        },
        sellArea: '800',
        dealerCd: '47023',
        certificationNumber: 9494250,
        makeCode: 'TOYOTA',
        vin: '5TFBY5F16HX592019',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8352',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:56:02.041Z'
        }
      },
      sort: [
        1591279641321
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJY5G19JS160384220302020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:06:33.961Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '43765.26',
          mileage: '61823'
        },
        sellArea: '220',
        dealerCd: '22030',
        certificationNumber: 9447289,
        makeCode: 'TOYOTA',
        vin: '5TDJY5G19JS160384',
        model: {
          modelDescription: 'SEQUOIA 4X4 LTD 8-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7929',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T16:42:41.778Z'
        }
      },
      sort: [
        1591279593961
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE7K3061997470232020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T14:04:00.438Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17398.00',
          mileage: '11188'
        },
        dealerCd: '47023',
        certificationNumber: 9354024,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE7K3061997',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-25T18:45:15.838Z'
        }
      },
      sort: [
        1591279440438
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KC007925371522020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:58:03.339Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23490.00',
          mileage: '13329'
        },
        sellArea: '600',
        dealerCd: '37152',
        certificationNumber: 9402308,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KC007925',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-29T00:48:34.364Z'
        }
      },
      sort: [
        1591279083339
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KC018811120422020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:55:55.277Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26775.00',
          mileage: '16541'
        },
        sellArea: '500',
        dealerCd: '12042',
        certificationNumber: 9415732,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KC018811',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T20:02:48.002Z'
        }
      },
      sort: [
        1591278955277
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC1HS839586220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:53:24.870Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21485.00',
          mileage: '38103'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9462647,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC1HS839586',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T14:27:21.697Z'
        }
      },
      sort: [
        1591278804870
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9H5471386240672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:51:43.496Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30372.46',
          mileage: '46664'
        },
        sellArea: '230',
        dealerCd: '24067',
        certificationNumber: 9408403,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9H5471386',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T16:15:55.155Z'
        }
      },
      sort: [
        1591278703496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU227500041542020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:50:41.137Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '18727.00',
          mileage: '21879'
        },
        sellArea: '500',
        dealerCd: '04154',
        certificationNumber: 9427237,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU227500',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T17:54:37.834Z'
        }
      },
      sort: [
        1591278641137
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU402732041542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:49:14.417Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16296.00',
          mileage: '38007'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9402115,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU402732',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T23:09:29.467Z'
        }
      },
      sort: [
        1591278554417
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE2LJ036299410702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:48:50.736Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17787.00',
          mileage: '18613'
        },
        sellArea: '500',
        dealerCd: '41070',
        certificationNumber: 9443068,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE2LJ036299',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-10T18:42:19.898Z'
        }
      },
      sort: [
        1591278530736
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1JU129727041542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:48:30.297Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20597.00',
          mileage: '22249'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9483731,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1JU129727',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:27:20.794Z'
        }
      },
      sort: [
        1591278510297
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS508400410702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:48:20.216Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28909.00',
          mileage: '53429'
        },
        sellArea: '160',
        dealerCd: '41070',
        certificationNumber: 9442536,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS508400',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T19:26:57.123Z'
        }
      },
      sort: [
        1591278500216
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV7JJ726788041542020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:48:06.695Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20998.00',
          mileage: '11722'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9437811,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV7JJ726788',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T19:42:13.393Z'
        }
      },
      sort: [
        1591278486695
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE4HJ524144410702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:47:44.035Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14992.00',
          mileage: '35718'
        },
        sellArea: '190',
        dealerCd: '41070',
        certificationNumber: 9434125,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE4HJ524144',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T18:50:00.057Z'
        }
      },
      sort: [
        1591278464035
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV0HJ708787041542020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:47:40.774Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '26182'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9414106,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV0HJ708787',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T20:22:19.471Z'
        }
      },
      sort: [
        1591278460774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP631606410702020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:47:22.794Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15787.00',
          mileage: '29702'
        },
        sellArea: '600',
        dealerCd: '41070',
        certificationNumber: 9493736,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP631606',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:06:14.815Z'
        }
      },
      sort: [
        1591278442794
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH6HS032031041542020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:46:56.596Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22072.00',
          mileage: '44553'
        },
        sellArea: '110',
        dealerCd: '04154',
        certificationNumber: 9484021,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH6HS032031',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:22:31.140Z'
        }
      },
      sort: [
        1591278416596
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC1HS172415130752020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:45:58.716Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '28981.00',
          mileage: '45290'
        },
        sellArea: '210',
        dealerCd: '13075',
        certificationNumber: 9496251,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC1HS172415',
        model: {
          modelDescription: 'SIENNA LTD PREM AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5387',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:49:59.484Z'
        }
      },
      sort: [
        1591278358716
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN3HM023231371762020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:42:13.095Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29999.00',
          mileage: '14593'
        },
        dealerCd: '37176',
        certificationNumber: 9383879,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN3HM023231',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T17:28:27.941Z'
        }
      },
      sort: [
        1591278133095
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM088899371762020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:41:09.968Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29626.00',
          mileage: '53655'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9474246,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM088899',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:42:28.448Z'
        }
      },
      sort: [
        1591278069968
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7G5311327371942020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:40:07.948Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27122.35',
          mileage: '70443'
        },
        sellArea: '500',
        dealerCd: '37194',
        certificationNumber: 9407355,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7G5311327',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T19:44:18.064Z'
        }
      },
      sort: [
        1591278007948
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1L11AK5LU869895310882020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:37:11.514Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23158.00',
          mileage: '6328'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9496939,
        makeCode: 'TOYOTA',
        vin: '4T1L11AK5LU869895',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:33:55.513Z'
        }
      },
      sort: [
        1591277831514
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN5JX122145380312020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:35:47.953Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35213.26',
          mileage: '15029'
        },
        sellArea: '170',
        dealerCd: '38031',
        certificationNumber: 9468117,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN5JX122145',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T12:35:16.936Z'
        }
      },
      sort: [
        1591277747953
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV5GD037152330272020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:35:43.314Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21950.00',
          mileage: '36969'
        },
        dealerCd: '33027',
        certificationNumber: 9126521,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV5GD037152',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-08-28T19:36:12.432Z'
        }
      },
      sort: [
        1591277743314
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1EW186109371612020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:35:13.033Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15400.00',
          mileage: '46210'
        },
        sellArea: '800',
        dealerCd: '37161',
        certificationNumber: 9435000,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1EW186109',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T18:33:13.003Z'
        }
      },
      sort: [
        1591277713033
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS407916190472020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:35:08.452Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '29999.0000',
          mileage: '31730'
        },
        sellArea: '160',
        dealerCd: '19047',
        certificationNumber: 9468184,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS407916',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T14:13:01.529Z'
        }
      },
      sort: [
        1591277708452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU442607341202020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:34:16.952Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17900.00',
          mileage: '9482'
        },
        sellArea: '220',
        dealerCd: '34120',
        certificationNumber: 9484263,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU442607',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:52:00.507Z'
        }
      },
      sort: [
        1591277656952
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW612599371612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:30:35.352Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22998.00',
          mileage: '18742'
        },
        sellArea: '800',
        dealerCd: '37161',
        certificationNumber: 9476690,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW612599',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:42:06.953Z'
        }
      },
      sort: [
        1591277435352
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU397785291082020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:28:41.133Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14670.06',
          mileage: '15031'
        },
        sellArea: '160',
        dealerCd: '29108',
        certificationNumber: 9468349,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU397785',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T15:32:35.773Z'
        }
      },
      sort: [
        1591277321133
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0JW829436371942020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:28:31.871Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23297.34',
          mileage: '37772'
        },
        sellArea: '800',
        dealerCd: '37194',
        certificationNumber: 9474178,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0JW829436',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:00:59.166Z'
        }
      },
      sort: [
        1591277311871
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM086649371612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:27:29.150Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27949.00',
          mileage: '47421'
        },
        sellArea: '170',
        dealerCd: '37161',
        certificationNumber: 9476711,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM086649',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:53:07.580Z'
        }
      },
      sort: [
        1591277249150
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7HD141293180372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:23:25.231Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21338.00',
          mileage: '28956'
        },
        dealerCd: '18037',
        certificationNumber: 9368449,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7HD141293',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T20:46:15.481Z'
        }
      },
      sort: [
        1591277005231
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU230096121022020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:22:36.451Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21383.11',
          mileage: '15947'
        },
        sellArea: '210',
        dealerCd: '12102',
        certificationNumber: 9496909,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU230096',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T13:14:23.898Z'
        }
      },
      sort: [
        1591276956451
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9G5378303180372020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:22:34.248Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25888.00',
          mileage: '65632'
        },
        dealerCd: '18037',
        certificationNumber: 9383813,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9G5378303',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T17:03:18.769Z'
        }
      },
      sort: [
        1591276954248
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8HM073197340442020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:21:56.831Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '40882'
        },
        sellArea: '170',
        dealerCd: '34044',
        certificationNumber: 9478798,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8HM073197',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T11:17:49.151Z'
        }
      },
      sort: [
        1591276916831
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW674344371612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:21:35.950Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19297.00',
          mileage: '26742'
        },
        sellArea: '800',
        dealerCd: '37161',
        certificationNumber: 9467547,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW674344',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T21:08:33.630Z'
        }
      },
      sort: [
        1591276895950
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK9J3050795340442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:15:26.408Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20429.00',
          mileage: '37440'
        },
        sellArea: '170',
        dealerCd: '34044',
        certificationNumber: 9475215,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK9J3050795',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T22:55:42.326Z'
        }
      },
      sort: [
        1591276526408
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ707292371942020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:11:45.307Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '21915'
        },
        sellArea: '800',
        dealerCd: '37194',
        certificationNumber: 9480201,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ707292',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T14:28:46.554Z'
        }
      },
      sort: [
        1591276305307
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5537026450532020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:10:12.146Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '34750.00',
          mileage: '32034'
        },
        sellArea: '800',
        dealerCd: '45053',
        certificationNumber: 9465898,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5537026',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:41:36.115Z'
        }
      },
      sort: [
        1591276212146
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV9HD074891371382019-12-11H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:07:33.666Z',
          retailDate: '2019-12-11',
          rdrStatus: 'H',
          soldAmount: '21193.29',
          mileage: '41790'
        },
        dealerCd: '37138',
        certificationNumber: 9286545,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV9HD074891',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-11T22:55:01.246Z'
        }
      },
      sort: [
        1591276053666
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK9FR515099371942020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T13:07:22.145Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14175.00',
          mileage: '58048'
        },
        sellArea: '800',
        dealerCd: '37194',
        certificationNumber: 9451272,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK9FR515099',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T16:42:02.865Z'
        }
      },
      sort: [
        1591276042145
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU149215410612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:51:37.031Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '3936'
        },
        sellArea: '500',
        dealerCd: '41061',
        certificationNumber: 9419596,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU149215',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T13:20:09.299Z'
        }
      },
      sort: [
        1591275097031
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU746011121022020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:50:57.690Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19875.09',
          mileage: '30109'
        },
        sellArea: '210',
        dealerCd: '12102',
        certificationNumber: 9453699,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU746011',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T18:34:40.903Z'
        }
      },
      sort: [
        1591275057690
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KC018532121022020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:49:38.989Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24877.22',
          mileage: '39750'
        },
        sellArea: '600',
        dealerCd: '12102',
        certificationNumber: 9410709,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KC018532',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T01:18:55.520Z'
        }
      },
      sort: [
        1591274978989
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXGD177379450862020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:49:33.669Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17999.00',
          mileage: '50010'
        },
        sellArea: '160',
        dealerCd: '45086',
        certificationNumber: 9481382,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXGD177379',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T13:32:20.490Z'
        }
      },
      sort: [
        1591274973669
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZRFH6HS218158410612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:47:24.430Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25250.00',
          mileage: '44662'
        },
        sellArea: '600',
        dealerCd: '41061',
        certificationNumber: 9477432,
        makeCode: 'TOYOTA',
        vin: '5TDZZRFH6HS218158',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6947',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:29:17.620Z'
        }
      },
      sort: [
        1591274844430
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC888747371492020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:44:18.229Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14963.00',
          mileage: '6937'
        },
        sellArea: '800',
        dealerCd: '37149',
        certificationNumber: 9486905,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC888747',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:05:21.997Z'
        }
      },
      sort: [
        1591274658229
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN6FM164680371182020-06-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:20:08.717Z',
          retailDate: '2020-06-04',
          rdrStatus: 'H',
          soldAmount: '23990.00',
          mileage: '63175'
        },
        sellArea: '800',
        dealerCd: '37118',
        certificationNumber: 9496853,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN6FM164680',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-04T12:12:55.845Z'
        }
      },
      sort: [
        1591273208717
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU395718210672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:19:25.659Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '28637'
        },
        sellArea: '160',
        dealerCd: '21067',
        certificationNumber: 9411000,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU395718',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T14:11:19.972Z'
        }
      },
      sort: [
        1591273165659
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F12JX770953340062020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:19:18.779Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '38895.00',
          mileage: '37023'
        },
        sellArea: '220',
        dealerCd: '34006',
        certificationNumber: 9429884,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F12JX770953',
        model: {
          modelDescription: 'TUNDRA 4X4 PLATINUM CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8377',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T19:08:14.507Z'
        }
      },
      sort: [
        1591273158779
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW607003340062020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:18:16.850Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21900.00',
          mileage: '35702'
        },
        sellArea: '220',
        dealerCd: '34006',
        certificationNumber: 9472465,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW607003',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T14:23:07.858Z'
        }
      },
      sort: [
        1591273096850
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM088921180342020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T12:10:44.614Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29999.00',
          mileage: '33279'
        },
        sellArea: '170',
        dealerCd: '18034',
        certificationNumber: 9493839,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM088921',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:08:55.130Z'
        }
      },
      sort: [
        1591272644614
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC853412410012020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T11:55:55.107Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15400.00',
          mileage: '9787'
        },
        sellArea: '220',
        dealerCd: '41001',
        certificationNumber: 9464191,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC853412',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T14:52:43.509Z'
        }
      },
      sort: [
        1591271755107
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9JJ726807280242020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T11:54:48.188Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19890.00',
          mileage: '20318'
        },
        sellArea: '160',
        dealerCd: '28024',
        certificationNumber: 9474654,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9JJ726807',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:48:22.519Z'
        }
      },
      sort: [
        1591271688188
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH8HS380268291012020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T11:24:00.636Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33989.50',
          mileage: '28269'
        },
        sellArea: '160',
        dealerCd: '29101',
        certificationNumber: 9404900,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH8HS380268',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T18:24:11.551Z'
        }
      },
      sort: [
        1591269840636
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP688150042222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T04:22:56.180Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14591.00',
          mileage: '40728'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9487504,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP688150',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:12:24.350Z'
        }
      },
      sort: [
        1591244576180
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1K5620686361032020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:50:32.585Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '43981.00',
          mileage: '14611'
        },
        sellArea: '130',
        dealerCd: '36103',
        certificationNumber: 9446828,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1K5620686',
        model: {
          modelDescription: '4RUNNER 4X4 NIGHTSHADE V6',
          modelSeries: '4RUNNER',
          modelNumber: '8669',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T21:48:08.435Z'
        }
      },
      sort: [
        1591242632585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV8HW381629042222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:31:09.847Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23250.00',
          mileage: '31071'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9431375,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV8HW381629',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T23:21:58.483Z'
        }
      },
      sort: [
        1591241469847
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV5KW004266042222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:29:15.558Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24496.00',
          mileage: '16344'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9409076,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV5KW004266',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T21:42:33.181Z'
        }
      },
      sort: [
        1591241355558
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV8KW013480042222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:28:41.857Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22991.00',
          mileage: '25829'
        },
        sellArea: '500',
        dealerCd: '04222',
        certificationNumber: 9488574,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV8KW013480',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:18:03.687Z'
        }
      },
      sort: [
        1591241321857
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE1LJ009160042222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:27:32.317Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17991.00',
          mileage: '10184'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9480469,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE1LJ009160',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T16:52:31.124Z'
        }
      },
      sort: [
        1591241252317
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE6LP016285042222020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:25:36.476Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19650.00',
          mileage: '12134'
        },
        sellArea: '600',
        dealerCd: '04222',
        certificationNumber: 9494278,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE6LP016285',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:05:39.066Z'
        }
      },
      sort: [
        1591241136476
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3GP421650046362020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:18:08.113Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13987.00',
          mileage: '20266'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9491922,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3GP421650',
        model: {
          modelDescription: 'COROLLA LE PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1854',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:43:40.522Z'
        }
      },
      sort: [
        1591240688113
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7JS491001020422020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:12:46.051Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30991.00',
          mileage: '25763'
        },
        sellArea: '210',
        dealerCd: '02042',
        certificationNumber: 9447729,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7JS491001',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T03:16:44.327Z'
        }
      },
      sort: [
        1591240366051
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9JJ728037020422020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:11:08.150Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22321.00',
          mileage: '17162'
        },
        sellArea: '150',
        dealerCd: '02042',
        certificationNumber: 9435785,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9JJ728037',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-29T17:14:39.226Z'
        }
      },
      sort: [
        1591240268150
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU202954020422020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:09:33.287Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18784.00',
          mileage: '37779'
        },
        sellArea: '500',
        dealerCd: '02042',
        certificationNumber: 9432077,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU202954',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T18:21:31.499Z'
        }
      },
      sort: [
        1591240173287
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN8KX187010020422020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:07:43.429Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '31599.00',
          mileage: '25593'
        },
        sellArea: '600',
        dealerCd: '02042',
        certificationNumber: 9479762,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN8KX187010',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T23:05:10.007Z'
        }
      },
      sort: [
        1591240063429
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAZ5CN1KX081194020422020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:04:22.547Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '30980.00',
          mileage: '29667'
        },
        sellArea: '600',
        dealerCd: '02042',
        certificationNumber: 9482669,
        makeCode: 'TOYOTA',
        vin: '5TFAZ5CN1KX081194',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T00:35:09.555Z'
        }
      },
      sort: [
        1591239862547
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1EU331610020422020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T03:01:16.884Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '12074.00',
          mileage: '58523'
        },
        sellArea: '210',
        dealerCd: '02042',
        certificationNumber: 9487832,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1EU331610',
        model: {
          modelDescription: 'CAMRY 2014.5 SE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:13:06.987Z'
        }
      },
      sort: [
        1591239676884
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU427257041872020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T02:47:22.098Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13700.00',
          mileage: '53091'
        },
        sellArea: '110',
        dealerCd: '04187',
        certificationNumber: 9495760,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU427257',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:30:27.934Z'
        }
      },
      sort: [
        1591238842098
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9FW400630050432020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T02:41:43.116Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '36616'
        },
        sellArea: '150',
        dealerCd: '05043',
        certificationNumber: 9479932,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9FW400630',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-24T17:17:56.607Z'
        }
      },
      sort: [
        1591238503116
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW647802042152020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T02:39:28.577Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21685.00',
          mileage: '38817'
        },
        sellArea: '120',
        dealerCd: '04215',
        certificationNumber: 9473403,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW647802',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T20:55:37.154Z'
        }
      },
      sort: [
        1591238368577
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F19JX744571371382020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T02:27:04.624Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '36882.76',
          mileage: '32304'
        },
        sellArea: '800',
        dealerCd: '37138',
        certificationNumber: 9460353,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F19JX744571',
        model: {
          modelDescription: 'TUNDRA 4X4 PLATINUM CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8375',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T23:16:37.448Z'
        }
      },
      sort: [
        1591237624624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX0JR013511046362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T02:25:49.546Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18552.00',
          mileage: '36250'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9460130,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX0JR013511',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:40:05.279Z'
        }
      },
      sort: [
        1591237549546
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV1HJ149895046362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T02:22:37.602Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18498.58',
          mileage: '31453'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9473650,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV1HJ149895',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T23:08:39.700Z'
        }
      },
      sort: [
        1591237357602
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1HW648253201452020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T02:16:15.377Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18878.00',
          mileage: '58492'
        },
        sellArea: '170',
        dealerCd: '20145',
        certificationNumber: 9487206,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1HW648253',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:26:37.894Z'
        }
      },
      sort: [
        1591236975377
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC783867044122020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T01:56:28.905Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14584.00',
          mileage: '32381'
        },
        dealerCd: '04412',
        certificationNumber: 9471859,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC783867',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T22:52:34.534Z'
        }
      },
      sort: [
        1591235788905
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU335406201452020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T01:49:34.566Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16697.00',
          mileage: '4386'
        },
        sellArea: '170',
        dealerCd: '20145',
        certificationNumber: 9449860,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU335406',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T13:36:28.734Z'
        }
      },
      sort: [
        1591235374566
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7J5598627060022020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T01:36:18.617Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '32999.00',
          mileage: '17498'
        },
        sellArea: '160',
        dealerCd: '06002',
        certificationNumber: 9464333,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7J5598627',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T16:00:08.266Z'
        }
      },
      sort: [
        1591234578617
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFHXFS140821150502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T01:35:40.578Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25800.00',
          mileage: '50383'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9485882,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFHXFS140821',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:51:41.378Z'
        }
      },
      sort: [
        1591234540578
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU360785270132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T01:12:20.986Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17750.00',
          mileage: '22676'
        },
        sellArea: '110',
        dealerCd: '27013',
        certificationNumber: 9494475,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU360785',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:27:29.750Z'
        }
      },
      sort: [
        1591233140986
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2H5451903270132020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T01:09:52.464Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '46120'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9466955,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2H5451903',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T16:07:45.270Z'
        }
      },
      sort: [
        1591232992464
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS411321250642020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T01:06:47.343Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30490.00',
          mileage: '34456'
        },
        sellArea: '130',
        dealerCd: '25064',
        certificationNumber: 9468210,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS411321',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T14:23:30.649Z'
        }
      },
      sort: [
        1591232807343
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMMWRFV1KD007591250642020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T01:04:33.022Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26990.00',
          mileage: '7442'
        },
        sellArea: '130',
        dealerCd: '25064',
        certificationNumber: 9481456,
        makeCode: 'TOYOTA',
        vin: 'JTMMWRFV1KD007591',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T14:52:15.258Z'
        }
      },
      sort: [
        1591232673022
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU339570380242020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:58:52.799Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18491.00',
          mileage: '26730'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9489151,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU339570',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T15:20:16.185Z'
        }
      },
      sort: [
        1591232332799
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREVXHD122981480072020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:56:49.779Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20489.00',
          mileage: '55949'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9475765,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREVXHD122981',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T14:15:59.258Z'
        }
      },
      sort: [
        1591232209779
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F15KX863767450882020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:48:03.952Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '43930.00',
          mileage: '9021'
        },
        sellArea: '800',
        dealerCd: '45088',
        certificationNumber: 9487542,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F15KX863767',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 EDITION CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8376',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:25:23.540Z'
        }
      },
      sort: [
        1591231683952
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KC013806041872020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:47:42.332Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26988.00',
          mileage: '21628'
        },
        sellArea: '600',
        dealerCd: '04187',
        certificationNumber: 9453439,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KC013806',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T16:57:19.511Z'
        }
      },
      sort: [
        1591231662332
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN1GM021929450882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:46:32.452Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21030.00',
          mileage: '62500'
        },
        dealerCd: '45088',
        certificationNumber: 9367796,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN1GM021929',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T14:35:17.344Z'
        }
      },
      sort: [
        1591231592452
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1C11AK3LU877218270202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:27:13.735Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25013.00',
          mileage: '10138'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9487784,
        makeCode: 'TOYOTA',
        vin: '4T1C11AK3LU877218',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:56:02.406Z'
        }
      },
      sort: [
        1591230433735
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJJREV1HD084344270202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:26:28.394Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28462.50',
          mileage: '54768'
        },
        sellArea: '130',
        dealerCd: '27020',
        certificationNumber: 9449116,
        makeCode: 'TOYOTA',
        vin: 'JTMJJREV1HD084344',
        model: {
          modelDescription: 'RAV4 HYBRID SE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T22:19:42.779Z'
        }
      },
      sort: [
        1591230388394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU648103270202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:25:07.575Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13738.00',
          mileage: '51261'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9465124,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU648103',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T01:03:10.644Z'
        }
      },
      sort: [
        1591230307575
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM101107110352020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:21:33.750Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27845.00',
          mileage: '57431'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9492768,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM101107',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:35:40.592Z'
        }
      },
      sort: [
        1591230093750
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8HM070297360992020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:04:34.217Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '68594'
        },
        sellArea: '150',
        dealerCd: '36099',
        certificationNumber: 9496366,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8HM070297',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:56:42.018Z'
        }
      },
      sort: [
        1591229074217
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW655418480602020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-04T00:01:39.976Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19931.00',
          mileage: '32368'
        },
        sellArea: '210',
        dealerCd: '48060',
        certificationNumber: 9476135,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW655418',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T17:50:15.749Z'
        }
      },
      sort: [
        1591228899976
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC857383070092020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:52:09.129Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '14959.00',
          mileage: '17073'
        },
        sellArea: '160',
        dealerCd: '07009',
        certificationNumber: 9487137,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC857383',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:06:40.022Z'
        }
      },
      sort: [
        1591228329129
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJGRFH2HS029297070092020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:50:58.307Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '28994.13',
          mileage: '33852'
        },
        sellArea: '800',
        dealerCd: '07009',
        certificationNumber: 9487126,
        makeCode: 'TOYOTA',
        vin: '5TDJGRFH2HS029297',
        model: {
          modelDescription: 'HIGHLANDER HYBRD XLE-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6965',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:01:03.160Z'
        }
      },
      sort: [
        1591228258307
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB1HU260538070092020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:47:22.446Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21337.00',
          mileage: '43952'
        },
        sellArea: '800',
        dealerCd: '07009',
        certificationNumber: 9483671,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB1HU260538',
        model: {
          modelDescription: 'AVALON 4-DR XLE PREMIUM',
          modelSeries: 'AVALON',
          modelNumber: '3546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T16:59:30.845Z'
        }
      },
      sort: [
        1591228042446
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV3HW346002270212020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:43:00.363Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18581.00',
          mileage: '21738'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9432050,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV3HW346002',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T18:13:17.680Z'
        }
      },
      sort: [
        1591227780363
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1FB0KU032497270212020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:38:14.699Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '20425.00',
          mileage: '13708'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9476627,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1FB0KU032497',
        model: {
          modelDescription: 'AVALON 4-DR LIMITED',
          modelSeries: 'AVALON',
          modelNumber: '3554',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:12:04.543Z'
        }
      },
      sort: [
        1591227494699
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU702641270212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:34:40.816Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '14951.00',
          mileage: '43367'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9428635,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU702641',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T17:35:27.555Z'
        }
      },
      sort: [
        1591227280816
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21HK3KU012570121552020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:33:46.813Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24750.00',
          mileage: '22987'
        },
        sellArea: '210',
        dealerCd: '12155',
        certificationNumber: 9457080,
        makeCode: 'TOYOTA',
        vin: '4T1B21HK3KU012570',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T21:50:22.525Z'
        }
      },
      sort: [
        1591227226813
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW593687380242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:33:12.456Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24591.00',
          mileage: '37430'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9489089,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW593687',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T14:43:59.233Z'
        }
      },
      sort: [
        1591227192456
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXGJ087831480592020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:32:45.335Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17495.00',
          mileage: '50789'
        },
        sellArea: '210',
        dealerCd: '48059',
        certificationNumber: 9495664,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXGJ087831',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:04:09.210Z'
        }
      },
      sort: [
        1591227165335
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4G5360176380242020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:31:53.755Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '30991.00',
          mileage: '36322'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9491886,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4G5360176',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:34:06.023Z'
        }
      },
      sort: [
        1591227113755
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV4JW474869270212020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:31:31.253Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22588.00',
          mileage: '16138'
        },
        sellArea: '500',
        dealerCd: '27021',
        certificationNumber: 9484546,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV4JW474869',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T23:15:09.681Z'
        }
      },
      sort: [
        1591227091253
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC901444380242020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:30:01.933Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14873.65',
          mileage: '33596'
        },
        sellArea: '170',
        dealerCd: '38024',
        certificationNumber: 9489329,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC901444',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T16:42:34.804Z'
        }
      },
      sort: [
        1591227001933
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS432962480602020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:29:25.813Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29483.00',
          mileage: '32231'
        },
        sellArea: '220',
        dealerCd: '48060',
        certificationNumber: 9418258,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS432962',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T14:22:53.644Z'
        }
      },
      sort: [
        1591226965813
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK5GR562342270212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:28:10.831Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '13425.00',
          mileage: '53739'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9489721,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK5GR562342',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T19:38:53.971Z'
        }
      },
      sort: [
        1591226890831
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV7KW022830410572020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:23:32.848Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29934.00',
          mileage: '7573'
        },
        sellArea: '600',
        dealerCd: '41057',
        certificationNumber: 9465786,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV7KW022830',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:31:57.537Z'
        }
      },
      sort: [
        1591226612848
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBPRHE6FP236255270212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:23:19.027Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '11985.00',
          mileage: '47700'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9486093,
        makeCode: 'TOYOTA',
        vin: '5YFBPRHE6FP236255',
        model: {
          modelDescription: 'COROLLA LE ECO',
          modelSeries: 'COROLLA',
          modelNumber: '1872',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:52:29.279Z'
        }
      },
      sort: [
        1591226599027
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7JU088410270212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:21:15.318Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16998.00',
          mileage: '44459'
        },
        sellArea: '600',
        dealerCd: '27021',
        certificationNumber: 9486416,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7JU088410',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T23:51:26.860Z'
        }
      },
      sort: [
        1591226475318
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC2HS164145220462020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:20:35.842Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29985.00',
          mileage: '27991'
        },
        sellArea: '160',
        dealerCd: '22046',
        certificationNumber: 9425315,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC2HS164145',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T15:07:09.015Z'
        }
      },
      sort: [
        1591226435842
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC008861220462020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:19:43.664Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24735.00',
          mileage: '17826'
        },
        sellArea: '500',
        dealerCd: '22046',
        certificationNumber: 9400693,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC008861',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T15:24:45.358Z'
        }
      },
      sort: [
        1591226383664
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHJ711257220462020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:18:59.304Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18785.00',
          mileage: '16654'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9476578,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHJ711257',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:39:05.349Z'
        }
      },
      sort: [
        1591226339304
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV2JW477155270212020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:18:48.203Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18998.00',
          mileage: '45778'
        },
        sellArea: '500',
        dealerCd: '27021',
        certificationNumber: 9415970,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV2JW477155',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T21:39:10.278Z'
        }
      },
      sort: [
        1591226328203
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHW5F11HX615934220462020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:18:06.662Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '38985.00',
          mileage: '10392'
        },
        dealerCd: '22046',
        certificationNumber: 9352382,
        makeCode: 'TOYOTA',
        vin: '5TFHW5F11HX615934',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8374',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-24T16:55:17.592Z'
        }
      },
      sort: [
        1591226286662
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1EB2JU061725290712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:18:01.924Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '26734'
        },
        sellArea: '160',
        dealerCd: '29071',
        certificationNumber: 9496429,
        makeCode: 'TOYOTA',
        vin: '4T1BD1EB2JU061725',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:55:41.430Z'
        }
      },
      sort: [
        1591226281924
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW542680220462020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:17:23.461Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23500.00',
          mileage: '26271'
        },
        dealerCd: '22046',
        certificationNumber: 9368503,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW542680',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T21:13:38.386Z'
        }
      },
      sort: [
        1591226243461
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW620409220462020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:16:43.743Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18385.00',
          mileage: '43046'
        },
        sellArea: '210',
        dealerCd: '22046',
        certificationNumber: 9465492,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW620409',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T14:54:38.951Z'
        }
      },
      sort: [
        1591226203743
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRX5GN2KX159954270212020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:15:21.980Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22777.00',
          mileage: '3855'
        },
        sellArea: '150',
        dealerCd: '27021',
        certificationNumber: 9486110,
        makeCode: 'TOYOTA',
        vin: '5TFRX5GN2KX159954',
        model: {
          modelDescription: 'TACOMA SR 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7162',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:57:10.531Z'
        }
      },
      sort: [
        1591226121980
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDK3DC2FS118932410572020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:12:40.659Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21813.00',
          mileage: '55415'
        },
        sellArea: '150',
        dealerCd: '41057',
        certificationNumber: 9459126,
        makeCode: 'TOYOTA',
        vin: '5TDDK3DC2FS118932',
        model: {
          modelDescription: 'SIENNA XLE PREM AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5377',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T21:08:05.431Z'
        }
      },
      sort: [
        1591225960659
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2J5537055250562020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:04:29.291Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '36500.00',
          mileage: '32835'
        },
        sellArea: '130',
        dealerCd: '25056',
        certificationNumber: 9496424,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2J5537055',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:53:14.109Z'
        }
      },
      sort: [
        1591225469291
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC1LS028132270212020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:03:16.973Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29598.00',
          mileage: '23260'
        },
        sellArea: '500',
        dealerCd: '27021',
        certificationNumber: 9421734,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC1LS028132',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T21:41:18.034Z'
        }
      },
      sort: [
        1591225396973
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU687238290622020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:02:16.492Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15699.00',
          mileage: '25595'
        },
        sellArea: '160',
        dealerCd: '29062',
        certificationNumber: 9496434,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU687238',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T22:59:48.652Z'
        }
      },
      sort: [
        1591225336492
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F11HX632452190602020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T23:01:49.749Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '37998.00',
          mileage: '37089'
        },
        sellArea: '800',
        dealerCd: '19060',
        certificationNumber: 9421590,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F11HX632452',
        model: {
          modelDescription: 'TUNDRA TRD PRO DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8351',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-14T18:17:05.686Z'
        }
      },
      sort: [
        1591225309749
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3K1RFVXKC030930480322020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:58:47.247Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22687.00',
          mileage: '2316'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9456710,
        makeCode: 'TOYOTA',
        vin: '2T3K1RFVXKC030930',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T16:30:09.454Z'
        }
      },
      sort: [
        1591225127247
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU731375290622020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:57:35.507Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15997.00',
          mileage: '33987'
        },
        dealerCd: '29062',
        certificationNumber: 9369038,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU731375',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-05T12:40:06.204Z'
        }
      },
      sort: [
        1591225055507
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMGZ5AN0HM076600291132020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:57:07.007Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29540.00',
          mileage: '34290'
        },
        sellArea: '160',
        dealerCd: '29113',
        certificationNumber: 9495809,
        makeCode: 'TOYOTA',
        vin: '3TMGZ5AN0HM076600',
        model: {
          modelDescription: 'TACOMA LIMITED 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7582',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:47:08.692Z'
        }
      },
      sort: [
        1591225027007
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F19HX656259410662020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:54:15.405Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '42251'
        },
        sellArea: '600',
        dealerCd: '41066',
        certificationNumber: 9486287,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F19HX656259',
        model: {
          modelDescription: 'TUNDRA 4X4 SR DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8332',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:34:19.965Z'
        }
      },
      sort: [
        1591224855405
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8JJ720138361002020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:53:58.165Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21712.00',
          mileage: '39271'
        },
        sellArea: '130',
        dealerCd: '36100',
        certificationNumber: 9496097,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8JJ720138',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:34:54.626Z'
        }
      },
      sort: [
        1591224838165
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4J5491311410662020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:53:54.947Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '34956.00',
          mileage: '31506'
        },
        sellArea: '220',
        dealerCd: '41066',
        certificationNumber: 9490036,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4J5491311',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T22:37:31.506Z'
        }
      },
      sort: [
        1591224834947
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4J5571529360472020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:52:48.084Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '37997.00',
          mileage: '12386'
        },
        sellArea: '130',
        dealerCd: '36047',
        certificationNumber: 9478158,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4J5571529',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T20:43:21.343Z'
        }
      },
      sort: [
        1591224768084
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS393868360472020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:52:08.704Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '30998.00',
          mileage: '29052'
        },
        sellArea: '130',
        dealerCd: '36047',
        certificationNumber: 9490600,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS393868',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T18:59:01.998Z'
        }
      },
      sort: [
        1591224728704
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F12GX512289480602020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:49:23.122Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26022.61',
          mileage: '72774'
        },
        sellArea: '210',
        dealerCd: '48060',
        certificationNumber: 9475999,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F12GX512289',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:48:08.264Z'
        }
      },
      sort: [
        1591224563122
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ706062211182020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:48:58.102Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18299.00',
          mileage: '37248'
        },
        sellArea: '220',
        dealerCd: '21118',
        certificationNumber: 9410268,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ706062',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T20:10:48.170Z'
        }
      },
      sort: [
        1591224538102
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1HK5JU007137311052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:46:21.761Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '9171'
        },
        sellArea: '160',
        dealerCd: '31105',
        certificationNumber: 9399209,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1HK5JU007137',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T18:46:26.103Z'
        }
      },
      sort: [
        1591224381761
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU4H3033565311052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:45:03.999Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '43758'
        },
        sellArea: '160',
        dealerCd: '31105',
        certificationNumber: 9421925,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU4H3033565',
        model: {
          modelDescription: 'PRIUS FOUR TOURING',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1228',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-15T15:42:11.583Z'
        }
      },
      sort: [
        1591224303999
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE0LJ001094045542020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:43:07.872Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19974.00',
          mileage: '24919'
        },
        sellArea: '110',
        dealerCd: '04554',
        certificationNumber: 9405664,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE0LJ001094',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T21:39:53.206Z'
        }
      },
      sort: [
        1591224187872
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC4HS844071140442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:42:30.617Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23471.00',
          mileage: '44462'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9446132,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC4HS844071',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-20T16:26:20.507Z'
        }
      },
      sort: [
        1591224150617
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU784275480452020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:42:16.155Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14400.00',
          mileage: '37893'
        },
        sellArea: '210',
        dealerCd: '48045',
        certificationNumber: 9474277,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU784275',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:57:31.299Z'
        }
      },
      sort: [
        1591224136155
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV8KC011942360972020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:34:30.611Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23587.00',
          mileage: '20323'
        },
        sellArea: '130',
        dealerCd: '36097',
        certificationNumber: 9475916,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV8KC011942',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:01:44.370Z'
        }
      },
      sort: [
        1591223670611
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU756773270242020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:34:04.228Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24425.00',
          mileage: '29169'
        },
        sellArea: '500',
        dealerCd: '27024',
        certificationNumber: 9475243,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU756773',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T23:12:48.311Z'
        }
      },
      sort: [
        1591223644228
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC2HS164732360972020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:32:32.548Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '22901.00',
          mileage: '58260'
        },
        sellArea: '500',
        dealerCd: '36097',
        certificationNumber: 9476336,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC2HS164732',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:11:54.982Z'
        }
      },
      sort: [
        1591223552548
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV2HW587303480602020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:32:13.605Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24300.00',
          mileage: '15235'
        },
        sellArea: '800',
        dealerCd: '48060',
        certificationNumber: 9476188,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV2HW587303',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:22:45.901Z'
        }
      },
      sort: [
        1591223533605
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK1LU912923046762020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:32:11.448Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23183.00',
          mileage: '5365'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9477482,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK1LU912923',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:49:40.366Z'
        }
      },
      sort: [
        1591223531448
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5LM289209046762020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:31:50.805Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '40198.00',
          mileage: '8200'
        },
        sellArea: '120',
        dealerCd: '04676',
        certificationNumber: 9488567,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5LM289209',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T00:14:30.050Z'
        }
      },
      sort: [
        1591223510805
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU418364360972020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:31:41.707Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '12889.00',
          mileage: '76709'
        },
        sellArea: '500',
        dealerCd: '36097',
        certificationNumber: 9476352,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU418364',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:16:17.222Z'
        }
      },
      sort: [
        1591223501707
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE8LP066452046762020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:31:14.247Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18625.00',
          mileage: '6756'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9477516,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE8LP066452',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T16:00:14.210Z'
        }
      },
      sort: [
        1591223474247
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK5LU914478046762020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:30:48.167Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23860.00',
          mileage: '5208'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9477486,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK5LU914478',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:50:27.766Z'
        }
      },
      sort: [
        1591223448167
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV3LC044728046762020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:30:31.867Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22909.82',
          mileage: '5668'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9482562,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV3LC044728',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T22:41:10.115Z'
        }
      },
      sort: [
        1591223431867
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK3LU306295046762020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:30:07.945Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24260.50',
          mileage: '5557'
        },
        sellArea: '110',
        dealerCd: '04676',
        certificationNumber: 9477462,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK3LU306295',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:44:33.604Z'
        }
      },
      sort: [
        1591223407945
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HJ145802280122020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:27:58.102Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '49283'
        },
        sellArea: '170',
        dealerCd: '28012',
        certificationNumber: 9434033,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HJ145802',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T17:32:22.870Z'
        }
      },
      sort: [
        1591223278102
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS420059150502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:25:55.820Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29557.00',
          mileage: '29072'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9479235,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS420059',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T17:46:38.367Z'
        }
      },
      sort: [
        1591223155820
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDEZ3DCXLS231085270132020-04-08V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:15:23.428Z',
          retailDate: '2020-04-08',
          rdrStatus: 'V',
          soldAmount: '36000.00',
          mileage: '3252'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9396181,
        makeCode: 'TOYOTA',
        vin: '5TDEZ3DCXLS231085',
        model: {
          modelDescription: 'SIENNA SE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5352',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T22:28:59.018Z'
        }
      },
      sort: [
        1591222523428
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DCXLS035124120152020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:11:45.450Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33296.00',
          mileage: '19531'
        },
        dealerCd: '12015',
        certificationNumber: 9367270,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DCXLS035124',
        model: {
          modelDescription: 'SIENNA SE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5343',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-03T22:18:03.145Z'
        }
      },
      sort: [
        1591222305450
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK0JU011573047262020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:09:09.507Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '28434.23',
          mileage: '28433'
        },
        sellArea: '110',
        dealerCd: '04726',
        certificationNumber: 9444087,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK0JU011573',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T21:37:24.599Z'
        }
      },
      sort: [
        1591222149507
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6GM020996070082020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:08:16.906Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28878.00',
          mileage: '35154'
        },
        sellArea: '800',
        dealerCd: '07008',
        certificationNumber: 9494059,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6GM020996',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:48:36.473Z'
        }
      },
      sort: [
        1591222096906
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0JW709176120152020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:07:18.325Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26811.00',
          mileage: '9999'
        },
        sellArea: '800',
        dealerCd: '12015',
        certificationNumber: 9444361,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0JW709176',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T18:14:32.458Z'
        }
      },
      sort: [
        1591222038325
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ116878070082020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:05:13.482Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20250.00',
          mileage: '34723'
        },
        sellArea: '160',
        dealerCd: '07008',
        certificationNumber: 9483271,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ116878',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:41:36.655Z'
        }
      },
      sort: [
        1591221913482
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV6FW369572370652020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:00:21.660Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17558.82',
          mileage: '53628'
        },
        dealerCd: '37065',
        certificationNumber: 9357602,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV6FW369572',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-28T18:39:03.907Z'
        }
      },
      sort: [
        1591221621660
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW669230070082020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T22:00:05.410Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20789.00',
          mileage: '24148'
        },
        sellArea: '800',
        dealerCd: '07008',
        certificationNumber: 9494044,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW669230',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:41:37.652Z'
        }
      },
      sort: [
        1591221605410
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK1JU016135140412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:58:44.543Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '33376'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9485747,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK1JU016135',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:09:19.992Z'
        }
      },
      sort: [
        1591221524543
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV3HW348142041362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:58:21.823Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18987.0000',
          mileage: '64365'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9411863,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV3HW348142',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T21:52:45.901Z'
        }
      },
      sort: [
        1591221501823
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8FP195359041362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:58:19.363Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '12088.0000',
          mileage: '74617'
        },
        sellArea: '600',
        dealerCd: '04136',
        certificationNumber: 9465098,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8FP195359',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T00:17:01.613Z'
        }
      },
      sort: [
        1591221499363
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFT4RCE6LP035875041362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:58:04.164Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24688.00',
          mileage: '1795'
        },
        sellArea: '110',
        dealerCd: '04136',
        certificationNumber: 9494508,
        makeCode: 'TOYOTA',
        vin: '5YFT4RCE6LP035875',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:35:36.075Z'
        }
      },
      sort: [
        1591221484164
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU775894140412020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:56:31.641Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '16796.00',
          mileage: '21985'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9472746,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU775894',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T16:31:13.193Z'
        }
      },
      sort: [
        1591221391641
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4JU531306070082020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:56:23.723Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18558.00',
          mileage: '34541'
        },
        sellArea: '800',
        dealerCd: '07008',
        certificationNumber: 9484309,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4JU531306',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:20:16.763Z'
        }
      },
      sort: [
        1591221383723
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKK3DCXFS540521140412020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:54:19.262Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '51273'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9451074,
        makeCode: 'TOYOTA',
        vin: '5TDKK3DCXFS540521',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T15:09:37.129Z'
        }
      },
      sort: [
        1591221259262
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3H5412155340932020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:53:27.741Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30900.00',
          mileage: '30189'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9479134,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3H5412155',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T16:54:35.754Z'
        }
      },
      sort: [
        1591221207741
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU688088370942020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:53:11.221Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16270.00',
          mileage: '41444'
        },
        sellArea: '800',
        dealerCd: '37094',
        certificationNumber: 9465991,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU688088',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T19:40:05.166Z'
        }
      },
      sort: [
        1591221191221
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV1HJ128429140412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:52:14.841Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '35561'
        },
        sellArea: '210',
        dealerCd: '14041',
        certificationNumber: 9431786,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV1HJ128429',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T15:48:18.522Z'
        }
      },
      sort: [
        1591221134841
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU675720140412020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:50:20.518Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '13600.00',
          mileage: '60909'
        },
        sellArea: '600',
        dealerCd: '14041',
        certificationNumber: 9437434,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU675720',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T16:56:48.093Z'
        }
      },
      sort: [
        1591221020518
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC951440042212020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:49:27.101Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '13877.00',
          mileage: '25137'
        },
        sellArea: '120',
        dealerCd: '04221',
        certificationNumber: 9496293,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC951440',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:09:35.711Z'
        }
      },
      sort: [
        1591220967101
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV0HY155932020102020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:47:55.298Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '11798.00',
          mileage: '23689'
        },
        sellArea: '150',
        dealerCd: '02010',
        certificationNumber: 9494239,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV0HY155932',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:50:58.779Z'
        }
      },
      sort: [
        1591220875298
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS418259120772020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:47:33.759Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26200.00',
          mileage: '29170'
        },
        sellArea: '210',
        dealerCd: '12077',
        certificationNumber: 9481876,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS418259',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T17:58:18.778Z'
        }
      },
      sort: [
        1591220853759
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1JU641858140412020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:47:30.440Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '16650.00',
          mileage: '66141'
        },
        sellArea: '600',
        dealerCd: '14041',
        certificationNumber: 9451478,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1JU641858',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T18:52:31.624Z'
        }
      },
      sort: [
        1591220850440
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP6H3065556041042020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:47:14.018Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21794.00',
          mileage: '10209'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9420498,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP6H3065556',
        model: {
          modelDescription: 'PRIUS PRIME PLUS',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1235',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T20:06:35.114Z'
        }
      },
      sort: [
        1591220834018
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6HW632668470322020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:45:34.997Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22491.00',
          mileage: '27899'
        },
        sellArea: '800',
        dealerCd: '47032',
        certificationNumber: 9485484,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6HW632668',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:03:39.990Z'
        }
      },
      sort: [
        1591220734997
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7HD062206140412020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:44:41.598Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '61819'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9469073,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7HD062206',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T20:48:41.020Z'
        }
      },
      sort: [
        1591220681598
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DWRFV8KW016548041042020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:43:07.376Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '34894.00',
          mileage: '14060'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9417147,
        makeCode: 'TOYOTA',
        vin: '2T3DWRFV8KW016548',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T18:20:26.019Z'
        }
      },
      sort: [
        1591220587376
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV2JJ194430140412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:42:17.256Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '13931'
        },
        sellArea: '500',
        dealerCd: '14041',
        certificationNumber: 9458917,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV2JJ194430',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T19:04:56.078Z'
        }
      },
      sort: [
        1591220537256
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW607220140412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:39:45.656Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18228.00',
          mileage: '28726'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9484289,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW607220',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:05:29.761Z'
        }
      },
      sort: [
        1591220385656
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV4KW009257041042020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:39:05.096Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29794.00',
          mileage: '20426'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9406045,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV4KW009257',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T23:07:40.793Z'
        }
      },
      sort: [
        1591220345096
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC1HS858824290752020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:38:58.660Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26300.00',
          mileage: '38526'
        },
        sellArea: '160',
        dealerCd: '29075',
        certificationNumber: 9439047,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC1HS858824',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T13:20:48.378Z'
        }
      },
      sort: [
        1591220338660
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE4LJ014885190712020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:38:06.540Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '16799.00',
          mileage: '13795'
        },
        sellArea: '800',
        dealerCd: '19071',
        certificationNumber: 9496326,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE4LJ014885',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T21:32:12.623Z'
        }
      },
      sort: [
        1591220286540
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM066169140412020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:37:24.680Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '30250.00',
          mileage: '22294'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9472971,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM066169',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T18:00:28.044Z'
        }
      },
      sort: [
        1591220244680
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU377033240672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:37:18.661Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17975.00',
          mileage: '35478'
        },
        dealerCd: '24067',
        certificationNumber: 9352384,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU377033',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-24T16:56:05.730Z'
        }
      },
      sort: [
        1591220238661
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7GJ097023070092020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:35:38.254Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17967.00',
          mileage: '46300'
        },
        sellArea: '160',
        dealerCd: '07009',
        certificationNumber: 9492347,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7GJ097023',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:36:38.088Z'
        }
      },
      sort: [
        1591220138254
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1GJ078838291062020-01-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:35:16.607Z',
          retailDate: '2020-01-28',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '32529'
        },
        sellArea: '160',
        dealerCd: '29106',
        certificationNumber: 9433000,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1GJ078838',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T17:11:21.231Z'
        }
      },
      sort: [
        1591220116607
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3ED084054140412020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:35:12.532Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '58985'
        },
        sellArea: '210',
        dealerCd: '14041',
        certificationNumber: 9447573,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3ED084054',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T21:03:16.816Z'
        }
      },
      sort: [
        1591220112532
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN8GX008293370832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:34:15.273Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28492.00',
          mileage: '20357'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9467030,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN8GX008293',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T16:35:44.274Z'
        }
      },
      sort: [
        1591220055273
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFP4RCE8LP019775120152020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:33:58.272Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18499.00',
          mileage: '5269'
        },
        sellArea: '210',
        dealerCd: '12015',
        certificationNumber: 9444185,
        makeCode: 'TOYOTA',
        vin: '5YFP4RCE8LP019775',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T13:12:45.770Z'
        }
      },
      sort: [
        1591220038272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE8LJ036369290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:32:57.173Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14953.00',
          mileage: '13823'
        },
        sellArea: '500',
        dealerCd: '29025',
        certificationNumber: 9462097,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE8LJ036369',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T18:58:59.317Z'
        }
      },
      sort: [
        1591219977173
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU718489140412020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:32:53.353Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '16250.00',
          mileage: '56008'
        },
        sellArea: '600',
        dealerCd: '14041',
        certificationNumber: 9464194,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU718489',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T14:54:18.829Z'
        }
      },
      sort: [
        1591219973353
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV1HJ708410290252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:31:31.373Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '36156'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9482289,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV1HJ708410',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:08:44.801Z'
        }
      },
      sort: [
        1591219891373
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH2HS443283140412020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:30:58.111Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '28900.00',
          mileage: '35260'
        },
        sellArea: '210',
        dealerCd: '14041',
        certificationNumber: 9473166,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH2HS443283',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T19:31:11.182Z'
        }
      },
      sort: [
        1591219858111
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC787076371742020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:30:48.351Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '17800'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9477789,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC787076',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T18:07:50.985Z'
        }
      },
      sort: [
        1591219848351
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH5GS341026220342020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:29:24.010Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27690.00',
          mileage: '66398'
        },
        dealerCd: '22034',
        certificationNumber: 9383614,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH5GS341026',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T15:37:33.458Z'
        }
      },
      sort: [
        1591219764010
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV8JD243753140412020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:29:12.811Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '26000.00',
          mileage: '21101'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9472541,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV8JD243753',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T15:03:01.295Z'
        }
      },
      sort: [
        1591219752811
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMN1RFV7KD519469290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:28:45.730Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31500.00',
          mileage: '4861'
        },
        sellArea: '500',
        dealerCd: '29025',
        certificationNumber: 9462082,
        makeCode: 'TOYOTA',
        vin: 'JTMN1RFV7KD519469',
        model: {
          modelDescription: 'RAV4 LIMITED AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T18:46:57.936Z'
        }
      },
      sort: [
        1591219725730
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE5LJ024107290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:27:30.031Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '15139'
        },
        sellArea: '500',
        dealerCd: '29025',
        certificationNumber: 9470502,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE5LJ024107',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T14:55:05.313Z'
        }
      },
      sort: [
        1591219650031
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4JS484684140412020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:26:46.969Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '28556.00',
          mileage: '40108'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9446675,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4JS484684',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T18:15:57.604Z'
        }
      },
      sort: [
        1591219606969
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2EW185637290252020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:26:21.249Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14288.78',
          mileage: '79989'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9469800,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2EW185637',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T16:12:42.250Z'
        }
      },
      sort: [
        1591219581249
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDW5G14HS149208140412020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:24:04.589Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '41998.00',
          mileage: '27858'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9492160,
        makeCode: 'TOYOTA',
        vin: '5TDDW5G14HS149208',
        model: {
          modelDescription: 'SEQUOIA 4X4 PLAT 7-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7944',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:51:22.858Z'
        }
      },
      sort: [
        1591219444589
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK3LU870447042822020-05-17V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:22:06.277Z',
          retailDate: '2020-05-17',
          rdrStatus: 'V',
          soldAmount: '22998.00',
          mileage: '7526'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9467600,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK3LU870447',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T21:52:47.713Z'
        }
      },
      sort: [
        1591219326277
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHW5F15JX739887140412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:21:19.546Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '39100.00',
          mileage: '24192'
        },
        sellArea: '230',
        dealerCd: '14041',
        certificationNumber: 9472466,
        makeCode: 'TOYOTA',
        vin: '5TFHW5F15JX739887',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8374',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T14:25:36.336Z'
        }
      },
      sort: [
        1591219279546
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU390999047392020-03-10V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:20:10.337Z',
          retailDate: '2020-03-10',
          rdrStatus: 'V',
          soldAmount: '17998.00',
          mileage: '36710'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9413057,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU390999',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-08T17:34:22.211Z'
        }
      },
      sort: [
        1591219210337
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS390449140412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:18:54.866Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '25233.00',
          mileage: '52154'
        },
        sellArea: '160',
        dealerCd: '14041',
        certificationNumber: 9432258,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS390449',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T19:56:28.876Z'
        }
      },
      sort: [
        1591219134866
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2GC719929040562020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:16:58.706Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '25354'
        },
        sellArea: '120',
        dealerCd: '04056',
        certificationNumber: 9489630,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2GC719929',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:26:18.409Z'
        }
      },
      sort: [
        1591219018706
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC2HS162282140412020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:16:16.966Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '25989.00',
          mileage: '33336'
        },
        sellArea: '800',
        dealerCd: '14041',
        certificationNumber: 9472607,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC2HS162282',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T15:40:28.870Z'
        }
      },
      sort: [
        1591218976966
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW611404291042020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:11:23.304Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20186.00',
          mileage: '37944'
        },
        dealerCd: '29104',
        certificationNumber: 9373527,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW611404',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-08T13:26:16.788Z'
        }
      },
      sort: [
        1591218683304
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFH1HS027528043092020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:07:37.782Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23778.54',
          mileage: '34326'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9438770,
        makeCode: 'TOYOTA',
        vin: '5TDZARFH1HS027528',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T18:18:31.784Z'
        }
      },
      sort: [
        1591218457782
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFHXJS049848210672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:05:24.882Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35490.00',
          mileage: '18645'
        },
        sellArea: '220',
        dealerCd: '21067',
        certificationNumber: 9468161,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFHXJS049848',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T13:56:59.553Z'
        }
      },
      sort: [
        1591218324882
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ146152310112020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T21:00:43.707Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21487.00',
          mileage: '21655'
        },
        sellArea: '160',
        dealerCd: '31011',
        certificationNumber: 9458870,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ146152',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T18:41:56.037Z'
        }
      },
      sort: [
        1591218043707
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE8LJ021573310112020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:59:29.820Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16990.00',
          mileage: '13418'
        },
        sellArea: '500',
        dealerCd: '31011',
        certificationNumber: 9460599,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE8LJ021573',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T12:32:25.069Z'
        }
      },
      sort: [
        1591217969820
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMY1RFV3KD514234291022020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:59:09.357Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '27776.00',
          mileage: '5597'
        },
        dealerCd: '29102',
        certificationNumber: 9355124,
        makeCode: 'TOYOTA',
        vin: 'JTMY1RFV3KD514234',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-27T17:05:33.465Z'
        }
      },
      sort: [
        1591217949357
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBEXK3019212310112020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:58:01.458Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17376.24',
          mileage: '12305'
        },
        dealerCd: '31011',
        certificationNumber: 9146073,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBEXK3019212',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-09-11T15:14:41.872Z'
        }
      },
      sort: [
        1591217881458
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMY1RFVXKD515428291022020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:57:26.158Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28749.81',
          mileage: '4979'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9407129,
        makeCode: 'TOYOTA',
        vin: 'JTMY1RFVXKD515428',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T17:54:09.868Z'
        }
      },
      sort: [
        1591217846158
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC4HS849423043092020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:56:27.177Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '25988.00',
          mileage: '31847'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9476167,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC4HS849423',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:12:14.449Z'
        }
      },
      sort: [
        1591217787177
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV1JD225630341202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:56:23.756Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '26789'
        },
        sellArea: '220',
        dealerCd: '34120',
        certificationNumber: 9496257,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV1JD225630',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:53:17.527Z'
        }
      },
      sort: [
        1591217783756
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFHPRAE5LP005170480072020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:55:25.616Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17287.00',
          mileage: '6921'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9438469,
        makeCode: 'TOYOTA',
        vin: '5YFHPRAE5LP005170',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T13:15:37.489Z'
        }
      },
      sort: [
        1591217725616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU217183360992020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:52:30.554Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '28312'
        },
        sellArea: '500',
        dealerCd: '36099',
        certificationNumber: 9482666,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU217183',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T00:32:03.716Z'
        }
      },
      sort: [
        1591217550554
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK9J3007591340932020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:52:25.994Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17750.00',
          mileage: '44110'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9447886,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK9J3007591',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T14:53:15.763Z'
        }
      },
      sort: [
        1591217545994
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB3HU244700340932020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:51:59.036Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22956.04',
          mileage: '40659'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9493675,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB3HU244700',
        model: {
          modelDescription: 'AVALON 4-DR LIMITED',
          modelSeries: 'AVALON',
          modelNumber: '3554',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:11:29.198Z'
        }
      },
      sort: [
        1591217519036
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0HM069225371062020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:51:27.396Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '31800.00',
          mileage: '4492'
        },
        sellArea: '800',
        dealerCd: '37106',
        certificationNumber: 9496196,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0HM069225',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:21:53.727Z'
        }
      },
      sort: [
        1591217487396
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU707304480072020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:48:17.593Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15989.00',
          mileage: '23552'
        },
        sellArea: '210',
        dealerCd: '48007',
        certificationNumber: 9439731,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU707304',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-03T15:32:21.292Z'
        }
      },
      sort: [
        1591217297593
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV5HJ125493220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:46:43.532Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23775.00',
          mileage: '22724'
        },
        sellArea: '160',
        dealerCd: '22030',
        certificationNumber: 9415101,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV5HJ125493',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T15:01:53.728Z'
        }
      },
      sort: [
        1591217203532
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RWRFV9KW032358290582020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:46:12.973Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '28289.00',
          mileage: '5368'
        },
        sellArea: '160',
        dealerCd: '29058',
        certificationNumber: 9394493,
        makeCode: 'TOYOTA',
        vin: '2T3RWRFV9KW032358',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T23:23:09.743Z'
        }
      },
      sort: [
        1591217172973
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK1FR490084110012020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:45:48.112Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13999.00',
          mileage: '49986'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9492201,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK1FR490084',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:03:20.712Z'
        }
      },
      sort: [
        1591217148112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV2GD050084371062020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:44:08.993Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21800.00',
          mileage: '37567'
        },
        sellArea: '800',
        dealerCd: '37106',
        certificationNumber: 9467373,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV2GD050084',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T19:33:04.400Z'
        }
      },
      sort: [
        1591217048993
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4JJ727569110012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:42:30.090Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24580.00',
          mileage: '18839'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9468468,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4JJ727569',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T16:19:10.088Z'
        }
      },
      sort: [
        1591216950090
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP7H3004832371062020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:41:45.412Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22200.00',
          mileage: '29050'
        },
        dealerCd: '37106',
        certificationNumber: 9355211,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP7H3004832',
        model: {
          modelDescription: 'PRIUS PRIME ADVANCED',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1239',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-27T17:31:35.019Z'
        }
      },
      sort: [
        1591216905412
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU665696371062020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:39:17.650Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '37384'
        },
        dealerCd: '37106',
        certificationNumber: 9172213,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU665696',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-09-27T15:11:32.246Z'
        }
      },
      sort: [
        1591216757650
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F14KX838830140522020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:39:03.051Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '37935.00',
          mileage: '33853'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9414047,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F14KX838830',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 EDITION CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8376',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T19:55:51.836Z'
        }
      },
      sort: [
        1591216743051
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F19HX640251110012020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:38:01.009Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '33999.00',
          mileage: '7404'
        },
        sellArea: '120',
        dealerCd: '11001',
        certificationNumber: 9485331,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F19HX640251',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:50:56.749Z'
        }
      },
      sort: [
        1591216681009
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7HM105363140522020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:36:24.029Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '28254.00',
          mileage: '8785'
        },
        dealerCd: '14052',
        certificationNumber: 9329101,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7HM105363',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-09T19:27:18.780Z'
        }
      },
      sort: [
        1591216584029
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU415500041222020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:36:11.589Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16386.00',
          mileage: '32104'
        },
        sellArea: '110',
        dealerCd: '04122',
        certificationNumber: 9496220,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU415500',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:33:25.152Z'
        }
      },
      sort: [
        1591216571589
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREVXHW678455311832020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:31:56.546Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18291.0000',
          mileage: '29224'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9427259,
        makeCode: 'TOYOTA',
        vin: '2T3BFREVXHW678455',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T18:03:59.237Z'
        }
      },
      sort: [
        1591216316546
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMF1RFV0KD501125311832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:31:53.927Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '21991.0000',
          mileage: '34985'
        },
        sellArea: '500',
        dealerCd: '31183',
        certificationNumber: 9443769,
        makeCode: 'TOYOTA',
        vin: 'JTMF1RFV0KD501125',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T14:16:09.201Z'
        }
      },
      sort: [
        1591216313927
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC4HS180219311832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:31:51.086Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24200.00',
          mileage: '27856'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9466202,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC4HS180219',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T21:06:49.313Z'
        }
      },
      sort: [
        1591216311086
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F13HX640620180372020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:30:30.326Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '32888.00',
          mileage: '19232'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9496210,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F13HX640620',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T20:26:53.187Z'
        }
      },
      sort: [
        1591216230326
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3EW132908140522020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:30:28.764Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '15991.00',
          mileage: '75459'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9488248,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3EW132908',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:25:17.626Z'
        }
      },
      sort: [
        1591216228764
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN0KM076721043092020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:29:54.104Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '35988.00',
          mileage: '13810'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9495951,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN0KM076721',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7568',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:37:19.967Z'
        }
      },
      sort: [
        1591216194104
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV9GW299167291042020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:29:15.847Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16987.00',
          mileage: '22566'
        },
        sellArea: '160',
        dealerCd: '29104',
        certificationNumber: 9478892,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV9GW299167',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T12:17:31.675Z'
        }
      },
      sort: [
        1591216155847
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU803198210672020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:28:20.944Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '17990.00',
          mileage: '25796'
        },
        sellArea: '220',
        dealerCd: '21067',
        certificationNumber: 9496127,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU803198',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:48:26.765Z'
        }
      },
      sort: [
        1591216100944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HD200851290582020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:20:47.034Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '20587.00',
          mileage: '36282'
        },
        dealerCd: '29058',
        certificationNumber: 9385771,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HD200851',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T16:42:19.405Z'
        }
      },
      sort: [
        1591215647034
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC8LS024245220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:20:08.613Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24955.00',
          mileage: '20756'
        },
        sellArea: '600',
        dealerCd: '22030',
        certificationNumber: 9398579,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC8LS024245',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T14:35:45.768Z'
        }
      },
      sort: [
        1591215608613
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU719612140032020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:18:19.893Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '62611'
        },
        sellArea: '230',
        dealerCd: '14003',
        certificationNumber: 9429481,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU719612',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T13:28:16.286Z'
        }
      },
      sort: [
        1591215499893
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH4GS265578341222020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:18:05.312Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '24763.00',
          mileage: '19803'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9495212,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH4GS265578',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:50:57.238Z'
        }
      },
      sort: [
        1591215485312
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH7HS026568140032020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:17:03.390Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '34990.00',
          mileage: '36813'
        },
        sellArea: '230',
        dealerCd: '14003',
        certificationNumber: 9447605,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH7HS026568',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T21:34:28.410Z'
        }
      },
      sort: [
        1591215423390
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZ3DC9HS794962210432020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:16:39.628Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23200.00',
          mileage: '18371'
        },
        dealerCd: '21043',
        certificationNumber: 9366875,
        makeCode: 'TOYOTA',
        vin: '5TDZZ3DC9HS794962',
        model: {
          modelDescription: 'SIENNA L 3.5L FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5328',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-03T19:15:05.335Z'
        }
      },
      sort: [
        1591215399628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK3JU090107341222020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:16:12.247Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23990.00',
          mileage: '39229'
        },
        sellArea: '800',
        dealerCd: '34122',
        certificationNumber: 9489667,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK3JU090107',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:50:12.898Z'
        }
      },
      sort: [
        1591215372247
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8K5645214140032020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:15:44.807Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '31780.00',
          mileage: '26687'
        },
        sellArea: '500',
        dealerCd: '14003',
        certificationNumber: 9408264,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8K5645214',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T14:38:07.352Z'
        }
      },
      sort: [
        1591215344807
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH6GS322200140032020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:14:16.248Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28990.00',
          mileage: '38054'
        },
        sellArea: '230',
        dealerCd: '14003',
        certificationNumber: 9417158,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH6GS322200',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T18:27:56.947Z'
        }
      },
      sort: [
        1591215256248
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV6HD111542140032020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:12:59.129Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22668.00',
          mileage: '41746'
        },
        sellArea: '230',
        dealerCd: '14003',
        certificationNumber: 9463170,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV6HD111542',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T18:28:09.922Z'
        }
      },
      sort: [
        1591215179129
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS384162310692020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:11:45.928Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28978.00',
          mileage: '22364'
        },
        sellArea: '160',
        dealerCd: '31069',
        certificationNumber: 9462740,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS384162',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:03:50.125Z'
        }
      },
      sort: [
        1591215105928
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7HW594544140522020-05-11H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:11:21.328Z',
          retailDate: '2020-05-11',
          rdrStatus: 'H',
          soldAmount: '18450.00',
          mileage: '36232'
        },
        sellArea: '210',
        dealerCd: '14052',
        certificationNumber: 9415653,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7HW594544',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T19:37:20.856Z'
        }
      },
      sort: [
        1591215081328
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DU3F0423382110012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:11:20.242Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16100.00',
          mileage: '44210'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9455289,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DU3F0423382',
        model: {
          modelDescription: 'PRIUS Persona Series',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1228',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T15:46:51.489Z'
        }
      },
      sort: [
        1591215080242
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HD219167140522020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:09:32.071Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '18524.22',
          mileage: '37914'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9466116,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HD219167',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T20:30:55.098Z'
        }
      },
      sort: [
        1591214972071
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN6GX014699110012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:08:27.301Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28499.00',
          mileage: '42310'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9475077,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN6GX014699',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7562',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T21:16:31.836Z'
        }
      },
      sort: [
        1591214907301
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREVXJW722021140522020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:08:16.158Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '25991.00',
          mileage: '22782'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9480182,
        makeCode: 'TOYOTA',
        vin: '2T3JFREVXJW722021',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T14:19:24.011Z'
        }
      },
      sort: [
        1591214896158
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP6K3111913140522020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:07:11.536Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '28080.00',
          mileage: '11974'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9414096,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP6K3111913',
        model: {
          modelDescription: 'PRIUS PRIME ADVANCED',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1239',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T20:17:00.187Z'
        }
      },
      sort: [
        1591214831536
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV9HY150065311562020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:05:57.316Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '12982.00',
          mileage: '18058'
        },
        sellArea: '160',
        dealerCd: '31156',
        certificationNumber: 9423854,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV9HY150065',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T16:47:13.630Z'
        }
      },
      sort: [
        1591214757316
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3A1RFV7KC035224380252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:05:10.076Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28950.00',
          mileage: '6965'
        },
        sellArea: '170',
        dealerCd: '38025',
        certificationNumber: 9493612,
        makeCode: 'TOYOTA',
        vin: '2T3A1RFV7KC035224',
        model: {
          modelDescription: 'RAV4 XLE PREM AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4478',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T13:16:39.039Z'
        }
      },
      sort: [
        1591214710076
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV1KC014976311922020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:04:09.873Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '19728'
        },
        sellArea: '600',
        dealerCd: '31192',
        certificationNumber: 9427773,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV1KC014976',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T22:24:35.200Z'
        }
      },
      sort: [
        1591214649873
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH6JS490418311562020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:03:59.234Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '31276.49',
          mileage: '18049'
        },
        sellArea: '160',
        dealerCd: '31156',
        certificationNumber: 9395554,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH6JS490418',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T18:14:47.421Z'
        }
      },
      sort: [
        1591214639234
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH5GS289808180372020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:02:43.632Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '22700.00',
          mileage: '23368'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9496151,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH5GS289808',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:59:00.945Z'
        }
      },
      sort: [
        1591214563632
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU729866121592020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:02:41.709Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19355.00',
          mileage: '38560'
        },
        sellArea: '600',
        dealerCd: '12159',
        certificationNumber: 9496096,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU729866',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:34:47.547Z'
        }
      },
      sort: [
        1591214561709
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DUXF1931973140522020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:02:06.849Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '12992.00',
          mileage: '73549'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9465525,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DUXF1931973',
        model: {
          modelDescription: 'PRIUS Two Model',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:11:58.098Z'
        }
      },
      sort: [
        1591214526849
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFLU4EN3FX118783450702020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:01:23.565Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '50337'
        },
        sellArea: '800',
        dealerCd: '45070',
        certificationNumber: 9491145,
        makeCode: 'TOYOTA',
        vin: '5TFLU4EN3FX118783',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7593',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T13:50:36.252Z'
        }
      },
      sort: [
        1591214483565
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH5FS183222140522020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:00:09.849Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '22512.00',
          mileage: '83546'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9468867,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH5FS183222',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:12:18.203Z'
        }
      },
      sort: [
        1591214409849
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK3EU116610220302020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T20:00:03.808Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '12500.00',
          mileage: '66719'
        },
        sellArea: '230',
        dealerCd: '22030',
        certificationNumber: 9470332,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK3EU116610',
        model: {
          modelDescription: 'CAMRY 2014.5 Hybrid LE',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T12:50:50.279Z'
        }
      },
      sort: [
        1591214403808
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KW030234121592020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:59:51.469Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24151.00',
          mileage: '36650'
        },
        sellArea: '600',
        dealerCd: '12159',
        certificationNumber: 9496089,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KW030234',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:31:12.564Z'
        }
      },
      sort: [
        1591214391469
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA14K9702526121382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:59:47.590Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '24704.00',
          mileage: '4239'
        },
        sellArea: '220',
        dealerCd: '12138',
        certificationNumber: 9445502,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA14K9702526',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6253',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T17:33:33.592Z'
        }
      },
      sort: [
        1591214387590
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1EB4GU049830371092020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:59:24.547Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18903.00',
          mileage: '71948'
        },
        sellArea: '800',
        dealerCd: '37109',
        certificationNumber: 9496076,
        makeCode: 'TOYOTA',
        vin: '4T1BD1EB4GU049830',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:19:56.314Z'
        }
      },
      sort: [
        1591214364547
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM078007180332020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:59:20.610Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31314.00',
          mileage: '25097'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9432927,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM078007',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T16:23:37.669Z'
        }
      },
      sort: [
        1591214360610
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS391512140522020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:58:23.990Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '29491.00',
          mileage: '33848'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9465661,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS391512',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:28:24.537Z'
        }
      },
      sort: [
        1591214303990
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFHXHS419692140522020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:57:13.349Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '24491.00',
          mileage: '40598'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9465653,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFHXHS419692',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:24:18.648Z'
        }
      },
      sort: [
        1591214233349
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXFW392417450702020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:57:02.348Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '68666'
        },
        sellArea: '220',
        dealerCd: '45070',
        certificationNumber: 9478139,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXFW392417',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T20:34:10.335Z'
        }
      },
      sort: [
        1591214222348
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1JP752413160052020-01-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:56:11.272Z',
          retailDate: '2020-01-25',
          rdrStatus: 'H',
          soldAmount: '13495.00',
          mileage: '51953'
        },
        dealerCd: '16005',
        certificationNumber: 9495481,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1JP752413',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:44:22.735Z'
        }
      },
      sort: [
        1591214171272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV7HD205747450702020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:55:45.438Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23800.00',
          mileage: '28458'
        },
        sellArea: '150',
        dealerCd: '45070',
        certificationNumber: 9461383,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV7HD205747',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T21:10:47.718Z'
        }
      },
      sort: [
        1591214145438
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH6HS449068140522020-05-07H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:55:25.349Z',
          retailDate: '2020-05-07',
          rdrStatus: 'H',
          soldAmount: '28150.00',
          mileage: '45708'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9465627,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH6HS449068',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:09:35.241Z'
        }
      },
      sort: [
        1591214125349
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HJ125179450702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:54:09.809Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19994.70',
          mileage: '31485'
        },
        sellArea: '160',
        dealerCd: '45070',
        certificationNumber: 9438701,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HJ125179',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T17:17:57.089Z'
        }
      },
      sort: [
        1591214049809
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU730731121592020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:52:20.410Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19452.00',
          mileage: '36650'
        },
        sellArea: '500',
        dealerCd: '12159',
        certificationNumber: 9496112,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU730731',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:44:07.522Z'
        }
      },
      sort: [
        1591213940410
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH2FS220200311752020-05-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:49:05.710Z',
          retailDate: '2020-05-04',
          rdrStatus: 'H',
          soldAmount: '25439.00',
          mileage: '34815'
        },
        sellArea: '160',
        dealerCd: '31175',
        certificationNumber: 9467716,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH2FS220200',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T22:55:19.749Z'
        }
      },
      sort: [
        1591213745710
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU212931121592020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:47:46.970Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19452.00',
          mileage: '37886'
        },
        sellArea: '500',
        dealerCd: '12159',
        certificationNumber: 9495843,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU212931',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:59:05.946Z'
        }
      },
      sort: [
        1591213666970
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3HP679359041342020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:44:53.271Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '14995.00',
          mileage: '30985'
        },
        sellArea: '110',
        dealerCd: '04134',
        certificationNumber: 9482623,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3HP679359',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T23:19:50.734Z'
        }
      },
      sort: [
        1591213493271
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU737209121592020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:40:02.090Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18150.00',
          mileage: '33476'
        },
        sellArea: '600',
        dealerCd: '12159',
        certificationNumber: 9491395,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU737209',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:20:12.047Z'
        }
      },
      sort: [
        1591213202090
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS515198140522020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:36:48.891Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '27991.00',
          mileage: '53507'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9488209,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS515198',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:14:43.680Z'
        }
      },
      sort: [
        1591213008891
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH6HS424249140522020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:35:40.852Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '32157.00',
          mileage: '33455'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9465536,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH6HS424249',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:20:44.588Z'
        }
      },
      sort: [
        1591212940852
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJGRFH3JS042081260232020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:34:10.771Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31500.00',
          mileage: '30174'
        },
        sellArea: '230',
        dealerCd: '26023',
        certificationNumber: 9469639,
        makeCode: 'TOYOTA',
        vin: '5TDJGRFH3JS042081',
        model: {
          modelDescription: 'HIGHLANDER HYBRD XLE-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6965',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T13:27:54.332Z'
        }
      },
      sort: [
        1591212850771
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2H5462030041192020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:33:37.050Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '36988.00',
          mileage: '35365'
        },
        sellArea: '120',
        dealerCd: '04119',
        certificationNumber: 9486437,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2H5462030',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T00:02:47.633Z'
        }
      },
      sort: [
        1591212817050
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMMU4FN2EM066764200962020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:33:31.970Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26995.00',
          mileage: '33635'
        },
        dealerCd: '20096',
        certificationNumber: 9496088,
        makeCode: 'TOYOTA',
        vin: '3TMMU4FN2EM066764',
        model: {
          modelDescription: 'TACOMA DBL CAB 4X4 V6 L/B',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7596',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:30:26.148Z'
        }
      },
      sort: [
        1591212811970
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC7LS032234220302020-05-30V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:33:24.271Z',
          retailDate: '2020-05-30',
          rdrStatus: 'V',
          soldAmount: '34995.00',
          mileage: '3142'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9465984,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC7LS032234',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T19:32:36.270Z'
        }
      },
      sort: [
        1591212804271
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC866343140522020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:30:57.592Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '13958.00',
          mileage: '39271'
        },
        sellArea: '210',
        dealerCd: '14052',
        certificationNumber: 9470526,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC866343',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:04:32.367Z'
        }
      },
      sort: [
        1591212657592
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN0GX007165291072020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:30:45.152Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22992.00',
          mileage: '53834'
        },
        sellArea: '160',
        dealerCd: '29107',
        certificationNumber: 9410450,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN0GX007165',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T22:04:22.983Z'
        }
      },
      sort: [
        1591212645152
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN3KM069956330252020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:28:32.251Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '35000.00',
          mileage: '11221'
        },
        sellArea: '230',
        dealerCd: '33025',
        certificationNumber: 9473373,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN3KM069956',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T20:45:47.425Z'
        }
      },
      sort: [
        1591212512251
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F18GX529141043472020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:27:40.710Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '33988.00',
          mileage: '54808'
        },
        sellArea: '110',
        dealerCd: '04347',
        certificationNumber: 9451665,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F18GX529141',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T21:35:29.047Z'
        }
      },
      sort: [
        1591212460710
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV7HJ132451043472020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:26:29.729Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23804.00',
          mileage: '30899'
        },
        sellArea: '110',
        dealerCd: '04347',
        certificationNumber: 9461462,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV7HJ132451',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T22:04:34.021Z'
        }
      },
      sort: [
        1591212389729
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH8JS494073043472020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:25:18.851Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '30788.00',
          mileage: '27960'
        },
        sellArea: '110',
        dealerCd: '04347',
        certificationNumber: 9410440,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH8JS494073',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T22:01:25.421Z'
        }
      },
      sort: [
        1591212318851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU194871121022020-03-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:25:16.112Z',
          retailDate: '2020-03-13',
          rdrStatus: 'H',
          soldAmount: '18225.00',
          mileage: '35111'
        },
        sellArea: '500',
        dealerCd: '12102',
        certificationNumber: 9496078,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU194871',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:20:06.995Z'
        }
      },
      sort: [
        1591212316112
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV1HY173341043472020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:24:09.272Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '11906.77',
          mileage: '23659'
        },
        sellArea: '110',
        dealerCd: '04347',
        certificationNumber: 9463655,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV1HY173341',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T21:59:10.288Z'
        }
      },
      sort: [
        1591212249272
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDY5G15GS145323190452020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:22:49.312Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '43000.00',
          mileage: '12030'
        },
        sellArea: '800',
        dealerCd: '19045',
        certificationNumber: 9496073,
        makeCode: 'TOYOTA',
        vin: '5TDDY5G15GS145323',
        model: {
          modelDescription: 'SEQUOIA 4X4 PLAT 7-PASS 5.7L V8',
          modelSeries: 'SEQUOIA',
          modelNumber: '7933',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T19:17:22.492Z'
        }
      },
      sort: [
        1591212169312
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU209150046882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:21:48.312Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21681.00',
          mileage: '32888'
        },
        sellArea: '600',
        dealerCd: '04688',
        certificationNumber: 9460198,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU209150',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T21:13:49.819Z'
        }
      },
      sort: [
        1591212108312
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F16HX663196046882020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:21:06.553Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '36981.00',
          mileage: '64798'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9482535,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F16HX663196',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 EDITION CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T22:23:06.946Z'
        }
      },
      sort: [
        1591212066553
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP573260140522020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:19:53.492Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '28701'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9465586,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP573260',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:48:45.253Z'
        }
      },
      sort: [
        1591211993492
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F15GX518541130542020-04-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:19:22.912Z',
          retailDate: '2020-04-30',
          rdrStatus: 'H',
          soldAmount: '26876.86',
          mileage: '81504'
        },
        sellArea: '210',
        dealerCd: '13054',
        certificationNumber: 9397346,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F15GX518541',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T18:04:44.428Z'
        }
      },
      sort: [
        1591211962912
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8ED056427461042020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:13:52.799Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17432.15',
          mileage: '45552'
        },
        sellArea: '130',
        dealerCd: '46104',
        certificationNumber: 9488087,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8ED056427',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:28:23.770Z'
        }
      },
      sort: [
        1591211632799
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KW043615360992020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:13:23.953Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '20289'
        },
        sellArea: '500',
        dealerCd: '36099',
        certificationNumber: 9471998,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KW043615',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T23:54:02.335Z'
        }
      },
      sort: [
        1591211603953
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRX5GN6GX065339371552020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:12:48.513Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '35845'
        },
        sellArea: '800',
        dealerCd: '37155',
        certificationNumber: 9464435,
        makeCode: 'TOYOTA',
        vin: '5TFRX5GN6GX065339',
        model: {
          modelDescription: 'TACOMA SR5 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7122',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T17:00:34.763Z'
        }
      },
      sort: [
        1591211568513
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE5LJ027256020362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:11:16.273Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16718.00',
          mileage: '29143'
        },
        sellArea: '600',
        dealerCd: '02036',
        certificationNumber: 9483182,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE5LJ027256',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:15:57.240Z'
        }
      },
      sort: [
        1591211476273
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1HK9KU029224020362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:09:29.893Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28176.35',
          mileage: '7308'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9473678,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1HK9KU029224',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T23:20:46.681Z'
        }
      },
      sort: [
        1591211369893
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV4HJ170947200962020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:08:54.013Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '35759'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9483095,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV4HJ170947',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T13:29:59.084Z'
        }
      },
      sort: [
        1591211334013
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F10KX841047201452020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:08:21.833Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '37585.00',
          mileage: '13584'
        },
        sellArea: '210',
        dealerCd: '20145',
        certificationNumber: 9410223,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F10KX841047',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T19:56:17.322Z'
        }
      },
      sort: [
        1591211301833
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV9GW520454311362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:07:46.834Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23690.00',
          mileage: '34893'
        },
        dealerCd: '31136',
        certificationNumber: 9229687,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV9GW520454',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-02T20:20:43.552Z'
        }
      },
      sort: [
        1591211266834
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV4KW060425360992020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:07:25.072Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24499.00',
          mileage: '12974'
        },
        sellArea: '600',
        dealerCd: '36099',
        certificationNumber: 9426761,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV4KW060425',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T14:28:46.053Z'
        }
      },
      sort: [
        1591211245072
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU619422311912020-03-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:07:00.813Z',
          retailDate: '2020-03-21',
          rdrStatus: 'H',
          soldAmount: '16340.00',
          mileage: '33721'
        },
        dealerCd: '31191',
        certificationNumber: 9230313,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU619422',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-04T16:16:16.212Z'
        }
      },
      sort: [
        1591211220813
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU012477280292020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:06:12.333Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17959.00',
          mileage: '33996'
        },
        sellArea: '170',
        dealerCd: '28029',
        certificationNumber: 9466611,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU012477',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T13:00:26.406Z'
        }
      },
      sort: [
        1591211172333
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2EC017588200962020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:04:18.174Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '10995.00',
          mileage: '56012'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9486959,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2EC017588',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:47:40.767Z'
        }
      },
      sort: [
        1591211058174
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX6KR067977311912020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:02:28.495Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '18141.00',
          mileage: '15665'
        },
        sellArea: '160',
        dealerCd: '31191',
        certificationNumber: 9427768,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX6KR067977',
        model: {
          modelDescription: 'C-HR LE',
          modelSeries: 'CHR',
          modelNumber: '2402',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T22:20:06.659Z'
        }
      },
      sort: [
        1591210948495
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB38G1138203311912020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T19:01:01.953Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '15854.00',
          mileage: '34569'
        },
        dealerCd: '31191',
        certificationNumber: 9369720,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB38G1138203',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-05T20:33:12.931Z'
        }
      },
      sort: [
        1591210861953
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJGRFH2KS052876370362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:59:24.515Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '36000.00',
          mileage: '19054'
        },
        sellArea: '800',
        dealerCd: '37036',
        certificationNumber: 9491747,
        makeCode: 'TOYOTA',
        vin: '5TDJGRFH2KS052876',
        model: {
          modelDescription: 'HIGHLANDER HYBRD XLE-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6965',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:56:25.279Z'
        }
      },
      sort: [
        1591210764515
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW663254311912020-05-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:58:09.415Z',
          retailDate: '2020-05-13',
          rdrStatus: 'H',
          soldAmount: '18052.00',
          mileage: '66029'
        },
        sellArea: '160',
        dealerCd: '31191',
        certificationNumber: 9422511,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW663254',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T17:13:39.191Z'
        }
      },
      sort: [
        1591210689415
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN2HM031046070092020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:55:52.235Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '26966.00',
          mileage: '14842'
        },
        sellArea: '120',
        dealerCd: '07009',
        certificationNumber: 9426344,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN2HM031046',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T23:29:20.210Z'
        }
      },
      sort: [
        1591210552235
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS435900150502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:54:56.455Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '35087'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9496002,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS435900',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:49:48.113Z'
        }
      },
      sort: [
        1591210496455
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW627356140462020-04-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:54:21.435Z',
          retailDate: '2020-04-09',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '40294'
        },
        sellArea: '230',
        dealerCd: '14046',
        certificationNumber: 9495957,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW627356',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:38:59.388Z'
        }
      },
      sort: [
        1591210461435
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU720254140522020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:48:50.796Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '15479.75',
          mileage: '49324'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9465565,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU720254',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:38:50.492Z'
        }
      },
      sort: [
        1591210130796
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU749182220302020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:48:25.935Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16485.00',
          mileage: '32003'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9474101,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU749182',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T12:43:50.544Z'
        }
      },
      sort: [
        1591210105935
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU695954140522020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:46:22.694Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '17824.00',
          mileage: '17901'
        },
        sellArea: '230',
        dealerCd: '14052',
        certificationNumber: 9465613,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU695954',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:03:19.879Z'
        }
      },
      sort: [
        1591209982694
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMP1RFVXKD012378361002020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:45:27.975Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25952.00',
          mileage: '9242'
        },
        sellArea: '130',
        dealerCd: '36100',
        certificationNumber: 9469340,
        makeCode: 'TOYOTA',
        vin: 'JTMP1RFVXKD012378',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T00:16:35.588Z'
        }
      },
      sort: [
        1591209927975
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP586715150502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:45:09.815Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14200.00',
          mileage: '33720'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9437405,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP586715',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T16:33:29.960Z'
        }
      },
      sort: [
        1591209909815
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2JP752677150502020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:44:05.317Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '13800.00',
          mileage: '40644'
        },
        sellArea: '500',
        dealerCd: '15050',
        certificationNumber: 9437397,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2JP752677',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T16:24:44.224Z'
        }
      },
      sort: [
        1591209845317
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBW5F18HX641730130542020-04-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:42:45.849Z',
          retailDate: '2020-04-28',
          rdrStatus: 'H',
          soldAmount: '36000.00',
          mileage: '18328'
        },
        sellArea: '210',
        dealerCd: '13054',
        certificationNumber: 9397375,
        makeCode: 'TOYOTA',
        vin: '5TFBW5F18HX641730',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DBL CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8354',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T18:18:28.132Z'
        }
      },
      sort: [
        1591209765849
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV4JD210415060492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:42:29.244Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '23891'
        },
        sellArea: '160',
        dealerCd: '06049',
        certificationNumber: 9474394,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV4JD210415',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T15:42:18.277Z'
        }
      },
      sort: [
        1591209749244
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW564431060492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:41:34.395Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '34177'
        },
        dealerCd: '06049',
        certificationNumber: 9341765,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW564431',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-17T15:37:24.133Z'
        }
      },
      sort: [
        1591209694395
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HD202287470322020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:29:40.896Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23691.00',
          mileage: '23549'
        },
        sellArea: '160',
        dealerCd: '47032',
        certificationNumber: 9486095,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HD202287',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:52:51.345Z'
        }
      },
      sort: [
        1591208980896
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC844656140462020-01-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:29:17.538Z',
          retailDate: '2020-01-25',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '24753'
        },
        sellArea: '230',
        dealerCd: '14046',
        certificationNumber: 9495391,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC844656',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:10:18.951Z'
        }
      },
      sort: [
        1591208957538
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC017950460712020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:28:46.057Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25748.00',
          mileage: '15454'
        },
        sellArea: '500',
        dealerCd: '46071',
        certificationNumber: 9397832,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC017950',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T21:43:13.846Z'
        }
      },
      sort: [
        1591208926057
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN5HM019326460712020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:27:36.736Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29948.00',
          mileage: '31806'
        },
        sellArea: '130',
        dealerCd: '46071',
        certificationNumber: 9424669,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN5HM019326',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T22:26:14.568Z'
        }
      },
      sort: [
        1591208856736
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU429725220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:27:14.496Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16275.00',
          mileage: '24113'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9456571,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU429725',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T14:42:50.320Z'
        }
      },
      sort: [
        1591208834496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB3JU268243250602020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:25:57.619Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25990.00',
          mileage: '22209'
        },
        sellArea: '130',
        dealerCd: '25060',
        certificationNumber: 9476795,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB3JU268243',
        model: {
          modelDescription: 'AVALON 4-DR TOURING',
          modelSeries: 'AVALON',
          modelNumber: '3548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T23:38:14.679Z'
        }
      },
      sort: [
        1591208757619
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU678356130772020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:25:27.196Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '30890'
        },
        sellArea: '210',
        dealerCd: '13077',
        certificationNumber: 9440398,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU678356',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-04T20:57:53.475Z'
        }
      },
      sort: [
        1591208727196
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0G5403296220382020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:23:28.564Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27407.10',
          mileage: '38467'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9495912,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0G5403296',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:20:50.573Z'
        }
      },
      sort: [
        1591208608564
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU362278220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:18:16.543Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16775.00',
          mileage: '32499'
        },
        sellArea: '800',
        dealerCd: '22030',
        certificationNumber: 9408632,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU362278',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T18:05:07.721Z'
        }
      },
      sort: [
        1591208296543
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9K5612206250642020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:15:39.804Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '39990.00',
          mileage: '10795'
        },
        sellArea: '130',
        dealerCd: '25064',
        certificationNumber: 9418736,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9K5612206',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T17:41:14.551Z'
        }
      },
      sort: [
        1591208139804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ120652190622020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:15:36.624Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21277.00',
          mileage: '18043'
        },
        sellArea: '160',
        dealerCd: '19062',
        certificationNumber: 9424440,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ120652',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T20:55:41.169Z'
        }
      },
      sort: [
        1591208136624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV8HW588469371852020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:14:37.546Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24098.00',
          mileage: '35969'
        },
        sellArea: '800',
        dealerCd: '37185',
        certificationNumber: 9430502,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV8HW588469',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T16:07:54.630Z'
        }
      },
      sort: [
        1591208077546
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3J5542491280372020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:14:34.824Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '29650.00',
          mileage: '42869'
        },
        sellArea: '160',
        dealerCd: '28037',
        certificationNumber: 9495616,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3J5542491',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:48:47.977Z'
        }
      },
      sort: [
        1591208074824
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK1JU514867290862020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:14:13.786Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '40843'
        },
        sellArea: '160',
        dealerCd: '29086',
        certificationNumber: 9489083,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK1JU514867',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T14:40:50.251Z'
        }
      },
      sort: [
        1591208053786
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV7HW315791240672020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:11:46.162Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17475.00',
          mileage: '59590'
        },
        sellArea: '600',
        dealerCd: '24067',
        certificationNumber: 9495879,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV7HW315791',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:09:40.512Z'
        }
      },
      sort: [
        1591207906162
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU385177290862020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:11:17.703Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '16990.00',
          mileage: '20989'
        },
        sellArea: '160',
        dealerCd: '29086',
        certificationNumber: 9474901,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU385177',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:40:43.728Z'
        }
      },
      sort: [
        1591207877703
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU669214060362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:09:33.100Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '14900.00',
          mileage: '12660'
        },
        sellArea: '160',
        dealerCd: '06036',
        certificationNumber: 9495819,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU669214',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:50:23.002Z'
        }
      },
      sort: [
        1591207773100
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU784997341202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:08:23.120Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19250.00',
          mileage: '21336'
        },
        sellArea: '220',
        dealerCd: '34120',
        certificationNumber: 9495861,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU784997',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T18:05:00.446Z'
        }
      },
      sort: [
        1591207703120
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU788565340892020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:03:59.779Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '19050.00',
          mileage: '27643'
        },
        sellArea: '500',
        dealerCd: '34089',
        certificationNumber: 9477254,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU788565',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T13:28:46.451Z'
        }
      },
      sort: [
        1591207439779
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC7HS849615130542020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:03:23.677Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '23300.00',
          mileage: '64677'
        },
        sellArea: '210',
        dealerCd: '13054',
        certificationNumber: 9463338,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC7HS849615',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T19:36:11.145Z'
        }
      },
      sort: [
        1591207403677
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HD207327371852020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:02:44.218Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20132.08',
          mileage: '29939'
        },
        sellArea: '800',
        dealerCd: '37185',
        certificationNumber: 9456875,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HD207327',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T18:31:53.441Z'
        }
      },
      sort: [
        1591207364218
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KC018987042012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:02:12.578Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25900.00',
          mileage: '17540'
        },
        sellArea: '600',
        dealerCd: '04201',
        certificationNumber: 9459573,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KC018987',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T15:06:07.849Z'
        }
      },
      sort: [
        1591207332578
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4GP486510042012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T18:00:53.176Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '12000.00',
          mileage: '70857'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9485708,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4GP486510',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:59:23.961Z'
        }
      },
      sort: [
        1591207253176
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8JJ718603121592020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:59:06.169Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '18846.00',
          mileage: '31100'
        },
        sellArea: '210',
        dealerCd: '12159',
        certificationNumber: 9491434,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8JJ718603',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:33:07.655Z'
        }
      },
      sort: [
        1591207146169
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3HC802187110352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:57:16.561Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16525.00',
          mileage: '32248'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9483055,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3HC802187',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T12:57:56.002Z'
        }
      },
      sort: [
        1591207036561
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV4HD115265371852020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:57:13.995Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21319.93',
          mileage: '35373'
        },
        sellArea: '800',
        dealerCd: '37185',
        certificationNumber: 9492449,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV4HD115265',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:58:51.795Z'
        }
      },
      sort: [
        1591207033995
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN7FM202238121592020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:56:43.935Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '23462.00',
          mileage: '58652'
        },
        sellArea: '210',
        dealerCd: '12159',
        certificationNumber: 9491300,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN7FM202238',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T14:43:31.881Z'
        }
      },
      sort: [
        1591207003935
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK0LU307680043092020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:55:33.934Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21988.00',
          mileage: '8257'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9432178,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK0LU307680',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T19:09:22.787Z'
        }
      },
      sort: [
        1591206933934
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE3HJ548662042502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:54:26.353Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16295.00',
          mileage: '30195'
        },
        dealerCd: '04250',
        certificationNumber: 9368277,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE3HJ548662',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T19:14:20.933Z'
        }
      },
      sort: [
        1591206866353
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV8JJ166020160052020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:53:02.695Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18990.00',
          mileage: '15655'
        },
        sellArea: '220',
        dealerCd: '16005',
        certificationNumber: 9487766,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV8JJ166020',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:50:29.279Z'
        }
      },
      sort: [
        1591206782695
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU305217200932020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:47:38.109Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '24054'
        },
        sellArea: '170',
        dealerCd: '20093',
        certificationNumber: 9487903,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU305217',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:38:10.501Z'
        }
      },
      sort: [
        1591206458109
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV7HW652301110352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:47:03.389Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20182.55',
          mileage: '28594'
        },
        sellArea: '170',
        dealerCd: '11035',
        certificationNumber: 9463078,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV7HW652301',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T17:44:58.893Z'
        }
      },
      sort: [
        1591206423389
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9GC727929043562020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:46:35.129Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '12876.00',
          mileage: '49748'
        },
        sellArea: '150',
        dealerCd: '04356',
        certificationNumber: 9495778,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9GC727929',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T17:34:04.070Z'
        }
      },
      sort: [
        1591206395129
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU521751200932020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:43:52.067Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19900.00',
          mileage: '39633'
        },
        sellArea: '170',
        dealerCd: '20093',
        certificationNumber: 9418206,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU521751',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T13:58:37.502Z'
        }
      },
      sort: [
        1591206232067
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0FW370829201142020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:42:31.245Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '13500.00',
          mileage: '49971'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9461920,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0FW370829',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T15:07:30.205Z'
        }
      },
      sort: [
        1591206151245
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW647197121592020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:41:53.046Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '18310.25',
          mileage: '5565'
        },
        sellArea: '210',
        dealerCd: '12159',
        certificationNumber: 9472613,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW647197',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T15:42:39.350Z'
        }
      },
      sort: [
        1591206113046
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBY5F12GX565026201402020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:40:46.183Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32000.00',
          mileage: '17491'
        },
        dealerCd: '20140',
        certificationNumber: 9302926,
        makeCode: 'TOYOTA',
        vin: '5TFBY5F12GX565026',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8352',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-23T16:23:38.975Z'
        }
      },
      sort: [
        1591206046183
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2KC007583201402020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:39:28.585Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '23695.00',
          mileage: '16469'
        },
        sellArea: '500',
        dealerCd: '20140',
        certificationNumber: 9492694,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2KC007583',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:14:33.725Z'
        }
      },
      sort: [
        1591205968585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4EW180451370362020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:38:48.224Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18225.00',
          mileage: '74588'
        },
        dealerCd: '37036',
        certificationNumber: 9340085,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4EW180451',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-16T17:51:49.442Z'
        }
      },
      sort: [
        1591205928224
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS421705110352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:37:26.723Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30704.00',
          mileage: '12585'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9488262,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS421705',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:31:05.051Z'
        }
      },
      sort: [
        1591205846723
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE4LJ022266201402020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:35:30.502Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17595.00',
          mileage: '11822'
        },
        sellArea: '500',
        dealerCd: '20140',
        certificationNumber: 9491515,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE4LJ022266',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:02:54.228Z'
        }
      },
      sort: [
        1591205730502
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB37F1114022040512020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:34:33.401Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '12500.00',
          mileage: '61237'
        },
        sellArea: '120',
        dealerCd: '04051',
        certificationNumber: 9477741,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB37F1114022',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:38:59.533Z'
        }
      },
      sort: [
        1591205673401
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH6HS373254201142020-05-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:34:25.322Z',
          retailDate: '2020-05-08',
          rdrStatus: 'H',
          soldAmount: '27000.00',
          mileage: '41489'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9408313,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH6HS373254',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T15:21:34.207Z'
        }
      },
      sort: [
        1591205665322
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU101998201402020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:34:20.882Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16600.00',
          mileage: '22480'
        },
        sellArea: '500',
        dealerCd: '20140',
        certificationNumber: 9492844,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU101998',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:51:08.754Z'
        }
      },
      sort: [
        1591205660882
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ708393311162020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:33:28.262Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18429.63',
          mileage: '20753'
        },
        sellArea: '160',
        dealerCd: '31116',
        certificationNumber: 9468507,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ708393',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T16:42:17.916Z'
        }
      },
      sort: [
        1591205608262
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP692689220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:32:05.079Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '13990.00',
          mileage: '22559'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9491057,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP692689',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T12:56:03.987Z'
        }
      },
      sort: [
        1591205525079
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMY1RFV9KD501911043092020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:31:15.572Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '29888.00',
          mileage: '4712'
        },
        sellArea: '110',
        dealerCd: '04309',
        certificationNumber: 9445700,
        makeCode: 'TOYOTA',
        vin: 'JTMY1RFV9KD501911',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T22:37:48.351Z'
        }
      },
      sort: [
        1591205475572
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU803396270132020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:31:03.119Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18897.00',
          mileage: '22246'
        },
        sellArea: '600',
        dealerCd: '27013',
        certificationNumber: 9488630,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU803396',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T01:10:30.037Z'
        }
      },
      sort: [
        1591205463119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU3G3009126020482020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:23:10.516Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15457.40',
          mileage: '65304'
        },
        sellArea: '150',
        dealerCd: '02048',
        certificationNumber: 9439849,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU3G3009126',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-03T16:53:35.161Z'
        }
      },
      sort: [
        1591204990516
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRX5GN7HX101055020482020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:21:36.615Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '31222'
        },
        sellArea: '150',
        dealerCd: '02048',
        certificationNumber: 9472867,
        makeCode: 'TOYOTA',
        vin: '5TFRX5GN7HX101055',
        model: {
          modelDescription: 'TACOMA SR 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7162',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T17:34:21.734Z'
        }
      },
      sort: [
        1591204896615
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU451954020482020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:20:52.612Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '12411.16',
          mileage: '50510'
        },
        sellArea: '150',
        dealerCd: '02048',
        certificationNumber: 9494701,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU451954',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:07:09.612Z'
        }
      },
      sort: [
        1591204852612
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB3XG1135786201282020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:18:59.753Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14719.59',
          mileage: '35550'
        },
        dealerCd: '20128',
        certificationNumber: 9129562,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB3XG1135786',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB ONE',
          modelSeries: 'PRIUS C',
          modelNumber: '1201',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-08-30T13:39:06.707Z'
        }
      },
      sort: [
        1591204739753
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM058498130542020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:17:26.152Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '29500.00',
          mileage: '44901'
        },
        sellArea: '210',
        dealerCd: '13054',
        certificationNumber: 9440734,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM058498',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T16:11:19.404Z'
        }
      },
      sort: [
        1591204646152
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU800373130752020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:16:33.311Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17536.00',
          mileage: '22009'
        },
        sellArea: '210',
        dealerCd: '13075',
        certificationNumber: 9461358,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU800373',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T20:47:52.126Z'
        }
      },
      sort: [
        1591204593311
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU735773044212020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:14:12.130Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23988.0000',
          mileage: '34825'
        },
        sellArea: '600',
        dealerCd: '04421',
        certificationNumber: 9433174,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU735773',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T18:45:07.670Z'
        }
      },
      sort: [
        1591204452130
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK4KU006547044212020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:14:08.691Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25320.44',
          mileage: '25291'
        },
        sellArea: '120',
        dealerCd: '04421',
        certificationNumber: 9460641,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK4KU006547',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T13:26:46.613Z'
        }
      },
      sort: [
        1591204448691
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV7HD199762150532020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:13:27.448Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20605.00',
          mileage: '28138'
        },
        sellArea: '230',
        dealerCd: '15053',
        certificationNumber: 9472550,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV7HD199762',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T15:10:13.214Z'
        }
      },
      sort: [
        1591204407448
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8HM080215371812020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:12:19.730Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '34540'
        },
        sellArea: '800',
        dealerCd: '37181',
        certificationNumber: 9491835,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8HM080215',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:16:38.309Z'
        }
      },
      sort: [
        1591204339730
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREV5GJ061313430192020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:10:11.929Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20613.00',
          mileage: '43757'
        },
        sellArea: '150',
        dealerCd: '43019',
        certificationNumber: 9477560,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREV5GJ061313',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T16:27:23.242Z'
        }
      },
      sort: [
        1591204211929
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS388074201142020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:09:28.628Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '29500.00',
          mileage: '36081'
        },
        sellArea: '160',
        dealerCd: '20114',
        certificationNumber: 9421974,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS388074',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T11:20:55.863Z'
        }
      },
      sort: [
        1591204168628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV6HW602448201142020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:08:39.466Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23500.00',
          mileage: '37763'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9421969,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV6HW602448',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T11:15:34.359Z'
        }
      },
      sort: [
        1591204119466
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KW045642500122020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:08:13.247Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '30188.00',
          mileage: '15192'
        },
        sellArea: '130',
        dealerCd: '50012',
        certificationNumber: 9459686,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KW045642',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T16:22:39.933Z'
        }
      },
      sort: [
        1591204093247
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU376923260312020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:07:14.066Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '14996.00',
          mileage: '24093'
        },
        sellArea: '220',
        dealerCd: '26031',
        certificationNumber: 9483683,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU376923',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:05:54.846Z'
        }
      },
      sort: [
        1591204034066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH3ES027744500122020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:07:06.047Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '28404.81',
          mileage: '46797'
        },
        sellArea: '130',
        dealerCd: '50012',
        certificationNumber: 9486214,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH3ES027744',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:55:10.073Z'
        }
      },
      sort: [
        1591204026047
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK2JU508486311312020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:06:58.555Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23000.00',
          mileage: '31974'
        },
        sellArea: '800',
        dealerCd: '31131',
        certificationNumber: 9419665,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK2JU508486',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T13:46:25.252Z'
        }
      },
      sort: [
        1591204018555
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0J5508797430192020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:06:12.992Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '36500.00',
          mileage: '33882'
        },
        sellArea: '150',
        dealerCd: '43019',
        certificationNumber: 9486213,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0J5508797',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:54:49.389Z'
        }
      },
      sort: [
        1591203972992
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1J5577465500122020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:05:27.049Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '39789.00',
          mileage: '13436'
        },
        sellArea: '130',
        dealerCd: '50012',
        certificationNumber: 9409357,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1J5577465',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T01:33:28.418Z'
        }
      },
      sort: [
        1591203927049
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8G5376302450922020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:05:09.650Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '27225.00',
          mileage: '46419'
        },
        sellArea: '800',
        dealerCd: '45092',
        certificationNumber: 9495524,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8G5376302',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:03:15.344Z'
        }
      },
      sort: [
        1591203909650
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU691674042652020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:04:41.250Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20288.00',
          mileage: '34409'
        },
        sellArea: '600',
        dealerCd: '04265',
        certificationNumber: 9468913,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU691674',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:34:50.081Z'
        }
      },
      sort: [
        1591203881250
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE2LJ011514201262020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:04:18.789Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '20998.00',
          mileage: '5860'
        },
        sellArea: '170',
        dealerCd: '20126',
        certificationNumber: 9491299,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE2LJ011514',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T14:43:23.127Z'
        }
      },
      sort: [
        1591203858789
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV5HJ120852201142020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:03:16.610Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '16830.00',
          mileage: '32053'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9430144,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV5HJ120852',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T11:49:12.476Z'
        }
      },
      sort: [
        1591203796610
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV7GW304267130542020-04-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:03:16.506Z',
          retailDate: '2020-04-25',
          rdrStatus: 'H',
          soldAmount: '18250.00',
          mileage: '29983'
        },
        sellArea: '600',
        dealerCd: '13054',
        certificationNumber: 9448603,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV7GW304267',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T15:32:20.839Z'
        }
      },
      sort: [
        1591203796506
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7JW816019220472020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:02:06.203Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19699.00',
          mileage: '34573'
        },
        dealerCd: '22047',
        certificationNumber: 9187368,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7JW816019',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-07T17:57:46.464Z'
        }
      },
      sort: [
        1591203726203
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBY5F11HX648349120712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T17:00:26.623Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '33395.00',
          mileage: '21778'
        },
        sellArea: '130',
        dealerCd: '12071',
        certificationNumber: 9495191,
        makeCode: 'TOYOTA',
        vin: '5TFBY5F11HX648349',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8352',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:40:56.336Z'
        }
      },
      sort: [
        1591203626623
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH6FS130853120712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:58:53.881Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25500.00',
          mileage: '48748'
        },
        sellArea: '210',
        dealerCd: '12071',
        certificationNumber: 9429032,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH6FS130853',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T20:16:21.668Z'
        }
      },
      sort: [
        1591203533881
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU677472120712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:58:08.041Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16250.00',
          mileage: '34512'
        },
        sellArea: '210',
        dealerCd: '12071',
        certificationNumber: 9413578,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU677472',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T16:15:10.157Z'
        }
      },
      sort: [
        1591203488041
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE2LP009714220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:54:14.139Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15755.00',
          mileage: '13221'
        },
        dealerCd: '22030',
        certificationNumber: 9385661,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE2LP009714',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T15:52:50.821Z'
        }
      },
      sort: [
        1591203254139
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFU6K3010857480462020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:49:55.037Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22879.00',
          mileage: '8175'
        },
        sellArea: '210',
        dealerCd: '48046',
        certificationNumber: 9429737,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFU6K3010857',
        model: {
          modelDescription: 'PRIUS LE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1263',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T16:09:22.414Z'
        }
      },
      sort: [
        1591202995037
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC008679311312020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:49:36.815Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '25799.00',
          mileage: '24142'
        },
        sellArea: '500',
        dealerCd: '31131',
        certificationNumber: 9475796,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC008679',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T14:36:46.217Z'
        }
      },
      sort: [
        1591202976815
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV2HW378913041592020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:42:35.834Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26435.00',
          mileage: '21746'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9466876,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV2HW378913',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T15:30:12.147Z'
        }
      },
      sort: [
        1591202555834
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9HC908921050672020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:40:31.677Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '13981.0000',
          mileage: '29536'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9492262,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9HC908921',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:21:27.210Z'
        }
      },
      sort: [
        1591202431677
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW653623050672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:40:27.470Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20479.8100',
          mileage: '36131'
        },
        sellArea: '150',
        dealerCd: '05067',
        certificationNumber: 9484403,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW653623',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:03:00.215Z'
        }
      },
      sort: [
        1591202427470
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS511162050672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:40:24.590Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28981.0000',
          mileage: '35201'
        },
        sellArea: '160',
        dealerCd: '05067',
        certificationNumber: 9494770,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS511162',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:58:52.877Z'
        }
      },
      sort: [
        1591202424590
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7HM072753050672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:40:21.897Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27981.0000',
          mileage: '36340'
        },
        sellArea: '170',
        dealerCd: '05067',
        certificationNumber: 9494047,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7HM072753',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:43:11.168Z'
        }
      },
      sort: [
        1591202421897
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS408862050672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:40:19.196Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27981.0000',
          mileage: '33056'
        },
        sellArea: '160',
        dealerCd: '05067',
        certificationNumber: 9492867,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS408862',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:54:38.576Z'
        }
      },
      sort: [
        1591202419196
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F15HX668828050672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:40:16.698Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '33199.00',
          mileage: '37294'
        },
        sellArea: '500',
        dealerCd: '05067',
        certificationNumber: 9478339,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F15HX668828',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:02:38.841Z'
        }
      },
      sort: [
        1591202416698
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU673714220382020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:39:56.355Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15219.00',
          mileage: '35351'
        },
        dealerCd: '22038',
        certificationNumber: 9336968,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU673714',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-14T21:16:40.759Z'
        }
      },
      sort: [
        1591202396355
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH9GS324541220382020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:38:43.435Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25919.00',
          mileage: '39502'
        },
        sellArea: '230',
        dealerCd: '22038',
        certificationNumber: 9398955,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH9GS324541',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T17:05:55.121Z'
        }
      },
      sort: [
        1591202323435
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU321859220382020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:37:58.757Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17297.00',
          mileage: '27908'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9411700,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU321859',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T20:40:46.013Z'
        }
      },
      sort: [
        1591202278757
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU705841020512020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:37:49.956Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16900.00',
          mileage: '23118'
        },
        sellArea: '150',
        dealerCd: '02051',
        certificationNumber: 9486011,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU705841',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:01:28.299Z'
        }
      },
      sort: [
        1591202269956
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2GU164418020512020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:35:45.548Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15550.00',
          mileage: '49329'
        },
        sellArea: '150',
        dealerCd: '02051',
        certificationNumber: 9494773,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2GU164418',
        model: {
          modelDescription: 'CAMRY SE SPECIAL EDITION',
          modelSeries: 'CAMRY',
          modelNumber: '2547',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T23:02:22.887Z'
        }
      },
      sort: [
        1591202145548
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV9KW014024043622020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:34:28.735Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '27998.00',
          mileage: '27447'
        },
        sellArea: '600',
        dealerCd: '04362',
        certificationNumber: 9481937,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV9KW014024',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:22:00.757Z'
        }
      },
      sort: [
        1591202068735
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS509245220302020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:33:04.232Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '37755.00',
          mileage: '35970'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9495574,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS509245',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:25:44.591Z'
        }
      },
      sort: [
        1591201984232
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV7JD197248130542020-05-04H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:32:18.874Z',
          retailDate: '2020-05-04',
          rdrStatus: 'H',
          soldAmount: '25500.00',
          mileage: '54507'
        },
        sellArea: '210',
        dealerCd: '13054',
        certificationNumber: 9430248,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV7JD197248',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T13:45:39.302Z'
        }
      },
      sort: [
        1591201938874
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH3HS025613480612020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:31:43.853Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '32406.00',
          mileage: '35038'
        },
        sellArea: '210',
        dealerCd: '48061',
        certificationNumber: 9425333,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH3HS025613',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T15:17:46.260Z'
        }
      },
      sort: [
        1591201903853
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8GC633363042782020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:28:18.369Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13425.00',
          mileage: '40257'
        },
        sellArea: '800',
        dealerCd: '04278',
        certificationNumber: 9492222,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8GC633363',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:12:19.288Z'
        }
      },
      sort: [
        1591201698369
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6FJ041878311652020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:26:26.868Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '16400.00',
          mileage: '44810'
        },
        sellArea: '160',
        dealerCd: '31165',
        certificationNumber: 9495555,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6FJ041878',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:19:35.679Z'
        }
      },
      sort: [
        1591201586868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6HJ144737341202020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:25:56.930Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '25581'
        },
        sellArea: '220',
        dealerCd: '34120',
        certificationNumber: 9478073,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6HJ144737',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:58:35.020Z'
        }
      },
      sort: [
        1591201556930
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV5HW598957311652020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:23:36.740Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22770.00',
          mileage: '14130'
        },
        sellArea: '160',
        dealerCd: '31165',
        certificationNumber: 9495560,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV5HW598957',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:21:16.557Z'
        }
      },
      sort: [
        1591201416740
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE7K3029745045432020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:23:20.699Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17061.67',
          mileage: '12241'
        },
        sellArea: '110',
        dealerCd: '04543',
        certificationNumber: 9466157,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE7K3029745',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T20:43:12.248Z'
        }
      },
      sort: [
        1591201400699
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRW5F19HX214698020162020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:23:05.600Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29311.00',
          mileage: '31284'
        },
        sellArea: '150',
        dealerCd: '02016',
        certificationNumber: 9492441,
        makeCode: 'TOYOTA',
        vin: '5TFRW5F19HX214698',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8231',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:57:54.093Z'
        }
      },
      sort: [
        1591201385600
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7JU629732041082020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:22:06.640Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18852.00',
          mileage: '5569'
        },
        sellArea: '110',
        dealerCd: '04108',
        certificationNumber: 9495553,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7JU629732',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:18:50.095Z'
        }
      },
      sort: [
        1591201326640
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV9HD126644020442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:11:32.840Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28202.02',
          mileage: '34906'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9477192,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV9HD126644',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T12:02:12.729Z'
        }
      },
      sort: [
        1591200692840
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS513018120822020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:11:08.420Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '28999.00',
          mileage: '36938'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9485146,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS513018',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:33:33.680Z'
        }
      },
      sort: [
        1591200668420
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU093416120772020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:10:49.600Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15494.00',
          mileage: '39287'
        },
        dealerCd: '12077',
        certificationNumber: 9355031,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU093416',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-27T16:32:49.103Z'
        }
      },
      sort: [
        1591200649600
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU209597020442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:10:33.422Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21078.99',
          mileage: '30592'
        },
        sellArea: '600',
        dealerCd: '02044',
        certificationNumber: 9477183,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU209597',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T11:38:41.817Z'
        }
      },
      sort: [
        1591200633422
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU704362341292020-02-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:10:22.721Z',
          retailDate: '2020-02-10',
          rdrStatus: 'H',
          soldAmount: '16991.00',
          mileage: '43323'
        },
        dealerCd: '34129',
        certificationNumber: 9360952,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU704362',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-30T15:45:53.828Z'
        }
      },
      sort: [
        1591200622721
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU760299020442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:10:13.673Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18395.00',
          mileage: '30505'
        },
        sellArea: '600',
        dealerCd: '02044',
        certificationNumber: 9477151,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU760299',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T11:21:37.911Z'
        }
      },
      sort: [
        1591200613673
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3JU538215020442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:09:53.801Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19303.94',
          mileage: '29065'
        },
        sellArea: '150',
        dealerCd: '02044',
        certificationNumber: 9486840,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3JU538215',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T12:02:55.266Z'
        }
      },
      sort: [
        1591200593801
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN5LX214844020442020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:09:36.801Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '36999.00',
          mileage: '11221'
        },
        sellArea: '500',
        dealerCd: '02044',
        certificationNumber: 9464041,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN5LX214844',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T12:43:30.057Z'
        }
      },
      sort: [
        1591200576801
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV2HD120349210682020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:06:32.839Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '22488.00',
          mileage: '33610'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9451092,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV2HD120349',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T15:24:39.336Z'
        }
      },
      sort: [
        1591200392839
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC774801291132020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:05:58.219Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14999.48',
          mileage: '24252'
        },
        sellArea: '160',
        dealerCd: '29113',
        certificationNumber: 9494346,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC774801',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:30:51.440Z'
        }
      },
      sort: [
        1591200358219
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP687194047392020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:03:11.979Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15998.00',
          mileage: '26406'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9495518,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP687194',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T16:00:20.345Z'
        }
      },
      sort: [
        1591200191979
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU774575120822020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:02:41.733Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17494.00',
          mileage: '30890'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9483971,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU774575',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:08:52.998Z'
        }
      },
      sort: [
        1591200161733
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH0HS023804043742020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:02:00.919Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31150.42',
          mileage: '39500'
        },
        sellArea: '120',
        dealerCd: '04374',
        certificationNumber: 9494612,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH0HS023804',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:08:32.432Z'
        }
      },
      sort: [
        1591200120919
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC807532120822020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:00:55.340Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13925.00',
          mileage: '26858'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9484283,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC807532',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:00:34.661Z'
        }
      },
      sort: [
        1591200055340
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW607980220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T16:00:35.998Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18790.00',
          mileage: '18367'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9483430,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW607980',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:23:50.285Z'
        }
      },
      sort: [
        1591200035998
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU429166120822020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:59:12.298Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '23852'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9484339,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU429166',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:37:44.660Z'
        }
      },
      sort: [
        1591199952298
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP689651047392020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:58:34.938Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '15588.00',
          mileage: '23065'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9495507,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP689651',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:55:48.446Z'
        }
      },
      sort: [
        1591199914938
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV3JJ749265050702020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:57:30.878Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '24890.00',
          mileage: '24445'
        },
        sellArea: '150',
        dealerCd: '05070',
        certificationNumber: 9401431,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV3JJ749265',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T19:35:45.240Z'
        }
      },
      sort: [
        1591199850878
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KW059095120822020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:57:28.617Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '24749.00',
          mileage: '23212'
        },
        sellArea: '600',
        dealerCd: '12082',
        certificationNumber: 9485103,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KW059095',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:14:45.369Z'
        }
      },
      sort: [
        1591199848617
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0HS777396341292020-02-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:55:42.276Z',
          retailDate: '2020-02-10',
          rdrStatus: 'H',
          soldAmount: '25284.00',
          mileage: '37436'
        },
        dealerCd: '34129',
        certificationNumber: 9297300,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC0HS777396',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-18T20:41:07.459Z'
        }
      },
      sort: [
        1591199742276
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB9GU237085290622020-01-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:55:41.920Z',
          retailDate: '2020-01-25',
          rdrStatus: 'H',
          soldAmount: '21969.00',
          mileage: '36127'
        },
        dealerCd: '29062',
        certificationNumber: 9495498,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB9GU237085',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:52:03.837Z'
        }
      },
      sort: [
        1591199741920
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC5LS037934120822020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:54:11.119Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '29399.00',
          mileage: '18415'
        },
        dealerCd: '12082',
        certificationNumber: 9345262,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC5LS037934',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-20T20:34:49.912Z'
        }
      },
      sort: [
        1591199651119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV8HY173756047392020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:52:35.940Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '12998.00',
          mileage: '41066'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9488333,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV8HY173756',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:55:20.579Z'
        }
      },
      sort: [
        1591199555940
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HJ704881120822020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:52:02.577Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '37949'
        },
        dealerCd: '12082',
        certificationNumber: 9364941,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HJ704881',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-31T23:48:51.872Z'
        }
      },
      sort: [
        1591199522577
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC5HS811252047392020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:51:41.217Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23890.00',
          mileage: '41537'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9404069,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC5HS811252',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-01T18:10:48.389Z'
        }
      },
      sort: [
        1591199501217
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW593412201142020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:51:36.475Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '26011'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9461923,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW593412',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T15:09:47.004Z'
        }
      },
      sort: [
        1591199496475
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV5HD103478047392020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:50:45.096Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20498.00',
          mileage: '25596'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9474545,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV5HD103478',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:54:46.858Z'
        }
      },
      sort: [
        1591199445096
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC841433120822020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:50:30.677Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '39991'
        },
        sellArea: '220',
        dealerCd: '12082',
        certificationNumber: 9467322,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC841433',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:59:04.138Z'
        }
      },
      sort: [
        1591199430677
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE0K3041008047392020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:49:44.156Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19682.00',
          mileage: '25051'
        },
        sellArea: '110',
        dealerCd: '04739',
        certificationNumber: 9490447,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE0K3041008',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T16:44:37.663Z'
        }
      },
      sort: [
        1591199384156
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV6HJ708211120822020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:49:03.216Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '23975'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9488330,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV6HJ708211',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:54:52.793Z'
        }
      },
      sort: [
        1591199343216
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU776310120822020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:47:12.075Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '17494.00',
          mileage: '42014'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9488327,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU776310',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:51:51.409Z'
        }
      },
      sort: [
        1591199232075
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP627428047372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:45:45.734Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13499.00',
          mileage: '30402'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9484471,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP627428',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:33:00.276Z'
        }
      },
      sort: [
        1591199145734
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU788551047372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:45:16.413Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18998.00',
          mileage: '19222'
        },
        sellArea: '600',
        dealerCd: '04737',
        certificationNumber: 9468745,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU788551',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:20:10.485Z'
        }
      },
      sort: [
        1591199116413
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP8H3005410047372020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:44:55.213Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22824.00',
          mileage: '21877'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9415036,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP8H3005410',
        model: {
          modelDescription: 'PRIUS PRIME ADVANCED',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1239',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T14:29:53.810Z'
        }
      },
      sort: [
        1591199095213
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZZRFH7HS212580047372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:44:22.995Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23400.00',
          mileage: '51109'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9491726,
        makeCode: 'TOYOTA',
        vin: '5TDZZRFH7HS212580',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6947',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:51:55.896Z'
        }
      },
      sort: [
        1591199062995
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKKRFHXGS132050410572020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:44:09.415Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24989.03',
          mileage: '47936'
        },
        sellArea: '600',
        dealerCd: '41057',
        certificationNumber: 9493901,
        makeCode: 'TOYOTA',
        vin: '5TDKKRFHXGS132050',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:38:39.676Z'
        }
      },
      sort: [
        1591199049415
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1HC907407047372020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:43:51.690Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '12998.00',
          mileage: '42664'
        },
        sellArea: '110',
        dealerCd: '04737',
        certificationNumber: 9485198,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1HC907407',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:50:39.349Z'
        }
      },
      sort: [
        1591199031690
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7HM067021120822020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:41:31.754Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '28000.00',
          mileage: '40733'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9426116,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7HM067021',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T21:18:23.919Z'
        }
      },
      sort: [
        1591198891754
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW587793120822020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:39:26.792Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '19994.00',
          mileage: '43650'
        },
        dealerCd: '12082',
        certificationNumber: 9366132,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW587793',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-01T22:14:51.770Z'
        }
      },
      sort: [
        1591198766792
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KC009613050702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:37:37.172Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24701.00',
          mileage: '18597'
        },
        dealerCd: '05070',
        certificationNumber: 9387337,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KC009613',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-19T15:45:53.619Z'
        }
      },
      sort: [
        1591198657172
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU502465240802020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:37:31.772Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23899.00',
          mileage: '11100'
        },
        sellArea: '230',
        dealerCd: '24080',
        certificationNumber: 9495350,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU502465',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:52:39.762Z'
        }
      },
      sort: [
        1591198651772
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU733955120822020-05-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:37:24.353Z',
          retailDate: '2020-05-13',
          rdrStatus: 'H',
          soldAmount: '15772.08',
          mileage: '43038'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9418515,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU733955',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T16:13:09.152Z'
        }
      },
      sort: [
        1591198644353
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ117198190052020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:34:07.998Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19989.00',
          mileage: '27086'
        },
        sellArea: '800',
        dealerCd: '19005',
        certificationNumber: 9431741,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ117198',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T15:10:41.268Z'
        }
      },
      sort: [
        1591198447998
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DC5FS641380120822020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:33:59.296Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '60384'
        },
        sellArea: '210',
        dealerCd: '12082',
        certificationNumber: 9484355,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DC5FS641380',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:43:30.713Z'
        }
      },
      sort: [
        1591198439296
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXKW031158190262020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:33:39.199Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '23989.00',
          mileage: '31403'
        },
        sellArea: '500',
        dealerCd: '19026',
        certificationNumber: 9470695,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXKW031158',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T16:01:07.867Z'
        }
      },
      sort: [
        1591198419199
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE0LJ008465341292020-02-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:32:05.099Z',
          retailDate: '2020-02-03',
          rdrStatus: 'H',
          soldAmount: '19905.00',
          mileage: '4692'
        },
        dealerCd: '34129',
        certificationNumber: 9360899,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE0LJ008465',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-30T15:23:41.652Z'
        }
      },
      sort: [
        1591198325099
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMDZ5BN3HM028770121352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:31:07.938Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26499.00',
          mileage: '48135'
        },
        dealerCd: '12135',
        certificationNumber: 9366174,
        makeCode: 'TOYOTA',
        vin: '3TMDZ5BN3HM028770',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7570',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-01T23:17:12.821Z'
        }
      },
      sort: [
        1591198267938
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH7HS030507220302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:30:52.799Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '33000.00',
          mileage: '44080'
        },
        sellArea: '210',
        dealerCd: '22030',
        certificationNumber: 9487173,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH7HS030507',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:19:14.870Z'
        }
      },
      sort: [
        1591198252799
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA16H9703413190262020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:29:22.999Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '20376.00',
          mileage: '26040'
        },
        sellArea: '170',
        dealerCd: '19026',
        certificationNumber: 9443811,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA16H9703413',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6253',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T15:01:29.021Z'
        }
      },
      sort: [
        1591198162999
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU761731190262020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:28:06.019Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '14822.00',
          mileage: '38105'
        },
        sellArea: '800',
        dealerCd: '19026',
        certificationNumber: 9460864,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU761731',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:57:58.823Z'
        }
      },
      sort: [
        1591198086019
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3J5533693201262020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:28:01.199Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '31998.00',
          mileage: '45769'
        },
        sellArea: '170',
        dealerCd: '20126',
        certificationNumber: 9495433,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3J5533693',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:22:33.837Z'
        }
      },
      sort: [
        1591198081199
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP604393043532020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:24:15.579Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14422.05',
          mileage: '54322'
        },
        sellArea: '110',
        dealerCd: '04353',
        certificationNumber: 9492115,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP604393',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:34:00.571Z'
        }
      },
      sort: [
        1591197855579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXLC085093140152020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:22:32.679Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28310.00',
          mileage: '6951'
        },
        sellArea: '500',
        dealerCd: '14015',
        certificationNumber: 9485446,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXLC085093',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:53:20.451Z'
        }
      },
      sort: [
        1591197752679
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRX5GN3KX152138043532020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:22:32.029Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23772.00',
          mileage: '21150'
        },
        sellArea: '110',
        dealerCd: '04353',
        certificationNumber: 9483781,
        makeCode: 'TOYOTA',
        vin: '5TFRX5GN3KX152138',
        model: {
          modelDescription: 'TACOMA SR 4X2 ACCESS CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7162',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:48:58.999Z'
        }
      },
      sort: [
        1591197752029
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV9JY310743470322020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:22:25.928Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '12791.00',
          mileage: '13952'
        },
        sellArea: '800',
        dealerCd: '47032',
        certificationNumber: 9429747,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV9JY310743',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T16:21:08.556Z'
        }
      },
      sort: [
        1591197745928
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7HW570583190262020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:22:25.620Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18944.00',
          mileage: '44425'
        },
        sellArea: '160',
        dealerCd: '19026',
        certificationNumber: 9428712,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7HW570583',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T18:12:49.247Z'
        }
      },
      sort: [
        1591197745620
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU295058370832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:20:40.139Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '20233'
        },
        dealerCd: '37083',
        certificationNumber: 9333956,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU295058',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-13T16:54:25.219Z'
        }
      },
      sort: [
        1591197640139
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2EC003416370832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:20:03.879Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '15274.00',
          mileage: '26429'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9491645,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2EC003416',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:35:13.146Z'
        }
      },
      sort: [
        1591197603879
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW690122201262020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:19:01.480Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '19798.00',
          mileage: '35811'
        },
        sellArea: '170',
        dealerCd: '20126',
        certificationNumber: 9495408,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW690122',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T15:15:23.818Z'
        }
      },
      sort: [
        1591197541480
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH7HS372131190262020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:17:19.599Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '31828.00',
          mileage: '25879'
        },
        sellArea: '800',
        dealerCd: '19026',
        certificationNumber: 9479211,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH7HS372131',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T17:25:18.222Z'
        }
      },
      sort: [
        1591197439599
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0FW294195190262020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:16:56.479Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '14443.00',
          mileage: '64025'
        },
        sellArea: '160',
        dealerCd: '19026',
        certificationNumber: 9411361,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0FW294195',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T17:15:55.858Z'
        }
      },
      sort: [
        1591197416479
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU688559370832020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:15:33.560Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '20980.00',
          mileage: '28529'
        },
        sellArea: '160',
        dealerCd: '37083',
        certificationNumber: 9426719,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU688559',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T14:06:33.905Z'
        }
      },
      sort: [
        1591197333560
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB34G1122323045282020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:14:27.159Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '14688.00',
          mileage: '48491'
        },
        sellArea: '110',
        dealerCd: '04528',
        certificationNumber: 9432405,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB34G1122323',
        model: {
          modelDescription: 'PRIUS c Persona Series',
          modelSeries: 'PRIUS C',
          modelNumber: '1208',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T21:29:50.968Z'
        }
      },
      sort: [
        1591197267159
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU3H3031127460422020-05-30V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:13:44.505Z',
          retailDate: '2020-05-30',
          rdrStatus: 'V',
          soldAmount: '24385.00',
          mileage: '11943'
        },
        sellArea: '130',
        dealerCd: '46042',
        certificationNumber: 9483717,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU3H3031127',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:23:40.375Z'
        }
      },
      sort: [
        1591197224505
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU227465046882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:07:31.818Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21682.00',
          mileage: '26550'
        },
        sellArea: '600',
        dealerCd: '04688',
        certificationNumber: 9460085,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU227465',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T20:20:58.564Z'
        }
      },
      sort: [
        1591196851818
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2HW564366311012020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:06:05.180Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23495.00',
          mileage: '13326'
        },
        sellArea: '160',
        dealerCd: '31101',
        certificationNumber: 9492478,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2HW564366',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:06:55.646Z'
        }
      },
      sort: [
        1591196765180
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1EB3GU056090410212020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:05:59.257Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19284.00',
          mileage: '72708'
        },
        sellArea: '220',
        certificationNumber: 9465743,
        dealerCd: '41021',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR XLE PREMIUM',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3508',
          modelYear: 2016
        },
        vin: '4T1BD1EB3GU056090',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:10:32.029Z'
        }
      },
      sort: [
        1591196759257
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC0HS158215480612020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:05:55.238Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29206.00',
          mileage: '18325'
        },
        sellArea: '160',
        dealerCd: '48061',
        certificationNumber: 9430438,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC0HS158215',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T15:38:16.692Z'
        }
      },
      sort: [
        1591196755238
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN3KX188422440142020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:05:54.966Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '33654.00',
          mileage: '12896'
        },
        sellArea: '170',
        dealerCd: '44014',
        certificationNumber: 9495207,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN3KX188422',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:48:21.921Z'
        }
      },
      sort: [
        1591196754966
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4GU120968046882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:05:50.926Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15782.00',
          mileage: '31251'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9466387,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4GU120968',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T23:42:04.252Z'
        }
      },
      sort: [
        1591196750926
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU443968040132020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:05:04.844Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '20613'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483293,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU443968',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:48:44.259Z'
        }
      },
      sort: [
        1591196704844
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE3LP007342480612020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:04:26.984Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15706.00',
          mileage: '14654'
        },
        sellArea: '500',
        dealerCd: '48061',
        certificationNumber: 9425321,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE3LP007342',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T15:11:46.017Z'
        }
      },
      sort: [
        1591196666984
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMNFREV4HJ714801040132020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:04:22.105Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '20892.00',
          mileage: '46537'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483256,
        makeCode: 'TOYOTA',
        vin: 'JTMNFREV4HJ714801',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:36:40.853Z'
        }
      },
      sort: [
        1591196662105
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU783816311762020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:04:08.104Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '16086'
        },
        sellArea: '160',
        dealerCd: '31176',
        certificationNumber: 9424759,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU783816',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T23:28:17.890Z'
        }
      },
      sort: [
        1591196648104
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU758754040132020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:03:37.204Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15692.00',
          mileage: '44877'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483215,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU758754',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:25:14.605Z'
        }
      },
      sort: [
        1591196617204
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU299379040132020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:02:50.352Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '14791.71',
          mileage: '27788'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483296,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU299379',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:49:40.422Z'
        }
      },
      sort: [
        1591196570352
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP682765046882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:02:45.749Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14481.00',
          mileage: '18683'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9487269,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP682765',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:50:29.902Z'
        }
      },
      sort: [
        1591196565749
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU214532046882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:02:22.884Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22481.00',
          mileage: '16056'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9484562,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU214532',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T23:30:08.489Z'
        }
      },
      sort: [
        1591196542884
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3HP681340040132020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:02:00.223Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '15992.00',
          mileage: '16462'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9487569,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3HP681340',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:30:31.796Z'
        }
      },
      sort: [
        1591196520223
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6JU093761310972020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:01:45.023Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19665.00',
          mileage: '36950'
        },
        sellArea: '500',
        dealerCd: '31097',
        certificationNumber: 9415358,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6JU093761',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T17:25:27.480Z'
        }
      },
      sort: [
        1591196505023
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU0G3023243450702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:01:40.423Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '62700'
        },
        sellArea: '800',
        dealerCd: '45070',
        certificationNumber: 9481567,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU0G3023243',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:44:50.561Z'
        }
      },
      sort: [
        1591196500423
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK7LU917513040132020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:01:10.102Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23392.00',
          mileage: '3933'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9462828,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK7LU917513',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:43:04.752Z'
        }
      },
      sort: [
        1591196470102
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2JJ210237440142020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:01:06.324Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19940.00',
          mileage: '55893'
        },
        sellArea: '170',
        dealerCd: '44014',
        certificationNumber: 9495326,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2JJ210237',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:41:39.920Z'
        }
      },
      sort: [
        1591196466324
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK7LU327487040132020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:00:30.538Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23392.00',
          mileage: '4979'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9462834,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK7LU327487',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:45:12.076Z'
        }
      },
      sort: [
        1591196430538
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU769348450702020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T15:00:09.437Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18300.00',
          mileage: '29451'
        },
        sellArea: '130',
        dealerCd: '45070',
        certificationNumber: 9488165,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU769348',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:58:17.834Z'
        }
      },
      sort: [
        1591196409437
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK7LU346167040132020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:59:45.337Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23392.00',
          mileage: '2628'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9462807,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK7LU346167',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:35:13.110Z'
        }
      },
      sort: [
        1591196385337
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV2HW366650040132020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:59:02.743Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '18742.00',
          mileage: '45252'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483286,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV2HW366650',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:46:54.101Z'
        }
      },
      sort: [
        1591196342743
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK2LU929794040132020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:58:01.564Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23392.00',
          mileage: '2364'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9462817,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK2LU929794',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:39:38.671Z'
        }
      },
      sort: [
        1591196281564
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU0J3587270041402020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:57:33.143Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20741.00',
          mileage: '13733'
        },
        dealerCd: '04140',
        certificationNumber: 9310344,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU0J3587270',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-28T18:16:36.247Z'
        }
      },
      sort: [
        1591196253143
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK5LU929790040132020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:57:21.064Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23392.00',
          mileage: '2334'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9462803,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK5LU929790',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:34:09.551Z'
        }
      },
      sort: [
        1591196241064
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2JW704180311012020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:56:52.722Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21032.61',
          mileage: '40295'
        },
        sellArea: '600',
        dealerCd: '31101',
        certificationNumber: 9492515,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2JW704180',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:17:34.564Z'
        }
      },
      sort: [
        1591196212722
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM089650040132020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:56:34.404Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '30599.00',
          mileage: '58571'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9487549,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM089650',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:26:34.946Z'
        }
      },
      sort: [
        1591196194404
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV8HJ704101040132020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:55:51.440Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '18492.00',
          mileage: '35371'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9470619,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV8HJ704101',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:36:24.641Z'
        }
      },
      sort: [
        1591196151440
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU1J3068776040132020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:55:11.461Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19492.00',
          mileage: '34295'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9448525,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU1J3068776',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T14:16:48.488Z'
        }
      },
      sort: [
        1591196111461
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU5H3537087040132020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:53:19.622Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '18792.00',
          mileage: '26186'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9475774,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU5H3537087',
        model: {
          modelDescription: 'PRIUS TWO ECO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1224',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T14:18:56.180Z'
        }
      },
      sort: [
        1591195999622
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA14H8703853270242020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:53:17.361Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22292.20',
          mileage: '17185'
        },
        sellArea: '150',
        dealerCd: '27024',
        certificationNumber: 9440825,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA14H8703853',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T17:47:50.255Z'
        }
      },
      sort: [
        1591195997361
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1FK4HU578717040132020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:52:35.901Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '20592.00',
          mileage: '33948'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9487562,
        makeCode: 'TOYOTA',
        vin: '4T1BK1FK4HU578717',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:29:02.580Z'
        }
      },
      sort: [
        1591195955901
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK9J3039473040132020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:51:53.222Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '18592.00',
          mileage: '19356'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9487233,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK9J3039473',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:36:30.329Z'
        }
      },
      sort: [
        1591195913222
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU789887040132020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:51:01.021Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16392.00',
          mileage: '35126'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483242,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU789887',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:31:20.067Z'
        }
      },
      sort: [
        1591195861021
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR6H5154552040132020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:49:52.380Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '28592.00',
          mileage: '36474'
        },
        sellArea: '110',
        dealerCd: '04013',
        certificationNumber: 9483275,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR6H5154552',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8642',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:42:44.857Z'
        }
      },
      sort: [
        1591195792380
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9K5672714410562020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:46:51.399Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '34991.00',
          mileage: '25515'
        },
        sellArea: '220',
        dealerCd: '41056',
        certificationNumber: 9492156,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9K5672714',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:50:22.055Z'
        }
      },
      sort: [
        1591195611399
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC911730201282020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:46:27.289Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15588.00',
          mileage: '28180'
        },
        sellArea: '170',
        dealerCd: '20128',
        certificationNumber: 9495331,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC911730',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T14:43:51.539Z'
        }
      },
      sort: [
        1591195587289
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH8GS301448121602020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:38:05.298Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '11567'
        },
        sellArea: '210',
        dealerCd: '12160',
        certificationNumber: 9482121,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH8GS301448',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:19:03.032Z'
        }
      },
      sort: [
        1591195085298
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8LS028208310972020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:33:22.696Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '36287.51',
          mileage: '13411'
        },
        sellArea: '600',
        dealerCd: '31097',
        certificationNumber: 9418503,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8LS028208',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T16:06:45.812Z'
        }
      },
      sort: [
        1591194802696
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK6JU004532311392020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:31:35.277Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21990.00',
          mileage: '47758'
        },
        sellArea: '160',
        dealerCd: '31139',
        certificationNumber: 9475031,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK6JU004532',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:53:43.624Z'
        }
      },
      sort: [
        1591194695277
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK1KU717226047532020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:28:59.537Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27498.00',
          mileage: '9122'
        },
        sellArea: '110',
        dealerCd: '04753',
        certificationNumber: 9440867,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK1KU717226',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T18:18:16.896Z'
        }
      },
      sort: [
        1591194539537
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU345185310972020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:26:30.876Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '22777'
        },
        sellArea: '160',
        dealerCd: '31097',
        certificationNumber: 9415338,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU345185',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T17:14:11.515Z'
        }
      },
      sort: [
        1591194390876
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN5HX109756311392020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:25:21.135Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25500.00',
          mileage: '34185'
        },
        sellArea: '160',
        dealerCd: '31139',
        certificationNumber: 9475065,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN5HX109756',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T21:11:44.854Z'
        }
      },
      sort: [
        1591194321135
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU4H3537241311712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:24:31.856Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '15819'
        },
        sellArea: '160',
        dealerCd: '31171',
        certificationNumber: 9456694,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU4H3537241',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T16:13:29.643Z'
        }
      },
      sort: [
        1591194271856
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU793718310972020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:24:15.234Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '23688.00',
          mileage: '10561'
        },
        sellArea: '160',
        dealerCd: '31097',
        certificationNumber: 9418531,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU793718',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T16:20:53.756Z'
        }
      },
      sort: [
        1591194255234
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8LS033859310972020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:19:00.555Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '36283.00',
          mileage: '17086'
        },
        sellArea: '600',
        dealerCd: '31097',
        certificationNumber: 9418542,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8LS033859',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T16:26:41.815Z'
        }
      },
      sort: [
        1591193940555
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV6HD127238340892020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:18:37.875Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22400.00',
          mileage: '19673'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9474736,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV6HD127238',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:30:43.754Z'
        }
      },
      sort: [
        1591193917875
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KC018620310972020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:16:39.793Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '27785.00',
          mileage: '13150'
        },
        sellArea: '500',
        dealerCd: '31097',
        certificationNumber: 9459972,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KC018620',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T19:06:53.662Z'
        }
      },
      sort: [
        1591193799793
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1GU254420311012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:12:21.913Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16595.00',
          mileage: '42828'
        },
        sellArea: '160',
        dealerCd: '31101',
        certificationNumber: 9409682,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1GU254420',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T15:16:32.441Z'
        }
      },
      sort: [
        1591193541913
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS388958311712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:11:56.872Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31595.00',
          mileage: '26267'
        },
        sellArea: '160',
        dealerCd: '31171',
        certificationNumber: 9418574,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS388958',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T16:45:34.881Z'
        }
      },
      sort: [
        1591193516872
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW616623180352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T14:11:29.613Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19499.00',
          mileage: '38695'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9495151,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW616623',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T13:02:24.019Z'
        }
      },
      sort: [
        1591193489613
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC835276240842020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:55:28.481Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '13991.00',
          mileage: '23225'
        },
        sellArea: '210',
        dealerCd: '24084',
        certificationNumber: 9457739,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC835276',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T18:45:14.342Z'
        }
      },
      sort: [
        1591192528481
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH2HS448810311312020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:53:32.882Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29999.00',
          mileage: '34730'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9487816,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH2HS448810',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:07:06.222Z'
        }
      },
      sort: [
        1591192412882
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFHPRAE8LP028233340892020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:53:02.623Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17848.13',
          mileage: '2101'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9439053,
        makeCode: 'TOYOTA',
        vin: '5YFHPRAE8LP028233',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T13:29:50.937Z'
        }
      },
      sort: [
        1591192382623
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0HW630947280242020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:52:23.403Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16572.00',
          mileage: '55638'
        },
        sellArea: '170',
        dealerCd: '28024',
        certificationNumber: 9476158,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0HW630947',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:06:14.097Z'
        }
      },
      sort: [
        1591192343403
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5JU676029371502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:52:18.722Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18776.00',
          mileage: '12923'
        },
        dealerCd: '37150',
        certificationNumber: 9352996,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5JU676029',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-24T20:53:25.689Z'
        }
      },
      sort: [
        1591192338722
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDZ5BN4KX045790371502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:51:31.982Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '33338.00',
          mileage: '4378'
        },
        sellArea: '800',
        dealerCd: '37150',
        certificationNumber: 9420934,
        makeCode: 'TOYOTA',
        vin: '5TFDZ5BN4KX045790',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DBL CAB LONG BED',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7566',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T23:33:41.261Z'
        }
      },
      sort: [
        1591192291982
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DC0GS753943371322020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:48:24.063Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23390.00',
          mileage: '35810'
        },
        dealerCd: '37132',
        certificationNumber: 9387602,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DC0GS753943',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-19T17:56:18.558Z'
        }
      },
      sort: [
        1591192104063
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1JW813309240632020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:47:09.044Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19801.00',
          mileage: '36229'
        },
        dealerCd: '24063',
        certificationNumber: 9336201,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1JW813309',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-14T16:47:10.998Z'
        }
      },
      sort: [
        1591192029044
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREVXHJ124409480212020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:46:27.002Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '19988.00',
          mileage: '33744'
        },
        sellArea: '210',
        dealerCd: '48021',
        certificationNumber: 9438670,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREVXHJ124409',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T16:38:49.065Z'
        }
      },
      sort: [
        1591191987002
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8HM078688180352020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:45:55.282Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '28500.00',
          mileage: '54652'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9472396,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8HM078688',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T13:32:03.188Z'
        }
      },
      sort: [
        1591191955282
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC899906220382020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:44:40.524Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '13245.00',
          mileage: '9252'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9494628,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC899906',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:17:17.922Z'
        }
      },
      sort: [
        1591191880524
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE8LJ009380311012020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:41:19.444Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20345.00',
          mileage: '1261'
        },
        sellArea: '160',
        dealerCd: '31101',
        certificationNumber: 9473541,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE8LJ009380',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T22:01:05.732Z'
        }
      },
      sort: [
        1591191679444
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC838490220382020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:40:58.184Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '13200.00',
          mileage: '21719'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9453648,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC838490',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T18:12:55.838Z'
        }
      },
      sort: [
        1591191658184
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV3HJ120587220382020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:40:08.524Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18919.00',
          mileage: '38079'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9409780,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV3HJ120587',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T16:00:39.924Z'
        }
      },
      sort: [
        1591191608524
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV4HW613665220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:39:07.745Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18597.00',
          mileage: '44627'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9433109,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV4HW613665',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T18:06:34.125Z'
        }
      },
      sort: [
        1591191547745
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC769588311762020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:38:57.545Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '14499.00',
          mileage: '38329'
        },
        sellArea: '160',
        dealerCd: '31176',
        certificationNumber: 9493637,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC769588',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T13:42:04.925Z'
        }
      },
      sort: [
        1591191537545
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBW5F11HX647594220382020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:38:12.826Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '34695.00',
          mileage: '43661'
        },
        sellArea: '230',
        dealerCd: '22038',
        certificationNumber: 9487929,
        makeCode: 'TOYOTA',
        vin: '5TFBW5F11HX647594',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DBL CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8354',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:46:07.496Z'
        }
      },
      sort: [
        1591191492826
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS376783220382020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:36:37.698Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '26707.04',
          mileage: '37637'
        },
        dealerCd: '22038',
        certificationNumber: 9391056,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS376783',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T19:37:32.560Z'
        }
      },
      sort: [
        1591191397698
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS404011220382020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:35:56.485Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '29317.00',
          mileage: '32828'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9432196,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS404011',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T19:17:59.311Z'
        }
      },
      sort: [
        1591191356485
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS374976220382020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:35:01.806Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '26419.00',
          mileage: '35426'
        },
        dealerCd: '22038',
        certificationNumber: 9361997,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS374976',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-30T22:26:25.022Z'
        }
      },
      sort: [
        1591191301806
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH9HS418946220382020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:34:19.646Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '29995.00',
          mileage: '36374'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9476398,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH9HS418946',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:30:31.152Z'
        }
      },
      sort: [
        1591191259646
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F13HX626958220382020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:33:26.795Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '33597.00',
          mileage: '37183'
        },
        sellArea: '160',
        dealerCd: '22038',
        certificationNumber: 9411693,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F13HX626958',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T20:37:31.631Z'
        }
      },
      sort: [
        1591191206795
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB1GU199822160512020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:32:37.426Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19672.00',
          mileage: '52971'
        },
        sellArea: '500',
        dealerCd: '16051',
        certificationNumber: 9451908,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB1GU199822',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T12:28:38.903Z'
        }
      },
      sort: [
        1591191157426
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP638489220382020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:32:36.105Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '12495.00',
          mileage: '52871'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9474225,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP638489',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:33:55.225Z'
        }
      },
      sort: [
        1591191156105
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV1HW315038341262020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:32:27.204Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '47886'
        },
        sellArea: '220',
        dealerCd: '34126',
        certificationNumber: 9466873,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV1HW315038',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T15:29:50.554Z'
        }
      },
      sort: [
        1591191147204
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV1HD207736341262020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:31:59.643Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22528.00',
          mileage: '36152'
        },
        sellArea: '800',
        dealerCd: '34126',
        certificationNumber: 9464640,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV1HD207736',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T19:00:43.754Z'
        }
      },
      sort: [
        1591191119643
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU720913341262020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:31:31.565Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14826.00',
          mileage: '36461'
        },
        sellArea: '160',
        dealerCd: '34126',
        certificationNumber: 9418166,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU720913',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T13:23:18.535Z'
        }
      },
      sort: [
        1591191091565
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV5HJ132453160512020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:31:03.505Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '23715'
        },
        dealerCd: '16051',
        certificationNumber: 9385958,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV5HJ132453',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T18:18:15.176Z'
        }
      },
      sort: [
        1591191063505
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU396623341262020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:30:50.924Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '31767'
        },
        sellArea: '220',
        dealerCd: '34126',
        certificationNumber: 9428383,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU396623',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T15:41:27.481Z'
        }
      },
      sort: [
        1591191050924
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDCRFH7GS017430220382020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:30:15.022Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '25919.00',
          mileage: '67245'
        },
        dealerCd: '22038',
        certificationNumber: 9320973,
        makeCode: 'TOYOTA',
        vin: '5TDDCRFH7GS017430',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-03T16:21:24.846Z'
        }
      },
      sort: [
        1591191015022
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU772488341262020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:30:00.945Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14500.00',
          mileage: '49135'
        },
        sellArea: '220',
        dealerCd: '34126',
        certificationNumber: 9456432,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU772488',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T13:09:36.485Z'
        }
      },
      sort: [
        1591191000945
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC894361160512020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:29:50.144Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16097.00',
          mileage: '22529'
        },
        sellArea: '220',
        dealerCd: '16051',
        certificationNumber: 9450389,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC894361',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T18:59:28.282Z'
        }
      },
      sort: [
        1591190990144
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXH5466343220382020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:29:29.844Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '34219.00',
          mileage: '47167'
        },
        dealerCd: '22038',
        certificationNumber: 9378380,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXH5466343',
        model: {
          modelDescription: '4RUNNER 4X4 TRD PRO V6',
          modelSeries: '4RUNNER',
          modelNumber: '8674',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-12T20:34:52.845Z'
        }
      },
      sort: [
        1591190969844
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9EU730944340782020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:28:52.123Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '10439.97',
          mileage: '83926'
        },
        sellArea: '220',
        dealerCd: '34078',
        certificationNumber: 9484577,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9EU730944',
        model: {
          modelDescription: 'CAMRY 2014.5 XLE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T23:41:16.655Z'
        }
      },
      sort: [
        1591190932123
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7HW571684220382020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:28:35.684Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '18919.00',
          mileage: '42545'
        },
        sellArea: '220',
        dealerCd: '22038',
        certificationNumber: 9409809,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7HW571684',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T16:14:55.211Z'
        }
      },
      sort: [
        1591190915684
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU059316340782020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:28:31.688Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15200.00',
          mileage: '40080'
        },
        sellArea: '220',
        dealerCd: '34078',
        certificationNumber: 9419732,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU059316',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T14:24:23.451Z'
        }
      },
      sort: [
        1591190911688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU354591220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:27:37.047Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15050.00',
          mileage: '20849'
        },
        dealerCd: '22038',
        certificationNumber: 9391334,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU354591',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T21:44:41.133Z'
        }
      },
      sort: [
        1591190857047
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW648423210682020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:26:27.740Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '24950.00',
          mileage: '11792'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9483089,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW648423',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T13:27:21.103Z'
        }
      },
      sort: [
        1591190787740
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0HM093850220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:25:42.367Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '37224'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9474267,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0HM093850',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:55:14.392Z'
        }
      },
      sort: [
        1591190742367
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HJ151916210682020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:25:26.707Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18396.57',
          mileage: '29293'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9468251,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HJ151916',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T14:46:54.142Z'
        }
      },
      sort: [
        1591190726707
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3HW663529210682020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:24:40.766Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26502.00',
          mileage: '3600'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9452050,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3HW663529',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T14:40:05.599Z'
        }
      },
      sort: [
        1591190680766
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK9HU692815220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:24:12.828Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16297.00',
          mileage: '39202'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9409824,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK9HU692815',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T16:28:46.714Z'
        }
      },
      sort: [
        1591190652828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1EC031224340782020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:24:09.828Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '11857.00',
          mileage: '65827'
        },
        sellArea: '800',
        dealerCd: '34078',
        certificationNumber: 9465709,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1EC031224',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1863',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:51:35.467Z'
        }
      },
      sort: [
        1591190649828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV2HW641768340782020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:23:18.688Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '18277.08',
          mileage: '30852'
        },
        sellArea: '220',
        dealerCd: '34078',
        certificationNumber: 9477725,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV2HW641768',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:33:16.261Z'
        }
      },
      sort: [
        1591190598688
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU361291220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:23:07.627Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15495.00',
          mileage: '39670'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9474241,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU361291',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:40:59.512Z'
        }
      },
      sort: [
        1591190587627
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM089158180352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:22:44.708Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32881.00',
          mileage: '35696'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9494027,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM089158',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:33:43.440Z'
        }
      },
      sort: [
        1591190564708
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREVXHJ144234340782020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:22:34.447Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23549.06',
          mileage: '13432'
        },
        sellArea: '220',
        dealerCd: '34078',
        certificationNumber: 9477717,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREVXHJ144234',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:29:56.829Z'
        }
      },
      sort: [
        1591190554447
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS460967220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:22:05.248Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '29563.38',
          mileage: '27497'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9442935,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS460967',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-10T15:41:12.844Z'
        }
      },
      sort: [
        1591190525248
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV5KW003497180352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:21:33.028Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29950.00',
          mileage: '16224'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9472415,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV5KW003497',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T13:51:11.819Z'
        }
      },
      sort: [
        1591190493028
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8JJ251553220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:21:08.289Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22419.00',
          mileage: '12307'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9398994,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8JJ251553',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T17:26:07.693Z'
        }
      },
      sort: [
        1591190468289
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW651029210682020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:19:05.069Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20279.00',
          mileage: '32392'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9451104,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW651029',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T15:31:45.882Z'
        }
      },
      sort: [
        1591190345069
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1L5740313060432020-05-15V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:18:26.218Z',
          retailDate: '2020-05-15',
          rdrStatus: 'V',
          soldAmount: '39493.00',
          mileage: '7207'
        },
        sellArea: '160',
        dealerCd: '06043',
        certificationNumber: 9468923,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1L5740313',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-ROAD V6',
          modelSeries: '4RUNNER',
          modelNumber: '8670',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:37:56.647Z'
        }
      },
      sort: [
        1591190306218
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU743823220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:17:55.209Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16997.00',
          mileage: '28384'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9419779,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU743823',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T14:48:52.941Z'
        }
      },
      sort: [
        1591190275209
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE2LJ025327291032020-05-30V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:17:07.798Z',
          retailDate: '2020-05-30',
          rdrStatus: 'V',
          soldAmount: '16900.00',
          mileage: '18097'
        },
        sellArea: '500',
        dealerCd: '29103',
        certificationNumber: 9476486,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE2LJ025327',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:03:10.996Z'
        }
      },
      sort: [
        1591190227798
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV1HW595473210682020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:16:53.029Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '23994.00',
          mileage: '35239'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9468224,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV1HW595473',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T14:30:22.753Z'
        }
      },
      sort: [
        1591190213029
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREVXHJ125737240672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:16:41.469Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16775.00',
          mileage: '37396'
        },
        sellArea: '230',
        dealerCd: '24067',
        certificationNumber: 9474745,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREVXHJ125737',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:33:31.710Z'
        }
      },
      sort: [
        1591190201469
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3JU091398220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:16:03.349Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17000.00',
          mileage: '44286'
        },
        sellArea: '600',
        dealerCd: '22038',
        certificationNumber: 9488086,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3JU091398',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:28:20.682Z'
        }
      },
      sort: [
        1591190163349
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN9LX214152310882020-06-01V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:16:00.931Z',
          retailDate: '2020-06-01',
          rdrStatus: 'V',
          soldAmount: '32495.00',
          mileage: '3985'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9492009,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN9LX214152',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:02:27.389Z'
        }
      },
      sort: [
        1591190160931
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1L11AK1LU869764310882020-06-01V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:15:17.553Z',
          retailDate: '2020-06-01',
          rdrStatus: 'V',
          soldAmount: '22000.00',
          mileage: '2516'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9448644,
        makeCode: 'TOYOTA',
        vin: '4T1L11AK1LU869764',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T15:51:24.657Z'
        }
      },
      sort: [
        1591190117553
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F17HX586311220382020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:15:08.270Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '30995.00',
          mileage: '28785'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9474251,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F17HX586311',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:47:24.910Z'
        }
      },
      sort: [
        1591190108270
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV9HJ124100240672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:13:26.071Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17975.00',
          mileage: '32290'
        },
        sellArea: '220',
        dealerCd: '24067',
        certificationNumber: 9494288,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV9HJ124100',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:08:33.788Z'
        }
      },
      sort: [
        1591190006071
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8HW615869220382020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:09:59.691Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '17995.00',
          mileage: '51110'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9475791,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8HW615869',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T14:33:46.735Z'
        }
      },
      sort: [
        1591189799691
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU722709121022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:09:19.370Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18733.11',
          mileage: '38431'
        },
        sellArea: '500',
        dealerCd: '12102',
        certificationNumber: 9419254,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU722709',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T22:20:28.667Z'
        }
      },
      sort: [
        1591189759370
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3JU021965220382020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:08:51.110Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '15995.00',
          mileage: '39870'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9474291,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3JU021965',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T15:02:48.376Z'
        }
      },
      sort: [
        1591189731110
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1HC810272290992020-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:08:42.671Z',
          retailDate: '2020-06-03',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '18954'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9448720,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1HC810272',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T16:39:19.260Z'
        }
      },
      sort: [
        1591189722671
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV7EW170325290992020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:07:58.771Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16912.00',
          mileage: '50118'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9429653,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV7EW170325',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-21T15:19:31.595Z'
        }
      },
      sort: [
        1591189678771
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HD205593220382020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:07:54.811Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '21597.00',
          mileage: '19080'
        },
        sellArea: '220',
        dealerCd: '22038',
        certificationNumber: 9435124,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HD205593',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T20:02:33.404Z'
        }
      },
      sort: [
        1591189674811
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS508758290992020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:06:59.211Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30996.00',
          mileage: '33999'
        },
        dealerCd: '29099',
        certificationNumber: 9370965,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS508758',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-06T17:41:01.659Z'
        }
      },
      sort: [
        1591189619211
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KW060247220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:06:42.730Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23495.00',
          mileage: '23038'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9493657,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KW060247',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:00:16.665Z'
        }
      },
      sort: [
        1591189602730
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F18FX468079290992020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:06:22.651Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32356.00',
          mileage: '38791'
        },
        dealerCd: '29099',
        certificationNumber: 9376802,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F18FX468079',
        model: {
          modelDescription: 'TUNDRA 4X4 LTD CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-11T18:59:14.628Z'
        }
      },
      sort: [
        1591189582651
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU340806290992020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:05:40.591Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18420.00',
          mileage: '30071'
        },
        dealerCd: '29099',
        certificationNumber: 9368227,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU340806',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T18:40:05.428Z'
        }
      },
      sort: [
        1591189540591
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F19HX632377290992020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:04:54.432Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31785.00',
          mileage: '72818'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9416124,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F19HX632377',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 EDITION CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T23:18:33.705Z'
        }
      },
      sort: [
        1591189494432
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3JFREV6HW597478371492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:04:37.350Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23113.00',
          mileage: '14247'
        },
        dealerCd: '37149',
        certificationNumber: 9357403,
        makeCode: 'TOYOTA',
        vin: '2T3JFREV6HW597478',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-28T17:02:40.481Z'
        }
      },
      sort: [
        1591189477350
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV7HW617511290992020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:04:08.152Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24995.00',
          mileage: '26496'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9487167,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV7HW617511',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:17:10.870Z'
        }
      },
      sort: [
        1591189448152
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU713496060362020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:03:28.252Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '18477.00',
          mileage: '20892'
        },
        sellArea: '160',
        dealerCd: '06036',
        certificationNumber: 9409972,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU713496',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T17:56:01.773Z'
        }
      },
      sort: [
        1591189408252
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK8JU058057290992020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:03:26.612Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21757.90',
          mileage: '28603'
        },
        sellArea: '160',
        dealerCd: '29099',
        certificationNumber: 9468902,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK8JU058057',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T19:29:43.100Z'
        }
      },
      sort: [
        1591189406612
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE5LJ024088201282020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:02:55.332Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18744.00',
          mileage: '12754'
        },
        dealerCd: '20128',
        certificationNumber: 9391110,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE5LJ024088',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T19:55:06.120Z'
        }
      },
      sort: [
        1591189375332
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1JW807915290992020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T13:02:46.492Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19965.00',
          mileage: '32556'
        },
        dealerCd: '29099',
        certificationNumber: 9382761,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1JW807915',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T16:45:33.178Z'
        }
      },
      sort: [
        1591189366492
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB5GU241702220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:58:44.812Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18419.00',
          mileage: '40757'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9467317,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB5GU241702',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:56:45.533Z'
        }
      },
      sort: [
        1591189124812
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW558649201142020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:57:24.214Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '20300.00',
          mileage: '32811'
        },
        dealerCd: '20114',
        certificationNumber: 9378169,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW558649',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-12T19:19:11.375Z'
        }
      },
      sort: [
        1591189044214
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7HJ702600480652020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:57:10.793Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20646.81',
          mileage: '33533'
        },
        sellArea: '210',
        dealerCd: '48065',
        certificationNumber: 9467527,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7HJ702600',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T20:56:45.807Z'
        }
      },
      sort: [
        1591189030793
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW582261201142020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:56:27.253Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20300.00',
          mileage: '37938'
        },
        dealerCd: '20114',
        certificationNumber: 9370504,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW582261',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-06T14:17:15.097Z'
        }
      },
      sort: [
        1591188987253
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS429212220382020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:55:56.113Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '26628.70',
          mileage: '41197'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9451227,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS429212',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T16:26:11.515Z'
        }
      },
      sort: [
        1591188956113
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC768001220382020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:54:51.333Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '13819.00',
          mileage: '27278'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9453697,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC768001',
        model: {
          modelDescription: 'COROLLA XLE',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T18:31:29.150Z'
        }
      },
      sort: [
        1591188891333
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH6HS384083220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:53:24.993Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '25419.00',
          mileage: '29208'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9401654,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH6HS384083',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T20:41:42.648Z'
        }
      },
      sort: [
        1591188804993
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB5JU278577220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:51:55.814Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22597.00',
          mileage: '15381'
        },
        sellArea: '210',
        dealerCd: '22038',
        certificationNumber: 9452426,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB5JU278577',
        model: {
          modelDescription: 'AVALON 4-DR XLE',
          modelSeries: 'AVALON',
          modelNumber: '3544',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T18:47:19.542Z'
        }
      },
      sort: [
        1591188715814
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7HD078034220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:50:41.254Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21619.00',
          mileage: '44936'
        },
        sellArea: '160',
        dealerCd: '22038',
        certificationNumber: 9399438,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7HD078034',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T20:03:58.888Z'
        }
      },
      sort: [
        1591188641254
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F1XHX655363220382020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:49:19.754Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '31919.00',
          mileage: '33734'
        },
        sellArea: '500',
        dealerCd: '22038',
        certificationNumber: 9393241,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F1XHX655363',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T15:45:01.197Z'
        }
      },
      sort: [
        1591188559754
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HD208304201142020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:48:43.955Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '14415'
        },
        sellArea: '170',
        dealerCd: '20114',
        certificationNumber: 9479008,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HD208304',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T15:02:09.501Z'
        }
      },
      sort: [
        1591188523955
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM072577043642020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:46:46.076Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '29205.00',
          mileage: '45149'
        },
        sellArea: '120',
        dealerCd: '04364',
        certificationNumber: 9495123,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM072577',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:40:39.591Z'
        }
      },
      sort: [
        1591188406076
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3FP316296190472020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:45:31.635Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13000.0000',
          mileage: '64144'
        },
        sellArea: '500',
        dealerCd: '19047',
        certificationNumber: 9470560,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3FP316296',
        model: {
          modelDescription: 'COROLLA S GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1862',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:14:15.213Z'
        }
      },
      sort: [
        1591188331635
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0KU785693200962020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:43:08.616Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '2819'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9428359,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0KU785693',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T15:28:59.634Z'
        }
      },
      sort: [
        1591188188616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2HS769980060452020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:39:12.517Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '22943.39',
          mileage: '54035'
        },
        sellArea: '160',
        dealerCd: '06045',
        certificationNumber: 9493659,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2HS769980',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:02:11.565Z'
        }
      },
      sort: [
        1591187952517
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN7KM109263043642020-03-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:38:37.457Z',
          retailDate: '2020-03-26',
          rdrStatus: 'H',
          soldAmount: '27793.00',
          mileage: '1647'
        },
        dealerCd: '04364',
        certificationNumber: 9352763,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN7KM109263',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-24T19:36:05.937Z'
        }
      },
      sort: [
        1591187917457
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV7HJ123086043642020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:33:15.377Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20788.00',
          mileage: '6380'
        },
        sellArea: '120',
        dealerCd: '04364',
        certificationNumber: 9441488,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV7HJ123086',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-07T20:28:11.603Z'
        }
      },
      sort: [
        1591187595377
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU239618180362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:31:40.356Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17246.67',
          mileage: '20132'
        },
        sellArea: '170',
        dealerCd: '18036',
        certificationNumber: 9492036,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU239618',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:10:58.427Z'
        }
      },
      sort: [
        1591187500356
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0FC441384311422019-06-03H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:27:11.538Z',
          retailDate: '2019-06-03',
          rdrStatus: 'H',
          soldAmount: '13000.00',
          mileage: '26310'
        },
        sellArea: '160',
        dealerCd: '31142',
        certificationNumber: 9495110,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0FC441384',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T12:23:43.909Z'
        }
      },
      sort: [
        1591187231538
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3KC006961371722020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:17:49.941Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '28543.00',
          mileage: '15520'
        },
        sellArea: '600',
        dealerCd: '37172',
        certificationNumber: 9423554,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3KC006961',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T14:00:24.878Z'
        }
      },
      sort: [
        1591186669941
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC935051371722020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:17:10.602Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16791.00',
          mileage: '16136'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9475781,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC935051',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T14:25:03.121Z'
        }
      },
      sort: [
        1591186630602
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS375453371722020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:16:27.700Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '32572.00',
          mileage: '15268'
        },
        sellArea: '160',
        dealerCd: '37172',
        certificationNumber: 9477430,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS375453',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T15:28:51.618Z'
        }
      },
      sort: [
        1591186587700
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV0HJ133484371722020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:15:18.439Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '24991.00',
          mileage: '20689'
        },
        sellArea: '800',
        dealerCd: '37172',
        certificationNumber: 9391993,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV0HJ133484',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-22T15:09:36.692Z'
        }
      },
      sort: [
        1591186518439
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5517973371722020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:14:06.420Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '34991.00',
          mileage: '11779'
        },
        dealerCd: '37172',
        certificationNumber: 9182230,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5517973',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-02T20:53:06.468Z'
        }
      },
      sort: [
        1591186446420
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21FB5KU002707121442020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T12:03:32.630Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30458.00',
          mileage: '19421'
        },
        sellArea: '210',
        dealerCd: '12144',
        certificationNumber: 9483910,
        makeCode: 'TOYOTA',
        vin: '4T1B21FB5KU002707',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR XSE',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3507',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:46:06.657Z'
        }
      },
      sort: [
        1591185812630
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8HS837425121442020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T11:54:55.262Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24998.00',
          mileage: '32940'
        },
        sellArea: '210',
        dealerCd: '12144',
        certificationNumber: 9442856,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8HS837425',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-10T14:29:27.812Z'
        }
      },
      sort: [
        1591185295262
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC3KS017721480322020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T04:58:11.105Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24350.00',
          mileage: '19812'
        },
        sellArea: '500',
        dealerCd: '48032',
        certificationNumber: 9452515,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC3KS017721',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T19:48:27.815Z'
        }
      },
      sort: [
        1591160291105
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3KC003977480322020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T04:56:09.403Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '21131'
        },
        sellArea: '150',
        dealerCd: '48032',
        certificationNumber: 9471003,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3KC003977',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T17:33:14.700Z'
        }
      },
      sort: [
        1591160169403
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6JU608922480322020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T04:52:51.481Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18200.00',
          mileage: '4588'
        },
        sellArea: '600',
        dealerCd: '48032',
        certificationNumber: 9453731,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6JU608922',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T18:56:54.372Z'
        }
      },
      sort: [
        1591159971481
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU252815480322020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T04:50:41.159Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '6627'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9450647,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU252815',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T21:39:25.206Z'
        }
      },
      sort: [
        1591159841159
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3NFREV8HW374084042822020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T04:10:59.051Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22998.00',
          mileage: '21613'
        },
        sellArea: '600',
        dealerCd: '04282',
        certificationNumber: 9484387,
        makeCode: 'TOYOTA',
        vin: '2T3NFREV8HW374084',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:57:56.395Z'
        }
      },
      sort: [
        1591157459051
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV2HW365592480322020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T04:08:11.728Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16879.81',
          mileage: '33962'
        },
        sellArea: '160',
        dealerCd: '48032',
        certificationNumber: 9494847,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV2HW365592',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T00:50:34.151Z'
        }
      },
      sort: [
        1591157291728
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4HM082768042222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T04:08:07.454Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30991.00',
          mileage: '32907'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9485788,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4HM082768',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:18:20.628Z'
        }
      },
      sort: [
        1591157287454
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2LS049474042222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T04:03:12.924Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '33344.23',
          mileage: '2178'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9486022,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2LS049474',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD AUTO ACCESS SEAT',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5345',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:05:00.214Z'
        }
      },
      sort: [
        1591156992924
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP614756042222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:58:21.623Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15991.00',
          mileage: '30702'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9488503,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP614756',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T23:41:34.274Z'
        }
      },
      sort: [
        1591156701623
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU792256042222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:57:20.243Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18596.00',
          mileage: '21792'
        },
        sellArea: '600',
        dealerCd: '04222',
        certificationNumber: 9413768,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU792256',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T18:05:00.876Z'
        }
      },
      sort: [
        1591156640243
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3NFREV0HW338017042222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:55:42.302Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20988.00',
          mileage: '31667'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9425713,
        makeCode: 'TOYOTA',
        vin: '2T3NFREV0HW338017',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T18:22:42.782Z'
        }
      },
      sort: [
        1591156542302
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU213350042222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:54:07.232Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21592.00',
          mileage: '30051'
        },
        sellArea: '500',
        dealerCd: '04222',
        certificationNumber: 9476153,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU213350',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:03:24.577Z'
        }
      },
      sort: [
        1591156447232
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE2K3065083042822020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:30:15.830Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19498.00',
          mileage: '15397'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9473339,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE2K3065083',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T20:33:35.716Z'
        }
      },
      sort: [
        1591155015830
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC7HS796037041872020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:26:06.736Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29200.00',
          mileage: '26080'
        },
        sellArea: '110',
        dealerCd: '04187',
        certificationNumber: 9483812,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC7HS796037',
        model: {
          modelDescription: 'SIENNA SE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5343',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:04:16.884Z'
        }
      },
      sort: [
        1591154766736
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBEXK3064747042822020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:18:52.912Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '14798'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9471023,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBEXK3064747',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T17:37:47.517Z'
        }
      },
      sort: [
        1591154332912
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KW047009042822020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:14:59.911Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '32443'
        },
        dealerCd: '04282',
        certificationNumber: 9391528,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KW047009',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T23:15:29.225Z'
        }
      },
      sort: [
        1591154099911
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP718455042822020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:09:07.910Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '15998.00',
          mileage: '39248'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9484396,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP718455',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:00:41.434Z'
        }
      },
      sort: [
        1591153747910
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC1HS168901040882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:06:12.768Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30588.00',
          mileage: '26677'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9467253,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC1HS168901',
        model: {
          modelDescription: 'SIENNA LTD PREM AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5387',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:18:54.860Z'
        }
      },
      sort: [
        1591153572768
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4GM031706040882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:05:05.908Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30888.00',
          mileage: '36142'
        },
        sellArea: '120',
        dealerCd: '04088',
        certificationNumber: 9490516,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4GM031706',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T17:52:11.225Z'
        }
      },
      sort: [
        1591153505908
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK8LU308334042822020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T03:03:09.348Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '6849'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9474742,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK8LU308334',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:32:40.731Z'
        }
      },
      sort: [
        1591153389348
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW686442110302020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T02:32:48.331Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23324.00',
          mileage: '31251'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9489114,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW686442',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T15:01:50.078Z'
        }
      },
      sort: [
        1591151568331
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9JW802946190722020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T02:13:46.475Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20089.00',
          mileage: '34420'
        },
        sellArea: '600',
        dealerCd: '19072',
        certificationNumber: 9494851,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9JW802946',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-03T00:54:06.786Z'
        }
      },
      sort: [
        1591150426475
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFHXHS510817042902020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T01:36:22.479Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '36380'
        },
        sellArea: '110',
        dealerCd: '04290',
        certificationNumber: 9484619,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFHXHS510817',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T00:48:50.070Z'
        }
      },
      sort: [
        1591148182479
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK7HU205651190722020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T01:29:46.073Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19071.00',
          mileage: '51363'
        },
        sellArea: '800',
        dealerCd: '19072',
        certificationNumber: 9494434,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK7HU205651',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID XLE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2560',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:06:38.541Z'
        }
      },
      sort: [
        1591147786073
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK9J3029090190722020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T01:24:27.431Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19245.00',
          mileage: '46583'
        },
        sellArea: '600',
        dealerCd: '19072',
        certificationNumber: 9493697,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK9J3029090',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T14:32:54.035Z'
        }
      },
      sort: [
        1591147467431
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1EB1FU046771270202020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T01:21:07.427Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '38450'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9463794,
        makeCode: 'TOYOTA',
        vin: '4T1BD1EB1FU046771',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T00:04:27.175Z'
        }
      },
      sort: [
        1591147267427
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN4HM028861270202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T01:19:45.908Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29955.67',
          mileage: '27988'
        },
        sellArea: '120',
        dealerCd: '27020',
        certificationNumber: 9492974,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN4HM028861',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:20:07.905Z'
        }
      },
      sort: [
        1591147185908
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK7ER435153270202020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T01:18:19.523Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '10483.00',
          mileage: '79794'
        },
        sellArea: '150',
        dealerCd: '27020',
        certificationNumber: 9493052,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK7ER435153',
        model: {
          modelDescription: 'CAMRY 2014.5 L Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2514',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:33:38.412Z'
        }
      },
      sort: [
        1591147099523
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DU3F0460643370832020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T01:12:45.281Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16637.00',
          mileage: '54385'
        },
        dealerCd: '37083',
        certificationNumber: 9388043,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DU3F0460643',
        model: {
          modelDescription: 'PRIUS Four Model',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1227',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-19T20:56:01.833Z'
        }
      },
      sort: [
        1591146765281
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS406213250602020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T01:00:48.414Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30805.00',
          mileage: '33680'
        },
        sellArea: '130',
        dealerCd: '25060',
        certificationNumber: 9475194,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS406213',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T22:47:04.566Z'
        }
      },
      sort: [
        1591146048414
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6KS621254250602020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:58:29.153Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '37689.00',
          mileage: '4799'
        },
        sellArea: '130',
        dealerCd: '25060',
        certificationNumber: 9445210,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6KS621254',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-16T20:12:06.887Z'
        }
      },
      sort: [
        1591145909153
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3J5543902250602020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:56:20.771Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '36990.00',
          mileage: '12109'
        },
        sellArea: '130',
        dealerCd: '25060',
        certificationNumber: 9467073,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3J5543902',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T16:52:52.761Z'
        }
      },
      sort: [
        1591145780771
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5J5593636041692020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:54:33.329Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '27994.00',
          mileage: '45203'
        },
        sellArea: '600',
        dealerCd: '04169',
        certificationNumber: 9433341,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5J5593636',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T20:34:56.527Z'
        }
      },
      sort: [
        1591145673329
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5J5574522041692020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:51:04.828Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '36186.00',
          mileage: '33309'
        },
        sellArea: '150',
        dealerCd: '04169',
        certificationNumber: 9484466,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5J5574522',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:30:14.794Z'
        }
      },
      sort: [
        1591145464828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE3LP016390041692020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:50:23.887Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '21789.00',
          mileage: '15290'
        },
        sellArea: '600',
        dealerCd: '04169',
        certificationNumber: 9438723,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE3LP016390',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T17:38:16.073Z'
        }
      },
      sort: [
        1591145423887
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3GC558409311312020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:46:59.364Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13999.00',
          mileage: '14149'
        },
        sellArea: '160',
        dealerCd: '31131',
        certificationNumber: 9492822,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3GC558409',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:47:32.916Z'
        }
      },
      sort: [
        1591145219364
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE1JJ559472370832020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:35:27.539Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15611.00',
          mileage: '48762'
        },
        sellArea: '800',
        dealerCd: '37083',
        certificationNumber: 9465639,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE1JJ559472',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:17:23.925Z'
        }
      },
      sort: [
        1591144527539
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5JJ196978371382020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:16:14.061Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22246.73',
          mileage: '25430'
        },
        sellArea: '800',
        dealerCd: '37138',
        certificationNumber: 9472836,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5JJ196978',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T17:21:56.527Z'
        }
      },
      sort: [
        1591143374061
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F1XHX605329371382020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:13:31.660Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28815.00',
          mileage: '37747'
        },
        sellArea: '800',
        dealerCd: '37138',
        certificationNumber: 9494489,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F1XHX605329',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:32:06.890Z'
        }
      },
      sort: [
        1591143211660
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV0HD101187360842020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:09:37.217Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19971.00',
          mileage: '31007'
        },
        sellArea: '110',
        dealerCd: '36084',
        certificationNumber: 9492107,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV0HD101187',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:30:25.265Z'
        }
      },
      sort: [
        1591142977217
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW644705340942020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:07:29.595Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18594.0000',
          mileage: '29561'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9487136,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW644705',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:06:36.020Z'
        }
      },
      sort: [
        1591142849595
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7HD084108340942020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:07:26.575Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22189.0000',
          mileage: '35266'
        },
        dealerCd: '34094',
        certificationNumber: 9382740,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7HD084108',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T16:24:16.496Z'
        }
      },
      sort: [
        1591142846575
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC4HS179942340942020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:07:23.836Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29554.20',
          mileage: '20840'
        },
        sellArea: '220',
        dealerCd: '34094',
        certificationNumber: 9481425,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC4HS179942',
        model: {
          modelDescription: 'SIENNA LTD PREM AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5387',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T14:19:43.206Z'
        }
      },
      sort: [
        1591142843836
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2HJ142176450892020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:05:55.876Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17832.77',
          mileage: '32872'
        },
        sellArea: '160',
        dealerCd: '45089',
        certificationNumber: 9489711,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2HJ142176',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T19:27:56.235Z'
        }
      },
      sort: [
        1591142755876
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV7HD125952045632020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:05:40.813Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '24900.00',
          mileage: '16674'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9470568,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV7HD125952',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:16:34.938Z'
        }
      },
      sort: [
        1591142740813
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3H5421275045632020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-03T00:00:12.891Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '31700.00',
          mileage: '30560'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9458507,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3H5421275',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T15:11:44.344Z'
        }
      },
      sort: [
        1591142412891
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMCY7AJ5G4044469045632020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:54:58.188Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '63200.00',
          mileage: '25363'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9475298,
        makeCode: 'TOYOTA',
        vin: 'JTMCY7AJ5G4044469',
        model: {
          modelDescription: 'LAND CRUISER 4WD SUV V8',
          modelSeries: 'LAND CRUISER',
          modelNumber: '6156',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T00:15:37.987Z'
        }
      },
      sort: [
        1591142098188
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F10HX667614045632020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:50:49.385Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '34800.00',
          mileage: '27872'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9450817,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F10HX667614',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T00:12:56.870Z'
        }
      },
      sort: [
        1591141849385
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMGZ5AN3HM076445110352020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:47:36.422Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '33656.00',
          mileage: '25769'
        },
        sellArea: '130',
        dealerCd: '11035',
        certificationNumber: 9488080,
        makeCode: 'TOYOTA',
        vin: '3TMGZ5AN3HM076445',
        model: {
          modelDescription: 'TACOMA LIMITED 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7582',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:27:19.339Z'
        }
      },
      sort: [
        1591141656422
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HD201792371252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:47:13.943Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21690.00',
          mileage: '21750'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9493581,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HD201792',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T12:08:29.717Z'
        }
      },
      sort: [
        1591141633943
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKN3DU4F1865050180332020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:46:46.362Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14995.00',
          mileage: '59531'
        },
        sellArea: '170',
        dealerCd: '18033',
        certificationNumber: 9459287,
        makeCode: 'TOYOTA',
        vin: 'JTDKN3DU4F1865050',
        model: {
          modelDescription: 'PRIUS Three Model',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T23:16:23.073Z'
        }
      },
      sort: [
        1591141606362
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV5KC008663371252020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:46:13.462Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23900.00',
          mileage: '12370'
        },
        dealerCd: '37125',
        certificationNumber: 9364508,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV5KC008663',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-31T22:36:20.680Z'
        }
      },
      sort: [
        1591141573462
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH2FS200632371252020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:45:22.802Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19791.00',
          mileage: '59291'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9467346,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH2FS200632',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T19:20:32.912Z'
        }
      },
      sort: [
        1591141522802
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS393394371252020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:44:49.070Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '27000.00',
          mileage: '35680'
        },
        sellArea: '160',
        dealerCd: '37125',
        certificationNumber: 9401350,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS393394',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T19:00:08.749Z'
        }
      },
      sort: [
        1591141489070
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8HC816960371252020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:43:51.161Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15244.01',
          mileage: '29989'
        },
        dealerCd: '37125',
        certificationNumber: 9457569,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8HC816960',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T17:19:46.521Z'
        }
      },
      sort: [
        1591141431161
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU728185371252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:42:46.898Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '14924.65',
          mileage: '29007'
        },
        sellArea: '160',
        dealerCd: '37125',
        certificationNumber: 9417368,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU728185',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T19:46:38.175Z'
        }
      },
      sort: [
        1591141366898
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU674893371252020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:40:14.738Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15551.00',
          mileage: '23971'
        },
        sellArea: '160',
        dealerCd: '37125',
        certificationNumber: 9411421,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU674893',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-06T17:55:27.912Z'
        }
      },
      sort: [
        1591141214738
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1G5402402371252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:39:44.917Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29119.05',
          mileage: '39335'
        },
        sellArea: '800',
        dealerCd: '37125',
        certificationNumber: 9420094,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1G5402402',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T17:08:44.796Z'
        }
      },
      sort: [
        1591141184917
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK8JU009800045632020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:36:51.356Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '23700.00',
          mileage: '15360'
        },
        dealerCd: '04563',
        certificationNumber: 9386302,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK8JU009800',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T21:04:25.344Z'
        }
      },
      sort: [
        1591141011356
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH5GS349840480492020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:34:40.674Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '29479.00',
          mileage: '24792'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9472948,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH5GS349840',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T17:55:40.362Z'
        }
      },
      sort: [
        1591140880674
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV3GW523852450892020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:34:33.874Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20386.00',
          mileage: '56546'
        },
        sellArea: '800',
        dealerCd: '45089',
        certificationNumber: 9488385,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV3GW523852',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T22:17:35.888Z'
        }
      },
      sort: [
        1591140873874
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV9JJ208116041852020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:33:41.154Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '21256.00',
          mileage: '32876'
        },
        sellArea: '120',
        dealerCd: '04185',
        certificationNumber: 9479165,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV9JJ208116',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T17:03:01.883Z'
        }
      },
      sort: [
        1591140821154
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU7K3076983371882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:33:09.492Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21999.00',
          mileage: '11496'
        },
        dealerCd: '37188',
        certificationNumber: 9386286,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU7K3076983',
        model: {
          modelDescription: 'PRIUS LE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T20:59:35.029Z'
        }
      },
      sort: [
        1591140789492
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU711611041852020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:32:40.212Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '16083.00',
          mileage: '53511'
        },
        sellArea: '110',
        dealerCd: '04185',
        certificationNumber: 9479185,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU711611',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T17:10:31.759Z'
        }
      },
      sort: [
        1591140760212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV2HW584403042922020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:32:14.813Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26441.42',
          mileage: '28873'
        },
        sellArea: '120',
        dealerCd: '04292',
        certificationNumber: 9463371,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV2HW584403',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T19:52:12.305Z'
        }
      },
      sort: [
        1591140734813
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1FB1KU031102480492020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:30:32.571Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '31894.00',
          mileage: '4503'
        },
        dealerCd: '48049',
        certificationNumber: 9211302,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1FB1KU031102',
        model: {
          modelDescription: 'AVALON 4-DR TOURING',
          modelSeries: 'AVALON',
          modelNumber: '3548',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-23T17:04:51.285Z'
        }
      },
      sort: [
        1591140632571
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS457014360472020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:29:12.102Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '31977.00',
          mileage: '20123'
        },
        sellArea: '130',
        dealerCd: '36047',
        certificationNumber: 9471787,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS457014',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T22:20:35.728Z'
        }
      },
      sort: [
        1591140552102
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW557069480492020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:25:47.087Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18486.00',
          mileage: '23110'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9474585,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW557069',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:16:53.381Z'
        }
      },
      sort: [
        1591140347087
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP5H3017207360472020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:23:40.567Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '26995.00',
          mileage: '18457'
        },
        sellArea: '120',
        dealerCd: '36047',
        certificationNumber: 9459957,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP5H3017207',
        model: {
          modelDescription: 'PRIUS PRIME ADVANCED',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1239',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T19:00:10.660Z'
        }
      },
      sort: [
        1591140220567
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE1LP057544360472020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:23:02.463Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18579.00',
          mileage: '2492'
        },
        sellArea: '130',
        dealerCd: '36047',
        certificationNumber: 9454022,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE1LP057544',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T20:38:07.277Z'
        }
      },
      sort: [
        1591140182463
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HJ165796360472020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:22:32.844Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '22748.00',
          mileage: '12065'
        },
        sellArea: '130',
        dealerCd: '36047',
        certificationNumber: 9487986,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HJ165796',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T19:59:23.273Z'
        }
      },
      sort: [
        1591140152844
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX7JR014087360472020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:21:47.046Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '26200'
        },
        sellArea: '130',
        dealerCd: '36047',
        certificationNumber: 9450692,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX7JR014087',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T22:10:09.704Z'
        }
      },
      sort: [
        1591140107046
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN6HM029929045632020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:21:38.804Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '26400.00',
          mileage: '43094'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9425163,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN6HM029929',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T14:10:18.945Z'
        }
      },
      sort: [
        1591140098804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK7J3044770360472020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:21:12.084Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19382.00',
          mileage: '29035'
        },
        sellArea: '130',
        dealerCd: '36047',
        certificationNumber: 9448707,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK7J3044770',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T16:33:43.997Z'
        }
      },
      sort: [
        1591140072084
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6HM084702360472020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:20:35.624Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30995.00',
          mileage: '42887'
        },
        sellArea: '800',
        dealerCd: '36047',
        certificationNumber: 9469026,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6HM084702',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T20:24:37.114Z'
        }
      },
      sort: [
        1591140035624
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU236612360472020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:20:02.484Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18997.00',
          mileage: '20697'
        },
        sellArea: '150',
        dealerCd: '36047',
        certificationNumber: 9488489,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU236612',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T23:24:13.531Z'
        }
      },
      sort: [
        1591140002484
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK5JU529095480492020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:16:17.341Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '24323.00',
          mileage: '28974'
        },
        sellArea: '210',
        dealerCd: '48049',
        certificationNumber: 9465594,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK5JU529095',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:54:21.615Z'
        }
      },
      sort: [
        1591139777341
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F15HX661131045632020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:13:47.359Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '34900.00',
          mileage: '16948'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9460410,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F15HX661131',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T01:56:37.429Z'
        }
      },
      sort: [
        1591139627359
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBKRFH6GS244022201452020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:09:12.137Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '64734'
        },
        sellArea: '170',
        dealerCd: '20145',
        certificationNumber: 9490004,
        makeCode: 'TOYOTA',
        vin: '5TDBKRFH6GS244022',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T22:19:25.029Z'
        }
      },
      sort: [
        1591139352137
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZARFHXHS029083045632020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:08:35.936Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '62026'
        },
        sellArea: '500',
        dealerCd: '04563',
        certificationNumber: 9405770,
        makeCode: 'TOYOTA',
        vin: '5TDZARFHXHS029083',
        model: {
          modelDescription: 'HIGHLANDER LE - L4 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6942',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T22:04:10.360Z'
        }
      },
      sort: [
        1591139315936
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV2HW640078120742020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:06:12.334Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23028.00',
          mileage: '35647'
        },
        sellArea: '210',
        dealerCd: '12074',
        certificationNumber: 9474818,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV2HW640078',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:03:38.478Z'
        }
      },
      sort: [
        1591139172334
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH2HS448040201452020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:05:46.075Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23697.00',
          mileage: '25059'
        },
        sellArea: '170',
        dealerCd: '20145',
        certificationNumber: 9474665,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH2HS448040',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T17:54:24.556Z'
        }
      },
      sort: [
        1591139146075
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS512170045632020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T23:02:59.471Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '29900.00',
          mileage: '28343'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9462338,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS512170',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-10T14:56:37.917Z'
        }
      },
      sort: [
        1591138979471
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS397038371712020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:58:13.768Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27250.00',
          mileage: '36374'
        },
        sellArea: '800',
        dealerCd: '37171',
        certificationNumber: 9420749,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS397038',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T21:49:02.020Z'
        }
      },
      sort: [
        1591138693768
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB32J1599128371712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:56:57.568Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16750.00',
          mileage: '13759'
        },
        sellArea: '800',
        dealerCd: '37171',
        certificationNumber: 9398593,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB32J1599128',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB TWO',
          modelSeries: 'PRIUS C',
          modelNumber: '1203',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T14:45:51.824Z'
        }
      },
      sort: [
        1591138617568
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ120420371882020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:56:29.307Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17918.00',
          mileage: '65015'
        },
        dealerCd: '37188',
        certificationNumber: 9388763,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ120420',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T14:03:45.050Z'
        }
      },
      sort: [
        1591138589307
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC3HS792278121382020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:54:17.086Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26000.00',
          mileage: '30060'
        },
        sellArea: '220',
        dealerCd: '12138',
        certificationNumber: 9419887,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC3HS792278',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T15:38:05.660Z'
        }
      },
      sort: [
        1591138457086
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC880390043092020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:48:27.221Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '12988.00',
          mileage: '33912'
        },
        sellArea: '600',
        dealerCd: '04309',
        certificationNumber: 9463719,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC880390',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T22:55:58.262Z'
        }
      },
      sort: [
        1591138107221
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR0J5175824270242020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:45:32.159Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28655.81',
          mileage: '70935'
        },
        sellArea: '600',
        dealerCd: '27024',
        certificationNumber: 9480585,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR0J5175824',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8642',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T17:44:40.451Z'
        }
      },
      sort: [
        1591137932159
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HJ106341140152020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:43:39.639Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20650.00',
          mileage: '32107'
        },
        sellArea: '230',
        certificationNumber: 9434861,
        dealerCd: '14015',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        vin: 'JTMRFREV0HJ106341',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T16:34:14.862Z'
        }
      },
      sort: [
        1591137819639
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HJ172121140152020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:43:04.998Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20699.00',
          mileage: '29618'
        },
        sellArea: '230',
        dealerCd: '14015',
        certificationNumber: 9456032,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HJ172121',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T15:10:20.910Z'
        }
      },
      sort: [
        1591137784998
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV9HD073010045632020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:39:41.855Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23200.00',
          mileage: '60445'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9467833,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV9HD073010',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T01:17:11.295Z'
        }
      },
      sort: [
        1591137581855
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK8JU158773290582020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:36:29.893Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21903.00',
          mileage: '39314'
        },
        sellArea: '800',
        dealerCd: '29058',
        certificationNumber: 9489936,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK8JU158773',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T21:25:21.518Z'
        }
      },
      sort: [
        1591137389893
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM104301371742020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:35:29.952Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29757.00',
          mileage: '32425'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9481663,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM104301',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:19:46.857Z'
        }
      },
      sort: [
        1591137329952
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU364930371742020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:33:44.111Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16393.00',
          mileage: '28792'
        },
        sellArea: '800',
        dealerCd: '37174',
        certificationNumber: 9408980,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU364930',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T20:53:31.053Z'
        }
      },
      sort: [
        1591137224111
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F16HX649300460502020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:31:59.049Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '38950.00',
          mileage: '34188'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9401425,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F16HX649300',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T19:33:28.922Z'
        }
      },
      sort: [
        1591137119049
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC812602460502020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:28:24.889Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '13959.00',
          mileage: '28144'
        },
        dealerCd: '46050',
        certificationNumber: 9494724,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC812602',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:20:33.711Z'
        }
      },
      sort: [
        1591136904889
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMBZ5DN6KM021226040612020-05-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:25:13.639Z',
          retailDate: '2020-05-10',
          rdrStatus: 'H',
          soldAmount: '32762.96',
          mileage: '15882'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9494718,
        makeCode: 'TOYOTA',
        vin: '3TMBZ5DN6KM021226',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DBL CAB LONG BED',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7172',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:17:51.890Z'
        }
      },
      sort: [
        1591136713639
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU643164291022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:21:59.342Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16335.00',
          mileage: '38923'
        },
        dealerCd: '29102',
        certificationNumber: 9373636,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU643164',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-08T15:34:51.404Z'
        }
      },
      sort: [
        1591136519342
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV4HW582474110282020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:21:22.441Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26500.00',
          mileage: '37319'
        },
        sellArea: '130',
        dealerCd: '11028',
        certificationNumber: 9493959,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV4HW582474',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:03:08.506Z'
        }
      },
      sort: [
        1591136482441
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMGZ5AN8HM083147460732020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:20:44.242Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '29202.76',
          mileage: '46562'
        },
        sellArea: '130',
        dealerCd: '46073',
        certificationNumber: 9415945,
        makeCode: 'TOYOTA',
        vin: '3TMGZ5AN8HM083147',
        model: {
          modelDescription: 'TACOMA LIMITED 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7582',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T21:24:52.433Z'
        }
      },
      sort: [
        1591136444242
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS398999291022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:20:25.220Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27950.00',
          mileage: '34115'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9494309,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS398999',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:18:00.873Z'
        }
      },
      sort: [
        1591136425220
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1KU184230410632020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:19:45.521Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19999.00',
          mileage: '27435'
        },
        sellArea: '500',
        dealerCd: '41063',
        certificationNumber: 9423495,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1KU184230',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T13:15:40.528Z'
        }
      },
      sort: [
        1591136385521
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC008441291022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:19:14.882Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23902.00',
          mileage: '18814'
        },
        sellArea: '600',
        dealerCd: '29102',
        certificationNumber: 9406807,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC008441',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T14:22:41.352Z'
        }
      },
      sort: [
        1591136354882
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ704845291022020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:17:39.383Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19329.90',
          mileage: '22385'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9494657,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ704845',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:36:39.306Z'
        }
      },
      sort: [
        1591136259383
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DC7HS167493460502020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:17:34.983Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '30495.00',
          mileage: '36080'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9492906,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DC7HS167493',
        model: {
          modelDescription: 'SIENNA XLE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5376',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:02:28.596Z'
        }
      },
      sort: [
        1591136254983
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU2G3024958046492020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:17:12.043Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17977.00',
          mileage: '44089'
        },
        dealerCd: '04649',
        certificationNumber: 9374044,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU2G3024958',
        model: {
          modelDescription: 'PRIUS THREE TOURING',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1226',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-08T23:28:36.363Z'
        }
      },
      sort: [
        1591136232043
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC883151280292020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:16:57.263Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17148.30',
          mileage: '26250'
        },
        sellArea: '170',
        dealerCd: '28029',
        certificationNumber: 9474090,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC883151',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T12:19:06.909Z'
        }
      },
      sort: [
        1591136217263
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH7GS335862190602020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:15:50.162Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27998.00',
          mileage: '15346'
        },
        sellArea: '800',
        dealerCd: '19060',
        certificationNumber: 9420895,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH7GS335862',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T22:58:24.108Z'
        }
      },
      sort: [
        1591136150162
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F16GX578862410632020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:15:39.662Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '28250.00',
          mileage: '62318'
        },
        sellArea: '220',
        dealerCd: '41063',
        certificationNumber: 9494711,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F16GX578862',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T22:13:24.993Z'
        }
      },
      sort: [
        1591136139662
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0JW777596121262020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:12:39.324Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18852.00',
          mileage: '60702'
        },
        sellArea: '500',
        dealerCd: '12126',
        certificationNumber: 9427502,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0JW777596',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T19:59:16.945Z'
        }
      },
      sort: [
        1591135959324
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4KM270147460732020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:12:29.784Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '34447.00',
          mileage: '6069'
        },
        sellArea: '130',
        dealerCd: '46073',
        certificationNumber: 9469338,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4KM270147',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T00:12:46.490Z'
        }
      },
      sort: [
        1591135949784
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KC007435460732020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:11:34.745Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '26565.00',
          mileage: '16851'
        },
        dealerCd: '46073',
        certificationNumber: 9381996,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KC007435',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T21:54:55.009Z'
        }
      },
      sort: [
        1591135894745
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV8HW359263410632020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:11:05.906Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21000.00',
          mileage: '22121'
        },
        sellArea: '500',
        dealerCd: '41063',
        certificationNumber: 9476160,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV8HW359263',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:06:58.454Z'
        }
      },
      sort: [
        1591135865906
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8JU662674410632020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:10:19.965Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '22032'
        },
        sellArea: '500',
        dealerCd: '41063',
        certificationNumber: 9471185,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8JU662674',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:39:55.343Z'
        }
      },
      sort: [
        1591135819965
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU7G3514177045632020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:10:10.344Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '17600.00',
          mileage: '37927'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9474684,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU7G3514177',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:05:04.519Z'
        }
      },
      sort: [
        1591135810344
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3YFREV0HW329878042212020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:09:35.586Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '26874.00',
          mileage: '26232'
        },
        sellArea: '120',
        dealerCd: '04221',
        certificationNumber: 9439507,
        makeCode: 'TOYOTA',
        vin: '2T3YFREV0HW329878',
        model: {
          modelDescription: 'RAV4 Platinum FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4474',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T22:26:39.772Z'
        }
      },
      sort: [
        1591135775586
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP657925410632020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:09:23.040Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '31684'
        },
        sellArea: '220',
        dealerCd: '41063',
        certificationNumber: 9472545,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP657925',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T15:05:52.819Z'
        }
      },
      sort: [
        1591135763040
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KC026783460732020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:08:51.039Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '18121'
        },
        sellArea: '500',
        dealerCd: '46073',
        certificationNumber: 9426276,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KC026783',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T22:57:29.943Z'
        }
      },
      sort: [
        1591135731039
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F1XHX599371201452020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:07:38.525Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32000.00',
          mileage: '35374'
        },
        sellArea: '160',
        dealerCd: '20145',
        certificationNumber: 9445628,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F1XHX599371',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T20:23:12.049Z'
        }
      },
      sort: [
        1591135658525
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK6JU046781460732020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:06:27.886Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '22751.15',
          mileage: '25398'
        },
        sellArea: '130',
        dealerCd: '46073',
        certificationNumber: 9469308,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK6JU046781',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T23:37:33.964Z'
        }
      },
      sort: [
        1591135587886
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR3G5337861460732020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:04:08.075Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '37283.00',
          mileage: '18100'
        },
        sellArea: '500',
        dealerCd: '46073',
        certificationNumber: 9431360,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR3G5337861',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T23:10:02.140Z'
        }
      },
      sort: [
        1591135448075
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFEY5F10KX251705020422020-05-25V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:04:05.184Z',
          retailDate: '2020-05-25',
          rdrStatus: 'V',
          soldAmount: '34267.00',
          mileage: '4343'
        },
        sellArea: '150',
        dealerCd: '02042',
        certificationNumber: 9482680,
        makeCode: 'TOYOTA',
        vin: '5TFEY5F10KX251705',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8261',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T00:51:56.288Z'
        }
      },
      sort: [
        1591135445184
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8J5582775361032020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T22:03:09.548Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '35888.00',
          mileage: '13662'
        },
        sellArea: '130',
        dealerCd: '36103',
        certificationNumber: 9446344,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8J5582775',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-20T21:09:33.026Z'
        }
      },
      sort: [
        1591135389548
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP4J3090784310712020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:58:23.268Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '24495.00',
          mileage: '12117'
        },
        dealerCd: '31071',
        certificationNumber: 9374266,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP4J3090784',
        model: {
          modelDescription: 'PRIUS PRIME ADVANCED',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1239',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-10T13:57:08.423Z'
        }
      },
      sort: [
        1591135103268
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV4HD128002440132020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:57:18.950Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22200.00',
          mileage: '29065'
        },
        sellArea: '170',
        dealerCd: '44013',
        certificationNumber: 9464274,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV4HD128002',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T15:40:08.608Z'
        }
      },
      sort: [
        1591135038950
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KC008408190682020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:57:03.677Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '23417'
        },
        sellArea: '600',
        dealerCd: '19068',
        certificationNumber: 9460847,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KC008408',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:46:33.689Z'
        }
      },
      sort: [
        1591135023677
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU688089240852020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:55:59.150Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13990.00',
          mileage: '39097'
        },
        sellArea: '160',
        dealerCd: '24085',
        certificationNumber: 9489505,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU688089',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T17:45:49.246Z'
        }
      },
      sort: [
        1591134959150
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM105293260232020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:54:37.893Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28329.00',
          mileage: '47834'
        },
        dealerCd: '26023',
        certificationNumber: 9178457,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM105293',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-09-30T22:14:07.251Z'
        }
      },
      sort: [
        1591134877893
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM069477050462020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:53:48.830Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32984.00',
          mileage: '42055'
        },
        sellArea: '150',
        dealerCd: '05046',
        certificationNumber: 9487502,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM069477',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:12:10.930Z'
        }
      },
      sort: [
        1591134828830
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS421067150502020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:53:45.372Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28980.00',
          mileage: '42031'
        },
        sellArea: '230',
        dealerCd: '15050',
        certificationNumber: 9466217,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS421067',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T21:11:12.534Z'
        }
      },
      sort: [
        1591134825372
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV7HJ136558050672020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:50:54.533Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '23981',
          mileage: '34647'
        },
        sellArea: '160',
        certificationNumber: 9494087,
        dealerCd: '05067',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        vin: 'JTMDFREV7HJ136558',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:57:42.137Z'
        }
      },
      sort: [
        1591134654533
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ110034341072020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:50:10.973Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16985.00',
          mileage: '28186'
        },
        dealerCd: '34107',
        certificationNumber: 9344928,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ110034',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-20T18:25:16.481Z'
        }
      },
      sort: [
        1591134610973
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU339358341072020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:49:08.653Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '15872.00',
          mileage: '12416'
        },
        sellArea: '220',
        dealerCd: '34107',
        certificationNumber: 9431088,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU339358',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T20:34:23.090Z'
        }
      },
      sort: [
        1591134548653
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH0HS196823240852020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:46:52.055Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26950.00',
          mileage: '43423'
        },
        sellArea: '600',
        dealerCd: '24085',
        certificationNumber: 9483146,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH0HS196823',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6950',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:02:55.610Z'
        }
      },
      sort: [
        1591134412055
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE3FC364896460082020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:46:36.993Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '12995.00',
          mileage: '18677'
        },
        dealerCd: '46008',
        certificationNumber: 9448373,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE3FC364896',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-23T23:23:49.084Z'
        }
      },
      sort: [
        1591134396993
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW680833290372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:44:01.915Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '22141.20',
          mileage: '7775'
        },
        sellArea: '160',
        dealerCd: '29037',
        certificationNumber: 9431603,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW680833',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T13:17:53.375Z'
        }
      },
      sort: [
        1591134241915
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV5HD122483045632020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:43:29.135Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '36087'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9416012,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV5HD122483',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T22:02:39.662Z'
        }
      },
      sort: [
        1591134209135
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW576477210322020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:43:13.595Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '18519.00',
          mileage: '50415'
        },
        sellArea: '210',
        dealerCd: '21032',
        certificationNumber: 9477683,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW576477',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:16:27.067Z'
        }
      },
      sort: [
        1591134193595
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREVXHD206147290372020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:43:00.196Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23994.00',
          mileage: '29557'
        },
        dealerCd: '29037',
        certificationNumber: 9367913,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREVXHD206147',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T15:48:35.863Z'
        }
      },
      sort: [
        1591134180196
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3HM083281460502020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:41:57.175Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '33500.00',
          mileage: '31521'
        },
        sellArea: '130',
        dealerCd: '46050',
        certificationNumber: 9492934,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3HM083281',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T22:08:50.481Z'
        }
      },
      sort: [
        1591134117175
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV1KW032498042652020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:41:34.596Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22750.00',
          mileage: '26377'
        },
        sellArea: '500',
        dealerCd: '04265',
        certificationNumber: 9427673,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV1KW032498',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T21:15:18.745Z'
        }
      },
      sort: [
        1591134094596
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F1XFX458748045632020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:38:35.178Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '28500.00',
          mileage: '69530'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9425236,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F1XFX458748',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T14:33:19.116Z'
        }
      },
      sort: [
        1591133915178
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F12EX374308045632020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:32:26.818Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '27900.00',
          mileage: '71042'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9406285,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F12EX374308',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX 5.7L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T00:34:57.907Z'
        }
      },
      sort: [
        1591133546818
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV6KW034490311722020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:31:50.679Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23000.00',
          mileage: '4736'
        },
        sellArea: '160',
        dealerCd: '31172',
        certificationNumber: 9435467,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV6KW034490',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T14:47:55.359Z'
        }
      },
      sort: [
        1591133510679
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFVXLW062276020442020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:30:53.400Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28661.00',
          mileage: '12445'
        },
        sellArea: '500',
        dealerCd: '02044',
        certificationNumber: 9474064,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFVXLW062276',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T12:00:47.121Z'
        }
      },
      sort: [
        1591133453400
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1FK8HU583614311722020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:30:47.480Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '24233.25',
          mileage: '14200'
        },
        dealerCd: '31172',
        certificationNumber: 9387743,
        makeCode: 'TOYOTA',
        vin: '4T1BK1FK8HU583614',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2554',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-19T19:01:52.657Z'
        }
      },
      sort: [
        1591133447480
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS513719210682020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:29:57.521Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28748.00',
          mileage: '38729'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9456598,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS513719',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T14:55:17.316Z'
        }
      },
      sort: [
        1591133397521
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE9GC725498020442020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:29:54.061Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '13085.00',
          mileage: '67269'
        },
        sellArea: '130',
        dealerCd: '02044',
        certificationNumber: 9490394,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE9GC725498',
        model: {
          modelDescription: 'COROLLA LE PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1854',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T14:17:13.768Z'
        }
      },
      sort: [
        1591133394061
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4G5320082210682020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:29:07.400Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '71734'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9456580,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4G5320082',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T14:46:18.077Z'
        }
      },
      sort: [
        1591133347400
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC949958311722020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:27:57.440Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '11488.00',
          mileage: '58256'
        },
        sellArea: '160',
        dealerCd: '31172',
        certificationNumber: 9494637,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC949958',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:23:01.040Z'
        }
      },
      sort: [
        1591133277440
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV7KY526639045632020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:27:19.261Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '13200.00',
          mileage: '11754'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9418734,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV7KY526639',
        model: {
          modelDescription: 'YARIS Sedan 4Dr.Sedan L 6AT',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6266',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T17:40:22.569Z'
        }
      },
      sort: [
        1591133239261
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFU9K3007080045632020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:22:24.005Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '4033'
        },
        dealerCd: '04563',
        certificationNumber: 9385840,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFU9K3007080',
        model: {
          modelDescription: 'PRIUS XLE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1265',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T17:16:46.758Z'
        }
      },
      sort: [
        1591132944005
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU446393211072020-05-11H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:22:15.725Z',
          retailDate: '2020-05-11',
          rdrStatus: 'H',
          soldAmount: '16615.00',
          mileage: '36152'
        },
        sellArea: '220',
        dealerCd: '21107',
        certificationNumber: 9468774,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU446393',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:32:00.695Z'
        }
      },
      sort: [
        1591132935725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE5HC891034250642020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:18:44.526Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '14990.00',
          mileage: '9917'
        },
        sellArea: '130',
        dealerCd: '25064',
        certificationNumber: 9494624,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE5HC891034',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:15:37.382Z'
        }
      },
      sort: [
        1591132724526
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV7HW629951311722020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:18:29.964Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23213.00',
          mileage: '30280'
        },
        sellArea: '160',
        dealerCd: '31172',
        certificationNumber: 9423874,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV7HW629951',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T16:52:22.131Z'
        }
      },
      sort: [
        1591132709964
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21HK1JU502164211072020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:18:27.886Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '23196.98',
          mileage: '55419'
        },
        dealerCd: '21107',
        certificationNumber: 9359035,
        makeCode: 'TOYOTA',
        vin: '4T1B21HK1JU502164',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-29T17:04:17.282Z'
        }
      },
      sort: [
        1591132707886
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4HM084780045632020-05-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:18:00.504Z',
          retailDate: '2020-05-14',
          rdrStatus: 'H',
          soldAmount: '31500.00',
          mileage: '30242'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9459095,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4HM084780',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T20:49:46.963Z'
        }
      },
      sort: [
        1591132680504
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU324247470282020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:17:51.025Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18991.00',
          mileage: '27332'
        },
        sellArea: '500',
        dealerCd: '47028',
        certificationNumber: 9462485,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU324247',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T12:42:19.179Z'
        }
      },
      sort: [
        1591132671025
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV8JD220753211072020-05-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:17:33.065Z',
          retailDate: '2020-05-19',
          rdrStatus: 'H',
          soldAmount: '25448.05',
          mileage: '4226'
        },
        sellArea: '220',
        dealerCd: '21107',
        certificationNumber: 9446059,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV8JD220753',
        model: {
          modelDescription: 'RAV4 HYBRID LE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4435',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-20T14:38:19.571Z'
        }
      },
      sort: [
        1591132653065
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS386495201012020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:17:07.766Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '28462.00',
          mileage: '43199'
        },
        sellArea: '170',
        dealerCd: '20101',
        certificationNumber: 9485884,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS386495',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:52:15.173Z'
        }
      },
      sort: [
        1591132627766
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HJ155332211072020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:16:28.465Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '21669.00',
          mileage: '29000'
        },
        sellArea: '220',
        dealerCd: '21107',
        certificationNumber: 9468796,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HJ155332',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:38:36.779Z'
        }
      },
      sort: [
        1591132588465
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU741911341232020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:15:47.925Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15998.00',
          mileage: '26643'
        },
        sellArea: '220',
        dealerCd: '34123',
        certificationNumber: 9494617,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU741911',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T21:10:52.763Z'
        }
      },
      sort: [
        1591132547925
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8HW572943201012020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:15:05.824Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17665.00',
          mileage: '53880'
        },
        dealerCd: '20101',
        certificationNumber: 9372965,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8HW572943',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T21:17:05.826Z'
        }
      },
      sort: [
        1591132505824
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH6HS378117371092020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:15:01.884Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28000.00',
          mileage: '54993'
        },
        sellArea: '800',
        dealerCd: '37109',
        certificationNumber: 9393853,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH6HS378117',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-24T19:33:49.524Z'
        }
      },
      sort: [
        1591132501884
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFU8K3006552045632020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:12:22.187Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '24500.00',
          mileage: '6007'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9396296,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFU8K3006552',
        model: {
          modelDescription: 'PRIUS XLE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1265',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T23:32:55.450Z'
        }
      },
      sort: [
        1591132342187
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFV7KW035695311722020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:09:33.808Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '21800.00',
          mileage: '5598'
        },
        sellArea: '160',
        dealerCd: '31172',
        certificationNumber: 9435492,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFV7KW035695',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T15:05:28.908Z'
        }
      },
      sort: [
        1591132173808
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7KM275987045632020-05-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:07:20.534Z',
          retailDate: '2020-05-10',
          rdrStatus: 'H',
          soldAmount: '29900.00',
          mileage: '18473'
        },
        dealerCd: '04563',
        certificationNumber: 9373018,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7KM275987',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T21:42:36.661Z'
        }
      },
      sort: [
        1591132040534
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH8GS267992370662020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:07:15.116Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '28650.00',
          mileage: '30012'
        },
        sellArea: '800',
        dealerCd: '37066',
        certificationNumber: 9494116,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH8GS267992',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:11:24.467Z'
        }
      },
      sort: [
        1591132035116
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9JU524867290692020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:03:45.894Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17100.00',
          mileage: '45530'
        },
        sellArea: '160',
        dealerCd: '29069',
        certificationNumber: 9414029,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9JU524867',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T19:43:06.851Z'
        }
      },
      sort: [
        1591131825894
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3N1RFV1KW066551190572020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:03:25.131Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '30690.00',
          mileage: '9319'
        },
        sellArea: '800',
        dealerCd: '19057',
        certificationNumber: 9435458,
        makeCode: 'TOYOTA',
        vin: '2T3N1RFV1KW066551',
        model: {
          modelDescription: 'RAV4 LIMITED AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T14:33:45.917Z'
        }
      },
      sort: [
        1591131805131
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV5JY319181290692020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:02:49.213Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '13800.00',
          mileage: '2253'
        },
        dealerCd: '29069',
        certificationNumber: 9195628,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV5JY319181',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-12T17:59:51.716Z'
        }
      },
      sort: [
        1591131769213
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV9HJ131769045632020-05-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:02:40.373Z',
          retailDate: '2020-05-10',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '41960'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9460412,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV9HJ131769',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T04:18:07.788Z'
        }
      },
      sort: [
        1591131760373
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH6HS449868290582020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:02:16.792Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '25884.00',
          mileage: '48185'
        },
        sellArea: '160',
        dealerCd: '29058',
        certificationNumber: 9483480,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH6HS449868',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:40:26.310Z'
        }
      },
      sort: [
        1591131736792
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU1J3062317190572020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:01:52.453Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '23990.00',
          mileage: '2369'
        },
        sellArea: '800',
        dealerCd: '19057',
        certificationNumber: 9464591,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU1J3062317',
        model: {
          modelDescription: 'PRIUS FOUR TOURING',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1228',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T18:31:58.724Z'
        }
      },
      sort: [
        1591131712453
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBEXLJ013589190572020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T21:01:10.432Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '16186'
        },
        sellArea: '800',
        dealerCd: '19057',
        certificationNumber: 9447392,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBEXLJ013589',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T18:20:41.840Z'
        }
      },
      sort: [
        1591131670432
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU3GJ054208045632020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:57:49.570Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '57050'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9401930,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU3GJ054208',
        model: {
          modelDescription: 'PRIUS v Three Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1245',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T22:03:54.014Z'
        }
      },
      sort: [
        1591131469570
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRM5F11JX126059042222020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:57:20.448Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28500.00',
          mileage: '27007'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9480558,
        makeCode: 'TOYOTA',
        vin: '5TFRM5F11JX126059',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8240',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T17:27:56.556Z'
        }
      },
      sort: [
        1591131440448
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV6HW649887480282020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:56:43.029Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20633.00',
          mileage: '32345'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9484560,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV6HW649887',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T23:29:11.465Z'
        }
      },
      sort: [
        1591131403029
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5GU190625190562020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:55:13.768Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14450.00',
          mileage: '42373'
        },
        sellArea: '600',
        dealerCd: '19056',
        certificationNumber: 9451758,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5GU190625',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T22:47:00.907Z'
        }
      },
      sort: [
        1591131313768
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0LS035329250662020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:54:46.689Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '29844.00',
          mileage: '21713'
        },
        dealerCd: '25066',
        certificationNumber: 9375300,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC0LS035329',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-10T21:06:44.678Z'
        }
      },
      sort: [
        1591131286689
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE1HJ542861211072020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:54:29.940Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '16283.00',
          mileage: '7283'
        },
        sellArea: '220',
        dealerCd: '21107',
        certificationNumber: 9465818,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE1HJ542861',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:52:28.473Z'
        }
      },
      sort: [
        1591131269940
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV0HW386282042222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:54:15.768Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18750.00',
          mileage: '26302'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9413788,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV0HW386282',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T18:16:59.823Z'
        }
      },
      sort: [
        1591131255768
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHY5F18HX646236370662020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:53:56.828Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '36600.00',
          mileage: '31295'
        },
        sellArea: '800',
        dealerCd: '37066',
        certificationNumber: 9465559,
        makeCode: 'TOYOTA',
        vin: '5TFHY5F18HX646236',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8372',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T15:34:37.667Z'
        }
      },
      sort: [
        1591131236828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F11JX692999211072020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:52:58.287Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '32669.00',
          mileage: '37586'
        },
        sellArea: '220',
        dealerCd: '21107',
        certificationNumber: 9415593,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F11JX692999',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T18:59:19.866Z'
        }
      },
      sort: [
        1591131178287
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMYFREV1HJ148688042222020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:52:07.928Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '22603.63',
          mileage: '34728'
        },
        sellArea: '110',
        dealerCd: '04222',
        certificationNumber: 9446883,
        makeCode: 'TOYOTA',
        vin: 'JTMYFREV1HJ148688',
        model: {
          modelDescription: 'RAV4 Limited FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4450',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-21T23:35:26.143Z'
        }
      },
      sort: [
        1591131127928
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV2HW585011370662020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:52:05.594Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18999.00',
          mileage: '38375'
        },
        dealerCd: '37066',
        certificationNumber: 9346509,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV2HW585011',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-21T14:21:03.545Z'
        }
      },
      sort: [
        1591131125594
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F13GX551783110112020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:51:40.135Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '33800.00',
          mileage: '34928'
        },
        dealerCd: '11011',
        certificationNumber: 9373769,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F13GX551783',
        model: {
          modelDescription: 'TUNDRA 4X4 PLATINUM CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8377',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-08T17:41:41.309Z'
        }
      },
      sort: [
        1591131100135
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3KC028992370662020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:51:09.454Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26000.00',
          mileage: '13136'
        },
        sellArea: '500',
        dealerCd: '37066',
        certificationNumber: 9424857,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3KC028992',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T12:39:36.712Z'
        }
      },
      sort: [
        1591131069454
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1FW324979380312020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:50:28.054Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20257.26',
          mileage: '35466'
        },
        sellArea: '170',
        dealerCd: '38031',
        certificationNumber: 9468120,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1FW324979',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T12:40:03.736Z'
        }
      },
      sort: [
        1591131028054
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFS4RCE8LP014232042222020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:48:38.495Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19991.00',
          mileage: '12657'
        },
        sellArea: '600',
        dealerCd: '04222',
        certificationNumber: 9479336,
        makeCode: 'TOYOTA',
        vin: '5YFS4RCE8LP014232',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T18:41:41.796Z'
        }
      },
      sort: [
        1591130918495
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV1HJ137051370662020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:47:29.617Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '19494.98',
          mileage: '25753'
        },
        sellArea: '800',
        dealerCd: '37066',
        certificationNumber: 9477609,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV1HJ137051',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T16:48:37.322Z'
        }
      },
      sort: [
        1591130849617
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KW066004250662020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:46:56.857Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '26596.88',
          mileage: '15488'
        },
        sellArea: '600',
        dealerCd: '25066',
        certificationNumber: 9485660,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KW066004',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:47:35.394Z'
        }
      },
      sort: [
        1591130816857
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN6HX064489340062020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:46:49.255Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '37925'
        },
        sellArea: '220',
        dealerCd: '34006',
        certificationNumber: 9472467,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN6HX064489',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T14:25:58.099Z'
        }
      },
      sort: [
        1591130809255
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5GU524894290312020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:46:29.177Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14802.00',
          mileage: '35855'
        },
        sellArea: '160',
        dealerCd: '29031',
        certificationNumber: 9433937,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5GU524894',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T16:17:02.519Z'
        }
      },
      sort: [
        1591130789177
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV9GW309442042502020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:46:05.136Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '20795.00',
          mileage: '22697'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9418709,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV9GW309442',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T17:29:56.274Z'
        }
      },
      sort: [
        1591130765136
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMH1RFV5KD500626042502020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:45:04.937Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '23595.00',
          mileage: '26765'
        },
        dealerCd: '04250',
        certificationNumber: 9377821,
        makeCode: 'TOYOTA',
        vin: 'JTMH1RFV5KD500626',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-12T16:47:24.707Z'
        }
      },
      sort: [
        1591130704937
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU648526211072020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:44:01.538Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '21800.00',
          mileage: '4769'
        },
        dealerCd: '21107',
        certificationNumber: 9388937,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU648526',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T15:55:41.600Z'
        }
      },
      sort: [
        1591130641538
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP691394042502020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:43:44.837Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '15995.00',
          mileage: '18525'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9468408,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP691394',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T15:55:29.682Z'
        }
      },
      sort: [
        1591130624837
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F16HX652437470282020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:43:41.591Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '32971.23',
          mileage: '22834'
        },
        sellArea: '800',
        dealerCd: '47028',
        certificationNumber: 9461237,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F16HX652437',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T19:27:21.319Z'
        }
      },
      sort: [
        1591130621591
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKARJE7HJ539771211182020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:43:11.398Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '11499.00',
          mileage: '44842'
        },
        dealerCd: '21118',
        certificationNumber: 9390415,
        makeCode: 'TOYOTA',
        vin: 'JTNKARJE7HJ539771',
        model: {
          modelDescription: 'COROLLA iM 5-DOOR HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-21T14:58:45.101Z'
        }
      },
      sort: [
        1591130591398
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DC6FS643431211182020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:42:08.098Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21999.00',
          mileage: '57207'
        },
        sellArea: '220',
        dealerCd: '21118',
        certificationNumber: 9494526,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DC6FS643431',
        model: {
          modelDescription: 'SIENNA LTD PREM FWD 7 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5357',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:39:33.334Z'
        }
      },
      sort: [
        1591130528098
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6HM104771250662020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:40:41.759Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '30565.00',
          mileage: '34996'
        },
        sellArea: '130',
        dealerCd: '25066',
        certificationNumber: 9465642,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6HM104771',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:21:20.727Z'
        }
      },
      sort: [
        1591130441759
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV4HY184074211072020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:40:32.639Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '12671.00',
          mileage: '31917'
        },
        sellArea: '220',
        dealerCd: '21107',
        certificationNumber: 9465734,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV4HY184074',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:05:31.969Z'
        }
      },
      sort: [
        1591130432639
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN3HM044730211072020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:39:05.920Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '26000.00',
          mileage: '10177'
        },
        sellArea: '220',
        dealerCd: '21107',
        certificationNumber: 9465852,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN3HM044730',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:16:18.074Z'
        }
      },
      sort: [
        1591130345920
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU038268290312020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:38:21.792Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '24976.00',
          mileage: '34139'
        },
        sellArea: '160',
        dealerCd: '29031',
        certificationNumber: 9408545,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU038268',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T17:19:24.141Z'
        }
      },
      sort: [
        1591130301792
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFUXH3037815211072020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:37:03.219Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '19407.00',
          mileage: '36133'
        },
        dealerCd: '21107',
        certificationNumber: 9377036,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFUXH3037815',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-11T20:31:08.580Z'
        }
      },
      sort: [
        1591130223219
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4HW661407311802020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:36:01.242Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19995.00',
          mileage: '24790'
        },
        sellArea: '160',
        dealerCd: '31180',
        certificationNumber: 9468416,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4HW661407',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T15:58:47.603Z'
        }
      },
      sort: [
        1591130161242
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK8J3032322211072020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:34:48.901Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '18640.00',
          mileage: '57847'
        },
        sellArea: '600',
        dealerCd: '21107',
        certificationNumber: 9465718,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK8J3032322',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:54:56.608Z'
        }
      },
      sort: [
        1591130088901
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX2K1041421043242020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:34:33.799Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '20035.18',
          mileage: '19866'
        },
        sellArea: '600',
        dealerCd: '04324',
        certificationNumber: 9426366,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX2K1041421',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T23:58:40.512Z'
        }
      },
      sort: [
        1591130073799
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU435978240362020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:33:48.500Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17500.00',
          mileage: '30456'
        },
        sellArea: '230',
        dealerCd: '24036',
        certificationNumber: 9494426,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU435978',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:03:13.222Z'
        }
      },
      sort: [
        1591130028500
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFUXH3045915041222020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:33:39.440Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23864.08',
          mileage: '20699'
        },
        sellArea: '120',
        dealerCd: '04122',
        certificationNumber: 9492520,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFUXH3045915',
        model: {
          modelDescription: 'PRIUS FOUR',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1227',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:19:24.112Z'
        }
      },
      sort: [
        1591130019440
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU817126190572020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:33:03.663Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19690.00',
          mileage: '4490'
        },
        sellArea: '800',
        dealerCd: '19057',
        certificationNumber: 9447243,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU817126',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T16:02:31.848Z'
        }
      },
      sort: [
        1591129983663
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV1KW007318043242020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:33:01.321Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '22599.99',
          mileage: '32556'
        },
        sellArea: '600',
        dealerCd: '04324',
        certificationNumber: 9424516,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV1KW007318',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T21:17:01.893Z'
        }
      },
      sort: [
        1591129981321
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU763512190572020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:31:47.083Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20990.00',
          mileage: '11969'
        },
        sellArea: '800',
        dealerCd: '19057',
        certificationNumber: 9465976,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU763512',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T19:24:48.673Z'
        }
      },
      sort: [
        1591129907083
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU218961043242020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:31:46.854Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '20990.00',
          mileage: '27395'
        },
        sellArea: '500',
        dealerCd: '04324',
        certificationNumber: 9397525,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU218961',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-26T19:30:11.914Z'
        }
      },
      sort: [
        1591129906854
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK6JU003647211072020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:31:36.014Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23339.00',
          mileage: '23236'
        },
        sellArea: '220',
        dealerCd: '21107',
        certificationNumber: 9426083,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK6JU003647',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T21:04:04.091Z'
        }
      },
      sort: [
        1591129896014
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ138055210652020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:30:58.795Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21999.00',
          mileage: '25801'
        },
        sellArea: '160',
        dealerCd: '21065',
        certificationNumber: 9407585,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ138055',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T21:54:58.017Z'
        }
      },
      sort: [
        1591129858795
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX8JR001770190572020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:30:02.705Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '17700.00',
          mileage: '23050'
        },
        sellArea: '800',
        dealerCd: '19057',
        certificationNumber: 9479618,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX8JR001770',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T21:14:15.216Z'
        }
      },
      sort: [
        1591129802705
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXGW532760210652020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:29:23.753Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19506.00',
          mileage: '70912'
        },
        dealerCd: '21065',
        certificationNumber: 9389181,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXGW532760',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T17:26:03.295Z'
        }
      },
      sort: [
        1591129763753
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB7GU229597410612020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:28:59.973Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '18995.00',
          mileage: '56089'
        },
        sellArea: '220',
        dealerCd: '41061',
        certificationNumber: 9493921,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB7GU229597',
        model: {
          modelDescription: 'AVALON 4-DR LIMITED',
          modelSeries: 'AVALON',
          modelNumber: '3554',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:48:04.651Z'
        }
      },
      sort: [
        1591129739973
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV6KC009580210652020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:28:22.012Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '25889.00',
          mileage: '10702'
        },
        sellArea: '500',
        dealerCd: '21065',
        certificationNumber: 9476700,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV6KC009580',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:45:54.958Z'
        }
      },
      sort: [
        1591129702012
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV1HD079979211072020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:28:19.531Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21234.72',
          mileage: '44914'
        },
        sellArea: '220',
        dealerCd: '21107',
        certificationNumber: 9465753,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV1HD079979',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:17:31.650Z'
        }
      },
      sort: [
        1591129699531
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFVXKC014474210652020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:26:10.330Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23758.79',
          mileage: '23346'
        },
        sellArea: '600',
        dealerCd: '21065',
        certificationNumber: 9444813,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFVXKC014474',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-15T18:45:12.781Z'
        }
      },
      sort: [
        1591129570330
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV4HJ148263200962020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:24:20.489Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '34574'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9445070,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV4HJ148263',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-16T16:01:02.434Z'
        }
      },
      sort: [
        1591129460489
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU5H3528163210652020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:24:02.770Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19506.00',
          mileage: '50433'
        },
        sellArea: '600',
        dealerCd: '21065',
        certificationNumber: 9424248,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU5H3528163',
        model: {
          modelDescription: 'PRIUS FOUR',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1227',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T19:30:16.511Z'
        }
      },
      sort: [
        1591129442770
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX2JR063102041502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:24:00.547Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '28449'
        },
        sellArea: '110',
        dealerCd: '04150',
        certificationNumber: 9478412,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX2JR063102',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:40:39.658Z'
        }
      },
      sort: [
        1591129440547
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV2LC084486410612020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:23:59.550Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27250.00',
          mileage: '4965'
        },
        sellArea: '500',
        dealerCd: '41061',
        certificationNumber: 9477207,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV2LC084486',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T12:28:10.855Z'
        }
      },
      sort: [
        1591129439550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP595842460502020-01-19H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:23:42.446Z',
          retailDate: '2020-01-19',
          rdrStatus: 'H',
          soldAmount: '17980.00',
          mileage: '31924'
        },
        dealerCd: '46050',
        certificationNumber: 9494454,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP595842',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:17:08.984Z'
        }
      },
      sort: [
        1591129422446
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HJ127643140442020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:23:30.424Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20638.00',
          mileage: '40813'
        },
        sellArea: '230',
        dealerCd: '14044',
        certificationNumber: 9415639,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HJ127643',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T19:29:59.873Z'
        }
      },
      sort: [
        1591129410424
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDZN3EU0HJ063689041502020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:23:22.066Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '27000.00',
          mileage: '13101'
        },
        sellArea: '110',
        dealerCd: '04150',
        certificationNumber: 9490497,
        makeCode: 'TOYOTA',
        vin: 'JTDZN3EU0HJ063689',
        model: {
          modelDescription: 'PRIUS v Four Model',
          modelSeries: 'PRIUS V',
          modelNumber: '1247',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T17:33:26.079Z'
        }
      },
      sort: [
        1591129402066
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXJW781525210652020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:22:57.806Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22990.00',
          mileage: '13081'
        },
        sellArea: '160',
        dealerCd: '21065',
        certificationNumber: 9395321,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXJW781525',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T16:46:08.960Z'
        }
      },
      sort: [
        1591129377806
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU319100041502020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:21:53.306Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '15599.00',
          mileage: '38403'
        },
        dealerCd: '04150',
        certificationNumber: 9369864,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU319100',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-05T21:17:13.230Z'
        }
      },
      sort: [
        1591129313306
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DC4GS741228210652020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:21:30.745Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22600.00',
          mileage: '43665'
        },
        sellArea: '500',
        dealerCd: '21065',
        certificationNumber: 9399564,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DC4GS741228',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T20:48:40.208Z'
        }
      },
      sort: [
        1591129290745
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV0JJ727930041502020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:20:49.648Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '21750.00',
          mileage: '34087'
        },
        sellArea: '110',
        dealerCd: '04150',
        certificationNumber: 9490858,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV0JJ727930',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T21:14:54.503Z'
        }
      },
      sort: [
        1591129249648
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS398705200962020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:20:37.625Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '37660'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9466704,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS398705',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T14:11:05.822Z'
        }
      },
      sort: [
        1591129237625
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV3LC075392410612020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:20:34.006Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27650.08',
          mileage: '4599'
        },
        sellArea: '500',
        dealerCd: '41061',
        certificationNumber: 9477196,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV3LC075392',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T12:08:30.512Z'
        }
      },
      sort: [
        1591129234006
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC957996210652020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:19:47.528Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '12147.51',
          mileage: '55699'
        },
        sellArea: '220',
        dealerCd: '21065',
        certificationNumber: 9489641,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC957996',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:33:59.990Z'
        }
      },
      sort: [
        1591129187528
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP716315041502020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:19:39.963Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '13923.00',
          mileage: '48733'
        },
        sellArea: '110',
        dealerCd: '04150',
        certificationNumber: 9461102,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP716315',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T18:16:58.621Z'
        }
      },
      sort: [
        1591129179963
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7FC376811200962020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:17:10.186Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '13995.00',
          mileage: '27645'
        },
        sellArea: '170',
        dealerCd: '20096',
        certificationNumber: 9486967,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7FC376811',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:50:24.968Z'
        }
      },
      sort: [
        1591129030186
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH4HS380299220342020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:13:57.243Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '30990.00',
          mileage: '38422'
        },
        sellArea: '210',
        dealerCd: '22034',
        certificationNumber: 9472611,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH4HS380299',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T15:41:56.476Z'
        }
      },
      sort: [
        1591128837243
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAY5F19JX692309370662020-03-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:13:28.603Z',
          retailDate: '2020-03-10',
          rdrStatus: 'H',
          soldAmount: '41969.00',
          mileage: '20077'
        },
        sellArea: '160',
        dealerCd: '37066',
        certificationNumber: 9408475,
        makeCode: 'TOYOTA',
        vin: '5TFAY5F19JX692309',
        model: {
          modelDescription: 'TUNDRA 4X4 PLATINUM CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8375',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T16:49:21.267Z'
        }
      },
      sort: [
        1591128808603
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS390811050632020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:08:51.001Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '31449.00',
          mileage: '26757'
        },
        sellArea: '150',
        dealerCd: '05063',
        certificationNumber: 9494416,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS390811',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T20:00:53.905Z'
        }
      },
      sort: [
        1591128531001
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN2KX192456060362020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:08:27.583Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '30599.00',
          mileage: '12990'
        },
        sellArea: '160',
        dealerCd: '06036',
        certificationNumber: 9482481,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN2KX192456',
        model: {
          modelDescription: 'TACOMA SR 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7552',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:56:09.978Z'
        }
      },
      sort: [
        1591128507583
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2GM024821480302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:07:52.283Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26100.00',
          mileage: '60350'
        },
        sellArea: '210',
        dealerCd: '48030',
        certificationNumber: 9494230,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2GM024821',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:48:19.175Z'
        }
      },
      sort: [
        1591128472283
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN4HM041335020362020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:04:35.104Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '29994.00',
          mileage: '33136'
        },
        sellArea: '110',
        dealerCd: '02036',
        certificationNumber: 9488230,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN4HM041335',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:20:18.303Z'
        }
      },
      sort: [
        1591128275104
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAEXLP076156020362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:02:35.043Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16694.00',
          mileage: '9455'
        },
        sellArea: '600',
        dealerCd: '02036',
        certificationNumber: 9487199,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAEXLP076156',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:25:04.713Z'
        }
      },
      sort: [
        1591128155043
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8HM053208371122020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:01:31.146Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '40890.00',
          mileage: '34950'
        },
        sellArea: '800',
        dealerCd: '37112',
        certificationNumber: 9395777,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8HM053208',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-25T19:43:00.064Z'
        }
      },
      sort: [
        1591128091146
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN0LX213987310882020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T20:00:31.779Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '32000.00',
          mileage: '3349'
        },
        sellArea: '160',
        dealerCd: '31088',
        certificationNumber: 9442319,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN0LX213987',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T15:44:20.485Z'
        }
      },
      sort: [
        1591128031779
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU744395020362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:59:01.205Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19838.83',
          mileage: '30015'
        },
        sellArea: '600',
        dealerCd: '02036',
        certificationNumber: 9486027,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU744395',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T20:06:14.457Z'
        }
      },
      sort: [
        1591127941205
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM074555280292020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:57:52.726Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '36812'
        },
        sellArea: '170',
        dealerCd: '28029',
        certificationNumber: 9404088,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM074555',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-01T18:39:15.881Z'
        }
      },
      sort: [
        1591127872726
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS387266045632020-05-06H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:55:18.468Z',
          retailDate: '2020-05-06',
          rdrStatus: 'H',
          soldAmount: '29954.00',
          mileage: '34665'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9412238,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS387266',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T01:03:52.849Z'
        }
      },
      sort: [
        1591127718468
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDP4RCE3LJ004766020362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:54:03.806Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19694.77',
          mileage: '26534'
        },
        sellArea: '150',
        dealerCd: '02036',
        certificationNumber: 9483205,
        makeCode: 'TOYOTA',
        vin: 'JTDP4RCE3LJ004766',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T14:22:51.848Z'
        }
      },
      sort: [
        1591127643806
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV8HW618852360992020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:53:25.548Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '33499'
        },
        sellArea: '130',
        dealerCd: '36099',
        certificationNumber: 9478078,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV8HW618852',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T20:00:35.821Z'
        }
      },
      sort: [
        1591127605548
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1J5539816050462020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:53:12.591Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '36398.00',
          mileage: '23985'
        },
        sellArea: '150',
        dealerCd: '05046',
        certificationNumber: 9455554,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1J5539816',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T18:47:57.888Z'
        }
      },
      sort: [
        1591127592591
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV1HW633922140462020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:53:09.808Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23495.00',
          mileage: '35265'
        },
        dealerCd: '14046',
        certificationNumber: 9110958,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV1HW633922',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-08-19T15:47:21.458Z'
        }
      },
      sort: [
        1591127589808
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV8JJ215203140462020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:52:47.489Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '20267'
        },
        sellArea: '230',
        dealerCd: '14046',
        certificationNumber: 9450372,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV8JJ215203',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T18:49:15.493Z'
        }
      },
      sort: [
        1591127567489
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMGZ5AN7HM114758311602020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:52:14.449Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '30863.00',
          mileage: '13973'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9493803,
        makeCode: 'TOYOTA',
        vin: '3TMGZ5AN7HM114758',
        model: {
          modelDescription: 'TACOMA LIMITED 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7582',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:46:37.199Z'
        }
      },
      sort: [
        1591127534449
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC778810210682020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:51:12.289Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '11996.00',
          mileage: '76843'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9462728,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC778810',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T14:57:49.001Z'
        }
      },
      sort: [
        1591127472289
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU721207210682020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:50:27.187Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17999.00',
          mileage: '39421'
        },
        sellArea: '220',
        dealerCd: '21068',
        certificationNumber: 9443853,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU721207',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T15:44:16.549Z'
        }
      },
      sort: [
        1591127427187
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDWRFVXKD027967311602020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:47:11.628Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '32741.00',
          mileage: '1637'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9493806,
        makeCode: 'TOYOTA',
        vin: 'JTMDWRFVXKD027967',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:48:39.102Z'
        }
      },
      sort: [
        1591127231628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CNXHM049536042502020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:45:21.824Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '29500.00',
          mileage: '15763'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9481884,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CNXHM049536',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:00:13.503Z'
        }
      },
      sort: [
        1591127121824
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HJ701576050672020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:45:21.487Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19981.0000',
          mileage: '35800'
        },
        sellArea: '160',
        dealerCd: '05067',
        certificationNumber: 9484447,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HJ701576',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:23:49.850Z'
        }
      },
      sort: [
        1591127121487
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW635588050672020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:45:18.867Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20981.0000',
          mileage: '11605'
        },
        sellArea: '160',
        dealerCd: '05067',
        certificationNumber: 9487195,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW635588',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:24:06.116Z'
        }
      },
      sort: [
        1591127118867
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV2HJ135180050672020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:45:14.691Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19399.00',
          mileage: '27230'
        },
        sellArea: '170',
        dealerCd: '05067',
        certificationNumber: 9478329,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV2HJ135180',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:00:03.375Z'
        }
      },
      sort: [
        1591127114691
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3K1RFV4KC008325042502020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:44:49.070Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '3523'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9438674,
        makeCode: 'TOYOTA',
        vin: '2T3K1RFV4KC008325',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T16:46:06.392Z'
        }
      },
      sort: [
        1591127089070
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV9HJ113077311602020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:44:25.170Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '19228.00',
          mileage: '28688'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9493815,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV9HJ113077',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:55:18.812Z'
        }
      },
      sort: [
        1591127065170
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV5FJ059596042502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:44:08.811Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18410.81',
          mileage: '30808'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9468755,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV5FJ059596',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:23:45.671Z'
        }
      },
      sort: [
        1591127048811
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC9KS018257410702020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:43:49.351Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23992.00',
          mileage: '17331'
        },
        dealerCd: '41070',
        certificationNumber: 9359020,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC9KS018257',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-29T16:58:04.397Z'
        }
      },
      sort: [
        1591127029351
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREVXHD091421042502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:42:51.671Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22995.00',
          mileage: '69919'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9478208,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREVXHD091421',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:08:15.836Z'
        }
      },
      sort: [
        1591126971671
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV5HJ713014211072020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:42:35.071Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18653.00',
          mileage: '41466'
        },
        sellArea: '220',
        certificationNumber: 9465843,
        dealerCd: '21107',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        vin: 'JTMBFREV5HJ713014',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T18:08:31.071Z'
        }
      },
      sort: [
        1591126955071
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU787733311602020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:42:10.732Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '16331.00',
          mileage: '31845'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9493820,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU787733',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:57:26.663Z'
        }
      },
      sort: [
        1591126930732
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP673990042502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:40:36.853Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '41823'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9451212,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP673990',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T16:19:41.973Z'
        }
      },
      sort: [
        1591126836853
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN1KX200647045632020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:40:15.953Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '34500.00',
          mileage: '5343'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9482354,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN1KX200647',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7543',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:42:39.796Z'
        }
      },
      sort: [
        1591126815953
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJK3DC8FS110496041042020-01-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:40:08.996Z',
          retailDate: '2020-01-25',
          rdrStatus: 'H',
          soldAmount: '20591.00',
          mileage: '62704'
        },
        dealerCd: '04104',
        certificationNumber: 9349574,
        makeCode: 'TOYOTA',
        vin: '5TDJK3DC8FS110496',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-22T21:04:33.982Z'
        }
      },
      sort: [
        1591126808996
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV9FJ033910042502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:40:05.652Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19769.57',
          mileage: '54235'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9448672,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV9FJ033910',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T16:09:38.180Z'
        }
      },
      sort: [
        1591126805652
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTKJF5C77GJ024107042502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:39:16.693Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '35436'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9475973,
        makeCode: 'TOYOTA',
        vin: 'JTKJF5C77GJ024107',
        model: {
          modelDescription: 'SCION tC 3 DOOR LIFTBACK',
          modelSeries: 'SCION TC',
          modelNumber: '6222',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:34:12.261Z'
        }
      },
      sort: [
        1591126756693
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE8LP009121042502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:38:43.893Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18100.00',
          mileage: '19898'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9468995,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE8LP009121',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T20:11:57.550Z'
        }
      },
      sort: [
        1591126723893
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK8J3051825121532020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:38:20.492Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '16877.00',
          mileage: '37559'
        },
        sellArea: '600',
        dealerCd: '12153',
        certificationNumber: 9408994,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK8J3051825',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T21:05:42.980Z'
        }
      },
      sort: [
        1591126700492
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1JU100695042502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:38:14.192Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18871.03',
          mileage: '35720'
        },
        sellArea: '110',
        dealerCd: '04250',
        certificationNumber: 9484434,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1JU100695',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T22:19:22.875Z'
        }
      },
      sort: [
        1591126694192
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS516729290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:38:01.314Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '26950.00',
          mileage: '45325'
        },
        sellArea: '160',
        dealerCd: '29025',
        certificationNumber: 9482345,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS516729',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:39:17.533Z'
        }
      },
      sort: [
        1591126681314
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDZKRFH2GS173884041042020-02-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:37:52.994Z',
          retailDate: '2020-02-18',
          rdrStatus: 'H',
          soldAmount: '22992.00',
          mileage: '62097'
        },
        dealerCd: '04104',
        certificationNumber: 9349566,
        makeCode: 'TOYOTA',
        vin: '5TDZKRFH2GS173884',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6946',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-22T21:00:06.680Z'
        }
      },
      sort: [
        1591126672994
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3J1RFV0KW020286290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:36:50.833Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '30500.00',
          mileage: '3716'
        },
        sellArea: '210',
        dealerCd: '29025',
        certificationNumber: 9469797,
        makeCode: 'TOYOTA',
        vin: '2T3J1RFV0KW020286',
        model: {
          modelDescription: 'RAV4 Adventure AWD',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4446',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T16:08:07.340Z'
        }
      },
      sort: [
        1591126610833
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2HJ142192311602020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:36:20.394Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '20918.00',
          mileage: '18514'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9493823,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2HJ142192',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:00:10.764Z'
        }
      },
      sort: [
        1591126580394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE5LP098758290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:36:00.533Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16492.00',
          mileage: '4334'
        },
        sellArea: '500',
        dealerCd: '29025',
        certificationNumber: 9462084,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE5LP098758',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T18:48:08.957Z'
        }
      },
      sort: [
        1591126560533
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0HW672888310692020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:35:08.447Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16999.00',
          mileage: '38604'
        },
        dealerCd: '31069',
        certificationNumber: 9378371,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0HW672888',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-12T20:29:26.701Z'
        }
      },
      sort: [
        1591126508447
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK3KU008158290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:35:03.300Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23500.00',
          mileage: '3127'
        },
        sellArea: '210',
        dealerCd: '29025',
        certificationNumber: 9467240,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK3KU008158',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:13:17.087Z'
        }
      },
      sort: [
        1591126503300
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP665548041042020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:34:56.719Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '15691.00',
          mileage: '31289'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9490224,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP665548',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-31T01:37:11.400Z'
        }
      },
      sort: [
        1591126496719
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F11HX648795045632020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:34:49.980Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '33000.00',
          mileage: '10769'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9475227,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F11HX648795',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T23:04:43.013Z'
        }
      },
      sort: [
        1591126489980
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HK1JU561252290252020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:34:12.121Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23850.00',
          mileage: '20246'
        },
        sellArea: '500',
        dealerCd: '29025',
        certificationNumber: 9467151,
        makeCode: 'TOYOTA',
        vin: '4T1B61HK1JU561252',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T17:29:15.661Z'
        }
      },
      sort: [
        1591126452121
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV6HJ115867480272020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:33:38.539Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19774.00',
          mileage: '35261'
        },
        dealerCd: '48027',
        certificationNumber: 9385828,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV6HJ115867',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T17:06:39.115Z'
        }
      },
      sort: [
        1591126418539
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV5HD103362311602020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:33:26.379Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '25266.00',
          mileage: '16809'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9493828,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV5HD103362',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:02:17.903Z'
        }
      },
      sort: [
        1591126406379
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6HP659431041042020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:32:45.180Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '15291.00',
          mileage: '15366'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9476246,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6HP659431',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:41:07.109Z'
        }
      },
      sort: [
        1591126365180
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU386828311602020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:31:16.499Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '17319.00',
          mileage: '19286'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9493853,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU386828',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:17:50.029Z'
        }
      },
      sort: [
        1591126276499
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH3HS024235041042020-05-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:28:53.217Z',
          retailDate: '2020-05-02',
          rdrStatus: 'H',
          soldAmount: '35792.00',
          mileage: '30279'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9405982,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH3HS024235',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T22:52:44.793Z'
        }
      },
      sort: [
        1591126133217
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH7HS382841045632020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:28:49.296Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '28900.00',
          mileage: '35853'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9425862,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH7HS382841',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T19:29:57.391Z'
        }
      },
      sort: [
        1591126129296
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7G5286512040722020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:27:24.232Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29500.00',
          mileage: '60440'
        },
        sellArea: '120',
        dealerCd: '04072',
        certificationNumber: 9492708,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7G5286512',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:18:08.226Z'
        }
      },
      sort: [
        1591126044232
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYK3DC5FS585196410812020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:26:38.054Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18914.00',
          mileage: '77099'
        },
        sellArea: '220',
        dealerCd: '41081',
        certificationNumber: 9488448,
        makeCode: 'TOYOTA',
        vin: '5TDYK3DC5FS585196',
        model: {
          modelDescription: 'SIENNA XLE PREM FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5349',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T22:54:09.998Z'
        }
      },
      sort: [
        1591125998054
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV8HY156245041042020-03-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:25:59.714Z',
          retailDate: '2020-03-26',
          rdrStatus: 'H',
          soldAmount: '12992.00',
          mileage: '27668'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9405895,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV8HY156245',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T22:39:32.226Z'
        }
      },
      sort: [
        1591125959714
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GNXHX101001043562020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:24:18.895Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '26745.00',
          mileage: '20833'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9494312,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GNXHX101001',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:20:56.818Z'
        }
      },
      sort: [
        1591125858895
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU2G3508533041042020-03-14H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:23:46.559Z',
          retailDate: '2020-03-14',
          rdrStatus: 'H',
          soldAmount: '20991.00',
          mileage: '23622'
        },
        sellArea: '120',
        dealerCd: '04104',
        certificationNumber: 9422168,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU2G3508533',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T14:40:49.730Z'
        }
      },
      sort: [
        1591125826559
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV5HJ116431311602020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:23:15.215Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '21905.00',
          mileage: '5420'
        },
        sellArea: '160',
        dealerCd: '31160',
        certificationNumber: 9494301,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV5HJ116431',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T19:15:33.751Z'
        }
      },
      sort: [
        1591125795215
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR9F5098667045632020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:22:54.072Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '25900.00',
          mileage: '60267'
        },
        sellArea: '110',
        dealerCd: '04563',
        certificationNumber: 9460413,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR9F5098667',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8646',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T04:24:14.222Z'
        }
      },
      sort: [
        1591125774072
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX0J1007413040722020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:21:13.954Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19990.00',
          mileage: '11931'
        },
        sellArea: '120',
        dealerCd: '04072',
        certificationNumber: 9476613,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX0J1007413',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:59:01.316Z'
        }
      },
      sort: [
        1591125673954
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBZV3GY117477041042020-02-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:20:43.212Z',
          retailDate: '2020-02-20',
          rdrStatus: 'H',
          soldAmount: '11292.00',
          mileage: '60000'
        },
        dealerCd: '04104',
        certificationNumber: 9347477,
        makeCode: 'TOYOTA',
        vin: '3MYDLBZV3GY117477',
        model: {
          modelDescription: 'SCION iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-21T20:17:58.947Z'
        }
      },
      sort: [
        1591125643212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP6H3055187041042020-03-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:13:02.406Z',
          retailDate: '2020-03-15',
          rdrStatus: 'H',
          soldAmount: '23092.00',
          mileage: '7942'
        },
        dealerCd: '04104',
        certificationNumber: 9389645,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP6H3055187',
        model: {
          modelDescription: 'PRIUS PRIME PLUS',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1235',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T21:13:19.799Z'
        }
      },
      sort: [
        1591125182406
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV3HW555854045632020-05-10H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:10:25.144Z',
          retailDate: '2020-05-10',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '41023'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9412627,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV3HW555854',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-07T20:35:34.137Z'
        }
      },
      sort: [
        1591125025144
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7HM087186340932020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:09:37.046Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '25750.00',
          mileage: '25731'
        },
        sellArea: '220',
        dealerCd: '34093',
        certificationNumber: 9482441,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7HM087186',
        model: {
          modelDescription: 'TACOMA SR 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:25:29.711Z'
        }
      },
      sort: [
        1591124977046
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK0HU406818130672020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:07:58.865Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '11849.00',
          mileage: '79522'
        },
        sellArea: '210',
        dealerCd: '13067',
        certificationNumber: 9485427,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK0HU406818',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T16:48:15.759Z'
        }
      },
      sort: [
        1591124878865
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F15JX700637130672020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:06:34.025Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '38845.00',
          mileage: '14132'
        },
        sellArea: '210',
        dealerCd: '13067',
        certificationNumber: 9460006,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F15JX700637',
        model: {
          modelDescription: 'TUNDRA 4X4 PLATINUM CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8377',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T19:28:53.491Z'
        }
      },
      sort: [
        1591124794025
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV8HJ127051130702020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T19:00:25.725Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17930.83',
          mileage: '36412'
        },
        sellArea: '210',
        dealerCd: '13070',
        certificationNumber: 9476524,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV8HJ127051',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:17:26.251Z'
        }
      },
      sort: [
        1591124425725
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK8HU311262130702020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:59:43.139Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18395.00',
          mileage: '10867'
        },
        sellArea: '500',
        dealerCd: '13070',
        certificationNumber: 9470634,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK8HU311262',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:43:44.530Z'
        }
      },
      sort: [
        1591124383139
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5HW629695130702020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:59:15.997Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18373.00',
          mileage: '42584'
        },
        sellArea: '160',
        dealerCd: '13070',
        certificationNumber: 9468192,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5HW629695',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T14:16:22.805Z'
        }
      },
      sort: [
        1591124355997
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3HW621420060362020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:54:28.137Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18991.00',
          mileage: '17312'
        },
        sellArea: '160',
        dealerCd: '06036',
        certificationNumber: 9426009,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3HW621420',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T20:33:11.961Z'
        }
      },
      sort: [
        1591124068137
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ168303290812020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:54:18.958Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '20000.00',
          mileage: '9908'
        },
        sellArea: '160',
        dealerCd: '29081',
        certificationNumber: 9486952,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ168303',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:45:59.123Z'
        }
      },
      sort: [
        1591124058958
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU727796160512020-05-28V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:54:11.210Z',
          retailDate: '2020-05-28',
          rdrStatus: 'V',
          soldAmount: '18200.00',
          mileage: '39642'
        },
        sellArea: '500',
        dealerCd: '16051',
        certificationNumber: 9427009,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU727796',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T16:18:04.749Z'
        }
      },
      sort: [
        1591124051210
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV5HJ717935043562020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:51:29.356Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '16306.00',
          mileage: '28322'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9494229,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV5HJ717935',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:48:07.555Z'
        }
      },
      sort: [
        1591123889356
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMN1RFV8KD502972130672020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:48:25.097Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '30950.00',
          mileage: '11959'
        },
        sellArea: '210',
        dealerCd: '13067',
        certificationNumber: 9447001,
        makeCode: 'TOYOTA',
        vin: 'JTMN1RFV8KD502972',
        model: {
          modelDescription: 'RAV4 LIMITED AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T13:15:02.928Z'
        }
      },
      sort: [
        1591123705097
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0HM096201280242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:46:49.568Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27942.00',
          mileage: '24064'
        },
        sellArea: '170',
        dealerCd: '28024',
        certificationNumber: 9473795,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0HM096201',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T10:58:01.160Z'
        }
      },
      sort: [
        1591123609568
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HD218411280242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:45:56.148Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19358.00',
          mileage: '25559'
        },
        sellArea: '170',
        dealerCd: '28024',
        certificationNumber: 9465741,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HD218411',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:09:43.952Z'
        }
      },
      sort: [
        1591123556148
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HJ154329280242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:44:42.056Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20116.00',
          mileage: '26194'
        },
        sellArea: '170',
        dealerCd: '28024',
        certificationNumber: 9464722,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HJ154329',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T19:44:38.790Z'
        }
      },
      sort: [
        1591123482056
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN8KX159633043562020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:44:25.774Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '27494.00',
          mileage: '13615'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9494196,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN8KX159633',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:40:59.790Z'
        }
      },
      sort: [
        1591123465774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3F1RFVXKC009027280242020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:43:33.853Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20326.00',
          mileage: '23986'
        },
        sellArea: '500',
        dealerCd: '28024',
        certificationNumber: 9452341,
        makeCode: 'TOYOTA',
        vin: '2T3F1RFVXKC009027',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T18:01:37.723Z'
        }
      },
      sort: [
        1591123413853
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS509682280242020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:42:39.722Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29090.00',
          mileage: '25117'
        },
        sellArea: '160',
        dealerCd: '28024',
        certificationNumber: 9476884,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS509682',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T10:53:40.939Z'
        }
      },
      sort: [
        1591123359722
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS413217280242020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:41:39.992Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31566.00',
          mileage: '21008'
        },
        sellArea: '160',
        dealerCd: '28024',
        certificationNumber: 9460779,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS413217',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T14:57:18.415Z'
        }
      },
      sort: [
        1591123299992
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP608449130672020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:40:56.052Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '15650.00',
          mileage: '26247'
        },
        sellArea: '500',
        dealerCd: '13067',
        certificationNumber: 9494172,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP608449',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:33:06.143Z'
        }
      },
      sort: [
        1591123256052
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU708338280242020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:40:40.732Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '15474.00',
          mileage: '28249'
        },
        sellArea: '160',
        dealerCd: '28024',
        certificationNumber: 9467461,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU708338',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T20:24:38.254Z'
        }
      },
      sort: [
        1591123240732
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3G1RFV8KW069553110362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:39:50.852Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23991.00',
          mileage: '19617'
        },
        sellArea: '130',
        dealerCd: '11036',
        certificationNumber: 9488203,
        makeCode: 'TOYOTA',
        vin: '2T3G1RFV8KW069553',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T21:13:14.392Z'
        }
      },
      sort: [
        1591123190852
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMW1RFV4KD036347043562020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:38:08.589Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '23532.00',
          mileage: '5291'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9494179,
        makeCode: 'TOYOTA',
        vin: 'JTMW1RFV4KD036347',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:34:39.826Z'
        }
      },
      sort: [
        1591123088589
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH1HS384727190472020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:33:42.609Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '29289.0000',
          mileage: '33754'
        },
        sellArea: '160',
        dealerCd: '19047',
        certificationNumber: 9470527,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH1HS384727',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:04:35.084Z'
        }
      },
      sort: [
        1591122822609
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSX5EN9HX052610360992020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:32:57.485Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23995.00',
          mileage: '30028'
        },
        sellArea: '120',
        dealerCd: '36099',
        certificationNumber: 9482649,
        makeCode: 'TOYOTA',
        vin: '5TFSX5EN9HX052610',
        model: {
          modelDescription: 'TACOMA SR 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7514',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T23:57:23.139Z'
        }
      },
      sort: [
        1591122777485
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU763165043562020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:31:40.828Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '14402.00',
          mileage: '50563'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9494152,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU763165',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:28:03.100Z'
        }
      },
      sort: [
        1591122700828
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3NFREV4HW334990130672020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:30:55.707Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '21795.00',
          mileage: '41983'
        },
        sellArea: '500',
        dealerCd: '13067',
        certificationNumber: 9485701,
        makeCode: 'TOYOTA',
        vin: '2T3NFREV4HW334990',
        model: {
          modelDescription: 'RAV4 SE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4470',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:57:24.682Z'
        }
      },
      sort: [
        1591122655707
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B61HKXJU058772130672020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:28:56.965Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '22950.00',
          mileage: '31899'
        },
        sellArea: '220',
        dealerCd: '13067',
        certificationNumber: 9410100,
        makeCode: 'TOYOTA',
        vin: '4T1B61HKXJU058772',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T18:51:51.365Z'
        }
      },
      sort: [
        1591122536965
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU236405190472020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:27:36.946Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '18500.0000',
          mileage: '15037'
        },
        sellArea: '160',
        dealerCd: '19047',
        certificationNumber: 9463322,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU236405',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T19:29:51.792Z'
        }
      },
      sort: [
        1591122456946
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK5JU086803130672020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:26:39.204Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '18791'
        },
        sellArea: '500',
        dealerCd: '13067',
        certificationNumber: 9484221,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK5JU086803',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T20:37:31.618Z'
        }
      },
      sort: [
        1591122399204
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV5JJ176443040872020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:25:25.726Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21880.00',
          mileage: '12455'
        },
        sellArea: '110',
        dealerCd: '04087',
        certificationNumber: 9491623,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV5JJ176443',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T16:30:30.613Z'
        }
      },
      sort: [
        1591122325726
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZRFHXHS209703130672020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:25:20.546Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '30250.00',
          mileage: '40861'
        },
        sellArea: '500',
        dealerCd: '13067',
        certificationNumber: 9485666,
        makeCode: 'TOYOTA',
        vin: '5TDYZRFHXHS209703',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6954',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T17:48:56.356Z'
        }
      },
      sort: [
        1591122320546
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS392756130672020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:24:00.784Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '26500.00',
          mileage: '45046'
        },
        sellArea: '210',
        dealerCd: '13067',
        certificationNumber: 9481717,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS392756',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:45:58.467Z'
        }
      },
      sort: [
        1591122240784
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV3JW798832040412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:22:46.922Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22490.00',
          mileage: '46912'
        },
        sellArea: '600',
        dealerCd: '04041',
        certificationNumber: 9476375,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV3JW798832',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:22:24.044Z'
        }
      },
      sort: [
        1591122166922
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH7GS298439130672020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:22:04.634Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '22500.00',
          mileage: '51845'
        },
        sellArea: '210',
        dealerCd: '13067',
        certificationNumber: 9482432,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH7GS298439',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:22:18.367Z'
        }
      },
      sort: [
        1591122124634
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F17HX635798470322020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:20:48.756Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '39891.00',
          mileage: '33474'
        },
        sellArea: '800',
        dealerCd: '47032',
        certificationNumber: 9475829,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F17HX635798',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8378',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T15:06:24.899Z'
        }
      },
      sort: [
        1591122048756
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6H5446784040412020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:20:37.036Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '31500.00',
          mileage: '34040'
        },
        sellArea: '110',
        dealerCd: '04041',
        certificationNumber: 9484386,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6H5446784',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T21:57:52.274Z'
        }
      },
      sort: [
        1591122037036
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDW5F14GX503089130672020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:20:17.055Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '30650.00',
          mileage: '72711'
        },
        sellArea: '210',
        dealerCd: '13067',
        certificationNumber: 9483829,
        makeCode: 'TOYOTA',
        vin: '5TFDW5F14GX503089',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8363',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T18:10:31.005Z'
        }
      },
      sort: [
        1591122017055
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV8JW460955043562020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:20:11.294Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '19938.00',
          mileage: '22716'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9494133,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV8JW460955',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:17:02.092Z'
        }
      },
      sort: [
        1591122011294
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH2HS416233121452020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:18:35.715Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28750.00',
          mileage: '25065'
        },
        sellArea: '210',
        dealerCd: '12145',
        certificationNumber: 9482072,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH2HS416233',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:06:29.474Z'
        }
      },
      sort: [
        1591121915715
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3WFREV8JW511583040412020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:17:14.592Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '24990.00',
          mileage: '18054'
        },
        sellArea: '600',
        dealerCd: '04041',
        certificationNumber: 9476362,
        makeCode: 'TOYOTA',
        vin: '2T3WFREV8JW511583',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T19:18:57.281Z'
        }
      },
      sort: [
        1591121834592
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HJ121253371482020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:15:29.812Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19299.00',
          mileage: '35133'
        },
        dealerCd: '37148',
        certificationNumber: 9386222,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HJ121253',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T20:38:43.622Z'
        }
      },
      sort: [
        1591121729812
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV1JD121504043562020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:14:34.152Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '21962.00',
          mileage: '29000'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9494118,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV1JD121504',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:11:47.508Z'
        }
      },
      sort: [
        1591121674152
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN7HM056116371122020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:13:57.651Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28990.00',
          mileage: '34176'
        },
        sellArea: '800',
        dealerCd: '37112',
        certificationNumber: 9482419,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN7HM056116',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:13:51.642Z'
        }
      },
      sort: [
        1591121637651
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HJ707412371732020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:13:39.212Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18829.20',
          mileage: '14557'
        },
        sellArea: '800',
        dealerCd: '37173',
        certificationNumber: 9487525,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HJ707412',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:19:50.177Z'
        }
      },
      sort: [
        1591121619212
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU394571210672020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:13:17.650Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16650.00',
          mileage: '30996'
        },
        sellArea: '210',
        dealerCd: '21067',
        certificationNumber: 9424949,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU394571',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T13:40:28.277Z'
        }
      },
      sort: [
        1591121597650
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREVXHW606377360802020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:12:15.709Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19977.00',
          mileage: '39643'
        },
        sellArea: '150',
        dealerCd: '36080',
        certificationNumber: 9443388,
        makeCode: 'TOYOTA',
        vin: '2T3RFREVXHW606377',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-11T00:38:36.337Z'
        }
      },
      sort: [
        1591121535709
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH0HS394214360802020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:10:33.150Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '31677.00',
          mileage: '29898'
        },
        sellArea: '130',
        dealerCd: '36080',
        certificationNumber: 9445280,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH0HS394214',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-16T23:00:20.074Z'
        }
      },
      sort: [
        1591121433150
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV7HW363479130752020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:10:06.689Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17942.00',
          mileage: '30737'
        },
        sellArea: '210',
        dealerCd: '13075',
        certificationNumber: 9485088,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV7HW363479',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T13:59:37.184Z'
        }
      },
      sort: [
        1591121406689
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDFREV1JJ211342110302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:08:29.849Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29768.00',
          mileage: '8943'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9489122,
        makeCode: 'TOYOTA',
        vin: 'JTMDFREV1JJ211342',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T15:07:11.419Z'
        }
      },
      sort: [
        1591121309849
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3KM250214250612020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:08:13.628Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '32982.00',
          mileage: '15251'
        },
        dealerCd: '25061',
        certificationNumber: 9337201,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3KM250214',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-14T22:38:15.203Z'
        }
      },
      sort: [
        1591121293628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC8HS786438043562020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:07:33.248Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '25775.00',
          mileage: '34966'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9494100,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC8HS786438',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:04:31.929Z'
        }
      },
      sort: [
        1591121253248
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV8FW394781250612020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:06:53.746Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19991.00',
          mileage: '60609'
        },
        certificationNumber: 9337225,
        dealerCd: '25061',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        vin: '2T3DFREV8FW394781',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-14T22:47:17.725Z'
        }
      },
      sort: [
        1591121213746
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU797200110302020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:06:39.527Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17431.13',
          mileage: '8706'
        },
        sellArea: '130',
        dealerCd: '11030',
        certificationNumber: 9483490,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU797200',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T15:44:44.771Z'
        }
      },
      sort: [
        1591121199527
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1J5608357250612020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:06:30.365Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '39792.00',
          mileage: '13796'
        },
        dealerCd: '25061',
        certificationNumber: 9264293,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1J5608357',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-26T23:21:00.972Z'
        }
      },
      sort: [
        1591121190365
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7H5468177371122020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:06:24.966Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '31990.00',
          mileage: '37584'
        },
        sellArea: '800',
        dealerCd: '37112',
        certificationNumber: 9494094,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7H5468177',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T18:00:49.944Z'
        }
      },
      sort: [
        1591121184966
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6GP459759041372020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:05:19.503Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '13900.00',
          mileage: '35268'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9491838,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6GP459759',
        model: {
          modelDescription: 'COROLLA S PLUS',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:16:57.387Z'
        }
      },
      sort: [
        1591121119503
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV8KC005319201442020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:04:44.147Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '23482.00',
          mileage: '18994'
        },
        dealerCd: '20144',
        certificationNumber: 9383566,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV8KC005319',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T15:07:02.817Z'
        }
      },
      sort: [
        1591121084147
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDL9RFUXK3000171480452020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:02:49.904Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '23499.00',
          mileage: '12400'
        },
        sellArea: '210',
        dealerCd: '48045',
        certificationNumber: 9451562,
        makeCode: 'TOYOTA',
        vin: 'JTDL9RFUXK3000171',
        model: {
          modelDescription: 'PRIUS LE AWD-e HYBRID',
          modelSeries: 'PRIUS AWD',
          modelNumber: '1263',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-28T20:08:52.343Z'
        }
      },
      sort: [
        1591120969904
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN2GX055694041372020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:02:25.585Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23000.00',
          mileage: '55780'
        },
        sellArea: '110',
        dealerCd: '04137',
        certificationNumber: 9491773,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN2GX055694',
        model: {
          modelDescription: 'TACOMA SR 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7186',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:02:16.762Z'
        }
      },
      sort: [
        1591120945585
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN2KM095983043562020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:01:14.804Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '30787.00',
          mileage: '17127'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9494088,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN2KM095983',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7148',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:58:10.103Z'
        }
      },
      sort: [
        1591120874804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU5H3025956180352020-01-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:00:31.463Z',
          retailDate: '2020-01-30',
          rdrStatus: 'H',
          soldAmount: '20991.00',
          mileage: '15697'
        },
        dealerCd: '18035',
        certificationNumber: 9355858,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU5H3025956',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-27T20:50:48.818Z'
        }
      },
      sort: [
        1591120831463
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV1HD082588371812020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T18:00:17.944Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24700.00',
          mileage: '25250'
        },
        sellArea: '800',
        dealerCd: '37181',
        certificationNumber: 9409667,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV1HD082588',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T15:00:41.913Z'
        }
      },
      sort: [
        1591120817944
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK0JU644069310972020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:58:33.521Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '24228.00',
          mileage: '37624'
        },
        sellArea: '600',
        dealerCd: '31097',
        certificationNumber: 9409077,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK0JU644069',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T21:42:34.780Z'
        }
      },
      sort: [
        1591120713521
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU2G3511977360492020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:56:06.521Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '17496.34',
          mileage: '33057'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9467290,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU2G3511977',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:42:31.565Z'
        }
      },
      sort: [
        1591120566521
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU329712049902020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:55:45.620Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '15622.00',
          mileage: '35428'
        },
        dealerCd: '04990',
        certificationNumber: 9332888,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU329712',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-11T20:10:52.076Z'
        }
      },
      sort: [
        1591120545620
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE3LP035481043562020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:55:14.579Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '18982.00',
          mileage: '9083'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9494071,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE3LP035481',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:52:31.396Z'
        }
      },
      sort: [
        1591120514579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEBRBE3LJ019539360492020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:53:43.520Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18100.97',
          mileage: '5262'
        },
        sellArea: '130',
        dealerCd: '36049',
        certificationNumber: 9459088,
        makeCode: 'TOYOTA',
        vin: 'JTDEBRBE3LJ019539',
        model: {
          modelDescription: 'COROLLA HYBRID HYBRID LE SEDAN',
          modelSeries: 'COROLLAHYBRID',
          modelNumber: '1882',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T20:48:34.661Z'
        }
      },
      sort: [
        1591120423520
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK7KU702213049902020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:52:34.279Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '19893.00',
          mileage: '35158'
        },
        sellArea: '500',
        dealerCd: '04990',
        certificationNumber: 9424719,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK7KU702213',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T22:39:27.159Z'
        }
      },
      sort: [
        1591120354279
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN9HM091420361032020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:50:03.558Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '32984.00',
          mileage: '29405'
        },
        sellArea: '130',
        dealerCd: '36103',
        certificationNumber: 9494057,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN9HM091420',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:47:03.369Z'
        }
      },
      sort: [
        1591120203558
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK8J3040940043562020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:49:18.115Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '14987.00',
          mileage: '23445'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9493920,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK8J3040940',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:46:47.861Z'
        }
      },
      sort: [
        1591120158115
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU796492360492020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:47:39.755Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '15949.00',
          mileage: '50243'
        },
        sellArea: '600',
        dealerCd: '36049',
        certificationNumber: 9493287,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU796492',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T00:00:10.252Z'
        }
      },
      sort: [
        1591120059755
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU125010043562020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:46:12.395Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '22116.00',
          mileage: '37312'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9494045,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU125010',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:42:30.388Z'
        }
      },
      sort: [
        1591119972395
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0LS033581310242020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:46:00.856Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31495.00',
          mileage: '9383'
        },
        sellArea: '160',
        dealerCd: '31024',
        certificationNumber: 9445344,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC0LS033581',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-17T12:50:23.700Z'
        }
      },
      sort: [
        1591119960856
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR8G5335314280202020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:44:08.094Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30979.00',
          mileage: '38629'
        },
        sellArea: '170',
        dealerCd: '28020',
        certificationNumber: 9481889,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR8G5335314',
        model: {
          modelDescription: '4RUNNER 4X4 LIMITED V6',
          modelSeries: '4RUNNER',
          modelNumber: '8668',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:01:31.301Z'
        }
      },
      sort: [
        1591119848094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5561567371872020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:40:21.712Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '33643.00',
          mileage: '48195'
        },
        sellArea: '800',
        dealerCd: '37187',
        certificationNumber: 9482062,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5561567',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T19:03:58.815Z'
        }
      },
      sort: [
        1591119621712
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX0JR009300049902020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:39:36.752Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '22900.00',
          mileage: '44773'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9494029,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX0JR009300',
        model: {
          modelDescription: 'C-HR XLE PREMIUM',
          modelSeries: 'CHR',
          modelNumber: '2406',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:34:26.959Z'
        }
      },
      sort: [
        1591119576752
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDGZRBH8LS001068180352020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:38:04.951Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '39937.00',
          mileage: '710'
        },
        sellArea: '170',
        dealerCd: '18035',
        certificationNumber: 9493746,
        makeCode: 'TOYOTA',
        vin: '5TDGZRBH8LS001068',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: null,
          modelNumber: '6953',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:15:26.017Z'
        }
      },
      sort: [
        1591119484951
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU729952043562020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:35:06.470Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '21977.00',
          mileage: '40502'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9494018,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU729952',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:31:20.178Z'
        }
      },
      sort: [
        1591119306470
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F18KX860777270132020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:33:22.970Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '41973.00',
          mileage: '5598'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9485909,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F18KX860777',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:05:12.031Z'
        }
      },
      sort: [
        1591119202970
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC0HS781349040222020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:32:08.249Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24000.00',
          mileage: '54606'
        },
        sellArea: '110',
        dealerCd: '04022',
        certificationNumber: 9414080,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC0HS781349',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-09T20:09:49.366Z'
        }
      },
      sort: [
        1591119128249
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH6KS713419270132020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:32:06.068Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '32550.00',
          mileage: '4797'
        },
        sellArea: '150',
        dealerCd: '27013',
        certificationNumber: 9477721,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH6KS713419',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:31:17.550Z'
        }
      },
      sort: [
        1591119126068
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU326746240672020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:31:08.467Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16575.00',
          mileage: '22035'
        },
        sellArea: '800',
        dealerCd: '24067',
        certificationNumber: 9493958,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU326746',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:02:49.757Z'
        }
      },
      sort: [
        1591119068467
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK5HU632840040222020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:30:26.989Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16899.00',
          mileage: '57313'
        },
        sellArea: '110',
        dealerCd: '04022',
        certificationNumber: 9443108,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK5HU632840',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-10T19:09:41.396Z'
        }
      },
      sort: [
        1591119026989
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH8HS030595045632020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:29:34.928Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '36500.00',
          mileage: '24473'
        },
        sellArea: '120',
        dealerCd: '04563',
        certificationNumber: 9490063,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH8HS030595',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T22:52:53.767Z'
        }
      },
      sort: [
        1591118974928
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA19G9705252049902020-04-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:27:47.347Z',
          retailDate: '2020-04-21',
          rdrStatus: 'H',
          soldAmount: '16994.00',
          mileage: '33168'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9449541,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA19G9705252',
        model: {
          modelDescription: 'SCION FR-S 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6253',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-25T19:29:36.609Z'
        }
      },
      sort: [
        1591118867347
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX9K1029248041592020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:26:53.628Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24435.00',
          mileage: '11180'
        },
        sellArea: '110',
        dealerCd: '04159',
        certificationNumber: 9450203,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX9K1029248',
        model: {
          modelDescription: 'C-HR LE',
          modelSeries: 'CHR',
          modelNumber: '2402',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T17:05:26.383Z'
        }
      },
      sort: [
        1591118813628
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN8KX148941043562020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:22:52.587Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '26443.00',
          mileage: '19730'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9493978,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN8KX148941',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:15:11.553Z'
        }
      },
      sort: [
        1591118572587
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA12H8710543049902020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:22:08.387Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '26598'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9469879,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA12H8710543',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-16T17:56:16.931Z'
        }
      },
      sort: [
        1591118528387
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC8HS858324140152020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:20:37.626Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '33894'
        },
        sellArea: '800',
        dealerCd: '14015',
        certificationNumber: 9493376,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC8HS858324',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T00:48:36.909Z'
        }
      },
      sort: [
        1591118437626
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFSZ5AN2KX190917180342020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:18:46.485Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '29275.00',
          mileage: '9734'
        },
        sellArea: '170',
        dealerCd: '18034',
        certificationNumber: 9425258,
        makeCode: 'TOYOTA',
        vin: '5TFSZ5AN2KX190917',
        model: {
          modelDescription: 'TACOMA SR5 4X4 ACCESS CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7558',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T14:45:21.531Z'
        }
      },
      sort: [
        1591118326485
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HD205874140152020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:17:40.805Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '18450.00',
          mileage: '43261'
        },
        sellArea: '210',
        dealerCd: '14015',
        certificationNumber: 9420654,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HD205874',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T21:01:24.842Z'
        }
      },
      sort: [
        1591118260805
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU344498140152020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:16:38.038Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '17199.00',
          mileage: '29864'
        },
        sellArea: '220',
        dealerCd: '14015',
        certificationNumber: 9449877,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU344498',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-27T13:52:52.558Z'
        }
      },
      sort: [
        1591118198038
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS417991260312020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:15:43.164Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28500.00',
          mileage: '24677'
        },
        sellArea: '230',
        dealerCd: '26031',
        certificationNumber: 9492240,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS417991',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:16:29.927Z'
        }
      },
      sort: [
        1591118143164
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6K5612356049902020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:13:30.777Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '37900.00',
          mileage: '28784'
        },
        sellArea: '110',
        dealerCd: '04990',
        certificationNumber: 9480467,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6K5612356',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T16:52:24.191Z'
        }
      },
      sort: [
        1591118010777
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN5KX192407048192020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:11:08.843Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '48990.00',
          mileage: '996'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9487131,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN5KX192407',
        model: {
          modelDescription: 'TACOMA TRD PRO 4X4 DBL CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7598',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:04:38.139Z'
        }
      },
      sort: [
        1591117868843
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JRXK5661222043562020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:10:48.603Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '32210.00',
          mileage: '31659'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9493965,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JRXK5661222',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T17:06:32.300Z'
        }
      },
      sort: [
        1591117848603
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS457291311012020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:05:30.242Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '29000.00',
          mileage: '25625'
        },
        sellArea: '160',
        dealerCd: '31101',
        certificationNumber: 9461928,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS457291',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T15:16:17.632Z'
        }
      },
      sort: [
        1591117530242
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7H5434207040512020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:05:02.862Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '32900.00',
          mileage: '41328'
        },
        sellArea: '120',
        dealerCd: '04051',
        certificationNumber: 9491369,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7H5434207',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:07:25.976Z'
        }
      },
      sort: [
        1591117502862
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFHXGS262838040512020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:04:07.581Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26900.00',
          mileage: '61220'
        },
        sellArea: '120',
        dealerCd: '04051',
        certificationNumber: 9442580,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFHXGS262838',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T20:43:42.758Z'
        }
      },
      sort: [
        1591117447581
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZRFH6HS512662043562020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:03:41.021Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '25540.00',
          mileage: '35865'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9493896,
        makeCode: 'TOYOTA',
        vin: '5TDKZRFH6HS512662',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 FWD',
          modelSeries: 'HIGHLANDER FWD',
          modelNumber: '6951',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:36:42.409Z'
        }
      },
      sort: [
        1591117421021
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6J5585254040512020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T17:03:12.617Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '36000.00',
          mileage: '30505'
        },
        sellArea: '120',
        dealerCd: '04051',
        certificationNumber: 9487699,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6J5585254',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T18:26:16.133Z'
        }
      },
      sort: [
        1591117392617
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB5GU219165210432020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:58:03.361Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19000.00',
          mileage: '45249'
        },
        sellArea: '220',
        dealerCd: '21043',
        certificationNumber: 9493876,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB5GU219165',
        model: {
          modelDescription: 'AVALON 4-DR XLE PREMIUM',
          modelSeries: 'AVALON',
          modelNumber: '3546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:28:05.648Z'
        }
      },
      sort: [
        1591117083361
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1HC905270042782020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:56:30.021Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16964.00',
          mileage: '45980'
        },
        sellArea: '110',
        dealerCd: '04278',
        certificationNumber: 9478352,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1HC905270',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T22:07:34.758Z'
        }
      },
      sort: [
        1591116990021
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK9ER363114041672020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:56:18.795Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '12150.00',
          mileage: '73485'
        },
        sellArea: '110',
        dealerCd: '04167',
        certificationNumber: 9407540,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK9ER363114',
        model: {
          modelDescription: 'CAMRY 2014.5 XLE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T21:37:38.026Z'
        }
      },
      sort: [
        1591116978795
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV0KC042414120152020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:54:58.020Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26706.00',
          mileage: '15199'
        },
        sellArea: '210',
        dealerCd: '12015',
        certificationNumber: 9452441,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV0KC042414',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T19:00:22.774Z'
        }
      },
      sort: [
        1591116898020
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE3LP034802043562020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:54:07.219Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18783.00',
          mileage: '13785'
        },
        sellArea: '110',
        dealerCd: '04356',
        certificationNumber: 9432468,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE3LP034802',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T22:00:09.945Z'
        }
      },
      sort: [
        1591116847219
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3HS513831480452020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:51:32.757Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29756.00',
          mileage: '35846'
        },
        sellArea: '210',
        dealerCd: '48045',
        certificationNumber: 9437128,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3HS513831',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-31T14:02:26.333Z'
        }
      },
      sort: [
        1591116692757
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3H1RFV7KW003645043562020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:51:10.816Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '23278.00',
          mileage: '30987'
        },
        sellArea: '600',
        dealerCd: '04356',
        certificationNumber: 9432356,
        makeCode: 'TOYOTA',
        vin: '2T3H1RFV7KW003645',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-24T20:58:02.391Z'
        }
      },
      sort: [
        1591116670816
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2JU132670042782020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:49:10.818Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18288.00',
          mileage: '19642'
        },
        sellArea: '110',
        dealerCd: '04278',
        certificationNumber: 9456788,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2JU132670',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T17:22:36.722Z'
        }
      },
      sort: [
        1591116550818
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMW1RFV6KD011661043562020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:48:57.237Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23988.00',
          mileage: '30967'
        },
        sellArea: '500',
        dealerCd: '04356',
        certificationNumber: 9431295,
        makeCode: 'TOYOTA',
        vin: 'JTMW1RFV6KD011661',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T22:03:33.564Z'
        }
      },
      sort: [
        1591116537237
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK8KU513290042782020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:46:44.814Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21404.00',
          mileage: '16929'
        },
        sellArea: '110',
        dealerCd: '04278',
        certificationNumber: 9468735,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK8KU513290',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T18:16:11.333Z'
        }
      },
      sort: [
        1591116404814
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5G5379285042782020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:45:14.796Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '34495.00',
          mileage: '29901'
        },
        sellArea: '600',
        dealerCd: '04278',
        certificationNumber: 9469185,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5G5379285',
        model: {
          modelDescription: '4RUNNER 4X4 TRAIL PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T22:03:48.379Z'
        }
      },
      sort: [
        1591116314796
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK1JU070422371612020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:44:14.435Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19078.00',
          mileage: '34055'
        },
        sellArea: '800',
        dealerCd: '37161',
        certificationNumber: 9476665,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK1JU070422',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T21:26:04.846Z'
        }
      },
      sort: [
        1591116254435
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B31HK2KU513379042782020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:43:47.556Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '27672.00',
          mileage: '15242'
        },
        sellArea: '110',
        dealerCd: '04278',
        certificationNumber: 9469023,
        makeCode: 'TOYOTA',
        vin: '4T1B31HK2KU513379',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T20:22:58.280Z'
        }
      },
      sort: [
        1591116227556
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU344273311802020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:42:16.568Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '10634'
        },
        sellArea: '160',
        dealerCd: '31180',
        certificationNumber: 9427056,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU344273',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T16:34:56.831Z'
        }
      },
      sort: [
        1591116136568
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBEXK3043154042782020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:41:49.689Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17485.00',
          mileage: '12611'
        },
        sellArea: '110',
        dealerCd: '04278',
        certificationNumber: 9475855,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBEXK3043154',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T15:22:48.428Z'
        }
      },
      sort: [
        1591116109689
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR7J5494171460992020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:40:01.368Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '38605.00',
          mileage: '29485'
        },
        sellArea: '130',
        dealerCd: '46099',
        certificationNumber: 9434921,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR7J5494171',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T17:23:13.325Z'
        }
      },
      sort: [
        1591116001368
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH7GS339889460992020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:38:43.713Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '33329.02',
          mileage: '34995'
        },
        sellArea: '130',
        dealerCd: '46099',
        certificationNumber: 9489590,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH7GS339889',
        model: {
          modelDescription: 'HIGHLANDER LIMITED PLT - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6957',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T18:06:20.939Z'
        }
      },
      sort: [
        1591115923713
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9JW821691043882020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:38:00.515Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '21675.56',
          mileage: '36385'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9476604,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9JW821691',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:51:31.881Z'
        }
      },
      sort: [
        1591115880515
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DCXHS769358043882020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:37:35.754Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '22695.00',
          mileage: '36726'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9474820,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DCXHS769358',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T19:04:36.456Z'
        }
      },
      sort: [
        1591115855754
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0HD217911460992020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:37:31.793Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23037.00',
          mileage: '25258'
        },
        sellArea: '130',
        dealerCd: '46099',
        certificationNumber: 9465082,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0HD217911',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T23:50:05.009Z'
        }
      },
      sort: [
        1591115851793
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN2KM099550043882020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:37:08.359Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '32995.00',
          mileage: '20678'
        },
        sellArea: '600',
        dealerCd: '04388',
        certificationNumber: 9485330,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN2KM099550',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7150',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:50:08.035Z'
        }
      },
      sort: [
        1591115828359
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM087008043882020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:36:44.059Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '32450.00',
          mileage: '35439'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9481714,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM087008',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:44:17.405Z'
        }
      },
      sort: [
        1591115804059
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN7HX086327043882020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:36:22.523Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '28195.00',
          mileage: '30480'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9426055,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN7HX086327',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-18T20:49:57.663Z'
        }
      },
      sort: [
        1591115782523
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU729719020312020-04-21V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:36:14.064Z',
          retailDate: '2020-04-21',
          rdrStatus: 'V',
          soldAmount: '23540.00',
          mileage: '25897'
        },
        sellArea: '150',
        dealerCd: '02031',
        certificationNumber: 9434055,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU729719',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-26T17:54:39.954Z'
        }
      },
      sort: [
        1591115774064
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3K1RFV1LC033376480322020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:36:09.119Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23899.00',
          mileage: '2736'
        },
        sellArea: '210',
        dealerCd: '48032',
        certificationNumber: 9455811,
        makeCode: 'TOYOTA',
        vin: '2T3K1RFV1LC033376',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T23:00:09.013Z'
        }
      },
      sort: [
        1591115769119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFJU4GN0FX080997043882020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:36:03.774Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '25995.00',
          mileage: '43585'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9485347,
        makeCode: 'TOYOTA',
        vin: '5TFJU4GN0FX080997',
        model: {
          modelDescription: 'TACOMA PRERUNNER DOUBLE CAB 4X2 V6',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7188',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:58:27.335Z'
        }
      },
      sort: [
        1591115763774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFJX4CN0EX039975043882020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:35:39.097Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '43662.31',
          mileage: '68805'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9481828,
        makeCode: 'TOYOTA',
        vin: '5TFJX4CN0EX039975',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X2',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7182',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T17:35:18.628Z'
        }
      },
      sort: [
        1591115739097
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE6K3002875047532020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:35:11.378Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16998.00',
          mileage: '20551'
        },
        sellArea: '120',
        dealerCd: '04753',
        certificationNumber: 9407878,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE6K3002875',
        model: {
          modelDescription: 'COROLLA HATCHBACK XSE 5DrHATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6275',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T01:01:05.980Z'
        }
      },
      sort: [
        1591115711378
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDXZ3DC2KS012175310972020-05-16H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:34:31.457Z',
          retailDate: '2020-05-16',
          rdrStatus: 'H',
          soldAmount: '41240.00',
          mileage: '17250'
        },
        sellArea: '800',
        dealerCd: '31097',
        certificationNumber: 9409085,
        makeCode: 'TOYOTA',
        vin: '5TDXZ3DC2KS012175',
        model: {
          modelDescription: 'SIENNA SE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5342',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T21:47:56.302Z'
        }
      },
      sort: [
        1591115671457
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F14KX868259043882020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:33:35.397Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '43662.31',
          mileage: '5495'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9473027,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F14KX868259',
        model: {
          modelDescription: 'TUNDRA TRD PRO CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8371',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T18:29:50.976Z'
        }
      },
      sort: [
        1591115615397
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1K61AK4LU301893045412020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:32:50.837Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '9454'
        },
        sellArea: '120',
        dealerCd: '04541',
        certificationNumber: 9488524,
        makeCode: 'TOYOTA',
        vin: '4T1K61AK4LU301893',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T23:54:50.661Z'
        }
      },
      sort: [
        1591115570837
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJFREVXHJ141482310972020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:32:05.037Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '23888.00',
          mileage: '15737'
        },
        sellArea: '160',
        dealerCd: '31097',
        certificationNumber: 9409669,
        makeCode: 'TOYOTA',
        vin: 'JTMJFREVXHJ141482',
        model: {
          modelDescription: 'RAV4 SE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4472',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T15:06:38.534Z'
        }
      },
      sort: [
        1591115525037
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFDY5F10JX719958043882020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:31:10.016Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '39509.08',
          mileage: '17690'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9470849,
        makeCode: 'TOYOTA',
        vin: '5TFDY5F10JX719958',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 CREWMAX',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8361',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T16:45:50.263Z'
        }
      },
      sort: [
        1591115470016
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5LM320488190502020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:30:07.116Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '33495.00',
          mileage: '311'
        },
        sellArea: '800',
        dealerCd: '19050',
        certificationNumber: 9416068,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5LM320488',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T22:37:50.821Z'
        }
      },
      sort: [
        1591115407116
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH9GS332748310882020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:30:01.208Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '34181'
        },
        dealerCd: '31088',
        certificationNumber: 8216718,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH9GS332748',
        model: {
          modelDescription: 'HIGHLANDER',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: '2016'
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-07-01T00:00:00.000Z'
        }
      },
      sort: [
        1591115401208
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3HU714419310972020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:29:44.613Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '14999.00',
          mileage: '42081'
        },
        sellArea: '600',
        dealerCd: '31097',
        certificationNumber: 9400582,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3HU714419',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T14:43:49.851Z'
        }
      },
      sort: [
        1591115384613
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAW5F16JX739334480602020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:29:41.992Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '37200.00',
          mileage: '48391'
        },
        sellArea: '210',
        dealerCd: '48060',
        certificationNumber: 9475929,
        makeCode: 'TOYOTA',
        vin: '5TFAW5F16JX739334',
        model: {
          modelDescription: 'TUNDRA 4X4 1794 CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8378',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:10:56.787Z'
        }
      },
      sort: [
        1591115381992
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV0HD214909460942020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:29:07.750Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '25998.00',
          mileage: '40988'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9478542,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV0HD214909',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T00:46:17.997Z'
        }
      },
      sort: [
        1591115347750
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE3JP751425041352020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:28:07.594Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '16995.00',
          mileage: '16865'
        },
        sellArea: '110',
        dealerCd: '04135',
        certificationNumber: 9482044,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE3JP751425',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T18:59:25.435Z'
        }
      },
      sort: [
        1591115287594
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU027360310972020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:27:28.754Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '25540.00',
          mileage: '25384'
        },
        sellArea: '160',
        dealerCd: '31097',
        certificationNumber: 9400556,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXJU027360',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T14:27:50.797Z'
        }
      },
      sort: [
        1591115248754
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE0HP645640460942020-05-09H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:26:52.013Z',
          retailDate: '2020-05-09',
          rdrStatus: 'H',
          soldAmount: '16500.00',
          mileage: '16617'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9442324,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE0HP645640',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-09T15:51:58.456Z'
        }
      },
      sort: [
        1591115212013
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE9HP604696041352020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:26:41.314Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '14995.00',
          mileage: '53987'
        },
        sellArea: '110',
        dealerCd: '04135',
        certificationNumber: 9466345,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE9HP604696',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T22:42:46.564Z'
        }
      },
      sort: [
        1591115201314
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'NMTKHMBX4JR024320040612020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:26:21.094Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18346.00',
          mileage: '46976'
        },
        sellArea: '110',
        dealerCd: '04061',
        certificationNumber: 9482659,
        makeCode: 'TOYOTA',
        vin: 'NMTKHMBX4JR024320',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T00:07:47.223Z'
        }
      },
      sort: [
        1591115181094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1HC765236480272020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:26:06.573Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '13392.00',
          mileage: '33491'
        },
        sellArea: '210',
        dealerCd: '48027',
        certificationNumber: 9428812,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1HC765236',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T18:51:22.862Z'
        }
      },
      sort: [
        1591115166573
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV8JD199350460942020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:25:45.234Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '34358.00',
          mileage: '17404'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9456597,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV8JD199350',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-04T14:54:57.518Z'
        }
      },
      sort: [
        1591115145234
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREVXHJ121445041352020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:24:45.394Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17878.80',
          mileage: '38248'
        },
        dealerCd: '04135',
        certificationNumber: 9368740,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREVXHJ121445',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T23:35:37.250Z'
        }
      },
      sort: [
        1591115085394
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS427223460942020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:24:41.675Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '28750.00',
          mileage: '25189'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9455210,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS427223',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T14:29:58.434Z'
        }
      },
      sort: [
        1591115081675
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS361471310972020-05-15H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:24:25.315Z',
          retailDate: '2020-05-15',
          rdrStatus: 'H',
          soldAmount: '33268.00',
          mileage: '14052'
        },
        sellArea: '160',
        dealerCd: '31097',
        certificationNumber: 9400517,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS361471',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T14:08:07.034Z'
        }
      },
      sort: [
        1591115065315
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3ZFREV8JW446456041352020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:23:47.493Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '17988.00',
          mileage: '46547'
        },
        dealerCd: '04135',
        certificationNumber: 9363590,
        makeCode: 'TOYOTA',
        vin: '2T3ZFREV8JW446456',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-31T19:31:04.219Z'
        }
      },
      sort: [
        1591115027493
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS396768460942020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:23:22.556Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '29885.00',
          mileage: '31896'
        },
        sellArea: '210',
        dealerCd: '46094',
        certificationNumber: 9469316,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS396768',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T23:43:50.185Z'
        }
      },
      sort: [
        1591115002556
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1FU497255311732020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:23:20.714Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '13888.00',
          mileage: '55005'
        },
        sellArea: '160',
        dealerCd: '31173',
        certificationNumber: 9493860,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1FU497255',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:19:29.646Z'
        }
      },
      sort: [
        1591115000714
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV8HJ125039280122020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:23:08.174Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '42419'
        },
        sellArea: '170',
        dealerCd: '28012',
        certificationNumber: 9430904,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV8HJ125039',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T18:47:21.593Z'
        }
      },
      sort: [
        1591114988174
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEZU5JR4G5147467041352020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:22:35.356Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '29788.00',
          mileage: '56841'
        },
        sellArea: '110',
        dealerCd: '04135',
        certificationNumber: 9441870,
        makeCode: 'TOYOTA',
        vin: 'JTEZU5JR4G5147467',
        model: {
          modelDescription: '4RUNNER 4X2 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8642',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T17:05:19.966Z'
        }
      },
      sort: [
        1591114955356
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMDJREV1HD104864460942020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:22:19.773Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '27100.00',
          mileage: '29286'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9467226,
        makeCode: 'TOYOTA',
        vin: 'JTMDJREV1HD104864',
        model: {
          modelDescription: 'RAV4 HYBRID LIMITED AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4454',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:07:48.725Z'
        }
      },
      sort: [
        1591114939773
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV0HJ703862041352020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:21:49.892Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17888.00',
          mileage: '40929'
        },
        sellArea: '110',
        dealerCd: '04135',
        certificationNumber: 9401105,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV0HJ703862',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-28T17:28:28.414Z'
        }
      },
      sort: [
        1591114909892
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMJJREV4JD228054460942020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:21:31.255Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '33995.00',
          mileage: '16399'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9459313,
        makeCode: 'TOYOTA',
        vin: 'JTMJJREV4JD228054',
        model: {
          modelDescription: 'RAV4 HYBRID SE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-06T23:58:37.081Z'
        }
      },
      sort: [
        1591114891255
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV6HD091096460942020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:20:33.168Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '24265.00',
          mileage: '30572'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9454509,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV6HD091096',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T22:46:20.847Z'
        }
      },
      sort: [
        1591114833168
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRW5F19GX195939043882020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:19:49.614Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '26500.00',
          mileage: '79433'
        },
        sellArea: '600',
        dealerCd: '04388',
        certificationNumber: 9485343,
        makeCode: 'TOYOTA',
        vin: '5TFRW5F19GX195939',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8231',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:56:34.716Z'
        }
      },
      sort: [
        1591114789614
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFRW5F15GX207908043882020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:19:26.375Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25878.00',
          mileage: '64430'
        },
        sellArea: '600',
        dealerCd: '04388',
        certificationNumber: 9481718,
        makeCode: 'TOYOTA',
        vin: '5TFRW5F15GX207908',
        model: {
          modelDescription: 'TUNDRA 4X2 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X2',
          modelNumber: '8231',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:46:17.026Z'
        }
      },
      sort: [
        1591114766375
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK8ER429927460942020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:19:24.429Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '12500.00',
          mileage: '47056'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9470512,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK8ER429927',
        model: {
          modelDescription: 'CAMRY 2014.5 LE Sedan',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T15:01:18.521Z'
        }
      },
      sort: [
        1591114764429
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV4HY156078043882020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:18:59.109Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '12699.00',
          mileage: '46078'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9474780,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV4HY156078',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T18:50:11.833Z'
        }
      },
      sort: [
        1591114739109
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3MYDLBYV2HY154149043882020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:18:21.546Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '12400.00',
          mileage: '60024'
        },
        sellArea: '110',
        dealerCd: '04388',
        certificationNumber: 9476579,
        makeCode: 'TOYOTA',
        vin: '3MYDLBYV2HY154149',
        model: {
          modelDescription: 'YARIS iA 4-DOOR SEDAN',
          modelSeries: 'YARIS SEDAN',
          modelNumber: '6262',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T20:39:50.368Z'
        }
      },
      sort: [
        1591114701546
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP729356460942020-05-13H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:18:15.877Z',
          retailDate: '2020-05-13',
          rdrStatus: 'H',
          soldAmount: '14550.00',
          mileage: '28775'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9467796,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP729356',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T00:11:18.702Z'
        }
      },
      sort: [
        1591114695877
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDEPRAE4LJ033615042822020-04-29V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:17:44.280Z',
          retailDate: '2020-04-29',
          rdrStatus: 'V',
          soldAmount: '19998.00',
          mileage: '15566'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9416029,
        makeCode: 'TOYOTA',
        vin: 'JTDEPRAE4LJ033615',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T22:16:00.581Z'
        }
      },
      sort: [
        1591114664280
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4HW640590460032020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:17:34.550Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '22900.00',
          mileage: '33228'
        },
        sellArea: '130',
        dealerCd: '46003',
        certificationNumber: 9493843,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4HW640590',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:10:05.144Z'
        }
      },
      sort: [
        1591114654550
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREVXHJ114853180372020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:17:31.787Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17161.00',
          mileage: '34606'
        },
        dealerCd: '18037',
        certificationNumber: 9368296,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREVXHJ114853',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T19:28:05.743Z'
        }
      },
      sort: [
        1591114651787
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV6JW715426250612020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:17:19.588Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21131.00',
          mileage: '28981'
        },
        sellArea: '130',
        dealerCd: '25061',
        certificationNumber: 9471792,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV6JW715426',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T22:23:22.033Z'
        }
      },
      sort: [
        1591114639588
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV9HJ125888311732020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:15:02.868Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '20993.00',
          mileage: '9789'
        },
        dealerCd: '31173',
        certificationNumber: 9376990,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV9HJ125888',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-11T20:11:02.586Z'
        }
      },
      sort: [
        1591114502868
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK6KU693410121572020-04-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:14:13.986Z',
          retailDate: '2020-04-30',
          rdrStatus: 'H',
          soldAmount: '18929.00',
          mileage: '38957'
        },
        dealerCd: '12157',
        certificationNumber: 9382105,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK6KU693410',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T22:43:21.642Z'
        }
      },
      sort: [
        1591114453986
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHEXHC833839460942020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:14:04.587Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '14666.00',
          mileage: '49329'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9457383,
        makeCode: 'TOYOTA',
        vin: '2T1BURHEXHC833839',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T14:10:49.432Z'
        }
      },
      sort: [
        1591114444587
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREVXHD080635180372020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:13:18.726Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20199.00',
          mileage: '53125'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9493845,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREVXHD080635',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T16:10:28.165Z'
        }
      },
      sort: [
        1591114398726
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB39G1139098460942020-05-11H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:13:14.046Z',
          retailDate: '2020-05-11',
          rdrStatus: 'H',
          soldAmount: '16200.00',
          mileage: '15529'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9454587,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB39G1139098',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB FOUR',
          modelSeries: 'PRIUS C',
          modelNumber: '1207',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-30T23:08:24.075Z'
        }
      },
      sort: [
        1591114394046
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN4HM077344190502020-05-18H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:12:41.235Z',
          retailDate: '2020-05-18',
          rdrStatus: 'H',
          soldAmount: '27500.00',
          mileage: '29113'
        },
        sellArea: '800',
        dealerCd: '19050',
        certificationNumber: 9481654,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN4HM077344',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:17:18.315Z'
        }
      },
      sort: [
        1591114361235
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH2FS185249460942020-05-08H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:12:18.094Z',
          retailDate: '2020-05-08',
          rdrStatus: 'H',
          soldAmount: '29100.00',
          mileage: '54903'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9460295,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH2FS185249',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-07T22:06:30.022Z'
        }
      },
      sort: [
        1591114338094
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV7HJ710887460942020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:11:24.714Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '18164.00',
          mileage: '27816'
        },
        sellArea: '600',
        dealerCd: '46094',
        certificationNumber: 9455192,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV7HJ710887',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T14:19:35.363Z'
        }
      },
      sort: [
        1591114284714
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS383773450622020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:11:07.040Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '32775.00',
          mileage: '45209'
        },
        sellArea: '160',
        dealerCd: '45062',
        certificationNumber: 9446033,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS383773',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-20T13:33:29.293Z'
        }
      },
      sort: [
        1591114267040
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KW041016042822020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:11:02.514Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '28995.00',
          mileage: '29793'
        },
        sellArea: '500',
        dealerCd: '04282',
        certificationNumber: 9431271,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KW041016',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T21:52:05.620Z'
        }
      },
      sort: [
        1591114262514
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1G11AK6LU873861042822020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:10:26.194Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '22998.00',
          mileage: '7514'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9468193,
        makeCode: 'TOYOTA',
        vin: '4T1G11AK6LU873861',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T14:16:26.523Z'
        }
      },
      sort: [
        1591114226194
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BPRHE5HC757907460942020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:10:10.975Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '14995.00',
          mileage: '31366'
        },
        sellArea: '130',
        dealerCd: '46094',
        certificationNumber: 9457380,
        makeCode: 'TOYOTA',
        vin: '2T1BPRHE5HC757907',
        model: {
          modelDescription: 'COROLLA LE ECO',
          modelSeries: 'COROLLA',
          modelNumber: '1872',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T14:08:08.492Z'
        }
      },
      sort: [
        1591114210975
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC848947121002020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:09:13.073Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '13200.00',
          mileage: '22348'
        },
        sellArea: '210',
        dealerCd: '12100',
        certificationNumber: 9484066,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC848947',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T19:31:43.697Z'
        }
      },
      sort: [
        1591114153073
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4HU672665450622020-05-23H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:08:26.475Z',
          retailDate: '2020-05-23',
          rdrStatus: 'H',
          soldAmount: '18899.00',
          mileage: '45309'
        },
        sellArea: '130',
        dealerCd: '45062',
        certificationNumber: 9443838,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4HU672665',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T15:27:10.104Z'
        }
      },
      sort: [
        1591114106475
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HD208520121002020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:06:43.835Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19795.00',
          mileage: '30352'
        },
        sellArea: '210',
        dealerCd: '12100',
        certificationNumber: 9444284,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HD208520',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-14T16:38:55.299Z'
        }
      },
      sort: [
        1591114003835
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN4FM194194460032020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:05:58.076Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '28981.00',
          mileage: '41383'
        },
        sellArea: '160',
        dealerCd: '46003',
        certificationNumber: 9430702,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN4FM194194',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T17:38:25.878Z'
        }
      },
      sort: [
        1591113958076
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV7HJ118133121002020-05-26H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:03:23.915Z',
          retailDate: '2020-05-26',
          rdrStatus: 'H',
          soldAmount: '20485.00',
          mileage: '31412'
        },
        sellArea: '210',
        dealerCd: '12100',
        certificationNumber: 9434784,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV7HJ118133',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-27T15:32:57.967Z'
        }
      },
      sort: [
        1591113803915
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC827898180372020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:01:23.415Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '13486.78',
          mileage: '32120'
        },
        sellArea: '160',
        dealerCd: '18037',
        certificationNumber: 9466930,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC827898',
        model: {
          modelDescription: 'COROLLA L',
          modelSeries: 'COROLLA',
          modelNumber: '1832',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T15:54:37.275Z'
        }
      },
      sort: [
        1591113683415
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW570987110282020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:01:19.275Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21500.00',
          mileage: '30399'
        },
        sellArea: '130',
        dealerCd: '11028',
        certificationNumber: 9476772,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW570987',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T23:12:26.111Z'
        }
      },
      sort: [
        1591113679275
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE7HC853935480272020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:01:13.496Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '14798.00',
          mileage: '29978'
        },
        sellArea: '210',
        dealerCd: '48027',
        certificationNumber: 9457532,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE7HC853935',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T16:50:09.292Z'
        }
      },
      sort: [
        1591113673496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK0GR564810311762020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:00:48.956Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15898.00',
          mileage: '22229'
        },
        sellArea: '160',
        dealerCd: '31176',
        certificationNumber: 9424397,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK0GR564810',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T20:30:37.606Z'
        }
      },
      sort: [
        1591113648956
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN0GM020377311392020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T16:00:23.896Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27995.00',
          mileage: '43257'
        },
        sellArea: '160',
        dealerCd: '31139',
        certificationNumber: 9481523,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN0GM020377',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:26:34.788Z'
        }
      },
      sort: [
        1591113623896
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4ENXFM203657311392020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:59:19.334Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26400.00',
          mileage: '60141'
        },
        dealerCd: '31139',
        certificationNumber: 9304225,
        makeCode: 'TOYOTA',
        vin: '3TMLU4ENXFM203657',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-12-23T22:56:34.316Z'
        }
      },
      sort: [
        1591113559334
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC854069121002020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:57:17.836Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14695.00',
          mileage: '34998'
        },
        sellArea: '210',
        dealerCd: '12100',
        certificationNumber: 9487511,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC854069',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T17:16:59.135Z'
        }
      },
      sort: [
        1591113437836
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KW027794020552020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:57:15.056Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26100.00',
          mileage: '18730'
        },
        sellArea: '500',
        dealerCd: '02055',
        certificationNumber: 9485312,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KW027794',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:44:56.204Z'
        }
      },
      sort: [
        1591113435056
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN3HM090490180372020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:56:56.536Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '27857.00',
          mileage: '37447'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9493813,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN3HM090490',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:55:02.580Z'
        }
      },
      sort: [
        1591113416536
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUM5F14HX072308311392020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:56:51.616Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28200.00',
          mileage: '23318'
        },
        dealerCd: '31139',
        certificationNumber: 9093808,
        makeCode: 'TOYOTA',
        vin: '5TFUM5F14HX072308',
        model: {
          modelDescription: 'TUNDRA 4X4 SR DBL CAB 4.6L V8',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8339',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-08-01T23:31:42.720Z'
        }
      },
      sort: [
        1591113411616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXJU537367180372020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:53:10.674Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '16400.00',
          mileage: '36797'
        },
        sellArea: '170',
        certificationNumber: 9493807,
        dealerCd: '18037',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        vin: '4T1B11HKXJU537367',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:50:01.980Z'
        }
      },
      sort: [
        1591113190674
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJKRFH2GS314241480362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:52:26.496Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26652.00',
          mileage: '42244'
        },
        sellArea: '210',
        dealerCd: '48036',
        certificationNumber: 9440973,
        makeCode: 'TOYOTA',
        vin: '5TDJKRFH2GS314241',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T21:14:47.476Z'
        }
      },
      sort: [
        1591113146496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU695518042012020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:52:20.416Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20900.00',
          mileage: '35917'
        },
        sellArea: '600',
        dealerCd: '04201',
        certificationNumber: 9398764,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU695518',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-27T16:00:38.919Z'
        }
      },
      sort: [
        1591113140416
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV2JD111805410662020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:51:25.535Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17951.00',
          mileage: '34235'
        },
        sellArea: '500',
        dealerCd: '41066',
        certificationNumber: 9410471,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV2JD111805',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T22:12:33.092Z'
        }
      },
      sort: [
        1591113085535
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFCZ5AN9KX190286410662020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:51:06.915Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27748.00',
          mileage: '29992'
        },
        sellArea: '600',
        dealerCd: '41066',
        certificationNumber: 9440753,
        makeCode: 'TOYOTA',
        vin: '5TFCZ5AN9KX190286',
        model: {
          modelDescription: 'TACOMA SR5 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-06T16:30:38.425Z'
        }
      },
      sort: [
        1591113066915
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW629571480362020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:48:25.293Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19611.00',
          mileage: '28609'
        },
        sellArea: '210',
        dealerCd: '48036',
        certificationNumber: 9478046,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW629571',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:48:44.976Z'
        }
      },
      sort: [
        1591112905293
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7GU160588180372020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:47:19.393Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '16200.00',
          mileage: '16981'
        },
        sellArea: '170',
        dealerCd: '18037',
        certificationNumber: 9465419,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7GU160588',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T14:07:12.415Z'
        }
      },
      sort: [
        1591112839393
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUW5F16HX602692480272020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:45:59.673Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '31799.00',
          mileage: '27874'
        },
        sellArea: '210',
        dealerCd: '48027',
        certificationNumber: 9487296,
        makeCode: 'TOYOTA',
        vin: '5TFUW5F16HX602692',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8331',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:59:15.544Z'
        }
      },
      sort: [
        1591112759673
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0FW260279220342020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:45:37.712Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17999.00',
          mileage: '60518'
        },
        sellArea: '210',
        dealerCd: '22034',
        certificationNumber: 9474478,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0FW260279',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T16:11:02.711Z'
        }
      },
      sort: [
        1591112737712
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS433645410652020-05-12H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:44:57.020Z',
          retailDate: '2020-05-12',
          rdrStatus: 'H',
          soldAmount: '30528.00',
          mileage: '34465'
        },
        sellArea: '170',
        dealerCd: '41065',
        certificationNumber: 9447637,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS433645',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-22T22:09:14.754Z'
        }
      },
      sort: [
        1591112697020
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FKXHU216658120152020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:44:31.437Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18611.00',
          mileage: '27534'
        },
        sellArea: '210',
        dealerCd: '12015',
        certificationNumber: 9427539,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FKXHU216658',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-19T20:12:32.865Z'
        }
      },
      sort: [
        1591112671437
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZ3DCXHS174146480362020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:43:59.511Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '27839.00',
          mileage: '33007'
        },
        sellArea: '210',
        dealerCd: '48036',
        certificationNumber: 9478058,
        makeCode: 'TOYOTA',
        vin: '5TDDZ3DCXHS174146',
        model: {
          modelDescription: 'SIENNA XLE PREM AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5377',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:52:15.518Z'
        }
      },
      sort: [
        1591112639511
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH5HS021661120152020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:43:30.192Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '34871.00',
          mileage: '35093'
        },
        sellArea: '210',
        dealerCd: '12015',
        certificationNumber: 9481560,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH5HS021661',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITED-V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6966',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:42:55.179Z'
        }
      },
      sort: [
        1591112610192
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU449515120152020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:42:31.851Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '16896.00',
          mileage: '31121'
        },
        sellArea: '220',
        dealerCd: '12015',
        certificationNumber: 9439061,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU449515',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T13:40:27.611Z'
        }
      },
      sort: [
        1591112551851
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP0H3024288201282020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:42:14.815Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19988.00',
          mileage: '32058'
        },
        dealerCd: '20128',
        certificationNumber: 9370767,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP0H3024288',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-06T16:25:54.806Z'
        }
      },
      sort: [
        1591112534815
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3HJ131204120152020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:41:27.536Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20723.00',
          mileage: '27116'
        },
        sellArea: '210',
        dealerCd: '12015',
        certificationNumber: 9433206,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3HJ131204',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T18:59:36.392Z'
        }
      },
      sort: [
        1591112487536
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV7KC009751120152020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:40:32.617Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23579.00',
          mileage: '23417'
        },
        sellArea: '500',
        dealerCd: '12015',
        certificationNumber: 9424520,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV7KC009751',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T21:20:34.575Z'
        }
      },
      sort: [
        1591112432617
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC2LS232262180352020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:40:21.616Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28773.00',
          mileage: '20000'
        },
        dealerCd: '18035',
        certificationNumber: 9366783,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC2LS232262',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-03T18:22:50.744Z'
        }
      },
      sort: [
        1591112421616
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDS4RCE4LJ022557480272020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:40:07.716Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '18289.00',
          mileage: '14706'
        },
        sellArea: '500',
        dealerCd: '48027',
        certificationNumber: 9486145,
        makeCode: 'TOYOTA',
        vin: 'JTDS4RCE4LJ022557',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:12:33.538Z'
        }
      },
      sort: [
        1591112407716
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC892272120152020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:39:26.815Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13453.98',
          mileage: '35968'
        },
        sellArea: '210',
        dealerCd: '12015',
        certificationNumber: 9482290,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC892272',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T20:09:32.667Z'
        }
      },
      sort: [
        1591112366815
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B21HK0JU510787042012020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:39:11.717Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '25000.00',
          mileage: '26075'
        },
        sellArea: '120',
        dealerCd: '04201',
        certificationNumber: 9466844,
        makeCode: 'TOYOTA',
        vin: '4T1B21HK0JU510787',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID SE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2561',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T15:17:16.067Z'
        }
      },
      sort: [
        1591112351717
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE0HC764174120152020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:38:40.837Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15709.18',
          mileage: '24768'
        },
        sellArea: '800',
        dealerCd: '12015',
        certificationNumber: 9451991,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE0HC764174',
        model: {
          modelDescription: 'COROLLA XLE',
          modelSeries: 'COROLLA',
          modelNumber: '1856',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T14:11:56.615Z'
        }
      },
      sort: [
        1591112320837
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F1XGX498488470282020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:33:21.496Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '34991.00',
          mileage: '14995'
        },
        sellArea: '800',
        dealerCd: '47028',
        certificationNumber: 9456091,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F1XGX498488',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-02T17:00:49.058Z'
        }
      },
      sort: [
        1591112001496
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK4GU503745150532020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:30:12.697Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15493.00',
          mileage: '43138'
        },
        sellArea: '230',
        dealerCd: '15053',
        certificationNumber: 9481524,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK4GU503745',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T15:26:54.148Z'
        }
      },
      sort: [
        1591111812697
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV1HD209685180352020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:29:24.777Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21499.00',
          mileage: '25719'
        },
        sellArea: '160',
        dealerCd: '18035',
        certificationNumber: 9475675,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV1HD209685',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T13:22:05.344Z'
        }
      },
      sort: [
        1591111764777
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3W1RFV6KW015387046882020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:29:24.324Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26081.00',
          mileage: '19075'
        },
        sellArea: '500',
        dealerCd: '04688',
        certificationNumber: 9458081,
        makeCode: 'TOYOTA',
        vin: '2T3W1RFV6KW015387',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T23:48:53.011Z'
        }
      },
      sort: [
        1591111764324
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMC1RFV1KD012364046882020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:28:57.284Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30481.00',
          mileage: '9833'
        },
        sellArea: '120',
        dealerCd: '04688',
        certificationNumber: 9487187,
        makeCode: 'TOYOTA',
        vin: 'JTMC1RFV1KD012364',
        model: {
          modelDescription: 'RAV4 XLE PREM FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4477',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T15:23:25.911Z'
        }
      },
      sort: [
        1591111737284
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE4HP681914046882020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:28:15.124Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '14981.00',
          mileage: '31313'
        },
        sellArea: '110',
        dealerCd: '04688',
        certificationNumber: 9478235,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE4HP681914',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T21:22:03.435Z'
        }
      },
      sort: [
        1591111695124
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU423089340372020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:28:00.804Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15500.00',
          mileage: '41599'
        },
        sellArea: '220',
        dealerCd: '34037',
        certificationNumber: 9472670,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU423089',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T16:03:16.239Z'
        }
      },
      sort: [
        1591111680804
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE6HP664113046362020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:27:55.943Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '14899.00',
          mileage: '34481'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9491965,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE6HP664113',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:51:42.356Z'
        }
      },
      sort: [
        1591111675943
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH0HS520915311652020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:26:46.022Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '28800.00',
          mileage: '28114'
        },
        sellArea: '160',
        dealerCd: '31165',
        certificationNumber: 9493768,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH0HS520915',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:24:14.637Z'
        }
      },
      sort: [
        1591111606022
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK9KU162198046362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:25:51.043Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20899.00',
          mileage: '29335'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9430894,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK9KU162198',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-23T18:38:50.112Z'
        }
      },
      sort: [
        1591111551043
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHEXHP691881046362020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:24:34.442Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13999.00',
          mileage: '40409'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9492030,
        makeCode: 'TOYOTA',
        vin: '5YFBURHEXHP691881',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T18:09:08.726Z'
        }
      },
      sort: [
        1591111474442
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP0H3062247046362020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:23:34.342Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '22989.00',
          mileage: '34424'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9485286,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP0H3062247',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T15:36:19.219Z'
        }
      },
      sort: [
        1591111414342
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV4JD120718046362020-05-17H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:22:40.641Z',
          retailDate: '2020-05-17',
          rdrStatus: 'H',
          soldAmount: '18950.00',
          mileage: '40640'
        },
        sellArea: '600',
        dealerCd: '04636',
        certificationNumber: 9462859,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV4JD120718',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-11T15:52:41.095Z'
        }
      },
      sort: [
        1591111360641
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV9KW067231211192020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:22:32.361Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22877.28',
          mileage: '8430'
        },
        sellArea: '220',
        dealerCd: '21119',
        certificationNumber: 9493750,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV9KW067231',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T15:16:55.084Z'
        }
      },
      sort: [
        1591111352361
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE4HC830080201272020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:22:29.502Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '13979.00',
          mileage: '25155'
        },
        sellArea: '170',
        dealerCd: '20127',
        certificationNumber: 9485982,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE4HC830080',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:38:56.359Z'
        }
      },
      sort: [
        1591111349502
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK1HU754689046362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:21:53.840Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16999.00',
          mileage: '29495'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9478541,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK1HU754689',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T00:45:37.817Z'
        }
      },
      sort: [
        1591111313840
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV8JW721633340372020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:21:52.238Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19714.00',
          mileage: '30706'
        },
        sellArea: '220',
        dealerCd: '34037',
        certificationNumber: 9415145,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV8JW721633',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T15:20:53.400Z'
        }
      },
      sort: [
        1591111312238
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR4K5632086340372020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:21:13.858Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '36504.00',
          mileage: '31494'
        },
        sellArea: '220',
        dealerCd: '34037',
        certificationNumber: 9442885,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR4K5632086',
        model: {
          modelDescription: '4RUNNER 4X4 TRD OFF-RD PREM',
          modelSeries: '4RUNNER',
          modelNumber: '8672',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-10T15:00:38.762Z'
        }
      },
      sort: [
        1591111273858
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T4BF1FK0GR572731042212020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:21:01.418Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '15995.00',
          mileage: '28600'
        },
        sellArea: '120',
        dealerCd: '04221',
        certificationNumber: 9436501,
        makeCode: 'TOYOTA',
        vin: '4T4BF1FK0GR572731',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-30T19:35:36.531Z'
        }
      },
      sort: [
        1591111261418
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9FW344042201272020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:20:48.399Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16384.00',
          mileage: '44819'
        },
        sellArea: '500',
        dealerCd: '20127',
        certificationNumber: 9481623,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9FW344042',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T16:03:51.095Z'
        }
      },
      sort: [
        1591111248399
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE7HP682555046362020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:20:24.878Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15399.00',
          mileage: '5949'
        },
        sellArea: '110',
        dealerCd: '04636',
        certificationNumber: 9491987,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE7HP682555',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:57:24.514Z'
        }
      },
      sort: [
        1591111224878
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU376426150532020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:19:44.758Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16718.00',
          mileage: '21639'
        },
        sellArea: '230',
        dealerCd: '15053',
        certificationNumber: 9464610,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU376426',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T18:42:24.014Z'
        }
      },
      sort: [
        1591111184758
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE8GC655458201272020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:19:20.798Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '10491.00',
          mileage: '79330'
        },
        sellArea: '170',
        dealerCd: '20127',
        certificationNumber: 9485968,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE8GC655458',
        model: {
          modelDescription: 'COROLLA LE GRADE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T19:34:23.011Z'
        }
      },
      sort: [
        1591111160798
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS429235042212020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:18:17.019Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '31884.00',
          mileage: '16915'
        },
        sellArea: '120',
        dealerCd: '04221',
        certificationNumber: 9484624,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS429235',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T01:21:51.488Z'
        }
      },
      sort: [
        1591111097019
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU306199201272020-05-20H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:17:45.119Z',
          retailDate: '2020-05-20',
          rdrStatus: 'H',
          soldAmount: '14980.00',
          mileage: '33403'
        },
        sellArea: '170',
        dealerCd: '20127',
        certificationNumber: 9485839,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU306199',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T18:41:19.745Z'
        }
      },
      sort: [
        1591111065119
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH7HS387171250652020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:17:32.946Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23601.00',
          mileage: '51056'
        },
        sellArea: '120',
        dealerCd: '25065',
        certificationNumber: 9415207,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH7HS387171',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T15:54:59.703Z'
        }
      },
      sort: [
        1591111052946
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU742526060532020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:17:18.664Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18000.00',
          mileage: '32294'
        },
        sellArea: '500',
        dealerCd: '06053',
        certificationNumber: 9424092,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU742526',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-17T18:36:22.953Z'
        }
      },
      sort: [
        1591111038664
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP4H3063370201382020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:07:56.139Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '19490.00',
          mileage: '26244'
        },
        sellArea: '170',
        dealerCd: '20138',
        certificationNumber: 9408573,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP4H3063370',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-04T17:29:34.762Z'
        }
      },
      sort: [
        1591110476139
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXHS396541110112020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:07:18.898Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '28168.00',
          mileage: '40500'
        },
        dealerCd: '11011',
        certificationNumber: 9381328,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXHS396541',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-14T16:41:04.661Z'
        }
      },
      sort: [
        1591110438898
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDKZ3DC3LS050252042822020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:06:42.940Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '29498.00',
          mileage: '18856'
        },
        sellArea: '110',
        dealerCd: '04282',
        certificationNumber: 9467284,
        makeCode: 'TOYOTA',
        vin: '5TDKZ3DC3LS050252',
        model: {
          modelDescription: 'SIENNA LE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5338',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T18:38:00.042Z'
        }
      },
      sort: [
        1591110402940
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS389481060262020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:03:32.938Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '29999.00',
          mileage: '29017'
        },
        sellArea: '160',
        dealerCd: '06026',
        certificationNumber: 9471713,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS389481',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T21:52:55.146Z'
        }
      },
      sort: [
        1591110212938
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFUXK3086441040972020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:01:25.317Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '23816.00',
          mileage: '5783'
        },
        sellArea: '110',
        dealerCd: '04097',
        certificationNumber: 9435321,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFUXK3086441',
        model: {
          modelDescription: 'PRIUS LE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-28T00:43:09.613Z'
        }
      },
      sort: [
        1591110085317
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV1HW553862110012020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:00:34.803Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '19409.00',
          mileage: '41520'
        },
        sellArea: '130',
        dealerCd: '11001',
        certificationNumber: 9461062,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV1HW553862',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T17:45:28.494Z'
        }
      },
      sort: [
        1591110034803
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE1HP706817040972020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:00:33.839Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '16118.00',
          mileage: '33306'
        },
        sellArea: '110',
        dealerCd: '04097',
        certificationNumber: 9465740,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE1HP706817',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T17:09:07.810Z'
        }
      },
      sort: [
        1591110033839
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU8J3058006480272020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:00:12.278Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '21285.00',
          mileage: '36563'
        },
        sellArea: '210',
        dealerCd: '48027',
        certificationNumber: 9416883,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU8J3058006',
        model: {
          modelDescription: 'PRIUS FOUR',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1227',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T16:43:38.695Z'
        }
      },
      sort: [
        1591110012278
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNB11HK2J3050749060262020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T15:00:10.547Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '20995.00',
          mileage: '21537'
        },
        sellArea: '160',
        dealerCd: '06026',
        certificationNumber: 9471726,
        makeCode: 'TOYOTA',
        vin: 'JTNB11HK2J3050749',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T21:58:53.629Z'
        }
      },
      sort: [
        1591110010547
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNKHMBX8K1032321040972020-05-24H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:59:39.709Z',
          retailDate: '2020-05-24',
          rdrStatus: 'H',
          soldAmount: '20894.00',
          mileage: '30739'
        },
        sellArea: '110',
        dealerCd: '04097',
        certificationNumber: 9443994,
        makeCode: 'TOYOTA',
        vin: 'JTNKHMBX8K1032321',
        model: {
          modelDescription: 'C-HR XLE',
          modelSeries: 'CHR',
          modelNumber: '2404',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-13T18:50:19.697Z'
        }
      },
      sort: [
        1591109979709
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFHXKS737853240842020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:58:20.469Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '31491.00',
          mileage: '4427'
        },
        sellArea: '230',
        dealerCd: '24084',
        certificationNumber: 9457635,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFHXKS737853',
        model: {
          modelDescription: 'HIGHLANDER LE Plus - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6949',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T18:02:03.646Z'
        }
      },
      sort: [
        1591109900469
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE2HP642643121532020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:57:22.708Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14988.00',
          mileage: '15086'
        },
        sellArea: '210',
        dealerCd: '12153',
        certificationNumber: 9455148,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE2HP642643',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-01T13:45:51.427Z'
        }
      },
      sort: [
        1591109842708
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDBZRFH6KS741740240842020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:56:38.067Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '28750.00',
          mileage: '4025'
        },
        sellArea: '230',
        dealerCd: '24084',
        certificationNumber: 9478972,
        makeCode: 'TOYOTA',
        vin: '5TDBZRFH6KS741740',
        model: {
          modelDescription: 'HIGHLANDER LE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6948',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T14:21:25.829Z'
        }
      },
      sort: [
        1591109798067
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS391475060262020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:55:18.127Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '30000.00',
          mileage: '22897'
        },
        sellArea: '170',
        dealerCd: '06026',
        certificationNumber: 9477746,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS391475',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:40:40.352Z'
        }
      },
      sort: [
        1591109718127
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFHXKS743843240842020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:54:41.507Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '35500.00',
          mileage: '3430'
        },
        sellArea: '230',
        dealerCd: '24084',
        certificationNumber: 9457933,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFHXKS743843',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T20:53:38.247Z'
        }
      },
      sort: [
        1591109681507
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU4K3075967240842020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:53:55.087Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23591.00',
          mileage: '15739'
        },
        sellArea: '150',
        dealerCd: '24084',
        certificationNumber: 9457716,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU4K3075967',
        model: {
          modelDescription: 'PRIUS XLE HYBRID',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T18:33:32.298Z'
        }
      },
      sort: [
        1591109635087
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV2ED082358140032020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:53:24.147Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15000.00',
          mileage: '43186'
        },
        sellArea: '210',
        dealerCd: '14003',
        certificationNumber: 9471836,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV2ED082358',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2014
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T22:42:22.124Z'
        }
      },
      sort: [
        1591109604147
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV4HW630044341222020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:53:13.686Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21490.00',
          mileage: '15807'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9485193,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV4HW630044',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T14:48:56.447Z'
        }
      },
      sort: [
        1591109593686
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFU4G3016103121122020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:51:13.247Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17250.00',
          mileage: '64600'
        },
        sellArea: '210',
        dealerCd: '12112',
        certificationNumber: 9486196,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFU4G3016103',
        model: {
          modelDescription: 'PRIUS THREE',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1225',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T21:44:17.564Z'
        }
      },
      sort: [
        1591109473247
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE8HP630772121122020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:50:30.806Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16669.00',
          mileage: '14401'
        },
        dealerCd: '12112',
        certificationNumber: 9473512,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE8HP630772',
        model: {
          modelDescription: 'COROLLA 50TH ANN SP ED',
          modelSeries: 'COROLLA',
          modelNumber: '1865',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T21:37:37.901Z'
        }
      },
      sort: [
        1591109430806
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMAZ5CN7KM100787150122020-05-22H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:48:46.103Z',
          retailDate: '2020-05-22',
          rdrStatus: 'H',
          soldAmount: '31950.00',
          mileage: '2529'
        },
        sellArea: '500',
        dealerCd: '15012',
        certificationNumber: 9444725,
        makeCode: 'TOYOTA',
        vin: '3TMAZ5CN7KM100787',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7146',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-15T15:54:37.993Z'
        }
      },
      sort: [
        1591109326103
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK2HU728599060262020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:47:22.422Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '17998.00',
          mileage: '61419'
        },
        sellArea: '170',
        dealerCd: '06026',
        certificationNumber: 9477759,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK2HU728599',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T17:48:21.232Z'
        }
      },
      sort: [
        1591109242422
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRJREV1HD110265140032020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:47:11.324Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '22603.00',
          mileage: '33468'
        },
        sellArea: '230',
        dealerCd: '14003',
        certificationNumber: 9475961,
        makeCode: 'TOYOTA',
        vin: 'JTMRJREV1HD110265',
        model: {
          modelDescription: 'RAV4 HYBRID XLE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4444',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T16:29:04.958Z'
        }
      },
      sort: [
        1591109231324
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JF1ZNAA16H8705006470212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:44:42.663Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21696.00',
          mileage: '28787'
        },
        sellArea: '160',
        dealerCd: '47021',
        certificationNumber: 9479359,
        makeCode: 'TOYOTA',
        vin: 'JF1ZNAA16H8705006',
        model: {
          modelDescription: '86 2-DOOR COUPE',
          modelSeries: '86',
          modelNumber: '6252',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T18:58:11.677Z'
        }
      },
      sort: [
        1591109082663
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV9HW603308470212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:43:44.523Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20547.00',
          mileage: '38629'
        },
        sellArea: '160',
        dealerCd: '47021',
        certificationNumber: 9469028,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV9HW603308',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T20:25:08.011Z'
        }
      },
      sort: [
        1591109024523
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDZRFH2HS435409470212020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:42:28.607Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '32691.00',
          mileage: '32809'
        },
        sellArea: '220',
        dealerCd: '47021',
        certificationNumber: 9486311,
        makeCode: 'TOYOTA',
        vin: '5TDDZRFH2HS435409',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-28T22:45:06.477Z'
        }
      },
      sort: [
        1591108948607
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFBY5F17GX534399060262020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:41:52.480Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '31999.00',
          mileage: '24803'
        },
        sellArea: '160',
        dealerCd: '06026',
        certificationNumber: 9441771,
        makeCode: 'TOYOTA',
        vin: '5TFBY5F17GX534399',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8352',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-08T14:42:58.321Z'
        }
      },
      sort: [
        1591108912480
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE1HC818808121532020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:36:59.977Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '14688.00',
          mileage: '15393'
        },
        dealerCd: '12153',
        certificationNumber: 9438874,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE1HC818808',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-01T20:35:51.605Z'
        }
      },
      sort: [
        1591108619977
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKBRFU3H3041492041372020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:36:20.259Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '18900.00',
          mileage: '19054'
        },
        sellArea: '210',
        dealerCd: '04137',
        certificationNumber: 9482422,
        makeCode: 'TOYOTA',
        vin: 'JTDKBRFU3H3041492',
        model: {
          modelDescription: 'PRIUS TWO',
          modelSeries: 'PRIUS FWD',
          modelNumber: '1223',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T21:16:02.850Z'
        }
      },
      sort: [
        1591108580259
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN6GM020447121352020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:27:05.099Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27564.00',
          mileage: '60179'
        },
        sellArea: '210',
        dealerCd: '12135',
        certificationNumber: 9439335,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN6GM020447',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T18:53:45.319Z'
        }
      },
      sort: [
        1591108025099
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DCXKS016091460762020-05-25H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:23:20.790Z',
          retailDate: '2020-05-25',
          rdrStatus: 'H',
          soldAmount: '34665.77',
          mileage: '29233'
        },
        sellArea: '500',
        dealerCd: '46076',
        certificationNumber: 9472769,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DCXKS016091',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T16:39:51.677Z'
        }
      },
      sort: [
        1591107800790
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK6HU755711240782020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:17:30.227Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13979.00',
          mileage: '68659'
        },
        sellArea: '230',
        dealerCd: '24078',
        certificationNumber: 9461882,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK6HU755711',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-09T13:52:39.537Z'
        }
      },
      sort: [
        1591107450227
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV3JJ260887340062020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:16:08.193Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '11239'
        },
        sellArea: '800',
        dealerCd: '34006',
        certificationNumber: 9406934,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV3JJ260887',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-03T15:59:05.262Z'
        }
      },
      sort: [
        1591107368193
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFBURHE5HP678309048192020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:15:17.493Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15950.00',
          mileage: '24779'
        },
        sellArea: '110',
        dealerCd: '04819',
        certificationNumber: 9491250,
        makeCode: 'TOYOTA',
        vin: '5YFBURHE5HP678309',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T14:22:12.906Z'
        }
      },
      sort: [
        1591107317493
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN5HM071746340062020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:15:08.954Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27465.00',
          mileage: '42803'
        },
        sellArea: '220',
        dealerCd: '34006',
        certificationNumber: 9492483,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN5HM071746',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T20:07:24.645Z'
        }
      },
      sort: [
        1591107308954
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMEWRFV2KJ008478048192020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:13:40.731Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '37115.00',
          mileage: '20623'
        },
        sellArea: '800',
        dealerCd: '04819',
        certificationNumber: 9483758,
        makeCode: 'TOYOTA',
        vin: 'JTMEWRFV2KJ008478',
        model: {
          modelDescription: 'RAV4 HYBRID XSE AWD SUV',
          modelSeries: 'RAV4 HYBRID',
          modelNumber: '4456',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-27T17:40:08.760Z'
        }
      },
      sort: [
        1591107220731
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HKXKU827769340062020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:13:38.133Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '21995.00',
          mileage: '3373'
        },
        sellArea: '220',
        dealerCd: '34006',
        certificationNumber: 9492340,
        makeCode: 'TOYOTA',
        vin: '4T1B11HKXKU827769',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T19:35:19.097Z'
        }
      },
      sort: [
        1591107218133
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH9FS095841370832020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:06:53.822Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '26528.00',
          mileage: '57758'
        },
        certificationNumber: 9385740,
        dealerCd: '37083',
        makeCode: 'TOYOTA',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2015
        },
        vin: '5TDDKRFH9FS095841',
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-18T16:24:10.549Z'
        }
      },
      sort: [
        1591106813822
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK3GU539474190422020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:06:51.288Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17450.00',
          mileage: '22540'
        },
        sellArea: '800',
        dealerCd: '19042',
        certificationNumber: 9492883,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK3GU539474',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2548',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T21:58:03.739Z'
        }
      },
      sort: [
        1591106811288
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3RFREV0HW651635200192020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:03:25.866Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '22420.00',
          mileage: '28780'
        },
        dealerCd: '20019',
        certificationNumber: 9128677,
        makeCode: 'TOYOTA',
        vin: '2T3RFREV0HW651635',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-08-29T20:15:44.209Z'
        }
      },
      sort: [
        1591106605866
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN8HM092333440202020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T14:02:07.567Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '31200.00',
          mileage: '18242'
        },
        sellArea: '170',
        dealerCd: '44020',
        certificationNumber: 9477550,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN8HM092333',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T16:17:05.771Z'
        }
      },
      sort: [
        1591106527567
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV5JW772426440202020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:59:55.786Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20500.00',
          mileage: '17130'
        },
        dealerCd: '44020',
        certificationNumber: 9368110,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV5JW772426',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-04T17:41:25.532Z'
        }
      },
      sort: [
        1591106395786
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F18HX627684440202020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:58:32.765Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '30500.00',
          mileage: '49837'
        },
        sellArea: '170',
        dealerCd: '44020',
        certificationNumber: 9477534,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F18HX627684',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T16:06:47.141Z'
        }
      },
      sort: [
        1591106312765
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR0H5446120280292020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:57:59.438Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '27698.00',
          mileage: '62908'
        },
        sellArea: '170',
        dealerCd: '28029',
        certificationNumber: 9466592,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR0H5446120',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 V6',
          modelSeries: '4RUNNER',
          modelNumber: '8664',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-14T12:42:11.386Z'
        }
      },
      sort: [
        1591106279438
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZ3DC6HS149215480652020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:57:47.623Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28451.00',
          mileage: '33673'
        },
        sellArea: '210',
        dealerCd: '48065',
        certificationNumber: 9452551,
        makeCode: 'TOYOTA',
        vin: '5TDJZ3DC6HS149215',
        model: {
          modelDescription: 'SIENNA LE 3.5L AWD 7 PSGR',
          modelSeries: 'SIENNA AWD',
          modelNumber: '5366',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-29T20:36:47.985Z'
        }
      },
      sort: [
        1591106267623
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV6HJ140723440202020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:56:31.143Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '40314'
        },
        sellArea: '170',
        dealerCd: '44020',
        certificationNumber: 9460828,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV6HJ140723',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-08T15:26:17.216Z'
        }
      },
      sort: [
        1591106191143
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH5HS520361160172020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:47:16.639Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '29900.00',
          mileage: '41759'
        },
        sellArea: '220',
        dealerCd: '16017',
        certificationNumber: 9479407,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH5HS520361',
        model: {
          modelDescription: 'HIGHLANDER SE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6952',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-23T19:39:32.657Z'
        }
      },
      sort: [
        1591105636639
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUY5F13HX631674380252020-05-21H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:44:46.579Z',
          retailDate: '2020-05-21',
          rdrStatus: 'H',
          soldAmount: '29925.39',
          mileage: '13726'
        },
        sellArea: '160',
        dealerCd: '38025',
        certificationNumber: 9465706,
        makeCode: 'TOYOTA',
        vin: '5TFUY5F13HX631674',
        model: {
          modelDescription: 'TUNDRA 4X4 SR5 DOUBLE CAB',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8341',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T16:50:49.226Z'
        }
      },
      sort: [
        1591105486579
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMRFREV5HJ707776380252020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:43:08.795Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19500.00',
          mileage: '28619'
        },
        sellArea: '170',
        dealerCd: '38025',
        certificationNumber: 9478066,
        makeCode: 'TOYOTA',
        vin: 'JTMRFREV5HJ707776',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-22T19:55:04.066Z'
        }
      },
      sort: [
        1591105388795
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKDTB33H1591694380252020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:42:38.516Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '11780.00',
          mileage: '54779'
        },
        sellArea: '170',
        dealerCd: '38025',
        certificationNumber: 9486942,
        makeCode: 'TOYOTA',
        vin: 'JTDKDTB33H1591694',
        model: {
          modelDescription: 'PRIUS c 2WD 5DR HB THREE',
          modelSeries: 'PRIUS C',
          modelNumber: '1205',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T13:37:10.460Z'
        }
      },
      sort: [
        1591105358516
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDKRFH2GS272537341222020-05-15V',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:42:18.118Z',
          retailDate: '2020-05-15',
          rdrStatus: 'V',
          soldAmount: '24990.00',
          mileage: '62398'
        },
        sellArea: '220',
        dealerCd: '34122',
        certificationNumber: 9468982,
        makeCode: 'TOYOTA',
        vin: '5TDDKRFH2GS272537',
        model: {
          modelDescription: 'HIGHLANDER LIMITED - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6956',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-15T20:05:19.298Z'
        }
      },
      sort: [
        1591105338118
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS469222380252020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:41:56.336Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '29159.00',
          mileage: '36262'
        },
        sellArea: '170',
        dealerCd: '38025',
        certificationNumber: 9466001,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS469222',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T19:48:13.344Z'
        }
      },
      sort: [
        1591105316336
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FKXHU379949380252020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:40:42.975Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '15436.15',
          mileage: '26727'
        },
        sellArea: '170',
        dealerCd: '38025',
        certificationNumber: 9466267,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FKXHU379949',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-13T21:41:38.304Z'
        }
      },
      sort: [
        1591105242975
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4JU642387380252020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:40:22.454Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18500.00',
          mileage: '40012'
        },
        sellArea: '600',
        dealerCd: '38025',
        certificationNumber: 9404419,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4JU642387',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-02T14:55:02.398Z'
        }
      },
      sort: [
        1591105222454
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK8KU204778130462020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:36:53.972Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '19961.00',
          mileage: '33863'
        },
        sellArea: '500',
        dealerCd: '13046',
        certificationNumber: 9472486,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK8KU204778',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T14:39:36.761Z'
        }
      },
      sort: [
        1591105013972
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5YFEPRAE9LP045397450922020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:29:46.210Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16615.00',
          mileage: '14543'
        },
        sellArea: '600',
        dealerCd: '45092',
        certificationNumber: 9489814,
        makeCode: 'TOYOTA',
        vin: '5YFEPRAE9LP045397',
        model: {
          modelDescription: 'COROLLA LE',
          modelSeries: 'COROLLA',
          modelNumber: '1852',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-30T20:13:18.589Z'
        }
      },
      sort: [
        1591104586210
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH1HS402260060022020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:29:13.530Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '28999.00',
          mileage: '26308'
        },
        sellArea: '160',
        dealerCd: '06002',
        certificationNumber: 9414856,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH1HS402260',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-10T11:53:08.454Z'
        }
      },
      sort: [
        1591104553530
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK4KU207595371502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:27:55.269Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21991.00',
          mileage: '13307'
        },
        sellArea: '160',
        dealerCd: '37150',
        certificationNumber: 9410333,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK4KU207595',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-05T20:43:41.015Z'
        }
      },
      sort: [
        1591104475269
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU661384371502020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:26:55.947Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15960.00',
          mileage: '34799'
        },
        dealerCd: '37150',
        certificationNumber: 9383107,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU661384',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-15T22:03:44.948Z'
        }
      },
      sort: [
        1591104415947
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTNK4RBE2K3045769371502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:26:09.328Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16999.00',
          mileage: '15009'
        },
        dealerCd: '37150',
        certificationNumber: 9372994,
        makeCode: 'TOYOTA',
        vin: 'JTNK4RBE2K3045769',
        model: {
          modelDescription: 'COROLLA HATCHBACK SE 5Dr HATCHBACK',
          modelSeries: 'COROLLA HATCHBK',
          modelNumber: '6272',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-07T21:28:45.954Z'
        }
      },
      sort: [
        1591104369328
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BF1FK7HU691078291022020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:26:04.388Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '15700.00',
          mileage: '13884'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9475004,
        makeCode: 'TOYOTA',
        vin: '4T1BF1FK7HU691078',
        model: {
          modelDescription: 'CAMRY 4-DOOR LE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2532',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T20:41:39.586Z'
        }
      },
      sort: [
        1591104364388
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3P1RFV4KW007454450702020-05-27H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:25:46.849Z',
          retailDate: '2020-05-27',
          rdrStatus: 'H',
          soldAmount: '23500.00',
          mileage: '37777'
        },
        sellArea: '500',
        dealerCd: '45070',
        certificationNumber: 9475666,
        makeCode: 'TOYOTA',
        vin: '2T3P1RFV4KW007454',
        model: {
          modelDescription: 'RAV4 XLE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4442',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T13:15:04.996Z'
        }
      },
      sort: [
        1591104346849
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK3KU172872371502020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:24:31.987Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '26990.00',
          mileage: '3697'
        },
        dealerCd: '37150',
        certificationNumber: 9389283,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK3KU172872',
        model: {
          modelDescription: 'CAMRY 4-DOOR XLE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2540',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-20T18:12:32.612Z'
        }
      },
      sort: [
        1591104271987
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE6HC908181291022020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:24:25.008Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '16290.00',
          mileage: '29749'
        },
        sellArea: '160',
        dealerCd: '29102',
        certificationNumber: 9491509,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE6HC908181',
        model: {
          modelDescription: 'COROLLA XSE',
          modelSeries: 'COROLLA',
          modelNumber: '1866',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T15:59:06.085Z'
        }
      },
      sort: [
        1591104265008
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1D21FB5LU014771341212020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:22:50.945Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '39500.00',
          mileage: '2744'
        },
        sellArea: '220',
        dealerCd: '34121',
        certificationNumber: 9491937,
        makeCode: 'TOYOTA',
        vin: '4T1D21FB5LU014771',
        model: {
          modelDescription: 'AVALON HYBRID 4-DR LIMITED',
          modelSeries: 'AVALON HYBRID',
          modelNumber: '3514',
          modelYear: 2020
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:46:09.539Z'
        }
      },
      sort: [
        1591104170945
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM071629311392020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:21:56.265Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '31000.00',
          mileage: '19132'
        },
        sellArea: '160',
        dealerCd: '31139',
        certificationNumber: 9475061,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM071629',
        model: {
          modelDescription: 'TACOMA TRD OFFRD 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7544',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T21:09:50.973Z'
        }
      },
      sort: [
        1591104116265
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREV0HW584481150562020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:21:47.785Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '21774.94',
          mileage: '41875'
        },
        sellArea: '230',
        dealerCd: '15056',
        certificationNumber: 9429009,
        makeCode: 'TOYOTA',
        vin: '2T3DFREV0HW584481',
        model: {
          modelDescription: 'RAV4 Limited AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4452',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-20T19:59:57.300Z'
        }
      },
      sort: [
        1591104107785
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR2G5294422311392020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:20:23.584Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '26500.00',
          mileage: '58865'
        },
        dealerCd: '31139',
        certificationNumber: 9245727,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR2G5294422',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-11-14T22:04:27.115Z'
        }
      },
      sort: [
        1591104023584
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR9K5613694150562020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:19:56.665Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '33987.00',
          mileage: '20501'
        },
        sellArea: '230',
        dealerCd: '15056',
        certificationNumber: 9493613,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR9K5613694',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-02T13:17:00.042Z'
        }
      },
      sort: [
        1591103996665
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFUU4EN1FX115997311392020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:18:34.203Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '23750.00',
          mileage: '25782'
        },
        sellArea: '160',
        dealerCd: '31139',
        certificationNumber: 9475052,
        makeCode: 'TOYOTA',
        vin: '5TFUU4EN1FX115997',
        model: {
          modelDescription: 'TACOMA 4X4 ACCESS CAB V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7554',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T21:04:46.499Z'
        }
      },
      sort: [
        1591103914203
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDYZ3DC2HS830440430252020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:18:00.185Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '24638.00',
          mileage: '33903'
        },
        sellArea: '110',
        dealerCd: '43025',
        certificationNumber: 9471212,
        makeCode: 'TOYOTA',
        vin: '5TDYZ3DC2HS830440',
        model: {
          modelDescription: 'SIENNA XLE 3.5L FWD 8 PSGR',
          modelSeries: 'SIENNA FWD',
          modelNumber: '5348',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-18T18:52:23.580Z'
        }
      },
      sort: [
        1591103880185
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH8HS381696340892020-06-02H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:17:36.522Z',
          retailDate: '2020-06-02',
          rdrStatus: 'H',
          soldAmount: '27088.00',
          mileage: '51340'
        },
        sellArea: '220',
        dealerCd: '34089',
        certificationNumber: 9488011,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH8HS381696',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:06:31.060Z'
        }
      },
      sort: [
        1591103856522
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR5H5465617311392020-05-28H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:17:23.422Z',
          retailDate: '2020-05-28',
          rdrStatus: 'H',
          soldAmount: '29995.00',
          mileage: '40753'
        },
        sellArea: '160',
        dealerCd: '31139',
        certificationNumber: 9419293,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR5H5465617',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-12T22:44:49.502Z'
        }
      },
      sort: [
        1591103843422
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH9HS413684480282020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:16:34.603Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '30376.00',
          mileage: '45247'
        },
        sellArea: '210',
        dealerCd: '48028',
        certificationNumber: 9457992,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH9HS413684',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-05T21:47:02.642Z'
        }
      },
      sort: [
        1591103794603
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T1BURHE2HC917699311392020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:16:11.622Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '13500.00',
          mileage: '43518'
        },
        sellArea: '160',
        dealerCd: '31139',
        certificationNumber: 9475079,
        makeCode: 'TOYOTA',
        vin: '2T1BURHE2HC917699',
        model: {
          modelDescription: 'COROLLA SE',
          modelSeries: 'COROLLA',
          modelNumber: '1864',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T21:18:57.153Z'
        }
      },
      sort: [
        1591103771622
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDDGRFH8HS033142430252020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:14:33.182Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30102.00',
          mileage: '69016'
        },
        sellArea: '150',
        dealerCd: '43025',
        certificationNumber: 9464921,
        makeCode: 'TOYOTA',
        vin: '5TDDGRFH8HS033142',
        model: {
          modelDescription: 'HIGHLANDER HYBRD LIMITD-PLAT V6 AWD-i',
          modelSeries: 'HIGH-HYBRID',
          modelNumber: '6967',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-12T21:30:03.173Z'
        }
      },
      sort: [
        1591103673182
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTDKARFP5H3000505311392020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:14:11.281Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '20855.00',
          mileage: '42318'
        },
        dealerCd: '31139',
        certificationNumber: 9333377,
        makeCode: 'TOYOTA',
        vin: 'JTDKARFP5H3000505',
        model: {
          modelDescription: 'PRIUS PRIME PREMIUM',
          modelSeries: 'PRIUS PRIME',
          modelNumber: '1237',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-01-12T18:00:31.149Z'
        }
      },
      sort: [
        1591103651281
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV0JJ202199430252020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:13:30.401Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '19842.00',
          mileage: '32952'
        },
        sellArea: '150',
        dealerCd: '43025',
        certificationNumber: 9472975,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV0JJ202199',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-19T18:01:23.384Z'
        }
      },
      sort: [
        1591103610401
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMLU4EN4FM195121311392020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T13:12:57.581Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '23495.00',
          mileage: '78629'
        },
        sellArea: '160',
        dealerCd: '31139',
        certificationNumber: 9475058,
        makeCode: 'TOYOTA',
        vin: '3TMLU4EN4FM195121',
        model: {
          modelDescription: 'TACOMA DOUBLE CAB 4X4 V6',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7594',
          modelYear: 2015
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T21:08:18.809Z'
        }
      },
      sort: [
        1591103577581
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFAX5GN5KX155877043642020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:47:30.635Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '27388.00',
          mileage: '26490'
        },
        sellArea: '500',
        dealerCd: '04364',
        certificationNumber: 9480335,
        makeCode: 'TOYOTA',
        vin: '5TFAX5GN5KX155877',
        model: {
          modelDescription: 'TACOMA SR5 4X2 DOUBLE CAB',
          modelSeries: '4 X 2 TACOMA',
          modelNumber: '7185',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-25T15:40:57.890Z'
        }
      },
      sort: [
        1591102050635
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN2HM087788043642020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:45:52.964Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '31988.00',
          mileage: '51369'
        },
        sellArea: '120',
        dealerCd: '04364',
        certificationNumber: 9488082,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN2HM087788',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:27:44.546Z'
        }
      },
      sort: [
        1591101952964
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMWFREV8HJ704566043642020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:44:06.855Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '21034.00',
          mileage: '25997'
        },
        sellArea: '110',
        dealerCd: '04364',
        certificationNumber: 9423042,
        makeCode: 'TOYOTA',
        vin: 'JTMWFREV8HJ704566',
        model: {
          modelDescription: 'RAV4 XLE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4440',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-16T21:58:49.659Z'
        }
      },
      sort: [
        1591101846855
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3K1RFVXKW019036043642020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:42:32.277Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23894.00',
          mileage: '10912'
        },
        sellArea: '120',
        dealerCd: '04364',
        certificationNumber: 9416822,
        makeCode: 'TOYOTA',
        vin: '2T3K1RFVXKW019036',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T16:16:47.428Z'
        }
      },
      sort: [
        1591101752277
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3K1RFV2KC004970043642020-05-29H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:40:55.434Z',
          retailDate: '2020-05-29',
          rdrStatus: 'H',
          soldAmount: '23688.00',
          mileage: '12284'
        },
        sellArea: '120',
        dealerCd: '04364',
        certificationNumber: 9448513,
        makeCode: 'TOYOTA',
        vin: '2T3K1RFV2KC004970',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-24T14:07:10.082Z'
        }
      },
      sort: [
        1591101655434
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV0JW777940043642020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:39:29.615Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18305.00',
          mileage: '45940'
        },
        sellArea: '500',
        dealerCd: '04364',
        certificationNumber: 9416889,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV0JW777940',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-11T16:45:33.875Z'
        }
      },
      sort: [
        1591101569615
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1B11HK2KU205019043642020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:37:30.574Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '21697.00',
          mileage: '30231'
        },
        sellArea: '600',
        dealerCd: '04364',
        certificationNumber: 9474169,
        makeCode: 'TOYOTA',
        vin: '4T1B11HK2KU205019',
        model: {
          modelDescription: 'CAMRY 4-DOOR SE SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2546',
          modelYear: 2019
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T13:55:21.123Z'
        }
      },
      sort: [
        1591101450574
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '3TMCZ5AN1HM048190371322020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:37:28.015Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29882.00',
          mileage: '27083'
        },
        dealerCd: '37132',
        certificationNumber: 9198611,
        makeCode: 'TOYOTA',
        vin: '3TMCZ5AN1HM048190',
        model: {
          modelDescription: 'TACOMA TRD SPORT 4X4 DOUBLE CAB',
          modelSeries: '4 X 4 TACOMA',
          modelNumber: '7542',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2019-10-15T14:01:07.065Z'
        }
      },
      sort: [
        1591101448015
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BZ1HK5JU502020043642020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:35:52.128Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '29477.00',
          mileage: '19801'
        },
        sellArea: '110',
        dealerCd: '04364',
        certificationNumber: 9439314,
        makeCode: 'TOYOTA',
        vin: '4T1BZ1HK5JU502020',
        model: {
          modelDescription: 'CAMRY 4-DOOR XSE V6 SEDAN',
          modelSeries: 'CAMRY',
          modelNumber: '2550',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T18:32:46.364Z'
        }
      },
      sort: [
        1591101352128
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BD1FK9HU209071043642020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:34:10.556Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '20122.00',
          mileage: '36863'
        },
        sellArea: '110',
        dealerCd: '04364',
        certificationNumber: 9432948,
        makeCode: 'TOYOTA',
        vin: '4T1BD1FK9HU209071',
        model: {
          modelDescription: 'CAMRY HYBRID HYBRID LE SEDAN',
          modelSeries: 'CAMRY HYBRID',
          modelNumber: '2559',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-25T16:32:59.292Z'
        }
      },
      sort: [
        1591101250556
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3BFREV9HW616707311832020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:31:24.375Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '17292.00',
          mileage: '51136'
        },
        sellArea: '160',
        dealerCd: '31183',
        certificationNumber: 9419747,
        makeCode: 'TOYOTA',
        vin: '2T3BFREV9HW616707',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-03-13T14:34:12.734Z'
        }
      },
      sort: [
        1591101084375
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TFHW5F10HX661304160612020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:20:39.275Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '37512.00',
          mileage: '36781'
        },
        sellArea: '220',
        dealerCd: '16061',
        certificationNumber: 9491976,
        makeCode: 'TOYOTA',
        vin: '5TFHW5F10HX661304',
        model: {
          modelDescription: 'TUNDRA 4X4 LIMITED CREWMAX FFV',
          modelSeries: 'TUNDRA 4X4',
          modelNumber: '8374',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-06-01T17:54:33.253Z'
        }
      },
      sort: [
        1591100439275
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMBFREV4HJ147816371762020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:05:15.414Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '16000.00',
          mileage: '61569'
        },
        sellArea: '800',
        dealerCd: '37176',
        certificationNumber: 9474258,
        makeCode: 'TOYOTA',
        vin: 'JTMBFREV4HJ147816',
        model: {
          modelDescription: 'RAV4 LE AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4432',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:51:59.853Z'
        }
      },
      sort: [
        1591099515414
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR6H5448311371762020-06-01H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T12:04:27.554Z',
          retailDate: '2020-06-01',
          rdrStatus: 'H',
          soldAmount: '30999.00',
          mileage: '23085'
        },
        dealerCd: '37176',
        certificationNumber: 9383677,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR6H5448311',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-02-17T16:08:59.703Z'
        }
      },
      sort: [
        1591099467554
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTEBU5JR1J5514169341092020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T11:38:27.634Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '29994.00',
          mileage: '53464'
        },
        sellArea: '220',
        dealerCd: '34109',
        certificationNumber: 9488046,
        makeCode: 'TOYOTA',
        vin: 'JTEBU5JR1J5514169',
        model: {
          modelDescription: '4RUNNER 4X4 SR5 PREMIUM V6',
          modelSeries: '4RUNNER',
          modelNumber: '8666',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-29T20:12:46.371Z'
        }
      },
      sort: [
        1591097907634
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: 'JTMZFREV4HJ143974341092020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T11:34:51.956Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '17309.00',
          mileage: '33004'
        },
        sellArea: '220',
        dealerCd: '34109',
        certificationNumber: 9474270,
        makeCode: 'TOYOTA',
        vin: 'JTMZFREV4HJ143974',
        model: {
          modelDescription: 'RAV4 LE FWD SUV',
          modelSeries: 'RAV 4X2',
          modelNumber: '4430',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-20T14:55:53.692Z'
        }
      },
      sort: [
        1591097691956
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '4T1BK1EB1GU223374341092020-05-30H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T11:25:17.254Z',
          retailDate: '2020-05-30',
          rdrStatus: 'H',
          soldAmount: '18959.65',
          mileage: '37080'
        },
        sellArea: '220',
        dealerCd: '34109',
        certificationNumber: 9481760,
        makeCode: 'TOYOTA',
        vin: '4T1BK1EB1GU223374',
        model: {
          modelDescription: 'AVALON 4-DR XLE PREMIUM',
          modelSeries: 'AVALON',
          modelNumber: '3546',
          modelYear: 2016
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-26T17:06:16.917Z'
        }
      },
      sort: [
        1591097117254
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '5TDJZRFH3JS498737341092020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T11:20:16.774Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '29956.00',
          mileage: '25942'
        },
        sellArea: '220',
        dealerCd: '34109',
        certificationNumber: 9476173,
        makeCode: 'TOYOTA',
        vin: '5TDJZRFH3JS498737',
        model: {
          modelDescription: 'HIGHLANDER XLE - V6 AWD',
          modelSeries: 'HIGHLANDER AWD',
          modelNumber: '6953',
          modelYear: 2018
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-05-21T18:15:00.349Z'
        }
      },
      sort: [
        1591096816774
      ]
    },
    {
      _index: 'vehicle-inventory',
      _type: 'vehicle',
      _id: '2T3DFREVXHW585055341092020-05-31H',
      _score: null,
      _source: {
        rdrDetail: {
          createdAt: '2020-06-02T10:39:36.092Z',
          retailDate: '2020-05-31',
          rdrStatus: 'H',
          soldAmount: '21299.00',
          mileage: '49564'
        },
        sellArea: '220',
        dealerCd: '34109',
        certificationNumber: 9439084,
        makeCode: 'TOYOTA',
        vin: '2T3DFREVXHW585055',
        model: {
          modelDescription: 'RAV4 Platinum AWD SUV',
          modelSeries: 'RAV4 4X4',
          modelNumber: '4476',
          modelYear: 2017
        },
        certificationStatus: 'C',
        certification: {
          certificationDate: '2020-04-02T14:01:35.553Z'
        }
      },
      sort: [
        1591094376092
      ]
    }
  ]
}
