import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UCInventorySummary } from '../../../services/model/InventorySummary';
import { ViewConstants } from '../../../shared/shared.constants';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import { SharedDataService } from '../../../services/common/shared-data.service';
import * as models from '../../../services/model/models';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'uc-inventory-grid',
  templateUrl: 'tmex-sales-report-grid.component.html',
  styleUrls: ['./tmex-sales-report-grid.component.scss']
})

/***
 * This component is used to the show the inventories in the inventory summar page
 */
export class UCTmexSalesReportGridComponent {
  // Variable for grid column definitions
  @Input() columnDefs: any[] = [];
  @Input() totalSaleCount: number;
  // private variable to hold the row datas
  _rows: Array<UCInventorySummary>;
  // Variable for sort columns list
  public sortColumnsList: Array<any> = [];
  // Event to emit on row select/unselect
  @Output() onClickedEvent = new EventEmitter<any>();
  // Event to emit when vin is clicked in the grid
  @Output() navigateToVehicleDetailEvent = new EventEmitter<any>();
  // Event to emit when vin is clicked in the grid
  @Output() countIncreaseEvent = new EventEmitter<any>();

  // Event to emit when vin is clicked in the grid
  @Output() countDecreaseEvent = new EventEmitter<any>();
  // to clear the current sorting for all columns
  @Input('clearSort')
  set clearSort(val: boolean) {
    if (val) {
      this.sortColumnsList = [];
    }
  }

  @ViewChild('inventoryTable',  {static: false}) inventoryTable: DatatableComponent;


  // Input variable to store the rows
  @Input('rows')
  get rows(): Array<UCInventorySummary> {
    return this._rows;
  }
  set rows(value: Array<UCInventorySummary>) {
    this._rows = value;
  }

  // Event to emit during sorting the datas
  @Output() applySortEvent = new EventEmitter<any>();
  // Event to apply the filters
  @Output() applyFilterEvent = new EventEmitter<any>();


  constructor(public sharedFunctionService: SharedFunctionService, public router: Router,
    public sharedDataService: SharedDataService) {
  }

  /**
   * Method to apply the sorting in the grid
   */
  applySort(columnName: string) {
    let sortOrder = ViewConstants.ASC;
    if (this.sortColumnsList.findIndex(x => x.columnName === columnName) > -1) {
      sortOrder = this.sortColumnsList.find(x => x.columnName === columnName)
        .sortOrder === ViewConstants.ASC ? ViewConstants.DESC : ViewConstants.ASC;
    }
    this.sortColumnsList = [];
    this.sortColumnsList.push({ columnName: columnName, sortOrder: sortOrder });
    this.applySortEvent.emit({ columnName: columnName, sortOrder: sortOrder });
  }

  applyFilter(event: any, columnName: string, filterType: models.FilterType) {
    if (!this.inventoryTable.offset)
      this.inventoryTable.offset = 0;
    let filter: models.Filter = {};
    filter.columnname = columnName;
    filter.filterValue = event.target.value;
    let array = String(event.target.value).split(' ');
    filter.filterType = filterType;
    this.applyFilterEvent.emit(filter);
  }

  /**
   * Grid Row Select/Unselect Event
   */
  onClicked(checkboxChange: any, row: any) {
    let data = {
      checkBoxChecked: checkboxChange.source.checked,
      row: row
    };
    this.onClickedEvent.emit(data);
  }



  navigateToVehicleDetailScreen(event: any) {
    let selectedVehicleRoutes: models.VehicleRoute = {};
    selectedVehicleRoutes.vin = event;
    this.sharedDataService.setSelectedVehicle(selectedVehicleRoutes);
    this.navigateToVehicleDetailEvent.emit(event);
    // this.router.navigate(['/vehicle/' + event + '/' + this.sharedDataService.dealerCode]);
  }

  countPlus() {
    this.countIncreaseEvent.emit();
  }

  countMinus() {
    this.countDecreaseEvent.emit();
  }
}
