import { Component, EventEmitter, Input, Output, HostListener } from '@angular/core';

import { ValidationTypes } from '../../../services/model/ValidatorTypes';

@Component({
  selector: 'accounting-vin-search-toolbar',
  templateUrl: 'accounting-vin-search-toolbar.component.html',
  styleUrls: ['./accounting-vin-search-toolbar.component.scss']
})

export class AccountingVinSearchToolbarComponent {

  @Output() findVehicleEvent = new EventEmitter<any>();

  @Input() enteredvin: string;

  public validationTypes: typeof ValidationTypes = ValidationTypes;


  findVehicle() {
    this.enteredvin = this.enteredvin.toUpperCase().trim();
    this.findVehicleEvent.emit(this.enteredvin);
  }
}
