/***
 * Import statements required for Inventory Detail Service
 * for invoking services required for populating
 * all sections in the Vehicle Inventory Detail Screen
 */
import { Injectable } from '@angular/core';
import {  Response, Headers, RequestOptionsArgs, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
// TODO: correctly add the JSON so that it can be read
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedFunctionService } from '../common/shared-function.service';
import { HttpClient } from '../../shared/components/helpers/http-client';
import { ViewConstants } from '../../shared/shared.constants';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

/***
 * Class that is used to invoke the Services for populating
 * all the sections of Vehicle Inventory Detail screen
 */
@Injectable()
export class FeatureConfigDetailService {
    public defaultHeaders: Headers = new Headers();
    protected getpersonIdServiceURL = environment.GET_PERSON_ID_SERVICE_URL;
    protected getFeatureConfigURL = environment.GET_FEATURE_CONFIG_URL;
    protected getTierEligibilityURL = environment.GET_TIERELIBILITY_URL;
    protected getWarrantyContarctURL = environment.GET_WARRANTY_CONTRACT_URL;


    /***
     * Constructor of Inventory Detail Service to inject the angluar http
     */
    constructor(
        private sharedFuncService: SharedFunctionService, protected httpClient: HttpClient,
        private logger: Logger) { }




    public getFeatureConfig(dealerCode: string): Observable<any> {
        this.logger.log('Executing Get dealer details method');
        // const path =  ServiceURL.DISURL;
        let path = this.getFeatureConfigURL + '?dealerCd=' + dealerCode;
        let queryParameters = new URLSearchParams();
        let headerParams = this.defaultHeaders;

        if (!dealerCode) {
            let msg = ViewConstants.REQUIRED_PARAMETER_IS_NULL_OR_UNDEFINED
                .replace('{ServiceName}', 'Get Feature Config')
                .replace('{ParameterName}', 'dealerCode');
            return this.sharedFuncService.handleError(msg);
        }

        let requestOptions: RequestOptionsArgs = {
            method: 'GET',
            headers: headerParams,
            search: queryParameters
        };

        // TODO: To be Removed
        this.logger.log('Get Feature Config Service invoked - ' + path);
        return this.httpClient.get(path, requestOptions)
        .pipe(map((response: HttpResponse<any>) => {
                this.logger.log(response);
                this.logger.log('Feature Config Service response received ' + response);
                if (response.status === 204) {
                    return undefined;
                } else {
                    let dealers = response.body;
                    return dealers;
                }
            }), catchError((err: any) => {
                return this.sharedFuncService.handleError(err);
            }));

    }

    public getTierElgibility(tierData: any): Observable<any> {
        this.logger.log('Executing Get dealer details method');
        const vin = tierData && tierData.data ? tierData.data.vin : '';
        const mileage = tierData && tierData.data.mileage ? tierData.data.mileage : '';
        const divisionName = tierData && tierData.data.divisionName ? tierData.data.divisionName : '';
        const modelYear = tierData && tierData.data.modelYear ? tierData.data.modelYear : '';
        const dealerCode = tierData && tierData.data.dealerCode ? tierData.data.dealerCode : '';
        // const path =  ServiceURL.DISURL;
        let path = this.getTierEligibilityURL + `?vin=${vin}&dealerCd=${dealerCode}&mileage=${mileage}&modelYear=${modelYear}&divisionName=${divisionName}`;
        let queryParameters = new URLSearchParams();
        let headerParams = this.defaultHeaders;

        if (!dealerCode) {
            let msg = ViewConstants.REQUIRED_PARAMETER_IS_NULL_OR_UNDEFINED
                .replace('{ServiceName}', 'Get Tier Eligibilty')
                .replace('{ParameterName}', 'dealerCode');
            return this.sharedFuncService.handleError(msg);
        }

        let requestOptions: RequestOptionsArgs = {
            method: 'GET',
            headers: headerParams,
            search: queryParameters
        };

        // TODO: To be Removed
        this.logger.log('tier eligibilty Service invoked - ' + path);
        return this.httpClient.get(path, requestOptions)
            .pipe(map((response) => {
                this.logger.log(response);
                this.logger.log('tier eligibilty Service response received ' + response);
                if (response.status === 204) {
                    return undefined;
                } else {
                    let dealers = response.body;
                    // dealers = dealers.Items.filter(e => e.dlrNo === dealerCode);
                    return dealers;
                }
            }))

    }

    public getWarrantyContarctData(tierData: any): Observable<any> {
        this.logger.log('Executing getWarrantyContarctData method');
        const vin = tierData && tierData.data ? tierData.data.vin : '';
        const dealerCode = tierData && tierData.data.dealerCode ? tierData.data.dealerCode : '';
        const dealerName = tierData && tierData.data.dealerName ? tierData.data.dealerName : '';
        let path = this.getWarrantyContarctURL + `?vin=${vin}&dealerCd=${dealerCode}&dealerName=${dealerName}`;

        let queryParameters = new URLSearchParams();
        let headerParams = this.defaultHeaders;

        if (!dealerCode) {
            let msg = ViewConstants.REQUIRED_PARAMETER_IS_NULL_OR_UNDEFINED
                .replace('{ServiceName}', 'getWarrantyContarctData')
                .replace('{ParameterName}', 'dealerCode');
            return this.sharedFuncService.handleError(msg);
        }

        let requestOptions: RequestOptionsArgs = {
            method: 'GET',
            headers: headerParams,
            search: queryParameters
        };

        this.logger.log('getWarrantyContarctData eligibilty Service invoked - ' + path);
        return this.httpClient.get(path, requestOptions)
            .pipe(map((response) => {
                this.logger.log(response);
                this.logger.log('getWarrantyContarctData res received ' + response.body);
                if (response.status === 204) {
                    return undefined;
                } else {
                    let dealers = response.body;
                    // dealers = dealers.Items.filter(e => e.dlrNo === dealerCode);
                    return dealers;
                }
            }))
    }

}
