<div class="uc-inventory-grid">
  <mat-card>
    <ngx-datatable #inventoryTable class='material striped dd-grid-size' [rows]='rows' [columnMode]="'force'"
      [headerHeight]="65" [footerHeight]="50" [rowHeight]="40" [scrollbarV]="true" [scrollbarH]="true"
      [externalSorting]="true">
      <ngx-datatable-column *ngFor="let columnDef of columnDefs" [name]="columnDef.key" [prop]="columnDef.fieldName"
        [width]="columnDef.columnWidth" [minWidth]="columnDef.columnMinWidth" [maxWidth]="columnDef.columnMaxWidth"
        [flexGrow]="columnDef.columnWidth">
        <ng-template let-model="model" ngx-datatable-header-template>
          <div class="datatable-header-cell-label">
            <div fxLayout="column" class="dd-grid-label-align">
              <div fxFlex="100" class="cursorP"
                [class.dd-grid-sorting]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)===''"
                [class.dd-grid-sorting-asc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList) === 'ASC'"
                [class.dd-grid-sorting-desc]="columnDef.sortable === 'true' && sharedFunctionService.getColumnSortIcon(columnDef.fieldName, sortColumnsList)==='DESC'"
                [innerHTML]="columnDef.key" (click)="columnDef.sortable !== 'true' || applySort(columnDef.fieldName)">
              </div>
              <div fxFlex>
                <mat-form-field class="dd-inv-input-background">
                  <!--<mat-form-field>-->
                  <input matInput [(ngModel)]="columnDef.filterValue" *ngIf="columnDef.key !== 'VIN'"
                    [ddFieldValidator]="columnDef.dataTypeformat"
                    (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                  <input matInput [(ngModel)]="columnDef.filterValue" style="text-transform: uppercase;"
                    *ngIf="columnDef.key === 'VIN'" [ddFieldValidator]="columnDef.dataTypeformat"
                    (keyup)='applyFilter($event, columnDef.fieldName, columnDef.filterType)'>
                  <!--</mat-form-field>-->
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div class="text-left"
            *ngIf="columnDef.key !== 'DATE OF FIRST REPAIR ORDER' && columnDef.key !== 'DATE OF SECOND REPAIR ORDER'"
            [innerHTML]="value"></div>
          <div class="text-left"
            *ngIf="columnDef.key === 'DATE OF FIRST REPAIR ORDER' || columnDef.key === 'DATE OF SECOND REPAIR ORDER'"
            [innerHTML]="value|date :'MM-dd-yyyy'"></div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
          <div style="margin-left: 70%">
            <!-- <div *ngIf="sharedDataService.paginationCount > 1 && sharedDataService.isNextVisible">
              Records from <strong>{{sharedDataService.paginationCount}}</strong> to
              <strong>{{sharedDataService.paginationCount+4999}}</strong> | <a (click)="countMinus()">Previous</a> | <a
                (click)="countPlus()">Show All</a>
            </div> -->
            <div *ngIf="sharedDataService.paginationCount === 1 && sharedDataService.isNextVisible">
              Records from <strong>1</strong> to <strong>5000</strong> | <a (click)="countPlus()">Show All</a>
            </div>
            <div *ngIf="sharedDataService.paginationCount === 1 && !sharedDataService.isNextVisible">
              Records from <strong>1</strong> to <strong>{{totalSaleCount}}</strong>
            </div>
            <!-- <div *ngIf="sharedDataService.paginationCount > 1 && !sharedDataService.isNextVisible">
              Records from <strong>{{sharedDataService.paginationCount}}</strong> to
              <strong>{{sharedDataService.paginationCount+totalSaleCount-1}}</strong> | <a (click)="countMinus()">Previous</a>
            </div> -->
          </div>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </mat-card>
</div>