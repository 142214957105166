



import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionBase } from '../actions/actionbase';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import * as inventorySummaryActions from '../actions/vehicle-report.action';
import { UCInventoryReportService } from '../services/api/vehicle-report.service';
import { UCInventorySummary } from '../services/model/InventorySummary';
import { map, switchMap, catchError } from 'rxjs/operators';
/**
 * Class used to handle the service interaction in the inventory summary screen
 */
@Injectable()
export class UCInventoryReportEffects {

    /**
     * Mehod used to get the inventory summary data on Initial Load
     */
    @Effect()
    getInventorySummaryReport$: Observable<Action> = this.actions$
        .pipe(ofType(inventorySummaryActions.ACTION_TYPES.GET_INVENTORY_REPORT_LOAD)
        ,map((action: ActionBase) => action.payload)
        , switchMap(data =>
            this.ucInventoryReportService.getInventoryReport(data)
            .pipe(map((inventories: Array<UCInventorySummary>) =>
                    new inventorySummaryActions.GetInventoryReportSuccessAction(inventories))
                    , catchError(error => of(
                    new inventorySummaryActions.GetInventoryReportFailureAction(error))))
        ));

    /**
     * Method used to get GST_SET data for inventory details
     */
    // @Effect()
    // getGSTSETData$: Observable<Action> =  this.actions$
    //     .pipe(
    //         ofType(inventorySummaryActions.ACTION_TYPES.GET_GST_SET_DATA)
    //         ,map((action: ActionBase) => action.payload)
    //         , switchMap(data =>
    //             this.ucInventoryReportService.getGSTSETData(data)
    //             .pipe(map((inventories: Array<UCInventorySummary>) =>
    //                 new inventorySummaryActions.GetGSTSETDataSuccessAction(inventories))
    //                 , catchError(error => of(
    //                     new inventorySummaryActions.GetGSTSETDataFailureAction(error))))
    //         ));

    /**
     * Constructor method used to create new instance of UCInventorySummaryEffects class
     */
    constructor(private actions$: Actions,
        private ucInventoryReportService: UCInventoryReportService) {

    }
}
