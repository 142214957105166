// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let env;
let hostname: string = '';
let apiBaseUrl: string = '';

(()=>{
  const envApiBaseUrl = {
    "localhost": {
      appURL: 'http://localhost:4200/',
      apiBaseUrl: "api.qa-usedcars.toyota.com",
      production: false,
      ENV: 'test',
      MIN_LOG_LEVEL: 'DEBUG',
      TENENT_ID : "9107b728-2166-4e5d-8d13-d1ffdf0351ef",
      CLIENT_ID : "167f36b4-e34e-4429-a89b-dcce092cc74b",
      GET_PERSON_ID_SERVICE_URL  : "https://graph.windows.net/tmnatest.onmicrosoft.com/users/",
      GET_PERSON_ID_TOKEN_SERVICE_URL  : "https://login.windows.net/tmnab2edev.onmicrosoft.com/oauth2/token?api-version=1.6",
    },
    "ui.test-usedcars.toyota.com": {
      appURL: 'https://ui.test-usedcars.toyota.com/',
      apiBaseUrl: "api.test-usedcars.toyota.com",
      production: false,
      ENV: 'test',
      MIN_LOG_LEVEL: 'DEBUG',
      TENENT_ID : "9107b728-2166-4e5d-8d13-d1ffdf0351ef",
      CLIENT_ID : "167f36b4-e34e-4429-a89b-dcce092cc74b",
      GET_PERSON_ID_SERVICE_URL  : "https://graph.windows.net/tmnatest.onmicrosoft.com/users/",
      GET_PERSON_ID_TOKEN_SERVICE_URL  : "https://login.windows.net/tmnab2edev.onmicrosoft.com/oauth2/token?api-version=1.6",
    },
    "ui.qa-usedcars.toyota.com": {
      appURL: 'https://ui.qa-usedcars.toyota.com/',
      apiBaseUrl: "api.qa-usedcars.toyota.com",
      production: false,
      ENV: 'test',
      MIN_LOG_LEVEL: 'DEBUG',
      TENENT_ID : "9107b728-2166-4e5d-8d13-d1ffdf0351ef",
      CLIENT_ID : "a620032d-1d1d-445a-b6c7-7b6f60eee61b",
      GET_PERSON_ID_SERVICE_URL  : "https://graph.windows.net/tmnatest.onmicrosoft.com/users/",
      GET_PERSON_ID_TOKEN_SERVICE_URL  : "https://login.windows.net/tmnab2edev.onmicrosoft.com/oauth2/token?api-version=1.6",
    },

    "ui.usedcars.toyota.com": {
      appURL: 'https://ui.usedcars.toyota.com/',
      apiBaseUrl:"api.usedcars.toyota.com",
      production: true,
      ENV: 'production',
      MIN_LOG_LEVEL: 'ERROR',
      TENENT_ID : "8c642d1d-d709-47b0-ab10-080af10798fb",
      CLIENT_ID : "920b20c4-34d6-4424-a4b3-1aaaff1e99a7",
      GET_PERSON_ID_SERVICE_URL  : "https://graph.windows.net/tmnatest.onmicrosoft.com/users/",
      GET_PERSON_ID_TOKEN_SERVICE_URL  : "https://login.windows.net/tmnab2edev.onmicrosoft.com/oauth2/token?api-version=1.6",
    },
    "ui.dev-usedcars.toyota.com": {
      appURL: 'https://ui.dev-usedcars.toyota.com/',
      apiBaseUrl: "api.dev-usedcars.toyota.com",
      production: false,
      ENV: 'test',
      MIN_LOG_LEVEL: 'DEBUG',
      TENENT_ID : "9107b728-2166-4e5d-8d13-d1ffdf0351ef",
      CLIENT_ID : "f5dcb73a-d6d4-49f0-83b3-f6747a24e809",
      GET_PERSON_ID_SERVICE_URL  : "https://graph.windows.net/tmnatest.onmicrosoft.com/users/",
      GET_PERSON_ID_TOKEN_SERVICE_URL  : "https://login.windows.net/tmnab2edev.onmicrosoft.com/oauth2/token?api-version=1.6",
    }
  };
  if (window.location && window.location.hostname) {
    hostname = window.location.hostname.toLowerCase();
  }
  env = envApiBaseUrl[hostname] || {};
  apiBaseUrl = env.apiBaseUrl
})();

export const environment = {
  ...env,
  DIS_URL : `https://${apiBaseUrl}/dealer-search/dealers`,
  REDIRECT_URL : `https://${hostname}/`,
  BASE_SERVICE_URL : `https://${apiBaseUrl}/inventory`,
  GET_VEHICLE_SERVICE_URL  : `https://${apiBaseUrl}/inventory-search/getVehicleDetails`,
  GET_VIN_INFO_SERVICE_URL  : `https://${apiBaseUrl}/check-eligibility/vehicle/`,
  DEALER_INVENTORY_SERVICE_URL  : `https://${apiBaseUrl}/inventory-search/dealers/`,
  SAVE_VEHICLE_SERVICE_URL  : `https://${apiBaseUrl}/inventory/vehicle/add`,
  UPDATE_VEHICLE_SERVICE_URL  : `https://${apiBaseUrl}/inventory/updateVehicleDetails`,
  DELETE_VEHICLE_SERVICE_URL  : `https://${apiBaseUrl}/inventory/deleteVehicleDetails`,
  SAVE_RDR_SERVICE_URL : `https://${apiBaseUrl}/rdr/add`,
  UPDATE_RDR_SERVICE_URL : `https://${apiBaseUrl}/rdr/update`,
  DELETE_RDR_SERVICE_URL : `https://${apiBaseUrl}/rdr/delete`,
  REVERSE_RDR_SERVICE_URL : `https://${apiBaseUrl}/rdr/reverse`,
  VEHICLE_DECERTIFICATION_SERVICE_URL  : `https://${apiBaseUrl}/ineligiblevehicle/update`,
  DEALER_PARTICIPANT_SERVICE_URL  : `https://${apiBaseUrl}/dealerparticipant/update`,
  GET_ROLE_MAPPING_SERVICE_URL  : `https://${apiBaseUrl}/rolemapping/getByRoleName`,
  SAVE_ROLE_MAPPING_SERVICE_URL  : `https://${apiBaseUrl}/rolemapping/update/`,
  GET_ROLES_BY_MAKECODE_SERVICE_URL  : `https://${apiBaseUrl}/rolemapping/get/`,
  VEHICLE_HISTORY_SERVICE_URL  : `https://${apiBaseUrl}/vehicle-history/get`,
  CERT_FROM_SALE_SERVICE_URL  : `https://${apiBaseUrl}/rdr/certifySoldVehicle`,
  SM_API_URL : `https://${apiBaseUrl}/staff-master/get`,
  VEHICLE_REPORT_SERVICE_URL : `https://${apiBaseUrl}/reports/fetch/`,
  GET_GSTSET_DATA_URL : `https://${apiBaseUrl}/reports/fetchgstset/`,
  REGION_OBJECTIVE_SERVICE_URL  : `https://${apiBaseUrl}/vehicle-objectives/region/`,
  DEALER_OBJECTIVE_SERVICE_URL  : `https://${apiBaseUrl}/vehicle-objectives/dealer/`,
  BUYSELL_SERVICE_URL  : `https://${apiBaseUrl}/buysell/automateBuySell`,
  ACCOUNTING_SUMMARY_SERVICE_URL  : `https://${apiBaseUrl}/Account-History/getAccountHistory`,
  GET_ACCOUNTING_SERVICE_URL  : `https://${apiBaseUrl}/Account-History/getSummaryInfo`,
  GET_FEATURE_CONFIG_URL:`https://${apiBaseUrl}/cva-wrapper/featureConfig`,
  GET_TIERELIBILITY_URL:`https://${apiBaseUrl}/cva-wrapper/tierEligibility`,
  GET_WARRANTY_CONTRACT_URL:`https://${apiBaseUrl}/cva-wrapper/getSetWarrantyContractNumber`,
  GET_SMF_API_URL: `https://${apiBaseUrl}/cva-wrapper/getStaffmasterFinance`,
  GET_SMS_API_URL: `https://${apiBaseUrl}/cva-wrapper/getStaffmasterSales`,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
