import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { UCLayoutService } from './services/layout.services';
import { from } from 'rxjs';
@Component({
  selector: 'uc-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss']
})

export class UCLayoutComponent implements OnDestroy, AfterViewInit {
  @ViewChild(MatSidenav,  {static: false}) _sideNav: MatSidenav;

  /**
   * title in sideNav menu
   */
  @Input() title: string;

  /**
   * icon for title in sideNav menu
   */
  @Input() icon: string;

  /**
   * logo file for title in sideNav menu
   */
  @Input() logo: string;

  /**
   * displayName in menu for users
   */
  @Input() displayName: string;

  @Input() displayDealerCode: string;
  /**
   * method thats called when logout is clicked
   */
  // @Output('logout') onLogoutEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() onLogoutEvent: EventEmitter<void> = new EventEmitter<void>();

  public _showUserMenu: boolean = false;
  private _subcriptions: Subscription[] = [];
  private userName: string;

  constructor(private layoutService: UCLayoutService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    public msalSvc: MsalService) {
    try {
      iconRegistry.addSvgIcon('dealer-daily-logo',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icon/dealer-daily-logo.png'));
      this._subcriptions.push(this.layoutService.registerSidenav('menu').subscribe(() => {
        this.open();
      }));
    } catch (error) {
      // this.logger.error(error);
    }
  }

  /**
   * removes subscriptions when destroyed
   */
  ngOnDestroy(): void {
    this._subcriptions.forEach((subs: Subscription) => {
      subs.unsubscribe();
    });
  }

  /**
   * subscribes as observable to the onClose() event from the sideNav to hide userMenu when closed.
   */
  ngAfterViewInit(): void {
    this._subcriptions.push(from(this._sideNav.close()).subscribe(() => {
      this._showUserMenu = false;
    }));
  }

  logoutClick(): void {
    this._onLogout();
  }

  /**
   * toggle userMenu to hide/show
   */
  toggleUserMenu(): void {
    this._showUserMenu = !this._showUserMenu;
  }

  isShowUserMenu(): boolean {
    return this._showUserMenu;
  }

  /**
   * Proxy toggle method to access sidenav from outside (from td-layout template).
   */
  public toggle(): void {
    this._sideNav.toggle();
  }

  /**
   * Proxy open method to access sidenav from outside (from td-layout template).
   */
  public open(): void {
    this._sideNav.open();
  }

  /**
   * Proxy close method to access sidenav from outside (from td-layout template).
   */
  public close(): void {
    this._sideNav.close();
  }

  /**
   * emits logoutEvent
   */
  public _onLogout(): void {
    this.onLogoutEvent.emit(undefined);
  }
}
