import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UCDailySalesGoldSilverPageReportComponent }
  from './daily-sales-gold-silver-report-page';
import { AuthenticationGuard } from '../../../services/common/auth-guard';

const inventorySummaryRoutes: Routes = [
  {
    path: 'dailyGoldSilverReport',
    component: UCDailySalesGoldSilverPageReportComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(inventorySummaryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCSalesReportGoldSilverwRoutingModule {

}


