import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../../services/common/auth-guard';
import { UCRdrDataReportPageComponent } from './rdr-data-report-page.component';


const inventorySummaryRoutes: Routes = [
  {
    path: 'rdrDataReport',
    component: UCRdrDataReportPageComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(inventorySummaryRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class UCRdrDataReportRoutingModule {

}
