<mat-card fxFlex="100" fxFlex.xs="100" class="uc-accounting-menubar-panel">
  <div fxFlex="82" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
    <div style="box-sizing:border-box">
      <button #btn1 [disabled]="!isAccountingSelected" mat-button target="_blank" class="uc-accounting-menubar-item"
        (click)="navigateToAccountingDetailsScreen()">
        <mat-icon [class.uc-accounting-menubar-item-icon-disabled]="btn1.disabled"
          [class.uc-accounting-menubar-item-icon-enabled]="!btn1.disabled">description</mat-icon>Accounting Details
      </button>
    </div>

    <div *ngIf="sharedDataService.inventoryScreenAccess">
      <button #btn8 mat-button [disabled]="!isAccountingSelected" target="_blank" class="uc-accounting-menubar-item"
        (click)="exportExcel()">
        <mat-icon [class.uc-accounting-menubar-item-icon-disabled]="btn8.disabled"
          [class.uc-accounting-menubar-item-icon-enabled]="!btn8.disabled">
          <img src="assets/icon/excel-3-24.png" alt="excel.png">
        </mat-icon>Export Excel
      </button>
    </div>
  </div>
  <div *ngIf="!showClearAllButton" fxFlex="10"></div>
  <div *ngIf="showClearAllButton" class="divider"></div>
  <div *ngIf="showClearAllButton" fxFlex="10" fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="row" class="clearAllDiv" fxLayoutAlign="center center">
      <div style="padding-left: 5px;">Clear All</div>
      <span class="clearAllCloseBtn" (click)="clearAllButtonClicked($event);">×</span>
    </div>
  </div>
  <div class="divider"></div>
  <div fxFlex="10" fxLayout="column" layout-gt-sm="row">
    <div fxLayout="row" fxFlex="100" layout-xs="column" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div>Total vehicles</div>&nbsp;&nbsp;&nbsp;
        <div class="totalVehiclesFont"><strong>{{ totalAccountingCount }}</strong></div>
      </div>
    </div>
  </div>
</mat-card>