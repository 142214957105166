<div fxLayout="column">
  <region-selection-vin-search-toolbar fxFlex="100" (filterDateEvent)="onLoad($event)" [enteredvin]="enteredvin"
    [fromDate]="fromDate" [toDate]="toDate" [reportType]="reportType" [enteredDealerCode]="enteredDealerCode">
  </region-selection-vin-search-toolbar>

  <uc-sale-reversal-report-menubar-panel [isInventorySelected]="isInventorySelected"
    [selectedCertificationStatus]="selectedCertificationStatus" [selectedMakeCode]="selectedMakeCode"
    (clearAllButtonEvent)="clearAllFilters();" [showClearAllButton]="showClearAllButton"
    [totalInventoriesCount]="totalInventoriesCount" (exportPdfEvent)="downLoadPdf()"
    (exportExcelEvent)="downLoadXlsx()"></uc-sale-reversal-report-menubar-panel>

  <uc-inventory-grid [columnDefs]="columnDefs" [rows]="rows" (applySortEvent)="applyInventorySort($event)"
    (applyFilterEvent)="ApplyFilter($event)" [clearSort]="clearSort">
  </uc-inventory-grid>
</div>
