'use strict';
import { UCAccountingSummary } from '../model/AccountingSummary';
import { Message } from '../model/Message';

// Vehicle Summary View Model
export interface UCAccountingSummaryView {
  endDate?: string;

  startDate?: string;
  // List of vehicles
  accounting: UCAccountingSummary[];
  // message object
  message?: Message;

  count?: number;
}
