import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { UCRecallReportRoutingModule }
  from './recall-report-routing.module';
import { UCRecallPageReportComponent } from './recall-report-page.component';
import { UCRecallReportGridComponent }
  from './recall-report-grid.component';

@NgModule({
  imports: [
    SharedModule,
    UCRecallReportRoutingModule
  ],
  exports: [
    UCRecallPageReportComponent
  ],
  declarations: [
    UCRecallPageReportComponent,
    UCRecallReportGridComponent
  ]
})

export class UCRecallReportModule {

}
